import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-modal-field-map',
  templateUrl: './field-map.component.html',
  styleUrls: ['./field-map.component.css']
})

export class FieldMapComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();
	public appearance 			: MatFormFieldAppearance = 'fill';

	public error 			= null;
	public response 		= null;

	public override settings : any = {
		'id' : 'am_modal_field_map',
		'title': 'Field Mapper',
		'canClose': true,
		'submit': 'Set Map',
		'addMap': 'Add Map',
		'close': 'Close',
		'fromField': null,
		'toField': null,
		'maps': [],
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 		public override parentSettings		: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}


	removeMap(index) {
		this.settings.maps.splice(index, 1);
	}

	addMap() {
		this.settings.maps.push({
			mapKey: '', mapValue: '',
		});
	}

	/*
	 * Prepare and submit
	*/
	prepareAndSubmit() : void {
		this.submit();
	}

	/*
	 * Submits the result
	 * @param {Object} result 
	*/
	override submit() : void {
		this.submitted_emitter.emit(this.settings);
	}
}

