import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { loaderService } from '../../services/loader.service';
import { lmsService } from '../../services/lms.service';
import { storageService } from '../../services/storage.service';
import { permissionService } from '../../services/permissions.service';
import { feedbackService } from '../../services/feedback.service';

import { errorLogSettings } from './error-log.settings';

@Component({
	selector: 'lio-error-log',
	templateUrl: './error-log.component.html'
})
export class ErrorLogComponent implements OnDestroy {
	public appearance 		:MatFormFieldAppearance = 'outline';
	public fields			:Array<any>				= this.permissionService.setFields(this.errorLogSettings.fields);
	public errorLog			:any 					= {};
	public errorID			:string 				= '';

	private subscriptions	:Subscription			= NEVER.subscribe();

	constructor(
		@Inject(navService)			public	navService			:navService,
		@Inject(debugService)		public	debugService		:debugService,
		@Inject(loaderService)		public	loaderService		:loaderService,
		@Inject(lmsService)			public	lmsService			:lmsService,
		@Inject(storageService)		public	storageService		:storageService,
		@Inject(permissionService)	public	permissionService	:permissionService,
		@Inject(feedbackService)	public	feedbackService		:feedbackService,
		@Inject(errorLogSettings)	public	errorLogSettings	:errorLogSettings
	){
		this.debugService.register('errorlog', this);
		this.navService.setActivePage('errorlog');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/*
	 * Init
	*/
	init(){
		var code = this.getErrorCode();
		this.errorID = code;
		if (code) {
			this.getError(code);
		} else {
			this.navService.displayPage();
		}
	}

	/*
	 * Gets the error code
	 * @return {string}
	*/
	getErrorCode(){
		var params = this.storageService.getInitialParams(),
			code = params ? params.code : this.storageService.get('errorCode');
	
		return code;
	}

	/*
	 * Gets an error by code
	 * @param {string} code
	*/
	getError(code){
		this.feedbackService.clearErrors();
		this.lmsService.post('error/getErrorByID', {'errorID': code}).then((result) => {
			var error = result.properties.error;

			if (error) {
				this.errorLog = error;
			} else {
				this.feedbackService.setError('noErrorFoundByThatID');
			}
			this.navService.displayPage();
        });
	}

	/*
	 * Delete an error by code
	 * @param {string} code
	*/
	deleteError(code){
		this.lmsService.post('error/delete', {'errorID': code}).then((result) => {
			var success = result.success;

			if (success) {
				this.feedbackService.setMessage('deletedSuccessfully');
			}
			this.errorLog = {};
        });
	}

	/*
	 * On submit
	*/
	submit(){
		let code = this.errorID;
		if (code) {
			this.getError(code);
		}
	}

	/*
	 * On submit
	*/
	delete(){
		let code = this.errorID;
		if (code) {
			this.deleteError(code);
		}
	}
}