/*
 * Service for storing inventory for reuse
*/
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { lioLogService } from './lio-log.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class storageService {
	courseID 				: number;
	companyID 				: number;
	activeCompanyID 		: number;
	employeeID 				: string;
	permissionID 			: number;
	initialParams 			: any;
	rawParams 				: any;
	inventory 				: any;
	maxStoredValueLength 	: number;
	lastRequest 			: any;
	persistentItems 		: any;
	allowedParams 			: any;

	constructor(
		@Inject(lioLogService)	private lioLogService	:lioLogService,
		@Inject(utilService)	private utilService		:utilService, 
		@Inject(debugService)	private debugService	:debugService, 
		@Inject(DOCUMENT)		private document		:Document
	) {
		this.courseID = null;
		this.companyID = null;
		this.activeCompanyID = null;
		this.employeeID = null;
		this.permissionID = null;
		this.initialParams = null;
		this.rawParams = null;
		this.inventory = [];
		this.maxStoredValueLength = 100000;
		this.lastRequest = {};
		this.persistentItems = ['branding', 'companyID', 'employeeID', 'localizingField', 'langID'];
		this.allowedParams = ['sid', 'qa', 'task', 'companyid', 'presetid', 'euid', 'suppresscontext', 'lang', 'langID', 'testlangs', 'speed', 'courseid', 'r', 'disclosureid', 'showlms', 'link'];
		this.debugService.register('storage', this);
		this.init();
	}

	/*
		* Initialize
	*/
	init() {
		this.setInitialParams();
		this.setLangIDFromParams();
		this.setQA();
	}

	setLangIDFromParams() {
		let langID = this.getEitherParam(['lang', 'langID']);
		if (langID) {
			this.setLangID(langID);
			this.set('changedLang', true);
		} else {
			this.set('changedLang', false);
		}
	}


	setQA() {
		if (this.initialParams) {
			let qa = this.initialParams.qa;
			if (typeof qa != 'undefined') {
				this.add('qa', qa);
			}	
		}
		
	}

	setLangID(langID) {
		if (langID) {
			this.set('langID', langID);
		}
	}

	getLangID() {
		return this.get('langID');
	}

	/*
		* Set the initial launch params
	*/
	setInitialParams() {
		let rawParams = this.document.defaultView.location.href.split('?')[1],
			params = this.utilService.queryStringToJSON();

		if (this.initialParams) {
			return;
		}
		
		this.rawParams = rawParams;

		if (params) {
			params = this.validateParams(params);
			this.initialParams = this.utilService.copy(params);
		}
	}

	/*
		* Validates params and clean some common booleans
		* @param {array} params
		* @return {array}
	*/
	validateParams(params) {
		let keys = Object.keys(params);
		keys.forEach((key) => {
			if (this.allowedParams.indexOf(key.toLowerCase()) == -1) {
				this.lioLogService.log(['Invalid Param Passed', key, params[key]]);
			}
			switch (params[key]) {
				case 'false':
					params[key] = false;
					break;
				case 'true':
					params[key] = true;
					break;
				default:
					break;
			}
		}, this);
		return params;
	}

	/*
		* Sets the last request
		* @param {object} request
	*/
	setLastRequest(request) {
		request = this.utilService.copy(request);
		if (request && request.password) {
			request.password = '********';
		}
		this.lastRequest = request;
	}

	/*
		* Gets the last request
		* @return {object}
	*/
	getLastRequest() {
		return this.lastRequest;
	}

	/*
		* Get the initial launch params
	*/
	getInitialParams() {
		return this.initialParams;
	}

	/*
		* Resets the launch params
	*/
	resetParams() {
		this.initialParams = null;
		this.setInitialParams();
	}

	/*
		* Get the current params
	*/
	getParams() {
		let params = this.utilService.queryStringToJSON();
		if (!params) {
			params = {};
		}
		return params;
	}

	/*
		* Get the current params by name
		* @param {string} name
		* @return {?string}
	*/
	getParam(name) {
		let params = this.getParams();
		if (params && params[name]) {
			return params[name];
		}
		return null;
	}

	/*
	* Get either of the provided params by name
	* @param {array} items
	* @return {?string}
	*/
	getEitherParam(items) {
		let value = null;
		items.forEach((item) => {
			item = this.getParam(item);
			if (item) {
				value = item;
				return;
			}
		});
		return value;
	}

	/*
		* Sets the course ID
		* @return {boolean}
	*/
	setCourseID(courseID) {
		this.courseID = courseID;
		return true;
	}

	/*
		* Gets the course ID
	*/
	getCourseID() {
		return this.courseID;
	}

	/*
		* Adds an item to the storage by name
		* @param {string} name
		* @param {*} value
		* @return {boolean}
	*/
	add(name, value) {
		this.remove(name);
		this.inventory.push({'name': name, 'value': value});
		if(this.document.defaultView.localStorage) {
			this.document.defaultView.localStorage.removeItem(name);
			if (value) {
				if (typeof value != 'string') {
					try{
						value = JSON.stringify(value);
					} catch(e) {return null;}
				}

				if (value.length < this.maxStoredValueLength) {
					this.document.defaultView.localStorage.setItem(name, value);
				}
			}
		}
		return true;
	}

	/*
		* Removes an item from the storage by name
		* @param {string} name
	*/
	remove(name) {
		let item = this.findItem(name);
		if (item) {
			this.deleteItem(item);
		}
		if(this.document.defaultView.localStorage) {
			this.document.defaultView.localStorage.removeItem(name);
		}
	}

	// Adding additional name, same functionality
	set(name, value) {
		return this.add(name, value);
	}

	/*
		* Gets the inventory
		* @return {array}
	*/
	getInventory() {
		return this.inventory;
	}

	/*
	* Clears the inventory
	*/
	clearInventory() {
		this.inventory.forEach((item) => {
			let deleteIt = true;
			if (this.persistentItems.indexOf(item.name) > -1) {
				deleteIt = false;
			}

			if (deleteIt) {
				this.lioLogService.log(['Deleting ' + item.name, item]);
				this.deleteItem(item);
			}
		});
		this.inventory = [];
	}


	/*
	* Handles being logged off
	*/
	handleLoggedOff() {
		this.clearInventory();
		this.remove('viewedNotifications');
		this.remove('deniedNotificationPermissions');
		this.remove('acceptedNotificationPermissions');
		this.remove('preview');
	}

	/*
		* Clears inventory
	*/
	reset() {
		if (this.document.defaultView.localStorage) {
			this.document.defaultView.localStorage.clear();
		}
		this.clearInventory();
	}

	/*
		* Finds an item in the storage
		* @param {string} name
		* @return {?object} value
	*/
	findItem(name) {
		let foundItem = null;
		this.inventory.forEach((item) => {
			if (item.name === name) {
				foundItem = item;
				return;
			}
		});
		return foundItem;
	}

	/*
		* Deletes an item from the inventory
		* @param {string} name
		* @return {boolean}
	*/
	deleteItem(item) {
		if (this.document.defaultView.localStorage) {
			this.document.defaultView.localStorage.removeItem(item.name);
			let index = this.inventory.indexOf(item);
			return this.inventory.splice(index, 1).length > 0;
		}
	}

	/*
		* Gets an item from a cookei
		* @param {string} name
		* @return {*}
	*/
	getFromCookie(item) {
		if (this.document.defaultView.localStorage) {
			let cookie = this.document.defaultView.localStorage.getItem(item);
			try{
				cookie = JSON.parse(cookie);
			} catch(e) {
				return cookie;
			}
			
			if (cookie) {
				return cookie;
			}
		}
		return null;
	}

	/*
		* Gets an item from the inventory by name
		* @param {string} name
		* @return {*} value
	*/
	get(name) {
		let foundItem = null;
		this.inventory.forEach((item) => {
			if (item.name === name) {
				foundItem = item;
				return foundItem;
			}
		});

		if (foundItem) {
			return foundItem.value;
		}

		return this.getFromCookie(name);
	}


	/*
	 	* Gets an item from the inventory by name and then deletes it
	*/
	getAndClear(name) {
		let value = this.get(name);
		this.remove(name);
		return value;
	}

	/**
	 * Sets a list of employees to use for enrollments
	 * - Removes rulesToAssign to avoid mixups
	 */
	setEmployeesToAssign(employees){
		this.set('employeesToAssign', employees);		
		this.remove('rulesToAssign');
	}

	/**
	 * Sets a list of rules to use for enrollments
	 */
	setRulesToAssign(rules, employees){
		this.set('rulesToAssign', rules);		
		if(employees){
			this.set('employeesToAssign', employees);	
		}else{
			this.remove('employeesToAssign');
		}
	}
}