<div class="row">
	<!-- Batch Processing Form -->
	<lio-panel-tabber #tabCache class="col-md-10 offset-md-1" (onTabChange)="onPanelTabChange($event)">
		<lio-panel-tab [settings]="tabSettings.config">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.config.selected"
					[fields]="tabSettings.config.fields"
					[fieldConfig]="tabSettings.config.fieldConfig"
					[model]="config"
					(onChanged)="onUpdate()">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)" 
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.config.buttons" 
					[model]="model">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.format">
			<form *lioPanelTabContent role="form" autocomplete="off" novalidate>
				<lio-file-format
					[model]="format"
					[controlObject]="formatCtrl" 
					onUpdate="onUpdate()">
					</lio-file-format>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.format.buttons" 
					[model]="model">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.upload">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.upload.selected"
					[fields]="tabSettings.upload.fields"
					[fieldConfig]="tabSettings.upload.fieldConfig"
					[model]="model"
					(onChanged)="onUpdate()">
				</lio-form-inputs>
				<div [hidden]="!filteredEmployees.length">
					<lio-card body-class="p-0" footer-class="container-fluid" [hideErrors]="true">
						<div lio-card-body lio-paginator controlID="tempEmployees" [collection]="employees" [settings]="tabSettings.upload.pagination" (onResults)="updateFilteredEmployees($event)">
							<lio-results-table
								paginatorID="tempEmployees"
								[fields]="tabSettings.employee.fields"
								[fieldConfig]="tabSettings.employee.fieldConfig"
								[results]="filteredEmployees">
							</lio-results-table>
						</div>
						<div lio-card-footer>
							<lio-paginator-controls class="row" controlID="tempEmployees"></lio-paginator-controls>
						</div>
					</lio-card>
				</div>
				<lio-buttons
					(onClick)="handleClick($event)" 
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.upload.buttons" 
					[model]="model">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.uploadHistory">
			<lio-batch-history *lioPanelTabContent [settings]="tabSettings.uploadHistory" [history]="history"></lio-batch-history>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.processHistory">
			<lio-batch-history *lioPanelTabContent [settings]="tabSettings.processHistory" [history]="history"></lio-batch-history>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.globalUploadHistory">
			<lio-batch-history *lioPanelTabContent [settings]="tabSettings.globalUploadHistory" [history]="history"></lio-batch-history>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.globalProcessHistory">
			<lio-batch-history *lioPanelTabContent [settings]="tabSettings.globalProcessHistory" [history]="history"></lio-batch-history>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>

