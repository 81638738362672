import { Component } from '@angular/core';
import { Optional } from '@angular/core';
import { Inject } from '@angular/core';

import { navService } from '../../../../services/nav.service';

@Component({
	selector: 'lio-title',
	templateUrl: './lio-title.component.html'
})
export class LIOTitleComponent {
	constructor(@Optional() @Inject(navService) private navService:navService){}

	getTitle(){
		if(this.navService){
			return this.navService.getActivePage();
		} else {
			return 'Loading...';
		}
	}
}