
/*
 * Service for Controlling Tracker of courses for learn IO
*/
import { encode, decode } from 'js-base64';
import { decompressFromBase64 } from 'lz-string';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { apiInterface } from './api.interface';

import { feedbackService } from './feedback.service';
import { scorm12Service } from './scorm-12.service';
import { lioLogService } from './lio-log.service';
import { utilService } from './util.service';
import { configSettings } from '../settings/config.settings';
import { scorm12Settings } from '../settings/scorm-12.settings';
import { lmsService } from './lms.service';


@Injectable({
	providedIn: 'root',
})
export class scormLSPService extends scorm12Service implements apiInterface {
	public override APIversion 			:string		= 'ScormLSP';
	public override protocol 			:string		= 'scormLSP';
	public override launchMethod 		:string		= 'scormLSP';
	public courseID 			:string		= '';

	constructor(
		@Inject(DOCUMENT)			protected override document			:Document,
		@Inject(feedbackService)	protected override feedbackService	:feedbackService,
		@Inject(lioLogService)		protected override lioLogService	:lioLogService,
		@Inject(utilService)		protected override utilService		:utilService,
		@Inject(scorm12Settings)	protected override scorm12Settings	:scorm12Settings,
		@Inject(lmsService)			protected lmsService				:lmsService,
		@Inject(configSettings)		protected configSettings			:configSettings
	){
		super(document, feedbackService, lioLogService, utilService, scorm12Settings);
	}

	/*
	 * Initialize the API
	 * @return {boolean}
	 * @override
	*/
	override initialize() {
		super.initialize();
		this.addWindowObserver();
		return true;
	}


	/*
	 * Gets the suspend data
	 * @return {string}
	*/
	override getSuspendData() {
		let suspendData = this.courseSession.core_lesson;

		if (suspendData) {
			suspendData = decodeURIComponent(suspendData);
		}
		
		if (suspendData) {
			suspendData = this.decompress(suspendData);		}

		return suspendData;
	}

	/*
	 * Sets the suspend data
	 * @param {string} suspendData
	 * @return {string}
	*/
	override setSuspendData(suspendData){
		return this.compress(suspendData);
	}

	/*
	 * Handles the course is closing
	*/
	override handleCourseClosing() {
		// Set completion when using on close method
		if (this.courseSession.completionMethod === 'onclose') {
			// no need to dispatch the commit, since this was invoked from the tracker and will automatically commit
			this.itemsToCommit['aicc_lesson_status'] = 'completed';
			this.itemsToCommit['aicc_score'] = '100';
		}

		this.log('Course Closing');
	}


	/*
	 * Compresses data
	 * @param {string} value
	 * @return {string}
	*/
	compress(value) {
		let data 		= {},
			stringified = '',
			compressed 	= '';

		try  {
			data[this.courseID] = value;
			stringified 		= JSON.stringify(data);
			compressed 			= encode(stringified);
			return compressed;
		} catch (e) {
			this.lioLogService.log(['Failed to compress', value]);
			return value;
		}
	}

	/*
	 * DeCompresses data
	 * @param {string} value
	 * @return {string}
	*/
	decompress(compressed) {
		let decodedURI 		= decodeURIComponent(compressed),
			decompressed	= '',
			parsed 			= '',
			value 			= '';
		
		try {
			decompressed 	= decode(decodedURI);
			this.lioLogService.log(['Decoded', decompressed]);
			parsed 			= JSON.parse(decompressed);
			this.lioLogService.log(['Parsed', parsed]);
			value 			= parsed[this.courseID];
			this.lioLogService.log(['Decompressed', value]);
			if (typeof value === 'undefined' || value === null) {
				value = '';
			}
			return value;
		} catch (e) {
			this.lioLogService.log(['Failed to decompress, trying legacy decompression']);
			return this.legacydecompress(compressed);
		}
	}


	/*
	 * DeCompresses data (legacy method)
	 * @param {string} value
	 * @return {string}
	*/
	legacydecompress(compressed) {
		let decodedURI 		= decodeURIComponent(compressed),
			decompressed	= '',
			parsed 			= '',
			value 			= '';
		
		try {
			decompressed 	= decompressFromBase64(decodedURI);
			this.lioLogService.log(['Legacy decoded', decompressed]);
			parsed 			= JSON.parse(decompressed);
			this.lioLogService.log(['Legacy parsed', parsed]);
			value 			= parsed[this.courseID];
			this.lioLogService.log(['Legacy Decompressed', value]);
			if (typeof value === 'undefined' || value === null) {
				value = '';
			}
			return value;
		} catch (e) {
			this.lioLogService.log(['Failed legacy decompression, returning as is', decodedURI]);
			return decodedURI;
		}
	}

	/*
	 * Sets the lesson location
	 * @param {string} lessonLocation
	 * @return {string}
	*/
	override setLessonLocation(lessonLocation) {
		return this.compress(lessonLocation);
	}

	/*
	 * Gets the lesson location
	 * @return {string}
	*/
	override getLessonLocation() {
		let lessonLocation = this.getModel('cmi.core.lesson_location').value;
		
		if (!lessonLocation) {
			lessonLocation = '';
		}

		return this.decompress(lessonLocation);
	}

	/*
	 * Gets the lesson location
	 * @return {string}
	*/
	override initLessonLocation() {
		let lessonLocation = this.courseSession.aicc_lesson_location;
		
		if (!lessonLocation) {
			lessonLocation = '';
		}

		return this.decompress(lessonLocation);
	}

	/*
	 * Initialize the API
	 * @return {boolean}
	 * @override
	*/
	addWindowObserver() {
		this.document.defaultView.onmessage = (e) => {
			let msg 			= e.data.msg ? e.data.msg : e.data,
				origin			= e.origin + '/',
				allowedOrigins = [
					'https://www.trueofficelearning.com/',
					'https://www.trueoffice.com/',
					'https://www.corpedia.com/',
					'https://www-stage.corpedia.com/',
					'http://127.0.0.1/', 
					'http://localhost/', 
					this.configSettings.serverGlobal,
				];
			
			if (allowedOrigins.indexOf(origin) > -1) {
				this.lioLogService.log('Origin Allowed');
			} else {
				this.dispatchError('Invalid Origin From Post Message: ' + origin);
				return;
			}


			if (msg == 'COURSE_ID_SET') {
				this.courseID = e.data.courseID;
				this.lioLogService.log(['courseID set', this.courseID]);
				// set on each courseID switch since we are dependent on the lsp choice
				if (!this.setAPIData()) {
					this.dispatchError('Failed to Set API Data');
					return false;
				}
			}

			if (msg === 'REQUEST_COURSE_ID') {
				this.getCourseInfo(e.data.courseID).then((course) => {
					e.source.postMessage({'msg': 'COURSE_INFO', 'course': course}, '*');
				});
			}
		};
	}


	/*
	 * Gets the course info
	 * @return {string} courseID
	 * @return {object}
	 * @override
	*/
	getCourseInfo(courseID) {
		return this.lmsService.post('courses/getCourseByID', {'courseID': courseID}).then((result) => {
				if (result.success && result.properties.course) {
					return result.properties.course;
				}
				return null;
			});
	}

}