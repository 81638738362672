import { Component, Inject, Input, OnDestroy } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../../services/nav.service';
import { formValidatorService } from '../../../../../services/form-validator.service';
import { coursesService } from '../../../../../services/courses.service';
import { storageService } from '../../../../../services/storage.service';
import { feedbackService } from '../../../../../services/feedback.service';
import { debugService } from '../../../../../services/debug.service';
import { utilService } from '../../../../../services/util.service';
import { eventService } from '../../../../../services/event.service';

@Component({
	selector: 'lio-event-editor',
	templateUrl: './editor.component.html',
})
export class LioEventEditor implements OnDestroy {
	private _eventService:eventService = null;

	public builderCtrl: any = {};

	private _controlObject :any;
	get controlObject(){
		return this._controlObject;
	}
	@Input() set controlObject(value: any) {    
		this._controlObject = value;

		if (value) {
			value.init				= this.init.bind(this);
		}
	}


	public get eventService(){
		return this._eventService;
	}
	@Input() public set eventService(value:eventService){
		this._eventService = value;
		
		this._eventService.setSettings(this.settings);

		// Subscription to events and handle after invoked
		this.subscriptions.add(
			this._eventService.eventsSubscription.subscribe(() => {
				if (this.eventID) {
					this.loadEvent();
				} else {
					if (this.managedEvent) {
						this.managedEvent = this._eventService.handleManagedEvent(this.managedEvent);
					} else if (this.model) {
						this.model = this._eventService.handleEvent(this.model);
					}
					
					this.loading = false;
					this.lockFields();
					this.navService.displayPage().then(() => {
						if (this.managedEvent) {
							this.model = this.managedEvent;
						}
						this.onUpdate();
					});
				}
			})
		);

		this.subscriptions.add(
			this._eventService.eventOptions.subscribe((options) => {
				this.formData.eventOptions = options;
			})
		);
			
		this._eventService.getEvents();
	}

	public formData = {
		eventOptions 	: [],
		filters 		: {},
		builderSettings : null
	};
	
	public loading			:boolean	= true;
	public canGetUserInfo	:boolean	= false;
	public model			:any		= {};
	public localeStrings 	:any 		= {
		setupEvent		: 'Setup Event',
		setupEventTrans	: 'scheduleEnrollments.setupEvent',
		saveEvent		: 'Save Existing Event',
		saveEventTrans	: 'scheduleEnrollments.saveEvent',
		getTotals		: 'Get User Count',
		getTotalsTrans	: 'scheduleEnrollments.getTotals',
		getUsers 		: 'Get Users',
		getUsersTrans 	: 'scheduleEnrollments.getUsers',
		clearForm		: 'Clear Form',
		clearFormTrans	: 'scheduleEnrollments.clearForm',
	};

	private _settings		:any		= {};
	public get settings(){
		return this._settings;
	}
	@Input() public set settings(value:any){
		this._settings 					= value;
		this.model 						= this.utilService.copy(value.model);
		this.formData.filters			= this.utilService.copy(value.filters);
		this.formData.builderSettings 	= this.utilService.copy(value.builderSettings);
	}

	private eventID			:string			= this.storageService.getAndClear('eventID');
	private managedEvent	:any			= this.storageService.getAndClear('managedEvent');

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(navService) 			public navService 			:navService,
		@Inject(formValidatorService) 	public formValidatorService :formValidatorService,
		@Inject(coursesService) 		public coursesService 		:coursesService,
		@Inject(storageService) 		public storageService 		:storageService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(utilService) 			public utilService 			:utilService
	){
		this.debugService.register('eventEditor', this);

		this.coursesService.get();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	init(eventID) {
		this.eventID = eventID;
		this.loadEvent();
	}
 
	/** 
	 * Loads the event
	 */
	loadEvent() {
		this.eventService.loadEvent(this.eventID).then((model) => {
			this.eventID = null;
			this.model = model;
			this.loading = false;
			this.lockFields();
			this.navService.displayPage();
		});
	}

	/*
	* Lock fields
	*/
	lockFields() {
		if(this.settings && this.settings.fields){
			this.settings.fields.forEach((field) => {
				if (field.lockWithID) {
					if (this.model.id) {
						field.locked = true;
					} else {
						field.locked = false;
					}
				}
			});
		}
	}

	/** 
	 * Resets the event form to defaults
	 */
	clearForm() {
		this.model = this.utilService.copy(this.settings.model);
	}

	/**
	 * On update of the form
	 */
	onUpdate(field:any = null) {
		console.log('updated', field);
		if (field) {
			this.navService.changedForm 	= true;
			if (field.model == 'filterValue') {
				//Update the filter if it exists
				this.model.filters.forEach((filter) => {
					if (filter.field == 'fn.daysuntilcoursedue' 
					|| filter.field == 'fn.dayspastdue') {
						filter.value = this.model.filterValue;
					}
				});
			}
		}
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.formValidatorService.resetFields();
		this.model.tempRules = this.getRules();
		this.canGetUserInfo = this.eventService.canGetUserInfo(this.model);
		this.model = this.eventService.handleEvent(this.model);

		console.log('rules', this.model.rules);
	}


	getRules() {
		if (!this.builderCtrl || typeof this.builderCtrl.getRules !== 'function') {
			return [];
		}
		let rules = this.builderCtrl.getRules(),
			clean = [];
		rules.forEach((rule) => {
			clean.push({
				field: rule.field,
				operator: rule.operator,
				value: rule.value,
				value2: rule.value2,
			})
		})
		return clean;
	}

	/** 
	 * Creates / Edits an event
	 */
	editEvent() {
		if (!this.eventService.blockPastDates(this.model)) {
			return;
		}
		if (!this.eventService.validateEvent(this.model)) {
			return;
		}
		this.eventService.editEvent(this.model);
	}

	/**
	 * Prevent default submit
	 */
	preventDefault() {
		return false;
	}

	/** 
	 * Gets the user count
	 */
	getUserCount() {
		this.eventService.getUserCount(this.model);
	}

	/** 
	 * Gets the user
	 */
	getUsers() {
		this.eventService.getUsers(this.model);
	}
}