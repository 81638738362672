<div class="row">
	<div class="col-md-10 offset-md-1">
		 <lio-panel-tabber>
			<lio-panel-tab [settings]="panelConfig.select">
				<ng-container *lioPanelTabContent>
					<form role="form" autocomplete="off" novalidate>
						<lio-form-inputs
							[fields]="panelConfig.select.settings.fields"
							[fieldConfig]="panelConfig.select.settings.fieldConfig"
							[model]="report"
							(onChanged)="onUpdate($event)">
						</lio-form-inputs>
						<div class="btn-group mb-1 ml-1" >
							<lio-buttons (onClick)="handleClick($event)" [buttons]="panelConfig.select.settings.buttons" [model]="report"></lio-buttons>
						</div>
					</form>
					<lio-query-tool 
						*ngIf="enableQueryTool"
						[hidden]="!showQueryTool"
						[filters]="filters" 
						[controlSettings]="reportType.queryToolSettings" 
						[controlObject]="querytool"
						(results)="handleResponse($event)"
						(loaded)="queriesLoaded()">
					</lio-query-tool>
				</ng-container>
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.edit"></lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.create"></lio-panel-tab>
		</lio-panel-tabber> 
	</div>
</div>