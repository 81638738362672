<lio-loading-spinner [hidden]="!loading"></lio-loading-spinner>
<section [hidden]="loading" 
	localize 
	[model]="localeStrings" 
	[items]="['setupEvent','saveEvent','getTotals','getUsers','clearForm']">

	<form class="container-fluid" name="eventForm" novalidate (submit)="preventDefault()">
		<lio-form-inputs
			[builderCtrl]="builderCtrl"
			[fields]="settings.fields"
			[fieldConfig]="settings.fieldConfig"
			[model]="model"
			(onChanged)="onUpdate($event)"
			[formdata]="formData"
			[appearance]="settings.appearance || 'fill'">
		</lio-form-inputs>
	</form>
	<!-- Buttons -->
	<div class="btn-toolbar d-block mt-2 text-center">
		<button id="am_form_submit" 
			type="submit" 
			(click)="editEvent()" 
			[ngClass]="{'btn-primary' : !model.id, 'btn-warning text-white' : model.id}" 
			class="btn btn-sm font-weight-bold m-1" >

			<span *ngIf="!model.id">{{localeStrings.setupEvent}}</span>
			<span *ngIf="model.id">{{localeStrings.saveEvent}}</span>
		</button>
		<button *ngIf="model.id || canGetUserInfo" 
			id="am_form_get_total" 
			(click)="getUserCount()" 
			class="btn btn-sm btn-primary font-weight-bold m-1">
			
			<span>{{localeStrings.getTotals}}</span>
		</button>

		<button *ngIf="model.id || canGetUserInfo" 
			id="am_load_results" 
			(click)="getUsers()" 
			class="btn btn-sm btn-primary font-weight-bold m-1" 
			type="submit">

			<span>{{localeStrings.getUsers}}</span>
		</button>

		<button *ngIf="model.id" 
			id="am_form_clear" 
			(click)="clearForm()" 
			class="btn btn-sm btn-outline-warning font-weight-bold m-1">

			<span>{{localeStrings.clearForm}}</span>
		</button>
	</div>
</section>