<!-- Topics -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Topics</mat-card-title>
	<mat-card-content id="am_form_updates" class="mb-0" [style.fontSize.px]="11">
			<mat-label>Topics</mat-label>
			<lio-searchable-select 
				[appearance]="appearance"
				[model]="model"
				[options]="topics"
				optionValueField="topicCodeID"
				[field]="topicField"
				[searchable]="true"
				[orderBy]="'name'"
				(onUpdate)="onUpdate.emit($event)"
				[multiple]="true">
			</lio-searchable-select>
	</mat-card-content>

	<mat-card-actions [style.fontSize.px]="11" localize [model]="localeStrings" item="addTopic">
		
		<ul class="max-size-150 row" *ngIf="!loading">
			<li class="col-6" *ngFor="let topic of topics | filter:{'items': model.topicCodeIDs, 'name': 'topicCodeID', 'requiredItems': true} | orderBy:'name':false" [innerHtml]="topic.name | html"></li>
		</ul>
		<!-- Add New Topic Button -->
		<button mat-button color="primary" [hidden]="showNewTopicInput" (click)="showNewTopicInput = true">
			<span class="fa fa-plus-square fa-lg mr-2"></span>
			<span>{{localeStrings.addTopic}}</span>
		</button>

		<!-- New Topic Input -->
		<div [hidden]="!showNewTopicInput" class="pl-2">
			<mat-form-field appearance="fill" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<label for="am_form_notes_newTopic" class="sr-only">New Topic</label>
				<input id="am_form_notes_newTopic" 
					matInput
					placeholder="Add new topic" 
					[(ngModel)]="topic.name" 
					type="text" 
					maxlength="{{field.max || 100}}"/>
			</mat-form-field>
			<button mat-button color="primary" [disabled]="!topic.name" (click)="addTopic()" type="submit">
				<span class="fa fa-plus-square fa-lg mr-2"></span>
				<strong>{{localeStrings.addTopic}}</strong>
			</button>
		</div>
	</mat-card-actions>
</mat-card>