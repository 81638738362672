import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';

import { localizationService } from '../../../../services/localization.service';
import { permissionService } from '../../../../services/permissions.service';
import { storageService } from '../../../../services/storage.service';
import { navService } from '../../../../services/nav.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';

@Component({
  selector: 'lio-catalog-collapsed',
  templateUrl: './catalog-collapsed.component.html'
})
export class LIOCatalogCollapsed {
	@Input() indexInList		:number;
	@Input() catalog			:any = null;
	@Input() view				:any = null;
	@Input() fieldsObject		:any = null;
	@Input() filters			:any = null;

	@Output() clickedCatalog	:EventEmitter<any> = new EventEmitter();
	@Output() previewCourse		:EventEmitter<any> = new EventEmitter();
	@Output() clickedPrint		:EventEmitter<any> = new EventEmitter();
	@Output() selfEnroll		:EventEmitter<any> = new EventEmitter();

	public localeStringItems = [
		'courseDescription',
		'courseTypeHeader',
		'courseURL',
		'enroll',
		'enrolled',
	];

	public localeStrings = {
		courseDescription 		: 'COURSE DESCRIPTION:',
		courseDescriptionTrans 	: 'coursecatalog.courseDescription',
		courseTypeHeader 		: 'COURSE TYPE:',
		courseTypeHeaderTrans 	: 'coursecatalog.courseTypeHeader',
		courseURL 				: 'DOWNLOAD LINK(s):',
		courseURLTrans 			: 'coursecatalog.courseURL',
		enroll 					: 'Enroll',
		enrollTrans 			: 'coursecatalog.enroll',
		enrolled 				: 'Enrolled in Course',
		enrolledTrans 			: 'coursecatalog.enrolled',
		print 				: 'Print',
		printTrans 			: 'coursecatalog.print'
	};
	
	constructor(
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(navService) 			public navService			:navService,
		@Inject(processingService)		public processingService	:processingService,
		@Inject(utilService)			public utilService			:utilService
	){}

	/*
	 * Is the field visible
	 @param {string} fieldName
	 @return {boolean}
	*/
	isFieldVisible(fieldName) {
		if (!this.fieldsObject) {
			return true;
		}
		return this.fieldsObject[fieldName].visible;
	}

	/*
	 * User clicked on catalog admin button
	 * @param {number} courseID
	*/
	gotoAdmin(courseID) {
		this.storageService.set('catalogID', courseID);
		this.navService.goto('catalogadmin');
	}
}