import { Component, Input, Inject, OnInit, DoCheck, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NEVER, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { navService } from '../../../../services/nav.service';
import { localizationService } from '../../../../services/localization.service';
import { debugService } from '../../../../services/debug.service';
import { permissionService } from '../../../../services/permissions.service';
import { utilService } from '../../../../services/util.service';

import { LioSearchableSelectField } from '../../lio-forms.models' ;

@Component({
	selector: 'lio-searchable-select',
	templateUrl: './searchable-select.component.html',
	styleUrls: ['./searchable-select.component.css']
})
export class LioSearchableSelect implements OnInit, DoCheck, OnDestroy {
	//outputs the field when the model changes
	@Output() onUpdate	: EventEmitter<LioSearchableSelectField> 	= new EventEmitter();
	//outputs the model when the model changes
	@Output() change	: EventEmitter<any> 						= new EventEmitter();
	// outputs what was searched for
	@Output() searchedFor	: EventEmitter<any> 						= new EventEmitter();

	public initialized				:boolean 				= false;

	private optionsSubscription		:Subscription			= null;
	public subscriptions		:any 			= null;

	public selectionFilterCtrl		:FormControl			= new FormControl();
	public filteredOptions			:BehaviorSubject<any[]>	= new BehaviorSubject<any[]>([]);
	public search					:string					= '';
	public _oldSearch				:string					= '';
	public _oldValue				:string					= '';
	public noOptions 				:any 					= {
		name		:'No Options Found', 
		nameTrans	:'form.noOptions', 
		value		:''
	};

	public labelLocaleStrings : {
		name		:string, 
		nameTrans	:string,
		nameMacros	:any
	} = {
		name		: '',
		nameTrans	: '',
		nameMacros	: null
	};

	//name of the field
	public get name():string{
		return this.labelLocaleStrings.name;
	}
	@Input() public set name(val: string){
		this.labelLocaleStrings.name = val
	}
	//translation tag for name of the field
	public get nameTrans():string{
		return this.labelLocaleStrings.nameTrans;
	}
	@Input() public set nameTrans(val: string){
		this.labelLocaleStrings.nameTrans = val
	}
	//translation macros for field label
	public get nameMacros():string{
		return this.labelLocaleStrings.nameMacros;
	}
	@Input() public set nameMacros(val: string){
		this.labelLocaleStrings.nameMacros = val
	}

	//form group for monitoring the controls
	@Input() formGroup					: FormGroup = new FormGroup({});
	//form group for monitoring the controls
	@Input() formControlRef				: FormControl = new FormControl();
	//data model we are editing
	@Input() model						: any 			= {};
	//options we can choose from, usually brought in via the field input
	@Input() options					: Array<any>	= [];
	//automation identifier
	@Input() amID						: string		= '';
	//if true, this is searchable
	@Input() searchable					: boolean 		= false;
	//if true, this is a multi-select
	@Input() multiple					: boolean 		= false;
	//if true, show an input label
	@Input() showLabel					: boolean 		= true;
	//Angular material appearance setting
	@Input() appearance					: MatFormFieldAppearance = 'fill';
	//Which field in each option is treated as the name
	@Input() optionNameField			: string		= 'name';
	//Which field in each option is treated as the value
	//Not used if optionAsValue is true
	@Input() optionValueField			: string		= 'value';
	//if true, the whole option is used as the value
	@Input() optionAsValue				: boolean		= false;
	//if true, remove form field padding from the bottom
	@Input() noFormFieldPB				: boolean		= false;
	//if true, include options marked as locked, but disable them
	@Input() includeLockedOptions		: boolean		= false;
	//if true, include options marked as invisible
	@Input() includeInvisibleOptions	: boolean		= false;
	//should the select stretch to fill its container
	@Input() fullWidth					: boolean		= true;
	//padding for top of select
	@Input() paddingTop					: number		= 0;
	//padding for bottom of select
	@Input() paddingBottom				: number		= 2;
	//alternative to field.locked, disables the select
	@Input() disabled					: boolean		= false;
	//Background color for the select
	//Options are '' for transparent or 'white' for white
	@Input() backgroundColor			: string		= '';
	//font size, also affects padding
	@Input() fontSize					: string		= '';
	//which field to use for the tooltip
	@Input() optionTooltipField			: string		= 'tooltip';
	//what groups should we seperate options into, should have a value and optionally a name
	@Input() optionGroups				: Array<any> 	= [];
	//which field to use to group options
	@Input() optionGroupField			: string		= '';
	//option field to use for sorting
	@Input() orderBy					: string		= '';
	//whether to show the clear button
	@Input() clearable					: boolean		= false;

	//if true, include an empty option at the top of the list
	private _addEmptyOption				:boolean = false;
	@Input() get addEmptyOption()		:boolean {
		return this._addEmptyOption;
	}
	set addEmptyOption(val: boolean) {
		this._addEmptyOption = coerceBooleanProperty(val);
	}

	//function used to determine if two options are equal
	private _optionComparator			:(option1, option2)=>boolean = (option1, option2) => { 
		//by default options are checked for strict equality, with an exception if both objects have ids that match
		return typeof option1 != 'undefined' 
			&& typeof option2 != 'undefined' 
			&& (option1 === option2 
				|| (option1.id && option2.id && option1.id == option2.id));
	};
	@Input() get optionComparator()		:(option1, option2)=>boolean {
		return this._optionComparator	;
	}
	set optionComparator(val: (option1, option2)=>boolean) {
		this._optionComparator = val;
	}
	
	//field settings for this select
	private _field :LioSearchableSelectField;
	get field(){
		return this._field;
	}
	@Input() set field(value: LioSearchableSelectField) {    
		this._field = value;

		setTimeout(() => {
			if(!this.formGroup.controls[this.field.model]){
				this.formGroup.addControl(this.field.model, new FormControl());
			}
		});
		
		this.applyFilters();
	}

	constructor(
										private changeDetectorRef	:ChangeDetectorRef,
		@Inject(navService)	 			public navService 	:navService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(utilService) 			public utilService 			:utilService
	){
		this.subscriptions = NEVER.subscribe();

		this.subscriptions.add(
			this.navService.exiting.subscribe(() => { 
				this.initialized = false; 
			})
		);
		this.debugService.register('searchable', this);
	}
	
	ngOnDestroy(){
		if(this.optionsSubscription){
			this.optionsSubscription.unsubscribe();
		}
	}

	//set up listeners for updates
	ngOnInit() {
		if(this.field.options instanceof Observable){
			//field options is an observable, set our local options whenever it outputs
			this.optionsSubscription = this.field.options.subscribe((options) => {
				this.options = options;
				this.applyFilters();
			});
			this._oldOptionsObservable = this.field.options;
		}else if(this.field.options){
			//field options is a simple array, just accept it as our options
			this.options = this.field.options;
			this.applyFilters();
		}

		let debounce = this.field.debounce ? this.field.debounce : 100;

		this.selectionFilterCtrl.valueChanges
		.pipe(debounceTime(debounce))
		.subscribe(() => {
			this.onupdate();
		});

		this.selectionFilterCtrl.valueChanges.subscribe(() => {
			this.applyFilters();
		});

		if (this.field.amID) {
			this.amID = this.field.amID;
		}
		
		this.initialized = true;
	}


	setOverridableProperties() {
		let overrideableProperties = [
			'name',
			'nameTrans',
			'nameMacros',
			'searchable', 
			'multiple', 
			'includeInvisibleOptions', 
			'includeLockedOptions',
			'optionAsValue', 
			'optionNameField', 
			'optionValueField', 
			'fontSize', 
			'backgroundColor', 
			'optionTooltipField', 
			'optionGroups', 
			'optionGroupField', 
			'fullWidth', 
			'addEmptyOption', 
			'orderBy', 
			'filters', 
			'nameFormatter',
			'clearable'
		];

		let value = this._field;

		overrideableProperties.forEach((property) => {
			if (this.initialized) {
				if (property === 'multiple') {
					// cannot override multiple after initialization
					return;
				}
			}

			if(typeof value[property] != 'undefined'){
				this[property] = value[property];
			}
		});

		if(!this.amID){
			this.amID = 'am_form_' + value.model;
		}
	}

	private _oldModel				:any				= {};
	private _oldOptions				:Array<any>			= [];
	private _oldOptionsObservable	:Observable<any>	= null;
	private _oldFilters				:Array<any>			= [];
	private _oldOptionGroups		:Array<any>			= [];
	
	// Implementing less frequent checking 
	ngDoCheck() {
		if (!this.initialized) {
			return;
		}
		if(this.field 
		&& this.field.optionGroups instanceof Array 
		&& this.field.optionGroups != this._oldOptionGroups){
			//check for change in the option groups
			this._oldOptionGroups 	= this.field.optionGroups;
			this.optionGroups 		= this.field.optionGroups;

			//if target property doesn't exist on model, create it
			if(this.field.model && this.model && typeof this.model[this.field.model] == 'undefined'){
				this.model[this.field.model] = null;
			}
		}

		if(this.field.options instanceof Observable){
			if(this._oldOptionsObservable != this.field.options){
				//options observable changed, unsubscribe from the old one and subscribe to the new one
				this._oldOptionsObservable = this.field.options;
				if (this.optionsSubscription) {
					this.optionsSubscription.unsubscribe();
				}
				this.optionsSubscription = this.field.options.subscribe((options) => {
					this.options = options;
					this.applyFilters();
				});
			}
		}else if(this.field.options){
			if(this.optionsSubscription){
				//we changed from an observable to a regular array, get rid of subscription
				this._oldOptionsObservable = null;
				this.optionsSubscription.unsubscribe();
			}
			if(this.options != this.field.options){
				this.options = this.field.options;
			}
		}

		if(this.options && !this.utilService.deepArrayEquals(this.options, this._oldOptions)){
			//options changed, updated filtered options
			this._oldOptions = this.utilService.deepCopy(this.options);
			this.applyFilters();
		}

		if(this.field.filters && this.field.filters != this._oldFilters){
			this._oldFilters = this.field.filters;
			this.applyFilters();
		}

		if(!this.utilService.shallowObjectEquals(this.model, this._oldModel)){
			//if target property doesn't exist on model, create it
			if(this.model && typeof this.model[this.field.model] == 'undefined'){
				this.model[this.field.model] = null;
			}
			this._oldModel = this.utilService.shallowCopy(this.model);
			if(this.field.filters){
				this.applyFilters();
			}
		}

		//matches form control disabled state to the field
		let control = this.formGroup.controls[this.field.model];
		let locked = this.field.locked || this.disabled;
		if(control && control.disabled != locked){
			if(locked){
				control.disable();
			}else{
				control.enable();
			}
		}
	}

	applyFilters(){
		setTimeout(() => {
			this.setOverridableProperties();
			if (!this.options) {
				return;
			}
			let filteredOptions = this.options.slice();

			filteredOptions = this.filterSelection(filteredOptions);

			if(this.field && this.field.filters){
				this.field.filters.forEach((filter) => {
					if(typeof filter == 'function'){
						filteredOptions = filter(filteredOptions, this.model);
					}
				});
			}

			filteredOptions = this.fieldLockedOrOptionNotLocked(filteredOptions);
			this.filteredOptions.next(filteredOptions);

			this.changeDetectorRef.detectChanges();
		});
	}

	//filters the options based on the search
	filterSelection(options){
		if(options){
			// get the search keyword
			this.search = this.selectionFilterCtrl.value;
			if(this.search){
				this.search = this.search.toLowerCase();
				// filter
				return options.filter((option) => {
					if(option[this.optionNameField] 
					&& option[this.optionNameField].toLowerCase().indexOf(this.search) > -1){
						return true;
					}
					if(!this.optionAsValue && option[this.optionValueField] 
					&& typeof option[this.optionValueField] == 'string'
					&& option[this.optionValueField].toLowerCase().indexOf(this.search) > -1){
						return true;
					}
					return false;
				});
			}else{
				return options.slice();
			}
		}
	}

	//emits update events
	onupdate(){
		let value = this.model[this.field.model];
		if (value === this._oldValue && this.search === this._oldSearch) {
			return;
		}
		this._oldSearch = this.utilService.copy(this.search); 
		this._oldValue = this.utilService.copy(value);
		this.searchedFor.emit(this.search);
		this.onUpdate.emit(this.field);
		this.change.emit(this.model[this.field.model]);
	}

	getOptionName(option){
		let name = '';
		if(typeof option[this.optionNameField] != 'undefined'){
			name = option[this.optionNameField];
		}else if(this.optionAsValue){
			name = option;
		}else{
			name = option[this.optionValueField];
		}

		if(this.field && this.field.nameFormatter){
			name = this.field.nameFormatter(name, option);
		}

		return name;
	}

	filterOptionGroup(group, options){
		let results = [];

		if(options){
			options.forEach((option) => {
				if(!this.optionGroupField || option[this.optionGroupField] == group.value){
					results.push(option);
				}
			});
		}

		return results;
	}

	/**
	 * Options are all shown if the field is locked since that means the user cannot make a selection anyway, we are just showing the model value
	 * Otherwise we check to make sure that the option is visible and unlocked
	 */
	fieldLockedOrOptionNotLocked(options){
		let results = [];
		
		if(options){
			options.forEach((option) => {
				if(!option){
					return;
				}

				if (typeof option.visible === 'function') {
					option.visible = option.visible();
				}

				if(option.visible && typeof option.visible == 'string'){
					option.visible = this.permissionService.hasPermission(option.visible);
				}

				if(option.locked && typeof option.locked == 'string'){
					option.locked = !this.permissionService.hasPermission(option.locked);
				}

				let optionIsSelected 	= option[this.optionValueField] == this.model[this.field.model];
				let optionIsvisible 	= typeof option.visible == 'undefined' || option.visible || this.includeInvisibleOptions;

				if (this.field.locked 
				|| ((optionIsSelected || !option.locked || option.disabled || this.includeLockedOptions) && optionIsvisible)) {
					results.push(option);
				}
			});
		}

		return results;
	}

	/**
	 * Clears the model of data
	 */
	clearModel($event){
		this.model[this.field.model] = null;
		$event.stopPropagation();
	}

	/**
	 * Returns true if the model currently has an assigned value
	 */
	hasValue(){
		return this.model 
		&& this.field 
		&& typeof this.model[this.field.model] 	!= 'undefined'
		&& this.model[this.field.model] 		!= null
		&& this.model[this.field.model] 		!== '';
	}

	/**
	 * Returns true if we should show the empty option
	 */
	showEmptyOption(){
		let options = this.filteredOptions.getValue();
		return this.addEmptyOption 
			&& options 
			&& options[0] 
			&& options[0][this.optionValueField];
	}
}