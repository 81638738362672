<section localize [model]="localeStrings" item="includeInactive">
	<span [hidden]="selectedField.model == 'inactive' || !allowToggleInactive">
		<mat-checkbox id="am_form_includeInactive"
			[(ngModel)]="includeInactive"
			(change)="onUpdate()">

			<span>{{localeStrings.includeInactive}}</span>
		</mat-checkbox>		
	</span>

	<lio-searchable-select [model]="selectedField"
		amID="am_search_selector"
		[searchable]="true"
		[field]="selectedField.field"
		optionValueField="model"
		(onUpdate)="onFieldChange()"
		[appearance]="appearance">
	</lio-searchable-select>

	<span *ngIf="field">
		<!-- Text -->
		<div *ngIf="field.type != 'select' && field.type != 'date' && field.type != 'boolean'">
			<mat-form-field [appearance]="appearance" class="pt-0 w-100" localize [model]="field" item="name">
				<mat-label id="am_form_label_{{field.model}}">
					{{field.name}}
				</mat-label>
				<input id="am_search_value" matInput [(ngModel)]="model.search" (change)="onUpdate()" (ngModelChange)="bufferedUpdate()"/>
			</mat-form-field>
		</div>

		<!-- Date -->
		<div *ngIf="field.type == 'date'">
			<lio-mat-wrap-date-field 
				amID="am_search_value"
				[field]="dateField"
				[model]="model"
				(onUpdate)="onUpdate()">
			</lio-mat-wrap-date-field>
		</div>

		<!-- Boolean -->
		<div *ngIf="field.type == 'boolean'" class="checkboxfield">
			<lio-searchable-select [model]="model"
				[field]="booleanField"
				(onUpdate)="onUpdate()"
				[appearance]="appearance">
			</lio-searchable-select>
		</div>

		<!-- Select Drop Down -->
		<div *ngIf="field.type == 'select'">
			<lio-searchable-select [model]="model"
				[field]="selectField"
				[searchable]="true"
				(onUpdate)="onUpdate()"
				[appearance]="appearance">
			</lio-searchable-select>
		</div>
	</span>

	<!-- Hierarchy -->
	<div [hidden]="hideHierarchy" *ngIf="permissionService.hasPermission('employeeSearch.controlHierarchy')" class="pt-2">
		<lio-searchable-select [model]="model"
			[field]="hierarchyField"
			(onUpdate)="onUpdate()"
			[appearance]="appearance">
		</lio-searchable-select>
	</div>

	<div class="container-fluid my-2" [hidden]="!loadingResults">
		<div class="row">
			<div class="mx-auto"><i class="fa fa-2x fa-spinner fa-spin loader"></i></div>
		</div>
	</div>
	<div id="am_searchusers_feedback_container">
		<div class="container-fluid my-2" *ngFor="let feedback of resultsFeedback" [hidden]="!resultsFeedback">
			<div class="row">
				<div class="mx-auto font-weight-bold">
					<div id="{{'am_searchusers_' + feedback.key}}" 
						[hidden]="!feedback.key" 
						localize [model]="feedback" item="value" 
						[macros]="feedback.macros">{{feedback.value}}</div>
					<div [hidden]="feedback.key" [innerHtml]="feedback | html"></div>
				</div>
			</div>
		</div>
	</div>
</section>