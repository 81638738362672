import { Component, Input, Inject, DoCheck, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { feedbackService } from '../../../../services/feedback.service';
import { utilService } from '../../../../services/util.service';

import { LioSearchableSelectField } from 'src/app/modules/lio-forms/lio-forms.models';

@Component({
	selector: 'lio-disclosure-question',
	templateUrl: './question.component.html',
})
export class LioDisclosureQuestion implements DoCheck{
	@Output() delete						:EventEmitter<any> 		= new EventEmitter();
	@Output() optionAdded					:EventEmitter<any> 		= new EventEmitter();
	@Output() notificationResponseAdded		:EventEmitter<any> 		= new EventEmitter();
	@Output() notificationResponseRemoved	:EventEmitter<any> 		= new EventEmitter();

	@Input() settings						:any 					= {};
	@Input() appearance						:MatFormFieldAppearance = 'outline';
	@Input() allQuestions					:Array<any> 			= [];

	private _question:any = {disabled: false, text: ''};
	public get question():any{
		return this._question;
	}
	@Input() public set question(val:any){		
		this._question = val;

		if(!val.text) {
			val.text = '';
		}

		if(!val.response) {
			val.response = {type:''};
		}

		if(val.disabledCondition) {
			this.addDisabledCondition();
		}
	}

	public disabledConditionQuestionField:LioSearchableSelectField = new LioSearchableSelectField({
		name				: 'Watch Question ID',
		model				: 'unlessquestionid',
		optionNameField		: 'questionid',
		optionValueField 	: 'questionid',
		optionTooltipField 	: 'text',
		optionGroupField 	: 'sectionIndex',
		options 			: []
	});
	public disabledConditionResponseField:LioSearchableSelectField = new LioSearchableSelectField({
		name				: 'Show When Response ID Equals',
		model				: 'unlessresponseid',
		options 			: [],
		nameFormatter		: (_name, option) => { return option.value + ' (' + option.text + ')'; }
	});
	public responseTypeField:LioSearchableSelectField = new LioSearchableSelectField({
		name				: 'Response Type',
		model				: 'type',
		options 			: [
			{
				name : 'None',
				value: ''
			},
			{
				name : 'Select from List',
				value: 'singleselect'
			},
			{
				name : 'Single Line Text',
				value: 'simpletext'
			},
			{
				name : 'Text Box',
				value: 'complextext'
			}
		]
	});

	constructor(
									private changeDetectorRef	:ChangeDetectorRef,
		@Inject(feedbackService) 	private feedbackService		:feedbackService,
		@Inject(utilService) 		private utilService			:utilService
	){}

	private _oldInputValue							:any 		= null;
	private _oldDisabledCondition					:any 		= null;
	private _oldDisabledConditionInput				:any 		= null;
	private _oldDisabledConditionResponseOptions	:Array<any> = [];
	private _oldAllQuestions						:Array<any> = [];
	ngDoCheck(){
		//watch for changes in all questions so that we can update disabled condition options
		if(!this.utilService.deepArrayEquals(this.allQuestions, this._oldAllQuestions)){
			this._oldAllQuestions = this.utilService.deepCopy(this.allQuestions);
			this.setDisabledConditionOptions();
		}
		
		if(this.question){ 
			//if response input value change, clear form errors
			if(this.question.response 
			&& this.question.response.input 
			&& this.question.response.input.value != this._oldInputValue){
				this._oldInputValue 	= this.question.response.input.value;
				this.question.invalid 	= false;
				this.feedbackService.clearErrors();
			}

			if(this.question.disabledCondition){ 
				//watch the unlessquestionid field for changes so that we can update the list of allowed responseIDs
				if(this.question.disabledCondition.unlessquestionid != this._oldDisabledCondition){
					this._oldDisabledCondition = this.question.disabledCondition.unlessquestionid;
					this.allQuestions.forEach((question) => {
						if(question.questionid == this.question.disabledCondition.unlessquestionid) {
							this.question.disabledCondition.targetQuestion = question;
						}
					});
				}

				//watch the target of our disable condition to see if the input changes, if it does, asses the disabled condition
				if(this.question.disabledCondition.targetQuestion 
				&& this.question.disabledCondition.targetQuestion.response){

					if(!this.utilService.itemEquals(this.question.disabledCondition.targetQuestion.response.input, this._oldDisabledConditionInput, false, true)){
						let newInput 					= this.utilService.deepCopy(this.question.disabledCondition.targetQuestion.response.input);
						this._oldDisabledConditionInput = newInput;
						this.checkDisabledCondition(newInput);
						this.changeDetectorRef.detectChanges();
					}

					if(this.question.disabledCondition.targetQuestion.response.options != this._oldDisabledConditionResponseOptions){
						this._oldDisabledConditionResponseOptions = this.question.disabledCondition.targetQuestion.response.options;
						this.setDisabledConditionResponseOptions();
					}
				}
			}
		}
	}

	/**
	 * Sets the allowed options for disabled conditions
	 * Organizes options into groups
	 */
	setDisabledConditionOptions(){
		let thisSectionQuestions 	:Array<any>	= [];
		let otherSectionQuestions	:Array<any> = [];
		let sectionGroups 			:Array<any>	= [];
		this.allQuestions.forEach((question) => {
			let hasUsableQuestions = false;
			
			if(this.isUsableAsConditionTarget(question)){
				hasUsableQuestions = true;
				if(question.sectionIndex == this.question.sectionIndex){
					thisSectionQuestions.push(question);
				}else{
					otherSectionQuestions.push(question);
				}
			}

			if(hasUsableQuestions){
				if(question.sectionIndex == this.question.sectionIndex){
					sectionGroups.unshift({
						name	:'Current Section',
						value	: this.question.sectionIndex
					});
				}else{
					sectionGroups.push({
						name 	: 'Section ' + question.sectionIndex, 
						value 	: question.sectionIndex
					});
				}
			}
		});

		this.disabledConditionQuestionField.optionGroups 	= sectionGroups;
		this.disabledConditionQuestionField.options 		= thisSectionQuestions.concat(otherSectionQuestions);
	}

	/**
	 * Sets the allowed options for disabled condition response values
	 */
	setDisabledConditionResponseOptions(){
		if(this.question.disabledCondition 
		&& this.question.disabledCondition.targetQuestion 
		&& this.question.disabledCondition.targetQuestion.response
		&& this.question.disabledCondition.targetQuestion.response.options){
			this.disabledConditionResponseField.options = this.question.disabledCondition.targetQuestion.response.options;
		}
	}

	/**
	 * Adds a condition to require in order for this question to appear
	 */
	addDisabledCondition() {
		if(!this.question.disabledCondition) {
			this.question.disabledCondition = {};
		}

		let disabledCondition 				= this.question.disabledCondition;
		disabledCondition.targetQuestion 	= this.getQuestion(disabledCondition.unlessquestionid);
	}

	getQuestion(id){
		let foundQuestion = null;
		this.allQuestions.forEach((question) => {
			if(question.questionid == id){
				foundQuestion = question;
			}
		});
		return foundQuestion;
	}

	/**
	 * Removes the disabled condition from this question
	 */
	removeDisabledCondition() {
		delete this.question.disabledCondition;
		this.question.disabled = false;
	}

	/**
	 * Checks the disabled condition for this question to see if it has been fulfilled
	 */
	checkDisabledCondition(newVal) {
		let disabledCondition = this.question.disabledCondition;
		if(typeof newVal != 'undefined' && newVal.value == disabledCondition.unlessresponseid) {
			this.question.disabled = false;
		} else {
			this.question.disabled = true;
			this.question.response.input = {};
		}
	}

	/**
	 * Returns whether or not this question can be used as the target for a disabled condition
	 * Conditions: a question cannot target itself, the target question must have a response type of 'singleselect'
	 */
	isUsableAsConditionTarget(question) {
		return question.questionid != this.question.questionid 
			&& question.response 
			&& question.response.type == 'singleselect';
	}
}