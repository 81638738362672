import { Pipe, PipeTransform } from '@angular/core';

/** 
 * Filter to match by template type if event model has type set, otherwise no filter
 */
@Pipe({
  name: 'filterByTemplateType'
})
export class FilterByTemplateType implements PipeTransform {
	transform(templates:Array<any>, model:any):boolean {
		if(templates && model){
			templates.forEach((template) => {
				return template.type == model.type;
			});
		}
		return false;
	}
}
