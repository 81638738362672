import { Component, Inject, Input, OnDestroy } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { permissionService } from '../../../../services/permissions.service';
import { fieldService } from '../../../../services/fields.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lmsService } from '../../../../services/lms.service';
import { localizationService } from '../../../../services/localization.service';
import { ccfService } from '../../../../services/ccf.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { stateService } from '../../../../services/state.service';

import { fieldsSettings } from '../../../../components/fields/fields.settings';
import { filterSettings } from '../../../../settings/filters.settings';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-filter-editor',
	templateUrl: './filter-editor.component.html',
})
export class LioFilterEditor implements OnDestroy {
	@Input() appearance			:MatFormFieldAppearance 		= 'outline';

	private subscriptions		:Subscription 	= NEVER.subscribe();

	private allPages			:Array<any>		= this.utilService.copy(this.filterSettings.pages);
	
	private dataModel			:any			= this.utilService.copy(this.filterSettings.dataModel);
	private allPageIDs			:Array<any>		= [];
	private roleName			:string			= 'GLOBAL';
	private localizedModelName	:string			= this.localizationService.get('fields.modelName');

	public copiedOptions		:any			= null;
	public employees			:Array<any>		= [];
	public copiedPage			:any			= null;
	public filteredEmployees	:Array<any>		= [];
	public filteredFields		:Array<any>		= [];
	public employeeFields		:Array<any>		= this.utilService.copy(this.fieldsSettings.employeeFields);
	public employeeSettings		:any 			= this.utilService.copy(this.fieldsSettings.employeeSettings);
	public page					:any			= null;

	public pageSelectField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Select a Page to Modify',
		nameTrans 			: '',
		model 				: 'targetPage',
		options 			: [],
	});
	public targetPageModel:any = {
		targetPage : ''
	};
	public roleSelectField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Select a Role',
		nameTrans 			: '',
		model 				: 'targetRole',
		options 			: [],
		optionValueField 	: 'roleID',
	});
	public targetRoleModel:any = {
		targetRole : ''
	};
	public typeField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Type',
		nameTrans 			: '',
		model 				: 'type',
		options 			: this.utilService.copy(this.filterSettings.fieldTypes)
	});
	public preExistingOptionsField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Pre-Existing Options',
		nameTrans 			: '',
		model 				: 'segregation',
		options 			: this.utilService.copy(this.filterSettings.segregationOptions)
	});
	public selectedOptionsField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Selected Options',
		nameTrans 			: '',
		model 				: 'selectedSelectedOptions',
		options 			: [],
		multiple 			: true
	});
	public availableOptionsField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Available Options',
		nameTrans 			: '',
		model 				: 'selectedAvailableOptions',
		options 			: [],
		multiple 			: true
	});

	public localeStrings:any = {
		fieldAddOption 			: 'Add Option',
		fieldAddOptionTrans 	: 'field.addOption',
		fieldsAddOption 		: 'Add Option',
		fieldsAddOptionTrans 	: 'fields.addOption'
	};
	
	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(fieldService) 			public fieldService 		:fieldService,
		@Inject(lioModalService) 		public lioModalService 		:lioModalService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(localizationService)	public localizationService 	:localizationService,
		@Inject(ccfService) 			public ccfService 			:ccfService,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(stateService) 			public stateService 		:stateService,
		@Inject(fieldsSettings) 		public fieldsSettings 		:fieldsSettings,
		@Inject(filterSettings) 		public filterSettings 		:filterSettings,
	){
		this.debugService.register('filtereditor', this);
		
		let options:Array<any> = [];
		this.allPages.forEach((page) => {
			let option = {
				name	: page.name,
				value	: page.id,
			};
			options.push(option);
		});

		options.sort((a,b) => {
			return a.name - b.name;
		});

		this.pageSelectField.options = options;

		this.setRoles();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
	
	getAvailableOptionsField(field){
		let fieldSettings 		= this.utilService.copy(this.availableOptionsField);
		fieldSettings.options 	= field.availableOptions;
		return fieldSettings;
	}
	
	getSelectedOptionsField(field){
		let fieldSettings 		= this.utilService.copy(this.selectedOptionsField);
		fieldSettings.options 	= field.selectedOptions;
		return fieldSettings;
	}

	/**
	 * Sets the roles
	 */
	setRoles() {
		this.roleSelectField.options 	= [{'name': 'GLOBAL', 'roleID': ''}];
		let roleAuthorities 			= this.permissionService.getRoleAuthorities('EDIT');
		this.roleSelectField.options 	= this.roleSelectField.options.concat(roleAuthorities);

		this.roleSelectField.options.sort((a,b) => {
			return a.roleID - b.roleID;
		});
	}

	/**
	 * Updates the selects
	 */
	updateSelects(fields:Array<any>) {
		fields.forEach((field) => {
			this.setDropDown(field);
			
			if (field.dropDown) {
				field = this.handleOptions(field);
			}
		});
	}

	/**
	 * Updates the filtered field
	 */
	updateFilteredFields(collection) {
		this.filteredFields = collection.filtered;
	}

	/**
	 * On updates
	 */
	onupdate() {
		this.updateSelects(this.page.fields);
	}

	/**
	 * Recieve the search results
	 */
	recieveResults(employees){
		this.employees = employees;
	}

	/**
	 * Sets whether the field uses drop down options or not
	 */
	setDropDown(field) {
		field.dropDown = false;
		
		if (field.disableOptionOverride) {
			return;
		}
		if (field.type === 'select') {
			field.dropDown = true;
		}
	}

	/**
	 * On update type
	 */
	onUpdateType(field) {
		this.setDropDown(field);

		if (field.dropDown) {
			this.lmsService.post('fields/getOptions', {'page': this.page.id, 'field': field.model}).then((result) => {
				if (result) {
					field.options = result.properties.options;
					field.initializedOptions = false;
				}
				this.updateSelects(this.page.fields);
			});
		}
	}

	/**
	 * Loads pre-existing options
	 */
	loadExisting(field) {
		this.lmsService.post('fieldoptions/loadExisting', {'model': field}).then((result) => {
			if (result) {
				field.options = result.properties.options;
				field.initializedOptions = false;
			}
			this.updateSelects(this.page.fields);
		});
	}

	/**
	 * Validates the fields chosen
	 * @return {boolean}
	 */
	validate() {
		let valid 			= true,
			originalFields	= this.page.originalFields,
			hasDifferences 	= false;

		this.feedbackService.clearErrors();
		this.filteredFields.forEach((field) => {
			hasDifferences = false;
			if (!valid) {
				return;
			}

			if (field.disableOverride) {
				return;
			}

			this.setDropDown(field);

			originalFields.forEach((originalField) => {
				if (field.model === originalField.field) {
					if (this.hasDifferences(field, originalField)) {
						hasDifferences = true;
						this.lioLogService.log([field, originalField]);
					}
				}
			});

			if (field.dropDown && hasDifferences) {
				if (!field.initializedOptions && !field.loadOptions && !field.customOptions) {
					this.feedbackService.addError('Please select the drop down options for ' + field.model);
					valid = false;
				}

				if (field.customOptions && !field.selectedOptions.length) {
					this.feedbackService.addError('Please select at least one drop down option for ' + field.model);
					valid = false;
				}
			} else {
				// Deletes in event this was changed
				delete field['loadOptions'];
				delete field['customOptions'];
				delete field['options'];
			}

		});

		return valid;
	}

	/**
	 * Gets the fields model to save
	 * @return {?array}
	 */
	getFieldsToSave() {
		let fields 			= this.filteredFields,
			originalFields	= this.page.originalFields,
			models		 	= [],
			model			= {};

		fields.forEach((field) => {
			originalFields.forEach((originalField) => {
				if (field.model === originalField.field) {
					if (this.hasDifferences(field, originalField)) {
						model = this.getDifferences(field);
						model['model'] = field.model;
						models.push(model);
					}
				}
			});
		});

		return models;
	}

	/**
	 * Gets the differences between two fields
	 * @param {object} newField
	 * @param {object} originalField
	 * @return {object}
	 */
	getDifferences(newField) {
		let differences = {},
			models 		= this.dataModel;

		// Gets model differences
		models.forEach((model) => {
			if (model.type == 'boolean') {
				if (this.utilService.isString(newField[model.name])) {
					this.lioLogService.log(['NON BOOLEAN DETECTED', newField]);
					return;
				}
			}
			differences[model.name] = newField[model.name]; 
		});

		// Gets options
		if (newField.customOptions && newField.selectedOptions) {
			differences['options'] = [];
			let savedOptionNames = [];
			newField.selectedOptions.forEach((option) => {
				savedOptionNames.push(option.name);
				option.selected = true;
				differences['options'].push(option);
			});

			newField.options.forEach((option) => {
				if (savedOptionNames.indexOf(option.name) === -1) {
					option.selected = false;
					differences['options'].push(option);
				}
			});

			if (!differences['options'].length) {
				delete differences['options'];
			}
		}

		return differences;
	}

	/**
	 * Do the two fields have differences
	 * @param {object} newField
	 * @param {object} originalField
	 * @return {boolean}
	 */
	hasDifferences(newField, originalField) {
		let differences = false,
			models 		= this.dataModel;


		// Gets model differences
		models.forEach((model) => {
			if (typeof newField[model.name] == 'undefined') {
				return;
			}
			if (newField[model.name] !== originalField[model.name]) {
				differences = true; 
			}
		});

		// Are there options
		if (newField.options && newField.customOptions) {
			differences = true;
		}

		return differences;
	}

	/**
	 * Saves Modifications for the page
	 * @return {Promise}
	 */
	modifyPage() {
		if (!this.validate()) {
			return;
		}

		let fields = this.getFieldsToSave();

		if (!fields.length) {
			this.lioModalService.show('Nothing to save');
			return;
		}

		this.feedbackService.clearErrors();
		this.lioLogService.log(['Saving ' + this.page.id, fields]);
		return this.lmsService.postAsync('fields/saveByPage', {'fields': fields, 'pageName': this.page.id, 'roleID': this.targetRoleModel.targetRole, 'pageIDs': this.allPageIDs},
		'processing').then((result) => {
			let token = result.properties.token;
			
			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleModifyPageResults(result);
				});
			} else {
				this.handleModifyPageResults(result);
			}
		});
	}

	/**
	 * Deletes fields for the page
	 */
	deletePage() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/deleteByPage', {'pageName': this.page.id, 'roleID': this.targetRoleModel.targetRole}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Deletes user fields modifications for the current page
	 */
	resetPageForAllUsers() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/resetPageForallUsers', {'pageName': this.page.id}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Reset a specific field
	 */
	resetField(field) {
		if (!field.overridden) {
			return;
		}

		if (field.permissionID && (field.permissionID != this.targetRoleModel.targetRole)) {
			this.lioModalService.show('fieldModifiedGlobally');
			return;
		}

		if (this.targetRoleModel.targetRole && (field.permissionID != this.targetRoleModel.targetRole)) {
			this.lioModalService.show('fieldModifiedGlobally');
			return;
		}	

		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/resetField', {'pageName': this.page.id, 'roleID': this.targetRoleModel.targetRole, 'field': field.model}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Handles results of modifying a role
	 */
	handleModifyPageResults(result) {
		if (result.success) {
			this.getPage();
			this.lioModalService.show('modelSaveSuccess', null, {key : 'modelName', value : this.localizedModelName});
		} else {
			this.lioModalService.show('modelSaveFail', null, {key : 'modelName', value : this.localizedModelName});
		}
	}

	/**
	 * Adds options
	 * @param {object} field
	 */
	addOptions(field) {
		if (!field.newOption) {
			return;
		}
		let newOptions = [];

		if (!field.newOption.bulk) {
			newOptions = [field.newOption];
		} else {

			// Explode the text area into key value pairs by line
			let lines = field.newOption.bulk.split('\n');
			lines.forEach((line) => {
				if (!line) {
					return;
				}
				let pair = line.split(':');
				if (pair.length === 2) {
					newOptions.push({'name': pair[0], 'value': pair[1]});
				} else {
					newOptions.push({'name': pair[0], 'value': pair[0]});

				}
			});
		}
		
		newOptions.forEach((newOption) => {
			this.addOption(field, newOption);
		});

		field.newOption = {};
	}

	/**
	 * Adds an option to the field
	 * @param {object} field
	 */
	addOption(field, newOption) {
		if (!newOption.name) {
			this.lioModalService.show('Please add a name for the option');
			return;
		}
		let existing = null,
			option = {};

		// Set the value to the name if not provided
		if (!newOption.value) {
			newOption.value = newOption.name;
		}

		// Trim
		newOption.name = newOption.name.trim();
		newOption.value = newOption.value.trim();

		// Check if the option already exists
		field.options.forEach((option) => {
			if (option.value === newOption.value || option.name === newOption.name) {
				existing = newOption.name;
			}
		});

		if (existing) {
			this.lioLogService.log(existing + ' already exists');
			return;
		}

		// Store the options in the respective arrays
		option = {
			'name': newOption.name,
			'value': newOption.value,
			'new': true,
			'selected': true,
		};
		if (!field.options) {
			field.options = [];
		}
		field.options.push(option);
		field.selectedOptions.push(option);
	}

	/**
	 * Handles fields once they are loaded
	 */
	handleFields(fields:Array<any>) {
		fields.forEach((field) => {
			for (let i in field) {
				if (field[i] === 1) {
					field[i] = true;
				}
			}

			if (field.tooManyResults) {
				this.feedbackService.addError('Too many results were found in ' + field.model + ' so the options were not loaded');
			}

			if (field.options && field.options.length) {
				field = this.handleOptions(field);
			}

			// Convert the field types to match
			if (field.type === 'text') {
				field.type = 'string';
			}

			if (field.type === 'integer') {
				field.type = 'number';
				field.disableOptionOverride = true;
			}
		});
		this.updateSelects(fields);	
		return fields;
	}

	/**
	 * Adds selected options
	 */
	addSelectedOptions(field) {
		if (!field.selectedAvailableOptions) {
			return;
		}

		field.selectedAvailableOptions.forEach((selectedOption) => {
			let option = this.findOption(field, selectedOption);
			if (option) {
				field.selectedOptions.push(option);
				let index = field.availableOptions.indexOf(option);
				field.availableOptions.splice(index, 1);

			}
		});

		field.selectedAvailableOptions = [];
	}

	/**
	 * Removes selected options
	 */
	removeSelectedOptions(field) {
		if (!field.selectedSelectedOptions) {
			return;
		}

		field.selectedSelectedOptions.forEach((selectedOption) => {
			let option = this.findOption(field, selectedOption);
			if (option) {
				field.availableOptions.push(option);
				let index = field.selectedOptions.indexOf(option);
				field.selectedOptions.splice(index, 1);
			}
		});
		field.selectedSelectedOptions = [];
	}

	/**
	 * Finds an option by name
	 */
	findOption(field, name) {
		let matchedOption = null;
		field.options.forEach((option) => {
			if (option.name === name || option.value === name) {
				matchedOption = option;
			}
		});
		return matchedOption;
	}

	/**
	 * Handles options
	 */
	handleOptions(field) {
		if (field.initializedOptions) {
			return field;
		}
		field.initializedOptions = true;
		field.selectedOptions = [];
		field.availableOptions = [];
		field.selectedAvailableOptions = [];
		field.selectedSelectedOptions = [];

		if(!field.options){
			field.options = [];
		}

		field.options.forEach((option) => {
			if (option.selected == 1) {
				field.selectedOptions.push(option);
			} else {
				field.availableOptions.push(option);
			}
		});
		return field;
	}

	/**
	 * Gets the page and fields
	 */
	getPage() {
		this.fieldService.clearCache();

		this.allPageIDs = [];
		let newPage 	= null;
		this.allPages.forEach((page) => {
			if (page.id === this.targetPageModel.targetPage) {
				newPage = this.utilService.copy(page);
			}
			this.allPageIDs.push(page.id);
		});

		newPage = this.setFields(newPage);

		this.lioLogService.log(['Filters', newPage.fields]);

		let config = {
			'fieldName'		: this.targetPageModel.targetPage,
			'roleID'		:  this.targetRoleModel.targetRole,
			'overrideFields': true,
		};

		return this.fieldService.setFields(newPage.fields, config).then((fields:Array<any>) => {
			newPage.fields 			= this.handleFields(fields);
			newPage.originalFields 	= this.utilService.copy(newPage.fields);
			this.page 				= newPage;
		});
	}

	/*
	* Removes specific default filters based on the page's filter settings
	* @param {array} filters
	* @return {array}
	*/
	removeDefault(page, filters) {
		page.fields.forEach((filter) => {
			if (filter.removeDefault) {
				filters.forEach((defaultFilter) => {
					if (defaultFilter.field == filter.removeDefault) {
						let index = filters.indexOf(defaultFilter);
						this.lioLogService.log(['removing', defaultFilter]);
						filters.splice(index, 1);
					}
				});
			}
		});
		return filters;
	}

	/**
	 * Sets the fields
	 */
	setFields(page) {
		// For simplicity, we will call fields filters 
		page.fields = page.filters;
		
		if (page.baseFilters) {
			page.baseFilters 	= this.removeDefault(page, page.baseFilters);
			page.fields 		= page.baseFilters.concat(page.fields);
		}

		// Add CCF
		if (page.addCCF) {
			page.fields = this.ccfService.addCCFtoFilters(page.fields);
		}

		// Add lang filter
		let langFilter = this.localizationService.getLangFilter();
		langFilter.visible = page.queryToolSettings.addLangFilter;
		page.fields.push(langFilter);

		// Set the fields model
		page.fields.forEach((field) => {
			field.model = field.field;
		});

		return page;
	}

	/**
	 * Gets the role's fields
	 */
	getRoleFields() {
		this.roleSelectField.options.forEach((role) => {
			if (role.roleID === this.targetRoleModel.targetRole) {
				this.roleName = role.name;
			}
		});
		this.resetPage();
	}

	/**
	 * Copy options
	 */
	copyOptions(field) {
		if (field.options) {
			this.copiedOptions = this.utilService.copy(field.options);
		}
	}

	/**
	 * Paste options
	 */
	pasteOptions(field) {
		if (!field.options) {
			field.options = [];
		}
		field.options = field.options.concat(this.copiedOptions);
		field.initializedOptions = false;
		field = this.handleOptions(field);
	}

	/**
	 * Copy a page
	 */
	copyPage() {
		this.copiedPage 			= this.utilService.copy(this.page);
		this.copiedPage.roleName 	= this.roleName;
	}

	/**
	 * Pastes a page (just the fields)
	 */
	pastePage() {
		let copiedFields = this.utilService.copy(this.copiedPage.fields),
			originalFields = [],
			overridden = false,
			differencesFound = false;

			copiedFields.forEach((copiedField) => {
				this.page.fields.forEach((field, key) => {
				if (copiedField.model === field.model) {
					if (field.disableOverride) {
						return;
					}
					if (this.hasDifferences(copiedField, field)) {
						originalFields = this.utilService.copy(field.originalFields);
						overridden = field.overridden;
						copiedField.overridden = overridden;
						copiedField.originalFields = originalFields;
						this.page.fields[key] = copiedField;
						differencesFound = true;
					}
				}
			});
		});

		if (!differencesFound) {
			this.lioModalService.show('No Differences Found to Copy');
		}
		this.onupdate();
	}
	
	/**
	 * Reset and wait
	 */
	resetAndWait() {
		this.lioModalService.showLoading('processing');
		setTimeout(() => {
			this.resetPage().then(() => {
				this.lioModalService.hideLoading();
			});
		}, 500);
	}

	/**
	 * Reset the page
	 */
	resetPage() {
		this.copiedPage = null;
		this.copiedOptions = null;
		return this.getPage();
	}
}