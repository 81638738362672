import { Directive, Inject, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { localizationService } from './../../services/localization.service';
import { utilService } from './../../services/util.service';

class TransMacro{
	public item	:string;
	public key	:string;
	public value:string|(()=>string);
}

@Directive({
	selector: '[localize]',
})
export class Localize implements OnInit, OnChanges, OnDestroy {
	//Model whose properties we will translate
	@Input() model		:any 				= null;
	//field on the item we are translating, only used if we are only translating one field
	@Input() item		:string 			= '';
	//translation tag for the field we are translating, only used if we are only translating one field
	@Input() itemTag	:string 			= '';
	//Array of fields we are translating on the model
	@Input() items		:Array<string>		= [];
	//Array of Trans Macros that specify a key and value to add dynamic data to a translation
	//Supports passing a function to determine the value
	@Input() macros		:Array<TransMacro>	= [];
	
	private subscriptions:Subscription		= NEVER.subscribe();

	constructor(
		@Inject(localizationService)	private localizationService :localizationService,
		@Inject(utilService)			private utilService 		:utilService
	){
		this.subscriptions.add(
			this.localizationService.localized.subscribe(() => {
				this.localize();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private _oldModel				:any		= null;
	private _oldItem				:string		= '';
	private _oldItemTag				:string		= '';
	private _oldItems				:Array<any>	= [];
	private _oldMacros				:Array<any>	= [];
	private _oldMacrosReturnValues	:any		= {};
	ngDoCheck(){
		if(!this.utilService.shallowObjectEquals(this.model, this._oldModel)){
			this._oldModel = this.utilService.shallowCopy(this.model);
			this.localize();
		}

		if(this.item != this._oldItem){
			this._oldItem = this.item;
			this.localize();
		}

		if(this.itemTag != this._oldItemTag){
			this._oldItemTag = this.itemTag;
			this.localize();
		}

		if(!this.utilService.shallowArrayEquals(this.items, this._oldItems)){
			this._oldItems	= this.items.slice();
			this.localize();
		}

		if(!this.utilService.shallowArrayEquals(this.macros, this._oldMacros)){
			this._oldMacros	= this.macros.slice();
			this.localize();
		}else if(this.macros && Array.isArray(this.macros)){
			//detects changes in macro functions, only supported if macros were passed in through the macro input
			this.macros.forEach((macro, index) => {
				if(typeof macro.value == 'function'){
					let returnValue = macro.value();
					if(returnValue !== this._oldMacrosReturnValues[index]){
						this._oldMacrosReturnValues[index] = returnValue;
						//if the item was specified, only re-translate the single item, otherwise we have to retranslate everything
						if(macro.item){
							this.trans(macro.item);
						}else{
							this.localize();
						}
					}
				}
			});
		}
	}

	ngOnInit() {
		this.localize();
	}

	ngOnChanges() {
		this.localize();
	}

	/*
	 * Initialize the localizations
	*/
	localize() {
		if (this.model && this.item) {
			this.trans(this.item);
		}

		if (this.model && this.items && Array.isArray(this.items)) {
			this.items.forEach((item) => {
				this.trans(item);
			});
		}
	}


	/*
	 * Translate an item
	 * @param {string} item
	*/
	trans(item) {
		let trans 		= this.itemTag || this.model[item + 'Trans'] || this.model[item];
		let macros 		= this.macros.filter((macro) => { return macro.item == item || typeof macro.item == 'undefined'; });
		let translation = '';

		if(!macros.length){
			macros = this.model[item + 'Macros'] || this.model[item + 'TransMacros'] || this.model['macros'];
		}

		translation = this.findTrans(trans, macros);

		let modelKeysToTry:Array<any> = [
			'trans',
			'localize',
			'key',
			'label',
			'model',
			'field'
		];

		modelKeysToTry.forEach((key) => {
			if (!translation) {
				trans 		= this.model[key];
				translation = this.findTrans(trans, macros);
			}
		});

		if (translation) {
			this.model[item] = translation;
		}
	}

	findTrans(text, macros){
		if (!text || !text[0]) {
			return;
		}

		text = text[0].toLowerCase() + text.substring(1);

		let translation = this.localizationService.get(text, '', macros);

		if (!translation) {
			translation = this.localizationService.get('modal.' + text, '', macros);
		}

		if (!translation) {
			translation = this.localizationService.get('message.' + text, '', macros);
		}

		if (!translation) {
			translation = this.localizationService.get('form.' + text, '', macros);
		}

		return translation;
	}
}
