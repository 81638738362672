<lio-panel-tabber *ngIf="catalogs.length">
	<lio-panel-tab [settings]="panelConfig.training"></lio-panel-tab>
	<lio-panel-tab [settings]="panelConfig.selfEnroll">
		<ng-container *lioPanelTabContent>
			<lio-catalog-search-bar 
				[filters]="filters"
				[view]="view"
				[showFilter]="showFilter"
				[localeStrings]="searchBarLocale"
				[clearBtnActive]="clearBtnActive">
			</lio-catalog-search-bar>

			<lio-catalog-results-header
				[catalogs]="catalogs"
				[collection]="collection"
				[loadingCatalogs]="loadingCatalogs"
				[view]="view">
			</lio-catalog-results-header>

			<lio-catalog-results-sub-header
				[canExpandCatalogs]="canExpandCatalogs"
			[catalogs]="catalogs"
				[pagination]="pagination"
				[appearance]="appearance"
				[view]="view"
				(expandAllCatalogs)="expandAllCatalogs()"
				(collapseAllCatalogs)="collapseAllCatalogs()">
			</lio-catalog-results-sub-header>

			<lio-catalogs 
				[catalogs]="catalogs" 
				[filters]="filters"  
				[pagination]="pagination" 
				[view]="view"
				[filters]="filters"
				(clickedCatalog)="clickedCatalog($event)"
				(catalogsFiltered)="updateFilteredCatalogs($event)"
				(selfEnroll)="selfEnroll($event)">
			</lio-catalogs>
		</ng-container>
	</lio-panel-tab>
</lio-panel-tabber>