import { Component, OnDestroy, Inject, DoCheck, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { NEVER, Subscription } from 'rxjs';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import moment from 'moment';
import * as Papa from 'papaparse';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { lmsService } from '../../services/lms.service';
import { permissionService } from '../../services/permissions.service';
import { lioModalService } from '../../services/lio-modal.service';
import { fieldService } from '../../services/fields.service';
import { lioLogService } from '../../services/lio-log.service';
import { stateService } from '../../services/state.service';
import { utilService } from '../../services/util.service';

import { configSettings } from '../../settings/config.settings';
import { disclosureManagerSettings } from './disclosure-manager.settings';

@Component({
	selector: 'lio-disclosure-manager',
	templateUrl: './disclosure-manager.component.html'
})
export class DisclosureManagerComponent implements OnDestroy, DoCheck {
	public disclosureTabAppearance	:MatFormFieldAppearance = 'fill';
	public notificationTabappearance:MatFormFieldAppearance = 'outline';

	private _flowBroseRef:ElementRef = null;
	public get flowBrowseRef():ElementRef{
		return this._flowBroseRef;
	}
	@ViewChild('flowBrowse', {read: ElementRef})
	public set flowBrowseRef(val:ElementRef){
		this._flowBroseRef = val;
		this.changeDetectorRef.detectChanges();
	} 

	public panelConfig					:any 			= this.disclosureManagerSettings.panelConfig;
	public operators					:Array<any>		= this.disclosureManagerSettings.operators;
	public fields						:Array<any>		= this.disclosureManagerSettings.fields;
	public filterByCourseField			:any			= this.disclosureManagerSettings.filterByCourseField;
	public disclosureField				:any			= this.disclosureManagerSettings.disclosureField;
	public notificationFieldField		:any			= this.disclosureManagerSettings.notificationFieldField;
	public notificationOperatorField	:any			= this.disclosureManagerSettings.notificationOperatorField;
	public notificationValueField		:any			= this.disclosureManagerSettings.notificationValueField;
	public model						:any			= {};
	public filterBy						:any 			= {
		status		: 'editable', 
		courseID 	: null
	};
	public mode							:string			= 'menu';
	public notificationEmails			:any 			= {
		current : [],
		remove 	: []
	};
	public csvUploadSettings			:any 			= {
		name				: 'Import Text',
		trans				: 'disclosure.importText',
		fileTask			: 'importcourse/upload',
		allowedFileTypes	: ['csv'],
		runTaskOnFileAdd	: false,
		hideButton 			: true
	};
	public exportedData					:string 		= '';

	private fieldConfig					:any 			= this.disclosureManagerSettings.fieldConfig;
	private disclosures					:Array<any>		= [];
	private defaultSettings				:any 			= this.disclosureManagerSettings.defaultSettings;
	private subscriptions				:Subscription	= NEVER.subscribe();

	constructor(
											private changeDetectorRef			:ChangeDetectorRef,
		@Inject(DOCUMENT) 					private document					:Document,
		@Inject(navService)					public	navService					:navService,
		@Inject(debugService)				public	debugService				:debugService,
		@Inject(lmsService)					public	lmsService					:lmsService,
		@Inject(permissionService)			public	permissionService			:permissionService,
		@Inject(lioModalService)			public	lioModalService				:lioModalService,
		@Inject(fieldService)				public	fieldService				:fieldService,
		@Inject(lioLogService)				public	lioLogService				:lioLogService,
		@Inject(configSettings)				public	configSettings				:configSettings,
		@Inject(stateService)				public	stateService				:stateService,
		@Inject(utilService)				public	utilService					:utilService,
		@Inject(disclosureManagerSettings)	public	disclosureManagerSettings	:disclosureManagerSettings
	){
		this.debugService.register('disclosure', this);
		this.navService.setActivePage('disclosure');

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private _oldDisclosures:Array<any> 	= [];
	private _oldFilterByStatus:string 	= '';
	private _oldFilterByCourseID:any 	= null;
	ngDoCheck(){
		if(this.disclosures != this._oldDisclosures){
			this._oldDisclosures = this.disclosures;
			this.filterDisclosures();
		}
		if(this.filterBy){
			if(this.filterBy.status != this._oldFilterByStatus){
				this._oldFilterByStatus = this.filterBy.status;
				this.filterBy.courseID = null;
				this.filterDisclosures();
			}
			if(this.filterBy.courseID != this._oldFilterByCourseID){
				this._oldFilterByCourseID = this.filterBy.courseID;
				this.filterDisclosures();
			}
		}
	}

	/*
	* Init
	*/
	init() {
		this.clear();
		this.fieldService.setFields(this.fields, this.fieldConfig).then((fields:any) => {
			this.fields = fields;
			
			Promise.all([
				this.getAll(), 
				this.getNotificationEmails()
			]).then(() =>{
				this.navService.displayPage();
			});
		});
	}

	getNotificationEmails(){
		return this.lmsService.post('disclosure/getNotificationEmails').then((result) => {
			this.notificationEmails.current 	= [];
			this.notificationEmails.remove 	= [];

			result.properties.notificationEmails.forEach((entry) => {
				this.fields.forEach((field) => {
					if(field.model == entry.field){
						entry.field = field;
						this.notificationEmails.current.push(entry);
					}
				});
			});
		});	
	}

	setNotificationEmails(){
		this.lioModalService.showLoading('processing');

		let notificationEmails = {
			current : [],
			remove 	: this.notificationEmails.remove
		};
		this.notificationEmails.current.forEach((entry) => {
			let temp = this.utilService.copy(entry);

			if(entry.field.model){
				temp.field = entry.field.model;
			}

			if(entry.field.type == 'date'){
				temp.condition = moment(temp.condition).format('YYYY-MM-DD hh:mm:ss');
			}

			notificationEmails.current.push(temp);
		});

		return this.lmsService.post('disclosure/setNotificationEmails', {notificationEmails : notificationEmails}).then((result) => {
			this.lioModalService.hideLoading();

			if (result.success) {
				this.lioModalService.show('message.savedSuccessfully');
				this.getNotificationEmails();
			} else {
				this.lioModalService.show('error.failedToSave');
			}
		});	
	}

	addNotificationEmail(){
		this.notificationEmails.current.push({
			field 		: '',
			operator 	: '=',
			condition 	: '',
			checkOrder	: this.notificationEmails.current.length,
			email 		: '',
			cc 			: ''
		});
	}

	removeNotificationEmail(index){
		let id = this.notificationEmails.current[index].id;
		if(typeof id != 'undefined'){
			this.notificationEmails.remove.push(id);
		}

		this.notificationEmails.current.splice(index,1);
	}

	/**
	 * Returns whether the current disclosure can be Saved
	 */
	canSave() {
		return this.model.data.title 
		&& !this.model.data.active 
		&& !this.model.data.archived 
		&& !this.model.data.disableReactivation;
	}

	/**
	 * Returns whether the current disclosure can be Activated
	 */
	canActivate() {
		return this.model.data.id 
		&& !this.model.data.active 
		&& !this.model.data.archived 
		&& !this.model.data.disableReactivation;
	}

	/**
	 * Returns whether the current disclosure can be DeActivated
	 */
	canDeActivate() {
		return this.model.data.active 
		&& !this.model.data.disableReactivation
		&& this.permissionService.hasPermission('disclosure.deactivate');
	}

	/**
	 * Returns whether the current disclosure can be Archived
	 */
	canArchive() {
		return this.model.data.id 
		&& !this.model.data.active 
		&& !this.model.data.archived 
		&& !this.model.data.disableReactivation;
	}

	/**
	 * Returns whether the current disclosure can be Restored
	 */
	canRestore() {
		return this.model.data.archived;
	}

	/**
	 * Returns whether the current disclosure can be switched between edit and review mode
	 */
	canSwitchMode() {
		return !this.model.data.active 
		&& !this.model.data.disableReactivation;
	}

	/**
	 * Returns whether the current disclosure can be edited
	 */
	isEditable() {
		return this.model.data.id
		&& !this.model.data.disableReactivation;
	}

	/**
	 * Switches model selector to view the specified status
	 */
	switchFilter() {
		this.model.data = this.utilService.copy(this.defaultSettings);
	}

	/**
	 * Filters disclosures based on their status
	 */
	filterDisclosures() {
		let results = [];
		this.disclosures.forEach((disclosure) => {
			let allow = false;

			//set initial filter allow setting based on status
			allow = this.disclosureMatchesFilter(disclosure);

			//filter out based on courseID
			if(this.filterBy.courseID && disclosure.courseID != this.filterBy.courseID){
				allow = false;
			}

			if(allow){
				results.push(disclosure);
			}
		});
		
		this.disclosureField.options = results;

		this.setCourseOptions();
	}

	/**
	 * Returns true if a disclosure matches the current status filter
	 */
	disclosureMatchesFilter(disclosure:any):boolean{
		switch (this.filterBy.status) {
			case 'editable':
				return !disclosure.active && !disclosure.archived;
			case 'active':
				return disclosure.active;
			case 'archived':
				return disclosure.archived;
			case 'all':
				return true;
		}
	}

	/**
	 * Unloads the currently loaded disclosure
	 */
	clear() {
		this.model.data 	= this.utilService.copy(this.defaultSettings);
		this.mode 			= 'menu';
		this.exportedData 	= '';
	}

	/**
	 * Saves the currently loaded disclosure
	 * Note: cannot be used on currently active or archived disclosures
	 */
	save() {
		this.lioModalService.confirm('disclosure.confirmSave').then((confirmed) => {
			if (confirmed) {
				this.saveModel();
			}
		});
	}

	/**
	 * Saves the model
	 */
	saveModel() {
		this.lioModalService.showLoading('processing');

		let model = this.utilService.copy(this.model.data);
		//mode is a transient setting, it doesn't need to be saved
		delete model.settings.mode;
		//purge any response values that may have been entered when the user tested the disclosure
		model.sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (!question.response) {
					question.response = {type : ''};
				}

				question.response.input = {};
			});
		});

		model.sections = JSON.stringify(model.sections);
		model.settings = JSON.stringify(model.settings);

		return this.lmsService.post('disclosure/save', {disclosure : model}).then((result) => {
			this.lioModalService.hideLoading();

			if (result.success) {
				this.model.data.id = result.properties.id;
				this.loadDisclosure().then(() => {
					this.getAll();
					this.lioModalService.show('disclosure.saveSuccess');
				});
			} else {
				this.lioModalService.show('disclosure.saveFail');
			}
		});	
	}

	/**
	 * Creates a clone of the currently loaded disclosure
	 */
	clone() {
		this.lioModalService.confirm('disclosure.confirmClone').then((confirmed) => {
			if (confirmed) {
				if (!this.model.data.id) {
					return;
				}
				delete this.model.data.id;
				this.model.data.title 			= this.model.data.title + '(copy)';
				this.model.data.certificationID	= null;
				this.model.data.active 			= 0;
				this.model.data.archived		= 0;
				this.saveModel();
			}
		});
	}

	/**
	 * Deletes the currently loaded disclosure
	 * Note: cannot be used on currently active or archived disclosures
	 */
	delete() {
		if (!this.model.data.id) {
			return;
		}
		this.lioModalService.confirm('disclosure.confirmDelete').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('processing');
				return this.lmsService.post('disclosure/delete', {id: this.model.data.id}).then((result) => {
					this.lioModalService.hideLoading();

					if (result.success) {
						this.clear();
						this.getAll();
						this.lioModalService.show('disclosure.deleteSuccess');
					} else {
						this.lioModalService.show('disclosure.deleteFail');
					}
				});	
			}
		});
	}

	/**
	 * Gets all disclosures at the current company
	 */
	getAll() {
		return this.lmsService.post('disclosure/getAll').then((result) => {
			this.disclosures = result.properties.disclosures;

			this.disclosures.forEach((disclosure) => {
				this.clean(disclosure);
				if(disclosure.courseID){
					this.filterByCourseField.options.push({
						name 	: disclosure.courseID,
						value 	:  disclosure.courseID
					});
				}
			});
			
			this.setCourseOptions();
		});	
	}

	setCourseOptions(){
		let options 	= [];
		let disclosures = this.disclosures.filter((disclosure) => { 
			return this.disclosureMatchesFilter(disclosure);
		});

		disclosures.forEach((disclosure) => {
			if(disclosure.courseID){
				let alreadyExists = false;
				options.forEach((option) => {
					if(option.value == disclosure.courseID){
						alreadyExists = true;
					}
				});
				if(!alreadyExists){
					options.push({
						name	:disclosure.courseID + ' - ' + disclosure.title, 
						value	:disclosure.courseID
					});
				}
			}
		});

		this.filterByCourseField.options = options;
	}

	/**
	 * Cleans a disclosure as needed
	 * @param {object} disclsosure
	 * @return {object}
	 */
	clean(disclosure) {
		try {
			disclosure.sections = JSON.parse(disclosure.sections);
			disclosure.settings = JSON.parse(disclosure.settings);
		} catch (e) {
			this.lioLogService.error(['Failed to parse disclosure or disclosure was already parsed']);
		}

		//sets the access link for providing a url to view the directive
		if (disclosure.active && disclosure.certificationID) {
			disclosure.accessLink = this.configSettings.serverGlobal + 'viewDisclosure?disclosureID=' + disclosure.id;
		}

		return disclosure;
	}

	/**
	 * Loads a disclosure into the editor
	 */
	load() {
		if (!this.model.data.id) {
			return;
		} 
		this.mode = 'display';

		if (!this.model.data.settings) {
			this.model.data.settings = this.utilService.copy(this.defaultSettings.settings);
		}	

		this.model.data.settings.disableReactivation = false;

		if (this.model.data.active || this.model.data.archived) {
			this.model.data.settings.mode = 'review';
		} else {
			this.model.data.settings.mode = 'edit';
		}

		if (this.model.data.certificationID && !this.model.data.active && !this.model.data.archived) {
			this.model.data.settings.wasActive = true;
			if (!this.permissionService.hasPermission('disclosure.canReactivate')) {
				this.model.data.settings.mode = 'review';
				this.model.data.disableReactivation = true;
				this.lioLogService.log(this.model.data.settings);
			}

		} else {
			this.model.data.settings.wasActive = false;
		}
	}

	/**
	 * Creates a new disclosure and loads it into the editor
	 */
	create() {
		this.model	= {data : this.utilService.copy(this.defaultSettings)};
		this.mode 	= 'display';
	}

	/**
	 * Confirm to activate a disclosure
	 */
	activate() {
		this.lioModalService.confirm('disclosure.confirmActivate').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('processing');
				this.confirmedActivation();
			}
		});
	}

	/**
	 * Activates a disclosure
	 */
	confirmedActivation() {
		return this.lmsService.postAsync('disclosure/activate', {id: this.model.data.id}).then((response) => {
			let token = response.properties.token;
			this.lmsService.getAsyncResult(token, (result)  => {
				if (result.success) {
					this.loadDisclosure().then(() => {
						this.lioModalService.hideLoading();
						this.lioModalService.show('disclosure.activateSuccess');
					});
				} else {
					this.lioModalService.hideLoading();
					this.lioModalService.show('disclosure.activateFail');
				}
			});
		});	
	}

	/**
	 * Archives a disclosure so that it can no longer be access
	 * Note: Does not delete, all information is retained, disclosures can also be re-activated later
	 */
	archive() {
		this.lioModalService.confirm('disclosure.confirmArchive').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('processing');
				return this.lmsService.post('disclosure/archive', {id: this.model.data.id}).then((result) => {
					this.lioModalService.hideLoading();

					if (result.success) {
						this.loadDisclosure().then(() => {
							this.lioModalService.show('disclosure.archiveSuccess');
						});
					} else {
						this.lioModalService.show('disclosure.archiveFail');
					}
				});	
			}
		});
	}

	/**
	 * Confirm to deactivate a disclosure
	 */
	deactivate() {
		this.lioModalService.confirm('disclosure.confirmDeactivate').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('processing');
				return this.confirmedDeactivation();
			}
		});
	}

	/**
	 * Deactivates a disclosure
	 */
	confirmedDeactivation() {
		return this.lmsService.post('disclosure/deactivate', {id: this.model.data.id}).then((result) => {
			this.lioModalService.hideLoading();

			if (result.success) {
				this.loadDisclosure().then(() => {
					this.lioModalService.show('disclosure.deactivateSuccess');
				});
			} else {
				this.lioModalService.show('disclosure.deactivateFail');
			}
		});	
	}

	/**
	 * Gets a disclosure by the current ID
	 */
	loadDisclosure() {
		return this.lmsService.post('disclosure/loadDisclosure', {id: this.model.data.id}).then((result) => {
			if (result.success) {
				let disclosure = result.properties.disclosure;
				this.model.data = this.clean(disclosure);

				// Update the list of loaded disclosures with this model
				let disclosureKeys = Object.keys(this.disclosures);
				disclosureKeys.forEach((key) => {
					if (this.disclosures[key].id === disclosure.id) {
						this.disclosures[key] = disclosure;
					}
				});

				this.load();
			}
		});	
	}

	/**
	 * Restores a disclosure that has been archived
	 */
	restore() {
		this.lioModalService.confirm('disclosure.confirmRestore').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('processing');
				return this.lmsService.post('disclosure/restore', {id: this.model.data.id}).then((result) => {
					this.lioModalService.hideLoading();

					if (result.success) {
						this.loadDisclosure().then(() => {
							this.lioModalService.show('disclosure.restoreSuccess');
						});
					} else {
						this.lioModalService.show('disclosure.restoreFail');
					}
				});	
			}
		});
	}

	/**
	 * Exports a disclosure to JSON
	 */
	exportToJSON() {
		let disclosure = {
			id 			: this.model.data.certificationid,
			title		: this.model.data.title,
			company		: this.model.data.company,
			sections	: this.model.data.sections,
			settings 	: this.model.data.settings
		};

		this.exportedData = JSON.stringify(disclosure);
	}

	/**
	 * Gets all text values shown in a disclosure
	 */
	getTextSections() {
		let texts = [];
		this.model.data.sections.forEach((section) => {
			if (section.text) {
				texts.push(section);
			}

			if (section.button && section.button.text) {
				texts.push(section.button);
			}

			section.questions.forEach((question) => {
				if (question.text) {
					texts.push(question);
				}

				if (question.response && question.response.options) {
					question.response.options.forEach((option) => {
						texts.push(option);
					});
				}
			});
		});

		return texts;
	}

	/**
	 * Exports all text values in a disclosure as a csv
	 */
	exportText() {
		let texts 	= this.getTextSections();
		//retrieve values from objects
		texts = texts.map((text) => {
			return {text : text.text || ''};
		});
		let text 	= Papa.unparse(texts, {header: false, skipEmptyLines: false}),
			title 	= 'Disclosure Verbiage.csv';

		if (this.document.defaultView.navigator && this.document.defaultView.navigator.msSaveOrOpenBlob) {
			// For IE
			let blob 	= new Blob([text], {type:"text/csv;charset=utf-8"});
			this.document.defaultView.navigator.msSaveOrOpenBlob(blob, title);
		} else {
			// For Non-IE
			let element	= this.document.createElement("a");
			element.href = "data:application/csv;charset=UTF-8,%EF%BB%BF" + encodeURIComponent(text);
			element.download = title;
			element.click();
		}
	}

	/**
	 * Imports text values for a disclosure from a csv, overwriting existing values
	 */
	importText(file) {
		file.file.text().then((data) => {
			let texts = this.getTextSections();
			let importedText = Papa.parse(data, {skipEmptyLines: false}).data;

			if (texts.length == importedText.length) {
				for(let i = 0; i < texts. length; i++) {
					texts[i].text = importedText[i][0];
				}
				this.lioModalService.show('disclosure.importTextSuccess');
			} else {
				this.lioModalService.show('disclosure.importTextFail', null, [
					{key : 'expected', value : texts.length}, 
					{key : 'actual', value : importedText.length}
				]);
			}
		});
	}

	/**
	 * Switches between review and edit mode
	 */
	switchMode() {
		if (this.model.data.settings.mode == 'review') {
			this.model.data.settings.mode = 'edit';
		} else {
			this.model.data.settings.mode = 'review';
		}
	}
}