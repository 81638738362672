import { Component, Inject } from '@angular/core';

import { debugService } from '../../../../services/debug.service';
import { errorsService } from '../../../../services/errors.service';
import { lmsService } from '../../../../services/lms.service';

@Component({
	selector: 'lio-critical-error',
	templateUrl: './critical-error.component.html',
})
export class LioCriticalError {
	constructor(
		@Inject(debugService) 	public debugService 	:debugService,
		@Inject(errorsService) 	public errorsService 	:errorsService,
		@Inject(lmsService) 	public lmsService 		:lmsService
	){
		this.debugService.register('criticalerror', this);
	}
}