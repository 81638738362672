import { Component, Inject } from '@angular/core';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { catalogService } from '../../../../services/catalog.service';
import { workerService } from '../../../../services/worker.service';
import { localizationService } from '../../../../services/localization.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { filesService } from '../../../../services/files.service';
import { fieldService } from '../../../../services/fields.service';
import { storageService } from '../../../../services/storage.service';
import { catalogAdminSettings } from '../../../../components/catalog-admin/catalog-admin.settings';
import { CatalogAdminEditComponent } from '../../components/catalog-admin-edit/catalog-admin-edit.component'

@Component({
  selector: 'lio-catalog-admin-add',
  templateUrl: './catalog-admin-add.component.html'
})
export class CatalogAdminAddComponent extends CatalogAdminEditComponent {

	constructor(
		@Inject(navService)				public override  	navService			:navService,
		@Inject(debugService)			public override 	debugService		:debugService,
		@Inject(feedbackService)		public override 	feedbackService		:feedbackService,
		@Inject(errorsService)			public 	override errorsService		:errorsService,
		@Inject(lmsService)				public 	override lmsService			:lmsService,
		@Inject(catalogService)			public override 	catalogService		:catalogService,
		@Inject(lioLogService)			public override 	lioLogService		:lioLogService,
		@Inject(stateService)			public override stateService		:stateService,
		@Inject(formValidatorService)	public override 	formValidatorService:formValidatorService,
		@Inject(filesService)			public override 	filesService		:filesService,
		@Inject(workerService)			public override 	workerService		:workerService,
		@Inject(permissionService)		public override 	permissionService	:permissionService,
		@Inject(localizationService)	public override 	localizationService	:localizationService,
		@Inject(lioModalService)		public override 	lioModalService		:lioModalService,
		@Inject(processingService) 		public override 	processingService	:processingService,
		@Inject(utilService) 			public 	override  utilService			:utilService,
		@Inject(fieldService) 			public override 	fieldService		:fieldService,
		@Inject(storageService)			public override  	storageService		:storageService,
		@Inject(catalogAdminSettings) 		public override  	catalogAdminSettings		:catalogAdminSettings
	){
		super(
			navService,
			debugService,
			feedbackService,
			errorsService, 
			lmsService,
			catalogService,
			lioLogService,
			stateService,
			formValidatorService,
			filesService,
			workerService,
			permissionService,
			localizationService,
			lioModalService,
			processingService,
			utilService,
			fieldService,
			storageService,
			catalogAdminSettings,
		);

		this.debugService.register('catalogadminadd', this);
		super.init();
		this.getFieldByName('courseID').locked = false;
	}


		/*
	 * Submits the edit
	*/
	override submit():boolean {
		if (!this.isValid()) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			return false;
		}

		this.catalogService.saveCatalog(this.catalog).then((result)  => {
			if (result) {
				this.allowSubmit = false;
			}
		});
		return true;
	}
}