import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: emailAuditLogSettings
})
export class emailAuditLogSettings {
	pagination:any = {
			'pageLimit'	: '10',
			'pageLimits': ['10', '50', '100'],
			'sortMode'	: {field : 'sentDate', direction : 'desc'}
	};

	searchFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Company ID',
			'model': 'companyID',
			'type': 'text',
			'visible': true,
		},
		{
			'name': 'UIDs',
			'model': 'UIDs',
			'type': 'text',
			'visible': true,
		},
		{
			'name': 'Email Addresses',
			'model': 'emails',
			'type': 'text',
			'visible': true,
		},
		{
			'name': 'Total Sent To',
			'model': 'totalSent',
			'type': 'number',
			'visible': true,
		},
		{
			'name': 'Total Failed To Send',
			'model': 'totalFailed',
			'type': 'number',
			'visible': true,
		},
		{
			'name': 'Subject',
			'model': 'subject',
			'type': 'text',
			'visible': true,
		},
		{
			'name': 'Sent Date',
			'model': 'sentDate',
			'type': 'date',
			'visible': true,
		},
		{
			'name': 'Admin ID',
			'model': 'adminID',
			'type': 'text',
			'visible': true,
		}
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Company ID',
			'model': 'companyID',
			'type': 'text',
			'visible': true,
			'sortable': true
		},
		{
			'name': 'Subject',
			'model': 'subject',
			'type': 'text',
			'visible': true,
			'sortable': true
		},
		{
			'name': 'Message',
			'model': 'message',
			'type': 'text',
			'visible': false,
			'export': true,
			'sortable': true
		},
		{
			'name': 'UIDs',
			'model': 'UIDs',
			'type': 'concat',
			'visible': true,
			'max': 50,
			'sortable': true
		},
		{
			'name': 'Email Addresses',
			'model': 'emails',
			'type': 'concat',
			'visible': true,
			'max': 50,
			'sortable': true
		},
		{
			'name': 'Total Sent To',
			'model': 'totalSent',
			'type': 'text',
			'visible': true,
			'sortable': true
		},
		{
			'name': 'Total Failed To Send',
			'model': 'totalFailed',
			'type': 'text',
			'visible': true,
			'sortable': true
		},
		{
			'name': 'Sent Date',
			'model': 'sentDate',
			'type': 'date',
			'visible': true,
			'sortable': true
		},
		{
			'name': 'Admin ID',
			'model': 'adminID',
			'type': 'text',
			'visible': true,
			'sortable': true
		}
	);
}