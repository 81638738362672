import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { permissionService } from '../../services/permissions.service';

import { adminToolsSettings } from './admin-tools.settings';
import { PanelTabData } from '../../modules/structural/structural.models';

@Component({
	selector: 'lio-admin-tools',
	templateUrl: './admin-tools.component.html'
})
export class AdminToolsComponent implements OnDestroy {
	public settingsCards		:Array<any>		= this.adminToolsSettings.settingsCards;
	public logsCards			:Array<any>		= this.adminToolsSettings.logsCards;
	public manageCards			:Array<any>		= this.adminToolsSettings.manageCards;

	public settingsPanelConfig	:PanelTabData	= this.adminToolsSettings.settingsPanelConfig;
	public logsPanelConfig		:PanelTabData	= this.adminToolsSettings.logsPanelConfig;
	public managePanelConfig	:PanelTabData	= this.adminToolsSettings.managePanelConfig;

	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				private	navService:navService,
		@Inject(debugService)			private	debugService:debugService,
		@Inject(feedbackService)		private	feedbackService:feedbackService,
		@Inject(stateService)			private	stateService:stateService,
		@Inject(permissionService) 		public	permissionService:permissionService,
		@Inject(adminToolsSettings)	 	private	adminToolsSettings:adminToolsSettings
	){
		this.debugService.register('adminTools', this);
		this.navService.setActivePage('adminTools');

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	init() {
		this.navService.displayPage();
		this.feedbackService.clearErrors();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}