import { Component, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { workerService } from '../../../../services/worker.service';
import { localizationService } from '../../../../services/localization.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { filesService } from '../../../../services/files.service';
import { fieldService } from '../../../../services/fields.service';
import { storageService } from '../../../../services/storage.service';
import { catalogService } from '../../../../services/catalog.service';
import { catalogAdminSettings } from '../../../../components/catalog-admin/catalog-admin.settings';

@Component({
  selector: 'lio-catalog-admin-search',
  templateUrl: './catalog-admin-search.component.html'
})
export class CatalogAdminSearchComponent implements OnDestroy {
	@Output() callback			:EventEmitter<any> 		= new EventEmitter();
	@Output() onLoaded			:EventEmitter<any> = new EventEmitter();

	public 	appearance:MatFormFieldAppearance = 'outline';
	public	fields:any									= [];
	public	loadFields:any							= false;
	public	pagination:any							= {};
	public 	search:string 							= '';
	public	catalogs:any								= [];
	public	catalogIDs:any							= [];
	public	filteredCatalogs:any				= [];
	public	fieldConfig:any							= {};
	public	searchPanelConfig:any				= {};
	public localeStrings:any 						= {};
	public localeStringsKeys:any 				= [];

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)		public 	feedbackService		:feedbackService,
		@Inject(errorsService)			public 	errorsService		:errorsService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(catalogService)			public 	catalogService		:catalogService,
		@Inject(lioLogService)			public 	lioLogService		:lioLogService,
		@Inject(stateService)			public 	stateService		:stateService,
		@Inject(formValidatorService)	public 	formValidatorService:formValidatorService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(workerService)			public 	workerService		:workerService,
		@Inject(permissionService)		public 	permissionService	:permissionService,
		@Inject(localizationService)	public 	localizationService	:localizationService,
		@Inject(lioModalService)		public 	lioModalService		:lioModalService,
		@Inject(processingService) 		public 	processingService	:processingService,
		@Inject(utilService) 			public 	utilService			:utilService,
		@Inject(fieldService) 			public 	fieldService		:fieldService,
		@Inject(storageService)			public 	storageService		:storageService,
		@Inject(catalogAdminSettings) 		public 	catalogAdminSettings		:catalogAdminSettings
	){
		this.debugService.register('catalogadminsearch', this);

		this.fields = this.permissionService.setFields(this.catalogAdminSettings.fields);
		this.loadFields = this.utilService.copy(this.catalogAdminSettings.loadFields);
		this.pagination = this.catalogAdminSettings.pagination;
		this.filteredCatalogs = [];
		this.fieldConfig = this.catalogAdminSettings.fieldConfig;

		this.searchPanelConfig 	= this.utilService.copy(this.catalogAdminSettings.panelConfig.search);
		this.subscriptions = NEVER.subscribe();
		this.localeStrings = {
			active					:'form.activeStatus',
			inactive				:'form.inactiveStatus',
			name						:'form.name',
			search					:'form.search',
			courseID				:'form.courseID',
			status					:'form.status',
			action					:'form.action',
			copy						:'form.copy',
			edit						:'form.edit',
			delete					:'form.delete',
			save						:'catalogadmin.save',
	}

	this.localeStringsKeys = Object.keys(this.localeStrings);
		this.subscriptions.add(
			this.catalogService.catalogs.subscribe((catalogs) => {
				this.catalogs = catalogs;
				this.navService.displayPage().then(() => {
					this.onLoaded.emit();
				})
			})
		);
		this.catalogService.init();
	}



	/**
	 * Recieves information from paginator
	 * @param {object} paginatorResults
	 */	
	updateFilteredCatalogs(paginatorResults) {
		this.filteredCatalogs = paginatorResults.filtered;
	}


	/*
	 * Selects a catalog for editing
	*/
	editCatalog(catalog) {
		this.catalogService.setCatalog(catalog);
		this.callback.emit({'switchTab': 'edit'});
	}

	/*
	 * Copies a catalog
	*/
	copyCatalog(catalog) {
		catalog = this.utilService.copy(catalog);
		catalog.courseID = null;
		this.catalogService.setCatalog(catalog);
		this.callback.emit({'switchTab': 'copy'});
	}

	/*
	 * Delete a catalog
	*/
	deleteCatalog(catalog) {
		let macros 				= [{'key': 'name', 'value': catalog.name}];

		this.lioModalService.confirm('areYouSureDeleteName', null, macros).then((confirmed) => {
			if (confirmed) {
				this.catalogService.deleteCatalog(catalog);
			}
		});
	}

	/*
	 * Deactivates a catalog
	*/
	deactivateCatalog(catalog) {
		this.catalogService.deactivateCatalog(catalog);
	}

	/*
	 * Activates a catalog
	*/
	activateCatalog(catalog) {
		this.catalogService.activateCatalog(catalog);
	}


	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}