<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys" *ngIf="!navService.loading">
	<div class="col-md-12">
		<!-- If course ID does not exist -->
		<lio-searchable-select
			[model]="chosenTopic"
			[options]="topics"
			[field]="topicField"
			(onUpdate)="selectedTopic()"
			appearance="outline">
		</lio-searchable-select>

		<!-- If course ID exists -->
			<mat-card class="mb-3">
				<lio-form-inputs-vertical
					[fields]="topicFields"
					[model]="topic">
				</lio-form-inputs-vertical>
			</mat-card>
			<lio-catalog-keywords [hidden]="!permissionService.hasPermission('catalog.keywords')"
				[model]="topic"
			></lio-catalog-keywords>


			<div class="btn-toolbar">
				<button id="am_catalogadmin_submit" [disabled]="!topic.name" (click)="saveTopic()" class="btn btn-sm btn-primary" type="submit"><strong>{{localeStrings.saveTopic}}</strong></button>

				<button id="am_catalogadmin_delete" [disabled]="!topic.name" (click)="deleteTopic()" class="btn btn-sm btn-warning ml-2" type="submit"><strong>{{localeStrings.deleteTopic}}</strong></button>
			</div>
	</div>
</div>