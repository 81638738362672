import { LioField } from './field.model';

import { Observable } from 'rxjs';

export class LioSearchableSelectField extends LioField{
	public searchable				:boolean;
	public multiple 				:boolean;
	public includeInvisibleOptions 	:boolean;
	public includeLockedOptions 	:boolean;
	public optionAsValue 			:boolean;
	public optionNameField 			:string;
	public optionValueField 		:string;
	public fontSize 				:string;
	public backgroundColor 			:string;
	public optionTooltipField 		:string;
	public optionGroups 			:Array<any>;
	public optionGroupField 		:string;
	public fullWidth 				:boolean;
	public addEmptyOption			:boolean;
	public orderBy					:string;
	public debounce					:number;
	public filters					:Array<any>;
	public nameFormatter 			:(name:string, option:any) => string;
	public options					:Array<any>|Observable<any>;
	public clearable				:boolean;
	public disabled					:boolean = false;

	constructor(obj:Partial<LioSearchableSelectField>){
		super(obj);
		Object.assign(this, obj);
	}
}