<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-12">
	<!-- Bulk Upload -->
		<form name="form" role="form" autocomplete="off" novalidate>
			<div flow-drop class="drop">
				<span>{{localeStrings.dragExcel}}</span>
				<div>
					<a (click)="getTemplate()" class="" type="submit"><strong>{{localeStrings.getTemplate}}</strong></a>
					<a (click)="getSample()" type="submit">
						<span style="margin:0 1em">|</span>
						<strong>
							<span>{{localeStrings.getSample}}</span>
						</strong>
					</a>
					<a (click)="exportAll()" type="submit">
						<span style="margin:0 1em">|</span>
						<strong>
							<span>{{localeStrings.exportAll}}</span>
						</strong>
					</a>
				</div>

				<div class="form-check">
					<div class="alert alert-dismissible" ng-class="{'alert-info':process.type  == 'DELTA', 'alert-success':process.type  == 'FULL_FILE', 'alert-danger':process.type  == ''}" alert-dismissible role="alert">
						<lio-searchable-select
							[model]="process"
							[options]="process.types"
							[field]="uploadTypeField"
							appearance="outline">
						</lio-searchable-select>
						
						<span *ngIf="process.type == ''">
							<i>{{localeStrings.makeSelection}}</i>
						</span>
					</div>
				</div>
			</div>
			<div>
				<lio-file-uploader [settings]="uploadSettings" (success)="fileAdded($event)"></lio-file-uploader>
				<button id="am_catalogadmin_process" [disabled]="!readyForProcess" (click)="processFile()" class="btn btn-sm btn-primary font-weight-bold" type="submit">{{localeStrings.process}}</button>
			</div>
		</form>
	</div>
</div>