<div class="overflow-auto rtl-direction text-center">
	<lio-fields [fields]="fields" [fieldConfig]="fieldConfig">
		<table class="table table-striped table-md small">
			<thead class="bg-primary">
				<tr>
					<th class="text-white" [ngClass]="headerPadding" *ngFor="let button of prefixButtons" localize [model]="button" item="header">
						<button mat-button
							[disabled]="!button.sortable"
							type="button"
							(click)="clickedHeader(button)"
							class="text-white">
								<span *ngIf="button.header">{{button.header}}</span>
								<span *ngIf="paginator.settings.sortMode && paginator.settings.sortMode.field" 
									[hidden]="paginator.settings.sortMode.field != button.model || !button.sortable">

									<i [hidden]="paginator.settings.sortMode.direction == 'desc'" class="fa fa-caret-up ml-1"></i>
									<i [hidden]="paginator.settings.sortMode.direction == 'asc'" class="fa fa-caret-down ml-1"></i>
								</span>
						</button>
					</th>
					<th *ngFor="let field of fields" 
						[hidden]="!field.visible" 
						class="text-white" 
						[ngClass]="headerPadding"
						localize
						[model]="field"
						item="name">
							<!-- IE Is running slow at rendering the lio-contect-menu directives -->
							<!-- TO DO: Once angularJS is out of the picture we can try re-adding them  -->
							<!-- <lio-context-menu [field]="field"> -->
							<button mat-button 
								id="{{'am_' + field.model}}"
								(click)="clickedHeader(field)"
								[disabled]="!field.headerClick && !field.sortable"
								type="button"
								class="text-white">
									<span *ngIf="field.name">{{field.name}}</span>
									<span *ngIf="paginator.settings.sortMode && paginator.settings.sortMode.field" 
										[hidden]="paginator.settings.sortMode.field != field.model || !field.sortable">

										<i [hidden]="paginator.settings.sortMode.direction == 'desc'" class="fa fa-caret-up ml-1"></i>
										<i [hidden]="paginator.settings.sortMode.direction == 'asc'" class="fa fa-caret-down ml-1"></i>
									</span>
							</button>
							<!-- </lio-context-menu> -->
					</th>
					
					<th *ngFor="let button of suffixButtons" class="text-white" [ngClass]="headerPadding">
						<span *ngIf="!button.sortable" mat-button disabled class="text-white">{{button.name}}</span>
						<button *ngIf="button.sortable" mat-button
							(click)="clickedHeader(button)"
							type="button"
							class="text-white">
								<span *ngIf="button.name">{{button.name}}</span>
								<mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon>
								<span *ngIf="paginator.settings.sortMode && paginator.settings.sortMode.field" 
									[hidden]="paginator.settings.sortMode.field != button.model || !button.sortable">
									
									<i [hidden]="paginator.settings.sortMode.direction == 'desc'" class="fa fa-caret-up ml-1"></i>
									<i [hidden]="paginator.settings.sortMode.direction == 'asc'" class="fa fa-caret-down ml-1"></i>
								</span>
						</button>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let result of results; index as i" [ngClass]="{'bg-light-green':result.selected}">
					
					<!-- Prefix Buttons -->
					<td *ngFor="let button of prefixButtons">
						<div [hidden]="button.visible && !button.visible(result)" class="text-center">
							<button id="{{'am_' + button.id + '_' + i}}" 
								[hidden]="button.type && button.type!='button'" 
								mat-flat-button
								class="px-1"
								[ngClass]="button.class"
								[style.minWidth.px]="0"
								[style.lineHeight.px]="30"
								[color]="result[button.dynamicColor] || button.color"
								[disabled]="button.disabled || result.disabled" 
								(click)="button.callback(result, button)"
								type="button">

								<span *ngIf="button.name && !button.dynamicName">{{button.name}}</span>
								<span *ngIf="button.icon && button.dataField">
									<mat-icon *ngIf="result[button.dataField]">{{button.icon}}</mat-icon>
									<mat-icon *ngIf="!result[button.dataField]">{{button.disabledIcon}}</mat-icon>
								</span>
								<mat-icon *ngIf="button.icon && !button.dataField">{{button.icon}}</mat-icon>

								<span *ngIf="button.dynamicName">{{result[button.dynamicName]}}</span>
							</button>
							<label for="{{'am_' + button.id + '_' + i}}" *ngIf="button.type == 'checkbox'">Label</label>
							<label *ngIf="button.type =='checkbox'">Label</label>
							<input name="{{'am_' + button.id + '_' + i}}" id="{{'am_' + button.id + '_' + i}}" *ngIf="button.type =='checkbox'" type="checkbox" [(ngModel)]="result[button.dataField]" (change)="button.callback(result)" />
						</div>
					</td>

					<td *ngFor="let field of fields; index as i;" [hidden]="!field.visible" [ngClass]="{'bg-light-green':result.selected,'bg-danger':result.error, 'text-center': field.type == 'checkbox'}">
						<span *ngIf="field.type == 'default'">{{result[field.model]}}</span>
						<span *ngIf="field.type == 'text'">{{result[field.model]}}</span>
						<span *ngIf="field.type == 'concat'">{{result[field.model] | slice : 0 : field.max}}</span>
						<span *ngIf="field.type == 'number'">{{result[field.model]}}</span>
						<span *ngIf="field.type == 'date'">{{formatDate(result[field.model])}}</span>
						<span *ngIf="field.type == 'timestamp'">{{formatTimeStamp(result[field.model])}}</span>
						<span *ngIf="field.type == 'email'">{{result[field.model]}}</span>
						<span *ngIf="field.type == 'select'&& !field.mapped">{{result[field.model]}}</span>
						<span *ngIf="field.mapped">{{result[field.model + 'mapped']}}</span>
						
						<!-- Button -->
						<div *ngIf="field.type == 'button'">
							<button [hidden]="field.dynamicName && !result[field.dynamicName]" *ngIf="!isDefined(result[field.gotResult])" id="{{'am_' + field.model + '_' + i}}" class="btn btn-sm btn-primary" [disabled]="!result[field.disabledModel]" (click)="clickedField(result, field)">
								<span *ngIf="field.name && !field.dynamicName">{{field.name}}</span>
								<span *ngIf="field.name && field.dynamicName">{{result[field.dynamicName] | limit:field.max}}</span>
								<mat-icon *ngIf="field.icon">{{field.icon}}</mat-icon>
							</button>
							<span *ngIf="isDefined(result[field.gotResult])">{{result[field.model]}}</span>
						</div>

						<!-- Textarea -->
						<span *ngIf="field.type == 'textarea'">
							<label for="{{newID()}}" class="sr-only">Text for {{field.name}}</label>
							<textarea id="{{getLastID()}}" [(ngModel)]="result[field.model]" [placeholder]="field.name" class="form-control" style="height: 220px; max-width: 100%" readonly></textarea>
						</span>

						<!-- Boolean -->
						<span *ngIf="field.type == 'boolean'" localize [model]="boolFields" [items]="['true','false']">
							<span *ngIf="result[field.model] == 1">{{boolFields.true}}</span>
							<span *ngIf="!result[field.model] || result[field.model] == 0">{{boolFields.false}}</span>
						</span>

						<!-- Checkbox -->
						<span *ngIf="field.type == 'checkbox' && result[field.model] && result[field.model] != 0">
							<i class="fa fa-circle require-checked"></i>
						</span>

						<!-- Checkselection -->
						<span class="mat-checkbox mat-accent" *ngIf="field.type == 'checkselection'">
							<label for="{{newID()}}" class="sr-only">Check selection for {{field.name}}</label>
							<input id="{{getLastID()}}" class="mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin" [(ngModel)]="result[field.model]" (click)="clickedField(result, field)" name="{{result[field.model]}}" [disabled]="result[field.disabledModel]" [hidden]="result[field.hiddenCondtion]" type="checkbox" />
						</span>

						<!-- Check -->
						<span *ngIf="field.type == 'check'">
							<span *ngIf="field.invertModel">
								<span [hidden]="result[field.model]"><i class="fa fa-circle require-checked" aria-hidden="true"></i></span>
							</span>
							<span *ngIf="!field.invertModel">
								<span [hidden]="!result[field.model]"><i class="fa fa-circle require-checked" aria-hidden="true"></i></span>
							</span>
						</span>
					</td>

					<!-- Suffix Buttons -->
					<td *ngFor="let button of suffixButtons">
						<div [hidden]="button.visible && !button.visible(result)">
							<button id="{{'am_' + button.id + '_' + i}}" 
								mat-flat-button
								[color]="result[button.dynamicColor] || button.color"
								class="px-1"
								[style.minWidth.px]="0"
								[style.lineHeight.px]="30"
								[hidden]="button.type && button.type!='button'" 
								[disabled]="button.disabled" 
								(click)="button.callback(result)"
								type="button">

								<span *ngIf="button.name && !button.dynamicName">{{button.name}}</span>
								<mat-icon *ngIf="button.icon">{{button.icon}}</mat-icon>
								<span *ngIf="button.dynamicName">{{result[button.dynamicName]}}</span>
							</button>
							<label for="{{'am_' + button.id + '_' + i}}" *ngIf="button.type =='checkbox'">Label</label>
							<input name="{{'am_' + button.id + '_' + i}}" id="{{'am_' + button.id + '_' + i}}" *ngIf="button.type =='checkbox'" type="checkbox" [(ngModel)]="result[button.dataField]" (change)="button.callback(result)" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</lio-fields>
</div>