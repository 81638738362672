/*
 * PDF
*/
import { Inject, Injectable, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { jsPDF } from "jspdf";

import { lioModalService } from './lio-modal.service';

@Injectable({
	providedIn: 'root',
})
export class pdfService {
	constructor(
		@Inject(DOCUMENT)			private document		: Document,
		@Inject(Meta)				private meta			: Meta,
		@Inject(lioModalService)	private lioModalService	: lioModalService
	){}

	/**
	 * Function that renders an element as a PDF using HTML2Canvas and JSPDF
	 * KNOWN ISSUES
	 *  - Page breaks are added before rendering since JSPDF cant handle them
	 * 		- this is done using the current screen width, but rendering happens at the screen width set below
	 * 		- this can cause media queries to use the wrong breakpoint
	 * 		- if this happens, content can be cut off between pages
	 */
	render(pdfTarget:ElementRef, allowImages:boolean = true):Observable<jsPDF>{
		this.lioModalService.showLoading('processing');

		//Remove images
		if(!allowImages){
			let temp = pdfTarget.nativeElement.getElementsByTagName('img');
			for(let i = 0; i < temp.length;){
				temp[i].remove();
			}
		}

		return new Observable((subscriber) => {
			let margin 		= 20;
			let pageSize 	= [816, 1054];

			//Force viewport width to make bootstrap use the breakpoints we want
			let oldPageWidth = this.meta.getTag('name="viewport"').content;
			this.meta.removeTag('name="viewport"');
			this.meta.addTag({ name: 'viewport', content: 'width=' + pageSize[0]});
			//Hack to get jsPDF to allow us to set pagewidth
			let oldStyleWidth 							= pdfTarget.nativeElement.style.width;
			let oldStylePadding							= pdfTarget.nativeElement.style.padding;
			pdfTarget.nativeElement.style.width 	= pageSize[0] + 'px';
			pdfTarget.nativeElement.style.padding 	= margin + 'px';
			pdfTarget.nativeElement.classList.add('print');

			let canvasSettings = {
				windowWidth	: pageSize[0],
				y 			: 0
			};

			let jsPdfOptions:any = {
				orientation : 'p',
				format 		: pageSize,
				unit		: 'pt'
			};

			let printOptions = {
				pagesplit: true,
				callback : (pdfInstance) => {
					//set element style back to what it was
					pdfTarget.nativeElement.style.width = oldStyleWidth;
					pdfTarget.nativeElement.style.padding = oldStylePadding;
					pdfTarget.nativeElement.classList.remove('print');
					//remove page breaks
					let pageBreaks = this.document.getElementsByClassName('temp-page-break');
					while(pageBreaks[0]){
						pageBreaks[0].parentNode.removeChild(pageBreaks[0]);
					}
					//reset page width
					this.meta.removeTag('name="viewport"');
					this.meta.addTag({ name: 'viewport', content: oldPageWidth });

					subscriber.next(pdfInstance);
					
					this.lioModalService.hideLoading();
				},
				backgroundColor : '#ffffff',
				html2canvas 	: canvasSettings
			};

			this.makePageBreaks(pdfTarget.nativeElement, pdfTarget.nativeElement.offsetTop + margin, pageSize[1]);
			
			let doc = new jsPDF(jsPdfOptions);
			doc.html(pdfTarget.nativeElement, printOptions);
		});
	}

	//hack to deal with JSpdf not understanding how to handle page breaks
	makePageBreaks(element:HTMLElement, pageStart:number = 0, pageLength:number = 1054):number{
		let addedBreaks 	= 0;
		let pageBreakIn 	= getComputedStyle(element).pageBreakInside;
		let pageBreakAfter 	= getComputedStyle(element).pageBreakAfter;
		if(pageBreakIn == 'avoid'){
			let yInPage = (element.offsetTop - pageStart) % pageLength;
			if(yInPage + element.offsetHeight > pageLength){
				//element crosses the page boundary, we need to make a page-break
				this.addPageBreak(element, yInPage, pageLength, 'before');
				addedBreaks++;
			}
		}
		if(pageBreakAfter == 'always'){
			//use elementRect.bottom since we are inserting after
			let yInPage = (element.offsetTop + element.offsetHeight - pageStart) % pageLength;
			this.addPageBreak(element, yInPage, pageLength, 'after');
			addedBreaks++;
		}
		for(let i = 0; i < element.children.length; i++){
			if(element.children[i] instanceof HTMLElement){
				let childAddedBreaks = this.makePageBreaks(element.children[i] as HTMLElement, pageStart, pageLength);
				i += childAddedBreaks;
			}
		}
		return addedBreaks;
	}

	addPageBreak(element:HTMLElement, yInPage:number = 0, pageLength:number = 1054, order:string = 'before'):void{
		let newMargin 	= pageLength - yInPage;
		let newNode 	= document.createElement('div');
		newNode.classList.add('col-12');
		newNode.classList.add('temp-page-break');
		newNode.style.marginTop = newMargin + 'px';

		switch(order){
			case 'before':
				element.insertAdjacentElement('beforebegin', newNode);
				break;
			case 'after':
				element.insertAdjacentElement('afterend', newNode);
				break;
		}
	}
}