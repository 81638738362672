import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';

import { debugService } from 'src/app/services/debug.service';

@Component({
  selector: 'lio-catalogs',
  templateUrl: './catalogs.component.html'
})
export class LioCatalogs{
	@Input() catalogs			:Array<any> 		= [];
	@Input() filters			:any 				= null;
	@Input() pagination			:any 				= null;
	@Input() view				:any 				= null;
	@Input() fieldsObject		:any 				= null;

	@Output() clickedCatalog	:EventEmitter<any> 	= new EventEmitter();
	@Output() selectModule		:EventEmitter<any> 	= new EventEmitter();
	@Output() previewModule		:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedPrint		:EventEmitter<any> 	= new EventEmitter();
	@Output() previewCourse		:EventEmitter<any> 	= new EventEmitter();
	@Output() selfEnroll		:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnScript	:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnInfoSheet:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnRequest	:EventEmitter<any> 	= new EventEmitter();
	@Output() catalogsFiltered	:EventEmitter<any> 	= new EventEmitter();

	public filteredCatalogs		:Array<any> 		= [];

	constructor(
		@Inject(debugService) public debugService:debugService
	){
		this.debugService.register('catalogs', this);
	}

	/**
	 * Recieves information from paginator
	 * @param {object} event
	 */	
	updateFilteredCatalogs(event) {
		this.filteredCatalogs = event.filtered;
		this.catalogsFiltered.emit(event);
	}
}