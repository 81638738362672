<div class="row" *ngIf="mode == 'menu'">
	<lio-panel-tabber class="col-md-10 offset-md-1">
		<lio-panel-tab [settings]="panelConfig.manage">	
			<ng-container *lioPanelTabContent>
				<div class="card-body">
					<h2 class="h5">Filter By:</h2>
					<mat-button-toggle-group [(ngModel)]="this.filterBy.status" 
						color="primary" 
						(change)="switchFilter()"
						class="mb-3">

						<mat-button-toggle value="editable">Editable</mat-button-toggle>
						<mat-button-toggle value="active">Active</mat-button-toggle>
						<mat-button-toggle value="archived">Archived</mat-button-toggle>
						<mat-button-toggle value="all">All</mat-button-toggle>
					</mat-button-toggle-group>
					
					<lio-searchable-select id="am_disclosure_list_filter"
						[model]="filterBy"
						[field]="filterByCourseField"
						[appearance]="disclosureTabAppearance">
					</lio-searchable-select>

					<lio-searchable-select id="am_disclosure_list"
						[model]="model"
						[field]="disclosureField"
						[appearance]="disclosureTabAppearance">
					</lio-searchable-select>

					<button mat-flat-button 
						color="warn" 
						class="mr-1 mb-1" 
						(click)="delete()" 
						[disabled]="!model.data || !model.data.id" 
						*ngIf="model.data && !model.data.active">
						
						<span>Delete</span>
					</button>
					<button mat-flat-button 
						color="primary" 
						class="mr-1 mb-1" 
						[disabled]="!model.data || !model.data.id" 
						(click)="load()">
						
						<span>Load</span>
					</button>
					<button mat-flat-button 
						color="primary" 
						class="mb-1" 
						[disabled]="!model.data || !model.data.id" 
						(click)="clone()">
						
						<span>Clone</span>
					</button>
				</div>
				<div class="card-footer">
					<button mat-flat-button color="primary" (click)="create()">Create</button>
				</div>
			</ng-container>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panelConfig.settings">
			<ng-container *lioPanelTabContent>
				<div class="card">
					<div class="card-header">
						<h4>Notification Emails</h4>
						<p>This list of conditions will determine who will recieve an email when a user completes a disclosure with responses that are set to send a notification</p>
						<p>When an email will be sent, the list will be checked in order and the first entry that has a matching condition will determine who is emailed</p>
						<p>Both the "To" and "cc" values may be a comma seperated list of emails</p>
					</div>
					<div class="card-body">
						<div *ngFor="let entry of notificationEmails.current; index as i">
							<lio-card cardClass="mb-3">
								<div lio-card-header>
									<div class="text-right">
										<button mat-flat-button color="warn" (click)="removeNotificationEmail(i)">
											<mat-icon>delete</mat-icon>
										</button>
									</div>
								</div>
								<div lio-card-body>
									<div class="d-block d-flex">
										<lio-searchable-select
											[model]="entry"
											[options]="fields"
											[field]="notificationFieldField"
											[appearance]="notificationTabappearance">
										</lio-searchable-select>
										<lio-searchable-select
											class="ml-1"
											[model]="entry"
											[options]="operators"
											[field]="notificationOperatorField"
											[appearance]="notificationTabappearance">
										</lio-searchable-select>
										<lio-searchable-select *ngIf="entry.field.type == 'select'"
											class="ml-1"
											[model]="entry"
											[options]="entry.field.options"
											[field]="notificationValueField"
											[appearance]="notificationTabappearance">
										</lio-searchable-select>
										<mat-form-field *ngIf="entry.field.type != 'select' && entry.field.type != 'date'" 
											[appearance]="notificationTabappearance" 
											class="w-100">

											<mat-label>Value</mat-label>
											<input matInput type="text" [(ngModel)]="entry.condition"/>
										</mat-form-field>
										<lio-mat-wrap-date-field *ngIf="entry.field.type == 'date'"
											[field]="{name:'value', model:'condition'}" 
											[model]="model"
											[appearance]="notificationTabappearance">
										</lio-mat-wrap-date-field>
									</div>
									<mat-form-field [appearance]="notificationTabappearance" class="w-100">
										<mat-label>Email(s)</mat-label>
										<input matInput type="text" [(ngModel)]="entry.email">
									</mat-form-field>
									<mat-form-field [appearance]="notificationTabappearance" class="w-100">
										<mat-label>CC(s)</mat-label>
										<input matInput type="text" [(ngModel)]="entry.cc">
									</mat-form-field>
								</div>
							</lio-card>
						</div>
					</div>
					<div class="card-footer">
						<button mat-flat-button color="primary" (click)="addNotificationEmail()">
							<mat-icon>add</mat-icon>
						</button>
						<button mat-flat-button color="primary" class="ml-1" (click)="setNotificationEmails()"><span>Save</span></button>
					</div>
				</div>
			</ng-container>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>

<div *ngIf="mode == 'display'" class="row mb-2">
	<div class="col-md-10 offset-md-1 text-right">
		<button mat-flat-button 
			color="warn" 
			class="mr-1 mb-1" 
			(click)="clear()">
			
			<span>Back</span>
		</button>
		<button mat-flat-button 
			color="primary" 
			class="mr-1 mb-1" 
			(click)="save()" 
			*ngIf="canSave()">
			
			<span>Save</span>
		</button>
		<button mat-flat-button 
			color="primary" 
			class="mr-1 mb-1" 
			(click)="activate()" 
			*ngIf="canActivate()">
			
			<span>Activate</span>
		</button>
		<button mat-flat-button 
			color="warn" 
			class="mr-1 mb-1" 
			(click)="deactivate()" 
			*ngIf="canDeActivate()">
			
			<span>Deactivate</span>
		</button>
		<button mat-flat-button 
			color="accent" 
			class="mr-1 mb-1" 
			(click)="archive()" 
			*ngIf="canArchive()">
			
			<span>Archive</span>
		</button>
		<button mat-flat-button 
			color="primary" 
			class="mr-1 mb-1" 
			(click)="restore()" 
			*ngIf="canRestore()">
			
			<span>Restore</span>
		</button>
		<button mat-flat-button 
			color="primary" 
			class="mr-1 mb-1" 
			(click)="switchMode()" 
			*ngIf="canSwitchMode()">
			
			<span>Switch Mode</span>
		</button>
		<!-- Tools Dropdown -->
		<button *ngIf="isEditable()" 
			mat-flat-button 
			color="primary" 
			type="button" 
			[matMenuTriggerFor]="disclosureMenu">

			<mat-icon>settings</mat-icon>
		</button>
		<mat-menu #disclosureMenu="matMenu">
			<button mat-menu-item #flowBrowse>
				<lio-file-uploader [flowBrowseInput]="flowBrowseRef" [settings]="csvUploadSettings" (success)="importText($event)">
					<span>Import Text</span>
				</lio-file-uploader>
			</button>
			<button mat-menu-item (click)="exportText()">Export Text</button>
			<button mat-menu-item (click)="exportToJSON()">Export To JSON</button>
			<button mat-menu-item>
				<mat-slide-toggle (click)="$event.stopPropagation()" 
					[(ngModel)]="model.data.settings.openLinksInIframe">
					
					<span>Open Links in iFrame</span>
				</mat-slide-toggle>
			</button>
		</mat-menu>
	</div>
</div>
<lio-disclosure *ngIf="mode == 'display'" [model]="model.data"></lio-disclosure>
	
<div *ngIf="exportedData" class="row mt-3">
	<div class="col">
		<lio-card header-text="Exported Disclosure" header-class="pb-1">
			<div lio-card-body>{{exportedData}}</div>
		</lio-card>
	</div>
</div>