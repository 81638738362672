import { Component, Inject, Input } from '@angular/core';

import { debugService } from '../../../../services/debug.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { utilService } from '../../../../services/util.service';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-assign-roles',
	templateUrl: './assign-roles.component.html',
})
export class LioAssignRoles{
	@Input() public employees 			:Array<any> = [];
	@Input() public filteredEmployees	:Array<any> = [];
	@Input() public unmodifiedEmployees :Array<any> = [];
	@Input() public fields 				:Array<any> = [];
	@Input() public suffixButtons		:Array<any> = [];
	@Input() public pagination 			:any 		= null;

	private _allRoles					:Array<any> = [];
	public get allRoles()				:Array<any>{
		return this._allRoles;
	}
	@Input() public set allRoles(val:Array<any>){
		this._allRoles 			= val;
		this.roleField.options 	= val;
	}

	private _targetRole				:any 		= null;
	public get targetRole()			:any{
		return this._targetRole;
	}
	@Input() public set targetRole(val:any){
		this._targetRole = val;
	}

	public roleField:LioSearchableSelectField = new LioSearchableSelectField({
		model 				: 'id',
		options 			: [],
		orderBy				: 'roleID',
		optionValueField	: 'roleID',
	});

	constructor(
		@Inject(debugService) 		public debugService 	:debugService,
		@Inject(lmsService) 		public lmsService 		:lmsService,
		@Inject(lioModalService) 	public lioModalService 	:lioModalService,
		@Inject(utilService) 		public utilService 		:utilService,
	){
		this.debugService.register('assignRolesComponent', this);

		this.suffixButtons = [
			{
				name 		: 'Allow',
				id 			: 'am_results_role_allow',
				color 		: 'primary',
				callback 	: (employee) => { this.activateRole(employee); },
				visible 	: (employee) => {
					return this.targetRole.id != -1 && employee.roles.indexOf(this.targetRole.id) == -1;
				}
			},
			{
				name 		: 'DisAllow',
				id 			: 'am_results_role_disallow',
				color 		: 'warn',
				callback 	: (employee) => { this.deActivateRole(employee); },
				visible 	: (employee) => {
					return this.targetRole.id != -1 && employee.roles.indexOf(this.targetRole.id) > -1;
				}
			}
		];
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection){
		this.filteredEmployees = collection.filtered;
	}

	activateRole(employee){
		let changes = [{UID : employee['UID'], addRoles : [this.targetRole.id]}];

		this.lmsService.postAsync('editpermissions/modifyRoles', {changes : changes}, 
		'processing').then((result) => {
			this.handleResults(result);
		});
	}

	deActivateRole(employee){
		let changes = [{UID : employee['UID'], removeRoles : [this.targetRole.id]}];

		this.lmsService.postAsync('editpermissions/modifyRoles', {changes : changes},
		'processing').then((result) => {
			this.handleResults(result);
		});
	}

	handleResults(result){
		var token = result.properties.token;
		if(token){
			this.lmsService.getAsyncResult(token, (gresult) => {
				if (gresult.success) {
					this.unmodifiedEmployees = this.utilService.copy(this.employees);
					this.lioModalService.show('changeSuccess');
				} else {
					this.lioModalService.show('changeFail');
				}
			});
		} else {
			if (result.success) {
				this.unmodifiedEmployees = this.utilService.copy(this.employees);
				this.lioModalService.show('changeSuccess');
			} else {
				this.lioModalService.show('changeFail');
			}
		}
	}
}