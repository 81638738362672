import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { NEVER, Subscription, of } from 'rxjs';

import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { navService } from '../../services/nav.service';
import { lmsService } from '../../services/lms.service';
import { storageService } from '../../services/storage.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { debugService } from '../../services/debug.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';

@Component({
	selector: 'lio-view-disclosure',
	templateUrl: './view-disclosure.component.html'
})
export class ViewDisclosureComponent implements OnDestroy {
	public displayModel		:boolean 		= false;
	public model			:any			= {};

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(DOCUMENT)			private document		:Document,
		@Inject(stateService)		public	stateService	:stateService,
		@Inject(errorsService)		public	errorsService	:errorsService,
		@Inject(navService)			public	navService		:navService,
		@Inject(lmsService)			public	lmsService		:lmsService,
		@Inject(storageService)		public	storageService	:storageService,
		@Inject(lioModalService)	public	lioModalService	:lioModalService,
		@Inject(lioLogService)		public	lioLogService	:lioLogService,
		@Inject(debugService)		public	debugService	:debugService,
		@Inject(utilService)		public	utilService		:utilService,
		@Inject(feedbackService)	public	feedbackService	:feedbackService
	){
		this.debugService.register('viewDisclosure', this);
		this.navService.setActivePage('viewDisclosure');

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.get().then(() => {
					this.navService.displayPage();
				});
			})
		);
	
		this.subscriptions.add(
			this.errorsService.criticalErrorSubject.subscribe(() => { 
				this.setError(this.errorsService.lastResponse, 'Error From AJAX');
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Loads the disclosure specified in the 'disclosureID' URL param
	 */
	get() {
		let params = this.storageService.getInitialParams() || {};

		this.lioModalService.showLoading();
		if (params.disclosureID) {
			return this.lmsService.post('disclosure/get', {id : params.disclosureID, includeProgress: true}).then((result) => {
				this.load(result);
			});	
		}else if (params && params.certificationID) {
			return this.lmsService.post('disclosure/getByCertificationConfigID', {id : params.certificationID, includeProgress: true}).then((result) => {
				this.load(result);
			});
		} else {
			this.lioModalService.showCriticalError('disclosure.noDisclosureID');
			return of(false);
		}
	}

	load(result){
		if (result.properties.disclosure) {
			this.model 				= result.properties.disclosure;
			this.model.sections 		= JSON.parse(this.model.sections);
			this.model.settings 		= JSON.parse(this.model.settings);
			this.model.progress 		= result.properties.progress;

			if(!this.model.settings) {
				this.model.settings = {openLinksInIframe : true};
			}

			this.model.settings.mode 	= 'read';
			this.displayModel 		= true;
		} else {
			this.setError(result, 'Failed To Get Disclosure');
		}
		this.lioModalService.hideLoading();
	}

	/**
	 * Handles an error
	 */
	setError(result, detail) {
		if (this.document.defaultView.parent && this.document.defaultView.parent != this.document.defaultView.window) {
			if (!result) { 
				result = {};
			}

			if (!detail) { 
				detail = '';
			}
			if (result.errors && result.errors.length) {
				detail += '\nError: ' + result.errors[0];
			}	

			if (result.criticalErrors && result.criticalErrors.length) {
				detail += '\nError: ' + result.criticalErrors[0];
			}		

			if (result.properties && result.properties.criticalErrorID) {
				detail += '\nCritical Error ID: ' + result.properties.criticalErrorID;
			}

			detail += '\n:RAW RESULT: ' + JSON.stringify(result);

			this.lioLogService.log(['Handle Error: ' + detail, result]);
			this.document.defaultView.parent.postMessage({'msg': 'ERROR', 'detail': detail}, '*');
		}
	}
}