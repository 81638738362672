import { HttpHandler, HttpInterceptor, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lioLogService } from './lio-log.service';
import { errorsService } from './errors.service';
import { configSettings } from '../settings/config.settings';
import { navService } from './nav.service';
import { DOCUMENT } from '@angular/common';
import { storageService } from './storage.service';
import { utilService } 		from './util.service';
import { processingService } from './processing.service';
import { debugService } from './debug.service';
import { map } from 'rxjs/operators';


@Injectable()
export class interceptService implements HttpInterceptor {

	constructor(
		@Inject(configSettings)    	private configSettings		:configSettings,
		@Inject(lioLogService)    	private lioLogService		:lioLogService,
		@Inject(DOCUMENT)			private document			:Document,
		@Inject(storageService)		private storageService		:storageService,
		@Inject(errorsService) 		private errorsService		:errorsService,
		@Inject(processingService)	private processingService	:processingService,
		@Inject(utilService)		private utilService 		:utilService,
		@Inject(debugService)		private debugService 		:debugService,
		@Inject(navService)			private navService			:navService
	) {
		this.debugService.register('intercept', this);
	}
	

	/*
	 * Intercepts backend requests/responses
	*/
	intercept(req,next: HttpHandler) {
		let task = req.url.split('?task=')[1];

		if (this.configSettings.logAjax) {
			this.lioLogService.log(['Request: ' + task, req]);
		}
		
		// Clonr the the request to make modifications
		const authReq = req.clone({
			// Add version to the request to help the backend determine if the version changed
			url: req.url + '&v=' + this.configSettings.version,
			// Prevent caching in IE, in particular IE11.
			// See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
			setHeaders: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache'
			}

		});

		return next.handle(authReq).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				if (this.configSettings.logAjax) {
					this.lioLogService.log(['Response: ' + task, event.body]);
				}
				event = event.clone({body: this.handleResponse(event.body)});
				this.removeTask(req);
			}
			return event;
		}));
	}

	/*
	 * Handle the response
	*/
	handleResponse(response: any) {
		if (!response || !response.properties) {
			return response;
		}

		// Handle access 
		response = this.handleAccess(response);

		if (!response) {
			return;
		}

		return response;
	}


	/*
	 * Handle access/move
	*/
	handleAccess(response: any) {
		if (response.properties.refreshme) {
			if (!this.storageService.get('refreshed')) {
				this.storageService.set('refreshed', 1);
				this.document.defaultView.location.href = this.document.defaultView.location.origin;
				return null;
			}
		} else {
			this.storageService.remove('refreshed');
		}


		if (response.goto) {
			if (this.utilService.inString('http', response.goto)) {
				this.errorsService.errorOccurred = true;
				this.document.location = response.goto;
			} else {
				if (response.goto == '405') {
					this.errorsService.errorOccurred = true;
					this.errorsService.setErrorCode(405);
				}
				this.storageService.set('permissionResults', response.properties.permissionResults);
				this.navService.goto(response.goto);
			}
			return;
		}

		if (response.info && response.info.length) {
			this.lioLogService.log(['Info from: ' + response.properties.task, response.info]);
		}

		if (response.properties.accessDenied) {
			this.errorsService.errorOccurred = true;
			this.errorsService.loggedOffError = true;
			if (this.storageService.get('loggedIn')) {
				this.navService.goto('loggedoff');
				this.navService.disableGoto = true;
				setTimeout(() => {
					this.navService.disableGoto = false;
				}, 1000);
			} else {
				this.navService.goto('login');
			}
			return null;
		}

		return response;
	}

	/*
	 * Remove the current task
	*/
	removeTask(request) {
		if (!request.url) {
			return;
		}
		this.processingService.removeRequest(request);
	}
}
