import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: delegateAccessSettings
})
export class delegateAccessSettings {
	delegatePanelConfig:any = {
		id				: 'panelTab_delegate_access',
		title			: 'Delegate Access',
		titleTrans		: 'delegateAccess.panelTitle',
		subtitle		: 'Search an employee below to add or remove delegations',
		subtitleTrans	: 'delegateAccess.panelSubtitle',
		tab				: 'Delegate Access',
		tabTrans		: 'delegateAccess.panelTab',
		pagination		: {
			id				: 'delegateAccessPanelConfig',
			currentPage		: 1,
			offset			: 0,
			pageLimit		: '10',
			pageLimits		: ['10', '50', '100'],
			sortMode		: {field : 'lastName', direction : 'asc'}
		}
	};

	existingdelegationsPanelConfig:any = {
		id				: 'panelTab_existing_delegations',
		title			: 'Existing Delegations',
		titleTrans		: 'delegationsExisting.panelTitle',
		subtitle		: 'The following people are authorized to act on your behalf',
		subtitleTrans	: 'delegationsExisting.panelSubtitle',
		tab				: 'Existing Delegations',
		tabTrans		: 'delegationsExisting.panelTab',
		bodyClass 		: 'card-body p-0',
		pagination		: {
			id				: 'existingDelegationsPanelConfig',
			currentPage		: 1,
			offset			: 0,
			pageLimit		: '10',
			pageLimits		: ['10', '50', '100'],
			sortMode		: {field : 'lastName', direction : 'asc'}
		}
	};

	settings:any = {
		removeSelf		: true
	};

	fieldConfig:any = {
		'fieldName'			: 'delegateAccess',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'addActiveField'	: true,
		'overrideFields'	: true,
		'addLangField'		: false,
		'ccfFields': {
			'locked': 'employee.updateCCF'
		},
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name: 'Employee ID',
			model: 'employeeID',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'First Name',
			model: 'firstName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'Address',
			model: 'address1',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Address 2',
			model: 'address2',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'City',
			model: 'city',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'State',
			model: 'state',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Country',
			model: 'country',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Phone #',
			model: 'phone',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Date Modified',
			model: 'updated',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Admin ID',
			model: 'AdminID',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Added By',
			model: 'addedBy',
			type	: 'text',
			visible	: false,
		}
	);
}

