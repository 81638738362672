import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { debugService } from '../../../../../services/debug.service';
import { localizationService } from '../../../../../services/localization.service';
import { catalogService } from '../../../../../services/catalog.service';

import { LioSearchableSelectField } from '../../../lio-forms.models';

@Component({
	selector: 'lio-catalog-topics',
	templateUrl: './topics.component.html',
})
export class LioCatalogTopics {
	public selectionFilterCtrl		:FormControl			= new FormControl();
	@Input() appearance?	:any = 'fill';
	@Input() model:any;
	@Input() update:any;
	@Input() field:any;
	@Output() onUpdate:EventEmitter<any> = new EventEmitter();


	public topics:Array<any> = [];
	public topic:any = {};
	public loading:boolean = false;
	public showNewTopicInput:boolean = true;

	public localeStrings = {
		addTopic		: 'Add topic',
		addTopicTrans	: 'catalogadmin.addTopic'
	};

	public topicField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'am_catalogadmin_topics',
		name			: 'Topics',
		nameTrans		: 'form.topics',
		model			: 'topicCodeIDs',
		optionValueField: 'topicCodeID',
		addEmptyOption	: false,
		searchable 		: true
	});

	constructor(
		@Inject(debugService) public debugService :debugService,
		@Inject(catalogService) public catalogService :catalogService,
		@Inject(localizationService) public localizationService :localizationService
	) {
		this.debugService.register('topics', this);

		this.catalogService.topics.subscribe((topics) => {
			this.topics = topics;
		})
	}



	/*
	 * Add new topic
	*/
	addTopic() {
		this.loading = true;
		this.catalogService.addTopic(this.topic).then((result) => {
			let topic = result.properties.topic,
				exists = result.properties.exists;
			if (topic) {
				if (!exists) {
					this.topics.push(topic);
					this.model.topics.push(topic);
				}
				this.model.topicCodeIDs.push(topic.topicCodeID);
				this.topic = {};
				this.loading = false;
			}
		});
	}

}