/*
 * Service for Controlling pop up windows and frames
*/
import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class settingsService {
	customSettings 		: Array<any>;
	settings 			: any;
	isSSO				: boolean;
	allowChangePassword : boolean;
	allowEditProfile 	: boolean;
	supressLangs 		: boolean;
	suppressCCFValues 	: boolean;
	hideLangMenu 		: boolean;
	launchMethod		: any;
	useCourseDimensions	: any;

	constructor(
		@Inject(lioLogService)	private lioLogService	: lioLogService,
		@Inject(utilService)	private utilService		: utilService,
		@Inject(debugService)	private debugService	: debugService,

	){
		this.debugService.register('settings', this);
		this.customSettings			= [];
		this.settings				= {};
		this.isSSO					= false;
		this.allowChangePassword 	= false;
		this.allowEditProfile 		= false;
		this.reset();
	}

	/*
	 * Resets all the settings
	*/
	reset() {
		this.customSettings.forEach((setting) => {
			delete this[setting];
		}, this);
		this.customSettings = [];
		this.settings 		= [];
	}

	/*
	 * Retrieved the settings
	 * @param {array} settings
	*/
	initSettings(settings) {
		this.reset();
		this.settings = settings;
	}

	/*
	 * Determines if a setting is set and gets the value
	 * @param {string} setting
	 * @return {*|boolean}
	*/
	getSetting(setting) {
		if (setting == 'bypass') {
			return true;
		}
		if (this.utilService.isDefined(this.settings[setting])) {
			return this.settings[setting];
		}

		return false;
	}

	/*
	 * Applies the settings
	*/
	apply() {
		let keys = Object.keys(this.settings);
		keys.forEach((key) => {
			if (key.indexOf('clientCustomField') === -1) {
				this[key] = this.settings[key];
				this.customSettings.push(key);
			}
		}, this);

		this.setCCFSettings(this.settings);
	}

	/*
	 * Sets the restrictions based on company settings
	 * @param {array} permissions
	*/
	setCompanyRestrictions(permissions) {
		if (!permissions) {
			this.lioLogService.log('Missing Company Permissions');
			return;
		}
		this.isSSO 					= permissions.allowSpiderSSO === 1;
		this.allowChangePassword 	= permissions.allowChangePassword === 1;
		this.allowEditProfile 		= permissions.editprofile === 1;
		this.customSettings.push('allowEditProfile');
		this.customSettings.push('allowChangePassword');
		this.customSettings.push('allowSpiderSSO');
	}

	/*
	 * Sets the CCF settings
	 * @param {array} settings
	*/
	setCCFSettings(settings) {
		let i				= 1,
			type			= '',
			settingType		= '',
			allowedTypes	= ['default', 'text', 'date', 'select', 'password', 'email', 'textarea', 'checkbox', 'number', 'boolean'],
			setting			= null;

		for (i = 1; i <= 30; i++) {
			type = 'default';
			setting = 'clientCustomField' + i + 'Type';
			if (Object.prototype.hasOwnProperty.call(settings, setting) && settings[setting]) {
				settingType = settings[setting];
				if (allowedTypes.indexOf(settingType) >= 0) {
					type = settingType;
				}
			}
			setting = 'clientCustomField' + i + '.type'; 
			this[setting] = type;
			this.customSettings.push(setting);
		}
	}
}