<lio-panel-tabber (onTabChange)="onTabChange()" localize [model]="localeStrings" [items]="localeStringsKeys">
	<!-- Filters -->
	<lio-panel-tab [settings]="selectByFilterPanelConfig">
		<div *lioPanelTabContent class="card-body">
			<div class="h6 font-weight-bold mx-1">{{localeStrings.filters}}</div>
			<lio-query-tool 
				[limitHeaders]="true"
				[filters]="filters" 
				[controlSettings]="queryToolSettings" 
				[controlObject]="queryTool" 
				(results)="handleResponse($event)">
			</lio-query-tool>
		</div>
	</lio-panel-tab>
	<!-- File -->
	<lio-panel-tab [settings]="selectByFilePanelConfig">
		<form *lioPanelTabContent name="form" role="form" autocomplete="off" novalidate>
			<div flow-drop class="border-dotted rounded mb-2 p-2">
				<div>{{localeStrings.dragExcel}}</div>
				<button mat-button color="primary" (click)="getTemplate()" class="ml-2" type="submit">
					<span class="font-weight-bold">{{localeStrings.getTemplate}}</span>
				</button>
				<span>|</span>
				<button mat-button color="primary" (click)="getSample()" type="submit">
					<span class="font-weight-bold">{{localeStrings.getSample}}</span>
				</button>

				<div class="form-check">
					<div class="alert alert-dismissible" [ngClass]="{'alert-info':model.processType == 'DELTA', 'alert-success':model.processType  == 'FULL_FILE', 'alert-danger':model.processType  == ''}">
						<lio-searchable-select
							[model]="model"
							[options]="processTypes"
							[field]="proccessTypeField"
							(onUpdate)="handleProcessType()"
							appearance="outline">
						</lio-searchable-select>

						<span *ngIf="model.processType == 'ENROLL'">{{localeStrings.enroll}}</span>
						<span *ngIf="model.processType == 'UNENROLL'">
							<lio-searchable-select
								[model]="model"
								[options]="courses"
								[field]="unenrollCourseField"
								appearance="outline">
							</lio-searchable-select>
							<span>{{localeStrings.unenrollNote}}</span>
						</span>

						<span *ngIf="model.processType == 'UPDATE'">						
							<lio-searchable-select
								[model]="model"
								[options]="courses"
								[field]="updateCourseField"
								appearance="outline">
							</lio-searchable-select>
							<span>{{localeStrings.updateNote}}</span>
						</span>

						<span *ngIf="model.processType == 'COMPLETE'">
							<lio-searchable-select
								[model]="model"
								[options]="courses"
								[field]="completeCourseField"
								appearance="outline">
							</lio-searchable-select>
						</span>
					</div>
				</div>
			</div>
			<div>
				<lio-file-uploader 
					[matchBootstrap]="false" 
					[settings]="uploadSettings" 
					(success)="fileAdded($event)"
					[disabled]="model.processType != 'ENROLL' && !model.courseID" 
					>
				</lio-file-uploader>
				<button id="am_enrollquery_process" 
					mat-flat-button
					color="primary"
					[disabled]="!readyForProcess || model.processType != 'ENROLL' && !model.courseID" 
					(click)="processFile()" 
					class="ml-2 mb-2" 
					type="submit">

					<span>{{localeStrings.process}}</span>
				</button>
			</div>
		</form>
	</lio-panel-tab>
</lio-panel-tabber>