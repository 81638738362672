import { Component, Inject, Input } from '@angular/core';

import { debugService } from '../../../../services/debug.service';

@Component({
	selector: 'lio-batch-history',
	templateUrl: './batch-history.component.html',
})
export class LioBatchHistory {
	@Input() settings			:any 		= {};
	@Input() history			:any 		= {};
	public filteredHistory		:any		= [];
	public searchHistory		:any		= {};

	constructor(
		@Inject(debugService) public debugService :debugService
	){
		this.debugService.register('batchHistory', this);
	}

	updateFilteredHistory(collection) {
		this.filteredHistory = collection.filtered;
	}

}