<!-- Notes -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Notes</mat-card-title>
	<mat-card-content id="am_form_notes" [style.fontSize.px]="11" class="mb-0" *ngFor="let note of model.notes; let i = index">
		<div class="row no-gutters">
			<div class="col-11" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<div class="row">
					<mat-form-field class="col-md-10" [appearance]="appearance">
						<input id="am_form_notes_{{i}}" 
							matInput
							placeholder="Add note" 
							[(ngModel)]="model.notes[i].note"
							(change)="onUpdate.emit($event)" 
							type="text" 
							maxlength="{{field.max || 100}}"/>
					</mat-form-field>
					<div class="col-md-1">
						<button mat-button color="warn" (click)="removeNote(i)" class="fa fa-minus-square fa-2x"></button>
					</div>
				</div>
			</div>
		</div>
	</mat-card-content>
	<mat-card-actions>
		<button mat-button color="primary" (click)="addNote()">
			<span class="fa fa-plus fa-lg mr-1"></span>
			<span>Add Note</span>
		</button>
	</mat-card-actions>
</mat-card>