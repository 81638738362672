<!-- NO LANGUAGES -->
<div class="row" 
	*ngIf="(!localizationService.shouldShowLanguageModal || settingsService.supressLangs || !required)
	&& !showSuccess" 
	localize 
	[model]="localeStrings" 
	[items]="localeStringsKeys">

	<div class="col-md-6 mx-auto">
		<!-- Password Reset Form -->
		<lio-card>
			<div lio-card-header>
				<button mat-button *ngIf="!required" (click)="navService.goback()" class="float-right">
					<mat-icon class="position-absolute">close</mat-icon>
				</button>
				<h4 id="am_changepass_optionalPassTitle" *ngIf="!required" class="col-11">{{localeStrings.optionalTitle}}</h4>
				<h4 id="am_changepass_requiredPassTitle" *ngIf="required">{{localeStrings.requiredTitle}}</h4>
				<div id="am_changepass_passwordValidationMessage">{{localeStrings.passwordValidationMessage}}</div>
			</div>
			<div lio-card-body>
				<form role="form">
					<lio-form-inputs-vertical
						[fields]="fields"
						[model]="employee"
						(onChanged)="onUpdate()">
					</lio-form-inputs-vertical>
				</form>
			</div>
			<div lio-card-footer>
				<button *ngIf="!required" 
					id="am_changepass_submit"  
					mat-flat-button
					color="primary"
					[disabled]="!allowSubmit" 
					(click)="submit()" 
					type="submit">
					
					<strong>{{localeStrings.submit}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
</div>

<!-- WITH LANGUAGES -->
<div *ngIf="localizationService.shouldShowLanguageModal 
	&& !settingsService.supressLangs 
	&& required 
	&& !showSuccess">

	<div class="row">
		<div class="col-12 col-md-6 mx-auto">
			<!-- Password Reset Form -->
			<lio-card>
				<div lio-card-header>
					<h4 id="am_changepass_requiredPassTitle" *ngIf="required">{{localeStrings.requiredTitle}}</h4>
					<p id="am_changepass_passwordValidationMessage">{{localeStrings.passwordValidationMessage}}</p>
				</div>
				<div lio-card-body>
					<h4 id="am_language_select_label">{{localeStrings.updateLang}}</h4>
					<span id="am_language_select_currentlang_label">{{localeStrings.currentLang}}</span>					
					
					<lio-searchable-select
						[model]="selectedLang"
						[options]="localizationService.availableLanguages"
						[field]="langIDField"
						(change)="selectLanguage()">
					</lio-searchable-select>
					<hr/>
					<form role="form">
						<lio-form-inputs-vertical
							[fields]="fields"
							[model]="employee"
							(onChanged)="onUpdate()">
						</lio-form-inputs-vertical>
						<button *ngIf="!required" 
							id="am_changepass_submit"  
							mat-flat-button
							color="primary"
							[disabled]="!allowSubmit" 
							(click)="submit()"
							type="submit">
							
							<strong>{{localeStrings.submit}}</strong>
						</button>
					</form>
				</div>
				<div lio-card-footer *ngIf="required">
					<button id="am_changepass_submit"  
						mat-flat-button
						color="primary"
						[disabled]="!allowSubmit" 
						(click)="submit()" 
						type="submit">
						
						<strong>{{localeStrings.save}}</strong>
					</button>
				</div>
			</lio-card>
		</div>
	</div>
</div>

<!-- SUCCESSFUL CHANGE -->
<div class="row" *ngIf="showSuccess">
	<div class="col-md-6 mx-auto">
		<lio-card headerText="Your profile was successfully updated" headerTrans="profile.successfullyChanged" [hideErrors]="true">
			<div lio-card-body>
				<button id="am_changepass_gohome" 
					mat-flat-button
					color="primary"
					(click)="navService.goHome()" 
					type="submit">
					
					<strong>{{localeStrings.goToHomePage}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
</div>