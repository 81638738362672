<!-- tables -->
<div id="am_form_table" 
	class="row no-gutters" 
	[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
	<mat-form-field class="col-6" appearance="fill">
		<input id="am_form_table_input_1" 
			matInput
			placeholder="Table Name" 
			[(ngModel)]="model.tableName" 
			type="text"
			(change)="onUpdate.emit()" 
			maxlength="{{field.max || 100}}"/>
	</mat-form-field>

	<mat-form-field class="col-6" appearance="fill">
		<input id="am_form_table_input_2" 
			matInput
			placeholder="Table alias" 
			[(ngModel)]="model.tableAlias" 
			type="text"
			(change)="onUpdate.emit()" 
			maxlength="{{field.max || 100}}"/>
	</mat-form-field>
</div>