import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { enrollmentEventService } from '../../services/enrollment-event.service';
import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { utilService } from '../../services/util.service';

import { scheduleEnrollmentSettings } from './schedule-enrollment.settings';
import { scheduleEnrollmentFilterSettings } from './schedule-enrollment-filter.settings';
import { scheduleEnrollmentEventSettings } from './schedule-enrollment-event.settings';
import { scheduleEnrollmentEventHistorySettings } from '../../settings/schedule-enrollment-event-history.settings';
import { scheduleEnrollmentEventListSettings } from './schedule-enrollment-event-list.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
	selector: 'lio-schedule-enrollments',
	templateUrl: './schedule-enrollments.component.html'
})
export class ScheduleEnrollmentsComponent implements OnDestroy {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache	:PanelTabCache 	= null;

	public panels			:any			= this.utilService.copy(this.scheduleEnrollmentSettings.panels);

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService)							public	loaderService							:loaderService,
		@Inject(enrollmentEventService)					public	enrollmentEventService					:enrollmentEventService,
		@Inject(navService)								public	navService								:navService,
		@Inject(debugService)							public	debugService							:debugService,
		@Inject(utilService)							public	utilService								:utilService,
		@Inject(scheduleEnrollmentSettings)				public	scheduleEnrollmentSettings				:scheduleEnrollmentSettings,
		@Inject(scheduleEnrollmentFilterSettings)		public	scheduleEnrollmentFilterSettings		:scheduleEnrollmentFilterSettings,
		@Inject(scheduleEnrollmentEventSettings)		public	scheduleEnrollmentEventSettings			:scheduleEnrollmentEventSettings,
		@Inject(scheduleEnrollmentEventHistorySettings)	public	scheduleEnrollmentEventHistorySettings	:scheduleEnrollmentEventHistorySettings,
		@Inject(scheduleEnrollmentEventListSettings)	public	scheduleEnrollmentEventListSettings		:scheduleEnrollmentEventListSettings
	){
		this.debugService.register('scheduleEnrollments', this);
		this.navService.setActivePage('scheduleEnrollments');

		this.subscriptions.add(
			this.enrollmentEventService.callback.subscribe((request) => {
				this.callback(request);
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Call back from one of the services
	*/
	callback(request) {
		if (request.name === 'changeTab') {
			this.changeTab(request);
		}
	}

	/*
	 * Changes the tab
	*/
	changeTab(request) {
		let panel = this.panels[request.value]; 
		if (panel) {
			setTimeout(() => {
				this.panelTabCache.selectTab(panel);
			});
		}
	}
}