export class CardButtonModel {
	public id 			:string 	= '';
	public color		:string 	= 'primary';
	public icon			:string 	= 'fa-exclamation-triangle';
	public title		:string 	= '';
	public titleTrans	:string 	= '';
	public subtitle		:string 	= '';
	public subtitleTrans:string 	= '';
	public class		:string 	= 'col-xl-3 col-lg-6 mb-3';
	public permission	:string 	= '';
	public onClick		:()=>any 	= null;

	public visible		:boolean 	= true;
	
	constructor(obj:Partial<CardButtonModel>){
		Object.assign(this, obj);
	}
}

export class CardButtonModelArray extends Array<CardButtonModel>{
	constructor(...args:(Partial<CardButtonModel>|CardButtonModel)[]){
		let elements:Array<CardButtonModel> = [];

		args.forEach((item) => {
			if(item instanceof CardButtonModel){
				elements.push(item);
			}else{
				elements.push(new CardButtonModel(item));
			}
		});

		super(...elements);
	}

	public hasVisible():boolean{
		let hasVisible = false;
		this.forEach((item:CardButtonModel) => {
			if(item.visible){
				hasVisible = true;
			}
		});

		return hasVisible;
	}
}