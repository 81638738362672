<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'description', 'close']">
	
	<!-- Close Button -->
	<button (click)="close()" [hidden]="!settings.canClose" class="button-hide modal-closer float-right">
		<i class="modal-close fa fa-times text-secondary"></i>
        <span class="sr-only">Close Modal</span>
	</button>

	<!-- Header -->
	<header class="modal-header">
		<h5 id="am_modal_title" class="modal-title mx-auto" [innerHtml]="settings.title | html"></h5>
	</header>
	
	<!-- Description -->
	<section
		id="am_modal_subtext"
		[hidden]="!settings.description"
		class="modal-content"
		[innerHtml]="settings.description | html">
	</section>

	<!-- Footer -->
	<footer class="modal-footer">
		<button [hidden]="!settings.close" class="mx-auto" mat-raised-button color="primary" id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>
