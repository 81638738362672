<div id="search-results" class="bg-learn card-header form-inline pt-3 pb-1 px-4" localize [model]="localeStrings" [items]="localeStringItems">
	<!-- Expand / Collapse -->
	<div *ngIf="canExpandCatalogs">
		<!-- Expand -->
		<button mat-flat-button 
			id="am_catalog_search_expand_all" 
			[disabled]="!catalogs.length" 
			class="text-primary rounded-pill mr-2 p-2" 
			(click)="expandAllCatalogs.emit()">
			<mat-icon>add_box</mat-icon>
			<span class="d-none d-lg-inline-block">Expand All</span>
		</button>
		<!-- Collapse -->
		<button mat-flat-button 
			id="am_catalog_search_collapse_all" 
			[disabled]="!catalogs.length" 
			class="text-primary rounded-pill p-2" 
			(click)="collapseAllCatalogs.emit()">
			<mat-icon>indeterminate_check_box</mat-icon>
			<span class="d-none d-lg-inline-block">Collapse All</span>
		</button>
	</div>

	<!-- Sort -->
	<lio-searchable-select 
		class="ml-4 d-none d-sm-inline-block"
		[model]="pagination.sortMode"
		[field]="sortField"
		[appearance]="appearance">
	</lio-searchable-select>
	<div class="ml-2 mb-2" *ngIf="pagination.sortMode.direction == 'desc'" (click)="pagination.sortMode.direction = 'asc'">
		<button mat-flat-button 
			id="am_catalog_search_sort_dir_desc" 
			class="p-2">
			<mat-icon>keyboard_arrow_down</mat-icon>
			<span class="d-none d-md-inline-block" >{{localeStrings.descending}}</span>
		</button>
	</div>
	<div class="ml-2 mb-2" *ngIf="pagination.sortMode.direction == 'asc'" (click)="pagination.sortMode.direction = 'desc'">
		<button mat-flat-button 
			id="am_catalog_search_sort_dir_asc" 
			class="p-2" 
			type="button">
			<mat-icon>keyboard_arrow_up</mat-icon>
			<span class="d-none d-md-inline-block" >{{localeStrings.ascending}}</span>
		</button>
	</div>
	<lio-searchable-select 
		class="ml-2 d-none d-sm-inline-block"
		[model]="pagination"
		[field]="pageLimitField"
		(onUpdate)="onupdate.emit()"
		[appearance]="appearance">
	</lio-searchable-select>
	<!-- Sort -->

	<!-- Print Buttons -->
	<div class="btn-group ml-auto" *ngIf="permissionService.hasPermission('catalog.print') && view.printable">
		<!-- Select Print -->
		<button mat-flat-button 
			id="am_catalog_search_print_select_all"
			[disabled]="!catalogs.length"
			class="text-primary rounded-pill m-2 p-2"
			(click)="checkPrintAll.emit()">
			<mat-icon>print</mat-icon>
			<span>{{localeStrings.all}}</span>
		</button><!-- Select Print -->
		<!-- Clear Print -->
		<button mat-flat-button 
			id="am_catalog_search_print_select_clear"
			[disabled]="!catalogs.length"
			class="text-primary rounded-pill m-2 p-2"
			(click)="clearPrint.emit()">
			<mat-icon>print</mat-icon>
			<span>{{localeStrings.clear}}</span>
		</button><!-- Clear Print -->
	</div>
</div>