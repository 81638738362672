import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { PanelTabData } from './panel-tab-data';

@Injectable()
export class PanelTabCache {
	public tabChange	:ReplaySubject<PanelTabData> 	= new ReplaySubject();
	private tabs		:Array<PanelTabData>			= [];
	private selectedTab	:PanelTabData 					= null;

	/**
	 * Add a new tab
	 * @param {PanelTabData} tab to add
	*/
	public addTab(tab:PanelTabData) {
		this.tabs.push(tab);
		this.tabChange.next(tab);
	}

	/**
	 * Gets all the tabs
	 * @return {Array<PanelTabData>}
	*/
	public getTabs():Array<PanelTabData> {
		return this.tabs;
	}

	/**
	 * Select a tab
	 * @param {PanelTabData} newTab to select
	 * @return {boolean} was a tab found
	*/
	public selectTab(newTab:PanelTabData):PanelTabData|null {
		let foundTab:PanelTabData 	= null;
		this.selectedTab 		= newTab;

		this.tabs.forEach((tab) => {
			tab.selected = tab.id === newTab.id;
			foundTab = tab;
		});

		return foundTab;
	}

	/**
	 * Clears the currently selected tab
	 */
	public clearSelectedTab(){
		this.selectedTab = null;
	}

	/**
	 * Select a tab by passing its ID
	 * @param {string} newTabID
	 * @return {PanelTabData} tab
	*/
	public selectTabById(newTabID:string):PanelTabData|null {
		let newTab = null;
		this.tabs.forEach((tab) => {
			if (tab.id == newTabID) {
				this.selectTab(tab);
				newTab = tab;
			}
		});
		return newTab;
	}

	/**
	 * Select a tab by passing its name
	 * @param {string} name
	 * @return {PanelTabData} tab
	*/
	public selectTabByName(name:string):PanelTabData|null {
		let newTab = this.getTabByName(name);
		if (newTab) {
			this.selectTab(newTab);
		}
		return newTab;
	}

	/**
	 * Gets the tab by its name
	 * @param {string} name
	 * @return {?PanelTabData}
	*/
	public getTabByName(name:string):PanelTabData|null {
		let newTab = null;
		this.tabs.forEach((tab) => {
			if (tab.tab == name) {
				newTab = tab;
			}
		});
		return newTab;
	}

	/**
	 * Gets the tab by its index
	 * @param {number} index 
	 * @return {?PanelTabData}
	*/
	public getTabByIndex(index:number):PanelTabData|null {
		if (this.tabs.length > index) {
			return this.tabs[index];
		}
		return null;
	}

	/**
	 * Select a tab by its index
	 * @param {number} index
	 * @return {PanelTabData}
	*/
	public selectTabByIndex(index:number):PanelTabData|null {
		if (this.tabs.length > index) {
			this.selectTab(this.tabs[index]);
		}
		return null;
	}

	/**
	 * Gets the selected tab
	 * @return {?PanelTabData} selected Tab or null
	*/
	public getSelectedTab():PanelTabData|null {
		return this.selectedTab;
	}

	/**
	 * Gets the currently selected tab ID
	 * @return {?string}
	*/
	public getSelectedTabID():string {
		if (this.selectedTab) {
			return this.selectedTab.id;
		}
		return '';
	}

	/*
	 * Get the name of the currently selected tab.
	 * @return {string}
	 */
	public getSelectedTabName():string {
		if (this.selectedTab) {
			return this.selectedTab.tab;
		}
		return '';
	}
}