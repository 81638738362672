import { Component, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { debugService } from '../../services/debug.service';
import { navService } from '../../services/nav.service';
import { loaderService } from '../../services/loader.service';
import { processingService } from '../../services/processing.service';
import { lmsService } from '../../services/lms.service';
import { coursesService } from '../../services/courses.service';
import { storageService } from '../../services/storage.service';
import { localizationService } from '../../services/localization.service';
import { lioModalService } from '../../services/lio-modal.service';
import { paginationRegistryService } from '../../services/pagination-registry.service';
import { fieldService } from '../../services/fields.service';
import { workerService } from '../../services/worker.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { lioLogService } from '../../services/lio-log.service';

import { emailSettings } from './email.settings';

@Component({
	selector: 'lio-email',
	templateUrl: './email.component.html'
})
export class EmailComponent implements OnDestroy {	
	public allowSend			:boolean 		= false;
	public allowTest			:boolean 		= false;
	public allowPreview			:boolean 		= true;
	public model				:{template:any} = {template:{}};
	public templates			:Array<any> 	= [];
	public employee				:any 			= {};
	public employees			:Array<any> 	= [];
	public filteredEmployees	:Array<any> 	= [];
	public fields				:Array<any>		= this.emailSettings.fields;
	public fieldConfig			:any 			= this.emailSettings.fieldConfig;
	public emailTemplateField	:any 			= this.emailSettings.emailTemplateField;
	public managing				:boolean		= false;
	public showTemplate			:boolean		= false;
	public header				:string			= '';
	public emailEditorPreview	:boolean 		= true;
	public emailPrefixButtons	:Array<any> 	= [
		{
			header 		: 'Send',
			id 			: 'am_email_send_prefix',
			color 		: 'primary',
			icon 		: 'email',
			callback 	: (employee) => { this.edit(employee); }
		}
	];

	public localeStrings:any = {
		manage				: 'email.manage',
		allEmailsSent		: 'email.allEmailsSent',
		allEmailsSentTrans	: 'email.allEmailsSent',
		sendToEmployees		: 'email.sendToEmployees',
		sendToEmployeesTrans: 'email.sendToEmployees',
		viewtemplate		: 'View template',
		viewtemplateTrans	: 'email.viewtemplate',
		edittemplate		: 'Edit template',
		edittemplateTrans	: 'email.edittemplate',
		preview				: 'Preview sample email',
		previewTrans		: 'email.preview',
		sendTest			: 'Send Test Email to myself',
		sendTestTrans		: 'email.sendTest',
		sendAll				: 'Send email to all users below',
		sendAllTrans		: 'email.sendAll',
	};
	public localeStringsKeys:Array<any> = [
		'viewtemplate',
		'edittemplate',
		'preview',
		'sendTest',
		'sendAll'
	];

	public localeStringMacros:Array<any> = [{
		item	: 'sendToEmployees',
		key		: 'total', 
		value	: () => { return this.employeeIDs.length; }
	}];


	public employeeIDs			:Array<any> 	= [];
	private renderedSubject		:string 		= '';
	private renderedMessage		:string 		= '';
	private isTest				:boolean		= false;
	private testSent			:boolean		= false;
	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
											private changeDetectorRef		:ChangeDetectorRef,
		@Inject(debugService)				public debugService				:debugService,
		@Inject(navService)					public navService				:navService,
		@Inject(loaderService)				public loaderService			:loaderService,
		@Inject(processingService)			public processingService		:processingService,
		@Inject(lmsService)					public lmsService				:lmsService,
		@Inject(coursesService)				public coursesService			:coursesService,
		@Inject(storageService)				public storageService			:storageService,
		@Inject(localizationService)		public localizationService		:localizationService,
		@Inject(lioModalService)			public lioModalService			:lioModalService,
		@Inject(paginationRegistryService)	public paginationRegistryService:paginationRegistryService,
		@Inject(fieldService)				public fieldService				:fieldService,
		@Inject(workerService)				public workerService			:workerService,
		@Inject(utilService)				public utilService				:utilService,
		@Inject(feedbackService)			public feedbackService			:feedbackService,
		@Inject(stateService)				public stateService				:stateService,
		@Inject(lioLogService)				public lioLogService			:lioLogService,
		@Inject(emailSettings)				public emailSettings			:emailSettings
	){
		this.debugService.register('email', this);
		this.navService.setActivePage('email');

		this.processingService.allowCancel = true;
		this.getTask();
	
		/*
		 * Init
		*/
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.getEmployees();
			})
		);
		
		this.subscriptions.add(
			this.navService.exit.pipe(first()).subscribe(() => { 
				this.onExit(); 
			})
		);
	
		this.subscriptions.add(
			this.coursesService.courses.subscribe(() => {
				this.getCourseByStoredID();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
 	 * Previews an email
	*/
	edit(employee) {
		if (!this.model.template.id) {
			this.lioModalService.show('email.selectTemplate');
			return;
		}
		let form = {
			model		: this.model.template,
			employeeIDs	: [employee.employeeID],
			courseIDs	: this.model.template.courseIDs,
			testing		: this.isTest,
			managing	: this.managing
		};

		this.lmsService.postAsync('emailtemplate/send', form, 'sendingEmail').then((result) => {
			let token = result.properties.token;
			if (!token) {
				this.handleSent(result);
			} else {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleSent(gresult);
				});
			}
		});
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection){
		this.filteredEmployees = collection.filtered;
	}

	/*
	 * Updates the template from the model editor
    */
	updateTemplate(template) {
		if (template) {
			this.model.template = template;
		}
		this.getCourseByStoredID();
		this.navService.changedForm = false;
	}

	/*
	 * Sets params based on current task
    */
	getTask() {
		let task = this.storageService.get('task');

		if (task === 'managing') {
			this.showTemplate = true;
			this.managing = true;
			this.allowTest = true;
			this.allowPreview = true;
			this.allowSend = false;
		} else {
			// Otherwise emailing 
			this.showTemplate = false;
			this.managing = false;
			this.allowTest = true;
			this.allowPreview = true;
			this.allowSend = true;
		}
	}

	/*
	 * toggels show template mode
	*/
	toggleShowTemplate() {
		this.showTemplate = !this.showTemplate;
		this.emailEditorPreview = false;
	}

	/*
	 * Gets the employes to email
	*/
	getEmployees() {
		let employees = this.storageService.get('employeesToEmail'),
			employeeIDs = this.storageService.get('employeeIDsToEmail'),
			employeeID = this.stateService.getEmployeeID();
		
		if (employees && employees.length) {
			this.employees = employees;
			this.employeeIDs = employeeIDs;
			return;
		}

		if (!employeeIDs) {
			if (!employeeID) {
				return;
			}
			employeeIDs = [employeeID];
		}

		this.loadEmployees(employeeIDs);
	}

	/*
	 * Loads the employees by id
	*/
	loadEmployees(employeeIDs) {
		this.lmsService.post('employee/getEmployeesByEmployeeIDs', {'employeeIDs': employeeIDs}).then((result) => {
			let employees = result.properties.employees;

			if (!employees) {
				if (!this.stateService.isAtHomeCompany()) {
					this.feedbackService.clearErrors();
					employees = [this.stateService.getSession()];
				} else {
					this.feedbackService.setError('noEmployeesFound');
					this.navService.displayPage();
					return;	
				}
			}

			this.employeeIDs = [];
			employees.forEach((employee) => {
				this.employeeIDs.push(employee.employeeID);
			});
			this.employees = employees;
		});
	}

	/*
 	 * On update handler
	*/
	onupdate() {
		this.feedbackService.clearErrors();
	}

	/*
 	 * Templates loaded
	*/
	templatesLoaded(templates) {
		this.templates = templates;
		this.navService.displayPage();
	}

	/*
	 * On Template Loaded
    */
	onTemplateLoad() {
		this.getCourseByStoredID();
		this.changeDetectorRef.detectChanges();
	}


	/*
 	 * Gets a random employee
	*/
	getRandomEmployee() {
		let employees 	= this.employees;
		let total 		= employees.length;
		let employee 	= employees[Math.floor(Math.random() * total)];
		return employee;
	}

	/*
 	 * Previews the email template
	*/
	previewTemplate() {
		this.renderedSubject = this.model.template.subject;
		this.renderedMessage = this.model.template.message;
		this.showEmail();
	}

	/*
 	 * Previews an email
	*/
	preview(employee = null) {
		let employeeID = null;
		
		if (employee) { 
			employeeID = employee.employeeID;
		} else if (this.employees.length > 0) {
			employeeID = this.employees[0].employeeID;
		} else {
			employeeID = this.employee.employeeID;
		}

		this.resetMessages();

		let form = {
			model		: this.model.template,
			courseIDs	: this.model.template.courseIDs,
			managing	: this.managing,
			employeeIDs	: [employeeID]
		};

		this.lioModalService.showLoading('renderingEmail');

		this.lmsService.post('emailtemplate/preview', form).then((result) => {
			let success = result.success,
				subject = result.properties.subject,
				message = result.properties.message,
				employee = result.properties.employee;

			this.lioModalService.hideLoading();

			if (success) {
				this.renderedSubject = subject;
				this.employee = employee;
				this.renderedMessage = message;
				this.showEmail();
			}
		});
	}

	/*
	 * Shows the email lioModal
	*/
	showEmail() {
		this.lioModalService.open({
			type	: 'email',
			subject	: this.renderedSubject,
			message	: this.renderedMessage,
			template: this.model.template,
			employee: this.employee || this.stateService.getSession(),
			width	: '70%',
		});
	}

	/*
	 * Sends an email
	*/
	sendEmail() {
		let form = {
			model		: this.model.template,
			employeeIDs	: this.employeeIDs,
			courseIDs	: this.model.template.courseIDs,
			testing		: this.isTest,
			managing	: this.managing
		};

		this.lmsService.postAsync('emailtemplate/send', form, 'sendingEmail').then((result) => {
			let token = result.properties.token;
			if (!token) {
				this.handleSent(result);
			} else {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleSent(gresult);
				});
			}
			
		});
	}

	/*
	 * Handles the result after the email was sent
	*/
	handleSent(result) {
		let errors 			= result.errors,
		totalSent 			= result.properties.totalSent,
		totalFailed 		= result.properties.totalFailed,
		failedEmails 		= result.properties.failedEmails,
		sentTo 				= result.properties.sentTo,
		excluded 			= result.properties.excluded,
		auditID 			= result.properties.auditID,
		sentToSelf 			= result.properties.sentToSelf, 
		macros 				= [],
		messages 			= [];


		if (totalSent) {
			macros = [];
			macros.push({'key': 'totalSentEmails', 'value': totalSent});
			messages.push(this.utilService.localizeMessage('sentSuccessfullyEmails', macros));
		}

		if (failedEmails) {
			macros = [];
			macros.push({'key': 'failedOnEmail', 'value': failedEmails});
			messages.push(this.utilService.localizeMessage('failedToSendEmailTo', macros));
		}

		if (totalFailed) {
			macros = [];
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			macros.push({'key': 'auditID', 'value': auditID});
			messages.push(this.utilService.localizeMessage('totalEmailUnexpectedIssues', macros));
		}

		if (excluded && excluded.length) {
			macros = [];
			macros.push({'key': 'totalExluded', 'value': excluded.length});
			messages.push(this.utilService.localizeMessage('totalExcludedUsers', macros));
		}

		if (sentToSelf && totalSent == 1) {
			messages = [];
			messages.push(this.utilService.localizeMessage('sentToSelf', []));
		}


		this.feedbackService.setErrors(errors);
		this.feedbackService.setMessages(messages);

		if (!this.isTest && sentTo) {
			this.updateSentList(sentTo).then(() => {
				this.lioModalService.hide();
			});
		} else {
			this.lioModalService.hide();
		}

		this.isTest = false;
		this.paginationRegistryService.getPaginator('emailtemplates').refresh();
	}

	/*
	 * Removes the employees from the list
	*/
	updateSentList(employeeIDs) {
		let setter = {
			processedIDs: employeeIDs,
			employees	: this.employees, 
			key			: 'employeeID',
			setter		: {'key': 'sentEmail', 'value': true}
		};

		return this.workerService.call('findAndSet', setter).then((response:any) => {
			if (!response) {
				this.lioLogService.log('Failed to find and set');
				return;
			}
			this.employees = response.employees;
			this.employeeIDs = response.remainingIDs;
		});
	}

	/*
	 * Sends a test email
	*/
	sendTest() {
		let currentEmployees = this.employeeIDs;
		this.isTest = true;
	
		this.employeeIDs = [this.stateService.getEmployeeID()];

		if (this.send()) {
			this.testSent = true;
		}

		if (currentEmployees) {
			this.employeeIDs = currentEmployees;
		}
	}

	/*
	 * The user clicked the send email 
	*/
	userSendEmail() {
		this.lioModalService.hide();

		if (!this.employeeIDs.length) {
			this.lioModalService.show('noEmployeesLeftToEmail');
			return;
		}

		this.navService.changedForm = true;
		if (!this.testSent) {
			this.lioModalService.confirm('areYouSureTitle', 'recommendTestEmail').then((confirmed) => {
				if(confirmed){
					this.confirmedToSend();
				}
			});
		} else {
			this.lioModalService.confirm('areYouSureTitle', 'areYouSureSendAll').then((confirmed) => {
				if(confirmed){
					this.confirmedToSend();
				}
			});
		}
	}

	/*
	 * The user clicked the send email with changes in the form
	*/
	unsavedChanges() {
		if (this.navService.changedForm) {
			this.navService.changedForm = true;
			this.lioModalService.confirm('areYouSureTitle', 'areYouSureSendUnsavedEmails').then(() => {
				this.sendEmail();
			});
			return true;
		}
		this.lioModalService.hide();
		return false;
	}

	/*
	 * User ignored saving lioModal
	*/
	ignoredSave() {	
		this.navService.changedForm = false;
		this.send();
	}

	/*
	 * Confirms to send the email
	*/
	confirmedToSend() {
		this.navService.changedForm = false;
		this.send();
	}

	/*
	 * Sends the email
	*/
	send() {
		if (this.unsavedChanges()) {
			return;
		}

		this.sendEmail();

		return true;
	}

	/*
	 * Resets the messages
	*/
	resetMessages() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.onupdate();
	}
	
	/*
	 * On exit of this screen, remove stored variables
	*/
	onExit() {
		this.storageService.remove('courseID');
		this.storageService.remove('employeesToEmail');
		this.storageService.remove('employeeIDsToEmail');
	}

	/*
	 * Gets the course from the stored courseID
	*/
	getCourseByStoredID() {
		if (this.managing) {
			// Dont get the stored courseID when managing templates 
			return;
		}
		let courseID = this.storageService.get('courseID');
		if (!courseID) {
			return null;
		}
		this.model.template.courseIDs = [courseID.toString()];
		return this.model.template.courseIDs;
	}
}