<section class="feedback" id="feedback">
	<div [hidden]="!errors" *ngFor="let error of $any(errors | slice:0:10)">
		<div id="am_error_container" class="alert bg-danger my-1">
			<div id="am_error_with_key" 
				*ngIf="error.key" 
				localize 
				[model]="error" 
				item="value" 
				[macros]="error.macros" 
				[innerHtml]="error.value | html">
			</div>
			<div id="am_error_with_key" 
				*ngIf="!error.key" 
				[innerHtml]="error | html">
			</div>
		</div>
	</div>

	<div [hidden]="!messages" *ngFor="let message of $any(messages | slice:0:10)">
		<div id="am_message_container" class="alert alert-warning my-1">
			<h5 id="am_msg" 
				*ngIf="message.key" 
				localize 
				[model]="message" 
				item="value" 
				[macros]="message.macros" 
				[innerHtml]="message.value | html">
			</h5>
			<h5 id="am_msg" 
				*ngIf="!message.key" 
				[innerHtml]="message | html">
			</h5>
		</div>
	</div>

	<div [hidden]="!showSQL">
		<div [innerHtml]="task | html"></div>
		<div [innerHtml]="sql | html"></div>
	</div>
</section>