<div class="row">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber>
			<lio-panel-tab [settings]="panelConfig.select"></lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.edit">
				<form *lioPanelTabContent role="form" autocomplete="off" novalidate>
					<lio-form-inputs
						[fields]="panelConfig.edit.fields"
						[fieldConfig]="panelConfig.edit.fieldConfig"
						[model]="report"
						(onChanged)="onUpdate($event)">
					</lio-form-inputs>
					<div class="btn-group mb-1 ml-1" >
						<lio-buttons (onClick)="handleClick($event)" [buttons]="panelConfig.select.buttons" [model]="report"></lio-buttons>
					</div>
					<lio-query-tool
						*ngIf="enableQueryTool"
						[hidden]="showQueryTool"
						[filters]="filters" 
						[controlSettings]="reportType.queryToolSettings" 
						[controlObject]="querytool"
						(onRun)="onRun($event)"
						(results)="handleResponse($event)"
						(loaded)="queriesLoaded()">
					</lio-query-tool>
				</form>
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.create"></lio-panel-tab>
		</lio-panel-tabber> 
	</div>
</div>