import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { processingService } from '../../services/processing.service';
import { navService } from '../../services/nav.service';
import { errorsService } from '../../services/errors.service';
import { permissionService } from '../../services/permissions.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { workerService } from '../../services/worker.service';
import { stateService } from '../../services/state.service';
import { feedbackService } from '../../services/feedback.service';
import { utilService } from '../../services/util.service';
import { debugService } from '../../services/debug.service';

import { globalSearchSettings } from './global-search.settings';

@Component({
	selector: 'lio-global-search',
	templateUrl: './global-search.component.html'
})
export class GlobalSearchComponent implements OnDestroy {
	public employees					:Array<any>		= [];
	public filteredEmployees			:Array<any> 	= [];
	public searches						:Array<any>		= [];
	public fields						:Array<any>		= this.utilService.copy(this.globalSearchSettings.fields);
	public fieldConfig					:any 			= this.utilService.copy(this.globalSearchSettings.fieldConfig);
	public searchConfig					:any 			= this.utilService.copy(this.globalSearchSettings.searchConfig);
	public pagination					:any 			= this.globalSearchSettings.pagination;
	public filters						:Array<any>		= this.utilService.copy(this.globalSearchSettings.filters);
	public queryToolSettings			:any 			= this.globalSearchSettings.queryToolSettings; 
	public search						:any			= {};

	public globalsearchSuffixButtons	:Array<any> 	= [
		{
			id 			: 'am_globalsearch_profile_suffix',
			color 		: 'primary',
			icon 		: 'arrow_right',
			callback 	: (employee) => { this.edit(employee); }
		}
	];

	public localeStrings				:any 			= {
		filters				:'Filters',
		filtersTrans		:'globalsearch.filters',
		editQuery			:'Edit Query',
		editQueryTrans		:'global.editquery',
		exportRecords		:'Export Records',
		exportRecordsTrans	:'global.exportrecords',
	};
	public localeStringsKeys			:Array<any>		= [
		'filters',
		'editquery',
		'exportrecords'
	];

	private collection					:Array<any>		= [];

	private subscriptions				:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(processingService)		public	processingService	:processingService,
		@Inject(navService)				public	navService			:navService,
		@Inject(errorsService)			public	errorsService		:errorsService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(workerService)			public	workerService		:workerService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(globalSearchSettings)	public	globalSearchSettings:globalSearchSettings
	){
		this.debugService.register('globalsearch', this);
		this.navService.setActivePage('globalsearch');

		processingService.allowCancel = true;

		this.subscriptions.add(
			this.errorsService.timeout.subscribe(() => {
				this.reset();
				this.lioModalService.show('systemTookTooLong');
			})
		);

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.setFilters();
				this.navService.displayPage();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Resets vars on submit
	*/
	reset() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.employees 			= [];
		this.filteredEmployees 	= [];
		this.utilService.removeObjectProperties(this.search);
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(paginatorResults){
		this.collection 		= paginatorResults.collection;
		this.filteredEmployees 	= paginatorResults.filtered;
	}

	/*
	 * Preps the already loaded employees
	*/
	loadEmployees(results) {
		if (results) {
			this.employees = results.properties.employees;
		}else {
			this.employees = [];
		}

		this.employees.forEach((employee) => {
			var role = this.permissionService.getRoleNameFromID(employee.permissionID),
				status = employee.inactive == 1 ? 'Inactive' : 'Active';
				
			employee.role = role;
			employee.status = status;
		});
		
        this.lioModalService.hideLoading();
	}

	/*
	 * Exports the employee records
	*/
	exportRecords() {
		if (!this.workerService.export(this.getEmployees(), this.fields, 'employees')) {
			this.lioModalService.show('failedToExportEmployees');
		}
	}

	/*
	 * Gets the employees
	 * @return {array} 
	*/
	getEmployees() {
		if (!this.collection.length) {
			return this.employees;
		}

		return this.collection;
	}

	/*
	 * Edit's an employee
	*/
	edit(employee) {
		this.storageService.set('employee', employee);
		this.storageService.set('UID', employee.UID);
		this.navService.goto('usersummary');
	}
	
	/*
	 * On submit of the edit query buttomn
	*/
	editQuery() {
		this.navService.scrollToTop();
	}

	/*
	 * Sets the filters
	*/
	setFilters() {
		let role = this.permissionService.getRoleFilter();
		
		if (role) {
			this.filters.push(role);
		}
	}
}