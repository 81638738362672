<section *ngIf="(!field.permission || permissionService.hasPermission(field.permission)) 
	&& (field.visible && !fieldService.hasConditions(field.conditions, model, field))" 
	[ngClass]="field.rowClass" class="row"> 

	<lio-context-menu [field]="field" [ngClass]="field.inputClass ? field.inputClass : 'col-sm-12 col-md-9 mx-auto'">	
		<div *ngIf="!field.subModel">
			<lio-field-input [field]="field" 
				[formGroup]="formGroup"
				[model]="model" 
				[builderCtrl]="builderCtrl"
				(onInputChanged)="onFieldChanged($event)"
				[formdata]="formdata"
				(onSelectSearch)="onFieldRowSearch.emit($event)"
				[appearance]="appearance">
			</lio-field-input>
		</div>

		<div *ngIf="field.subModel">
			<lio-field-input [field]="field" 
				[formGroup]="formGroup"
				[builderCtrl]="builderCtrl"
				[model]="model[field.subModel]" 
				(onInputChanged)="onFieldChanged($event)"
				(onSelectSearch)="onFieldRowSearch.emit($event)"
				[formdata]="formdata" 
				[appearance]="appearance">
			</lio-field-input>
		</div>
	</lio-context-menu>
</section>
