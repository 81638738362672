import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DefaultModalComponent } from './../default/default.component';
import { ChooseRoleComponent } from './../choose-role/choose-role.component';
import { ChooseCompanyComponent } from './../choose-company/choose-company.component';
import { ChooseLanguageComponent } from './../choose-language/choose-language.component';
import { LoadingComponent } from './../loading/loading.component';
import { CatalogComponent } from './../catalog/catalog.component';
import { ConfirmComponent } from './../confirm/confirm.component';
import { InputComponent } from './../input/input.component';
import { EmailComponent } from './../email/email.component';
import { IFrameComponent } from './../iframe/iframe.component';
import { TableComponent } from './../table/table.component';
import { ImpersonateComponent } from './../impersonate/impersonate.component';
import { SendEmailComponent } from './../send-email/send-email.component';
import { FieldMapComponent } from './../field-map/field-map.component';


@Component({
	selector: 'app-modal',
	templateUrl: './modals.component.html'
})
export class ModalComponent{

	public opened						: boolean;
	public closed						: boolean;
	private modal						: any;

	@Output() opened_emitter		= new EventEmitter<any>();
	@Output() closed_emitter		= new EventEmitter<any>();
	@Output() submitted_emitter		= new EventEmitter<any>();

	@Input() hideafteropen?				: boolean;
	@Input() settings?					: any;
	@Input() onopen?					: any;
	@Input() onclose?					: any;
	@Input() onsubmit?					: any;
	@Input() fields?					: any;

	constructor(@Inject(MatDialog) public matDialog: MatDialog) {
		this.opened = false;
		this.closed = false;
	}

	/*
	 * Open the modal
	*/
	openModal(): void {
		this.createModal();	
	}

	/*
	 * Get the modal component
	 * @return {boolean}
	*/
	getComponent(): any {
		let type = this.settings.type,
			component = null;

		switch (type) {
			case 'choose-role':
				component = ChooseRoleComponent;
				break;
			case 'choose-company':
				component = ChooseCompanyComponent;
				break;
			case 'choose-language':
				component = ChooseLanguageComponent;
				break;
			case 'loading':
				component = LoadingComponent;
				break;
			case 'email':
				component = EmailComponent;
				break;
			case 'catalog':
				component = CatalogComponent;
				break;
			case 'confirm':
				component = ConfirmComponent;
				break;
			case 'input':
				component = InputComponent;
				break;
			case 'iframe':
				component = IFrameComponent;
				break;
			case 'impersonate':
				component = ImpersonateComponent;
				break;
			case 'send-email':
				component = SendEmailComponent;
				break;
			case 'field-map':
				component = FieldMapComponent;
				break;
			case 'table':
				component = TableComponent;
				break;
			default:
				// default to basic modal component
				component = DefaultModalComponent;
				break;
		}
		return component;
	}

	init(settings): any {
		this.settings = settings;
	}

	/*
	 * Create the modal
	 * @return {boolean}
	*/
	createModal(): boolean {
		const dialogConfig = new MatDialogConfig();
		const component = this.getComponent();

		//  Set options
		dialogConfig.disableClose = true;
		dialogConfig.id = this.settings.id;
		dialogConfig.height = this.settings.height || 'auto';
		dialogConfig.width = this.settings.width || '600px';
		dialogConfig.data = this.settings;
		if (!this.settings.width) {
			dialogConfig.maxWidth = this.settings.maxWidth || '50%';
		}
		dialogConfig.panelClass = 'tol-modal-container';

		this.modal = this.matDialog.open(component, dialogConfig);
	
		// Attach events to the modal
		this.modal.componentInstance.opened_emitter.subscribe(() => {
			this.modalOpened();
		});

		this.modal.componentInstance.closed_emitter.subscribe(($e) => {
			this.requestClose($e);
		});

		this.modal.componentInstance.submitted_emitter.subscribe(($e) => {
			this.submitted($e);
		});

		// After the modal closes
		this.modal.afterClosed().subscribe($e => {
		this.closed_emitter.emit($e);
			if (this.onclose) {
				this.onclose();
			}
		});

		this.opened = true;
		return true;
	}


	/*
	 * Updates the settings for the component
	*/
	updateSettings(settings): void {
		if (this.modal.componentInstance) {
			this.modal.componentInstance.updateSettings(settings);
		}
	}

	/*
	 * Updates a setting for the component
	*/
	updateSetting(name, value): void {
		if (this.modal.componentInstance && this.modal.componentInstance.settings) {
			this.modal.componentInstance.updateSetting(name, value);
		}
	}


	/*
	 * On open of the modal
	*/
	modalOpened(): void {
		this.closed = false;
		this.opened = true;
		this.opened_emitter.emit();
		if (this.onopen) {
			this.onopen();
		}
	}

	/*
	 * Submitted the modal
	*/
	submitted($e): void {
		this.submitted_emitter.emit($e);
		if (this.onsubmit) {
			this.onsubmit($e);
		}
	}

	/*
	 * Closes the modal
	*/
	requestClose($e:any = null): void {
		if (!this.modal) {
			return;
		}
		this.modal.close($e);
		this.closed = true;
		this.opened = false;
	}
}