import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-confirm',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', '../input/input.component.css',  './confirm.component.css']  
})

export class ConfirmComponent extends InputComponent implements OnInit {
	public override selection 				: any = true;
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public override settings : any = {
		'id' : 'am_modal_confirm',
		'title': 'Are you sure you want to do this?',
		'titleTrans': 'modal.areYouSureTitle',
		'canClose': false,
		'submit': 'Yes',
		'submitTrans': 'modal.yes',
		'close': 'No',
		'closeTrans': 'modal.no',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public override parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}

	/*
	 * Accept
	*/
	override submit(response): void {
		this.submitted = true;
		this.submitted_emitter.emit(response);
	}

	/*
	 * Deny
	*/
	override close(): void {
		this.selection = false;
		this.closed_emitter.emit(this.selection);
	}
}