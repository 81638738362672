<form role="form" autocomplete="off" novalidate>
	<section>
		<lio-form-inputs
			[fields]="modelFields"
			[fieldConfig]="fieldConfig"
			[model]="model"
			(onChanged)="onUpdate($event)">
		</lio-form-inputs>
		<!-- Link -->
		<lio-card body-class="p-0" footer-class="container-fluid" [hideErrors]="true">
			<div lio-card-body>
				<span>Link:</span>
				<input 
					class="m-2 p-1 w-75" 
					id="am_form_link"
					name="link"
					[(ngModel)]="link"
					[disabled]="true" 
					title="Link"/>
				<button mat-button color="primary" [disabled]="disableCopy" (click)="copyToClipboard()">
					<span class="fa fa-copy fa-lg mr-1"></span>
					<span>Copy To Clipboard</span>
			</button>
			</div>
		</lio-card>
		<table class="table">
			<thead class="thead-light">
				<tr>
					<th *ngFor="let field of fields">{{field.name}}</th>
					<th>Remove</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of model.fields; let r = index">
					<td *ngFor="let field of fields">
						<lio-field-input 
							[formGroup]="formGroup"
							[field]="field" 
							[model]="row" 
							(onInputChanged)="compileFormat()"
							[appearance]="appearance">
						</lio-field-input>
					</td>
					<td>
						<button mat-button color="warn" (click)="removeRow(r)" class="fa fa-minus-square fa-2x"></button>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="btn-toolbar">
			<button class="btn btn-sm btn-primary mr-1" (click)="savePresets()" type="submit">
				<span>Save</span>
			</button>

			<button class="btn btn-sm btn-primary mr-1" (click)="deletePresets()" type="submit">
				<span>Delete</span>
			</button>

			<button mat-button color="primary" (click)="addRow()">
				<span class="fa fa-plus fa-lg mr-1"></span>
				<span>Add Row</span>
			</button>
		</div>
	</section>
</form>
