/*
 * Service for Controlling Exports of files
*/
import { Observable } from 'rxjs';

import '../../js/app/workers/employee_exporter';
import '../../js/app/workers/pagination_sorter';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { lioLogService } from './lio-log.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';


@Injectable({
	providedIn: 'root',
})
export class workerService {
	workers 		: Array<any>;
	testFallback 	: boolean;
	exporters 		: any;

	constructor(
		@Inject(DOCUMENT) 				private document			: Document,
		@Inject(lioLogService)			private lioLogService		: lioLogService,
		@Inject(localizationService)	private localizationService	: localizationService,
		@Inject(debugService)			private debugService		: debugService,
		@Inject(utilService)			private utilService			: utilService
	){
		this.workers 		= [];
		this.testFallback 	= false;
		this.exporters 		= {
			EmployeeExporter 	: (self as any).EmployeeExporter, 
			PaginationSorter 	: (self as any).PaginationSorter
		};
		this.debugService.register('worker', this);
	}

	/*
	 * Gets the localizations needed for the exporter to map data
	 * @return {object}
	*/
	getLocalizations () {
		return {
			'dashboard.courseStartStatusInProgress': this.localizationService.get('dashboard.courseStartStatusInProgress'),
			'dashboard.courseStartStatusEnrolled': this.localizationService.get('dashboard.courseStartStatusEnrolled'),
			'dashboard.courseCompleteStatusComplete': this.localizationService.get('dashboard.courseCompleteStatusComplete'),
			'dashboard.courseCompleteStatusIncomplete': this.localizationService.get('dashboard.courseCompleteStatusIncomplete'),
			'form.true': this.localizationService.get('form.true'),
			'form.false': this.localizationService.get('form.false'),
			'form.role': this.localizationService.get('form.role'),
			'form.status': this.localizationService.get('form.status'),
			'dateFormat': this.localizationService.getSelectedDateFormatForExport()
		};
	}

	/*
	 * Gets the worker
	 * @return {object}
	*/
	getWorker(subscriber) {
		if (typeof Worker === 'undefined' || this.testFallback) {
			return null;
		}

		if (this.workers.length) {
			let availableWorker = null;
			this.workers.forEach((worker) => {
				if(worker.status == 'available'){
					availableWorker = worker;
				}
			});

			if(availableWorker){
				availableWorker.status 		= 'processing';
				availableWorker.subscriber 	= subscriber;
				return availableWorker;
			}
		}
		let newWorker = {
			status 		: 'processing', 
			worker 		: new Worker('js/app/utilities/worker.js'),
			subscriber 	: subscriber
		};

		newWorker.worker.addEventListener('message', (e) => {
			let response = e.data;

			newWorker.status = 'available';

			if (!response.status) {
				this.lioLogService.log(['Recieved A Worker Failure', response]);
				newWorker.subscriber.next(false);
				newWorker.subscriber.complete();
				return;
			}

			this.lioLogService.log(['Heard Response From Worker', response]);

			switch (response.cmd) {
				case 'getEmployeesForExport':
					newWorker.subscriber.next(response.employees);
					newWorker.subscriber.complete();
					break;
				case 'exportRecords':
					this.save(newWorker.subscriber, response.text, response.title);
					break;
				case 'prepareExport':
					newWorker.subscriber.next(response);
					newWorker.subscriber.complete();
					break;
				default:
					// Generic Callback for a simple result 
					newWorker.subscriber.next(response.result);
					newWorker.subscriber.complete();
					break;
				}

		}, false);

		this.workers.push(newWorker);

		return newWorker;
	}

	/*
	 * Maps values that are being exported to friendlier values
	 * @param {array} employees
	 * @param {array} fields
	 * @return {array}
	*/
	getEmployeesForExport(employees, fields) {
		let deffered = new Observable((subscriber) => {
			let worker = this.getWorker(subscriber),
				useFallBack = worker ? false : true,
				localizations = this.getLocalizations(),
				exporter;


			if (worker) {
				fields = this.cleanFields(fields);
				if (!this.callWorker(worker, {'cmd': 'getEmployeesForExport', 'employees': employees, 'fields': fields, 'localizations': localizations})) {
					useFallBack = true;
				}
			}

			if (useFallBack) {
				this.lioLogService.log('FALLBACK');
				let exporterRef = this.exporters['EmployeeExporter'];
				exporter = new exporterRef();
				employees = exporter.getEmployeesForExport(employees, fields, localizations);
				subscriber.next(employees);
				subscriber.complete();
			}
		});

		return deffered.toPromise();
	}

	/*
	 * Calls a specific function by name
	 * @param {string} functionName
	 * @param {object} data
	 * @param {string} exporterName
	 * @return {array}
	*/
	call(functionName, data, exporter = null) {
		this.lioLogService.log('CALLING: ' + functionName);

		let deffered = new Observable((subscriber) => {
			let worker = this.getWorker(subscriber),
			useFallBack = worker ? false : true,
				result;

			if (worker) {
				if (!this.callWorker(worker, {'cmd': functionName, 'data': data, 'exporter': exporter})) {
					useFallBack = true;
				}
			}

			if (useFallBack) {
				this.lioLogService.log('FALLBACK');
				if (!exporter) {
					exporter = 'EmployeeExporter';
				}
				let exporterRef = this.exporters[exporter]
					exporter 	= exporterRef ? new exporterRef(): null;
			
				if (!exporter) {
					this.lioLogService.log(['FALLBACK FAILED TO FIND EXPORTER', this.exporters]);
					subscriber.complete();
					return;
				}

				result = exporter[functionName](data);
				subscriber.next(result);
				subscriber.complete();
			}
		});
		return deffered.toPromise();
	}

	/*
	 * Calls the worker
	 * @param {object} message
	 * @return {boolean}
	*/
	callWorker(worker, message) {
		try {
			worker.worker.postMessage(message);
		} catch (e) {
			this.lioLogService.log(['Failed to post to worker', e]);
			return false;
		}
		return true;
	}

	/*
	 * Exports data to a file
	 * @param {array[objects]} employees
	 * @param {array[objects]} fields
	 * @param {string} title
	 * @param {string} exporterName
	 * @return {Promise}
	*/
	export(employees, fields, title, exporter = null) {
		let deffered = new Observable((subscriber) => {
			setTimeout(() => {
				let worker = this.getWorker(subscriber),
					useFallBack = worker ? false : true,
					localizations = this.getLocalizations(),
					text;

				if (!title) {
					title = 'export_' + this.utilService.randomNumber();			
				}

				if (worker) {
					fields = this.cleanFields(fields);
					if (!this.callWorker(worker, {'cmd': 'exportRecords', 'exporter': exporter, 'employees': employees, 'fields': fields, 'localizations': localizations, 'title': title})) {
						useFallBack = true;
					}
				} 

				if (useFallBack) {
					this.lioLogService.log('FALLBACK');
					if (!exporter) {
						exporter = 'EmployeeExporter';
					}
					let exporterRef = this.exporters[exporter]
					exporter 		= new exporterRef();

					employees = exporter.getEmployeesForExport(employees, fields, localizations);
					text = exporter.convertToText(employees, fields);
					this.save(subscriber, text, title);
				}
			}, 1000);
		});
		return deffered.toPromise();
	}

	/*
	 * Prepares calling fields to the post
	 * @param {array[objects]} fields
	 * @return {array}
	*/
	cleanFields(fields) {
		fields = this.utilService.copy(fields);
		if (!fields) {
			this.lioLogService.error('NO FIELDS');
			return [];
		}
		fields.forEach((field) => {
			// Mapping function is too deep to be placed into the post message
			field['mappingFunction'] = 'TRUNCATED';
			field['mappingTransFunction'] = 'TRUNCATED';
		});

		return fields;
	}

	/*
	 * Prepares exporting data to a file
	 * @param {array[objects]} employees
	 * @param {array[objects]} fields
	 * @param {string} title
	 * @return {boolean}
	*/
	prepareExport(employees, fields, title) {
		let deffered = new Observable((subscriber) => {
			let worker = this.getWorker(subscriber),
				useFallBack = worker ? false : true,
				localizations = this.getLocalizations(),
				text,
				exporter;

			if (worker) {
				fields = this.cleanFields(fields);
				if (!this.callWorker(worker, {'cmd': 'prepareExport', 'employees': employees, 'fields': fields, 'localizations': localizations, 'title': title})) {
					useFallBack = true;
				}
			}

			if (useFallBack) {
				this.lioLogService.log('FALLBACK');
				exporter = new this.exporters.EmployeeExporter(),
				employees = exporter.getEmployeesForExport(employees, fields, localizations);
				text = exporter.convertToText(employees, fields);
				subscriber.next(text);
				subscriber.complete();
			}
		});

		return deffered.toPromise();
	}

	/*
	 * Saves the blob
	 * @param {string} text
	 * @param {string} title
	*/
	save(subscriber, text, title) {
		if (!text || !title) {
			subscriber.next(false);
			subscriber.complete();
			return;
		}

		title = title + '.csv';
		
		if (this.document.defaultView.navigator && this.document.defaultView.navigator.msSaveOrOpenBlob) {
			// For IE
			let blob = new Blob([text], {type:"text/csv;charset=utf-8"});
			this.document.defaultView.navigator.msSaveOrOpenBlob(blob, title);
		} else {
			// For Non-IE
			let element	= this.document.createElement("a");
			element.href = "data:application/csv;charset=UTF-8,%EF%BB%BF" + encodeURIComponent(text);
			element.download = title;
			element.click();
		}
		subscriber.next(true);
		subscriber.complete();
	}
}