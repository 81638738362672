<div class="row">
	<div class="col-md-10 mx-auto">
		<lio-panel-tabber>
			<lio-panel-tab [settings]="panelConfigs.profile">
				<lio-profile *lioPanelTabContent
					[updateProfilePermission]="'self.updateProfile'"
					[fields]="fields"
					[config]="fieldConfig"
					[updateCCFPermission]="'self.updateCCFAdminRights'"
					[self]="true"
				></lio-profile>
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</div>