import { Component, Inject, Input, OnDestroy } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { fieldService } from '../../../../services/fields.service';

@Component({
	selector: 'lio-fields',
	templateUrl: './fields.component.html',
})
export class LioFields implements OnDestroy {
	@Input() fields:Array<any>;

	private _fieldConfig :any;
	get fieldConfig(){
		return this._fieldConfig;
	}
	@Input() set fieldConfig(value: any) {    
		this._fieldConfig = value;
		this.init();
	}

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(debugService) public debugService :debugService,
		@Inject(fieldService) public fieldService :fieldService
	){
		this.debugService.register('fieldsdirective', this);

		this.subscriptions.add(
			this.fieldService.fieldsModified.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
		* Sets the fields per config and overrides as needed 
	*/
	init() {
		if (this.fieldConfig) {
			this.fieldService.setFields(this.fields, this.fieldConfig).then((fields:Array<any>) => {
				fields.forEach((field, index) => {
					this.fields[index] = field;
				});
			});
		}
	}
}