<div *ngIf="initialized" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'" [formGroup]="formGroup">
	<mat-form-field
		[id]="amID + '_form_field'"
		[appearance]="appearance" 
		[ngClass]="{'w-100':fullWidth}" 
		class="{{'pt-' + paddingTop + ' pb-' + paddingBottom + ' ' + backgroundColor}}"
		localize [model]="labelLocaleStrings" item="name"
		[style.fontSize]="fontSize">

		<!-- LABEL -->
		<span *ngIf="showLabel" for="amID" [id]="amID + '_label'">{{labelLocaleStrings.name}}</span>
		<span *ngIf="field.required"> *</span>
		<!-- Select Drop Down -->
		<mat-select [id]="amID"
			[formControl]="formControlRef"
			[(ngModel)]="model[field.model]"
			(selectionChange)="onupdate()"
			[required]="field.required"
			[attr.disabled]="field.disabled"
			[multiple]="multiple"
			[compareWith]="optionComparator"> 

			<!-- Search Field -->
			<mat-option *ngIf="searchable">
				<ngx-mat-select-search 
					[id]="amID + '_search'"
					[formControl]="selectionFilterCtrl"
					[placeholderLabel]="localizationService.get('form.searchPlaceholder')" 
					[noEntriesFoundLabel]="localizationService.get('form.searchFail')">
				</ngx-mat-select-search>
			</mat-option>

			<!-- No Options Found -->
			<mat-option *ngIf="!(filteredOptions | async)" 
				[id]="amID + '_option_nooptions'"
				disabled
				localize [model]="noOptions" item="name"
				[value]="noOptions.value">
				{{noOptions.name}}
			</mat-option>

			<!-- Empty Option -->
			<mat-option *ngIf="showEmptyOption()"
				[id]="amID + '_option_empty'">
			</mat-option>

			<!-- Options List -->
			<!-- Options When using Options groups -->
			<mat-optgroup *ngFor="let group of optionGroups" [label]="group.name || group.value">
				<mat-option
					[id]="amID + '_option_' + getOptionName(option)"
					*ngFor="let option of filterOptionGroup(group, (filteredOptions | async | orderBy:orderBy))"
					localize [model]="option" [item]="optionNameField"
					[disabled]="option.locked"
					[matTooltip]="option[optionTooltipField]"
					[value]="optionAsValue ? option : option[optionValueField]">
					{{getOptionName(option)}}
				</mat-option>
			</mat-optgroup>
			<ng-container *ngIf="optionGroups.length == 0">
				<!-- Options When NOT using Options groups -->
				<mat-option
					[id]="amID + '_option_' + getOptionName(option)"
					*ngFor="let option of (filteredOptions | async | orderBy:orderBy)"
					localize [model]="option" [item]="optionNameField"
					[disabled]="option.locked"
					[matTooltip]="option[optionTooltipField]"
					[value]="optionAsValue ? option : option[optionValueField]">
					{{getOptionName(option)}}
				</mat-option>
			</ng-container>
			<!-- Options List -->
		</mat-select>
		<ng-content></ng-content>
		<button *ngIf="clearable && hasValue()" matSuffix mat-icon-button (click)="clearModel($event)">
			<mat-icon>close</mat-icon>
		</button>
	</mat-form-field>
</div>