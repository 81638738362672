
/*
 * Service for Controlling Tracker of courses for learn IO
*/
import moment from 'moment';
import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { apiInterface } from './api.interface';
import { trackerInterface } from './tracker.interface';

import { aiccService } from './aicc.service';
import { documentService } from './document.service';
import { lioLogService } from './lio-log.service';
import { lmsService } from './lms.service';
import { scorm12Service } from './scorm-12.service';
import { scormLSPService } from './scorm-lsp.service';
import { storageService } from './storage.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';
import { processingService } from './processing.service';

@Injectable({
	providedIn: 'root',
})
export class trackerService implements trackerInterface {
	active				:boolean;
	API					:apiInterface;
	course				:any;
	isSaving 			:boolean;
	debug	 			:boolean;
	handledError		:boolean;
	endingSession		:boolean;
	startTime			:any;
	commits				:Array<any>;
	preview				:boolean;
	handleCompletion 	:boolean;
	handledCompletion 	:boolean;


	public trackerError:Subject<any> = new Subject();

	constructor(
		@Inject(aiccService)		private aiccService			: aiccService,
		@Inject(documentService)	private documentService		: documentService,
		@Inject(lioLogService)		private lioLogService		: lioLogService,
		@Inject(lmsService)			private lmsService			: lmsService,
		@Inject(processingService)	private processingService	: processingService,
		@Inject(scorm12Service)		private scorm12Service		: scorm12Service,
		@Inject(scormLSPService)	private scormLSPService		: scormLSPService,
		@Inject(storageService)		private storageService		: storageService,
		@Inject(debugService)		private debugService		: debugService,
		@Inject(utilService)		private utilService			: utilService
	){
		this.active					= false;
		this.API					= null;
		this.course					= null;
		this.isSaving 				= false;
		this.debug	 				= false;
		this.handledError			= false;
		this.endingSession			= false;
		this.startTime				= null;
		this.commits				= [];
		this.preview				= false;
		this.handleCompletion		= false;
		this.handledCompletion		= false;
		this.debugService.register('tracker', this);
	}

	/*
	 * Is the tracker active?
	 * @return {boolean}
	*/
	isActive() {
		return this.active;
	}

	/*
	 * Reset the tracker states
	*/
	reset() {
		this.active					= false;
		this.API					= null;
		this.course					= null;
		this.isSaving 				= false;
		this.debug	 				= false;
		this.handledError			= false;
		this.endingSession			= false;
		this.startTime				= null;
		this.commits				= [];
		this.preview				= false;
		this.handleCompletion		= false;
		this.handledCompletion		= false;

	}

	/*
	 * Sets preview mode to avoid committing data
	*/
	setPreviewMode() {
		this.preview = true;
	}

	/*
	 * Handles an API commit
	 * @return {Promise}
	*/
	handleCommit() {
		if (this.isSaving || !this.API) {
			this.lioLogService.log('Already Saving');
			return;
		}

		this.isSaving = true;

		setTimeout(() => {
			if (!this.endingSession) {
				this.saveData();
			}
		}, 1000);
	}

	/*
	 * Initializes the course
	 * @return {Promise}
	*/
	initialize() {
		let employeeInCourseID 		= this.course.employeeInCourseID,
			courseID				= this.course.courseID,
			launchMethod 			= this.getLaunchMethod();

		if (this.preview) {
			return new Promise((resolve) => { resolve(true); });
		}

		this.lioLogService.log('Initializing');
		return this.lmsService.post('training/initialize', {'employeeInCourseID': employeeInCourseID, 'courseID': courseID, 'launchMethod': launchMethod})
		.then((result:any) => {
			if (result.success) {
				this.lioLogService.log('Initialized');
				return true;
			}
			return false;
		});
	}

	/*
	 * Finish the course
	 * @return {Promise}
	*/
	finish() {
		if (!this.course) {
			return new Promise((resolve) => { resolve(false); });
		}
		let employeeInCourseID 		= this.course.employeeInCourseID,
			courseID				= this.course.courseID,
			handleCompletion 		= this.handleCompletion,
			handledCompletion 		= this.handledCompletion,
			launchMethod 			= this.getLaunchMethod();

		if (this.preview) {
			return new Promise((resolve) => { resolve(true); });
		}

		this.lioLogService.log('Finishing');

		return this.lmsService.post('training/finish',
			{
				employeeInCourseID		: employeeInCourseID, 
				courseID 				: courseID, 
				launchMethod 			: launchMethod,
				handleCompletion 		: handleCompletion,
				handledCompletion 		: handledCompletion,
			}).then((result:any) => {
				if (result.success) {
					if (result.properties.handledCompletion) {
						this.lioLogService.log('Completion handled');
						this.handledCompletion = true;
						this.handleCompletion = false;
					}
					this.lioLogService.log('Finished');
					return true;
				}
				return false;
			});
	}

	/*
	 * Saves the tracker data
	 * @return {Promise}
	*/
	saveData() {
		if (!this.API || this.preview || !this.course) {
			return new Promise((resolve) => { resolve(true); });
		}

		let employeeInCourseID 		= this.course.employeeInCourseID,
			courseID				= this.course.courseID,
			data 					= this.utilService.copy(this.API.getData());

		if (this.utilService.isEmpty(data)) {
			if (!this.endingSession) {
				return new Promise((resolve) => { resolve(false); });
			} else {
				data = {};
			}
		}

		data['aicc_time'] = this.setSessionTime();
		this.lioLogService.log(['SAVING DATA', data]);
		this.commits.push(data);
		this.processingService.resendOnInvalidResponse = true;

		return this.lmsService.post('training/updateData', {
				'employeeInCourseID'	: employeeInCourseID, 
				'courseID'				: courseID,
				'data'					: data, 
			}).then((result:any) => {
				this.processingService.resendOnInvalidResponse = false;
				this.isSaving = false;
				
				if (result.success) {
					if (result.properties.handleCompletion) {
						this.handleCompletion = true;
					}
					if (this.API) {
						this.API.clearData(data);
					}
				} else if (result.errors && result.errors.length) {
					this.dispatchError(result.errors[0]);
				} else {
					this.dispatchError('Failed to update data');
				}
		});
	}

	/*
	 * Sets the session time
	 * @return {string}
	*/
	setSessionTime() {
		let initialTime		= this.course.aicc_time || '00:00:00',
			endTime = new Date().getTime(),
			duration =  endTime - this.startTime,
			hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
			minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
			seconds = Math.floor((duration % (1000 * 60)) / 1000),
			totalTime;

		totalTime = moment(initialTime, 'HH:mm:ss').add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds').format('HH:mm:ss');
		return totalTime;
	}

	/*
	 * Gets the API protocol
	 * @return {string}
	*/
	getProtocol() {
		if (this.API) {
			return this.API.protocol;	
		}
		return null;
	}

	/*
	 * Gets the launch Method
	 * @return {string}
	*/
	getLaunchMethod() {
		if (this.API) {
			return this.API.launchMethod;	
		}
		return 'NO API';
	}

	/*
	 * Handles the course is open
	 * @param {object} course
	*/
	handleCourseOpen () {
		if (this.API) {
			this.lioLogService.log('Set Start Time');
			this.startTime = new Date().getTime();
			this.API.handleCourseOpen();
		}
	}

	/*
	 * Activate the tracker
	 * @param {object} course
	*/
	activate(course) {
		this.course = course;
		this.commits = [];
		this.API = this.getAPI();
		this.startTime = new Date().getTime();
		
		if (!this.API) {
			return new Promise((resolve) => { resolve(false); });
		}


		this.API.debug = this.debug;
		if (this.API.activate(course)) {
			this.addAPIObservers();
			this.active = true;
			this.lioLogService.log(['Tracker Activated', course]);
		} else {
			this.dispatchError('API Failed to Activate');
			return new Promise((resolve) => { resolve(false); });
		}
		return this.initialize();
	}

	/*
	 * Determine if the course should use the document tracker
	 * @return {boolean}
	*/
	isDocument() {
		if (this.course.documentLaunch) {
			return true;
		}

		let extensions	= [
				'png', 'pdf', 'jpg', 'jpeg', 'txt', 'doc', 'docx',
				'ppt', 'pptx', 'csv', 'xls', 'xlsx', 'mp4', 'avi',
				'mpeg', 'mpeg4'
			],
			url 		= this.course.courseURL.split('.'),
			extension	= url.length > 1 ? url[1] : ''; 
	
		if (extensions.indexOf(extension) > -1) {
			return true;
		}
		return false;
	}

	/*
	 * Get the API
	 * @return {Class}
	*/
	getAPI() {
		let aicc = this.course.aiccLaunch,
			scorm = this.course.scormLaunch,
			documentLaunch = this.isDocument(),
			lspLaunch = this.course.lspLaunch,
			version = 'Scorm 1.2';

		if (aicc == 1) {
			version = 'AICC';
		}

		if (documentLaunch) {
			version = 'Document';
		}

		if (scorm == 1 && lspLaunch == 1) {
			version = 'ScormLSP';
		}

		switch (version) {
			case 'Scorm 1.2':
				return this.scorm12Service;
			case 'ScormLSP':
				return this.scormLSPService;
			case 'AICC':
				return this.aiccService;
			case 'Document':
				return this.documentService;
			default:
				this.dispatchError('API Version Not found');
				break;
		}
		return null;
	}

	/*
	 * Determines if this API allows third party hosted content
	 * @return {Promise}
	*/
	allowsThirdPartyHost() {
		if (!this.API) {
			return true;
		}
		return this.API.allowsThirdPartyHost;
	}

	/*
	 * Deactivare the API
	 * @return {Promise}
	*/
	deactivate() {
		this.lioLogService.log(['Deactivating Tracker', this.API]);

		if (this.API) {
			this.endingSession = true;
			this.API.handleCourseClosing();
			return this.saveData().then(() => {
				return this.finish().then(() => {
					if (this.API) {
						this.API.deactivate();
					}
					this.reset();
				});
			});
		}
		return new Promise((resolve) => { resolve(true); });
	}

	/*
	 * Dispatch Error
	 * @param {string} error
	*/
	dispatchError(error) {
		if (this.handledError) {
			return;
		}
		this.handledError = true;

		this.lioLogService.log(['Deactivating Tracker Due to Errror', error]);
		this.deactivate().then(() => {
			this.lioLogService.log(['Sending Errror To Course Launch', error]);
			this.trackerError.next(error);
		});
	}

	/*
	 * Handle Error From the API
	 * @param {string} error
	*/
	handleAPIError(error) {
		error = error ? error : this.storageService.get('dispatchedParams');
		this.lioLogService.log(['Heard API Errror', error]);
		this.dispatchError(error);
	}

	/*
	 * Adds API listeners
	*/
	addAPIObservers() {
		this.API.apiError.subscribe((error) => {
			this.handleAPIError(error);
		});
		this.API.apiCommit.subscribe(() => {
			this.handleCommit();
		});
		this.API.apiInitialized.subscribe(() => {
			this.handleCourseOpen();
		});
		this.API.apiFinished.subscribe(() => {
			this.finish();
		});
	}
}