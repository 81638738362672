<div class="table-responsive form-table">
	<mat-accordion multi>
		<mat-expansion-panel *ngFor="let group of groups; index as i" [hidden]="!group.visible">
			<mat-expansion-panel-header (click)="onUpdate.emit($event)">
				<mat-panel-title>{{group.name}}</mat-panel-title>
			</mat-expansion-panel-header>
			<div id="group_{{i}}">
				<div class="list-group">
					<div class="card-header">
						<div class="row">
							<div class="col-2" id="action">Reset</div>
							<div class="col-4" id="name">Name</div>
							<div class="col" id="value">Value</div>
						</div>
					</div>
					<ng-container *ngFor="let field of group.fields; index as i">
						<div *ngIf="field.visible" class="list-group-item">
							<div class="row">
								<div class="col-2">
									<button [disabled]="!model[field.model]" (click)="resetField.emit(field.model)" class="btn button fa fa-undo"></button>
								</div>
								<div class="col-4">
									<span localize [model]="field" item="name">{{field.name}}</span>
									<tooltip *ngIf="field.tooltip" [tooltipField]="field"></tooltip>
								</div>
								<div class="col">
									<lio-field-input 
										[field]="group.fields[i]" 
										[model]="model" 
										(onInputChanged)="onUpdate.emit($event)" 
										[appearance]="appearance"
										[formGroup]="formGroup"></lio-field-input>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</mat-expansion-panel>			
	</mat-accordion>
</div>