<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-8 mx-auto">
		<!-- Register form -->
		<lio-card [hidden]="showSuccess">
			<div lio-card-header>
				<button mat-button 
					(click)="navService.goback()" class="float-right">
					<mat-icon class="position-absolute">close</mat-icon>
				</button>
				<h2 class="h6">{{localeStrings.alreadyRegistered}}</h2>
				<button id="am_register_submit" 
					mat-flat-button
					color="primary"
					(click)="navService.goto('login')" 
					type="submit">
					
					<strong>{{localeStrings.clickToLogin}}</strong>
				</button>
				<h3 class="mt-2 h4">{{localeStrings.header}}</h3>
				<span>{{localeStrings.subHeader}}</span>
			</div>
			<div lio-card-body>
				<form name="form" role="form" autocomplete="off" novalidate>
					<lio-form-inputs
						[fields]="fields"
						[fieldConfig]="fieldConfig"
						[model]="employee"
						(onChanged)="onUpdate()">
					</lio-form-inputs>					
				</form>
			</div>
			<div lio-card-footer>
				<button id="am_register_submit" 
					mat-flat-button
					color="primary"
					[disabled]="!allowSubmit" 
					(click)="submit()">
					
					<strong>{{localeStrings.add}}</strong>
				</button>
			</div>
		</lio-card>

		<!-- Register form success -->
		<lio-card [hidden]="!showSuccess" 
			[headerText]="localeStrings.successfullyAdded"
			[headerTrans]="localeStrings.successfullyAddedTrans" 
			[subHeaderText]="localeStrings.youMayCloseWindow" 
			[subHeaderTrans]="localeStrings.youMayCloseWindowTrans">
		</lio-card>
	</div>
</div>