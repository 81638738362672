import { Pipe, Inject } from '@angular/core';

import { permissionService } from 'src/app/services/permissions.service';

@Pipe({
  name: "permissionToBoolean"
})
export class PermissionToBooleanPipe {
	constructor(
		@Inject(permissionService) public permissionService :permissionService
	){}

	transform(permission: boolean|string|number):boolean {		
		if(typeof permission == 'undefined'){
			return false;
		}
		if(typeof permission == 'boolean'){
			return permission;
		}else if(permission === 1 || permission === '1' || permission === 'true'){
			return true;
		}else if(permission === 0 || permission === '0' || permission === 'false'){
			return false;
		}else{
			return this.permissionService.hasPermission(permission);
		}
	}
}