import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { debugService } from '../../../../services/debug.service';
import { permissionService } from '../../../../services/permissions.service';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
  selector: 'lio-catalog-results-sub-header',
  templateUrl: './results-sub-header.component.html'
})
export class LioResultsSubHeader{
	@Input() canExpandCatalogs		:boolean 			= false;
	@Input() catalogs				:Array<any>			= [];
	@Input() appearance				:MatFormFieldAppearance = 'outline';
	@Input() view					:any 				= null;

	private _pagination:any = null;
	public get pagination():any{
		return this._pagination;
	}
	@Input() public set pagination(val:any){
		this._pagination 			= val;
		this.pageLimitField.options = val.pageLimits;
	}

	@Output() expandAllCatalogs		:EventEmitter<any> 	= new EventEmitter();
	@Output() collapseAllCatalogs	:EventEmitter<any> 	= new EventEmitter();
	@Output() onupdate				:EventEmitter<any> 	= new EventEmitter();
	@Output() checkPrintAll			:EventEmitter<any> 	= new EventEmitter();
	@Output() clearPrint			:EventEmitter<any> 	= new EventEmitter();

	public sortField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Sort By',
		nameTrans 	: 'coursecatalog.sortBy',
		model 		: 'field',
		options 	: [
			{
				name		: 'Course Number',
				nameTrans 	: 'coursecatalog.courseNumber',
				value		:'courseID'
			},
			{
				name		: 'Course Name',
				nameTrans 	: 'coursecatalog.courseName',
				value		:'name'
			}
		],
		fontSize 			: '14px',
		backgroundColor		: 'white'
	});

	public pageLimitField:LioSearchableSelectField = new LioSearchableSelectField({
		name 			: 'Display:',
		nameTrans 		: 'message.display',
		model 			: 'pageLimit',
		options 		: [],
		optionAsValue 	: true,
		fontSize 		: '14px',
		backgroundColor	: 'white'
	});

	public localeStringItems:Array<any> = [
		'clear',
		'descending',
		'ascending',
		'all'
	];

	public localeStrings:any = {
		clear	 				: 'CLEAR',
		clearTrans	 			: 'coursecatalog.clear',
		descending 				: 'Descending',
		descendingTrans			: 'coursecatalog.descending',
		ascending 				: 'Ascending',
		ascendingTrans 			: 'coursecatalog.ascending',
		all 					: 'All',
		allTrans 				: 'coursecatalog.all'
	};

	constructor(
		@Inject(debugService) 		public debugService		:debugService,
		@Inject(permissionService) 	public permissionService:permissionService
	){
		this.debugService.register('catalogresultssubheader', this);
	}
}