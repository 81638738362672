<span #flowDrop class="rtl">
	<label for="{{settings.name}}" class="sr-only">{{settings.name}}</label>
	<button mat-flat-button color="primary"
		*ngIf="!settings || !settings.hideButton" 
		#flowBrowse 
		[style.lineHeight.px]="matchBootstrap ? '32' : ''"
		[disabled]="disabled"
		class="font-weight-bold"
		[ngClass]="settings.class" 
		type="submit">

		<strong localize [model]="settings" [items]="['name']">{{settings.name}}</strong>
	</button>
	<ng-content></ng-content>
</span>