import { LioField } from './field.model';

export class LioButtonField extends LioField{
	//another field in this model that controls whether this button field is disabled
	public disabledModel	:string;

	//callback for button fields
	public callback			:(event:any)=>void;

	//whether the function this button called has completed
	public gotResult 		:string;

	public dynamicName 		:string;

	constructor(obj:Partial<LioButtonField>){
		super(obj);
		Object.assign(this, obj);
	}
}