<div *ngFor="let field of fields; index as i">
	<section [ngClass]="field.rowClass" class="form-group" [hidden]="!field.visible" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">

		<!-- Label Only -->
		<label *ngIf="field.type == 'label'" id="am_form_label_{{field.model}}">
			<span localize [model]="field" item="name">{{field.name}}</span>
		</label>

		<!-- Input -->
		<lio-field-input
			*ngIf="field.type != 'label'"
			[formGroup]="formGroup"
			[field]="fields[i]"
			[model]="model"
			(onInputChanged)="onChanged.emit($event)"
			[appearance]="appearance">
		</lio-field-input>
	</section>
</div>