import { Component, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { NEVER, Subscription } from 'rxjs';

import moment from 'moment';

import { localizationService } from '../../../../../services/localization.service';
import { debugService } from '../../../../../services/debug.service';
import { coursesService } from '../../../../../services/courses.service';
import { utilService } from '../../../../../services/util.service';
import { permissionService } from '../../../../../services/permissions.service';
import { lioLogService } from '../../../../../services/lio-log.service';
import { stateService } from '../../../../../services/state.service';
import { ccfService } from '../../../../../services/ccf.service';
import { fieldService } from '../../../../../services/fields.service';
import { feedbackService } from '../../../../../services/feedback.service';
import { lioModalService } from '../../../../../services/lio-modal.service';

import { querySettings } from '../../../../../settings/query.settings';

@Component({
	selector: 'lio-query-builder',
	templateUrl: './query-builder.component.html',
})
export class LioQueryBuilder implements OnDestroy {
	@Input() formGroup		:FormGroup = new FormGroup({});

	@Output() runReport		:EventEmitter<any> 			= new EventEmitter();
	@Output() builderReady	:EventEmitter<any> 			= new EventEmitter();
	@Input() filters		:any;
	@Output() filtersChange	:EventEmitter<Array<any>> 	= new EventEmitter<Array<any>>();
	@Output() updatedBuilder	:EventEmitter<Array<any>> 	= new EventEmitter<Array<any>>();

	public revalidate		:EventEmitter<any> 			= new EventEmitter();
	public cleanFilters		:any = [];

	private _controlObject :any;
	get controlObject(){
		return this._controlObject;
	}
	@Input() set controlObject(value: any) {    
		this._controlObject = value;
		if (value) {
			value.getRules 		= () 		=> { return this.getCleanRules(); };
			value.createBuilder	= () 		=> { return this.createBuilder(); };
			value.isValid 		= () 		=> { return this.isValid(); };
			value.reset			= () 		=> { return this.reset(); };
			value.updatedRules 	= (rules) 	=> { return this.updatedRules(rules); };
		}
	
	}

	@Output() rulesChange:EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
	private _rules :Array<any> = [];
	private _filters :Array<any> = [];
	get rules(){
		return this._rules;
	}
	@Input() set rules(value: Array<any>) {
		if (this.built) {
			this.updatedRules(value);
			return;
		}
		this._rules = value;

		if (!this._rules) {
			this.lioLogService.log('No Rules Found');
			return;
		}
		if (!this._rules.length && !this.settings.suppressDefaultRules) {
			this.lioLogService.log('no rules provided, setting to default');
			this._rules = this.utilService.copy(this.querySettings.defaultRules);
			this.reset();
		} else if (typeof this._rules === 'undefined') {
			this._rules = [];
		}
		if (!this.built) {
			this.createBuilder();
		}
	}

	private _settings :any = {};
	get settings(){
		return this._settings;
	}
	@Input() set settings(value: any) {    
		this._settings = value;

		if(value.text){
			this.runReportLabel.nameTrans = value.text.runReport;
		}
	}

	public maxRuleCount		:number 	= 10;
	public validRules		:boolean 	= false;
	public ready			:boolean 	= false;
	public building			:boolean 	= false;
	public built			:boolean 	= false;

	public addRuleLabel = {
		name : 'AddRule',
		nameTrans : 'dashboard.addrule'
	};
	public resetLabel = {
		name : 'Reset',
		nameTrans : 'dashboard.reset'
	};
	public runReportLabel = {
		name : 'Run Report',
		nameTrans : ''
	};

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(lioModalService)		public lioModalService 		:lioModalService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(coursesService) 		public coursesService 		:coursesService,
		@Inject(querySettings) 			public querySettings 		:querySettings,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(stateService) 			public stateService 		:stateService,
		@Inject(ccfService) 			public ccfService 			:ccfService,
		@Inject(fieldService) 			public fieldService 		:fieldService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService
	){
		this.debugService.register('queryBuilder', this);

		this.subscriptions.add(
			this.coursesService.courses.subscribe(() => {
				this.ready = true;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize the filters and rules
	 * @param {array} filters
	 * @param {array} rules
	 * @return {boolean}
	*/
	init(filters, rules) {
		this._filters = this.removeDuplicates(filters);
		
		if (!this._filters) {
			this._filters = [];
			return false;
		}

		rules = this.addRequiredRules(rules);
		rules = this.removeUnknownRules(rules, filters);

		// Fix if nothing left
		if (!rules.length) {
			if (!this.settings.suppressDefaultRules) {
				this.lioLogService.log('No rules left after init, reverting to default');
				rules = this.querySettings.defaultRules;
			}
			// In event the field editor takes out the employeeID we need to remove it from here too'
			rules = this.removeUnknownRules(rules, filters);
		}

		// If no rules exist, just pick the first filter
		if (!rules.length) {
			rules = [filters[0]];
		}

		this._rules = rules;

		return true;
	}

	/*
	 * Creates the query builder
	*/
	create(filters, rules) {
		if (!this.init(filters, rules)) {
			console.error('Failed to initialize builder');
			return;
		}

		return true;
	}


	/*
	 * Adds required rules based on a permisison string
	 * @param {Object} filters
	 * @return {Object}
	*/
	addRequiredRules(rules) {
		let alreadyAdded 	= false,
			value 			= null,
			operator 		= null,
			rule 			= {};
		
		if (!rules) {
			rules = [];
		}

		if (this.settings.suppressRequiredRules) {
			return;
		}

		this._filters.forEach((filter) => {
			if (filter.required) {
				alreadyAdded = false;
				if (this.utilService.isString(filter.required) && !this.permissionService.hasPermission(filter.required)) {
					filter.required = false;
					return;
				}

				rules.forEach((rule) => {
					if (rule.field === filter.field) {
						rule.required = true;
						alreadyAdded = true;
						return;
					}
				});
				
				if (!alreadyAdded) {
					value = null;
					operator = null;
					if (filter.options) {
						let firstAllowedOption = null;
						filter.options.forEach((option) => {
							if(!firstAllowedOption && (!option.locked || this.permissionService.hasPermission(option.locked))){
								firstAllowedOption = option;
							}
						});
						if(firstAllowedOption){
							value = firstAllowedOption.value;
						}
					}
					if (filter.operators) {
						operator = filter.operators[0].value;
					}
					rule = {
						field		: filter.field,
						operator	: operator,
						value		: value,
						required	: true,
					};
					this.lioLogService.log(['Adding required rule', rule]);
					rules.push(rule);
				}
			}
		});

		return rules;
	}

	/*
	 * Removes unknown rules that may have at one time existed
	 * @param {array} rawRules
	 * @param {array} filters
	 * @return {array}
	*/
	removeUnknownRules(rawRules, filters) {
		let rules = [];
		
		if (!rawRules) {
			rawRules = [];
		}

		rawRules.forEach((rule) => {
			let matched = false;
			filters.forEach((filter) => {
				if (rule.field === filter.field) {
					matched = true;
					return;
				}
			});
			if (matched) {
				rules.push(rule);
			} else {
				if (this.stateService.isAtHomeCompany()) {
					if (!this.settings.suppressUnknownRuleError) {
						this.lioModalService.show('error.filterPermissionNotAllowed');
					}
				}
				this.lioLogService.log(['Rule was not understood or permission was missing', rule]);
			}
		});

		return rules;
	}

	/*
	 * Removes duplicates from the filters
	 * @param {array} filters
	 * @return {array}
	*/
	removeDuplicates(filters) {
		let cleanFilters = [],
			usedIDS = [],
			id;

		filters.forEach((filter) => {			
			id = filter.field;
			if (!id) {
				this.lioLogService.log(['FILTER IS MISSING ID', filter]);
				return;
			}

			// Check permissions while we're at it
			if (this.utilService.isString(filter.locked) && !this.permissionService.hasPermission(filter.locked)) {
				filter.required = false;
				return;
			}

			// Check if the filter is not visible
			if (!filter.visible) {
				return;
			}

			// Check if the filter is already in the array
			if (!this.utilService.valueinArray(usedIDS, id)) {
				cleanFilters.push(filter);
				usedIDS.push(id);
			}
		});

		if (!cleanFilters.length) {
			this.lioLogService.log('NO FILTERS FOUND');
			return null;
		}
		return cleanFilters;
	}

	/*
	 * Permissions Values to remove filters that are not available
	 * @param {array} filter
	 * @return {array}
	*/
	permissionValues(filter) {
		let options = [];

		filter.options.forEach((value) => {
			// Check permissions while we're at it
			if (this.utilService.isString(value.locked) && !this.permissionService.hasPermission(value.locked)) {
				return;
			}

			options.push(value);
		});

		return options;
	}

	/*
	 * Adds a query rule
	*/
	addRule() {
		let filter = this._filters[0],
			rule = {
			'field': filter.field,
			'operator': filter.operators[0].value,
			'value': '',
		};

		this._rules.push(rule);
	}

	/**
	 * Gets the total number of rules including invalid ones
	 */
	getRuleCount(){
		return this._rules.length;
	}

	/*
	 * Resets the query rules
	*/
	reset() {
		let rules	= this.utilService.copy(this.querySettings.defaultRules);
		rules		= this.addRequiredRules(rules);
		this._rules 	= rules;
	}

	/*
	 * Validates the current filters
	*/
	isValid() {
		if (!this.ready) {
			return false;
		}

		this.revalidate.emit();

		this._rules.forEach((rule) => {
			if (rule.error) {
				this.validRules = false;
			}
		});

		this.lioLogService.log('Validate');
		return this.validRules; 
	}

	/*
	 * Converts a date type query value into the proper format
	 * @param {string} date
	 * @param {string} formatTo
	 * @return {string}
	*/
	parseRuleDate(date, formatTo) {
		if (!date) {
			return '';
		}
		let formats = [formatTo, 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY/DD/MM'];
		let formattedDate = moment(date, formats).format(formatTo);
		
		if (formattedDate == "Invalid date") {
			this.lioLogService.log(['Builder Invalid date', date]);
			formattedDate = null;
		}
		return formattedDate;
	}

	/*
	 * Gets the rules without errors
	 * @return {array}
	*/
	getCleanRules() {
		let rules = this._rules,
			isValid = true,
			cleanRules = [];
		
		rules.forEach((rule) => {
			rule = this.modifyRules(rule);
			if (this.utilService.isDefined(rule.value) && rule.value !== '') {
				cleanRules.push(rule);
			} else {
				rule.error = true;
				isValid = false;
			}
		});

		this.validRules = isValid;

		return cleanRules;
	}

	/*
	 * Gets the rules
	 * @return {array}
	*/
	getRules() {
		return this._rules;
	}

	/*
	 * Loops through the value of a rule to parse the date 
	 * @param {array} rule
	 * @param {string} formatTo 
	*/
	parseRuleDates(rule, formatTo) {
		let i;
		
		if (Array.isArray(rule['value'])) {
			for (i = 0; i < rule['value'].length; i++) {
				rule['value'][i] = this.parseRuleDate(rule['value'][i], formatTo);
			}
		} else {
			rule['value'] = this.parseRuleDate(rule['value'], formatTo);
		}
		return rule;
	}

	/*
	 * Modifies rules based on the filter format for that rule
	 * @param {array} rule
	 * @return {array} rule
	*/
	modifyRules(rule) {
		let filters = this._filters,
			filter,
			total = filters.length,
			i;

		// Modify by format
		for (i = 0; i < total; i++) {
			filter = filters[i];
			if (filter.field == rule.field) {
				rule.type = filter.type;
				if (filter.format) {
					rule.format = filter.format;
					rule.formatParam = filter.formatParam;
				}
				// Adds additional settings for the backend
				if (filter.settings) {
					rule.settings = filter.settings;
				}
			}
		}

		// Modify by type
		if (rule['type'] === 'date' && rule['operator'] !== 'within_past_days') {
			rule = this.parseRuleDates(rule, 'MM/DD/YYYY');
		}

		return rule;
	}

	/*
	 * Removes specific default filters based on the page's filter settings
	 * @param {array} baseFilters
	 * @return {array}
	*/
	removeDefault(baseFilters) {
		this._filters.forEach((filter) => {
			if (filter.removeDefault) {
				baseFilters.forEach((baseFilter) => {
					if (baseFilter.field == filter.removeDefault) {
						let index = baseFilters.indexOf(baseFilter);
						baseFilters.splice(index, 1);
					}
				});
			}
		});
		return baseFilters;
	}

	/*
	 * Preps the filters
	*/
	prepFilters() {
		let baseFilters = this.utilService.shallowCopy(this.querySettings.filters),
			roleFilter = this.permissionService.getRoleFilter(),
			langFilter = this.localizationService.getLangFilter();


		langFilter.visible = this.settings.addLangFilter;
		this._filters.push(langFilter);

		if (this.settings.addRoleFilter == 1) {
			this._filters.push(roleFilter);
		}

		if (this.settings.addBaseFilters == 1) {
			baseFilters = this.removeDefault(baseFilters);
			this._filters = baseFilters.concat(this._filters);
		}

		if (this.settings.addCCFtoFilters == 1) {
			this._filters = this.ccfService.addCCFtoFilters(this._filters);
		}



		// The Field Overrider is expecting a filter model
		this._filters.forEach((filter) => {
			filter.model = filter.field;
		});

		return this.fieldService.setFields(this._filters, {'overrideFields': true, 'fieldName': this.settings.filterOverrideID}).then((filters:any) => {
			this._filters = filters;
			this.cleanFilters = this.formatFilters();
		});
	}

	/*
	 * Sets the filters operators
	*/
	setOperators() {
		this._filters.forEach((filter) => {
			let operatorsByType		= this.utilService.copy(this.querySettings.operatorsByType),
				booleanValues 		= this.utilService.copy(this.querySettings.booleanValues);

			if (!filter.operators) {
				switch (filter.type) {
					case 'string':
						filter.operators = operatorsByType.default;
						break;
					case 'boolean':
						filter.operators = operatorsByType.boolean;
						filter.options = booleanValues;
						break;
					case 'date':
						filter.operators = operatorsByType.date;
						break;
					case 'select':
						if (filter.multiple) {
							filter.operators = operatorsByType.multiSelect;
						} else {
							filter.operators = operatorsByType.select;
						}
						break;
					default:
						filter.operators = this.querySettings.operatorsByType.default;
						break;
				}
			}
		});
	}

	/*
	 * Formats overridden filters
	 * @return {object}
	*/
	formatFilters() {
		this.setOperators();
		this.coursesService.get();
		let filters = this.utilService.shallowCopy(this._filters);
		return this.removeDuplicates(filters);
	}

	/*
	 * Set up
	*/
	setUp() {
		this.ready		= false;
		this._filters	= [];
		this._rules		= [];
	}

	/*
	 * Deletes a rule
	*/
	deleteRule(rule) {
		let index = this._rules.indexOf(rule);
		if (index > -1) {
			this._rules.splice(index, 1);
		}
	}

	/*
	 * Runs the report
	*/
	run() {
		if (!this._rules.length) {
			this.feedbackService.addError('error.createAtLeastOneRule');
			return;
		}

		this.runReport.emit();
	}

	/*
	 * Updated rules
	*/
	updatedRules(rules) {
		this.lioLogService.log(['updated rules', rules]);
		this._filters = this.utilService.shallowCopy(this.filters);
		this._rules = rules;
		this.prepFilters().then(() => {
			this.create(this._filters, this._rules);
			this.building = false;
			if (!this.built) {
				this.builderReady.emit(true);
			}
			this.built = true;
		});
	}
	
	/*
	 * Creates the builder
	*/
	createBuilder() {
		if (!this.filters) {
			return;
		}
		this.building = true;
		this._filters = this.utilService.shallowCopy(this.filters); 
		this.prepFilters().then(() => {
			this.create(this._filters, this._rules);
			this.building = false;
			if (!this.built) {
				this.builderReady.emit(true);
			}
			this.built = true;
		});
	}
}