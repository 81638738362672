import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../services/debug.service';
import { navService } from '../../services/nav.service';
import { loaderService } from '../../services/loader.service';
import { storageService } from '../../services/storage.service';
import { lmsService } from '../../services/lms.service';
import { passwordResetService } from '../../services/password-reset.service';
import { lioModalService } from '../../services/lio-modal.service';
import { permissionService } from '../../services/permissions.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { loginService } from '../../services/login.service';
import { localizationService } from '../../services/localization.service';

import { forgotPasswordSettings } from './forgot-pass.settings';

@Component({
	selector: 'lio-forgot-password',
	templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnDestroy {
	public fields			:Array<any> 	= [];
	public initialFields	:Array<any> 	= [];
	public showLink			:boolean 		= false;
	public showSuccess		:boolean 		= false;
	public showCode			:boolean 		= true;
	public allowSubmit		:boolean 		= false;
	public employee			:any 			= {};
	public chooseLangField	:any  			= this.forgotPasswordSettings.chooseLangField;

	public localeStrings 	:any 			= {
		title							:'Forgot Your Password?',
		titleTrans						:'forgotpass.title',
		subtitle						:'Enter either your <b>e-mail address</b> or your <b>employeeID</b> along with the <b>Company Name</b><br />and we\'ll send you a link to reset your password.',
		subtitleTrans					:'forgotpass.subtitle',
		receiveAnEmail					:'You will receive an email with a password reset code',
		receiveAnEmailTrans				:'forgotpass.receiveAnEmail',
		pleaseEnterNewPass				:'Please enter your new password',
		pleaseEnterNewPassTrans			:'forgotpass.pleaseEnterNewPass',
		pleaseEnterResetCode			:'Please enter the password reset code along with your new password.',
		pleaseEnterResetCodeTrans		:'forgotpass.pleaseEnterResetCode',
		passwordValidationMessage		:'Password must be at least 8 characters, and contain any 3 of the following 4: a lowercase letter, an uppercase letter, a number, or a non-alphabetic character (such as ?,!,$,%,*,^)',
		passwordValidationMessageTrans	:'forgotpass.passwordValidationMessage',
		submit							:'Submit',
		submitTrans						:'forgotpass.submit',
		logIn							:'Log In',
		logInTrans						:'forgotpass.logIn',
	};
	public localeStringsKeys:Array<any> 	= [
		'title',
		'subtitle',
		'receiveAnEmail',
		'pleaseEnterNewPass',
		'pleaseEnterResetCode',
		'passwordValidationMessage',
		'submit',
		'logIn'
	];

	private params			:any 			= null;
	private companyID		:string 		= '';
	private employeeID		:string 		= '';
	
	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(debugService) 			public debugService				:debugService,
		@Inject(navService) 			public navService				:navService,
		@Inject(loaderService) 			public loaderService			:loaderService,
		@Inject(storageService) 		public storageService			:storageService,
		@Inject(lmsService) 			public lmsService				:lmsService,
		@Inject(passwordResetService) 	public passwordResetService		:passwordResetService,
		@Inject(lioModalService) 		public lioModalService			:lioModalService,
		@Inject(permissionService) 		public permissionService		:permissionService,
		@Inject(utilService) 			public utilService				:utilService,
		@Inject(feedbackService) 		public feedbackService			:feedbackService,
		@Inject(stateService) 			public stateService				:stateService,
		@Inject(loginService) 			public loginService				:loginService,
		@Inject(localizationService)	public localizationService		:localizationService,
		@Inject(forgotPasswordSettings) public forgotPasswordSettings	:forgotPasswordSettings
	){
		this.debugService.register('forgotpass', this);
		this.navService.setActivePage('forgotpass');

		this.navService.disableNav = false;

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		this.storageService.resetParams();
		this.params 	= this.storageService.getInitialParams();
		this.companyID 	= this.stateService.getActiveCompanyID();
		this.employeeID = this.storageService.get('employeeID');
		this.employee = {
			code		: '',
			password	: '',
			password2	: '',
			email		: null,
			companyID	: this.companyID,
			employeeID	: this.employeeID
		};

		this.subscriptions.add(
			this.loginService.notLoggedIn.subscribe(() => { 
				this.notLoggedIn(); 
			})
		);
	
		this.subscriptions.add(
			this.loginService.loggedIn.subscribe(() => { 
				this.loggedIn(); 
			})
		);
	
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => { 
				this.ready(); 
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * On update handler
	*/
	onUpdate() {
		let password = this.employee.password ? this.employee.password.trim() : null;
		let password2 = this.employee.password2 ? this.employee.password2.trim() : null;
		let code = this.employee.code ? this.employee.code.trim() : '';
		let valid = password || password2 ||  code;

		this.allowSubmit = valid;
	}

	/*
	 * Not logged in, show form
	*/
	notLoggedIn() {
		let params = this.params,
			link = params ? params.link : '',
			code = params ? params.code : '';

		if (link) {
			link = link.trim();
			link = link.match(/^[a-zA-Z0-9]+/);
			if (link) {
				link = link[0].trim();
			}
			
			if (link) {
				this.employee.code = link;
				this.getCompanyIDfromLink(link);
			}
		}

		if (code) {
			this.showLink = true;
			this.showCode = true;
		}
	}

	/*
	 * On Ready
	*/
	ready() {
		if (this.stateService.isLoggedIn()) {
			this.loggedIn();
			return;
		}
		this.fields 		= this.permissionService.setFields(this.forgotPasswordSettings.fields);
		this.initialFields 	= this.permissionService.setFields(this.forgotPasswordSettings.initialFields);
		if (this.employeeID && this.companyID && !this.employee.code) {
			this.submit();
		}
		this.navService.displayPage();
	}

	/*
	 * Not logged in, show form
	*/
	getCompanyIDfromLink(link) {
		this.lmsService.post('password/getCompanyIDFromLink', {'link': link}).then((result) => {
			let companyID = result.properties.companyID;
			if (companyID) {
				this.storageService.set('comapnyID', companyID);
				this.employee.companyID = companyID;
				this.showCode = false;
				this.showLink = true;
				this.employee.code = link;
			}
        });
	}

	/*
	 * Gets a form error
	 * @return {string}
	*/
	getFormError() {
		let password 	= this.employee.password 	? this.employee.password.trim() 	: null;
		let password2 	= this.employee.password2 	? this.employee.password2.trim() 	: null;
		let code 		= this.employee.code 		? this.employee.code.trim() 		: '';
		let error 		= this.utilService.getPasswordError(password);

		if (!password) {
			return this.utilService.localizeError('pleaseEnterPass');
		}

		if (!password2) {
			return this.utilService.localizeError('pleaseReEnterPass');
		}

		if (!code) {
			return this.utilService.localizeError('pleaseEnterPassResetCode');
		}


		if (password !== password2) {
			return this.utilService.localizeError('passDontMatch');
		}

		return error;
	}

	/*
	 * Determines if password meets complexity requirements
	 * @param {string} password
	 * @return {boolean}
	*/
	meetsComplexity(password) {
		let errors = [];

		if(password.length <= 7){
			errors.push('error.passMustBeAtLeast8Chars');
		}

		if(!this.utilService.hasNumber(password)){
			errors.push('error.passNoNumber');
		}

		if(!this.utilService.hasLower(password)){
			errors.push('error.passNoLower');
		}

		if(!this.utilService.hasUpper(password)){
			errors.push('error.passNoUpper');
		}

		if(!this.utilService.hasSpecialChar(password)){
			errors.push('error.passNoSpecial');
		}

		if(errors.length){
			this.feedbackService.setErrors(errors);
			return false;
		}

		return true;
	}

	/*
	 * Submits the password reset
	*/
	submitPasswordReset() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		let password = this.employee.password ? this.employee.password.trim() : null,
			password2 = this.employee.password2 ? this.employee.password2.trim() : null,
			code = this.employee.code ? this.employee.code.trim() : '',
			error = this.getFormError();

		if (error) {
			this.feedbackService.setError(error);
			return; 
		}

		if (!this.meetsComplexity(password2)) {
			return;
		}

		this.lioModalService.showLoading('processing');

		this.lmsService.post('password/changePasswordFromLink', {'link': code, 'password': password}).then((result) => {
			this.lioModalService.hideLoading();

			if (result.success) {
				this.showSuccess 	= true;
				this.showLink 		= false;
			} else if (error) {
				this.feedbackService.setError(error);
			}
        });
	}

	/*
	 * Submit Forgot password
	*/
	submit() {
		this.showLink = false;

		this.passwordResetService.resetRequest(this.employee).then((result) => {
			if (result) {
				this.showLink = true;
				this.lioModalService.show('emailOnWay');
			}
		});
	}

	/*
	 * Go to login
	*/
	login() {
		this.navService.goto('login');
	}

	/*
	 * Logged in
	*/
	loggedIn() {
		this.navService.goto('home');
	}

	changeLang() {
		if(this.employee.selectedLang){
			this.loaderService.changeLang(this.employee.selectedLang.code, this.employee.companyID);
			this.feedbackService.clearMessages();
			this.feedbackService.clearErrors();
		}
	}

	/*
	 * Retry
	*/
	retry() {
		this.showLink = false;
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
	}	
}