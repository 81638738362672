import { Injectable } from '@angular/core';

import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: accessLogSettings
})
export class accessLogSettings {
	pagination:any = {
			'pageLimit'	: '10',
			'pageLimits': ['10', '50', '100'],
			'sortMode'	: {field : 'date', direction : 'asc'}
	};

	fieldConfig:any = {
		'fieldName'			: 'accesssearch',
		'addCCFtoFields'	: false,
		'localizeFields'	: false,
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
	};

	searchFieldConfig:any = {
		'fieldName'			: 'accesssearch',
		'addCCFtoFields'	: false,
		'localizeFields'	: false,
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
	};

	searchFields:LioFieldArray = new LioFieldArray(
		{
			name	: 'UID',
			model	: 'UID',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Employee ID',
			model	: 'employeeID',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Company ID',
			model	: 'companyID',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Task',
			model	: 'task',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Date',
			model	: 'date',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Time',
			model	: 'time',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Role',
			model	: 'role',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Status',
			model	: 'status',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Response Size',
			model	: 'responseSize',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Browser',
			model	: 'browser',
			type	: 'text',
			visible	: true,
		},
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			name	: 'UID',
			model	: 'UID',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Company ID',
			model	: 'companyID',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Task',
			model	: 'task',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'employeeID',
			model	: 'employeeID',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Date',
			model	: 'date',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Time',
			model	: 'time',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Role',
			model	: 'role',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Status',
			model	: 'status',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Request Size',
			model	: 'requestSize',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Response Size',
			model	: 'responseSize',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Browser',
			model	: 'browser',
			type	: 'text',
			sortable: true
		},
	);
}