/*
 * PDF
*/
import { Inject, Injectable } from '@angular/core';


import { feedbackService } from './feedback.service';
import { lmsService } from './lms.service';
import { storageService } from './storage.service';
import { utilService } from './util.service';
import { lioModalService } from './lio-modal.service';

@Injectable({
	providedIn: 'root',
})
export class passwordResetService {
	constructor(
		@Inject(feedbackService)	private feedbackService	: feedbackService,
		@Inject(lmsService)			private lmsService		: lmsService,
		@Inject(storageService)		private storageService	: storageService,
		@Inject(utilService)		private utilService		: utilService,
		@Inject(lioModalService)	private lioModalService	: lioModalService,
	){

	}

	/*
 	* Requests a password reset 
 	* @param {array} employee
 	* @param {boolean} proxy - send the request for someone else 
 	* @return {Promise}
	*/
	resetRequest(employee, proxy:boolean = false) {
		let email = employee.email,
			employeeID = employee.employeeID,
			companyID = employee.companyID,
			langID = this.storageService.getLangID(),
			url = 'password/forgotPass';

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (proxy) {
			url = 'password/forgotPassProxy';
		}

		if (!employeeID && !companyID && !email) {
			this.feedbackService.setError('pleaseEnterEitherEmailOrEmployeeID');
			return new Promise((resolve) => { resolve(false); });
		}

		if (email) {
			if (!this.utilService.validateEmail(email)) {
				this.feedbackService.setError('pleaseEnterValidEmail');
				return new Promise((resolve) => { resolve(false); });
			}
		} else {
			if (employeeID && !companyID) {
				this.feedbackService.setError('pleaseEnterYourCompanyName');
				return new Promise((resolve) => { resolve(false); });
			}
			if (!employeeID && companyID) {
				this.feedbackService.setError('pleaseEnterYourEmployeeID');
				return new Promise((resolve) => { resolve(false); });
			}
		}


		this.lioModalService.showLoading('processing')
		return this.lmsService.post(url, {'email': email, 'employeeID': employeeID, 'companyID': companyID, 'langID': langID}).then((result:any) => {
			this.lioModalService.hideLoading()
			if (result.properties.sentEmail) {
				return true;
			}
			return false;
		});
	}
}