import { Injectable, Inject } from '@angular/core';
import { ccfService } from '../../../../services/ccf.service';

import { LioField, LioSearchableSelectField, LioFieldArray } from '../../../lio-forms/lio-forms.models';

export class LioFileFormatField extends LioField {
	public delimitter				:string;
	public rowIndex					:string;
	public active 					:string;
	public ignoreRow 				:string;

	constructor(obj:Partial<LioFileFormatField>){
		super(obj);
		Object.assign(this, obj);
	}
}

@Injectable({
	providedIn	: 'root', 
	useClass	: fileFormatSettings
})
export class fileFormatSettings {
	constructor(
		@Inject(ccfService) private ccfService:ccfService,
	){}

	fieldConfig:any = {
		fieldName			: 'fileformatconfig',
		localizeFields		: true,
		permissionFields	: true,
	};


	fields:LioFieldArray = new LioFieldArray(
		new LioSearchableSelectField({
			name:"Field Name",
			model:"fieldName",
			type:"select",
			required: false,
			min: 2,
			max: 2000,
			options: this.addOptions(),
			visible:true,
		}),
		new LioSearchableSelectField({
			name:"Delimitter",
			model:"delimitter",
			type:"select",
			required: false,
			min: 2,
			max: 2000,
			options:[
				{
					value: 'comma',
					name: 'Comma (,)',
				},
				{
					value: 'pipe',
					name: 'Pipe (|)',
				},
				{
					value: 'tab',
					name: 'Tab',
				},
				{
					value: 'return-r',
					name: 'Character Return (\\r)',
				},
				{
					value: 'return-n',
					name: 'Character Return (\\n)',
				},
				{
					value: 'return-rn',
					name: 'Character Return (\\r\\n)',
				}
			],
			visible:true,
		}),
		new LioSearchableSelectField({
			name:"Skip Field",
			model:"skipField",
			type:"select",
			required: false,
			min: 2,
			max: 2000,
			options:[
				{
					value: '1',
					name: 'True',
				},
				{
					value: '0',
					name: 'False',
				},
			],
			visible:true,
		}),
	)


	modalSettings:any = {
		'type'			: 'field-map',
		'title' 		: 'Field map',
		'fromField'		: new LioSearchableSelectField({
							name:"From Field",
							model:"mapFrom",
							type:"select",
							required: false,
							min: 2,
							max: 2000,
							options: this.addOptions(),
							visible:true,
							disabled:true
						}),
		'toField'		: new LioSearchableSelectField({
							name:"To Field",
							model:"mapTo",
							type:"select",
							required: false,
							min: 2,
							max: 2000,
							options: this.addOptions(),
							visible:true,
						}),
		'width'			: '50%',
		'suppressHide'	: true,
	};

	addOption(model:string, name:string) {
		return {
			value			: model,
			name			: name,
		}
	}

	addOptions() {
		let options = [];

		options.push(this.addOption('employeeID', 'Employee ID'));
		options.push(this.addOption('password', 'password'));
		options.push(this.addOption('firstName', 'First Name'));
		options.push(this.addOption('lastName', 'Last Name'));
		options.push(this.addOption('email', 'e-mail'));
		options.push(this.addOption('phone', 'Phone #'));
		options.push(this.addOption('areaCode', 'Area Code'));
		options.push(this.addOption('address1', 'Address'));
		options.push(this.addOption('address2', 'Address 2'));
		options.push(this.addOption('city', 'City'));
		options.push(this.addOption('state', 'State'));
		options.push(this.addOption('zipCode', 'Zip Code'));
		options.push(this.addOption('country', 'Country'));
		options.push(this.addOption('langID', 'Lang ID'));
		options.push(this.addOption('role', 'Role'));
		options.push(this.addOption('status', 'Status'));
		options.push(this.addOption('userCompany', 'User Company'));
		options.push(this.addOption('positionHeld', 'Position Held'));
		options = options.concat(this.ccfService.getCCFOptions());
		options.push(this.addOption('customField', 'Custom Field'));
		return options;
	}
}

