import { Component, Input, Inject } from '@angular/core';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { storageService } from '../../../../services/storage.service';
import { navService } from '../../../../services/nav.service';

@Component({
	selector: 'lio-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.css'],
})
export class LioPieChart  {
	@Input() width	:number = 160;
	@Input() height	:number = 160;
	@Input() contrast:string = '';

	private completed	:number = 0;
	private total		:number = 0;

	private _data:SingleDataSet = [0,100];
	@Input() set data(value:any){
		this.completed 	= value[0];
		this.total 		= value[1];

		this._data = [this.completed, this.total - this.completed];
	}
	get data():any{
		return this._data;
	}

	@Input()
	set skipAnimation(val) {
		if(val){
			this.pieChartOptions.animation.duration = 0;
		}else{
			this.pieChartOptions.animation.duration = 1000;
		}
	}
	get skipAnimation(): boolean {
		return this.skipAnimation;
	} 

	public pieChartLabels: Label[] 			= ['Complete', 'Incomplete'];
	public pieChartLegend 					= false;
	public pieChartPlugins:Array<any>		= [
		pluginDataLabels,
		{
			id: 'custom_canvas_background_color',
			beforeDraw: (chart) => {
				const ctx = chart.canvas.getContext('2d');
				ctx.save();
				ctx.globalCompositeOperation = 'destination-over';
				ctx.fillStyle = 'white';
				ctx.fillRect(0, 0, chart.width, chart.height);
				ctx.restore();
			}
		}];

	// Pie


	public pieChartColors = [{
		backgroundColor: [
			'#000',    // color for data at index 0
			'#333',   // color for data at index 1
		]
	}];

	public pieChartOptions:any = {
		responsive: false,
		rotation: 0,
		elements: {
			arc: {
				borderWidth: 0
			}
		},
		plugins : {
			datalabels: {
				formatter: (value:number) => {
					if(value > 0){
						let formattedValue 	= Math.floor(value / this.total * 10000);
						formattedValue 		= formattedValue / 100;
						
						return formattedValue + '%';
					}else{
						return '';
					}
				},
				color: '#fff',
				font: {
					weight: 'bold',
					size: 12
				},
			}
		},
		animation: {
			duration: 1000
		}
	};

	constructor(
		@Inject(storageService)		public 	storageService		:storageService,
		@Inject(navService)			public 	navService			:navService
	) {
		monkeyPatchChartJsTooltip();
		monkeyPatchChartJsLegend();

		this.navService.contrastChange.subscribe((contrast) => {
			this.contrast = contrast;
			this.setColors();
		})
		this.contrast = this.storageService.get('contrast');
		this.setColors();
	}

	setColors() {
		if (this.contrast) {
			this.pieChartColors = [{
				backgroundColor: [
					'#000',    // color for data at index 0
					'#333',   // color for data at index 1
				]
			}];
			this.pieChartOptions.plugins.datalabels.color = '#fff';
		} else {
			this.pieChartColors = [{
				backgroundColor: [
					'#137676',    // color for data at index 0
					'#999999',   // color for data at index 1
				]
			}];
			this.pieChartOptions.plugins.datalabels.color = '#000';
		}
	}
}
