import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
	selector: 'lio-form-inputs-table-group',
	templateUrl: './form-inputs-table-group.component.html',
})
export class LioFormInputsTableGroup {
	private _parentGroup:FormGroup;
	public get parentGroup():FormGroup{
		return this._parentGroup;
	}
	@Input() public set	parentGroup(val:FormGroup){
		this._parentGroup = val;
		val.addControl('formInputs', this.formGroup);
	}

	public formGroup:FormGroup = new FormGroup({});

	@Output() onUpdate	:EventEmitter<any> 		= new EventEmitter();
	@Output() resetField:EventEmitter<any> 		= new EventEmitter();
	@Input() model		:any;
	@Input() groups		:any;
	@Input() appearance:MatFormFieldAppearance = 'fill';
}