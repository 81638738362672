
/*
 * Service for Controlling Lanching of courses
*/
import { Inject, Injectable } from '@angular/core';

import { lioModalService } from './lio-modal.service';
import { localizationService } from './localization.service';
import { navService } from './nav.service';
import { stateService } from './state.service';
import { storageService } from './storage.service';
import { utilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class notificationsService {
	notification 		: any;
	initialized 		: boolean;
	notifications 		: Array<any>;
	viewedNotifications : Array<any>;
	notificationIDs 	: Array<any>;

	constructor(
		@Inject(lioModalService)		private lioModalService		: lioModalService,
		@Inject(localizationService)	private localizationService	: localizationService,
		@Inject(navService)				private navService			: navService,
		@Inject(stateService)			private stateService		: stateService,
		@Inject(storageService)			private storageService		: storageService,
		@Inject(utilService)			private utilService			: utilService
	){
		this.notification 			= null;
		this.initialized 			= false;
		this.notifications 			= [];
		this.viewedNotifications 	= [];
		this.notificationIDs 		= [];
	}

	/*
	 * Gets the launch URL
	 * @param {array} notifications
	*/
	init(notifications = []) {
		var id;

		notifications.forEach((notification) => {
			this.notification = notification;
			if (this.shouldShow(notification)) {
				id = notification.id;
				if (!this.utilService.valueinArray(this.notificationIDs, id)) {
					this.notifications.push(notification);
					this.notificationIDs.push(id);
				}
			}
		});

		if (!this.initialized) {
			this.navService.displayedPage.subscribe(() => { this.process();});
			this.initialized = true;
		}
	}

	/*
	 * Reset
	*/
	reset() {
		this.notifications = [];
	}

	/*
	 * Should we show the notification
	 * @param {array} notification
	 * @return {boolean}
	*/
	shouldShow(notification) {
		var title = notification.title,
			shouldShow = true,
			storedViewedNotifications = this.storageService.get('viewedNotifications'),
			viewedNotifications = this.viewedNotifications,
			companyID = this.stateService.getActiveCompanyID();

		if (storedViewedNotifications) {
			storedViewedNotifications.forEach((viewedNotification) => {
				if (viewedNotification == title) {
					shouldShow = false;
				}
			});
		}

		if (viewedNotifications) {
			viewedNotifications.forEach((viewedNotification) => {
				if (viewedNotification == title) {
					shouldShow = false;
				}
			});
		}

		if (companyID != notification.companyID) {
			if (notification.companyID != 'GLOBAL') {
				shouldShow = false;
			}
		}

		return shouldShow;
	}

	/*
	 * Process
	*/
	process() {
		var currentPage = this.navService.page,
			locked = this.navService.locked,
			index = 0;

			this.notifications.forEach((notification) => {

			if (locked) {
				return;
			}	

			if (notification.page == currentPage) {
				this.notification = notification;
				this.show();
				index = this.notifications.indexOf(notification);
				this.notifications.splice(index, 1);
			} else if (!notification.page && this.stateService.isLoggedIn()) {
				this.notification = notification;
				this.show();
				index = this.notifications.indexOf(notification);
				this.notifications.splice(index, 1);
			}
		});
	}

	/*
	 * Shows the notification
	*/
	show() {
		var title = this.notification.title,
			content = this.notification.text;

		if (!this.shouldShow(this.notification)) {
			return;
		}

		setTimeout(() => {
			this.lioModalService.show(title, content).then(() => {
				this.onclose();
			});
		}, 750);
	}

	/*
	 * Localization
	 * @param {string} text
	 * return {string}
	*/
	localize(text) {
		var localized = this.localizationService.get('notification.' + text);
		if (localized) {
			return localized;
		}
		return text;
	}

	/*
	 * On Close Handler
	*/
	onclose() {
		var storedNotifications = this.storageService.get('viewedNotifications'),
			viewedNotifications = [this.notification.title];

		this.viewedNotifications.push(this.notification.title);

		if (storedNotifications) {
			storedNotifications.forEach((storedNotification) => {
				viewedNotifications.push(storedNotification);
			});
		}

		if (!this.notification.persistent) {
			this.storageService.set('viewedNotifications', viewedNotifications);
		}
	}
}