
<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'description', 'close']">
	
	<!-- Close Button -->
	<button (click)="close()" [hidden]="!settings.canClose" class="button-hide modal-closer float-right">
		<i class="modal-close fa fa-times text-secondary"></i>
        <span class="sr-only">Close Modal</span>
	</button>

	<!-- Header -->
	<header class="modal-header">
		<h5 id="am_modal_title" class="modal-title mx-auto">{{settings.title}}</h5>
	</header>


	<!-- Form -->
	<section class="modal-content">
		<form name="ang_field_map_modalForm" role="form" autocomplete="off">
			<!-- Fields -->
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">From Field*</label>
				<div class="col-sm-10">
					<lio-searchable-select
						[model]="settings"
						[name]="settings.fromField.name"
						[field]="settings.fromField"
						[appearance]="appearance">
					</lio-searchable-select>
				</div>
			</div>
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">To Field*</label>
				<div class="col-sm-10">
					<lio-searchable-select
						[model]="settings"
						[name]="settings.toField.name"
						[field]="settings.toField"
						[appearance]="appearance">
					</lio-searchable-select>
				</div>
			</div>
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">Map*</label>
				<div class="col-sm-10">
					<div class="col-11" *ngFor="let map of settings.maps; let index = index">
						<div class="row">
							<mat-form-field class="col-md-10" [appearance]="appearance">
								<input
									matInput
									name="key{{index}}"
									placeholder="Map Key"
									[(ngModel)]="settings.maps[index]['mapKey']"
									type="text"
									maxlength="100"/>
							</mat-form-field>
							<mat-form-field class="col-md-10" [appearance]="appearance">
								<input
									matInput
									name="value{{index}}"
									placeholder="Map Value"
									[(ngModel)]="map['mapValue']"
									type="text"
									maxlength="100"/>
							</mat-form-field>
							<div class="col-md-1">
								<button mat-button color="warn" (click)="removeMap(index)" class="fa fa-minus-square fa-2x"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</section>

	<!-- Feedback -->
	<section class="feedback">
		<div *ngIf="error" class="error mx-auto">
			<span class="input-group-text">{{error}}</span>
		</div>
	</section>

		<!-- Footer -->
	<footer class="modal-footer">
		<button class="btn btn-sm btn-primary" mat-raised-button id="modal_action_button" (click)="addMap()">{{settings.addMap}}</button>
		<button class="btn btn-sm btn-primary" mat-raised-button id="modal_action_button" (click)="prepareAndSubmit()">{{settings.submit}}</button>
		<button [hidden]="!settings.canClose" class="btn btn-sm btn-primary" mat-raised-button id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>
