import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: registerSettings
})
export class registerSettings {
	pagination:any = {
			'pageLimit'	: '5',
			'pageLimits': ['5', '10', '50', '100'],
			'sortMode'	: {field : 'lastName', direction : 'asc'}
	};

	fieldConfig:any = {
		'fieldName'			: 'register',
		'permission'		: 'bypass',
		'url'				: 'fields/getSelfReg',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'langField'			: {
			'locked': false
		},
		'ccfFields': {
		}
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name: 'Employee ID',
			model: 'employeeID',
			type: 'text',
			required: true,
			min: 2,
			max: 255,
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true,
		},
		{
			name: 'First Name',
			model: 'firstName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'email',
			required: true,
			checkForHTMlInEmail: true,
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g
		},
		{
			name: 'Address',
			model: 'address1',
			type: 'text',
			required: false,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'Address 2',
			model: 'address2',
			type: 'text',
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'City',
			model: 'city',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'State',
			model: 'state',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'Country',
			model: 'country',
			type: 'text',
			min: 2,
			max: 60,
			varchar: false,
			required: false,
			checkForSpecialChars: false,
			checkForHTMl: true

		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type: 'text',
			required: false,
			checkForSpecialChars: true,
			checkForHTMl: true
		},
		{
			name: 'Phone #',
			model: 'phone',
			type: 'text',
			required: false,
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type: 'date',
			required: false,
			locked: true,
			visible: 'employee.viewAddDate'
		},
	);
}