import { Inject, Injectable } from '@angular/core';
import { lioModalService } from '../../services/lio-modal.service';
import { enrollmentEventService } from '../../services/enrollment-event.service';

import { querySettings } from '../../settings/query.settings';
import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';
import { LioButtonField } from '../../modules/lio-forms/models/button-field.model';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEnrollmentEventListSettings
})
export class scheduleEnrollmentEventListSettings {
	constructor(
		@Inject(lioModalService) 		private lioModalService			:lioModalService,
		@Inject(enrollmentEventService)	private enrollmentEventService	:enrollmentEventService,
		@Inject(querySettings) 			private querySettings			:querySettings
	){}

	appearance:string = 'outline';

	pagination:any = {
		id				: 'scheduleenrollmenteventlist',
		currentPage		: 1,
		offset			: 0,
		pageLimit		: '10',
		pageLimits		: ['10', '50', '100'],
		sortMode		: {field : 'schedule.startTime', direction : 'desc'}
	};

	fieldConfig:any = {
		'fieldName'			: 'scheduleenrollmenteventlist',
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
		'addLangField'		: false,
		'addActiveField'	: false,
	};


	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			model		: 'name',
			type		: 'text',
			required	: true,
			visible		: true,
			sortable	: true
		},
		new LioButtonField({
			name			: 'Description',
			model			: 'description',
			disabledModel 	: 'description',
			type			: 'button',
			callback		: (event) => {
				this.lioModalService.show('descriptionOf', event.description, {key : 'describedObject', value : event.name})
			},
			required		: true,
			visible			: true,
			sortable		: true,
		}),
		new LioSearchableSelectField({
			name		: 'Type',
			model		: 'type',
			type		: 'select',
			options 	: [
				{name : 'Course Overdue'	, value : 'Past Due'},
				{name : 'Course Registered'	, value : 'Registration'},
				{name : 'Reminder'			, value : 'Reminder'}],
			required	: true,
			visible		: true,
			sortable	: true
		}),
		{
			name		: 'Start Date',
			model		: 'startTime',
			type		: 'date',
			visible		: true,
			sortable	: true
		},
		{
			name		: 'Course',
			model		: 'courseID',
			type		: 'text',
			required	: true,
			visible		: true,
			sortable	: true
		},
		new LioButtonField({
			name				: 'enrollment Filters',
			model				: 'filters',
			disabledModel	 	: 'filters',
			type				: 'button',
			callback			: (event) => {
				this.enrollmentEventService.showFilters(event);
			},
			visible				: true
		}),
		new LioButtonField({
			name				: 'Current User Count',
			model				: 'totalUsers',
			gotResult			: 'totalUsers',
			type	 			: 'button',
			callback			: (event) => {
				this.enrollmentEventService.showTotalUsers(event);
			},
			disabledModel 		: 'id',
			visible				: true
		}),
		new LioButtonField({
			name			: 'Schedule',
			model			: 'recurranceDay',
			type			: 'button',
			callback		: (event) => {
				this.enrollmentEventService.showSchedule(event);
			},
			disabledModel 	: 'id',
			visible 		: true
		}),
		new LioSearchableSelectField({
			name		: 'Frequency',
			model		: 'recurranceType',
			type		: 'select',
			visible		: true,
			sortable	: true,
			options		:  [
				{
					'value': 'once',
					'name': 'Once',
				},
				{
					'value': 'daily',
					'name': 'Daily',
				},
				{
					'value': 'weekly',
					'name': 'Weekly',
				},
				{
					'value': 'monthly',
					'name': 'Monthly',
				}
			]
		}),
	);

	prefixButtons:Array<any> = [];
	
	suffixButtons:Array<any> = [
		{
			name 		: 'Active',
			id 			: 'am_eventschedule_active',
			color 		: 'warn',
			dynamicColor: 'activeColor',
			dynamicName	: 'activeStatus',
			callback	: (event) => {
				this.enrollmentEventService.changeState(event);
			},
		},
		{
			name 		: 'Edit',
			id 			: 'am_eventschedule_edit',
			color 		: 'primary',
			callback	: (event) => {
				this.enrollmentEventService.loadEventForEditing(event);
			},
		},
		{
			name 		: 'Delete',
			id 			: 'am_eventschedule_delete',
			color 		: 'warn',
			callback	: (event) => {
				this.enrollmentEventService.deleteEvent(event);
			},
		},
		{
			name 		: 'Manage Emails',
			id 			: 'am_eventschedule_goto',
			color 		: 'primary',
			callback	: (event) => {
				this.enrollmentEventService.manageEmails(event);
			},
		}
	];

	eventFormFieldConfig:any = {
		'fieldName'			: 'scheduleEnrollments',
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
		'addLangField'		: false,
		'addActiveField'	: false,
	};

	allowedFilterValues:Array<any> = [
		'fn.daysuntilcoursedue',
		'fn.dayspastdue'
	] 

	filterTemplates:any = {
		'Past Due':{
			name				: 'Enter minimum number of days past due date',
			nanError 			: 'The value entered was not a number',
			minError			: 'The minimum number of days past the due date must be greater than 0',
			maxError			: 'The minimum number of days past the due date must be less than 10000',
			field				: 'fn.dayspastdue',
			type				: 'number',
			operator			: '>=',
			value				: 1,
			min					: 1,
			max					: 10000,
			required			: true

		},
		'Reminder' : {
			name		: 'Enter number of days until Date',		
			nanError	: 'The value entered was not a number',
			minError	: 'The number of days until the due date must be greater than 0',
			maxError	: 'The number of days until the due date must be less than 10000',
			field 		: 'fn.daysuntilcoursedue',
			type 		: 'number',
			operator 	: '<',
			value 		: 1,
			min			: 1,
			max			: 10000,
			required	: true
		}
	};

	weekDays:Array<any> = [
		{name: 'sun', trans : 'scheduleEnrollments.sundayShort'		, value: 'sunday'},
		{name: 'mon', trans : 'scheduleEnrollments.mondayShort'		, value: 'monday'},
		{name: 'tue', trans : 'scheduleEnrollments.tuesdayShort'		, value: 'tuesday'},
		{name: 'wed', trans : 'scheduleEnrollments.wednesdayShort'	, value: 'wednesday'},
		{name: 'thu', trans : 'scheduleEnrollments.thursdayShort'	, value: 'thursday'},
		{name: 'fri', trans : 'scheduleEnrollments.fridayShort'		, value: 'friday'},
		{name: 'sat', trans : 'scheduleEnrollments.saturdayShort'	, value: 'saturday'}
	];

	filters:any = [
		{
			'field': 'ec.courseCompletion',
			'label': 'form.courseCompleted',
			'type': 'select',
			'visible': true,
			'width': '200px',
			'options': [
				{
					'value': 1,
					'name': 'Completed',
					'nameTrans': 'form.completed'
				},
				{
					'value': 0,
					'name': 'Incomplete',
					'nameTrans': 'form.incomplete'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	

		},
	];

	builderSettings:any = {
		'type'					: 'scheduledEnrollments',
		'addBaseFilters'		: true,
		'hasButtons'			: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'usesQueryTool'			: false,
		'showSaver'				: false,
		'filterOverrideID' 		: 'scheduledEnrollments',
		'hideDelete' 			: false,
		'hideRunReport'			: true,
	};
}