import { Injectable } from '@angular/core';
import { LioField, LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: profileSettings
})
export class profileSettings {
	fieldsSet:boolean = false;

	panelConfigs:any = {
		profile : {
			id				: 'panelTab_profile_edit',
			title			: 'Profile',
			titleTrans		: 'profile.header',
			tab				: 'Profile',
			tabTrans		: 'profile.header',
			reloadOnTabChange : true,
		},
		enrollments : {
			id				: 'panelTab_profile_enrollments',
			title			: 'Enrollments',
			titleTrans		: 'profile.enrollments',
			tab				: 'Enrollments',
			tabTrans		: 'profile.enrollments',
			reloadOnTabChange : true,
		},
		loginHistory: {
			id				: 'panelTab_profile_loginHistory',
			title			: 'Login History',
			titleTrans		: 'profile.loginHistory',
			tab				: 'Login History',
			tabTrans		: 'profile.loginHistory',
			reloadOnTabChange : true,
		},
		courseHistory: {
			id				: 'panelTab_profile_courseHistory',
			title			: 'Course History',
			titleTrans		: 'profile.courseHistory',
			tab				: 'Course History',
			tabTrans		: 'profile.courseHistory',
			reloadOnTabChange : true,
		},
		emailHistory: {
			id				: 'panelTab_profile_emailHistory',
			title			: 'Email History',
			titleTrans		: 'profile.emailHistory',
			tab				: 'Email History',
			tabTrans		: 'profile.emailHistory',
			reloadOnTabChange : true,
		}
	};

	externalConfig:any = {
		fieldName			: 'externalProfile',
		addCCFtoFields		: true,
		localizeFields		: true,
		permissionFields	: true,
		addRoleToFields		: true,
		overrideFields		: true,
		addLangField		: true,
		addActiveField		: true,
		ccfFields: {
			locked: 'employee.updateCCF'
		},
	};

	personalConfig:any = {
		fieldName			: 'profile',
		addCCFtoFields		: true,
		localizeFields		: true,
		permissionFields	: true,
		addRoleToFields		: true,
		overrideFields		: true,
		addLangField		: true,
		addActiveField		: true,
		ccfFields: {
			locked: 'self.updateCCF',
		},
		activeField			: {
			locked: true
		},
		roleField			: {
			locked: true
		},
	};

	personalFields:LioFieldArray = new LioFieldArray(
		{
			name: 'Employee ID',
			model: 'employeeID',
			type: 'text',
			required: true,
			min: 2,
			max: 200,
			locked: 'employee.updateEmployeeID',
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true
		},
		{
			name: 'First Name',
			model: 'firstName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'email',
			required: true,
			checkForHTMlInEmail: true,
			checkForSpecialChars: true,
			blockEditHigherRole: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g
		},
		{
			name: 'Address',
			model: 'address1',
			type: 'text',
			required: false,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g
		},
		{
			name: 'Address 2',
			model: 'address2',
			type: 'text',
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g
		},
		{
			name: 'City',
			model: 'city',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'State',
			model: 'state',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Country',
			model: 'country',
			type: 'text',
			min: 2,
			max: 60,
			varchar: false,
			required: false,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForSpecialChars: false,
			checkForHTMl: true,
		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type: 'text',
			required: false,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Phone #',
			model: 'phone',
			type: 'text',
			max : 50,
			required: false,
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true,
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type: 'date',
			required: false,
			locked: true,
			visible: true
		},
		{
			name: 'Date Modified',
			model: 'updated',
			type: 'date',
			required: false,
			locked: 'employee.editAddDate',
			visible: 'employee.viewAddDate'
		},
		{
			name: 'Admin ID',
			model: 'AdminID',
			type: 'number',
			required: false,
			visible: 'employee.viewAdminID',
			locked: true
		},
		{
			name: 'Added By',
			model: 'addedBy',
			type: 'text',
			visible:  'employee.viewAddedBy',
			required: false,
			locked: true
		},
	);


	externalFields:LioFieldArray = new LioFieldArray(
		{
			name: 'Employee ID',
			model: 'employeeID',
			type: 'text',
			required: true,
			min: 2,
			max: 200,
			locked: 'employee.updateEmployeeID',
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true
		},
		{
			name: 'First Name',
			model: 'firstName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type: 'text',
			required: true,
			varchar: false,
			min: 2,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'email',
			required: true,
			checkForHTMlInEmail: true,
			checkForSpecialChars: true,
			blockEditHigherRole: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g
		},
		{
			name: 'Address',
			model: 'address1',
			type: 'text',
			required: false,
			max: 70,
			checkForSpecialChars: true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g
		},
		{
			name: 'Address 2',
			model: 'address2',
			type: 'text',
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g
		},
		{
			name: 'City',
			model: 'city',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'State',
			model: 'state',
			type: 'text',
			varchar: true,
			required: false,
			max: 60,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Country',
			model: 'country',
			type: 'text',
			min: 2,
			max: 60,
			varchar: false,
			required: false,
			checkForSpecialChars: false,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type: 'text',
			required: false,
			checkForSpecialChars: true,
			checkForHTMl: true,
		},
		{
			name: 'Phone #',
			model: 'phone',
			type: 'text',
			max : 50,
			required: false,
			checkForSpecialChars: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl: true,
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type: 'date',
			required: false,
			locked: true,
			visible: true
		},
		{
			name: 'Date Modified',
			model: 'updated',
			type: 'date',
			required: false,
			locked: 'employee.editAddDate',
			visible: 'employee.viewAddDate'
		},
		{
			name: 'Admin ID',
			model: 'AdminID',
			type: 'number',
			required: false,
			visible: 'employee.viewAdminID',
			locked: true
		},
		{
			name: 'SSO Login',
			model: 'isSSO',
			type: 'boolean',
			required: false,
			locked: 'employee.editSSO',
			visible: 'employee.viewSSO'
		},
		{
			name: 'Added By',
			model: 'addedBy',
			type: 'text',
			visible:  'employee.viewAddedBy',
			required: false,
			locked: true
		},
	);


	setPasswordField:LioField = new LioField({
		name		: 'New Password',
		model		: 'newPassword',
		type		: 'text',
		min 		: 5,
		required	: true,
		visible	: true
	});
}