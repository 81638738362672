import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn	: 'root', 
	useClass	: configSettings
})
export class configSettings {
	_version		: string 	= '';
	online			: boolean 	= true;
	loggedIn		: boolean 	= false;
	logAjax			: boolean 	= false;
	allowAsync		: boolean 	= true;
	allowDebug		: boolean 	= false;
	allowRetry		: boolean 	= true;
	serverGlobal	: string 	= '';
	SUPPORT_EMAIL	: string 	= '<a href="mailto:support@trueoffice.com">support@trueoffice.com</a>';
	corpediaServer	: string 	= 'https://www.corpedia.com/';
	disableLegacyComm : boolean	= false;

	constructor(
		@Inject(DOCUMENT) private document :Document
	){
		if(this.document){
			let winRef 			= this.document.defaultView;
			this._version 		= winRef['GLOBAL_VARS']['VERSION'];
			this.allowDebug 	= winRef['GLOBAL_VARS']['DEBUG_MODE'];
			this.disableLegacyComm = winRef['GLOBAL_VARS']['DISABLE_LEGACY_COMM'];

			winRef['lioConfig'] = this;
			this.setServerGlobal();
		}
	}

	setServerGlobal() {
		let winRef 			= this.document.defaultView;
		this.serverGlobal = winRef.location.protocol + '//' + winRef.location.hostname;
		if (winRef.location.port) {
			this.serverGlobal +=  ':' + winRef.location.port;
		}
		this.serverGlobal += '/';
	}

	public get version():string{
		return this._version;
	}
}