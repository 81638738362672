/*
 * Service for Controlling navigation
*/
declare global {
    interface Window { lioLogService: any; }
}

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class lioLogService{
	/**
	 * Logging Types - 
	 * 0 - None
	 * 1 - Normal
	 * 2 - Deferred
	 * 3 - Info
	 * 4 - Warn
	 * 5 - Error
	 */
	private loggingType : number;

	private defaultType: number = 1;

	private defferedLog : Array<any>;

	constructor(@Inject(DOCUMENT) private document: Document){
		this.loggingType = this.defaultType;
		this.defferedLog = [];

		this.document.defaultView.lioLogService = this;
	}

	/*
	 * Logs the message based on the logging type
	*/
	log(messages: any) {
		switch (this.loggingType) {
			case 0:
				// No Logging
				break;
			case 1:
				console.log(messages);
				break;
			case 2:
				this.defferedLog[this.defferedLog.length] = messages;
				break;
			case 3:
				console.info(messages);
				break;
			case 4:
				console.warn(messages);
				break;
			case 5:
				console.error(messages);
				break;
			default: 
				// No Logging
				break;

		}
	}

	/*
	 Info - 3
	*/
	info(messages: any) {
		this.loggingType = 3;
		this.log(messages);
		this.loggingType = this.defaultType;
	}

	/*
	 Warn - 4
	*/
	warn(messages: any) {
		this.loggingType = 4;
		this.log(messages);
		this.loggingType = this.defaultType;
	}

	/*
	 Error - 5
	*/
	error(messages: any) {
		this.loggingType = 5;
		this.log(messages);
		this.loggingType = this.defaultType;
	}

	/*
	 Dumps out deferred logs
	*/
	dump(){
		this.defferedLog.forEach(element => {
			console.log(element);
		});
		this.defferedLog = [];
	}
}