<div class="row" [hidden]="!errorsService.criticalErrors.length || !errorsService.showCriticalErrors">
	<div class="col-0 col-md-1"></div>
	<div class="col-12 col-md-10">
		<button mat-raised-button type="button" (click)="lmsService.resend()">Resend the backend call</button>
		<lio-card *ngFor="let criticalError of errorsService.criticalErrors">
			<div lio-card-body>
				<div *ngIf="!criticalError.key" [innerHtml]="criticalError | html"></div>
				<div *ngIf="criticalError.key" [innerHtml]="criticalError.key | html"></div>
			</div>
		</lio-card>
	</div>
</div>