import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { localizationService } from '../../services/localization.service';
import { coursesService } from '../../services/courses.service';
import { storageService } from '../../services/storage.service';
import { permissionService } from '../../services/permissions.service';
import { processingService } from '../../services/processing.service';
import { utilService } from '../../services/util.service';

import { assignSettings } from './assign.settings';


@Component({
	selector: 'lio-assign',
	templateUrl: './assign.component.html'
})
export class AssignComponent implements OnDestroy {
	public employees					:Array<any>		= [];
	public filteredEmployees			:Array<any>		= [];
	public pagination					:any			= this.assignSettings.pagination;
	public fields						:Array<any>		= this.assignSettings.fields;
	public formFields					:Array<any>		= this.assignSettings.formFields;
	public fieldConfig					:any			= this.utilService.copy(this.assignSettings.fieldConfig);
	public formFieldConfig				:any			= this.utilService.copy(this.assignSettings.formFieldConfig);
	public allowEnroll					:boolean		= false;
	public allowUnEnroll				:boolean		= false;
	public enrolledUsers				:boolean		= false;
	public blockAdditionalEnrollments	:boolean 		= false;
	public search						:any			= {};
	public model						:any			= this.utilService.copy(this.assignSettings.model);
	public localizations				:any			= this.assignSettings.localizations;

	public localeStrings				:any			= {
		title		: '',
		titleTrans	: 'assign.header'
	};
	public localeStringsKeys			:Array<any>		= [
		'title'
	];
	public localeStringsMacros			:Array<any>			= [{
		item	: 'title',
		key		: 'totalEmployees', 
		value	: () => { return this.employeeIDs.length; }
	}];

	private employeeIDs					:Array<any>		= [];
	private verifiedSameAvailableDate	:boolean		= false;
	private lastAvailableDate			:any			= {};

	private subscriptions				:Subscription	= NEVER.subscribe();

	constructor(
			@Inject(navService)					public	navService:navService,
			@Inject(debugService)			 	public	debugService:debugService,
			@Inject(feedbackService)			public	feedbackService:feedbackService,
			@Inject(errorsService)				public	errorsService:errorsService,
			@Inject(lmsService)					public	lmsService:lmsService,
			@Inject(lioLogService)				public	lioLogService:lioLogService,
			@Inject(stateService)			 	public	stateService:stateService,
			@Inject(workerService)			 	public	workerService:workerService,
			@Inject(permissionService) 			public	permissionService:permissionService,
			@Inject(utilService)			 	public	utilService:utilService,
			@Inject(coursesService)				public 	coursesService:coursesService,
			@Inject(storageService)				public	storageService:storageService,
			@Inject(processingService)			public	processingService:processingService,
			@Inject(localizationService)		public	localizationService:localizationService,
			@Inject(assignSettings)	 			public	assignSettings:assignSettings,
			@Inject(lioModalService)			public	lioModalService:lioModalService)
	{
		this.debugService.register('assign', this);
		this.navService.setActivePage('assign');
		
		this.processingService.allowCancel = false;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	/*
	 * Sets the courses
	*/
	setCourses() {
		this.coursesService.reset();
		this.coursesService.get();
	}

	init() {
		if (!this.getEmployees()) {
			this.navService.goto('enrollquery');
			return;
		}
		this.navService.displayPage();
		this.feedbackService.clearErrors();
		this.setCourses();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/*
	 * Sets the employees
	 * @return {boolean}
	*/
	getEmployees() {
		let employees = this.storageService.get('employeesToAssign');

		if (!employees) {
			return false;
		}

		employees.forEach((employee) => {
			this.employeeIDs.push(employee.employeeID);
			this.employees.push(employee);
		});

		return true;
	}

	/*
	 * Gets the enrollment object
	 * @return {object}
	*/
	getEnrollment():any {
		let enrollment:any 	= {};
		let format 			= 'yyyy-mm-dd';

		enrollment.courseID = this.model.courseID;
		enrollment.courseAvailableDate = this.utilService.formatDate(this.model.availableDate, format);
		enrollment.courseDueDate = this.utilService.formatDate(this.model.dueDate, format);
		enrollment.courseExpires = this.utilService.formatDate(this.model.expirationDate, format);
		return enrollment;
	}

	/*
	 * Go to the email users page
	*/
	sendEmailToEnrolled() {
		let employees 	= [];
		let employeeIDs = [];
		
		this.employees.forEach((employee) => {
			if (employee['enrolled_' + this.model.courseID]) {
				employees.push(employee);
				employeeIDs.push(employee.employeeID);
			}
		});

		this.storageService.set('employeesToEmail', employees);
		this.storageService.set('employeeIDsToEmail', employeeIDs);
		this.storageService.set('task', 'emailing');
		this.removeFromAssignments(employeeIDs);
	}

	/*
	 * Removes the employeeIDs from the enrolled users as they are being sent emails
	 * Technically they should be removed as they are emailed, but thats a bigger change
	 * @param {array} employeeIDs
	*/
	removeFromAssignments(employeeIDs) {
		let setter = {
			'processedIDs': employeeIDs,
			'employees': this.employees, 
			'key': 'employeeID',
			'setter': {'key': 'enrolled_' + this.model.courseID, 'value': false}
		};

		this.workerService.call('findAndSet', setter).then((response:any) => {
			this.storageService.setEmployeesToAssign(response.employees);
			this.navService.goto('email');
		});
	}

	/*
	 * On Update of the form, validate the form
	*/
	onUpdate = function(field) {
		if(field.model == 'courseID'){
			this.enrolledUsers = false;
			this.employees.forEach((employee) => {
				if (this.enrolledUsers) {
					return;
				}
				if (employee['enrolled_' + this.model.courseID]) {
					this.enrolledUsers = true;
				}
			});
		}

		this.allowEnroll = this.isAllowedToEnroll();
		this.allowUnEnroll = this.isAllowedToUnEnroll();
		this.updateExpirationDate();
		this.lastAvailableDate = this.utilService.copy(this.model.availableDate);
		this.verifiedSameAvailableDate = false; 
	}

	/*
	 * Updates the expiration date 365 days from the provided available date
	 * If the user is allowed to change the expiration data, don't auto update this
	*/
	updateExpirationDate() {
		if (this.permissionService.hasPermission('employeeCourses.modifyExpiration')) {
			if (this.lastAvailableDate === this.model.availableDate) {
				return;
			}
		}

		let availableDate = this.utilService.formatDate(this.model.availableDate, 'mm/dd/yyyy'),
			expirationDate = this.utilService.addOneYearFromProvidedDate(availableDate, 'mm/dd/yyyy');
		
		if (expirationDate) {
			this.model.expirationDate = expirationDate;
		}
	}

	/*
	 * Validates the un-enrollment
	 * @return {boolean}
	*/
	isAllowedToUnEnroll() {
		let isValid = false,
			enrollment = this.getEnrollment();

		if (
			enrollment.courseID
		) {
			isValid = true;
		}

		return isValid;
	}

	/*
	 * Validates the enrollment
	 * @return {boolean}
	*/
	isAllowedToEnroll() {
		let isValid = false,
			enrollment = this.getEnrollment();

		if (
			enrollment.courseID &&
			enrollment.courseAvailableDate &&
			enrollment.courseDueDate &&
			enrollment.courseExpires
			) {
			isValid = true;
		}

		return isValid;
	}

	/*
	 * Validates the dates
	*/
	isValidDates() {
		let enrollment = this.getEnrollment();

		if (!this.utilService.isFutureDate(enrollment.courseAvailableDate, -1)) {
			if (enrollment.courseAvailableDate !== '1950-01-01') {
				this.feedbackService.setError('availableDateCannotBePast');
				this.feedbackService.clearMessages();
				return false;
			}
		}

		if (!this.utilService.isFutureDate(enrollment.courseDueDate, -1)) {
			if (enrollment.courseDueDate !== '1900-01-01') {
				this.feedbackService.clearMessages();
				this.feedbackService.setError('dueDateCannotBePast');
				return false;
			}
		}

		if (!this.utilService.isFutureDate(enrollment.courseExpires, -1)) {
			this.feedbackService.clearMessages();
			this.feedbackService.setError('expirationDateCannotBePast');
			return false;
		}

		if (enrollment.courseAvailableDate > enrollment.courseDueDate) {
			this.feedbackService.clearMessages();
			this.feedbackService.setError('dueDateCannotPrecedeAvailableDate1');
			return false;
		}

		if (enrollment.courseDueDate > enrollment.courseExpires) {
			this.feedbackService.clearMessages();
			this.feedbackService.setError('dueDateCannotExceedExpirationDate');
			return false;
		}

		if (!this.verifiedSameAvailableDate && enrollment.courseAvailableDate === this.utilService.getDate(0, 'yyyy-mm-dd')) {
			this.lioModalService.confirm('assign.confirmSameAvailableDayModalTitle', 'assign.confirmSameAvailableDayModalBody').then((confirmed) => {
				this.verifiedSameAvailableDate = confirmed;
				if (confirmed) {
					this.enroll();
				}
			});
			return false;
		}

		return true;
	}

	/*
	 * Sets the enrolled course for sending emails
	*/
	setCourse() {
		let enrollment = this.getEnrollment();
		this.storageService.set('courseID', enrollment.courseID);
	}

	/*
	 * Submits the enrollment
	*/
	enroll() {
		if (!this.isAllowedToEnroll()) {
			return;
		}

		if (!this.isValidDates()) {
			return;
		}

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		let form = {'employeeIDs': this.employeeIDs, 'enrollment': this.getEnrollment()};

		this.lmsService.postAsync('employeeCourses/assignTraining', form, 'enrollingUsers', false).then((result) => {
			let token = result.properties.token;
	
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.assignTrainingCallback(gresult);
				});
			} else {
				this.assignTrainingCallback(result);
			}
		});
	}

	/*
	 * Submits the un-enrollment
	*/
	unenroll() {
		if (!this.isAllowedToUnEnroll()) {
			return;
		}
		
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		let form 	= {'employeeIDs': this.employeeIDs, 'enrollment': this.getEnrollment()};
		let url 	= 'employeeCourses/unassignTraining';

		this.lmsService.postAsync(url, form, 'processing', false).then((result) => {
			let token = result.properties.token;
	
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.unassignTrainingCallback(gresult);
				});
			} else {
				this.unassignTrainingCallback(result);
			}
		});
	}

	/*
	 * Assign training callback
	 * @param {array} result
	*/
	assignTrainingCallback(result:any) {
		let success 		= result.success;
		let properties 		= result.properties;
		let cancelRequested = properties.cancelRequested;
		let cancelled  		= properties.cancelled;
		let macros 			= [];
		let messages 		= [];

		this.lioModalService.hideLoading();

		if (cancelRequested || cancelled) {
			return;
		}

		if (success) {
			if (properties.errors && properties.errors.length) {
				macros.push({'key': 'failedNumber', 'value': properties.errors.length});
				messages.push(this.utilService.localizeMessage('usersFailedToEnroll', macros));
			}else if (properties.errors > 0){
				macros.push({'key': 'failedNumber', 'value': properties.errors});
				messages.push(this.utilService.localizeMessage('usersFailedToEnroll', macros));
			}

			if (properties.processed && properties.processed.length) {
				macros.push({'key': 'succcessNumber', 'value': properties.processed.length});
				messages.push(this.utilService.localizeMessage('usersSuccessfullyEnrolled', macros));
			}else if (properties.processed > 0){
				macros.push({'key': 'succcessNumber', 'value': properties.processed});
				messages.push(this.utilService.localizeMessage('usersSuccessfullyEnrolled', macros));
			}

			if (properties.skipped && properties.skipped.length) {
				macros.push({'key': 'skippedNumber', 'value': properties.skipped.length});
				messages.push(this.utilService.localizeMessage('usersWereAlreadyEnrolled', macros));
			}else if (properties.skipped > 0){
				macros.push({'key': 'skippedNumber', 'value': properties.skipped});
				messages.push(this.utilService.localizeMessage('usersWereAlreadyEnrolled', macros));
			}
			
			if (properties.inactive && properties.inactive.length) {
				macros.push({'key': 'inactiveUsersNumber', 'value': properties.inactive.length});
				messages.push(this.utilService.localizeMessage('inactiveUsersCantBeEnrolled', macros));
			}else if (properties.inactive > 0){
				macros.push({'key': 'inactiveUsersNumber', 'value': properties.inactive});
				messages.push(this.utilService.localizeMessage('inactiveUsersCantBeEnrolled', macros));
			}

			// THIS NEEDS TO BE MOVED TO A WORKER
			if (properties.processed && properties.processed.length) {
				this.lioModalService.showLoading('finalizing');

				let setter = {
					'processedIDs': properties.processed,
					'employees': this.employees, 
					'key': 'employeeID',
					'setter': {'key': 'enrolled_' + this.model.courseID, 'value': true}
				};

				this.workerService.call('findAndSet', setter).then((response:any) => {
					this.lioModalService.hideLoading();

					if (!response) {
						this.lioLogService.log('Failed to find and set');
						return;
					}
					if (response.totalMatched) {
						this.enrolledUsers = true;
					}
					this.employees = response.employees;
				});
			}
			this.setCourse();
		}

		this.feedbackService.setMessages(messages);
	}

	/*
	 * Un-assign training callback
	 * @param {array} result
	*/
	unassignTrainingCallback(result:any) {
		let success = result.success,
			properties = result.properties,
			cancelRequested = properties.cancelRequested,
			cancelled = properties.cancelled,
			macros = [],
			messages = [];


		if (cancelRequested || cancelled) {
			this.lioModalService.hideLoading();
			return;
		}

		if (success) {
			if (properties.errors.length) {
				macros.push({'key': 'failedNumber', 'value': properties.errors.length});
				messages.push(this.utilService.localizeMessage('usersFailedToUnEnroll', macros));
			}else if (properties.errors > 0) {
				macros.push({'key': 'failedNumber', 'value': properties.errors});
				messages.push(this.utilService.localizeMessage('usersFailedToUnEnroll', macros));
			}

			if (properties.processed.length) {
				macros.push({'key': 'succcessNumber', 'value': properties.processed.length});
				messages.push(this.utilService.localizeMessage('usersSuccessfullyUnenrolled', macros));
			}else if (properties.processed > 0) {
				macros.push({'key': 'succcessNumber', 'value': properties.processed});
				messages.push(this.utilService.localizeMessage('usersSuccessfullyUnenrolled', macros));
			}

			if (properties.skipped.length) {
				macros.push({'key': 'notEnrolledNumber', 'value': properties.skipped.length});
				messages.push(this.utilService.localizeMessage('usersWereNotAlreadyEnrolled', macros));
			}else if (properties.skipped > 0) {
				macros.push({'key': 'notEnrolledNumber', 'value': properties.skipped});
				messages.push(this.utilService.localizeMessage('usersWereNotAlreadyEnrolled', macros));
			}

			if (properties.inactive.length) {
				macros.push({'key': 'inactiveUsersNumber', 'value': properties.inactive.length});
				messages.push(this.utilService.localizeMessage('inactiveUsersCantBeUnenrolled', macros));
			}else if (properties.inactive > 0) {
				macros.push({'key': 'inactiveUsersNumber', 'value': properties.inactive});
				messages.push(this.utilService.localizeMessage('inactiveUsersCantBeUnenrolled', macros));
			}

			if (properties.alreadyCompleted.length) {
				macros.push({'key': 'totalCompleted', 'value': properties.alreadyCompleted.length});
				messages.push(this.utilService.localizeMessage('alreadyCompletedCannotUnenroll', macros));
			}else if (properties.alreadyCompleted > 0) {
				macros.push({'key': 'totalCompleted', 'value': properties.alreadyCompleted});
				messages.push(this.utilService.localizeMessage('alreadyCompletedCannotUnenroll', macros));
			}

			if (properties.alreadyStarted.length) {
				macros.push({'key': 'totalStarted', 'value': properties.alreadyStarted.length});
				messages.push(this.utilService.localizeMessage('alreadyStartedCannotUnenroll', macros));
			}else if (properties.alreadyStarted > 0) {
				macros.push({'key': 'totalStarted', 'value': properties.alreadyStarted});
				messages.push(this.utilService.localizeMessage('alreadyStartedCannotUnenroll', macros));
			}

			if (properties.processed.length) {
				let setter = {
					'processedIDs': properties.processed,
					'employees': this.employees, 
					'key': 'employeeID',
					'setter': {'key': 'enrolled_' + this.model.courseID, 'value': false}
				};

				this.lioModalService.showLoading('finalizing');

				this.workerService.call('findAndSet', setter).then((response:any) => {
					this.lioModalService.hideLoading();

					if (!response) {
						this.lioLogService.log('Failed to find and set');
						return;
					}
					this.employees = response.employees;
				});
			} else {
				this.lioModalService.hideLoading();
			}
		}

		this.enrolledUsers = false;

		this.feedbackService.setMessages(messages);
	}
}