import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-choose-company',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', '../input/input.component.css',  './choose-company.component.css']  

})
export class ChooseCompanyComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public override settings : any = {
		'id' : 'am_modal_choose_company',
		'title': 'Please select a company',
		'titleTrans': 'message.selectCompany',
		'canClose': true,
		'submit': 'Set Company',
		'submitTrans': 'messaige.setCompany',
		'close': 'Close',
		'optionName': 'Choose a Company',
		'optionNameTrans': 'user.chooseCompany',
		'selection': null,
		'options': [],
		'searchFail': 'No matching options found',
		'searchFailTrans': 'user.searchFail',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public override parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.formatOptions();
		this.selection = this.settings.selection;
		this.setSelectedCompany();
	}

	/*
	 * Format the options 
	*/
	formatOptions() : void {
		if (this.settings.options) {
			this.settings.options.forEach((option) => {
				option.name = option['companyID'] + ' : ' + option['coName'];
			});
		}
	}

	/*
	 * Sets the selected company 
	*/
	setSelectedCompany(): void {
		if (this.selection && this.settings.options) {
			this.settings.options.forEach((option) => {
				if (option['companyID'] == this.selection['companyID']) {
					this.selection = option;
				}
			});
		}
	}
}