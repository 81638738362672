import { Component, OnInit, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { fieldService } from '../../services/fields.service';
import { exportService } from '../../services/export.service';
import { permissionService } from '../../services/permissions.service';
import { ccfService } from '../../services/ccf.service';
import { reportsService } from '../../services/reports.service';
import { utilService } from '../../services/util.service';
import { reportSettings } from './reports.settings';
import { reportEditorSettings } from './report-editor.settings';


@Component({
  selector: 'lio-report-editor',
  templateUrl: './report-editor.component.html'
})
export class ReportEditorComponent implements OnInit, OnDestroy {

	public queryFilters:any 								= [];
	public reportTypes:any 									= [];
	public report:any 											= {};
	public fields:any 											= [];
	public combinedReports:any							= [];
	public availableReports:any							= [];
	public filteredReports:any							= [];
	public filters:any 											= [];
	public customReports:any								= [];
	public fieldConfig:any 									= {};
	public panelConfig:any 									= {};
	public searchFields:any 								= [];
	public searchFieldConfig:any 						= {};
	public pagination:any 									= null;
	public customReportType:any 						= null;
	public reportsFormatted:any 						= null;
	public querytool:any 										= {};
	public reportType:any 									= {};
	public filteredEmployees:any 						= [];
	public employees:any 										= [];
	public employeeIDs:any 									= [];
	public debugging:boolean 								= false;
	public allowSubmit:boolean 							= false;
	public enableQueryTool:boolean 					= false;
	public showQueryTool:boolean 						= false;
	public errorlog:any 										= null;
	public searchers:any 										= null;
	public serverName:any 									= null;
	public selectedField:any 								= null;
	public permissions:any 									= null;
	private subscriptions:Subscription 			= NEVER.subscribe();

  constructor(
		@Inject(navService) 							public 	navService:navService,
		@Inject(debugService)	 						public 	debugService:debugService,
		@Inject(feedbackService) 					public 	feedbackService:feedbackService,
		@Inject(errorsService) 						public 	errorsService:errorsService,
		@Inject(lmsService) 							public 	lmsService:lmsService,
		@Inject(lioLogService) 						public 	lioLogService:lioLogService,
		@Inject(stateService) 						public 	stateService:stateService,
		@Inject(workerService) 						public 	workerService:workerService,
		@Inject(permissionService)				public 	permissionService:permissionService,
		@Inject(lioModalService)					public 	lioModalService:lioModalService,
		@Inject(utilService)							public 	utilService:utilService,
		@Inject(exportService)						public 	exportService:exportService,
		@Inject(fieldService)							public 	fieldService:fieldService,
		@Inject(ccfService)								public 	ccfService:ccfService,
		@Inject(reportSettings) 					public 	reportSettings:reportSettings,
		@Inject(reportEditorSettings) 		public 	reportEditorSettings:reportEditorSettings,
		@Inject(reportsService) 					public 	reportsService:reportsService
	){
		this.debugService.register('reporteditor', this);
		this.navService.setActivePage('reporteditor');
		

		this.customReportType			= this.reportSettings.customReportType;
		this.queryFilters 				= this.reportSettings.baseFilters;
		this.reportTypes 					= this.reportSettings.reportTypes;
		this.panelConfig 					= this.reportEditorSettings.panelConfig;
		this.reportType 					= this.reportEditorSettings.reportType;
		this.reportsService.setReportTypes(this.reportTypes);
		this.reportsService.setCustomReportType(this.customReportType);


		this.permissions 				= this.permissionService;
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}


	/*
	 * Queries loaded, update the filtered reports
	*/
	queriesLoaded() {
		let queries = this.querytool.getQueries();
		this.reportsService.setFilteredReports(queries);
	}

	/*
	 * On update
	*/
	onUpdate(field) {
		this.report.canDownload = false;

		// Update the filtered reports based on the type 
		if (field.model == 'type') {
			this.report.selectedReport = {};
			this.updateQueryTool();
		}

		if (field.model == 'selectedReport') {
			this.loadSelectedReport();
		}
	}

	/**
	 * Grab some report info when a report is run
	 */
	onRun(queryInfo) {
		console.log('on run', queryInfo);
		this.report.selectedReport = queryInfo.model;
	}

		/*
	 * Sets the report type by the current selected report type
	 * @return {boolean}
	*/
	determineReportType() {
		this.reportType = this.reportsService.getReportType(this.report);
		if (this.reportType) {
			this.setQueryTool();
			return true;
		}
		return false;
	}

	/*
	 * Set the query tool
	*/
	setQueryTool() {
		let roleFilter 	= this.permissionService.getRoleFilter();

		this.filters = this.reportType.filters;
		this.filters = this.queryFilters.concat(this.filters);
		this.filters = this.ccfService.addCCFtoFilters(this.filters);
		this.filters.push(roleFilter);
		this.enableQueryTool = true;
	}

	/*
	 * Got report results
	*/	
	handleResponse(result) {
		console.log(result);
		if (result) {
			let records = result.properties.employees;
			this.getFieldsByType().then((fields) => {
				this.exportRecords(records, fields);
			});	
		}
	}

	/*
	 * The query tool is ready to go
	*/
	queryToolReady() {
		this.lioModalService.hideLoading();
	}

	/*
	 * Loads a selected report
	 * @return {Promise} 
	*/
	loadSelectedReport() {
		if(this.report.selectedReport){
			this.querytool.loadSelectedID(this.report.selectedReport.id);
			this.querytool.updatedModel();
			this.report.canDownload = true;
		}
	}	

	/*
	 * Gets the query (spiderman) report
	 * @param {object} report 
	*/
	download() {
		this.querytool.runReport();
	}

	/*
	 * Gets the fields by the report type
	*/
	getFieldsByType() {
		let fields = this.reportType.fields,
			fieldConfig = this.reportType.fieldConfig,
			type = this.reportType.type;

		if (!fieldConfig) {
			fieldConfig  = {
				'fieldName': type,
			};
		}

		// Custom reports need export turned on
		if (this.reportType.isCustom) {
			fields.forEach((field) => {
				field.export = true;
			});
		}
		return this.fieldService.setFields(fields, fieldConfig);
	}

	/*
	 * Exports the records
	 * @param {array} records
	 * @param {array} fields
	*/
	exportRecords(records, fields) {
		this.lioLogService.log(['Exporting', records, fields]);
		let name = this.report.selectedReport.name || 'Unsaved_Report_' + new Date().toString();
		this.exportService.exportReport(records, fields, name).then((result) => {
			if (!result) {
				this.lioModalService.show('Failed to export ' + name);
			} else {
				this.lioModalService.hideLoading();
			}
		});
	}

	/*
	 * Updates the query tool's type
	 * @return {Promise}
	*/
	updateQueryTool() {
		this.enableQueryTool = false;

		// Grab the queries
		if (!this.report) {
			this.reportType = this.reportTypes[0];
			this.filteredReports = [];
		}

		// Allow time for the query tool to disable and re-enable
		setTimeout(() => {
			if (!this.determineReportType()) {
				this.lioLogService.error('No matching report type');
				this.lioModalService.hideLoading();
				this.lioModalService.show('No reports found for this type');
			}
		}, 1000);

		this.lioModalService.showLoading('loadingRecords');
	}

	/*
	 * Gets all records and inits
	*/
	init() {
		this.reportsService.init().then(() => {
				this.navService.displayPage();
		});
	}

	handleClick($fn) {
		this[$fn]();
	}


	ngOnInit() {
		this.init();
		this.navService.displayPage();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
   }
}