import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { storageService } from '../../services/storage.service';
import { utilService } from '../../services/util.service';
import { loginService } from '../../services/login.service';
import { feedbackService } from '../../services/feedback.service';
import { lioModalService } from '../../services/lio-modal.service';
import { debugService } from '../../services/debug.service';
import { permissionService } from '../../services/permissions.service';

@Component({
	selector: 'lio-logout',
	templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnDestroy {
	public allowLogIn		:boolean 		= false;
	public localeStrings	:any 			= {
		header			:'You have successfully logged out of the LMS.',
		headerTrans		:'logout.header',
		subheader		:'You may now close this browser page.',
		subheaderTrans	:'logout.subheader',
		logbackin		:'Log back in',
		logbackinTrans	:'login.logbackin',
	};

	public localeStringsKeys:Array<any>		= [
		'header',
		'subheader',
		'logbackin'
	];

	private permissions		:any 			= this.storageService.get('permissions');
	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(navService)			public	navService		:navService,
		@Inject(permissionService)	public 	permissionService : permissionService,
		@Inject(storageService)		public	storageService	:storageService,
		@Inject(utilService)		public	utilService		:utilService,
		@Inject(loginService)		public	loginService	:loginService,
		@Inject(feedbackService)	public	feedbackService	:feedbackService,
		@Inject(lioModalService)	public	lioModalService	:lioModalService,
		@Inject(debugService)		public	debugService	:debugService
	){
		this.debugService.register('logout', this);
		this.navService.setActivePage('logout');

		if (!this.loginService.hasSession()) {
			this.navService.goto('login');
		} else {
			this.subscriptions.add(
				this.loginService.loggedIn.subscribe(() => {
					this.logout();
				})
			);
		}
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

    /*
	 * Goes to the login screen / SSO loging portal
	*/
    handledLogout(){
		let permissions 	= this.permissions,
			logoutLocation 	= permissions ? permissions.logoutLocation : null,
			allowSpiderSSO 	= permissions ? permissions.allowSpiderSSO : null;

		if (allowSpiderSSO == 1) {
			if (logoutLocation) {
				this.gotoExternal(logoutLocation);
				return;
			}
		} else {
			this.allowLogIn = true;
		}

		this.navService.locked = true;
		this.feedbackService.clearAll();
		this.navService.displayPage();
    }

    /*
	 * Takes the user outside of the LMS
	 * @param {string} location 
	*/
    gotoExternal(location){
		if (this.utilService.inString('https://', location) || this.utilService.inString('http://', location)) {
			// All good
		} else if (this.utilService.inString('www', location)) {
			location = 'https://' + location;
		}
		document.location.href = location;
    }

    /*
	 * Log out function
	*/
	logout(){
		this.feedbackService.clearAll();
		this.loginService.logout().then(() => {
			this.handledLogout();
		});
	}

	/*
	 * Log in by refreshing
	*/
	login(){
		this.lioModalService.showLoading('modal.reloading');
		this.navService.loading = true;
		setTimeout(() => {
			this.navService.reload();
		}, 1000);
	}	
}