import { Component, OnDestroy, Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { utilService } from '../../services/util.service';
import { profileSettings } from './profile.settings';

@Component({
	selector: 'profile',
	templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnDestroy {
	
	public panelConfigs		:any 		= this.utilService.copy(this.profileSettings.panelConfigs);
	public fields				:Array<any>		= [];
	public fieldConfig			:any			= null;
	
	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(profileSettings) 		public profileSettings		:profileSettings
	){
		this.navService.setActivePage('profile');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);

		this.fieldConfig = this.utilService.copy(this.profileSettings.externalConfig);
		this.fields = this.utilService.copy(this.profileSettings.externalFields);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.navService.displayPage();
	}

}