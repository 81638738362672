<div class="row rtl-direction">
	<lio-panel-tabber class="col-sm-12">
		<lio-panel-tab [settings]="panelConfig">
			<div *lioPanelTabContent class="container-fluid p-0">
				<div class="row">
					<lio-card-button *ngFor="let card of cards"
						[model]="card"
						[hidden]="!card.visible"
						(keyup)="onKeyUp($event)"
						(click)="card.onClick()">
					</lio-card-button>
				</div>					
			</div>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>