import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { errorsService } from '../../services/errors.service';
import { localizationService } from '../../services/localization.service';
import { stateService } from '../../services/state.service';
import { feedbackService } from '../../services/feedback.service';
import { permissionService } from '../../services/permissions.service';

import { configSettings } from '../../settings/config.settings';
import { errorLogSettings } from '../error-log/error-log.settings';

@Component({
	selector: 'lio-error-page',
	templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnDestroy {
	public error			:string 		= '';
	public detail			:string 		= '';
	public showLoading		:boolean 		= false;
	public allowRetry		:boolean 		= true;
	public allowSend		:boolean 		= true;
	public hasErrorID		:boolean 		= false;

	public localeStrings 	:any 			= {
		tryAgain		:'Try Again',
		tryAgainTrans	:'modal.tryAgain'
	};
	public localeStringsKeys:Array<any> 	= [
		'tryAgain'
	];

	private hasError		:boolean 		= false;
	private errorID			:string 		= '';
	private errorCode		:number 		= 0;
	private sendErrorToIT	:boolean 		= true;
	private sentError		:boolean		= false;

	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService			:navService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(errorsService)			public	errorsService		:errorsService,
		@Inject(localizationService)	public	localizationService	:localizationService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(configSettings)			public	configSettings		:configSettings,
		@Inject(errorLogSettings)		public	errorLogSettings	:errorLogSettings
	){
		this.debugService.register('errors', this);
		if (this.errorsService.errorCode) {
			this.errorCode = this.errorsService.errorCode;
			this.navService.setActivePage(this.errorsService.errorCode.toString());
		} else {
			this.navService.setActivePage('errors');
		}

		this.navService.changedForm = false;
		this.feedbackService.clearAll();

		this.setError();
		if (!this.errorsService.hasError() && !this.hasError) {
			this.navService.goHome();
			return;
		}
		this.navService.locked = true;
		this.navService.disableNav = true;
		this.lioModalService.hide();
		this.navService.displayPage();
		this.navService.scrollToTop();
		if (!this.sentError) {
			this.sentError = true;
			this.sendError();
		}
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
		this.navService.locked = false;
		this.navService.disableNav = false;
	}

	/* 
	 * Sets the error
	*/
	setError() {
		if (!this.navService.initialized) {
			return;
		}

		if (this.errorCode === 405) {
			this.sendErrorToIT 	= true;
			this.allowSend 		= true;
			this.hasError 		= true;
			this.detail 		= this.localizationService.get('error.takenBackToHomeShortly');
			this.error 			= this.localizationService.get('error.youDoNotHavePermissionToRunThisTask');
			this.errorCode 		= 405;
		}

		if (this.errorsService.getErrorID()) {
			this.hasErrorID = true;
		}
	}

	/* 
	 * Goes to the error
	*/
	gotoError() {
		var code = this.errorID;
		this.storageService.add('errorCode', code);
		this.navService.locked = false;
		this.navService.disableNav = false;
		this.navService.goto('errorlog');
	}

	/* 
	 * On response of alerting the admin
	 * @param {string} code
	*/
	gotCode(code) {
		code 		= code.toUpperCase();
		let macros 	= [{
			key		: 'code', 
			value	: code
		}];
		this.detail 		= this.localizationService.get('error.weAlertedTechnicians', '', macros);
		this.showLoading 	= false;
		this.allowRetry 	= true;
		this.hasErrorID 	= true;
	}

	/* 
	 * On response of failing to alert the admin
	*/
	noCode() {
		let code 	= this.errorsService.getTask();
		let detail 	= '';
		let macros 	= [
				{'key': 'code', 'value': code},
				{'key': 'supportEmail', 'value' : this.configSettings.SUPPORT_EMAIL}
			];

		if (code) {
			detail = this.localizationService.get('error.persistsWithCode', '', macros);
		} else {
			detail = this.localizationService.get('error.persistsWithOutCode', '', macros);
		}

		this.detail 		= detail;
		this.showLoading 	= false;
		this.allowRetry 	= true;
		this.detail 		= detail;
	}

	/* 
	 * Gets the error form to send
	 * @return {object}
	*/
	getErrorForm() {
		let errors 				= this.errorsService.getErrors();
		let task 				= this.errorsService.getTask();
		let browser 			= this.errorsService.getBrowser();
		let history 			= this.feedbackService.getHistory();
		let deniedPermissions 	= this.getDeniedPermissions();
		let lastRequest 		= this.storageService.getLastRequest();
		let form 				= {};

		if (!errors) {
			errors = [this.errorsService.lastResponse];
		}
		form = {'errors': errors, 'task': task, 'history': history, 'browser': browser, 'lastRequest': lastRequest, 'deniedPermissions': deniedPermissions};

		return form;
	}

	getDeniedPermissions() {
		let permissionResults = this.storageService.get('permissionResults'),
			deniedResults = [];
		
		if (!permissionResults) {
			return null;
		}
		
		let permissionKeys = Object.keys(permissionResults);
		permissionKeys.forEach((key) => {
			if (permissionResults[key].indexOf('rejected') > -1) {
				deniedResults.push(key);
			}
		});

		this.storageService.remove('permissionResults');
		return deniedResults;
	}

	/*
	 * Emails the error to us
	*/
	emailError(errorID) {
		let form 	= {
			errorID		: errorID,
			errorCode	: this.errorCode
		};

		this.errorID = errorID;
		this.errorsService.setErrorID(errorID);
		this.storageService.reset();

		this.lmsService.post('error/sendError', form).then((result) => {
			var sent = result.success;
			if (sent) {
				this.gotCode(errorID);
				this.feedbackService.clearHistory();
			} else {
				this.noCode();
			}
			this.navService.displayPage();
		});
	}

	/* 
	 * Sends the error to an admin
	*/
	sendError() {
		let	errorForm 	= this.getErrorForm();
		let errorID 	= this.errorsService.getErrorID();

		if (!this.sendErrorToIT) {
			return;
		}

		this.showLoading = true;
		this.allowSend = false;

		if (this.errorsService.loggedOffError) {
			this.errorsService.loggedOffError = false;
			return;
		}

		this.detail = this.localizationService.get('weAreAlertingTechnicians');
		
		// If an errorID was already created, we just need to email it
		if (errorID) {
			this.emailError(errorID);
			return;
		}

		// Otherwise create the error
		this.lmsService.post('error/createError', errorForm).then((result) => {
			var code = result.properties.code;
			if (code) {
				this.emailError(code);
				this.gotCode(code);
			} else {
				this.noCode();
			}
		});
	}

	/* 
	 * Go home
	*/
	goHome() {
		setTimeout(() => {
			this.navService.locked 		= false;
			this.navService.disableNav 	= false;
			this.navService.goHome();
			setTimeout(() => {
				this.navService.reload();
			});
		}, 8000);
	}

	/* 
	 * Try again
	*/
	retry() {
		// Reload the page
		this.lioModalService.showLoading('processing');
		setTimeout(() => {
			this.navService.reload();
		}, 1000);
	}
}