import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
	selector: 'lio-disclosure-button',
	templateUrl: './button.component.html',
})
export class LioDisclosureButton{
	@Output() response			:EventEmitter<any> 		= new EventEmitter();
	@Input() settings			:any 					= null;
	@Input() sendingResponse	:boolean				= false;
	@Input() appearance			:MatFormFieldAppearance = 'outline';

	private _button:any = null;
	public get button():any{
		return this._button;
	}
	@Input() public set button(val:any){
		if (!val.text) {
			val.text = 'Continue';
		}

		if (!val.hide) {
			val.hide = false;
		}

		this._button = val;
	}

	hide() {
		this.button.hide = true;
	}

	show() {
		this.button.hide = false;
		this.button.text = 'Continue';
	}
}