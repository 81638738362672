import { LioSearchableSelectField } from './searchable-select-field.model';


export class FilterOptionField extends LioSearchableSelectField{
	public operators:Array<any>;
	public settings:Array<any>;
	public default:any;

	constructor(obj:Partial<FilterOptionField>){
		super(obj);
		Object.assign(this, obj);
	}
}
