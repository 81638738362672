<!-- Keyworda -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Keywords</mat-card-title>
	<mat-card-content id="am_form_keywords" [style.fontSize.px]="11" class="mb-0" *ngFor="let keyword of model.keywords; let i = index">
		<div class="row no-gutters">
			<div class="col-md-8">
				<mat-form-field class="col" appearance="fill">
					<input id="{{'am_form_keywords_name_' + i}}" 
						matInput
						placeholder="Keyword Name" 
						[(ngModel)]="keyword.name" 
						type="text"
						(onUpdate)="onUpdate.emit($event)"
					/>
				</mat-form-field>
			</div>
			<div class="col-md-1">
				<mat-checkbox
					id="{{'am_form_keywords_name_' + i}}"
					[(ngModel)]="keyword.featured"
					(onUpdate)="onUpdate.emit($event)"
					title="Featured">
					Featured
				</mat-checkbox>
			</div>
			<div class="col-md-1">
				<button mat-button color="warn" (click)="removeKeyword(keyword)" class="fa fa-minus-square fa-2x button"></button>
			</div>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- New Keyword -->
		<button mat-button color="primary" (click)="addKeyword()">
			<span class="fa fa-plus-square fa-lg button mr-2"></span>
			<span>Keyword</span>
		</button>
	</mat-card-actions>
</mat-card>