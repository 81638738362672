<!-- Form Entry -->
<section class="row" *ngIf="!showSuccess" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="offset-md-2 col-md-5">
		<!-- Forgot Password form -->
		<lio-card *ngIf="!showLink">
			<div lio-card-header>
				<button (click)="navService.goback()" class="button-hide modal-closer float-right">
					<i class="modal-close fa fa-times text-secondary"></i>
        			<span class="sr-only">Close Page</span>
				</button>
				<h2 class="h4" id="am_forgotpass_title">{{localeStrings.title}}</h2>
				<p id="am_forgotpass_subtitle" [innerHtml]="localeStrings.subtitle | html"></p>
			</div>
			<div lio-card-body>
				<form role="form" novalidate>
					<lio-form-inputs-vertical
						[fields]="initialFields"
						[model]="employee"
						(onChanged)="onUpdate()">
					</lio-form-inputs-vertical>
				</form>
			</div>
			<div lio-card-footer>
				<button id="am_forgot_submit" 
					mat-flat-button
					color="primary"
					(click)="submit()" 
					type="submit">

					<strong>{{localeStrings.submit}}</strong>
				</button>
			</div>
		</lio-card>

		<!-- Password Reset Form -->
		<lio-card *ngIf="showLink">
			<div lio-card-header>
				<h3 class="h4" *ngIf="showCode">{{localeStrings.receiveAnEmail}}</h3>
				<h4 *ngIf="!showCode">{{localeStrings.pleaseEnterNewPass}}</h4>

				<p *ngIf="showCode" class="font-weight-bold">{{localeStrings.pleaseEnterResetCode}}</p>
				<p>{{localeStrings.passwordValidationMessage}}</p>
			</div>
			<div lio-card-body>
				<form role="form" autocomplete="new-password">
					<lio-form-inputs-vertical
						[fields]="fields"
						[model]="employee"
						(onChanged)="onUpdate()">
					</lio-form-inputs-vertical>
				</form>
			</div>
			<div lio-card-footer>
				<button id="am_forgot_submit" 
					mat-flat-button
					color="primary"
					[disabled]="!allowSubmit" 
					(click)="submitPasswordReset()" 
					type="submit">
					
					<strong>{{localeStrings.submit}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
	<div class="col-md-3">
		<!-- LANGUAGE SELECTOR -->    
		<lio-card headerId="am_forgotpass_chooseLangText" headerText="Choose Language" headerTrans="user.chooseLang" [hideErrors]="true">
			<div lio-card-body>
				<lio-searchable-select
					[model]="employee"
					[options]="localizationService.availableLanguages"
					[field]="chooseLangField"
					(onUpdate)="changeLang()"
					appearance="fill">
				</lio-searchable-select>
			</div>
		</lio-card>
	</div>
</section>

<!-- Password Reset success -->
<section *ngIf="showSuccess">
	<div class="col-md-8 offset-md-2">
		<lio-card headerTrans="forgotpass.successfullyReseted" 
			headerText="Your password was successfully reset, you may now log in using your new credentials" 
			bodyClass="text-center m-3">

			<div lio-card-body>
				<button (click)="login()" 
					mat-flat-button
					color="primary" 
					type="submit">
					<strong>{{localeStrings.logIn}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
</section>