import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { stateService } from '../../services/state.service';
import { processingService } from '../../services/processing.service';

import { documentationSettings } from './documentation.settings';

@Component({
	selector: 'lio-documentation',
	templateUrl: './documentation.component.html'
})
export class DocumentationComponent implements OnDestroy {
	public addUsersPanelConfig		:any 			= this.documentationSettings.addUsersPanelConfig;
	public enrollPanelConfig		:any 			= this.documentationSettings.enrollPanelConfig;
	public schedulingPanelConfig	:any 			= this.documentationSettings.schedulingPanelConfig;
	public workflowPanelConfig		:any 			= this.documentationSettings.workflowPanelConfig;
	public rolesPanelConfig			:any 			= this.documentationSettings.rolesPanelConfig;
	public launchPrepPanelConfig	:any 			= this.documentationSettings.launchPrepPanelConfig;
	public selfenrollPanelConfig	:any 			= this.documentationSettings.selfenrollPanelConfig;
	public otherPanelConfig			:any 			= this.documentationSettings.otherPanelConfig;

	private subscriptions			:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService				:navService,
		@Inject(debugService)			public	debugService			:debugService,
		@Inject(stateService)			public	stateService			:stateService,
		@Inject(processingService)		public	processingService		:processingService,
		@Inject(documentationSettings)	public	documentationSettings	:documentationSettings
	){
		this.debugService.register('documentation', this);
		this.navService.setActivePage('documentation');
		
		this.processingService.allowCancel = false;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.navService.displayPage();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}