<!-- Modules -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Modules</mat-card-title>
	<mat-card-content id="am_form_modules" [style.fontSize.px]="11" class="mb-0" *ngFor="let module of model.modules; index as i">
		<div class="row no-gutters">
			<div class="col-md-11" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<mat-form-field class="col" [appearance]="appearance">
					<input id="{{'am_form_modules_name_' + i}}" 
						matInput
						placeholder="Module Name" 
						[(ngModel)]="module.name" 
						type="text" 
						(change)="onUpdate.emit($event)"/>
				</mat-form-field>

				<mat-form-field class="col" [appearance]="appearance">
					<input id="{{'am_form_modules_description_' + i}}" 
						matInput
						placeholder="Module Description" 
						[(ngModel)]="module.description" 
						type="text" 
						(change)="onUpdate.emit($event)"/>
				</mat-form-field>

				<mat-form-field class="col" [appearance]="appearance">
					<input id="{{'am_form_modules_url_' + i}}" 
						matInput
						placeholder="Module URL or Course ID" 
						[(ngModel)]="module.url" 
						type="text" 
						(change)="onUpdate.emit($event)"/>
				</mat-form-field>

				<mat-form-field class="col" [appearance]="appearance">
					<input id="{{'am_form_modules_scriptLocation_' + i}}" 
						matInput
						placeholder="Module Script location" 
						[(ngModel)]="module.scriptLocation" 
						type="text"
						(change)="onUpdate.emit($event)"/>
				</mat-form-field>
			</div>
			<div class="col-md-1">
				<button mat-button color="warn" (click)="removeModule(i)" class="fa fa-minus-square fa-2x button"></button>
			</div>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- New Module -->
		<button mat-button color="primary" (click)="addModule()">
			<span class="fa fa-plus-square fa-lg button mr-2"></span>
			<span>Module</span>
		</button>
	</mat-card-actions>
</mat-card>