import { Inject, Injectable } from '@angular/core';
import { permissionService } from '../../services/permissions.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEmailSettings
})
export class scheduleEmailSettings {
	constructor(
		@Inject(permissionService) 			private permissionService:permissionService)
	{}

	panels:any = {
		emailTemplate : {
			id					: 'emails',
			tab					: 'Email Templates',
			tabTrans 			: 'scheduleemails.title',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEmails');
			}
		},
		edit: {
			id				: 'editor',
			title			: 'Schedule an Email',
			titleTrans		: 'scheduleEmails.eventFormTitle',
			subtitle		: 'Setup an Email to be sent on a schedule',
			subtitleTrans	: 'scheduleEmails.eventFormSubtitle',
			tab				: 'Schedule an Email',
			tabTrans		: 'scheduleEmails.eventFormTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEmails');
			}
		},
		list : {
			id				: 'list',
			title			: 'View and Edit Email Schedule',
			titleTrans		: 'scheduleEmails.eventListTitle',
			subtitle		: 'Active Email Schedules are colored green',
			subtitleTrans	: 'scheduleEmails.eventListSubtitle',
			tab				: 'Email Schedule',
			tabTrans		: 'scheduleEmails.eventListTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEmails');
			}
		},
		history : {
			id				: 'history',
			title			: 'Mail History',
			titleTrans		: 'scheduleEmails.eventHistoryTitle',
			subtitle		: 'Below is a list of Mail jobs that have run and who they sent emails to',
			subtitleTrans	: 'scheduleEmails.eventHistorySubtitle',
			tab				: 'Mail History',
			tabTrans		: 'scheduleEmails.eventHistoryTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEmailHistory');
			}
		},
	};
}