import { Inject, Injectable } from '@angular/core';

import { coursesService } from '../../services/courses.service';
import { utilService } from '../../services/util.service';
import { permissionService } from '../../services/permissions.service';

import { querySettings } from '../../settings/query.settings';

import { LioField, LioDateField, LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';
import { LioButtonField } from '../../modules/lio-forms/models/button-field.model';

@Injectable({
	providedIn	: 'root', 
	useClass	: enrollmentSettings
})
export class enrollmentSettings {
	constructor(
		@Inject(coursesService)		private coursesService		:coursesService,
		@Inject(permissionService) 	private permissionService	:permissionService,
		@Inject(utilService) 		private utilService			:utilService,
		@Inject(querySettings)		private querySettings		:querySettings
	){}

	localizations: any = {
		viewingUser 		: 'enrollments.viewingUser',
		totalCourses 		: 'dashboard.totalCourses',
		started 			: 'dashboard.started',
		totalEnrollments	: 'dashboard.totalEnrollments',
		completed 			: 'dashboard.completed',
		totalLogins 		: 'dashboard.totalLogins',
		totalActiveLogins	: 'dashboard.totalActiveLogins',
		completionHistory	: 'dashboard.completionHistory',
		thisWeek			: 'dashboard.thisWeek',
		lastWeek			: 'dashboard.lastWeek',
		thisMonth			: 'dashboard.thisMonth',
		lastMonth			: 'dashboard.lastMonth',
		markToComplete		: 'enrollments.markToComplete',
		assignCourses		: 'enrollments.assignCourses',
		exportRecords		: 'enrollments.exportRecords',
		editProfile			: 'user.editProfile',
		exportPDF			: 'global.exportPDF',
	};

	proccessTypeField:LioSearchableSelectField = new LioSearchableSelectField({
		amID			: 'am_delta_select',
		name			: 'Choose how you would like us to process an uploaded file',
		nameTrans 		: 'addusers.choose',
		model			: 'processType'
	});

	unenrollCourseField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'All employees listed in the uploaded file will be un-enrolled in course',
		nameTrans		: 'enrollquery.unenroll',
		amID			: 'am_course_select',
		model			: 'courseID',
		addEmptyOption	: true,
		optionNameField : 'nameID',
		optionValueField: 'courseID'
	});

	updateCourseField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'All employees listed in the uploaded file will be updated in course',
		nameTrans		: 'enrollquery.update',
		amID			: 'am_course_select',
		model			: 'courseID',
		addEmptyOption	: true,
		optionNameField : 'nameID',
		optionValueField: 'courseID'
	});

	completeCourseField:LioSearchableSelectField = new LioSearchableSelectField({
		name			:'All employees listed in the uploaded file will be marked as completed for this course',
		nameTrans		:'enrollquery.complete',
		amID			: 'am_course_select',
		model			: 'courseID',
		addEmptyOption	: true,
		optionNameField : 'nameID',
		optionValueField: 'courseID'
	});

	
	panelConfig:any = {
		'selectByFilter' : {
			id				: 'panelTab_enroll_filter',
			title			: 'Select Users By Filters',
			titleTrans		: 'enrollquery.title',
			subtitle		: 'Enter fields below to select the employees you would like to enroll',
			subtitleTrans	: 'enrollquery.subheader',
			tab				: 'Enroll Users',
			tabTrans		: 'enrollquery.selectByFilters',
			bodyClass 		: 'notaclass'
		},
		'selectByFile' : {
			id				: 'panelTab_enroll_file',
			title			: 'Select Users By File',
			titleTrans		: 'enrollquery.fileHeader',
			tab				: 'Bulk Upload',
			tabTrans		: 'enrollquery.selectByFile',
			visible			: () => { return this.permissionService.hasPermission('enrollment.bulkEnroll'); }
		}
	};

	queryToolSettings:any = {
		'type'					: 'enrollment',
		'addBaseFilters'		: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'addLangField'			: true,
		'usesQueryTool'			: true,
		'showSaver'				: true,
		'hasButtons'			: true,
		'filterOverrideID' 		: 'enrollQueryFilters',
		'text'				: {
			'runReport'			: 'enrollquery.assign',
		},
		'endpoints'				: {
			'get'					: 'query/getAllByType',
			'save'					: 'query/save',
			'delete'				: 'query/deleteByID',
			'report'				: 'enrollment/getEmployeesByRules',
		},
	};

	pagination:any = {
			'pageLimit'	: '5',
			'pageLimits': ['5', '10', '50', '100'],
			'sortMode'	: {field : 'courseID', direction : 'asc'}
	};

	defaultProcessType:any = 'ENROLL';

	processTypes:any = [
		{
			'value': 'ENROLL',
			'trans': 'assign.enrollUsers',
			'name': 'Enroll Users',
			'visible': 'enrollment.bulkEnroll',
			'selected': true,
			'sampleUsers': [
				{
					'employeeID': 'activeUser1',
				},
				{
					'employeeID': 'activeUser2',
				},
				{
					'employeeID': 'activeUser3',
				},
			],
			'sampleHeaders': [
				'Employee ID*',
			],
			'sampleFileName': 'enrollment_sample',
			'templateHeaders': [
				'Employee ID*',
			],
			'templateFileName': 'enrollment_template',
		},
		{
			'value': 'UNENROLL',
			'trans': 'assign.unenrollUsers',
			'name': 'Un-Enroll Users',
			'visible': 'enrollment.bulkUnenroll',
			'selected': false,
			'sampleUsers': [
				{
					'employeeID': 'activeUser1',
				},
				{
					'employeeID': 'activeUser2',
				},
				{
					'employeeID': 'activeUser3',
				},
			],
			'sampleHeaders': [
				'Employee ID*',
			],
			'sampleFileName': 'unenrollment_sample',
			'templateHeaders': [
				'Employee ID*',
			],
			'templateFileName': 'unenrollment_template',
		},
		{
			'value': 'UPDATE',
			'trans': 'assign.updateEnrollments',
			'visible': 'enrollment.bulkUpdate',
			'name': 'Update Enrollments',
			'selected': false,
			'sampleUsers': [
				{
					'employeeID': 'activeUser1',
					'Course Available Date': this.utilService.getDate(-5, 'mm/dd/yyyy'),
					'Course Due Date': this.utilService.getDate(30, 'mm/dd/yyyy'),
				},
				{
					'employeeID': 'activeUser2',
					'Course Available Date': this.utilService.getDate(5, 'mm/dd/yyyy'),
					'Course Due Date': this.utilService.getDate(45, 'mm/dd/yyyy'),
				},
				{
					'employeeID': 'activeUser3',
					'Course Available Date': this.utilService.getDate(15, 'mm/dd/yyyy'),
					'Course Due Date': this.utilService.getDate(90, 'mm/dd/yyyy'),
				}
			],
			'sampleHeaders': [
				'Employee ID*',
				'Course Available Date',
				'Course Due Date'
			],
			'sampleFileName': 'update_sample',
			'templateHeaders': [
				'Employee ID*',
				'Course Available Date',
				'Course Due Date'
			],
			'templateFileName': 'update_template',
		},
		{
			'value': 'COMPLETE',
			'trans': 'assign.completeEnrollments',
			'visible': 'enrollment.bulkComplete',
			'name': 'Complete Enrollments',
			'selected': false,
			'sampleUsers': [
				{
					'employeeID': 'activeUser1',
					'courseCompletionDate': this.utilService.getDate(-5, 'mm/dd/yyyy'),
				},
				{
					'employeeID': 'activeUser2',
					'courseCompletionDate': this.utilService.getDate(-15, 'mm/dd/yyyy'),
				},
				{
					'employeeID': 'activeUser3',
					'courseCompletionDate': this.utilService.getDate(0, 'mm/dd/yyyy'),
				},
			],
			'sampleHeaders': [
				'Employee ID*',
				'Course Completion Date'
			],
			'sampleFileName': 'completion_sample',
			'templateHeaders': [
				'Employee ID*',
				'Course Completion Date'
			],
			'templateFileName': 'completion_template',
		}
	];


	searchConfig:any = {
		'fieldName'			: 'enrollmentSearch',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'ccfFields': {
		},
		'roleField': {
			'viewAuthority': 'CREATE',
			'lockedAuthority': 'VIEW'
		}
	};

	fieldConfig:any = {
		'fieldName'			: 'enrollments',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addActiveField'	: true,
	};

	prefixButtons:any = [];


	fields:LioFieldArray = new LioFieldArray(
		new LioButtonField({
			'name'				: 'select',
			'nameTrans'			: 'form.select',
			'model'				: 'selectedForCourseCompletion',
			'type'				: 'checkselection',
			'disabledModel'		: 'disabledForCourseCompletion',
			'visible'			: 'employeeCourses.complete',
			'export'			: false,
			'class'				: 'center',
		}),
		{
			'name': 'employeeID',
			'model': 'employeeID',
			'type': 'text',
			'visible': false,
		},
		{
			'name': 'courseID',
			'model': 'courseID',
			'type': 'text',
			'class': 'center',
		},
		{
			'name': 'name',
			'model': 'courseName',
			'type': 'text',
		},
		{
			'name': 'required',
			'model': 'required',
			'type': 'checkbox',
			'class': 'center',
		},
		{
			'name': 'signupdate',
			'model': 'courseSignUpDate',
			'type': 'date',
			'class': 'center',
		},
		{
			'name': 'availabledate',
			'model': 'courseAvailableDate',
			'type': 'date',
			'class': 'center',
		},
		{
			'name': 'completiondate',
			'model': 'courseCompletionDate',
			'type': 'date',
			'class': 'center',
		},
		{
			'name': 'duedate',
			'model': 'courseDueDate',
			'type': 'date',
			'class': 'center',
		},
		{
			'name': 'expirationDate',
			'model': 'courseExpires',
			'type': 'date',
			'class': 'center',
		},
		{
			'name': 'started',
			'model': 'courseStarted',
			'type': 'checkbox',
			'class': 'center',
		},
		{
			'name': 'completed',
			'model': 'completed',
			'type': 'checkbox',
			'class': 'center',
		},
	);

	searches:any = [
		{
			'id': 'course_id',
			'name': 'courseID',
			'model': 'courseID',
			'type': 'text',
			'visible': true
		},
		{
			'id': 'course_name',
			'name': 'courseName',
			'model': 'courseName',
			'type': 'text',
			'visible': true
		},
		{
			'id': 'required',
			'name': 'required',
			'model': 'required',
			'type': 'select',
			'width': 300,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '1',
					'trans': 'form.requiredTrue',
					'name': 'True',
					'selected': false,
				},
				{
					'value': '0',
					'trans': 'form.requiredFalse',
					'name': 'False',
					'selected': false,
				}
			],
			'visible': true
		},
		{
			'id': 'completed',
			'name': 'completed',
			'model': 'completed',
			'type': 'select',
			'width': 300,
			'visible': true,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '1',
					'trans': 'form.completed',
					'name': 'Completed',
					'selected': false,
				},
				{
					'value': '0',
					'trans': 'form.incomplete',
					'name': 'Incomplete',
					'selected': false,
				}
			],
		},
		{
			'name': 'signupdate',
			'model': 'courseSignUpDate',
			'type': 'date',
			'visible': false
		},
		{
			'name': 'availabledate',
			'model': 'courseAvailableDate',
			'type': 'date',
			'visible': false
		},
		{
			'name': 'completiondate',
			'model': 'courseCompletionDate',
			'type': 'date',
			'visible': false
		},
		{
			'name': 'duedate',
			'model': 'courseDueDate',
			'type': 'date',
			'visible': false
		},
		{
			'name': 'expirationDate',
			'model': 'courseExpires',
			'type': 'date',
			'visible': false
		},
		{
			'name': 'started',
			'model': 'courseStarted',
			'type': 'select',
			'width': 300,
			'visible': false,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '1',
					'trans': 'form.true',
					'name': 'True',
					'selected': false,
				},
				{
					'value': '0',
					'trans': 'form.false',
					'name': 'False',
					'selected': false,
				}
			],
		},
	];

	filters:any = [
		{
			'field': 'ec.courseSignUpDate',
			'label': 'form.courseSignUpDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseAvailableDate',
			'label': 'form.courseAvailableDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseDueDate',
			'label': 'form.courseDueDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseExpires',
			'label': 'form.courseExpirationDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseCompletion',
			'label': 'form.courseCompleted',
			'type': 'select',
			'visible': true,
			'input': 'select',
			'width': '200px',
			'options': [
				{
					'value': 1,
					'name': 'Completed',
					'nameTrans': 'form.completed'
				},
				{
					'value': 0,
					'name': 'Incomplete',
					'nameTrans': 'form.incomplete'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	
		},
		{
			'field': 'fn.enrolled',
			'label': 'form.enrolled',
			'type': 'select',
			'visible': true,
			'multiple': true,
			'operators': [this.querySettings.operatorOptions.in],
			'disableOptionOverride' : true,
			'searchable': true,
			'options': this.coursesService.courses,
			'optionNameField': 'nameID',
			'optionValueField':'courseID'
		},
		{
			'field': 'fn.notEnrolled',
			'label': 'form.notEnrolled',
			'type': 'select',
			'visible': true,
			'multiple': true,
			'operators': [this.querySettings.operatorOptions.in],
			'disableOptionOverride' : true,
			'searchable': true,
			'options': this.coursesService.courses,
			'optionNameField': 'nameID',
			'optionValueField':'courseID'
		},
		{
			'field': 'fn.hierarchy',
			'label': 'form.hierarchy',
			'type': 'select',
			'visible': true,
			'operators': [this.querySettings.operatorOptions.equal],
			'options': [
				{
					'name': 'bypass',
					'value': 'bypass',
					'nameTrans': 'form.bypassHierarchy',
					'locked': 'filters.bypassSegregation'
				},
				{
					'name': 'subordinate',
					'value': 'subordinate',
					'nameTrans': 'form.subordinate',
					'locked': 'filters.subordinateOption'
				},
				{
					'name': 'region',
					'value': 'region',
					'nameTrans': 'form.region',
					'locked': 'filters.regionOption'
				},
				{
					'name': 'subordinateInRegion',
					'value': 'subordinateInRegion',
					'nameTrans': 'form.subordinateInRegion',
					'locked': 'filters.subordinateInRegionOption'
				},
			],
			'locked': 'filters.hierarchy',
			'required': 'filters.requireHierarchy',
			'default': true,
			'disableOptionOverride' : true,	
		}
	];

	completionDateField:LioField = new LioDateField({
		name		: 'CompletionDate',
		model		: 'completionDate',
		type		: 'date',
		required	: true,
		visible		: true
	});
}