import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { lioLogService } from './lio-log.service';
import { configSettings } from '../settings/config.settings';

@Injectable({
  providedIn: 'root'
})
export class debugService {
	public registry_:any = {};

	constructor(
		@Inject(DOCUMENT) 		private document		: Document,
		@Inject(lioLogService)	private lioLogService	: lioLogService,
		@Inject(configSettings)	private configSettings	: configSettings
	){
		this.exposeDebugger();
		this.register('debugger', this);
	}

	/*
	* Register a name with a class
	*/
	register(name, object, reset?:boolean) {
		if (this.configSettings.allowDebug) {
			if (!this.registry_[name.toLowerCase()] || reset) {
				this.registry_[name.toLowerCase()] = [];
			}
			this.registry_[name.toLowerCase()].push(object);
		}
	}

	/*
	* Gets all registered class name
	*/
	getRegistry() {
		if (this.configSettings.allowDebug) {
			return Object.keys(this.registry_);
		}
		return null;
	}

	/*
	* Gets a class
	*/
	get(name) {
		if (!this.registry_[name.toLowerCase()]) {
			this.lioLogService.error('This class is not registered');
			return null;
		}
		if (this.registry_[name.toLowerCase()].length == 1) {
			return this.registry_[name.toLowerCase()][0];
		}
		return this.registry_[name.toLowerCase()];
	}

	/*
	* Exposes the debugger
	*/
	exposeDebugger() {
		if (this.configSettings.allowDebug) {
			this.document.defaultView['getClass'] = (name) => {
				if (name) {
					return this.get(name); 
				}
			};
		}
	}
}