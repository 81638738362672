import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-catalog-notes',
	templateUrl: './notes.component.html',
})
export class LioCatalogNotes {
	@Input() appearance			:MatFormFieldAppearance = 'fill';
	@Input() model:any;
	@Input() update:any;
	@Input() field:any;
	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}


	removeNote(index) {
		this.model.notes.splice(index, 1);
		this.onUpdate.emit();
	}

	addNote() {
		this.model.notes.push({
			note: '',
		});
	}
}