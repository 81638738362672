<div class="row" *ngIf="!refreshing" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-8 mx-auto">
		<!-- Forgot form -->
		<lio-card bodyClass="pb-2" 
			[headerText]="isGlobal ? localeStrings.globalHeader : localeStrings.header"
			[subHeaderText]="localeStrings.subHeader">

			<div lio-card-body>
				<form role="form" autocomplete="off" novalidate>
					<!-- Inputs -->
					<lio-form-inputs-table-group
						[groups]="groups"
						[model]="settings"
						(resetField)="resetField($event)"
						(onUpdate)="onUpdate()">
					</lio-form-inputs-table-group>
				</form>
			</div>
			<div lio-card-footer>
				<button id="am_spiderSettings_update" 
					mat-flat-button
					color="primary"
					[disabled]="!allowSubmit" 
					(click)="submit()" 
					class="ml-1" 
					type="submit">

					<strong>{{localeStrings.update}}</strong>
				</button>
				<button id="am_spiderSettings_editGlobalSettings" 
					mat-flat-button
					color="primary"
					*ngIf="!isGlobal" 
					[hidden]="!permissionService.hasPermission('company.readGlobalSettings')" 
					(click)="editGlobalSettings()" 
					class="ml-1" 
					type="submit">

					<strong>{{localeStrings.editGlobalSettings}}</strong>
				</button>
				<button id="am_spiderSettings_editGlobalSettings" 
					mat-flat-button
					color="primary"
					*ngIf="isGlobal" 
					[hidden]="!permissionService.hasPermission('company.readGlobalSettings')" 
					(click)="editLocalSettings()" 
					class="ml-1" 
					type="submit">

					<strong>{{localeStrings.editLocalSettings}}</strong>
				</button>

				<button id="am_spiderSettings_buildCCF" 
					mat-flat-button
					color="primary"
					(click)="buildCCF()" 
					class="ml-1" 
					type="submit">

					<strong>{{localeStrings.buildCCF}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
</div>