/*
 * Simple method for determining browser and browser settings
*/
import { Inject, Injectable } from '@angular/core';

import { browserService } from './browser.service';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class analyticsService {
	
	page		: string = '';
	lastProcess	: string = '';
	now			: boolean = false;
	then		: moment.Moment;
	info		: string = '';

	constructor(
		@Inject(browserService) private browserService 	: browserService
	){
		this.startTimer();
	}

	startTimer(){
		this.then = moment();
	}

	getTimeSpent(){
		if (!this.then) {
			return 0;
		}
		let now = moment();
		return now.diff(this.then, 'seconds') + ' seconds';
	}

	setPage(pageName){
		this.page = pageName;
	}

	setLastProcess(lastProcess){
		this.lastProcess = lastProcess;
	}

	setInfo(info){
		this.info = info;
	}

	getData(){
		let analytics = {
			'page'				: this.page,
			'info'				: this.info,
			'lastProcess'		: this.lastProcess,
			'timeOnLastScreen'	: this.getTimeSpent(),
			'ieVersion'			: this.browserService.detectIE(),
			'userAgent'			: this.browserService.getUserAgent(),
		};

		this.startTimer();

		return analytics;
	}	
}

