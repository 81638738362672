<section localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="card-header bg-white">
		<div class="row">
			<div class="col-md-12 ml-auto" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<mat-form-field [appearance]="appearance" class="w-100" [style.fontSize.rem]="0.8">
					<mat-label>{{localeStrings.search}}</mat-label>
					<input matInput 
						id="search" 
						type="text"
						[(ngModel)]="search">
					<mat-icon matSuffix>search</mat-icon>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="card-body table-responsive p-0 rtl-direction" [hidden]="!courses">
		<table lio-paginator 
			[controlID]="panelConfig.pagination.id" 
			[collection]="courses" 
			[settings]="panelConfig.pagination"
			[filters]="search"
			(onResults)="updateFilteredCourses($event)" 
			class="table table-striped mb-0 text-center text-dark-gray table-border-dark">
			<thead>
				<tr class="bg-ultra-light-gray">
					<th *ngFor="let field of fields" [ngClass]="field.class" class="dark-text small font-weight-bold rtl p-1" 
						[hidden]="!permissionService.hasPermission(field.permission) || !settingsService.getSetting(field.setting)">
						<button (click)="sortCourses(field.model)"
							mat-button
							color="primary"
							localize
							[model]="field"
							item="name"
							[itemTag]="'training.' + field.model">

							<span class="d-flex">
								<span id="{{'am_' + field.model}}">{{field.name}}</span>
								<span [hidden]="panelConfig.pagination.sortMode.field != field.model" class="mb-n2">
									<mat-icon [hidden]="panelConfig.pagination.sortMode.direction != 'asc'" color="primary" class="pt-1">arrow_drop_up</mat-icon>
									<mat-icon [hidden]="panelConfig.pagination.sortMode.direction != 'desc'" color="primary" class="pt-1">arrow_drop_down</mat-icon>
								</span>
							</span>
						</button>
					</th>
					<th class="small font-weight-bold p-1" id="am_empty" aria-hidden="true">
						<p aria-hidden="true" [hidden]="true">Empty Element for WAVE</p>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let course of filteredCourses">
					<td class="text-nowrap font-weight-bold small h5-lg text-left p-2" 
						[hidden]="!permissionService.hasPermission('training.viewCourseID') || settingsService.getSetting('showCourseID') != 1">

						<span>{{course.courseID}}</span>
					</td>
					<td class="text-left small w-100 rtl p-2">
						<div class="font-weight-bold h5-lg rtl" 
							localize 
							[model]="course" 
							item="courseName" 
							[itemTag]="'course.' + course.courseID">

							<span>{{course.courseName}}</span>
						</div>
						<button [hidden]="!course.courseDescription" 
							[style.fontSize.rem]="0.7"
							[style.lineHeight.px]="18"
							mat-button 
							color="primary" 
							class="small" 
							(click)="showDescription(course.courseID)">

							<span>{{localeStrings.courseDescription}}</span>
						</button>

						<div [hidden]="settingsService.getSetting('sendCompletion') != 1 || settingsService.getSetting('resendCompletion') != 1" 
							*ngIf="course.completed && permissionService.hasPermission('training.sendEmail')">
							<button mat-button 
								*ngIf="!course.failed" 
								[style.fontSize.rem]="0.7"
								[style.lineHeight.px]="18"
								class="small" 
								(click)="sendCompletionEmail(course.courseID)">
								<span>{{localeStrings.resendCompletionEmail}}</span>
							</button>
							<button mat-button 
								*ngIf="course.failed" 
								[style.fontSize.rem]="0.7"
								[style.lineHeight.px]="18"
								class="small"
								(click)="sendFailedCompletionEmail(course.courseID)">
								<span>{{localeStrings.resendCompletionEmail}}</span>
							</button>
						</div>
					</td>
					<td class="text-nowrap d-none d-lg-table-cell align-middle p-2">
						<span>{{course.courseDueDate | date : localizationService.getSelectedDateFormat() | slice:0:10}}</span>
					</td>
					<td class="text-nowrap align-middle">
						<div [hidden]="!course.required" class="d-flex">
							<mat-icon class="mx-auto font-weight-bold">radio_button_checked</mat-icon>
						</div>
					</td>
					<td class="text-nowrap align-middle d-none d-lg-table-cell text-left p-2">
						<div *ngIf="course.optionShown == 'complete' || course.optionShown == 'restart'">
							
							<span *ngIf="!course.failed">
								<mat-icon color="primary" class="font-weight-bold">done</mat-icon>
							</span>
							<span *ngIf="course.failed">
								<mat-icon color="warn" class="font-weight-bold">close</mat-icon>
							</span>

							<span class="d-inline-block">
								<p *ngIf="course.failed" class="status-failed m-0">{{localeStrings.failed}}</p>
								<p *ngIf="!course.failed" class="status-completed m-0">{{localeStrings.completed}}</p>
								<div class="small">{{course.courseCompletionDate | date : localizationService.getSelectedDateFormat() | slice:0:10}}</div>
							</span>
						</div>
						<div *ngIf="course.optionShown == 'resume'" class="d-flex">
							<mat-icon color="primary" class="font-weight-bold">play_circle_outline</mat-icon>
							<span>{{localeStrings.inProgress}}</span>
						</div>
						<div *ngIf="course.optionShown == 'start'" class="d-flex">
							<mat-icon color="primary" class="font-weight-bold">play_circle_outline</mat-icon>
							<span>{{localeStrings.toDo}}</span>
						</div>
						<div *ngIf="course.optionShown == 'unavailable'" class="d-flex">
							<mat-icon color="warn" class="font-weight-bold">not_interested</mat-icon>
							<span class="d-inline-block">
								<span>{{localeStrings.notAvailable}}</span>
								<span class="small">{{course.courseAvailableDate  | date : localizationService.getSelectedDateFormat() | slice:0:10}}</span>
							</span>
						</div>
						<div *ngIf="course.optionShown == 'disabled'" class="d-flex">
							<mat-icon color="warn" class="font-weight-bold">not_interested</mat-icon>
							<span class="d-inline-block">
								<span>{{localeStrings.disabled}}</span>
							</span>
						</div>
					</td>

					<td class="text-nowrap align-middle p-2" *ngIf="allowLaunch">
						<button *ngIf="course.optionShown == 'start'" 
							class="w-100"
							mat-flat-button
							color="primary"
							id="am_training_{{course.courseID}}"
							attr.aria-label="Start course, {{course.courseName}}"
							(click)="startCourse(course.courseID)" 
							type="submit">

							<span class="font-weight-bold d-none d-md-inline-block">{{localeStrings.startCourse}}</span>
							<mat-icon class="font-weight-bold">play_arrow</mat-icon>
						</button>
						<button *ngIf="course.optionShown == 'complete'" 
							class="w-100"
							mat-flat-button
							color="primary"
							[disabled]="true">

							<span class="font-weight-bold d-none d-md-inline-block">{{localeStrings.completed}}</span>
							<mat-icon class="font-weight-bold">done</mat-icon>
						</button>
						<button *ngIf="course.optionShown == 'restart'" 
							class="w-100"
							mat-flat-button
							[color]="course.failed ? 'warn' : 'primary'"
							id="am_training_{{course.courseID}}"
							attr.aria-label="Restart course, {{course.courseName}}" 
							(click)="startCourse(course.courseID)" 
							type="submit">

							<span class="font-weight-bold d-none d-md-inline-block">{{localeStrings.restartCourse}}</span>
							<mat-icon>refresh</mat-icon>
						</button>
						<button *ngIf="course.optionShown == 'resume'"
							class="w-100"
							mat-flat-button
							color="primary"
							attr.aria-label="Resume course, {{course.courseName}}"
							(click)="startCourse(course.courseID)" 
							type="submit">

							<span class="font-weight-bold d-none d-md-inline-block m-1">{{localeStrings.resumeCourse}}</span>
							<mat-icon class="font-weight-bold">play_arrow</mat-icon>
						</button>
						<button *ngIf="course.optionShown == 'unavailable'"
							class="w-100"
							mat-flat-button
							color="primary" 
							[disabled]="true" 
							type="submit">

							<span class="font-weight-bold d-none d-md-inline-block m-1">
								<span>{{localeStrings.notAvailable}}</span>
								<span class="ml-1">{{course.courseAvailableDate | date : localizationService.getSelectedDateFormat() | slice:0:10}}</span>
							</span>
							<mat-icon class="font-weight-bold">not_interested</mat-icon>
						</button>
						<button *ngIf="course.optionShown == 'disabled'"
							class="w-100"
							mat-flat-button
							color="primary" 
							[disabled]="true" 
							type="submit">
							<span class="font-weight-bold d-none d-md-inline-block m-1">
								<span>{{localeStrings.disabled}}</span>
							</span>
							<mat-icon class="font-weight-bold">not_interested</mat-icon>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</section>