import { Component, Input, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatTabGroup } from '@angular/material/tabs';

import { lioLogService } from '../../../../services/lio-log.service';
import { lmsService } from '../../../../services/lms.service';

@Component({
	selector: 'lio-disclosure',
	templateUrl: './disclosure.component.html',
	styleUrls: ['./disclosure.component.css']
})
export class LioDisclosure{
	private _tabGroup:MatTabGroup = null;
	public get tabGroup():MatTabGroup{
		return this._tabGroup;
	}
	@ViewChild('tabGroup') public set tabGroup(val:MatTabGroup){
		this._tabGroup = val;
		this.tabGroup.selectedIndex = this.activeSectionIndex;
	}

	@Input() appearance	:MatFormFieldAppearance = 'outline';

	private _model		:any = null;
	public get model()	:any{
		return this._model;
	}
	@Input() public set model(val:any){
		if(!val.sections) {
			val.sections = [];
		}
		
		if(!val.title) {
			val.title = '';
		} else if(val.title.text) {
			val.title = val.title.text;
		}

		if(!val.showReport || val.showReport == '0') {
			val.showReport = false;
		} else {
			val.showReport = true;
		}

		//finds the current highest sectionID, questionID, and responseID so that we know where to start from when adding to the disclosure
		if(val.sections) {
			val.sections.forEach((section) => {
				if(section.sectionid > this.highestSectionID) {
					this.highestSectionID = section.sectionid;
				}

				if(section.questions) {
					section.questions.forEach((question) => {
						if(question.questionid > this.highestQuestionID) {
							this.highestQuestionID = question.questionid;
						}

						if(question.response && question.response.options) {
							question.response.options.forEach((options) => {
								if(options.value > this.highestResponseOptionID) {
									this.highestResponseOptionID = options.value;
								}
							});
						}
					});
				}
			});
		} else {
			val.sections = [{}];
		}

		if(!val.settings){
			val.settings = {};
		}
		if(!val.settings.notificationResponses){
			val.settings.notificationResponses 	= [];
		}
		if(!val.settings.notificationEmail){
			val.settings.notificationEmail 		= '';
		}

		this._model = val;
		this.findAllQuestions();
		this.resumeProgress();
	}

	public activeSectionIndex		:number 	= 0;

	public activeSection			:number		= 0;
	private sentCompletion			:boolean	= false;

	private highestSectionID		:number 	= 0;
	private highestQuestionID		:number 	= 0;
	private highestResponseOptionID	:number 	= 0;

	public sendingResponse			:boolean 	= false;
	public allQuestions				:Array<any>	= [];

	constructor(
		@Inject(DOCUMENT)		private document		: Document,
		@Inject(lioLogService)	private lioLogService	: lioLogService,
		@Inject(lmsService)		private lmsService		: lmsService
	){}

	/**
	 * Resumes from the first un-ansered section if the user has already submitted some responses for this disclosure
	 */
	resumeProgress() {
		this.changedSection(0);

		if (this.model.progress) {
			let responsedSections = [];
			this.model.progress.forEach((response) => {
				if (!responsedSections[response.sectionID]) {
					responsedSections[response.sectionID] = [];
				}
				responsedSections[response.sectionID].push(response);
			});

			do {
				let section 				= this.model.sections[this.activeSectionIndex];
				let hasRespondableQuestion 	= false;
				section.questions.forEach((question) => {
					if(question.response && question.response.type) {
						hasRespondableQuestion = true;
					}
				});

				if (!hasRespondableQuestion) {
					this.changedSection(this.activeSectionIndex+1);
					continue;
				}

				let foundResponse = false;
				Object.keys(responsedSections).forEach((sectionID) => {
					if (sectionID == this.activeSection.toString()) {
						foundResponse = true;
					}
				});

				if (foundResponse) {
					this.changedSection(this.activeSectionIndex+1);
					continue;
				} else {
					break;
				}
			} while(this.activeSectionIndex < this.model.sections.length - 1);
		}
		
		this.gotoSection(this.activeSectionIndex);
	}

	/**
	 * Returns the index of the current active section in the list
	 */
	getActiveSection() {
		return this.activeSection;
	}

	/**
	 * Switches the view to a certain section
	 */
	gotoSection(sectionIndex){
		if(sectionIndex < 0) {
			sectionIndex = 0;
		}else if(sectionIndex > this.model.sections.length - 1) {
			sectionIndex = this.model.sections.length - 1;
			//we tried to move past the last section, send completion
			this.sendCompletion();
			//Continue button was pressed on the last section, close the window
			this.closeWindow();
		}
		//give the tab handler time to see the new tab
		setTimeout(() => {
			if(this.tabGroup){
				this.tabGroup.selectedIndex = sectionIndex;
			}
		});
	}

	/**
	 * Called when the tabGroup registers that the active tab has changed
	 */
	changedSection(sectionIndex) {
		if (this.model.sections && this.model.sections[sectionIndex]) {
			this.activeSectionIndex 	= sectionIndex;
			this.activeSection 			= this.model.sections[sectionIndex].sectionid;
		}

		if(sectionIndex > this.model.sections.length - 2 
			&& this.model.sections[sectionIndex].questions.length == 0) {
			//we are on the last section, send completion if there are no questions on the last section
			this.sendCompletion();
		}
		this.sendingResponse = false;
	}

	/**
	 * Sets the previous section to be active
	 */
	prevSection() {
		this.gotoSection(this.activeSectionIndex - 1);
	}

	/**
	 * Sends in one or more responses to a section, then proceeds to the next section
	 */
	sendResponse() {
		this.sendingResponse = true;
		if(this.model.settings.mode == 'read') {
			let section 	= this.model.sections[this.activeSectionIndex];
			let responses 	= [];

			section.questions.forEach((question) => {
				let response = {
					questionid 		: question.questionid,
					responseType 	: question.response.type,
					response 		: question.response.input
				};

				responses.push(response);
			});

			if(responses.length > 0) {
				let data = {
					certificationID	: this.model.certificationID,
					sectionID 		: section.sectionid, 
					responses 		: responses
				};

				this.lmsService.post('disclosure/sendResponse', data).then(() => {
					this.nextSection();
				});
			} else {
				this.nextSection();
			}
		} else {
			this.nextSection();
		}
	}

	/**
	 * Sets the next section to be active
	 */
	nextSection() {
		this.gotoSection(this.activeSectionIndex + 1);
	}

	/**
	 * Sends completion to the parent/opener window
	 */
	sendCompletion() {
		if(this.model.settings.mode == 'read' && !this.sentCompletion) {
			this.lioLogService.log('Sending Completion');

			if (this.document.defaultView.parent) {
				this.document.defaultView.parent.postMessage('IFRAME_COMPLETED', '*');
			}
			
			if (this.document.defaultView.opener) {
				this.document.defaultView.opener.postMessage('IFRAME_COMPLETED', '*');
			}
			this.sentCompletion = true;
		}
	}

	/**
	 * Closes the window
	 */
	closeWindow() {
		if(this.model.settings.mode == 'read'){
			if (this.document.defaultView.parent) {
				// iFrames cannot control the window.close function
				this.lioLogService.log('Telling the parent to close the window');
				this.document.defaultView.parent.postMessage('CLOSE_WINDOW', '*');
			}
			this.document.defaultView.close();
		}
	}

	/**
	 * Adds a new section to the disclosure
	 */
	createSection() {
		this.model.sections.push({sectionid : this.getNewSectionID(), button : {}});
		this.gotoSection(this.model.sections.length - 1);
	}

	/**
	 * Deletes a section from the disclosure
	 */
	deleteSection(section) {
		if(this.model.sections.length > 1) {
			for(let i = 0; i < this.model.sections.length; i++) {
				if(this.model.sections[i] == section) {
					this.model.sections.splice(i, 1);
				}
			}
			this.prevSection();
		}
	}

	/**
	 * Gets a new sectionID
	 */
	getNewSectionID() {
		let newID = this.highestSectionID + 10;
		this.highestSectionID = newID;
		return newID;
	}

	/**
	 * Gets a new questionId
	 */
	getNewQuestionID() {
		let newID = this.highestQuestionID + 10;
		this.highestQuestionID = newID;
		return newID;
	}

	/**
	 * Gets a new responseID
	 */
	getNewResponseOptionID() {
		let newID = this.highestResponseOptionID + 10;
		this.highestResponseOptionID = newID;
		return newID;
	}

	addNotificationResponse(optionID){
		if(this.model.settings.notificationResponses.indexOf(optionID) == -1){
			this.model.settings.notificationResponses.push(optionID);
		}
	}

	removeNotificationResponse(optionID){
		let index = this.model.settings.notificationResponses.indexOf(optionID);

		if(index != -1){
			this.model.settings.notificationResponses.splice(index, 1);
		}
	}

	findAllQuestions(){
		let allQuestions = [];
		if(this.model && this.model.sections){
			this.model.sections.forEach((section, sectionIndex) => {
				if(section.questions){
					section.questions.forEach((question) => {
						question.sectionIndex = sectionIndex + 1;
					});
					allQuestions = allQuestions.concat(section.questions);
				}
			});
		}
		this.allQuestions = allQuestions;
	}

	questionAdded(question:any){
		question.questionid = this.getNewQuestionID();
		this.findAllQuestions();
	}

	questionDeleted(){
		this.findAllQuestions();
	}

	optionAdded(option:any){
		option.value = this.getNewResponseOptionID();
	}
}