import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { permissionService } from '../../services/permissions.service';
import { utilService } from '../../services/util.service';
import { accessibilityService } from '../../services/accessibility.service';


import { homeSettings } from './home.settings';

@Component({
	selector: 'lio-home',
	templateUrl: './home.component.html'
})
export class HomeComponent implements OnDestroy {
	public cards			:any			= [];
	public panelConfig		:any			= {};

	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)			private	navService			:navService,
		@Inject(debugService)		private	debugService		:debugService,
		@Inject(feedbackService)	private	feedbackService		:feedbackService,
		@Inject(stateService)		private	stateService		:stateService,
		@Inject(accessibilityService)		private	accessibilityService		:accessibilityService,
		@Inject(permissionService)	public	permissionService	:permissionService,
		@Inject(utilService)		private	utilService			:utilService,
		@Inject(homeSettings)		private	homeSettings		:homeSettings
	){
		this.debugService.register('home', this);
		this.navService.setActivePage('home');
		
		this.panelConfig		= this.utilService.copy(this.homeSettings.panelConfig);
		this.cards		 		= this.utilService.copy(this.homeSettings.cards);
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.navService.displayPage();
				this.feedbackService.clearErrors();
			})
		);
	}


	onKeyUp(event) {
		console.log('heard key up');
		if (event) {
			this.accessibilityService.tabChanged();
		}
	}



	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}