<div class="row">
	<div class="col-md-10 offset-md-1">
		<!-- Search users form -->
		<lio-card [showClose]="true">
			<div lio-card-header>
				<h4 class="d-inline-block">Search Access Logs<span [hidden]="!serverName"> on {{serverName}}</span></h4>
			</div>
			<div lio-card-body bodyClass="container-fluid py-2">
				<lio-form-inputs
					[fields]="searchFields"
					[fieldConfig]="searchFieldConfig"
					[model]="searchers" 
					(onChanged)="onUpdate()">
				</lio-form-inputs>
			</div>
			<div lio-card-footer>
				<button [disabled]="!allowSubmit" (click)="submit()" class="btn btn-sm btn-primary m-t-n-xs" type="submit"><strong>Search Logs</strong></button>
			</div>
		</lio-card>
	</div>
	<div class="col-md-2"></div>
</div>

<div class="row mt-3" [hidden]="!filteredEmployees.length">
	<div class="col-12">
		<lio-card body-class="p-0" footer-class="container-fluid" [hideErrors]="true">
			<div lio-card-body lio-paginator controlID="accesslog" [collection]="employees" [settings]="pagination" (onResults)="updateFilteredEmployees($event)">
				<lio-results-table
					paginatorID="accesslog"
					[fields]="fields"
					[fieldConfig]="fieldConfig"
					[results]="filteredEmployees">
				</lio-results-table>
			</div>
			<div lio-card-footer>
				<lio-paginator-controls class="row" controlID="accesslog"></lio-paginator-controls>
				<!-- Buttons -->
				<div class="btn-toolbar">
					<button id="am_employeesearch_exportrecords" [hidden]="!permissionService.hasPermission('reports.export')" class="btn btn-sm btn-primary" type="button" (click)="exportRecords()">
						<span>Export Records</span>
						<i class="fa fa-file-excel ml-1" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</lio-card>
	</div>
</div>