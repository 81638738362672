<div [id]="'am_rule_' + ruleIndex"
	[ngClass]="{'col-md-11 col-10':!hideDelete}" 
	class="container-fluid mx-0" 
	[style.fontSize.px]="13">
	
	<!-- LTR (If making changes, ensure to copy to the RTL and swap locations of filters and values) -->
	<div *ngIf="!localizationService.isRTL()" class="row" [ngClass]="rule.error ? 'alert-dark' : ''">
		<!-- FILTERS -->
		<div class="col-md-4 px-1" *ngIf="filters">
			<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_filter'"
				[formGroup]="ruleGroup"
				[noFormFieldPB]="true"
				[appearance]="appearance"
				[model]="rule"
				[field]="filterField"
				[searchable]="true"
				(onUpdate)="updatedFilter()">
			</lio-searchable-select>
		</div>

		<!-- OPERATORS -->
		<div class="col-md-4 px-1">
			<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_operator'"
				[formGroup]="ruleGroup"
				[noFormFieldPB]="true"
				[appearance]="appearance"
				[model]="rule"
				[field]="operatorField"
				(onUpdate)="updatedOperator()">
			</lio-searchable-select>
		</div>

		<!-- VALUE -->
		<div class="col-md-4 px-1">
			<label *ngIf="filter.type !== 'select' && filter.type !== 'date'" [for]="'am_rule_' + ruleIndex + '_value'" class="sr-only">Input for <span>{{filter.name}}</span></label>
			<!-- Integer Input -->
			<div *ngIf="filter.type == 'integer'">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="valueControl"
						[id]="'am_rule_' + ruleIndex + '_value'"
						type="number" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value"/>
				</mat-form-field>
			</div>

			<!-- Text Input -->
			<div *ngIf="filter.type == 'string' && !operator.multiple">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="valueControl"
						[id]="'am_rule_' + ruleIndex + '_value'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value"/>
				</mat-form-field>
			</div>
			
			<!-- 2 Text Inputs -->
			<div *ngIf="filter.type == 'string' && operator.multiple">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="value1Control"
						[id]="'am_rule_' + ruleIndex + '_value_1'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value[0]"/>
				</mat-form-field>
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="value2Control"
						[id]="'am_rule_' + ruleIndex + '_value_2'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value[1]"/>
				</mat-form-field>
			</div>

			<!-- Date Input -->
			<div *ngIf="filter.type == 'date' && !operator.multiple && !operator.noDate">
				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value'"
					[model]="rule"
					[field]="{'model':'value'}"
					(onUpdate)="updatedValue()" 
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>
			</div>

			<!-- 2 Date Inputs -->
			<div *ngIf="filter.type == 'date' && operator.multiple && !operator.noDate">
				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value_1'"
					[model]="rule.value"
					[field]="{'model':0}"
					(onUpdate)="updatedValue()"
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>

				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value_2'"
					[model]="rule.value"
					[field]="{'model':1}"
					(onUpdate)="updatedValue()"
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>
			</div>

			<div *ngIf="filter.type == 'date' && operator.noDate">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="valueControl"
						[id]="'am_rule_' + ruleIndex + '_value'"
						type="number"
						(change)="updatedValue()"
						[placeholder]="filter.placeholderLocalized"
						autocomplete="new-password"
						[(ngModel)]="rule.value"/>
				</mat-form-field>
			</div>

			<!-- Single Select -->
			<div *ngIf="filter.type == 'select' && !filter.multiple">
				<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_value'"
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[model]="rule"
					[field]="valuesField"
					[multiple]="false"
					(onUpdate)="updatedValue()">
				</lio-searchable-select>
			</div>

			<!-- Multiple Select -->
			<div *ngIf="filter.type == 'select' && filter.multiple">
				<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_value'"
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[model]="rule"
					[field]="valuesField"
					[multiple]="true"
					(onUpdate)="updatedValue()">
				</lio-searchable-select>
			</div>

			<!-- Secondary input -->
			<div *ngIf="rule.has2ndInput">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput
						[formControl]="value2Control"
						[id]="'am_rule_' + ruleIndex + '_value_2'"
						[placeholder]="filter.addInputLocalized" 
						type="text" 
						(change)="updatedValue()" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value2"/>
				</mat-form-field>
			</div>
		</div>
	</div>

	<!-- RTL (FLipped positions of filters and values) -->
	<div *ngIf="localizationService.isRTL()" class="row" [ngClass]="rule.error ? 'alert-dark' : ''">
	
		<!-- VALUE -->
		<div class="col-md-4 px-1">
			
			<!-- Integer Input -->
			<div *ngIf="filter.type == 'integer'">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput 
						[formControl]="valueControl"
						[id]="'am_rule_' + ruleIndex + '_value'" 
						type="number" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value"/>
				</mat-form-field>
			</div>

			<!-- Text Input -->
			<div *ngIf="filter.type == 'string' && !operator.multiple">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput 
						[formControl]="valueControl"
						[id]="'am_rule_' + ruleIndex + '_value'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value"/>
				</mat-form-field>
			</div>
			
			<!-- 2 Text Inputs -->
			<div *ngIf="filter.type == 'string' && operator.multiple">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput 
						[formControl]="value1Control"
						[id]="'am_rule_' + ruleIndex + '_value_1'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value[0]"/>
				</mat-form-field>
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput 
						[formControl]="value2Control"
						[id]="'am_rule_' + ruleIndex + '_value_2'" 
						type="text" 
						(change)="updatedValue()" 
						[placeholder]="filter.placeholderLocalized" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value[1]"/>
				</mat-form-field>
			</div>

			<!-- Date Input -->
			<div *ngIf="filter.type == 'date' && !operator.multiple">
				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value'"
					[model]="rule"
					[field]="{'model':'value'}"
					(onUpdate)="updatedValue()" 
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>
			</div>

			<!-- 2 Date Inputs -->
			<div *ngIf="filter.type == 'date' && operator.multiple">
				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value_1'"
					[model]="rule.value"
					[field]="{'model':0}"
					(onUpdate)="updatedValue()" 
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>

				<lio-mat-wrap-date-field 
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[amID]="'am_rule_' + ruleIndex + '_value_2'"
					[model]="rule.value"
					[field]="{'model':1}"
					(onUpdate)="updatedValue()" 
					[disabled]=" rule.disabled"
					[placeholder]="filter.placeholderLocalized">
				</lio-mat-wrap-date-field>
			</div>

			<!-- Single Select -->
			<div *ngIf="filter.type == 'select' && !filter.multiple">
				<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_value'"
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[model]="rule"
					[field]="valuesField"
					[multiple]="false"
					(onUpdate)="updatedValue()">
			</lio-searchable-select>
		</div>
			<!-- Multiple Select -->
			<div *ngIf="filter.type == 'select' && filter.multiple">
				<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_value'"
					[formGroup]="ruleGroup"
					[noFormFieldPB]="true"
					[appearance]="appearance"
					[model]="rule"
					[field]="valuesField"
					[multiple]="true"
					(onUpdate)="updatedValue()">
				</lio-searchable-select>
			</div>


			<!-- Secondary input -->
			<div *ngIf="rule.has2ndInput">
				<mat-form-field [appearance]="appearance" class="w-100">
					<input matInput 
						[formControl]="value2Control"
						[id]="'am_rule_' + ruleIndex + '_value_2'" 
						[placeholder]="filter.addInputLocalized" 
						type="text" 
						(change)="updatedValue()" 
						autocomplete="new-password" 
						[(ngModel)]="rule.value2">
				</mat-form-field>
			</div>
		</div>

		<!-- OPERATORS -->
		<div class="col-md-4 px-1">
			<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_operator'"
				[formGroup]="ruleGroup"
				[noFormFieldPB]="true"
				[appearance]="appearance"
				[model]="rule"
				[field]="operatorField"
				(onUpdate)="updatedOperator()">
			</lio-searchable-select>
		</div>

		<!-- FILTERS -->
		<div class="col-md-4 px-1" *ngIf="filters">
			<lio-searchable-select [amID]="'am_rule_' + ruleIndex + '_filter'"
				[formGroup]="ruleGroup"
				[noFormFieldPB]="true"
				[appearance]="appearance"
				[model]="rule"
				[field]="filterField"
				(onUpdate)="updatedFilter()">
			</lio-searchable-select>
		</div>
	</div>
</div>
<!-- Buttons -->
<div class="col-1 pt-1 px-2">
	<button [id]="'am_rule_' + ruleIndex + '_delete'" class="p-2 px-3 btn btn-danger" *ngIf="!rule.required && !hideDelete" [disabled]="rule.locked" (click)="deleteRule()">
		<i class="fa fa-trash"></i>
		<span aria-hidden="true" [hidden]="true">Delete Rule></span>
	</button>
</div>