<div class="row">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber #tabCache (onTabChange)="onTabChange()">
			<!-- Search -->
			<lio-panel-tab [settings]="searchPanelConfig">
				<lio-catalog-admin-search 
					(callback)="handleCallback($event)"
					(onLoaded)="loaded()"
					*lioPanelTabContent>
				</lio-catalog-admin-search>
			</lio-panel-tab>

			<!-- Add form -->
			<lio-panel-tab [settings]="addPanelConfig">
				<lio-catalog-admin-add
					*lioPanelTabContent>
				</lio-catalog-admin-add>
			</lio-panel-tab>

			<!-- Edit form -->
			<lio-panel-tab [settings]="editPanelConfig">
				<lio-catalog-admin-edit *lioPanelTabContent>
				</lio-catalog-admin-edit>
			</lio-panel-tab>

			<!-- Keyword/Topic form -->
			<lio-panel-tab [settings]="topicPanelConfig">
				<lio-catalog-admin-topics *lioPanelTabContent></lio-catalog-admin-topics>
			</lio-panel-tab>

			<!-- Bulk Upload -->
			<lio-panel-tab [settings]="bulkPanelConfig">
				<lio-catalog-admin-bulk
					(onUpdated)="resetCatalogs()"
					*lioPanelTabContent>
				</lio-catalog-admin-bulk>
			</lio-panel-tab>

			<!-- Collection Link -->
			<lio-panel-tab [settings]="collectionPanelConfig"></lio-panel-tab>
		</lio-panel-tabber>
	</div>
</div>