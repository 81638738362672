<!-- Query Builder -->
<section *ngIf="active">
	<lio-query-builder  
		[hidden]="hide" 
		[controlObject]="builderCtrl" 
		[settings]="settings" 
		(runReport)="runReport()" 
		(builderReady)="toolReady()"
		[(filters)]="filters" 
		[(rules)]="rules">
	</lio-query-builder>

	<!-- Note -->
	<span *ngIf="queryToolNote">
		<span localize [model]="queryToolNote" item="text" class="text-danger small hc-dark-text">{{queryToolNote.text}}</span>
	</span>

	<hr *ngIf="showSaver" />

	<!-- Saver -->
	<div *ngIf="showSaver" 
		[style.fontSize.px]="12"
		class="form-inline mt-2 float-rtl-right" 
		localize 
		[model]="localeStrings" 
		[items]="['name', 'save', 'placeholder', 'visibility', 'savedQueries', 'delete']">

		<div class="input-group input-group-sm mr-2" [hidden]="!permissionService.hasPermission('query.save')">
			<mat-form-field [appearance]="appearance" class="pt-0 pb-2">
				<mat-label id="am_save_query_label">{{localeStrings.name}}</mat-label>
				<input
					id="am_save_query_input"
					matInput
					[disabled]="!ready" 
					type="text" 
					[placeholder]="localeStrings.placeholder" 
					[(ngModel)]="model.selected.name"/>
			</mat-form-field>
			<div class="ml-2 my-2">
				<button id="am_save_query_button" 
					mat-raised-button
					color="primary"
					[disabled]="!model.selected.name || !ready" 
					(click)="save()" 
					class="font-weight-bold hc-white-text">
					{{localeStrings.save}}
				</button>
			</div>
		</div>
		
		<!-- Save Globally / Privately -->
		<div class="input-group input-group-sm2 mr-2" [hidden]="!permissionService.hasPermission('query.save')">
			<lio-searchable-select amID="am_visibility_list"
				[appearance]="appearance"
				[model]="model.selected"
				[field]="visibilityField"
				[noFormFieldPB]="true"
				[includeLockedOptions]="true">
			</lio-searchable-select>
		</div>

		<div class="input-group mr-2">
			<lio-searchable-select amID="am_query_list"
				[appearance]="appearance"
				[model]="model"
				[field]="queryField"
				[searchable]="true"
				(onUpdate)="loadQuery()"
				[noFormFieldPB]="true"
				[optionAsValue]="true">
			</lio-searchable-select>
			<div class="ml-2 my-2">
				<button id="am_delete_query" 
					mat-raised-button
					matSuffix
					color="warn"
					[hidden]="!permissionService.hasPermission('query.delete')"
					[disabled]="!model.selected.id || !ready" 
					(click)="delete()">
					<strong>{{localeStrings.delete}}</strong>
				</button>
			</div>
		</div>
	</div>
</section>