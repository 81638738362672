import { Component, Input, Inject } from '@angular/core';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-event-days',
	templateUrl: './days.component.html',
})
export class LioEventDays {
	@Input() model:any;
	@Input() field:any;

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}
}