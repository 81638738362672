import { Component, Inject, ViewChild } from '@angular/core';
import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { utilService } from '../../services/util.service';
import { companyService } from '../../services/company.service';
import { storageService } from '../../services/storage.service';
import { companyAdminSettings } from './company-admin.settings';
import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
  selector: 'lio-company-admin',
  templateUrl: './company-admin.component.html'
})
export class CompanyAdminComponent {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache:PanelTabCache 	= null;
	public searchPanelConfig:any		= {};
	public createPanelConfig:any			= {};
	public editPanelConfig:any			= {};

	constructor(
		@Inject(navService)				public 	navService				:navService,
		@Inject(debugService)			public 	debugService			:debugService,
		@Inject(storageService)			public 	storageService			:storageService,
		@Inject(companyService)			public 	companyService			:companyService,
		@Inject(utilService)			public 	utilService				:utilService,
		@Inject(companyAdminSettings) 	public 	companyAdminSettings	:companyAdminSettings
	){
		this.debugService.register('companyadmin', this);
		this.navService.setActivePage('company');
		this.searchPanelConfig = this.utilService.copy(this.companyAdminSettings.panelConfig.search);
		this.createPanelConfig = this.utilService.copy(this.companyAdminSettings.panelConfig.add);
		this.editPanelConfig = this.utilService.copy(this.companyAdminSettings.panelConfig.edit);
	}

	loaded() {
		let companyID = this.storageService.getAndClear('companyID');
		if (companyID) {
			if (this.panelTabCache) {
				this.panelTabCache.selectTab(this.editPanelConfig);
				this.panelTabCache.getTabByName('Edit Company').disabled = 'disabled';
			}
		}
	}

	resetCompany() {
		this.companyService.init();
	}

	onTabChange(event) {
		this.companyService.setSearch('');
		this.companyService.resetCompany();
		if (event.id == 'panelTab_company_admin_edit') {
			this.companyService.init();
		}
		// Prevent click on Edit Company tab when it is selected.
		let selectedTab = this.panelTabCache.getSelectedTab();
		let editTab = this.panelTabCache.getTabByName('Edit Company');
		editTab.disabled = '';
		if (selectedTab.id === editTab.id) {
			editTab.disabled = 'disabled';
		}
	}

	handleCallback(event) {
		this.panelTabCache.getTabByName('Edit Company').disabled = '';
		if (event.switchTab === 'edit') {
			this.panelTabCache.selectTab(this.editPanelConfig);
			this.panelTabCache.getTabByName('Edit Company').disabled = 'disabled';
		} else if (event.switchTab === 'copy') {
			this.panelTabCache.selectTab(this.createPanelConfig);
		} else if (event.switchTab === 'search') {
			this.panelTabCache.selectTab(this.searchPanelConfig);
			this.companyService.setSearch(event.companyID);
		}
	}
}
