<section class="row" localize [model]="localeStrings" [items]="localeStringItems">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-header text-center">
				<h2 id="am_catalog_search_title">{{localeStrings.title}}</h2>
				<p id="am_catalog_search_subtitle">{{localeStrings.subheader}}</p>
				<div class="row no-gutters">
					<!-- Input -->
					<div class="col-9" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
						<mat-form-field [appearance]="appearance" class="w-100">
							<mat-label>Enter Search Here</mat-label>
							<input matInput
								type="text"
								id="am_catalog_search_input" 
								[(ngModel)]="filters['*']" 
								debounce="500" 
								(change)="onupdate.emit()"/>
							<mat-icon matSuffix>search</mat-icon>
						</mat-form-field>
					</div>
					<!-- Filter Button -->
					<div class="col-3 pt-1" *ngIf="view.hasFilters" id="am_catalog_search_toggle_filters">
						<button mat-flat-button color="primary" class="mb-1" (click)="toggleFilters.emit()">
							<span>{{localeStrings.filters}}</span>
							<i *ngIf="!showFilter" class="ml-1 fa fa-chevron-right"></i>
							<i *ngIf="showFilter" class="ml-1 fa fa-chevron-down"></i>
						</button>
						<!-- Clear Button -->
						<button mat-flat-button color="primary" class="ml-1" (click)="resetQuery.emit()" [disabled]="!clearBtnActive && !filters['*']">
							<span class="inline filter b">{{localeStrings.clear}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>