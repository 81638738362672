/*
 * Service for company administration.
 */
import { ReplaySubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { lmsService } from './lms.service';
import { lioModalService } from './lio-modal.service';
import { debugService } from './debug.service';
import { utilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class companyService {
	companies:ReplaySubject<any> = new ReplaySubject(1);
	company:ReplaySubject<any> = new ReplaySubject(1);
	search:ReplaySubject<string> = new ReplaySubject(1);
	subCompanies:ReplaySubject<any> = new ReplaySubject(1);
	onEvent:ReplaySubject<any> = new ReplaySubject();

	public _companies = [];
	public _company:any = null;
	public _search:string = '';
	public _subCompanies = [];

	constructor(
		@Inject(lmsService) 		private lmsService		:lmsService,
		@Inject(lioModalService)	private lioModalService	:lioModalService,
		@Inject(debugService)		private debugService	:debugService,
		@Inject(utilService) 		private utilService		:utilService
	){
		this.debugService.register('companies', this);
	}

	/*
	 * Init
	 */
	init() {
		return this.getCompanies();
	}

	/*
	 * Reset
	 */
	resetCompany() {
		this._company = null;
		this.company.next(null);
	}

	/*
	 * Get all companies.
	 */
	getCompanies(headers:Array<any> = []) {
		return new Promise((resolve) => {
			if (this._companies.length) {
				this.companies.next(this._companies);
				resolve(true);
				return;
			}
			this.lmsService.post('company/getCompanies', { 'headers': headers}).then((result) => {
				let companies = result.properties.companies;
				companies.forEach((company) => {
					this.formatCompany(company);
				});
				this.companies.next(companies);
				this._companies = companies;
				resolve(true);
			});
		});
	}

	/*
	 * Get subcompanies for the current company.
	 *
	 * @param {int} companyID (if not passed, current company's ID will be used)
	 */
	getSubCompanies(companyID = 0) {
		return new Promise((resolve) => {
			if (!companyID && !this._company.companyID) {
				this.resetSubCompanies();
				resolve(true);
				return;
			}
			if (!companyID) {
				companyID = this._company.companyID;
			}
			return this.lmsService.post('subcompany/getSubCompanies', {'companyID': companyID}).then((result) => {
				let subCompanies 	= result.properties.subCompanies;
				this.setSubCompanies(subCompanies);
				resolve(true);
			});
		});
	}

	/*
	 * Clear list of subcompanies.
	 */
	resetSubCompanies() {
		this._subCompanies = [];
		this.subCompanies.next(this._subCompanies);
	}

	/*
	 * Set subcompanies.
	 *
	 * @param {array} subCompanies
	 */
	setSubCompanies(subCompanies) {
		this._subCompanies 	= this.formatSubCompanies(subCompanies);
		this.subCompanies.next(this._subCompanies);
	}

	/*
	 * Format subcompanies.
	 */
	formatSubCompanies(subcompanies) {
		if (subcompanies) {
			subcompanies.forEach((company, index) => {
				company.visible = true;
				company.name 	= 'Sub Company ' + (index + 1);
				company.groupId = 'sub' + index;
			});
		} else {
			subcompanies = [];
		}
		return subcompanies;
	}

	/*
	 * Function for global admin to save any new or modified company.
	 */
	globalSaveCompany(company, subCompanies) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('company/globalSaveCompany', {
				'company' : company,
				'subCompanies' : subCompanies
			}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				let company = result.properties.company;
				company = this.formatCompany(company);
				this.setCompany(company);
				this.updateCompanies(company);
			}
			return result.success;
		});
	}

	/*
	 * Set the search term.
	 *
	 * This function is useful for displaying the modified company
	 * after saving changes on the Edit Company tab.
	 *
	 * @param {string} search
	 */
	setSearch(search) {
		this._search = search;
		this.search.next(this._search);
	}

	editCompany(event) {
		this.setCompany(event);
		this.onEvent.next('edit')
	}

	/*
	 * Sets the company.
	 */
	setCompany(company) {
		let selectedCompany = this.utilService.copy(company);
		this._company = selectedCompany;
		this.company.next(selectedCompany);
	}

	/*
	 * Toggles Active status of a company.
	 * @param {object} company
	 */
	toggleStatus(company) {
		let toggledStatus = !company.active;
		this.lioModalService.showLoading('processing');
		this.lmsService.post('company/setActive', {
			'companyID' : company.companyID, 'active' : toggledStatus
		}).then((result) => {
			if (result.success) {
				company.active 			= toggledStatus;
				company.activeStatus 	= toggledStatus ? 'Active' : 'Inactive';
				company.activeColor 	= toggledStatus ? 'primary' : '';
				this.updateCompanies(company);
				let heading = toggledStatus ? 'activatedSuccessfully' : 'deactivatedSuccessfully',
					message = toggledStatus ? 'companyActivated' : 'companyDeactivated',
					macros = [{'key': 'coName', 'value': company.coName}]
				this.lioModalService.show(heading, message, macros);
			}
			this.lioModalService.hideLoading();
		})
	}

	/*
	 * Update the company list.
	 */
	updateCompanies(company) {
		let companyExists = false;
		this._companies.forEach((_company, index) => {
			if (_company.companyID === company.companyID) {
				companyExists = true;
				this._companies[index] = company;
				this.companies.next(this._companies);
				return;
			}
		})
		if (!companyExists) {
			this._companies.push(company);
		}
	}

	/*
	 * Set selected company by company ID.
	 *
	 * @param {string} companyID
	 * @return {void}
	 */
	setCompanyByCompanyID(companyID) {
		this._companies.every((company) => {
			if (company.companyID == companyID) {
				this.setCompany(company);
				return false;
			}
			return true;
		})
	}


	copyCompany(company) {
		this.lioModalService.showLoading('loading');
		company = this.utilService.copy(company);
		this.getSubCompanies(company.companyID).then(() => this.lioModalService.hideLoading());
		company.companyID = null;
		this.setCompany(company);
		this.onEvent.next('copy');
	}

	/*
	 * Format company.
	 *
	 * This function sets numeric string values to true or false
	 * to ensure checkboxes and switches display correctly.
	 *
	 * @return {array}
	 */
	formatCompany(company) {
		let companyKeys = Object.keys(company);
		companyKeys.forEach((key) => {
			let needsFormat = false;
			let formatKeys = ['active', 'allowSpiderSSO', 'changeLanguage',
				'changePassword', 'editprofile','randomizesPassword'];
			if (this.utilService.inString('Visible', key) || this.utilService.inString('Groupable', key)) {
				needsFormat = true;
			} else if (formatKeys.indexOf(key) !== -1) {
				needsFormat = true;
			}
			if (needsFormat) {
				if (company[key] === '1') {
					company[key] = true;
				} else if (company[key] === '0') {
					company[key] = false;
				}
			}
			company.activeStatus 	= company.active ? 'Active' : 'Inactive';
			company.activeColor 	= company.active ? 'primary' : '';
		});
		return company;
	}
}
