<div class="container-fluid p-0" [style.fontSize.px]="12">
	<div id="searches">
		<lio-fields [fields]="searchFields">
			<div class="row">
				<div class="col-sm-3" 
					*ngFor="let field of searchFields; index as i" 
					[hidden]="!field.visible" 
					[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">

					<mat-form-field *ngIf="field.type != 'select' && field.type != 'date'"
						[appearance]="appearance" 
						localize [model]="field" item="name"
						class="w-100">

						<!-- LABEL -->
						<mat-label id="am_form_label_{{field.model}}">
							<span class="sr-only">Field Input</span>{{field.name}}
						</mat-label>

						<input matInput 
							type="text" 
							[id]="'am_' + field.model" 
							[placeholder]="field.name" 
							[(ngModel)]="search[field.model]"
							(change)="update.emit(field)">
					</mat-form-field>

					<div *ngIf="field.type == 'date'">
						<lio-mat-wrap-date-field 
							[amID]="field.id"
							[model]="search"
							[field]="field"
							(onUpdate)="update.emit(field)" 
							[placeholder]="field.name">
						</lio-mat-wrap-date-field>
					</div>

					<lio-searchable-select *ngIf="field.type == 'select'"
						[model]="search"
						[field]="searchFields[i]"
						(onUpdate)="update.emit(field)"
						[appearance]="appearance">
					</lio-searchable-select>
				</div>
			</div>
		</lio-fields>
	</div>
</div>