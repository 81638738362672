/*
 * Service for Login
*/
import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { browserService } from './browser.service';
import { localizationService } from './localization.service';
import { notificationsService } from './notifications.service';
import { lmsService } from './lms.service';
import { settingsService } from './settings.service';
import { stateService } from './state.service';
import { storageService } from './storage.service';
import { brandingService } from './branding.service';
import { configSettings } from '../settings/config.settings';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class loginService {
	gettingBranding		: boolean		= false;
	loadedBrandIds		: Array<any>	= [];
	loadedBrands		: Array<any>	= [];
	sentSID				: boolean		= false;
	SID					: any			= null;
	euid				: any			= null;
	invalidCompanyID	: boolean		= false;

	private _loggedIn		: boolean				= false;
	public loggedIn			: ReplaySubject<void> 	= new ReplaySubject(1);
	public notLoggedIn		: ReplaySubject<void> 	= new ReplaySubject(1);
	public loggedOff		: ReplaySubject<void> 	= new ReplaySubject(1);
	public employeeSet		: ReplaySubject<void> 	= new ReplaySubject(1);

	constructor(
		@Inject(configSettings)				private configSettings				: configSettings,
		@Inject(browserService)				private browserService				: browserService,
		@Inject(lmsService)					private lmsService					: lmsService,
		@Inject(localizationService)		private localizationService			: localizationService,
		@Inject(notificationsService)		private notificationsService		: notificationsService,
		@Inject(settingsService)			private settingsService				: settingsService,
		@Inject(stateService)				private stateService				: stateService,
		@Inject(storageService)				private storageService				: storageService,
		@Inject(brandingService)			private brandingService				: brandingService,
		@Inject(debugService)				private debugService				:debugService
	){
		this.debugService.register('loginService', this);
	}

	public setLoggedIn(status:boolean) {
		this._loggedIn = status;
	}


	/*
	 * Has a session
	 * @return {boolean}
	*/
	public hasSession() {
		return this._loggedIn;
	}

	/*
	 * Determines if user is logged in, if so, gets the session
	*/
	isLoggedIn() {
		let paramsCompanyID		= this.storageService.getParams().companyID, 
			companyID			= this.stateService.getActiveCompanyID(),
			storedCompanyID		= this.storageService.get('companyID'),
			euid				= this.storageService.getParams().euid || this.storageService.getParams().EUID,
			langID				= this.storageService.getLangID();

		if (paramsCompanyID) {
			companyID = paramsCompanyID; 
		}

		if (!companyID) {
			companyID = storedCompanyID;
		}

		this.storageService.remove('frontEndCompanyID');
		this.storageService.remove('frontEndPermissionID');
		this.storageService.remove('frontEndEmployeeID');
		this.euid = euid;

		this.lmsService.post('quickaccess/getSession', {'companyID': companyID, 'euid': this.euid, 'langID': langID}).then((result:any) => {
			if (result.properties.session) {
				return this.stateService.getState().then(() => {
					this.handleSession(result);
				});
			} else {
				if (!result.properties.company) {
					this.invalidCompanyID = true;
				}
				
				return this.stateService.getState().then(() => {
					this.handleSession(result);
				});
			}
		});
	}

	/*
	 * Submit a login by SID and companyID
	*/
	submitLogin(SID, companyID) {
		return this.lmsService.post('useraccess/submitLogin', {'SID': SID, 'companyID': companyID}).then((result) => {
			let session = result.properties.session;
			return this.getLocalization(session, companyID);
		});
	}

	/*
	 * Handles the session
	 * @param {array} result
	*/
	handleSession(result) {
		let session			= result.properties.session,
			corpediaServer	= result.properties.corpediaServer,
			notifications	= result.properties.notifications,
			branding		= result.properties.branding,
			employee		= result.properties.employee,
			companyID 		= result.properties.companyID,
			company			= result.properties.company;

			if (corpediaServer) {
				this.configSettings.corpediaServer = corpediaServer;
			}

			if (employee) {
				this.storageService.set('employee', employee);
				this.invalidCompanyID = false;
				this.employeeSet.next(employee);
			}

			if (company && company.companyID) {
				companyID = company.companyID;
			} else if (employee && employee.companyID) {
				companyID = employee.companyID;
			}
			
			if (session) {
				this.stateService.setSession(session);
				this.stateService.setLoggedIn(true);
			} else {
				this.stateService.setSession(null);
				this.stateService.setLoggedIn(false);
				if (this.euid && employee) {
					this.stateService.setActiveCompanyID(employee.companyID);
					this.stateService.setEmployeeID(employee.employeeID);
					this.invalidCompanyID = false;
				}
			}

			this.setCompanySettings(result.properties);

			let forceIEToEdge = this.settingsService.getSetting('forceIEToEdge');
			if (this.browserService.navigators.isIE && forceIEToEdge) {
				let sid = this.stateService.getSID(),
					url = this.configSettings.serverGlobal + 'login';
				
				if (sid) {
					url += '?SID=' + encodeURIComponent(sid);
				}
				this.browserService.forceUseEdge(url);
			}

			this.notificationsService.init(notifications);
			this.brandingService.setBranding(branding);
			this.getLocalization(session, companyID).then(() => {
				if (session || this._loggedIn) {
					this._loggedIn = true;
					this.loggedIn.next();
				} else {
					this._loggedIn = false;
					this.notLoggedIn.next();
				}
			})
	}


	logout() {
		return this.lmsService.get('useraccess/logOut').then(() => {
			this.loggedOffVerified();
		});
	}


	loggedOffVerified() {
		this.stateService.clearSession();
		this.storageService.handleLoggedOff();
		this.stateService.setLoggedIn(false);
		this.stateService.logOff();
		this.loggedOff.next();
		this.euid = null;
	}


	/*
	 * Gets localization by session and companyID
	 * @param {object} sesion
	 * @param {?string} companyID
	*/
	getLocalization(session:any = {activeCompany:null}, companyID = this.stateService.activeCompany.companyID) {
		let langID = this.localizationService.getLangIDFromSession(session);

		companyID = session.activeCompany ? session.activeCompany.companyID : companyID;

		return this.lmsService.post('localization/getFromCompanyID', {'companyID': companyID, 'langID': langID}).then((result:any) => {
			this.localizationService.setLocalizations(langID, result);
		});
	}


	/*
	 * Gets localization by langID
	 * @param {string} langID
	*/
	getLocalizationByLangID(langID) {
		let companyID = this.stateService.activeCompany.companyID;
		return this.lmsService.post('localization/getFromCompanyID', {'companyID': companyID, 'langID': langID}).then((result:any) => {
			this.localizationService.setLocalizations(langID, result);
		});
	}

	/*
	 * Sets the company settings
	 * @param {array} settings
	*/
	setCompanySettings(settings) {
		var ccf					= settings.ccf,
			ccfValues			= settings.ccfValues,
			permissions			= settings.permissions;

		settings = settings.settings;

		this.storageService.set('ccfs', ccf);
		this.storageService.set('ccfValues', ccfValues);
		this.storageService.set('permissions', permissions);
		this.storageService.set('settings', settings);
		this.settingsService.initSettings(settings);
		this.settingsService.setCompanyRestrictions(permissions);
		this.settingsService.apply();

	}

	/*
	 * Gets the company settings
	*/
	updateCCFValues() {
		if(!this.settingsService.getSetting('suppressCCF')){
			this.lmsService.get('company/getCCFValues').then((result:any) => {
				var ccfValues = result.properties.ccfValues;
				if (ccfValues) {
					this.storageService.set('ccfValues', ccfValues);
				}
			});
		}
	}
}