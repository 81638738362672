import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: disclosureReportSettings
})
export class disclosureReportSettings {
	disclosureField:any = {
		name			: 'Disclosures',
		nameTrans		: 'disclosure.disclosures',
		model			: 'data',
		optionNameField : 'title',
		optionAsValue	: true,
		searchable 		: true,
		addEmptyOption 	: true
	};

	panelConfig:any = {
		completions	: {
			id				: 'panelTab_disclosureReport_completions',
			bodyClass 		: 'none',
			type			: 'completions',
			subtitle		: 'Users who have completed this disclosure',
			subtitleTrans	: 'disclosureReport.completionsSubtitle',
			tab				: 'Completions',
			tabTrans		: 'disclosureReport.completionsTab',
			pagination		: {
				id 				: 'disclosureReportCompletions',
				pageLimit		: '10',
				pageLimits		: ['10', '50', '100'],
				sortMode		: {field : 'lastName', direction : 'asc'}
			},
			fieldConfig 	: {
				'fieldName'			: 'disclosureReportCompletions',
				'addCCFtoFields'	: true,
				'localizeFields'	: true,
				'addRoleToFields'	: true,
				'overrideFields'	: true,
				'addLangField'		: true,
				'permissionFields'	: true,
				'ccfFields': {
					'visible': false,
					'export': false,
					'required': false,
				},
				'activeField': {
					'visible': false,
					'export': false,
					'required': false,
				},
				'roleField': {
					'visible': false,
					'export': false,
					'required': false,
				}
			},
			fields: new LioFieldArray(
				{
					name	: 'Employee ID',
					model	: 'employeeID',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'First Name',
					model	: 'firstName',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'Last Name',
					model	: 'lastName',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'e-mail',
					model	: 'email',
					type	: 'email',
					sortable: true
				},
				{
					name	: 'Completion Date',
					model	: 'completionDate',
					type	: 'date',
					sortable: true
				},
				{
					name	: 'Lang ID',
					model	: 'langID',
					type	: 'text',
					sortable: true
				},
			),
		},
		incompletions	: {
			id				: 'panelTab_disclosureReport_incompletions',
			bodyClass 		: 'none',
			subtitle		: 'Users who have not completed this disclosure',
			subtitleTrans	: 'disclosureReport.incompletionsSubtitle',
			tab				: 'Incompletions',
			type			: 'incompletions',
			tabTrans		: 'disclosureReport.incompletionsTab',
			pagination		: {
				id 				: 'disclosureReportincompletions',
				pageLimit		: '10',
				pageLimits		: ['10', '50', '100'],
				sortMode		: {field : 'lastName', direction : 'asc'}
			},
			fieldConfig 	: {
				'fieldName'			: 'disclosureReportIncompletions',
				'addCCFtoFields'	: true,
				'localizeFields'	: true,
				'permissionFields'	: true,
				'addRoleToFields'	: true,
				'overrideFields'	: true,
				'addLangField'		: false,
				'ccfFields': {
					'visible': false,
					'export': false,
					'required': false,
				},
				'activeField': {
					'visible': false,
					'export': false,
					'required': false,
				},
				'roleField': {
					'visible': false,
					'export': false,
					'required': false,
				}
			},
			fields: new LioFieldArray(
				{
					name	: 'Employee ID',
					model	: 'employeeID',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'First Name',
					model	: 'firstName',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'Last Name',
					model	: 'lastName',
					type	: 'text',
					sortable: true
				},
				{
					name	: 'e-mail',
					model	: 'email',
					type	: 'email',
					sortable: true
				},
				{
					name	: 'Course Started',
					model	: 'courseStarted',
					type	: 'checkbox',
					sortable: true
				},
				{
					name	: 'Course Completed',
					model	: 'courseCompletion',
					type	: 'checkbox',
					sortable: true
				},
				{
					name	: 'Course Completion Date',
					model	: 'courseCompletionDate',
					type	: 'date',
					sortable: true
				},
			),
		},
		clear 				: {
			id					: 'panelTab_disclosureReport_back',
			type				: 'clear',
			tab					: 'Back',
			tabTrans			: 'disclosureReport.back',
			class				: 'bg-danger text-light nav-link'
		}
	};
}