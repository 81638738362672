import { Component, Input, Output, ViewChild, ElementRef, OnInit, EventEmitter, Inject } from '@angular/core';

import { utilService } from 'src/app/services/util.service';

@Component({
	selector: 'lio-disclosure-pdf',
	templateUrl: './disclosure-pdf.component.html',
})
export class LioDisclosurePDF implements OnInit {
	@ViewChild('pdfTarget') public pdfTarget:ElementRef;
	@Output() public pdfTargetReady:EventEmitter<ElementRef> = new EventEmitter();

	private _responseData:any = null;
	public get responseData():any{
		return this._responseData;
	}
	@Input() public set responseData(val:any){
		this._responseData = val;

		if(val && val.responses){
			val.completion.completionDate = this.utilService.formatDate(val.completion.completionDate);
			this.localeStrings.headerMacros = [
				{key : 'firstName'				, value : val.completion.firstName},
				{key : 'lastName'				, value : val.completion.lastName},
				{key : 'courseCompletionDate'	, value : val.completion.completionDate}
			];
			this.localeStrings.subHeaderMacros = this.localeStrings.headerMacros;
		}

		this.pdfTargetReady.emit(this.pdfTarget);
	}

	public localeItems:Array<string> = [
		'header',
		'subHeader',
		'questionLabel',
		'answerLabel',
	];

	public localeStrings:any = {
		header				: '',
		headerTrans			: 'disclosurePDF.header',
		headerMacros 		: [],
		subHeader			: '',
		subHeaderTrans		: 'disclosurePDF.subHeader',
		subHeaderMacros		: [],
		questionLabel		: 'Question : ',
		questionLabelTrans	: 'disclosurePDF.questionLabel',
		answerLabel			: 'Answer : ',
		answerLabelTrans	: 'disclosurePDF.answerLabel',
	};

	constructor(@Inject(utilService) public utilService:utilService){}

	ngOnInit(){
		this.pdfTargetReady.emit(this.pdfTarget);
	}
}