import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DefaultModalComponent } from '.././default/default.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-input',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', './input.component.css']
})
export class InputComponent extends DefaultModalComponent implements OnInit {
	public selection 			= null;
	public model 				= {value:null};
	public submitted 			= false;

	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public selectionFilterCtrl: FormControl = new FormControl();
	public filteredSelection: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	protected _onDestroy = new Subject<void>();

	public override settings : any = {
		'id' : 'am_modal_input',
		'title': 'Please input',
		'titleTrans': 'modal.pleaseInput',
		'canClose': false,
		'submit': 'Submit',
		'submitTrans': 'modal.submit',
		'searchFail': 'No matching options found',
		'searchFailTrans': 'user.searchFail',
		'close': 'Close',
		'closeTrans': 'modal.close',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		if (this.settings.options) {
			this.filteredSelection.next(this.settings.options.slice());
		}

		this.selectionFilterCtrl.valueChanges
		.pipe(takeUntil(this._onDestroy))
		.subscribe(() => {
			this.filterSelection();
		});
	}

	filterSelection(){
		if (!this.settings.options) {
			return;
		}
		// get the search keyword
		let search = this.selectionFilterCtrl.value;
		if (!search) {
			this.filteredSelection.next(this.settings.options.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		// filter the banks
		this.filteredSelection.next(
			this.settings.options.filter(option => option.name.toLowerCase().indexOf(search) > -1)
		);
	}

	/*
	 * Submit
	*/
	submit(response:any = true): void {
		this.submitted = true;
		this.submitted_emitter.emit(response);
	}
}
