<div class="modal-container" id="settings.catalog_modal">
	<!-- Header -->
	<header>
		<button (click)="close()" [hidden]="!settings.canClose" class="button-hide modal-closer float-right">
			<i class="modal-close btn-cursor fa fa-times text-secondary"></i>
	        <span class="sr-only">Close Modal</span>
		</button>
	</header>

	<section>
		<div class="envelope">
			<h5>
				<span>Subject: </span><span>{{settings.subject}}</span>
			</h5>
			<h6>
				<span class="font-normal">From: </span><span >{{settings.template.fromName}} <{{settings.template.fromAddress}}></span>
			</h6>
			<h6>
				<span class="font-normal">To: </span><span >{{settings.employee.firstName}} {{settings.employee.lastName}} <{{settings.employee.email}}></span>
			</h6>
			<h6 ng-show="template.replyTo">
				<span class="font-normal">Reply To: </span><span >{{settings.template.replyTo}}</span>
			</h6>
			<h6>
				<span class="font-normal">Date: {{date}}</span>
			</h6>
		</div>
		<div id="emailFrameContainer" [ngClass]="{'ie-iframe-modal-container': isIE}">
			<div [hidden]="isIE">
				<iframe class="email-frame w-100" [srcdoc]="settings.message | html"></iframe>
			</div>
			<div [hidden]="!isIE" class="ie-frame">
				<div class="email-frame w-100" [innerHTML]="settings.message | html">></div>
			</div>
		</div>
	</section>
	<!-- Footer -->
	<footer class="modal-footer">
		<button class="btn btn-sm btn-primary mx-auto" mat-raised-button id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>