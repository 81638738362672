<div [hidden]="(settings.mode == 'read' || settings.mode == 'review') && question.disabled" 
	[ngClass]="{'bg-danger' : question.invalid}" 
	class="list-group-item" >
	<div *ngIf="settings.mode == 'read' || settings.mode == 'review'">
		<lio-disclosure-text [settings]="settings" 
			[model]="question">
		</lio-disclosure-text>
		<lio-disclosure-response [settings]="settings" 
			[appearance]="appearance"
			[response]="question.response"
			(optionAdded)="optionAdded.emit($event)" 
			(notificationResponseAdded)="notificationResponseAdded.emit($event)"
			(notificationResponseRemoved)="notificationResponseRemoved.emit($event)">
		</lio-disclosure-response>
	</div>
	<div *ngIf="settings.mode == 'edit'">
		<lio-card cardClass="bg-faded-blue text-white mb-0" bodyClass="bg-white text-dark" footerClass="bg-light">
			<div lio-card-header>
				<div class="row">
					<h5 class="col-12 col-md-6 text-left">Question ID : {{question.questionid}}</h5>
					<div class="col-12 col-md-6 text-right">
						<mat-slide-toggle *ngIf="!question.disabledCondition" 
							class="mr-2"
							color="primary"
							[checked]="!question.noresponserequired" 
							(change)="question.noresponserequired = !$event.checked">
							<span>Required</span>
						</mat-slide-toggle>

						<button mat-flat-button color="primary" 
							(click)="addDisabledCondition()" 
							*ngIf="!question.disabledCondition && (disabledConditionQuestionField.options | asArray).length > 0">Add Show Condition</button>
						<button mat-flat-button color="warn"
							class="ml-2"
							(click)="removeDisabledCondition()" 
							*ngIf="question.disabledCondition">Remove Show Condition</button>
						<button mat-flat-button color="warn"
							class="ml-2"
							[disabled]="settings.wasActive" 
							(click)="delete.emit(question)">

							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div lio-card-body>
				<div class="list-group-item form-inline" *ngIf="question.disabledCondition">
					<div class="form-row">
						<lio-searchable-select [appearance]="appearance" 
							[field]="disabledConditionQuestionField" 
							[model]="question.disabledCondition">
						</lio-searchable-select>

						<lio-searchable-select [appearance]="appearance" 
							class="ml-2"
							[field]="disabledConditionResponseField" 
							[model]="question.disabledCondition">
						</lio-searchable-select>
					</div>
				</div>
				<lio-disclosure-text [settings]="settings" [model]="question"></lio-disclosure-text>
			</div>
			
			<div lio-card-footer>
				<lio-searchable-select [appearance]="appearance" 
					[field]="responseTypeField" 
					[model]="question.response" 
					[disabled]="settings.wasActive && question.response.type">
				</lio-searchable-select>

				<lio-disclosure-response [settings]="settings" 
					[appearance]="appearance"
					[response]="question.response"
					(optionAdded)="optionAdded.emit($event)"
					(notificationResponseAdded)="notificationResponseAdded.emit($event)"
					(notificationResponseRemoved)="notificationResponseRemoved.emit($event)">
				</lio-disclosure-response>
			</div>
		</lio-card>
	</div>
</div>