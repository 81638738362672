<div [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'" [formGroup]="formGroup">
	<mat-form-field 
		[appearance]="appearance" 
		[ngClass]="{'lio-font-sm': sm}" 
		(click)="dateField.open()"
		class="w-100 p-0">
		<!-- LABEL -->
		<mat-label *ngIf="showLabel" id="am_form_label_{{field.model}}">
			<span class="sr-only">Date Input</span>{{field.name}}
		</mat-label>
		
		<input matInput 
			[formControl]="formControlRef"
			[matDatepicker]="dateField"
			[name]="field.model"
			[id]="amID"
			type="text" 
			autocomplete="new-password"
			[placeholder]="placeholder"
			[(ngModel)]="_date"
			(dateChange)="onChange()"
			[matDatepickerFilter]="blackoutFilter"
			[min]="minDateMoment()" 
			[max]="maxDateMoment()"
			[required]="required">
		<mat-datepicker-toggle matSuffix [for]="dateField"></mat-datepicker-toggle>
		<mat-datepicker #dateField></mat-datepicker>
	</mat-form-field>
</div>