import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-catalog-modules',
	templateUrl: './modules.component.html',
})
export class LioCatalogModules {
	@Input() model:any;
	@Input() update:any;
	@Input() field:any;
	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();
	@Input() appearance				:MatFormFieldAppearance = 'fill';

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}

	removeModule(index) {
		this.model.modules.splice(index, 1);
		this.onUpdate.emit();
	}

	addModule() {
		this.model.modules.push({
			name: '',
			description: '',
			url: '',
			scriptLocation: '',
		});
	}
}