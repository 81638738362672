<div *ngIf="(settings.mode == 'read' || settings.mode == 'review') && response.type" class="row">
	<div class="col-12 col-md-9 mx-auto">
		<lio-searchable-select *ngIf="response.type=='singleselect'" 
			[appearance]="appearance" 
			[field]="inputField" 
			[model]="response.input">
		</lio-searchable-select>

		<div *ngIf="response.type=='complextext'" class="input-group w-100">
			<textarea class="w-100 form-control" [(ngModel)]="response.input.value" style="height:100px;"></textarea>
		</div>
		<mat-form-field *ngIf="response.type=='simpletext'" 
			[appearance]="appearance" 
			class="ml-2 w-100">
			<input matInput [(ngModel)]="response.input.value"/>
		</mat-form-field>
	</div>
</div>
<lio-card *ngIf="settings.mode == 'edit' && response.type=='singleselect'" 
	cardClass="bg-faded-blue text-white mt-1" 
	bodyClass="bg-white text-dark pl-2 pr-2 pt-2 pb-0">

	<div lio-card-header>
		<div class="row text-right">
			<h4 class="col-sm-6">Options</h4>
			<div class="col-sm-6">
				<button mat-flat-button color="primary" (click)="addOption()">Add Option</button>
			</div>
		</div>
	</div>
	<div lio-card-body>
		<div class="mb-2 d-flex" [style.fontSize.rem]="0.75" *ngFor="let option of response.options">
			<button class="my-1" mat-flat-button color="warn" [disabled]="settings.wasActive" (click)="deleteOption(option)">
				<mat-icon>delete</mat-icon>
			</button>
			<mat-form-field [appearance]="appearance" class="ml-2" [style.width.px]="75">
				<mat-label>ID</mat-label>
				<input matInput disabled type="text" [(ngModel)]="option.value">
			</mat-form-field>
			<mat-form-field [appearance]="appearance" class="ml-2 w-100">
				<mat-label>Name</mat-label>
				<input matInput type="text" [(ngModel)]="option.text">
			</mat-form-field>
			<mat-slide-toggle class="ml-2 my-2 py-1"
				color="primary"
				[(ngModel)]="option.notify" 
				(change)="toggledNotification(option)">
				<span>Notify</span>
			</mat-slide-toggle>
		</div>
	</div>
</lio-card>