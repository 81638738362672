import { Component, Inject, Input } from '@angular/core';

import { localizationService } from './../../../../services/localization.service';

@Component({
	selector: 'tooltip',
	templateUrl: './tooltip.component.html'
})
export class LioTooltip {
	private _tooltipField:any		= null;
	private _tooltipValue:string	= '';

	public innerVal:string 			= '';

	constructor(
		@Inject(localizationService) private localizationService :localizationService
	){}

	@Input()
	set tooltipField(val){
		this._tooltipField = val;
		this.render();
	}

	@Input()
	set tooltipValue(val){
		this._tooltipValue = val;
		this.render();
	}

	render() {
		let field = this._tooltipField;
		let model = field.model ? field.model : field;

		if (model) {
			this.innerVal = this.localizationService.get('tooltip.' + model);
		}
		else if (this.tooltipValue) {
			//uses a direct value instead of a translation
			this.innerVal = this._tooltipValue;
		}
	}
}