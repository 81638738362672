import { Component, OnInit, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { fieldService } from '../../services/fields.service';
import { exportService } from '../../services/export.service';
import { permissionService } from '../../services/permissions.service';
import { ccfService } from '../../services/ccf.service';
import { utilService } from '../../services/util.service';
import { browserService } from '../../services/browser.service';

import { reportSettings } from './reports.settings';
import { reportCreatorSettings } from './report-creator.settings';


@Component({
  selector: 'lio-report-creator',
  templateUrl: './report-creator.component.html'
})
export class ReportCreatorComponent implements OnInit, OnDestroy {

	public queryFilters:any 							= [];
	public employeeFields:any							= [];
	public reportTypes:any 								= [];
	public report:any 										= {};
	public fields:any 										= [];
	public combinedReports:any						= [];
	public enrollmentFields:any 					= [];
	public filters:any 										= [];
	public fieldConfig:any 								= {};
	public panelConfig:any 								= {};
	public searchFields:any 							= [];
	public searchFieldConfig:any 					= {};
	public pagination:any 								= null;
	public customReportType:any 					= null;
	public reportType:any 								= {};
	public filteredEmployees:any 					= [];
	public formData:any 									= [];
	public employees:any 									= [];
	public employeeIDs:any 								= [];
	public debugging:boolean 							= false;
	public allowSubmit:boolean 						= false;
	public errorlog:any 									= null;
	public searchers:any 									= null;
	public serverName:any 								= null;
	public selectedField:any 							= null;
	public model:any 											= {};
	public permissions:any 								= null;
	private subscriptions:Subscription 		= NEVER.subscribe();

  constructor(
		@Inject(navService) 						public 	navService:navService,
		@Inject(debugService)	 					public 	debugService:debugService,
		@Inject(feedbackService) 				public 	feedbackService:feedbackService,
		@Inject(errorsService) 					public 	errorsService:errorsService,
		@Inject(lmsService) 						public 	lmsService:lmsService,
		@Inject(lioLogService) 					public 	lioLogService:lioLogService,
		@Inject(stateService) 					public 	stateService:stateService,
		@Inject(workerService) 					public 	workerService:workerService,
		@Inject(permissionService)			public 	permissionService:permissionService,
		@Inject(lioModalService)				public 	lioModalService:lioModalService,
		@Inject(utilService)						public 	utilService:utilService,
		@Inject(exportService)					public 	exportService:exportService,
		@Inject(fieldService)						public 	fieldService:fieldService,
		@Inject(browserService)					public 	browserService:browserService,
		@Inject(ccfService)							public 	ccfService:ccfService,
		@Inject(reportSettings) 				public 	reportSettings:reportSettings,
		@Inject(reportCreatorSettings)	public 	reportCreatorSettings:reportCreatorSettings
  ){
		this.debugService.register('reportcreator', this);
		this.navService.setActivePage('reportcreator');
		
		this.filters 					= [];
		this.report 					= {};
		this.queryFilters 				= this.reportSettings.baseFilters;
		this.reportTypes 				= this.reportSettings.reportTypes;
		this.customReportType			= this.reportSettings.customReportType;
		this.enrollmentFields 			= this.reportCreatorSettings.enrollmentFields;
		this.employeeFields 			= this.reportCreatorSettings.employeeFields;
		this.panelConfig 				= this.reportCreatorSettings.panelConfig;
		this.formData 					= this.reportCreatorSettings.formData;
		this.model 						= this.reportCreatorSettings.model;


		this.reportType 				= {};
		this.debugging 					= true;
		this.permissions 				= this.permissionService;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
				this.bindFormData();
			})
		);
	}

	bindFormData() {
		this.formData.addNew 								= (item) => { return this.addNew(item); };
		this.formData.addEmployeeFields 		= () => { return this.addEmployeeFields(); };
		this.formData.addEnrollmentFields 	= () => { return this.addEnrollmentFields() };
		this.formData.addEnrollmentJoints 	= () => { return this.addEnrollmentJoints() };
		this.formData.addCompanyWhere 			= () => { return this.addCompanyWhere() };
		this.formData.remove 								= (item, model) => { return this.remove(item, model) };
		this.formData.cleanFields 					= () => { return this.cleanFields() };
		this.formData.addCCFFields 					= () => { return this.addCCFFields() };

	}

	onReportLoaded(report) {
		if (report && report.id) {
			this.report = report;
		} else {
			this.report = Object.assign(report, this.utilService.copy(this.model));
		}
		if (!this.report.settings) {
				this.report.settings = this.utilService.copy(this.model.settings);
		}
	}

	onClick(fn) {
		this[fn]();
	}

	/*
	 * Resets the messages
	*/
	resetMessages() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
	}

	getSQL() {
		this.lioModalService.showLoading('loadingReport');
		this.resetMessages();

		return this.lmsService.post('customreports/getSQL', {'report': this.report, 'fields': this.report.fields}).then((result) => {
			let sql = result.properties.sql;
			if (sql) {
				this.feedbackService.addError(sql);
			}
			this.lioModalService.hideLoading();
		});
	}

	/*
	 * Copies the report to the cache
	*/
	copyReportToCache() {
		let copiedReport = this.utilService.copy(this.report);
		copiedReport.id = null;
		copiedReport.name += ' (COPY)';
		this.browserService.copyToClipboard(copiedReport);
	}

	/*
	 * Pastes the report from the cache
	*/
	pasteReportFromCache() {
		this.browserService.pasteFromClipboard().then((copiedReport) => {
			if (copiedReport) {
				this.report = this.utilService.copy(copiedReport);
			}
		});
	}

	/*
	 * Modify model prior to creation
	*/
	modifyReportPriorToSave(report) {
		let settings = [],
				key;

		// Settings needs to be a converted into an array 
		for (key in report.settings) {
			settings.push({
				'type': 'settings', 
				'name': key, 
				'value': report.settings[key]
			});
		}

		report.settings = settings;
	}


	addNew(item) {
		if (!this.report[item]) {
			this.report[item] = [];
		}
		this.report[item].push({});
	}

	addEmployeeFields() {
		this.employeeFields.forEach((field) => {
			let exists = false;
			this.report.fields.forEach((existingField) => {
				if (field.model === existingField.model) {
					exists = true;
				}
			});
			if (!exists) {
				this.report.fields.push(field);	
			}
		});
		this.cleanFields();
	}

	addCCFFields() {
		this.report.fields = this.ccfService.addCCFtoFields(this.report.fields, {});
		this.cleanFields();
	}

	addEnrollmentFields() {
		this.enrollmentFields.forEach((field) => {
			let exists = false;
			this.report.fields.forEach((existingField) => {
				if (field.model === existingField.model) {
					exists = true;
				}
			});
			if (!exists) {
				this.report.fields.push(field);	
			}
		});
		this.cleanFields();
	}

	addEnrollmentJoints() {
		this.report.joints.push({
			'tableName': 'tblEmployeesincourses',
			'alias': 'ec',
			'joinOn': 'UID',
			'matchOn': 'UID',
			'matchOnAlias': 'e',
		});

		this.report.joints.push({
			'tableName': 'tblCourses',
			'alias': 'co',
			'joinOn': 'courseID',
			'matchOn': 'courseID',
			'matchOnAlias': 'ec',
		});
	}
	
	addCompanyWhere() {
		this.report.wheres.push({
			'alias': 'e',
			'field': 'companyID',
			'operator': '=',
			'value': '{{companyID}}',
		});
	}
	
	remove(item, model) {
		let index = this.report[item].indexOf(model);
		this.report[item].splice(index, 1);
	}

	/*
	 * Cleans the Fields
	*/
	cleanFields() {
		this.report.fields.forEach((field) => {
			if (field.type !== 'date') {
				field.type = 'text';
			}
		});
	}


	/*
	 * Got report results
	*/	
	handleResponse(result) {
		if (result) {
			let records = result.properties.employees;
			this.getFieldsByType().then((fields) => {
				this.exportRecords(records, fields);
			});	
		}
	}

	/*
	 * Tests reports by getting the total records
	*/
	testReport() {
		this.lioModalService.showLoading('loadingReport');
		this.resetMessages();

		return this.lmsService.post('customreports/testReport', {'report': this.report, 'fields': this.report.fields}).then((result) => {
			this.lioModalService.hideLoading();		
			if (result.success) {
				if (result.properties.total) {
					let macros = [{'key': 'total', 'value': result.properties.total}];
					this.lioModalService.show('customreports.foundTotalResults', null, macros);
				}
			}
		});
	}

	/*
	 * Exports the test report
	*/
	testExportReport() {
		this.resetMessages();

		return this.lmsService.postAsync('customreports/testExportReport', {'report': this.report, 'fields': this.report.fields},
		'loadingReport').then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					let fields = result.properties.fields,
						employees = result.properties.employees;

					this.lioModalService.hideLoading();
					if (result.success) {
						if (employees) {
							this.exportRecords(employees, fields);
						}
					}
				});
			} else {
				this.lioModalService.hideLoading();
				let fields = result.properties.fields,
					employees = result.properties.employees;
				
				if (result.success) {
					if (employees) {
						this.exportRecords(employees, fields);
					}
				}
			}
		});
	}



	/*
	 * Gets the fields by the report type
	*/
	getFieldsByType() {
		let fields = this.reportType.fields,
			fieldConfig = this.reportType.fieldConfig,
			type = this.reportType.type;

		if (!fieldConfig) {
			fieldConfig  = {
				'fieldName': type,
			};
		}

		// Custom reports need export turned on
		if (this.reportType.isCustom) {
			fields.forEach((field) => {
				field.export = true;
			});
		}
		return this.fieldService.setFields(fields, fieldConfig);
	}

	/*
	 * Exports the records
	 * @param {array} records
	 * @param {array} fields
	*/
	exportRecords(records, fields) {
		this.lioLogService.log(['Exporting', records, fields]);
		let name = this.report.selectedReport.name;
		this.exportService.exportReport(records, fields, name).then((result) => {
			if (!result) {
				this.lioModalService.show('Failed to export ' + name);
			} else {
				this.lioModalService.hideLoading();
			}
		});
	}


	/*
	 * Gets all records and inits
	*/
	init() {
		this.navService.displayPage();
	}


	handleClick($fn) {
		this[$fn]();
	}


	ngOnInit() {
		this.init();
		this.navService.displayPage();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}