<div localize [model]="localizations" [items]="localizationItems">
	<!-- Close Filters -->
	<section class="row mb-3" *ngIf="employeesLoaded">
		<div class="col-lg-12 text-right">
			<button id="am_dashboard_filters_open" [hidden]="!showFilters" [disabled]="!canCloseFilters" class="btn btn-sm btn-outline-primary" type="button" (click)="toggleFilters()"><i class="fa fa-sort-up" aria-hidden="true"></i> &nbsp;&nbsp;<span>{{localizations.closeFilters}}</span></button>
			<button id="am_dashboard_filters_close" [hidden]="showFilters" [disabled]="!canOpenFilters" class="btn btn-sm btn-outline-primary hc-dark-text" type="button" (click)="toggleFilters()"><i class="fa fa-filter" aria-hidden="true"></i><span>{{localizations.filters}}</span>&nbsp;&nbsp;&nbsp;</button>
		</div>
	</section>

	<!-- FILTERS -->
	<section class="row mb-3" [hidden]="!showFilters">
		<div class="col-lg-12">
			<lio-card cardId="dashboard_filter" [showClose]="true">
				<div lio-card-header>
					<div id="am_dashboard_filter_title" class="h4">{{localizations.header}}</div>
					<div id="am_dashboard_filter_subtitle" class="h6">{{localizations.subHeader}}</div>
				</div>
				<div lio-card-body bodyClass="container-fluid py-2">
					<h2 class="font-weight-bold text-left mx-1 h6">{{localizations.filters}}</h2>
					<lio-query-tool (loaded)="queryToolReady()" 
						[filters]="filters" 
						[controlSettings]="queryToolSettings" 
						[controlObject]="queryTool" 
						(results)="loadQueryResults($event)" 
						(onRun)="requestedDashboard()"
						[queryToolNote]="dashboardSettings.queryToolNote">
						<div class="note">
							<span class="text-danger small">{{localizations.expiredNote}}</span>
						</div>
					</lio-query-tool>
				</div>
			</lio-card>
		</div>
	</section>

</div><!-- Show / Hide Dashboard -->

<!-- TOGGLE STATS -->
<div class="row">
	<div #pdfTarget [hidden]="!showDashboard" class="container-fluid">
		<!-- STATS -->
		<div class="row" [hidden]="!statsLoaded || !showStats || !showDashboard">
			<div class="col-6 col-md-3 mb-2" id="am_stats_usercount_panel">
				<lio-card cardClass="h-100" bodyClass="p-2" [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_usercount_title">{{localizations.totalUsers}}</div>
					</div>
					<div lio-card-body>
						<div class="h4 mb-0" id="am_stats_usercount_fraction">{{stats.totalActive}}/{{stats.totalUsers}}</div>
						<div class="font-weight-bold" id="am_stats_usercount_percent">{{(100*stats.totalActive/stats.totalUsers)| number:'1.0-0'}}%</div>
						<div id="am_stats_usercount_text">{{localizations.activeInactive}}</div>
					</div>
				</lio-card>
			</div>
			<div class="col-6 col-md-3 mb-2" id="am_stats_coursecount_panel">
				<lio-card cardClass="h-100" bodyClass="p-2" [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_coursecount_title">{{localizations.totalCourses}}</div>
					</div>
					<div lio-card-body>
						<div class="h4 mb-0" id="am_stats_coursecount_total">{{stats.totalCourses}}</div>
						<div class="font-weight-bold" id="am_stats_coursecount_percent">{{(100*stats.totalStarted/stats.totalEnrollments) | number:'1.0-0'}}%</div>
						<div class="small" id="am_stats_coursecount_text">{{localizations.started}}</div>
					</div>
				</lio-card>
			</div>
			<div class="col-6 col-md-3 mb-2" id="am_stats_enrollcount_panel">
				<lio-card cardClass="h-100" bodyClass="p-2" [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_enrollcount_title">{{localizations.totalEnrollments}}</div>
					</div>
					<div lio-card-body>
						<div class="h4 mb-0" id="am_stats_enrollcount_total">{{stats.totalEnrollments}}</div>
						<div class="font-weight-bold" id="am_stats_enrollcount_percent">{{stats.percentCompletion}}%</div>
						<div class="small" id="am_stats_enrollcount_text">{{localizations.completed}}</div>
					</div>
				</lio-card>
			</div>
			<div class="col-6 col-md-3 mb-2" id="am_stats_logincount_panel">
				<lio-card cardClass="h-100" bodyClass="p-2" [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_logincount_title">{{localizations.totalLogins}}</div>
					</div>
					<div lio-card-body>
						<div class="h4 mb-0" id="am_stats_logincount_total">{{stats.totalLogins}}</div>
						<div class="font-weight-bold" id="am_stats_logincount_active">{{stats.totalActiveLogins}}</div>
						<div class="small" id="am_stats_logincount_text">{{localizations.totalActiveLogins}}</div>
					</div>
				</lio-card>
			</div>
			<div class="col-12 mb-2" id="am_stats_completion_panel">
				<lio-card cardClass="h-100" bodyClass="p-2" [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_completion_title">{{localizations.completionHistory}}</div>
					</div>

					<div lio-card-body>
						<div class="row">
							<div class="col-3" id="am_stats_completion_thisweek_panel">
								<div class="small" id="am_stats_completion_thisweek_title">{{localizations.thisWeek}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_thisweek_total">{{stats.completedThisWeek}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_lastweek_panel">
								<div class="small" id="am_stats_completion_lastweek_title">{{localizations.lastWeek}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_lastweek_total">{{stats.completedLastWeek}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_thismonth_panel">
								<div class="small" id="am_stats_completion_thismonth_title">{{localizations.thisMonth}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_thismonth_total">{{stats.completedThisMonth}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_lastmonth_panel">
								<div class="small" id="am_stats_completion_lastmonth_title">{{localizations.lastMonth}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_lastmonth_total">{{stats.completedLastMonth}}</div>
							</div>
						</div>
					</div>
				</lio-card>
			</div>
		</div>
		<div class="row" [hidden]="!coursesLoaded || !showStats">
			<div class="col-md-4 col-lg-3 mb-2 print-section" *ngFor="let course of courses; index as i">
				<lio-card bodyClass="p-3" cardClass="h-100" id="{{'am_stats_pie_panel_' + course.courseID}}" [hideErrors]="true">
					<div lio-card-header [style.minHeight.em]="3.5">
						<div class="small mb-0" id="{{'am_stats_pie_title_' + course.courseID}}">{{course.courseID}} - {{course.courseName | limit:50}}</div>
					</div>
					<div lio-card-body>
						<lio-pie-chart id="{{course.courseID}}" [data]="[course.completed, course.total]"></lio-pie-chart>
					</div>
				</lio-card>
			</div>
		</div>
	</div>
</div>
<!-- SEARCH FORM -->	
<div [hidden]="!showDashboard">
	<lio-card headerClass="pb-0" bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
		<div lio-card-header>
			<lio-result-filters
				(update)="onupdate()"
				[searchConfig]="searchConfig"
				[search]="search"
				[searchFields]="searches">
			</lio-result-filters>
		</div>
		<div lio-card-body>
			<div lio-paginator controlID="dashboard" [collection]="employees" [filters]="search" [settings]="pagination" (onResults)="updateFilteredEmployees($event)">
				<lio-results-table
					paginatorID="dashboard"
					[fields]="fields"
					[results]="filteredEmployees"
					[fieldConfig]="fieldConfig"
					[prefixButtons]="prefixButtons">
				</lio-results-table>
			</div>
		</div>
		<div lio-card-footer>
			<lio-paginator-controls class="row" controlID="dashboard"></lio-paginator-controls>
			<div class="btn-toolbar">
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_editquery" (click)="editQuery()">{{localizations.editQuery}}</button>
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_assigncourses" [hidden]="!permissionService.hasPermission('enrollment.enroll')" (click)="assignCourses()" [disabled]="disableAssignCourses || !courses">{{localizations.assignCourses}}</button>
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_exportrecords" [hidden]="!permissionService.hasPermission('reports.export')" [disabled]="!filteredEmployees.length" (click)="exportRecords()">{{localizations.exportRecords}}<i class="ml-1 fa fa-file-excel ml-1" aria-hidden="true"></i></button>
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_exportpdf" [hidden]="!permissionService.hasPermission('dashboard.export')" [disabled]="!filteredEmployees.length" (click)="exportPDF()">{{localizations.exportPDF}}<i class="ml-1 fa fa-file-pdf ml-1" aria-hidden="true"></i></button>
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_emailusers" [hidden]="!permissionService.hasPermission('email.sendToUsers')" (click)="sendEmailToEnrolled()" [disabled]="!courses || !allowSendEmail">{{localizations.emailUsers}}<i class="ml-1 fa fa-envelope ml-1" aria-hidden="true"></i></button>
				<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_emailrecords" [hidden]="!permissionService.hasAllPermissionsIn(['reports.read','email.sendExport'])" [disabled]="!filteredEmployees.length" (click)="emailRecords()">{{localizations.emailRecords}}<i class="ml-1 fa fa-envelope ml-1" aria-hidden="true"></i></button>
			</div>
		</div>
	</lio-card>

	<lio-error></lio-error>
</div>