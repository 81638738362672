import { Component, Optional, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { processingService } from '../../../../services/processing.service';
import { navService } from '../../../../services/nav.service';
import { brandingService } from '../../../../services/branding.service';
import { stateService } from '../../../../services/state.service';
import { breadcrumbService } from '../../../../services/breadcrumbs.service';
import { settingsService } from '../../../../services/settings.service';
import { localizationService } from '../../../../services/localization.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lmsService } from '../../../../services/lms.service';
import { storageService } from '../../../../services/storage.service';
import { debugService } from '../../../../services/debug.service';
import { permissionService } from '../../../../services/permissions.service';
import { accessibilityService } from '../../../../services/accessibility.service';

@Component({
	selector: 'lio-nav-bar',
	styleUrls: ['./navbar.component.css'],
	templateUrl: './navbar.component.html'
})
export class LIONavbar implements OnDestroy {
	public processing:boolean			= false;
	public mouseOnMenu:boolean			= false;
	public mouseOnMenuButton:boolean	= false;

	public localeStrings:any		= {
		editProfile			: 'Edit Profile',
		editProfileTrans	: 'user.editProfile',
		toggleContrast		: 'global.toggleContrast',
		chooseRole			: 'Choose Role',
		chooseRoleTrans		: 'user.chooseRole',
		chooseLang			: 'Choose Language',
		chooseLangTrans		: 'user.chooseLang',
		changePass			: 'Change password',
		changePassTrans		: 'user.changePass',
		documentation		: 'Help',
		documentationTrans	: 'home.documentation',
		logout				: 'Logout',
		logoutTrans			: 'user.logout',
		processing			: 'processing...',
		processingTrans		: 'global.processing',
	};

	public localeKeys:Array<string> = [
		'editProfile',
		'chooseRole',
		'chooseLang',
		'changePass',
		'documentation',
		'logout',
		'processing',
		'toggleContrast'
	];

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		private changeDetectorRef	:ChangeDetectorRef,
		@Optional() @Inject(lioModalService)		private lioModalService		:lioModalService,
		@Optional() @Inject(lmsService)			private lmsService			:lmsService,
		@Optional() @Inject(storageService)			private storageService		:storageService,
		@Optional() @Inject(processingService)		public processingService	:processingService,
		@Optional() @Inject(localizationService)	public localizationService	:localizationService,
		@Optional() @Inject(stateService)			public stateService			:stateService,
		@Optional() @Inject(navService)			public navService			:navService,
		@Optional() @Inject(brandingService)		public brandingService		:brandingService,
		@Optional() @Inject(debugService)			public debugService			:debugService,
		@Optional() @Inject(accessibilityService)	public accessibilityService	:accessibilityService,
		@Optional() @Inject(breadcrumbService)		public breadcrumbService	:breadcrumbService,
		@Optional() @Inject(settingsService)		public settingsService		:settingsService,
		@Optional() @Inject(permissionService)		public permissionService	:permissionService
	) {
		this.debugService.register('navbar', this);
		this.processing = false;

		this.subscriptions.add(
			this.processingService.processor.subscribe((result) => {
				this.processing = result;
				this.changeDetectorRef.detectChanges();
			})
		);

		this.subscriptions.add(
			this.accessibilityService.tabChange.subscribe(() => {
				this.mouseOnMenuButton = false;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}


	onKeyUp(event) {
		if (event.code == 'Enter') {
			this.mouseOnMenuButton = !this.mouseOnMenuButton;	
		}
	}

	profileActive() {
		return this.mouseOnMenu || this.mouseOnMenuButton;
	}

	getActiveRole() {
		return this.stateService.activeRole || {name:'',roleID:''};
	}

	getActiveCompany() {
		return this.stateService.activeCompany || {companyID:''};
	}

	getImpersonationTarget() {
		return this.stateService.impersonationTarget || {employeeID:'',firstName:'',lastName:''};
	}

	endProxy() {
		this.lioModalService.showLoading('processing');
		this.stateService.endProxy();
	}

	isHighContrast() {
		return this.storageService.get('contrast') == 'high-contrast';
	}

	toggleContrast() {
		let contrast = '';
		if (!this.isHighContrast()) {
			contrast = 'high-contrast';
		}
		this.navService.setContrast(contrast);
	}

	/*
	* Shows the choose role modal
	*/
	chooseRole() {
		this.lioModalService.open({
			'type':'choose-role',
			'options': this.stateService.allowedRoles,
			'selection': this.stateService.activeRole
		}).then((role) => {
			if (role) {
				this.switchRole(role);
			}
		});
	}

	/*
	* Shows the choose company modal
	*/
	showCompany = () => {
		this.lioModalService.open({
			'type':'choose-company',
			'options': this.stateService.getAllowedCompanies(),
			'selection': this.stateService.activeCompany
		}).then((company) => {
			if (company) {
				this.switchCompany(company);
			}
		});
	}

	/**
	 * Switches the current role
	 */
	switchRole(role) {
		this.stateService.changeState('role', role.roleID).then((success) => {
			if(!success) {
				this.lioModalService.show('Error: Permission Denied.');
			}
		});
	}

	/** Switches the current company
	 */
	switchCompany(company) {
		this.stateService.changeState('company', company.companyID).then((success) => {
			if(!success) {
				this.lioModalService.show('Error: Permission Denied.');
			}
		});
	}

	/*
	* Shows the impersonation modal
	*/
	showImpersonate() {
		this.lioModalService.open({
			'type':'impersonate',
			'options': this.stateService.impersonateOptions,
		}).then((impersonation) => {
			if (impersonation) {
				this.impersonateAllowed(impersonation.employeeID);
			}
		});
	}

	/**
	 * Start impersonation
	 */
	impersonateAllowed(target) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('impersonation/impersonateAllowed', {'targetEmployeeID' : target}).then((result) => {
			if (result.success) {
				this.stateService.loggedOff = false;
				this.stateService.impersonationTarget = result.properties.impersonationTarget;
				this.stateService.loggedOff = false;
				this.storageService.set('state', result.properties);
				this.stateService.set(result.properties);	
				this.navService.goto(this.navService.getHomePage());
			} else if (result.errors) {
				this.lioModalService.showError(result.errors[0]);
			}
		});
	}

	/**
	 * Return to home company
	 */
	switchToHomeCompany() {
		this.lmsService.post('permissions/resetCompany').then((result) => {
			if (result.success) {
				this.storageService.reset();
				this.navService.reload();
			} else {
				this.lioModalService.show('Error: Permission Denied.');
			}
		});
	}

	/**
	 * Return to default role
	 */
	switchToDefaultRole() {
		this.lmsService.post('permissions/resetRole').then((result) => {
			if (result.success) {
				this.storageService.reset();
				this.navService.reload();
			} else {
				this.lioModalService.show('Error: Permission Denied.');
			}
		});
	}

	/*
	* Logout
	*/
	logout() {
		this.navService.lockLocation = null;
		this.navService.goto('logout');
	}

	/*
	* Is this the last crumb?
	* @return {boolean}
	*/
	isLast(index) {
		return this.breadcrumbService.isLast(index);
	}

	/*
	* is the user locked from navigation
	* @return {boolean}
	*/
	isLocked() {
		return this.navService.locked || this.navService.disableNav;
	}


	/*
	* Go home if not locked
	*/
	goHome() {
		if (!this.isLocked()) {
			this.navService.goHome();
		}
	}


	/*
		* Choose language modal
	*/
	chooseLang() {
		this.lioModalService.open({'type':'choose-language'}).then((lang) => {
			if (lang) {
				this.lmsService.post('employee/setLang', {'langID': lang.code}).then((result) => {
					if (result.success) {
						setTimeout(() => {
							this.navService.reload();
						}, 3000);
						this.lioModalService.show('savedRefresh');
					}
				});
			}
		});
	}

	/*
	* Edits the profile
	*/
	editProfile() {
		this.storageService.set('employeeToEdit', this.stateService.getSession());
		this.storageService.set('task', 'personal');
		this.navService.goto('selfprofile');
	}
}