<div class="list-group-item">
	<div class="row no-gutters">
		<lio-searchable-select class="ml-auto col-6 col-md-8"
			[field]="roleField" 
			[model]="targetRole"
			(onUpdate)="getRole()">
		</lio-searchable-select>
		<div class="mr-auto d-flex mb-4">
			<button class="mb-2 ml-1" mat-flat-button color="primary" *ngIf="!copiedRole" (click)="copyRole()">Copy Role</button>
			<button class="mb-2 ml-1" mat-flat-button color="primary" *ngIf="copiedRole" (click)="pasteRole()">Paste {{copiedRole.name}}</button>
			<button class="mb-2 ml-1" mat-stroked-button color="primary">
				<i *ngIf="originalRole" class="fa fa-sync-alt" (click)="resetRole()"></i>
			</button>
		</div>
	</div>
</div>
<div class="list-group-item">
	<lio-form-inputs
		[fields]="fields"
		[model]="editRole">
	</lio-form-inputs>
</div>

<div class="list-group-item text-center">
	<mat-expansion-panel class="no-pad" [disabled]="!editRole.authorities">
		<mat-expansion-panel-header>
			<mat-panel-title>Authorities</mat-panel-title>
		</mat-expansion-panel-header>
		<table class="table table-striped" id="authoritiesSection">
			<thead>
				<tr class="container">
					<td class="col-md-4">Role ID</td>
					<td class="col-md-4">Role Name</td>
					<td class="col-sm-4">Authorities<span>
					</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr class="container" *ngFor="let authority of editRole.authorities">
					<td class="col-md-4">
						<span><b>{{authority.roleID}}</b></span>
					</td>
					<td class="col-md-4">
						<span><b>{{authority.name}}</b></span>
					</td>
					<td class="col-md-4">
						<lio-searchable-select [amID]="'am_form_'+authority.roleID" 
							[field]="authorityField" 
							[model]="authority"
							[multiple]="true"
							>
						</lio-searchable-select>
					</td>
				</tr>
			</tbody>
		</table>
	</mat-expansion-panel>
</div>


<div class="list-group-item text-center">
	<h5>Permissions</h5>
	<div>
		<button mat-flat-button color="primary" class="ml-1" (click)="assignAllPermissions()">Assign All</button>
		<button mat-flat-button color="primary" class="ml-1" (click)="unAssignAllPermissions()">Assign None</button>
		<button mat-flat-button color="primary" class="ml-1" (click)="toggleAllSectionCollapse()">Expand / Collapse All</button>
	</div>
</div>
<div class="list-group-item">
	<mat-form-field appearance="outline" class="w-100">
		<mat-label>Search Permissions</mat-label>
		<input matInput [(ngModel)]="query"/>
	</mat-form-field>
	<mat-accordion #permissionSections="matAccordion" multi>
		<mat-expansion-panel class="no-pad" *ngFor="let section of searchPermissions(inProgressPermissions, query) | keyvalue">
			<mat-expansion-panel-header class="bg-primary border-flat-bottom">
				<mat-panel-title>
					<span class="font-weight-bold text-white">{{section.key}}</span>
				</mat-panel-title>
			</mat-expansion-panel-header>				
			<table class="table table-striped m-0">
				<thead>
					<tr>
						<td>Permission Name</td>
						<td>Description</td>
						<td>Default </td>
						<td class="text-right">
							<mat-checkbox
								(change)="checkAll(section.value)" 
								[checked]="checkedAll(section.value)">
								Allowed
							</mat-checkbox>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr class="container" *ngFor="let permission of $any(section.value) | keyvalue">
						<td>
							<span><b>{{permission.key}}</b></span>
						</td>
						<td>
							<span><b>{{$any(permission.value).description}}</b></span>
						</td>
						<td>
							<mat-checkbox [checked]="getDefaultPermission(section.key + '.' + permission.key)" [disabled]="true">Default</mat-checkbox>
						</td>
						<td class="text-right">
							<mat-checkbox [(ngModel)]="$any(permission.value).settings.allowed"
								[disabled]="!$any(permission.value).self || !$any(permission.value).self.allowed">
								Allowed
							</mat-checkbox>
						</td>
					</tr>
				</tbody>
			</table>
		</mat-expansion-panel>
	</mat-accordion>
</div>
<div class="list-group-item">
	<div class="form-group">
		<lio-file-uploader [matchBootstrap]="false" [settings]="uploadSettings" (success)="fileAdded($event)"></lio-file-uploader>
		<button mat-flat-button color="primary" class="ml-1" id="am_permissions_process" (click)="importPermissionsProcess()">Process Permissions</button>
		<button mat-flat-button color="primary" class="ml-1" id="am_permissions_export" [hidden]="targetRole.roleID < 0" (click)="exportPermissions()">Export Permissions</button>
		<button mat-flat-button color="primary" class="ml-1" id="am_permissions_modify" [hidden]="targetRole.roleID < 0" (click)="modifyRole()">Modify Role</button>
	</div>
</div>