<section class="row" localize [model]="localeStrings" [items]="localeStringsKeys" [macros]='localeStringMacros'>
	<div class="col-md-10 offset-md-1">
		<!-- Email Form -->
		<lio-card 
			cardId="emailtemplates"
			[headerText]="employeeIDs.length && !managing ? localeStrings.sendToEmployees : !managing ? localeStrings.allEmailsSent : localeStrings.manage" 
			[showClose]="true" 
			[hideErrors]="true">

			<div lio-card-body>
				<lio-email-editor [hidden]="!showTemplate" 
					[preview]="emailEditorPreview"
					[showCourses]="true" 
					[settings]="emailSettings" 
					(onOptionsLoaded)="templatesLoaded($event)"
					(updateTemplate)="updateTemplate($event)"
					[template]="model.template">
				</lio-email-editor>
				<form *ngIf="!showTemplate" role="form" autocomplete="off" novalidate>
					<div class="row">
						<div class="col-md-8 mx-auto">
							<lio-searchable-select
								[model]="model"
								[options]="templates"
								[field]="emailTemplateField"
								appearance="outline"
								(change)="onTemplateLoad()">
							</lio-searchable-select>
						</div>
					</div>
				</form>
			</div>
			<div lio-card-footer>
				<div class="btn-toolbar">
					<span *ngIf="!showTemplate" class="mr-1">	
						<button id="am_view_template" 
							mat-flat-button
							color="primary"
							[disabled]="!model.template.id" 
							(click)="previewTemplate()" 
							class="mr-1 mb-1" 
							type="submit">
							
							<strong>{{localeStrings.viewtemplate}}</strong>
						</button>
						<button id="am_edit_template" 
							mat-flat-button
							color="primary"
							[disabled]="!model.template.id"  
							(click)="toggleShowTemplate()" 
							class="mr-1 mb-1" 
							type="submit">
							
							<strong>{{localeStrings.edittemplate}}</strong>
						</button>
					</span>
					<span>
						<button id="am_preview_email" 
							mat-flat-button
							color="primary"
							[disabled]="!model.template.id" 
							*ngIf="allowPreview" 
							(click)="preview()" 
							class="mr-1 mb-1" 
							type="submit">
							
							<strong>{{localeStrings.preview}}</strong>
						</button>
						<button id="am_send_test_email"
							mat-flat-button
							color="primary" 
							[disabled]="!model.template.id" 
							*ngIf="allowTest" 
							(click)="sendTest()" 
							class="mr-1 mb-1" 
							type="submit">
							
							<strong>{{localeStrings.sendTest}}</strong>
						</button>
						<button id="am_send_email" 
							mat-flat-button
							color="primary"
							[disabled]="!model.template.id" 
							*ngIf="allowSend" 
							(click)="userSendEmail()" 
							class="mr-1 mb-1" 
							type="submit">
							
							<strong>{{localeStrings.sendAll}}</strong>
						</button>
					</span>
				</div>
			</div>
		</lio-card>
	</div>
</section>
<section class="row" [hidden]="!filteredEmployees.length || managing">
	<div class="col-md-10 offset-md-1">
		<lio-card bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
			<div lio-card-body 
				lio-paginator 
				controlID="emailtemplates" 
				[collection]="employees" 
				[settings]="emailSettings.pagination" 
				(onResults)="updateFilteredEmployees($event)">

				<lio-results-table
					paginatorID="emailtemplates"
					[fields]="fields"
					[results]="filteredEmployees"
					[fieldConfig]="fieldConfig"
					[prefixButtons]="emailPrefixButtons">
				</lio-results-table>
			</div>
			<div lio-card-footer>
				<lio-paginator-controls class="row" controlID="emailtemplates"></lio-paginator-controls>
			</div>
		</lio-card>
	</div>
</section>
<section class="row">
	<div class="col-md-10 offset-md-1 unset">
		<lio-error></lio-error>
	</div>
</section>