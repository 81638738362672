import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { settingsService } from '../../services/settings.service';
import { permissionService } from '../../services/permissions.service';
import { formValidatorService } from '../../services/form-validator.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { workerService } from '../../services/worker.service';
import { stateService } from '../../services/state.service';
import { passwordResetService } from '../../services/password-reset.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { loginService } from '../../services/login.service';
import { debugService } from '../../services/debug.service';

import { userSummarySettings } from './user-summary.settings';

@Component({
	selector: 'lio-user-summary',
	templateUrl: './user-summary.component.html'
})
export class UserSummaryComponent implements OnDestroy {

	public editable					:boolean 		= false;

	public loginHistory				:Array<any>		= [];
	
	public ccfFields				:Array<any>		= [];
	public fields					:Array<any>		= this.utilService.copy(this.userSummarySettings.fields);
	public loginFields				:Array<any>		= this.utilService.copy(this.userSummarySettings.loginFields);
	public employeeFieldConfig		:any 			= this.utilService.copy(this.userSummarySettings.employeeFieldConfig);
	public loginFieldConfig			:any 			= this.utilService.copy(this.userSummarySettings.loginFieldConfig);
	public ccfFieldConfig			:any			= this.utilService.copy(this.userSummarySettings.ccfFieldConfig);

	public currentLog				:any 			= {};

	public allowResetPassword		:boolean 		= true;

	public enrollmentFields			:Array<any>		= this.utilService.copy(this.userSummarySettings.enrollmentFields);
	public loginHistoryFields		:Array<any>		= this.utilService.copy(this.userSummarySettings.loginHistoryFields);
	public courseHistoryFields		:Array<any>		= this.utilService.copy(this.userSummarySettings.courseHistoryFields);

	public enrollmentPagination		:any 			= this.utilService.copy(this.userSummarySettings.enrollmentPagination);
	public loginHistoryPagination	:any 			= this.utilService.copy(this.userSummarySettings.loginHistoryPagination);
	public courseHistoryPagination	:any 			= this.utilService.copy(this.userSummarySettings.courseHistoryPagination);

	public employee					:any 			= {};

	public filteredEnrollments		:Array<any>		= [];

	public filteredLoginHistory		:Array<any>		= [];
	public filteredCourseHistory	:Array<any>		= [];
	public enrollments				:Array<any>		= [];
	public courseHistory			:Array<any>		= [];

	private editingSelf				:boolean 		= false;
	private originalEmployee		:any 			= {};
	private originalCCF				:any 			= null;
	private originalSettings		:any 			= null;
	private UID						:string 		= '';
	private error					:any 			= null;
	
	private subscriptions			:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(settingsService) 		public settingsService		:settingsService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(formValidatorService) 	public formValidatorService	:formValidatorService,
		@Inject(lmsService) 			public lmsService			:lmsService,
		@Inject(lioModalService) 		public lioModalService		:lioModalService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(workerService) 			public workerService		:workerService,
		@Inject(stateService) 			public stateService			:stateService,
		@Inject(passwordResetService) 	public passwordResetService	:passwordResetService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(feedbackService) 		public feedbackService		:feedbackService,
		@Inject(loginService) 			public loginService			:loginService,
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(userSummarySettings) 	public userSummarySettings	:userSummarySettings,
	)
	{
		this.debugService.register('usersummary', this);
		this.navService.setActivePage('usersummary');
		/*
		* Resets the CCF
		*/
		this.subscriptions.add(
			this.navService.exit.subscribe(() => {
				this.resetCCF();
			})
		);
		
		/*
		* Init
		*/
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				if (!this.getEmployee()) {
					this.navService.goto('home');
					return;
				}
				this.getUserSummary(() => {
					this.getSettings();
					this.resetCCF();
				});
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initializes
    */
	initForm() {
		this.originalEmployee = this.utilService.copy(this.employee);
		this.navService.displayPage();
	}

	/*
	* Go to profile page for employee
	*/
	tryGotoProfile() {
		let company1 = this.stateService.getActiveCompanyID();
		let company2 = this.employee.companyID;

		let allowedCompanies = this.stateService.getAllowedCompanies().map((company) => {
			return company.companyID;
		});

		if (company1 == company2) {
			this.gotoProfile();
		} else if(!this.permissionService.hasPermission('self.changeState') || !allowedCompanies.includes(company2)) {
			this.lioModalService.show('usersummary.companyNotAllowed', null, [
				{key : 'company1', value : company1},
				{key : 'company2', value : company2},
			]);
		} else {
			this.lioModalService.confirm('usersummary.companyChangeRequired', null, [
				{key : 'company1', value : company1},
				{key : 'company2', value : company2},
			]).then((confirm) => {
				if (confirm) {
					this.stateService.changeState('changeActiveCompany', company2, true).then((success) => {
						if (success) {
							this.storageService.reset();
							this.storageService.set('employeeToEdit', this.employee);
							this.storageService.set('task', 'external');
							this.storageService.set('redirect', 'profile');
							this.navService.reload();
						}else{
							this.lioModalService.show('Error: Permission Denied.');
						}
					});
				}
			});
		}
	}

	gotoProfile() {
		this.storageService.set('employeeToEdit', this.employee);
		this.storageService.set('task', 'external');
		this.navService.goto('profile');
	}

	updateEnrollments(collection) {
		this.filteredEnrollments = collection.filtered;
	}

	updateLoginHistory(collection) {
		this.filteredLoginHistory = collection.filtered;
	}

	updateCourseHistory(collection) {
		this.filteredCourseHistory = collection.filtered;
	}

	/*
	 * Gets the users CCF fields
    */
	getExternalCCF() {
		this.originalCCF = this.utilService.copy(this.storageService.get('ccfs'));
		this.lmsService.post('userSummary/getExternalCCF', {'externalCompanyID': this.employee.companyID}).then((result) => {
			let ccf = result.properties.ccf;
			
			this.storageService.set('ccfs', ccf);
			this.initForm();
		});
	}

	getSettings() {
		this.originalSettings = this.utilService.copy(this.settingsService.settings); 
		this.lmsService.post('settings/getExternalSettings', {'externalCompanyID': this.employee.companyID}).then(() => {
			this.getExternalCCF();
		});
	}

	/*
	 * ReSets the ccf
    */
	resetCCF() {
		if (this.originalCCF) {
			this.storageService.set('ccfs', this.originalCCF);
			this.settingsService.initSettings(this.originalSettings);
			this.settingsService.apply();
		}
	}

	/*
	 * Sets the fields
    */
	setFields() {
		this.employeeFieldConfig.langID = this.employee.langID;
		this.loginFields = this.permissionService.setFields(this.loginFields);

		this.ccfFields.forEach((field) => {
			field.locked = true;
		});

		if (this.employee.allowSpiderSSO == 1) {
			this.allowResetPassword = false;
		}
		
		this.updateFieldsBasedOnUser();
		this.setLastLogin();
	}

	/*
	 * Updates the fields based on self editing
    */
	updateFieldsBasedOnUser() {
		var employee = this.employee,
			currentUser = this.stateService.getSession();

		if (employee.AdminID === '99999999') {
			employee.AdminID = "Auto Process";
		}

		employee.company = employee.coName + ' (' + employee.companyID + ')';

		if (employee.employeeID === currentUser.employeeID &&
			currentUser.companyID === employee.companyID) {
			this.editingSelf = true;
		}

		// If Editing Self, modify fields
		if (this.editingSelf) {
			var fields = ['employeeID', 'inactive', 'permissionID'];
			this.fields.forEach((field) => {
				if (this.utilService.valueinArray(fields, field.model)) {
					field.editable = false;
				}
			});
		}
	}

	/*
	 * Gets the employee from storage
    */
	getEmployee() {
		this.UID = this.storageService.get('UID');
		return this.UID ? true : false;
	}

	/*
	 * Gets the user summary
    */
	getUserSummary(callback) {
		this.lmsService.post('userSummary/getSummary', {'UID': this.UID}).then((result) => {
			var employee = result.properties.employee,
				courseHistory = result.properties.courseHistory,
				enrollments = result.properties.enrollments,
				loginHistory = result.properties.loginHistory,
				role = this.permissionService.getRoleNameFromID(employee.permissionID),
				status = employee.inactive == 1 ? 'Inactive' : 'Active';

			employee.role = role;
			employee.status = status;
			this.employee = employee;

			if(enrollments){
				this.enrollments = enrollments;
			}
			if(courseHistory){
				this.courseHistory = courseHistory;
			}
			if(loginHistory){
				this.loginHistory = loginHistory;
			}
			callback();
		});
	}

	/*
	 * Set the last login
    */
	setLastLogin() {
		if (this.loginHistory && this.loginHistory.length) {
			this.employee.lastLoginSuccess = this.loginHistory[0].loginDate;
		}
	}

	/*
	 * Show course enrollments
    */
	enableCourseEnrollments() {
		this.currentLog.fields = this.utilService.copy(this.userSummarySettings.enrollmentFields);
		this.currentLog.class = 'enrollments';
		this.currentLog.logs = this.enrollments;
		this.currentLog.onupdate = this.onupdate;
	}

	/*
	 * Show userLoginDates
    */
	enableLoginHistory() {
		this.currentLog.fields = this.utilService.copy(this.userSummarySettings.loginHistoryFields);
		this.currentLog.class = 'loginHistory';
		this.currentLog.logs = this.loginHistory;
		this.currentLog.onupdate = this.onupdate;
	}

	/*
	 * Show userCourseDates
    */
	enableCourseHistory() {
		this.currentLog.fields = this.utilService.copy(this.userSummarySettings.courseHistoryFields);
		this.currentLog.class = 'courseHistory';
		this.currentLog.logs = this.courseHistory;
		this.currentLog.onupdate = this.onupdate;
	}

	/*
	 * Exports the records
	*/
	exportRecords() {
		var logs = this.currentLog.logs,
			fields = this.currentLog.fields,
			name = this.currentLog.class;
	
		if (!this.workerService.export(logs, fields, name)) {
			this.lioModalService.show('Failed to export ' + name);
		}
	}

	/*
	 * Checks if the form is valid
    */
	isValid() {
		var isValid = true;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (!this.formValidatorService.isValid(this.employee, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}


		return isValid;
	}

	/*
	 * On update
    */
	onupdate() {
		this.formValidatorService.resetFields();
	}

	/*
	 * Gets the employee properties defined in the fields
    */
	getEmployeeForm() {
		let employee 	= {
			originalEmployeeID	:null, 
			companyID			:null
		};
		let	fields 		= this.fields;

		fields = fields.concat(this.ccfFields);
		fields = fields.concat(this.loginFields);

		fields.forEach((field) => {
			var value = this.employee[field.model];
			if (!value && (field.type == 'text' || field.type == 'default')) {
				value = '';
			}
			if (!field.locked) {
				employee[field.model] = value;
			}
		});

		employee.originalEmployeeID = this.originalEmployee.employeeID;
		employee.companyID = this.employee.companyID;
		return employee;
	}

	/*
	 * Cancels the edit
    */
    cancel() {
		this.editable = false;
		this.employee = this.utilService.copy(this.originalEmployee);
    }

	/*
	 * Resets the password
    */
    resetPassword() {
		if (!this.allowResetPassword) {
			return;
		}

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.lioModalService.showLoading('processing');

		this.passwordResetService.resetRequest(this.employee, true).then((result) => {
			this.lioModalService.hideLoading();
			if (result) {
				this.lioModalService.show('emailToUserToResetPass');
			}
		});
	}

	/*
	 * Submits the edit
    */
    submit() {
		let employee 	= this.getEmployeeForm();
		let form 		= {'employee': employee};

		if (this.error) {
			return;
		}

		if (!this.isValid()) {
			return;
		}

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lioModalService.showLoading('processing');

		this.lmsService.post('userSummary/editProfile', form).then((result) => {
			this.lioModalService.hideLoading();
			this.navService.changedForm = false;
			this.originalEmployee = this.utilService.copy(this.employee);

			if (result.success) {
				this.loginService.updateCCFValues();
				this.feedbackService.setMessage('profileSuccessfullyUpdated');
			}
		});
		this.editable = !this.editable;
	}

	/*
	 * On Click of the assign Courses button
	*/
	userSummaryAssignCourses() {
		var employees = [this.employee];
		this.storageService.setEmployeesToAssign(employees);
		this.navService.goto('assign');
	}

	/*
	 * View an employee's enrollments 
	*/
	viewEnrollments(employee) {
		this.editable = false;
		this.storageService.set('employee', employee);
		this.navService.goto('enrollments');
	}
}