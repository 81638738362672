import { Component, Optional, Inject, OnDestroy, Input, OnInit } from '@angular/core';

import { NEVER, Subscription, Subject } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { eventService } from '../../../../services/event.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { navService } from '../../../../services/nav.service';
import { utilService } from '../../../../services/util.service';
import { workerService } from '../../../../services/worker.service';

@Component({
	selector: 'lio-enrollment-editor',
	templateUrl: './enrollment-editor.component.html'
})
export class LioEnrollmentEditor implements OnDestroy, OnInit{
	private _eventService		:eventService = null;
	public get eventService()	:eventService{
		return this._eventService;
	}
	@Input() public set eventService(val:eventService){
		this._eventService = val;

		this.eventService.setSettings(this.settings);
			this.subscriptions.add(
				this.eventService.eventsSubscription.subscribe((events) => {
					this.events = events;
					this.navService.displayPage();
				})
			);
		this.eventService.getEvents();
	}

	private _settings		:any = {};
	public get settings()	:any{
		return this._settings;
	}
	@Input() public set settings(val:any){
		this._settings 			= val;
		this.filters 			= this.utilService.copy(val.filters);
		this.queryToolSettings 	= this.utilService.copy(val.queryToolSettings);
	}

	public filters				:Array<any>		= [];
	public queryToolSettings	:any			= null;
	public results				:Array<any>		= [];
	private events				:Array<any>		= [];
	public filteredResults		:Array<any>		= [];
	public exportingRecords		:boolean		= false;

	private subscriptions		:Subscription 	= NEVER.subscribe();

	constructor(
		@Optional() @Inject(debugService)		public debugService		:debugService,
		@Optional() @Inject(feedbackService)	public feedbackService	:feedbackService,
		@Optional() @Inject(lioModalService)	public lioModalService	:lioModalService,
		@Optional() @Inject(navService)			public navService		:navService,
		@Optional() @Inject(utilService)		public utilService		:utilService,
		@Optional() @Inject(workerService)		public workerService	:workerService
	){
		this.debugService.register('enrollmentEditor', this);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	ngOnInit(){
		if (!this.eventService) {
			this.navService.displayPage();
		}
	}

	/*
	* Pagination update
	*/
	updateFilteredResults(results) {
		this.filteredResults = results.filtered;
	}

	/**
	 * On query saving
	 * @param {string} action
	 * @param {array} query
	 * @return {Promise}
	 */
	confirm(data:{type:string, model:any, confirmed:Subject<boolean>}) {
		data.model = this.bindQueryToEvent(data.model);
			
		switch (data.type) {
			case 'save':
				if (data.model.hasActiveEvent) {
					this.lioModalService.confirm('activeFiltersWarning').then((confirmed) => {
						data.confirmed.next(confirmed);
						data.confirmed.complete();
					});
				}else{
					data.confirmed.next(true);
					data.confirmed.complete();
				}
				break;
			case 'delete':
				if (data.model.hasEvent) {
					this.lioModalService.open({
						'type': 'table', 
						'title': 'eventDeniedFilterDeletion', 
						'fields': this.settings.tableFields, 
						'models': data.model.events,
					});

					data.confirmed.next(false);
					data.confirmed.complete();
				} else {
					this.lioModalService.confirm('areYouSureDeleteName').then((confirmed) => {
						data.confirmed.next(confirmed);
						data.confirmed.complete();
					});
				}
				break;
			default:
				console.error('Confirmation type not determined', data.type);
				break;
		}
	}

	/**
	 * Binds the queries to an event to know if it has an (active) event
	 * @param {array} query
	 * @return {array} modified query
	 */
	bindQueryToEvent(query) {
		query.hasActiveEvent = false;
		query.hasEvent = false;
		query.events = [];
		this.events.forEach((event) => {
			if (event.queryID === query.id) {
				query.events.push(event);
				query.hasEvent = true;
				if (event.active) {
					query.hasActiveEvent = true;
				}	
			}
		});
		return query;
	}

	/*
	* ON query resuls
	*/
	handleQueryResults(results) {
		this.lioModalService.hideLoading();
	
		if (!results) {
			this.results = [];	
			return;	
		}
		this.results = results.properties.employees;
	}

	/*
	* Exports the records
	*/
	exportRecords() {
		if (this.exportingRecords) {
			return;
		}

		let results = this.results,
			fields = this.settings.fields;

			this.lioModalService.showLoading('processing');
		
		this.workerService.export(results, fields, 'employees').then((result) => {
			this.lioModalService.hideLoading();
			if (!result) {
				this.feedbackService.setError('failedToExportRecords');
			}
			this.exportingRecords = false;
		});

		this.exportingRecords = true;
	}
}