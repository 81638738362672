import { Injectable } from '@angular/core';
import { LioFieldArray, LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: roleSettings
})
export class roleSettings {
	newRole:any = {
		roleID 		: -1, 
		name 		: 'New Role', 
		ipWhiteList : false, 
		homePage 	: 'home', 
		permissions : []
	};

	pagination:any = {
		'currentPage'   : 0,
		'offset'        : 0,
		'pageLimit'     : '10',
		'pageLimits'    : ['10', '50', '100']
	};

	authorities:Array<any> = [
		{'name': 'NONE', 'value': 'NONE'},
		{'name': 'VIEW', 'value': 'VIEW'},
		{'name': 'EDIT', 'value': 'EDIT'}, 
		{'name': 'CREATE', 'value': 'CREATE'}, 
		{'name': 'ENROLL', 'value': 'ENROLL'}, 
	];

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Role ID',
			'model': 'roleID',
			'type': 'text',
			'locked': true,
		},
		{
			'name': 'Role Name',
			'model': 'name',
			'type': 'text',
			'required': true,
			'min': 2,
			'max': 200,
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'checkForHTMl': true,
		},
		{
			'name': 'Ip Whitelisting',
			'model': 'ipWhiteList',
			'type': 'checkbox',
			'required': true,
		},
		new LioSearchableSelectField({
			'name': 'Home Page',
			'model': 'homePage',
			'type': 'select',
			'required': true,
			'options' : [
				{name : 'Add Users'			, value : 'addusers'},
				{name : 'Admin Tools'		, value : 'adminTools'},
				{name : 'Assign'			, value : 'assign'},
				{name : 'Catalog Search'	, value : 'catalogsearch'},
				{name : 'Branding'			, value : 'branding'},
				{name : 'Dashboard'			, value : 'dashboard'},
				{name : 'Email'				, value : 'email'},
				{name : 'Employee Search'	, value : 'employeesearch'},
				{name : 'Enrollments'		, value : 'enrollments'},
				{name : 'Enrollment Query'	, value : 'enrollquery'},
				{name : 'Global Search'		, value : 'globalsearch'},
				{name : 'Home'				, value : 'home', selected: true},
				{name : 'Localization'		, value : 'localization'},
				{name : 'Profile'			, value : 'profile'},
				{name : 'Reports'			, value : 'reports'},
				{name : 'Training'			, value : 'training'},
				{name : 'Upload Course'		, value : 'courseuploader'},
				
				/*
				'login',
				'logout',
				'register',
				'forgotpass',
				'changepass',
				'usersummary',
				'loggedoff',
				'405',
				'500',
				'localizationadmin',
				'learniosettings',
				'testing',
				'errorlog',
				'accesslog',
				'auditlog',
				'viewReport',
				'catalogPDF',
				'catalogadmin',
				'courselaunch',
				'editPermissions',
				'scheduleEmails',
				'delegateAccess',
				*/
			]
		}),
	);
}