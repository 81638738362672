<div *ngIf="formGroup" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'" [formGroup]="formGroup">
	<mat-form-field *ngIf="matFormField"
		[appearance]="appearance" 
		class="pt-0 w-100" 
		[ngClass]="formFieldClass"
		localize [model]="field" item="name">

		<!-- LABEL -->
		<mat-label *ngIf="showLabel" id="am_form_label_{{field.model}}">
			{{field.name}}
		</mat-label>

		<!-- Default -->
		<!-- Text -->
		<!-- Number -->
		<!-- Password -->
		<!-- Email -->
		<input *ngIf="fieldType == 'standard'"
			id="am_form_{{field.model}}"
			matInput 
			[name]="field.model"
			[formControl]="formControl"
			[(ngModel)]="model[field.model]"
			[type]="inputType" 
			placeholder="{{field.placeholder || field.name}}"
			[ngClass]="{'field-error' : field.error == true}" 
			(change)="onInputUpdate(field)"
			(keyup)="onKeyUp($event, field)"
			[required]="field.required" 
			[min]="field.min"
			[max]="field.max"
			maxlength="{{field.max || 100}}" 
			title="{{field.name}}"/>

		<!-- Textarea -->
		<textarea *ngIf="fieldType == 'textarea'"
			id="am_form_{{field.model}}" 
			maxlength="{{field.max}}" 
			matInput 
			[name]="field.model"
			[formControl]="formControl"
			[(ngModel)]="model[field.model]" 
			[placeholder]="field.placeholder || field.name" 
			[ngClass]="{'field-error' : field.error == true}"
			(change)="onInputUpdate(field)" 
			style="height: 220px; max-width: 100%" 
			[required]="field.required">
		</textarea>

		<mat-chip-list *ngIf="fieldType == 'chips'" 
			[(ngModel)]="model[field.model]" 
			[formControl]="formControl"
			[required]="field.required" 
			(change)="onInputUpdate(field)"
			localize
			[model]="localeStrings"
			[items]="localeStringsKeys"
			[macros]="localeStringsMacros">

			<mat-chip *ngFor="let option of model[field.model] | slice:0:field.max | asArray"
				removable
				(removed)="removeChip(option);onInputUpdate(field);">
				{{option}}
				<mat-icon matChipRemove>cancel</mat-icon>
			</mat-chip>
			<mat-chip *ngIf="model[field.model] && model[field.model].length > field.max" [matTooltip]="model[field.model].join(', ')">
				<mat-icon>add</mat-icon>
				<span>{{localeStrings.moreChips}}</span>
			</mat-chip>
		</mat-chip-list>

		<mat-hint *ngIf="showHint" 
			localize [model]="field" item="hint">
			{{field.hint}}
		</mat-hint>

		<div class="d-block mt-2 field-error-txt">
			{{field.errorString}}
		</div>
	</mat-form-field>

</div>

<!-- Boolean -->
<span *ngIf="fieldType == 'boolean'" [formGroup]="formGroup">
	<mat-slide-toggle
		id="am_form_{{field.model}}"
		color="primary"
		[(ngModel)]="model[field.model]"
		[name]="field.model"
		[formControl]="formControl"
		(change)="onInputUpdate(field)">
		{{field.name}}
	</mat-slide-toggle>
</span>

<!-- Select Drop Down -->
<lio-searchable-select *ngIf="fieldType == 'select' && selectField.multiple"
	[model]="model"
	[name]="field.model"
	[formGroup]="formGroup"
	[formControlRef]="formControl"
	[field]="selectField"
	[searchable]="searchable"
	[multiple]="true"
	(searchedFor)="onSelectSearch.emit($event)"
	(onUpdate)="onInputUpdate(field)"
	[appearance]="appearance">
</lio-searchable-select>

<!-- Select Drop Down - identical to above since matselect cant dynamically change multiple property -->
<lio-searchable-select *ngIf="fieldType == 'select' && !selectField.multiple"
	[model]="model"
	[name]="field.model"
	[formGroup]="formGroup"
	[formControlRef]="formControl"
	[field]="selectField"
	[searchable]="searchable"
	[multiple]="false"
	(searchedFor)="onSelectSearch.emit($event)"
	(onUpdate)="onInputUpdate(field)"
	[appearance]="appearance">
</lio-searchable-select>

<!-- Checkbox -->
<mat-checkbox *ngIf="fieldType == 'checkbox'"
	id="am_form_{{field.model}}"
	[(ngModel)]="model[field.model]"
	[name]="field.model"
	[formGroup]="formGroup"
	[formControl]="formControl"
	[ngClass]="{'field-error' : field.error == true, 'checkbox' : fieldType == 'checkbox'}" 
	(change)="onInputUpdate(field)"
	[required]="field.required"
	title="{{field.name}}">
	{{field.name}}
</mat-checkbox>

<mat-card *ngIf="fieldType == 'range'" class="mb-1" localize [model]="field" item="name" [formGroup]="formGroup">
	<mat-card-title class="text-secondary mb-3">{{field.name}}</mat-card-title>
	<mat-card-content>
		<!-- Drop down of a range of numbers -->
		<mat-slider id="am_form.{{field.model}}" 
			color="primary"
			class="w-100"
			[(ngModel)]="model[field.model]" 
			[formControl]="formControl"
			(change)="onInputUpdate(field)" 
			thumbLabel
			[min]="field.min" 
			[max]="field.max" 
			step="1">
		</mat-slider>
	</mat-card-content>
</mat-card>

<!-- Date -->
<div *ngIf="fieldType == 'date'">
	<lio-mat-wrap-date-field
		amID="am_form_{{dateField.model}}"
		[formGroup]="formGroup"
		[formControlRef]="formControl"
		[model]="model" 
		[field]="dateField" 
		(onUpdate)="onInputUpdate($event)"
		[minDate]="dateField.minDate" 
		[maxDate]="dateField.maxDate"
		[placeholder]="dateField.placeholder"
		[required]="dateField.required == true"
		[appearance]="appearance">
	</lio-mat-wrap-date-field>
</div>

<!-- Image -->
<span *ngIf="fieldType == 'image'" [ngClass]="imageField.class">
	<img [src]="model[imageField.model]" [width]="imageField.width" [height]="imageField.height"/>
</span>

<!-- Custom Reports Inputs -->
<lio-report-fields *ngIf="fieldType == 'reportFields'" 
	[model]="model" [field]="field" (onUpdate)="onInputUpdate($event)" [formdata]="formdata"></lio-report-fields>

<lio-report-joints *ngIf="fieldType == 'reportJoints'" 
	[model]="model" [field]="field" (onUpdate)="onInputUpdate($event)" [formdata]="formdata"></lio-report-joints>

<lio-report-settings *ngIf="fieldType == 'reportSettings'" 
	[model]="model" [field]="field" (onUpdate)="onInputUpdate($event)"></lio-report-settings>

<lio-report-table *ngIf="fieldType == 'reportTable'" 
	[model]="model" [field]="field" (onUpdate)="onInputUpdate($event)" [formdata]="formdata"></lio-report-table>

<lio-report-wheres *ngIf="fieldType == 'reportWheres'" 
	[model]="model" [field]="field" (onUpdate)="onInputUpdate($event)" [formdata]="formdata"></lio-report-wheres>
<!-- END Custom Reports Inputs -->
	
<lio-catalog-modules *ngIf="fieldType == 'catalogModules'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [field]="field"></lio-catalog-modules>

<lio-catalog-notes *ngIf="fieldType == 'catalogNotes'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [field]="field"></lio-catalog-notes>

<lio-catalog-topics *ngIf="fieldType == 'catalogTopics'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [field]="field"></lio-catalog-topics>

<lio-catalog-updates *ngIf="fieldType == 'catalogUpdates'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [field]="field"></lio-catalog-updates>
<!-- END Catalog Inputs -->

<!-- Event Inputs -->
<lio-event-conditions *ngIf="fieldType == 'eventConditions'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [formdata]="formdata" [field]="field"></lio-event-conditions>

<lio-event-days *ngIf="fieldType == 'eventDays'" 
[model]="model" (onUpdate)="onInputUpdate($event)" [field]="field"></lio-event-days>

<lio-query-builder *ngIf="fieldType == 'eventFilters'" 
	[(filters)]="formdata.filters"
	[controlObject]="builderCtrl"
	[settings]="formdata.builderSettings" 
	[(rules)]="model.rules"
	(updatedBuilder)="onInputUpdate($event)"
	[formGroup]="formGroup">
</lio-query-builder>
<!-- END Event Inputs -->