let FiltersClass = function() {
	this.selectedLanguage = function(catalogs, languages) {
		if (!catalogs) {
			return;
		}
		return catalogs.filter(function(catalog) {
			let cataloglanguage;
			let language;
			let match;

			if (!languages || !languages[0]) {
				return true;
			}

			for (let l = 0; l < languages.length; l++) {
				language = languages[l];
				match = false;
				for (let i in catalog.langs) {
					cataloglanguage = catalog.langs[i].name;
					if (cataloglanguage == language) {
						match = true;
						break;
					}
				}

				if (!match) {
					return false;
				}
			}			
			return true;
		});
	}

	// Get catalogs based on topic (more limiting as more topics are chosen)
	this.selectedTopic = function(catalogs, topics) {
		if (!catalogs) {
			return;
		}
		return catalogs.filter(function(catalog)  {
			let catalogTopic,
					topic,
					match,
					i;

			if (!topics || !topics[0]) {
				return true;
			}

			for (let l = 0; l < topics.length; l++) {
				topic = topics[l];
				match = false;
				for (i in catalog.topics) {
					catalogTopic = catalog.topics[i].name;
					if (catalogTopic == topic) {
						match = true;
						break;
					}
				}

				if (!match) {
					return false;
				}
			}			
			return true;
		});
	}
	
	this.getFilter = function(name){
		switch(name){
			case 'selectedTopic'	:
				return Filters.selectedTopic;
			case 'selectedLanguage'	:
			default					:
				return Filters.selectedLanguage;
		}
	}
}


let Filters = new FiltersClass(); 


/*
 * Sorting Worker
*/
PaginationSorter = function() {
	this.sortCollection = function(params) {
		let self = this,
			collection 	= params.collection,
			sortMode 	= params.sortMode;

		if (sortMode && sortMode.field) {
			collection.sort(function(a, b) {			
				//allows a property with a single dot operator to be passed
				let splitString = sortMode.field.split('.');
				if(sortMode.direction == 'desc'){
					return self.sortWithOperators(a, b, splitString, sortMode.fieldType) * -1;
				}else{
					return self.sortWithOperators(a, b, splitString, sortMode.fieldType);
				}
			});
		}
		return collection;
	};

	/**
	 * Does null checks for every step on the path when sorting things with dot operators
	 * Should be able to handle any number of dot operators
	 */
	this.sortWithOperators = function(a, b, path, type) {
		let self = this;

		if(!path.length) {
			return self.sortItem(a, b, type);
		} else {
			let nextOperator = path.shift();
			if (typeof a === 'undefined' || a === null) {
				return -1;
			} else if (typeof b === 'undefined' || b === null) {
				return 1;
			} else {
				return self.sortWithOperators(a[nextOperator], b[nextOperator], path, type);
			}
		}
	};

	/**
	 * Sorts an individual item in the collection
	 * if both items can be converted to numbers, sorting is done numerically, otherwise string sorting is used
	 */
	this.sortItem = function(a, b, type) {
		if (typeof a === 'undefined' || a === null) {
			return -1;
		}

		if (typeof b === 'undefined' || b === null) {
			return 1;
		}
		if(isNaN(a) || isNaN(b)){
			if(type && type == 'datetime'){
				let aAsDate = moment(a, "MM-DD-YYYY hh:mm:ss").format();
				if(aAsDate && aAsDate != 'Invalid date'){
					a = aAsDate;
				}

				let bAsDate = moment(b, "MM-DD-YYYY hh:mm:ss").format();
				if(bAsDate && bAsDate != 'Invalid date'){
					b = bAsDate;
				}
			}
			
			if(a.toString().toUpperCase() == b.toString().toUpperCase()){
				return 0;
			}
			else if(a.toString().toUpperCase() < b.toString().toUpperCase()){
				return -1;
			}else{
				return 1;
			}
		}else{
			return a - b;
		}
	};

	/**
	 * Applies filters to the collection
	 */
	this.filterCollection = function(params) {
		let self = this,
			collection 	= params.collection,
			filters 	= params.filters,
			strict 		= params.strict;

		//single strings are run against all fields, any matching entry 
		if(typeof filters == 'string'){
			collection = this.applySearchFilter(collection, filters);
		}
		else if(Array.isArray(filters)){
			filters.forEach(function(filter) {
				if(typeof filter == 'function'){
					//functions are run on the collection
					collection = filter(collection);
				}else if(typeof filter == 'string' || Array.isArray(filter) || typeof filter == 'object'){
					//strings, objects, or arrays are run through again
					collection = self.filterCollection(collection);
				}
			});
		//objects are expected to contain string keys with either string values or array values containing strings
		}else if(typeof filters == 'object'){
			if(filters.filter && filters.param){
				let filterFunc = Filters.getFilter(filters.filter);
				collection = filterFunc(collection, filters.param);
			}else{
				let keys = Object.keys(filters);
				for(let i = 0; i < keys.length; i++){
					let field = keys[i];
					let values = filters[keys[i]];
					if(values && values.filter && values.param){
						let filterFunc = Filters.getFilter(values.filter);
						collection = filterFunc(collection, values.param);
					}else{
						//if values came in as array, apply each value as a filter
						if(!Array.isArray(values)){
							values = [values];
						}

						//a field value of '*' is applied as a search filter
						if(field == '*'){
							values.forEach(function(value) {
								collection = self.applySearchFilter(collection, value);
							});
						}else{
							collection = self.applyColumnFilter(collection, field, values, strict);
						}
					}
				}
			}
		}

		return collection;
	};

	/**
	 * Applies an individual filter to the collection
	 */
	this.applyColumnFilter = function(collection, field, values, strict) {
		let newCollection 	= [],
			self = this;

		if(Array.isArray(values) && values.length > 0){
			collection.forEach(function(item)  {
				if(typeof item[field] != 'undefined'){
					let matched = false;

					values.forEach(function(value) {
						if(value === null){
							matched = true;
						}else{
							if (typeof value == 'object') {
								if (typeof value.value != 'undefined') {
									if (typeof value.strict != 'undefined') {
										strict = value.strict;
									}
									value = value.value;
								} else {
									matched = true;
								}
							}

							if(typeof value != 'undefined' && value != null && value != ''){
								if(Array.isArray(item[field])){
									item[field].forEach(function(subItem) {
										if(self.matchField(subItem, value, strict)){
											matched = true;
										}
									});
								}else if(self.matchField(item[field], value, strict)){
									matched = true;
								}
							} else {
								matched = true;
							}
						}
					});

					if(matched){
						newCollection.push(item);
					}
				}
			});

			return newCollection;
		}else{
			return collection;
		}
	};

	/**
	 * Checks an individual field to see if it matches a search value
	 */
	this.matchField = function(field, value, strict) {
		if (field == value) {
			return true;
		}

		if (typeof field === 'string') {
			field = field.toLowerCase();
		}
		if (typeof value === 'string') {
			value = value.toLowerCase();
		}



		if(field && (field == value || (!strict && typeof field.indexOf == 'function' && field.indexOf(value) >= 0))){
			return true;
		}
		return false;
	};

	/**
	 * searches all fields in each entry for the search value
	 * @warning computationally expensive - O(n^2)
	 */
	this.applySearchFilter = function(collection, value) {
		let newCollection 		= [];

		if(typeof value != 'undefined' && value != null && value != ''){
			collection.forEach(function(item) {
				let matched = false,
					keys 	= Object.keys(item);

				keys.forEach(function(key) {
					let field = item[key];
					if (typeof field === 'string') {
						// Match any case and remove any hyphens,slashes, commas and pipes 
						field = field.toLowerCase().replace(/,|-|\||\/|\s/g,"");
					}
					if (typeof value === 'string') {
						// Match any case and remove any hyphens,slashes, commas and pipes 
						value = value.toLowerCase().replace(/,|-|\||\/|\s/g,"");
					}

					if(!matched && item[key] == value 
					|| (item[key] && typeof field.indexOf == 'function' && field.indexOf(value) >= 0)){
						matched = true;
					}
				});

				if (matched) {
					newCollection.push(item);
				}
			});

			return newCollection;
		}else{
			return collection;
		}
	};
};
