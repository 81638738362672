import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { debugService } from 'src/app/services/debug.service';
import { localizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'lio-catalog-search-bar',
  templateUrl: './search-bar.component.html'
})
export class LioCatalogSearchBar {
	@Input() appearance		:MatFormFieldAppearance = 'outline';
	@Input() localeStrings		:any 				= null;
	@Input() filters		:any 				= null;
	@Input() view			:any 				= null;
	@Input() showFilter		:boolean			= false;
	@Input() clearBtnActive	:boolean			= false;

	@Output() onupdate		:EventEmitter<any> 	= new EventEmitter();
	@Output() toggleFilters	:EventEmitter<any> 	= new EventEmitter();
	@Output() resetQuery	:EventEmitter<any> 	= new EventEmitter();

	public localeStringItems:Array<any> 		= [
		'title',
		'subheader',
		'filters',
		'clear',
	];

	constructor(
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(localizationService) 	public localizationService	:localizationService
	){
		this.debugService.register('catalogsearchbar', this);
	}
}