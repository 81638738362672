import { Component, OnDestroy, Inject, ElementRef } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NEVER, Subscription } from 'rxjs';
import moment from 'moment';
import jsPDF from 'jspdf';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { permissionService } from '../../services/permissions.service';
import { processingService } from '../../services/processing.service';
import { utilService } from '../../services/util.service';
import { fieldService } from '../../services/fields.service';
import { storageService } from '../../services/storage.service';
import { pdfService } from '../../services/pdf.service';
import { catalogService } from '../../services/catalog.service';
import { catalogSettings } from '../../settings/catalog.settings';

@Component({
  selector: 'lio-catalog-search',
  templateUrl: './catalog-search.component.html'
})
export class CatalogSearchComponent implements OnDestroy {
	public appearance	:MatFormFieldAppearance = 'outline';
	public view			:any = {
		pageName: 'coursecatalog',
		canDownload: true,
		hasFilters: true,
		printable: true,
		canPreview: true,
		selfEnroll: false
	};

	public pdfDomReady:boolean = false;

	protected courseTypes				:Array<any> 		= [];
	protected expandAll				:boolean 			= false;
	protected expanded				:boolean 			= false;
	protected activeCollections		:any 				= null;
	protected fields					:any 				= this.utilService.copy(this.catalogSettings.fields);
	protected allCourses				:boolean 			= false;
	protected availableCourseIDs		:Array<any> 		= [];
	protected lastGrabbedAllCourses	:any 				= null;
	protected waitingToRender			:boolean 			= false;
	protected readyToRender			:boolean 			= false;
	protected renderTarget			:ElementRef 		= null;
	
	public rendering					:boolean 			= false;
	public activeCore					:boolean 			= false;
	public fieldsObject				:any 				= null;
	public collection					:Array<any> 		= [];
	public collections				:Array<any> 		= [];
	public catalogs						:Array<any> 		= [];
	public showFilter					:boolean 			= false;
	public loadingCatalogs		:boolean 			= false;
	public canExpandCatalogs	:boolean 			= true;
	public pagination					:any 				= this.catalogSettings.pagination;
	public clearBtnActive			:boolean 			= false;
	public languageCodes			:Array<any> 		= [];
	public topics							:Array<any> 		= [];
	public printable					:boolean 			= false;
	public printingAll				:boolean 			= false;
	public printingNone				:boolean 			= true;
	public printingCount			:number 			= 0;

	public expandedAll				:boolean 			= false;
	public collapsedAll				:boolean 			= true;
	public totalCatalogs			:number 			= 0;
	public unReleasedStatus		:boolean 			= false;
	public releasedStatus			:boolean 			= false;

	public getCatalogsEndPoint:string 			= 'catalog/getAllActiveCatalogs';


	public filters:any = {
		'*' 			: '',
		langs			: {filter : 'selectedLanguage', param : []},
		topics		: {filter : 'selectedTopic', param : []},
		courseID		: [],
	};

	public searchBarLocale:any 		= {
		title					: 'DEFAULT',
		titleTrans				: 'coursecatalog.title',
		subheader 				: 'Enter search criteria below to select the courses you would like to view',
		subheaderTrans 			: 'coursecatalog.subheader',
		filters 				: 'FILTERS',
		filtersTrans 			: 'coursecatalog.filters',
		clear	 				: 'CLEAR',
		clearTrans	 			: 'coursecatalog.clear',
	};

	protected subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)	public 	feedbackService		:feedbackService,
		@Inject(errorsService)		public 	errorsService		:errorsService,
		@Inject(catalogService)		public 	catalogService		:catalogService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(lioLogService)		public 	lioLogService		:lioLogService,
		@Inject(workerService)		public 	workerService		:workerService,
		@Inject(permissionService)	public 	permissionService	:permissionService,
		@Inject(lioModalService)	public 	lioModalService		:lioModalService,
		@Inject(processingService) 	public 	processingService	:processingService,
		@Inject(utilService) 		public 	utilService			:utilService,
		@Inject(fieldService) 		public 	fieldService		:fieldService,
		@Inject(storageService)		public 	storageService		:storageService,
		@Inject(pdfService)			public 	pdfService			:pdfService,
		@Inject(catalogSettings) 	public 	catalogSettings		:catalogSettings
	){
		this.debugService.register('catalogsearch', this);

		this.processingService.allowCancel = true;
		this.processingService.setLangIDParam = true;

		this.init();
	}


	init() {
		this.navService.setActivePage('catalogsearch');
		this.getCollections();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/**
	 * Recieves information from paginator
	 * @param {object} event
	 */	
	updateFilteredCatalogs(event) {
		this.collection = event.collection;
	}

	/*
	 * Sets the fields
	*/
	setFields() {
		return this.fieldService.setFields(this.fields, {'fieldName': 'catalogSearch', overrideFields : true}).then((fields) => {
			this.setFieldsObject(fields);
		});
	}

	/*
	 * Sets an object to contain the fields for quick parsing of visible states 
	*/
	setFieldsObject(fields) {
		let fieldsObject = {};

		fields.forEach((field) => {
			fieldsObject[field.model] = field;
		});
		this.fieldsObject = fieldsObject;
	}

	/*
	 * Is the field visible
	 @param {string} fieldName
	 @return {boolean}
	*/
	isFieldVisible(fieldName) {
		if (!this.fieldsObject) {
			return true;
		}
		return this.fieldsObject[fieldName].visible;
	}

	/**
	 * Resets filter fields
	 */
	resetFilters() {
		this.filters['*']		= '';
		this.filters.langs		= {filter : 'selectedLanguage', param : []};
		this.filters.topics		= {filter : 'selectedTopic', param : []};
		this.filters.courseID	= [];
		this.lioLogService.log('Reset');
		delete this.filters.isAvailable;
		delete this.filters.isUnavailable;
	}

	/*
	 * Activate languages
	*/
	activateLanguage(language) {
		let name = language.name;
		let index;

		language.active = !language.active;
		if (language.active) {
			this.filters.langs.param.push(name);
		} else {
			index = this.filters.langs.param.indexOf(name);
			this.filters.langs.param.splice(index, 1);
		}

		this.clearBtnActive = true;
		this.activeCore = false;
		this.checkPrintability();
	}

	/*
	 * Toggles the filter display
	*/
	toggleFilters() {
		this.showFilter = !this.showFilter;
	}

	/*
	 * Activate core languages
	*/
	activateCoreLangs() {
		let langCodes = this.languageCodes;
		let totalCodes = langCodes.length;
		let langCode;
		let langName;
		let activeCore = !this.activeCore;
		let isCore;
		let i;

		this.filters.langs.param = [];

		for (i = 0; i < totalCodes; i++) {
			langCode = langCodes[i];
			isCore = langCode.isCore === '1'; 
			langName = langCode.name;
			langCode.active = false;
			if (isCore && activeCore) {
				langCode.active = true;
				this.filters.langs.param.push(langName);
			}
		}

		this.clearBtnActive = true;
		this.activeCore = activeCore;
		this.checkPrintability();
	}

	/*
	 * Activate topics
	 * @param {object} topic
	*/
	activateTopic(topic) {
		let name = topic.name;
		let index;

		topic.active = !topic.active;
		if (topic.active) {
			this.filters.topics.param.push(name);	
		} else {
			index = this.filters.topics.param.indexOf(name);
			this.filters.topics.param.splice(index, 1);
		}
		this.clearBtnActive = true;
		this.checkPrintability();
	}

	/*
	 * Activate collections
	 * @param {?object} selectedCollection
	*/
	activateCollection(selectedCollection:any = null) {
		let totalActive		:number 	= 0;
		let currentActive	:boolean 	= false;

		this.activeCollections = [];

		this.collections.forEach((collection:any) => {
			if (collection.active) {
				totalActive++;
				if (selectedCollection) {
					if (collection.collectionID === selectedCollection.collectionID) {
						currentActive = true;
					}
				}
			}
		});

		if (totalActive == 1 && currentActive) {
			this.lioLogService.log('This is already active');
			return;
		}

		if (selectedCollection) {
			selectedCollection.active = !selectedCollection.active;
		}
		this.allCourses = false;
		this.availableCourseIDs = [];

		// Combines all collection courses
		this.collections.forEach((collection) => {
			if (this.allCourses) {
				return;
			}
			if (collection.active && collection.allCourses == 1) {
				this.allCourses = true;
				this.availableCourseIDs =  [];
			} else if (collection.active) {
				this.availableCourseIDs.push(...collection.courseIDs);
			}
		});

		// Get the active collections for after previewing
		this.collections.forEach((collection) => {
			if (collection.active) {
				this.activeCollections.push(collection.collectionID); 
			}
		});


		// We already grabbed all courses, no need to reload
		if (this.lastGrabbedAllCourses && this.allCourses) {
			return;
		} 

		this.clearBtnActive = true;
		this.catalogs = [];
		// Activate the current catalog collection
		this.loadCatalogs();
	}

	/*
	 * Load the catalogs
	*/
	loadCatalogs() {
		this.resetQuery();

		this.getLanguages();
		this.getCourseTypes().then(() => {
			this.getCatalogs().then(() => {
				this.catalogsLoaded();
				this.finishPreview();
			});
		});
		this.setFields().then(() => {	
			this.navService.displayPage();
		});
	}

	/*
	 * Selects a module
	 * @param {object} catalog
	 * @param {object} selectedModule
	*/
	selectModule(event) {
		event.module.selected = !event.module.selected;
		
		let count = 0,
			deselectedCount = 0,
			delected = false,
			selected = event.module.selected;

		// Deselecting existing 
		if (!selected && event.module.selectCount) {
			deselectedCount = event.module.selectCount;
			event.module.selectCount = 0;
			delected = true;
		}

		event.catalog.modules.forEach((module) => {
			if (module.selected) {
				if (delected && module.selectCount > deselectedCount) {
					module.selectCount--;
				}
				count++;
			}
		});

		if (selected) {
			event.module.selectCount = count;
		}

		this.checkPrintability();
	}

	/*
	 * Clicked on release status
	*/
	clickedReleased() {
		this.releasedStatus = !this.releasedStatus;

		if (this.releasedStatus) {
			this.filters.isAvailable = true;
			delete this.filters.isUnavailable;
			this.clearBtnActive = true;
		} else {
			delete this.filters.isAvailable;
		}

		this.checkPrintability();
	}

	/*
	 * Clicked on un release status
	*/
	clickedUnreleased() {
		this.unReleasedStatus = !this.unReleasedStatus;

		if (this.unReleasedStatus) {
			this.filters.isUnavailable = true;
			delete this.filters.isAvailable;
			this.clearBtnActive = true;
		} else {
			delete this.filters.isUnavailable;
		}

		this.checkPrintability();
	}

	/*
	 * Formats each catalog for a specific task
	 * @param {date} catalog
	 * @param {number} key
	*/
	formatCatalogs() {
		this.catalogs.forEach((catalog, key) => {
			this.setCourseTypeToCatalog(catalog);
			this.handleRBS(catalog);
			this.checkAvailability(catalog['availableDate'], key);
			this.setOrder(catalog, key);
			this.setPrintable(catalog);
			this.checkModules(catalog);
			this.addSearchShortcuts(catalog);
		});
	}

	/*
	 * Handles catalogs with modules
	 * @param {date} catalog
	 * @param {number} key
	*/
	checkModules(catalog) {
		if (!catalog.modules.length) {
			return;
		}
		catalog.modules.forEach((module) => {
			if (module.description) {
				catalog.hasModuleDescriptions = true;
			}
			module.selectCount = 0;
		});
	}



	/*
	 * Stringify arrays for searching common parts of the catalog
	*/
	addSearchShortcuts(catalog) {
		catalog.topicsString = '';

		if (catalog.topics) {
			let keywords = [];
			catalog.topics.forEach((topic) => {
				catalog.topicsString += topic.name + ',';
				if (topic.keywords) {
					topic.keywords.forEach((keyword) => {
						catalog.topicsString += keyword.name + ',';
						if (keyword.featured) {
							keywords.push(keyword.name);
						}
					});
				}
			});
			catalog.featuredKeywords = keywords;
		}

		catalog.modulesString = '';
		if (catalog.modules) {
			catalog.modules.forEach((module) => {
				catalog.modulesString += module.name + ',' + module.description + ' ';
			});
		}

		catalog.langString = '';
		if (catalog.langs) {
			catalog.langs.forEach((lang) => {
				catalog.langString += lang.name + ' ';
			});
		}
	}

	/*
	 * Handles RBS catalogs
	 * @param {date} catalog
	 * @param {number} key
	*/
	handleRBS(catalog) {
		catalog.isRBS = catalog.courseType === 'RBS';
		
		if (catalog.isRBS) {
			catalog.requestedScript = true;
		}
	}

	/*
	 * Sets the catalog image
	 * @param {date} catalog
	 * @param {number} key
	*/
	setCourseTypeToCatalog(catalog) {
		this.courseTypes.forEach((courseType) => {
			if (courseType.id === catalog.courseTypeCode) {
				catalog.type = courseType.name;
				catalog.courseType = courseType.type;
				catalog.image = courseType.imageURL;

			}
		});
	}

	/*
	 * Gets the available course collections
	*/
	getCollections() {
		this.activeCollections = this.storageService.get('activeCollections'),
		this.storageService.remove('activeCollections');

		return this.lmsService.post('catalogcollection/getAvailable', {}).then((result) => {
			this.collections = result.properties.collections;
			if (this.collections.length) {
				if (this.activeCollections) {
					this.collections.forEach((collection) => {
						if (this.activeCollections.indexOf(collection.collectionID) > -1) {
							collection.active = 1;
						}
					});
					this.activateCollection();
				} else {
					let defaultCollection = this.collections[0];
					// Get the default collections, or use the first if none are set as default
					this.collections.forEach((collection) => {
						if (collection.defaultCollection == 1) {
							collection.active = 1;
							defaultCollection = null;
						}
					});

					this.activateCollection(defaultCollection);	
				}
				
				this.showFilter = true;
			} else {
				this.loadCatalogs();
			}
		});
	}

	/*
	 * Gets the course types
	*/
	getCourseTypes() {
		return this.catalogService.getCourseTypes().then((courseTypes) => {
			this.courseTypes = courseTypes;
		})
	}

	/*
	 * Sets the catalog order
	 * @param {date} catalog
	 * @param {number} key
	*/
	setOrder(catalog, key) {
		if (catalog.isAvailable) {
			catalog.order = '0' + catalog.courseID;	
		} else {
			catalog.order = '1' + catalog.availableDate;
		}
		this.catalogs[key] = catalog;
	}

	/*
	 * Sets printable to true for catalog
	 * @param {date} catalog
	 * @param {number} key
	*/
	setPrintable(catalog) {
		catalog.print = true;
	}

	/*
	 * Sets all catalogs to printable
	*/
	checkPrintAll() {
		this.collection.forEach((catalog) => {
			this.setPrintable(catalog);
		});
		this.printingAll = true;
		this.checkPrintability();
	}

	/*
	 * On click of the course script button
	 * @param {object} catalog
	*/
	clickedOnScript(catalog) {
		if (catalog.requestedScript) {
			return;
		}
		if (this.permissionService.hasPermission('catalog.canDownloadScript')) {
			if (catalog.scriptLocation.indexOf('http') == -1) {
				catalog.scriptLocation = 'https://' + catalog.scriptLocation;
			}
			this.navService.open(catalog.scriptLocation);
		} else {
			this.lmsService.post('catalog/requestScript', {'catalog': catalog}).then((result) => {
				let success = result.success;
				if (success) {
					this.lioModalService.show('requestedScript');
				}
			});
		}
		catalog.requestedScript = true;
	}

	/*
	 * On click of the course request button
	 * @param {object} catalog
	*/
	clickedOnRequest(catalog) {
		if (catalog.requestedImplementation) {
			return;
		}

		this.lmsService.post('catalog/requestImplementation', {'catalog': catalog}).then((result) => {
			let success = result.success;
			if (success) {
				this.lioModalService.show('requestedImplementation');
			}
		});
		catalog.requestedImplementation = true;		
	}

	/*
	 * On click of the Course Infosheet button
	 * @param {object} catalog
	*/
	clickedOnInfoSheet(catalog) {
		if (catalog.requestedInfoSheet) {
			return;
		}

			if (catalog.infoSheetLocation.indexOf('http') == -1) {
				catalog.infoSheetLocation = 'https://' + catalog.infoSheetLocation;
			}
			this.navService.open(catalog.infoSheetLocation);
		catalog.requestedInfoSheet = true;
	}

	/*
	 * On click of the Course one pager button
	 * @param {object} catalog
	*/
	clickedOnOnePager(catalog) {
		if (catalog.requestedOnePager) {
			return;
		}

		if (catalog.onePager.indexOf('http') == -1) {
			catalog.onePager = 'https://' + catalog.infoSheetLocation;
		}

		this.lioLogService.log(['open', catalog.onePager]);
		this.navService.open(catalog.onePager);
		catalog.requestedOnePager = true;
	}

	/*
	 * Checks the availability of a catalog
	 * @param {date} futureDate
	 * @param {number} key
	*/
	checkAvailability(futureDate, key) {
		let date1 = new Date();
		let date2 = new Date(futureDate);
		let isAvailable = true;

		// Verify date difference
		if (futureDate && date2 > date1) {
			isAvailable = false;
		}
		this.catalogs[key]['isAvailable']  = isAvailable;
		this.catalogs[key]['isUnavailable']  = !isAvailable;
	}

	/*
	 * Reset the query
	*/
	resetQuery() {
		if (this.pagination.sortMode) {
			this.pagination.sortMode.field = 'courseID';
			this.pagination.sortMode.order = 'asc';
		}
		this.resetFilters();

		if (this.topics) {
			for (let i = 0; i < this.topics.length; i++) {
				this.topics[i].active = false;
			}	
		}
		
		if (this.languageCodes) {
			for (let i = 0; i < this.languageCodes.length; i++) {
				this.languageCodes[i].active = false;
			}
		}

		this.activeCore = false;
		this.releasedStatus = false;
		this.unReleasedStatus = false;
		this.clearBtnActive = false;
		this.checkPrintability();
	}

	/*
	 * Gets languages
	*/
	getLanguages() {
		this.lmsService.post('catalog/getAllUsedLangs', {}).then((result) => {
			let languages = result.properties.languages;
			this.languageCodes = languages;
		});
	}

	/*
	 * Gets topics
	*/
	getTopics(courseIDs) {
		this.lmsService.post('catalog/getAllUsedTopics', {'courseIDs': courseIDs}).then((result) => {
			let topics = result.properties.topics;
			this.topics = topics;
		});
	}

	/*
	 * Expand All Catalogs
	*/
	expandAllCatalogs() {
		let catalogs = this.collection;
		let totalCatalogs = catalogs.length;
		let catalog;

		this.expanded = true;

		for (let i = 0; i < totalCatalogs; i++) {
			catalog = catalogs[i];
			catalog.expanded = this.expanded;
		}

		this.expandedAll = true;
		this.collapsedAll = false;
	}

	/*
	 * Clicked catalog
	 * @param {array} catalog
	*/
	clickedCatalog(catalog) {
		catalog.expanded = !catalog.expanded;
		this.expandedAll = false;
		this.collapsedAll = false;
	}

	/*
	 * Collapse All Catalogs
	*/
	collapseAllCatalogs() {
		let catalogs = this.collection;
		let totalCatalogs = catalogs.length;
		let catalog;

		this.expanded = false;

		for (let i = 0; i < totalCatalogs; i++) {
			catalog = catalogs[i];
			catalog.expanded = this.expanded;
		}

		this.expandedAll = false;
		this.collapsedAll = true;
	}

	/*
	 * Clear all prints
	*/
	clearPrint() {
		let catalogs = this.collection;
		let totalCatalogs = catalogs.length;
		let catalog;

		this.expanded = false;

		for (let i = 0; i < totalCatalogs; i++) {
			catalog = catalogs[i];
			catalog.print = false;
		}
		this.printable = false;
		this.printingAll = false;
		this.printingNone = true;
		this.printingCount = 0;
	}

	/*
	 * Check the printability
	*/
	checkPrintability() {
		let catalogs = this.collection;
		let totalCatalogs = catalogs.length;
		let catalog;
		let printable = false;
		let printingAll = true;
		let printingNone = true;
		let printingCount = 0;

		for (let i = 0; i < totalCatalogs; i++) {
			catalog = catalogs[i];
			catalog.last = false;
			if (catalog.print) {
				printable = true;
				printingCount++;
				printingNone = false;
			} else {
				printingAll = false;
			}
		}

		this.printable = printable;
		this.printingCount = printingCount;
		this.updateLastPrintable();
		this.printingAll = printingAll;
		this.printingNone = printingNone;
	}

	/*
	 * Updates the last printabile catalog
	 * The last printable needs to be set to avoid adding line breaks after the last one
	*/
	updateLastPrintable() {
		let catalogs = this.collection;
		let totalCatalogs = catalogs.length;
		let catalog;
		let count = 0;

		for (let i = 0; i < totalCatalogs; i++) {
			catalog = catalogs[i];
			catalog.last = false;
			if (catalog.print) {
				count++;
				if (count === this.printingCount) {
					catalog.last = true;
				}
			}
		}
	}

	/*
	 * Generate the PDF
	 * @param {array} catalogs
	 * @param {boolean} printAll
	*/
	generatePDF(){
		if(this.readyToRender && !this.waitingToRender){
			this.rendering = true;
			setTimeout(() => {
				this.pdfService.render(this.renderTarget).subscribe((pdf:jsPDF) => {
					pdf.save(moment().toString() + '.pdf');
					this.rendering = false;
				});
			}, 2000);
		}else{
			this.waitingToRender = true;
		}
	}

	enablePDFDom() {
		this.pdfDomReady = true;
		this.waitingToRender = true;
	}

	setRendererReady(data:{ready:boolean,target:ElementRef}){
		this.readyToRender 	= data.ready;
		this.renderTarget 	= data.target;
		if(this.waitingToRender){
			this.waitingToRender = false;
			setTimeout(() => {
				this.generatePDF();
			}, 100);
		}
	}

	filterPrintCatalogs(catalogs){
		let out = [];

		catalogs.forEach((catalog) => {
			if (catalog.print) {
				out.push(catalog);
			}
		});

		return out;
	}

	/*
	 * Print current list
	*/
	print() {
		if (!this.printable) {
			this.lioModalService.show('Please select at least one course to print');
			return;
		}
		this.enablePDFDom();
	}

	/*
	 * Print all
	*/
	printAll() {
		this.collection.forEach((catalog) => {
			catalog.print = true;
		});
		this.checkPrintability();
		this.print();
	}

	/*
	 * Gets all the active catalogs
	 * @param {function} callback
	*/
	getCatalogs() {
		this.loadingCatalogs = true;
		let promise = new Promise((resolve) => {
			this.lmsService.postAsync(this.getCatalogsEndPoint, {'courseIDs': this.availableCourseIDs, 'allCourses': this.allCourses}).then((result) => {
				if (result) {
					let token = result.properties.token;
					if (token) {
						this.lmsService.getAsyncResult(token, (result) => {
							resolve(this.gotCatalogs(result));
						}, {'destroyResult': true});
					} else {
						resolve(this.gotCatalogs(result));
					}
				}
			});
		});
		return promise;
	}


	/*
	 * Catalogs returned, handled them
	 * @return {boolean}
	*/
	gotCatalogs(result:any):boolean {
		let catalogs = result.properties.catalogs,
				courseIDs = [];
		this.lastGrabbedAllCourses = this.allCourses;

		if (!catalogs.length) {
			this.loadingCatalogs = false;
		} else {
			this.catalogs = catalogs;
			this.catalogs.forEach((catalog) => {
				courseIDs.push(catalog.courseID);

				catalog.featuredTopics = catalog.topics.filter((topic) => {
					return topic.featuredTopic == 1;
				});
			
				catalog.genericTopics = catalog.topics.filter((topic) => {
					return topic.featuredTopic == 0;
				});

			});

			this.getTopics(courseIDs);
		}
		return true;
	}

	/*
	 * Catalogs are loaded, prep
	*/
	catalogsLoaded() {
		this.formatCatalogs();
		
		// Hide unreleased catalogs to non admins
		if (!this.permissionService.hasPermission('catalog.admin')) {
			this.clickedReleased(); 
		}

		if (this.expandAll) {
			this.expandAllCatalogs();
		}

		this.clearBtnActive = false;
	}

	/*
	 * User clicked the print
	 * @param {array} catalog
	*/
	clickedPrint(catalog) {
		catalog.print = !catalog.print;
		this.checkPrintability();
	}

	/*
	 * After previewing a course, we need to update the course log
	 * @param {object} catalog
	*/
	finishPreview() {
		let courseID 		= this.storageService.get('catalogSessionCourseID'),
			launchError 	= this.storageService.get('launchError');


		// Launch error
		if (launchError) {
			this.storageService.remove('launchError');
			this.lmsService.post('catalog/launchError', {'courseID': courseID, 'launchError': launchError}).then((result) => {
				let success = result.success;
				if (success) {
					this.lioLogService.log(launchError);
					this.lioModalService.show('courselaunch.previewError');
				}
			});
			return;
		}

		// Finished viewing course
		if (courseID) {
			this.lmsService.post('catalog/endPreview', {'courseID': courseID}).then((result) => {
				if (result.success) {
					this.lioLogService.log('Ended Preview');
				}
				this.storageService.remove('catalogSessionCourseID');
			});
		}
	}

	/*
	 * On click of an RBS video
	 * @param {object} catalog
	*/
	clickedOnRBS(catalog) {
		if (catalog.scriptLocation && catalog.scriptLocation.indexOf('http') == -1) {
			catalog.scriptLocation = 'https://' + catalog.scriptLocation;
		}
		this.lmsService.post('catalog/previewRBS', 
			{'courseID': catalog.courseID, 'launchMethod': 'rbs-preview'}
		).then((result) => {
			let success = result.success;
			let course = result.properties.course;
			let courseURL = catalog.scriptLocation;

			if (course) {
				courseURL = course.courseURL;
			} else {
				course = {};
			}

			if (success) {
				course.courseURL = courseURL;
				course.courseID = catalog.courseID;
				this.gotoPreview(course);
			}
		});
	}

	/*
	 * Goes to the course previewer
	 * @param {array} module
	*/
	gotoPreview(course) {
		this.storageService.set('origin', 'catalogsearch');
		this.storageService.set('preview', course);
		this.storageService.set('courseID', course.courseID);
		this.storageService.set('catalogSessionCourseID', course.courseID);
		this.storageService.set('activeCollections', this.activeCollections);
		this.navService.goto('courselaunch');
	}

	/*
	 * Clicked on preview module
	 * @param {array} catalog
	 * @param {array} module
	*/
	previewModule(event) {
		let url 		= event.module.url;
		let courseID 	= event.catalog.courseID;
		let course:any	= {};

		if (!url) {
			this.lioLogService.log('Module does not have a URL to preview, falling back to catalog courseID');
			this.preview(courseID);
			return;
		}

		if (!parseInt(url)) {
			this.lioLogService.log('Module URL is not a number going to preview the direct URL');
			course.courseID = event.catalog.courseID;
			course.courseURL = url;
			this.gotoPreview(course);
			return;
		}

		// The url is actually a courseID
		this.lioLogService.log('Module URL is a number going to preview the url as a courseID');
		this.preview(url);
	}

	/*
	 * User clicked the course preview
	 * @param {array} catalog
	*/
	previewCourse(catalog) {
		let courseID = catalog.courseID;

		// RBS
		if (catalog.isRBS) {
			this.clickedOnRBS(catalog);
			return;
		}

		this.preview(courseID);
	}

	/*
	 * Preview
	 * @param {number} courseID
	*/
	preview(courseID) {
		// PREVIEW THE COURSE
		this.lmsService.post('catalog/previewCourse', 
			{'courseID': courseID, 'launchMethod': 'catalog-preview'}
		).then((result) => {
			let course = result.properties.course;
			if (course) {
				this.gotoPreview(course);
			}
		});
	}

	/*
	 * User clicked on catalog collections edit
	 * @param {number} courseID
	*/
	editCollections() {
		this.navService.goto('catalogcollection');
	}
}