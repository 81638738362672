/*
 * Simple service for registering paginators
*/
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class paginationRegistryService{
	paginators	: Array<any>;
	listeners	: Array<any>;

	constructor(){
		this.paginators 		= [];
		this.listeners 			= [];
	}

	/**
	 * Registers a new paginator with the service, paginator with a matching id are overriden
	 */
	registerPaginator(id, component){
		this.paginators[id] = component;

		if(this.listeners[id]){
			this.listeners[id].forEach((callback) => {
				if(typeof callback == 'function'){
					callback(this.paginators[id]);
				}
			});
		}
	}

	/**
	 * Registers a new listener with the service, listener functions are called when a paginator with a matching id is registered
	 * callback is executed immediately if a matching paginator already exists
	 */
	registerObserver(id, callback){
		if(!this.listeners[id]){
			this.listeners[id] = [];
		}

		this.listeners[id].push(callback);

		if(this.paginators[id] && typeof callback == 'function'){
			callback(this.paginators[id]);
		}
	}

	/**
	 * Gets a paginator by its control id
	 */
	getPaginator(id){
		return this.paginators[id];
	}
}