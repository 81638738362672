import { Component, EventEmitter, OnInit, OnDestroy, Inject, Input, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { companyService } from '../../../../services/company.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { stateService } from '../../../../services/state.service';
import { companyAdminSettings } from '../../../../components/company-admin/company-admin.settings';

@Component({
  selector: 'lio-company-admin-edit',
  templateUrl: './company-admin-edit.component.html'
})
export class CompanyAdminEditComponent implements OnInit, OnDestroy {
	@Input() action:string = '';
	@Output() callback:EventEmitter<any> = new EventEmitter();

	public appearance:MatFormFieldAppearance = 'outline';
	public fields:any = [];
	public companies:any = [];
	public company:any = {};
	public fieldConfig:any = {};
	public companyIDField:any = {};
	public allowSubmit:boolean = false;
	public localeStrings:any = {};
	public localeStringsKeys:any= [];
	public macros:any = [];
	public subCompanies:Array<any> = [];
	public subCompanyFields:Array<any> = this.companyAdminSettings.subCompanyFields;
	private originalModel:any = {};
	private changedData:any = {};
	public groups:Array<any> = this.companyAdminSettings.customFieldGroups;
	public subCompanyFieldConfig:any = this.companyAdminSettings.subCompanyFieldConfig;

	protected subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService) public navService:navService,
		@Inject(debugService) public debugService:debugService,
		@Inject(stateService) public stateService:stateService,
		@Inject(feedbackService) public feedbackService:feedbackService,
		@Inject(lmsService) public lmsService:lmsService,
		@Inject(companyService) public companyService:companyService,
		@Inject(formValidatorService) public formValidatorService:formValidatorService,
		@Inject(permissionService) public permissionService:permissionService,
		@Inject(lioModalService) public lioModalService:lioModalService,
		@Inject(processingService) public processingService:processingService,
		@Inject(utilService) public utilService:utilService,
		@Inject(companyAdminSettings) public companyAdminSettings:companyAdminSettings
	){
		this.debugService.register('companyadmin' + this.action, this);
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.navService.displayPage();
			})
		);
	}

	/*
	 * On init.
	 */
	ngOnInit() {
		if (this.action === 'create' && !this.company.coName) {
			this.companyService.resetSubCompanies();
		}
		this.init();
		this.navService.displayPage();
	}


	/*
	 * Init.
	 */
	init() {
		this.initFields();
		this.fieldConfig = this.companyAdminSettings.companyFieldConfig;
		this.companyIDField = this.companyAdminSettings.companyIDField;
		this.company = this.utilService.copy(this.companyAdminSettings.model);
		this.processingService.setLangIDParam = true;
		this.processingService.allowCancel = true;
		this.subscriptions = NEVER.subscribe();

		this.localeStrings = {
			save : 'companyadmin.save',
			cancel : 'message.cancel',
			addSubCompany : 'Add Sub Company',
		}

		this.localeStringsKeys = Object.keys(this.localeStrings);

		this.subscriptions.add(
				this.companyService.companies.subscribe((companies) => {
					this.companies = companies;
				})
		);

		this.subscriptions.add(
				this.companyService.company.subscribe((company) => {
					if (!company) {
						company = this.utilService.copy(this.companyAdminSettings.model);
					}
					this.company = this.companyService.formatCompany(company);
					if (this.company.companyID) {
						this.lioModalService.showLoading('loading');
						this.companyService.getSubCompanies().then(() => this.lioModalService.hideLoading());
					}
				})
		);

		this.subscriptions.add(
			this.companyService.subCompanies.subscribe((subCompanies) => {
				this.subCompanies = subCompanies;
			})
		);

		if (!this.permissionService.hasPermission('company.editccf')) {
			this.groups = [];
		}

		if (this.getFieldByName('companyID')) {
			this.getFieldByName('companyID').locked = true;
		}
	}

	/*
	 * Init function for form fields.
	 */
	initFields() {
		if (this.action === 'create') {
			this.fields	= this.permissionService.setFields(this.companyAdminSettings.createFields);
		} else if (this.action === 'edit') {
			this.fields	= this.permissionService.setFields(this.companyAdminSettings.editFields);
		}
	}

	/*
	 * On update of the profile.
	 */
	onUpdate() {
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
		this.navService.changedForm = true;
		this.allowSubmit = true;
	}

	/*
	 * Checks if the form is valid.
	 *
	 * @return {boolean}
	 */
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.formValidatorService.setOriginalModel(this.originalModel);
		if (!this.formValidatorService.isValid(this.company, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.company = this.formValidatorService.getData();
		this.changedData = this.formValidatorService.getDelta();
		if (this.changedData) {
			let changedDataKeys = Object.keys(this.changedData);
			changedDataKeys.forEach((key) => {
				if (this.utilService.inString('Visible', key) || this.utilService.inString('Groupable', key)) {
					if (this.changedData[key] === true) {
						this.changedData[key] = 1;
					} else {
						this.changedData[key] = 0;
					}
				}
			});
		}

		if (isValid) {
			isValid = this.validateSubCompanies();
		}

		return isValid;
	}

	/*
	 * Submit the edit.
	 *
	 * @return {boolean}
	 */
	submit():boolean {
		if (!this.isValid()) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			return false;
		}
		if (this.action === 'create') {
			this.subCompanies.forEach((subCompany) => {
				delete subCompany.id;
			});
		}
		this.companyService.globalSaveCompany(this.company, this.subCompanies).then((result)  => {
			if (result) {
				this.macros = [{'key': 'coName', 'value': this.company.coName}]
				this.onSaveSuccess();
			}
		});
		return true;
	}

	/*
	 * Take actions after changes are successfully saved.
	 */
	onSaveSuccess() {
		this.allowSubmit = false;
		if (this.action === 'edit') {
			let companyID = this.utilService.copy(this.company.companyID);
			this.lioModalService.show('updatedSuccessfully', 'companyUpdated', this.macros);
			this.companyService.resetCompany();
			this.callback.emit({'switchTab': 'search', 'companyID' : companyID});
		} else if (this.action === 'create') {
			this.lioModalService.show('addedSuccessfully', 'companyAdded', this.macros);
			this.companyService.setCompany(this.company);
			this.callback.emit({'switchTab': 'edit'});
		}
	}

	/*
	 * Get a company by company ID.
	 */
	getCompany() {
		if (this.company.companyID) {
			this.companyService.setCompanyByCompanyID(this.company.companyID);
			this.feedbackService.clearErrors();
			this.formValidatorService.resetFields();
		}
	}

	/*
	 * Get field by name.
	 *
	 * @return {array|null}
	 */
	getFieldByName(modelName) {
		let foundField = null;
		this.fields.forEach((field) => {
			if (field.model === modelName) {
				foundField = field;
				return;
			}
		});
		return foundField;
	}

	/*
	 * First check to delete a sub company.
	 *
	 * @return {void}
	 */
	deleteSubCompany(subCompany) {
		let id = subCompany.id;

		if (!id) {
			this.confirmedDelete(subCompany);
			return;
		}
		this.lioModalService.confirm('areYouSureDeleteGeneric').then((confirmed) => {
			if (!confirmed) {
				return;
			}
			this.confirmedDelete(subCompany);
		});
	}

	/*
	 * Validation function for subcompanies.
	 *
	 * @return {boolean}
	 */
	validateSubCompanies() {
		if (!this.subCompanies.length) {
			return true;
		}
		let isValid 		= true;
		let error 			= null;
		let macros 			= [];
		let subcompanyNames = [this.company.coName];

		this.subCompanies.forEach((subCompany) => {
			if (this.utilService.inArray(subcompanyNames, subCompany.coName)) {
				error = 'pleaseAddUniqueName';
				macros = [{'key': 'name', 'value': subCompany.coName}];
				this.feedbackService.setError(error, macros);

				isValid = false;
				return;
			}
			subcompanyNames.push(subCompany.coName);

			if (!this.formValidatorService.isValid(subCompany, this.subCompanyFields)) {
				isValid = false;
				this.feedbackService.setErrors(this.formValidatorService.getErrors());
			}
		});

		return isValid;
	}

	/*
	 * Delete a sub company.
	 */
	confirmedDelete(subCompany) {
		let	index 	= this.subCompanies.indexOf(subCompany);
		let id 		= subCompany.id;

		if (id) {
			this.lioModalService.showLoading('processing');
			return this.lmsService.post('subcompany/deleteSubCompany', {'companyID': this.company.companyID, 'subCompanyID': id}).then((result) => {
				var success = result.success;
				this.lioModalService.hideLoading();
				this.subCompanies.splice(index, 1);
				return success;
			});
		} else {
			this.subCompanies.splice(index, 1);
		}

		this.feedbackService.clearErrors();
	}

	/*
	 * Add a sub company.
   */
	addSubCompany() {
		let index = this.subCompanies.length + 1;
		this.subCompanies.push(
			{
				name	: 'Sub Company ' + index,
				groupId	: 'sub' + index,
				visible	: true,
			}
		);
	}

	/*
	 * On destroy.
	 */
	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}
