import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatFormFieldAppearance } from '@angular/material/form-field';
import { localizationService }	from '../../../../services/localization.service';

@Component({
	selector: 'lio-form-inputs-vertical',
	templateUrl: './form-inputs-vertical.component.html',
	styleUrls: ['./form-inputs-vertical.component.css']
})
export class LioFormInputsVertical {
	private _parentGroup:FormGroup;
	public get parentGroup():FormGroup{
		return this._parentGroup;
	}
	@Input() public set	parentGroup(val:FormGroup){
		this._parentGroup = val;
		val.addControl('formInputs', this.formGroup);
	}

	public formGroup:FormGroup = new FormGroup({});

	@Input() model:any;
	@Input() fields:any;
	@Output() onChanged: EventEmitter<any> = new EventEmitter();
	@Input() appearance: MatFormFieldAppearance = 'fill';

	constructor(@Inject(localizationService) public localizationService :localizationService){}
}