import { Component, ViewChild } from '@angular/core';

import { DragDrop } from '@angular/cdk/drag-drop';

@Component({
	selector: 'lio-drag',
	template: '<div #lioDrag></div>'
})
export class LioDrag {
	@ViewChild('lioDrag') lioDrag
	constructor(private dragDrop: DragDrop){
		setTimeout(() => {
			this.dragDrop.createDrag(this.lioDrag.nativeElement.parentElement.parentElement);
		});
	}
}