import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';

import { debugService } from 'src/app/services/debug.service';
import { permissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'lio-catalog-results-header',
  templateUrl: './results-header.component.html'
})
export class LioCatalogResultsHeader{
	@Input() catalogs		:Array<any>			= [];
	@Input() collection		:Array<any>			= [];
	@Input() loadingCatalogs:boolean 			= false;
	@Input() view			:any				= null;
	@Input() printingCount	:number				= 0;

	@Output() print			:EventEmitter<any> 	= new EventEmitter();
	@Output() printAll		:EventEmitter<any> 	= new EventEmitter();

	public localeStringItems:Array<any> = [
		'loading',
		'noCatalogsFound',
		'results',
		'print',
		'printAll',
	];

	public localeStrings:any = {
		loading			 		: 'Loading...',
		loadingTrans			: 'coursecatalog.loading',
		noCatalogsFound 		: 'No Catalogs Found',
		noCatalogsFoundTrans 	: 'coursecatalog.noCatalogsFound',
		results 				: 'Results',
		resultsTrans 			: 'coursecatalog.results',
		print 					: 'Print',
		printTrans 				: 'coursecatalog.print',
		printAll 				: 'Print All',
		printAllTrans 			: 'coursecatalog.printAll',
	};

	constructor(
		@Inject(debugService) 		public debugService		:debugService,
		@Inject(permissionService) 	public permissionService:permissionService
	){
		this.debugService.register('catalogresultsheader', this);
	}
}