
<lio-loading-spinner [hidden]="loadedHistory"></lio-loading-spinner>
<section [hidden]="!loadedHistory">
	<mat-form-field [appearance]="appearance" 
		class="pt-0 w-100" 
		[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'"
		localize [model]="searchField" item="name">

		<!-- LABEL -->
		<mat-label id="am_form_label_{{searchField.model}}">
			{{searchField.name}}
		</mat-label>

		<input matInput 
			id="am_eventList_search"
			type="text"
			[(ngModel)]="searchField.search"/>
		<mat-icon matSuffix>search</mat-icon>
	</mat-form-field>
	<lio-card bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
		<div lio-card-body 
			lio-paginator 
			controlID="eventHistory" 
			[filters]="searchField.search" 
			[collection]="history" 
			[settings]="pagination" 
			(onResults)="updateFilteredHistory($event)">
			
			<lio-results-table paginatorID="eventHistory"
				[fields]="fields"
				[results]="filteredHistory">
			</lio-results-table>
		</div>
		<div lio-card-footer>
			<lio-paginator-controls class="row" controlID="eventHistory"></lio-paginator-controls>
		</div>
	</lio-card>
</section>