import { Component, Optional, Inject } from '@angular/core';

import { debugService } from '../../../../services/debug.service';
import { iframeService } from '../../../../services/iframe.service';
import { navService } from '../../../../services/nav.service';

import { configSettings } from '../../../../settings/config.settings';

@Component({
	selector: 'lio-footer',
	templateUrl: './footer.component.html'
})
export class LioFooter {
	public currentTime:Date = new Date();

	constructor(
		@Optional() @Inject(debugService)	public debugService		:debugService,
		@Optional() @Inject(iframeService)	public iframeService	:iframeService,
		@Optional() @Inject(navService)		public navService		:navService,
		@Optional() @Inject(configSettings)	public configSettings	:configSettings,
	){
		this.debugService.register('footer', this);
	}
}