import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { LioField } from '../../modules/lio-forms/lio-forms.models';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { stateService } from '../../services/state.service';
import { localizationService } from '../../services/localization.service';
import { workerService } from '../../services/worker.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { debugService } from '../../services/debug.service';
import { permissionService } from '../../services/permissions.service';
import { paginationRegistryService } from '../../services/pagination-registry.service';
import { localizationSettings } from '../localization/localization.settings';

@Component({
	selector: 'lio-localization-admin',
	templateUrl: './localization-admin.component.html'
})
export class LocalizationAdminComponent implements OnDestroy {
	public searches						:Array<any> 	= this.utilService.copy(this.localizationSettings.searches);
	public search						:any 			= {};
	public localizationItems			:Array<any> 	= [];
	public filteredLocalizationItems	:Array<any> 	= [];
	public langs						:Array<any> 	= this.utilService.copy(this.localizationSettings.langs);
	public pagination					:any 			= this.localizationSettings.pagination;
	public readyToProcess				:boolean 		= false;
	public disableUpload				:boolean 		= false;

	public uploadSettings				:any 			= {
		'name'				: 'Import Translations',
		'trans'				: 'localization.importTranslations',
		'fileTask'			: 'importlocalization/upload',
		'allowedFileTypes'	: ['xlsx'],
		'hideLoading'		: false,
		'class'				: 'btn-primary mr-1',
	};

	public fields:Array<LioField> = [
		new LioField({
			model		:'field',
			visible		: true,
			type		:'text',
			name		:'Field',
			nameTrans	:'localization.field',
			sortable	:true
		}),
		new LioField({
			model		:'page',
			visible		: true,
			type		:'text',
			name		:'Page',
			nameTrans	:'localization.page',
			sortable	:true
		}),
		new LioField({
			model		:'languageKey',
			visible		: true,
			type		:'text',
			name		:'Language Key',
			nameTrans	:'localization.languageKey',
			sortable	:true
		}),
		new LioField({
			model		:'default',
			visible		: true,
			type		:'text',
			name		:'default',
			nameTrans	:'localization.default',
			sortable	:true
		})
	];

	public prefixButtons				:Array<any>		= [
		{
			header 		: 'Edit',
			headerTrans	: 'localization.edit',
			icon 		: 'search',
			id 			: 'am_localization_edit',
			color 		: 'primary',
			callback 	: (item) => { this.edit(item); }
		}
	];

	public localeStrings				:any 			= {
		headerTitle							:'Localizations',
		headerTitleTrans					:'localization.headerTitle',
		globalHeaderTitle					:'Global Localizations',
		globalHeaderTitleTrans				:'localization.globalHeaderTitle',
		getCompanyLocalizations				:'Get Company Translations',
		getCompanyLocalizationsTrans		:'localization.getCompanyLocalizations',
		getGlobalLocalizations				:'Get Global Translations',
		getGlobalLocalizationsTrans			:'localization.getGlobalLocalizations',
		exportAllFields						:'All Fields',
		exportAllFieldsTrans				:'localization.exportAllFields',
		exportMissingTranslations			:'Missing Translations',
		exportMissingTranslationsTrans		:'localization.exportMissingTranslations',
		exportTranslations					:'Translations',
		exportTranslationsTrans				:'localization.exportTranslations',
		processImport						:'Process Import',
		processImportTrans					:'localization.processImport',
		processImportGlobally				:'Process Import Globally',
		processImportGloballyTrans			:'localization.processImportGlobally',
		deleteTranslations					:'Delete Translations',
		deleteTranslationsTrans				:'localization.deleteTranslations',
		deleteAllCompanysTranslations		:'Delete All Company\'s Translations',
		deleteAllCompanysTranslationsTrans	:'localization.deleteAllCompanysTranslations',
	};
	public localeStringsKeys			:Array<any> 	= [
		'headerTitle',
		'globalHeaderTitle',
		'getGlobalLocalizations',
		'exportTranslations',
		'exportAllFields',
		'exportMissingTranslations',
		'processImport',
		'processImportGlobally',
		'deleteTranslations',
		'deleteAllCompanysTranslations'
	];

	public	global						:boolean 		= false;
	private localizableItems			:Array<any> 	= this.localizationService.getLocalizableItems();
	private confirmed					:boolean 		= false;
	private filePath					:any 			= null;

	private subscriptions				:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService)			public	loaderService		:loaderService,
		@Inject(paginationRegistryService)	public	paginationRegistryService	:paginationRegistryService,
		@Inject(navService)				public	navService			:navService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(localizationService)	public	localizationService	:localizationService,
		@Inject(workerService)			public	workerService		:workerService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(localizationSettings)	public	localizationSettings:localizationSettings
	){
		this.debugService.register('localizationadmin', this);
		this.navService.setActivePage('localizationadmin');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.setFields();
				this.getLocalizationItems();
			})
		);

		this.langs.forEach((lang) => {
			this.fields.push(new LioField({
				model		: lang.code,
				visible		: true,
				type		: 'concat',
				max 		: 25,
				name		: lang.name,
				nameTrans	: 'localization.' + lang.label,
				sortable	: true
			}));
		});
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredLocalizationItems(collection){
		this.filteredLocalizationItems = collection.filtered;
	}

	/*
	 * Edit's an localization item
	*/
	edit(localizationItem) {
		if (this.localizationService.localize(localizationItem.field)) {
			this.navService.goto('localization');
		}
	}

	/*
	 * Sets the fields
	*/
	setFields() {
		var lang = {
			model	: 'default', 
			name	: 'Default', 
			lang	: 'default', 
			type	: 'text',
			visible	: true
		};
		this.searches.push(lang);

		// Add the langs to the search fields
		this.langs.forEach((lang) => {
			if (lang.searchable) {
				let newField = {
					model	: lang.code, 
					name	: lang.name, 
					type	: 'text',
					visible	: true
				}
				this.searches.push(newField);
			}
		});
	}

	/*
	 * Gets all fields from db for companyID we are logged in,
	 * For each field in the localization settings
	 * @param {?number} companyID
	*/
	getLocalizationItems(keepNotifications:boolean = false) {
		let companyID:string = this.stateService.getActiveCompanyID();
		if(!keepNotifications){
			this.feedbackService.clearErrors();
			this.feedbackService.clearMessages();
		}
		this.initializeLocalizationObjects();

		if (this.global) {
			companyID = 'GLOBAL';
		}

		// Call getAllFieldsAndLocalizations and get all localized fields for specified CompanyID
		this.lmsService.post('localization/getAllFieldsAndLocalizations', {'companyID': companyID}).then((result) => {
			let localizations = result.properties.localizations;

			if (localizations) {
				this.setLocalizations(localizations);
			}
		});

		this.navService.displayPage();
	}

	/*
	 * Gets all fields from the global localizations
	*/
	getGlobalLocalizationItems() {
		this.localizationItems = [];
		this.global = true;
		this.getLocalizationItems();
	}

	/*
	 * Gets all fields from the current company localizations
	*/
	getCompanyLocalizationItems() {
		this.localizationItems = [];
		this.global = false;
		this.getLocalizationItems();
	}

	/*
	 * Empties the DB of localizations
	*/
	confirmedToErase() {
		this.confirmed = false;
		var companyID = this.stateService.getActiveCompanyID();

		// Call getAllFieldsAndLocalizations and get all localized fields for specified CompanyID
		this.lmsService.post('localization/clearLocalizations', {'companyID': companyID}).then(() => {
			this.localizationItems = [];
			this.getLocalizationItems();
		});

		this.navService.displayPage();
	}

	/*
	 * Deletes the current company's localizations
	*/
	confirmedToDelete() {
		this.confirmed = false;
		var companyID = this.stateService.getActiveCompanyID();

		// Call getAllFieldsAndLocalizations and get all localized fields for specified CompanyID
		this.lmsService.post('localization/deleteLocalizations', {'companyID': companyID}).then(() => {
			this.localizationItems = [];
			this.getLocalizationItems();
		});

		this.navService.displayPage();
	}

	/*
	 * Empties the DB of localizations 
	*/
	emptyLocalizations() {
		if (!this.confirmed) {
			this.navService.changedForm = true;
			this.lioModalService.confirm('areYouSureTitle', 'Are you sure you want to empty all localizations?');
			return;
		}
		this.confirmedToErase();
	}

	/*
	 * Empties the DB of localizations 
	*/
	deleteLocalizations() {
		if (!this.confirmed) {
			this.navService.changedForm = true;
			this.lioModalService.confirm('areYouSureTitle', 'Are you sure you want to delete this company\'s localizations?');
			return;		}
		this.confirmedToErase();
	}

	/*
	 * Initialized the localization object
	*/
	initializeLocalizationObjects() {
		this.localizableItems.forEach((localizableItem) => {
			let localization = {
				field		: localizableItem.field, 
				page		: localizableItem.field.split('.')[0], 
				languageKey	: localizableItem.field.split('.')[1], 
				default		: localizableItem.default
			};

			this.langs.forEach((lang) => {
				localization[lang.code] = '';
			});

			this.localizationItems.push(localization);
		});
	}

	/*
	 * Groups the localizations by LangID and updates the text
	 * @param {array} localizations
	*/
	setLocalizations(localizations) {
		this.localizationItems.forEach((localizationItem) => {
			let field = localizationItem.field,
				langID;

			localizations.forEach((localization) => {
				if (localization.field === field)  {
					langID = localization.langID;
					if (localization.text) {
						localizationItem[langID] = localization.text;
						localizationItem.localized = true;
					}
				}
			});
		});
		this.paginationRegistryService.getPaginator('localizationItems').refresh();
	}


	/*
	 * Gets the localization by a field
	 * @param {string} field
	 * @return {object}
	 * @helper
	*/
	getLocalizationByFIeld(field) {
		let match = null;
		this.localizationItems.forEach((localizationItem) => {
			if (!match && localizationItem.field == field) {
				match = localizationItem;
				return;
			}
		});

		return match;
	}


	/*
	 * Updates the search of localized items
	*/
	update() {
		this.cleanSearch();
	}


	/*
	 * On update of the search fields
	*/
	private _debounceTimer:any = null;
	onupdate() {
		clearTimeout(this._debounceTimer);
		this._debounceTimer = setTimeout(() => {
			this.update();
			clearTimeout(this._debounceTimer);
		}, 500);
	}


	/*
	 * Cleans the search query
	*/
	cleanSearch() {
		let searchKeys = Object.keys(this.search);
		searchKeys.forEach((key) => {
			if (this.search[key] === '') {
				delete this.search[key];
			}
		});
	}


	/*
	 * Gets the headers for export
	 * @return {array}
	*/
	getHeaders() {
		let langs	:Array<any> = this.utilService.copy(this.langs);
		let headers	:Array<any> = [];
		let header	:any 		= {};

		headers.push({
			model	: 'field', 
			name	: 'field',
			export 	: true
		});
		headers.push({
			model	: 'page', 
			name	: 'page',
			export 	: true
		});
		headers.push({
			model	: 'languageKey', 
			name	: 'languageKey',
			export 	: true
		});

		headers.push({
			model	: 'default', 
			name	: 'default',
			export 	: true
		});

		langs.forEach((lang) => {
			header = {
				model 	: lang.code,
				name	: lang.code,
				export 	: true
			};
			headers.push(header);
		});

		return headers;
	}


	/*
	 * Exports all records and localization items
	*/
	exportAllFields() {
		let localizations	:Array<any> = this.utilService.copy(this.localizationItems);
		let name			:string 	= "All Fields";
		let headers			:Array<any> = this.getHeaders();

		localizations.forEach((localization) => {
			if(localization.langs){
				let langKeys = Object.keys(localization.langs);
				langKeys.forEach((key) => {
					localization[key] = localization.langs[key].value;
				});
			}
			delete localization.langs;
		});

		this.lioModalService.showLoading('processing');

		this.workerService.export(localizations, headers, name).then((result) => {
			this.lioModalService.hideLoading();
			if (!result) {
				this.feedbackService.setError('failedToExportRecords');
			}
		});
	}


	/*
	 * Exports empty records
	*/
	exportEmptyRecords() {
		let localizations:Array<any> 	= this.utilService.copy(this.localizationItems);
		let name 						= "Missing Translations";
		let emptyLocalizations 			= [];
		let headers 					= this.getHeaders();
		let hasLangs 					= false;

		localizations.forEach((localization) => {
			hasLangs = false;
			this.langs.forEach((lang) => {
				if (localization[lang.code]) {
					hasLangs = true;
				}
			});
			if (!hasLangs) {
				emptyLocalizations.push(localization);
			}
		});

		if (!emptyLocalizations.length) {
			this.lioModalService.show('No empty localizations');
			return;
		}
		if (!this.workerService.export(emptyLocalizations, headers, name)) {
			this.feedbackService.setError('failedToExportRecords');
		}
	}


	/*
	 * Exports only localized records
	*/
	exportRecords() {
		let localizations:Array<any> 	= this.utilService.copy(this.localizationItems),
			name 						= "Translations",
			filledLocalizations 		= [],
			headers 					= this.getHeaders();

		localizations.forEach((localization) => {
			if (localization.localized) {
				filledLocalizations.push(localization);
			}
		});

		if (!filledLocalizations.length) {
			this.lioModalService.show('No localizations to export');
			return;
		}
		if (!this.workerService.export(filledLocalizations, headers, name)) {
			this.feedbackService.setError('failedToExportRecords');
		}
	}


	/* 
	 * On File Added
	*/
	fileAdded(event) {
		this.filePath = event.filePath;
		this.preprocess();
	}


	/*
	 * File preprocess / validation
	*/
	preprocess() {
		this.lmsService.postAsync('importlocalization/preprocess', {'filePath': this.filePath, 'langs' : this.langs, 'global': this.global},
		'processing').then((result) => {
			var token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handlePreprocessResults(result);
				});
			} else {
				this.handlePreprocessResults(result);
			}
		});
	}


	/*
	 * File process
	*/
	process() {
		this.lmsService.postAsync('importlocalization/process', {'filePath': this.filePath, 'langs' : this.langs, 'global': this.global},
		'processing').then((result) => {
			var token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleProcessResults(result);
				});
			} else {
				this.handleProcessResults(result);
			}
		});
	}


	/*
	 * Handles process results
	*/
	handleProcessResults(result) {
		this.lioModalService.hideLoading();

		if (!result.success || !result.properties) {
			return;
		}

		let properties 		= result.properties;
		let totalToProcess 	= properties.totalToProcess;
		let totalAdded 		= properties.totalAdded;
		let totalEdited 	= properties.totalEdited;
		let totalFailed 	= properties.totalFailed;
		let cancelled 		= properties.cancelled;
		let errors 			= properties.errors;
		let messages 		= [];
		let macros 			= [];


		if (cancelled) {
			return;
		}

		if (!totalToProcess) {
			return;
		}

		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('localizationsSuccessfullyInserted', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'totalUpdated', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('localizationsSuccessfullyUpdated', macros));
		}

		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('localizationsUnexpectedIssues', macros));
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);

		this.navService.changedForm = false;
		this.readyToProcess = false;
		this.localizationItems = [];
		this.getLocalizationItems(true);
	}


	/*
	 * Handles pre-process results
	*/
	handlePreprocessResults(result) {
		this.lioModalService.hideLoading();

		if (!result.success || !result.properties) {
			return;
		}
		let properties 		= result.properties;
		let totalToProcess 	= properties.totalToProcess;
		let totalAdded 		= properties.totalAdded;
		let totalEdited 	= properties.totalEdited;
		let totalFailed 	= properties.totalFailed;
		let cancelled 		= properties.cancelled;
		let errors 			= properties.errors;
		let isValidFile 	= true;
		let messages 		= [];
		let macros 			= [];

		if (cancelled) {
			return;
		}

		if (!totalToProcess) {
			return;
		}
		macros.push({'key': 'numberToProcess', 'value': totalToProcess});
		messages.push(this.utilService.localizeMessage('totalLocalizationsDetected', macros));

		if (totalAdded) {
			macros.push({'key': 'totalToBeAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalLocalizationsWillBeAdded', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'numberToBeUpdated', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('totalLocalizationsWillBeUpdated', macros));
		}


		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalLocalizationsIssues', macros));
			isValidFile = false;
		} else {
			this.readyToProcess = true;
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);

		if (isValidFile) {
			this.navService.changedForm = true;
		}
	}
}