import { Component, Inject, Input, Output, ViewChild, ElementRef } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { lmsService } from '../../../../services/lms.service';

@Component({
	selector	: 'lio-catalog-pdf',
	templateUrl	: './catalog-pdf.component.html',
	styleUrls	: ['./catalog-pdf.component.css']
})
export class LioCatalogPDF{
	@ViewChild('pdfTarget') public pdfTarget:ElementRef;

	private _catalogs:Array<any> = [];
	public get catalogs():Array<any>{
		return this._catalogs; 
	}
	@Input() public set catalogs(val:Array<any>){
		this.readyToRender.next({
			ready	:false,
			target	:this.pdfTarget
		});
		this._catalogs = val;

		this.formatCatalogs();
		this.render();
	}

	@Output() public readyToRender:ReplaySubject<any> = new ReplaySubject(1);

	public courseTypes		:Array<any> 					= [];
	public combinedTOCs		:Array<any>						= [];
	public combinedCatalogs	:Array<any>						= [];

	public localeStrings	:any 							= {
		coverTitle 				: 'Compliance<br>Course Catalog',
		coverTitleTrans 		: 'catalogpdf.coverTitle',
		tocHeader				: 'True Office Learning',
		tocHeaderTrans			: 'catalogpdf.tocHeader',
		page					: 'Page',
		pageTrans				: 'catalogpdf.page',
		courseID				: 'Course ID',
		courseIDTrans			: 'catalogpdf.courseID',
		courseName				: 'Course Name',
		courseNameTrans			: 'catalogpdf.courseName',
		courseType				: 'Course Type',
		courseTypeTrans			: 'catalogpdf.courseType',
		type					:'TYPE:',
		courseDescription 		: 'COURSE DESCRIPTION:',
		courseDescriptionTrans 	: 'catalogpdf.courseDescription',
		typeTrans				:'catalogpdf.type',
		length					:'LENGTH:',
		lengthTrans				:'catalogpdf.length',
		topicsCovered			:'TOPICS COVERED:',
		topicsCoveredTrans		:'catalogpdf.topicsCovered',
		more					:' more...',
		moreTrans				:'catalogpdf.more',
		availableLangs			:'AVAILABLE LANGUAGES:',
		availableLangsTrans		:'catalogpdf.availableLangs',
		format					:'FORMAT:',
		formatTrans				:'catalogpdf.format',
		footerTop				: `<h5 class="headline-text font-weight-bold">About True Office Learning</h5>
		<h3 class="body-copy">From compliance to custom, we have solutions to transform your business.</h3>
		
		<h3 class="body-copy">True Office Learning is a leading enterprise education and analytics company that connects e-learning to advanced behavioral
			data through an adaptive technology platform to drive results</h3>
		
		<h3 class="body-copy">The company’s cloud-based software provides organizational insights that were previously immeasurable, enabling organizations
			to ensure learning, analyze efficacy, and drive better business outcomes.</h3>`,
		footerTopTrans			: 'catalogpdf.footerTop',
		footerBottom			: `<div class="small">True Office Learning, Inc. owns and maintains the copyright and intellectual property in the materials presented in this
		catalog. Any unauthorized use or reproduction of this material is prohibited. © Copyright 2019 True Office Learning, Inc.
		All rights reserved.</div>
	<br>
	<div class="small">No part of this publication may be reproduced, transmitted, transcribed, stored in a retrieval system, or translated into
		any language, in any form, by any means electronic, mechanical, photocopied, recorded, or otherwise, without prior written
		permission from True Office Learning. All copyright, confidential information, patents, design rights and all other intellectual
		property rights of whatsoever nature contained herein are and shall be the sole and exclusive property of True Office
		Learning, Inc. The information furnished herein is believed to be accurate and reliable.</div>`,
		footerBottomTrans		: 'catalogpdf.footerBottom',
	};

	constructor(
		@Inject(debugService) 	public debugService :debugService,
		@Inject(lmsService) 	public lmsService 	:lmsService,
	){
		this.debugService.register('catalogpdf', this);

		this.getCourseTypes();
	}

	// Formats each catalog for a specific task
	formatCatalogs() {
		this.sortBy(this.catalogs, 'name');
		this.sortBy(this.catalogs, 'type');

		this.catalogs.forEach((catalog, key) => {
			this.checkAvailability(catalog['availableDate'], key);
			this.formatDates(catalog['availableDate'], key);
			this.formatUpdates(catalog['updates'], key);
			this.setOrder(catalog, key);
		});
	}

	// Sorts an array by a key in alphabetical order
	sortBy(array, key) {
		array.sort((a, b) => {
			if (!a[key] || !b[key]) {
				return 0;
			}
			var nameA = a[key].toLowerCase(),
				nameB = b[key].toLowerCase();

			if (nameA < nameB) {
				return -1;
			}

			if (nameA > nameB) {
				return 1;
			}

			return 0;
		});
	}

	/*
	 * Set the Table of contents
	*/
	combineTOCs() {
		let page 			= 0;
		let count 			= 0;
		let itemsPerTOC 	= 45;
		this.combinedTOCs 	= [];

		// Sort by page nunber
		this.catalogs.sort((a,b)=>{
			return a.pageNumber - b.pageNumber;
		});

		this.catalogs.forEach((catalog) => {
			// Combine the TOCs
			if (!this.combinedTOCs[page]) {
				this.combinedTOCs[page] = {catalogs : [catalog]};
			} else {
				this.combinedTOCs[page]['catalogs'].push(catalog);
			}

			this.combinedTOCs[page]['type'] = catalog.type; 
			count++;

			if (count > 0 && count % itemsPerTOC === 0) {
				page++;
			}
		});
		
	}

	/*
	 * Combine Catalogs by type and adds the page number
	*/
	combineCatalogsByType() {
		let page 		= 1;
		let catalogs 	= [];

		this.courseTypes.forEach((courseType) => {
			let type			= courseType.name;
			let combinedCatalog = {
				pages 		: [], 
				type 		: courseType,
				pageNumber 	: 0
			};

			this.catalogs.forEach((catalog) => {
				if (catalog.type === type) {
					if (!combinedCatalog['pages'][page]) {
						combinedCatalog['pages'][page] = [catalog];
					} else {
						combinedCatalog['pages'][page].push(catalog);
					}
					catalog['pageNumber'] = page;
					courseType['used'] = true;
					page++;
				}
			});
			catalogs.push(combinedCatalog);
		});

		this.combinedCatalogs = catalogs;
	}

	render(){
		if(this.courseTypes && this.catalogs){
			this.combineCatalogsByType();
			this.combineTOCs();
			this.readyToRender.next({
				ready	:true,
				target	:this.pdfTarget
			});
		}
	}

	/*
	 * Gets the course types
	 * @param {function} callback
	*/
	getCourseTypes() {
		return this.lmsService.get('catalog/getAllCourseTypes').then((result) => {
			this.courseTypes = result.properties.courseTypes;
			this.render();
		});
	}

	/*
	 * Set the order param
	 * @param {array} catalog
	 * @param {string} key
	*/
	setOrder(catalog, key) {
		if (catalog.isAvailable) {
			catalog.order = '0' + catalog.courseID;	
		} else {
			catalog.order = '1' + catalog.dateAvailable;
		}
		this.catalogs[key] = catalog;
	}

	/*
	 * Formats date string to a js date
	 * @param {array} topics
	 * @param {string} key
	*/
	formatDates(date, key) {
		date = date ? date.split("-").join("/") : null;
		var dateOut = new Date(date);
		
		if (isNaN(dateOut.getDay())) {
			dateOut = null;
		}
		this.catalogs[key]['dateAvailable'] = dateOut;
	}

	/*
	 * Formats update date string to a js date
	 * @param {array} updates
	 * @param {string} key
	*/
	formatUpdates(updates, key) {
		updates.forEach((update, updateKey) => {
			var dateOut = new Date(update['date']);
			this.catalogs[key]['updates'][updateKey]['date'] = dateOut;
		});
	}

	/*
	 * Checks availability of a catalog
	 * @param {string} futureDate
	 * @param {string} key
	*/
	checkAvailability(futureDate, key) {
		var date1 = new Date(),
			date2 = new Date(futureDate),
			isAvailable = true;

			// Verify date difference
			if (futureDate && date2 > date1) {
				isAvailable = false;
			}
		this.catalogs[key]['isAvailable']  = isAvailable;
		this.catalogs[key]['isUnavailable']  = !isAvailable;
	}

	/*
	 * Gets all course IDs
	 * @return {array}
	*/
	getIds() {
		var ids = [];
		// Get all catalog IDs
		this.catalogs.forEach((catalog) => {
			ids.push(catalog['courseID']);
		});

		return ids;
	}
}