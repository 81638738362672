/**
 * Wraps the Angular material datepicker so that it can be used in AngularJS templates
 * - Template reference variables are the feature that prevents the material datepicker from working as a downgraded component
 */
import { Component, Input, Inject, Output, EventEmitter, DoCheck } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import moment from 'moment';

import { localizationService } from '../../../../services/localization.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { debugService } from '../../../../services/debug.service';
import { utilService } from '../../../../services/util.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'lio-mat-wrap-date-field',
	templateUrl: './date-field.component.html',
})
export class LioMatWrapDateField implements DoCheck {
	private _sm						: boolean;
	private _format					: string = 'MM/DD/YYYY';

	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();

	@Input() public formGroup		: FormGroup 				= new FormGroup({});
	@Input() public formControlRef	: FormControl 				= new FormControl();
	@Input() public amID			: string 					= '';
	@Input() public disabled		: boolean 					= false;
	@Input() public placeholder		: string 					= '';
	@Input() public required		: boolean 					= false;
	@Input() public showLabel		: boolean 					= true;
	@Input() public appearance		: MatFormFieldAppearance 	= 'fill';
	@Input() public noFormFieldPB	: boolean 					= false;
	@Input() public model			: any 						= {};

	public _date 					: moment.Moment | null;

	@Input()
	get sm(): boolean {
		return this._sm;
	}
	set sm(val: boolean) {
		this._sm = coerceBooleanProperty(val);
	}

	@Input() minDate:Date = null;
	@Input() maxDate:Date = null;
	public minDateMoment(): moment.Moment {
		return moment(this.minDate, this._format);
	} 
	public maxDateMoment(): moment.Moment {
		return moment(this.maxDate, this._format);
	} 

	private _field:any = {};
	public get field() {
		return this._field;
	}
	@Input() public set field(val: any) {
		this._field = val;

		setTimeout(() => {
			if(!this.formGroup.controls[this.field.model]){
				this.formGroup.addControl(this.field.model, new FormControl());
			}

		});

		this.setInternalDate();
	}

	constructor(
		@Inject(debugService) 			public debugService:debugService,
		@Inject(lioLogService) 			public lioLogService:lioLogService,
		@Inject(localizationService) 	public localizationService:localizationService,
		@Inject(utilService) 			public utilService:utilService
	) {
		this.debugService.register('dates', this, true);
		this.debugService.register('moment', moment, true);
	}

	private _oldModel:any;
	ngDoCheck(){
		if(!this.utilService.deepObjectEquals(this._oldModel, this.model)){
			this._oldModel = this.utilService.deepCopy(this.model);
			this.setInternalDate();
			this._format = this.localizationService.getDateFormat().toUpperCase();
		}

		//matches form control disabled state to the field
		let control = this.formGroup.controls[this.field.model];
		if(control && control.disabled != this.field.locked){
			if(this.field.locked){
				control.disable();
			}else{
				control.enable();
			}
		}
	}

	/**
	 * Sets the internal moment for the model
	 */
	setInternalDate() {
		if (this.model && this.field && (this.field.model || this.field.model === 0)) {
			let date = this.model[this.field.model];
			if (moment.isMoment(date)) {
				this._date = date;
			} else if (date) {
				this._date = this.formatDate(date);
			}
		}
	}

	/**
	 * Attempts to format the date string to a valid moment date
	 * @param {string} date
	 * @return {moment}
	 */
	formatDate(date) {
		let momentDate = moment(date, 'MM/DD/YYYY');
		if (momentDate.isValid()) {
			return momentDate;
		}

		let jsDate = new Date(date),
			year = jsDate.getFullYear(),
			month = jsDate.getMonth() + 1,
			day = jsDate.getDate(),
			monthString = '',
			dayString = '',
			formattedDate = '';

		if (isNaN(year)) {
			return momentDate;
		}

		monthString  = month < 10 ? '0' + month.toString() : month.toString();
		dayString  = day < 10 ? '0' + day.toString() : day.toString();
		formattedDate = monthString + '/' + dayString + '/' + year;

		momentDate = moment(formattedDate, 'MM/DD/YYYY');
		if (momentDate.isValid()) {
			return momentDate;
		}

		this.lioLogService.log(['Invalid Date', date, this.field.model]);					
		return date;
	}

	onChange() {
		if (this._date) {
			this.model[this.field.model] = this._date.format(this._format);
			this.onUpdate.emit(this.field);
		}
	}

	blackoutFilter = (date: moment.Moment | null): boolean => {
		if (this.field && this.field.blackoutDates) {
			return this.field.blackoutDates(date, this.model);
		}
		return true;
	};
}