import { Component, Inject, Input, Output, EventEmitter, DoCheck, OnDestroy } from '@angular/core';

import { localizationService } from '../../../../../services/localization.service';
import { lioLogService } from '../../../../../services/lio-log.service';
import { debugService } from '../../../../../services/debug.service';
import { lioModalService } from '../../../../../services/lio-modal.service';
import { stateService } from '../../../../../services/state.service';
import { permissionService } from '../../../../../services/permissions.service';
import { utilService } from '../../../../../services/util.service';

import { LioSearchableSelectField } from '../../../lio-forms.models';
import { FormControl, FormGroup } from '@angular/forms';

/** Directive that handles query rules, which are sets of 3 inputs used to set filters on a query
 * @param {object} field settings for an input
 * 	@param {array} filters *optional* a list of options for the first input
 * @param {object} rule the data model to be manipulated
 * @param {function} deleteFunction *optional* if included, a delete button is shown that will call this function
 */
@Component({
	selector: 'lio-query-rule',
	templateUrl: './query-rule.component.html'
})
export class LioQueryRule implements DoCheck, OnDestroy {
	private _formGroup : FormGroup;
	public get formGroup(){
		return this._formGroup;
	}
	@Input() public set formGroup(val:FormGroup){
		this._formGroup = val;
		val.addControl('form_rule_' + this.ruleIndex + '_group', this.ruleGroup);
	}

	public ruleGroup		:FormGroup 		= new FormGroup({});
	public filterControl 	:FormControl 	= new FormControl();
	public operatorControl 	:FormControl 	= new FormControl();
	public valueControl 	:FormControl 	= new FormControl();
	public value1Control 	:FormControl 	= new FormControl();
	public value2Control 	:FormControl 	= new FormControl();

	@Output() delete		: EventEmitter<any> = new EventEmitter();
	@Output() updatedRule	: EventEmitter<any> = new EventEmitter();

	private _revalidate : EventEmitter<any> = null;
	public get revalidate(){
		return this._revalidate;
	}
	@Input() public set revalidate(val: EventEmitter<any>){
		this._revalidate = val;
		this.revalidate.subscribe(() => {
			this.validate();
		});
	}

	@Input() appearance?	:any = 'outline';
	@Input() hideDelete		:any;
	@Input() ruleIndex?		:number;

	private _rule :any;
	get rule(){
		return this._rule;
	}
	@Input() set rule(value: any) {   
		this._rule = value;
 
		if (!this.rule) {
			this.lioLogService.log('No rule');
			return;
		}
		this.setFilter();
		this.setOperator();
		this.convertValues();
		this.setFilterInput();
	}

	private _filters :any;
	get filters(){
		return this._filters;
	}
	@Input() set filters(value: any) {   
		this._filters 				= value;
		this.filterField.options 	= value;
	}

	private _filter :any = {};
	get filter(){
		return this._filter;
	}
	set filter(value: any) {   
		this._filter 						= value;
		this.operatorField.options 			= value.operators;
		this.valuesField.options 			= value.options;
		this.valuesField.searchable 		= value.searchable;
		this.valuesField.clearable 			= value.clearable;
		this.valuesField.optionNameField 	= value.optionNameField || 'name';
		this.valuesField.optionValueField 	= value.optionValueField;

		this.rule.required 		= this.filter.required;
		this.filterField.locked = this.filter.required;
	}

	public operator	:any = {};

	public filterField:LioSearchableSelectField = new LioSearchableSelectField({
		model 			: 'field',
		options 		: [],
		backgroundColor	: 'white',
		optionValueField: 'model'
	});

	public operatorField:LioSearchableSelectField = new LioSearchableSelectField({
		model 			: 'operator',
		options 		: [],
		backgroundColor	: 'white'
	});

	public valuesField:LioSearchableSelectField = new LioSearchableSelectField({
		model 			: 'value',
		options 		: [],
		backgroundColor	: 'white',
	});

	constructor(
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(stateService) 			public stateService 		:stateService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(lioModalService)		public lioModalService 		:lioModalService
	){
		this.debugService.register('queryRule', this);

		this.ruleGroup.addControl('field', this.filterControl);
		this.ruleGroup.addControl('operator', this.operatorControl);
		this.ruleGroup.addControl('value', this.valueControl);
		this.ruleGroup.addControl('0', this.value1Control);
		this.ruleGroup.addControl('1', this.value2Control);
	}

	ngOnDestroy(){
		this.formGroup.removeControl('form_rule_' + this.ruleIndex + '_group');
	}

	ngDoCheck(){
		if(this.filter.operators != this.operatorField.options){
			this.operatorField.options = this.filter.operators;

			this.setOperator();
			this.convertValues();
		}
	
		this.syncControlDisabledState(this.filterControl);
		this.syncControlDisabledState(this.operatorControl);
		this.syncControlDisabledState(this.valueControl);
		this.syncControlDisabledState(this.value1Control);
		this.syncControlDisabledState(this.value2Control);
	}

	syncControlDisabledState(control){
		if(control.disabled != this.rule.disabled){
			if(this.rule.disabled){
				control.disable();
			}else{
				control.enable();
			}
		}
	}

	/*
	 * Sets the current filter vased on the rule
	 * @return {boolean}
	*/
	setFilter() {
		let set = false;
		this.filterField.options.forEach((filter) => {
			if (this.rule.field === filter.field) {
				this.filter = filter;
				set = true;
			}
		});

		if (!set) {
			this.filter = this.filterField.options[0];
			this.rule.error = true;
			this.rule.value = null;
			this.lioModalService.show('error.filterPermissionNotAllowed');
		}
	}

	/*
	 * Sets the current operator vased on the rule
	*/
	setOperator() {
		let matched = false;
		if(this.operatorField.options){
			this.operatorField.options.forEach((operator) => {
				if (this.rule.operator === operator.value) {
					this.operator = operator;
					matched = true;
				}
			});

			if (!matched) {
				this.lioLogService.log(['Did not match operator, setting to first available', this.rule.operator]);
				this.operator = this.operatorField.options[0];
				this.rule.operator = this.operator.value;
			}
		}
	}

	/*
	 * Converts values bases on the filter/operator
	*/
	convertValues() {
		// Convert the value to an array/string depending on the type of operator 
		if (this.operator.multiple) {
			if (!Array.isArray(this.rule.value)) {
				this.rule.value = [this.rule.value];
			}
		} else if (!this.filter.multiple) {
			if (Array.isArray(this.rule.value)) {
				this.rule.value = '';
			}
		}

		// Handle null operators
		if (this.operator.setNull) {
			this.rule.value = null;
			this.rule.disabled = true;
		} else {
			this.rule.disabled = false;
		}

		// Handle integer conversions
		if (this.filter.type == 'integer') {
			this.rule.value = Number(this.rule.value);
		}

		// Match to closest option (generally a string/number issue)
		if (this.valuesField.options && typeof this.rule.value == "string") {
			this.valuesField.options.forEach((value) => {
				if (value.locked === true || this.rule.missingValue || this.utilService.isString(value.locked) && !this.permissionService.hasPermission(value.locked)) {
					return;
				} else if (this.rule.value == value.value) {
					this.rule.value = value.value;
				}
			});
		}
	}

	/*
	 * On update of the filter
	*/
	updatedFilter() {
		this.setFilter();
		// Set the current operator to the first operator available 
		this.rule.operator = this.operatorField.options[0].value;
		this.rule.value = null;
		this.setOperator();
		this.convertValues();
		this.updatedRule.emit(this.rule);
	}

	/*
	 * Sets the second filter input based on the option selected
	*/
	setFilterInput() {
		if (this.valuesField.options) {
			this.valuesField.options.forEach((option) => {
				if (option.value == this.rule.value) {
					if (option.addInput) {
						this.rule.has2ndInput = true;
					} else {
						this.rule.has2ndInput = false;
					}
				}
			});
		}

		// Remove value 2 if second input is not set
		if (!this.rule.has2ndInput) {
			this.rule.value2 = null;	
		}
	}

	/**
	 * On update of the operator
	 */
	updatedOperator() {
		this.setOperator();
		this.convertValues();
		this.updatedRule.emit(this.rule);
	}

	/**
	 * On Update of the Value
	 */
	updatedValue() {
		this.rule.error = false;
		this.setFilterInput();
		this.validate();
		this.updatedRule.emit(this.rule);
	}


	/**
	 * Validate values
	 */
	validate() {
		let rule = this.rule;
		// Validate Dates
		if (this.filter.type == 'date') {
			if (rule.operator.indexOf('between') > -1 && Array.isArray(rule.value)) {
				if (!rule.value[0] || !rule.value[1] || rule.value[0] == 'Invalid date' || rule.value[1] == 'Invalid date') {
					rule.error = true;
				}
			} else if (rule.value === '' || rule.value === null || rule.value == 'Invalid date') {
				rule.error = true;
			}
		} else if (!this.operator.setNull && (rule.value === '' || rule.value === null)) {
			rule.error = true;
		}
	}

	/**
	 * Tell the parent to delete this rule 
	 */
	deleteRule() {
		this.delete.emit(this.rule);
		this.updatedRule.emit(this.rule);

	}
}