<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys" [macros]="localeStringsMacros">
	<div class="col-md-10 offset-md-1">
		<form role="form" autocomplete="off" novalidate>
			<lio-card headerId="am_assign_header" [headerText]="localeStrings.title" [showClose]="true">
				<div lio-card-body>
					<lio-form-inputs
						[fields]="formFields"
						[fieldConfig]="formFieldConfig"
						[model]="model"
						(onChanged)="onUpdate($event)">
					</lio-form-inputs>

					<div class="btn-toolbar">
						<button id="am_assign_enroll" [disabled]="!allowEnroll || blockAdditionalEnrollments" (click)="enroll()" class="btn btn-sm btn-primary mr-1 mb-1" type="submit"><strong localize [model]="localizations" item="enrollUsers">{{localizations.enrollUsers}}</strong></button>
						<button id="am_assign_email" *ngIf="permissionService.hasPermission('enrollment.email')" [hidden]="!enrolledUsers" (click)="sendEmailToEnrolled()" class="btn btn-sm btn-primary mr-1 mb-1" type="submit"><strong localize [model]="localizations" item="emailEnrolled">{{localizations.emailEnrolled}}</strong></button>
						<button id="am_assign_unenroll" *ngIf="permissionService.hasPermission('enrollment.unenroll')" [disabled]="!allowUnEnroll" (click)="unenroll()" class="btn btn-sm btn-primary mb-1" type="submit"><strong localize [model]="localizations" item="unenrollUsers">{{localizations.unenrollUsers}}</strong></button>
					</div>
				</div>
			<div lio-card-footer>
				<div [hidden]="!filteredEmployees.length">
					<div lio-paginator controlID="assign" [collection]="employees" [filters]="search" [settings]="pagination" (onResults)="updateFilteredEmployees($event)">
						<lio-results-table
							paginatorID="assign"
							[fields]="fields"
							[fieldConfig]="fieldConfig"
							[results]="filteredEmployees">
						</lio-results-table>
					</div>
					<div>
						<lio-paginator-controls class="row" controlID="assign"></lio-paginator-controls>
					</div>
				</div>
			</div>
		</lio-card>
		</form>
	</div>
</div>