import { Injectable } from '@angular/core';

import { LioSearchableSelectField, LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: changePassSettings
})
export class changePassSettings {

	langIDField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'am_language_select',
		name			: 'Language',
		nameTrans		: 'form.langID',
		model			: 'code',
		addEmptyOption	: true,
		searchable 		: true,
		optionNameField : 'lang',
		optionValueField: 'code'
	});

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Current Password',
			'nameTrans': 'changepass.currentPassword',
			'model': 'currentPassword',
			'type': 'password',
			'required': true,
		},
		{
			'name': 'New Password',
			'nameTrans': 'changepass.password',
			'model': 'password',
			'type': 'password',
			'required': true,
			'min': 8,
		},
		{
			'name': 'Re-enter Password',
			'nameTrans': 'changepass.password2',
			'model': 'password2',
			'type': 'password',
			'required': true,
			'min': 8,
		},
	);
}