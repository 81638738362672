import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../../../services/loader.service';
import { navService } from '../../../../services/nav.service';
import { settingsService } from '../../../../services/settings.service';
import { permissionService } from '../../../../services/permissions.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { storageService } from '../../../../services/storage.service';
import { workerService } from '../../../../services/worker.service';
import { stateService } from '../../../../services/state.service';
import { passwordResetService } from '../../../../services/password-reset.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { loginService } from '../../../../services/login.service';
import { debugService } from '../../../../services/debug.service';

import { userSummarySettings } from '../../../../components/user-summary/user-summary.settings';
import { localizationService } from '../../../../services/localization.service';

@Component({
	selector: 'lio-course-history',
	templateUrl: './course-history.component.html'
})
export class LioCourseHistory implements OnDestroy {

	public fields					:Array<any>		= this.utilService.copy(this.userSummarySettings.fields);
	public searchConfig				:any			= this.utilService.copy(this.userSummarySettings.searchConfig);
	public searches					:Array<any>		= this.utilService.copy(this.userSummarySettings.searches);
	public search					:any 			= {};
	
	public courseHistoryFields		:Array<any>		= this.utilService.copy(this.userSummarySettings.courseHistoryFields);
	public courseHistoryPagination	:any 			= this.utilService.copy(this.userSummarySettings.courseHistoryPagination);

	public employee					:any 			= {};
	public filteredCourseHistory	:Array<any>		= [];
	public courseHistory			:Array<any>		= [];

	private UID						:string 		= '';
	
	private subscriptions			:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(settingsService) 		public settingsService		:settingsService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(formValidatorService) 	public formValidatorService	:formValidatorService,
		@Inject(lmsService) 			public lmsService			:lmsService,
		@Inject(lioModalService) 		public lioModalService		:lioModalService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(workerService) 			public workerService		:workerService,
		@Inject(stateService) 			public stateService			:stateService,
		@Inject(passwordResetService) 	public passwordResetService	:passwordResetService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(feedbackService) 		public feedbackService		:feedbackService,
		@Inject(loginService) 			public loginService			:loginService,
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(userSummarySettings) 	public userSummarySettings	:userSummarySettings,
	)
	{
		this.debugService.register('coursehistory', this);

		/*
		* Init
		*/
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				if (!this.getEmployee()) {
					this.navService.goto('home');
					return;
				}
				this.getCourseHistory();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initializes
    */
	initForm() {
		this.navService.displayPage();
	}


	updateCourseHistory(collection) {
		this.filteredCourseHistory = collection.filtered;
	}

	

	/*
	 * Gets the employee from storage
    */
	getEmployee() {
		this.UID = this.storageService.get('UID');
		return this.UID ? true : false;
	}

	/*
	 * Gets the user summary
    */
	getCourseHistory() {
		this.lmsService.post('userSummary/getCourseHistory', {'UID': this.UID}).then((result) => {
			if (result.properties.courseHistory) {
				this.courseHistory = result.properties.courseHistory;
			}
		});
	}

	/*
	 * Exports the records
	*/
	exportRecords() {
		if (!this.workerService.export(this.courseHistory, this.fields, 'Course History')) {
			this.lioModalService.show('Failed to export course history');
		}
	}
}