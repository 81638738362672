<div [hidden]="!showLoginScreen" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="row">
		<div class="col-md-6 mx-auto">
			<lio-card headerClass="login-robot-container">
				<div lio-card-header>
					<img alt="Login Logo" src="images/assets/LearnIO.png" class="login-robot"/>
				</div>
				<div lio-card-body>
					<form id="am_login_form" class="lio-login-form" role="form" 
						(keyup.enter)="login()"
						[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
						<mat-form-field [appearance]="appearance" class="w-100">
							<mat-label>{{localeStrings.username}}</mat-label>
							<input matInput
								id="am_login_employeeID" 
								name="employeeID"
								[(ngModel)]="model.employeeID" 
								type="text" 
								required>
						</mat-form-field>
						<mat-form-field [appearance]="appearance" class="w-100">
							<mat-label>{{localeStrings.password}}</mat-label>
							<input matInput
								id="am_login_password" 
								name="password"
								[(ngModel)]="model.password" 
								type="password" 
								required>
						</mat-form-field>
						<mat-form-field [appearance]="appearance" class="w-100" [hidden]="!displayCompanyID">
							<mat-label>{{localeStrings.company}}</mat-label>
							<input matInput 
								id="am_login_companyID" 
								name="companyID"
								[(ngModel)]="model.companyID" 
								type="text" 
								required>
						</mat-form-field>
					</form>
				</div>
				
				<div lio-card-footer>
					<div class="row no-gutters">
						<button id="am_login_submit" 
							mat-flat-button
							color="primary"
							(click)="login()" 
							type="submit">

							<strong>{{localeStrings.login}}</strong>
						</button>
						<button id="am_login_register" 
							mat-flat-button
							color="primary"
							class="ml-auto"
							[hidden]="!settingsService.getSetting('canSelfRegister')" 
							(click)="gotoRegister()"
							type="button">
							
							<strong>{{localeStrings.register}}</strong>
						</button>
						<button id="am_login_forgot" 
							mat-flat-button
							color="primary"
							class="ml-1"
							(click)="navService.goto('forgotpass')"
							type="button">

							<span>{{localeStrings.forgotPassword}}</span>
						</button>
					</div>
				</div>
			</lio-card>
		</div>
	</div>
</div>