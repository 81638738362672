/*
 * Service for interacting with the Dom
*/
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { browserService } from './browser.service';
import { debugService } from './debug.service';
import { localizationService } from './localization.service';

@Injectable({
	providedIn: 'root',
})
export class domService {

	private allowScroll:boolean 			= true;

	constructor(
		@Inject(debugService) 			private debugService		:debugService,
		@Inject(browserService) 		private browserService		:browserService,
		@Inject(localizationService) 	private localizationService		:localizationService,
		@Inject(DOCUMENT) 				private document			:Document)
	{
		this.debugService.register('dom', this);

		this.localizationService.localized.subscribe(() => {
			this.setLangID();
		});
	}

	/*
	* Gets inputs
	*/
	getInputs() {
		return this.document.getElementsByTagName('input');
	}


	/*
	* Scrolls to the top of the page
	* @return {Promise}
	*/
	scrollToTop() {
		return new Promise((resolve) => {
			if (!this.allowScroll) {
				resolve(true);
				return;
			}
			this.document.defaultView.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			setTimeout(() => {
				resolve(true);
			}, 800);
			return;
		});
	}


	/*
	* Focuses on an element by id
	* @param {string} elementID
	*/
	focusOn(elementID) {
		let el = this.getEl(elementID);
		if (el) {
			el.focus();
		}
	}


	/*
	 * Set the lang ID to the HTML tag
	*/
	setLangID() {
		this.document.documentElement.lang = this.localizationService.getLangID();
	}


	/*
	* Scrolls to the bottom of the page
	*/
	scrollToBottom() {
		if (!this.allowScroll) {
			return;
		}
		setTimeout(() => {
			this.document.defaultView.scrollTo({
				top: this.document.body.scrollHeight,
				left: 0,
				behavior: 'smooth'
			});
		}, 100);
	}


	/*
	* Gets an element by id
	* @param {string} elementID
	*/
	getEl(elementID) {
		return this.document.getElementById(elementID);
	}


	/*
	* Scrolls to an element by id
	* @param {string} elementID
	*/
	scrollTo(elementID) {
		if (!this.allowScroll) {
			return;
		}
		let el = this.getEl(elementID);
		
		if (!el) {
			return;
		}

		this.document.defaultView.scrollTo({
			top: el.offsetTop,
			left: 0,
			behavior: 'smooth'
		});
	}

	/*
	* Refreshes the spinner due to IE being IE
	*/
	refreshSpinner() {
		if (!this.browserService.isIE) {
			return;
		}

		let el = this.getEl('spinner');
		if (el) {
			el.className = 'fa fa-spinner fa-3x';
			setTimeout(() => {
				el.className = 'fa fa-spinner fa-spin fa-3x';
			});
		}
	}


}