import { AngularMaterialModule } from './../../imports/angular-material';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LioDirectivesModule } from './../../directives/directives.module';
import { LioDataDisplayModule } from './../../modules/data-display/data-display.module';
import { LioFormsModule } from './../../modules/lio-forms/lio-forms.module';
import { LioPipesModule } from './../../pipes/pipes.module';

import { LIONavbar } from './components/navbar/navbar.component';
export { LIONavbar } from './components/navbar/navbar.component';

import { LIOTitleComponent } from './components/title/lio-title.component';
export { LIOTitleComponent } from './components/title/lio-title.component';

import { LIOCard } from './components/card/card.component';
export { LIOCard } from './components/card/card.component';

import { LIOCardButton } from './components/card-button/card-button.component';
export { LIOCardButton } from './components/card-button/card-button.component';

import { LioEmailEditor } from './components/email-editor/email-editor.component';
export { LioEmailEditor } from './components/email-editor/email-editor.component';

import { LioEventList } from './components/event-list/event-list.component';
export { LioEventList } from './components/event-list/event-list.component';

import { LioEventHistory } from './components/event-history/event-history.component';
export { LioEventHistory } from './components/event-history/event-history.component';

import { LioCriticalError } from './components/critical-error/critical-error.component';
export { LioCriticalError } from './components/critical-error/critical-error.component';

import { LioFieldEditor } from './components/field-editor/field-editor.component';
export { LioFieldEditor } from './components/field-editor/field-editor.component';

import { LioFilterEditor } from './components/filter-editor/filter-editor.component';
export { LioFilterEditor } from './components/filter-editor/filter-editor.component';

import { LioPresetsEditor } from './components/presets-editor/presets-editor.component';
export { LioPresetsEditor } from './components/presets-editor/presets-editor.component';

import { LioPanelTabber } from './components/panel-tabber/panel-tabber.component';
export { LioPanelTabber } from './components/panel-tabber/panel-tabber.component';

import { LioPanelTab } from './components/panel-tabber/panel-tab.component';
export { LioPanelTab } from './components/panel-tabber/panel-tab.component';

import { LioPanelTabContent } from './components/panel-tabber/panel-tab-content.directive';
export { LioPanelTabContent } from './components/panel-tabber/panel-tab-content.directive';

import { LioDisclosurePDF } from './components/disclosure-pdf/disclosure-pdf.component';
export { LioDisclosurePDF } from './components/disclosure-pdf/disclosure-pdf.component';


import { LioTrainingCourses } from './components/training/training-courses.component';
export { LioTrainingCourses } from './components/training/training-courses.component';


import { LioEnrollments } from './components/enrollments/enrollments.component';
export { LioEnrollments } from './components/enrollments/enrollments.component';


import { LioLoginHistory } from './components/login-history/login-history.component';
export { LioLoginHistory } from './components/login-history/login-history.component';


import { LioProfile } from './components/profile/profile.component';
export { LioProfile } from './components/profile/profile.component';


import { LioCourseHistory } from './components/course-history/course-history.component';
export { LioCourseHistory } from './components/course-history/course-history.component';


import { LioEmailAuditHistory } from './components/email-audit-history/email-audit-history.component';
export { LioEmailAuditHistory } from './components/email-audit-history/email-audit-history.component';

import { LioBatchHistory } from './components/batch-history/batch-history.component';
export { LioBatchHistory } from './components/batch-history/batch-history.component';

import { LioFooter } from './components/footer/footer.component';
export { LioFooter } from './components/footer/footer.component';

import { LioEnrollmentEditor } from './components/enrollment-editor/enrollment-editor.component';
export { LioEnrollmentEditor } from './components/enrollment-editor/enrollment-editor.component';


@NgModule({
	exports: [
		LIONavbar,
		LIOTitleComponent,
		LIOCard,
		LIOCardButton,
		LioEmailEditor,
		LioEventList,
		LioEventHistory,
		LioCriticalError,
		LioFieldEditor,
		LioFilterEditor,
		LioPresetsEditor,
		LioPanelTabber,
		LioPanelTab,
		LioDisclosurePDF,
		LioTrainingCourses,
		LioEnrollments,
		LioLoginHistory,
		LioProfile,
		LioCourseHistory,
		LioEmailAuditHistory,
		LioBatchHistory,
		LioFooter,
		LioEnrollmentEditor,
		LioPanelTabContent
	],
	declarations: [
		LIONavbar,
		LIOTitleComponent,
		LIOCard,
		LIOCardButton,
		LioEmailEditor,
		LioEventList,
		LioEventHistory,
		LioCriticalError,
		LioFieldEditor,
		LioFilterEditor,
		LioPresetsEditor,
		LioPanelTabber,
		LioPanelTab,
		LioDisclosurePDF,
		LioTrainingCourses,
		LioEnrollments,
		LioLoginHistory,
		LioProfile,
		LioCourseHistory,
		LioEmailAuditHistory,
		LioBatchHistory,
		LioFooter,
		LioEnrollmentEditor,
		LioPanelTabContent
	],
	bootstrap: [
		LIONavbar,
		LIOTitleComponent,
		LIOCard,
		LIOCardButton,
		LioEmailEditor,
		LioEventList,
		LioEventHistory,
		LioCriticalError,
		LioFieldEditor,
		LioFilterEditor,
		LioPresetsEditor,
		LioPanelTabber,
		LioPanelTab,
		LioDisclosurePDF,
		LioTrainingCourses,
		LioEnrollments,
		LioLoginHistory,
		LioProfile,
		LioCourseHistory,
		LioEmailAuditHistory,
		LioBatchHistory,
		LioFooter,
		LioEnrollmentEditor
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		AngularMaterialModule,
		FontAwesomeModule,
		CommonModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioFormsModule,
		LioPipesModule
	]
})
export class LioStructuralModule {}