import { Component, Inject, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultModalComponent } from '.././default/default.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import moment from 'moment';

import { utilService } from '../../../../services/util.service';
import { browserService } from '../../../../services/browser.service';

@Component({
  selector: 'app-modal-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})

export class EmailComponent extends DefaultModalComponent implements OnInit {
	@ViewChild("email-frame") frame: ElementRef;

	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public date 	: any;
	public isIE 	: boolean = false;
	public override settings : any = {
		'id' : 'am_modal_email',
		'canClose': true,
		'close': 'Close',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public parentSettings: any,
		@Inject(browserService) 	private browserService: browserService,
		@Inject(utilService) 		private utilService :utilService) {
		super(parentSettings);
		this.settings = Object.assign(this.settings, parentSettings);
		this.date = moment(new Date()).format('MM/DD/YYYY');

	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.settings.message = this.formatForHTML(this.settings.message);
		this.isIE = this.browserService.isIE();
	}

	formatForHTML(html) {
		if (!html) {
			return '';
		}

		if (this.utilService.hasHTML(html)) {
			html = this.utilService.replaceAll(html, '\n', '<br />');
		}

		return html;
	}
}
