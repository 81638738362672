import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { stateService } from '../../services/state.service';
import { processingService } from '../../services/processing.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { feedbackService } from '../../services/feedback.service';
import { fieldService } from '../../services/fields.service';
import { errorsService } from '../../services/errors.service';

import { catalogCollectionSettings } from './catalog-collection.settings';

@Component({
	selector: 'lio-catalog-collection',
	templateUrl: './catalog-collection.component.html'
})
export class CatalogCollectionComponent implements OnDestroy {
	public pagination			:any			= this.catalogCollectionSettings.pagination;
	public settings				:any			= this.catalogCollectionSettings;
	public catalogFields		:Array<any>		= this.catalogCollectionSettings.catalogFields;
	public employeeFieldConfig	:any 			= this.catalogCollectionSettings.employeeFieldConfig;
	public employeeFields		:Array<any>		= this.catalogCollectionSettings.employeeFields;
	public catalogConfig		:any 			= this.catalogCollectionSettings.catalogConfig;
	public employeeConfig		:any 			= this.catalogCollectionSettings.employeeConfig;
	public query				:string 		= '';
	public catalogs				:Array<any>		= [];
	public employees			:Array<any>		= [];
	public filteredCatalogs		:Array<any>		= [];
	public filteredEmployees	:Array<any>		= [];

	public model				:any 			= {
		collection : {
			id			: null,
			name		: null,
			collectionID: null,
			courseIDs	: [],
			employeeIDs	: [],
		}
	};

	public catalogPrefixButtons	:Array<any> 	= [
		{
			header			: 'Select',
			id 				: 'am_results_select',
			color 			: 'primary',
			class 			: 'check-border',
			icon			: 'done',
			disabledIcon 	: '',
			dataField		: 'selected',
			callback 		: (catalog) => {this.select(catalog); },
			disabled   		: false,
		}
	];

	public employeePrefixButtons:Array<any> 	= [
		{
			header			: 'Select',
			id 				: 'am_results_select',
			color 			: 'primary',
			class 			: 'check-border',
			icon			: 'done',
			disabledIcon	: '',
			dataField		: 'selected',
			callback 		: (employee) => { this.selectEmployee(employee); }
		}
	];

	public localeStrings		:any 			= {
		selectAll		:'Select All',
		selectAllTrans	:'global.selectAll',
		clear			:'Clear All',
		clearTrans		:'global.clear'
	};
	public localeStringsKeys	:Array<any> 	= [
		'selectAll',
		'clear'
	];

	private globalOption		:any 			= {'name': 'Global', 'value': 'GLOBAL', 'selected': false};
	private pageDisplayed		:boolean 		= false;
	private catalogCourseIDs	:Array<any>		= [];
	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)					public	navService					:navService,
		@Inject(debugService)				public	debugService				:debugService,
		@Inject(stateService)				public	stateService				:stateService,
		@Inject(processingService)			public	processingService			:processingService,
		@Inject(lmsService)					public	lmsService					:lmsService,
		@Inject(lioModalService)			public	lioModalService				:lioModalService,
		@Inject(feedbackService)			public	feedbackService				:feedbackService,
		@Inject(fieldService)				public	fieldService				:fieldService,
		@Inject(errorsService)				public	errorsService				:errorsService,
		@Inject(catalogCollectionSettings)	public	catalogCollectionSettings	:catalogCollectionSettings
	){
		this.debugService.register('catalogCollection', this);
		this.navService.setActivePage('catalogCollection');

		this.processingService.allowCancel = true;
		this.errorsService.showCriticalErrors = true;
		
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
				this.setCatalogs();
			})
		);
	
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
    */
	init() {
		// Add Global Role to apply to all users
		this.settings.fields.forEach((field) => {
			if (field.model === 'permissionID') {
				field.options.push(this.globalOption);
			}
		});
	}

	/*
	 * Selects all catalogs
    */
	selectAll() {
		this.model.collection.courseIDs = [];
		this.catalogs.forEach((catalog) => {
			this.model.collection.courseIDs.push(catalog.courseID);
			catalog.selected = true;
		});
		this.updateSelected();
	}

	/*
	 * Clear all catalogs
    */
	clearSelected() {
		this.model.collection.courseIDs = [];
		this.catalogs.forEach((catalog) => {
			catalog.selected = false;
		});
		this.updateSelected();
	}

	/*
	 * Selects all employees
    */
	selectAllEmployees() {
		this.model.collection.employees = [];
		this.employees.forEach((employee) => {
			this.model.collection.employeeIDs.push(employee.employeeID);
			employee.selected = true;
		});
		this.updateSelected();
	}

	/*
	 * Clear all employees
    */
	clearSelectedEmployees() {
		this.model.collection.employeeIDs = [];
		this.employees.forEach((employee) => {
			employee.selected = false;
		});
		this.updateSelected();
	}

	/*
	 * Select a catalog
    */
	select(catalog) {
		if (!this.model.collection.courseIDs) {
			this.model.collection.courseIDs = [];
		}

		catalog.selected = !catalog.selected;
		let index = this.model.collection.courseIDs.indexOf(catalog.courseID);
		if (index > -1) {
			this.model.collection.courseIDs.splice(index, 1);
		} else {
			this.model.collection.courseIDs.push(catalog.courseID);
		}
		this.updateSelected();
	}

	/*
	 * Select a employee
    */
	selectEmployee(employee) {
		if (!this.model.collection.employeeIDs) {
			this.model.collection.employeeIDs = [];
		}
		employee.selected = !employee.selected;
		let index = this.model.collection.employeeIDs.indexOf(employee.employeeID);
		if (index > -1) {
			this.model.collection.employeeIDs.splice(index, 1);
		} else {
			this.model.collection.employeeIDs.push(employee.employeeID);
		}
		this.updateSelectedEmployees();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredCatalogs(collection) {
		this.filteredCatalogs = collection.filtered;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/*
	 * Recieves results
    */
	recieveResults(results) {
		this.employees = results;
		this.updateSelectedEmployees();
	}

	/**
	 * Model editor says all options have been loaded
	 */
	onOptionsLoaded() {
		if (!this.pageDisplayed) {
			this.pageDisplayed = true;
			this.navService.displayPage();
		}
	}

	/*
	 * On Tab Chane
    */
	onTabChange() {
		this.updateSelected();
		this.updateSelectedEmployees();
	}

	/*
	 * On Collections Loaded
    */
	onCollectionLoad(collection) {
		if (collection) {
			this.model.collection = collection;
		} else {
			this.model.collection = {};
		}
		this.updateSelected();
		this.updateSelectedEmployees();
	}

	/*
	 * Updates the selected catalogs
    */
	updateSelected() {
		if (!this.model.collection.courseIDs) {
			this.model.collection.courseIDs = [];
		}

		// Remove course IDs that are no longer available
		this.model.collection.courseIDs.forEach((courseID) => {
			if (this.catalogCourseIDs.indexOf(courseID) == -1) {
				let index = this.model.collection.courseIDs.indexOf(courseID);
				this.model.collection.courseIDs.splice(index, 1);
			}
		});

		this.catalogs.forEach((catalog) => {
			catalog.selected = this.model.collection.courseIDs.indexOf(catalog.courseID) > -1;
		});

		if (!this.model.collection.permissionID && this.globalOption) {
			this.globalOption.selected = true;
		}
	}

	/*
	 * Updates the selected employees
    */
	updateSelectedEmployees() {
		let employeeIDs = this.model.collection.employeeIDs ? this.model.collection.employeeIDs : [];
		this.employees.forEach((employee) => {
			employee.selected = employeeIDs.indexOf(employee.employeeID) > -1;
		});
	}

	/*
 	 * On update handler
	*/
	onupdate(field = null) {
		if (field) {
			this.navService.changedForm = true;
		}
		this.feedbackService.clearErrors();

		if (this.model.collection.allCourses == 1) {
			this.model.collection.courseIDs = [];
			this.catalogPrefixButtons[0].disabled = true;
		} else {
			this.catalogPrefixButtons[0].disabled = false;
		}
		this.updateSelected();
		this.updateSelectedEmployees();
	}

	/*
	 * Sets the courses to create the courses filter
	*/
	setCatalogs() {
		let headers = ['cc.name', 'cc.courseID'];
		this.processingService.allowCancel = false;
		this.lioModalService.showLoading('coursecatalog.loading');
		this.lmsService.post('catalog/getAllForCollection', { 'headers': headers}).then((result) => {
			let catalogs = result.properties.catalogs;
			if (!catalogs.length) {
				this.feedbackService.setError('noRecordsFound');
			} else {
				this.catalogs = catalogs;
				this.catalogCourseIDs = [];
				this.catalogs.forEach((catalog) => {
					this.catalogCourseIDs.push(catalog.courseID);
				});
				this.onupdate();
			}
			this.lioModalService.hideLoading();
			this.processingService.allowCancel = true;
		});
	}
}