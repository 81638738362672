import { Component, Inject, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { lmsService } from '../../../../services/lms.service';
import { permissionService } from '../../../../services/permissions.service';
import { utilService } from '../../../../services/util.service';
import { storageService } from '../../../../services/storage.service';
import { feedbackService } from '../../../../services/feedback.service';
import { fieldService } from '../../../../services/fields.service';

import { searchEmployeesSettings } from './search-employees.settings';

import { LioSearchableSelectField } from '../../lio-forms.models';

@Component({
	selector: 'lio-search-employees',
	templateUrl: './search-employees.component.html',
})
export class LioSearchEmployees implements OnInit, OnDestroy  {
	private subscriptions			:Subscription 	= NEVER.subscribe();
	private segregationOptions		:Array<any>		= this.utilService.copy(this.searchEmployeesSettings.segregationOptions);
	private employees				:Array<any>		= [];
	private updateTimeout 			:any 			= null;

	public resultsFeedback			:Array<any>		= [];
	public loadingResults			:boolean		= false;
	public includeInactive			:boolean		= false;
	public model					:any			= {
		search 		: this.storageService.get('search'),
		segregation : this.getSegregation()
	};
	public localeStrings 			:any 			= {
		includeInactive 		: 'Include Inactive Employees',
		includeInactiveTrans 	: 'employeesearch.includeInactive'
	};
	public selectedField:{field:LioSearchableSelectField, model:any} = {
		field	: new LioSearchableSelectField({
			name 		: 'Search Field',
			nameTrans 	: 'form.searchField',
			model 		: 'model',
			options		: []
		}),
		model	: null,
	};
	public booleanField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Search',
		nameTrans 	: 'form.search',
		model 		: 'search',
		options 	: [
			{
				name 		: 'True', 
				nameTrans 	: 'form.true', 
				value 		: 1
			},
			{
				name 		: 'False', 
				nameTrans 	: 'form.false', 
				value 		: 0
			}
		]
	});
	public selectField:LioSearchableSelectField = new LioSearchableSelectField({
		model 	: 'search',
		options : []
	});
	public dateField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Search',
		nameTrans 	: 'form.search',
		model 		: 'search'
	});
	public hierarchyField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Limit Results based on hierarchy',
		nameTrans 	: 'employeeSearch.hierarchy',
		model 		: 'segregation',
		options 	: [
			{
				name 		: 'Default', 
				nameTrans 	: 'form.defaultHierarchy',
				locked 		: 'employeeSearch.defaultHierarchy'
			},
			{
				name 		: 'Bypass', 
				nameTrans 	: 'form.bypassHierarchy', 
				value 		: 'bypass',
				locked 		: 'employeeSearch.bypassHierarchy'
			},
			{
				name 		: 'Subordinate', 
				nameTrans 	: 'form.subordinate', 
				value 		: 'subordinate',
				locked 		: 'employeeSearch.subordinateHierarchy'
			},
			{
				name 		: 'Region', 
				nameTrans 	: 'form.region', 
				value 		: 'region',
				locked 		: 'employeeSearch.regionHierarchy'
			},
			{
				name 		: 'Subordinate In Region', 
				nameTrans 	: 'form.subordinateInRegion', 
				value 		: 'subordinateInRegion',
				locked 		: 'employeeSearch.subordinateInRegion'
			}
		]
	});

	private _field :any = null;
	get field(){
		return this._field;
	}
	@Input() set field(value: any) {    
		this._field 			= value;
		this.selectField 		= this.utilService.copy(value);
		this.selectField.model 	= 'search';
	}

	get fields(){
		return this.selectedField.field.options;
	}
	@Input() set fields(value: any) {    
		this.selectedField.field.options = value;
		
		if (!this.selectedField.model) {
			this.selectedField.model = this.getField('employeeID').model;
		}
		this.setFields();
		this.setField();
		this.model.segregation = this.getSegregation();
	}

	private _settings :any = {endPoint : 'employee/searchEmployees'};
	get settings(){
		return this._settings;
	}
	@Input() set settings(value: any) { 
		if(!value){
			value = {};
		}   

		if (!value.endPoint) {
			value.endPoint = 'employee/searchEmployees';
		}

		this._settings = value;
	}

	private _fieldConfig :any = {};
	get fieldConfig(){
		return this._fieldConfig;
	}
	@Input() set fieldConfig(value: any) { 
		this._fieldConfig = value;
		this.setFields();
		this.setField();
	}

	@Input() allowToggleInactive	:boolean = false;
	@Input() hideHierarchy			:boolean = false;
	@Input() appearance 			:MatFormFieldAppearance = 'outline';

	@Output() onResults 			:EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(debugService) 				public debugService 			:debugService,
		@Inject(lmsService) 				public lmsService 				:lmsService,
		@Inject(permissionService) 			public permissionService 		:permissionService,
		@Inject(utilService) 				public utilService 				:utilService,
		@Inject(storageService)				public storageService			:storageService,
		@Inject(feedbackService)			public feedbackService			:feedbackService,
		@Inject(fieldService)				public fieldService				:fieldService,
		@Inject(searchEmployeesSettings) 	public searchEmployeesSettings 	:searchEmployeesSettings,
	){
		this.debugService.register('searchEmployees', this);

		this.subscriptions = NEVER.subscribe();

		this.selectedField.model 	= this.storageService.get('selectedField');
		this.includeInactive 		= this.storageService.get('includeInactive');
	
		if (this.includeInactive === null) {
			this.includeInactive = false;
		}

		if (this.selectedField.model) {
			this.restoreFields();
		}
	}

	ngOnInit() {
		this.setEmployees();
	}

	/**
	 * Calls the onUpdate function with a delay of 500ms, refreshes the countdown when called
	 */
	bufferedUpdate(){
		clearTimeout(this.updateTimeout);
		this.updateTimeout = setTimeout(() => {
			this.onUpdate();
			clearTimeout(this.updateTimeout);
		}, 500);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/*
	* Gets the field by model name
	* @param {string} modelName
	* @return {?array}
	*/
	getField(modelName) {
		let matchedField = null;
		this.selectedField.field.options.forEach((field) => {
			if (field.model === modelName) {
				matchedField = field;
			}
		});

		return matchedField;
	}

	/*
	* Gets the segregation from the storage, or if only one option is available, get that one
	* @return {?string}
	*/
	getSegregation() {
		if (this.permissionService.hasPermission('employeeSearch.controlHierarchy')) {
			let segregation 	= this.storageService.get('segregation');
			let defaultValue 	= null;

			for (let i = 0; i < this.segregationOptions.length; i++) {
				if (this.segregationOptions[i].visible){
					if (segregation && this.segregationOptions[i].value == segregation) {
						//option matches stored value and is visible, return it
						return this.segregationOptions[i].value;
					}

					if (defaultValue === null) {
						//option is first visible option in list, this will be the default in the event that we don't find a usable stored value
						defaultValue = this.segregationOptions[i].value;
					}
				}
			}

			return defaultValue;
		} else {
			return null;
		}
	}

	/*
	* Sets the courses to create the courses filter
	*/
	setEmployees() {
		if (!this.settings || !this.settings.endPoint) {
			return;
		}
		if (!this.selectedField.model || !this.model.search) {
			this.resultsFeedback = [];
			this.employees = [];
			this.onResults.emit(this.employees);
			return;
		}

		let form = {
			'field'				: this.selectedField.model, 
			'search'			: this.model.search, 
			'includeInactive' 	: this.includeInactive,
			'settings'	 		: this.settings,
			'segregation'		: this.model.segregation
		},
		macros = [];
			
		this.loadingResults = true;
		this.resultsFeedback = [];
		this.feedbackService.addToHistory('SearchIng For: ' + this.model.search + ' in ' + this.selectedField.model);

		this.lmsService.post(this.settings.endPoint, form).then((result) => {
			if (!result) {
				return;
			}
			let employees = result.properties.employees || [],
				total = result.properties.total,
				feedback;

			if (employees && employees.length) {
				macros.push({'key': 'totalAllowed', 'value': employees.length});
				macros.push({'key': 'total', 'value': total});
				if (total > employees.length) {
					feedback = this.utilService.localizeMessages('displayingEmployees', macros);
				} else {
					feedback = this.utilService.localizeMessages('numberOfFoundEmployees', macros);
				}
			} else {
				feedback = this.utilService.localizeMessages('noEmployeesFound', macros);
			}

			this.loadingResults = false;
			this.employees = [];
			this.resultsFeedback = feedback;

			employees.forEach((employee) => {
				employee = this.postHandleEmployee(employee);
				this.employees.push(employee);
			});
			this.storageService.set('employees',this.employees);
			this.storageService.set('search', this.model.search);
			this.storageService.set('includeInactive', this.includeInactive);
			this.storageService.set('segregation', this.model.segregation);

			this.onResults.emit(this.employees);
		});
	}

	/*
	* Post handler for the employee
	* @param {object} employee
	* @return {object} employee
	*/
	postHandleEmployee(employee) {
		var role = this.permissionService.getRoleNameFromID(employee.permissionID),
			status = employee.inactive == 1 ? 'Inactive' : 'Active';

		employee.role = role;
		employee.status = status;

		return employee;
	}

	/*
	* On Update of the form, validate the form
	*/
	onUpdate() {			
		this.setEmployees();
		this.feedbackService.clearErrors();
	}

	/*
	* On field change
	*/
	onFieldChange() {
		this.model.search = null;
		this.resultsFeedback = [];
		this.employees = [];
		this.onResults.emit(this.employees);
		this.storageService.set('selectedField', this.selectedField.model);
		this.storageService.set('search', null);
		this.setField();
	}

	/*
	* Set the selected field
	*/
	setFields() {
		this.segregationOptions = this.permissionService.setFields(this.segregationOptions);
		this.fieldService.setFields(this.selectedField.field.options, this.fieldConfig).then((fields:Array<LioSearchableSelectField>) => {
			this.selectedField.field.options = fields;
			this.field = this.getField('employeeID');
			this.restoreFields();
		});
	}

	/*
	* Sets the field
	*/
	setField() {
		this.selectedField.field.options.forEach((field) => {
			if (field.model === this.selectedField.model) {
				this.field = field;
				if (field.type === 'select') {
					field.options.forEach((option) => {
						option.selected = false;
					});
				}
			}
		});
	}

	/*
	* Restore fields on re-entry
	*/
	restoreFields() {
		this.selectedField.field.options.forEach((field) => {
			if (field.model === this.selectedField.model) {
				field.selected = true;
				this.field = field;
				if (field.type === 'select') {
					field.options.forEach((option) => {
						if (this.model.search === option.value) {
							option.selected = true;
						} else {
							option.selected = false;
						}
					});
				}
			} else {
				field.selected = false;
			}
		});
	}
}