<div  class="row">
	<div class="col-md-10 offset-md-1">
		<mat-tab-group #tabGroup 
			dynamicHeight 
			mat-align-tabs="start" 
			(selectedTabChange)="changedSection($event.index)"
			[ngClass]="{'no-tab-headers':(model.settings.mode == 'read')}">

			<mat-tab *ngFor="let section of model.sections; index as i" 
				[disabled]="model.settings.mode != 'edit' && model.settings.mode != 'review'">
				<ng-template mat-tab-label>
					<span>{{(i + 1).toString()}}</span>
				</ng-template>
				<lio-card cardClass="mt-1" bodyClass="p-0">
					<div lio-card-header>
						<lio-disclosure-title [settings]="model.settings" 
							[appearance]="appearance"
							[model]="model">
						</lio-disclosure-title>
					</div>
					<div lio-card-body>
						<!-- Section is loaded as long as it is within one of active section in order to smoothly transition -->
						<lio-disclosure-section 
							*ngIf="activeSectionIndex >= i - 1 && activeSectionIndex <= i + 1"
							[appearance]="appearance"
							[settings]="model.settings" 
							[section]="model.sections[i]" 
							[allQuestions]="allQuestions"
							(questionAdded)="questionAdded($event)"
							(questionDeleted)="questionDeleted()"
							(delete)="deleteSection($event)"
							(optionAdded)="optionAdded($event)" 
							(notificationResponseAdded)="addNotificationResponse($event)"
							(notificationResponseRemoved)="removeNotificationResponse($event)"
							(response)="sendResponse()"
							[sendingResponse]="sendingResponse">
						</lio-disclosure-section>
					</div>
				</lio-card>
			</mat-tab>

			<mat-tab *ngIf="model.settings.mode == 'edit'" disabled>
				<ng-template mat-tab-label>
					<button mat-flat-button (click)="createSection()" >
						<mat-icon>add</mat-icon>
					</button>
				</ng-template>
				<lio-card cardClass="mt-1">
					<div lio-card-header>
						<lio-disclosure-title [settings]="model.settings" 
							[appearance]="appearance"
							[model]="model">
						</lio-disclosure-title>
					</div>
				</lio-card>
			</mat-tab>
	  	</mat-tab-group>
	</div>
</div>