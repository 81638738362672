import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../services/localization.service';

@Component({
	selector: 'lio-result-filters',
	templateUrl: './result-filters.component.html',
})
export class LioResultFilters {
	@Output() update:EventEmitter<any> = new EventEmitter();
	@Input() searchFields;
	@Input() searchConfig;
	@Input() search:any;
	@Input() appearance:MatFormFieldAppearance = 'outline';

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}
}