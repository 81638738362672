<div class="row">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber>
			<lio-panel-tab [settings]="panelConfig.select"></lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.edit"></lio-panel-tab>
			<lio-panel-tab [settings]="panelConfig.create">
				<lio-model-editor *lioPanelTabContent [model]="report" 
					[settings]="panelConfig.create.settings" 
					[formData]="formData"
					(onClick)="onClick($event)"
					(onModelLoad)="onReportLoaded($event)"
					(onModelPreSave)="modifyReportPriorToSave($event)" 
					(onModelPreCreate)="modifyReportPriorToSave($event)" 
					(onModelPreCopy)="modifyReportPriorToSave($event)">
				</lio-model-editor>
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</div>


