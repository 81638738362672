/*
 * Global Helper
*/
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class iframeService {
	iframeContents : string = '';
}