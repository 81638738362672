 <!-- Course Info When Expanded -->
 <div *ngIf="catalog.expanded" localize [model]="localeStrings" [items]="localeStringItems">
	<div class="row">
		<div class="col-12 col-md-8 d-inline-block ">
			
			<!-- Course Header -->
			<div class="mb-5">
				<!-- Course Image -->
				<img [alt]="'Catalog ' + catalog.type" [id]="'am_catalog_entry_' + indexInList + '_image'" *ngIf="isFieldVisible('image') && catalog.image" class="float-left mr-4 mb-4" style="height: 150px;vertical-align:top;" [src]="catalog.image">
				<!-- Course Name -->
				<div [id]="'am_catalog_entry_' + indexInList + '_name'" *ngIf="isFieldVisible('name')" class="h5 ml-3" [innerHtml]="catalog.name | html" (click)="clickedCatalog.emit(catalog)"></div>
				<!-- Course Type -->
				<div *ngIf="isFieldVisible('type')" class="h5 ml-3">
					<small>
						<span [id]="'am_catalog_entry_' + indexInList + '_type'">{{localeStrings.courseTypeHeader}}</span>
						<span class="ml-2" [innerHtml]="catalog.type  | uppercase | html"></span>
					</small>
				</div>

				<!-- Length -->
				<div [id]="'am_catalog_entry_' + indexInList + '_length'" 
					*ngIf="isFieldVisible('length') && catalog.length && permissionService.hasPermission('catalog.showLength')" 
					class="border-0 bg-transparent">
					<h3 class="h6 subsection-title-bold"><span>{{localeStrings.courseLength}}</span><span class="ml-2">{{catalog.length}}</span></h3>
				</div>

				<!-- KEYWORDS -->
				<div *ngIf="catalog.featuredKeywords.length" class="card border-0 bg-transparent ml-1" id="keywords">
					<div class="subsection-title-bold">{{localeStrings.keywords}}</div>
					<ul class="max-size-150 row">
						<li class="col-6" *ngFor="let keyword of catalog.featuredKeywords" [innerHtml]="keyword | html"></li>
					</ul>
				</div>

				<!-- Description -->
				<div [id]="'am_catalog_entry_' + indexInList + '_description'" 
					*ngIf="isFieldVisible('description')" 
					class="border-0 bg-transparent mt-3">
					<div class="h5">{{localeStrings.courseDescription}}</div>
					<div class="course-text" style="line-height:24px" [innerHtml]="catalog.description | html"></div>
				</div>

				<!-- Misc -->
				<section class="mt-3">
					<!-- Format -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
					*ngIf="isFieldVisible('format')&& catalog.format && permissionService.hasPermission('catalog.admin')">
						<p class="subsection-title-bold"><span>{{localeStrings.format}}</span> <span [innerHtml]="catalog.format | html"></span></p>
					</div>

					<!-- Cascade -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
					*ngIf="isFieldVisible('cascadeID') && catalog.cascadeID && catalog.cascadeID > 1 && permissionService.hasPermission('catalog.admin')">
						<p class="subsection-title-bold"><span>{{localeStrings.cascadeCourse}}</span> <span>#{{catalog.cascadeID}}</span></p>
					</div>

					<!-- Micro Download Links -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
						*ngIf="isFieldVisible('URL') && catalog.URL && catalog.URL.length">
						<p><span>{{localeStrings.courseURL}}</span> <span [innerHtml]="catalog.URL | html"></span></p>
					</div>

						<!-- Tuesday Download Links -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
						*ngIf="isFieldVisible('tuesday') && catalog.tuesday">
						<p><span>{{localeStrings.courseURL}}</span> <span [innerHtml]="catalog.tuesday | html"></span></p>
					</div>

						<!-- Turk Download Links -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
						*ngIf="isFieldVisible('turk') && catalog.turk">
						<p><span>{{localeStrings.courseURL}}</span> <span [innerHtml]="catalog.turk | html"></span></p>
					</div>

						<!-- BroadCat Download Links -->
					<div [id]="'am_catalog_entry_' + indexInList + '_url_expanded'" 
						*ngIf="isFieldVisible('broadcat') && catalog.broadcat">
						<p><span>{{localeStrings.courseURL}}</span> <span [innerHtml]="catalog.broadcat | html"></span></p>
					</div>

					<!-- PROJECTED RELEASE -->
					<div [id]="'am_catalog_entry_' + indexInList + '_projectedrelease'" 
						*ngIf="isFieldVisible('availableDate') && permissionService.hasPermission('catalog.admin') && !catalog.isAvailable">
						<h4 [id]="'am_catalog_entry_' + indexInList + '_availabledate'">{{utilService.formatDate(catalog.availableDate) | date : localizationService.getSelectedDateFormat()}}</h4>
						<h4>{{localeStrings.projectedRelease}}</h4>
					</div>
				</section>

				<!-- Modules -->
				<div [id]="'am_catalog_entry_' + indexInList + '_modules'" 
					*ngIf="isFieldVisible('modules') && catalog.modules.length" class="card border-0 bg-transparent bg-transparent mt-4">
					<div class="h5">{{localeStrings.modules}}</div>
					<p class="course-text">{{localeStrings.moduleDescription}}</p>

					<section class="row">
						<div class="col-md-6 module" *ngFor="let module of catalog.modules; index as i">
							<div [id]="'am_catalog_entry_' + indexInList + '_module_' + i" class="card text-white text-center bg-secondary mb-3 rounded-top-lg">
								<div (click)="selectModule.emit({catalog:catalog, module:module})" class="card-header module bg-dark rounded-top-lg">
									<span *ngIf="module.selected" class="border-circle float-left">{{module.selectCount}}</span>
									<span [id]="'am_catalog_entry_' + indexInList + '_module_' + i + '_name'" 
										class="text-center text-white btn">
										{{module.name}}
									</span>
								    <button [id]="'am_catalog_entry_' + indexInList + '_module_' + i + '_preview'" 
										(click)="previewModule.emit({catalog:catalog, module:module})" 
										title="Preview" 
										class="play fa fa-play-circle fa-2x text-white float-right btn p-0">
									</button>
								</div>
								<div (click)="selectModule.emit({catalog:catalog, module:module})" 
									class="card-body btn bg-ultra-light-gray">
									<p [id]="'am_catalog_entry_' + indexInList + '_module_' + i + '_description'" 
										class="card-text">
										{{module.description}}
									</p>
								</div>
							</div>
						</div>
					</section>
				</div><!-- Modules -->
			</div>
		</div>

		<!-- Course ID -->
		<div class="col-12 col-md-4" *ngIf="catalog.isAvailable">

			<!-- Right TOP Side -->
			<div class="row">
				<div class="col-md-8">
					<span *ngIf="!permissionService.hasPermission('catalog.admin') && permissionService.hasPermission('catalog.showcourseID')">
						<span class="h4" [innerHtml]="'#' + catalog.courseID | html"></span>
					</span>
					<span *ngIf="permissionService.hasPermission('catalog.admin')">
						<button class="btn btn-secondary" [innerHtml]="'#' + catalog.courseID | html" (click)="gotoAdmin(catalog.courseID)"></button>
					</span>
				</div>
    			<div class="col-md-4 text-right">
					<span class="float-right">
						<button [id]="'am_catalog_entry_' + indexInList + '_print'" 
							class="button-hide btn" 
							*ngIf="permissionService.hasPermission('catalog.print') && view.printable"
							title="coursecatalog.print"
							(click)="clickedPrint.emit(catalog)">
							<i class="fa fa-print fa-3x mx-1" title="Print this catalog" [ngClass]="catalog.print ? 'text-info' : 'text-secondary'"></i>
						</button>
						<button [id]="'am_catalog_entry_' + indexInList + '_preview'" 
							class="button-hide btn" 
							*ngIf="view.canPreview && !catalog.modules.length" 
							(click)="previewCourse.emit(catalog)" 
							title="coursecatalog.previewCourse">
							<i class="fa fa-play-circle fa-3x text-info mx-1" title="Preview this catalog"></i>
						</button>
					</span>

					<!-- Self Enrollment -->
					<button *ngIf="view.selfEnroll && !catalog.enrolled" [disabled]="false" class="btn btn-primary" (click)="selfEnroll.emit(catalog)">{{localeStrings.enroll}}</button>

					<button *ngIf="view.selfEnroll && catalog.enrolled" [disabled]="true" class="btn btn-disabled btn-primary">{{localeStrings.enrolled}}</button>
				</div>
			</div>

			<!-- Right BOTTOM Side -->
			<div class="row">
				<div class="col-md-12 card border-0 bg-transparent mt-3">
					<!-- BUTTONS -->
					<div *ngIf="view.canDownload">
						
						<button class="btn btn-outline-learn btn-block rounded-pill mt-1" 
							*ngIf="canRequestScript(catalog) && isFieldVisible('scriptLocation') && permissionService.hasPermission('catalog.showscriptButton')" 
							[disabled]="catalog.requestedScript" (click)="clickedOnScript.emit(catalog)">{{localeStrings.courseScript}}</button>
						<button class="btn btn-outline-learn btn-block rounded-pill mt-1" 
							*ngIf="catalog.infoSheetLocation && isFieldVisible('infoSheetLocation') && permissionService.hasPermission('catalog.showInfoButton')" 
							[disabled]="!catalog.infoSheetLocation || catalog.requestedInfoSheet" (click)="clickedOnInfoSheet.emit(catalog)">{{localeStrings.infoText}}</button>
						<button class="btn btn-outline-learn btn-block rounded-pill mt-1" 
							*ngIf="isFieldVisible('infoSheetLocation') && permissionService.hasPermission('catalog.showimpButton')" 
							[disabled]="catalog.requestedImplementation" (click)="clickedOnRequest.emit(catalog)">{{localeStrings.requestImplementation}}</button>
					</div>
				</div>

				<!-- UPDATES -->
				<div [id]="'am_catalog_entry_' + indexInList + '_updates'" 
					*ngIf="isFieldVisible('updates') && catalog.updates.length" 
					class="col-md-12 mt-3 card border-0 bg-transparent">
					<div class="h5">{{localeStrings.latestUpdates}}</div>
					<div *ngFor="let update of $any(catalog.updates | slice:0:5)">
						<div class="button" (click)="update.expanded = !update.expanded" *ngIf="update">
							<i class="fa" [ngClass]="update.expanded ? 'fa-caret-down' : 'fa-caret-right'"></i>
							<span [textContent]="update.updateDate | date: localizationService.getSelectedDateFormat()"></span>
						</div>
						<ul *ngIf="update.expanded && update.updateGroup">
							<li *ngFor="let update of update.updateGroup" [innerHtml]="update.text | html"></li>
						</ul>
					</div>
				</div><!-- Updates -->

				<!-- NOTES -->
				<div [id]="'am_catalog_entry_' + indexInList + '_notes'" 
					*ngIf="isFieldVisible('notes') && catalog.notes.length && permissionService.hasPermission('catalog.admin')" 
					class="col-md-12 mt-3 card border-0 bg-transparent">

					<div class="h5">{{localeStrings.adminNotes}}</div>
					<ul>
						<li *ngFor="let note of catalog.notes" [innerHtml]="note.note | html"></li>
					</ul>
				</div><!-- Notes -->	
			</div>
		</div>
	</div>
		<div class="card-columns ml-4" *ngIf="catalog.expanded" style="column-count: 2;">
			<!-- Languages -->
			<div [id]="'am_catalog_entry_' + indexInList + '_languages'" 
				*ngIf="isFieldVisible('languages') && catalog.langs.length" 
				class="card border-0 bg-transparent">
				<h6 class="subsection-title-bold">{{localeStrings.availableLanguages}}</h6>
				<ul class="max-size-150 row">
					<li class="col-6" *ngFor="let language of catalog.langs | orderBy:'name':false" 
						[innerHtml]="language.name | html">{{language.name}}</li>
				</ul>
			</div>

			<!-- TOPICS -->
			<div [id]="'am_catalog_entry_' + indexInList + '_topics'" 
				*ngIf="isFieldVisible('topics') && catalog.topics.length" 
				class="card border-0 bg-transparent ml-1">
				<div class="subsection-title-bold">{{localeStrings.topics}}</div>
				<ul class="max-size-150 row">
					<li class="col-6 font-weight-bold" *ngFor="let topic of catalog.featuredTopics | orderBy:'name':false" [innerHtml]="topic.name | html"></li>
					<li class="col-6" *ngFor="let topic of catalog.genericTopics | orderBy:'name':false" [innerHtml]="topic.name | html"></li>
				</ul>
			</div>
		</div>
</div><!-- Row Expanded -->