<h6 *ngIf="settings.mode == 'read' || settings.mode == 'review'">{{model.title}}</h6>
<div *ngIf="settings.mode == 'edit'" class="row no-gutters">
	<mat-form-field [appearance]="appearance" class="col">
		<mat-label>Disclosure Title</mat-label>
		<input matInput type="text" [(ngModel)]="model.title"/>
	</mat-form-field>
	<mat-form-field [appearance]="appearance" class="col ml-2">
		<mat-label>Disclosure Course ID</mat-label>
		<input matInput type="text" [(ngModel)]="model.courseID"/>
	</mat-form-field>
</div>
<div *ngIf="model.settings.notificationResponses.length > 0 && (settings.mode == 'edit' || settings.mode == 'review')">
	<mat-form-field [appearance]="appearance" class="w-100">
		<mat-label>Send Notifications to</mat-label>
		<input matInput 
			type="text" 
			[disabled]="settings.mode == 'review'" 
			[(ngModel)]="model.settings.notificationEmail"/>
	</mat-form-field>
</div>
<div *ngIf="settings.mode == 'edit' || settings.mode == 'review'">
	<div class="row my-2">
		<div class="col">
			<span>Disclosure is </span>
			<span *ngIf="!model.active && !model.archived" class="badge bg-danger text-white">Not Active</span>
			<span *ngIf="model.active" class="badge bg-success text-white">Active</span>
			<span *ngIf="model.archived" class="badge bg-warning text-white">Archived</span>
		</div>
	</div>
	<div class="row no-gutters">
		<lio-searchable-select [appearance]="appearance" 
			class="col"
			[field]="showReportField" 
			[model]="model">
		</lio-searchable-select>
		<lio-searchable-select [appearance]="appearance" 
			class="col ml-2"
			[field]="languageField" 
			[model]="model">
		</lio-searchable-select>
	</div>

	<div class="row">
		<div class="col">
			<div *ngIf="settings.wasActive && !model.active && !model.archived">
				<span>Disclosure was previously activated and some options are disabled</span>
			</div>
			<div *ngIf="model.active">
				<div>it can be reached at the following link</div>
				<div>{{model.accessLink}}</div>
			</div>
		</div>
	</div>
</div>