import { Inject, Injectable } from '@angular/core';
import { storageService } from '../../services/storage.service';
import { navService } from '../../services/nav.service';

import { PanelTabData, CardButtonModelArray } from '../../modules/structural/structural.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: homeSettings
})
export class homeSettings {
	constructor(
		@Inject(storageService) private storageService	:storageService,
		@Inject(navService) 	private navService		:navService
	){}

	panelConfig:PanelTabData = new PanelTabData({
		id				: 'am_home_panel',
		title			: 'Where would you like to start?',
		titleTrans		: 'home.header',
		titleClass		: 'text-left text-huge mx-3 rtl',
		tab				: 'Home',
		tabTrans		: '',
		visible 		: () => {return true;}
	});

	cards:CardButtonModelArray = new CardButtonModelArray(
		{
			permission			: 'pages.training',
			onClick 			: () => { 
				this.navService.goto('training');
			},
			color				: 'yellow',
			icon 				: 'fa-graduation-cap',
			id					: 'am_home_gotoTraining',
			title 				: 'My Learning',
			titleTrans 			: 'home.myLeanring',
			subtitle 			: 'View your active courses',
			subtitleTrans		: 'home.myLeanringSub',
		},
		{
			permission 			: 'pages.selfEnroll',
			onClick 			: () => {
				this.navService.goto('selfenroll');
			},
			color				: 'yellow',
			icon				: 'fa-graduation-cap',
			id					: 'am_home_gotoSelfEnroll' ,
			subtitle			: 'Enroll youself in a course' ,
			subtitleTrans		: 'home.selfEnrollSub' ,
			title				: 'Self Enrollment' ,
			titleTrans			: 'home.selfEnroll',
		},
		{
			permission			: 'pages.dashboard' ,
			onClick 			: () => {
						this.navService.goto('dashboard');
			},
			color				: 'faded-blue',
			icon				: 'fa-chart-pie',
			id					: 'am_home_gotoDashboard' ,
			subtitle			: 'Create a query and view/export stats' ,
			subtitleTrans		: 'home.dashboardSub' ,
			title				: 'Dashboard' ,
			titleTrans			: 'home.dashboard',
		},
		{
			permission			: 'pages.addUsers',
			onClick 			: () => {
				this.navService.goto('addusers');
			},
			color				: 'green' ,
			icon				: 'fa-user' ,
			id					: 'am_home_gotoAddUsers' ,
			subtitle			: 'Add employees to the LMS' ,
			subtitleTrans		: 'home.addUsersSub' ,
			title				: 'Add Users' ,
			titleTrans			: 'home.addUsers',
		},
		{
			class				: 'col-xl-3 col-lg-6 mb-3',
			permission			: 'pages.batchprocessing',
			onClick 			: () => {
				this.navService.goto('batchprocessing');
			},
			color				: 'green' ,
			icon				: 'fa-user' ,
			id					: 'am_home_gotoBatch' ,
			subtitle			: 'Batch Processing Manager' ,
			subtitleTrans		: 'home.batchProcessingSub' ,
			title				: 'Batch Processing' ,
			titleTrans			: 'home.batchProcessing',
		},
		{
			class				: 'col-xl-3 col-lg-6 mb-3',
			permission			: 'pages.enrollQuery' ,
			onClick 				: () => {
				this.navService.goto('enrollquery');
			},
			color				: 'orange' ,
			icon				: 'fa-users' ,
			id					: 'am_home_gotoEnrollUsers' ,
			subtitle			: 'Enroll selected users into courses' ,
			subtitleTrans		: 'home.enrollUsersSub' ,
			title				: 'Enroll Users' ,
			titleTrans			: 'home.enrollUsers',
		},
		{
			permission			: 'pages.employeeSearch' ,
			onClick 			: () => {
				this.navService.goto('employeesearch');
			},
			color				: 'blue' ,
			icon				: 'fa-search' ,
			id					: 'am_home_gotoSearchEmployees' ,
			subtitle			: 'Search for an Employee' ,
			subtitleTrans		: 'home.searchUsersSub' ,
			title				: 'Search Users' ,
			titleTrans			: 'home.searchUsers',
		},
		{
			permission			: 'pages.reports' ,
			onClick 			: () => {
				this.navService.goto('reports');
			},
			color				: 'blue' ,
			icon				: 'fa-folder-open' ,
			id					: 'am_home_gotoReports' ,
			subtitle			: 'View Reports' ,
			subtitleTrans		: 'home.viewReportsSub' ,
			title				: 'View Reports' ,
			titleTrans			: 'home.viewReports',
		},
		{
			permission			: 'pages.email' ,
			onClick 			: () => {
				this.storageService.set('task', 'managing');
				this.navService.goto('email');
			},
			color				: 'yellow' ,
			icon				: 'fa-envelope' ,
			id					: 'am_home_gotoManageTemplates' ,
			subtitle			: 'View and modify email templates' ,
			subtitleTrans		: 'home.emailTemplatesSub' ,
			title				: 'Email Templates' ,
			titleTrans			: 'home.emailTemplates',
		},
		{
			permission			: 'pages.catalogSearch' ,
			onClick 			: () => {
				this.navService.goto('catalogsearch');
			},
			color				: 'green' ,
			icon				: 'fa-book-open' ,
			id					: 'am_home_courseCatalog' ,
			subtitle			: 'Course Catalog' ,
			subtitleTrans		: 'coursecatalog.title' ,
			title				: 'Course Catalog' ,
			titleTrans			: 'coursecatalog.title',
		},
		{
			permission			: 'pages.courseuploader' ,
			onClick 			: () => {
				this.storageService.remove('uploadedCourse');
				this.navService.goto('courseuploader');
			},
			color				: 'yellow' ,
			icon				: 'fa-upload' ,
			id					: 'am_home_gotoManageCourses' ,
			subtitle			: 'Course Manager' ,
			subtitleTrans		: 'courseuploader.title' ,
			title				: 'Course Manager' ,
			titleTrans			: 'courseuploader.title',
		},
		{
			permission			: 'pages.scheduleEmails' ,
			onClick 			: () => {
				this.navService.goto('scheduleEmails');
			},
			color				: 'green' ,
			icon				: 'fa-calendar' ,
			id					: 'am_home_gotoScheduleEmails' ,
			subtitle			: 'Schedule Emails' ,
			subtitleTrans		: 'scheduleEmails.title' ,
			title				: 'Schedule Emails' ,
			titleTrans			: 'scheduleEmails.title',
		},
		{
			permission			: 'pages.enrollmentHistory' ,
			onClick 			: () => {
				this.navService.goto('enrollmentHistory');
			},
			color				: 'green' ,
			icon				: 'fa-users' ,
			id					: 'am_home_gotoEnrollmentHistory' ,
			subtitle			: 'Enrollment History' ,
			subtitleTrans		: 'enrollmentHistory.title' ,
			title				: 'Enrollment History' ,
			titleTrans			: 'enrollmentHistory.title',
		},
		{
			permission			: 'pages.delegateAccess' ,
			onClick 			: () => {
				this.navService.goto('delegateAccess');
			},
			color				: 'orange' ,
			icon				: 'fa-address-book' ,
			id					: 'am_home_gotoDelegateAccess' ,
			subtitle			: 'Delegate Access' ,
			subtitleTrans		: 'delegateAccess.title' ,
			title				: 'Delegate Access' ,
			titleTrans			: 'delegateAccess.title',
		},
		{
			permission			: 'pages.excludeUsers' ,
			onClick 			: () => {
				this.navService.goto('excludeUsers');
			},
			color				: 'faded-blue' ,
			icon				: 'fa-address-book' ,
			id					: 'am_home_gotoexcludeusers' ,
			subtitle			: 'Exclude Users' ,
			subtitleTrans		: 'excludeUsers.title' ,
			title				: 'Exclude Users' ,
			titleTrans			: 'excludeUsers.title',
		},
		{
			permission			: 'pages.scheduleEnrollments' ,
			onClick 			: () => {
				this.navService.goto('scheduleEnrollments');
			},
			color				: 'green' ,
			icon				: 'fa-calendar' ,
			id					: 'am_home_gotoScheduleEnrollments' ,
			subtitle			: 'Schedule Enrollments' ,
			subtitleTrans		: 'scheduleEnrollments.title' ,
			title				: 'Schedule Enrollments' ,
			titleTrans			: 'scheduleEnrollments.title',
		},
		{
			permission			: 'pages.emailHistory' ,
			onClick 			: () => {
				this.navService.goto('emailHistory');
			},
			color				: 'green' ,
			icon				: 'fa-envelope' ,
			id					: 'am_home_gotoEmailHistory' ,
			subtitle			: 'Email History' ,
			subtitleTrans		: 'emailHistory.title' ,
			title				: 'Email History' ,
			titleTrans			: 'emailHistory.title',
		},
	
		{
			permission			: 'pages.disclosureReport' ,
			onClick 			: () => {
				this.navService.goto('disclosureReport');
			},
			color				: 'faded-blue' ,
			icon				: 'fa-chart-bar' ,
			id					: 'am_home_gotoDisclosureReport' ,
			subtitle			: 'Disclosure Reports' ,
			subtitleTrans		: 'home.disclosureReportSub' ,
			title				: 'Disclosure Reports' ,
			titleTrans			: 'home.disclosureReport',
		},
		{
			permission			: 'pages.adminTools' ,
			onClick 			: () => {
				this.navService.goto('adminTools');
			},
			color				: 'faded-blue' ,
			icon				: 'fa-wrench' ,
			id					: 'am_home_gotoAdminTools' ,
			subtitle			: 'Admin Tools' ,
			subtitleTrans		: 'home.adminToolsSub' ,
			title				: 'Admin Tools' ,
			titleTrans			: 'home.adminTools',
		},
	);
}
