import { Component, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { NEVER, Subject, of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debugService } from '../../../../../services/debug.service';
import { feedbackService } from '../../../../../services/feedback.service';
import { pushNotificationService } from '../../../../../services/push-notification.service';
import { permissionService } from '../../../../../services/permissions.service';
import { navService } from '../../../../../services/nav.service';
import { stateService } from '../../../../../services/state.service';
import { lioLogService } from '../../../../../services/lio-log.service';
import { lioModalService } from '../../../../../services/lio-modal.service';
import { lmsService } from '../../../../../services/lms.service';
import { storageService } from '../../../../../services/storage.service';
import { utilService } from '../../../../../services/util.service';
import { querySettings } from '../../../../../settings/query.settings';

import { LioSearchableSelectField } from '../../../lio-forms.models';

@Component({
	selector: 'lio-query-tool',
	templateUrl: './query-tool.component.html'
})
export class LioQueryTool implements OnDestroy {
	@Output() results: EventEmitter<any> = new EventEmitter();
	@Output() loaded: EventEmitter<any> = new EventEmitter();
	@Output() onRun: EventEmitter<any> = new EventEmitter();
	@Output() customConfirmation: EventEmitter<any> = new EventEmitter();
	@Input() filters?			:any;
	@Input() onInit?			:any;
	@Input() queryToolNote?		:any;
	@Input() appearance?		:any = 'outline';
	@Input() limitHeaders		:boolean = false;
	
	private loadedDependencies:any 	= ['queries', 'builder'];

	private _controlSettings :any;
	get controlSettings(){
		return this._controlSettings;
	}
	@Input() set controlSettings(value: any) {    
		this._controlSettings = value;

		if (this.initializing) {
			return;
		}
		this.init();
	}

	private _controlObject :any;
	get controlObject(){
		return this._controlObject;
	}
	@Input() set controlObject(value: any) {    
		this._controlObject = value;

		if (value) {
			value.init				= this.init.bind(this);
			value.save				= this.save.bind(this);
			value.delete			= this.delete.bind(this);
			value.getQueryID		= this.getQueryID.bind(this);
			value.getQueries		= this.getQueries.bind(this);
			value.getSelected		= this.getSelected.bind(this);
			value.loadSelectedID	= this.loadSelectedID.bind(this);
			value.setName			= this.setName.bind(this);
			value.runReport			= this.runReport.bind(this);
			value.updatedModel		= this.updatedModel.bind(this);
			value.clearSelection	= this.clearSelection.bind(this);
			value.getSettings		= this.getSettings.bind(this);
			value.getAsyncToken		= this.getAsyncToken.bind(this);
			value.getRules			= this.getBuilderRules.bind(this);
			value.reset				= this.reset.bind(this);
		}
	}

	public confirmed			:boolean 		= false;
	public showSaver			:boolean 		= true;
	public builderCtrl			:any 			= {};
	public name					:any 			= null;
	public model				:any 			= {selected : {name:''}};
	public rules				:Array<any> 	= [];
	public ready				:boolean 		= false;
	public hide					:boolean 		= true;
	public watching				:boolean 		= false;
	public settings				:any 			= {};
	public lastToken			:any 			= null;
	public builderID			:string 		= 'builder';
	public subscriptions		:any 			= null;
	public active				:boolean 		= false;
	public initializing			:boolean 		= false;
	public visibilityOptions	:any 			= false;
	public reportSubject 		:Subject<any> 	= null;
	public localeStrings 		:any 			= {
		name						: 'Name',
		nameTrans					: 'query.name',
		save						: 'Save Query',
		saveTrans					: 'query.save',
		placeholder					: 'Enter name to save as',
		placeholderTrans			: 'query.placeholder',
		delete						: 'Delete Query',
		deleteTrans					: 'query.delete',
		showExpiredCoursesNote 		: '*** To see expired courses, select the "Show Expired Courses" filter',
		showExpiredCoursesNoteTrans : 'dashboard.showExpiredCoursesNote'
	};
	public createNewQueryOption = {
		name 		: 'Create New',
		nameTrans 	: 'query.create',
		value 		: ''
	};
	public queryField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Saved Queries',
		nameTrans	: 'query.savedQueries',
		model 		: 'selected',
		options 	: []
	});
	public visibilityField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Visibility',
		nameTrans	: 'query.visibility',
		model 		: 'visibility',
		options 	: [],
		locked 		: true
	});

	constructor(
		@Inject(debugService)				public debugService				:debugService,
		@Inject(feedbackService)			public feedbackService			:feedbackService,
		@Inject(pushNotificationService)	public pushNotificationService	:pushNotificationService,
		@Inject(permissionService)			public permissionService		:permissionService,
		@Inject(navService)					public navService				:navService,
		@Inject(stateService)				public stateService				:stateService,
		@Inject(lioLogService)				public lioLogService			:lioLogService,
		@Inject(lioModalService)			public lioModalService			:lioModalService,
		@Inject(lmsService)					public lmsService				:lmsService,
		@Inject(storageService)				public storageService			:storageService,
		@Inject(utilService)				public utilService				:utilService,
		@Inject(querySettings)				public querySettings			:querySettings
	){
		this.debugService.register('querytool', this, true);
		this.subscriptions = NEVER.subscribe();
		
		this.subscriptions.add(
			this.navService.exit.subscribe(() => { 
				this.deleteLastResult(); 
			})
		);

		this.subscriptions.add(
			this.navService.exiting.subscribe(() => { 
				this.active = false; 
			})
		);

		this.handleLoaded();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
		
	/**
	 * Init
	 */ 
	init() {
		this.initializing = true;
		if (!this.controlSettings || !this.filters || !this.controlSettings.usesQueryTool) {
			this.active = false;
			if (!this.ready) {
				this.hide = true;
			}
			return;
		}
		this.active							= true;
		this.settings 						= Object.assign(this.utilService.copy(this.querySettings.queryToolSettings), this.controlSettings);
		this.visibilityField.options	= this.utilService.copy(this.settings.visibilityOptions);
		this.showSaver 						= this.settings.showSaver;

		this.setVisibility();
		this.visibilityField.locked = false;
		this.ready = true;
		this.hide = false;
		return this.get().pipe(map(() => {
			// Selector needs a moment
			if (this.builderCtrl.createBuilder) {
				this.builderCtrl.createBuilder();
			}
			if (this.onInit) {
				this.onInit();
			}
			this.initializing = false;
		}));
	}

	/*
		* Reset
	*/
	reset() {
		this.rules = [];
		if (this.builderCtrl.reset) {
			this.builderCtrl.reset();
		}
	}

	/*
	* Sets the visibility options and selected visible based on fist available option
	*/
	setVisibility() {
		if (!this.model.selected) {
			this.model.selected = {};
		}
		this.visibilityField.options = this.permissionService.setFields(this.visibilityField.options as Array<any>);
		this.visibilityField.options.forEach((option) => {
			if (!this.model.selected.visibility && !option.locked) {
				this.model.selected.visibility = option.value;
			}
		});
	}

	/*
	* Gets the builder rules 
	*/
	getBuilderRules(includeInvalid = false) {
		if (this.builderCtrl && typeof this.builderCtrl.getRules == 'function') {
			return this.builderCtrl.getRules(includeInvalid);
		}
		return [];
	}

	/*
	* Check if the builder rules are valid
	* @return {boolean}
	*/
	validateRules() {
		if (this.builderCtrl) {
			return this.builderCtrl.isValid();
		}

		return false;
	}

	/*
	* Gets the selected model 
	*/
	getSelected() {
		return this.model.selected;
	}

	/*
	* Gets the available queries 
	*/
	getQueries() {
		return this.queryField.options;
	}

	/*
	* Gets the guery ID 
	* @return {string}
	*/
	getQueryID() {
		let model = this.model.selected;
		if (model) {
			return model.id;
		}
		return null;
	}

	/*
		* Gets the settings
		* @return {Object}
	*/
	getSettings() {
		return this.settings;
	}

	/*
		* Gets the async token
		* @return {?string}
	*/
	getAsyncToken() {
		return this.lastToken;
	}

	/*
		* Deletes the last result
	*/
	deleteLastResult(){
		if (this.lastToken) {
			this.lmsService.postTask('async/destroyAsyncResult&token='+ this.lastToken).then(() => {
				this.lioLogService.log('Deleted Last Result');
			});
		}
	}

	/*
		* Run the report and 'report' back to the controller
	*/
	runReport() {
		// Let the parent know that a run was requested
		this.onRun.emit({
			url 		: this.limitHeaders ? this.settings.endpoints.report + '&limitHeaders=true' : this.settings.endpoints.report,
			type		: this.settings.type,
			fields		: this.settings.fields,
			model 		: this.model.selected,
			rules 		: this.getBuilderRules()
		});
		this.storageService.set(this.settings.type, this.getBuilderRules());			

		this.submitReport().subscribe((response) => {
			if (response) {
				this.results.emit(response);
			}
		});
	}

	/**
	 * Submits the report
	 */ 
	submitReport() {
		let url 		= this.limitHeaders ? this.settings.endpoints.report + '&limitHeaders=true' : this.settings.endpoints.report,
			type		= this.settings.type,
			fields		= this.settings.fields,
			reportID	= this.settings.reportID,
			rules 		= this.getBuilderRules();

		this.reportSubject = new Subject();

		if (this.confirmed) {
			url += '&confirmed=true';
			this.confirmed = false;
		}

		if (!this.validate()) {
			return of(false);
		}

		this.lioLogService.log(['Submitting', type, fields, rules, reportID]);

		this.lmsService.postAsync(url, {'type': type, 'fields': fields, 'rules': rules, 'reportID': reportID},
		'loadingRecords').then((result) => {
			if(result){
				let token 			= result.properties.token;
				
				this.lastToken 	= token;
				
				if (token) {
					this.lmsService.getAsyncResult(token, (result) => {
						this.processReport(result);
					}, {'destroyResult': this.settings.destroyResult});
				} else {
					this.processReport(result);
				}
			}
		});
		
		return this.reportSubject;
	}

	/**
	 * Processes a report
	 */ 
	processReport(result) {
		let employees = result.properties.employees,
			chunkCancelled = result.chunkCancelled,
			confirmationNeeded = result.properties.confirmationNeeded,
			tooMany = result.properties.tooMany,
			maxAmount = result.properties.maxAmount,
			cancelRequested = result.properties.cancelRequested,
			cancelled = result.cancelled,
			success = result.success,
			macros = [];


		if (!success) {
			this.lioLogService.log('Failed from bad success');
			this.lioModalService.hideLoading();

			this.reportSubject.next(false);
			this.reportSubject.complete();
			return;
		} 

		if (cancelRequested || cancelled || chunkCancelled) {
			this.lioLogService.log(['Cancelled Heard', result]);
			this.lioModalService.hideLoading();

			this.reportSubject.next(false);
			this.reportSubject.complete();
			return;
		}

		if (confirmationNeeded) {
			macros = [{'key': 'total', 'value': confirmationNeeded}];
			return this.lioModalService.confirm(this.settings.feedback.confirmLoad, null, macros).then((confirmed) => {
				if (confirmed) {
					this.confirmed = confirmed;
					return this.pushNotificationService.askToNotify().then(() => {
						return this.runReport();
					});
				}
			});
		}


		if (this.pushNotificationService.shouldSendNotification) {
			this.pushNotificationService.send(this.settings.feedback.pushNotificationTitle, this.settings.feedback.pushNotificationMsg);
		}

		if (tooMany) {
			macros = [
				{'key': 'tooManyTotal', 'value': tooMany},
				{'key': 'maxAmount', 'value': maxAmount}
			];
			this.lioModalService.hideLoading();
			this.lioModalService.showError(this.settings.feedback.tooMany, null, macros);
			
			this.reportSubject.next(false);
			this.reportSubject.complete();
			return;
		}

		if (!employees) {
			this.lioModalService.hideLoading();
			this.lioModalService.show(this.settings.feedback.noRecords);
			
			this.reportSubject.next(false);
			this.reportSubject.complete();
			return;
		}

		this.reportSubject.next(result);
		this.reportSubject.complete();
		return;
	}

	setQueryFieldOptions(options){
		this.queryField.options = [];
		this.queryField.options.push(this.utilService.copy(this.createNewQueryOption));
		this.queryField.options = this.queryField.options.concat(options);
	}

	/*
	* Load queries
	*/
	get() {
		let url 		= this.settings.endpoints.get,
			type 		= this.settings.type,
			subject 	= new Subject();

			this.lmsService.post(url, {'type': type}, {'cache': true}).then((result) => {
				let token = result.properties.token;
				if (token) {
					this.lmsService.getAsyncResult(token, (result)  => {
						this.gotQueries(result, subject);
					});
				} else {
					this.gotQueries(result, subject);
				}
			});

		return subject;
	}


	/*
	* On Load of queries
	*/
	gotQueries(result, subject) {
		if (result.success) {
			this.setQueryFieldOptions(result.properties.values);
		}

		subject.next(result.success);
		subject.complete();
		this.loadedDependencies = this.loadedDependencies.filter(item => item != 'queries');
		this.handleLoaded();
	}


	/* 
	 * Builder tool ready
	 */
	toolReady() {
		this.loadedDependencies = this.loadedDependencies.filter(item => item != 'builder');
		this.handleLoaded();
	}


	/* 
	 * Dependency check to emit the loaded signal
	 */
	handleLoaded() {
		if (!this.loadedDependencies.length) {
			this.loaded.emit(true);
		}
	}

	/*
	* On load of an exisitng query
	*/
	loadQuery() {
		this.updatedModel();
	}

	/*
	* Model was updated
	*/
	updatedModel() {
		this.lioLogService.log(['current rules', this.rules]);

		let model = this.model.selected;
		if (model && model.filters) {
			this.rules = model.filters;
		} else {
			this.createNew();
			this.setVisibility();
		}
	}

	/*
	* Start a new query
	*/
	createNew() {
		this.model = {selected : {name:''}};
		this.rules = [];
	}

	/*
	* Validates prior to saving
	* @return {boolean} 
	*/
	validate() {
		let isValid = true,
			model 	= this.model.selected;

		if (!this.getBuilderRules()) {
			this.feedbackService.setError('createAtLeastOneRule');
			return false;
		}

		if (!this.validateRules()) {
			this.feedbackService.setError('pleaseCheckTheFilters');
			return false;	
		}

		this.queryField.options.forEach((value) => {
			if (value && value.name == model.name && model.id !== value.id) {
				this.feedbackService.setError('thisNameAlreadyExists');
				isValid = false;
				return false;
			}
		});

		return isValid;
	}

	/*
	* Clears the selection
	* @param {string} id 
	* @return {boolean} 
	*/
	clearSelection() {
		this.model.selected = {};
		this.setVisibility();		
	}

	/*
	* Loads the selected id
	* @param {string} id 
	* @return {boolean} 
	*/
	loadSelectedID(id) {
		let matched = false;
		this.queryField.options.forEach((value) => {
			if (value.id === id) {
				this.model.selected = value;
				matched = true;
			}
		});

		return matched;
	}

	/*
	* Sets the selected name
	* @param {string} name 
	*/
	setName(name) {
		this.model.selected.name = name;
	}

	/*
	* Save queries 
	* @return {Promise}
	*/
	save() {
		this.feedbackService.clearAll();
		if (!this.validate()) {
			return;
		}

		let hasCustomConfirmation = this.customConfirmation.observers.length > 0;
		let data:{
			type		:string, 
			model		:any, 
			confirmed	:Subject<boolean>
		} = {
			type		:'save', 
			model		: this.model.selected, 
			confirmed	: new Subject()
		};

		data.confirmed.subscribe((confirmed:boolean) => {
			if (confirmed) {
				this.confirmedSave();
			}
		});

		if (hasCustomConfirmation) {
			this.customConfirmation.emit(data);
		} else {
			data.confirmed.next(true);
			data.confirmed.complete();
		}
	}

	/*
	* Save queries 
	* @return {Promise}
	*/
	confirmedSave() {
		let filters 	= this.getBuilderRules(),
			selected 	= this.model.selected,
			id 		 	= selected.id,
			type 		= this.settings.type;

		if (id) {
			this.lioModalService.showLoading('updating');
		} else {
			this.lioModalService.showLoading('saving');
		}

		this.storageService.set(type, this.getBuilderRules());

		return this.lmsService.post(
			this.settings.endpoints.save,
			{'type': type, 'model': selected, 'filters': filters},
			{'clearCache': true})
		.then((result) => {
			this.lioModalService.hideLoading();
			this.storageService.remove('queryToolResults_' + type);
		
			if (result.success) {
				this.model.selected.id = result.properties.value.id;
				if (result.properties.updated) {
					if (this.showSaver) {
						this.lioModalService.show('updatedSuccessfully');
					}
				} else {
					if (this.showSaver) {
						this.lioModalService.show('savedSuccessfully');
					}
				}
				return this.get().pipe(map(() => {
					if (result.properties.value) {
						return this.loadSelectedID(result.properties.value.id); 
					}
				}));
			} else if (result.errors) {
				this.lioModalService.showError(result.errors[0]);
			}
		});
	}

	/*
	* Delete requested
	*/
	delete() {
		let name 				= this.model.selected.name;
		let macros 				= [{'key': 'name', 'value': name}];
		let wrappedObservable 	= new Observable((subscriber) => {
			subscriber.next({
				type 		: 'delete', 
				data 		: this.model.selected, 
				confirm 	: null
			});
			subscriber.complete();
		});

		this.customConfirmation.emit(wrappedObservable);
			
		wrappedObservable.subscribe((obj:{type:string, data:any, confirm:Subject<boolean>}) => {
			if (obj.confirm) {
				obj.confirm.subscribe(() => {
					this.confirmedDelete();
				});
			}else{
				this.lioModalService.confirm('areYouSureDeleteName', null, macros).then((result) => {
					if (result) {
						this.confirmedDelete();
					}
				});
			}
		});
	}

	/*
	* Delete confirmed
	*/
	confirmedDelete() {
		if (this.showSaver) {
			this.lioModalService.showLoading('deleting');
		}
		return this.lmsService.post(
			this.settings.endpoints.delete,
			{'type': this.settings.type, 'id': this.model.selected.id},
			{'clearCache': true})
		.then((result) => {
			this.lioModalService.hideLoading();
			this.storageService.remove('queryToolResults_' + this.settings.type);
			
			if (result.success) {
				if (this.showSaver) {
					this.lioModalService.show('deletedSuccessfully');
				}
				this.model.selected = {}; 
				this.get();
			}
		});
	}
}