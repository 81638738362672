import { Injectable } from '@angular/core';

import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: emailSettings
})
export class emailSettings {
	emailTemplateField:LioSearchableSelectField = new LioSearchableSelectField({
		amID			: 'am_email_template_select',
		name			: 'Which email template would you like to use?',
		nameTrans 		: 'email.whichtemplate',
		model			: 'template',
		addEmptyOption	: true,
		optionAsValue 	: true,
		searchable		: true
	});

	pagination:any = {
			'pageLimit'	: '10',
			'pageLimits': ['10', '50', '100'],
			'sortMode'	: {field : 'lastName', direction : 'asc'}
	};
	

	builderSettings:any = {
		'type'					: 'email',
		'addBaseFilters'		: false,
		'hasButtons'			: false,
		'addCCFtoFilters'		: false,
		'addRoleFilter'			: false,
		'usesQueryTool'			: false,
		'showSaver'				: false,
		'filterOverrideID' 		: 'emailFilters',
		'hideDelete' 			: true,
		'suppressUnknownRuleError': true,
	};

	templates:Array<any> = [
		{
			'name': 'Offline Template',
			'fromAddress': 'msmith@hr.com',
			'replyTo': 'msmith@hr.com',
			'fromName': 'Mike Smith',
			'subject': 'You have been enrolled in {{courseName}}',
			'message': 'Dear {{firstName}} {{lastName}},\nYou have been enrolled in {{courseName}}\nPlease log in here {{loginLink}}',
		},
	];


	fieldConfig:any = {
		'fieldName'			: 'email',
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: true,
	};


	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Sent Email',
			'nameTrans': 'email.sentEmail',
			'model': 'sentEmail',
			'type': 'checkbox',
			'required': true,
			'sortable': true
		},
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'required': true,
			'allowHide': true,
			'sortable': true
		},
		{
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'required': true,
			'allowHide': true,
			'sortable': true
		},
		{
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'required': true,
			'allowHide': true,
			'sortable': true
		},
		{
			'name': 'e-mail',
			'model': 'email',
			'type': 'email',
			'required': true,
			'allowHide': true,
			'sortable': true
		},
		{
			'name': 'langID',
			'model': 'langID',
			'type': 'text',
			'required': true,
			'allowHide': true,
			'sortable': true
		},
	);
}