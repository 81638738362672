import { Component, Input, Output, Inject, EventEmitter, DoCheck } from '@angular/core';

import { debugService } from 'src/app/services/debug.service';
import { permissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'lio-catalog-filters',
  templateUrl: './filters.component.html'
})
export class LioCatalogFilters implements DoCheck {
	@Input() fieldsObject		:any 				= null;
	@Input() collections		:Array<any>			= [];
	@Input() topics				:Array<any>			= [];
	@Input() languageCodes		:Array<any>			= [];
	@Input() activeCore			:boolean 			= false;
	@Input() releasedStatus		:boolean 			= false;
	@Input() unReleasedStatus	:boolean 			= false;

	@Output() editCollections	:EventEmitter<any> 	= new EventEmitter();
	@Output() activateCollection:EventEmitter<any> 	= new EventEmitter();
	@Output() activateTopic		:EventEmitter<any> 	= new EventEmitter();
	@Output() activateCoreLangs	:EventEmitter<any> 	= new EventEmitter();
	@Output() activateLanguage	:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedReleased	:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedUnreleased	:EventEmitter<any> 	= new EventEmitter();

	public featuredTopics = [];
	public genericTopics = [];

	public localeStringItems:Array<any> = [
		'collectionsHeader',
		'released',
		'unReleased',
		'topicsHeader',
		'languageHeader',
		'coreLangs',
		'courseNumber',
		'courseName',
	];

	public localeStrings:any = {
		collectionsHeader 		: 'COLLECTIONS:',
		collectionsHeaderTrans 	: 'coursecatalog.collectionsHeader',
		released 				: 'Released',
		releasedTrans 			: 'coursecatalog.released',
		unReleased 				: 'Unreleased',
		unReleasedTrans			: 'coursecatalog.unReleased',
		topicsHeader 			: 'TOPICS:',
		topicsHeaderTrans 		: 'coursecatalog.topicsHeader',
		languageHeader 			: 'LANGUAGES:',
		languageHeaderTrans		: 'coursecatalog.languageHeader',
		coreLangs 				: 'Core Languages',
		coreLangsTrans 			: 'coursecatalog.coreLangs',
	};

	constructor(
		@Inject(debugService) 		public debugService		:debugService,
		@Inject(permissionService) 	public permissionService:permissionService
	){
		this.debugService.register('catalogfilters', this);
	}


	ngDoCheck() {
		if (!this.topics || !this.topics.length) {
			return;
		}
		this.featuredTopics = this.topics.filter((topic) => {
			return topic.featuredTopic == 1;
		});
	
		this.genericTopics = this.topics.filter((topic) => {
			return topic.featuredTopic == 0;
		});
	}

	/*
	 * Is the field visible
	 @param {string} fieldName
	 @return {boolean}
	*/
	isFieldVisible(fieldName) {
		if (!this.fieldsObject) {
			return true;
		}
		return this.fieldsObject[fieldName].visible;
	}

}