import { Inject, Injectable } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { localizationService } from './localization.service';
import { navService } from './nav.service';
import { debugService } from './debug.service';
import { loginService } from './login.service';

@Injectable({
	providedIn: 'root',
})
export class breadcrumbService {
	breadcrumbs : any = [];

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(localizationService)	private localizationService	:localizationService,
		@Inject(loginService)			private loginService		:loginService,
		@Inject(debugService)			private debugService		:debugService,
		@Inject(navService)				private navService			:navService,
	){

		this.debugService.register('breadcrumbs', this);

		this.subscriptions.add(
			this.navService.activePageSet.subscribe(() => {
				this.set();
			})
		);
		this.subscriptions.add(
			this.localizationService.localized.subscribe(() => {
				this.rebuildLocalization();
			})
		);
		this.subscriptions.add(
			this.navService.exit.subscribe((params = {path : 'home'}) => {
				this.removeNextCrumbs(params.path);
			})
		);
		this.subscriptions.add(
			this.navService.confirmedExit.subscribe((params = {path : 'home'}) => {
				this.removeNextCrumbs(params.path);
			})
		);
		this.subscriptions.add(
			this.loginService.notLoggedIn.subscribe(() =>{
				this.init();
			})
		);
		this.subscriptions.add(
			this.loginService.loggedOff.subscribe(() => {
				this.handleLoggedOff();
			})
		);
		this.init();
	}

	/*
	 * Initialize
	*/
	init() {
		this.breadcrumbs = [];
		this.addHomeCrumb();
	}

	/*
	 * Sets the breadcrumb
	*/
	set() {
		this.rebuildHome();
		if (this.shouldAddCrumb()) {
			this.addCrumb();
		}
	}

	/*
	 * Rebuilds the home crumb
	*/
	rebuildHome () {
		let homeCrumb = this.getHomeCrumb();

		this.breadcrumbs.forEach((breadcrumb, index) => {
			if (breadcrumb.home) {
				this.breadcrumbs[index] = homeCrumb;
			}
		});
	}

	/*
	 * Rebuilds all crumbs for localization
	*/
	rebuildLocalization () {
		this.breadcrumbs.forEach((breadcrumb, index) => {
			let localization = this.localizationService.get(breadcrumb.localization);
			this.breadcrumbs[index].name = localization;
		});
	}
	
	/*
	 * Adds a breadcrumb
	*/
	addCrumb() {
		if(this.navService.page){
			var path = this.navService.page,
				name = this.navService.crumbName,
				localization = this.navService.crumbName,
				id = 'am_crumb_' + path.toLowerCase();

			name = this.localizationService.get(name);

			if (this.shouldAddCrumb()) {
				this.breadcrumbs.push({'id': id, 'path': path, 'name': name, 'localization': localization});
			}
		}
	}

	/*
	 * Gets the home breadcrumb
	 * @return {object}
	*/
	getHomeCrumb() {
		let home			= this.navService.getHomePage(),
			id				= 'am_crumb_home',
			name			= this.localizationService.get(home + '.crumb'),
			path			= home;
		
		return {'id': id, 'path': path, 'name': name, 'localization': home + '.crumb', 'home': true};
	}

	/*
	 * Adds the home breadcrumb
	*/
	addHomeCrumb() {
		let breadcrumb = this.getHomeCrumb();
		this.breadcrumbs.push(breadcrumb);
	}

	/*
	 * Gets the breadcrumbs
	 * @return {array}
	*/
	getCrumbs() {
		return this.breadcrumbs;
	}

	/*
	 * Should we add a crumb?
	 * @return {boolean}
	*/
	shouldAddCrumb() {
		return this.isNewCrumb();
	}

	/*
	 * Is this a new crumb?
	 * @return {boolean}
	*/
	isNewCrumb() {
		var isNew = true;
		this.breadcrumbs.forEach((breadcrumb) => {
			if (breadcrumb.path === this.navService.page) {
				isNew = false;
				return;
			}
		});
		return isNew;
	}

	/*
	 * Removes any breadcrumbs after specified one
	 * @param {?array} breadcrumb
	*/
	removeNextCrumbs(path) {
		let index = -1;
		
		if (path) {
			this.breadcrumbs.forEach((crumb, ind) => {
				if(crumb.path == path){
					index = ind;
				}
			});
		}

		if(index > -1){
			this.breadcrumbs = this.breadcrumbs.splice(0, index+1);
			if (!this.breadcrumbs.length) {
				this.init();
			}
		}
	}


	/*
	 * Gandles logged off
	*/
	handleLoggedOff() {
		this.breadcrumbs = [];
		this.addHomeCrumb();
	}

	/*
	 * Removes a breadcrumb
	 * @param {array} breadcrumb
	*/
	removeCrumb(breadcrumb) {
		let index = this.breadcrumbs.indexOf(breadcrumb);

		if (breadcrumb && breadcrumb.home) {
			return;
		}
		this.breadcrumbs.splice(index, 1);
	}

	/*
	 * Removes the last breadcrumb
	*/
	removeLastCrumb() {
		var index = this.breadcrumbs.length - 1,
			breadcrumb = this.breadcrumbs[index];

		if (breadcrumb && breadcrumb.home) {
			return;
		}

		this.breadcrumbs.splice(index, 1);
	}

	/*
	 * Gets the last crumb
	 * @return {boolean}
	*/
	getLastCrumb() {
		let lastIndex = this.breadcrumbs.length - 1,
			lastCrumb = this.breadcrumbs[lastIndex - 1];
	
		return lastCrumb;
	}

	/*
	 * Is this the last breadcrumb?
	 * @return {boolean}
	*/
	isLast(index) {
		if (!this.breadcrumbs[index + 1]) {
			return true;
		}
		return false;
	}

	/*
	* Goto a breadcrumb
	* @param {array} breadcrumb
	*/
	gotoCrumb(breadcrumb) {
		if (this.navService.locked || this.navService.disableNav) {
			return;
		}

		this.navService.confirm(breadcrumb.path);
	}

	/*
	* Goes back one crumb
	*/
	goBackOne() {
		if (this.navService.locked || this.navService.disableNav) {
			return;
		}
		let crumb = this.getLastCrumb();
		if (crumb) {		
			this.gotoCrumb(crumb);
		} else {
			this.navService.goHome()
		}
	}
}