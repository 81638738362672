import { Subject, ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';
import { lioModalService } from './lio-modal.service';
import { lmsService } from './lms.service';


/*
 * Service for Handling custom reports
*/

@Injectable({
	providedIn: 'root',
})
export class reportsService {
	public reports			:Subject<any> = new Subject();
	public combinedReports:any			= [];
	public customReports:any			= [];
	public filteredReports				:ReplaySubject<Array<any>>	= new ReplaySubject(1);
	public availableReports				:ReplaySubject<Array<any>>	= new ReplaySubject(1);
	public reportsFormatted				:ReplaySubject<Array<any>>	= new ReplaySubject(1);
	public reportTypes:any 				= [];
	public customReportType:any 		= null;

	constructor(
		@Inject(lioLogService)		private lioLogService	:lioLogService,
		@Inject(utilService)		private utilService		:utilService,
		@Inject(lmsService)	 		public 	lmsService 		:lmsService,
		@Inject(lioModalService)	public 	lioModalService :lioModalService,
		@Inject(debugService)		private debugService	:debugService,
	){
		this.debugService.register('reportsService', this);
	}


	/*
	 * Sets the reports types
	*/
	setReportTypes(types) {
		types.forEach((type) => {
			type.queryToolSettings.suppressCachedRules = true;
		});
		this.reportTypes = types;
	}


	/*
	 * Sets the custom reports types
	*/

	setCustomReportType(types) {
		this.customReportType = types;
	}


	/*
	 * Gets all records and inits
	*/
	init() {
		return this.lmsService.post('customreports/getAll', {}, {'cache': true}).then((response:any) => {
			if (response.success) {
				this.setCustomReports(response.properties.values);
				this.combineReports();
				this.lioLogService.log(['Reports Loaded']);
			}
		});
	}


	/*
	 * Sets the custom reports
	*/
	setCustomReports(reports) {
		let reportsFormatted = [];

		reports.forEach((report, index) => {
			reportsFormatted.push({'name': report.name.toUpperCase(), 'value': report.name});
			report.type = report.name;
			report.isCustom = true;
			report = this.utilService.extend(this.utilService.copy(this.customReportType), report);
			report.queryToolSettings.type = report.type;
			report.queryToolSettings.reportID = report.id;
			reports[index] = report;
		});

		this.customReports = reports;
		this.reportsFormatted.next(reportsFormatted);
	}



	/*
	 * Combines the reports
	*/
	combineReports() {
		this.combinedReports = this.utilService.copy(this.reportTypes.concat(this.customReports));
		let availableReports = [],
			type;

		this.combinedReports.forEach((report) => {
			if (report) {
				type = report.type || report.name;
				availableReports.push({'name': type.toUpperCase(), 'value': type});
			}
		});

		this.availableReports.next(availableReports);
	}



	/*
	 * Queries loaded, update the filtered reports
	*/
	setFilteredReports(reports) {
		let filteredReports = [];

		// Remove the empty create new option
		reports = reports.filter(item => item.value != '');

		this.lioModalService.hideLoading();

		if (!reports.length) {
			this.lioModalService.show('No reports found for this type');
		} else {
			reports.forEach((report) => {
				filteredReports.push({'name': report.name.toUpperCase(), 'value': report});
			});	
		}

		this.filteredReports.next(filteredReports);
	}


	/*
	 * Sets the report type by the current selected report type
	 * @return {object}
	*/
	getReportType(report) {
		let setType = null;
		if (report) {
			this.combinedReports.forEach((reportType) => {
				reportType = this.utilService.copy(reportType);
				if (reportType.type == report.type) {
					setType = reportType;
				}
			});	
		}
		return setType;
	}
	
}