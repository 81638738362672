import { LioField } from "./field.model";

export class LioFieldArray extends Array<LioField>{
	constructor(...args:(Partial<LioField>|LioField)[]){
		let elements:Array<LioField> = [];

		args.forEach((item) => {
			if(item instanceof LioField){
				elements.push(item);
			}else{
				elements.push(new LioField(item));
			}
		});

		super(...elements);
	}
}