import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-catalog-updates',
	templateUrl: './updates.component.html',
})
export class LioCatalogUpdates {
	@Input() appearance			:MatFormFieldAppearance = 'fill';
	
	@Input() model:any;
	@Input() field:any;
	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();

	public dateField = {
		model: 'updateDate'
	};

	constructor(
		@Inject(localizationService) public localizationService :localizationService,
	){}

	addUpdate() {
		this.model.updates.push({
			updateDate: '',
			updateGroup: 	[],
		});
	}

	removeUpdate(index) {
		this.model.updates.splice(index, 1);
		this.onUpdate.emit();
	}

	removeUpdateText(updateModel, index) {
		updateModel.updateGroup.splice(index, 1);
		this.onUpdate.emit();
	}


	addText(updateModel) {
		updateModel.updateGroup.push({'text': '', 'updateDate': updateModel.updateDate})
	}

}