import { Injectable, Inject } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

import { querySettings } from '../../settings/query.settings';

@Injectable({
	providedIn	: 'root', 
	useClass	: globalSearchSettings
})
export class globalSearchSettings {

	constructor(
		@Inject(querySettings) private querySettings: querySettings
	){}

	pagination:any = {
			'pageLimit'	: '5',
			'pageLimits': ['5', '10', '50', '100'],
			'sortMode'	: {field : 'lastName', direction : 'asc'}
	};

	queryToolSettings:any = {
		'type'					: 'globalsearch',
		'addBaseFilters'		: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'showSaver'				: true,
		'usesQueryTool'			: true,
		'text'				: {
			'runReport'			: 'globalsearch.search',
		},
		'endpoints'				: {
			'get'					: 'query/getAllByType',
			'save'					: 'query/save',
			'delete'				: 'query/deleteByID',
			'report'				: 'userSummary/searchUserSummary',
		},
	};

	filters:Array<any> = [
			{
				'field': 'e.companyID',
				'name': 'Company ID',
				'type': 'string',
				'placeholder': 'Enter a company id',
				'size': 40,
				'operators': [
					this.querySettings.operatorOptions.equal, 
					this.querySettings.operatorOptions.in
				],
				'visible': true
			},
			{
				'field': 'c.coName',
				'name': 'Company Name',
				'type': 'string',
				'placeholder': 'Enter a company name',
				'size': 80,
				'operators': [
					this.querySettings.operatorOptions.equal, 
					this.querySettings.operatorOptions.begins_with, 
					this.querySettings.operatorOptions.in
				],
				'visible': true
			},
	];


	fieldConfig:any = {
		'fieldName'			: 'globalSearch',
		'addCCFtoFields'	: false,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: false,
		'addLangField'		: true,
	};

	searchConfig:any = {
		'fieldName'			: 'globalSearch',
		'addCCFtoFields'	: false,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: false,
		'addLangField'		: true,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Company ID',
			'model': 'companyID',
			'type': 'text',
			'invertModel': false,
			'sortable': true
		},
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'invertModel': false,
			'sortable': true
		},
		{
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'invertModel': false,
			'sortable': true
		},
		{
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'invertModel': false,
			'sortable': true
		},
		{
			'name': 'e-mail',
			'model': 'email',
			'type': 'text',
			'invertModel': false,
			'sortable': true
		}
	);

	searches:Array<any> = [
		{
			'id': 'company_id',
			'name': 'Company ID',
			'model': 'companyID',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'employee_id',
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'first_name',
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'last_name',
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'e-mail',
			'name': 'e-mail',
			'model': 'email',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'state',
			'name': 'State',
			'model': 'state',
			'type': 'text',
			'visible': true,
		},
		{
			'id': 'Status',
			'name': 'status',
			'model': 'inactive',
			'nameTrans': 'form.status',
			'type': 'select',
			'width': 300,
			'visible': true,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '0',
					'trans': 'form.active',
					'name': 'active',
					'selected': false,
				},
				{
					'value': '1',
					'trans': 'form.inactive',
					'name': 'inactive',
					'selected': false,
				}
			]
		},
	];
}