/*
 * Service for LMS Data retrieval using an AJAX factory
*/
import { Observable, Subject, Subscriber } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { configSettings } from '../settings/config.settings';
import { errorsService } from './errors.service';
import { feedbackService } from './feedback.service';
import { lioLogService } from './lio-log.service';
import { lioModalService } from './lio-modal.service';
import { navService } from './nav.service';
import { processingService } from './processing.service';
import { storageService } from './storage.service';
import { debugService } from './debug.service';
import { analyticsService } from './analytics.service';
import { utilService } from './util.service';

export class RequestInfo{
	public url				:string;
	public params			:any;
	public method			:any;

	public urlPrepend 		:string 			= '';
	public callback 		:any				= null;
	public subscriber		:Subscriber<any> 	= null;
	public currentAttempts	:number 			= 0;

	constructor(
		url		:string,
		params	:any = {responseType : null},
		method	:any,
	){
		this.url	= url;
		this.params	= params;
		this.method	= method;
	}

	defer():Observable<any>{
		return new Observable((subscriber) => {	
			if(this.subscriber == null){
				this.subscriber = subscriber;
			}		

			this.callback = (data) => {
				this.subscriber.next(data);
				this.subscriber.complete();
			}

			this.send();
		});
	}

	send():void{
		this.method(this);
	}
}

@Injectable({
	providedIn: 'root',
})
export class lmsService{
	requesting 			:any 		= {};
	cached 				:any 		= {};
	currentToken 		:any 		= null;
	currentChunk 		:any		= null;
	chunkIDs 			:Array<any>	= [];
	chunked 			:boolean	= false;
	exited 				:boolean	= false;
	result 				:any		= null;
	totalChunks 		:number		= 0;
	chunks 				:Array<any>	= [];
	warnedPartialOutput :boolean 	= false;
	allowPartialOutput 	:boolean 	= false;
	asyncTime			:number		= 2000;
	cancelRequested		:boolean		= false;
	maxAttemptsAllowed	:number 	= 3;
	lastRequest			:any 		= null;
	lastTask 			:any 		= null; 
	asyncing			:boolean 	= false;
	cancelledSubject	:Subject<any> = new Subject();
	emptyResponse		:any 	= {
		success: false,
		criticalErrors: [],
		errors: [], 
		properties: [], 
	};


	constructor(
		@Inject(configSettings)		private configSettings 		:configSettings,
		@Inject(lioModalService)	private lioModalService		:lioModalService,
		@Inject(navService)			private navService			:navService,
		@Inject(feedbackService)	private feedbackService		:feedbackService,
		@Inject(processingService)	private processingService	:processingService,
		@Inject(storageService)		private storageService		:storageService,
		@Inject(lioLogService)		private lioLogService		:lioLogService,
		@Inject(HttpClient)			private HttpClient			:HttpClient,
		@Inject(analyticsService)	private analyticsService	:analyticsService,
		@Inject(errorsService) 		private errorsService		:errorsService, 
		@Inject(utilService) 		private utilService			:utilService, 
		@Inject(debugService)		private debugService		:debugService
	) {
		this.defferedGet 		= this.defferedGet.bind(this);
		this.defferedGetReal 	= this.defferedGetReal.bind(this);
		this.defferedGetTask	= this.defferedGetTask.bind(this);
		this.defferedPost 		= this.defferedPost.bind(this);
		this.defferedPostCross 	= this.defferedPostCross.bind(this);
		this.defferedPostTask	= this.defferedPostTask.bind(this);
		this.navService.exit.subscribe((result) => {
			this.warnedPartialOutput 	= false;
			this.allowPartialOutput 	= false;
			if (result.path != 500) {
				this.sendAnalytics('Navigating to ' + result.path);
			}
		});

		this.navService.confirmedExit.subscribe(() => {
			this.handleExit();
		});
		
		this.debugService.register('lmsService', this);
	}


	sendAnalytics(info) {
		this.analyticsService.setInfo(info);
		this.analyticsService.setPage(this.navService.activePage);
		this.analyticsService.setLastProcess(this.lastTask);
		this.post('analytics/send', {'analytics': this.analyticsService.getData()});
	}


	get(task) {		
		task = this.getURLParams(task);
		return this.getTask(task);
	}


	getPostParams(params) {
		if (!params) {
			params = {};
		}
		if (this.storageService.get('frontEndCompanyID')) {
			params['frontEndCompanyID'] = this.storageService.get('frontEndCompanyID');
		}
		if (this.storageService.get('frontEndPermissionID')){
			params['frontEndPermissionID'] = this.storageService.get('frontEndPermissionID');
		}

		if (this.storageService.get('frontEndEmployeeID')){
			params['frontEndEmployeeID'] = this.storageService.get('frontEndEmployeeID');
		}

		return params;
	}


	getURLParams(url) {
		if (!url) {
			url = '';
		}
		
		return url;
	}

	clearCache() {
		this.cached = {};
		this.requesting = {}; 
	}

	//makes a simple get request
	getReal(task, params) {
		let requestInfo = new RequestInfo(task, params, this.defferedGetReal);
		return requestInfo.defer().pipe(map(results => {
			return results;
		})).toPromise();
	}

	post(task, params = null, config:any = {}) {
		let cacheID = this.utilService.hash(task, params);

		task = this.getURLParams(task);
		params = this.getPostParams(params);

		if (config.clearCache) {
			this.clearCache();
		}

		if (config.cache) {
			if (this.cached[cacheID]) {
				return new Promise((resolve) => {
					resolve(this.cached[cacheID]);
				});
			}
		}


		if (!config.keepChangedForm) {
			this.navService.changedForm = false;
		}

		return this.postTask(task, params).then((response) => {
			if (config.cache) {
				this.cached[cacheID] = response;
			}
			return response;
		});
	}

	postAsync(task, params:any = {}, processingMessage:string = '', canCancel:boolean = true) {
		this.lastTask = task;
		this.cancelRequested = false;
		let allowAsync = this.configSettings.allowAsync;

		if (processingMessage) {
			// We do not allow async cancellation until the first request to get the asynctoken comes through
			this.lioModalService.showLoading(processingMessage, !allowAsync).then((result) => {
				if (result === 'CANCELLED') {
					this.cancel();
				}
			});
		}

		if (allowAsync) {
			task = task + '&async=true';
			this.asyncing = true;
		}

		task = this.getURLParams(task);

		params = this.getPostParams(params);		
		return this.postTask(task, params).then((response) => {
			if (processingMessage && canCancel) {
				this.lioModalService.updateSetting('canCancel', true);
			}

			// Extend the wait time when cancellation is suppressed
			if (!canCancel) {
				this.lioModalService.updateSetting('maxWaitTime', 180);
			}
			return response;
		})
	}

	postCross(task) {

		let requestInfo = new RequestInfo(task, null, this.defferedPostCross)
		return requestInfo.defer().pipe(map(results => {
			return results;
		})).toPromise();
	}

	getTask(task, post = null) {
		this.lastTask = task;
		post = this.getPostParams(post);

		let requestInfo = new RequestInfo(task, post, this.defferedGetTask);
		return requestInfo.defer().pipe(map(results => {
			return results;
		})).toPromise();
	}

	postTask(task, post = null) {
		this.lastTask = task;
		let requestID = this.utilService.hash(task, post);
		if (this.requesting[requestID]) {
			return this.requesting[requestID];
		}

		this.requesting[requestID] = new Promise((resolve) => {
			let requestInfo = new RequestInfo(task, post, this.defferedPostTask);
			return requestInfo.defer().pipe(map(results => {
				if (!results) {
					results = this.emptyResponse;					
				}
				resolve(results);
				this.requesting[requestID] = null;
				return;
			})).toPromise();
		});

		return this.requesting[requestID];
	}

	cancel() {
		this.lioModalService.updateTitle('stopping');
		this.cancelRequested = true;
		this.asyncing = false;

		this.processingService.setCancellingProcesses();

		if (!this.currentToken) {
			return this.cancelled(this.emptyResponse);
		}

		return this.get('async/cancelAsync&token=' + this.currentToken).then((result:any) => {
			return this.cancelled(result);
		});
	}

	cancelled(result) {
		this.processingService.cancelProcesses();
		this.navService.changedForm = false;
		this.processingService.downloading = false;
		this.lioModalService.hideLoading();
		this.chunkIDs = [];
		this.cancelledSubject.next(null);
		return new Promise((resolve) => { resolve(result); });
	}

	getChunks(token, callback, config:any = {}) {
		var url = 'async/getChunks&token=' + token + '&chunk=' + this.currentChunk;

		url += '&destroyResult=' + config.destroyResult;

		if (this.cancelRequested) {
			return;
		}

		this.currentToken = token;
		this.currentChunk++;
		this.get(url).then((result:any) => {
			if (!result) {
				this.clearCache();
				return;
			}
			let chunks = result.properties.chunks,
				totalChunks = this.totalChunks,
				currentChunk = this.currentChunk,
				chunkedvariable  = result.properties.chunkedvariable,
				chunkID = result.properties.chunkID,
				finished = result.properties.finished,
				array = [],
				progress = Math.floor(currentChunk / totalChunks * 100);

			if (finished) {
				this.processingService.processing = false;
				this.processingService.processingRequests = [];
				this.result['chunkCompleted'] = true;
				this.processingService.downloading = false;
				this.warnedPartialOutput = false;	
				this.chunkIDs = [];
				callback(this.result);
				return;
			}

			if (chunks) {
				if (!this.result.properties[chunkedvariable]) {
					this.result.properties[chunkedvariable] = [];
				}
				array = this.result.properties[chunkedvariable];
				if (this.chunkIDs.indexOf(chunkID) === -1) {
					array = array.concat(chunks);
					this.chunkIDs.push(chunkID);
				}

				if (progress > 100) {
					progress = 100;
				}

				if (progress < 100) {
					this.processingService.downloading = true;
					this.lioModalService.updateSetting('showProgress', true);
					this.lioModalService.updateSetting('progress', progress);
				} else {
					this.lioModalService.updateSetting('showProgress', false);
				}
				
				this.result.properties[chunkedvariable] = array;
				this.result['chunkedResponse'] = true;

				// Staggers results to the controller
				if (this.allowPartialOutput && totalChunks > 10) {
					this.result['warnPartial'] = !this.warnedPartialOutput;
					this.warnedPartialOutput = true;
					callback(this.result);
				}
			}

			setTimeout(() => {
				this.getChunks(token, callback, config);
			}, this.asyncTime / 100);
		});
	}

	getAsyncResult(token, callback, config:any = {}, isFinished = null) {
		if (typeof config.destroyResult === 'undefined') {
			config.destroyResult = true;
		}

		if (this.cancelRequested) {
			return;
		}

		let url = 'async/checkAsyncCompletion&token=' + token;

		url += '&destroyResult=' + config.destroyResult;

		this.chunkIDs = [];

		this.currentToken = token;

		if (this.chunked) {
			url += '&chunk=' + this.currentChunk;
			this.currentChunk++;
		}

		if (isFinished) {
			url += '&isFinished=true';
		}

		
		setTimeout(() => {
			this.submitAsyncRequest(url, token, callback, config);
		}, this.asyncTime / 10);
	}

	handleExit() {
		this.lioLogService.log(['Heard Exit']);
		this.exited = true;
		if (this.processingService.allowCancel && (this.asyncing || this.processingService.processing)) {
			this.cancel();
		}
	}

	submitAsyncRequest(url, token, callback, config:any = {}) {

		this.post(url).then((gresult:any) => {
			if (!gresult) {
				this.clearCache();
				return;
			}

			let properties 			= gresult.properties,
				totalToProcess 		= properties.totalToProcess,
				hideProgress 		= properties.hideProgress,
				currentlyProcessed 	= properties.currentlyProcessed,
				done 				= properties.done,
				totalChunks 		= properties.totalChunks,
				behindTheScenes 	= properties.behindTheScenes,
				chunkify 			= properties.chunkify,
				processingMsg 		= properties.processingMsg,
				errors 				= gresult.criticalErrors,
				messages 			= gresult.messages;

			if (messages && messages.length) {
				this.feedbackService.setMessages(messages);
			}

			if (totalToProcess) {
				if (currentlyProcessed > totalToProcess) {
					currentlyProcessed = totalToProcess;
				}

				if (!hideProgress) {
					this.lioModalService.updateSetting('showProgress', true);
					this.lioModalService.updateSetting('progress', Math.floor(currentlyProcessed / totalToProcess * 100));
				}
			}

			if (processingMsg && done == 0) {
				this.lioModalService.updateTitle(processingMsg);
			}

			if (errors.length) {
				this.lioModalService.hideLoading();
				return;
			}

			if (behindTheScenes) {
				this.lioLogService.log(['behindTheScenes', behindTheScenes]);
			}

			if (chunkify) {
				this.result = gresult;
				this.totalChunks = totalChunks;
				this.chunks = [];
				this.currentChunk = 0;
				this.getChunks(token, callback, config);
				return;
			}

			if (done == 0) {
				setTimeout(() => {
					this.getAsyncResult(token, callback, config);
				}, this.asyncTime);
			} else {
				this.asyncing = false;
				this.lioModalService.updateSetting('showProgress', false);
				//this.processingService.reset();
				callback(gresult);
			}
		});
	}

	init(requestInfo:RequestInfo) {
		this.errorsService.reset(requestInfo.urlPrepend + requestInfo.url);
		this.processingService.init(requestInfo);
		this.feedbackService.addToHistory('TASK: ' + requestInfo.urlPrepend + requestInfo.url);
		if (requestInfo.url) {
			this.errorsService.lastProcess = requestInfo.url;
		}

		if (requestInfo.params) {
			if (this.processingService.setLangIDParam && requestInfo.params !== null && typeof requestInfo.params === 'object') {
				requestInfo.params.langID = this.storageService.getLangID();
			}
			this.storageService.setLastRequest(requestInfo.params);
		}
	}
	defferedPost(requestInfo:RequestInfo) {
		if(!requestInfo.params){
			requestInfo.params = {responseType : 'json'};
		}

		return this.HttpClient.post(
			requestInfo.urlPrepend + requestInfo.url, 
			requestInfo.params, {
			headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
			responseType: requestInfo.params.responseType
		}).pipe(
			map((response:any) => {
				try {
					return response;
				}catch(e) {
					this.feedbackService.addToHistory('Ajax Error: DataService Success - Not Valid JSON');
				}
			}),
			catchError(error => {
				this.errorsService.statusCode = error.status;
				throw error;
			})
		).toPromise();
	}

	defferedGet(requestInfo:RequestInfo) {
		return this.HttpClient.post(requestInfo.urlPrepend + requestInfo.url, requestInfo.params, {
			headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
			responseType: 'json'
		}).pipe(
			map((response:any) => {
				try {
					return response;
				}catch(e) {
					this.feedbackService.addToHistory('Ajax Error: DataService Success - Not Valid JSON');
				}
			}),
			catchError(error => {
				this.errorsService.statusCode = error.status;
				throw error;
			})
		).toPromise();
	}

	//makes a simple get request
	defferedGetReal(requestInfo:RequestInfo) {
		this.init(requestInfo);

		let httpOptions:any = {};
		if(requestInfo.params && requestInfo.params.responseType){
			httpOptions.responseType = requestInfo.params.responseType;
		}

		return this.HttpClient.get(requestInfo.urlPrepend + requestInfo.url, httpOptions).pipe(
			map((response:any) => {	
				if (requestInfo.callback) {
					requestInfo.callback({
						success	: true, 
						data 	: response
					});
				}
			}),
			catchError(error => {
				this.errorsService.statusCode = error.status;
				if (requestInfo.callback) {
					requestInfo.callback({success : false});
				}
				throw error;
			})
		).toPromise();
	}

	defferedGetTask(requestInfo:RequestInfo) {
		this.init(requestInfo);

		requestInfo.urlPrepend = 'backend/tasks.php?task=';

		this.defferedGet(requestInfo).then(
			(response:any) => {
				this.handleResponse(response, requestInfo);
			},(response:any) => {
				this.handleCriticalError(response, requestInfo);
			}
		);
	}

	defferedPostTask(requestInfo:RequestInfo) {
		this.init(requestInfo);

		requestInfo.urlPrepend = 'backend/tasks.php?task=';

		this.defferedPost(requestInfo).then(
			(response:any) => {
				this.handleResponse(response, requestInfo);
			},(response:any) => {
				this.handleCriticalError(response, requestInfo);
			}
		);
	}

	handleCriticalError(response, requestInfo:RequestInfo) {
		let error = '',
			throwError = false,
			errorID = null;

		if (!response) {
			error += 'NO RESPONSE FROM SERVER';
			this.feedbackService.addToHistory('Ajax Error: ' + error);
		} else {
			if (response.message) {
				error += '<br />INVALID RESPONSE MESSAGE: ' + response.message + '<br />';
			}

			if (response.error) {
				response = response.error;
			}

			if (response.text) {
				error += '<br />INVALID RESPONSE TEXT: ' + response.text + '<br />';
			}


			if (response.criticalErrors && response.criticalErrors.length) {
				throwError = true;
				error += '<br />Critical Error Heard: ' + response.criticalErrors[0] + '<br />';
			}

			if (response.properties && response.properties.criticalErrorID) {
				errorID = response.properties.criticalErrorID;
				throwError = true;
			}
		}

		if (!error) {
			error = response;
		}

		requestInfo.currentAttempts++;

		this.clearCache();
		this.asyncing = false;
		this.processingService.removeRequest(requestInfo);

		if (requestInfo.currentAttempts >= this.maxAttemptsAllowed) {
			throwError = true;	
		}

		if (!this.configSettings.allowRetry) {
			throwError = true;
		}

		if (throwError) {
			if (requestInfo.method == 'postCross') {
				this.errorsService.errorCode = 501;
			} else {
				this.errorsService.errorCode = 500;
			}
			requestInfo.currentAttempts = 0;
			this.lastRequest = requestInfo;
			this.errorsService.setErrorID(errorID);
			this.errorsService.throwCriticalError(error);
		} else {
			this.lioLogService.log(['AJAX ERROR',  'TRYING AGAIN, ATTEMPT #' + requestInfo.currentAttempts, error, response]);
			this.feedbackService.addToHistory('Ajax Error: ' + error);
			setTimeout(() => {
				this.resend(requestInfo);
			}, 1000);
		}
		
	}

	handleResponse(response, requestInfo:RequestInfo) {
		if (!response || !response.properties) {
			this.handleCriticalError(response, requestInfo);
			return;
		}

		// Handle async that is still writing, we handle this logic in the backend
		if (response.properties.invalidResults) {
			requestInfo.params['invalidCount'] = response.properties.invalidResults;
			if (response.properties.invalidResults >= this.maxAttemptsAllowed) {
				requestInfo.currentAttempts = this.maxAttemptsAllowed;

				this.handleCriticalError('INVALID RESULTS FROM ASYNC AFTER 3 ATTEMPTS', requestInfo);
				return;
			}
			this.resend(requestInfo);
			return;
		}


		if (response.modal) {
			this.lioModalService.show(response.modal);
		}

		if (!response.properties.chunks && response.info && response.info.length) {
			response.info.forEach((info) => {
				this.feedbackService.addToHistory('INFO: ' + info, true);
			});
		}

		if (response.properties.behindTheScenes) {
			this.lioLogService.info(['Backend:', response.properties.behindTheScenes]);
		}

		if (response.messages && response.messages.length) {
			this.feedbackService.setMessages(response.messages);
		}

		if (response.history) {
			this.lioLogService.log(['Queries', response.history]);
		}


		if (response.criticalErrors.length) {
			this.handleCriticalError(response, requestInfo);
			return;
		}

		if (response.errors.length) {
			this.feedbackService.setErrors(response.errors);
		}

		if (response.properties.sql) {
			this.errorsService.sql = response.properties.sql;
		}

		if (response.properties && response.properties.task) {
			this.errorsService.task = response.properties.task;
		}

		
		if (requestInfo.callback) {
			requestInfo.callback(response);
		}
	}

	defferedPostCross(requestInfo:RequestInfo) {
		this.init(requestInfo);

		requestInfo.params = {responseType: 'text'};

		this.defferedPost(requestInfo).then((response) => {
			if (response) {
				if (requestInfo.callback) {
					requestInfo.callback(response);
				}
			} else {
				this.handleCriticalError(response, requestInfo);
			}
		},(response) => {
			this.handleCriticalError(response, requestInfo);
		});
	}

	resend(requestInfo:RequestInfo = null) {
		if(!requestInfo){
			let last = this.processingService.processingRequests.length - 1;
			requestInfo = this.processingService.processingRequests[last];
		}

		if (!requestInfo || !requestInfo.send) {
			requestInfo = this.lastRequest;
		}

		return requestInfo.send();
	}
}