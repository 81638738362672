<section class="card"><!-- Filters -->
	<div id="catalog-filters" class="row card-body">
		<!-- Collections -->
		<div class="col-5 list-group h-100" *ngIf="collections.length && isFieldVisible('filterCollections')">
			<div *ngIf="permissionService.hasPermission('catalog.admin')" 
				id="am_catalog_collection_title" 
				(click)="editCollections.emit()" 
				class="list-group-item">{{localeStrings.collectionsHeader}}</div>

			<div *ngIf="!permissionService.hasPermission('catalog.admin')" 
				id="am_catalog_collection_title" 
				class="list-group-item">{{localeStrings.collectionsHeader}}</div>
				
			<div class="list-group-item max-size-150">
				<!-- All Collections -->
				<div *ngFor="let collection of collections | orderBy: 'defaultCollection': true; index as i">
					<div id="{{'am_catalog_collection_activate_'+i}}" 
						[ngClass]="collection.active ? 'bg-warning rounded' : ''" 
						class="form-check m-1" 
						(click)="activateCollection.emit(collection)">
						<span class="form-check-label" for="collection.name">{{collection.name}}</span>
					</div>
				</div>
			</div>

			<!-- STATUS -->
			<div *ngIf="isFieldVisible('filterStatus')">
				<div class="list-group-item">STATUS:</div>
				<div class="list-group-item overflow-auto">
					
					<div class="form-check m-1" [ngClass]="releasedStatus ? 'bg-warning rounded' : ''" (click)="clickedReleased.emit()">
						<span class="form-check-label" for="collection.name">{{localeStrings.released}}</span>
					</div>
					<div class="form-check m-1" [ngClass]="unReleasedStatus ? 'bg-warning rounded' : ''" (click)="clickedUnreleased.emit()">
						<span class="form-check-label" for="collection.name">{{localeStrings.unReleased}}</span>
					</div>
				</div>
			</div>
		</div>

		<!-- TOPICS -->
		<div class="list-group h-100" *ngIf="topics.length && isFieldVisible('filterTopics')">
			<div id="am_catalog_search_topics_title" class="list-group-item">{{localeStrings.topicsHeader}}</div>
			<div class="list-group-item max-size-150">
				<!-- Featured Topics -->
				<div *ngFor="let topic of featuredTopics | orderBy:'name':false; index as i">
					<div [id]="'am_catalog_search_topic_activate_'+i" 
						class="form-check m-1 font-weight-bold" 
						[ngClass]="topic.active ? 'bg-warning rounded' : ''" 
						(click)="activateTopic.emit(topic)">
						<span class="form-check-label" for="collection.name">{{topic.name}}</span>
					</div>
				</div>
				<!-- Generic Topics -->
				<div *ngFor="let topic of genericTopics | orderBy:'name':false; index as i">
					<div [id]="'am_catalog_search_topic_activate_'+i" 
						class="form-check m-1" 
						[ngClass]="topic.active ? 'bg-warning rounded' : ''" 
						(click)="activateTopic.emit(topic)">
						<span class="form-check-label" for="collection.name">{{topic.name}}</span>
					</div>
				</div>
			</div>
		</div><!-- TOPICS -->

		<!-- LANGUAGES -->
		<div class="col-sm-4 list-group h-10" *ngIf="languageCodes.length && isFieldVisible('filterLanguages')">
			<div id="am_catalog_search_languages_title" class="list-group-item">{{localeStrings.languageHeader}}</div>
			<div class="list-group-item max-size-150">
				<!-- Core Lang -->
				<div id="am_catalog_search_core_languages_activate" 
					class="form-check m-1" 
					[ngClass]="activeCore ? 'bg-warning rounded' : ''" 
					(click)="activateCoreLangs.emit()">
					<span class="form-check-label" for="collection.name" >{{localeStrings.coreLangs}}</span>
				</div>

				<!-- All Langs -->
				<div *ngFor="let languageCode of languageCodes | orderBy:'name':false; index as i"
				localize [model]="languageCode" item="name">
					<div id="{{'am_catalog_search_language_activate_'+i}}" 
						class="form-check m-1" 
						[ngClass]="languageCode.active ? 'bg-warning rounded' : ''" 
						(click)="activateLanguage.emit(languageCode)">
						<span class="form-check-label" for="collection.name">{{languageCode.name}}</span>
					</div>
				</div>
			</div>
		</div><!-- LANGUAGES -->
	</div><!-- Show Filter -->
</section><!-- Filters -->