import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { loaderService } from '../../services/loader.service';
import { processingService } from '../../services/processing.service';
import { navService } from '../../services/nav.service';
import { loginService } from '../../services/login.service';
import { storageService } from '../../services/storage.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { lioLogService } from '../../services/lio-log.service';
import { debugService } from '../../services/debug.service';
import { settingsService } from '../../services/settings.service';
import { localizationService } from '../../services/localization.service';

@Component({
	selector: 'lio-login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnDestroy {
	public appearance		:MatFormFieldAppearance = 'outline';
	
	public model			:any 					= {
		employeeID 	: this.storageService.get('employeeID'),
		password 	: null,
		companyID 	: this.storageService.get('companyID')
	};
	public displayCompanyID:boolean 				= true;
	public showLoginScreen	:boolean 				= true;

	public localeStrings	:any 					= {
		username				:'Employee ID',
		usernameTrans			:'login.username',
		password				:'Password',
		passwordTrans			:'login.password',
		company					:'Company name or ID',
		companyTrans			:'login.company',
		login					:'Log in',
		loginTrans				:'login.login',
		forgotPassword			:'Forgot Password',
		forgotPasswordTrans		:'login.forgotPassword',
		register				:'Register',
		registerTrans			:'login.register'
	};
	public localeStringsKeys:Array<any> 			= [
		'username',
		'password',
		'company',
		'login',
		'forgotPassword',
		'register'
	];

	private params			:any 					= this.storageService.getInitialParams();
	private euid			:any  					= null;
	private SID				:any 					= null;
	private debug			:boolean 				= false;

	private subscriptions	:Subscription 			= NEVER.subscribe();

	constructor(
			@Inject(loaderService)			public	loaderService			:loaderService,
			@Inject(processingService)		public	processingService		:processingService,
			@Inject(navService)				public	navService				:navService,
			@Inject(loginService)			public	loginService			:loginService,
			@Inject(storageService)			public	storageService			:storageService,
			@Inject(lmsService)				public	lmsService				:lmsService,
			@Inject(lioModalService)		public	lioModalService			:lioModalService,
			@Inject(feedbackService)		public	feedbackService			:feedbackService,
			@Inject(stateService)			public	stateService			:stateService,
			@Inject(lioLogService)			public	lioLogService			:lioLogService,
			@Inject(debugService)			public	debugService			:debugService,
			@Inject(settingsService)		public	settingsService			:settingsService,
			@Inject(localizationService)	public	localizationService		:localizationService
	){
		this.debugService.register('login', this);
		this.navService.setActivePage('login');

		this.processingService.allowCancel = false;

		this.subscriptions.add(
			this.loginService.loggedIn.subscribe(() => {
				this.loginComplete(); 
			})
		);

		this.subscriptions.add(
			this.loginService.employeeSet.subscribe((employee) => {
				this.model = employee; 
			})
		);
	
		this.subscriptions.add(
			this.loginService.notLoggedIn.subscribe(() => {
				this.notLoggedIn(); 
				this.navService.displayPage();
			})
		);
	
		this.setParams();
		this.navService.locked = false;

		if (this.SID) {
			this.handleSSO();
		}
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}	

	/*
	 * Sets the parameters
	*/
	setParams() {
		if (!this.params) {
			return;
		}

		let params = this.params,
			euid = params.euid ? params.euid : null,
			companyID = params.companyID ? params.companyID : null,
			SID = params.SID ? params.SID : null;

		if (!companyID) {
			companyID = params.coName ? params.coName : this.stateService.getActiveCompanyID();
		}

		if (companyID && !this.loginService.invalidCompanyID) {
			this.model.companyID = companyID;
			this.storageService.set('companyID', this.model.companyID);	
		}

		if (euid && this.model.companyID) {
			this.euid  = euid;
			this.displayCompanyID = false;
		}

		if (this.loginService.invalidCompanyID) {
			this.storageService.remove('companyID');
			this.model.companyID  = null;
			this.displayCompanyID = true;
		}
		
		this.SID = SID;
	}


	/*
	 * Handles login options based onurl parameters
	 * @return {boolean}
	*/
	handleLoginFromParams() {
		this.setParams();

		if (this.SID) {
			this.showLoginScreen = false;
			this.submitLocalLogin();
			return true;
		}

		if (this.euid) {
			this.getFromEUID();
			return true;
		}

		return false;
	}

    /*
	 * Submit local login
    */
	submitLocalLogin() {
		this.lioModalService.showLoading('authenticating');
		let form = this.getLoginForm();

		return this.lmsService.post('useraccess/submitLogin', form).then((result) => {
			let session = result.properties.session;
			if (session) {
				this.stateService.setSession(session);
				this.storageService.set('employeeID', this.model.employeeID);
				this.storageService.set('companyID', this.model.companyID);
				this.lioModalService.showLoading('validatingSession');
				this.loaderService.successFullLogin();
			} else {
				this.showLoginScreen = true;
				this.lioModalService.hideLoading();
				this.params = null;
				this.stateService.setLoggedIn(false);
				this.stateService.setSession(null);
				this.storageService.set('employeeID', null);
				this.storageService.set('companyID', null);
				this.navService.displayPage();
			}
		});
    }

	/*
	 * Gets the employee from the encrypted UID 
	*/
	getFromEUID() {
		let employee = this.storageService.get('employee');

		if (employee) {
			this.model.employeeID = employee.employeeID;
			this.model.companyID = employee.companyID;
			this.storageService.set('employeeID', this.model.employeeID);
			this.storageService.set('companyID', this.model.companyID);
		}
    }

    /*
	 * Login from the inputs
    */
	login() {
		let employeeID	= this.model.employeeID,
			password	= this.model.password,
			companyID	= this.model.companyID;

		if (employeeID && password && companyID) {
			if(companyID.toString().match(/^[\$\d][\d.]+$/)) {
				companyID = companyID.toString().slice(0,9);
			}
			this.model.companyID = companyID.replace(/[\()\+]/g, '');
			this.feedbackService.clearErrors();
			this.lioModalService.showLoading('authenticating');
			this.loginWithCredentials();
		} else {
			this.feedbackService.setError('missingRequiredCredentials');
		}
	}

	/*
	 * Gets the login form
	 * @return {object}
    */
	getLoginForm() {
		return {
			'employeeID': 	this.model.employeeID,
			'password': 	this.model.password,
			'langID': 		this.storageService.getLangID(),
			'changedLang': 	this.storageService.get('changedLang'),
			'companyID': 	this.model.companyID,
			'SID': 			this.SID,
			'debugSID': 	this.debug
		};
	}

    /*
	 * Submit login with credentials
    */
    loginWithCredentials() {
		let form = this.getLoginForm();

		this.lioLogService.log('Logging in');

		this.lmsService.post('userAccess/loginWithCredentials', form).then((result) => {
			let session = result.properties.session;
			if (session) {
				this.lioLogService.log('Login Success');
				this.SID = session.SID;
				this.stateService.setSession(session);
				this.storageService.set('employeeID', this.model.employeeID);
				this.storageService.set('companyID', this.model.companyID);
				this.loaderService.successFullLogin();
			} else {
				this.lioModalService.hideLoading();
				this.params = null;
				this.stateService.setLoggedIn(false);
				this.stateService.setSession(null);
				this.storageService.set('employeeID', null);
				this.storageService.set('companyID', null);
				return null;
			}
		});
    }

    /*
	 * Not logged in, show form
    */
    notLoggedIn() {
		if (this.params) {
			this.handleLoginFromParams();
		}

		if (this.loginService.euid) {
			this.model.companyID = this.stateService.getActiveCompanyID();
			this.model.employeeID = this.stateService.getEmployeeID();
			this.loginService.euid = null;
		}
    }

	/* Ready acton
	 * @return {boolean}
    */
    ready() {
		this.navService.displayPage();
    }

   /*
	 * Handles SSO
    */
    handleSSO() {
		// First Check for an existing logged in SID 
		return this.lmsService.get('useraccess/getSID').then((result) => {
			let SID = result.properties.SID;
			// If there is a SID, log out and then log in
			if (SID && SID !== this.SID) {
				this.lioLogService.log('New SSO SID Detected, Logging Out');
				this.lmsService.get('useraccess/logOut').then(() => {
					this.handleLoginFromParams();
				});
			} else {
				// Otherwise just log in
				this.handleLoginFromParams();
			}
		});
	}

	/*
	 * Goes to the self register page
    */
	gotoRegister() {
		this.storageService.set('companyID', this.model.companyID);
		this.navService.goto('register');
	}

    /*
	 * Log in is complete, now go to wherever
    */
	loginComplete() {
		// If we just logged off, reload the page 
		if (!this.stateService.isLoggedIn()) {
			this.lioModalService.showLoading('modal.reloading');
			setTimeout(() => {
				this.navService.reload();
			}, 1000);
			return;
		}
		this.navService.locked = false;
		this.navService.goto(this.navService.getHomePage());
	}
}