import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FormGroup } from '@angular/forms';

import { localizationService } from '../../../../services/localization.service';
import { presetsSettings } from '../../../../settings/presets.settings';
import { configSettings } from '../../../../settings/config.settings';
import { presetModelService } from '../../../../services/preset-model.service';
import { browserService } from '../../../../services/browser.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';

@Component({
	selector: 'lio-presets-editor',
	templateUrl: './presets-editor.component.html',
})
export class LioPresetsEditor implements OnInit {
	@Input() appearance 			: MatFormFieldAppearance = 'fill';
	public formGroup:FormGroup = new FormGroup({});

	public disableCopy:boolean 		= true;
	public link:string 				= '';
	public model:any 				= {
		fields: []
	};
	public modelFields:any 			= this.presetsSettings.modelFields;
	public fields:any 				= this.presetsSettings.fields;
	public fieldConfig:any 			= this.presetsSettings.fieldConfig;

	constructor(
		@Inject(formValidatorService) public formValidatorService :formValidatorService,
		@Inject(feedbackService) public feedbackService :feedbackService,
		@Inject(browserService) public browserService :browserService,
		@Inject(stateService) public stateService :stateService,
		@Inject(presetsSettings) public presetsSettings :presetsSettings,
		@Inject(configSettings) public configSettings :configSettings,
		@Inject(lioModalService) public lioModalService :lioModalService,
		@Inject(debugService) public debugService :debugService,
		@Inject(presetModelService) public presetModelService :presetModelService,
		@Inject(localizationService) public localizationService :localizationService
	){
		this.debugService.register('presetsEditor', this);
	}

	ngOnInit() {
		if (!this.model.fields.length) {
			this.addRow();
		}
	}


	/*
	 * On Update
	*/
	onUpdate(field) {
		if (field.model == 'pageName') {
			this.getPresetsByPage();
		}
		if (field.model == 'paramID' && field.type == 'select') {
			this.getPresets();
		}

		this.setLink();
	}


	/*
	 * Sets the link
	*/
	setLink() {
		let link = '';
		if (this.model.pageName && this.model.paramID) {
			this.disableCopy = false;
			link = this.configSettings.serverGlobal + this.model.pageName + '?presetID=' + this.model.paramID;
			if (this.model.pageName == 'register') {
				link += '&companyID=' + this.stateService.getActiveCompanyID();
			}
		}
		this.link = link;
	}


	/*
	 * Copy link to clipboard
	*/
	copyToClipboard() {
		this.browserService.copyToClipboard(this.link);
		this.disableCopy = true;
	}


	/*
	 * Gets the presets by page
	*/
	getPresetsByPage() {
		let page = this.model.pageName;
		this.presetModelService.getPresetsByPage(page).then((preset) => {
			if (preset) {
				this.model = preset;
			}
		});
	};

	/*
	 * Gets the model by preset ID
	*/
	getPresets() {
		let paramID = this.model.paramID;
		this.model.fields = [];
		this.addRow();
		this.presetModelService.getPresetsByParamID(paramID).then((preset) => {
			if (preset) {
				this.model = preset;
			}
		});
	};



	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();
		this.model.fields.forEach((model) => {
			if (!this.formValidatorService.isValid(model, this.fields)) {
				isValid = false;
				this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			}	
		});
		

		return isValid;
	}


	/*
	 * Save
	*/
	savePresets() {
		if (!this.isValid()) {
			return;
		}
		this.lioModalService.showLoading();
		this.presetModelService.savePresets(this.model).then((result) => {
			this.lioModalService.hideLoading();
			if (result) {
				this.lioModalService.show('savedSuccessfully');
			}
			this.getPresetsByPage();
		});
	};


	/*
	 * Delete
	*/
	deletePresets() {
		this.lioModalService.showLoading();
		this.presetModelService.deleteByParamID(this.model.paramID).then((result) => {
			this.lioModalService.hideLoading();
			if (result) {
				this.lioModalService.show('deletedSuccessfully');
			}
			this.model.paramID = null;
			this.model.fields = [];
			this.getPresetsByPage();
		});
	};


	/*
	 * On Update
	*/
	compileFormat() {
	};


	/*
	 * Remove Row
	*/
	removeRow(index) {
		this.model.fields.splice(index, 1);
	};


	/*
	 * Adds row
	*/
	addRow() {
		this.model.fields.push({
			field: '',
			locked: '',
			pageName: '',
			value: '',
		});
	};
}