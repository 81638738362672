import { Component, Inject, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { navService } from '../../../../services/nav.service';
import { lmsService } from '../../../../services/lms.service';
import { storageService } from '../../../../services/storage.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { localizationService } from '../../../../services/localization.service';
import { utilService } from '../../../../services/util.service';
import { eventService } from '../../../../services/event.service';

@Component({
	selector: 'lio-event-list',
	templateUrl: './event-list.component.html',
})
export class LioEventList implements OnDestroy {
	@Output() callback		:EventEmitter<any> = new EventEmitter();
	@Input() appearance		:MatFormFieldAppearance = 'outline';

	private _eventService	:eventService = null;
	public get eventService(){
		return this._eventService;
	}
	@Input() public set eventService(val:eventService){
		this._eventService = val;
		
		//subscription to events
		this.subscriptions.add(
			this.eventService.eventsSubscription.subscribe((events) => {
				events.forEach((event, index) => {
					events[index] = this.handleEvent(event);
				});
				this.events = events;
				this.loading = false;
			})
		);

		this.subscriptions.add(
			this.eventService.onLoadEventForEditing.subscribe((event) => {
				this.onLoadEvent(event);
			})
		);

		this.eventService.getEvents();
	}

	private _settings		:any = {};
	public get settings(){
		return this._settings;
	}
	@Input() public set settings(val:any){
		this._settings = val;

		this.pagination		= this.utilService.copy(this.settings.pagination);
		this.prefixButtons	= this.utilService.copy(this.settings.prefixButtons);
		this.suffixButtons	= this.utilService.copy(this.settings.suffixButtons);
	}

	private subscriptions	:Subscription		= NEVER.subscribe();

	public filteredEvents	:Array<any>	= [];
	public loading			:boolean	= true;
	public prefixButtons	:any		= null;
	public suffixButtons	:any		= null;
	public events			:Array<any>	= [];
	public pagination		:any		= null;
	public search			:string		= '';

	public localeStrings	:any		= {
		search 					: 'Search',
		searchTrans				: 'form.search',
		searchPlaceholder 		: 'Enter Search Here',
		searchPlaceholderTrans	: 'scheduleEmails.searchPlaceholder'
	};

	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(navService) 			public navService 			:navService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(storageService) 		public storageService 		:storageService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(utilService) 			public utilService 			:utilService
	){
		this.debugService.register('eventList', this);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
 
	/**
	 * Hanldes the event schedule for the results table
 	*/
	handleEvent(event) {
		for (let item in event.schedule) {
			event[item] = event.schedule[item];
		}

		if (event.active == 1) {
			event.selected = true;
			event.activeStatus 	= 'Yes';
			event.activeColor 	= 'primary';
		} else {
			event.activeStatus 	= 'No';
			event.activeColor 	= 'warn';
		}
		return event;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEvents(collection){
		this.filteredEvents = collection.filtered;
	}

	/**
	 * Sorts events by the selected field
	 */
	sortFilteredEvents(sortField){
		if (sortField.sortable) {		
			if (sortField.type) {
				this.pagination.sortMode.fieldType = sortField.type;
			}	

			if (this.pagination.sortMode.field == sortField.model) {
				if (this.pagination.sortMode.direction == 'asc') {
					this.pagination.sortMode.direction = 'desc';
				} else {
					this.pagination.sortMode.direction = 'asc';
				}
			} else {
				this.pagination.sortMode.field = sortField.model;
			}
		}
	}

	/**
	 * Sets the array of active events
	 */
	setEvents(events){
		this.events = events;
	}

	/*
	* Validates a schedule 
	*/
	validateSchedule(model) {
		if (model.schedule.recurranceType) {
			if (model.schedule.recurranceType == 'weekly' || model.schedule.recurranceType == 'monthly') {
				if (!model.schedule.recurranceDay) {
					if (model.schedule.recurranceType == 'weekly') {
						this.feedbackService.setErrors(['Please select a day of the week for this event to run on']);
						return false;
					} else if (model.schedule.recurranceType == 'monthly') {
						this.feedbackService.setErrors(['Please select a day of the month for this event to run on']);
						return false;
					}
				}
				
				if (!model.schedule.recurranceDetail) {
					this.feedbackService.setErrors(['Please select how often this should run']);
					return false;
				}
			} else if (model.schedule.recurranceType == 'daily'){
				let schedule = model.schedule;
				if (!schedule.monday && !schedule.tuesday && !schedule.wednesday && !schedule.thursday && !schedule.friday && !schedule.saturday && !schedule.sunday) {
					this.feedbackService.setErrors(['Please select at least one day of the week for this to run']);
					return false;
				}
			}
		} else {
			this.feedbackService.setErrors(['Please select how often this should run']);
			return false;
		}

		if (!model.schedule.startTime) {
			this.feedbackService.setErrors(['Please select when this event should first run']);
			return false;
		}
		return true;
	}
	
	/** 
	 * On load of the event
	 */
	onLoadEvent(event) {
		this.storageService.set('eventID', event.id);
		this.callback.emit({'name': 'changeTab', 'value': 'edit'});
	}
}