/*
* Sends push notifications
*/
import { Observable, Subscriber } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { browserService } from './browser.service';
import { lioModalService } from './lio-modal.service';
import { localizationService } from './localization.service';
import { storageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class pushNotificationService {
	permissionDenied 		: boolean;
	shouldSendNotification 	: boolean;
	subscriber 				: Subscriber<any>;

	constructor(
		@Inject(browserService)			private browserService		: browserService,
		@Inject(lioModalService)		private lioModalService		: lioModalService,
		@Inject(localizationService)	private localizationService	: localizationService,
		@Inject(storageService)			private storageService		: storageService
	){
		this.permissionDenied 		= false;
		this.shouldSendNotification = false;
	}

	/*
	* Can we send?
	* @return {promise}
	*/
	canSend() {
		return this.browserService.canSendPushNotifications();
	}
	/*
	* Ask to send notification
	*/
	askToNotify() {
		this.shouldSendNotification = false;
		let deferred = new Observable((subscriber) => {
			this.subscriber = subscriber;

			if (this.storageService.get('deniedNotificationPermissions')) {
				this.subscriber.next(true);
				this.subscriber.complete();
				return;
			}

			if (this.storageService.get('acceptedNotificationPermissions')) {
				this.subscriber.next(true);
				this.subscriber.complete();
				return;
			}

			this.lioModalService.confirm('wouldYouLikeToSendNotification').then((confirm) => {
				if (confirm) {
					this.confirmedRequestedPushPermission().then()
				} else {
					this.deniedRequestedPushPermission();
				}
			});
		});

		return deferred.toPromise();
	}

	/*
	* Confirmed to request permisison to push
	*/
	confirmedRequestedPushPermission() {
		this.storageService.set('acceptedNotificationPermissions', true);
		this.lioModalService.hide();
		return this.requestPermission().then((result) => {
			if (result) {
				this.shouldSendNotification = true;
				this.subscriber.next(true);
				this.subscriber.complete();
			} else {
				this.subscriber.next(false);
				this.subscriber.complete();
			}
		});
	}

	/*
	* Denied to request permisison to push
	*/
	deniedRequestedPushPermission () {
		this.storageService.set('deniedNotificationPermissions', true);
		this.lioModalService.hide();
		this.subscriber.next(false);
		this.subscriber.complete();
	}

	/*
	* Request Permission to send notifications
	* @return {promise}
	*/
	requestPermission() {
		if (!this.canSend()) {
			return new Promise((resolve) => { resolve(true); });
		}
		return new Promise((resolve) => {
			const permissionResult = Notification.requestPermission((result) => {
				// Handling deprecated version with callback.
				resolve(result);
			});

			if (permissionResult) {
				permissionResult.then(resolve);
			}
		})
		.then((permissionResult) => {
			if (permissionResult !== 'granted') {
					this.permissionDenied = true;
					this.storageService.remove('acceptedNotificationPermissions');
					this.lioModalService.show('permissionDeniedForNotificaions');
					return new Promise((resolve) => { resolve(false); });
			} else {
					this.permissionDenied = false;
					return new Promise((resolve) => { resolve(true); });
			}
		});
	}

	/*
	* Send the notification
	* @param {string} title
	* @param {string} message
	* @return {boolean}
	*/
	send(title, message) {		
		title = this.localizationService.get(title);
		message = this.localizationService.get(message);
		
		if (!this.canSend()) {
			alert(title + '\n' + message);
			return true;
		}

		try {
			new Notification(title, {
				body: message
			});  
		} catch (e) {
				this.permissionDenied = true;
				this.lioModalService.show('notifications.notSupported');
			return false;
		}
		return true;
	}
}