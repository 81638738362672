/**
 * Third Party Imports
 */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularMaterialModule } from './imports/angular-material';

/**
 * Angular Stuff
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Our Native Angular Stuff
 */
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LioPagesModule } from './components/pages.module';
import { LioCatalogModule } from './modules/catalog/catalog.module';
import { LioCatalogAdminModule } from './modules/catalog-admin/catalog-admin.module';
import { LioFormsModule } from './modules/lio-forms/lio-forms.module';
import { LioModalsModule } from './modules/modals/modals.module';
import { LioPipesModule } from './pipes/pipes.module';
import { LioDirectivesModule } from './directives/directives.module';
import { LioDataDisplayModule } from './modules/data-display/data-display.module';
import { LioStructuralModule } from './modules/structural/structural.module';
import { LioDisclosuresModule } from './modules/disclosures/disclosures.module';
import { LioPermissionsModule } from './modules/permissions/permissions.module';

import { interceptService } from './services/intercept.service';

@NgModule({
	bootstrap: [ 
		AppComponent
	],
	declarations: [
		AppComponent
	],
	imports: [
		/**
		 * Third Party Imports
		 */
		FontAwesomeModule,
		AngularMaterialModule,
		/**
		 * Angular Stuff
		 */
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		/**
		 * Our Native Angular Modules
		 */
		LioCatalogModule,
		LioCatalogAdminModule,
		LioFormsModule,
		LioDataDisplayModule,
		LioModalsModule,
		LioPipesModule,
		LioDirectivesModule,
		LioPagesModule,
		LioStructuralModule,
		LioDisclosuresModule,
		LioPermissionsModule
	],
	providers : [
		{ provide: HTTP_INTERCEPTORS, useClass: interceptService, multi: true }
	]
})
export class AppModule {}
