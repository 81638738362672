<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-12">
		<!-- Search -->
		<div class="row">
			<div class="col-md-12 ml-auto" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<mat-form-field [appearance]="appearance" class="w-100" [style.fontSize.rem]="0.8">
					<label class="sr-only" for="search">{{localeStrings.search}}</label>
					<input matInput 
						id="search" 
						type="text"
						[(ngModel)]="search">
					<mat-icon matSuffix>search</mat-icon>
				</mat-form-field>
			</div>
		</div>
		<div class="card-body table-responsive p-0 rtl-direction">
			<table lio-paginator 
				controlID="searchPanelControlID" 
				[collection]="catalogs" 
				[filters]="search" 
				[settings]="searchPanelConfig.pagination" 
				(onResults)="updateFilteredCatalogs($event)" 
				class="table table-striped text-center text-dark-gray table-border-dark">
				<thead class="bg-primary">
					<tr class="text-white">
					<th class="text-white">{{localeStrings.name}}</th>
					<th class="text-white">{{localeStrings.courseID}}</th>
					<th class="text-white">{{localeStrings.status}}</th>
					<th class="text-white">{{localeStrings.edit}}</th>
					<th class="text-white">{{localeStrings.copy}}</th>
					<th class="text-white">{{localeStrings.delete}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let catalog of filteredCatalogs">
						<!-- Name -->
						<td>
							<div class="margin1" [innerHtml]="catalog.name | html"></div>
						</td>

						<!-- Course ID -->
						<td>
							<div class="margin1" [innerHtml]="catalog.courseID"></div>
						</td>

						<!-- Status -->
						<td>     
							<button [hidden]="!catalog.active" (click)="deactivateCatalog(catalog)" class="btn btn-sm btn-primary" type="submit"><strong>{{localeStrings.active}}</strong></button>
							<button [hidden]="!!catalog.active" (click)="activateCatalog(catalog)" class="btn btn-sm btn-primary" type="submit"><strong>{{localeStrings.inactive}}</strong></button>
						</td>
						<!-- Edit -->
						<td>     
							<button (click)="editCatalog(catalog)" class="btn btn-sm btn-primary mr-1" type="submit"><strong>{{localeStrings.edit}}</strong>&nbsp;&nbsp;&nbsp;<i class="fa fa-pencil-alt"></i></button>
						</td>
						<!-- Copy -->
						<td>
							<button (click)="copyCatalog(catalog)" class="btn btn-sm btn-primary mr-1" type="submit"><strong>{{localeStrings.copy}}</strong>&nbsp;&nbsp;&nbsp;<i class="fa fa-copy"></i></button>
						</td>
						
						<!-- Delete -->
						<td>     
							<button (click)="deleteCatalog(catalog)" class="btn btn-sm btn-danger mr-1" type="submit"><strong>{{localeStrings.delete}}</strong>&nbsp;&nbsp;&nbsp;<i class="fa fa-trash"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="m-4">
				<lio-paginator-controls class="row" controlID="searchPanelControlID"></lio-paginator-controls>
			</div>
		</div>
	</div>
</div>