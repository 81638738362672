import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { processingService } from '../../services/processing.service';
import { formValidatorService } from '../../services/form-validator.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { settingsService } from '../../services/settings.service';
import { storageService } from '../../services/storage.service';
import { feedbackService } from '../../services/feedback.service';
import { navService } from '../../services/nav.service';
import { lioLogService } from '../../services/lio-log.service';
import { debugService } from '../../services/debug.service';
import { utilService } from '../../services/util.service';
import { permissionService } from '../../services/permissions.service';

@Component({
	selector: 'lio-settings',
	templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnDestroy {
	public groups			:Array<any>		= [];
	public settings			:any 			= {};
	public isGlobal			:boolean		= false;
	public allowSubmit		:boolean		= false;
	public refreshing		:boolean		= false;

	public localeStrings	:any 			= {
		globalHeader 			: 'Global Learn IO Settings',
		globalHeaderTrans 		: 'spiderSettings.globalHeader',
		header 					: 'Learn IO Settings',
		headerTrans 			: 'spiderSettings.header',
		subHeader 				: 'Hover over any of the settings for a description of what it does',
		subHeaderTrans 			: 'spiderSettings.subHeader',
		update 					: 'Update',
		updateTrans 			: 'form.update',
		editGlobalSettings		: 'Edit Global Settings',
		editGlobalSettingsTrans	: 'form.editGlobalSettings',
		editLocalSettings		: 'Edit Local Settings',
		editLocalSettingsTrans	: 'form.editLocalSettings',
		buildCCF 				: 'Rebuild CCF Cache',
		buildCCFTrans 			: 'fields.buildCCF',
	};

	public localeStringsKeys:Array<any> = [
		'globalHeader',
		'header',
		'subHeader',
		'update'
	];

	private fields			:Array<any>		= [];
	private defaultSettings	:any 			= {};

	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
										private changeDetectorRef	:ChangeDetectorRef,
		@Inject(navService)				public	navService			:navService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(loaderService)			public	loaderService		:loaderService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioLogService)			public	lioLogService		:lioLogService,
		@Inject(processingService)		public	processingService	:processingService,
		@Inject(formValidatorService)	public	formValidatorService:formValidatorService,
		@Inject(settingsService)		public	settingsService		:settingsService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(permissionService)		public	permissionService	:permissionService
	){
		this.debugService.register('learnioSettings', this);
		this.navService.setActivePage('learnioSettings');

		this.processingService.allowCancel = false;

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.getFields();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	resetField(field) {
		if (!this.defaultSettings[field]) {
			delete this.settings[field];
			this.defaultSettings = this.utilService.copy(this.settings);
			return;
		}

		this.lioModalService.showLoading('processing');

		this.navService.changedForm = false;
		
		this.changeDetectorRef.detectChanges();

		this.lmsService.post('settings/deleteSetting', {'field': field, 'isGlobal': this.isGlobal}).then((result) => {
			this.allowSubmit = false;
			if (result.success) {
				delete this.settings[field];
				this.defaultSettings = this.utilService.copy(this.settings);
				this.refreshSettings();
			} else {
				this.lioModalService.hideLoading();
			}
        });
	}

	/*
	 * Loads the setting fields
	*/
	getFields() {
		this.refreshing = true;
		this.lmsService.get('settings/getAllFieldsAndSettings').then((result) => {
			let success = result.success,
				fields = result.properties.fields,
				settings = result.properties.settings;

			if (success) {
				this.setFields(fields);
				this.setSettings(settings);
				this.compileGroups();
				this.setDefaults();
				this.navService.displayPage();
				this.refreshing = false;
			}
		});
	}

	/*
	 * Sets the fields
    */
	setFields(settingFields) {
		let fieldKeys = Object.keys(settingFields),
			field,
			ccfs = this.storageService.get('ccfs'),
			ccf;

		fieldKeys.forEach((key) => {
			field = settingFields[key];
			// Get the actual name of the CCF
			if (field.isCCF) {
				ccf = ccfs[field.key];
				if (ccf) {
					if (field.isCCFtype) {
						field.name = 'Type for CCF' + field.index + ' (' + ccf + ')'; 
					} else if (field.isCCFLoad) {
						field.name = 'Load CCF' + field.index + ' (' + ccf + ')'; 
					}
				}
			}
			field.default = '';
			field.model = key;
			field.visible = true;
			this.fields.push(field);
		});
	}

	/*
	 * Sets the settings
	*/
	setSettings(settings) {
		let field;
		this.settings = {};

		let settingsKeys = Object.keys(settings);
		settingsKeys.forEach((key) => {
			field = this.getFieldByModelName(key);
			if (!field) {
				this.lioLogService.log(['Field not available for ', key]);
				return;
			}
			this.settings[key] = settings[key];
			if (field.type === 'boolean') {
				if (settings[key]) {
					this.settings[key] = '1';
				} else {
					this.settings[key] = '0';
				}
			}
			if(field.options){
				let fieldOptionKeys = Object.keys(field.options);
				fieldOptionKeys.forEach((optionKey) => {
					if (field.options[optionKey].value == settings[key]) {
						field.options[optionKey].selected = true;
					}
				});
			}

			if (field.model == 'emailTemplateLangField') {
				let fieldOptionKeys = Object.keys(field.options);
				fieldOptionKeys.forEach((optionKey) => {
					if (field.options[optionKey].value == settings[key]) {
						field.options[optionKey].selected = true;
					}
				});
			}
			this.defaultSettings = this.utilService.copy(this.settings);
		});
	}

	/*
	 * Compule the groups
    */
	compileGroups() {
		let groupsObj = {},
			groups = [];

		// Organize all fields into its designated group
		this.fields.forEach((field) => {
			if (!field.group) {
				this.lioLogService.log(['Field missing a group', field]);
				return;
			}
			if (!groupsObj[field.group]) {
				groupsObj[field.group] = {'fields': [field], 'name': field.group};
			} else {
				groupsObj[field.group].fields.push(field);
			}
		});

		let groupKeys = Object.keys(groupsObj);
		// Convert to an array
		groupKeys.forEach((key) => {
			let visible = false;
			groupsObj[key].fields.forEach((field) => {
				if (field.visible) {
					visible = true;
				}
			});
			groupsObj[key].visible = visible;
			groups.push(groupsObj[key]);
		});

		this.groups = groups;
	}

	/*
	 * Gets the field by model name
	*/
	getFieldByModelName(modelName) {
		let foundField = null;
		this.fields.forEach((field) => {
			if (field.model === modelName) {
				foundField = field;
			}
		});
		return foundField;
	}


	buildCCF() {
		this.lioModalService.showLoading('processing');
		
		return this.lmsService.post('fields/buildCCF', {}).then(() => {
			this.lioModalService.show('savedSuccessfully');
		});
	}

	/*
	 * On update
	 * @param {boolean} apply
	*/
	onUpdate() {
		this.navService.changedForm = true;
		this.allowSubmit = true;
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
		this.changeDetectorRef.detectChanges();
	}

	/*
	 * Edits the Global Settings
    */
	editGlobalSettings() {
		this.isGlobal = true;
		this.refreshing = true;
		this.lmsService.get('settings/getAllGlobalSettings').then((result) => {
			let success = result.success,
				settings = result.properties.settings,
				fields = result.properties.fields;

			if (success) {
				this.setSettings(settings);
				this.fields = [];
				this.setFields(fields);
				this.compileGroups();
				this.setDefaults();
				this.refreshing = false;

			}
		});
	}

	setDefaults() {
		let setting;
		this.fields.forEach((field) => {
			setting = this.settings[field.model];
			if (!setting) {
				this.settings[field.model] = field.default;
			}
		})
	}

	/*
	 * Edits the Local Settings
    */
	editLocalSettings() {
		this.isGlobal = false;
		this.fields = [];
		this.getFields();
	}

	/*
	 * Checks if the form is valid
    */
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (!this.formValidatorService.isValid(this.settings, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.settings = this.formValidatorService.getData();
		
		return isValid;
	}

	/*
	 * Refresh the settings
	*/
	refreshSettings() {
		return this.lmsService.get('settings/getSettings').then((result) => {
			let success = result.success;
			let settings = result.properties.settings;

			if (success) {
				this.setSettings(settings);
				this.storageService.set('settings', settings);
				this.settingsService.initSettings(settings);
			}
			this.lioModalService.hideLoading();
		});
	}

	/*
	 * Gets the changed settings
	 * @return {?object}
    */
	getChangedSettings() {
		let changedSettings = {},
			hasChanges = false;

			let settingsKeys = Object.keys(this.settings);
			settingsKeys.forEach((key) => {
			let defaultSetting = this.defaultSettings[key];
			if (defaultSetting != this.settings[key]) {
				changedSettings[key] = this.settings[key];
				hasChanges = true;
			}
		});

		if (hasChanges) {
			return changedSettings;
		}
		return null;
	}

	/*
	 * Submits the edit
    */
    submit() {
		let settings = this.getChangedSettings();

		if (!this.isValid()) {
			return;
		}

		if (!settings) {
			this.lioModalService.show('No changes detected');
			return;
		}

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lioModalService.showLoading('processing');

		this.navService.changedForm = false;

		this.lmsService.post('settings/edit', {'settings': settings, 'isGlobal': this.isGlobal}).then((result) => {
			
			this.allowSubmit = false;

			if (result.success) {
				this.refreshSettings().then(() => {
					this.buildCCF().then(() => {
						this.lioModalService.show('savedRefresh');
						setTimeout(() => {
							this.navService.reload();
						}, 3000);
					})
				});
			} else {
				this.lioModalService.hideLoading();
			}
        });
    }	
}