import { Component, OnDestroy, Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { utilService } from '../../services/util.service';
import { trainingSettings } from './training.settings';

@Component({
	selector: 'lio-training',
	templateUrl: './training.component.html'
})
export class TrainingComponent implements OnDestroy {
	
	public panelConfig		:any 		= this.utilService.copy(this.trainingSettings.panelConfig);

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(trainingSettings) 		public trainingSettings		:trainingSettings
	){
		this.navService.setActivePage('training');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.navService.displayPage();
	}

}