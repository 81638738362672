<div class="container-fluid mx-0 mr-n5 mb-1" *ngIf="ready && built">
	<!-- Rules -->
	<lio-query-rule *ngFor="let rule of getRules(); index as i" 
		[formGroup]="formGroup"
		[ruleIndex]="i"
		[filters]="cleanFilters"
		[rule]="rules[i]" 
		(delete)="deleteRule(rule)"
		(updatedRule)="updatedBuilder.emit(rule)"
		[hideDelete]="settings.hideDelete" 
		class="row" 
		[ngClass]="{'mr-n4' : !settings.hideDelete}"
		[revalidate]="revalidate">
	</lio-query-rule>

	<!-- Buttons -->
	<div class="row mt-1 mr-n4" *ngIf="settings.hasButtons">
		<div class="btn-toolbar col-md-11 col-10 px-1">
			<button [disabled]="!ready || getRuleCount() > maxRuleCount" id="am_add_rule" (click)="addRule()" class="btn btn-sm btn-primary mb-1" type="submit">
				<i class="fa fa-plus mr-1" aria-hidden="true"></i>
				<span localize [model]="addRuleLabel" item="name">{{addRuleLabel.name}}</span>
			</button>
			<button [disabled]="!ready" id="am_reset_query" (click)="reset()" class="btn btn-sm btn-link mb-1 text-danger ml-auto hc-dark-text" type="submit">
				<i class="fa fa-sync-alt mr-1" aria-hidden="true"></i>
				<span localize [model]="resetLabel" item="name">{{resetLabel.name}}</span>
			</button>
			<button [hidden]="settings.hideRunReport" [disabled]="!ready" id="am_create_dash" (click)="run()" class="btn btn-sm mb-1 btn-primary" type="submit">
				<span localize [model]="runReportLabel" item="name">{{runReportLabel.name}}</span>
			</button>
		</div>
	</div>

</div>