import { Component, OnDestroy, Inject, ViewChild } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { processingService } from '../../services/processing.service';
import { lmsService } from '../../services/lms.service';
import { permissionService } from '../../services/permissions.service';
import { lioModalService } from '../../services/lio-modal.service';
import { fieldService } from '../../services/fields.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';

import { delegateAccessSettings } from './delegate-access.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
	selector: 'lio-delegate-access',
	templateUrl: './delegate-access.component.html'
})
export class DelegateAccessComponent implements OnDestroy {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache					:PanelTabCache 	= null;

	public fields							:Array<any>		= this.delegateAccessSettings.fields;
	public fieldConfig						:any			= this.delegateAccessSettings.fieldConfig;
	public delegatePanelConfig				:any			= this.delegateAccessSettings.delegatePanelConfig;
	public existingdelegationsPanelConfig	:any 			= this.delegateAccessSettings.existingdelegationsPanelConfig;
	public settings							:any			= this.delegateAccessSettings.settings;
	public existingDelegations				:Array<any> 	= [];
	public employees						:Array<any> 	= [];
	public filteredEmployees				:Array<any> 	= [];
	public filteredDelegations				:Array<any> 	= [];
	public searchPrefixButtons				:Array<any> 	= [
		{
			header 		: 'Delegate',
			name 		: 'Delegate',
			id 			: 'am_results_delegate',
			color 		: 'primary',
			callback 	: (employee) => { this.addDelegation(employee); }
		}
	];

	public existingDelegationsPrefixButtons	:Array<any> 	= [
		{
			header 		: 'Remove',
			name 		: 'Remove',
			id 			: 'am_results_remove_delegate',
			color 		: 'warn',
			callback 	: (employee) => { this.removeDelegation(employee); }
		}
	];

	private subscriptions					:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService				:navService,
		@Inject(debugService)			public	debugService			:debugService,
		@Inject(processingService)		public	processingService		:processingService,
		@Inject(lmsService)				public	lmsService				:lmsService,
		@Inject(permissionService)		public	permissionService		:permissionService,
		@Inject(lioModalService)		public	lioModalService			:lioModalService,
		@Inject(fieldService)			public	fieldService			:fieldService,
		@Inject(feedbackService)		public	feedbackService			:feedbackService,
		@Inject(stateService)			public	stateService			:stateService,
		@Inject(delegateAccessSettings)	public	delegateAccessSettings	:delegateAccessSettings
	){
		this.debugService.register('delegateAccess', this);
		this.navService.setActivePage('delegateAccess');

		this.processingService.allowCancel 	= false;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.getDelegations().then(() =>{
					this.navService.displayPage();
				});
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Checks if an employee is already being delegated
	 * @param {object} employee
	 * @return {boolean}
	*/
	exists(employee) {
		let exists = false;
		this.existingDelegations.forEach((existingDelegation) => {
			if (existingDelegation.employeeID === employee.employeeID) {
				exists = true;
			}
		});

		return exists;
	}

	/*
	 * Adds a delegation
	 * @param {object} employee
	*/
	addDelegation(employee){
		if (this.exists(employee)) {
			this.lioModalService.show('This user is already being delegated');
			return;
		}

		this.lioModalService.showLoading('processing');
		return this.lmsService.post('impersonation/addDelegation', {inheritorEmployeeID : employee['employeeID']}).then((result) => {
			this.lioModalService.hideLoading();
			
			if (result.success){
				this.existingDelegations = result.properties.delegations;
				this.panelTabCache.selectTabByIndex(1);
				this.lioModalService.show('delegation.addSuccess');
			} else {
				this.lioModalService.show('delegation.addFail');
			}
		});
	}

	/*
	 * Tab change
	*/
	onTabChange() {
		this.feedbackService.clearAll();
	}

	/*
	 * Removes a delegation
	 * @param {object} employee
	*/
	removeDelegation(employee){
		this.lioModalService.showLoading('processing');

		return this.lmsService.post('impersonation/removeDelegation', {inheritorEmployeeID : employee['employeeID']}).then((result) => {
			this.lioModalService.hideLoading();
		
			if(result.success){
				this.existingDelegations = result.properties.delegations;
				this.lioModalService.show('delegation.removeSuccess');
			} else {
				this.lioModalService.show('delegation.removeFail');
			}
		});
	}

	/*
	 * Sets the employees
	 * @param {array} employees
	*/
	recieveResults(employees) {
		this.employees = employees;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredDelegations(collection) {
		this.filteredDelegations = collection.filtered;
	}

	/**
	 * Gets the list of existing delegations
	 */
	getDelegations() {
		return this.lmsService.post('impersonation/getDelegations').then((result) => {
			if (result.success) {
				this.existingDelegations = result.properties.delegations;
			}
		});
	}
}