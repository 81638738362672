import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';

import { fieldService } from './../../../../services/fields.service';
import { debugService } from './../../../../services/debug.service';

@Component({
	selector: 'lio-buttons',
	templateUrl: './buttons.component.html'
})
export class LioButtons{
	@Input() 	buttons?	: Array<any>;
	@Output() 	onClick		: EventEmitter<any> 		= new EventEmitter();
	@Output() 	onUpload	: EventEmitter<any> 		= new EventEmitter();
	@Input() 	model?		: any;

	constructor(
		@Inject(fieldService) public fieldService :fieldService,
		@Inject(debugService) public debugService :debugService
	){
		this.debugService.register('buttons', this);
	}

	ngOnInit() {
		this.setButtons();
	}


	handleUploaded(button, $event) {
		if (button.onSuccess) {
			button.onSuccess($event);
		}
		this.onUpload.emit({'fn': button.onUploadCmd, 'event': $event});
	}

	handleFailure(button, $event) {
		if (button.onFailure) {
			button.onFailure($event);
		}
		this.onUpload.emit({'fn': button.onFailureCmd, 'event': $event});
	}


	/*
	* Set button values as needed
	*/
	setButtons() {
		if (this.buttons) {
			this.buttons.forEach((button) => {
				if (!button.class) {
					button.class = 'btn-primary';
				}
			});
		}
	}
}