<form role="form" autocomplete="off" novalidate>
	<div class="row mt-2">
		<div class="col-12">
			<lio-result-filters
				[searchConfig]="settings.fieldConfig"
				[search]="searchHistory"
				[searchFields]="settings.searches">
			</lio-result-filters>
			<div lio-paginator
				[controlID]="settings.id"
				[collection]="history"
				[filters]="searchHistory"
				[settings]="settings.pagination"
				(onResults)="updateFilteredHistory($event)">
				<lio-results-table
					[paginatorID]="settings.id"
					[fields]="settings.fields"
					[results]="filteredHistory">
				</lio-results-table>
			</div>
			<lio-paginator-controls [controlID]="settings.id"></lio-paginator-controls>
		</div>
	</div>
</form>