<!-- fields -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Fields</mat-card-title>
	<mat-card-content id="{{'am_form_fields_' + i}}" [style.fontSize.px]="11" class="mb-0" *ngFor="let field of model.fields; index as i;">
		<div class="row no-gutters" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Alias -->
				<input id="{{'am_form_fields_' + i + '_Alias'}}" 
					matInput
					placeholder="Alias" 
					[(ngModel)]="field.alias" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Field -->
				<input id="{{'am_form_fields_' + i + '_Model'}}" 
					matInput
					placeholder="Model" 
					[(ngModel)]="field.model" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Name -->
				<input id="{{'am_form_fields_' + i + '_Name'}}" 
					matInput
					placeholder="Name" 
					[(ngModel)]="field.name" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<mat-label>Type</mat-label>
				<!-- Type -->
				<mat-select id="am_form_{{field.model}} + '_Type'"
					[(ngModel)]="field.type"> 

					<!-- Options List -->
					<mat-option
						*ngFor="let option of formdata.fieldTypes"
						[model]="option"
						[value]="option.value" 
						localize
						item="name">
						{{option.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<span (click)="formdata.remove('fields', field)" class="col col-xl-2 fa fa-minus-square fa-2x p-1 button"></span>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- New field -->
		<button mat-button color="primary" (click)="formdata.addNew('fields')">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">Add field</span>
		</button>

		<!-- Employee field -->
		<button mat-button color="primary" (click)="formdata.addEmployeeFields()">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">Employee fields</span>
		</button>

		<!-- CCF fields -->
		<button mat-button color="primary" (click)="formdata.addCCFFields()">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">CCF fields</span>
		</button>

		<!-- Enrollment fields -->
		<button mat-button color="primary" (click)="formdata.addEnrollmentFields()">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">Enrollment fields</span>
		</button>
	</mat-card-actions>
</mat-card>