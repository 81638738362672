<!-- Footer -->
<footer [hidden]="!navService.showFooter" class="footer">
	<div [innerHtml]="iframeService.iframeContents | html"></div>
	<div id="am_pageFooter" class="text-right bg-learn text-white small p-2">
		<div id="am_pageFooter_inner">
			<div id="am_pageFooter_text" class="row no-gutters">
				<div class="col-12 col-sm-auto ml-auto">
					<span id="am_pageFooter_copyright">Copyright True Office Learning &copy;</span>
					<span id="am_pageFooter_year" class="mx-1">{{currentTime | date :  "yyyy"}}</span>
				</div>
				<div class="col-12 col-sm-auto">
					<span id="am_pageFooter_version">v.{{configSettings.version}}</span>
					<span id="am_pageFooter_divider" class="mx-1">|</span>
					<span id="am_pageFooter_privacy">
						<a class="text-white" href="https://www.trueofficelearning.com/privacy/" target="_blank">Privacy Policy</a>
					</span>
				</div>
				<div class="col-12 col-sm-auto">
					<span class="mx-1 d-none d-sm-inline-block" id="am_pageFooter_divider2">|</span>
					<a id="am_pageFooter_link" class="text-white" href="https://www.trueofficelearning.com" target="_blank">trueofficelearning.com</a>
				</div>
			</div>
		</div>
	</div>
	<div id="bottom"></div>
</footer>