import { Inject, Injectable } from '@angular/core';
import { LioFieldArray, LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';
import { lioModalService } from '../../../../services/lio-modal.service';
import { localizationService } from '../../../../services/localization.service';
import { popupService } from '../../../../services/popup.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: mailTemplateSettings
})
export class mailTemplateSettings {
	constructor(
		@Inject(popupService) private popupService:popupService,
		@Inject(localizationService) private localizationService:localizationService,
		@Inject(lioModalService) private lioModalService:lioModalService
	){}

	appearance:string = 'outline';

	modelName:string = 'Mail Template';

	amID:string = 'am_form_templateID';

	modelNameTrans:string = 'emailTemplate.modelName';

	copyable:boolean = true;

	sortable:boolean = true;

	endpoints:any = {
		create				: 'emailtemplate/save',
		copy				: 'emailtemplate/save',
		getAll				: 'emailtemplate/getAll',
		save				: 'emailtemplate/save',
		delete				: 'emailtemplate/delete',
	};
	
	additionalButtons:Array<any> = [
		{
			id 		: 'am_view_instructions',
			name	: 'instructions', 
			trans	: 'email.instructions', 
			action	: () => { this.popupService.showInstructions(); }
		},
		{
			id 		: 'am_getSample',
			name	: 'Get Sample', 
			trans	: 'email.getSample', 
			action	: (model, setModel) => {
				if (model) {
					let localizationKey = model.courseIDs.length > 1 ? 'emailTemplateMultiCourse' : 'emailTemplate';
					
					switch (model.type) {
						case 'Completion':
							model.name = 'Completion Email Template';
							model.fromAddress = 'donotreply@trueoffice.com';
							model.fromName = 'True Office Learning';
							model.replyTo = 'donotreply@trueoffice.com';
							model.subject = this.localizationService.get(localizationKey + '.sampleCompletionSubject');
							model.message = this.localizationService.get(localizationKey + '.sampleCompletionMessage');
							break;
						case 'Registration':
							model.name = 'Registration Email Template';
							model.fromAddress = 'donotreply@trueoffice.com';
							model.fromName = 'True Office Learning';
							model.replyTo = 'donotreply@trueoffice.com';
							model.subject = this.localizationService.get(localizationKey + '.sampleRegistrationSubject');
							model.message = this.localizationService.get(localizationKey + '.sampleRegistrationMessage');
							break;
						case 'Reminder':
							model.name = 'Reminder Email Template';
							model.fromAddress = 'donotreply@trueoffice.com';
							model.fromName = 'True Office Learning';
							model.replyTo = 'donotreply@trueoffice.com';
							model.subject = this.localizationService.get(localizationKey + '.sampleReminderSubject');
							model.message = this.localizationService.get(localizationKey + '.sampleReminderMessage');
							break;
						case 'Past Due':
							model.name = 'Past Due Template';
							model.fromAddress = 'donotreply@trueoffice.com';
							model.fromName = 'True Office Learning';
							model.replyTo = 'donotreply@trueoffice.com';
							model.subject = this.localizationService.get(localizationKey + '.samplePastDueSubject');
							model.message = this.localizationService.get(localizationKey + '.samplePastDueMessage');
							break;
						default:
							return this.lioModalService.show('Please select a template type');
					}
					setModel(model);
				}
			}
		}
	];

	customConfirmations:Array<any> = [
		{
			operations 	: ['save'],
			callback 	: (model) => {
				if (model.activeEvent) {
					return this.lioModalService.confirm('activeEventsWarning');
				}
				return new Promise((resolve) => {resolve(true);});
			}
		},
		{
			operations 	: ['delete'],
			callback 	: (model) => {
				if (model.eventID && !model.archivedEvent) {
					return this.lioModalService.show('eventDeniedTemplateDeletion');
				} else {
					let macros = [];
					if (model.name) {
						macros = [{'key': 'name', 'value': model.name}];
					}
					return this.lioModalService.confirm('areYouSureDeleteName', null, macros);
				}
			}
		}
	];

	panelConfig:any = {
		id 				: 'panelTab_email_template_form',
		title			: 'Edit Email Templates',
		titleTrans		: 'scheduleEmails.editTemplateTitle',
		subtitle		: 'Keywords can be used in templates to customize them for each individual employee',
		subtitleTrans	: 'scheduleEmails.editTemplateSubtitle',
		tab				: 'Email Templates',
		tabTrans		: 'scheduleEmails.editTemplateTab'
	};

	fieldConfig:any = {
		'fieldName'			: 'mailtemplate',
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: false,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			'name'					: 'Name',
			'model'					: 'name',
			'type'					: 'text',
			'required'				: true,
			'visible'				: true,
			'sortable'				: true
		},
		{
			'name'					: 'From Address',
			'model'					: 'fromAddress',
			'type'					: 'email',
			'required'				: true,
			'visible'				: true,
			'checkForHTMl'			: true
		},
		{
			'name'					: 'From Name',
			'model'					: 'fromName',
			'type'					: 'text',
			'required'				: true,
			'visible'				: true,
			'checkForSpecialChars'	: true,
			'checkForHTMl'			: true
		},
		{
			'name'					: 'Reply To Address',
			'model'					: 'replyTo',
			'type'					: 'email',
			'required'				: false,
			'visible'				: true,
			'checkForHTMl'			: true
		},
		{
			'name'					: 'CC Addresses',
			'model'					: 'cc',
			'type'					: 'email',
			'required'				: false,
			'visible'				: true,
			'checkForHTMl'			: true,
		},
		{
			'name'					: 'Bounceback Address',
			'model'					: 'bounceBack',
			'type'					: 'email',
			'required'				: false,
			'visible'				: false,
			'checkForHTMl'			: true,
		},
		{
			'name'					: 'Subject',
			'model'					: 'subject',
			'type'					: 'text',
			'required'				: true,
			'visible'				: true,
			'checkForSpecialChars'	: true,
			'checkForHTMl'			: true,
			'regex'					: /\~|\!|\#|\$|\%|\^|\*|\=|\|\||\\|\<|\>|\?|\;/g
		},
		{
			'name'					: 'Message',
			'model'					: 'message',
			'type'					: 'textarea',
			'required'				: true,
			'visible'				: true,
			'checkForHTMlInEmail'	: true
		},
		new LioSearchableSelectField({
			'name'					: 'Type',
			'model'					: 'type',
			'type'					: 'select',
			'required'				: true,
			'visible'				: true,
			'options'				:  [
				{
					'value'				: 'Registration',
					'name'				: 'Registration',
					'trans'				: 'emailEvent.registration',
					'selected'			: false,
				},
				{
					'value'				: 'Reminder',
					'name'				: 'Reminder',
					'trans'				: 'emailEvent.reminder',
					'selected'			: false,
				},
				{
					'value'				: 'Past Due',
					'name'				: 'Past Due',
					'trans'				: 'emailEvent.pastDue',
					'selected'			: false,
				},
				{
					'value'				:	'Completion',
					'name'				:	'Completion',
					'trans'				:	'emailEvent.completion',
					'selected'			:	false,
				},
			]
		}),
		{
			'name' 		: 'Date Created',
			'model'		: 'creationDate',
			'visible'	: false,
			'sortable'	: true
		},
		{
			'name' 		: 'Date Modified',
			'model'		: 'modificationDate',
			'visible'	: false,
			'sortable'	: true,
			'sortOrder' : ['modificationDate', 'creationDate']
		}
	)
}