import { Pipe, PipeTransform } from '@angular/core';
import { localizationService } from '../../services/localization.service';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {
constructor(private localizationService: localizationService) { }
	transform(value) {
		if (this.localizationService.isRTL() && Array.isArray(value)) {
			return value.slice().reverse();
		}
		return value;
	}
}