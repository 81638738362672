import { Component, Input, Inject } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../services/localization.service';

import { LioSearchableSelectField } from 'src/app/modules/lio-forms/lio-forms.models';

@Component({
	selector: 'lio-disclosure-title',
	templateUrl: './title.component.html',
})
export class LioDisclosureTitle{
	@Input() settings		:any 					= null;
	@Input() model			:any 					= null;
	@Input() appearance 	:MatFormFieldAppearance = 'outline';

	public showReportField:LioSearchableSelectField = new LioSearchableSelectField({
		name 		: 'Show Report',
		model 		: 'showReport',
		options 	: [
			{
				name 		: 'true',
				nameTrans	: 'form.true',
				value 		: true
			},
			{
				name 		: 'false',
				nameTrans	: 'form.false',
				value 		: false
			}
		]
	});
	
	public languageField:LioSearchableSelectField = new LioSearchableSelectField({
		name 			: 'Disclosure Language',
		model 			: 'langID',
		options 		: this.localizationService.availableLanguages,
		optionValueField: 'code',
		nameFormatter	: (_name, option) => { return  option.lang + ' : ' + option.name},
	});

	constructor(@Inject(localizationService) private localizationService:localizationService){}
}