import { Inject, Injectable } from '@angular/core';
import { permissionService } from '../../services/permissions.service';
import { utilService } from '../../services/util.service';
import { LioSearchableSelectField, LioFieldArray, LioField } from '../../modules/lio-forms/lio-forms.models';
import { PanelTabData } from '../../modules/structural/structural.models';
import { companyService } from '../../services/company.service';

// Class for add/edit company fields.
export class LioCompanyField extends LioField {
	// Specify whether the field is shown on the Add Company form.
	public addCompanyDisplay:boolean = true;
	// Specify whether the field is shown on the Search Companies page.
	public searchCompaniesDisplay:boolean = true;

	constructor(obj:Partial<LioCompanyField>){
		super(obj);
		Object.assign(this, obj);
	}
}

export class CCFField extends LioSearchableSelectField{
	public groupableModel 			:string;
	public visibleModel				:string;

	constructor(obj:Partial<CCFField>){
		super(obj);
		Object.assign(this, obj);
	}
}

@Injectable({
	providedIn	: 'root',
	useClass	: companyAdminSettings
})
export class companyAdminSettings {

	constructor(
		@Inject(permissionService) private permissionService:permissionService,
		@Inject(companyService) private companyService:companyService,
		@Inject(utilService) private utilService:utilService
	){}

	companyIDField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 				: 'am_companyadmin_companyIDs',
		name				: 'Company ID',
		nameTrans			: 'form.companyID',
		model				: 'companyID',
		addEmptyOption		: true,
		searchable 			: true,
		optionNameField 	: 'coName',
		optionValueField 	: 'companyID',
		nameFormatter		: (_name, option) => { return  option.companyID + ' : ' + option.coName},
	});

	panelConfig:{
		search	:PanelTabData,
		add		:PanelTabData,
		edit	:PanelTabData
	} = {
		search : new PanelTabData({
			id					: 'panelTab_company_admin_search',
			title				: 'Search Companies',
			titleTrans			: 'companyadmin.searchCompanies',
			tab					: 'Search Companies',
			tabTrans			: 'companyadmin.searchCompanies',
			reloadOnTabChange	: true,
			bodyClass 			: 'card-body p-0',
			pagination 			: {
						id 			: 'companySearchControlID',
						pageLimit	: '15',
						pageLimits	: ['5', '10', '15', '25', '50'],
						sortMode	: {'field' : 'companyID', 'direction' : 'desc'}
			},
			visible				: () => {
				return this.permissionService.hasPermission('pages.company');
			}
		}),
		add : new PanelTabData({
			id					: 'panelTab_company_admin_add',
			title				: 'Please fill out the required(*) fields in the form below',
			titleTrans			: 'companyadmin.addEditSubHeader',
			tab					: 'Add Company',
			tabTrans			: 'companyadmin.addCompany',
			reloadOnTabChange	: true,
			visible				: () => {
				return this.permissionService.hasPermission('pages.company');
			}
		}),
		edit : new PanelTabData({
			id					: 'panelTab_company_admin_edit',
			tab					: 'Edit Company',
			tabTrans			: 'companyadmin.editCompany',
			reloadOnTabChange	: true,
			visible				: () => {
				return this.permissionService.hasPermission('pages.company');
			}
		})
	};

	editPanelTitles:any = {
		'noCompanyIDTitle' 		: 'Please select a company ID to edit',
		'noCompanyIDTitleTrans'	: 'companyadmin.selectCompanyID',
		'companyIDTitle' 		: 'Please fill out the required(*) fields in the form below',
		'companyIDTitleTrans' 	: 'companyadmin.addEditSubHeader'
	};

	pagination:any = {
		pageLimit	: '10',
		pageLimits	: ['10', '15', '25', '50'],
		sortMode	: {'field' : 'companyID', 'direction' : 'desc'}
	};

	fieldsSet:any = false;

	loadFields:LioFieldArray = new LioFieldArray(
		new LioSearchableSelectField({
			'name'		: 'Company ID',
			'model'		: 'companyIDs',
			'type'		: 'select',
			'required'	: true,
			'visible'	: true,
			'options'	: []
		})
	);

	fieldConfig:any = {
		'fieldName'			: 'companyfields',
		'localizeFields'	: true,
		'permissionFields'	: true,
	};

	editFields = new LioFieldArray(
		new LioCompanyField({
			'name' : 'Company ID',
			'model' : 'companyID',
			'type' : 'text',
			'validateAs' : 'number',
			'required' : true,
			'visible' : true,
			'min' : 2,
			'max' : 999999999,
			'addCompanyDisplay' : false,
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'checkForHTMl': true,
			'sortable' : true,
		}),
		new LioCompanyField({
			'name'					: 'Company Name',
			'model'					: 'coName',
			'type'					: 'text',
			'required'				: true,
			'visible'				: true,
			'min'					: 2,
			'max'					: 70,
			'checkForSpecialChars'	: true,
			'regex'					: /\`|\~|\!|\#|\$|\%|\^|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;/g,
			'checkForHTMl'			: true,
			'sortable'				: true,
		}),
		new LioCompanyField({
			'name'					: 'Company Login Name',
			'model'					: 'coLoginName',
			'type'					: 'text',
			'required'				: true,
			'varchar'				: true,
			'min'					: 1,
			'max'					: 100,
			'checkForSpecialChars'	: true,
			'checkForHTMl'			: true,
			'sortable'				: true,
		}),
		new LioCompanyField({
			'name': 'Branding ID',
			'model': 'coBrandID',
			'type': 'number',
			'required': true,
			'varchar': false,
			'min': 0,
			'max': 999999999,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
			'visible': 'pages.branding',
			'addCompanyDisplay' : false,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name': 'Default Password',
			'model': 'defaultPassword',
			'type': 'text',
			'required': false,
			'varchar': false,
			'min': 1,
			'max': 70,
			'visible': true,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name'			: 'Active',
			'model'			: 'active',
			'type'			: 'checkbox',
			'required'		: false,
			'visible'		: true,
			'varchar'		: false,
			'addCompanyDisplay' : false,
			'sortable' 		: true,
			'searchCompaniesDisplay' : false,

		}),
		new LioCompanyField({
			'name'		: 'Edit Profile',
			'model'		: 'editprofile',
			'type'		: 'checkbox',
			'required'	: false,
			'visible'	: true,
			'varchar'	: false,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name'		: 'Allow Change Password',
			'model'		: 'changePassword',
			'type'		: 'checkbox',
			'required'	: false,
			'visible'	: true,
			'varchar'	: false,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name'		: 'Randomizes Password',
			'model'		: 'randomizesPassword',
			'type'		: 'checkbox',
			'required'	: false,
			'visible'	: true,
			'varchar'	: false,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name'		: 'Allow Spider SSO',
			'model'		: 'allowSpiderSSO',
			'type'		: 'checkbox',
			'required'	: false,
			'visible'	: true,
			'varchar'	: false,
			'searchCompaniesDisplay' : false,
		}),
		new LioCompanyField({
			'name'			: 'Change Language',
			'model'			: 'changeLanguage',
			'type'			: 'checkbox',
			'required'		: false,
			'visible'		: true,
			'varchar'		: false,
			'addCompanyDisplay' : false,
			'searchCompaniesDisplay' : false,
		}),
	);

	createFields = this.utilService.copy(this.editFields).filter(field => field.addCompanyDisplay);
	searchFields = this.utilService.copy(this.editFields).filter(field => field.searchCompaniesDisplay);

	searchFieldSuffixButtons:Array<any> = [
		{
			name 		: 'Active',
			id 			: 'am_companyedit_active',
			color 		: 'warn',
			dynamicColor: 'activeColor',
			dynamicName	: 'activeStatus',
			callback	: (event) => {
				this.companyService.toggleStatus(event);
			},
			model		: 'active',
			sortable 	: true
		},
		{
			name 		: 'Edit',
			id 			: 'am_companyedit_edit',
			color 		: 'primary',
			callback	: (event) => {
				this.companyService.editCompany(event);
			},
		},
		{
			name 		: 'Copy',
			id 			: 'am_companyedit_copy',
			color 		: 'warn',
			icon 		: '',
			callback	: (event) => {
				this.companyService.copyCompany(event);
			},
		},
	];

	companyFieldConfig:any = {
		'fieldName'			: 'companyAdmin',
		'addCCFtoFields'	: false,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: true,
		'addLangField'		: false,
		'addActiveField'	: false,
		'allowSpecificUser'	: true,
	};

	subCompanyFields:LioFieldArray = new LioFieldArray(
		new LioCompanyField({
			'name': 'Company Name',
			'model': 'coName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 2,
			'max': 70,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
			'visible': true,
		}),
		new LioCompanyField({
			'name': 'Company Login Name',
			'model': 'coLoginName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 1,
			'max': 70,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
			'visible': true,
		}),
		new LioCompanyField({
			'name': 'Branding ID',
			'model': 'coBrandID',
			'type': 'number',
			'required': false,
			'varchar': false,
			'min': 0,
			'max': 999999999,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
			'visible': 'pages.branding',
		}),
	);

	subCompanyFieldConfig:any = {
		'fieldName'			: 'subCompanyFields',
		'localizeFields'	: true,
		'permissionFields'	: true,
	};

	model:any = {
		'companyID'			: 0,
		'coName'			: '',
		'coLoginName'		: '',
		'editprofile'		: 0,
		'active'			: 1,
		'changePassword'	: 0,
		'randomizesPassword': 0,
		'allowSpiderSSO'	: 0,
		'changeLanguage'	: 1,
	};

	customFieldGroups:Array<{
		name	:string,
		id		:string,
		visible	:string|boolean,
		fields	:LioFieldArray
	}> = [
		{
			name	: "Custom Fields 1-10",
			id		: "g1",
			visible	: 'company.editccf',
			fields	: [
				new CCFField({
					"name":"Custom Field 1",
					"model":"clientCustomField1",
					"groupableModel": "customField1Groupable",
					"visibleModel": "customField1Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 2",
					"model":"clientCustomField2",
					"groupableModel": "customField2Groupable",
					"visibleModel": "customField2Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 3",
					"model":"clientCustomField3",
					"groupableModel": "customField3Groupable",
					"visibleModel": "customField3Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 4",
					"model":"clientCustomField4",
					"groupableModel": "customField4Groupable",
					"visibleModel": "customField4Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 5",
					"model":"clientCustomField5",
					"groupableModel": "customField5Groupable",
					"visibleModel": "customField5Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 6",
					"model":"clientCustomField6",
					"groupableModel": "customField6Groupable",
					"visibleModel": "customField6Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 7",
					"model":"clientCustomField7",
					"groupableModel": "customField7Groupable",
					"visibleModel": "customField7Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 8",
					"model":"clientCustomField8",
					"groupableModel": "customField8Groupable",
					"visibleModel": "customField8Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 9",
					"model":"clientCustomField9",
					"groupableModel": "customField9Groupable",
					"visibleModel": "customField9Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 10",
					"model":"clientCustomField10",
					"groupableModel": "customField10Groupable",
					"visibleModel": "customField10Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
			]
		},
		{
			"name":"Custom Fields 11-20",
			"id":"g2",
			"visible": true,
			"fields": [
				new CCFField({
					"name":"Custom Field 11",
					"model":"clientCustomField11",
					"groupableModel": "customField11Groupable",
					"visibleModel": "customField11Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 12",
					"model":"clientCustomField12",
					"groupableModel": "customField12Groupable",
					"visibleModel": "customField12Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 13",
					"model":"clientCustomField13",
					"groupableModel": "customField13Groupable",
					"visibleModel": "customField13Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 14",
					"model":"clientCustomField14",
					"groupableModel": "customField14Groupable",
					"visibleModel": "customField14Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 15",
					"model":"clientCustomField15",
					"groupableModel": "customField15Groupable",
					"visibleModel": "customField15Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 16",
					"model":"clientCustomField16",
					"groupableModel": "customField16Groupable",
					"visibleModel": "customField16Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 17",
					"model":"clientCustomField17",
					"groupableModel": "customField17Groupable",
					"visibleModel": "customField17Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 18",
					"model":"clientCustomField18",
					"groupableModel": "customField18Groupable",
					"visibleModel": "customField18Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 19",
					"model":"clientCustomField19",
					"groupableModel": "customField19Groupable",
					"visibleModel": "customField19Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 20",
					"model":"clientCustomField20",
					"groupableModel": "customField20Groupable",
					"visibleModel": "customField20Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
			]
		},
		{
			"name":"Custom Fields 20-30",
			"id":"g3",
			"visible": true,
			"fields": [
				new CCFField({
					"name":"Custom Field 21",
					"model":"clientCustomField21",
					"groupableModel": "customField21Groupable",
					"visibleModel": "customField21Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 22",
					"model":"clientCustomField22",
					"groupableModel": "customField22Groupable",
					"visibleModel": "customField22Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 23",
					"model":"clientCustomField23",
					"groupableModel": "customField23Groupable",
					"visibleModel": "customField23Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 24",
					"model":"clientCustomField24",
					"groupableModel": "customField24Groupable",
					"visibleModel": "customField24Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 25",
					"model":"clientCustomField25",
					"groupableModel": "customField25Groupable",
					"visibleModel": "customField25Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 26",
					"model":"clientCustomField26",
					"groupableModel": "customField26Groupable",
					"visibleModel": "customField26Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 27",
					"model":"clientCustomField27",
					"groupableModel": "customField27Groupable",
					"visibleModel": "customField27Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 28",
					"model":"clientCustomField28",
					"groupableModel": "customField28Groupable",
					"visibleModel": "customField28Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 29",
					"model":"clientCustomField29",
					"groupableModel": "customField29Groupable",
					"visibleModel": "customField29Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
				new CCFField({
					"name":"Custom Field 30",
					"model":"clientCustomField30",
					"groupableModel": "customField3-Groupable",
					"visibleModel": "customField30Visible",
					"type":"select",
					'required': false,
					'min': 2,
					'max': 2000,
					"options":[],
					"visible":true,
					'checkForHTMl': true,
					'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
				}),
			]
		},
	];
}
