import { Inject, Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';
import { employeeSearchSettings } from '../employee-search/employee-search.settings';

import { querySettings } from '../../settings/query.settings';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEnrollmentFilterSettings
})
export class scheduleEnrollmentFilterSettings {
	constructor(
		@Inject(employeeSearchSettings)		private employeeSearchSettings	:employeeSearchSettings,
		@Inject(querySettings) 				private querySettings			:querySettings
	){}

	queryToolSettings:any = {
		'type'					: 'scheduledEnrollments',
		'addBaseFilters'		: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'showSaver'				: true,
		'usesQueryTool'			: true,
		'filterOverrideID'		: 'scheduleEnrollmentsFilters',
		'text'				: {
			'runReport'			: 'enrollquery.loadResults',
		},
		'endpoints'				: {
			'get'					: 'query/getAllByType',
			'save'					: 'query/save',
			'delete'				: 'query/deleteByID',
			'report'				: 'enrollment/getEmployeesByRules',
		},
		'visibilityOptions': [
			{
				'name'		: 'All Users',
				'value'		: 'public',
				'locked'	: 'query.savePublicly',
			}
		],
	};

	eventFieldConfig:any = {
		'fieldName'			: 'scheduleEnrollments',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'addActiveField'	: false,
		'overrideFields'	: true,
	};

	fields:any 				= this.employeeSearchSettings.fields;
	fieldConfig:any 		= this.employeeSearchSettings.fieldConfig;
	pagination:any 			= this.employeeSearchSettings.pagination;

	tableFields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			nameTrans 	: 'emailEvent.name',
			model		: 'name'
		}, 
		{
			name		: 'Course ID',
			nameTrans 	: 'emailEvent.courseID',
			model		: 'courseID'
		}, 
	);

	filters:Array<any> = [
		{
			'id': 'Status',
			'field': 'e.inactive',
			'name': 'status',
			'model': 'inactive',
			'nameTrans': 'form.status',
			'type': 'select',
			'width': 300,
			'visible': true,
			'options': [
				{
					'value': 0,
					'nameTrans': 'form.active',
					'name': 'active',
					'selected': false,
				},
				{
					'value': 1,
					'nameTrans': 'form.inactive',
					'name': 'inactive',
					'selected': false,
					'locked': 'enrollment.enrollInactive',
				}
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'removeDefault': 'e.inactive'
		}
	];
}