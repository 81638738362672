
<div class="wrapper wrapper-content">
	<div class="row">
		<div class="col-md-10 mx-auto">
			<!-- Search users form -->
			<lio-card headerText="Search Email Audit Logs" subHeaderText="Searches all manual emails that come directly from Learn IO" [showClose]="true">
				<div lio-card-body>
					<form class="form-group" role="form" autocomplete="off" novalidate>
						<lio-form-inputs
							[fields]="searchFields"
							[model]="searchers"
							(onChanged)="onupdate()">
						</lio-form-inputs>
						<button mat-flat-button color="primary" [disabled]="!allowSubmit" (click)="submit()" type="submit">
							<strong>Search Logs</strong>
						</button>
					</form>
				</div>
			</lio-card>
		</div>
	</div>

	<div class="row mt-2" [hidden]="!filteredLogs.length">
		<div class="col-md-10 mx-auto">
			<lio-card bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
				<div lio-card-body 
					lio-paginator 
					controlID="auditlog" 
					[collection]="logs" 
					[settings]="pagination" 
					(onResults)="updateFilteredLogs($event)">

					<lio-results-table
						paginatorID="auditlog"
						[fields]="fields"
						[results]="filteredLogs">
					</lio-results-table>
				</div>
				<div lio-card-footer>
					<lio-paginator-controls class="row" controlID="auditlog"></lio-paginator-controls>
					<!-- Buttons -->
					<div class="btn-toolbar">
						<button id="am_logsearch_exportrecords" 
							*ngIf="permissionService.hasPermission('reports.export')" 
							mat-flat-button
							color="primary"
							type="button" 
							(click)="exportRecords()">

							<span trans="global.exportrecords">Export Records</span>
							<mat-icon>file_download</mat-icon>
						</button>
					</div>
				</div>
			</lio-card>
		</div>
	</div>
</div>