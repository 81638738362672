/*
 * Service for Controlling a user's own state info
*/
import cloneDeep from 'lodash/cloneDeep';
import objectHash from 'object-hash';
import moment from 'moment';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { debugService } from '../services/debug.service';
import { Subscriber } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class utilService {
	constructor(
		@Inject(debugService)			private debugService		:debugService,
		@Inject(DOCUMENT) 				private document 			:Document
	){
		this.debugService.register('utility', this);
	}


	/**
	 * Copy command using loaddash
	 * @param {array|object} object
	 * @return {array|object}
	 */
	copy(object:any) {
		if (typeof object === 'undefined') {
			return object;
		}
		return cloneDeep(object);
	}
	//second syntax for copy so that it is clear the copy being performed is deep
	deepCopy(object:any){
		return this.copy(object);
	}

	/**
	 * Copy command using assign
	 * Outer reference will change, but any properties that reference objects will keep their references
	 * @param {array|object} object
	 * @return {array|object}
	 */
	shallowCopy(object:any = {}){
		if (typeof object === 'undefined') {
			return object;
		}else if (object instanceof Array) {
			return object.slice();
		}
		return Object.assign({}, object);
	}

	/**
	 * Detects whether two items are equal
	 * @param {array|object} 	item1 				- the 1st item to check
	 * @param {array|object} 	item2 				- the 2nd item to check
	 * @param {boolean} 		strict 				- whether we should only match on strict equality
	 * @param {boolean} 		deep 				- whether to do a shallow or deep equality check
	 * @param {boolean} 		equalWhenCircular 	- if set to true, when a circular reference is detected, items will be considered equal
	 * @param {boolean} 		referencedItems 	- a collection of items that have already been seen, for circular reference detection
	 */
	itemEquals(item1, item2, strict:boolean = false, deep:boolean = false, equalWhenCircular:boolean = false, referencedItems:Array<any> = []){
		//make a copy of referenced items since we only care about seeing an object twice within a single child branch
		let newReferencedItems = referencedItems.slice();
		if(deep 
		&& (referencedItems.indexOf(item1) >= 0 
		|| referencedItems.indexOf(item2) >= 0)){
			//circular reference detected, revert to simple equality to avoid an infinite loop
			return equalWhenCircular || (!strict && item1 == item2) || item1 === item2;	
		}else{
			if(item1 instanceof Object){
				newReferencedItems.push(item1);
			}
			if(item2 instanceof Object){
				newReferencedItems.push(item2);
			}
		}

		//subscribers cannot be properly evaluated for equality, we'll assume the are equal
		if(item1 instanceof Subscriber && item2 instanceof Subscriber){
			return true;
		}

		if(deep && Array.isArray(item1)){
			if(Array.isArray(item2)){
				return this.arrayEquals(item1, item2, strict, deep, equalWhenCircular, newReferencedItems);
			}else{
				return false;
			}
		}else if(deep && typeof(item1) == 'object'){
			if(typeof(item2) == 'object'){
				return this.objectEquals(item1, item2, strict, deep, equalWhenCircular, newReferencedItems);
			}else{
				return false;
			}
		}else if((!strict && item1 != item2) || (strict && item1 !== item2)){
			return false;
		}
		return true;
	}

	/**
	 * Function for determining if two objects are equal
	 */
	objectEquals(object1:any = {}, object2:any = {}, strict:boolean = false, deep:boolean = false, equalWhenCircular:boolean = false, referencedItems:Array<any> = []):boolean{
		if(typeof object1 == 'undefined' && typeof object2 == 'undefined'){
			return true;
		} else if(typeof object1 == 'undefined'){
			return false;
		}else if(typeof object2 == 'undefined'){
			return false;
		}

		if(object1 == null && object2 == null){
			return true;
		}else if(object1 == null){
			return false;
		}else if(object2 == null){
			return false;
		}

		let keys1 = Object.keys(object1);
		let keys2 = Object.keys(object2);

		if(keys1.length == keys2.length){
			let foundNonMatching = false;

			keys1.forEach((key, i) => {
				if(key != keys2[i] 
				|| !this.itemEquals(object1[key], object2[key], strict, deep, equalWhenCircular, referencedItems)){
					foundNonMatching = true;
				}
			});

			if(!foundNonMatching){
				return true;
			}
		}

		return false;
	}

	/**
	* WARNING
	* expensive on large objects
	*/
	deepObjectEquals(object1:any = {}, object2:any = {}, strict:boolean = false, equalWhenCircular:boolean = false):boolean{
		let startTime	:number 	= Date.now();
		let result		:boolean 	= this.objectEquals(object1, object2, strict, true, equalWhenCircular);
		let timeTaken 	:number 	= Date.now() - startTime;
		if(timeTaken > 1000){
			//Log an event if this takes too long
			let keys1 = Object.keys(object1);
			let keys2 = Object.keys(object2);
			console.log(['Deep Object Equality Check took ' + (timeTaken/1000).toFixed() + ' seconds. Keys of the objects checked will follow.', keys1, keys2]);
		}
		return result;
	}

	/**
	 * Function for determining if two objects are equal in a shallow way
	 */
	shallowObjectEquals(object1:any = {}, object2:any = {}, strict:boolean = false, equalWhenCircular:boolean = false):boolean{
		return this.objectEquals(object1, object2, strict, false, equalWhenCircular);
	}

	/**
	 * Function for determining if two objects are equal
	 */
	arrayEquals(array1:Array<any> = [], array2:Array<any> = [], strict:boolean = false, deep:boolean = false, equalWhenCircular:boolean = false, referencedItems:Array<any> = []):boolean{
		if(array1 === null){
			if(array2 === null){
				return true;
			}
			return false;
		}
		if(array1.length == array2.length){
			let foundNonMatching = false;

			array1.forEach((item, i) => {
				if(!this.itemEquals(item, array2[i], strict, deep, equalWhenCircular, referencedItems)){
					foundNonMatching = true;
				}
			});

			if(!foundNonMatching){
				return true;
			}
		}

		return false;
	}

	/**
	 * Function for determining if two arrays are equal in a shallow way
	 */
	shallowArrayEquals(array1:Array<any> = [], array2:Array<any> = [], strict:boolean = false, equalWhenCircular:boolean = false):boolean{
		return this.arrayEquals(array1, array2, strict, false, equalWhenCircular);
	}

	/**
	 * Function for determining if two arrays are equal in a deep way
	 * WARNING
	 * expensive on large arrays
	 */
	deepArrayEquals(array1:Array<any> = [], array2:Array<any> = [], strict:boolean = false, equalWhenCircular:boolean = false):boolean{
		return this.arrayEquals(array1, array2, strict, true, equalWhenCircular);
	}

	/**
	 * Extends an object
	 * @param {array|object} object
	 * @param {array|object} object
	 * @return {array|object}
	 */
	extend(object1, object2) {
		return Object.assign(object1, object2);
	}


	/**
	 * Hash an object
	 * @param {any} item
	 * @param {any} item2
	 * @return {string}
	 */
	hash(item:any, item2?:any) {
		let hash = '',
			hash2 = '';
		
		if (item) {
			hash = objectHash(item);
		}
		if (item2) {
			hash2 = objectHash(item2);
			hash = objectHash(hash + hash2);
		}
		
		return hash;
	}


	/*
	* Determines if an object is empty
	* @param {object} obj
	* @return {boolean} 
	*/
	isEmpty(obj) {
		var i;
		for (i in obj) {
			if (Object.prototype.hasOwnProperty.call(obj,i)) {
				return false;
			}
		}
		return true;
	}

	/*
	* Gets a random number between 2 digits
	* @param {?number} min 
	* @param {?number} max
	* @return {number} 
	*/
	randomNumber(min = 0, max = 10000000000) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/*
	* Validates a string
	* @param {string} str
	* @return {boolean} 
	*/
	isString(str) {
		if (typeof str !== 'string') {
			return false;
		}
		return str.search(/[a-z]/i) > -1;
	}

	/*
	* Validates a string is alphanumeric
	* @param {string} str
	* @return {boolean} 
	*/
	isAlphaNumeric(str) {
		if (!this.isString(str)) {
			return false;
		}
		
		var code, i, len;

		for (i = 0, len = str.length; i < len; i++) {

			code = str.charCodeAt(i);

			if (code == 32 || code == 46 || code == 45 || code == 50  || code == 64|| code == 95) {
				continue;
			} 
			
			if (!(code > 47 && code < 58) && // numeric (0-9)
				!(code > 64 && code < 91) && // upper alpha (A-Z)
				!(code > 96 && code < 123)) { // lower alpha (a-z)
				return false;
			}
		}
		return true;
	}
	
	/*
	* Validates a string does not contain special chars
	* @param {string} str
	* @param {string} regex
	* @return {boolean} 
	*/
	hasSpecialChars(value, regex) {
		var regexSpecialChar = regex ? regex : /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			hasBadChars = false;
		if (value && value.toString().match(regexSpecialChar)) {
			hasBadChars = true;
		}
		return hasBadChars;
	}

	/*
	* Validates a string does not contain html tags
	* @param {string} str
	* @return {boolean} 
	*/
	hasHTML(value) {
		var regexHTMLChar = new RegExp("\<\/?([a-zA-Z']+[1-6]?)(\s[^>]*)?(\s?\/)?\>"),
			isValid = true;

		if (value && value.toString().match(regexHTMLChar)) {
			isValid = false;
		}
		return isValid;
	}

	/*
	* Validates a string only contains numbers
	* @param {string} str
	* @return {boolean} 
	*/
	isNumeric(str) {
		//Replacement for JQuery isNumeric function
		return !isNaN(str) 
			&& str !== "" 
			&& str !== null 
			&& str !== true 
			&& str !== false 
			&& str !== Infinity;
	}

	/*
	* Validates a string has an upper case letter
	* @param {string} str
	* @return {boolean} 
	*/
	hasUpper(str) {
		if (typeof str !== 'string') {
			return false;
		}
		return str.search(/[A-Z]/) > -1;
	}

	/*
	* Validates a string has a lower case letter
	* @param {string} str
	* @return {boolean} 
	*/
	hasLower(str) {
		if (typeof str !== 'string') {
			return false;
		}
		return str.search(/[a-z]/) > -1;
	}

	/*
	* Converts something to a boolean
	* @param {any} val
	* @return {boolean} 
	*/
	toBool(val:any):boolean {
		if(val === true 
		|| (typeof val == 'string' 
			&& val !== 'false' 
			&& val !== 'False' 
			&& val !== '0' 
			&& val !== ''
		)
		|| (typeof val == 'number' 
			&& val > 0
		)
		|| (Array.isArray(val)
			&& val.length > 0
		)
		|| (typeof val == 'object'
			&& val != {}
		)){
			return true;
		}
		return false;
	}

	/*
	* Converts to a boolean
	* @param {string} str
	* @return {boolean} 
	*/
	hasNumber(str) {
		return /\d/.test(str);
	}

	/*
	* Validates a string has a special character
	* @param {string} str
	* @return {boolean} 
	*/
	hasSpecialChar(str) {
		return /[?!$%*^{}~@#&()\-+_=]+/.test(str);
	}

	/*
	* Validates a string
	* @param {string} str
	* @param {string} haystack
	* @return {boolean} 
	*/
	inString(needle, haystack) {
		if (typeof needle !== 'string' || typeof haystack !== 'string') {
			return false;
		}
		var reg = new RegExp(needle);
		return reg.test(haystack);
	}

	/*
	* Validates a variable is defined
	* @param {*} variable
	* @return {boolean} 
	*/
	isDefined(variable) {
		return typeof variable !== 'undefined';
	}

	/*
	* Reverses a string
	* @param {string}
	* @return {string} 
	*/
	reverseString(str) {
		var newString = "";
		for (var i = str.length - 1; i >= 0; i--) {
			newString += str[i];
		}
		return newString;
	}

	/*
	* Is a valid email 
	* @param {string}
	* @return {boolean} 
	*/
	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	/*
	* Determines if string is in array
	* @param {array} value1
	* @param {string]array} value2
	* @param {matcher} matcher
	* @return {boolean} 
	*/
	inArray(value1, value2, matcher = 'id') {
		if (typeof value2 !== 'string') {
			return this.arrayinArray(value1, value2, matcher);
		}
		return value1.indexOf(value2) > -1;
	}

	/*
	* Determines if string is in array
	* @param {array} value1
	* @param {string]array} value2
	* @param {matcher} matcher
	* @return {boolean} 
	*/
	valueinArray(array, value) {
		return array.indexOf(value) > -1;
	}

	/*
	* Determines if array is in arrays
	* @param {array[array]} value1
	* @param {array} value2
	* @param {string} matcher
	* @return {boolean} 
	*/
	arrayinArray(arrays, arrayMatch, matcher = 'id') {
		var total = arrays.length,
			array,
			i;

		for (i = 0; i < total; i++) {
			array = arrays[i];
			if (array[matcher]) {
				if (array[matcher] === arrayMatch[matcher]) {
					return true;
				}
			}
		}
		return false;
	}

	/*
	* Gets query string as object 
	* @param {array[array]} value1
	* @param {array} value2
	* @param {string} matcher
	* @return {boolean} 
	*/
	queryStringToJSON() { 
		var query = this.document.defaultView.location.href.split('?')[1],         
			pairs = query ? query.split('&') : null,
			pair,
			total = pairs ? pairs.length : 0,
			result = {},
			i;

		if (!query) {
			return;
		}
		
		for (i = 0; i < total; i++) {
			pair = pairs[i];
			pair = pair.split('=');
			result[pair[0]] = decodeURIComponent(pair[1] || '');
		}
			
		return JSON.parse(JSON.stringify(result));
	}

	/*
	* Converts object to query string 
	* @param {object} queryObject
	* @return {string} 
	*/
	JSONtoQueryString(queryObject) { 
		var query = '',
			object,
			index = 0,    
			i;

		for (i in queryObject) {
			object = queryObject[i];
			if (index) {
				query += '&';
			}
			query += i + '=' + object;
		}
			
		return query;
	}

	/*
	* Converts Message String into Message Object
	* @param {string} key
	* @param {string} macros
	* @return {string}
	*/
	localizeMessage(key, macros = {}) {
		return {'key': 'message.' + key, 'macros': macros};
	}

	/*
	* Converts Message String into Message Array
	* @param {string} key
	* @param {string} macros
	* @return {array}
	*/
	localizeMessages(key, macros = {}) {
		return [this.localizeMessage(key, macros)];
	}

	/*
	* Converts Error String into Error Object
	* @param {string} key
	* @param {string} macros
	* @return {string}
	*/
	localizeError(key, macros = null) {
		return {'key': 'error.' + key, 'macros': macros};
	}

	/*
	* Converts Error String into Error Array
	* @param {string} key
	* @param {string} macros
	* @return {array}
	*/
	localizeErrors(key, macros) {
		return [this.localizeError(key, macros)];
	}

	/*
	* Validates if a string is a valid password
	* @param {string} password
	* @return {!string}
	*/
	getPasswordError(password) {
		var badSpecial = /'|;|--|#|\/\*|\*\/|<|>|(EXEC|VARCHAR|NVARCHAR|CAST|CONVERT)\s+/,
			pass = password ? password.trim() : '',
			macros = [];
		
		if (!pass) {
			return this.localizeError('missingPass', 'Missing Password');
		}

		if (pass.length < 8) {
			macros.push({'key': 'min', 'value': 8});
			return this.localizeError('passMustBeAtLeast8Chars', macros);
		}

		if (pass.length > 128) {
			macros.push({'key': 'max', 'value': 128});
			return this.localizeError('passIsToLong', macros);
		}

		if (badSpecial.test(pass)) {
			return this.localizeError('passCantContainSpecialChars');
		}

		return '';
	}

	/*
	* Gets a date one year from today
	* @param {string} date ie "07-25-2020"
	* @param {string} format ie "mm/dd/yyyy"

	* @return {string}
	*/
	addOneYearFromProvidedDate(dateString, format) {
		//strip milliseconds
		dateString = dateString.replace(/\.\d{3}/g, '');

		var nextYear = new Date(new Date(dateString).setFullYear(new Date(dateString).getFullYear() + 1)),
			addedDay = new Date(nextYear.setDate(nextYear.getDate() + 1));

		let momentDate = moment(addedDay, format.toUpperCase());
		return momentDate.format(format.toUpperCase());
	}

	/*
	* Gets a date from today
	* @param {number} offset
	* @return {string}
	*/
	getDateOffset(offset:number = 0) {
		var date = new Date();
		date.setDate(date.getDate() + offset);
		return date;
	}

	/*
	* Gets a date from today
	* @param {number} offset
	* @return {string}
	*/
	getDate(offset, format) {
		offset = offset ? offset : 0;
		var today = new Date(), 
			date = new Date(today.getTime() + (offset * 24 * 60 * 60 * 1000));

		return this.formatDate(date, format);
	}

	/*
	* Gets a current time stamp
	* @return {string}
	*/
	getTimeStamp() {
		var today = new Date();

		return this.formatDate(today, 'mm-dd-yy HH:MM:ss:mm');
	}

	/*
	* Formats date as time stamp
	* @param {string} date
	* @return {string}
	*/
	formatTimeStamp(date) {
		if (!date) {
			return '';
		}
		if (typeof date === 'string') {
			date = date.replace(/-/g, '/');
			//strip milliseconds
			date = date.replace(/\.\d{3}/g, '');
		}

		let momentDate = moment(new Date(date));
		return momentDate.format('yyyy-MM-DD HH:mm:ss');
	}

	/*
	* Formats a date
	* @param {string} date ie 11/23/2010
	* @param {string} format ie yyyy-mm-dd
	* @return {string} ie 2016-11-23
	*/
	formatDate(date, format = 'mm-dd-yyyy') {
		if (!date) {
			return '';
		}
		if (typeof date === 'string') {
			date = date.replace(/-/g, '/');
			//strip milliseconds
			date = date.replace(/\.\d{3}/g, '');
		}

		let momentDate = moment(new Date(date));
		return momentDate.format(format.toUpperCase());
	}

	/*
	* Replaces all instances in a string
	* @param {string} str
	* @param {string} from
	* @param {string} to
	* @return {string}
	*/
	replaceAll(str, from, to) {
		var lb = "" + from + "",
			re = new RegExp(lb, 'g');
		return str.replace(re, to);
	}

	/*
	* Is the date in the future?
	* @param {string} future
	* @param {string} offset
	* @return {boolen}
	*/
	isFutureDate(future, offset:number = 0) {
		var today = this.formatDate(this.getDateOffset(offset), 'yyyy-mm-dd'),
			todayDate = new Date(today),
			futureDate = new Date(future),
			todayTime = todayDate.getTime(),
			futureTime = futureDate.getTime();

		return futureTime > todayTime;
	}

	/**
	 * Removes all properties from an object
	 * Used to set an object to {} without destroying references to it
	 */
	removeObjectProperties(obj){
		if(typeof obj == 'object'){
			let keys = Object.keys(obj);
			for(let i = 0; i < keys.length; i++){
				delete obj[keys[i]];
			}
		}
	}

	/**
	 * Hightlights text based on search criteria
	 */
	highlight(input:string = '', search:string = ''): string {
		if(!input || !search){
			return input;
		}
		return input.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>');
	}
}