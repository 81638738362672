/*
 * Service for Controlling errors/message feedback
*/
import moment from 'moment';
import 'moment-timezone';

import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { lioLogService } from './lio-log.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class feedbackService{
	messages 	: Array<any>;
	errors 		: Array<any>;
	emitError	: boolean = true;
	history 	: Array<any>;
	rawHistory 	: Array<any>;
	public addedFeedback : ReplaySubject<void> 	= new ReplaySubject(1);
	public resetFeedback : ReplaySubject<void> 	= new ReplaySubject(1);

	constructor(
		@Inject(lioLogService)			private lioLogService		: lioLogService,
		@Inject(localizationService)	private localizationService	: localizationService,
		@Inject(utilService)			private utilService			: utilService,
		@Inject(debugService)			private debugService		: debugService
	){
		this.messages 		= [];
		this.errors			= [];

		this.history 		= [];
		this.rawHistory 	= [];
		this.debugService.register('feedback', this);
	}

	clearMessages() {
		this.messages = [];
		this.resetFeedback.next();
	}

	clearErrors() {
		this.errors = [];
		this.resetFeedback.next();
	}
	
	clearAll() {
		this.messages = [];
		this.errors = [];
		this.resetFeedback.next();
	}

	getMessages() {
		return this.messages;
	}

	getErrors() {
		return this.errors;
	}
	
	hasFeedback() {
		return this.errors.length || this.messages.length;
	}

	getHistory() {
		return this.history;
	}
	

	/*
	 * Sets the message from a key and macros
	 * @param {string|array} message
	 * @param {?array} macros
	*/
	setMessage(message, macros?:any) {
		if (!message) {
			return '';
		}
		if (!message['key']) {
			message = this.utilService.localizeMessage(message, macros);
		}
		return this.setMessages([message]);
	}

	/*
	 * Sets the messages from a localized message array
	 * @param {array} mesaages
	*/
	setMessages(messages) {
		this.clearMessages();

		messages.forEach((message) => {
			this.addMessage(message);
		});

		return this.messages; 
	}

	addMessage(message){
		let key 	= message['key'] ? message['key'] : message,
			macros 	= message['macros'],
			text 	= this.localizationService.get(key, '', macros);

		if (!text) {
			text = message;
		}

		if (this.messages.indexOf(text) == -1) {
			this.lioLogService.log(['Message:', text]);
			this.messages.push(text);
			this.addToHistory('Info: ' + text);
			this.addedFeedback.next();
		}
	}

	/*
	 * Sets the error from a key and macros
	 * @param {string|array} error
	 * @param {?array} macros
	*/
	setError(error, macros = null) {
		if (!error['key']) {
			error = this.utilService.localizeError(error, macros);
		}
		return this.setErrors([error]);
	}

	/*
	 * Sets the errors from a localized error array
	 *  - the emitter activates the scroll down mechanism from the nav service
	 *  - some times we dont want this to occur on each error 
	 * @param {array} errors
	 * @param {boolean} emit
	*/
	setErrors(errors:Array<any>, emit:boolean = true) {
		this.emitError = emit;
		this.clearErrors();

		errors.forEach((error) => {
			this.addError(error);
		});

		return this.errors;
	}

	addError(error) {
		let key 	= error['key'] ? error['key'] : error,
			macros 	= error['macros'],
			text 	= this.localizationService.get(key, '', macros);

		if (!text) {
			text = error;
		}

		if (this.errors.indexOf(text) == -1) {
			this.lioLogService.log(['Error:', text]);
			this.errors.push(text);
			if (this.emitError) {
				this.addedFeedback.next();
			}
			this.addToHistory('Error: ' + text);
		}
	}
	
	/*
	* Adds items to history
	* @param {string} item
	* @param {boolean} suppressDuplicates
	*/
	addToHistory(item, suppressDuplicates = false) {
		var time = moment().tz('America/Chicago').format('MM-DD-YYYY h:mm:ssa');
		if (suppressDuplicates) {
			if (this.rawHistory.indexOf(item) > -1) {
				return;
			}	
		}
		// Keep the raw history so we can check for duplicates
		this.rawHistory.push(item);
		this.history.push(time + ' ' + item);
	}

	/*
		* clears all items from history
	*/
	clearHistory() {
		this.history = [];
	}
}