import { Component, Inject, Input, OnDestroy } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { fieldService } from '../../../../services/fields.service';
import { permissionService } from '../../../../services/permissions.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lmsService } from '../../../../services/lms.service';
import { localizationService } from '../../../../services/localization.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { stateService } from '../../../../services/state.service';
import { utilService } from '../../../../services/util.service';

import { fieldsSettings } from '../../../../components/fields/fields.settings';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-field-editor',
	templateUrl: './field-editor.component.html',
})
export class LioFieldEditor implements OnDestroy {
	@Input() appearance			:MatFormFieldAppearance = 'outline';

	private subscriptions		:Subscription 	= NEVER.subscribe();

	private allPages			:Array<any> 	= this.utilService.copy(this.fieldsSettings.pages);

	private dataModel			:any			= this.utilService.copy(this.fieldsSettings.dataModel);
	private allPageIDs			:Array<any>		= [];
	private roleName			:string			= 'GLOBAL';
	private localizedModelName	:string			= this.localizationService.get('fields.modelName');

	public copiedOptions		:Array<any>		= null;
	public employees			:Array<any>		= [];
	public copiedPage			:any			= null;
	public filteredEmployees	:Array<any>		= [];
	public filteredFields		:Array<any>		= [];
	public employeeFields		:Array<any>		= this.utilService.copy(this.fieldsSettings.employeeFields);
	public employeeSettings		:any 			= this.utilService.copy(this.fieldsSettings.employeeSettings);
	public page					:any			= null;

	public showSelectedUsers	:boolean		= false;
	public selectedUsers		:Array<any>		= [];
	public currentEmployee		:any			= null;

	public resultPrefixButtons	:Array<any> = [
			{
			header 		: 'Select',
			id 			: 'am_results_select',
			color 		: 'primary',
			icon 		: 'done',
			callback 	: (user) => {this.select(user)}
		},
		{
			header 		: 'Load',
			id 			: 'am_results_load',
			color 		: 'primary',
			icon 		: 'search',
			callback 	: (user) => {this.selectUID(user)}
		}
	];

	public pageSelectField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Select a Page to Modify',
		nameTrans 			: '',
		model 				: 'targetPage',
		options 			: []
	});
	
	public targetPageModel:any = {
		targetPage : ''
	};
	public roleSelectField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Select a Role',
		nameTrans 			: '',
		model 				: 'targetRole',
		options 			: [],
		optionValueField 	: 'roleID'
	});
	
	public targetRoleModel:any = {
		targetRole : ''
	};
	
	public typeField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Type',
		nameTrans 			: '',
		model 				: 'type',
		options 			: this.utilService.copy(this.fieldsSettings.fieldTypes)
	});
	
	public preExistingOptionsField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Pre-Existing Options',
		nameTrans 			: '',
		model 				: 'segregation',
		options 			: this.utilService.copy(this.fieldsSettings.segregationOptions)
	});
	
	public availableOptionsField:LioSearchableSelectField = new LioSearchableSelectField({
		name 				: 'Available Options',
		nameTrans 			: '',
		model 				: 'selectedOptions',
		options				: [],
		multiple 			: true
	});

	public localeStrings:any = {
		fieldAddOption 			: 'Add Option',
		fieldAddOptionTrans 	: 'field.addOption',
		fieldsAddOption 		: 'Add Option',
		fieldsAddOptionTrans 	: 'fields.addOption',
		selectAll				: 'Select All',
		selectAllTrans			: 'global.selectAll',
		clear					: 'Clear All',
		clearTrans				: 'global.clear'
	};

	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(fieldService) 			public fieldService 		:fieldService,
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(lioModalService) 		public lioModalService 		:lioModalService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(stateService) 			public stateService 		:stateService,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(fieldsSettings) 		public fieldsSettings 		:fieldsSettings
	){
		this.debugService.register('fieldeditor', this);		

		let options:Array<any> = [];
		this.allPages.forEach((page) => {
			let option = {
				name	: page.name,
				value	: page.config.fieldName,
			};
			options.push(option);
		});

		options.sort((a,b) => {
			return a.name - b.name;
		});

		this.pageSelectField.options = options;

		this.setRoles();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
	
	getAvailableOptionsField(field) {
		let optionsField = this.utilService.copy(this.availableOptionsField);
		optionsField.options = field.options;
		return optionsField;
	}

  
	/**
	 * On user selection
	 */
	select(user) {
		// We need to reset once they are done previewing a user
		if (this.currentEmployee) {
			this.currentEmployee.selected = false;
			this.currentEmployee = null;
			this.getPage();
		}
		user.selected = !user.selected;
		if (user.selected) {
			this.selectedUsers.push(user);
		} else {
			this.selectedUsers.forEach((selectedUser) => {
				if (selectedUser.employeeID === user.employeeID) {
					let index = this.selectedUsers.indexOf(selectedUser);
					this.selectedUsers.splice(index, 1);
				}
			});
		}
	}

	/**
	 * Select all users
	 */
	selectAll() {
		// We need to reset once they are done previewing a user
		if (this.currentEmployee) {
			this.currentEmployee = null;
			this.getPage();
		}

		this.employees.forEach((employee) => {
			let alreadySelected = false;
			this.selectedUsers.forEach((selectedUser) => {
				if (selectedUser.employeeID === employee.employeeID) {
					alreadySelected = true;
				}
			});

			if (!alreadySelected) {
				employee.selected = true;
				this.selectedUsers.push(employee);
			}
		});
	}

	/**
	 * Select user to preview
	 */
	selectUID(user) {
		this.clearSelected();
		user.selected = true;
		this.currentEmployee = user;
		this.getPage(this.currentEmployee.UID);
	}

	/**
	 * Clears Selected users
	 */
	clearSelected() {
		if (this.filteredEmployees) {
			this.filteredEmployees.forEach((employee) => {
				employee.selected = false;
			});
		}
		
		this.selectedUsers = [];
	}

	/**
	 * Sets the roles
	 */
	setRoles() {
		this.roleSelectField.options 	= [{'name': 'GLOBAL', 'roleID': ''}];
		let roleAuthorities 			= this.permissionService.getRoleAuthorities('EDIT');
		this.roleSelectField.options 	= this.roleSelectField.options.concat(roleAuthorities);

		this.roleSelectField.options.sort((a,b) => {
			return a.roleID - b.roleID;
		});
	}


	/**
	 * Updates the filtered results
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
		if (!this.selectedUsers.length) {
			return;
		}
		this.filteredEmployees.forEach((filteredEmployee) => {
			this.selectedUsers.forEach((selectedUser) => {
				if (selectedUser.employeeID === filteredEmployee.employeeID) {
					filteredEmployee.selected = true;
				}
			});
		});
	}

	/**
	 * Updates the filtered field
	 */
	updateFilteredFields(collection) {
		this.filteredFields = collection.filtered;
	}

	/**
	 * Sets whether the field uses drop down options or not
	 */
	setDropDown(field) {
		field.dropDown = false;

		if (field.disableOptionOverride && !field.allowOptions) {
			return;
		}
		
		if (field.type === 'select') {
			field.dropDown = true;
			field.selectedOptions = [];
			if (field.options) {
				field.options.forEach((loadedOption, i) => {
					let option = {name: loadedOption.name, value: loadedOption.value};
					field.selectedOptions.push(loadedOption.value);
					field.options[i] = option;
				});	
			}
		}
	}

	/**
	 * On updates
	 */
	onUpdate() {
		//this.getFieldsToSave();
	}

	/**
	 * On update type
	 */
	onUpdateType(field, i) {
		this.setDropDown(field);

		if (field.dropDown) {
			this.lmsService.post('fields/getOptions', {'page': this.page.config.fieldName, 'field': field.model}).then((result) => {
				if (result) {
					field.options = result.properties.options;
					this.page.fields[i] = field;
				}
			});
		}
	}

	/**
	 * Validates the fields chosen
	 * @return {boolean}
	 */
	validate() {
		let valid = true,
			originalFields	= this.page.originalFields,
			hasDifferences 	= false;

			this.feedbackService.clearErrors();

		this.filteredFields.forEach((field) => {
			hasDifferences = false;

			if (!valid) {
				return;
			}
			if (field.disableOverride) {
				return;
			}

			originalFields.forEach((originalField) => {
				if (field.model === originalField.field) {
					if (this.hasDifferences(field, originalField)) {
						hasDifferences = true;
					}
				}
			});

			if (field.dropDown && hasDifferences) {
				if (!field.loadOptions && !field.customOptions) {
					this.feedbackService.addError('Please select the drop down options for ' + field.model);
					valid = false;
				}

				if (field.customOptions && !field.selectedOptions.length) {
					this.feedbackService.addError('Please select at least one drop down option for ' + field.model);
					valid = false;
				}
			}

		});

		return valid;
	}

	/**
	 * Gets the fields model to save
	 * @return {?array}
	 */
	getFieldsToSave() {
		let fields 			= this.filteredFields,
			originalFields	= this.page.originalFields,
			models		 	= [],
			model			= {};

		fields.forEach((field) => {
			if (field.disableOverride) {
				return;
			}
			originalFields.forEach((originalField) => {
				if (field.model === originalField.model) {
					if (this.hasDifferences(field, originalField)) {
						field.hasChanges = true;
						model = this.getDifferences(field);
						model['model'] = field.model;
						models.push(model);
					}
				}
			});
		});

		return models;
	}

	/**
	 * Gets the differences between two fields
	 * @param {object} newField
	 * @param {object} originalField
	 * @return {object}
	 */
	getDifferences(newField) {
		let differences = {},
			models 		= this.dataModel;

		if (newField.hideFromFieldEditor) {
			return null;
		}

		// Gets model differences
		models.forEach((model) => {
			if (model.type == 'boolean') {
				if (this.utilService.isString(newField[model.name])) {
					this.lioLogService.log(['NON BOOLEAN DETECTED', newField]);
					return;
				}
			}
			differences[model.name] = newField[model.name]; 
		});

		// Gets options
		if (newField.customOptions && newField.selectedOptions) {
			differences['options'] = [];
			newField.selectedOptions.forEach((optionValue) => {
				let matchedOption = this.findOption(newField, optionValue),
					option:any = {};
				if (matchedOption) {
					option.selected = true;
					option.name = matchedOption.name;
					option.value = matchedOption.value;
					differences['options'].push(option);
				}
			});

			if (!differences['options'].length) {
				delete differences['options'];
			}
		}

		return differences;
	}


	/**
	 * Finds an option by name
	 */
	findOption(field, value) {
		let matchedOption = null;
		field.options.forEach((option) => {
			if (option.value === value) {
				matchedOption = option;
			}
		});
		return matchedOption;
	}

	/**
	 * Do the two fields have differences
	 * @param {object} newField
	 * @param {object} originalField
	 * @return {boolean}
	 */
	hasDifferences(newField, originalField) {
		let differences = false,
			models 		= this.dataModel;

		if (newField.hideFromFieldEditor) {
			return false;
		}

		// Gets model differences
		models.forEach((model) => {
			if (newField[model.name] !== originalField[model.name]) {
				differences = true;
			}
		});

		// Are there options
		if (newField.options && newField.customOptions) {
			differences = true;
		}

		return differences;
	}

	/**
	 * Gets the selected UIDs to apply to
	 * @return {array}
	 */
	getUIDs() {
		let UIDs = [];
		// Add the selected UIDS
		if (this.selectedUsers.length) {
			this.selectedUsers.forEach((selectedUser) => {
				UIDs.push(selectedUser.UID);
			});
		} else if (this.currentEmployee) {
			UIDs.push(this.currentEmployee.UID);
		}
		return UIDs;
	}

	/**
	 * Saves Modifications for the page
	 * @return {Promise}
	 */
	modifyPage() {
		if (!this.validate()) {
			return;
		}

		let fields = this.getFieldsToSave();

		if (!fields.length) {
			this.lioModalService.show('Nothing to save');
			return;
		}

		this.feedbackService.clearErrors();
		this.lioLogService.log(['Saving ' + this.page.config.fieldName, fields]);
		return this.lmsService.postAsync('fields/saveByPage', {'fields': fields, 'pageName': this.page.config.fieldName, 'roleID': this.targetRoleModel.targetRole, 'pageIDs': this.allPageIDs, 'UIDs': this.getUIDs()},
		'processing').then((result) => {
			let token = result.properties.token;
			
			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleModifyPageResults(result);
				});
			} else {
				this.handleModifyPageResults(result);
			}
		});
	}

	/**
	 * Deletes fields for the page
	 */
	deletePage() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/deleteByPage', {'pageName': this.page.config.fieldName, 'roleID': this.targetRoleModel.targetRole, 'UIDs': this.getUIDs()}).then((result)  => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Deletes all pages for this company 
	 */
	deleteAllPagesByRole() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();
		
		this.lmsService.post('fields/deleteAllPagesByRole', {'roleID': this.targetRoleModel.targetRole, 'UIDs': this.getUIDs()}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Deletes all pages for all roles for this company 
	 */
	deleteAllPagesForAllRoles() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/deleteAllPagesByRole', {'roleID': 'GLOBAL'}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Reset a specific field
	 */
	resetField(field) {
		if (!field.overridden) {
			return;
		}

		if (field.permissionID && (field.permissionID != this.targetRoleModel.targetRole)) {
			if (this.targetRoleModel.targetRole && (field.permissionID != this.targetRoleModel.targetRole)) {
				this.lioModalService.show('fieldModifiedGlobally');
			}
			return;
		}
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/resetField', {'pageName': this.page.config.fieldName, 'roleID': this.targetRoleModel.targetRole, 'field': field.model, 'UIDs': this.getUIDs()}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Deletes user fields modifications for the current page
	 */
	resetPageForAllUsers() {
		this.lioModalService.showLoading('processing');
		this.feedbackService.clearErrors();

		this.lmsService.post('fields/resetPageForallUsers', {'pageName': this.page.config.fieldName}).then((result) => {
			if (result.success) {
				this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localizedModelName});
			}
			this.resetPage();
		});
	}

	/**
	 * Handles results of modifying a role
	 */
	handleModifyPageResults(result) {
		// Reload the fields to verify the results
		this.getPage().then(() => {
			if (result.success) {
				this.lioModalService.show('modelSaveSuccess', null, {key : 'modelName', value : this.localizedModelName});
			} else {
				this.lioModalService.show('modelSaveFail', null, {key : 'modelName', value : this.localizedModelName});
			}
		});
	}

	/**
	 * Determines if the type is recognized
	 * @param {string} type
	 * @return {boolean}
	 */
	isRecognizedType(field) {
		let recognized = false;

		this.typeField.options.forEach((fieldType) => {
			if (field.type == fieldType.value) {
				recognized = true;
			}
		});
			
		return recognized;
	}

	/**
	 * Show inputs for adding a new option
	 */
	showNewOption(field){
		if(!field.newOption){
			field.newOption = {
				name 	: '',
				value 	: '',
				bulk 	: ''
			};
		}
	}

	/**
	 * Adds options
	 * @param {object} field
	 */
	addOptions(field) {
		if (!field.newOption) {
			return;
		}
		let newOptions = [];

		if (!field.newOption.bulk) {
			newOptions = [field.newOption];
		} else {

			// Explode the text area into key value pairs by line
			let lines = field.newOption.bulk.split('\n');
			lines.forEach((line) => {
				if (!line) {
					return;
				}
				let pair = line.split(':');
				if (pair.length === 2) {
					newOptions.push({'name': pair[0], 'value': pair[1]});
				} else {
					newOptions.push({'name': pair[0], 'value': pair[0]});

				}
			});
		}
		
		newOptions.forEach((newOption) => {
			this.addOption(field, newOption, true);
		});

		field.newOption = {name:'', value:''};
	}

	/**
	 * Adds an option to the field
	 * @param {object} field
	 * @param {newOption} array
	 * @param {boolean} addToSelected
	 */
	addOption(field, newOption, addToSelected) {
		if (!newOption.name) {
			this.lioModalService.show('Please add a name for the option');
			return;
		}
		let existing = null,
			option = {};

		// Set the value to the name if not provided
		if (!newOption.value) {
			newOption.value = newOption.name;
		}

		// Trim
		newOption.name = newOption.name.trim();
		newOption.value = newOption.value.trim();

		// Check if the option already exists
		field.options.forEach((option) => {
			if (option.value === newOption.value || option.name === newOption.name) {
				existing = newOption.name;
			}
		});

		if (existing) {
			this.lioLogService.log(existing + ' already exists');
			return;
		}

		// Store the options in the respective arrays
		option = {
			'name': newOption.name,
			'value': newOption.value,
		};
		if (!field.options) {
			field.options = [];
		}
		field.options.push(option);
		if (addToSelected) {
			if (!field.selectedOptions) {
				field.selectedOptions = [];
			}
			field.selectedOptions.push(newOption.value);
		}
	}

	/**
	 * Loads pre-existing options
	 */
	loadExisting(field) {
		this.lmsService.post('fieldoptions/loadExisting', {'model': field}).then((result) => {
			if (result) {
				field.options = result.properties.options;
			}
		});
	}

	/**
	 * Handles fields once they are loaded
	 */
	handleFields(fields:Array<any>) {
		fields.forEach((field) => {
			for (let i in field) {
				if (field[i] === 1) {
					field[i] = true;
				}
			}

			if (field.visible && !this.utilService.isDefined(field.export)) {
				field.export = true;
			}

			if (field.tooManyResults) {
				this.feedbackService.addError('Too many results were found in ' + field.model + ' so the options were not loaded');
			}

			field.mappingFunction 		= null;
			field.mappingTransFunction 	= null;
			this.setDropDown(field);
		});
		return fields;
	}


	/**
	 * Gets the page and fields
	 */
	getPage(UID = null) {
		this.fieldService.clearCache();
		
		this.allPageIDs = [];
		let newPage 	= null;
		this.allPages.forEach((page) => {
			if (page.config.fieldName === this.targetPageModel.targetPage) {
				newPage = this.utilService.copy(page);
			}
			this.allPageIDs.push(page.config.fieldName);
		});

		newPage.config.roleID 			= this.targetRoleModel.targetRole;
		newPage.config.fieldName 		= this.targetPageModel.targetPage;
		newPage.config.overrideFields 	= true;
		newPage.config.UID 				= UID;

		return this.fieldService.setFields(newPage.fields,newPage.config).then((fields:Array<any>) => {
			newPage.fields 			= this.handleFields(fields);
			newPage.originalFields 	= this.utilService.copy(newPage.fields);
			this.page 				= newPage;
		});
	}

	/**
	 * Gets the role's fields
	 */
	getRoleFields() {
		this.roleSelectField.options.forEach((role) => {
			if (role.roleID === this.targetRoleModel.targetRole) {
				this.roleName = role.name;
			}
		});
		this.getPage();
	}

	/**
	 * Copy options
	 */
	copyOptions(field) {
		if (field.options) {
			this.copiedOptions = this.utilService.copy(field.options);
		}
	}

	/**
	 * Paste options
	 */
	pasteOptions(field) {
		if (!field.options) {
			field.options = [];
		}
		this.copiedOptions.forEach((option) => {
			this.addOption(field, option, false);
		});
	}

	/**
	 * Copy a page
	 */
	copyPage() {
		this.copiedPage 			= this.utilService.copy(this.page);
		this.copiedPage.roleName 	= this.roleName;
	}

	/**
	 * Pastes a page (just the fields)
	 */
	pastePage() {
		let copiedFields = this.utilService.copy(this.copiedPage.fields),
			originalFields = [],
			overridden = false,
			differencesFound = false;

			copiedFields.forEach((copiedField) => {
				this.page.fields.forEach((field, key) => {
				if (copiedField.model === field.model) {
					if (field.disableOverride) {
						return;
					}
					if (this.hasDifferences(copiedField, field)) {
						originalFields = this.utilService.copy(field.originalFields);
						overridden = field.overridden;
						copiedField.overridden = overridden;
						copiedField.originalFields = originalFields;
						this.page.fields[key] = copiedField;
						differencesFound = true;
					}
				}
			});
		});

		if (differencesFound) {
			this.lioModalService.show('Copied Succesffully');
		} else {
			this.lioModalService.show('No Differences Found to Copy');

		}
		this.onUpdate();
	}

	/**
	 * Reset and wait
	 */
	resetAndWait(){
		this.lioModalService.showLoading('processing');
		setTimeout(() => {
			this.resetPage().then(() => {
				this.lioModalService.hideLoading();
			});
		}, 500);
	}

	/**
	 * Reset the page
	 */
	resetPage(){
		this.copiedPage = null;
		this.copiedOptions = null;
		this.clearSelected();
		return this.getPage();
	}
}