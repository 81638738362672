<div class="row">
	<div class="col-sm-0 col-md-1"></div>
	<lio-panel-tabber #tabCache class="col-sm-12 col-md-10">
		<lio-panel-tab [settings]="panels.emailTemplate">
			<lio-email-editor *lioPanelTabContent
				[showCourses]="false" 
				[settings]="emailSettings"
				(onChanged)="onTemplatesChanged()"
				(onOptionsLoaded)="templatesLoaded()">
			</lio-email-editor>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.edit">
			<lio-event-editor *lioPanelTabContent
				[settings]="scheduleEmailEventSettings"
				[eventService]="emailEventService">
			</lio-event-editor>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.list">
			<lio-event-list *lioPanelTabContent
				[settings]="scheduleEmailEventListSettings" 
				[eventService]="emailEventService" 
				(callback)="callback($event)">
			</lio-event-list>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.history">
			<lio-event-history *lioPanelTabContent
				[settings]="scheduleEmailEventHistorySettings" 
				[includePacEvents]="true" 
				[eventService]="emailEventService">
			</lio-event-history>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>