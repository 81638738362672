<div #pdfTarget  
	class="container-fluid" 
	localize 
	[model]="localeStrings" 
	[items]="localeItems"
	[style.fontSize.rem]="0.75">
	<div *ngIf="responseData">
		<div class="row print-section">
			<lio-card class="col" headerClass="p-0 pt-1" bodyClass="p-2">
				<h6 lio-card-header>{{responseData.title}}</h6>
				<div lio-card-body>
					<h6>{{localeStrings.header}}</h6>
					<span>{{localeStrings.subHeader}}</span>
				</div>
			</lio-card>
		</div>
		<div class="row print-section" *ngFor="let section of responseData.responses">
			<lio-card class="col" bodyClass="p-0">
				<div lio-card-body>
					<div class="list-group-item p-2" *ngFor="let question of section">
						<div>
							<span class="d-inline-block">{{localeStrings.questionLabel}}</span>
							<span class="d-inline-block" [innerHtml]="question.question | html"></span>
						</div>
						<div>
							<span class="d-inline-block">{{localeStrings.answerLabel}}</span>
							<span class="d-inline-block">{{question.response}}</span>
						</div>
					</div>
				</div>
			</lio-card>
		</div>
	</div>
</div>