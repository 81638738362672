import { Component, Inject, OnDestroy, DoCheck } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../services/debug.service';
import { brandingService } from '../../services/branding.service';
import { lioLogService } from '../../services/lio-log.service';
import { navService } from '../../services/nav.service';
import { lioModalService } from '../../services/lio-modal.service';
import { loaderService } from '../../services/loader.service';

class Branding {
	logo	:string;
	resize	:boolean;
	width	:string;
	height	:string;
	css		:string;
}

@Component({
	selector: 'lio-branding',
	templateUrl: './branding.component.html',
	styleUrls: ['./branding.component.css']
})
export class BrandingComponent implements OnDestroy, DoCheck {
	public branding:Branding = {
		logo	: '',
		resize	: false,
		width	: '',
		height	: '',
		css		: ''
	};

	public allowSave	:boolean 				= false;

	public show		:{
		instructions:boolean, 
		loginKey	:boolean
	} = {
		instructions: false,
		loginKey	: false
	};
	
	public fields	:Array<any> = [
		{
				'name': 'Logo',
				'model': 'logo',
				'type': 'text',
				'required': false,
				'min': 2,
				'max': 200,
				'debounce': 1000,
				'visible': true
		},
		{
				'name': 'Resize',
				'model': 'resize',
				'type': 'checkbox',
				'required': false,
				'visible': true
		},
		{
				'name': 'Max Width',
				'model': 'width',
				'type': 'number',
				'required': false,
				'visible': true
		},
		{
				'name': 'Max Height',
				'model': 'height',
				'type': 'number',
				'required': false,
				'visible': true
		},
	];

	public uploadSettings	:any 			= {
		'name'				: 'Upload Logo',
		'trans'				: 'branding.uploadLogo',
		'fileTask'			: 'importimage/upload',
		'allowedFileTypes'	: ['jpg', 'png', 'jpeg', 'gif'],
		'hideLoading'		: false,
	};

	public filePath			:string 		= '';

	public sampleCss		:string = `.logo-container {<br/>
		&nbsp;&nbsp;&nbsp;&nbsp;background-color: #ff0000; <br/>
		}<br/>
		<br/>
		<br/>
		.login-key {<br/>
		&nbsp;&nbsp;&nbsp;&nbsp;background-image: url('https://www.corpedia.com/images/3d_systems.png');<br/>
		&nbsp;&nbsp;&nbsp;&nbsp;background-size: 350px;<br />
		&nbsp;&nbsp;&nbsp;&nbsp;width: 350px;<br/>
		}<br/>`;

	public monacoOptions = {
		theme	: 'vs-dark', 
		language: 'css'
	};

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(debugService) 		public debugService 	:debugService,
		@Inject(brandingService) 	public brandingService 	:brandingService,
		@Inject(lioLogService) 		public lioLogService 	:lioLogService,
		@Inject(navService) 		public navService 		:navService,
		@Inject(lioModalService) 	public lioModalService 	:lioModalService,
		@Inject(loaderService) 		public loaderService 	:loaderService
	){
		this.debugService.register('branding', this);

		this.navService.setActivePage('branding');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.setCSS();
			})
		);
	}

	private _oldCss:string = '';
	ngDoCheck(){
		if(this.branding && this.branding.css != this._oldCss){
			this._oldCss = this.branding.css;
			this.onupdate();
		}
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/* 
	 * On File Added
	*/
	fileAdded(response) {
		this.filePath = response.filePath;
		this.brandingService.logoURL = null;
		this.saveLogo();
	}

	/*
	 * File preprocess / validation
	*/
	saveLogo() {
		let filePath 	= this.filePath,
			resize 		= this.branding.resize,
			width 		= this.branding.width,
			height 		= this.branding.height;


		// Remove image dimensions since we are controlling the size
		if (resize && this.branding.css.indexOf('.logo img') === -1) {
			this.branding.css += "\n\n.logo img {\n\theight: auto;\n}";	
		}

		this.subscriptions.add(
			this.brandingService.brandingUpdated.subscribe((result) => {
				if (result) {
					setTimeout(() => {
						// give the server some time to move the image
						this.handleUploaded(result);
					}, 2000);
				}
			})
		);	

		this.brandingService.saveLogo(filePath, resize, width, height);
	}

	/*
	 * Handle logo uploaded
	*/
	handleUploaded(result) {
		if (result.success) {
			let logo = result.properties.logo;
			this.branding.logo = logo;
			this.brandingService.logoURL = logo;
			if (result.lioModal) {
				this.lioModalService.show(result.lioModal);
			} else {
				this.lioModalService.show('savedSuccessfully');
			}
		}
	}

	/* 
	 * On File Failure
	*/
	fileFailed(response) {
		this.lioLogService.log(['File Failed', response]);
	}

	/* 
	 * On update of the editor changes
	*/
	onupdate() {
		this.allowSave = true;
		this.brandingService.setBranding(this.branding);
	}

	/* 
	 * Sets the CSS from the existing branding
	*/
	setCSS() {
		this.brandingService.getBranding().then((result) => {
			this.branding = result.properties.branding;
			this.navService.displayPage();
		});
	}

	/* 
	 * Sets the CSS from the existing branding
	*/
	saveCSS() {
		this.brandingService.saveBranding(this.branding).then(() => {
			this.allowSave = false;
		});
	}
}