import { Component, OnDestroy, DoCheck, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { formValidatorService } from '../../services/form-validator.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { localizationService } from '../../services/localization.service';
import { fieldService } from '../../services/fields.service';
import { passwordResetService } from '../../services/password-reset.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { loginService } from '../../services/login.service';
import { debugService } from '../../services/debug.service';
import { lioLogService } from '../../services/lio-log.service';
import { utilService } from '../../services/util.service';

import { registerSettings } from './register.settings';

@Component({
	selector: 'lio-register',
	templateUrl: './register.component.html'
})
export class RegisterComponent implements OnDestroy, DoCheck {
	public allowSubmit		:boolean 		= false;
	public employee			:any 			= {};
	public showSuccess		:boolean 		= false;
	public fields			:Array<any>		= this.utilService.copy(this.registerSettings.fields);
	public fieldConfig		:any 			= this.utilService.copy(this.registerSettings.fieldConfig);
	private _oldEmail		:any 			= null;
	private _oldEmployeeID	:any 			= null;

	public localeStrings 	:any 			= {
		alreadyRegistered 		: 'Already Registered?',
		alreadyRegisteredTrans	: 'register.alreadyRegistered',
		clickToLogin			: 'Click here to log in',
		clickToLoginTrans		: 'register.clickToLogin',
		header					: 'Register',
		headerTrans				: 'register.header',
		subHeader				: 'Please fill in all the required fields(*)',
		subHeaderTrans			: 'register.subHeader',
		add						: 'Register',
		addTrans				: 'register.add',
		successfullyAdded		: 'Your account was successfully added, you will receive an email shortly with a link to set your password. You may now close this window.',
		successfullyAddedTrans	: 'register.successfullyAdded',
		youMayCloseWindow		: 'You may now close this window',
		youMayCloseWindowTrans	: 'register.youMayCloseWindow',
	};
	public localeStringsKeys:Array<any> 	= [
		'alreadyRegistered',
		'clickToLogin',
		'header',
		'subHeader',
		'add',
		'successfullyAdded',
		'youMayCloseWindow'
	];

	private params			:any 			= this.storageService.getInitialParams();
	private companyID		:string 		= (this.params && this.params.companyID) || this.stateService.getActiveCompanyID() || this.storageService.get('companyID');
	private langID			:string			= '';

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService)			public	loaderService		:loaderService,
		@Inject(navService)				public	navService			:navService,
		@Inject(formValidatorService)	public	formValidatorService:formValidatorService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(localizationService)	public	localizationService	:localizationService,
		@Inject(fieldService)			public	fieldService		:fieldService,
		@Inject(passwordResetService)	public	passwordResetService:passwordResetService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(loginService)			public	loginService		:loginService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(lioLogService)			public	lioLogService		:lioLogService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(registerSettings)		public	registerSettings	:registerSettings
	){
		this.debugService.register('register', this);
		this.navService.setActivePage('register');

		this.navService.disableNav = false;

		this.subscriptions.add(
			this.loginService.notLoggedIn.subscribe(() => { 
				this.notLoggedIn(); 
			})
		);
	
		this.subscriptions.add(
			this.loginService.loggedIn.subscribe(() => { 
				this.alreadyLoggedin(); 
			})
		);
	
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.initForm();
			})
		);
	
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => { 
				this.fieldsReady();
			})
		);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initializes the form
	*/
	initForm() {
		if (this.stateService.isLoggedIn() || !this.companyID) {
			this.navService.goHome();
			return;
		}

		this.setFields();
	}

	/*
	 * Sets the fields
	*/
	setFields() {
		this.employee = {};
		this.employee.inactive = 0;
		this.employee.permissionID = 10;
		this.fieldConfig.companyID = this.companyID;
	}

	/*
	 * Fields ready
	*/
	fieldsReady() {
		this.fields.forEach((field) => {
			if (field.model === 'langID') {
				field.locked = false;
			}
		});
		this.navService.displayPage();
	}


	/*
	 * Evalate email and employeeID for changes - SP-4174
	*/
	ngDoCheck(): void {
		if (this.employee.employeeID && this.employee.email) {
			if ((this._oldEmployeeID != this.employee.employeeID) || (this._oldEmail != this.employee.email)) {
				this.onUpdate();
			}

			this._oldEmployeeID = this.utilService.copy(this.employee.employeeID);
			this._oldEmail = this.utilService.copy(this.employee.email);
		}
	}


	/*
	 * On update of the profile
	*/
	onUpdate() {
		this.navService.changedForm = true;
		this.langID = this.localizationService.getLangID();


		if (this.hasValue()) {
			this.allowSubmit = true;
		} else {
			this.allowSubmit = false;
		}

		if (!this.employee.langID) {
			this.employee.langID = this.langID;
		}

		this.formValidatorService.resetFields();
		this.feedbackService.clearErrors();
	}

	/*
	 * Does the form have a value?
	*/
	hasValue() {
		var hasValue = false;
		this.fields.forEach((field) => {
			if (this.employee[field.model]) {
				hasValue = true;
			}
		});
		return hasValue;
	}

	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;
		
		this.feedbackService.clearErrors();

		if (!this.formValidatorService.isValid(this.employee, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		return isValid;
	}

	/*
	 * Closes the window
	*/
	closeWindow() {
		this.navService.close();
	}

	/*
	 * Submits the edit
	*/
	submit() {
		if (!this.isValid()) {
			return;
		}

		this.lioModalService.show('register.saving');
		var form = {'employee': this.employee, 'companyID': this.companyID};

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		
		this.lmsService.post('selfregister/registerUser', form).then((result) => {
			let employeeID = result.properties.employeeID;

			this.allowSubmit = false;
			this.navService.changedForm = false;

			if (result.success) {
				if (employeeID) {
					this.employee.employeeID = employeeID;
				}
				this.employee.companyID = this.companyID;
				this.submitForgotPass();
			} else if (result.errors) {
				this.lioModalService.hide();
				this.feedbackService.setErrors(result.errors);
			}
		});
	}

	/*
	 * Sends the user a password reset link
    */
	submitForgotPass() {
		this.passwordResetService.resetRequest(this.employee).then((result) => {
			if (result) {
				this.showSuccess = true;
			}
			this.lioModalService.hide();
		});
	}

	/*
	 * Already logged in
	*/
	alreadyLoggedin() {
		this.navService.goHome();
	}

	/*
	 * Check if user can self register
	*/
	canSelfRegister() {
		return this.lmsService.post('selfregister/canSelfRegister', {'companyID': this.companyID}).then((result) => {
			if (!result) {
				return false;
			}
			return result.properties.canSelfRegister;
		});
	}

	/*
	 * Not logged in, show form
	*/
	notLoggedIn() {
		this.canSelfRegister().then((canSelfRegister) => {
			if (!canSelfRegister) {
				this.lioLogService.log(['cannot self register']);
				this.navService.goto('login');
			} else {
				this.lioLogService.log(['Confirmed can self register']);
			}
		});
	}
}