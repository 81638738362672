<div class="row">
	<div class="col-md-10 mx-auto">
		<lio-panel-tabber>
			<lio-panel-tab [settings]="panelConfigs.profile">
				<lio-profile *lioPanelTabContent
					[updateProfilePermission]="'employee.update'"
					[fields]="fields"
					[config]="fieldConfig"
					[updateCCFPermission]="'employee.updateCCFAdminRights'"
					[self]="false"
				></lio-profile>
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfigs.enrollments">
				<lio-enrollments [showUser]="false" [allowEditProfile]="false" *lioPanelTabContent></lio-enrollments>
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfigs.loginHistory">
				<lio-login-history *lioPanelTabContent></lio-login-history> 
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfigs.courseHistory">
				<lio-course-history *lioPanelTabContent></lio-course-history> 
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfigs.emailHistory">
				<lio-email-audit-history *lioPanelTabContent></lio-email-audit-history> 
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</div>