import { Inject, Injectable } from '@angular/core';

import { Subject, Subscription, NEVER } from 'rxjs';


import { navService } from './nav.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class accessibilityService {
	breadcrumbs : any = [];

	public tabChange		:Subject<void> = new Subject();
	private subscriptions	:Subscription	= NEVER.subscribe();


	constructor(
		@Inject(debugService)			private debugService		:debugService,
		@Inject(navService)				private navService			:navService,
	){

		this.subscriptions.add(
			this.navService.exiting.subscribe(() => {
				this.tabChange.next();
			})
		);
		this.debugService.register('accessibility', this);
	}


	public tabChanged() {
		this.tabChange.next();
	}



	
}