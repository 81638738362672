import { Inject, Injectable } from '@angular/core';
import { permissionService } from '../../services/permissions.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lmsService } from '../../services/lms.service';
import { profileSettings } from '../profile/profile.settings';

import { LioButtonField } from '../../modules/lio-forms/models/button-field.model';
import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';
import { PanelTabData } from '../../modules/structural/structural.models';

import { filesService } from '../../services/files.service';

class BatchTab extends PanelTabData{
	fields		:LioFieldArray
	fieldConfig?:any
	fileConfig?	:any
	searches?	:any
	buttons?	:Array<any>
}

@Injectable({
	providedIn	: 'root', 
	useClass	: batchProcessingSettings
})
export class batchProcessingSettings {
	constructor(
		@Inject(profileSettings) 	private profileSettings	:profileSettings,
		@Inject(lioModalService) 	private lioModalService	:lioModalService,
		@Inject(lmsService) 		private lmsService		:lmsService,
		@Inject(filesService) 		private filesService	:filesService,
		@Inject(permissionService) 	private permissionService	:permissionService,
	){}

	tabSettings:{
		employee 			:any,
		config				:BatchTab,
		format				:BatchTab,
		upload				:BatchTab,
		uploadHistory		:BatchTab,
		globalUploadHistory	:BatchTab,
		processHistory		:BatchTab,
		globalProcessHistory:BatchTab,
	} = {
		employee : {
			fields 		: this.profileSettings.externalFields,
			fieldConfig : {
			fieldName			: 'batchProfile',
				addCCFtoFields		: true,
				localizeFields		: false,
				permissionFields	: true,
				addRoleToFields		: true,
				overrideFields		: false,
				addLangField		: true,
				addActiveField		: true,
				ccfFields: {
					locked: 'employee.updateCCF'
				},
			},
		},
		config : {
			id				: 'config',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.editConfig');
			},
			title			: 'Config',
			titleTrans		: 'batchProcessing.config',
			subtitle 		: 'Update the configuration for the batch procesisng',
			subtitleTrans 	: 'batchProcessing.configSubtitle',
			tab				: 'Configuation',
			tabTrans		: 'batchProcessing.configTab',
			fieldConfig 	: {
				fieldName		: 'config',
				permissionFields: true,
			},
			fields : new LioFieldArray(
				{
					name			: 'Client Name',
					model			: 'company_identifier_name',
					type			: 'text',
					visible			: true,
				},
				{
					name			: 'Add Employees',
					model			: 'add_employees',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Update Employees',
					model			: 'update_employees',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Deactivate Employees not listed in File',
					model			: 'deactivate_employees',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Reactivate Employees listed in File',
					model			: 'reactivate_employees',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Deactivate Manually Added Employees',
					model			: 'deactivate_manual_adds',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Bypass Deactivation Limits',
					model			: 'bypass_mass_deactivate_check',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Maximum Duplication of Emails Allowed',
					model			: 'max_dupe_emails',
					type			: 'number',
					visible			: true,
				},
				{
					name			: 'Maximum Duplication of EmployeeIDs Allowed',
					model			: 'max_dupe_employeeids',
					type			: 'number',
					visible			: true,
				},
				{
					name			: 'Maximum Deactivation Percentage Allowed',
					model			: 'max_deactivations',
					type			: 'number',
					visible			: true,
				},
				{
					name			: 'Are the files pgp Encrypted?',
					model			: 'pgp_encrypted',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'When is the earliest hour (in 24 hours format) we should process files?',
					model			: 'start_time',
					type			: 'range',
					min 			: 0,
  					max 			: 23,
					visible			: true,
				},
				{
					name			: 'When is the latest hour (in 24 hours format) we should process files?',
					model			: 'end_time',
					type			: 'range',
					min 			: 1,
  					max 			: 24,
					visible			: true,
				},
				{
					name			: 'Do you want email notifications sent?',
					model			: 'send_upload_notification',
					type			: 'checkbox',
					visible			: true,
				},
				{
					name			: 'Upload Notification Email Address',
					model			: 'upload_notification_list',
					conditions 		: ['send_upload_notification'],
					type			: 'email',
					visible			: true,
				},
				{
					name			: 'Process Notification Email Address',
					model			: 'process_notification_list',
					conditions 		: ['send_upload_notification'],
					type			: 'email',
					visible			: true,
				},
				{
					name			: 'If you are sending multiple files that need to be merged, please list the file names, with comma seperations',
					model			: 'merge_names',
					max				: 1000,
					type			: 'text',
					visible			: true,
				},
				{
					name			: 'Specific file name format with optional date string (i.e. companyname_!##DATE_STYLE_1##!.txt)',
					model			: 'file_upload_name',
					type			: 'text',
					visible			: true,
				},

				new LioSearchableSelectField({
					name		: 'Choose the Frequency Of your upload schedule',
					model		: 'frequency_value',
					type		: 'select',
					visible		: true,
					required	: true,
					options		:  [
						{
							'value': '1',
							'name': 'Daily',
						},
						{
							'value': '7',
							'name': 'Weekly',
						},
						{
							'value': '14',
							'name': 'Bi-Weekly',
						},
						{
							'value': '31',
							'name': 'Monthly',
						}
					]
				}),
				{
					name			: 'Legacy Processing',
					model			: 'is_active',
					nameTrans		: 'form.active',
					type			: 'boolean',
					visible			: true,
					required		: false,
				},
				{
					name			: 'LIO Processing',
					model			: 'lio_is_active',
					nameTrans		: 'form.active',
					type			: 'boolean',
					visible			: true,
					required		: false,
				},
				{
					name			: 'Testing Mode',
					model			: 'test_mode',
					type			: 'boolean',
					visible			: true,
				},
			),
			buttons			: [
				{
					id					: 'save',
					visible				: true,
					text				: 'Save',
					textTrans			: 'batchProcessing.saveCourse',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'saveConfig',
				},
			],
		},
		format : {
			id				: 'format',
			title			: 'File Format',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.editFormat');
			},
			titleTrans		: 'batchProcessing.edittitle',
			subtitle		: 'Edit the format of the batch files',
			subtitleTrans	: 'batchProcessing.editSubtitle',
			tab				: 'File Format',
			tabTrans		: 'batchProcessing.eventFormTab',
			fieldConfig 	: {
				'fieldName'			: 'editformat',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			fields : new LioFieldArray(
				{
					name				: 'Fields',
					model				: 'fields',
					required			: true,
					visible				: true,
					sortable			: true,
					type				: 'fileFormat',
					class				: 'col',
				},
			),
			buttons			: [
				{
					id					: 'save',
					visible				: true,
					text				: 'Save Format',
					textTrans			: 'batchProcessing.saveFormat',
					class				: 'btn-primary',
					callback			: 'saveFormatFields',
				},
				{
					id					: 'getSample',
					visible				: true,
					text				: 'Get Sample',
					textTrans			: 'batchProcessing.getSample',
					class				: 'btn-primary',
					callback			: 'getSample',
				},
				{
					id					: 'getFormat',
					visible				: true,
					text				: 'Get Format File',
					textTrans			: 'batchProcessing.getFormat',
					class				: 'btn-primary',
					callback			: 'getFormatFile',
				},
				{
					id					: 'formatUpload',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: [],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload Format File',
						trans				: 'batchProcessing.uploadFile',
						fileTask			: 'batchImport/upload',
						allowedFileTypes	: ['fmt', 'txt'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'formatFileAdded'
				},
				{
					id					: 'keyUpload',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: [],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload Public Key',
						trans				: 'batchProcessing.uploadFile',
						fileTask			: 'batchImport/uploadPublicKey',
						allowedFileTypes	: ['key', 'txt', 'asc'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'keyFileAdded'
				},
				{
					id					: 'keyUpload',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: [],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload Private Key',
						trans				: 'batchProcessing.uploadFile',
						fileTask			: 'batchImport/uploadPrivateKey',
						allowedFileTypes	: ['key', 'txt', 'asc'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'keyFileAdded'
				},
				{
					id					: 'keyUpload',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: [],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload Passphrase',
						trans				: 'batchProcessing.uploadFile',
						fileTask			: 'batchImport/uploadPassPhrase',
						allowedFileTypes	: ['key', 'txt', 'asc'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'keyFileAdded'
				},
			],
		},
			upload : {
			id				: 'upload',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.upload');
			},
			title			: 'Batch Processing Manager',
			titleTrans		: 'batchProcessing.title',
			subtitle		: 'Upload a File',
			subtitleTrans	: 'batchProcessing.subtitle',
			description		: 'Upload a file to process',
			descriptionTrans: 'batchProcessing.uploadDescription',
			tab				: 'Upload',
			tabTrans		: 'batchProcessing.eventFormTab',
			fields 			: new LioFieldArray(
				{
					name			: 'Upload ID',
					model			: 'uploadID',
					nameTrans		: 'batch.uploadID',
					type			: 'text',
					locked			: true,
					visible			: true,
					required		: true,
					conditions 		: ['uploadID'],
				},
				{
					name			: 'Company ID',
					model			: 'COMPANYID',
					nameTrans		: 'batch.companyID',
					type			: 'text',
					locked			: true,
					visible			: true,
					required		: true,
					conditions 		: ['COMPANYID'],
				},
				{
					name			: 'File Name',
					model			: 'fileName',
					nameTrans		: 'batch.fileName',
					type			: 'text',
					locked			: true,
					visible			: true,
					required		: true,
				},
				{
					name			: 'Total In File',
					model			: 'TOTALINFILE',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total Changes Detected',
					model			: 'TOTALCHANGES',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total To Add',
					model			: 'TOTALTOADD',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total To Update',
					model			: 'TOTALTOUPDATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total To Deactivate',
					model			: 'TOTALTODEACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total To Reactivate',
					model			: 'TOTALTOREACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total Email Duplicates',
					model			: 'TOTALEMAILDUPLICATES',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				{
					name			: 'Total EmployeeID Duplicates',
					model			: 'TOTALEMPLOYEEIDDUPLICATES',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['preProcessed'],
				},
				// PROCESS
				{
					name			: 'Total Changed',
					model			: 'TOTALCHANGED',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['processed'],
				},
				{
					name			: 'Total Added',
					model			: 'TOTALADDED',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['processed'],
				},
				{
					name			: 'Total Updated',
					model			: 'TOTALUPDATED',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['processed'],
				},
				{
					name			: 'Total Deactivated',
					model			: 'TOTALDEACTIVATED',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['processed'],
				},
				{
					name			: 'Total Reactivated',
					model			: 'TOTALREACTIVATED',
					type			: 'text',
					visible			: true,
					locked 			: true,
					conditions 		: ['processed'],
				},
			),
			buttons	: [
				{
					id					: 'uploadbtn',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: ['canUpload'],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload File',
						trans				: 'batchProcessing.uploadFile',
						fileTask			: 'batchImport/upload',
						preFileTask			: 'batchUpload/resetUploads',
						allowedFileTypes	: ['xlsx', 'csv', 'txt', 'pgp'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'fileAdded',
				},
				{
					id					: 'getInserts',
					visible				: true,
					text				: 'Load Inserts',
					textTrans			: 'batchProcessing.getInserted',
					visibleConditions 	: ['TOTALTOADD', 'preProcessed'],
					class				: 'btn-primary',
					callback			: 'getInserts',
				},
				{
					id					: 'getAll',
					visible				: true,
					text				: 'Load All From File',
					textTrans			: 'batchProcessing.getAll',
					visibleConditions 	: ['TOTALINFILE', 'preProcessed'],
					class				: 'btn-primary',
					callback			: 'getAll',
				},
				{
					id					: 'getUpdates',
					visible				: true,
					text				: 'Load Updates',
					textTrans			: 'batchProcessing.getUpdates',
					visibleConditions 	: ['TOTALTOUPDATE', 'preProcessed'],
					class				: 'btn-primary',
					callback			: 'getUpdates',
				},
				{
					id					: 'getReactivations',
					visible				: true,
					text				: 'Load Reactivations',
					textTrans			: 'batchProcessing.getReactivations',
					visibleConditions 	: ['TOTALTOREACTIVATE', 'preProcessed'],
					class				: 'btn-primary',
					callback			: 'getReactivations',
				},
				{
					id					: 'getDeactivations',
					visible				: true,
					text				: 'Load Deactivations',
					textTrans			: 'batchProcessing.getDeactivations',
					visibleConditions 	: ['TOTALTODEACTIVATE', 'preProcessed'],
					class				: 'btn-primary',
					callback			: 'getDeactivations',
				},
				{
					id					: 'getAll',
					visible				: true,
					text				: 'Load All Processed',
					textTrans			: 'batchProcessing.getAll',
					visibleConditions 	: ['TOTALCHANGED', 'processed'],
					class				: 'btn-primary',
					callback			: 'getAll',
				},
				{
					id					: 'getInserted',
					visible				: true,
					text				: 'Load Inserted',
					textTrans			: 'batchProcessing.getInserted',
					visibleConditions 	: ['TOTALADDED', 'processed'],
					class				: 'btn-primary',
					callback			: 'getInserts',
				},
				{
					id					: 'getUpdated',
					visible				: true,
					text				: 'Load Updated',
					textTrans			: 'batchProcessing.getUpdated',
					visibleConditions 	: ['TOTALUPDATED', 'processed'],
					class				: 'btn-primary',
					callback			: 'getUpdates',
				},
				{
					id					: 'getReactivated',
					visible				: true,
					text				: 'Load Reactivated',
					textTrans			: 'batchProcessing.getReactivated',
					visibleConditions 	: ['TOTALREACTIVATED', 'processed'],
					class				: 'btn-primary',
					callback			: 'getReactivations',
				},
				{
					id					: 'getDeactivated',
					visible				: true,
					text				: 'Load Deactivated',
					textTrans			: 'batchProcessing.getDeactivated',
					visibleConditions 	: ['TOTALDEACTIVATED', 'processed'],
					class				: 'btn-primary',
					callback			: 'getDeactivations',
				},
				{
					id					: 'assignCourses',
					visible				: true,
					text				: 'Assign Users',
					textTrans			: 'batchProcessing.assignCourses',
					visibleConditions 	: ['TOTALCHANGED', 'processed'],
					class				: 'btn-primary',
					callback			: 'assignCourses',
				},
				{
					id					: 'process',
					visible				: true,
					text				: 'Process',
					textTrans			: 'batchProcessing.process',
					disabledConditions 	: ['canProcess'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'process',
				},
				{
					id					: 'uploadlog',
					visible 		: () => {
						return this.permissionService.hasPermission('batch.admin');
					},
					text				: 'Get Upload Log',
					textTrans			: 'batchProcessing.getLogs',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'getUploadLog',
				},
				{
					id					: 'processlog',
					visible 		: () => {
						return this.permissionService.hasPermission('batch.admin');
					},
					text				: 'Get Process Log',
					textTrans			: 'batchProcessing.getLogs',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'getProcessLog',
				},
				{
					id					: 'purge_uploads',
					visible 		: () => {
						return this.permissionService.hasPermission('batch.admin');
					},
					text				: 'Purge Uploads',
					textTrans			: 'batchProcessing.purgeUploads',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'purgeUploads',
				},
			],
			pagination : {
				pageLimit	: '5',
				pageLimits	: ['5', '10', '50', '100'],
				sortMode	: {field : 'lastName', direction : 'asc'}
			},
		},
		uploadHistory: {
			id				: 'uploadHistory',
			title			: 'File Upload History',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.editFormat');
			},
			titleTrans		: 'batchProcessing.uploadHistoryTitle',
			subtitle		: 'History of file uploads',
			subtitleTrans	: 'batchProcessing.uploadHistorySubtitle',
			tab				: 'Upload History',
			tabTrans		: 'batchProcessing.eventFormTab',
			fieldConfig 	: {
				'fieldName'			: 'uploadHistory',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			pagination : {
				pageLimit	: '5',
				pageLimits	: ['5', '10', '50', '100'],
				sortMode	: {field : 'uploadID', direction : 'desc'}
			},
			searches 		: [
				{
					id					: 'uploadID',
					name				: 'Upload ID',
					model				: 'id',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: '',
					name				: 'File Name',
					model				: 'file_upload_name',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'date_file_uploaded',
					name				: 'Date Uploaded',
					model				: 'date_file_uploaded',
					type				: 'text',
					visible				: true,
					searchable 			: true
				}
			],
			fields : new LioFieldArray(
				new LioButtonField({
					name			: 'Upload ID',
					model			: 'id',
					dynamicName 	: 'id',
					disabledModel 	: 'id',
					nameTrans 		: 'batchProcessing.id',
					type			: 'button',
					callback		: (model) => {
						this.downloadFile(model, 'txt').then(() => {
							this.downloadFile(model, 'fmt');
						});
					},
					visible			: true
				}),
				{
					name			: 'File Name',
					model			: 'file_upload_name',
					max 			: 15,
					type			: 'default',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Date Uploaded',
					model			: 'date_file_uploaded',
					type			: 'timestamp',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Total To Add',
					model			: 'TOTALTOADD',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Update',
					model			: 'TOTALTOUPDATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Deactivate',
					model			: 'TOTALTODEACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Reactivate',
					model			: 'TOTALTOREACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				new LioButtonField({
					name			: 'Email Duplicates',
					model			: 'TOTALEMAILDUPLICATES',
					dynamicName 	: 'TOTALEMAILDUPLICATES',
					disabledModel 	: 'TOTALEMAILDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmails', 'EMAIL');
					},
				}),
				new LioButtonField({
					name			: 'EmployeeID Duplicates',
					model			: 'TOTALEMPLOYEEIDDUPLICATES',
					dynamicName 	: 'TOTALEMPLOYEEIDDUPLICATES',
					disabledModel 	: 'TOTALEMPLOYEEIDDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmployeeIDs', 'EMPLOYEEID');
					},
				}),
				new LioButtonField({
					name			: 'Error',
					model			: 'error',
					dynamicName 	: 'error',
					disabledModel 	: 'error',
					nameTrans 		: 'batchProcessing.error',
					type			: 'button',
					callback		: (model) => {
					 	this.lioModalService.show(model.error);
					},
					visible			: true
				}),
				{
					name			: 'Status',
					model			: 'status',
					type			: 'default',
					visible			: true
				},
			),
		},
		globalUploadHistory: {
			id				: 'globalUploadHistory',
			title			: 'File Upload History',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.admin');
			},
			titleTrans		: 'batchProcessing.globalUploadHistoryTitle',
			subtitle		: 'History of file uploads for all companies',
			subtitleTrans	: 'batchProcessing.globalUploadHistorySubtitle',
			tab				: 'Global Upload History',
			tabTrans		: 'batchProcessing.eventFormTab',
			fieldConfig 	: {
				'fieldName'			: 'globalUploadHistory',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			pagination : {
				pageLimit	: '5',
				pageLimits	: ['5', '10', '50', '100'],
				sortMode	: {field : 'uploadID', direction : 'desc'}
			},
			searches 		: [
				{
					id					: 'uploadID',
					name				: 'Upload ID',
					model				: 'id',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'companyID',
					name				: 'Company ID',
					model				: 'companyid',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: '',
					name				: 'File Name',
					model				: 'file_upload_name',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'date_file_uploaded',
					name				: 'Date Uploaded',
					model				: 'date_file_uploaded',
					type				: 'text',
					visible				: true,
					searchable 			: true
				}
			],
			fields : new LioFieldArray(
				new LioButtonField({
					name			: 'Upload ID',
					model			: 'id',
					dynamicName 	: 'id',
					disabledModel 	: 'id',
					nameTrans 		: 'batchProcessing.id',
					type			: 'button',
					callback		: (model) => {
						this.downloadFile(model, 'txt').then(() => {
							this.downloadFile(model, 'fmt');
						});
					},
					visible			: true
				}),
				{
					name				: 'Company ID',
					model				: 'companyid',
					max 				: 15,
					type				: 'default',
					visible				: true,
					sortable 			: true
				},
				{
					name			: 'File Name',
					model			: 'file_upload_name',
					max 			: 15,
					type			: 'default',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Date Uploaded',
					model			: 'date_file_uploaded',
					type			: 'timestamp',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Total To Add',
					model			: 'TOTALTOADD',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Update',
					model			: 'TOTALTOUPDATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Deactivate',
					model			: 'TOTALTODEACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				{
					name			: 'Total To Reactivate',
					model			: 'TOTALTOREACTIVATE',
					type			: 'text',
					visible			: true,
					locked 			: true,
				},
				new LioButtonField({
					name			: 'Email Duplicates',
					model			: 'TOTALEMAILDUPLICATES',
					dynamicName 	: 'TOTALEMAILDUPLICATES',
					disabledModel 	: 'TOTALEMAILDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmails', 'EMAIL');
					},
				}),
				new LioButtonField({
					name			: 'EmployeeID Duplicates',
					model			: 'TOTALEMPLOYEEIDDUPLICATES',
					dynamicName 	: 'TOTALEMPLOYEEIDDUPLICATES',
					disabledModel 	: 'TOTALEMPLOYEEIDDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmployeeIDs', 'EMPLOYEEID');
					},
				}),
				new LioButtonField({
					name			: 'Error',
					model			: 'error',
					dynamicName 	: 'error',
					disabledModel 	: 'error',
					nameTrans 		: 'batchProcessing.error',
					type			: 'button',
					callback		: (model) => {
					 	this.lioModalService.show(model.error);
					},
					visible			: true
				}),
				{
					name			: 'Status',
					model			: 'status',
					type			: 'default',
					visible			: true
				},
			),
		},
		processHistory: {
			id				: 'processHistory',
			title			: 'File Process History',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.editformat');
			},
			titleTrans		: 'batchProcessing.historyTitle',
			subtitle		: 'History of file processes',
			subtitleTrans	: 'batchProcessing.historySubtitle',
			tab				: 'Process History',
			tabTrans		: 'batchProcessing.eventFormTab',
			fieldConfig 	: {
				'fieldName'			: 'history',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			pagination : {
				pageLimit	: '5',
				pageLimits	: ['5', '10', '50', '100'],
				sortMode	: {field : 'uploadID', direction : 'desc'}
			},
			searches 		: [
				{
					id					: 'uploadID',
					name				: 'Upload ID',
					model				: 'id',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: '',
					name				: 'File Name',
					model				: 'file_upload_name',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'date_file_processed',
					name				: 'Date Processed',
					model				: 'date_file_processed',
					type				: 'text',
					visible				: true,
					searchable 			: true
				}
			],
			fields : new LioFieldArray(
				new LioButtonField({
					name			: 'Upload ID',
					model			: 'id',
					dynamicName 	: 'id',
					disabledModel 	: 'id',
					nameTrans 		: 'batchProcessing.id',
					type			: 'button',
					callback		: (model) => {
						this.downloadFile(model, 'txt').then(() => {
							this.downloadFile(model, 'fmt');
						});
					},
					visible			: true
				}),
				{
					name			: 'File Name',
					model			: 'file_upload_name',
					type			: 'default',
					max  			: 10,
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Date Processed',
					model			: 'date_file_processed',
					type			: 'timestamp',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Total Inserted',
					model			: 'added_count',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Updated',
					model			: 'total_updated',
					nameTrans 		: 'batchProcessing.totalUpdated',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Deactivated',
					model			: 'deactivated_count',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Reactivated',
					model			: 'reactivated_count',
					type			: 'default',
					visible			: true
				},
				new LioButtonField({
					name			: 'Email Duplicates',
					model			: 'TOTALEMAILDUPLICATES',
					dynamicName 	: 'TOTALEMAILDUPLICATES',
					disabledModel 	: 'TOTALEMAILDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmails', 'EMAIL');
					},
				}),
				new LioButtonField({
					name			: 'EmployeeID Duplicates',
					model			: 'TOTALEMPLOYEEIDDUPLICATES',
					dynamicName 	: 'TOTALEMPLOYEEIDDUPLICATES',
					disabledModel 	: 'TOTALEMPLOYEEIDDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmployeeIDs', 'EMPLOYEEID');
					},
				}),
				new LioButtonField({
					name			: 'Error',
					model			: 'error',
					dynamicName 	: 'error',
					disabledModel 	: 'error',
					nameTrans 		: 'batchProcessing.error',
					type			: 'button',
					callback		: (model) => {
					 	this.lioModalService.show(model.error);
					},
					visible			: true
				}),
				{
					name			: 'Status',
					model			: 'status',
					type			: 'default',
					visible			: true
				},
			),
		},
		globalProcessHistory: {
			id				: 'globalProcessHistory',
			title			: 'File Process History',
			visible 		: () => {
				return this.permissionService.hasPermission('batch.admin');
			},
			titleTrans		: 'batchProcessing.historyTitle',
			subtitle		: 'History of file processes for all companies',
			subtitleTrans	: 'batchProcessing.globalHistorySubtitle',
			tab				: 'Global Process History',
			tabTrans		: 'batchProcessing.eventFormTab',
			fieldConfig 	: {
				'fieldName'			: 'history',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			pagination : {
				pageLimit	: '5',
				pageLimits	: ['5', '10', '50', '100'],
				sortMode	: {field : 'uploadID', direction : 'desc'}
			},
			searches 		: [
				{
					id					: 'uploadID',
					name				: 'Upload ID',
					model				: 'id',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'companyID',
					name				: 'Company ID',
					model				: 'companyid',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: '',
					name				: 'File Name',
					model				: 'file_upload_name',
					type				: 'text',
					visible				: true,
					searchable 			: true
				},
				{
					id					: 'date_file_processed',
					name				: 'Date Processed',
					model				: 'date_file_processed',
					type				: 'text',
					visible				: true,
					searchable 			: true
				}
			],
			fields : new LioFieldArray(
				new LioButtonField({
					name			: 'Upload ID',
					model			: 'id',
					dynamicName 	: 'id',
					disabledModel 	: 'id',
					nameTrans 		: 'batchProcessing.id',
					type			: 'button',
					callback		: (model) => {
						this.downloadFile(model, 'txt').then(() => {
							this.downloadFile(model, 'fmt');
						});
					},
					visible			: true
				}),
				{
					name			: 'Company ID',
					model			: 'companyid',
					type			: 'default',
					max  			: 15,
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'File Name',
					model			: 'file_upload_name',
					type			: 'default',
					max  			: 15,
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Date Processed',
					model			: 'date_file_processed',
					type			: 'timestamp',
					visible			: true,
					sortable 		: true,
				},
				{
					name			: 'Total Inserted',
					model			: 'added_count',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Updated',
					model			: 'total_updated',
					nameTrans 		: 'batchProcessing.totalUpdated',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Deactivated',
					model			: 'deactivated_count',
					type			: 'default',
					visible			: true
				},
				{
					name			: 'Total Reactivated',
					model			: 'reactivated_count',
					type			: 'default',
					visible			: true
				},
				new LioButtonField({
					name			: 'Email Duplicates',
					model			: 'TOTALEMAILDUPLICATES',
					dynamicName 	: 'TOTALEMAILDUPLICATES',
					disabledModel 	: 'TOTALEMAILDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmails', 'EMAIL');
					},
				}),
				new LioButtonField({
					name			: 'EmployeeID Duplicates',
					model			: 'TOTALEMPLOYEEIDDUPLICATES',
					dynamicName 	: 'TOTALEMPLOYEEIDDUPLICATES',
					disabledModel 	: 'TOTALEMPLOYEEIDDUPLICATES',
					visible			: true,
					locked 			: true,
					type			: 'button',
					callback		: (model) => {
					 	this.getDuplicates(model.uploadID, model.companyid, 'duplicateEmployeeIDs', 'EMPLOYEEID');
					},
				}),
				new LioButtonField({
					name			: 'Error',
					model			: 'error',
					dynamicName 	: 'error',
					disabledModel 	: 'error',
					nameTrans 		: 'batchProcessing.error',
					type			: 'button',
					callback		: (model) => {
					 	this.lioModalService.show(model.error);
					},
					visible			: true
				}),
				{
					name			: 'Status',
					model			: 'status',
					type			: 'default',
					visible			: true
				},
			),
		},
	};


	downloadFile(model, mode) {
		this.lioModalService.showLoading('modal.downloading');
		this.filesService.downloadBatchFile(model.uploadID, model.companyid, mode);
		this.lioModalService.hideLoading();
		return new Promise((resolve) => {
			setTimeout(() => {
		 		resolve(true);
			}, 1000);
  		});
	}


	getDuplicates(uploadID, companyID, status, item) {
		this.lioModalService.showLoading();
		this.lmsService.post('batchValidation/getByTempStatus', {'uploadID': uploadID, 'companyID': companyID, 'status': status}).then((result) => {
			this.lioModalService.open({
				type: 'table',
				title: item[0].toUpperCase() + item.toLowerCase().slice(1) + ' Duplicates',
				fields: new LioFieldArray(
					{
						name		: item,
						nameTrans 	: 'batchProcessing.totalDuplicates',
						model		: item
					},
					{
						name		: 'Total Duplicates',
						nameTrans 	: 'batchProcessing.totalDuplicates',
						model		: 'TOTALDUPLICATES'
					},
				),
				models: result.properties.employees,
			});
		});
	}
}