/*
 * Service for functions related to email events
*/
import moment from 'moment';
import { Subject, ReplaySubject, of } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { lmsService } from './lms.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';
import { lioModalService } from './lio-modal.service';
import { feedbackService } from './feedback.service';
import { formValidatorService } from './form-validator.service';
import { storageService } from './storage.service';
import { debugService } from './debug.service';
import { exportService } from './export.service';
import { fieldService } from './fields.service';
import { navService } from './nav.service';
import { filesService } from './files.service';

@Injectable({
	providedIn: 'root',
})
export abstract class eventService {
	public eventOptions			: ReplaySubject<any> 	= new ReplaySubject(1);
	public eventsSubscription	: Subject<any> 			= new Subject();
	public callback				: Subject<any> 			= new Subject();
	public onLoadEventForEditing: Subject<any> 			= new Subject();
	public events 				: Array<any> = [];
	public event 				: any = {};
	public gettingEvents 		: boolean = false;
	public settings				: any = {};

	public endPoint				: string = '';
	public historyEndPoint		: string = '';
	public weekDays 			: Array<any> = [
		{name: 'sun', trans : 'scheduleEmails.sundayShort'		, value: 'sunday'},
		{name: 'mon', trans : 'scheduleEmails.mondayShort'		, value: 'monday'},
		{name: 'tue', trans : 'scheduleEmails.tuesdayShort'		, value: 'tuesday'},
		{name: 'wed', trans : 'scheduleEmails.wednesdayShort'	, value: 'wednesday'},
		{name: 'thu', trans : 'scheduleEmails.thursdayShort'	, value: 'thursday'},
		{name: 'fri', trans : 'scheduleEmails.fridayShort'		, value: 'friday'},
		{name: 'sat', trans : 'scheduleEmails.saturdayShort'	, value: 'saturday'}
	];

	public genericFields:Array<any> = [
		{
			name	: 'Field',
			trans	: 'eventSchedule.field',
			model	: 'name'
		}, 
		{
			name	: 'Value',
			trans	: 'eventSchedule.value',
			model	: 'value'
		}
	];

	public weekDayPreviewFields:Array<any> = [
		{
			name	: 'Day',
			trans	: 'eventSchedule.day',
			model	: 'name'
		}, 
		{
			name	: 'Active',
			trans	: 'eventSchedule.active',
			model	: 'value'
		}
	];

	constructor(
		@Inject(lioLogService)			public lioLogService		:lioLogService,
		@Inject(lmsService)				public lmsService			:lmsService,
		@Inject(localizationService)	public localizationService	:localizationService,
		@Inject(lioModalService)		public lioModalService		:lioModalService,
		@Inject(feedbackService)		public feedbackService		:feedbackService,
		@Inject(utilService)			public utilService			:utilService,
		@Inject(storageService)			public storageService		:storageService,
		@Inject(formValidatorService)	public formValidatorService	:formValidatorService,
		@Inject(debugService)			public debugService			:debugService,
		@Inject(exportService)			public exportService		:exportService,
		@Inject(fieldService)			public fieldService			:fieldService,
		@Inject(navService)				public navService			:navService,
		@Inject(filesService)			public filesService			:filesService
	){
		this.debugService.register('events', this);
	}

	/*
	 * Sets the settings
	*/
	setSettings(settings) {
		this.settings = settings;
	}

	/** 
	 * Creates / Edits an event
	 */
	editEvent(event) {
		let eventCopy = this.prepEvent(event);
		if (eventCopy.id) {
			this.sendEventEdit('edit', eventCopy);
		} else {
			this.duplicateEventCheckAndConfirm(eventCopy).then((result) => {
				if (result) {
					this.sendEventEdit('create', eventCopy);
				}
			});
		}
	}

	/** 
	 * Preps the event
	 */
	prepEvent(event) {
		event.schedule.startTime = moment(event.schedule.startTime).format('YYYY-MM-DD') + ' 11:00:00';
		return this.utilService.copy(event);
	}

	/** 
	 * Determines if there is enough info to get the user information
 	 * @return {boolean}
	 */
	abstract canGetUserInfo(event):boolean

	/**
	 * Sends out a confirmed edit or create request
	 */
	sendEventEdit(endPoint, event) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post(this.endPoint + '/' + endPoint, {'event' : event}).then((result) => {
			if (result.success && result.properties.eventID) {
				this.loadEvent(result.properties.eventID).then((event) => {
					this.updateEvent(event);
					this.lioModalService.hideLoading();
					this.lioModalService.show('eventSaved', 'newEventsAreInactive').then(() => {
						this.callback.next({'name': 'changeTab', 'value': 'list'});
					})
				});
			} else {
				this.lioModalService.hideLoading();
				if (endPoint == 'edit') {
					this.lioModalService.show('eventEditFailed');
				} else {
					this.lioModalService.show('eventCreateFailed');
				}
			}
		});
	}

	/*
	 * Updates the event
	*/
	updateEvent(newEvent) {
		let updated = false;
		this.events.forEach((event, index) => {
			if (!updated && event.id === newEvent.id) {
				this.events[index] = newEvent;
				updated = true;
				return;
			}
		});
		
		if (!updated) {
			this.events.push(newEvent);
		}

		this.eventsSubscription.next(this.handleEvents(this.events));
	}

	/** 
	 * Gets the user count
	 */
	getUserCount(event) {
		let eventCopy = this.prepEvent(event);

		this.lioModalService.showLoading('loadingRecords');
		this.feedbackService.clearErrors();

		return this.lmsService.postAsync(this.endPoint + '/getRunningTotal', {'event' : eventCopy}).then((result) => {
			if (result.properties.token) {
				this.lmsService.getAsyncResult(result.properties.token, (result) => {
					if (result.success) {
						this.lioModalService.show('Current User Count: ' + result.properties.event.totalUsers);
					} else {
						this.lioModalService.hideLoading();
						this.feedbackService.setError('failedToLoadEvent');
					}
				});
			} else {
				if (result.success) {
					this.lioModalService.hideLoading();
					this.lioModalService.show('Current User Count: ' + result.properties.event.totalUsers);
				}
			}
		});
	}


	/** 
	 * Gets the users
	 */
	getUsers(event) {
		let eventCopy = this.prepEvent(event);

		this.lioModalService.showLoading('loadingRecords');
		this.feedbackService.clearErrors();

		return this.lmsService.postAsync(this.endPoint + '/getRunningEmployees', {'event' : eventCopy}).then((result) => {
			if (result.properties.token) {
				this.lmsService.getAsyncResult(result.properties.token, (result) => {
					if (result.success && result.properties.event.totalUsers) {
						this.getEmployeesByEmployeeIDs(result.properties.event.employeeIDs);
					} else if (result.success) { 
						this.lioModalService.show('Current User Count: ' + result.properties.event.totalUsers);
					} else {
						this.lioModalService.hideLoading();	
						this.feedbackService.setError('failedToLoadEvent');
					}
				});
			} else {
				if (result.success && result.properties.event.totalUsers) {
					this.getEmployeesByEmployeeIDs(result.properties.event.employeeIDs);
				} else {
					this.lioModalService.show('Current User Count: ' + result.properties.event.totalUsers);
				}
			}
		});
	}


	/** 
	 * Gets employee data
	 * @param {array} employeeIDs
	 */
	getEmployeesByEmployeeIDs(employeeIDs) {
		this.lmsService.postAsync('employee/getEmployeesByEmployeeIDs', {'employeeIDs': employeeIDs}).then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					if (gresult.success) {
						let employees = gresult.properties.employees;
						this.exportEmployees(employees);	
					} else {
						this.lioModalService.hideLoading();
					}
				});
			} else {
				if (result.success) {
					let employees = result.properties.employees;
					this.exportEmployees(employees);
				} else {
					this.lioModalService.hideLoading();
				}
			}
		});
	}


	/** 
	 * Exports employees
	 * @param {array} employees
	 */
	exportEmployees(employees) {
		this.fieldService.setFields(this.settings.employeeFields, this.settings.employeeConfig).then((fields) => {
			this.exportService.exportReport(employees, fields, 'Employees').then((result) => {
				this.lioModalService.hideLoading();
				if (!result) {
					this.feedbackService.setError('failedToExportRecords');
				}
			});
		});
	}


	/** 
	 * Loads an event
	 * @return {boolean}
	 */
	loadEvent(id) {
		let promise = new Promise((resolve) => {
			return this.lmsService.postAsync(this.endPoint + '/getEventByID', {'id': id}).then((result) => {
				let token = result.properties.token;
				if (!token) {
					if (result.properties.event) {
						resolve(this.handleEvent(result.properties.event));
					} else {
						resolve(null);
					}
				} else {
					return this.lmsService.getAsyncResult(token, (gresult) => {
						if (gresult.properties.event) {
							resolve(this.handleEvent(gresult.properties.event));
						} else {
							resolve(null);
						}
					});
				}
			});
		});

		return promise;
	}


	/*
	 * Handles managed event redirects
	*/
	handleManagedEvent(managedEvent) {
		return managedEvent;
	}

	/** 
	 * Handles the event
	 */
	handleEvent(event) {
		event.active	= this.utilService.toBool(event.active);
		event.archived	= this.utilService.toBool(event.archived);
		if (event.schedule) {
			event.schedule.startTime = (moment(event.schedule.startTime)).format('MM/DD/YYYY');
			this.weekDays.forEach((day) => {
				event.schedule[day.value] = this.utilService.toBool(event.schedule[day.value]);
			});
		}
		
		this.event = event;
		return event;
	}



	/** 
	 * Validate an event
	 * @return {boolean}
	 */
	public validateEvent(event) {
		if (!this.findNextUsableDate(event)) {
			return false;
		}

		let eventCopy = this.prepEvent(event);
		let dupe = false;

		if (!this.formValidatorService.isValid(eventCopy, this.settings.fields)) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors());
			return false;
		}
		
		if (!this.validateSchedule(eventCopy)) {
			return false;
		}

		// Checks to ensure that the name isnt already being used
		this.events.forEach((checkEvent) => {
			if (event.id === checkEvent.id) {
				return;
			}
			if (event.name == checkEvent.name) {
				this.feedbackService.setError('thisNameAlreadyExists');
				dupe = true;
			}
		});

		if (dupe) {
			return false;
		}

		this.feedbackService.clearErrors();

		return true;
	}


	/**
	 * Checks if the event we are creating is unique
	 * @param newEvent the new event to ve created
	 */
	duplicateEventCheckAndConfirm(event) {
		let duplicate = null,
			macros = [];
		this.events.forEach((existingEvent) => {
			if (event.type == existingEvent.type && event.courseID == existingEvent.courseID && existingEvent.active) {
				duplicate = existingEvent;
				macros.push({'key': 'duplicateName', 'value': existingEvent.name});
				return;
			}
		});

		if (duplicate) {
			return this.lioModalService.confirm('duplicateEventSave', null, macros);
		} else {
			return new Promise((resolve) => { resolve(true); });
		}
	}



	/**
	 * Returns true if the active event event schedule has at least one week day currently activated
	 */
	moreThanOneWeekDayEnabled = (event = null) => {
		if(event){
			let numActive = 0;
			this.weekDays.forEach((day) => {
				if (event[day.value]) {
					numActive++;
				}
			});

			if (numActive > 1) {
				return true;
			}
		}
		return false;
	};


	/**
	 * Checks if the currently set date is usable, if it isn't, finds the first usable date after the one selected
	 */
	findNextUsableDate(event) {
		let passingValidation = true;
		
		if (event.schedule.startTime) {
			let date = new Date(event.schedule.startTime),
				foundValidDate = null,
				testDate,
				macros = [],
				i;
			
			for (i = 0; i < 31; i++) {
				if (!foundValidDate) {
					//adding i days to the desired date until we find a usable one
					testDate = new Date(date);
					testDate.setDate(testDate.getDate() + i);
					testDate 		= moment(testDate);
					foundValidDate 	= this.isBlackoutDate(testDate, event.schedule);
				}
			}

			if (foundValidDate) {
				if (moment(date).format('YYYY-MM-DD') != testDate.format('YYYY-MM-DD')) {
					//requested date was invalid, but a valid one was found to use instead
					event.schedule.startTime = testDate.format('MM/DD/YYYY');

					//adding ' 00:00:00-7:00' tells moment that the input was in the chicago time zone at midnight
					let newDateWithTimeZone = moment(testDate.format('YYYY-MM-DD') + 'T00:00:00-07:00');
					
					macros.push({key : 'newDate', value : newDateWithTimeZone.local().format('MM/DD/YYYY')});
					this.lioModalService.show('dateInvalid', 'dateSetTo', macros);
					passingValidation = false;
				}
			} else {
				//an error occured, we could not find a valid date
				this.lioModalService.show('dateValidationError');
				passingValidation = false;
			}
		}

		return passingValidation;
	}


	blockPastDates(event) {
		if (event.schedule.startTime) {
			let day = moment(moment(event.schedule.startTime).format('YYYY-MM-DD') + 'T00:00:00-07:00'),
				tommorow = this.getMinEventStartDate(true),
				macros = [{key : 'newDate', value : tommorow}];

			if (day.isBefore(tommorow)) {
				this.lioModalService.show('dateInvalid', 'dateSetTo', macros);
				event.schedule.startTime = tommorow;
				return false;
			}
		}
		return true;
	}

	/**
	 * Get black out days
	 */
	isBlackoutDate(date:moment.Moment, schedule) {
		if(date === null){
			return false;
		}
		switch(schedule.recurranceType) {
			case 'monthly'	:
				return date.date() == schedule.recurranceDay;
			case 'weekly'	:
				switch(schedule.recurranceDay){
					default			:
					case 'sunday'	:
						return date.day() == 0;
					case 'monday'	:
						return date.day() == 1;
					case 'tuesday'	:
						return date.day() == 2;
					case 'wednesday':
						return date.day() == 3;
					case 'thursday'	:
						return date.day() == 4;
					case 'friday'	:
						return date.day() == 5;
					case 'saturday'	:
						return date.day() == 6;
				}
			case 'daily'	:
				return (schedule.sunday		&& date.day() == 0)
					|| (schedule.monday		&& date.day() == 1)
					|| (schedule.tuesday	&& date.day() == 2)
					|| (schedule.wednesday 	&& date.day() == 3)
					|| (schedule.thursday 	&& date.day() == 4)
					|| (schedule.friday		&& date.day() == 5)
					|| (schedule.saturday 	&& date.day() == 6);
			case 'once'		:
			default			:
				return true;
		}
	}

	/** 
	 * Gets the list of active and inactive events
	 */
	getEvents(includePacEvents:boolean = false) {
		if (this.events.length) {
			this.eventsSubscription.next(this.handleEvents(this.events));
			return;
		}
		let params = {};
		if (includePacEvents) {
			params['includePacEvents'] = true;
		}

		if (this.gettingEvents) {
			return;
		}

		this.gettingEvents = true;
		this.lmsService.postAsync(this.endPoint + '/getAllEvents', params).then((result:any) => {
			if (result.properties.token) {
				this.lmsService.getAsyncResult(result.properties.token, (result) => {
					this.eventsSubscription.next(this.handleEvents(result.properties.events));
					this.gettingEvents = false;
				});
			} else {
				this.eventsSubscription.next(this.handleEvents(result.properties.events));
				this.gettingEvents = false;
			}
			this.callback.next({'name': 'loaded', 'value': 'events'});
		});
	}


	/** 
	 * Gets an event by ID
	 * @param {string} id
	 * @param {boolean} includePacEvents
	 * @return {Promise}
	 */
	getEventByID(id, includePacEvents) {
		let params = {'id': id};
		if (includePacEvents) {
			params['includePacEvents'] = true;
		}

		return this.lmsService.post(this.endPoint + '/getEventByID', params).then((result:any) => {
			if (result.success) {
				return result.properties.event;
			}
		});
	}

	/** 
	 * Gets an event to show
	 * @param {object} event
	 */
	showEvent(event, fields:Array<any>, previewFields:Array<any>) {
		this.lioModalService.showLoading('loading');
		this.getEventByID(event.eventID, true).then((event) => {
			this.lioModalService.hideLoading();
			if (!event) {
				return;
			}
			return this.showEventHandler(event, fields, previewFields);
		});
	}
	
	/** 	
	 * Shows a table lioModal containing an event
	 * @param {object} event An event object
	 */
	showEventHandler(event, fields:Array<any>, previewFields:Array<any>) {
		let models 		= [];
		let cellValue 	= {};
		
		fields.forEach((header) => {
			cellValue = this.getEventTableCellValue(event, header);
			if (cellValue) {
				let cellObject:any = {};
				//append properties if we recieved an object, otherwise wrap the recieved value
				if (typeof cellValue == 'object' && !Array.isArray(cellValue)) {
					cellObject = cellValue;
				} else {
					cellObject.value = cellValue;
				}

				cellObject.name 		= header.name;
				cellObject.nameTrans 	= 'form.' + header.name.toLowerCase();
			
				models.push(cellObject);
			}
		});

		this.lioModalService.open({'type': 'table', 'title': event.name, 'fields': previewFields, 'models': models});
	}

	/** 
	 * Submit request to export lists of users
	 * @param {object} historyEvent An history entry object
	 */
	exportUsers(historyEvent) {
		let total = historyEvent.totalProcessed;
		if (!total) {
			return this.lioModalService.show('There were no users processed');
		}

		if (total > 10000) {
			return this.lioModalService.show('tooManyContactUs');
		}

		return this.lmsService.postAsync(this.historyEndPoint +  '/exportByProcessID', {'processID' : historyEvent.processID},
		'loadingRecords').then((result) => {
			if (result.properties.token) {
				this.lmsService.getAsyncResult(result.properties.token, (result) => {
					this.handleExportedRecords(result);
				});
			} else {
				this.handleExportedRecords(result);
			}
		});
	}
	/** 
	 * Handles exported list of users
	 */
	handleExportedRecords(result) {
		if(!result.properties.cancelled && !result.properties.cancelRequested) {
			let file = result.properties.file;
			if (!file) {
				this.lioModalService.hide();
				this.feedbackService.setError('failedToExportRecords');
				return;
			}

			this.filesService.getFile(file);
		}
		this.lioModalService.hide();
	}

	/*
	 * Gets the min event start date
	 * @TODO - dry this up
	*/
	getMinEventStartDate(asString:boolean = false):moment.Moment|string {
		//adding ' 00:00:00-7:00' tells moment that the input was in the chicago time zone at midnight
		let date = moment(moment().format('YYYY-MM-DD') + 'T00:00:00-07:00').add(1, 'day');
		if (asString) {
			return date.local().format('MM/DD/YYYY');
		} else {
			return date;
		}
	}


	/*
	 * Validates a schedule 
	*/
	validateSchedule(event) {
		if (event.schedule.recurranceType) {
			if (event.schedule.recurranceType == 'weekly' || event.schedule.recurranceType == 'monthly') {
				if (!event.schedule.recurranceDay) {
					if (event.schedule.recurranceType == 'weekly') {
						this.feedbackService.setErrors(['Please select a day of the week for this event to run on']);
						return false;
					} else if (event.schedule.recurranceType == 'monthly') {
						this.feedbackService.setErrors(['Please select a day of the month for this event to run on']);
						return false;
					}
				}
				
				if(!event.schedule.recurranceDetail) {
					this.feedbackService.setErrors(['Please select how often this should run']);
					return false;
				}
			} else if(event.schedule.recurranceType == 'daily'){
				let schedule = event.schedule;
				if(!schedule.monday && !schedule.tuesday && !schedule.wednesday && !schedule.thursday && !schedule.friday && !schedule.saturday && !schedule.sunday) {
					this.feedbackService.setErrors(['Please select at least one day of the week for this to run']);
					return false;
				}
			}
		} else {
			this.feedbackService.setErrors(['Please select how often this should run']);
			return false;
		}

		if (!event.schedule.startTime) {
			this.feedbackService.setErrors(['Please select when this event should first run']);
			return false;
		}
		return true;
	}

	/** 
	 * Handles events
	 * @param {array} events
	 */
	handleEvents (events) {
		if (!events || !events.length) {
			return [];
		}

		for(let i = 0; i < events.length; i++){
			events[i] = this.handleEvent(events[i]);
		}

		this.events = events;

		return events;
	}

	/**
	 * Gets the value for a cell in a table that displays a list of events
	 * @param {object} event An event object
	 * @param {object} header the field settings for the cell
	 */
	getEventTableCellValue(event, header) {
		switch (header.model) {
			case 'filters':
				return this.getFilterDescription(event);
			case 'active':
				return event[header.model] == true;
			case 'type':
				return event[header.model];
			default:
				if (header.model.indexOf('schedule.') > -1) {
					let recurrance = header.model.replace('schedule.', '');
					if(recurrance === 'recurranceDay' && event.schedule.recurranceType === 'daily') {
						return this.getWeekDayString(event);
					}
				}
		}
		return event[header.model];
	}

	/**
	 * Gets an object representing the weekdays of an event
	 */
	getWeekDays(event){
		let result = [
			{name: 'Sunday'		, nameTrans : 'emailEvent.sunday'	, trans : null, valueTrans : null, value: event.schedule.sunday},
			{name: 'Monday'		, nameTrans : 'emailEvent.monday'	, trans : null, valueTrans : null, value: event.schedule.monday},
			{name: 'Tuesday'	, nameTrans : 'emailEvent.tuesday'	, trans : null, valueTrans : null, value: event.schedule.tuesday},
			{name: 'Wednesday'	, nameTrans : 'emailEvent.wednesday', trans : null, valueTrans : null, value: event.schedule.wednesday},
			{name: 'Thursday'	, nameTrans : 'emailEvent.thursday'	, trans : null, valueTrans : null, value: event.schedule.thursday},
			{name: 'Friday'		, nameTrans : 'emailEvent.friday'	, trans : null, valueTrans : null, value: event.schedule.friday},
			{name: 'Saturday'	, nameTrans : 'emailEvent.saturday'	, trans : null, valueTrans : null, value: event.schedule.saturday}
		];

		//Ignore individual days values if recurrance is weekly, display the proper day of the week
		if(event.schedule.recurranceType == 'weekly'){
			let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
			days.forEach((day, i) => {
				if(day == event.schedule.recurranceDay){
					result[i].value = true;
					result = [result[i]];
				}
			});
		}

		result.forEach((day) => {
			if(JSON.parse(day.value)){
				day.valueTrans = 'form.true';
			} else {
				day.valueTrans = 'form.false';
			}
		});

		return result;
	}

	/**
	 * Gets the weekdays of an event as a comma connected string
	 * @return {string}
	 */
	getWeekDayString(event) {
		let weekDays 		= this.getWeekDays(event),
			weekDayString 	= [];

		weekDays.forEach((day) => {
			if (JSON.parse(day.value)) {
				let local = this.localizationService.get(day.trans);
				if (local) {
					weekDayString.push(local);
				} else {
					weekDayString.push(day.name);
				}
			}
		});
		return weekDayString.join(', ');
	}

	/**
	 * Gets the filter title
	 * @param {array} event
	 * @return {object}
	 */
	abstract getFilterTitle(event):string;

	/**
	 * turns event filter data into friendlier descriptions
	 * @param {array} event
	 * @return {array}
	 */
	abstract getFilterDescription(event):Array<any>;

	/**
	 * Gets an object representing the weekly event
	 */
	getWeekly(event) {
		let dayOfWeek = this.getWeekDayString(event),
			result = [
			{
				name: 'Recurrance',
				nameTrans : 'emailEvent.recurrance',
				value: 'Runs every ' + event.schedule.recurranceDetail + ' week(s)',
				valueTrans : 'schedule.weeklyScheduleRecurrance',
				valueTransMacros: [{key: 'detail', value: event.schedule.recurranceDetail}]
			},
			{name: 'Start Time'	, nameTrans : 'emailEvent.startTime', trans : null, valueTrans : null, value: event.schedule.startTime},
			{name: 'Day(s) Of Week', nameTrans : 'emailEvent.dayOfWeek', trans : null, valueTrans : null, value: dayOfWeek},
		];


		return result;
	}

	/**
	 * Gets an object representing the monthly event
	 */
	getMonthly(event) {
		let result = [
			{
				name: 'Recurrance',
				nameTrans : 'emailEvent.recurrance',
				value: 'Runs every ' + event.schedule.recurranceDetail + ' month(s)',
				valueTrans : 'schedule.monthlyScheduleRecurrance',
				valueTransMacros: [{key: 'detail', value: event.schedule.recurranceDetail}]
			},
			{name: 'Start Time'	, nameTrans : 'emailEvent.startTime', trans : null, valueTrans : null, value: event.schedule.startTime},
			{name: 'Day Of Month', nameTrans : 'emailEvent.dayOfMonth', trans : null, valueTrans : null, value: event.schedule.recurranceDay},
		];

		return result;
	}

	/** 
	 * Shows a table lioModal containing a list of the filters an event uses
	 * @param {object} event An event object
	 */
	showFilters(event) {
		let title 	= this.getFilterTitle(event),
			fields 	= [{'model' : 'value'}], 
			models 	= this.getFilterDescription(event);

		models.forEach((model) => {
			model.value = this.localizationService.get(model.trans, model.value, model.macros);
		});

		this.lioModalService.open({'type': 'table', 'title': title, 'fields': fields, 'models': models});
	}
	
	/** 
	 * Grabs the total users
	 * @param {object} event An event object
	 */
	showTotalUsers(event, suppressModal:boolean = false) {
		if (!suppressModal) {
			this.lioModalService.showLoading('loading');
		}

		if (event.totalUsers) {
			if (!suppressModal) {
				this.lioModalService.hideLoading();
			}
			return of(event).toPromise();
		}

		return this.lmsService.post(this.endPoint + '/getUserCount', {'id' : event.id}).then((result) => {
			if (!suppressModal) {
				this.lioModalService.hideLoading();
			}
			
			if (result.success) {
				this.updateEvent(result.properties.event);
				event.totalUsers 		= result.properties.event.totalUsers;
				event.suppressButton 	= true;
				return event;
			}
		});
	}

	/** 
	 * Gets warning messages about this event
	 * @param {object} event An event object
	 * @return {object}
 
	*/
	getTemplateWarning(event) {
		let	message = {
				'description': null, 
				'macros': [{key : 'event', value : event.name}],
			};

		if (event.missingCount) {
			message.macros.push({'key': 'totalUsers', 'value': event.missingCount});
			message.description = 'usersExcludedFromTemplates';
		}

		return message;
	}

	/** 
	 * Shows a table based on the schedule type of an event
	 * @param {object} event An event object
	 */
	showSchedule(event) {
		let type = event.schedule.recurranceType;
		switch (type) {
			case 'once':
				this.showOnce(event);
				return;
			case 'daily':
				this.showWeekDays(event);
				return;
			case 'weekly':
				this.showWeekly(event);
				return;
			case 'monthly':
				this.showMonthly(event);
				return;
			default:
				this.lioLogService.error(['Type not set', type]);
				return;
		}
	}

	/** 
	 * Shows a one time event schedule
	 * @param {object} event An event object
	 */
	showOnce(event) {
		let startTime = event.schedule.startTime;
		this.lioModalService.show('eventSchedule.oneTimeSchedule', 'eventSchedule.oneTimeDetail', [{'key': 'detail', 'value':  startTime}]);
	}

	/** 
	 * Shows a weekly schedule
	 * @param {object} event An event object
	 */
	showWeekly(event) {
		let models	 	= this.getWeekly(event),
			title 		= 'eventSchedule.weeklySchedule';

		this.lioModalService.open({'type': 'table', 'title': title, 'fields': this.genericFields, 'models': models});
	}

	/** 
	 * Shows a monthly schedule
	 * @param {object} event An event object
	 */
	showMonthly(event) {
		let models	 	= this.getMonthly(event),
			title 		= 'eventSchedule.monthlySchedule';

		this.lioModalService.open({'type': 'table', 'title': title, 'fields': this.genericFields, 'models': models});
	}

	/** 
	 * Shows a table lioModal containing a list of weekdays an event uses
	 * @param {object} event An event object
	 */
	showWeekDays(event) {
		let models	 	= this.getWeekDays(event),
			title 		= 'eventSchedule.daysEachWeek';

		this.lioModalService.open({'type': 'table', 'title': title, 'fields': this.weekDayPreviewFields, 'models': models});
	}

	/*
	* Changes the state of the event
	*/
	changeState(event) {
		if (event.active) {
			this.deactivateEvent(event);
		} else {
			this.activateEvent(event);

		}
	}

	/**
	 * Send the backend an activation request for an event
	 */
	activateEvent(event) {
		let warning = this.getTemplateWarning(event);
		return this.lioModalService.confirm('modal.confirmActivation', warning.description, warning.macros).then((confirm) => {
			if (confirm) {
				this.lioModalService.showLoading('processing');
				return this.lmsService.post(this.endPoint + '/activate', {'id' : event.id}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						event.active = true;
						event.selected = true;
						event.activeStatus 	= 'Yes';
						event.activeColor 	= 'primary';
						this.updateEvent(event);
						this.feedbackService.clearErrors();
						this.lioModalService.show('modal.eventActivateSuccess', this.endPoint + 'TimeNotification');
					} else {
						this.lioModalService.show('eventActivateFail');
					}
				});
			}
		});
	}

	/** 
	 * Loads the selected event into the editing form, asks the user to deactivate it first if it is active
	 */
	loadEventForEditing(event) {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		if (event.active) {
			return this.lioModalService.confirm('modal.blockEventEditConfirm', null, {key : 'event', value : event.name}).then((confirm) => {
				if (confirm) {
					this.deactivateEvent(event, true).then((result) => {
						if (result) {
							this.onLoadEventForEditing.next(event);
						}
					});
				}
			});
		} else {
			this.onLoadEventForEditing.next(event);
		}
	}

	/**
	 * Send the backend a deactivation request for an event
	 */
	sendDeactivateEvent(event) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post(this.endPoint + '/deactivate', {'id' : event.id}).then((result) => {
			this.lioModalService.hideLoading();

			if (result.success) {
				event.active = false;
				event.selected = false;
				event.activeStatus 	= 'No';
				event.activeColor 	= 'warn';
				this.updateEvent(event);
				this.feedbackService.clearErrors();
				return true;
			} else {
				this.lioModalService.show('eventDeactivateFail');
				return false;
			}
		});
	}

	/**
	 * Confirm whether to deactivate an event, or skip confirmation if requested
	 */
	deactivateEvent(event, skipConfirm:boolean = false) {
		if (skipConfirm) {
			return this.sendDeactivateEvent(event);
		} else {
			return this.lioModalService.confirm('modal.eventDeactivateConfirm', null, {key : 'event', value : event.name}).then((confirm) => {
				if (confirm) {
					return this.sendDeactivateEvent(event);
				}
			});
		}
	}

	/**
	 * Deletes an event
	 */
	deleteEvent(event) {
		if (event.active) {
			this.lioModalService.show('<i class="fa fa-exclamation-triangle text-danger text-huge"></i>', 'eventDeleteActiveFail', {key : 'event', value : event.name});
			return;
		}

		return this.lioModalService.confirm('modal.eventDeleteConfirm', null, {key : 'event', value : event.name}).then((confirm) => {
			if (confirm) {
				this.lioModalService.showLoading('processing');
				this.lmsService.post(this.endPoint + '/archiveByID', {'id' : event.id}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						this.feedbackService.clearErrors();

						this.events.forEach((otherEvent, index) => {
							if (otherEvent.id === event.id) {
								this.events.splice(index, 1);
								return;
							}
						});

						this.events.forEach((loadedEvent, index) => {
							if (loadedEvent.id == event.id) {
								this.events.splice(index, 1);
							}
						});
						this.lioModalService.show('eventDeleteSuccess');
					} else {
						this.lioModalService.show('eventDeleteFail');
					}
				});
			}
		});
	}

	/**
	 * Manages Enrollments from the email event
	 */
	manageEnrollments(event) {
		this.storageService.set('managedEvent', event);
		this.storageService.set('selectedTab', 'editor');
		this.navService.goto('scheduleEnrollments');
	}

	/**
	 * Manages Emails from the enrollment event
	 */
	manageEmails(event) {
		this.storageService.set('managedEvent', event);
		this.storageService.set('selectedTab', 'editor');
		this.navService.goto('scheduleEmails');
	}
}