<div>
	<canvas baseChart [width]="width" [height]="height" class="mx-auto"
		[data]="data"
		[labels]="pieChartLabels"
		[legend]="pieChartLegend"
		chartType="pie"
		[colors]="pieChartColors"
		[options]="pieChartOptions"
        [plugins]="pieChartPlugins">
	</canvas>
</div>