/*
 * Fields Helper
*/
import { Subject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';

import { ccfService } from './ccf.service';
import { lioLogService } from './lio-log.service';
import { lioModalService } from './lio-modal.service';
import { lmsService } from './lms.service';
import { localizationService } from './localization.service';
import { navService } from './nav.service';
import { permissionService } from './permissions.service';
import { settingsService } from './settings.service';
import { stateService } from './state.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class fieldService{
	debug 					:boolean 	= false;
	hidingField 			:boolean 	= false;
	gotAllPages				:boolean	= false;
	fieldName				:string 	= '';
	pages					:any 		= {};

	public fieldsUpdated	:Subject<void> = new Subject();
	public fieldsModified	:Subject<void> = new Subject();

	constructor(
		@Inject(ccfService)				private ccfService			:ccfService,
		@Inject(lioLogService)			private lioLogService		:lioLogService,
		@Inject(lioModalService)		private lioModalService		:lioModalService,
		@Inject(lmsService)				private lmsService			:lmsService,
		@Inject(localizationService)	private localizationService	:localizationService,
		@Inject(navService)				private navService			:navService,
		@Inject(permissionService)		private permissionService	:permissionService,
		@Inject(settingsService)		private settingsService		:settingsService,
		@Inject(stateService)			private stateService		:stateService,
		@Inject(utilService)			private utilService			:utilService,
		@Inject(debugService)			private debugService		:debugService
	){
		this.debugService.register('fields', this);
	}

	/*
	 * Sets the fields
 	 * @param {object} this
	 * @param {array} fields
	 * @param {?array} config
	 * @return {array}
	*/
	setFields(fields, config) {
		let activeField,
			roleField;

		if (config && config.fieldName) {
			this.fieldName = config.fieldName;
		}

		if (config && config.addCCFtoFields) {
			fields =  this.ccfService.addCCFtoFields(fields, config);
		}

		if (config && config.addActiveField) {
			activeField = this.permissionService.getActiveField(config);

			if (!this.fieldExists(fields, activeField)) {
				fields.push(activeField);
			}
		}
		
		if (config && config.addRoleToFields) {
			roleField = this.permissionService.getRoleField(config);

			if (roleField && !this.fieldExists(fields, roleField)) {
				fields.push(roleField);
			}
		}

		if (config && config.addLangField) {
			fields = this.addLangField(fields, config);

		}

		if (config && config.localizeFields) {
			fields = this.localizationService.translateFields(fields);
		}
		
		if (config && config.permissionFields) {
			if (!config.permissionID) {
				config.permissionID = this.stateService.getActiveRoleID();
			}
			fields = this.permissionService.setFields(fields, config);
		}

		if(config){
			return this.overrideFields(fields, config).then((fields) => {
				this.fieldsUpdated.next();
				return fields;
			});
		}else{
			return new Observable((subscriber) => {
				subscriber.next(fields);
				subscriber.complete();
			}).toPromise().then((fields)=>{
				this.fieldsUpdated.next();
				return fields;
			});
		}
	}



	/*
	 * Adds the lang field
	 * @param {array} fields
	 * @param {object} config
	 * @return {array}
	*/
	addLangField(fields, config) {
		if (this.settingsService['supressLangs']) {
			return fields;
		}

		let langID 		= config.langID,
			langField 	= this.localizationService.getLangField(langID, config),
			exists 		= false;
		
			fields.forEach((field) =>{
			if (field.model === langField.model) {
				exists = true;
			}
		});

		if (!exists) {
			fields.push(langField);
		}
		return fields;
	}


	/**
	 * Overrides fields from the db
	 * @param {string} name
	 * @param {array} fields
	 * @param {object} config
	 * @return {array}
	 */
	overrideFields(fields, config) {
		let activeRoleID 	= this.stateService.getActiveRoleID(),
			name 			= config.fieldName,
			roleID 			= config.roleID,
			UID 			= config.UID;

		if (!this.utilService.isDefined(roleID)) {
			roleID = activeRoleID;
		}

		let observable = new Observable((subscriber) => {
			//wait for permissions to be loaded
			this.stateService.waitForLoaded.pipe(take(1)).subscribe(() => {
				if (!config.overrideFields) {
					subscriber.next(fields);
					subscriber.complete();
					return;
				}
				
				this.getFieldsByPageName(name, roleID, config, UID).subscribe((dbFields) => {
					if (dbFields) {
						fields.forEach((field) => {
							this.overrideField(field, dbFields, name);
						});
					}
					subscriber.next(fields);
					subscriber.complete();
				});
			});
		});

		return observable.toPromise();
	}



	/**
	 * Gets fields from the db
	 * @param {string} name
	 * @param {?number} roleID
	 * @param {?object} config
	 * @return {array}
	 */
	getFieldsByPageName(name, roleID, config, UID) {
		let companyID 		= config.companyID || this.stateService.getActiveCompanyID(),
			permission		= config.permission || 'fields.read',
			url				= config.url;

		let observable = new Observable((subscriber) => {
			// Check permissions
			if (permission !== 'bypass' && !this.permissionService.hasPermission(permission)) {
				this.lioLogService.log(['Field Permission Denied', permission]);
				subscriber.next([]);
				subscriber.complete();
				return;
			}

			if (!url) {
				// Got all pages, return provided 
				if (this.gotAllPages) {
					subscriber.next(this.pages[name]);
					subscriber.complete();
					return ;
				}

				// Get all pages, and return provided 
				if (!this.gotAllPages) {
					this.getAllPages(roleID, companyID, UID).then((pages) => {
						if (pages) {
							this.pages = pages;
							subscriber.next(this.pages[name]);
						} else {
							subscriber.next([]);
						}
						subscriber.complete();
					});
					return;
				}
			}

			// Custom fields request
			this.lmsService.post(url, {'name': name, 'roleID' : roleID, 'companyID': companyID, 'UID': UID}, {'cache': true}).then((result:any) => {
				if (result.success) {
					if(result.properties.fields.length) {
						subscriber.next(result.properties.fields);
						subscriber.complete();
					}
				}
				this.gotAllPages = true;
				subscriber.next([]);
				subscriber.complete();
			});
		});

		return observable;
	}


	/**
	 * Gets fields from the db
	 * @param {string} name
	 * @param {?number} roleID
	 * @param {?object} config
	 * @return {array}
	 */
	getAllPages(roleID, companyID, UID) {
		return this.lmsService.post('fields/getAllPages', {'roleID' : roleID, 'companyID': companyID, 'UID': UID}, {'cache': true}).then((result:any) => {
			this.gotAllPages = true;
			if (result.success) {
				return result.properties.pages;
			} else {
				return null;
			}
		});
	}


	/**
	 * Saves hidden state of field for this user
	 * @param {string} name
	 * @return {array}
	 */
	hideField(field) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('fields/hideField', {'pageName': this.fieldName, 'field': field.model}).then(() => {
			this.lioModalService.hideLoading();
			this.hidingField = true;
			field.visible = false;
			this.clearCache();
			this.fieldsModified.next();

			return true;
		});
	}



	/**
	 * Resets field caches
	 */
	clearCache() {
		this.lmsService.clearCache();
		this.gotAllPages = false;
		this.pages = {};
	}

	/**
	 * Resets hidden field for this page
	 * @param {string} name
	 * @return {array}
	 */
	resetPage() {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('fields/resetPage', {'pageName': this.fieldName}).then((result:any) => {
			if (result.success) {
				this.clearCache();
				setTimeout(() => {
					this.navService.reload();
				}, 3000);
				this.lioModalService.show('savedRefresh');
			}
			return true;
		});
	}
	
	/**
	 * Overrides the specific field
	 * @param {object} field
	 * @param {array} dbFields
	 * @param {string} name
	 * @return {array}
	 */
	overrideField(field, dbFields, name) {
		let options = ['required', 'visible', 'locked', 'export', 'options', 'multiple', 'type', 'customOptions', 'segregation', 'loadOptions', 'tooManyResults'],
			option,
			i;

			let keys = Object.keys(dbFields);
			keys.forEach((key) => {
			if (!dbFields[key] || field.disableOverride) {
				return;
			}
			if (field.model === dbFields[key].model) {
				if (!field.originalField) {
					field.originalField = this.utilService.copy(field);
				}
				for (i in options) {
					option = options[i];
					if (dbFields[key][option] === null || dbFields[key][option] === undefined) {
						continue;
					}
					//custom types are gone, don't allow db to override to custom
					//Also prevent CCF types from being overridden, those are currently handled in settings
					if(option == 'type'){
						if(dbFields[key][option] != 'custom' && !field.isCCF){
							field[option] = dbFields[key][option];
						}
					}else{
						field[option] = dbFields[key][option];
					}

					//force boolean options to be booleans
					if(options[i] == 'required' 
					|| options[i] == 'visible' 
					|| options[i] == 'locked' 
					|| options[i] == 'export' 
					|| options[i] == 'multiple'){
						field[option] = this.utilService.toBool(field[option]);
					}
				}
				field.overridden = true;
				field.permissionID = dbFields[key].permissionID;
				if (this.debug) {
					this.lioLogService.log('Field Override: ' + name + '.' + field.model);
				}
			}
		}, this);

		if (this.hidingField) {
			this.lioModalService.hideLoading();
			this.hidingField = false;
		}

		return field;

	}

	/**
	 * Checks if the passed field already exists in a collection of fields, check is made based on model name
	 * @param {array} fields A collection of fields
	 * @param {object} newField The field to check for
	 * @return {boolean}
	 */
	fieldExists(fields, newField){ 
		let found = false;
		let keys = Object.keys(fields);
		keys.forEach((key) => {
			if(fields[key].model == newField.model){
				found = true;
			}
		});
		return found;
	}



	/**
	 * Determines if a field should be hidden based on conditions
	* - 	e.g.  conditions : ['queryID', {'recurranceType': 'daily'}], //// the model's recurranceType must equal daily and have a queryID
	 * @param {array} conditions
	 * @param {array} model
	 * @param {array} field
	 * @return {boolean}
	 */
	hasConditions(conditions, model, field) {
		if (!conditions) {
			field.hiddenByConditions = false;
			return false;
		}

		let totalConditions = conditions.length,
			originalModel 	= this.utilService.copy(model),
			totalMatches 	= 0,
			debug 			= field.debug;


		// Conditions are object based matches
		conditions.forEach((condition) => {
			model = this.utilService.copy(originalModel);
			if (condition !== null && typeof condition === 'object') {		
				let conditionkeys = Object.keys(condition);		
				conditionkeys.forEach((key) => {
					let keys = key.split('.');

					// Split up periods to handle submodels
					keys.forEach((key) => {
						if (model) {
							model = model[key];
						}
					});

					if (typeof model === 'undefined') {
						return;
					}

					if (Array.isArray(condition[key])) {
						if (condition[key].indexOf(model) > -1) {
							totalMatches++;
						}
					} else {
						// HANDLE Exceptions
						let value = condition[key];
						if (typeof value === 'string' && value.indexOf('!') > -1) {
							value = value.split('!')[1];
							if (model !== value) {
								totalMatches++;
							}
						} else {
							if (model === value) {
								totalMatches++;
							}
						}
					}
					
				});
			} else {
				let keys = condition.split('.');

				// Split up periods to handle submodels
				keys.forEach((key) => {
					if (model) {
						model = model[key];
					}
				});

				if (model) {
					totalMatches++;
				}
			}
		});

		// If we haven't matched all conditions, then we stil have conditions
		if (totalMatches !== totalConditions) {
			if (debug) {
				this.lioLogService.log(['Has Conditions', originalModel, field, conditions]);
			}
			field.hiddenByConditions = true;
			return true;
		}
		field.hiddenByConditions = false;
		return false;
	}
}