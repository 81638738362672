import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: errorLogSettings
})
export class errorLogSettings {
	pagination:any = {
		'pageLimit'	: '10',
		'pageLimits': ['10', '50', '100'],
		'sortMode'	: {field : 'lastName', direction : 'asc'}
	};
	
	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Error ID',
			'model': 'errorID',
			'type': 'text',
		},
		{
			'name': 'Table',
			'model': 'tableName',
			'type': 'text',
		},
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
		},
		{
			'name': 'User Role',
			'model': 'role',
			'type': 'text',
		},
		{
			'name': 'e-mail',
			'model': 'email',
			'type': 'email',
		},
		{
			'name': 'IP Address',
			'model': 'IP',
			'type': 'text',
		},
		{
			'name': 'Browser',
			'model': 'userAgent',
			'type': 'text',
		},
		{
			'name': 'URL',
			'model': 'referrer',
			'type': 'text',
		},
		{
			'name': 'Error',
			'model': 'errorMsg',
			'type': 'textarea',
		},
		{
			'name': 'History',
			'model': 'history',
			'type': 'textarea',
		},
	);
}