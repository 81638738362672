<div class="m-3">
	<span *ngFor="let button of buttons">
		<span *ngIf="button.type == 'upload'">
			<lio-file-uploader 
				[settings]="button.settings" 
				[hidden]="!button.visible || fieldService.hasConditions(button.visibleConditions, model, button)" 
				[disabled]="button.disabled || fieldService.hasConditions(button.disabledConditions, model, button)" 
				(success)="handleUploaded(button, $event)" 
				(failure)="handleFailure(button, $event)">
			</lio-file-uploader>
		</span>

		<span *ngIf="button.type != 'upload'">
			<button 
				id="am_button_{{button.id}}" 
				(click)="onClick.emit(button.callback)" 
				*ngIf="!button.remove" 
				[hidden]="!button.visible || fieldService.hasConditions(button.visibleConditions, model, button)" 
				[disabled]="button.disabled || fieldService.hasConditions(button.disabledConditions, model, button)" 
				class="btn btn-sm m-t-n-xs mr-1" 
				[ngClass]="button.class" 
				type="submit">
				<strong localize [model]="button" [items]="['text', 'name']">{{button.text}}</strong>
			</button>
		</span>
	</span>
</div>