import { Inject, Injectable } from '@angular/core';
import { dashboardSettings } from '../dashboard/dashboard.settings';
import { assignSettings } from '../assign/assign.settings';
import { reportsService } from '../../services/reports.service';
import { enrollmentSettings } from '../enrollments/enrollments.settings';
import { permissionService } from '../../services/permissions.service';
import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: reportSettings
})
export class reportSettings {
	constructor(
		@Inject(permissionService) private permissionService:permissionService,
		@Inject(dashboardSettings) private dashboardSettings:dashboardSettings,
		@Inject(enrollmentSettings) private enrollmentSettings:enrollmentSettings,
		@Inject(reportsService) private reportsService:reportsService,
		@Inject(assignSettings) private assignSettings:assignSettings,
	){}
	
	panelConfig:any = {
		'select' : {
			'id'				: 'panelTab_report_select_form',
			'reloadOnChange'	: true,
			'hasQueryTool'		: true,
			'title'				: 'Select a report to download',
			'titleTrans'		: 'report.selectHeader',
			'tab'				: 'Select Report',
			'tabTrans'			: 'report.selectHeader',
			'settings': {
				'fields': new LioFieldArray(
					new LioSearchableSelectField({
						'name'		: 'Report Type',
						'model'		: 'type',
						'type'		: 'select',
						'required'	: true,
						'locked'	: false,
						'visible' 	: true,
						'options'	: this.reportsService.availableReports,
					}),
					new LioSearchableSelectField({
						'name'		: 'Reports',
						'model'		: 'selectedReport',
						'type'		: 'select',
						'conditions': ['type'],
						'required'	: false,
						'locked'	: false,
						'visible' 	: true,
						'options'	: this.reportsService.filteredReports,
						searchable 	: true
					}),
				),
				'fieldConfig': {
					'fieldName'			: 'customreportselect',
				},
				'buttons'			: [
					{
						'id'					: 'download',
						'visible'				: true,
						'text'   				: 'Download Report',
						'textTrans'				: 'reports.download',
						'disabledConditions' 	: [],
						'visibleConditions' 	: ['canDownload'],
						'class'					: 'btn-primary',
						'callback'			: 'download',
					},
				],
			},
		},
		'edit' : {
			id 			: '',
			tab			: 'Create / Edit Report',
			tabTrans 	: 'editreport.title',
			link 		: 'reporteditor',
			visible 	: () =>  { 
				return this.permissionService.hasPermission('reports.editCustom');
			}
		},
		'create' : {
			id 			: '',
			tab			: 'Create Custom Report',
			tabTrans 	: 'customreports.title',
			link 		: 'reportcreator',
			visible 	: () =>  { 
				return this.permissionService.hasPermission('reports.writeCustom');
			}
		},
	};


	baseFilters:Array<any> = this.dashboardSettings.filters;

	customReportType:any = {
		'showFilters'				: true,
		'filters'					: [],
		'fields'					: [],
		'fieldConfig'				: null,
		'queryToolSettings'			: {
			'endpoints'	: {
				'get'		: 'query/getAllByType',
				'save'		: 'query/save',
				'delete'	: 'query/deleteByID',
				'report'	: 'customreports/runReport',
			},
			'addBaseFilters'		: true,
			'addEnrollmentFilters'	: true,
			'addCCFtoFilters'		: true,
			'addRoleFilter'			: true,
			'addActiveField'		: true,
			'showSaver'				: true,
			'usesQueryTool'			: true,
			'suppressCachedRules'	: true,
		},
	}

	reportTypes:Array<any> = [
		{
			'type' 						: 'dashboard',
			'showFilters'				: true,
			'filters'					: this.dashboardSettings.filters,
			'fields'					: this.dashboardSettings.fields,
			'fieldConfig'				: this.dashboardSettings.fieldConfig,
			'queryToolSettings'			: this.dashboardSettings.queryToolSettings,
		},
		{
			'type' 						: 'enrollment',
			'showFilters'				: true,
			'filters'					: this.enrollmentSettings.filters,
			'fields'					: this.assignSettings.fields,
			'fieldConfig'				: {
				'fieldName'			: 'assign',
				'addCCFtoFields'	: true,
				'localizeFields'	: true,
				'permissionFields'	: true,
				'addRoleToFields'	: true,
				'overrideFields'	: true,
				'addActiveField'	: true,
			},
			'queryToolSettings'			: this.enrollmentSettings.queryToolSettings,
		},
	];

	
}