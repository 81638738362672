import { Inject, Injectable } from '@angular/core';
import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';
import { coursesService } from '../../services/courses.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: userSummarySettings
})
export class userSummarySettings {
	constructor(
		@Inject(coursesService) 	private coursesService		:coursesService,
	){}

	fieldsSet:boolean = false;

	ccfFields:LioFieldArray = new LioFieldArray();

	loginHistoryPagination:any = {
		'pageLimit': '5',
		'pageLimits': ['5', '10', '50', '100'],
		'orderBy': 'loginDate'
	};

	searchConfig:any = {
		'fieldName'			: 'usersummary',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'ccfFields': {
		},
		'roleField': {
			'viewAuthority': 'CREATE',
			'lockedAuthority': 'VIEW'
		}
	};

	searches:any = [
		{
			'id'				: 'courseID',
			'name'				: 'Course ID',
			'model'				: 'courseID',
			'type'				: 'select',
			'options' 			: this.coursesService.courseOptionsRepSub,
			'strict'			: true,
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		}
	];

	courseHistoryPagination:any = {
		'pageLimit': '5',
		'pageLimits': ['5', '10', '50', '100'],
		'orderBy': 'courseID'
	};

	emailAuditHistoryPagination:any = {
		'pageLimit': '5',
		'pageLimits': ['5', '10', '50', '100'],
		'orderBy': 'sentDate'
	};

	enrollmentPagination:any = {
		'pageLimit': '5',
		'pageLimits': ['5', '10', '50', '100'],
		'orderBy': 'courseID'
	};

	enrollmentFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Course ID',
			'model': 'courseID',
			'type': 'text',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Name',
			'model': 'courseName',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Required',
			'model': 'required',
			'type': 'check',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Sign Up Date',
			'model': 'courseSignUpDate',
			'type': 'date',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Available Date',
			'model': 'courseAvailableDate',
			'type': 'date',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Completion Date',
			'model': 'courseCompletionDate',
			'type': 'date',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Due Date',
			'model': 'courseDueDate',
			'type': 'date',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Expiration Date',
			'model': 'courseExpires',
			'type': 'date',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Started',
			'model': 'courseStarted',
			'type': 'check',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Completed',
			'model': 'completed',
			'type': 'check',
			'class': 'center',
			'locked' : true,
			'visible' : true,
		}
	);

	courseHistoryFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Course ID',
			'model': 'courseID',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Course Started',
			'model': 'startCourse',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Course Ended',
			'model': 'endCourse',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Duration In Course',
			'model': 'duration',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'IP Address',
			'model': 'remote_addr',
			'type': 'text',
			'locked' : true,
			'visible' : false,
		},
	);

	emailAuditHistorySearches:any = [
		{
			'id'				: 'subject',
			'name'				: 'Subject',
			'model'				: 'subject',
			'type'				: 'text',
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		},
		{
			'id'				: 'admin',
			'name'				: 'Sent From',
			'model'				: 'admin',
			'type'				: 'text',
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		}
	];

	emailAuditHistoryFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Sent Date',
			'model': 'sentDate',
			'type': 'date',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Subject',
			'model': 'subject',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Message',
			'model': 'message',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Sent By',
			'model': 'admin',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
	);

	logihHistorySearches:any = [
		{
			'id'				: 'logindate',
			'name'				: 'Login Date',
			'model'				: 'loginDate',
			'type'				: 'text',
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		},
		{
			'id'				: 'pageaccesed',
			'name'				: 'Page Accessed',
			'model'				: 'page',
			'type'				: 'text',
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		}
	];

	loginHistoryFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Login Date',
			'model': 'loginDate',
			'type': 'timestamp',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Page Accessed',
			'model': 'page',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Time spent',
			'model': 'timeOnLastScreen',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Last Process',
			'model': 'lastProcess',
			'type': 'text',
			'locked' : true,
			'visible' : false,
		},
		{
			'name': 'Browser',
			'model': 'browser',
			'type': 'text',
			'locked' : true,
			'visible' : true,
		},
	);
	
	employeeFieldConfig:any = {
		'fieldName'			: 'loginFields',
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'addLangField'		: true,
		'activeField'		: {
			'locked' : true
		},
		'roleField' 		: {
			'locked' : true
		},
		'langField'			: {
			'locked': true
		}
	};

	loginFieldConfig:any = {
		'fieldName'			: 'loginFields',
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addLangField'		: false,
	};

	ccfFieldConfig:any = {
		'fieldName'			: 'ccfFields',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'ccfFields'	: {
			'locked' : true
		}
	};

	loginFields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Last Login',
			'model': 'lastLoginSuccess',
			'type': 'text',
			'required': false,
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'User Created By',
			'model': 'AdminID',
			'type': 'text',
			'required': false,
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'Last Updated',
			'model': 'updated',
			'type': 'text',
			'required': false,
			'locked' : true,
			'visible' : true,
		},
		{
			'name': 'User Created On',
			'model': 'dateCreated',
			'type': 'text',
			'required': false,
			'locked' : true,
			'visible' : true,
		},
		new LioSearchableSelectField({
			'name': 'Locked',
			'model': 'locked',
			'type': 'select',
			'required': false,
			'locked' : true,
			'visible' : true,
			'options': [
				{
					'value': '0',
					'name': 'No',
				},
				{
					'value': '1',
					'name': 'Yes',
				}
			]
		}),
		{
			'name': 'Last Time Password Changed',
			'model': 'dateModified',
			'type': 'text',
			'required': false,
			'locked' : true,
			'visible' : true,
		}
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Company',
			'model': 'coName',
			'type': 'text',
			'checkForHTMl': true,
			'locked' : true,
		},
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'required': true,
			'min': 2,
			'max': 70,
			
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'checkForHTMl': true,
			'locked' : true,
		},
		{
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 2,
			'max': 70,
			
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 2,
			'max': 70,
			
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'e-mail',
			'model': 'email',
			'type': 'email',
			'required': true,	
			'checkForHTMlInEmail': true,
			'checkForSpecialChars': true,
			'locked' : true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g
		},
		{
			'name': 'Address',
			'model': 'address1',
			'type': 'text',
			'required': false,
			'max': 70,
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Address 2',
			'model': 'address2',
			'type': 'text',
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'City',
			'model': 'city',
			'type': 'text',
			'varchar': true,
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'State',
			'model': 'state',
			'type': 'text',
			'varchar': true,
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Country',
			'model': 'country',
			'type': 'text',
			'min': 2,
			'max': 60,
			'varchar': false,
			'required': false,
			'checkForSpecialChars': false,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Zip Code',
			'model': 'zipCode',
			'type': 'text',
			'required': false,
			
			'checkForSpecialChars': true,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Phone #',
			'model': 'phone',
			'type': 'text',
			'required': false,
			'max' : 50,
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'locked' : true,
			'checkForHTMl': true
		},
		{
			'name': 'Date Added',
			'model': 'addDate',
			'type': 'text',
			'required': false,
			'locked' : true,
		},
	);
}