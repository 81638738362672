<div class="col-12 mx-auto" localize [model]="localeStrings" [items]="['fieldAddOption','fieldsAddOption']">
	<!-- PAGES -->
	<div class="row mb-1 no-gutters">		
		<lio-searchable-select
			class="col"
			[model]="targetPageModel"
			[field]="pageSelectField"
			(onUpdate)="getPage()"
			[appearance]="appearance">
		</lio-searchable-select>
		<div *ngIf="page && page.name">
			<button mat-raised-button color="primary" class="p-2 my-2 ml-2" *ngIf="!copiedPage" (click)="copyPage()">Copy Page</button>
			<button mat-raised-button color="primary" class="p-2 my-2 ml-2" *ngIf="copiedPage" (click)="pastePage()">Paste {{copiedPage.id}} - {{copiedPage.roleName}}</button>
			<button mat-raised-button color="primary" class="p-2 my-2 ml-2" title="Refresh Page" (click)="resetAndWait()"><i class="fa fa-sync-alt"></i></button>
		</div>
	</div>

	<!-- ROLES -->
	<div class="row mb-1">
		<lio-searchable-select
			class="col"
			[model]="targetRoleModel"
			[field]="roleSelectField"
			(onUpdate)="getRoleFields()"
			[disabled]="!targetPageModel.targetPage"
			[appearance]="appearance">
		</lio-searchable-select>
	</div>
</div>

<div *ngIf="page && page.fields" 
	class="text-center"
	lio-paginator 
	controlID="pageFields" 
	[collection]="page.fields" 
	[settings]="fieldsSettings.fieldsPagination"
	(onResults)="updateFilteredFields($event)">
	<h4 class="mx-auto my-2">Filters</h4>
	<table id="fields_table_header" class="table table-responsive-md" [style.fontSize.px]="11">
		<thead>
			<tr>
				<th>Reset</th>
				<th>Apply Globally</th>
				<th>Field Name</th>
				<th>Type</th>
				<th>Visible</th>
				<th>Locked</th>
				<th>Required</th>
				<th>Export</th>
				<th>Options</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let field of filteredFields; index as i" 
				[hidden]="field.hideFromFieldEditor"
				[ngClass]="{'table-primary': field.overridden,'table-secondary': field.hasChanges}"
				localize
				[model]="field"
				item="name">

				<td>
					<button [disabled]="!field.overridden" (click)="resetField(field)" class="btn button fa fa-undo"></button>
				</td>
				<td>
					<mat-slide-toggle color="primary" 
						[(ngModel)]="field.global" 
						[disabled]="field.disableOverride">
					</mat-slide-toggle>
				</td>
				<td><span>{{field.name}}</span></td>
				<td>
					<lio-searchable-select
						[model]="filteredFields[i]"
						[field]="typeField"
						(onUpdate)="onUpdateType(field)"
						[disabled]="field.disableOverride || field.disableOptionOverride"
						[appearance]="appearance">
					</lio-searchable-select>
				</td>
				<td>
					<mat-slide-toggle color="primary" 
						[(ngModel)]="field.visible"
						[disabled]="field.disableOverride">
					</mat-slide-toggle>
				</td>
				<td>
					<mat-slide-toggle
						color="primary" 
						[disabled]="true"
						[(ngModel)]="field.locked">
					</mat-slide-toggle>
				</td>
				<td>
					<mat-slide-toggle color="primary"
						[(ngModel)]="field.multiple" 
						[disabled]="field.required">
					</mat-slide-toggle>
				</td>

				<td>
					<mat-slide-toggle
						color="primary"
						[(ngModel)]="field.export" 
						[disabled]="field.disableOverride">
					</mat-slide-toggle>
				</td>

				<!-- Select Options -->
				<td class="container" *ngIf="field.dropDown && !field.disableOverride">
					<div class="row">
						<div class="col">
							<div>
								<mat-slide-toggle color="primary"
									[(ngModel)]="field.loadOptions" 
									(change)="field.customOptions = false">
									Pre-Existing Options
								</mat-slide-toggle>
							</div>
							<div>
								<mat-slide-toggle color="primary"
									[(ngModel)]="field.customOptions" 
									(change)="field.loadOptions = false">
									Custom Options
								</mat-slide-toggle>
							</div>
						</div>

						<!-- Collapser -->
						<button *ngIf="field.dropDown && field.customOptions" class="button-hide col-3 text-left">
							<span *ngIf="!field.expand" (click)="field.expand = true" class="fa fa-caret-right fa-lg button ml-1-2r fa-2x"></span>
							<span *ngIf="field.expand" (click)="field.expand = false" class="fa fa-caret-down fa-lg button ml-1-2r fa-2x"></span>
						</button>
					</div>

					<!-- Pre-Existing Options -->
					<div *ngIf="field.loadOptions">
						<lio-searchable-select
							[model]="filteredFields[i]"
							[field]="preExistingOptionsField"
							(onUpdate)="onupdate()"
							[disabled]="!targetPageModel.targetPage"
							[appearance]="appearance">
						</lio-searchable-select>
					</div>

					<!-- Custom Options -->
					<div *ngIf="field.customOptions" [hidden]="!field.expand">
						<div class="row no-gutters">
							<button mat-raised-button color="primary" class="col m-1" 
								(click)="loadExisting(field)">
								<span>Load Options</span>
							</button>
							<button mat-raised-button color="primary" class="col m-1" 
								(click)="copyOptions(field)" 
								*ngIf="!copiedOptions" 
								[disabled]="!field.options.length">
								<span>Copy Options</span>
							</button>
							<button mat-raised-button color="primary" class="col m-1" 
								(click)="pasteOptions(field)" 
								*ngIf="copiedOptions">
								<span>Paste Options</span>
							</button>
						</div>

						<lio-searchable-select
							[model]="filteredFields[i]"
							[field]="getAvailableOptionsField(field)"
							(onUpdate)="onupdate()"
							[disabled]="!targetPageModel.targetPage"
							[appearance]="appearance"
							[fullWidth]="false">
						</lio-searchable-select>

						<!-- Move Options -->
						<div class="row no-gutters">
							<button mat-raised-button color="primary" class="col m-1" (click)="addSelectedOptions(field)">
								<mat-icon>arrow_downward</mat-icon>
							</button>
							<button mat-raised-button color="primary" class="col m-1"  (click)="removeSelectedOptions(field)">
								<mat-icon>arrow_upward</mat-icon>
							</button>
						</div>

						<lio-searchable-select
							[model]="filteredFields[i]"
							[field]="getSelectedOptionsField(field)"
							(onUpdate)="onupdate()"
							[disabled]="!targetPageModel.targetPage"
							[appearance]="appearance"
							[fullWidth]="false">
						</lio-searchable-select>

						<!-- New Option -->
						<div *ngIf="field.newOption">
							<div class="row" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
								<mat-form-field class="col-12" [appearance]="appearance">
									<mat-label>Name</mat-label>
									<input id="am_form_newOptionName"
										matInput 
										[(ngModel)]="field.newOption.name"
										type="text" 
										placeholder="Add new option name (e.g. New York)"
										(change)="onupdate()" 
										maxlength="{{field.max || 100}}" />
								</mat-form-field>
								<mat-form-field class="col-12" [appearance]="appearance">
									<mat-label>Value</mat-label>
									<input id="am_form_newOptionValue"
										matInput 
										[(ngModel)]="field.newOption.value"
										type="text" 
										placeholder="Add new option value (e.g. ny)"
										(change)="onupdate()" 
										maxlength="{{field.max || 100}}" />
								</mat-form-field>
								<!-- Bulk Insert -->
							</div>
							<div class="row">
								<mat-form-field class="col-12" [appearance]="appearance">
									<mat-label>Bulk</mat-label>
									<textarea id="am_form_newOptionBulk" 
										matInput
										rows="6" 
										style="width:100%;" 
										placeholder="Bulk Load Options seperated by new lines. For value pairs, seperate the values by colons. For example:&#10;&#10;Michigan&#10;Alabama&#10;Kentucky&#10;New York:ny&#10;California:ca&#10;Florida:fl" 
										[(ngModel)]="field.newOption.bulk" 
										type="text"
										(change)="onupdate()"></textarea>
								</mat-form-field>
							</div>
							<div class="row">
								<button mat-raised-button 
									color="primary" 
									class="mx-auto"
									(click)="addOptions(field)" 
									type="submit">
									<strong>{{localeStrings.fieldsAddOption}}</strong>
								</button>
							</div>
						</div>
					</div>
				</td>

				<td *ngIf="!field.dropDown ||  field.disableOverride">
					<span>N/A</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<lio-paginator-controls controlID="pageFields"></lio-paginator-controls>

<div class="text-center" *ngIf="page && page.fields">
	<div class="col-12 mx-auto">
		<button id="am_fields_modify" 
			mat-raised-button
			color="primary"
			[disabled]="!page.name" 
			(click)="modifyPage()">Modify Fields</button>
		<button *ngIf="permissionService.hasPermission('fields.deleteAll')" 
			class="ml-1"
			id="am_fields_reset" 
			mat-raised-button
			color="primary"
			[disabled]="!page.name" 
			(click)="deletePage()">Reset Fields</button>
	</div>
</div>