/*
 * Service for controlling critical errors
*/
import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { lioModalService } from './lio-modal.service';
import { navService } from './nav.service';
import { permissionService } from './permissions.service';
import { debugService } from './debug.service';
import { utilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class errorsService {
	public criticalErrorSubject	:Subject<void> = new Subject();
	public timeout				:Subject<void> = new Subject();

	macros 				:Array<any> = [];
	task 				:any;
	errors 				:Array<any> = [];
	error				:any;
	errorID 			:any;
	criticalErrors		:Array<any> = [];
	errorOccurred 		:boolean 	= false;
	loggedOffError 		:boolean 	= false;
	sql					:string		= '';
	showSQL				:boolean	= false;
	showCriticalErrors 	:boolean	= true;
	errorCode 			:number 	= null;
	statusCode 			:number 	= null;
	lastTask 			:any 		= null;
	lastResponse		:any 		= null;
	errorsService		:any 		= null;
	lastProcess			:any 		= null;

	constructor(
		@Inject(lioLogService)		private lioLogService		:lioLogService,
		@Inject(lioModalService)	private lioModalService		:lioModalService,
		@Inject(navService)			private navService			:navService,
		@Inject(permissionService)	private permissionService	:permissionService,
		@Inject(utilService)		private utilService			:utilService,
		@Inject(debugService)		private debugService		:debugService,
	){
		this.debugService.register('errors', this);
	}

	throwCriticalError(response = null){
		if (response) {
			this.lastResponse = response;
			this.setError(response);
		} else {
			this.handleNoResponse();
		}

		this.criticalErrorSubject.next();
	}


	reset(task:string) {
		this.criticalErrors 	= [];
		this.sql 				= null;
		this.lastTask 			= task;
		this.statusCode 		= null;
		this.errorCode 			= 0;
	}

	setErrorCode(code) {
		this.errorCode = code;
	}

	setError(response) {
		let properties = response.properties ? response.properties : {},
			task = properties.task ? properties.task : this.lastTask,
			criticalErrors = response.criticalErrors && response.criticalErrors.length  ? response.criticalErrors : [response],
			errorID = properties.criticalErrorID;

		this.task = task;
		
		this.criticalErrors = criticalErrors;
		
		if (criticalErrors && criticalErrors[0] === 'SQL') {
			this.errorCode = 502;
		}

		if (this.criticalErrors && this.criticalErrors[0].error && this.criticalErrors[0].error.text) {
			this.criticalErrors = [this.criticalErrors[0].error.text];
		} else if (this.criticalErrors && this.criticalErrors[0].error && this.criticalErrors[0].error.criticalErrors) {
			this.criticalErrors = this.criticalErrors[0].error.criticalErrors;
		}

		this.errors = criticalErrors;
		if (errorID) {
			this.errorID = errorID;
		}
		this.lioModalService.hide();
		this.errorOccurred = true;

		if (!this.criticalErrors) {
			this.criticalErrors = [];
		}

		if (this.permissionService.hasPermission('error.debug') && this.showCriticalErrors) {
			this.lioLogService.log(['criticalErrors', criticalErrors]);
			this.lioModalService.hide();
			return;
		}

		if (this.utilService.inString('<title>Service Unavailable</title>', response)) {
			this.onTimeout();
		} else {
			this.on500Error();
		}
	}

	handleNoResponse() {
		this.macros.push({'key':'lastTask','value': this.lastTask});
		if (this.statusCode || this.statusCode === 0) {
			this.macros.push({'key':'status','value': this.statusCode});
			this.errors = this.utilService.localizeErrors('noResponseFromServerStatusCodeWas', this.macros);
		} else {
			this.errors = this.utilService.localizeErrors('noResponseFromServer', this.macros);
		}
		this.lioLogService.log(['this.errors', this.errors]);
		this.errorOccurred = true;
		this.navService.goto('500');
	}

	onTimeout() {
		this.errorOccurred = true;

		this.timeout.next();
	}

	on500Error() {
		this.errorOccurred = true;
		this.lioLogService.log('500');
		this.navService.goto('500');
	}

	getErrors() {
		return this.errors;
	}

	hasError() {
		return this.errorOccurred; 
	}

	getErrorID() {
		return this.errorID;
	}

	setErrorID(errorID) {
		this.errorID = errorID;
	}

	getUserInfo() {
		return this.error;
	}

	getTask() {
		return this.task || this.lastProcess;
	}

	getBrowser() {
		let browser = {};
		
		browser['App Code Name'] = navigator.appCodeName;
		browser['Application Name'] = navigator.appName;
		browser['Application Version'] = navigator.appVersion;
		browser['Cookies Enabled'] = navigator.cookieEnabled;
		browser['Platform'] = navigator.platform;
		browser['User Agent'] = navigator.userAgent;
		browser['System'] = navigator['oscpu'];
		return browser;
	}
}