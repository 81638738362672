import { Component, Input, Inject, ViewChild } from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { navService } from '../../../../services/nav.service';
import { fieldService } from './../../../../services/fields.service';
import { storageService } from './../../../../services/storage.service';
import { localizationService } from './../../../../services/localization.service';
import { permissionService } from './../../../../services/permissions.service';

@Component({
	selector: 'lio-context-menu',
	templateUrl: './context-menu.component.html'
})
export class LioContextMenu {
	@ViewChild(MatMenuTrigger)
	contextMenu: MatMenuTrigger;
	contextMenuPosition = { x: '0px', y: '0px' };
	suppressContext 		: boolean		= true;
	faEyeSlash 	= faEyeSlash as IconProp;
	faEye 		= faEye as IconProp;
	faEdit 		= faEdit as IconProp;
	
	@Input() field : any;

	constructor(
		@Inject(permissionService) 		private permissionService 	:permissionService,
		@Inject(storageService) 		private storageService 		:storageService,
		@Inject(fieldService) 			private fieldService 		:fieldService,
		@Inject(localizationService)	private localizationService :localizationService,
		@Inject(navService)				private navService 			:navService){}
	
	onContextMenu(event: MouseEvent) {
		if(this.showContext()){
			event.preventDefault();
			this.contextMenuPosition.x = event.clientX + 'px';
			this.contextMenuPosition.y = event.clientY + 'px';
			this.contextMenu.openMenu();
		}
	}

	canHide(){
		// Must not be required (allowHide overrides that requirement) 
		if (this.field && (!this.field.required || this.field.allowHide)) {
			// If already overridden, we cannot allow them to hide or it will cause a conflict
			if (!this.field.overridden) {
				return this.canReset();
			}
		}
	}

	canReset(){
		return this.permissionService.hasPermission('fields.saveUserSetting');
	}

	canEdit(){
		// Must not be required (allowHide overrides that requirement) 
		if (this.field){
			let trans = this.field.trans || 'form' + '.' + this.field.model;
			return trans && this.permissionService.hasPermission('localization.edit');
		}
	}

	hide(){
		this.fieldService.hideField(this.field);
	}

	reset(){
		this.fieldService.resetPage();
	}

	edit(){
		let trans = this.field.trans || 'form' + '.' + this.field.model;
		if (this.localizationService.handleContextMenu(trans)) {
			this.navService.goto('localization');
		}
	}

	showContext() {
		let params = this.storageService.getInitialParams() || this.storageService.getParams();
		if (this.suppressContext) {
			return false;
		}
		if (params && params.suppressContext) {
			return !params.suppressContext;
		} else {
			return true;
		}
	}
}