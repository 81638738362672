import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root'
})
export class processingService {
	processor				:Subject<any> = new Subject();
	processing				:boolean	= false;
	processingRequests		:Array<any> = [];
	downloading				:boolean	= false;
	allowCancel				:boolean	= false;
	setLangIDParam			:boolean	= false;
	resendOnInvalidResponse	:boolean	= false;

	private cancellingRequests:Array<any> = [];
	
	constructor(
		@Inject(debugService)			private debugService		:debugService) 
	{
		this.debugService.register('processingService', this);
	}

	/** sets the current list of processing into an array to be cancelled 
	 * this way if cancel is called via async, we wont get rid of any new processes
	 */
	setCancellingProcesses(){
		this.processingRequests.forEach((request) => {
			this.cancellingRequests.push(request);
		});
	}

	/**
	 * Cancels the list of processes that are marked for cancellation, removes them from the active requests
	 * if no processes remain, mark as no longer processing
	 */
	cancelProcesses() {
		this.cancellingRequests.forEach((request) => {
			request.subscriber.next();
			request.subscriber.complete();

			//remove the cancelled frequest from the processing list
			let requestIndex = this.processingRequests.indexOf(request);
			this.processingRequests.splice(requestIndex,1);
		});

		if(this.processingRequests.length == 0){
			this.processing = false;
			this.processor.next(false);
		}
	}

	init(request) {
		this.processingRequests.push(request);
		this.processing = true;
		this.processor.next(true);
	}

	removeRequest(request) {
		let total = this.processingRequests.length,
			item,
			i;

		for (i = 0; i < total; i++) {
			item = this.processingRequests[i];
			if (!item) {
				continue;
			}
			if ((item.urlPrepend + item.url) === request.url) {
				this.processingRequests.splice(i, 1);
			}
		}

		if (!this.processingRequests.length) {
			this.processing = false;
			this.processor.next(false);
		}
	}
}