import { Component, Inject } from '@angular/core';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { enrollmentEventService } from '../../services/enrollment-event.service';

import { scheduleEnrollmentEventHistorySettings } from '../../settings/schedule-enrollment-event-history.settings';
import { scheduleEnrollmentSettings } from '../schedule-enrollments/schedule-enrollment.settings';

@Component({
	selector: 'lio-enrollment-history',
	templateUrl: './enrollment-history.component.html'
})
export class EnrollmentHistoryComponent {
	public settings	:any = this.scheduleEnrollmentEventHistorySettings;
	public panels	:any = this.scheduleEnrollmentSettings.panels;

	constructor(
		@Inject(navService)								public	navService								:navService,
		@Inject(debugService)							public	debugService							:debugService,
		@Inject(enrollmentEventService)					public	enrollmentEventService					:enrollmentEventService,
		@Inject(scheduleEnrollmentEventHistorySettings)	public	scheduleEnrollmentEventHistorySettings	:scheduleEnrollmentEventHistorySettings,
		@Inject(scheduleEnrollmentSettings)				public	scheduleEnrollmentSettings				:scheduleEnrollmentSettings,
	){
		this.debugService.register('enrollmentHistory', this);
		this.navService.setActivePage('enrollmentHistory');
	}
}