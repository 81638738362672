<!-- order -->
<div id="am_settings_order" 
	class="row no-gutters" 
	[style.fontSize.px]="11" 
	*ngIf="model.settings" 
	[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">

	<mat-form-field class="col-3" appearance="fill">
		<mat-label id="am_form_label_order_by">Order By</mat-label>
		<!-- Order By -->
		<input id="am_form_fields_orderby" 
			matInput
			placeholder="Order By" 
			[(ngModel)]="model.settings.orderBy" 
			type="text" 
			(change)="onUpdate.emit()" 
			maxlength="100" 
			debounce="100">
	</mat-form-field>

	<mat-form-field class="col-3" appearance="fill">
		<mat-label>Direction</mat-label>
		<!-- Type -->
		<mat-select id="am_form_direction" [(ngModel)]="model.settings.orderDirection"> 
			<mat-option value="ASC">ASC</mat-option>
			<mat-option value="DESC">DESC</mat-option>
		</mat-select>
	</mat-form-field>

	<!-- limit -->
	<mat-form-field class="col-3" id="settings_limit" appearance="fill">
		<mat-label id="am_form_label_limit">Limit</mat-label>
		<input id="am_form_fields_limit" 
			matInput
			placeholder="Limit" 
			[(ngModel)]="model.settings.limit" 
			type="text"
			(change)="onUpdate.emit()" 
			maxlength="100" 
			debounce="100">
	</mat-form-field>

	<!-- Distinct -->
	<mat-form-field id="settings_distinct" class="col-3" appearance="fill">
		<mat-label>Direction</mat-label>
		<!-- Type -->
		<mat-select id="am_form_distinct" [(ngModel)]="model.settings.distinct"> 
			<mat-option value="">Not Distinct</mat-option>
			<mat-option value="1">Distinct</mat-option>
		</mat-select>
	</mat-form-field>
</div>
