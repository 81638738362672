<div class="mb-3">
	<span *ngFor="let day of field.weekDays"
		class="mr-3"
		[dir]="localizationService.isRTL() ? 'rtl' : 'ltr'"
		localize
		[model]="day"
		item="name">
		<!-- Hidden input because Angular Material gets pissy if it doesn't have a qualifying input -->
		<textarea matInput hidden></textarea>
		<mat-checkbox id="am_form_weekday_{{day.value}}_checkbox"
			color="primary"
			[(ngModel)]="model[day.value]"
			[ngClass]="{'field-error' : field.error == true}" 
			[disabled]="model[day.value] && !field.moreThanOneWeekDayEnabled(model)">
			{{day.name}}
		</mat-checkbox>
	</span>
</div>