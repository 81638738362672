import { Inject, Injectable } from '@angular/core';
import { LioFieldArray, LioSearchableSelectField } from '../modules/lio-forms/lio-forms.models';
import { LioButtonField } from '../modules/lio-forms/models/button-field.model';

import { enrollmentEventService } from './../services/enrollment-event.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEnrollmentEventHistorySettings
})
export class scheduleEnrollmentEventHistorySettings {
	constructor(
		@Inject(enrollmentEventService) private enrollmentEventService	:enrollmentEventService
	){}

	appearance:string = 'outline';
	
	endPoint:string = 'enrollmenteventhistory';

	pagination:any = {
		id				: 'scheduleenrollmenteventhistory',
		currentPage		: 1,
		offset			: 0,
		pageLimit		: '10',
		pageLimits		: ['10', '50', '100'],
		sortMode		: {field : 'schedule.startTime', direction : 'desc'}
	};

	fieldConfig:any = {
		'fieldName'			: 'schedulenrollmenteventhistory',
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
		'addLangField'		: false,
		'addActiveField'	: false,
	};


	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			model		: 'name',
			nameTrans 	: 'scheduleEnrollments.name',
			type		: 'text',
			visible		: true,
			sortable 	: true
		},
		{
			name		: 'Type',
			model		: 'type',
			nameTrans 	: 'scheduleEnrollments.type',
			type		: 'text',
			visible		: true,
			sortable 	: true
		},
		{
			name		: 'Date Sent',
			model		: 'endTime',
			nameTrans 	: 'scheduleEnrollments.dateSent',
			type		: 'text',
			visible		: true,
			sortable 	: true
		},
		{
			name		: 'Users Emailed',
			model		: 'totalProcessed',
			nameTrans 	: 'scheduleEnrollments.usersEmailed',
			type		: 'text',
			visible		: true,
			sortable 	: true
		},
		new LioButtonField({
			name			: 'Export Users',
			model			: 'totalProcessed',
			disabledModel 	: 'totalProcessed',
			nameTrans 		: 'scheduleEnrollments.exportUsers',
			type			: 'button',
			callback		: (event) => { this.enrollmentEventService.exportUsers(event); },
			visible			: true
		}),
		new LioButtonField({
			name			: 'View Event',
			model			: 'viewEvent',
			disabledModel 	: 'event',
			nameTrans 		: 'scheduleEnrollments.viewEvent',
			type			: 'button',
			callback		: (event) => { this.enrollmentEventService.showEvent(event, this.eventFields, this.eventPreviewFields); },
			visible			: true
		}),
	);

	eventFields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			model		: 'name',
			type		: 'text',
			required	: true,
			visible		: true
		},
		{
			name		: 'Description',
			model		: 'description',
			type		: 'textarea',
			required	: true,
			visible		: true
		},
		new LioSearchableSelectField({
			name		: 'Type',
			model		: 'type',
			type		: 'select',
			options 	: [
				{name : 'Course Overdue'	, value : 'Past Due'},
				{name : 'Course Registered'	, value : 'Registration'},
				{name : 'Reminder'			, value : 'Reminder'}],
			required	: true,
			visible		: true
		}),
		{
			name		: 'Start Date',
			model		: 'schedule.startTime',
			type		: 'text',
			visible		: true
		},
		{
			name		: 'Course',
			model		: 'courseID',
			type		: 'text',
			required	: true,
			visible		: true
		},
		{
			name		: 'Templates',
			model		: 'templates',
			type		: 'text',
			visible		: true
		},
		new LioSearchableSelectField({
			name		: 'Frequency Type',
			model		: 'schedule.recurranceType',
			type		: 'select',
			visible		: true,
			options		:  [
				{
					'value': 'once',
					'name': 'Once',
				},
				{
					'value': 'daily',
					'name': 'Daily',
				},
				{
					'value': 'weekly',
					'name': 'Weekly',
				},
				{
					'value': 'monthly',
					'name': 'Monthly',
				}
			]
		}),
		{
			name		: 'Runs On',
			model		: 'schedule.recurranceDay',
			type		: 'text',
			visible		: true
		},
		{
			name		: 'Frequency',
			model		: 'schedule.recurranceDetail',
			type		: 'text',
			visible		: true
		},
		{
			name		: 'Active',
			model		: 'active',
			type		: 'boolean',
			visible		: true
		}
	);

	eventPreviewFields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Field',
			nameTrans 	: 'enrollmentEvent.field',
			model		: 'name'
		}, 
		{
			name		: 'Value',
			nameTrans 	: 'enrollmentEvent.value',
			model		: 'value'
		}, 
	);

	prefixButtons:Array<any> = [];
	
	suffixButtons:Array<any> = [];

}