import { Inject, Injectable } from '@angular/core';
import { permissionService } from '../../services/permissions.service';
import { stateService } from '../../services/state.service';
import { coursesService } from '../../services/courses.service';

import { LioFieldArray, LioSearchableSelectField, LioImageField } from '../../modules/lio-forms/lio-forms.models';
import { PanelTabData } from '../../modules/structural/structural.models';

class UploaderTab extends PanelTabData{
	fields		:LioFieldArray
	fieldConfig?:any
	fileConfig?	:any
	buttons		:Array<any>

	constructor(obj:Partial<UploaderTab>){
		super(obj);
	}
}

@Injectable({
	providedIn	: 'root', 
	useClass	: courseUploaderSettings
})
export class courseUploaderSettings {
	constructor(
		@Inject(permissionService) 	private permissionService	:permissionService,
		@Inject(coursesService) 	private coursesService		:coursesService,
		@Inject(stateService) 		private stateService		:stateService
	){}

	uploadSettings:any = {
		name				: 'Upload File',
		trans				: 'addusers.upload',
		fileTask			: 'importcourse/upload',
		allowedFileTypes	: ['zip', 'jpg', 'png', 'jpeg', 'pdf', 'mp4', 'mpeg4', 'mpg4', 'ppt', 'pptx'],
		hideLoading			: false,
	};

	tabSettings:{
		upload				:UploaderTab,
		add					:UploaderTab,
		edit				:UploaderTab,
		addToCompany		:UploaderTab,
		removeFromCompany	:UploaderTab,
		access				:UploaderTab,
		download			:UploaderTab
	} = {
		upload : new UploaderTab({
			id				: 'upload',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.upload');
			},
			title			: 'Course Manager',
			titleTrans		: 'courseuploader.title',
			subtitle		: 'Upload a File or Click on the Add Course From URL button',
			subtitleTrans	: 'courseuploader.subtitle',
			description		: 'You can upload a Scorm Package or click on the "Add course from URL" button',
			descriptionTrans: 'courseuploader.uploadDescription',
			tab				: 'Upload',
			tabTrans		: 'courseuploader.eventFormTab',
			reloadOnTabChange : true,
			fileConfig		: {
				fileTask		: 'importcourse/upload',
				buttonClass		: '.js_browseButton',
				allowedFileTypes: ['zip', 'jpg', 'png', 'jpeg', 'pdf', 'mp4', 'mpeg4', 'mpg4', 'ppt', 'pptx'],
				allowedFileType	: 'zip',
				allowUpload		: true
			},
			fields 			: new LioFieldArray(
				{
					name			: 'Course ID',
					model			: 'courseID',
					type			: 'number',
					locked			: 'courses.editCourseID',
					visible			: true,
				},
				{
					name			: 'Link to URL',
					placeholder		: 'YouTube (or other 3rd party) URL',
					model			: 'courseURL',
					type			: 'text',
					visible			: true,
					conditions		: ['showURL'],
					required		: true,
				},
				{
					name			: 'Overwrite',
					model			: 'overwrite',
					type			: 'checkbox',
					locked			: false,
					visible			: 'courses.editCourseID',
					conditions		: ['requireOverwrite'],
				},
			),
			buttons	: [
				{
					id					: 'uploadbtn',
					visible				: true,
					type 				: 'upload',
					disabledConditions 	: ['courseID', 'validCourseID'],
					visibleConditions 	: [],
					debug				: false,
					disabled 			: false,
					settings 			: {
						name				: 'Upload File',
						trans				: 'courseuploader.uploadFile',
						fileTask			: 'importcourse/upload',
						allowedFileTypes	: ['zip', 'jpg', 'png', 'jpeg', 'pdf', 'mp4', 'mpeg4', 'mpg4', 'ppt', 'pptx'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'fileAdded'
				},
				{
					id					: 'addlink',
					visible				: true,
					text   				: 'Add Course From URL ',
					textTrans			: 'courseuploader.addLinkToCourse',
					disabledConditions 	: [],
					visibleConditions 	: [{'showURL': false}],
					class				: 'btn-lg btn-primary',
					callback			: 'showLink',
				},
				{
					id					: 'addCourse',
					visible				: true,
					text   				: 'Add Course',
					textTrans   			: 'courseuploader.addCourse',
					disabledConditions 	: [],
					visibleConditions 	: [{'showURL': true}],
					class				: 'btn-lg btn-primary',
					callback			: 'addCourseManually',
				},
				{
					id					: 'generate',
					text   				: 'Generate ID',
					textTrans   			: 'courseuploader.generateID',
					disabledConditions 	: [{'courseID': ''}],
					visible				: 'courses.editCourseID',
					callback			: 'generateID',
				}
			],
		}),
		add : new UploaderTab({
			id				: 'add',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.add');
			},
			title			: 'Add a course',
			titleTrans		: 'courseuploader.add',
			subtitle 		: 'Add a course that links to a YouTube video or other provider',
			subtitleTrans 	: 'courseuploader.addSubtitle',
			tab				: 'Add',
			tabTrans		: 'courseuploader.addTab',
			reloadOnTabChange : true,
			fieldConfig 	: {
				fieldName		: 'addcourse',
				permissionFields: true,
			},
			fields : new LioFieldArray(
				{
					name			: 'Course ID',
					model			: 'courseID',
					type			: 'number',
					visible			: true,
					locked			: 'courses.editCourseID',
				},
				{
					name			: 'Course Name',
					model			: 'courseName',
					nameTrans		: 'courseuploader.courseName',
					type			: 'text',
					visible			: true,
					required		: true,
				},
				new LioImageField({
					name			: 'Thumbnail',
					model			: 'thumbnail',
					type			: 'image',
					width			: '160px',
					height			: '120px',
					visible			: true,
					conditions  	: ['thumbnail'],
					class 			: 'float-left',
					required		: false,
				}),
				{
					name			: 'Course Description',
					model			: 'courseDescription',
					nameTrans		: 'courseuploader.courseDescription',
					max				: 2000,
					type			: 'textarea',
					visible			: true,
				},
				{
					name			: 'Course URL',
					model			: 'courseURL',
					nameTrans		: 'courseuploader.courseURL',
					type			: 'text',
					locked			: 'courses.admin',
					visible			: true,
					required		: true,
				},
				{
					name			: 'Course Parameters',
					model			: 'webLaunchParams',
					nameTrans		: 'courseuploader.webLaunchParams',
					type			: 'text',
					locked			: 'courses.admin',
					visible			: true,
					required		: false,
				},
				{
					name			: 'Course Length',
					model			: 'length',
					type			: 'text',
					nameTrans		: 'courseuploader.length',
					visible			: true,
					required		: true,
					max				: 50,
				},
				{
					name			: 'Mastery Score',
					model			: 'masteryScore',
					type			: 'text',
					nameTrans		: 'courseuploader.masteryScore',
					visible			: true,
					required		: true,
					max				: 50,
				},
				{
					name			: 'LSP Launch',
					model			: 'lspLaunch',
					type			: 'boolean',
					nameTrans		: 'courseuploader.lspLaunch',
					visible			: true,
					required		: false,
					locked			: 'courses.admin',
					max				: 50,
				},
				new LioSearchableSelectField({
					name 			: 'Company ID',
					model 			: 'companyID',
					nameTrans 		: 'courseuploader.companyID',
					type 			: 'select',
					searchable		: true,
					visible 		: 'courses.addToCompany',
					conditions 		: ['courseID'],
					required 		: false,
					locked			: 'courses.admin',
					options 		: this.stateService.allowedCompanies,
					optionValueField: 'companyID',
					nameFormatter 	: (_name, option) => { 
						return option.companyID + ': ' + option.coName; 
					},
				}),
				new LioSearchableSelectField({
					name			: 'Launch Method',
					model			: 'launchMethod',
					nameTrans		: 'courseuploader.launchMethod',
					type			: 'select',
					options 		: [
						{'name': 'iFrame', 'value': 'iframe'}, 
						{'name': 'Pop Out Window', 'value': 'popout'}, 
						{'name': 'Default', 'value': ''}
					],
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
					searchable 		: true
				}),
				new LioSearchableSelectField({
					name			: 'Completion Method',
					model			: 'completionMethod',
					nameTrans		: 'courseuploader.completionMethod',
					type			: 'select',
					options 		: [
						{'name': 'On Post Message', 'value': 'onpostmessage'}, 
						{'name': 'On Course Close', 'value': 'onclose'}, 
						{'name': 'Default', 'value': ''}
					],
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
					searchable 		: true
				}),
				{
					name			: 'Active',
					model			: 'active',
					nameTrans		: 'form.active',
					type			: 'boolean',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
				},
			),
			buttons			: [
				{
					id					: 'save',
					visible				: true,
					text   				: 'Save',
					textTrans			: 'courseuploader.saveCourse',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'saveCourse',
				}
			],
		}),
		access: new UploaderTab({
			id				: 'access',
			title			: 'Course Access',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.admin');
			},
			titleTrans		: 'courseuploader.accessTitle',
			subtitle		: 'Edit access to exisiting courses',
			subtitleTrans	: 'courseuploader.editSubtitle',
			tab				: 'Access',
			tabTrans		: 'courseuploader.eventFormTab',
			reloadOnTabChange : true,
			fieldConfig 	: {
				'fieldName'			: 'accesstcourse',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			fields : new LioFieldArray(
				new LioSearchableSelectField({
					name			: 'Course ID',
					model			: 'courseID',
					type			: 'select',
					debounce		: 1000,
					visible			: true,
					options 		: this.coursesService.courseOptionsRepSub,
					searchable 		: true
				}),
				{
					name			: 'Course Archived',
					model			: 'archived',
					nameTrans		: 'form.archived',
					type			: 'boolean',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
				},
				{
					name			: 'Course WhiteListed',
					model			: 'whitelist',
					nameTrans		: 'courseuploader.whitelist',
					type			: 'boolean',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
				},
			),
			buttons			: [
				{
					id					: 'save',
					visible				: true,
					text   				: 'Save',
					textTrans			: 'courseuploader.saveAccess',
					disabledConditions 	: ['courseID'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'saveAccess',
				}
			],
		}),
		download: new UploaderTab({
			id				: 'download',
			title			: 'Course Download',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.download');
			},
			titleTrans		: 'courseuploader.downloadTitle',
			subtitle		: 'Download courses',
			subtitleTrans	: 'courseuploader.editSubtitle',
			tab				: 'Download',
			tabTrans		: 'courseuploader.eventFormTab',
			reloadOnTabChange : true,
			fieldConfig 	: {
				'fieldName'			: 'downloadcourses',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			fields : new LioFieldArray(
				new LioSearchableSelectField({
					name			: 'Course ID',
					model			: 'courseIDs',
					type			: 'select',
					debounce		: 1000,
					visible			: true,
					multiple 		: true,
					options 		: this.coursesService.courseOptionsRepSub,
					searchable 		: true
				}),
			),
			buttons			: [
				{
					id					: 'uploadaws',
					visible				: true,
					text   				: 'Upload Selected To AWS',
					textTrans			: 'courseuploader.uploadToAWS',
					disabledConditions 	: ['courseIDs'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'uploadToAWS',
				},
				{
					id					: 'uploadaws',
					visible				: true,
					text   				: 'Upload All Courses To AWS',
					textTrans			: 'courseuploader.uploadAllToAWS',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'uploadAllToAWS',
				},
				{
					id					: 'download',
					visible				: true,
					text   				: 'Download',
					textTrans			: 'courseuploader.download',
					disabledConditions 	: ['courseIDs'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'download',
				},
				{
					id					: 'downloadAll',
					visible				: true,
					text   				: 'Download All',
					textTrans			: 'courseuploader.downloadAll',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'downloadAll',
				},
				{
					id					: 'getCourseList',
					visible				: true,
					text   				: 'Get Course List',
					textTrans			: 'courseuploader.getCourseList',
					disabledConditions 	: [],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'getCourseList',
				}
			],
		}),
		edit : new UploaderTab({
			id				: 'edit',
			title			: 'Course Editor',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.edit');
			},
			titleTrans		: 'courseuploader.edittitle',
			subtitle		: 'Edit an exisiting course',
			subtitleTrans	: 'courseuploader.editSubtitle',
			tab				: 'Edit',
			tabTrans		: 'courseuploader.eventFormTab',
			reloadOnTabChange : true,
			fileConfig		: {
				'fileTask': 'importcourse/upload',
				'buttonClass': '.js_browseButton2',
				'allowedFileTypes': ['zip', 'jpg', 'png', 'jpeg', 'pdf', 'mp4', 'mpeg4', 'mpg4', 'ppt', 'pptx'],
				'allowedFileType': 'zip',
				'allowUpload': true,
			},
			fieldConfig 	: {
				'fieldName'			: 'editcourse',
				'permissionFields'	: true,
				'overrideFields'	: true,
			},
			fields : new LioFieldArray(
				new LioSearchableSelectField({
					name			: 'Course ID',
					model			: 'courseID',
					type			: 'select',
					searchable		: true,
					debounce		: 1000,
					visible			: true,
					options 		: this.coursesService.courseOptionsRepSub,
				}),
				{
					name			: 'Course Name',
					model			: 'courseName',
					nameTrans		: 'courseuploader.courseName',
					type			: 'text',
					visible			: true,
					conditions  	: ['courseID'],
					required		: true,
				},
				{
					name			: 'Course Description',
					model			: 'courseDescription',
					nameTrans		: 'courseuploader.courseDescription',
					max				: 2000,
					type			: 'textarea',
					visible			: true,
					conditions  	: ['courseID'],
					required		: false,
				},
				{
					name			: 'Course Length',
					model			: 'length',
					type			: 'text',
					nameTrans		: 'courseuploader.length',
					visible			: true,
					conditions 		: ['courseID'],
					required		: true,
					max				: 50,
				},
				{
					name			: 'Mastery Score',
					model			: 'masteryScore',
					type			: 'text',
					nameTrans		: 'courseuploader.masteryScore',
					visible			: true,
					conditions  	: ['courseID'],
					required		: true,
					max				: 50,
				},
				{
					name			: 'LSP Launch',
					model			: 'lspLaunch',
					type			: 'boolean',
					nameTrans		: 'courseuploader.lspLaunch',
					visible			: true,
					required		: false,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					max				: 50,
				},
				{
					name			: 'Course URL',
					model			: 'courseURL',
					nameTrans		: 'courseuploader.courseURL',
					type			: 'text',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: true,
				},
				{
					name			: 'Course Parameters',
					model			: 'webLaunchParams',
					nameTrans		: 'courseuploader.webLaunchParams',
					type			: 'text',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
				},
				new LioSearchableSelectField({
					name			: 'Company ID',
					model			: 'companyID',
					nameTrans		: 'courseuploader.companyID',
					type			: 'select',
					searchable		: true,
					visible			: 'courses.addToCompany',
					conditions  	: ['courseID'],
					required		: false,
					locked			: 'courses.admin',
					optionValueField: 'companyID',
					options 		: this.stateService.allowedCompanies,
					nameFormatter 	: (_name, option) => { 
						return option.companyID + ': ' + option.coName; 
					},
				}),
				new LioSearchableSelectField({
					name			: 'Launch Method',
					model			: 'launchMethod',
					nameTrans		: 'courseuploader.launchMethod',
					type			: 'select',
					options 		: [
						{'name': 'iFrame', 'value': 'iframe'}, 
						{'name': 'Pop Out Window', 'value': 'popout'}, 
						{'name': 'Default', 'value': ''}
					],
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
					searchable 		: true
				}),
				new LioSearchableSelectField({
					name			: 'Completion Method',
					model			: 'completionMethod',
					nameTrans		: 'courseuploader.completionMethod',
					type			: 'select',
					options 		: [
						{'name': 'On Post Message', 'value': 'onpostmessage'}, 
						{'name': 'On Course Close', 'value': 'onclose'}, 
						{'name': 'Default', 'value': ''}
					],
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
					searchable 		: true
				}),
				{
					name			: 'Active',
					model			: 'active',
					nameTrans		: 'form.active',
					type			: 'boolean',
					visible			: true,
					locked			: 'courses.admin',
					conditions  	: ['courseID'],
					required		: false,
				},
			),
			buttons			: [
				{
					id					: 'edit',
					visible				: true,
					text   				: 'Edit Course',
					textTrans			: 'courseuploader.editCourse',
					disabledConditions 	: [],
					visibleConditions 	: ['courseID'],
					class				: 'btn-primary',
					callback			: 'editCourse',
				},
				{
					id					: 'uploadbtn',
					type 				: 'upload',
					visible				: true,
					disabledConditions 	: [],
					visibleConditions 	: ['courseID'],
					disabled 			: false,
					settings 			: {
						name   				: 'Replace Course With New File',
						trans   			: 'courseuploader.uploadNewFile',
						fileTask			: 'importcourse/upload',
						allowedFileTypes	: ['zip', 'jpg', 'png', 'jpeg', 'pdf', 'mp4', 'mpeg4', 'mpg4', 'ppt', 'pptx'],
						class 				: 'btn btn-sm m-t-n-xs mr-1 btn-primary'
					},
					onUploadCmd			: 'reuploadCourse'
				},
				{
					id					: 'preview',
					visible				: true,
					text   				: 'Preview Course',
					textTrans			: 'courseuploader.previewCourse',
					disabledConditions 	: ['courseURL'],
					visibleConditions 	: ['courseID'],
					class				: 'btn-primary',
					callback			: 'previewCourse',
				},
			],
		}),
		addToCompany : new UploaderTab({
			id				: 'addToCompany',
			title			: 'Add To Company',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.addToCompany');
			},
			titleTrans		: 'courseuploader.addToCompany',
			subtitle		: 'Adds a course to a company',
			subtitleTrans	: 'courseuploader.addToCompanySubtitle',
			tab				: 'Add To Company',
			tabTrans		: 'courseuploader.addToCompanyTab',
			reloadOnTabChange : true,
			fieldConfig 	: {
				fieldName		: 'addtocompany',
				permissionFields: true,
			},
			fields : [
				new LioSearchableSelectField({
					name 			: 'Course ID',
					placeholder		: 'Search By Course ID or Name',
					nameTrans 		: 'courseuploader.courseID',
					model			: 'courseIDs',
					type 			: 'select',
					searchable 		: true,
					multiple 		: true,
					debounce 		: 200,
					visible 		: true,
					options 		: this.coursesService.coursesOutsideCompany,
				}),
				new LioSearchableSelectField({
					name 			: 'Company ID',
					placeholder		: 'Search By Company ID or Name',
					model 			: 'companyID',
					nameTrans 		: 'courseuploader.companyID',
					type 			: 'select',
					searchable		: true,
					visible 		: 'courses.addToCompany',
					conditions  	: [],
					debounce 		: 1000,
					required		: false,
					optionValueField: 'companyID',
					options 		: this.stateService.allowedCompanies,
					nameFormatter 	: (_name, option) => { 
						return option.companyID + ': ' + option.coName; 
					}
				}),
			],
			buttons			: [
				{
					id					: 'addToCompany',
					visible				: true,
					text   				: 'Add To Company',
					textTrans			: 'courseuploader.add',
					disabledConditions 	: ['courseIDs'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'addToCompany',
				},
			],
		}),
		removeFromCompany : new UploaderTab({
			id				: 'removeFromCompany',
			title			: 'Remove a course from a Company',
			visible 		: () => {
				return this.permissionService.hasPermission('courses.removeFromCompany');
			},
			titleTrans		: 'courseuploader.removeFromCompany',
			subtitle		: 'Removes a course from a company',
			subtitleTrans	: 'courseuploader.removeFromCompanySubtitle',
			tab				: 'Remove From Company',
			tabTrans		: 'courseuploader.removeFromCompanyTab',
			reloadOnTabChange : true,
			fieldConfig 	: {
				fieldName		: 'removefromcompany',
				permissionFields: true,
			},
			fields : [
				new LioSearchableSelectField({
					name 			: 'Course ID',
					model			: 'courseID',
					type 			: 'select',
					searchable 		: true,
					debounce 		: 200,
					visible 		: true,
					options 		: this.coursesService.courseOptionsRepSub,
				}),
				new LioSearchableSelectField({
					name 			: 'Company ID',
					model 			: 'companyID',
					nameTrans 		: 'courseuploader.companyID',
					type 			: 'select',
					searchable 		: true,
					visible 		: 'courses.addToCompany',
					conditions  	: [],
					debounce 		: 1000,
					required		: false,				
					optionValueField: 'companyID',
					options 		: this.stateService.allowedCompanies,
					nameFormatter 	: (_name, option) => { 
						return option.companyID + ': ' + option.coName; 
					}
				}),
			],
			buttons : [
				{
					id					: 'removeFromCompany',
					visible				: true,
					text   				: 'Remove From Company',
					textTrans			: 'courseuploader.removeFromCompany',
					disabledConditions 	: ['courseID'],
					visibleConditions 	: [],
					class				: 'btn-primary',
					callback			: 'removeFromCompany',
				},
			],
		}),
	};
}