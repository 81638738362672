import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';

import { localizationService } from '../../../../services/localization.service';
import { permissionService } from '../../../../services/permissions.service';
import { storageService } from '../../../../services/storage.service';
import { navService } from '../../../../services/nav.service';
import { utilService } from '../../../../services/util.service';

@Component({
  selector: 'lio-catalog-expanded',
  templateUrl: './catalog-expanded.component.html'
})
export class LIOCatalogExpanded {
	@Input() indexInList		:number;
	@Input() catalog			:any 				= null;
	@Input() view				:any 				= null;
	@Input() fieldsObject		:any 				= null;
	@Input() filters			:any 				= null;

	@Output() clickedCatalog	:EventEmitter<any> 	= new EventEmitter();
	@Output() selectModule		:EventEmitter<any> 	= new EventEmitter();
	@Output() previewModule		:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedPrint		:EventEmitter<any> 	= new EventEmitter();
	@Output() previewCourse		:EventEmitter<any> 	= new EventEmitter();
	@Output() selfEnroll		:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnScript	:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnInfoSheet:EventEmitter<any> 	= new EventEmitter();
	@Output() clickedOnRequest	:EventEmitter<any> 	= new EventEmitter();

	public localeStringItems = [
		'courseTypeHeader',
		'courseLength',
		'courseDescription',
		'format',
		'cascadeCourse',
		'courseURL',
		'projectedRelease',
		'modules',
		'moduleDescription',
		'enroll',
		'enrolled',
		'requestCourseMaterials',
		'download',
		'courseScript',
		'infoText',
		'requestImplementation',
		'latestUpdates',
		'adminNotes',
		'availableLanguages',
		'topics',
	];

	public localeStrings = {
		courseTypeHeader 			: 'COURSE TYPE: ',
		courseTypeHeaderTrans 		: 'coursecatalog.courseTypeHeader',
		courseLength 				: 'COURSE LENGTH:',
		courseLengthTrans 			: 'coursecatalog.courseLength',
		courseDescription 			: 'Course Description:',
		courseDescriptionTrans 		: 'coursecatalog.courseDescription',
		format 						: 'Format:',
		formatTrans 				: 'coursecatalog.format',
		cascadeCourse 				: 'Cascade Course:',
		cascadeCourseTrans 			: 'coursecatalog.cascadeCourse',
		courseURL					: 'DOWNLOAD LINK(s):',
		courseURLTrans 				: 'coursecatalog.courseURL',
		projectedRelease 			: 'PROJECTED RELEASE',
		projectedReleaseTrans 		: 'coursecatalog.projectedRelease',
		modules 					: 'Modules:',
		modulesTrans 				: 'coursecatalog.modules',
		moduleDescription 			: 'Click on any of the following modules to create a custom course containing the selected modules. Select them in the order you wish them to appear in the course. Select a single module and click the play button to preview that selected module.',
		moduleDescriptionTrans 		: 'coursecatalog.moduleDescription',
		enroll 						: 'Enroll',
		enrollTrans 				: 'coursecatalog.enroll',
		enrolled 					: 'Enrolled in Course',
		enrolledTrans 				: 'coursecatalog.enrolled',
		requestCourseMaterials 		: 'Request Course Materials',
		requestCourseMaterialsTrans : 'coursecatalog.requestCourseMaterials',
		download 					: 'Download',
		downloadTrans 				: 'coursecatalog.download',
		courseScript				: 'Course Script',
		courseScriptTrans			: 'coursecatalog.courseScript',
		infoText					: 'Course Overview',
		infoTextTrans				: 'coursecatalog.infoText',
		requestImplementation		: 'Request Implementation',
		requestImplementationTrans	: 'coursecatalog.requestImplementation',
		latestUpdates 				: 'Latest Updates:',
		latestUpdatesTrans 			: 'coursecatalog.latestUpdates',
		adminNotes 					: 'Admin Notes:',
		adminNotesTrans 			: 'coursecatalog.adminNotes',
		availableLanguages 			: 'Available Languages:',
		availableLanguagesTrans 	: 'coursecatalog.availableLanguages',
		topics 						: 'Topics:',
		topicsTrans 				: 'coursecatalog.topics',
		keywords 							: 'Featured Content:',
		keywordsTrans 				: 'coursecatalog.keywords',
	};
	
	constructor(
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(navService)			 			public navService	:navService,
		@Inject(storageService) 			public storageService		:storageService,
		@Inject(utilService) 					public utilService			:utilService
	){}

	/*
	 * Is the field visible
	 @param {string} fieldName
	 @return {boolean}
	*/
	isFieldVisible(fieldName) {
		if (!this.fieldsObject) {
			return true;
		}
		return this.fieldsObject[fieldName].visible;
	}

	/*
	 * User clicked on catalog admin button
	 * @param {number} courseID
	*/
	gotoAdmin(courseID) {
		this.storageService.set('catalogID', courseID);
		this.navService.goto('catalogadmin');
	}

	/**
	 * Checks whether the user should be able to request a script for a particular catalog
	 * @param catalog - the catalog we are checking
	 */
	canRequestScript(catalog){
		//!! is just forcing a boolean conversion
		let requestable = !!catalog.scriptLocation;

		if(catalog.modules){
			catalog.modules.forEach((module) => {
				if(module.selected && module.scriptLocation){
					requestable = true;
					return;
				}
			});
		}

		return requestable;
	}
}