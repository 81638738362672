<div class="row">
	<div class="col-12">
		<lio-result-filters
			[searchConfig]="searchConfig"
			[search]="search"
			[searchFields]="searches">
		</lio-result-filters>
	</div>
</div>
<div class="row mt-2">
	<div class="col-12">
		<div lio-paginator 
			controlID="emailAuditHistory" 
			[collection]="emailAuditHistory" 
			[filters]="search"
			[settings]="emailAuditHistoryPagination" 
			(onResults)="updateEmailAuditHistory($event)">
			<lio-results-table
				paginatorID="emailAuditHistory"
				[fields]="emailAuditHistoryFields"
				[results]="filteredEmailAuditHistory">
			</lio-results-table>
		</div>
		<lio-paginator-controls controlID="emailAuditHistory"></lio-paginator-controls>
	</div>
</div>