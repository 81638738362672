/*
 * Service for Controlling pop up windows and frames
*/
import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { debugService } from './debug.service';
import { utilService } from './util.service';

import { querySettings } from '../settings/query.settings';

@Injectable({
	providedIn: 'root',
})
export class permissionService{
	debug						: boolean;
	debugSpecificPermissions	: Array<any>;
	allRoles 					: Array<any>;
	allowedRoles 				: Array<any>;
	permissionSet 				: any;
	isImpersonating 			: boolean;
	switchedCompany				: boolean;
	hasBypassRoleFilter			: boolean;
	authorities 				: Array<any>;
	allRolesMap 				: any;
	roleAuthorities 			: Array<any>;

	constructor(
		@Inject(lioLogService)	private lioLogService	: lioLogService,
		@Inject(debugService)	private debugService	: debugService,
		@Inject(utilService)	private utilService		: utilService,
		@Inject(querySettings)	private querySettings	: querySettings
	){
		this.debugService.register('permissions', this);

		//-----------------------------------------------------------------------------------------
		//DEBUG SETTINGS
		//logs all checks against any permission
		this.debug 						= false;
		//logs checks against the permissions listed in the array, format is *section.permissionName*
		this.debugSpecificPermissions 	= [];
		//-----------------------------------------------------------------------------------------

		this.allRoles 					= [];
		this.allowedRoles 				= [];
		this.roleAuthorities 			= [];
		this.permissionSet 				= {};

		this.isImpersonating 			= false;
		this.switchedCompany 			= false;
		this.hasBypassRoleFilter		= false;

		this.authorities 				= [
			{'name': 'NONE', 'value': 'NONE'},
			{'name': 'VIEW', 'value': 'VIEW'},
			{'name': 'EDIT', 'value': 'EDIT'}, 
			{'name': 'CREATE', 'value': 'CREATE'}, 
			{'name': 'ENROLL', 'value': 'ENROLL'}, 
		];
	}

	/**
	 * Sets the values for allowed permissions, roles, and companies
	 */
	setPermissions(permissions, allowedRoles, roleAuthorities = []){
		//all keys to lower case
		this.permissionSet = {};
		let permKeys = Object.keys(permissions);
		permKeys.forEach((key) => {
			this.permissionSet[key.toLowerCase()] = permissions[key];
		});

		this.allowedRoles 		= allowedRoles;
		this.roleAuthorities 	= roleAuthorities;

		//if user has the bypassRoleFilter permission, all role authorities are granted
		if (this.hasPermission('filters.bypassRoleFilter')) {
			this.roleAuthorities = [];
			this.allRoles.forEach((role) => {
				let newAuthority = {roleID : role.roleID, authorityLevels : null};

				newAuthority.authorityLevels = this.utilService.copy(this.authorities);
				this.roleAuthorities.push(newAuthority);
			});
		}
	}

	setAllRoles(allRoles){
		this.allRoles 		= allRoles;
		this.allRolesMap 	= {};

		allRoles.forEach((role) => {
			this.allRolesMap[role.roleID] = role;
		});
	}

	setAllowedRoles(allowedRoles){
		this.allowedRoles = allowedRoles;
	}

	/**
	 * Gets the active permission set
	 */
	getPermissions(){
		return this.permissionSet;
	}

	/**
	 * Gets the roles which the current user has the passed authority for
	 * @param {array} authorityLevels - an array of strings that name which authority levels the current user must have in order for an authority to be returned
	 * 		@note if passed value is a string it will be nested in an array
	 * @return {object} - A name and roleID for the role
	 */
	getRoleAuthorities(authorityLevels){
		let result = [];

		if (!Array.isArray(authorityLevels)) {
			authorityLevels = [authorityLevels];
		}

		this.roleAuthorities.forEach((authority) => {
			let authorities 	= [];
			let hasAuthority 	= true;

			authority.authorityLevels.forEach((authority) =>{
				authorities.push(authority.value);
			});

			authorityLevels.forEach((level) => {
				if (authorities.indexOf(level.toUpperCase()) === -1) {
					hasAuthority = false;
				}
			});

			if (hasAuthority) {
				let authEntry = {name : null, roleID : null};
				authEntry.name 		= this.getRoleNameFromID(authority.roleID, null);
				authEntry.roleID 	= authority.roleID;
				result.push(authEntry);
			}
		});

		return result;
	}

	/**
	 * Checks if the user has at least one of the passed permissions
	 */
	hasEitherPermission(permissionStrings){
		if (!Array.isArray(permissionStrings)){
			if (typeof permissionStrings == 'string'){
				permissionStrings = [permissionStrings];
			}else{
				this.lioLogService.log('Permission service failed to parse parameters.');
			}
		}

		let foundPassing = false;
		permissionStrings.forEach((permissionString) => {
			if (this.hasPermission(permissionString)){
				foundPassing = true;
			}
		});
		return foundPassing;
	}

	/**
	 * Checks if the user has ALL of the passed permissions
	 */
	hasAllPermissionsIn(permissionStrings){
		if (!Array.isArray(permissionStrings)){
			if (typeof permissionStrings == 'string'){
				permissionStrings = [permissionStrings];
			}else{
				this.lioLogService.log('Permission service failed to parse parameters.');
			}
		}

		let foundFailing = false;
		permissionStrings.forEach((permissionString) => {
			if (!this.hasPermission(permissionString)){
				foundFailing = true;
			}
		});
		return !foundFailing;
	}

	/**
	 * Checks whether the current user has a specific permission
	 * @param {string} permissionString
	 */
	hasPermission(permissionString, debug = false) {
		if (typeof permissionString !== 'string') {
			this.lioLogService.error(['Permission is not a string, Result: DENIED', permissionString]);
			return false;
		}

		// all permission names are converted to lower case
		permissionString = permissionString.toLowerCase();


		if (permissionString === 'bypass') {
			return true;
		}

		if (debug) {
			this.lioLogService.log(permissionString);
		}

		if (!this.permissionSet || Object.keys(this.permissionSet).length == 0) {
			if (this.debug || this.debugSpecificPermissions.indexOf(permissionString) > -1){
				this.lioLogService.log('Permission Checked: ' + permissionString + ' Result: DENIED, NO PERMISSIONS LOADED');
			}
			return false;
		}

		if (!this.utilService.isDefined(this.permissionSet[permissionString])) {
			if (this.debug) {
				this.lioLogService.log('Permission:' + permissionString + ' Result: DENIED, A permission with that name does not exist, or did not exist when this role was last modified.');
			}
			return false;
		}

		if (JSON.parse(this.permissionSet[permissionString]['allowed'])){
				
			// Check if we are currently switching the companyID and if so, check if we deny on a company switch
			// ie certain actions 
			if (this.switchedCompany && this.permissionSet[permissionString]['denyonswitchedcompany']){
				if (this.debug || this.debugSpecificPermissions.indexOf(permissionString) > -1){
					this.lioLogService.log('Permission Checked: ' + permissionString + ' Result: DENIED, Permission cannot be used while switiching companies');
				}
				return false;
			}

			// Check impersonating permissions
			if (!this.isImpersonating || this.permissionSet[permissionString]['impersonatable']){
				if (this.debug || this.debugSpecificPermissions.indexOf(permissionString) > -1){
					this.lioLogService.log('Permission Checked: ' + permissionString + ' Result: APPROVED');
				}
				return true;
			}else{
				if (this.debug || this.debugSpecificPermissions.indexOf(permissionString) > -1){
					this.lioLogService.log('Permission Checked: ' + permissionString + ' Result: DENIED, Permission cannot be used while impersonating');
				}
				return false;
			}
		}
		if (this.debug || this.debugSpecificPermissions.indexOf(permissionString) > -1){
			this.lioLogService.log('Permission Checked: ' + permissionString + ' Result: DENIED');
		}
		return false;
	}

	/**
	 * Gets the list of roles the current user is allowed to use
	 */
	getAllowedRoles() {
		return this.utilService.copy(this.allowedRoles);
	}

	/**
	 * Gets the list of pages the current user is allowed to use
	 */
	getAllowedPages() {
		return [];
	}

	/**
	 * Gets the name of a role from its ID
	 * @param {number} roleID
	 * @param {?object} map
	 */
	getRoleNameFromID(roleID, map = null) {
		if (!map) {
			map = this.allRolesMap;
		}

		if (map && map[roleID]) {
			return map[roleID].name;
		}
		return '';
	}

	/**
	 * Gets the localized status
	 * @param {boolean} (Status comes in as 0 for active and 1 for inactive) 
	 * @return {boolean} 
	 */
	getLocalizedStatusOfUser(status) {
		if (status == 0) {
			return 'form.activeStatus';
		} 
		return 'form.inactiveStatus';
	}

	/**
	 * Gets the model for the 'role' field to be used in forms
	 */
	getRoleField(configuration) {
		var roles 			= this.utilService.copy(this.allRoles),
			config 			= configuration && configuration['roleField'] ? configuration['roleField'] : {},
			required 		= this.utilService.isDefined(config.required) ? config.required : true,
			viewAuthority 	= this.utilService.isDefined(config.viewAuthority) ? config.viewAuthority : 'VIEW',
			locked 			= this.utilService.isDefined(config.locked) ? config.locked : undefined,
			lockedAuthority = this.utilService.isDefined(config.lockedAuthority) ? config.lockedAuthority : 'EDIT',
			visible			= this.utilService.isDefined(config.visible) ? config.visible : true,
			exportable		= this.utilService.isDefined(config.export) ? config.export : true;

		roles.forEach((role) => {
			role.value 		= role.roleID;
			role.locked 	= !this.hasRoleAuthority(role.roleID, lockedAuthority);
			role.visible 	= this.hasRoleAuthority(role.roleID, viewAuthority);

			if (!role.roleID) {
				role.visible = false;
			}
		});

		roles.sort((a,b) => {
			return parseInt(a.roleID) - parseInt(b.roleID);
		});

		return {
			'name'					: 'Role',
			'model'					: 'permissionID',
			'nameTrans'				: 'form.role',
			'type'					: 'select',
			'visible'				: visible,
			'export'				: exportable,
			'options'				: roles,
			'mappingFunction'		: this.getRoleNameFromID,
			'mapData'				: this.allRolesMap,
			'blockEditHigherRole' 	: true,
			'required'				: required,
			'locked'				: locked,
			'allowHide'				: true,
			'disableOptionOverride' : true,
			'sortable' 				: true,
		};
	}

	/**
	 * Gets the model for the 'role' filter to be used in the query builder
	 */
	getRoleFilter(){
		let roles 	= this.utilService.copy(this.allRoles),
			options = [],
			option  = {};

		roles.sort((a,b) => {
			return parseInt(a.roleID) - parseInt(b.roleID);
		});

		roles.forEach((role) => {
			if (role.roleID != 0 && this.hasRoleAuthority(role.roleID, 'VIEW')) {
				option = {
					'name'		: role['name'],
					'value'		: role['roleID'],
				};
				options.push(option);	
			}
		});

		return {
			'label': 'Role',
			'name': 'Role',
			'alias': 'e',
			'model': 'permissionID',
			'field': 'e.permissionID',
			'type': 'select',
			'options': options,
			'multiple': true,
			'operators': [
				this.querySettings.operatorOptions.in, 
				this.querySettings.operatorOptions.not_in
			],
			'disableOptionOverride' : true,		
			'visible': true,		
		};
	}

	/**
	 * Gets the model for the 'active' field to be used in forms
	 */
	getActiveField(configuration) {
		let config 		= configuration && configuration['activeField'] ? configuration['activeField'] : {},
			exportable 	= this.utilService.isDefined(config['export']) ? config['export'] : undefined,
			visible 	= this.utilService.isDefined(config['visible']) ? config['visible'] : undefined,
			locked 		= this.utilService.isDefined(config['locked']) ? config['locked'] : undefined;

		return {
			'name'					: 'Status',
			'alias'					: 'e',
			'model'					: 'inactive',
			'nameTrans'				: 'form.status',
			'type'					: 'select',
			'mappingTransFunction'	: this.getLocalizedStatusOfUser,
			'blockEditHigherRole' 	: true,
			'disableOptionOverride' : true,
			'locked' 				: locked,
			'visible'				: visible,
			'export'				: exportable,
			'sortable' 				: true,
			'options'				: [
				{
					'value': '0',
					'name': 'Active',
					'trans': 'form.activeStatus',
					'visible': true
				},
				{
					'value': '1',
					'name': 'Inactive',
					'trans': 'form.inactiveStatus',
					'visible': true
				}
			]
		};
	}

	/*
	 * Determines if any item in an array of permissions is allowed
	 * @param {array} permissions
	 * @param {object} config
	 * @return {boolean}
	*/
	setFields(fields = [], config = null) {
		if (!config) { 
			config = {};
		}
		fields.forEach((field) => {
			if (!this.utilService.isDefined(field.visible)) {
				field.visible = true;
			} else if (typeof field.visible === 'string') {
				field.visible = this.hasPermission(field.visible);
			}

			if (this.utilService.isDefined(field.locked) && field.locked !== true && field.locked !== false) {
				if (typeof field.locked === 'string') {
					field.locked = !this.hasPermission(field.locked);
				}
			}

			if (!this.hasRoleAuthority(config.permissionID, 'EDIT')) {
				if (field.blockEditHigherRole) {
					field.locked = true;
				}
			}


		}, this);
		return fields;
	}

	/**
	 * Checks the level of authority the active role has over another role
	 */
	hasRoleAuthority(roleID, level) {
		let result 	= false;
		level 		= level.toUpperCase();

		if (this.hasBypassRoleFilter || this.hasPermission('filters.bypassRoleFilter')){
			// Optomize performance to avoid this permission check on each iteration
			this.hasBypassRoleFilter = true;
			return true;
		}

		this.roleAuthorities.forEach((authority) => {
			if (authority.roleID == roleID) {
				authority.authorityLevels.forEach((authorityLevel) => {
					if (level.toUpperCase() === authorityLevel.toUpperCase()) {
						result = true;
						return;
					}
				});
			}
		});

		return result;
	}
}