import { Component, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { coursesService } from '../../../../services/courses.service';
import { workerService } from '../../../../services/worker.service';
import { localizationService } from '../../../../services/localization.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { filesService } from '../../../../services/files.service';
import { fieldService } from '../../../../services/fields.service';
import { storageService } from '../../../../services/storage.service';
import { catalogAdminSettings } from '../../../../components/catalog-admin/catalog-admin.settings';


@Component({
  selector: 'lio-catalog-admin-bulk',
  templateUrl: './catalog-admin-bulk.component.html'
})
export class CatalogAdminBulkComponent implements OnDestroy {
	@Output() onUpdated			:EventEmitter<any> = new EventEmitter();

	public 	appearance:MatFormFieldAppearance = 'outline';
	public	uploadTypeField:any					= {};
	public	process:any									= null;
	public	filePath:any									= null;
	public	readyForProcess:boolean			= false;
	public	file :any										= {};
	public	importing:boolean						= false;
	public	uploadSettings:any					= {};
	public	localeStrings:any 						= {};
	public	localeStringsKeys:any 				= [];

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)		public 	feedbackService		:feedbackService,
		@Inject(errorsService)			public 	errorsService		:errorsService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(coursesService)			public 	coursesService		:coursesService,
		@Inject(lioLogService)			public 	lioLogService		:lioLogService,
		@Inject(stateService)			public 	stateService		:stateService,
		@Inject(formValidatorService)	public 	formValidatorService:formValidatorService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(workerService)			public 	workerService		:workerService,
		@Inject(permissionService)		public 	permissionService	:permissionService,
		@Inject(localizationService)	public 	localizationService	:localizationService,
		@Inject(lioModalService)		public 	lioModalService		:lioModalService,
		@Inject(processingService) 		public 	processingService	:processingService,
		@Inject(utilService) 			public 	utilService			:utilService,
		@Inject(fieldService) 			public 	fieldService		:fieldService,
		@Inject(storageService)			public 	storageService		:storageService,
		@Inject(catalogAdminSettings) 		public 	catalogAdminSettings		:catalogAdminSettings
	){
		this.debugService.register('catalogadminbulk', this);

		this.uploadTypeField = this.utilService.copy(this.catalogAdminSettings.uploadTypeField);
		this.uploadSettings  = this.utilService.copy(this.catalogAdminSettings.uploadSettings);
		this.process 			= {type : this.catalogAdminSettings.processType};
		this.process.types 	= this.catalogAdminSettings.processTypes;

		this.readyForProcess = false;
		this.file = null;
		this.processingService.allowCancel = true;
		this.importing = false;

		this.subscriptions = NEVER.subscribe();
		this.localeStrings = {
			selectAll				:'catalogadmin.searchCatalogs',
			active					:'form.activeStatus',
			inactive				:'form.inactiveStatus',
			name						:'form.name',
			courseID				:'form.courseID',
			status					:'form.status',
			action					:'form.action',
			copy						:'form.copy',
			edit						:'form.edit',
			save						:'catalogadmin.save',
			saveTopic				:'catalogadmin.saveTopic',
			deleteTopic			:'catalogadmin.deleteTopic',
			dragExcel				:'addusers.dragExcel',
			getTemplate			:'addusers.getTemplate',
			getSample				:'addusers.getSample',
			exportAll				:'catalogadmin.exportAll',
			makeSelection		:'catalogadmin.makeSelection',
			process					:'catalogadmin.process',
	}
		this.localeStringsKeys = Object.keys(this.localeStrings);
	}


	/*
	 * Handles not allows to upload
	*/
	handleNotAllowed() {
		this.feedbackService.setError('pleaseSelectAProcessType');
	}


	/* 
	 * On File Added
	*/
	fileAdded(event) {
		this.filePath = event.filePath;
		this.preprocess();
	}

	/*
	 * File preprocess / validation
	*/
	preprocess() {
		let filePath = this.filePath,
			langs = this.localizationService.getLangs();

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();


		this.lmsService.postAsync('importcatalog/preprocess', {'processType': this.process.type, 'filePath': filePath, 'langs': langs},
		'uploading').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handlePreprocessResults(result);
				});
			} else {
				this.handlePreprocessResults(result);
			}
		});	
	}

	/*
	 * Handles pre-process results
	*/
	handlePreprocessResults(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}

		let properties = result.properties,
			totalAdded = properties.totalAdded,
			totalEdited = properties.totalEdited,
			totalFailed = properties.totalFailed,
			cancelled = properties.cancelled,
			totalDetected = properties.totalDetected,
			file = properties.file,
			errors = properties.errors,
			isValidFile = true,
			messages = [],
			macros = [];

		if (cancelled) {
			return;
		}

		if (!totalDetected) {
			totalDetected = 0;
		}
		
		macros.push({'key': 'total', 'value': totalDetected});
		messages.push(this.utilService.localizeMessage('totalDetected', macros));
		
		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalRecordsWillBeInserted', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'totalEdited', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('totalRecordsWillBeUpdated', macros));
		}

		if (!totalEdited) {
			messages.push(this.utilService.localizeMessage('zeroRecordsWillBeUpdated'));
		}
		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalIssuesWithTheFile', macros));
			isValidFile = false;
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		this.readyForProcess = isValidFile;
		this.file = file;
		if (isValidFile) {
			this.navService.changedForm = true;
		}
	}

	/*
	 * Process the file
	*/
	processFile() {
		this.lioModalService.showLoading('processing');
		let	langs = this.localizationService.getAvailableLanguages();

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lmsService.postAsync('importcatalog/process', {'filePath': this.filePath, 'processType': this.process.type, 'langs': langs, 'lang': this.storageService.getLangID()},
		'processing').then((result) => {
			let token = result.properties.token;
			this.importing = true;

			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleProcessResponse(gresult);
				});
			} else {
				this.handleProcessResponse(result);
			}
			
		});
	}

	/*
	 * Handler for process response
	*/
	handleProcessResponse(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}
		let properties = result.properties,
			totalAdded = properties.totalAdded,
			totalEdited = properties.totalEdited,
			totalFailed = properties.totalFailed,
			messages = [],
			macros = [];

		this.lioModalService.hideLoading();
		this.importing = false;
		this.readyForProcess = false;

		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalRecordsSuccessfullyInserted', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'totalEdited', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('totalRecordsSuccessfullyUpdated', macros));
		}
		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalNumberOfUnexpectedIssues', macros));
		}

		this.feedbackService.setMessages(messages);
		this.navService.changedForm = false;
		// Update the catalogs now that they have been updated
		this.onUpdated.emit(true);
	}

	/*
	 * Gets the importing template
	*/
	getTemplate() {
		if (this.processingService.processing) {
			return;
		}
		this.lmsService.post('catalog/getTemplate', {'processType': this.process.type}).then((result) => {
			let success = result.success,
				file = result.properties.file;

			if (success) {
				this.filesService.getFile(file);
			}
			
		});
	}

	/*
	 * Gets the importing sample
	*/
	getSample() {
		if (this.processingService.processing) {
			return;
		}
		this.lmsService.post('catalog/getSample', {'processType': this.process.type}).then((result) => {
			let success = result.success,
				file = result.properties.file;

			if (success) {
				this.filesService.getFile(file);
			}
			
		});
	}

	/*
	 * Exports all records for this type
	*/
	exportAll() {
		this.lioModalService.show('loadingRecords');
		if (this.processingService.processing) {
			return;
		}
		this.lmsService.post('catalog/exportAll', {'processType': this.process.type}).then((result) => {
			let success = result.success,
				file = result.properties.file;

			if (success) {
				this.filesService.getFile(file);
			}

			this.lioModalService.hide();

		});
	}


	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}