import { Component, Input, Optional, Inject, OnInit, OnDestroy, Host, ContentChild } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { lioLogService } from '../../../../services/lio-log.service';
import { navService } from '../../../../services/nav.service';

import { PanelTabData } from './panel-tab-data';
import { PanelTabCache } from './panel-tab-cache.service';
import { LioPanelTabContent } from './panel-tab-content.directive';

@Component({
	selector: 'lio-panel-tab',
	templateUrl: './panel-tab.component.html'
})
export class LioPanelTab implements OnInit, OnDestroy {	
	@ContentChild(LioPanelTabContent)
	public content? :LioPanelTabContent;
	private subscriptions	:Subscription	= NEVER.subscribe();

	public active			:boolean 		= true;
	@Input() public settings:PanelTabData = null;
	
	constructor(	
		@Optional() @Inject(lioLogService) 	private lioLogService	:lioLogService,
		@Host() 							public tabCache			:PanelTabCache,
		@Inject(navService) 				private navService		:navService
	){
		this.subscriptions.add(
			this.navService.exiting.subscribe(() => {
				this.active = false;
			})
		)
	}

	ngOnInit() {
		if (this.settings) {
			if(typeof this.settings._visible == 'undefined'){
				this.settings._visible = true;
			}
			this.tabCache.addTab(this.settings);
		}else{
			this.lioLogService.log('Missing Settings for paneltabber');
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}



}