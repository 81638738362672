import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from './../../imports/angular-material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { LioPipesModule } from './../../pipes/pipes.module';
import { LioDirectivesModule } from './../../directives/directives.module';
import { LioFormsModule } from '../lio-forms/lio-forms.module';

import { ModalComponent } from './components/base/modals.component';
export { ModalComponent } from './components/base/modals.component';

import { DefaultModalComponent } from './components/default/default.component';
export { DefaultModalComponent } from './components/default/default.component';

import { ChooseRoleComponent } from './components/choose-role/choose-role.component';
export { ChooseRoleComponent } from './components/choose-role/choose-role.component';

import { ChooseCompanyComponent } from './components/choose-company/choose-company.component';
export { ChooseCompanyComponent } from './components/choose-company/choose-company.component';

import { ChooseLanguageComponent } from './components/choose-language/choose-language.component';
export { ChooseLanguageComponent } from './components/choose-language/choose-language.component';

import { LoadingComponent } from './components/loading/loading.component';
export { LoadingComponent } from './components/loading/loading.component';

import { CatalogComponent } from './components/catalog/catalog.component';
export { CatalogComponent } from './components/catalog/catalog.component';

import { ConfirmComponent } from './components/confirm/confirm.component';
export { ConfirmComponent } from './components/confirm/confirm.component';

import { InputComponent } from './components/input/input.component';
export { InputComponent } from './components/input/input.component';

import { SendEmailComponent } from './components/send-email/send-email.component';
export { SendEmailComponent } from './components/send-email/send-email.component';

import { FieldMapComponent } from './components/field-map/field-map.component';
export { FieldMapComponent } from './components/field-map/field-map.component';

import { TableComponent } from './components/table/table.component';
export { TableComponent } from './components/table/table.component';

import { EmailComponent } from './components/email/email.component';
export { EmailComponent } from './components/email/email.component';

import { IFrameComponent } from './components/iframe/iframe.component';
export { IFrameComponent } from './components/iframe/iframe.component';

import { ImpersonateComponent } from './components/impersonate/impersonate.component';
export { ImpersonateComponent } from './components/impersonate/impersonate.component';

@NgModule({
	declarations: [
		ModalComponent,
		DefaultModalComponent,
		ChooseRoleComponent,
		ChooseCompanyComponent,
		ChooseLanguageComponent,
		LoadingComponent,
		CatalogComponent,
		ConfirmComponent,
		InputComponent,
		SendEmailComponent,
		FieldMapComponent,
		TableComponent,
		EmailComponent,
		IFrameComponent,
		ImpersonateComponent
	],
	exports: [
		ModalComponent,
		DefaultModalComponent,
		ChooseRoleComponent,
		ChooseCompanyComponent,
		ChooseLanguageComponent,
		LoadingComponent,
		CatalogComponent,
		ConfirmComponent,
		InputComponent,
		SendEmailComponent,
		FieldMapComponent,
		TableComponent,
		EmailComponent,
		IFrameComponent,
		ImpersonateComponent
	],
	imports: [
		AngularMaterialModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMatSelectSearchModule,
		MatFormFieldModule, 
		MatSelectModule,
		LioPipesModule,
		LioDirectivesModule,
		LioFormsModule
	]
})
export class LioModalsModule {}