<ng-container *ngIf="content && settings && active">
	<div *ngIf="settings.reloadOnTabChange ? tabCache.getSelectedTab() == settings: true"
		[id]="settings.id"
		[ngClass]="{'card' : !settings.panelClass, '{{settings.panelClass}}' : settings.panelClass}" 
		[hidden]="tabCache.getSelectedTab() != settings"
		localize
		[model]="settings"
		[items]="['title', 'subtitle', 'description']">

		<div *ngIf="settings.title || settings.subtitle" 
			class="card-header text-center hc-dark-text">
			<div [id]="settings.id + '_title'" 
				[ngClass]="{'list-group-item-heading':settings.subtitle}" 
				[class]="'h4 ' + settings.titleClass">{{settings.title}}</div>
			<div [id]="settings.id + '_subtitle'" 
				[class]="'h6 ' + settings.subTitleClass">{{settings.subtitle}}</div>
			<div [id]="settings.id + '_description'" 
				*ngIf="settings.description" 
				[class]="settings.descriptionClass">{{settings.description}}</div>
		</div>
		<div [id]="settings.id + '_body'" 
			[ngClass]="{'card-body' : !settings.bodyClass, '{{settings.bodyClass}}' : settings.bodyClass}">

			<ng-container *ngTemplateOutlet="content.templateRef"></ng-container>
		</div>
		<div class="card-footer" *ngIf="settings.pagination">
			<div class="container-fluid">
				<lio-paginator-controls class="row" [controlID]="settings.pagination.id"></lio-paginator-controls>
			</div>
		</div>
	</div>
</ng-container>