<lio-card bodyClass="container-fluid px-3"
	localize
	[model]="localeStrings"
	[items]="localeStringsKeys">
	<div lio-card-header>
		<div *ngIf="!global" class="h4">{{localeStrings.headerTitle}}</div>
		<div *ngIf="global" class="h4">{{localeStrings.globalHeaderTitle}}</div>
		<hr/>
		<lio-result-filters
			(update)="onupdate()"
			[search]="search"
			[searchFields]="searches">
		</lio-result-filters>
	</div>
	<div lio-card-body>
		<div class="row overflow-auto" lio-paginator
			controlID="localizationItems"
			[collection]="localizationItems"
			[filters]="search"
			[settings]="pagination"
			(onResults)="updateFilteredLocalizationItems($event)">

			<lio-results-table class="text-center"
				paginatorID="localizationItems"
				headerPadding="p-0"
				[fields]="fields"
				[results]="filteredLocalizationItems"
				[prefixButtons]="prefixButtons">
			</lio-results-table>
		</div>
	</div>
	<div lio-card-footer>
		<lio-paginator-controls class="row" controlID="localizationItems"></lio-paginator-controls>
		<div class="btn-toolbar mt-2">
			<!-- Buttons -->
			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				*ngIf="!global && permissionService.hasPermission('localization.global')"
				(click)="getGlobalLocalizationItems()">
				<span>{{localeStrings.getGlobalLocalizations}}</span>
			</button>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				*ngIf="global"
				(click)="getCompanyLocalizationItems()">
				<span>{{localeStrings.getCompanyLocalizations}}</span>
			</button>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				(click)="exportAllFields()">
				<span>{{localeStrings.exportAllFields}}</span>
				<mat-icon>file_download</mat-icon>
			</button>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				(click)="exportEmptyRecords()">
				<span>{{localeStrings.exportMissingTranslations}}</span>
				<mat-icon>file_download</mat-icon>
			</button>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				(click)="exportRecords()">
				<span>{{localeStrings.exportTranslations}}</span>
				<mat-icon>file_download</mat-icon>
			</button>

			<lio-file-uploader [settings]="uploadSettings"
				[matchBootstrap]="false"
				[disabled]="disableUpload"
				(success)="fileAdded($event)">
			</lio-file-uploader>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				[hidden]="!readyToProcess"
				(click)="process()">

				<span>{{localeStrings.processImport}}</span>
			</button>

			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				*ngIf="permissionService.hasPermission('localization.edit')"
				(click)="deleteLocalizations()" type="submit">

				<span>{{localeStrings.deleteTranslations}}</span>
			</button>
			<button mat-flat-button
				color="primary"
				class="mr-1 mb-1"
				*ngIf="permissionService.hasPermission('localization.global')"
				(click)="emptyLocalizations()" type="submit">

				<span>{{localeStrings.deleteAllCompanysTranslations}}</span>
			</button>
		</div>
	</div>
</lio-card>