import { Pipe } from '@angular/core';

@Pipe({
  name: "highlight"
})
export class HighlightPipe {
	transform(input:string = '', search:string = ''): string {
		if(!input || !search){
			return input;
		}
		return input.replace(new RegExp(search, 'gi'), '<span class="highlightedText">$&</span>');
	}
}