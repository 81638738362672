<form class="container-fluid" 
	name="form" 
	role="form" 
	autocomplete="off" 
	novalidate 
	(submit)="submit()" 
	localize 
	[model]="localeStrings" 
	[items]="localeStringsKeys"
	[macros]="localeStringsMacros"
	[formGroup]="formGroup">

	<div class="form-inline row">
		<div class="col-12 px-1 mb-1" [ngClass]="{'col-md-7' : settings.sortable}">
			<div class="input-group w-100">
				<lio-searchable-select [model]="_model"
					[formGroup]="formGroup"
					[field]="_model.field"
					[amID]="amID"
					[appearance]="appearance"
					[optionAsValue]="true"
					(onUpdate)="modelLoaded();"
					[searchable]="true"
					class="w-100">
				</lio-searchable-select>
			</div>
		</div>
		<div class="col-12 col-md-5 px-1 mb-1" *ngIf="settings.sortable">
			<div class="row no-gutters w-100">
				<lio-searchable-select [model]="modelSort"
					[formGroup]="formGroup"
					class="col-10"
					[field]="modelSort.field"
					[appearance]="appearance"
					(onUpdate)="sortModelOptions()">
				</lio-searchable-select>

				<span id="am_modelEditor_modelSortDir_label" class="p-1 col-2">
					<button matButton class="btn btn-primary p-3" type="button" (click)="switchModelSortDir()">
				        <span class="sr-only">Toggle Order</span>
						<i class="fa fa-caret-up" [hidden]="modelSort.reverse"></i>
						<i class="fa fa-caret-down" [hidden]="!modelSort.reverse"></i>
					</button>
				</span>
			</div>
		</div>
	</div>

	<hr class="row mt-0"/>

	<div class="row">
		<div class="col-md-12">
			<lio-form-inputs
				[parentGroup]="formGroup"
				[fields]="settings.fields"
				[fieldConfig]="settings.fieldConfig"
				[model]="model"
				(onChanged)="updatedField($event)"
				[formdata]="formData"
				[appearance]="settings.appearance || 'fill'">
			</lio-form-inputs>
		</div>
	</div>

	<hr class="row"/>

	<ng-content></ng-content>

	<hr class="row"/>

	<div class="btn-toolbar">
		<div class="btn-group mb-1" [hidden]="model.id">
			<button id="am_modelEditor_button_create" (click)="submitMode='create'" class="btn btn-sm btn-primary" type="submit">
				<strong>{{localeStrings.createModel}}</strong>
			</button>
		</div>
		<div class="btn-group mb-1 ml-1" [hidden]="!model.id">
			<button id="am_modelEditor_button_save" (click)="submitMode='save'" class="btn btn-sm btn-primary" type="submit">
				<strong>{{localeStrings.saveModel}}</strong>
			</button>
		</div>
		<div class="btn-group mb-1 ml-1" [hidden]="!model.id || !settings.copyable">
			<button id="am_modelEditor_button_copy" (click)="submitMode='copy'" class="btn btn-sm btn-success" type="submit">
				<strong>{{localeStrings.copyModel}}</strong>
			</button>
		</div>
		<div class="btn-group mb-1 ml-1" [hidden]="!model.id">
			<button id="am_modelEditor_button_delete" (click)="submitMode='delete'" class="btn btn-sm btn-danger" type="submit">
				<strong>{{localeStrings.deleteModel}}</strong>
			</button>
		</div>
		<div class="btn-group mb-1 ml-1" *ngFor="let button of settings.additionalButtons">
			<button [id]="button.id" 
				type="button" 
				class="btn btn-sm btn-primary" 
				(click)="button.action(model, setModel)"
				localize [model]="button"
				item="name">
				
				<strong>{{button.name}}</strong>
			</button>
		</div>

		<!-- Custom Buttons -->
		<div ng-if="settings.buttons" class="btn-group mb-1 ml-1" >
			<lio-buttons (onClick)="onClick.emit($event)" [buttons]="settings.buttons" [model]="model"></lio-buttons>				
		</div>
	</div>
</form>