<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<lio-panel-tabber class="mx-auto col-12 col-md-10"(onTabChange)="onTabChange()">
		<!--  -->
		<lio-panel-tab [settings]="exclusionPanelConfig">
			<ng-container *lioPanelTabContent>
				<lio-search-employees
					[fields]="searchFields"
					[fieldConfig]="searchFieldConfig"
					[settings]="settings"
					(onResults)="recieveResults($event)">
				</lio-search-employees>

				<div class="mx-n1 mt-2">
					<div lio-paginator 
						class="mx-n3" 
						[controlID]="exclusionsPagination.id" 
						[collection]="employees" 
						[settings]="exclusionsPagination" 
						(onResults)="updateFilteredEmployees($event)">

						<lio-results-table
							[paginatorID]="exclusionsPagination.id"
							(fieldClick)="onFieldClick($event)"
							[fields]="fields"
							[fieldConfig]="fieldConfig"
							[results]="filteredEmployees">
						</lio-results-table>
					</div>
				</div>

				<div class="btn-toolbar my-2">
					<!-- Buttons -->
					<button id="am_exclusions_add" 
						mat-flat-button
						color="primary" 
						type="button" 
						[disabled]="!hasExclusions" 
						(click)="addExclusions()">
						
						<strong>{{localeStrings.addExclusions}}</strong>
					</button>
				</div>
			</ng-container>
		</lio-panel-tab>

		<lio-panel-tab [settings]="existingExclusionPanelConfig">
			<ng-container *lioPanelTabContent>
				<div lio-paginator 
					[controlID]="existingExclusionsPagination.id" 
					[collection]="existingExclusions" 
					[settings]="existingExclusionsPagination" 
					(onResults)="updateFilteredExclusions($event)">

					<lio-results-table
						[paginatorID]="existingExclusionsPagination.id"
						(fieldClick)="onFieldClick($event)"
						[fields]="fields"
						[fieldConfig]="fieldConfig"
						[results]="filteredExclusions">
					</lio-results-table>
				</div>

				<div class="btn-toolbar my-2">
				<!-- Buttons -->
					<button 
						mat-flat-button
						color="primary"
						class="mr-1 mb-1" 
						type="button" 
						id="am_exclusions_add" 
						[disabled]="!hasExclusions" 
						(click)="addExclusions()">

						<strong>{{localeStrings.modifyExclusions}}</strong>
					</button>
				</div>
			</ng-container>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>