import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { permissionService } from '../../services/permissions.service';
import { accessLogSettings } from './access-log.settings';

@Component({
  selector: 'lio-access-log',
  templateUrl: './accesslog.component.html'
})
export class AccessLogComponent implements OnInit {
	public fields				:Array<any>		= [];
	public fieldConfig			:any			= {};
	public searchFields			:Array<any>		= [];
	public searchFieldConfig	:any			= {};
	public pagination			:any			= null;
	public filteredEmployees	:Array<any>		= [];
	public employees			:Array<any>		= [];
	public allowSubmit			:boolean		= false;
	public searchers			:any			= null;
	public serverName			:any			= null;

	private subscriptions		:Subscription	= NEVER.subscribe();
	
	constructor(
		@Inject(navService)			public 	navService			:navService,
		@Inject(debugService)		public 	debugService		:debugService,
		@Inject(feedbackService)	public 	feedbackService		:feedbackService,
		@Inject(errorsService)		public 	errorsService		:errorsService,
		@Inject(lmsService)			public 	lmsService			:lmsService,
		@Inject(lioLogService)		public 	lioLogService		:lioLogService,
		@Inject(stateService)		public 	stateService		:stateService,
		@Inject(workerService)		public 	workerService		:workerService,
		@Inject(permissionService)	public 	permissionService	:permissionService,
		@Inject(lioModalService)	public 	lioModalService		:lioModalService,
		@Inject(accessLogSettings) 	public 	accessLogSettings	:accessLogSettings
	){
		this.debugService.register('accesslog', this);
		this.navService.setActivePage('accesslog');
			
		this.fields				= this.accessLogSettings.fields;
		this.fieldConfig		= this.accessLogSettings.fieldConfig;
		this.searchFields		= this.accessLogSettings.searchFields;
		this.searchFieldConfig 	= this.accessLogSettings.searchFieldConfig;
		this.pagination			= this.accessLogSettings.pagination;
		this.filteredEmployees 	= [];
		this.employees			= [];
		this.allowSubmit		= false;
		this.searchers			= {};

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Init
	*/
	init() {
		this.getMostRecentLogs();
		this.getServerName().then(() => {
			this.navService.displayPage();
		});
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/*
	 * Exports the employee records
	*/
	exportRecords() {
		this.lioModalService.showLoading('global.exportingrecords');
		this.workerService.export(this.employees, this.fields, 'Access Logs').then((result) => {
			this.lioModalService.hideLoading();
			if (!result) {
				this.lioModalService.show('Failed to export Access Logs');
			}
		});
	}

	/*
	 * Searches for a log
	*/
	submit() {
		this.lioModalService.showLoading('loadingRecords');
		this.feedbackService.clearAll();
		this.employees = [];
		this.lmsService.post('accesslog/search', {'searchers': this.searchers}).then((result) => {
			this.lioModalService.hideLoading();
			let errors = result.errors,
				accessLogs = result.properties.accessLogs;
				if (accessLogs) {
					this.employees = accessLogs;
				} else if (errors.length) {
					this.employees = [];
					this.feedbackService.setErrors(errors);
				} else {
					this.employees = [];
					this.feedbackService.setError('noLogsFound');
			}
        });
	}

	/*
	 * Gets the server name
	*/
	getServerName() {
		return this.lmsService.get('accesslog/getServerName').then((result) => {
			let serverName = result.properties.serverName;
			this.serverName = serverName;
		});
	}

	/*
	 * Gets the most recent logs
	*/
	getMostRecentLogs() {
		this.lmsService.get('accesslog/getMostRecent').then((result) => {
			let accessLogs = result.properties.accessLogs;
			if (accessLogs) {
				this.employees = accessLogs;
			} else if (result.errors.length) {
				this.feedbackService.setErrors(result.errors);
			}
        });
	}

	/*
	 * On Update
	*/
	onUpdate() {
		let hasValue = false;

		for (let key in this.searchers) {
			let searcher = this.searchers[key];
			if (searcher) {
				this.searchers[key] = this.searchers[key].trim();
				hasValue = true;
			}
		}
		this.allowSubmit = hasValue;
	}

	ngOnInit() {
		this.init();
		this.navService.displayPage();
	}
}