<section class="row mb-3" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber (onTabChange)="onTabChange()">
			<lio-panel-tab [settings]="modifyRolesPanelConfig">
				<lio-modify-roles *lioPanelTabContent></lio-modify-roles>
			</lio-panel-tab>
			
			<lio-panel-tab [settings]="queryPanelPanelConfig">
				<lio-card *lioPanelTabContent [hideErrors]="true">
					<div lio-card-body>
						<div class="text-center" *ngIf="showTitle">
							<p>{{localeStrings.subheader}}</p>
						</div>
						<lio-query-tool 
							[filters]="filters" 
							[controlSettings]="queryToolSettings" 
							[controlObject]="queryTool" 
							(results)="loadEmployees($event)">
						</lio-query-tool>
					</div>
					<div lio-card-footer *ngIf="employees.length">
						<lio-panel-tabber>
							<lio-panel-tab [settings]="assignCompaniesPanelConfig">
								<lio-assign-companies *lioPanelTabContent [employees]="employees" 
									[targetCompany]="targetCompany" 
									[unmodifiedEmployees]="unmodifiedEmployees" 
									[pagination]="assignCompaniesPagination" 
									[fields]="fields" 
									[allCompanies]="allCompanies">
								</lio-assign-companies>
							</lio-panel-tab>
							<lio-panel-tab [settings]="assignRolesPanelConfig">
								<lio-assign-roles *lioPanelTabContent [employees]="employees" 
									[targetRole]="targetRole" 
									[unmodifiedEmployees]="unmodifiedEmployees" 
									[pagination]="assignRolesPagination" 
									[fields]="fields" 
									[allRoles]="allRoles">
								</lio-assign-roles>
							</lio-panel-tab>
						</lio-panel-tabber>
					</div>
				</lio-card>
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</section>