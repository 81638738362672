import { Component, OnDestroy, Inject } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { catalogService } from '../../../../services/catalog.service';
import { workerService } from '../../../../services/worker.service';
import { localizationService } from '../../../../services/localization.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { filesService } from '../../../../services/files.service';
import { fieldService } from '../../../../services/fields.service';
import { storageService } from '../../../../services/storage.service';
import { catalogAdminSettings } from '../../../../components/catalog-admin/catalog-admin.settings';

@Component({
  selector: 'lio-catalog-admin-topics',
  templateUrl: './catalog-admin-topics.component.html'
})
export class CatalogAdminTopicsComponent implements OnDestroy {

	public 	appearance:MatFormFieldAppearance = 'outline';
	public	fields:any									= [];
	public	chosenTopic:any							= {topicCodeID: null};
	public	topicField:any							= {};
	public	topicFields:Array<any>			= [];
	public	topics :any									= [];
	public	topic :any									= {};
	public	localeStrings:any 					= {};
	public	localeStringsKeys:any 			= [];

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)		public 	feedbackService		:feedbackService,
		@Inject(errorsService)			public 	errorsService		:errorsService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(catalogService)			public 	catalogService		:catalogService,
		@Inject(lioLogService)			public 	lioLogService		:lioLogService,
		@Inject(stateService)			public 	stateService		:stateService,
		@Inject(formValidatorService)	public 	formValidatorService:formValidatorService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(workerService)			public 	workerService		:workerService,
		@Inject(permissionService)		public 	permissionService	:permissionService,
		@Inject(localizationService)	public 	localizationService	:localizationService,
		@Inject(lioModalService)		public 	lioModalService		:lioModalService,
		@Inject(processingService) 		public 	processingService	:processingService,
		@Inject(utilService) 			public 	utilService			:utilService,
		@Inject(fieldService) 			public 	fieldService		:fieldService,
		@Inject(storageService)			public 	storageService		:storageService,
		@Inject(catalogAdminSettings) 		public 	catalogAdminSettings		:catalogAdminSettings
	){
		this.debugService.register('catalogadmintopics', this);
		this.fields = this.permissionService.setFields(this.catalogAdminSettings.fields);
		this.topicField = this.utilService.copy(this.catalogAdminSettings.topicField);
		this.topicFields = this.utilService.copy(this.catalogAdminSettings.topicFields);

		this.topics = [];
		this.topic = {};
		this.localeStrings = {
			saveTopic				:'catalogadmin.saveTopic',
			deleteTopic			:'catalogadmin.deleteTopic',
		}

		this.localeStringsKeys = Object.keys(this.localeStrings);
		this.subscriptions.add(
			this.catalogService.topics.subscribe((topics) => {
				this.topics = topics;
			})
		);
	}


	/*
	 * User selected a course ID from the drop down
	*/
	selectedTopic() {
		this.topics.forEach((topic) => {
				if (topic.topicCodeID === this.chosenTopic.topicCodeID) {
					this.topic = topic;
				}
		});
	}

	/*
	 * Save the topic
	*/
	saveTopic() {
		return this.lmsService.post('catalog/editTopic', {'topic': this.topic}).then((result) => {
			if (result.success) {
				this.lioModalService.show('message.savedSuccessfully');
				this.catalogService.getTopics();
			}
		});
	}

	/*
	 * Delete the topic
	*/
	deleteTopic() {
		return this.lmsService.post('catalog/deleteTopic', {'topic': this.topic}).then((result) => {
			if (result.success) {
				this.lioModalService.show('message.deletedSuccessfully');
				this.catalogService.getTopics();
				this.topic = {};
			}
		});
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}