<!-- Nav -->
<section localize [model]="localeStrings" [items]="localeKeys">
	<nav id="am_top_navbar"
		[hidden]="!navService.showNavBar"
		class="navbar-top navbar navbar-expand navbar-dark text-white logo-container p-0 pl-3 rtl-direction rtl"
		role="navigation"> 

		<div id="am_top_navRow" 
			class="navbar-brand py-0 ml-2 w-60p">

			<div id="am_top_navLogo" 
				[ngClass]="{'disabled': isLocked()}" 
				[hidden]="!brandingService.logoURL" 
				class="logo-header" 
				(click)="goHome()">

				<img class="pageLogo btn-cursor" [src]="brandingService.logoURL" alt="{{brandingService.coName}} Logo">
				<span id="am_header_title" 
					[textContent]="brandingService.coName" 
					class="btn-cursor logo-title d-none d-sm-inline-block branding-text">True Office Learning Dashboard</span>
			</div>
		</div>
	
		<div id="am_top_session" 
			[ngClass]="{'disabled': isLocked(), 'bg-info':stateService.isSessionModified()}" 
			class="navbar-nav ml-auto" 
			[hidden]="!stateService.loggedIn || !stateService.userName" 
			style="height: 80px;">

			<div id="am_top_sessionToggle" 
				tabindex="0"
				[attr.aria-expanded]="mouseOnMenuButton"
				aria-label="Expandable navigation menu" 
				class="nav-item dropdown focus-primary btn-cursor" 
				(mouseover)="mouseOnMenuButton = true"
				(mouseout)="mouseOnMenuButton = false"
				(keyup)="onKeyUp($event)"
			>
				
				<div class="nav-link dropdown-toggle text-light branding-text no-caret p-0">
					<section *ngIf="stateService.isSessionModified()" class="font-weight-bold d-inline-block pr-7 pl-4">
						<div class="row d-flow-root">
							<span class="text-faded-blue text-right col-6 p-0">Role:</span>
							<span class="col-6" [textContent]="getActiveRole().name"></span>
						</div>
						<div class="row d-flow-root">
							<span class="text-faded-blue text-right col-6 p-0">Company:</span>
							<span class="col-6" [textContent]="getActiveCompany().companyID"></span>
						</div>
					</section>
					<section class="d-none d-sm-inline-block lh-125" 
						[ngClass]="{'p-3' : !stateService.isImpersonating(), 'p-2' : stateService.isImpersonating()}">

						<!-- Impersonation Employee ID -->
						<div class="row d-flow-root" *ngIf="stateService.isImpersonating() && getImpersonationTarget().employeeID" id="am_user_impersonation_employeeID">
							<span class="font-weight-bold col-6">Impersonating</span>
							<span class="mb-1 col-6" [textContent]="getImpersonationTarget().employeeID | limit:50"></span>
						</div>
						<div *ngIf="!stateService.isImpersonating()" id="am_user_employeeID">
							<p class="mb-1" [textContent]="(stateService.getEmployeeID() | limit:50)">
							</p>
						</div>
	
						<!-- Employee Name -->
						<div *ngIf="stateService.isImpersonating() && getImpersonationTarget().firstName" id="am_user_impersonation_employeeName">
							<p [textContent]="$any(getImpersonationTarget().firstName | limit:72:'') 
								+ ' ' 
								+ $any(getImpersonationTarget().lastName | limit:72)">
							</p>
						</div>
						<div *ngIf="!stateService.isImpersonating()" id="am_user_employeeName">
							<p [textContent]="(stateService.userName | limit:72)"></p>
						</div>
					</section>
					<section class="d-inline-block" [ngClass]="{'p-3' : !stateService.isImpersonating(), 'p-2' : stateService.isImpersonating()}">
						<p><i class="fa fa-user-circle fa-3x"></i></p>
					</section>
				</div>
			</div>
		</div>
	</nav>
	
	
	<!-- Submenu -->
	<nav 
		id="am_top_submenu"
		[hidden]="!navService.showNavBar"
		class="navbar navbar-expand-lg navbar-dark bg-light navbar-fixed-top border-bottom border-light-gray p-2 pl-4 rtl-direction rtl"
		role="navigation"> 
		<!-- Dropdown menu -->
		<div id="am_top_sessionDropdown" 
			(mouseover)="mouseOnMenu = true"
			(mouseout)="mouseOnMenu = false"
			[ngClass]="{
				'disabled': navService.disableNav, 
				'show':profileActive(), 
				'dropdown-menu-right': !localizationService.isRTL(), 
				'dropdown-menu-left': localizationService.isRTL()}" 
			class="dropdown-menu mt-0 rtl" 
			*ngIf="!isLocked()">

			<button id="am_user_editProfile" 
				class="dropdown-item" 
				[hidden]="!permissionService.hasPermission('self.viewProfile') 
					|| !settingsService.allowEditProfile 
					|| isLocked() 
					|| !stateService.isAtHomeCompany()" 
				(click)="editProfile()">{{localeStrings.editProfile}}</button>

			<button id="am_user_impersonate" 
				class="dropdown-item" 
				[hidden]="!stateService.impersonateOptions.length || stateService.isImpersonating()" 
				(click)="showImpersonate()">Impersonate</button>

			<button id="am_user_endImpersonate" 
				class="dropdown-item" 
				[hidden]="!stateService.isImpersonating()" 
				(click)="stateService.impersonateEnd()">End Impersonation</button>

			<button id="am_user_endImpersonate" 
				class="dropdown-item" 
				[hidden]="!stateService.isProxying()" 
				(click)="endProxy()">End Proxy</button>

			<button id="am-user_resetRole" 
				class="dropdown-item" 
				[hidden]="stateService.isUsingDefaultRole() || stateService.isImpersonating()" 
				(click)="switchToDefaultRole()">Reset Role</button>

			<button id="am_user_resetCompany" 
				class="dropdown-item" 
				[hidden]="stateService.isAtHomeCompany() || stateService.isImpersonating()" 
				(click)="switchToHomeCompany()">Reset Company</button>

			<button id="am_user_chooseRole" 
				class="dropdown-item" 
				*ngIf="stateService.canChangeRole()" 
				(click)="chooseRole()">{{localeStrings.chooseRole}}</button>

			<button id="am_user_chooseCompany" 
				class="dropdown-item" 
				[hidden]="!stateService.canChangeCompany()" 
				(click)="showCompany()">Choose Company</button>

			<button id="am_user_chooseLang" 
				class="dropdown-item" 
				[hidden]="isLocked() 
					|| !localizationService.shouldShowLanguageModal 
					|| settingsService.supressLangs 
					|| settingsService.hideLangMenu 
					|| !permissionService.hasPermission('self.setLanguages')" 
				(click)="chooseLang()">{{localeStrings.chooseLang}}</button>

			<button id="am_user_changePass" 
				class="dropdown-item" 
				[hidden]="!permissionService.hasPermission('self.changePassword') || settingsService.isSSO || !settingsService.allowChangePassword || isLocked()" 
				(click)="navService.goto('changepass')">{{localeStrings.changePass}}</button>

			<button id="am_user_help" 
				class="dropdown-item" 
				[hidden] ="!permissionService.hasPermission('pages.documentation')"
				(click)="navService.goto('documentation')">{{localeStrings.documentation}}</button>

			<button id="am_user_logOut" 
				class="dropdown-item" 
				[hidden]="isLocked()" 
				(click)="logout()">{{localeStrings.logout}}</button>
		</div>
	
		<ul id="am_top_submenuNavBar" class="nav navbar-nav small">
			<div id="submenu">		
				
				<span *ngIf="breadcrumbService.breadcrumbs.length > 1">
					<button  
						queryParamsHandling="preserve" 
						id="am_crumb_back" 
						aria-label="Go back" 
						class="m-0 p-0 button-hide text-decoration-none ml-2 hc-dark-text"
						[ngClass]="{'disabled': isLocked()}"
						(keyup)="mouseOnMenuButton = false"
						(click)="breadcrumbService.goBackOne();">

						<i *ngIf="!localizationService.isRTL()" class="fa fa-lg fa-arrow-circle-left text-primary"></i>
						<i *ngIf="localizationService.isRTL()" class="fa fa-lg fa-arrow-circle-right text-primary"></i>
					</button>
					<span class="ml-2 font-weight-bold">|</span>
				</span>
	
				<span>
					<button
						queryParamsHandling="preserve" 
						*ngIf="breadcrumbService.breadcrumbs.length > 1" 
						id="am_crumb_home" 
						tabindex="0" 
						aria-label="Go home" 
						class="m-0 p-0 button-hide text-decoration-none hc-dark-text"
						[ngClass]="{'disabled': isLocked() || breadcrumbService.breadcrumbs.length == 1}" 
						(keyup)="mouseOnMenuButton = false"
						(click)="goHome();">

						<i class="fa fa-lg fa-home text-primary ml-2"></i>
					</button>
					<span 
						*ngIf="breadcrumbService.breadcrumbs.length == 1" 
						id="am_crumb_home"
						(keyup)="mouseOnMenuButton = false"
					>
						<i class="fa fa-lg fa-home hc-dark-text text-secondary ml-2"></i>
					</span>
				</span>
				
				<span *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; index as i">
					<span [hidden]="i < breadcrumbService.breadcrumbs.length - 1" class="text-secondary hc-dark-text">
						<span *ngIf="breadcrumbService.breadcrumbs.length > 1" class="ml-2 mr-1">/</span>
						<h1 id="{{breadcrumb.id}}" class="ml-1 span">{{breadcrumb.name}}</h1>
					</span>
				</span>
			</div>
		</ul>
		<ul id="am_top_submenuRightBar" class="nav navbar-nav ml-auto">
			<div class="label label-danger animated" 
				[ngClass]="{'fadeInRight': processing}" [hidden]="!processing">

				<span>{{localeStrings.processing}}</span>
				<i class="p-1 fa fa-spinner fa-spin"></i>
			</div>
				<button id="am_user_highcontrast"
				*ngIf="!processing"
				[title]="localeStrings.toggleContrast"
				class="hide-button contrast-icon dropdown-item hc-dark-text"
				[ngClass]="{'active':isHighContrast()}"
				(click)="toggleContrast()">
					<i class="fa fa-adjust" aria-hidden="true"></i>
				</button>


		</ul>
	</nav>
	</section>