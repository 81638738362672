import { Inject, Injectable } from '@angular/core';
import { LioSearchableSelectField } from '../modules/lio-forms/lio-forms.models';
import { ccfService } from '../services/ccf.service';
import { presetModelService } from '../services/preset-model.service';


@Injectable({
	providedIn	: 'root', 
	useClass	: presetsSettings
})
export class presetsSettings {
	constructor(
		@Inject(ccfService) private ccfService:ccfService,
		@Inject(presetModelService) private presetModelService:presetModelService,
	){}

	panelConfig:any = {
		'page' : {
			id				: 'panelTab_filter_page_editor',
			title			: 'Presets Editor',
			subtitle		: 'Presets Editor',
			tab				: 'Presets Editor',
			reloadOnTabChange: true,
		}
	};

	fieldConfig:any = {
		fieldName			: 'fileformatconfig',
		localizeFields		: true,
		permissionFields	: true,
	};

	modelFields:Array<any> = [
		new LioSearchableSelectField({
			name:"Page Name",
			model:"pageName",
			type:"select",
			required: true,
			min: 2,
			max: 2000,
			options: [
				{name: 'Register', value: 'register'}
			],
			visible:true,
		}),
		new LioSearchableSelectField({
			name:"Saved Params",
			model:"paramID",
			type:"select",
			required: true,
			min: 2,
			max: 2000,
			options: this.presetModelService.options,
			visible:true,
			clearable: true,
		}),
		{
			name:"Param Name",
			model:"paramID",
			type:"text",
			required: true,
			min: 2,
			max: 2000,
			visible:true,
		},
	];


	fields:Array<any> = [
		new LioSearchableSelectField({
			name:"Field Name",
			model:"field",
			type:"select",
			required: true,
			min: 2,
			max: 2000,
			options: this.addOptions(),
			visible:true,
		}),
		{
			name: 'Field Value',
			model: 'value',
			type	: 'text',
			selected: true,
			visible	: true,
			export	: true,
			sortable: true
		},
		new LioSearchableSelectField({
			name:"Locked",
			model:"locked",
			type:"select",
			required: false,
			options:[
				{
					value: 0,
					name: 'False',
				},
				{
					value: 1,
					name: 'True',
				},
			],
			visible:true,
		}),
	];




	addOption(model:string, name:string) {
		return {
			value			: model,
			name			: name,
		}
	}

	addOptions() {
		let options = [];

		options.push(this.addOption('employeeID', 'Employee ID'));
		options.push(this.addOption('firstName', 'First Name'));
		options.push(this.addOption('lastName', 'Last Name'));
		options.push(this.addOption('email', 'e-mail'));
		options.push(this.addOption('city', 'City'));
		options.push(this.addOption('state', 'State'));
		options.push(this.addOption('address', 'Address'));
		options.push(this.addOption('address2', 'Address 2'));
		options.push(this.addOption('country', 'Country'));
		options.push(this.addOption('state', 'State'));
		options.push(this.addOption('zipCode', 'Zip Code'));
		options.push(this.addOption('phone', 'Phone #'));
		options = options.concat(this.ccfService.getCCFOptions());
		return options;
	}
}