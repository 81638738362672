import { NgModule, Component, Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Routes, Router, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { navService } from './services/nav.service';
import { trackerService } from './services/tracker.service';
import { stateService } from './services/state.service';
import { storageService } from './services/storage.service';

import { 
	AccessLogComponent,
	AuditLogComponent,
	AddUsersComponent,
	BatchProcessingComponent,
	AssignComponent,
	AdminToolsComponent,
	HomeComponent,	
	CourseUploaderComponent,
	ReportsComponent,
	ReportEditorComponent,
	ReportCreatorComponent,
	DashboardComponent,
	EnrollmentComponent,
	CatalogSearchComponent,
	SelfEnrollComponent,
	BrandingComponent,
	UserSummaryComponent,
	TrainingComponent,
	SettingsComponent,
	ViewDisclosureComponent,
	ScheduleEnrollmentsComponent,
	ScheduleEmailsComponent,
	ProfileComponent,
	SelfProfileComponent,
	LogoutComponent,
	LoginComponent,
	LoggedOffComponent,
	RegisterComponent,
	LocalizationAdminComponent,
	LocalizationComponent,
	GlobalSearchComponent,
	ForgotPasswordComponent,
	FieldsComponent,
	ExcludeUsersComponent,
	ErrorLogComponent,
	ErrorPageComponent,
	EnrollQueryComponent,
	EnrollmentHistoryComponent,
	EmployeeSearchComponent,
	EmailHistoryComponent,
	EmailAuditLogComponent,
	EmailComponent,
	EditPermissionsComponent,
	DocumentationComponent,
	DelegateAccessComponent,
	DisclosureManagerComponent,
	DisclosureReportsComponent,
	ChangePasswordComponent,
	CCFAdminComponent,
	CatalogCollectionComponent,
	CourseLaunchComponent,
	CatalogAdminComponent,
	CompanyAdminComponent
} from './components/pages.module';

@Component({selector: 'empty', template: ''})
class EmptyComponent {}

//Resolver to handle page not found
@Injectable()
export class PageNotFoundResolver implements Resolve<any> {
	constructor(
		@Inject(navService) private navService:navService,
		@Inject(Router) private Router:Router
	){}

	resolve(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		if(state.url.search('#/') > -1){
			let fixedPath = state.url.replace('#/', '');
			this.Router.navigateByUrl(fixedPath);
		}else{
			this.navService.goto(this.navService.getHomePage());
		}
	}
}


//Resolver to turn off navbar and footer for disclosures loaded from courses
@Injectable()
export class ViewDisclosureResolver implements Resolve<any> {
	constructor(
		@Inject(navService) 	private navService		:navService,
		@Inject(storageService) private storageService	:storageService,
	){}

	resolve(){
		let params = this.storageService.getInitialParams() || {};
		this.navService.showNavBar = params.showLMS;
		this.navService.showFooter = params.showLMS;
		return true;
	}
}

//Resolver to close existing course window if one is found
@Injectable()
export class TrainingResolver implements Resolve<any> {
	constructor(
		@Inject(trackerService) private trackerService:trackerService,
		@Inject(storageService) private storageService:storageService,
	){}

	resolve(){
		if (this.trackerService.isActive()) {
			let courseWindow = this.storageService.get('openedWindow');
			return this.trackerService.deactivate().then(() => {
				if (courseWindow && courseWindow.close) {
					courseWindow.close();
				}	
			});
		}
		return true;
	}
}

/**
 * Guard for the profile page redirects to the selfprofile if the user is trying to view themselves
 */
@Injectable()
export class ProfileGuard implements CanActivate {
	constructor(
		@Inject(stateService) 	private stateService	:stateService,
		@Inject(storageService) private storageService	:storageService,
								public 	router			:Router
	){}

	canActivate() {
		let employeeID = this.stateService.getSession().employeeID,
			storedEmployee = this.storageService.get('employeeToEdit');
		
		if ((!storedEmployee) || storedEmployee.employeeID === employeeID) {
			this.router.navigate(['selfprofile']);
			return false;
		}
		return true;
	}
}

const routes: Routes = [
	{ path: 'accesslog'				, component: AccessLogComponent 			},
	{ path: 'adminTools'			, component: AdminToolsComponent 			},
	{ path: 'addusers'				, component: AddUsersComponent 				},
	{ path: 'batchprocessing'		, component: BatchProcessingComponent 		},
	{ path: 'assign'				, component: AssignComponent 				},
	{ path: 'auditlog'				, component: AuditLogComponent 				},
	{ path: 'branding'				, component: BrandingComponent 				},
	{ path: 'catalogadmin'			, component: CatalogAdminComponent 			},
	{ path: 'catalogcollection'		, component: CatalogCollectionComponent 	},
	{ path: 'catalogsearch'			, component: CatalogSearchComponent 		},
	{ path: 'ccfadmin'				, component: CCFAdminComponent 				},
	{ path: 'changepass'			, component: ChangePasswordComponent 		},
	{ path: 'company'				, component: CompanyAdminComponent          },
	{ path: 'courselaunch'			, component: CourseLaunchComponent 			},
	{ path: 'courseuploader'		, component: CourseUploaderComponent 		},
	{ path: 'dashboard'				, component: DashboardComponent 			},
	{ path: 'delegateAccess'		, component: DelegateAccessComponent 		},
	{ path: 'disclosure'			, component: DisclosureManagerComponent 	},
	{ path: 'disclosureReport'		, component: DisclosureReportsComponent 	},
	{ path: 'documentation'			, component: DocumentationComponent 		},
	{ path: 'editPermissions'		, component: EditPermissionsComponent 		},
	{ path: 'emailauditlog'			, component: EmailAuditLogComponent 		},
	{ path: 'email'					, component: EmailComponent 				},
	{ path: 'emailHistory'			, component: EmailHistoryComponent 			},
	{ path: 'employeesearch'		, component: EmployeeSearchComponent 		},
	{ path: 'enrollments'			, component: EnrollmentComponent 			},
	{ path: 'enrollmentHistory'		, component: EnrollmentHistoryComponent 	},
	{ path: 'enrollquery'			, component: EnrollQueryComponent 			},
	{ path: 'errorlog'				, component: ErrorLogComponent 				},
	{ path: '405'					, component: ErrorPageComponent 			},
	{ path: '500'					, component: ErrorPageComponent 			},
	{ path: 'excludeUsers'			, component: ExcludeUsersComponent 			},
	{ path: 'fields'				, component: FieldsComponent 				},
	{ path: 'forgotpass'			, component: ForgotPasswordComponent 		},
	{ path: 'globalsearch'			, component: GlobalSearchComponent 			},
	{ path: 'home'					, component: HomeComponent 					},
	{ path: 'localizationadmin'		, component: LocalizationAdminComponent 	},
	{ path: 'localization'			, component: LocalizationComponent 			},
	{ path: 'loggedoff'				, component: LoggedOffComponent 			},	
	{ path: 'login'					, component: LoginComponent 				},
	{ path: 'logout'				, component: LogoutComponent 				},
	{ path: 'register'				, component: RegisterComponent 				},
	{ path: 'reports'				, component: ReportsComponent 				},
	{ path: 'reportcreator'			, component: ReportCreatorComponent 		},
	{ path: 'reporteditor'			, component: ReportEditorComponent 			},
	{ path: 'scheduleEmails'		, component: ScheduleEmailsComponent		},
	{ path: 'scheduleEnrollments'	, component: ScheduleEnrollmentsComponent 	},
	{ path: 'selfenroll'			, component: SelfEnrollComponent 			},
	{ path: 'learniosettings'		, component: SettingsComponent 				},
	{ path: 'usersummary'			, component: UserSummaryComponent 			},
	{ path: 'profile'				, component: ProfileComponent				, canActivate: [ProfileGuard]},
	{ path: 'selfprofile'			, component: SelfProfileComponent			},
	{ path: 'training'				, component: TrainingComponent				, resolve: { contact: TrainingResolver }},
	{ path: 'viewDisclosure'		, component: ViewDisclosureComponent		, resolve: { contact: ViewDisclosureResolver }},
	{ path: '**'					, component: EmptyComponent					, resolve: { contact: PageNotFoundResolver }},
];

@NgModule({
	imports		: [RouterModule.forRoot(routes)],
	exports		: [RouterModule],
	providers	: [
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		PageNotFoundResolver,
		TrainingResolver,
		ProfileGuard,
		ViewDisclosureResolver
	]
})
export class AppRoutingModule{}