import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../services/debug.service';
import { navService } from '../../services/nav.service';
import { loaderService } from '../../services/loader.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';

import { fieldsSettings } from './fields.settings';
import { filterSettings } from '../../settings/filters.settings';
import { presetsSettings } from '../../settings/presets.settings';

@Component({
	selector: 'lio-fields',
	templateUrl: './fields.component.html'
})
export class FieldsComponent implements OnDestroy {	
	public fieldsPanelConfig:any 			= this.fieldsSettings.panelConfig.page;
	public filterPanelConfig:any 			= this.filterSettings.panelConfig.page;
	public presetsPanelConfig:any 			= this.presetsSettings.panelConfig.page;

	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(debugService)		public debugService		:debugService,
		@Inject(navService)			public navService		:navService,
		@Inject(loaderService)		public loaderService	:loaderService,
		@Inject(feedbackService)	public feedbackService	:feedbackService,
		@Inject(stateService)		public stateService		:stateService,
		@Inject(fieldsSettings)		public fieldsSettings	:fieldsSettings,
		@Inject(presetsSettings)	public presetsSettings	:presetsSettings,
		@Inject(filterSettings)		public filterSettings	:filterSettings
	){
		this.debugService.register('fields', this);
		this.navService.setActivePage('fields');

		this.feedbackService.clearErrors();

		//Init on refresh
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.navService.displayPage();
			})
		);
		
		//Init
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.navService.displayPage();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	onTabChange() {
		this.navService.displayPage();
	}
}