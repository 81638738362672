<section>
	<div class="card-body container-fluid py-2">
		<h6 class="font-weight-bold text-left" trans="enrollquery.filters">Filters</h6>
		<div class="small mb-2" trans="enrollquery.subheader">Enter fields below to select the results you would like to enroll</div>
		<lio-query-tool 
			[filters]="filters" 
			[controlSettings]="queryToolSettings"
			(customConfirmation)="confirm($event)"
			(results)="handleQueryResults($event)">
		</lio-query-tool>
	</div>

	<!-- QUERY RESULTS -->
	<lio-card [hidden]="!filteredResults.length" bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
		<div lio-card-body lio-paginator controlID="filterEditor" [collection]="results" [settings]="settings.pagination" (onResults)="updateFilteredResults($event)">
			<lio-results-table
				paginatorID="filterEditor"
				[fields]="settings.fields"
				[results]="filteredResults"
				[fieldConfig]="settings.fieldConfig">
			</lio-results-table>
		</div>
		<div lio-card-footer>
			<lio-paginator-controls controlID="filterEditor"></lio-paginator-controls>
			<div class="col-md-10">
				<button id="am_scheduleenrollments_exportrecords" class="btn btn-info" type="button" (click)="exportRecords()" [disabled]="exportingRecords">
					<span trans="global.exportrecords">Export Records</span>
					<i class="fa fa-file-excel fa-margin" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</lio-card>
</section>