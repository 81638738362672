/*
 * Service for Controlling navigation
*/

import { Inject, Injectable } from '@angular/core';
import { ModalComponent } from '../modules/modals/modals.module';
import { MatDialog } from '@angular/material/dialog';

import { lioLogService } from './lio-log.service';
import { configSettings } from '../settings/config.settings';
import { debugService } from '../services/debug.service';

@Injectable({
	providedIn: 'root',
})
export class lioModalService {
	private _promise 			: any;
	private _modal				: ModalComponent;
	private _opened				: boolean = false;
	private _opening 			: boolean = null;
	private _settings 			: any = {};
	private _type				: any;
	private _resolver 			: any = null;
	private _active 			: boolean = true;
	private _suppressActivation : boolean = false;
	private _openingModals		: Array<any> = [];

	constructor(
		@Inject(MatDialog)				private matDialog			:MatDialog,
		@Inject(configSettings)			private configSettings		:configSettings,
		@Inject(debugService)			private debugService		:debugService,
		@Inject(lioLogService)			private lioLogService		:lioLogService,
	) {
		this.debugService.register('modals', this);
	}


	/*
	 * is the modal open/opening?
	 * return {boolean}
	*/
	isOpen():boolean {
		return this._opening || this._opened;
	}


	/*
	 * Deletes empty settings so they can be overridden by the defaults 
	 * @param {Object}
	 * @return {Object}
	*/
	clean(settings) {
		if (!settings.title) {
			delete settings.title;
		}
		if (!settings.description) {
			delete settings.description;
		}
		if (!settings.macros) {
			delete settings.macros;
		}
		return settings;
	}


	/*
	 * Sets the modal active
	*/
	setActive() {
		if (!this._suppressActivation) {
			this._active = true;
		}
	}

	/*
	 * Sets the modal inactive
	*/
	setInactive() {
		this._active = false;
	}


	/*
	 * Open the modal after a slight delay to avoid flickering
	 * @return {Promise}
	*/
	open(settings) {
		this._promise = new Promise((resolve) => {
			this._opening = true;

			if (!this._active) {
				resolve(false);
				return;
			}

			let modalInfo = {
				timeout		: null,
				settings 	: settings
			};

			modalInfo.timeout = setTimeout(() => {
				// Handle if there is already a modal opened
				if (this._opened) {
					if (this._type === settings['type']) {
						// If we're opening the same type, just update the settings 
						this._resolver = resolve;
						this.updateSettings(settings);
						//resolve('UPDATED');
					} else {
						// Close the modal and then open it once its confirmed closed
						this.closeAndWait().then(() => {
							this.createModal(settings, resolve);
							this._opening = false;
						});
					}
					
					return;
				}

				this.createModal(settings, resolve);
				this._opening = false;
			});

			this._openingModals.push(modalInfo);
		});
		return this._promise;
	}


	/*
	 * Updates the modal settings
	 * @param {Object} settings
	*/
	updateSettings(settings) {
		settings = this.clean(settings);
		this._settings = settings;
		this.lioLogService.log(['Updating settings', settings]);
		this._modal.updateSettings(settings);
	}


	/*
	 * Suppress hiding of the modal
	*/
	suppressHide() {
		this._suppressActivation = true;
		this._active = false;
	}


	/*
	 * Updates a setting for the component
	*/
	updateSetting(name, value): void {
		if (this._settings) {
			this._settings[name] = value;
			if (this._modal) {
				this._modal.updateSetting(name, value);	
			}
		}
	}


	/*
	 * Updates a setting for the component
	*/
	updateTitle(name): void {
		if (this._settings && this._modal)  {
			this._settings['title'] = name;
			this._settings['titleTrans'] = 'modal.'+ name;
			this._modal.updateSetting('title', this._settings['title']);	
		}
	}


	/*
	 * Called to open the modal
	 * @param {Object} settings
	 * @param {Function} resolve
	*/
	createModal(settings, resolve) {
		settings = this.clean(settings);
		this._settings = settings;
		this._type = settings['type'];

		// Create the base modal component
		this._modal = new ModalComponent(this.matDialog);
		this._modal.init(settings);
		this._modal.openModal();

		// Handle feedback
		// Submitted Handler
		this._modal.submitted_emitter.subscribe(($e) => {
			this.lioLogService.log(['Heard Submitted', $e]);
			this._modal.requestClose();
			resolve($e);
		});

		// Opened Handler
		this._modal.opened_emitter.subscribe(($e) => {
			this.lioLogService.log(['Heard Opened', $e]);
			this._opened = true;
			for(let i = 0; i < this._openingModals.length; i++){
				this._openingModals.splice(i,1);
				break;
			}
		});

		// Closed Handler
		this._modal.closed_emitter.subscribe(($e) => {
			this.lioLogService.log(['Heard Closed', $e]);
			this._opened = false;
			this._opening = false;
			// The resolver is geting lost during update settings
			if (this._resolver) {
				this._resolver($e);
			}
			resolve($e);
		});

	}


	/*
	 * Close and wait for the confirmation
	 * @return {Promise}
	*/
	closeAndWait() {
		return new Promise((resolve) => {
			setTimeout(() => {
				if (this.hide()) {
					// Wait for the closed Handler
					this._modal.closed_emitter.subscribe(() => {
						resolve(true);
					});
				} else {
					// Nothing to hide, go for it
					resolve(true);
				}

			});
		});
	}

	/*
	 * Hide the modal
	 * @param {?string}
	 * @return {boolean}
	*/
	hide(type?:string) {
		if (!this._active) {
			return false;
		}

		for(let i = 0; i < this._openingModals.length; i++){
			if(this._openingModals[i].settings.type == type){
				clearTimeout(this._openingModals[i].timeout);
				this._openingModals.splice(i, 1);
				i--;
			}
		}

		if (this._opened) {
			if (type && this._type !== type) {
				return false;
			}
			this._modal.requestClose();
			return true;
		}
		return false;
	}

	/*
	 * Show the loading modal
	 * @param {string} title
	 * @param {boolean} can cancel
	 * @param {?Object} settings
	*/
	showLoading(title:string = 'processing', canCancel?: boolean) {
		return this.open({
			'title': title, 
			'titleTrans': title, 
			'type': 'loading',
			'canCancel': canCancel,
			'cancel': 'Cancel',
		})
	}

	/*
	 * Hide the loading modal
	*/
	hideLoading() {
		if (!this._settings.suppressHide) {
			this.hide('loading');
		}
	}

	/*
	 * Show the error modal
	 * @param {string} title
	 * @param {string} description
	 * @param {?array} macros
	*/
	showError(title:string, description?:string, macros?:any) {
		return this.open({'type': 'default', 'title': title, 'description': description, 'macros': macros, 'canClose': true, 'close': 'Close'});
	}



	/*
	 * Show a critical error
	 * @param {string} title
	 * @param {?string} description
	 * @param {?array} macros
	*/
	showCriticalError(title:string, description?:string, macros?:any) {
		let macro = {
			'key': 'supportEmail',
			'value' : this.configSettings.SUPPORT_EMAIL
		};
		
		if (!macros) {
			macros = [macro];
		} else {
			macros.push(macro);
		}

		if (!description) {
			description = 'error.persistsWithOutCode';
		}
		return this.open({'type': 'default', 'title': title, 'description': description, 'macros': macros, 'canClose': false});
	}


	/*
	 * Show a default modal
	 * @param {string} title
	 * @param {string} description
	 * @param {?array} macros
	*/
	show(title:string, description?:string, macros?:any) {
		return this.open({'type': 'default', 'title': title, 'description': description, 'macros': macros, 'canClose': true, 'close': 'Close'});
	}

	/*
	 * Shows a confirmation modal
	 * @return {Promise}
	*/
	confirm(title:string, description?:string, macros?:any) {
		return this.open({'type': 'confirm', 'title': title, 'titleTrans': title, 'description': description, 'macros': macros});
	}
}