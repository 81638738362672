/*
 * Exporting Worker
*/
self.EmployeeExporter = function(){
	this.localizations = [];

	/*
	 * Gets the headers
	 * @return {array}
	*/
	this.getHeaders = function(fields) {
		let headers = [],
			header = {},
			i,
			total = fields.length,
			field;


		for (i = 0; i < total; i++) {
			field = fields[i];
			if ((field.export === false || field.export === 0) || (!field.export && !field.visible)) {
				continue;
			}

			if (field.model === 'inactive') {
				field.model = 'status';
				if (this.localizations) {
					field.name = this.localizations['form.status'];
				}
			}
			if (field.model === 'permissionID') {
				field.model = 'role';
				if (this.localizations) {
					field.name = this.localizations['form.role'];
				}
			}

			header = {'name': field.name, 'model': field.model, 'field': field};
			headers.push(header);
		}

		if (!headers.length) {
			return null;
		}

		return headers;
	};

	/*
	 * Preps the export
	 * @param {array} rawData
	 * @param {array} fields
	 * @return {array}
	*/
	this.convertToText = function(data, fields) {
		let headers = this.getHeaders(fields);
		if (!headers) {
			return '';
		}
		return this.convertDataToString(data, headers);
	};

	/*
	 * Converts data to a string
	 * @param {object} data
	 * @return {string}
	*/
	this.convertDataToString = function(data, headers) {
		let str = '',
			row = "",
			LB = '\r\n',
			line,
			index = 0,
			model,
			name,
			value,
			header,
			total,
			item,
			dateFormat =  'MM/DD/YYYY hh:mm:ssa',
			i;

		for (item in headers) {
			header = headers[item];
			row += header['name'] + ',';
		}
		row = row.slice(0, -1);
		
		if (this.localizations && this.localizations['dateFormat']) {
			dateFormat = this.localizations['dateFormat'];
		}

		//append Label row with line break
		str += row + LB;
		total = data.length;
		for (i = 0; i < total; i++) {
			line = '';
			item = data[i];
			for (index in headers) {
				model = headers[index]['model'];
				name = headers[index]['name'];
				let field = headers[index]['field'];

				if (field.export == false || field.export == 0) {
					continue;
				}
				if (line !== '') {
					line += ',';
				}

				value = item[model];

				// Support for custom reports with aliased model names
				if (value == null) {
					value = item[name];
				}
				if (typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim().toLowerCase() === 'null')) {
					value = '';
				}

				if (field.type === 'date' && value) {
					value = moment(value).format(dateFormat);
					if (value === 'Invalid date') {
						console.log('Date Invalid: ' + model + ' = ' + item[model]);
					}
				}

				value = this.sanitizeString(value);

				// CSV rows with leading zeroes don't show up in excel unless they are formatted with extra stuff
				if (field.model === 'employeeID' && value.indexOf('0') === 0) {
					value = '=""' + value +  '""'; 
				}

				if (value.length > 1000) {
					value = value.substring(0, 996) + '...';
				}
			    line += '"' + value + '"';
			}
			str += line + LB;
		}
		return str;
	};


	/*
	 * Sanitizes values for csv output
	 * @param {string} value
	 * @return {string}
	*/
	this.sanitizeString = function(value) {
		if (typeof value === 'string') {
			return value.replace(/"/g, '""');
		}
		return value;
	}

	/*
	 * Maps values that are being exported to friendlier values
	 * @param {array} employees
	 * @param {array} fields
	 * @return {array}
	*/
	this.getEmployeesForExport = function(employees, fields, localizations) {
		let i = 0,
			f = 0,
			employee,
			field,
			total = employees.length;

		this.localizations = localizations;

		for (i = 0; i < total; i++) {
			employee = employees[i];
			
			let courseStarted = employee['courseStarted'],
				courseCompletion = employee['courseCompletion'];

			for (f = 0; f < fields.length; f++) {
				field = fields[f];
				if (field.export === false || field.export === 0) {
					continue;
				}
				if (field.type === 'boolean') {
					if (employee[field.model] === '1') {
						employee[field.model] = localizations['form.true'];
					} else if (employee[field.model] === '0') {
						employee[field.model] = localizations['form.false'];
					}
				}
			}

			switch (courseStarted) {
				case '1':
					employee["courseStarted"] = localizations['dashboard.courseStartStatusInProgress'];
				break;
				case '0':
				default :
					employee["courseStarted"] = localizations['dashboard.courseStartStatusEnrolled'];
					break;
			}

			switch (courseCompletion) {
				case '1':
					employee["courseCompletion"] = localizations['dashboard.courseCompleteStatusComplete'];
				break;
				case '0':
				default :
					employee["courseCompletion"] = localizations['dashboard.courseCompleteStatusIncomplete'];
					break;
			}
		}

		return employees;
	};

	/*
	 * Finds unique data
	 * @param {object} data
	 * @return {array}
	*/
	this.getUnique = function(data) {
		let key					= data.key,
			employees			= data.employees,
			totalEmployees		= employees.length,
			uniqueEmployees		= [],
			uniqueIDs	= [],
			map 				= new Map(),
			employee,
			employeeID,
			i;

		for (i = 0; i < totalEmployees; i++) { 
			employee = employees[i];
			employeeID = employee[key];
			if (!map.has(employeeID)) {
				map.set(employeeID, true);
				uniqueEmployees.push(employee);
				uniqueIDs.push(employeeID);
			}
		}

		return {'unique': uniqueEmployees, 'uniqueIDs': uniqueIDs};
	};

	/*
	 * Finds and sets data
	 * @param {object} data
	 * @return {array}
	*/
	this.findAndSet = function(data) {
		let totalMatched	= 0,
			processedIDs	= data.processedIDs,
			key				= data.key,
			setter			= data.setter,
			setterKey 		= setter.key,
			setterValue 	= setter.value,
			employees		= data.employees,
			totalEmployees	= employees.length,
			totalProcessed	= processedIDs.length,
			matchedIDs 		= [],
			remainingIDs	= [],
			matched			= false,
			employee,
			processedID,
			i,
			p;

		for (i = 0; i < totalEmployees; i++) {
			employee = employees[i];
			matched = false;
			for (p = 0; p < totalProcessed; p++) {
				processedID = processedIDs[p];
				if (employee[key] === processedID) {
					totalMatched++;
					matched = true;
					employee[setterKey] = setterValue;
					matchedIDs.push(processedID);
				}
			}
			if (!matched) {
				remainingIDs.push(employee[key]);
			}
		}

		return {'employees': employees, 'totalMatched': totalMatched, 'matchedIDs': matchedIDs, 'remainingIDs': remainingIDs};
	};
};