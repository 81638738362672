<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'subTitle', 'close']">
	
	<!-- Close Button -->
	<button (click)="close()" [hidden]="!settings.canClose" class="button-hide modal-closer float-right">
		<i class="modal-close fa fa-times text-secondary"></i>
        <span class="sr-only">Close Modal</span>
	</button>

	<!-- Header -->
	<header class="modal-header">
		<h5 id="am_modal_title" class="modal-title mx-auto">{{settings.title}}</h5>
	</header>
	
	<!-- Subtitle -->
	<div class="row">
		<div id="am_modal_subtitle" *ngIf="settings.subTitle" class="modal-title text-center">
			<span [innerHtml]="settings.subTitle"></span>
		</div>
	</div>

	<!-- Table -->
	<section class="p-0">
		<table class="table table-striped small mb-0">
			<thead class="thead-light" *ngIf="hasFieldNames">
				<tr>
					<th *ngFor="let field of settings.fields">{{field.name}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let model of settings.models">
					<td *ngFor="let field of settings.fields">{{model[field.model]}}</td>
				</tr>
			</tbody>
		</table>
	</section>

	<!-- Footer -->
	<footer class="modal-footer">
		<button [hidden]="!settings.close" class="btn btn-sm btn-primary mx-auto" mat-raised-button id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>
