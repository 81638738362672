<!-- FILTERS -->
<lio-panel-tabber (onTabChange)="onTabChange()">
	<lio-panel-tab [settings]="fieldsPanelConfig">
		<lio-field-editor *lioPanelTabContent></lio-field-editor>
	</lio-panel-tab>
	<lio-panel-tab [settings]="filterPanelConfig">
		<lio-filter-editor *lioPanelTabContent></lio-filter-editor>
	</lio-panel-tab>
	<lio-panel-tab [settings]="presetsPanelConfig">
		<lio-presets-editor *lioPanelTabContent></lio-presets-editor>
	</lio-panel-tab>
</lio-panel-tabber>
