import { Component, Input, OnDestroy, DoCheck, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { loaderService } from '../../../../services/loader.service';
import { navService } from '../../../../services/nav.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { storageService } from '../../../../services/storage.service';
import { permissionService } from '../../../../services/permissions.service';
import { settingsService } from '../../../../services/settings.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { debugService } from '../../../../services/debug.service';
import { localizationService } from '../../../../services/localization.service';

import { trainingSettings } from '../../../../components/training/training.settings';

@Component({
	selector: 'lio-training-courses',
	templateUrl: './training-courses.component.html'
})
export class LioTrainingCourses implements OnDestroy, DoCheck {
	
			
	@Input() allowLaunch	:boolean 	= false;
	@Input() settings		:any 	= {};

	public appearance		:MatFormFieldAppearance = 'outline';
	public courses			:Array<any> = [];
	public filteredCourses	:Array<any> = [];
	private catalogs		:Array<any> = [];
	private courseTypes		:Array<any> = [];
	private courseType		:any 		= null;
	private course			:any 		= null;
	public panelConfig		:any 		= this.utilService.copy(this.trainingSettings.panelConfig);
	public fields			:Array<any> = this.utilService.copy(this.trainingSettings.fields);

	public search			:string 	= '';

	public localeStrings 	:any 		= {
		search						:'Search',
		searchTrans					:'training.search',
		courseDescription			:'Description',
		courseDescriptionTrans		:'training.courseDescription',
		resendCompletionEmail		:'Resend Course Completion',
		resendCompletionEmailTrans	:'training.resendCompletionEmail',
		failed						:'Failed',
		failedTrans					:'training.failed',
		completed					:'Completed',
		completedTrans				:'training.completed',
		inProgress					:'In Progress',
		inProgressTrans				:'training.inProgress',
		toDo						:'To Do',
		toDoTrans					:'training.toDo',
		notAvailable				:'Not Available Until',
		notAvailableTrans			:'training.notAvailable',
		disabled					:'Disabled',
		disabledTrans				:'training.disabled',
		startCourse					:'Start Course',
		startCourseTrans			:'training.startCourse',
		restartCourse				:'Restart Course',
		restartCourseTrans			:'training.restartCourse',
		resumeCourse				:'Resume Course',
		resumeCourseTrans			:'training.resumeCourse',
	};

	public localeStringsKeys:Array<any>	= [
		'search',
		'courseDescription',
		'resendCompletionEmail',
		'failed',
		'completed',
		'inProgress',
		'toDo',
		'notAvailable',
		'startCourse',
		'restartCourse',
		'resumeCourse',
		'notAvailable'
	];

	constructor(
		@Inject(DOCUMENT) 				private document			:Document,
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(lmsService) 			public lmsService			:lmsService,
		@Inject(lioModalService) 		public lioModalService		:lioModalService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(settingsService) 		public settingsService		:settingsService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(feedbackService) 		public feedbackService		:feedbackService,
		@Inject(lioLogService) 			public lioLogService		:lioLogService,
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(trainingSettings) 		public trainingSettings		:trainingSettings,
	){
		this.debugService.register('trainingCourses', this);
	}

	ngOnDestroy() {
		this.settings.loaded = false;
	}

	ngDoCheck() {
		if (!this.settings.id || this.settings.loaded) {
			return;
		}

		this.settings.loaded = true;
		this.init();
	}

	/*
	 * Initialize
	*/
	init() {
		this.storageService.remove('preview');

		return this.getTraining().then(() => {
			this.navService.displayPage();
		});
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredCourses(paginatorResults) {
		this.filteredCourses = paginatorResults.filtered;
	}

	/**
	 * Sorts events by the selected field
	 */
	sortCourses(sortField){		
		if(this.panelConfig.pagination.sortMode.field == sortField) {
			if(this.panelConfig.pagination.sortMode.direction == 'asc') {
				this.panelConfig.pagination.sortMode.direction = 'desc';
			} else {
				this.panelConfig.pagination.sortMode.direction = 'asc';
			}
		} else {
			this.panelConfig.pagination.sortMode.field = sortField;
		}
	}

	/*
	 * Shows the course description
	*/
	showDescription(courseID) {
		var course = this.getCourseByID(courseID),
			catalog = this.getCatalogByID(courseID);
		if (catalog) {
			catalog.expanded = true;
			this.courseType = catalog['courseType'];
			this.lioModalService.open({'type': 'catalog', 'catalog': catalog, 'courseType': this.courseType, 'width': '65%'});
		} else {
			this.lioModalService.show(course.courseName, course.courseDescription);
		}
	}

	/*
	 * Start the course
	 * @param {string} courseID
	*/
	startCourse(courseID) {
		this.course = this.getCourseByID(courseID);

		this.lioLogService.log(['Course', this.course]);

		let config = this.getPopupConfig();

		if (this.shouldPopout()) {
			// We open a popup here to avoid popup blockers
			this.lioLogService.log(['Opening launcher to prevent pop up blockers', config]);
			let openedWindow = this.document.defaultView.open('launcher/index.html', this.course.courseName, config);
			if (openedWindow && openedWindow.window) {
				this.storageService.set('openedWindow', openedWindow.window);
			}
		}
		this.storageService.set('origin', 'training');
		this.storageService.set('courseID', courseID);
		this.storageService.set('tracking', true);

		if (this.course.failed) {
			this.resetProgress().then(() => {
				this.navService.goto('courselaunch');
			});
		} else {
			this.navService.goto('courselaunch');
		}
	}

	/*
	 * Should we open a popup window to launch the course?
	 * @return {boolean}
	*/
	shouldPopout() {
		let method = this.settingsService.getSetting('launchMethod') ? this.settingsService.getSetting('launchMethod') : 'popout';

		// Course's launch method overrides launch method setting
		if (this.course.launchMethod) {
			method = this.course.launchMethod;
		}

		// If not using the spider launcher, then revert to the legacy method
		if (!this.settingsService.getSetting('spiderLauncher')) {
			method = 'legacy';
		}


		if (method !== 'popout') {
			return false;
		}

		return true;
	}

	/*
	 * Get the pop up dinensions
	 * NOTE: If you change this here, change it also on courselaunch, will revisit making this DRY
	 * @return {string}
	*/
	getPopupConfig() {
		let width		= this.settingsService.getSetting('useCourseDimensions') ? this.course.courseWidth : (this.document.defaultView.screen.width * 90) / 100,
			height		= this.settingsService.getSetting('useCourseDimensions') ? this.course.courseHeight : (this.document.defaultView.screen.height * 85) / 100,
			top			= this.document.defaultView.top.outerHeight / 2 + this.document.defaultView.top.screenY - ( this.document.defaultView.screen.height / 2) + 30,
			left		= this.document.defaultView.top.outerWidth / 2 + this.document.defaultView.top.screenX - ( width / 2),
			config	= '';


		config += 'directories=0, titlebar=0, toolbar=0, status=0, menubar=0, scrollbars=1, location=0, statusbar=0, resizable=1,';
		config += 'width=' + width + ', height=' + height + ', left=' + left + ', top=' + top;

		return config;
	}

	/*
	 * Gets course by courseID
	 * @param {number} courseID
	*/
	getCourseByID(courseID) {
		var foundCourse = null;
		this.courses.forEach((course) => {
			if (course.courseID === courseID) {
				foundCourse = course;
			}
		});
		return foundCourse;
	}

	/*
	 * Gets catalog by courseID
	 * @param {number} courseID
	*/
	getCatalogByID(courseID) {
		var foundCatalog = null;
		this.catalogs.forEach((catalog) => {
			if (catalog.courseID === courseID) {
				foundCatalog = catalog;
			}
		});

		// Map the course type to the catalog
		if (foundCatalog) {
			this.courseTypes.forEach((courseType) => {
				if (foundCatalog.type === courseType.name) {
					foundCatalog['courseType'] = courseType;
				}
			});
		}
		
		return foundCatalog;
	}

	/*
	 * Set the course variables
	*/
	setCourses() {
		this.courses.forEach((course) => {
			var field = 'course.' + course.courseID,
				name = course.courseName,
				localized = this.localizationService.get('course.' + course.courseID),
				catalog;
			
			if (!localized) {
				this.localizationService.addItemToLocalize(field, name);
			}

			course.isFuture = false;
			course.started = false;
			course.completed = false;
			course.optionShown = 'empty';
			if (this.utilService.isFutureDate(course.courseAvailableDate)) {
				course.isFuture = true;
			}

			if (course.courseCompletion == 0 && course.courseStarted == 1) {
				course.started = true;
			}

			if (course.aicc_lesson_status === 'failed') {
				course.failed = true;
			}

			if (course.courseCompletion == 1) {
				course.completed = true;
			}


			// Don't show a due date if its not applicable 
			if (course.courseDueDate == '1900-01-01 00:00:00' || course.courseDueDate == '1900-01-01') {
				course.courseDueDate = '';
			}

			if (!course.courseDescription) {
				catalog = this.getCatalogByID(course.courseID);
				if (catalog) {
					course.courseDescription = catalog.description;
				}
			}

			if(course.completed) {
				if (this.settingsService.getSetting('allowCourseRestart')) {
					course.optionShown = 'restart';
				} else {
					course.optionShown = 'complete';
				}
			} else {
				if (course.isFuture) {
					course.optionShown = 'unavailable';
				} else {
					if (course.started) {
						course.optionShown = 'resume';
					} else {
						course.optionShown = 'start';
					}
				}
			}

			if (course.active == 0) {
				course.optionShown = 'disabled';
			}
		});
	}

	/*
	 * Gets the courses assigned to the user
	 * @return {Promise}
	*/
	getTraining() {
		return this.lmsService.post(this.settings.getTrainingEndPoint, {'langID': this.storageService.getLangID()}).then((result) => {
			var courses = result.properties.courses,
				catalogs = result.properties.catalogs,
				courseTypes = result.properties.courseTypes;

			if (!courses) {
				console.log('this.settings.localeStrings.noCoursesAssigned', this.settings.localeStrings.noCoursesAssigned);
				this.feedbackService.setError(this.settings.localeStrings.noCoursesAssigned);
			} else {
				this.courses = courses;
				this.catalogs = catalogs;
				this.courseTypes = courseTypes;
			}
			this.setCourses();
		});
	}

	/*
	 * Resets the course progress
	 * @return {Promise}
	*/
	resetProgress() {
		return this.lmsService.post('training/resetProgress', {
			courseID			: this.course.courseID, 
			employeeInCourseID	: this.course.employeeInCourseID
		}).then((result) => {
			if (result.success) {
				this.course.failed = false;
				this.course.courseCompletion = 0;
				this.course.courseCompletionDate = null;
				return true;
			}
			return false;
		});
	}

	/*
	 * Sends the course completion email
	 * @param {string} courseID
	*/
	sendCompletionEmail(courseID) {
		this.lioModalService.showLoading();

		return this.lmsService.post('training/sendCompletionEmail', {
			langID	: this.storageService.getLangID(), 
			courseID: courseID
		}).then((result) => {	
			var success = result.success;
			if (success) {
				this.lioModalService.hideLoading();
				this.lioModalService.show('email.sentEmail');
			} else if (!result.lioModal) {
				this.lioModalService.hideLoading();
			}
		});
	}

	/*
	 * Sends the course completion email
	 * @param {string} courseID
	*/
	sendFailedCompletionEmail(courseID) {
		this.lioModalService.showLoading();

		return this.lmsService.post('training/sendFailedCompletionEmail', {
			langID	: this.storageService.getLangID(), 
			courseID: courseID
		}).then((result) => {	
			var success = result.success;
			if (success) {
				this.lioModalService.hideLoading();
				this.lioModalService.show('email.sentEmail');
			} else if (!result.lioModal) {
				this.lioModalService.hideLoading();
			}
		});
	}
}