import { Component, Input, Optional, Inject } from '@angular/core';

import { navService } from '../../../../services/nav.service';

@Component({
	selector: 'lio-card',
	templateUrl: './card.component.html'
})
export class LIOCard {	
	@Input() cardClass	:string 	= '';
	@Input() showClose	:boolean 	= false;
	@Input() headerClass:string		= '';
	@Input() bodyClass	:string 	= '';
	@Input() footerClass:string 	= '';
	@Input() hideErrors	:boolean 	= false;

	private suffixes:{
		card 		: string
		header 		: string,
		subheader 	: string,
		body 		: string,
		footer 		: string,
	} = {
		card 		: '',
		header 		: '_header',
		subheader 	: '_subheader',
		body 		: '_body',
		footer 		: '_footer',
	};

	public localeStrings = {
		headerText 			: '',
		headerTextTrans 	: '',
		subHeaderText 		: '',
		subHeaderTextTrans 	: '',
	};

	get headerText(){
		return this.localeStrings.headerText;
	}
	@Input() set headerText(value:string){
		this.localeStrings.headerText = value;
	}

	get subHeaderText(){
		return this.localeStrings.subHeaderText;
	}
	@Input() set subHeaderText(value:string){
		this.localeStrings.subHeaderText = value;
	}

	get subHeaderTrans(){
		return this.localeStrings.subHeaderTextTrans;
	}
	@Input() set subHeaderTrans(value:string){
		this.localeStrings.subHeaderTextTrans = value;
	}

	get headerTrans(){
		return this.localeStrings.headerTextTrans;
	}
	@Input() set headerTrans(value:string){
		if(value && this._cardId == 'generic'){
			this._cardId = value;
		}
		this.localeStrings.headerTextTrans = value;
	}

	private _cardId:string = 'generic';
	get cardId(){
		let prefix = 'am_';
		if(this._cardId.length >= 3 && this._cardId.slice(0,3) == prefix){
			prefix = '';
		}
		return prefix + this._cardId + this.suffixes.card;
	}
	@Input() set cardId(value:string){
		if(value){
			this._cardId = value;
		}
	}

	private _headerId:string = '';
	get headerId(){
		if(this._headerId){
			return this._headerId;
		}else{
			return this.cardId + this.suffixes.header;
		}
	}
	@Input() set headerId(value:string){
		this._headerId = value;
	}

	private _subHeaderId:string = '';
	get subHeaderId(){
		if(this._subHeaderId){
			return this._subHeaderId;
		}else{
			return this.cardId + this.suffixes.subheader;
		}
	}
	@Input() set subHeaderId(value:string){
		this._subHeaderId = value;
	}

	private _bodyId:string = '';
	get bodyId(){
		if(this._bodyId){
			return this._bodyId;
		}else{
			return this.cardId + this.suffixes.body;
		}
	}
	@Input() set bodyId(value:string){
		this._bodyId = value;
	}

	private _footerId:string = '';
	get footerId(){
		if(this._footerId){
			return this._footerId;
		}else{
			return this.cardId + this.suffixes.footer;
		}
	}
	@Input() set footerId(value:string){
		this._footerId = value;
	}
	
	constructor(@Optional() @Inject(navService) private navService:navService){}

	goback() {
		this.navService.goback();
	}
}