import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import moment from 'moment';

import { NEVER, Subscription, Observable } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { feedbackService } from '../../../../services/feedback.service';
import { filesService } from '../../../../services/files.service';
import { lmsService } from '../../../../services/lms.service';
import { navService } from '../../../../services/nav.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { localizationService } from '../../../../services/localization.service';
import { utilService } from '../../../../services/util.service';
import { eventService } from '../../../../services/event.service';

@Component({
	selector: 'lio-event-history',
	templateUrl: './event-history.component.html',
})
export class LioEventHistory implements OnDestroy, OnInit {
	@Input() includePacEvents	:boolean;
	@Input() appearance			:MatFormFieldAppearance = 'outline';
	@Input() eventService		:eventService;

	private _settings:any = {};
	public get settings(){
		return this._settings;
	}
	@Input() public set settings(val:any){
		this._settings 			= val;
		this.pagination			= this.utilService.copy(this.settings.pagination);
		this.fields				= this.utilService.copy(this.settings.fields);
		this.endPoint			= this.utilService.copy(this.settings.endPoint);
	}

	private endPoint			:string;
	private events				:Array<any>		= [];

	private subscriptions		:Subscription 	= NEVER.subscribe();

	public loadedHistory 		:boolean 		= false;
	public history				:Array<any>		= [];
	public filteredHistory		:Array<any>		= [];
	public pagination			:any;
	public fields				:Array<any>;
	public searchField 			:any 			= {
		name		: 'Enter Search Here',
		nameTrans 	: 'scheduleEmails.searchPlaceholder',
		model 		: 'search',
		search 		: ''
	};

	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(filesService) 			public filesService 		:filesService,
		@Inject(lioModalService) 		public lioModalService 		:lioModalService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(localizationService)	public localizationService	:localizationService,
		@Inject(navService) 			public navService 			:navService,
		@Inject(utilService) 			public utilService 			:utilService
	){
		this.debugService.register('eventHistory', this);
	}

	ngOnInit(){
		this.subscriptions.add(
			this.eventService.eventsSubscription.subscribe((events) => {
				Array.prototype.push.apply(this.events, events);
				this.getEventHistory().then(() => {
					this.navService.displayPage();
				})
			})
		);
		
		this.eventService.getEvents(true);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
	
	/**
	 * Recieves information from paginator
	 */
	updateFilteredHistory(collection){
		this.filteredHistory = collection.filtered;
	}

	/** 
	 * Gets the list of history events
	 */
	getEventHistory() {
		let params = {
			'includePacEvents': this.includePacEvents,
		}, observable = new Observable((subscriber) => {
			if (this.loadedHistory) {
				subscriber.complete();
				return;
			}

			this.lmsService.postAsync(this.endPoint + '/getAllEvents', params).then((result) => {
				if (result.properties.token) {
					this.lmsService.getAsyncResult(result.properties.token, (result) => {
						this.handleHistory(result.properties.events);
						this.loadedHistory = true;
						subscriber.complete();
					});
				} else {
					this.handleHistory(result.properties.events);
					this.loadedHistory = true;
					subscriber.complete();
				}
			});
		});
		return observable.toPromise();
	}

	/** 
	 * Handles event history
	 * @param {array} history
	 */
	handleHistory(history) {
		if (!history || !history.length) {
			this.history = [];
			return;
		}

		this.history = history;

		this.history.forEach((history) => {
			if (history) {
				if (history.endTime) {
					history.endTime = moment(history.endTime).format('MM/DD/YYYY HH:mma');
				}
				if (history.totalProcessed === '0') {
					history.totalProcessed = 0;
				}
				history.fromLearnIO = this.utilService.toBool(history.fromLearnIO);
			}
		});
		this.bindToEvent();
	}

	/** 
	 * Bind the history to events
	 */
	bindToEvent() {
		if (this.events && this.history) {
			this.history.forEach((history) => {
				if (history) {
					this.events.forEach((event) => {
						if (event.id == history.eventID) {
							history.event = event;
						}
					});
				}
			});
		}
	}
}