<div class="row">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber #tabCache (onTabChange)="onTabChange($event)">
			<!-- Search -->
			<lio-panel-tab [settings]="searchPanelConfig">
				<lio-company-admin-search
					(callback)="handleCallback($event)"
					(onLoaded)="loaded()"
					*lioPanelTabContent>
				</lio-company-admin-search>
			</lio-panel-tab>

			<!-- Add form -->
			<lio-panel-tab [settings]="createPanelConfig">
				<lio-company-admin-edit
					action="create"
					(callback)="handleCallback($event)"
					*lioPanelTabContent>
				</lio-company-admin-edit>
			</lio-panel-tab>

			<!-- Edit form -->
			<lio-panel-tab [settings]="editPanelConfig">
				<lio-company-admin-edit
					action="edit"
					(callback)="handleCallback($event)"
					*lioPanelTabContent>
				</lio-company-admin-edit>
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</div>
