<section class="row">
	<div class="col-md-8 mx-auto">
		<!-- Email Form -->
		<lio-card headerText="Localization" [showClose]="true">
			<div lio-card-body>
				<form role="form" autocomplete="off" novalidate>
					<!-- Repeat Fields -->
					<div class="row">
						<div class="col-md-8 text-center mx-auto">
							<lio-searchable-select
								[model]="model"
								[options]="items.options"
								[field]="localizeItemField"
								(onUpdate)="onItemChange()"
								appearance="fill">
							</lio-searchable-select>
						</div>
					</div>
					
					<lio-form-inputs
						[fields]="fields"
						[model]="localization"
						(onChanged)="onupdate()">
					</lio-form-inputs>
				</form>
			</div>
			<div lio-card-footer>
				<button mat-flat-button
					color="primary"
					[disabled]="processingService.processing || !allowSave" 
					(click)="save()" 
					type="submit" 
					[hidden]="!showForm">

					<span>Save Localizations</span>
				</button>
			</div>
		</lio-card>
	</div>
</section>