/*
 * Fields Helper
*/
import { ReplaySubject, Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { storageService } from './storage.service';
import { lmsService } from './lms.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class presetModelService {
	model					:any 		= {};
	options 					:ReplaySubject<any>	= new ReplaySubject(1);
	public modelReady	:Subject<void> = new Subject();
	private params			:any 			= this.storageService.getInitialParams();

	constructor(
		@Inject(lmsService)				private lmsService			:lmsService,
		@Inject(storageService)			private storageService		:storageService,
		@Inject(debugService)			private debugService		:debugService
	){
		this.debugService.register('presetModel', this);

	}

	init() {
		if (this.params && this.params.presetID) {
			this.getPresetByID(this.params.presetID);
		}
	}


	/**
	 * Gets the presets by id
	 * @param {string} id
	 * @return {array}
	 */
	getPresetByID(id) {
		return this.lmsService.post('presets/getByParamID', {'paramID': id}).then((result) => {
			if (!result.success || !result.properties.preset) {
				return;
			}
			let preset = result.properties.preset;
			this.modelReady.next(preset);
		});
	}

	/**
	 * Gets the presets by page
	 * @param {string} page
	 * @return {array}
	 */
	getPresetsByPage(page) {
		let options = [],
			option ={name: 'Create New', value: ''};
		return this.lmsService.post('presets/getByPage', {'page': page}).then((result) => {
			if (!result.success || !result.properties.presets) {
				this.options.next([option])
				return;
			}
			options = result.properties.presets;
			options.unshift(option);
			this.options.next(options);
		});
	}


	
	/**
	 * Gets the presets by param ID
	 * @param {string} paramID
	 * @return {array}
	 */
	getPresetsByParamID(paramID) {
		return this.lmsService.post('presets/getByParamID', {'paramID': paramID}).then((result) => {
			if (!result.success || !result.properties.preset) {
				return null;
			}
			return result.properties.preset;
		});
	}


	/**
 * Deletes the presets by paramID
	 * @param {string} paramID
	 * @return {array}
	 */
	deleteByParamID(paramID) {
		return this.lmsService.post('presets/deleteByParamID', {'paramID': paramID}).then((result) => {
			return result.success;
		});
	}


	/**
	 * Saves the presets
	 * @param {string} page
	 * @return {boolean}
	 */
	savePresets(model) {
		return this.lmsService.post('presets/save', {'model': model}).then((result) => {
			return result.success;
		});
	}


}