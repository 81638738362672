import { Component } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { localizationService } from '../../services/localization.service';
import { permissionService } from '../../services/permissions.service';
import { utilService } from '../../services/util.service';

import { auditLogSettings } from './audit-log.settings';


@Component({
	selector: 'lio-audit-log',
	templateUrl: './audit-log.component.html'
})
export class AuditLogComponent{

	private subscriptions:Subscription 			= NEVER.subscribe();
	public fields:any 							= [];
	public employees:any 						= [];
	public filteredEmployees:any 				= [];
	public searchFields:any 					= [];
	public fieldConfig:any 						= {};
	public searchFieldConfig:any 				= {};
	public searchers:any 						= {};
	public pagination:any 						= {};
	public permissions:any 						= null;
	public allowSubmit:boolean 					= false;
	public errorlog:any 						= null;
	public selectedField:string 				= 'UID';

	constructor(
			@Inject(navService)					public	navService:navService,
			@Inject(debugService)		 		public	debugService:debugService,
			@Inject(feedbackService)			public	feedbackService:feedbackService,
			@Inject(errorsService)				public	errorsService:errorsService,
			@Inject(lmsService)					public	lmsService:lmsService,
			@Inject(lioLogService)				public	lioLogService:lioLogService,
			@Inject(stateService)			 	public	stateService:stateService,
			@Inject(workerService)			 	public	workerService:workerService,
			@Inject(permissionService) 			public	permissionService:permissionService,
			@Inject(utilService)			 	public	utilService:utilService,
			@Inject(localizationService)		public	localizationService:localizationService,
			@Inject(auditLogSettings)	 		public	auditLogSettings:auditLogSettings,
			@Inject(lioModalService)			public	lioModalService:lioModalService)
	{
		this.debugService.register('auditlog', this);
		this.navService.setActivePage('auditlog');
		
		this.fields					= this.utilService.copy(this.auditLogSettings.fields);
		this.searchFields			= this.utilService.copy(this.auditLogSettings.searchFields);
		this.searchFields			= this.permissionService.setFields(this.searchFields);
		this.fieldConfig			= this.utilService.copy(this.auditLogSettings.fieldConfig);
		this.searchFieldConfig		= this.utilService.copy(this.auditLogSettings.searchFieldConfig);
		this.pagination				= this.utilService.copy(this.auditLogSettings.pagination);
		this.subscriptions 			= NEVER.subscribe();
		this.permissions 			= this.permissionService;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}


	init() {
		this.feedbackService.clearErrors();
		this.getMostRecentLogs();
	}

	getMostRecentLogs() {
		this.lmsService.get('auditlog/getMostRecent').then((result) => {
			let auditLogs = result.properties.auditLogs;
			if (auditLogs) {
				this.employees = auditLogs;
			} else if (result.errors.length) {
				this.feedbackService.setErrors(result.errors);
			}
			this.navService.displayPage();
        });
	}

	submit() {
		this.employees = [];
		this.feedbackService.clearErrors();

		this.lmsService.post('auditlog/search', {'searchers': this.searchers}).then((result) => {
			let errors = result.errors,
				auditLogs = result.properties.auditLogs;
				
			if (auditLogs) {
				this.employees = auditLogs;
			} else if (errors.length) {
				this.feedbackService.setErrors(errors);
			} else {
				this.feedbackService.setError('noLogsFound');
			}
        });
	}

	exportRecords() {
		if (!this.workerService.export(this.employees, this.fields, 'Audit Logs')) {
			this.lioModalService.show('Failed to export Audit Logs');
		}
	}

	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	onUpdate() {
		let hasValue = false;
		for (let key in this.searchers) {
			let searcher = this.searchers[key];
			if (searcher) {
				this.searchers[key] = this.searchers[key].trim();
				hasValue = true;
			}
		}
		this.allowSubmit = hasValue;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}


}
