/*
 * Service for Controlling errors/message feedback
*/
import moment from 'moment';
import 'moment-timezone';

import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { coursesService } from './courses.service';
import { workerService } from './worker.service';
import { permissionService } from './permissions.service';

@Injectable({
	providedIn: 'root',
})
export class exportService {

	constructor(
		@Inject(lioLogService)			private lioLogService		: lioLogService,
		@Inject(workerService)			private workerService		: workerService,
		@Inject(coursesService)			private coursesService		: coursesService,
		@Inject(permissionService)		private permissionService	: permissionService
	) {}


	exportReport(records, fields, name) {
		this.lioLogService.log(['exporting records', records, fields, name]);
		records.forEach((record) => {
			let role 			= this.permissionService.getRoleNameFromID(record.permissionID),
				status 			= record.inactive == 1 ? 'Inactive' : 'Active',
				required		= record.required == '1' && record.courseDueDate,
				completed 		= record.courseCompletion == '1',
				courseName 		= this.coursesService.localize(record.courseName, record.courseID);

			record.status = status;
			record.role = role, 		
			record.courseName = courseName;

			// If the course is not required we do not need to determnine late/on time
			if (!required) {
				record.courseDueDate = null;
				return;
			}

			if (completed) {
				// Check if they completed on time
				if (record.courseDueDate && record.courseCompletionDate) {
					let dueDate = moment(record.courseDueDate).format('Y-MM-DD'),
						completionDate = moment(record.courseCompletionDate).format('Y-MM-DD'),
						completedOnTime = moment(dueDate).isSameOrAfter(completionDate),
						completedLate = !completedOnTime;

					record.completedOnTime = completedOnTime;
					record.completedLate = completedLate;
				}
			} else {
				// Check if they are past due
				if (record.courseDueDate) {
					let dueDate = moment(record.courseDueDate).format('Y-MM-DD'),
						currentDate = moment().format('Y-MM-DD'),
						pastDue = moment(currentDate).isAfter(dueDate);
					record.pastDue = pastDue;
				}
			}
		});
		
		return this.workerService.export(records, fields, name);
	}

	
}