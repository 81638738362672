import { AngularMaterialModule } from '../../imports/angular-material';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LioPipesModule } from '../../pipes/pipes.module';

import { LioDirectivesModule } from '../../directives/directives.module';
import { LioDataDisplayModule } from '../data-display/data-display.module';
import { LioFormsModule } from '../lio-forms/lio-forms.module';
import { LioStructuralModule } from '../structural/structural.module';

import { CatalogAdminEditComponent } from './components/catalog-admin-edit/catalog-admin-edit.component';
export { CatalogAdminEditComponent } from './components/catalog-admin-edit/catalog-admin-edit.component';

import { CatalogAdminAddComponent } from './components/catalog-admin-add/catalog-admin-add.component';
export { CatalogAdminAddComponent } from './components/catalog-admin-add/catalog-admin-add.component';

import { CatalogAdminSearchComponent } from './components/catalog-admin-search/catalog-admin-search.component';
export { CatalogAdminSearchComponent } from './components/catalog-admin-search/catalog-admin-search.component';

import { CatalogAdminBulkComponent } from './components/catalog-admin-bulk/catalog-admin-bulk.component';
export { CatalogAdminBulkComponent } from './components/catalog-admin-bulk/catalog-admin-bulk.component';

import { CatalogAdminTopicsComponent } from './components/catalog-admin-topics/catalog-admin-topics.component';
export { CatalogAdminTopicsComponent } from './components/catalog-admin-topics/catalog-admin-topics.component';

const monacoConfig: NgxMonacoEditorConfig = {
	baseUrl: './assets', // configure base path cotaining monaco-editor directory after build default: './assets'
	defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
	onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
	exports: [
		CatalogAdminEditComponent,
		CatalogAdminAddComponent,
		CatalogAdminSearchComponent,
		CatalogAdminBulkComponent,
		CatalogAdminTopicsComponent
	],
	declarations: [
		CatalogAdminEditComponent,
		CatalogAdminAddComponent,
		CatalogAdminSearchComponent,
		CatalogAdminBulkComponent,
		CatalogAdminTopicsComponent
	],
	bootstrap: [
		CatalogAdminEditComponent,
		CatalogAdminAddComponent,
		CatalogAdminSearchComponent,
		CatalogAdminBulkComponent,
		CatalogAdminTopicsComponent
	],
	imports: [
		MonacoEditorModule.forRoot(monacoConfig),
		FormsModule,
		AngularMaterialModule,
		FontAwesomeModule,
		AngularEditorModule,
		CommonModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioFormsModule,
		LioStructuralModule,
		LioPipesModule
	]
})
export class LioCatalogAdminModule {}