/*
 * Service for functions related to email events
*/
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { lmsService } from './lms.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';
import { lioModalService } from './lio-modal.service';
import { feedbackService } from './feedback.service';
import { formValidatorService } from './form-validator.service';
import { eventService } from './event.service';
import { storageService } from './storage.service';
import { debugService } from './debug.service';
import { exportService } from './export.service';
import { fieldService } from './fields.service';
import { navService } from './nav.service';
import { coursesService } from './courses.service';
import { filesService } from './files.service';

@Injectable({
	providedIn: 'root',
})
export class emailEventService extends eventService {
	public override endPoint 			:string = 'emailevents';
	public override historyEndPoint 		:string = 'emaileventhistory';

	private allowedFilterValues :Array<any> = [
		'fn.daysuntilcoursedue',
		'fn.dayspastdue'
	];

	private templatePreviewFields:Array<any> = [
		{
			name	: 'name',
			trans	: 'eventSchedule.name',
			model	: 'name'
		}, 
		{
			name	: 'type',
			trans	: 'eventSchedule.type',
			model	: 'type'
		}, 
		{
			name	: 'subject',
			trans	: 'eventSchedule.subject',
			model	: 'subject'
		}, 
		{
			name	: 'language',
			trans	: 'eventSchedule.language',
			model	: 'language'
		}, 
		{
			name	: 'total',
			trans	: 'eventSchedule.total',
			model	: 'total'
		}
	];



	private coursePreviewFields:Array<any> = [
		{
			name	: 'courseID',
			trans	: 'eventSchedule.courseID',
			model	: 'courseID'
		},
		{
			name	: 'name',
			trans	: 'eventSchedule.name',
			model	: 'courseName'
		},  
	];

	private mailTemplates			:Array<any> 		= [];

	constructor(
		@Inject(lioLogService)			public override lioLogService 			:lioLogService,
		@Inject(lmsService)				public override lmsService				:lmsService,
		@Inject(localizationService)	public override localizationService		:localizationService,
		@Inject(lioModalService)		public override lioModalService			:lioModalService,
		@Inject(feedbackService)		public override feedbackService			:feedbackService,
		@Inject(utilService)			public override utilService 			:utilService,
		@Inject(storageService)			public override storageService 			:storageService,
		@Inject(formValidatorService)	public override formValidatorService 	:formValidatorService, 
		@Inject(debugService)			public override debugService 			:debugService,
		@Inject(fieldService)			public override fieldService			:fieldService,
		@Inject(exportService)			public override exportService			:exportService,
		@Inject(navService)				public override navService				:navService,
		@Inject(filesService)			public override filesService			:filesService,
		@Inject(coursesService)			public coursesService			:coursesService,
	){
		super(
			lioLogService, 
			lmsService, 
			localizationService, 
			lioModalService, 
			feedbackService, 
			utilService, 
			storageService, 
			formValidatorService, 
			debugService, 
			exportService, 
			fieldService,
			navService,
			filesService
		);
	}


	/** 
	 * Validate an event
	 * @return {boolean}
	 * @override
	 */
	public override validateEvent(event) {
		if (!super.validateEvent(event)) {
			return false;
		}

		if (!event.actions.length) {
			this.feedbackService.setErrors(['Please select a template']);
			return false;
		}

		let filterType = this.settings.filterTemplates[event.type];
		if (filterType) {
			filterType.value = parseInt(event.filterValue);
			if (!filterType.value && filterType.value !== 0) {
				this.feedbackService.setErrors([filterType.nanError]);
				return false;
			}
			else if (filterType.value < filterType.min) {
				this.feedbackService.setErrors([filterType.minError]);
				return false;
			} else if (filterType.value > filterType.max) {
				this.feedbackService.setErrors([filterType.maxError]);
				return false;
			}
		}

		this.feedbackService.clearErrors();

		return true;
	}


	/** 
	 * Determines if there is enough info to get the user information
	 * @return {boolean}
	 * @override
	 */
	canGetUserInfo(event):boolean {
		return event.type && event.courseID && event.actions.length;
	}

	/** 
	 * Preps the event
	 * @override
	 */
	override prepEvent(event) {
		let eventCopy = super.prepEvent(event)

		let filterModel 	= this.settings.filterTemplates[eventCopy.type],
			actions 		= [],
			filters 		= eventCopy.tempRules || eventCopy.rules;


		if (eventCopy.filterValue && filterModel) {
			filters.push({
				'field': filterModel.field,
				'type': filterModel.type,
				'operator': filterModel.operator,
				'value': eventCopy.filterValue,
			});
		}

		eventCopy.actions.forEach((template) => {
			actions.push({id : template.id, type : 'email'});
		});

		eventCopy.actions = actions;
		eventCopy.filters = filters;

		if (eventCopy.tempRules) {
			event.rules = eventCopy.tempRules;
		}

		return eventCopy;
	}

	/** 
	 * Gets the list of active and inactive events
	 */
	override getEvents(includePacEvents) {
		super.getEvents(includePacEvents);
		this.getEmailTemplates().subscribe(() => {
			this.lioModalService.hideLoading();
		});
	}


	/** 
	 * Handles the event
	 * @override
	 */
	override handleEvent(event) {
		event = super.handleEvent(event);
		let rules = event.rules || [],
			filters = this.utilService.copy(rules);

		event.rules = [];

		// Format the filters
		filters.forEach((filter) => {
			if (this.allowedFilterValues.indexOf(filter.field) > -1) {
				event.filterValue = filter.value;
			} else {
				event.rules.push(filter);
			}
		});

		return event;
	}

	/*
	 * Handles managed event redirects
	*/
	override handleManagedEvent(managedEvent) {
		let matchedEvent 	= null,
			courseID 		= [];

		if (managedEvent) {
			if (managedEvent.courseID && managedEvent.courseID.indexOf(',') > -1) {
				courseID = managedEvent.courseID.split(',');
			} else if (managedEvent.courseID) {
				courseID = [managedEvent.courseID];
			}
			this.events.forEach((event) => {
				if (courseID.indexOf(event.courseID) > -1 && event.type === 'Registration') {
					// Set the event to the existing email event
					matchedEvent = event;
					matchedEvent.courseID = courseID;
					if (event.active) {
						this.callback.next({'name': 'changeTab', 'value': 'list'});
						return;
					}
				}
			});

			if (!matchedEvent) {
				matchedEvent = {
					id: null,
					name: managedEvent.name,
					description: managedEvent.description,
					type: 'Registration',
					eventType: 'email',
					courseID: courseID,
					actions: [],
					active: false,
					archived: false,
					filterValue: 0,
					filters: [],
					rules: [],
					schedule: managedEvent.schedule,
				};
			}
		}

		console.log('matchedEvent', matchedEvent, matchedEvent.courseID);

		return this.handleEvent(matchedEvent);
	}

	/**
	 * Gets the filter title
	 * @param {array} event
	 * @return {object}
	 */
	override getFilterTitle() {
		let title 	= this.localizationService.get('emailEvent.usersWho', 'Applies to users');
		return title;
	}

	/**
	 * turns event filter data into friendlier descriptions
	 * @param {array} event
	 * @return {array}
	 * @overide
	 */
	override getFilterDescription(event):Array<any> {
		console.log(event);
		let descriptions = [];
		switch (event.type) {
			case 'Past Due':
				descriptions.push({
					value				: 'Are marked active', 
					trans	 			: 'emailEvent.isActive',
					macros 				: null,
				});
				descriptions.push({
					value				: 'Have not completed course ', 
					trans	 			: 'emailEvent.notComplete', 
					macros 				: [{key: 'courseID', value: event.courseID}],
				});
				descriptions.push({
					value				: '{{filterVal}} days or more past their due date', 
					trans	 			: 'emailEvent.pastDueDate',
					macros 				: [{key: 'filterVal', value: event.filterValue}],

				});
				descriptions.push({
					value				: 'Were not self enrolled', 
					trans	 			: 'emailEvent.notSelfEnrolled',
					macros 				: null,
				});

				break;
			case 'Reminder':
				descriptions.push({
					value				: 'Are marked active', 
					trans	 			: 'emailEvent.isActive',
					macros 				: null,
				});
				descriptions.push({
					value				: 'Have not completed course {{courseID}}', 
					trans	 			: 'emailEvent.notComplete', 
					macros 				: [{key: 'courseID', value: event.courseID}],
				});
				descriptions.push({
					value				: '{{filterVal}} days or less until their due date', 
					trans	 			: 'emailEvent.dueSoon',
					macros 				: [{key: 'filterVal', value: event.filterValue}],
				});
				descriptions.push({
					value				: 'Are not currently past their due date', 
					trans	 			: 'emailEvent.notPastDue',
					macros 				: null,
				});
				descriptions.push({
					value				: 'Were not self enrolled', 
					trans	 			: 'emailEvent.notSelfEnrolled',
					macros 				: null,
				});

				break;
			case 'Registration':
				descriptions.push({
					value				: 'Are marked active', 
					trans	 			: 'emailEvent.isActive',
					macros 				: null,
				});
				descriptions.push({
					value				: 'Have not completed course {{courseID}}', 
					trans	 			: 'emailEvent.notComplete', 
					macros 				: [{key: 'courseID', value: event.courseID}],
				});
				descriptions.push({
					value				: 'Have been registered for course {{courseID}}', 
					trans	 			: 'emailEvent.isRegistered',
					macros 				: [{key: 'courseID', value: event.courseID}],
				});
				descriptions.push({
					value				: 'Have not been sent a registration email for course {{courseID}}', 
					trans	 			: 'emailEvent.notEmailed',
					macros 				: [{key: 'courseID', value: event.courseID}],
				});
				descriptions.push({
					value				: 'Were not self enrolled', 
					trans	 			: 'emailEvent.notSelfEnrolled',
				});

				break;
			default:
				this.lioLogService.log('The event type "' + event.type + '" does not have a description');
		}

		event.rules.forEach((rule) => {
			let field = rule.field.split('.')[1],
				operator = rule.operator,
				value = rule.value;

			descriptions.push({value: field + ' ' + operator + ' ' + value});
		})
		
		return descriptions;
	}



	/**
	 * Gets a list of the email templates used in an event
	 */
	getTemplates(event, mailTemplates) {
		let data = [];

		event.actions.forEach((action) => {
			mailTemplates.forEach((template) => {
				if (template.id == action.templateID) {
					data.push({
						name 		: template.name, 
						type 		: template.type, 
						subject 	: template.subject, 
						language 	: template.langID,
						total		: action.totalUsers
					});
				}
			});
		});
		return data;
	}

	/** 
	 * Shows a table lioModal containing a list of email templates an event uses
	 * @param {object} event An event object
	 */
	showTemplates(event) {
		this.lioModalService.showLoading('loading');
		this.getEmailTemplates().subscribe(() => {
			this.showTotalUsers(event, true).then((event) => {
				let models 		= this.getTemplates(event, this.mailTemplates),
					warning		= this.getTemplateWarning(event);

				this.lioModalService.hideLoading();

				this.lioModalService.open({
					'type': 'table', 
					'subtitle': warning.description, 
					'macros': warning.macros,
					'title': 'eventSchedule.templatesModalTitle', 
					'fields': this.templatePreviewFields, 
					'models': models
				})
			});
		});
	}


	/** 
	 * Shows a table lioModal containing a list of course ids used
	 * @param {object} event An event object
	 */
	showCourses(event) {
		let models 		= this.coursesService.rawCourses,
			courseIDs = typeof event.courseID === 'string' ? event.courseID.split(',') : event.courseID,
			courses = [];

		models.forEach((model) => {
			if (this.utilService.valueinArray(courseIDs, model.courseID)) {
				courses.push(model);
			}
		})

		this.lioModalService.hideLoading();

		this.lioModalService.open({
			'type': 'table', 
			'subtitle': 'courseIDs', 
			'title': 'eventSchedule.courses', 
			'fields': this.coursePreviewFields, 
			'models': courses,
		})
	}
	
	/** 
	 * Get email templates
	 */
	getEmailTemplates() {
		return new Observable<Array<any>>((subscriber) => {
			if (this.mailTemplates.length) {
				subscriber.next(this.mailTemplates);
				subscriber.complete();
				return;
			}
			this.lioModalService.showLoading('loading');

			this.lmsService.postAsync('emailtemplate/getAll').then((result) => {
				let token = result.properties.token;
				if (!token) {
					if (result.properties.values) {
						this.mailTemplates = result.properties.values;
						subscriber.next(result.properties.values);
						subscriber.complete();
					}
				} else {
					this.lmsService.getAsyncResult(token, (gresult) => {
						if (gresult.properties.values) {
							this.mailTemplates = gresult.properties.values;
							subscriber.next(gresult.properties.values);
							subscriber.complete();
						}
					});
				}
			});
		});
	}
}
