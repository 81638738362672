<div *ngIf="!refreshing">
	<div class="row">
		<div class="col-12 col-lg-8 mx-auto">
			<!-- Forgot form -->
			<lio-card body-class="p-0" role="form" autocomplete="off" novalidate>
				<div lio-card-header id="am_ccfAdmin_header">
					<button mat-button (click)="navService.goback()" class="float-right">
						<mat-icon class="position-absolute">close</mat-icon>
					</button>
					<h4 *ngIf="differentEmployee">Admin Rights For {{employeeID}}</h4>
					<h4 *ngIf="!differentEmployee">{{localeStrings.header}}</h4>
					<div>{{localeStrings.subHeader}}</div>
				</div>
				<div lio-card-body>
					<div *ngIf="suppressCCF" class="list-group-item">
						<p class="center alert-info">{{localeStrings.suppresingCCFNote}}</p>
					</div>
					<!-- Inputs -->
					<mat-accordion>
						<mat-expansion-panel *ngFor="let group of groups" [hidden]="!group.visible">
							<mat-expansion-panel-header>
								<mat-panel-title>{{group.name}}</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="overflow-x-auto mx-n4">
								<table class="table table-striped table-md text-center small mb-0">
									<thead>
										<tr>
											<th id="am_ccfadmin_name">Name</th>
											<th id="am_ccfadmin_value">Value</th>
											<th id="am_ccfadmin_global">Administer All</th>
											<th id="option">Add Option</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let field of group.fields" 
											[hidden]="!field.visible" 
											localize 
											[model]="field" 
											item="name" 
											[itemTag]="'form.' + field.model">

											<td class="label.{{field.type}} align-middle">
												<span>{{field.name}}</span>
												<tooltip *ngIf="field.tooltip" [tooltipField]="field"></tooltip>
											</td>
											<td class="align-middle">
												<lio-searchable-select
													[disabled]="model[field.model].wildCard"
													[model]="model[field.model]"
													[options]="field.options"
													[multiple]="true"
													[field]="optionsField"
													(onUpdate)="onupdate()"
													appearance="outline">
												</lio-searchable-select>
											</td>
											<td class="align-middle">
												<mat-slide-toggle [(ngModel)]="model[field.model].wildCard" (change)="onupdate()"></mat-slide-toggle>
											</td>
											<td class="align-middle">
												<mat-form-field appearance="outline">
													<input matInput [(ngModel)]="field.customOption">
													<button matSuffix mat-icon-button color="primary" (click)="addOption(field)">
														<mat-icon>add</mat-icon>
													</button>
												</mat-form-field>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<div lio-card-footer>
					<button id="am_ccfAdmin_update" 
						mat-flat-button
						color="primary"
						[disabled]="!allowSubmit" 
						(click)="submit()" 
						type="submit">{{localeStrings.update}}</button>
				</div>
			</lio-card>
		</div>
	</div>
</div>
