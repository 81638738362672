<div lio-paginator 
	controlID="catalogs" 
	[collection]="catalogs" 
	[filters]="filters" 
	[settings]="pagination" 
	(onResults)="updateFilteredCatalogs($event)">
	<section id="catalogs" *ngFor="let catalog of filteredCatalogs; index as i">
		<!-- Expand with HR -->
		<div [id]="'am_catalog_entry_'+ i" class="list-group-item" [ngClass]="{'bg-light' : i % 2 == 0}">
			<div (click)="clickedCatalog.emit(catalog)">
				<button class="button button-hide float-left" *ngIf="!catalog.missingCatalog">
					<mat-icon aria-expanded="false" [id]="'am_catalog_entry_'+ i +'_expand'" *ngIf="!catalog.expanded">add_box</mat-icon>
					<mat-icon aria-expanded="true" [id]="'am_catalog_entry_'+ i +'_collapse'" *ngIf="catalog.expanded">indeterminate_check_box</mat-icon>
				</button>
			</div>

			<lio-catalog-collapsed 
				[indexInList]="i"
				[catalog]="catalog" 
				[view]="view" 
				[fieldsObject]="fieldsObject" 
				[filters]="filters"
				(clickedCatalog)="clickedCatalog.emit($event)"
				(clickedPrint)="clickedPrint.emit($event)"
				(previewCourse)="previewCourse.emit($event)" 
				(selfEnroll)="selfEnroll.emit($event)">
			</lio-catalog-collapsed>
			<lio-catalog-expanded 
				[indexInList]="i"
				[catalog]="catalog" 
				[view]="view" 
				[fieldsObject]="fieldsObject" 
				[filters]="filters"
				(clickedCatalog)="clickedCatalog.emit($event)"
				(selectModule)="selectModule.emit($event)"
				(previewModule)="previewModule.emit($event)"
				(clickedPrint)="clickedPrint.emit($event)"
				(previewCourse)="previewCourse.emit($event)"
				(selfEnroll)="selfEnroll.emit($event)"
				(clickedOnScript)="clickedOnScript.emit($event)"
				(clickedOnInfoSheet)="clickedOnInfoSheet.emit($event)"
				(clickedOnRequest)="clickedOnRequest.emit($event)">
			</lio-catalog-expanded>
		</div>
	</section>
</div>
<div class="mt-2">
	<lio-paginator-controls controlID="catalogs"></lio-paginator-controls>
</div>