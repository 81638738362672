<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col">
		<!-- Search users form -->
		<lio-card headerTrans="globalsearch.title" headerText="Global Search" [showClose]="true">
			<div lio-card-body>
				<h5 class="filters">{{localeStrings.filters}}</h5>
				<lio-query-tool 
					[filters]="filters" 
					[controlSettings]="queryToolSettings" 
					(results)="loadEmployees($event)">
				</lio-query-tool>
			</div>
		</lio-card>
		
		<!-- SEARCH FORM -->	
		<div [hidden]="!filteredEmployees.length">
			<lio-card headerClass="pb-0" bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
				<div lio-card-header>
					<lio-result-filters
						[searchConfig]="searchConfig"
						[search]="search"
						[searchFields]="searches">
					</lio-result-filters>
				</div>
				<div lio-card-body 
					lio-paginator 
					controlID="globalsearch" 
					[collection]="employees" 
					[filters]="search" 
					[settings]="pagination" 
					(onResults)="updateFilteredEmployees($event)">

					<lio-results-table
						paginatorID="globalsearch"
						[fields]="fields"
						[results]="filteredEmployees"
						[fieldConfig]="fieldConfig"
						[suffixButtons]="globalsearchSuffixButtons">
					</lio-results-table>
				</div>
				<div lio-card-footer>
					<lio-paginator-controls class="row" controlID="globalsearch"></lio-paginator-controls>
					<div class="btn-toolbar">
						<button mat-flat-button
							color="primary"
							class="mr-1 mb-1" 
							id="am_employeesearch_editquery" 
							type="button" 
							(click)="editQuery()">
							
							<span>{{localeStrings.editQuery}}</span>
						</button>
						<button mat-flat-button
							color="primary"
							class="mr-1 mb-1" 
							id="am_employeesearch_exportrecords" 
							type="button" 
							[hidden]="!permissionService.hasPermission('reports.export')" 
							[disabled]="!filteredEmployees.length" 
							(click)="exportRecords()">
							
							<span>{{localeStrings.exportRecords}}</span>
							<mat-icon>file_download</mat-icon>
						</button>
					</div>
				</div>
			</lio-card>
		</div>
	</div>
</div>	