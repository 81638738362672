<div class="row rtl-direction">
	<lio-panel-tabber class="col-sm-12">
		<lio-panel-tab [settings]="managePanelConfig">
			<div *lioPanelTabContent class="container-fluid p-0">
				<div class="row">
					<lio-card-button *ngFor="let card of manageCards"
						[model]="card"
						(click)="card.onClick()">
					</lio-card-button>
				</div>					
			</div>
		</lio-panel-tab>
		<lio-panel-tab [settings]="settingsPanelConfig">
			<div *lioPanelTabContent class="container-fluid p-0">
				<div class="row">
					<lio-card-button *ngFor="let card of settingsCards"
						[model]="card"
						(click)="card.onClick()">
					</lio-card-button>
				</div>					
			</div>
		</lio-panel-tab>
		<lio-panel-tab [settings]="logsPanelConfig">
			<div *lioPanelTabContent class="container-fluid p-0">
				<div class="row">
					<lio-card-button *ngFor="let card of logsCards"
						[model]="card"
						(click)="card.onClick()">
					</lio-card-button>
				</div>					
			</div>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>