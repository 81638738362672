<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<lio-panel-tabber class="col-sm-12 col-md-10 mx-auto">
		<!-- Employee Search Panel -->
		<lio-panel-tab [settings]="panelConfig">
			<ng-container *lioPanelTabContent>
				<lio-search-employees
					[fields]="fields"
					[fieldConfig]="fieldConfig"
					[settings]="settings"
					(onResults)="recieveResults($event)"
					[allowToggleInactive]="true">
				</lio-search-employees>
				<div class="mx-n1 mt-2">
					<div lio-paginator 
						class="mx-n3" 
						controlID="searchEmployees" 
						[collection]="employees" 
						[settings]="pagination" 
						(onResults)="updateFilteredEmployees($event)">

						<lio-results-table
							paginatorID="searchEmployees"
							[fields]="fields"
							[results]="filteredEmployees"
							[fieldConfig]="fieldConfig"
							[prefixButtons]="resultPrefixButtons">
						</lio-results-table>
					</div>
				</div>
				<div class="container-fluid mt-2">
					<lio-paginator-controls class="row" controlID="searchEmployees"></lio-paginator-controls>
				</div>
				<!-- Buttons -->
				<div class="btn-toolbar">
					<button id="am_employeesearch_assigncourses" 
						*ngIf="employees.length && permissionService.hasPermission('enrollment.enroll')" 
						class="btn btn-sm btn-primary" 
						type="button" 
						(click)="assignCourses()">

						<span>{{localeStrings.assigncourses}}</span>
					</button>
				</div>
			</ng-container>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>