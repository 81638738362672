import { Injectable, Inject } from '@angular/core';

import { coursesService } from '../../services/courses.service';

import { querySettings } from '../../settings/query.settings';

import { LioFieldArray, LioSearchableSelectField, FilterOptionField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: dashboardSettings
})
export class dashboardSettings {

	constructor(
		@Inject(coursesService)	private coursesService	:coursesService,
		@Inject(querySettings)	private querySettings	:querySettings
	){}

	localizations: any = {
		header 				: 'dashboard.header',
		subHeader			: 'dashboard.subheader',
		closeFilters  		: 'dashboard.closefilters',
		filters				: 'dashboard.filters',
		expiredNote 		: 'dashboard.showExpiredCoursesNote',
		totalUsers 			: 'dashboard.totalUsers',
		activeInactive 		: 'dashboard.activeInactive',
		totalCourses 		: 'dashboard.totalCourses',
		started 			: 'dashboard.started',
		totalEnrollments	: 'dashboard.totalEnrollments', 
		completed  			: 'dashboard.completed', 
		totalLogins 		: 'dashboard.totalLogins',
		totalActiveLogins	: 'dashboard.totalActiveLogins',
		completionHistory	: 'dashboard.completionHistory',
		thisWeek			: 'dashboard.thisWeek',
		lastWeek			: 'dashboard.lastWeek',
		thisMonth			: 'dashboard.thisMonth',
		lastMonth			: 'dashboard.lastMonth',
		exportrecords 		: 'dashboard.exportrecords',
		emailusers			: 'dashboard.emailusers',
		emailrecords		: 'dashboard.emailrecords',
		assignCourses		: 'global.assigncourses',
		editQuery			: 'global.editquery',
		exportRecords		: 'global.exportrecords',
		exportPDF			: 'global.exportPDF',
		emailUsers			: 'global.emailusers',
		emailRecords		: 'global.emailrecords',
	};

	queryToolSettings:any = {
		'type'					: 'dashboard',
		'destroyResult' 		: false,
		'addBaseFilters'		: true,
		'addCCFtoFilters'		: true,
		'addLangFilter'			: true,
		'addRoleFilter'			: true,
		'showSaver'				: true,
		'usesQueryTool'			: true,
		'hasButtons'			: true,
		'filterOverrideID' 		: 'dashboardFilters',
		'text'				: {
			'runReport'			: 'dashboard.create',
		},
		'endpoints'				: {
			'get'					: 'query/getAllByType',
			'save'					: 'query/save',
			'delete'				: 'query/deleteByID',
			'report'				: 'dashboard/getEmployeesByRules',
		},
	};

	queryToolNote:any = {
		text		:'*** To see expired courses, select the "Show Expired Courses" filter',
		textTrans	:'dashboard.showExpiredCoursesNote'
	};

	pagination:any = {
		'pageLimit'	: '5',
		'pageLimits': ['5', '10', '50', '100'],
		'sortMode'	: {field : 'inactive', direction : 'asc'}
	};

	fieldConfig:any = {
		'fieldName'			: 'dashboard',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addActiveField'	: true,
		'addLangField'		: true,
		'ccfFields': {
		},
		'roleField': {
			'viewAuthority': 'CREATE',
			'lockedAuthority': 'VIEW'
		}
	};


	searchConfig:any = {
		'fieldName'			: 'dashboardSearch',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'ccfFields': {
		},
		'roleField': {
			'viewAuthority': 'CREATE',
			'lockedAuthority': 'VIEW'
		}
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			'alias': 'e',
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Course ID',
			'model': 'courseID',
			'type': 'text',
			'invertModel': false,
			'disableOverride': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Course Name',
			'model': 'courseName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'e-mail',
			'model': 'email',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'State',
			'model': 'state',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'Country',
			'model': 'country',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
			'visible': false,
		},
		{
			'alias': 'ec',
			'name': 'Course Due Date',
			'model': 'courseDueDate',
			'type': 'date',
			'visible' : true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Course Completed',
			'model': 'courseCompletion',
			'type': 'checkbox',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Completion Date',
			'model': 'courseCompletionDate',
			'type': 'date',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'selfEnrolled',
			'model': 'selfEnrolled',
			'type': 'checkbox',
			'visible': 'filters.selfEnroll',
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Required',
			'model': 'required',
			'type': 'checkbox',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Completed On Time',
			'model': 'completedOnTime',
			'type': 'checkbox',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Completed Late',
			'model': 'completedLate',
			'type': 'checkbox',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'ec',
			'name': 'Past Due',
			'model': 'pastDue',
			'type': 'checkbox',
			'visible': true,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'Date Added',
			'model': 'addDate',
			'type': 'date',
			'visible': false,
			'sortable': true,
		},
		{
			'alias': 'e',
			'name': 'Date Modified',
			'model': 'updated',
			'type': 'date',
			'visible': false,
			'sortable': true,
		},
		{
			'alias': 'e',
			'name': 'Admin ID',
			'model': 'AdminID',
			'type': 'number',
			'visible': false,
			'sortable': true,
		},
		{
			'alias': 'e',
			'name': 'Added By',
			'model': 'addedBy',
			'type': 'text',
			'visible':  false,
			'sortable': true,
		},
		{
			'alias': 'ec',
			'name': 'Course Sign-Up Date',
			'model': 'courseSignUpDate',
			'type': 'date',
			'visible': false,
			'sortable' : true,
			'export' : true
		},
		{
			'alias': 'ec',
			'name': 'Course Available Date',
			'model': 'courseAvailableDate',
			'type': 'date',
			'visible': false,
			'sortable' : true,
			'export' : true
		},
		{
			'alias': 'ec',
			'name': 'Course Started',
			'model': 'courseStarted',
			'type': 'boolean',
			'visible': false,
			'sortable' : true,
		},
	);

	filters:LioFieldArray = new LioFieldArray(
		{
			'field': 'ec.courseSignUpDate',
			'nameTrans': 'form.courseSignUpDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseAvailableDate',
			'nameTrans': 'form.availableDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseDueDate',
			'nameTrans': 'form.courseDueDate',
			'type': 'date',
			'visible': true,
		},
		{
			'field': 'ec.courseExpires',
			'nameTrans': 'form.courseExpirationDate',
			'type': 'date',
			'visible': true,
		},
		new FilterOptionField({
			'field': 'ec.courseCompletion',
			'nameTrans': 'form.courseCompleted',
			'type': 'select',
			'visible': true,
			'options': [
				{
					'value': 1,
					'name': 'Completed',
					'nameTrans': 'form.completed'
				},
				{
					'value': 0,
					'name': 'Incomplete',
					'nameTrans': 'form.incomplete'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	
		}),
		new FilterOptionField({
			'field': 'fn.enrolled',
			'nameTrans': 'form.enrolled',
			'type': 'select',
			'visible': true,
			'multiple': true,
			'operators': [this.querySettings.operatorOptions.in],
			'disableOptionOverride' : true,
			'searchable': true,
			'options': this.coursesService.courses,
			'optionNameField': 'nameID',
			'optionValueField':'courseID'
		}),
		new FilterOptionField({
			'field': 'fn.notEnrolled',
			'nameTrans': 'form.notEnrolled',
			'type': 'select',
			'visible': true,
			'multiple': true,
			'operators': [this.querySettings.operatorOptions.in],
			'disableOptionOverride' : true,
			'searchable': true,
			'options': this.coursesService.courses,
			'optionNameField': 'nameID',
			'optionValueField':'courseID'
		}),
		new FilterOptionField({
			'field': 'fn.allowExpired',
			'nameTrans': 'form.showExpired',
			'type': 'select',
			'visible': true,
			'options': [
				{
					'value': 1,
					'name': 'True',
					'nameTrans': 'form.true'
				},
				{
					'value': 0,
					'name': 'False',
					'nameTrans': 'form.false'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	
		}),
		new FilterOptionField({
			'field': 'fn.completedOnTime',
			'nameTrans': 'form.completedOnTime',
			'type': 'select',
			'visible': true,
			'options': [
				{
					'value': 1,
					'name': 'True',
					'nameTrans': 'form.true'
				},
				{
					'value': 0,
					'name': 'False',
					'nameTrans': 'form.false'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	
		}),
		new FilterOptionField({
			'field': 'fn.pastDue',
			'nameTrans': 'form.pastDue',
			'type': 'select',
			'visible': true,
			'options': [
				{
					'value': 1,
					'name': 'True',
					'nameTrans': 'form.true'
				},
				{
					'value': 0,
					'name': 'False',
					'nameTrans': 'form.false'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	
		}),
		new FilterOptionField({
			'field': 'ec.selfEnrolled',
			'nameTrans': 'form.selfEnrolled',
			'type': 'select',
			'operators': [this.querySettings.operatorOptions.equal],
			'settings': [
				{
					'name': 'addNull',
					'condition' : 0
				}
			],
			'locked': 'filters.selfEnroll',
			'visible': true,
			'options': [
				{
					'value': 1,
					'name': 'True',
					'nameTrans': 'form.true'
				},
				{
					'value': 0,
					'name': 'False',
					'nameTrans': 'form.false'
				},
			],
		}),
		new FilterOptionField({
			'field': 'fn.hierarchy',
			'nameTrans': 'form.hierarchy',
			'type': 'select',
			'visible': true,
			'operators': [this.querySettings.operatorOptions.equal],
			'options': [
				{
					'name': 'bypass',
					'value': 'bypass',
					'nameTrans': 'form.bypassHierarchy',
					'locked': 'filters.bypassSegregation'
				},
				{
					'name': 'subordinate',
					'value': 'subordinate',
					'nameTrans': 'form.subordinate',
					'locked': 'filters.subordinateOption'
				},
				{
					'name': 'region',
					'value': 'region',
					'nameTrans': 'form.region',
					'locked': 'filters.regionOption',
				},
				{
					'name': 'subordinateInRegion',
					'value': 'subordinateInRegion',
					'nameTrans': 'form.subordinateInRegion',
					'locked': 'filters.subordinateInRegionOption'
				},
				{
					'name': 'subordinateAsManager',
					'value': 'subordinateAsManager',
					'nameTrans': 'form.subordinateAsManager',
					'locked': 'filters.subordinateAsManager',
					'addInput': 'Add Employee ID of manager',
					'addInputLocalize': 'form.addManagerEmployeeID',
				},
				{
					'name': 'regionAsManager',
					'value': 'regionAsManager',
					'nameTrans': 'form.regionAsManager',
					'locked': 'filters.regionManagerOption',
					'addInput': 'Add Employee ID of manager',
					'addInputLocalize': 'form.addManagerEmployeeID',
				},
				{
					'name': 'subordinateInRegionAsManager',
					'value': 'subordinateInRegionAsManager',
					'nameTrans': 'form.subordinateInRegionAsManager',
					'locked': 'filters.subordinateInRegionManagerOption',
					'addInput': 'Add Employee ID of manager',
					'addInputLocalize': 'form.addManagerEmployeeID',
				},
			],
			'locked': 'filters.hierarchy',
			'required': 'filters.requireHierarchy',
			'default': true,
			'disableOptionOverride' : true,
		}),
	);

	searches:LioFieldArray = new LioFieldArray(
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'visible': true
		},
		{
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'visible': true
		},
		{
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'visible': true
		},
		{
			'name': 'email',
			'model': 'email',
			'type': 'text',
			'visible': true
		},
		{
			'name': 'state',
			'model': 'state',
			'type': 'text',
			'visible': true
		},
		{
			'name': 'country',
			'model': 'country',
			'type': 'text',
			'visible': false
		},
		new LioSearchableSelectField({
			'name': 'status',
			'model': 'inactive',
			'nameTrans': 'form.status',
			'type': 'select',
			'visible': true,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '0',
					'nameTrans': 'form.active',
					'name': 'active',
					'selected': false,
				},
				{
					'value': '1',
					'nameTrans': 'form.inactive',
					'name': 'inactive',
					'selected': false,
				}
			]
		}),
		new LioSearchableSelectField({
			'name': 'Course Completed',
			'model': 'courseCompletion',
			'type': 'select',
			'visible': true,
			'disableOptionOverride': true,
			'options': [
				{
					'value': '',
					'name': '',
					'selected': false,
				},
				{
					'value': '1',
					'nameTrans': 'form.completed',
					'name': 'Completed',
					'selected': false,
				},
				{
					'value': '0',
					'nameTrans': 'form.incomplete',
					'name': 'Incomplete',
					'selected': false,
				}
			]
		}),
		new LioSearchableSelectField({
			'name'				: 'Course ID',
			'model'				: 'courseID',
			'type'				: 'select',
			'options'			: [],
			'visible'			: true,
			'disableOverride'	: true,
			'searchable' 		: true
		})
	);
}