
<div (contextmenu)="onContextMenu($event)">
	<ng-content></ng-content>
	<div *ngIf="showContext()">
		<div style="visibility: hidden; position: fixed"
			[style.left]="contextMenuPosition.x"
			[style.top]="contextMenuPosition.y"
			[matMenuTriggerFor]="lioContextMenu">
		</div>
		
		<mat-menu #lioContextMenu="matMenu">
			<button mat-menu-item *ngIf="canHide()" (click)="hide()">
				<fa-icon [icon]="faEyeSlash"></fa-icon>
				<span class="ml-1">Hide</span>
			</button>
			<button mat-menu-item *ngIf="canReset()" (click)="reset()">
				<fa-icon [icon]="faEye"></fa-icon>
				<span class="ml-1">Reset</span>
			</button>
			<button mat-menu-item *ngIf="canEdit()" (click)="edit()">
				<fa-icon [icon]="faEdit"></fa-icon>
				<span class="ml-1">Localize</span>
			</button>
		</mat-menu>
	</div>
</div>