import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-choose-role',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', '../input/input.component.css', './choose-role.component.css']

})

export class ChooseRoleComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter	= new EventEmitter<any>();

	public override settings : any = {
		'id' : 'am_modal_choose_role',
		'title': 'Please select a Role',
		'canClose': true,
		'submit': 'Set Role',
		'submitTrans': 'message.setRole',
		'close': 'Close',
		'closeTrans': 'modal.close',
		'optionName': 'Choose a role',
		'optionNameTrans': 'user.chooseRole',
		'options': [],
		'searchFail': 'No matching options found',
		'searchFailTrans': 'user.searchFail',
	};


	constructor(
		@Inject(MAT_DIALOG_DATA) 	public override parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.selection = this.settings.selection;
		this.setSelectedRole();
	}

	/*
	 * Sets the selected role 
	*/
	setSelectedRole(): void {
		if (this.selection && this.settings.options) {
			this.settings.options.forEach((option) => {
				if (option['roleID'] == this.selection['roleID']) {
					this.selection = option;
				}
			});
		}
	}
}