<div class="modal-container" id="settings.catalog_modal">
	<!-- Header -->
	<header class="modal-header">
		<div class="row w-100">
			<!-- Course Image -->
			<div class="col-12">
				<div class="float-left" id="image" *ngIf="settings.courseType.imageURL">
					<img class="rounded" style="height: 130px;" [src]="settings.courseType.imageURL" align="left" class="img-left">
				</div>
				<div class="float-left px-4" id="name">
					<h6 class="font-weight-bold">Course Name</h6>
					<h5 id="courseName" class="m-0" [innerHtml]="settings.catalog.name"></h5>
				</div>
			</div>
		</div>
	</header>
	<!-- Body -->
	<section>
		<div class="row mt-3">
			<!-- Left Side -->
			<div class="col-md-8 text-left">

				<!-- Description -->
				<div id="description">
					<h5>Course Description:</h5>
					<div class="small mb-1 max-size-300" [innerHtml]="settings.catalog.description | html"></div>
				</div>

				<!-- Length -->
				<div class="mt-3" id="length" *ngIf="settings.catalog.length">
					<h5>Course Length:</h5>
					<h6 style="margin: 0">{{settings.catalog.length}}</h6>
				</div>

			</div><!-- Left Side -->

			<!-- Right BOTTOM Side -->
			<div class="col-md-4 rightCourse">

				<!-- TOPICS -->
				<div id="topics" *ngIf="settings.catalog.topics && settings.catalog.topics.length">
					<h5>Topics:</h5>
					<ul class="max-size-150">
						<li class="topic" *ngFor="let topic of settings.catalog.topics" [innerHtml]="topic"></li>
					</ul>
				</div>

				<!-- Languages -->
				<div class="mt-3" *ngIf="settings.catalog.langs && settings.catalog.langs.length" id="languages">
					<h5 >Available Languages:</h5>
					<ul class="max-size-150">
						<li class="lang" *ngFor="let language of settings.catalog.langs" [innerHtml]="language.name">{{language.name}}</li>
					</ul>
				</div>
			</div><!-- Right Side -->
		</div>
	</section>
	<!-- Footer -->
	<footer class="modal-footer">
		<button class="btn btn-sm btn-primary" mat-raised-button id="am_modal_close" (click)="close()">Close</button>
	</footer>
</div>