<!-- Email Form -->
<lio-model-editor [model]="template" 
	[settings]="modelSettings" 
	(onUpdate)="onupdate($event)" 
	(onOptionsLoaded)="onOptionsLoaded.emit($event);" 
	(onModelLoad)="onTemplateLoad($event)" 
	(onModelDelete)="onTemplateDelete()"
	(onModelSave)="onTemplateSaved($event)"
	(onModelPreSubmit)="onModelPreSubmit($event)"
	[appearance]="appearance">

	<!-- Course Selector -->
	<div class="row p-3" *ngIf="showCourses">
		<div class="col-md-1"></div>
		<div class="col-md-10">
			<lio-searchable-select [model]="template"
				[field]="templateField"
				[multiple]="true"
				[appearance]="appearance"
				(update)="onupdate();"
				[disabled]="!preview"
				[searchable]="true">
			</lio-searchable-select>
		</div>
	</div>

	<!-- Language Filter -->
	<div class="row p-3" localize [model]="localeStrings" [items]="['sampleSelect','courseSelect','ruleLabel']">
		<div class="col-md-1"></div>
		<div class="col-md-10">
			<lio-card cardClass="bg-light" *ngIf="filters.length" [hideErrors]="true">
				<div lio-card-header>
					<div class="font-weight-bold text-center small hc-dark-text">{{localeStrings.ruleLabel}}</div>
				</div>
				<div lio-card-body>
					<lio-query-builder 
						[(filters)]="filters" 
						[settings]="settings.builderSettings" 
						[(rules)]="template.filters"
						[controlObject]="builderCtrl">
					</lio-query-builder>
				</div>
			</lio-card>
		</div>
	</div>
</lio-model-editor>