import { Pipe } from '@angular/core';

@Pipe({
  name: "search"
})
export class SearchPipe {
	transform(options: Array<any>, search:string): Array<any> {
		if (!options || !search) {
			return options; 
		}

		search = search.toLowerCase();
		return options.filter((option) => {
			if (option.name) {
				return option.name.toLowerCase().indexOf(search) > -1
			}
			return 0;
		});
	}
}