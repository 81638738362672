<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-8 mx-auto">
		<div class="logout-sign"></div>
		<lio-card cardClass="logout-card p-3">
			<div lio-card-header>
				<img src="images/assets/logout.png" class="logout-sign"/>
				<h2>{{localeStrings.header}}</h2>
				<p>{{localeStrings.subheader}}</p>
				<div class="mt-2" *ngIf="allowLogIn || permissionService.hasPermission('logout.allowLogBackinWithSSO')">
					<button id="am_logout_submit" 
						mat-flat-button
						color="primary"
						(click)="login()" 
						class="mt-3" 
						type="submit">

						<strong>{{localeStrings.logbackin}}</strong>
					</button>
				</div>
			</div>
		</lio-card>
	</div>
</div>