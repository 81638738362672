<lio-panel-tabber>
	<lio-panel-tab [settings]="panelConfig.coursesTab">
		<lio-training-courses *lioPanelTabContent
			[settings]="panelConfig.coursesTab.settings"
			[allowLaunch]="true">
		</lio-training-courses>
	</lio-panel-tab>
	<lio-panel-tab [settings]="panelConfig.expireCoursesTab">
		<lio-training-courses *lioPanelTabContent
			[settings]="panelConfig.expireCoursesTab.settings" 
			[allowLaunch]="false">
		</lio-training-courses>
	</lio-panel-tab>
	<lio-panel-tab [settings]="panelConfig.selfEnroll"></lio-panel-tab>
</lio-panel-tabber>