import { Injectable } from '@angular/core';

import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: localizationSettings
})
export class localizationSettings{
	localizableItems 	: Array<any>;
	pagination 			: any;
	langs 				: Array<any>;
	datepickerLangMap 	: any;
	items 				: any;
	fields 				: LioFieldArray;
	searches 			: Array<any>;

	localizeItemField:LioSearchableSelectField = new LioSearchableSelectField({
		amID			: 'am_item',
		name			: 'Choose the item to localize',
		model			: 'item',
		addEmptyOption	: true,
		searchable 		: true
	});

	constructor(){
	this. localizableItems = [
		// Global
		{'field': 'global.processing',								'default' : 'processing...'},
		{'field': 'global.toggleContrast',							'default' : 'Toggle Contrast'},
		{'field': 'global.assigncourses',							'default' : 'Assign Courses'},
		{'field': 'global.editquery',								'default' : 'Edit Query'},
		{'field': 'global.emailuser',								'default' : 'Email User'},
		{'field': 'global.emailusers',								'default' : 'Email Users'},
		{'field': 'global.emailaddedusers',							'default' : 'Email Added Users'},
		{'field': 'global.exportrecords',							'default' : 'Export Records'},
		{'field': 'global.exportingrecords',						'default' : 'Exporting Records'},
		{'field': 'global.emailrecords',							'default' : 'Email Records'},
		{'field': 'global.exportPDF',								'default' : 'Export PDF'},

		
		// Query Tool
		{'field': 'query.name',										'default' : 'Name'},
		{'field': 'query.default',									'default' : 'Default'},
		{'field': 'query.save',										'default' : 'Save Query'},
		{'field': 'query.delete',									'default' : 'Delete Query'},
		{'field': 'query.placeholder',								'default' : 'Enter name to save as'},
		{'field': 'query.load',										'default' : 'Load a Saved Query'},
		{'field': 'query.runReport',								'default' : 'Run Report'},
		{'field': 'query.create',									'default' : 'Create New'},

		// User Drop Down``
		{'field': 'user.editProfile',								'default' : 'Edit Profile'},
		{'field': 'user.chooseLang',								'default' : 'Choose Language'},
		{'field': 'user.chooseCompany',								'default' : 'Choose Company'},
		{'field': 'user.chooseRole',								'default' : 'Choose Role'},
		{'field': 'user.changePass',								'default' : 'Change Password'},
		{'field': 'user.resetPass',									'default' : 'Reset User\'s Password'},
		{'field': 'user.logout',									'default' : 'Logout'},
		{'field': 'user.searchFail',								'default' : 'No matching options found'},

		// Access Logs
		{'field': 'accesslog.crumb', 								'default' : ' Access Logs'},
		{'field': 'accesslog.title', 								'default' : ' Access Logs'},
		{'field': 'accesslog.search', 								'default' : ' Search Access Logs'},


		// Audit Logs
		{'field': 'auditlog.crumb', 								'default' : ' Audit Logs'},
		{'field': 'auditlog.title', 								'default' : ' Audit Logs'},
		{'field': 'auditlog.search', 								'default' : ' Search Audit Logs'},

		// Email Audit Logs
		{'field': 'emailauditlog.crumb', 							'default' : ' Email Audit Logs'},
		{'field': 'emailauditlog.title', 							'default' : ' Email Audit Logs'},
		{'field': 'emailauditlog.search', 							'default' : ' Search Email Audit Logs'},


		// Admin Tools
		{'field': 'adminTools.crumb', 								'default' : ' Admin Tools'},
		{'field': 'adminTools.title', 								'default' : ' Admin Tools'},
		{'field': 'adminTools.header', 								'default' : ' Admin Tools'},

		// Fields
		{'field': 'fields.crumb', 									'default' : ' Fields Editor'},
		{'field': 'fields.title', 									'default' : ' Fields Editor'},
		{'field': 'fields.header', 									'default' : ' Fields Editor'},
		{'field': 'fields.modelName', 								'default' : ' Fields'},
		{'field': 'fields.buildCCF', 								'default' : ' Re-Build CCF Cache'},


		// Add Users
		{'field': 'addusers.crumb',									'default' : 'Add Users'},
		{'field': 'addusers.title',									'default' : 'Add Users'},
		{'field': 'addusers.header',								'default' : 'Add Users'},

		// Batch Processing
		{'field': 'batchProcessing.crumb',							'default' : 'Batch Processing'},
		{'field': 'batchProcessing.title',							'default' : 'Batch Processing'},
		{'field': 'batchProcessing.header',							'default' : 'Batch Processing'},
		{'field': 'batchProcessing.nothingToProcess',				'default' : 'No changes were detected in the uploaded file'},
		{'field': 'batchProcessing.foundChanges',					'default' : '{{total}} change(s) were detected in the uploaded file'},
		{'field': 'files.failedToUpload',							'default' : 'Hmmm..something didn\'t quite upload properly, please try again.'},


		// Bulk Upload
		{'field': 'addusers.bulkUpload',							'default' : 'Bulk Upload'},
		{'field': 'addusers.bulkUploadHeader',						'default' : 'Bulk Add/Update Users'},


		{'field': 'addusers.dragExcel',								'default' : 'Drag And Drop your excel (xlsx) file anywhere on this page'},
		{'field': 'addusers.getSample',								'default' : 'Get Sample File'},
		{'field': 'addusers.getTemplate',							'default' : 'Get Template For Importing'},
		{'field': 'addusers.add',									'default' : 'Add User'},
		{'field': 'addusers.upload',								'default' : 'Upload File'},
		{'field': 'addusers.process',								'default' : 'Process File'},
		{'field': 'addusers.choose', 								'default' : 'Choose how you would like us to process an uploaded file:'},
		{'field': 'addusers.makeSelection',							'default' : 'Please make a selection'},
		{'field': 'addusers.full',									'default' : '<i>Employees listed in the uploaded file will be modified.<br /><strong>All employees</strong> not listed in the file will be marked as inactive.</i><br /><i><strong class="red">PROCEED WITH CAUTION!</strong></i>'},
		{'field': 'addusers.delta',									'default' : '<i>Only employees listed in the uploaded file will be modified.</i>'},
		{'field': 'addusers.partial',								'default' : '<i>Only employees listed in the uploaded file will be modified. Empty fields will not be modified. Values set to NULL will be deleted.</i>'},

		{'field': 'addusers.deltaOption',							'default' : 'Delta (Changes Only)'},
		{'field': 'addusers.fullOption',							'default' : 'Full File'},
		{'field': 'addusers.partialOption',							'default' : 'Partial Delta File'},
		{'field': 'addusers.exportProcessReport',					'default' : 'Export Process Report'},

		// Assign Training
		{'field': 'assign.crumb',									'default' : 'Assign Training'},
		{'field': 'assign.title',									'default' : 'Assign Training'},
		{'field': 'assign.header', 									'default' : 'Assign Courses to {{totalEmployees}} employee(s)'},
		{'field': 'assign.courseavailableDate',						'default' : 'Course Available Date'},
		{'field': 'assign.courseDueDate',							'default' : 'Course Due Date'},
		{'field': 'assign.courseExpires',							'default' : 'Course Expiration Date'},
		{'field': 'assign.enrollUsers',								'default' : 'Enroll Users'},
		{'field': 'assign.emailEnrolled',							'default' : 'Email Enrolled Users'},
		{'field': 'assign.unenrollUsers',							'default' : 'Un-Enroll Users'},
		{'field': 'assign.updateEnrollments',						'default' : 'Update Enrollments'},
		{'field': 'assign.completeEnrollments',						'default' : 'Complete Enrollments'},
		{'field': 'assign.confirmSameAvailableDayModalTitle',		'default' : 'Are you sure you want to enroll these users today?'},
		{'field': 'assign.confirmSameAvailableDayModalBody',		'default' : 'You have chosen to make this course available today. If you are using automated scheduled emails, these users will not receive notifications. It is recommended to set the available date to at least one day from now.'},



		// Self Enroll

		{'field': 'selfenroll.title',								'default' : 'Self Enrollment'},
		{'field': 'selfenroll.crumb',								'default' : 'Self Enrollment'},
		{'field': 'selfenroll.title',								'default' : 'Self Enrollment'},
		{'field': 'selfenroll.enroll',								'default' : 'Enroll in Course'},
		{'field': 'selfenroll.enrolled',							'default' : 'Enrolled in Course'},
		{'field': 'selfenroll.subheader',							'default' : 'Enter search criteria below to select the courses you would like to view'},
		{'field': 'selfenroll.alreadyEnrolled',						'default' : 'You are already enrolled in this course'},
		{'field': 'forgotpass.alreadyLoggedIn',						'default' : 'You are already logged in, please wait while your screen reloads'},

		{'field': 'selfenroll.courseID',							'default' : 'Course ID'},
		{'field': 'selfenroll.noCatalogsFound', 					'default' : 'No Courses Left to Self Enroll, please check back another time'},
		{'field': 'selfenroll.courseName',							'default' : 'Course Name'},
		{'field': 'selfenroll.success',								'default' : 'You have successfully enrolled yourself in <br > {{courseName}}'},


		// Catalog

		{'field': 'catalogsearch.title',							'default' : 'Catalog Search'},
		{'field': 'catalogsearch.crumb',							'default' : 'Catalog Search'},
		{'field': 'coursecatalog.title',							'default' : 'Course Catalog Search'},
		{'field': 'coursecatalog.subheader',						'default' : 'Enter search criteria below to select the courses you would like to view'},
		{'field': 'coursecatalog.courseID',							'default' : 'Course ID'},
		{'field': 'coursecatalog.courseName',						'default' : 'Course Name'},
		{'field': 'coursecatalog.courseDescription',				'default' : 'Course Description'},
		{'field': 'coursecatalog.courseLength',						'default' : 'COURSE LENGTH:'},
		{'field': 'coursecatalog.cascadeCourse',					'default' : 'Cascade Course'},
		{'field': 'coursecatalog.more',								'default' : 'more...'},
		{'field': 'coursecatalog.more',								'default' : 'more...'},
		{'field': 'coursecatalog.less',								'default' : 'less...'},
		{'field': 'coursecatalog.format',							'default' : 'Format: '},
		{'field': 'coursecatalog.courseType',						'default' : 'Course Type'},
		{'field': 'coursecatalog.availableLanguages',				'default' : 'Available Languages:'},
		{'field': 'coursecatalog.page',								'default' : 'Page'},
		{'field': 'coursecatalog.coverHeader',						'default' : 'Compliance<br />Course Catalog'},
		{'field': 'coursecatalog.crumb',							'default' : 'Catalog Search'},
		{'field': 'coursecatalog.viewCatalog',						'default' : 'View Catalog'},
		{'field': 'coursecatalog.searchCatalog',					'default' : 'Search Catalog'},
		{'field': 'coursecatalog.displaying',						'default' : 'Displaying'},
		{'field': 'coursecatalog.course',							'default' : 'Course'},
		{'field': 'coursecatalog.catalog',							'default' : 'Catalog'},
		{'field': 'coursecatalog.filters',							'default' : 'FILTERS'},
		{'field': 'coursecatalog.clear',							'default' : 'Clear'},
		{'field': 'coursecatalog.released', 						'default' : 'Released'},
		{'field': 'coursecatalog.unreleased', 						'default' : 'Unreleased'},
		{'field': 'coursecatalog.status', 							'default' : 'Status'},
		{'field': 'coursecatalog.typesHeader',						'default' : 'TYPES:'},
		{'field': 'coursecatalog.topicsHeader',						'default' : 'TOPICS:'},
		{'field': 'coursecatalog.statusHeader', 					'default' : 'STATUS:'},
		{'field': 'coursecatalog.types',							'default' : 'Types'},
		{'field': 'coursecatalog.topics',							'default' : 'Topics:'},
		{'field': 'coursecatalog.keywords',							'default' : 'Featured Content:'},
		{'field': 'coursecatalog.latestUpdates',					'default' : 'Latest Updates:'},
		{'field': 'coursecatalog.modules',							'default' : 'Modules:'},
		{'field': 'coursecatalog.moduleDescription',				'default' : 'Select a single module and click the play button to preview the selected module. Click on multiple modules to request scripts or an implementation.'},
		{'field': 'catalogadmin.adminNotes',						'default' : 'Admin Notes:'},
		{'field': 'coursecatalog.projectedRelease',					'default' : 'PROJECTED RELEASE'},
		{'field': 'coursecatalog.editCatalog',						'default' : 'Edit Catalog'},
		{'field': 'coursecatalog.previewCourse',					'default' : 'Preview Course'},
		{'field': 'coursecatalog.requestImplementation',			'default' : 'Request Implementation'},


		{'field': 'catalogadmin.choose',							'default' : 'Choose what you would like to upload:'},
		{'field': 'catalogadmin.catalogs',							'default' : 'Catalogs'},
		{'field': 'catalogadmin.courseTypes',						'default' : 'Course Types'},
		{'field': 'catalogadmin.languages',							'default' : 'Languages'},
		{'field': 'catalogadmin.salesTeam',							'default' : 'Sales Team'},
		{'field': 'catalogadmin.exportAll',							'default' : 'Export All Records'},
		{'field': 'catalogadmin.deleteAll',							'default' : 'Delete All Records'},
		{'field': 'catalogadmin.process',							'default' : 'Process'},
		{'field': 'catalogadmin.coreLangs',							'default' : 'Core Languages'},
		{'field': 'catalogadmin.coreLangs',							'default' : 'Core Languages'},


		// Catalog PDF
		{'field': 'catalogpdf.tocHeader',							'default' : 'True Office Learning'},
		{'field': 'catalogpdf.page',								'default' : 'Page'},
		{'field': 'catalogpdf.courseID',							'default' : 'Course ID'},
		{'field': 'catalogpdf.courseName',							'default' : 'Course Name'},
		{'field': 'catalogpdf.courseType',							'default' : 'Course Type'},
		{'field': 'catalogpdf.more',								'default' : 'more...'},
		{'field': 'catalogpdf.availableLangs',						'default' : 'AVAILABLE LANGUAGES:'},
		{'field': 'catalogpdf.topicsCovered',						'default' : 'TOPICS COVERED:'},
		{'field': 'catalogpdf.length',								'default' : 'LENGTH:'},
		{'field': 'catalogpdf.format',								'default' : 'FORMAT:'},
		{'field': 'catalogpdf.type',								'default' : 'TYPE:'},
		{'field': 'catalogpdf.coverTitle',							'default' : 'Compliance<br>Course Catalog'},
		{'field': 'catalogpdf.type',								'default' : 'TYPE:'},
		{'field': 'catalogpdf.courseDescription',					'default' : 'COURSE DESCRIPTION:'},
		{'field': 'coursecatalog.languageHeader', 					'default' : 'LANGUAGES:'},
		{'field': 'coursecatalog.sortBy', 							'default' : 'Sort By:'},
		{'field': 'coursecatalog.print', 							'default' : 'Print'},
		{'field': 'coursecatalog.printAll', 						'default' : 'Print All'},
		{'field': 'coursecatalog.all', 								'default' : 'All'},
		{'field': 'coursecatalog.ascending', 						'default' : 'Ascending'},
		{'field': 'coursecatalog.descending', 						'default' : 'Descending'},
		{'field': 'coursecatalog.results', 							'default' : 'Results'},
		{'field': 'coursecatalog.courseName', 						'default' : 'Course Name'},
		{'field': 'coursecatalog.courseNumber', 					'default' : 'Course Number'},
		{'field': 'coursecatalog.courseTypeHeader', 				'default' : 'COURSE TYPE:'},
		{'field': 'coursecatalog.projectedRelease', 				'default' : 'PROJECTED RELEASE'},
		{'field': 'coursecatalog.projectedRelease', 				'default' : 'PROJECTED RELEASE'},
		{'field': 'coursecatalog.downloadCatalog',					'default' : 'Download Catalog PDF'},
		{'field': 'coursecatalog.requestCourseMaterials',			'default' : 'Request Course Materials'},
		{'field': 'coursecatalog.courseScript',						'default' : 'Course Script'},
		{'field': 'coursecatalog.infoText',							'default' : 'Course Overview'},
		{'field': 'coursecatalog.onePager',							'default' : 'Course Pamphlet'},
		{'field': 'coursecatalog.downloadOnePager',					'default' : 'Download Course Pamphlet'},
		{'field': 'coursecatalog.download',							'default' : 'Download'},

		{'field': 'coursecatalog.title',							'default' : 'Catalog Search'},
		{'field': 'coursecatalog.header', 							'default' : 'Please use the filters to search a course catalog'},
		{'field': 'coursecatalog.loading', 							'default' : 'Loading Catalogs...'},
		{'field': 'coursecatalog.noCatalogsFound', 					'default' : 'No Catalogs Found'},
		{'field': 'modal.generatingPDF',	 						'default' : 'Generating PDF...'},
		{'field': 'modal.oneSec',		 							'default' : 'One Second...'},
		{'field': 'modal.requestedScript', 							'default' : 'We have notified our sales team, and they will get back to you shortly with your request!'},
		{'field': 'modal.courseNotAvailable',						'default' : 'The course requested is not currently available'},
		{'field': 'modal.requestedScript',							'default' : '<h4>Thank you for submitting a Course Script Request.</h4><p>True Office Learning Service Team will be working on processing your request shortly.<br /> Our team endeavors to respond to all requests received within 1-2 business days.</p><p>If you are experiencing a longer than expected delay or need emergency assistance, please email <a href="mailto:service@trueoffice.com">service@trueoffice.com</a>.</p>'},
		{'field': 'modal.requestedImplementation',					'default' : '<h4>Thank you for submitting a Course Implementation Request.</h4><p>A member of the True Office Learning Implementation Team will be reaching out to you shortly.<br />Our team endeavors to respond to all requests received within 1-2 business days.</p><p>If you are experiencing a longer than expected delay or need emergency assistance, please email <a href="mailto:implementations@trueoffice.com">implementations@trueoffice.com</a>.</p>'},
		{'field': 'modal.tooManyModulesSelected',					'default' : 'Too many modules selected to preview. Please select a single module and then try again.'},
		{'field': 'modal.moduleDoesNotHaveCourseID',				'default' : 'This module does not have the ability to preview. Please select another module and try again.'},
		{'field': 'modal.switchingCompaniesFeatureDenied',			'default' : 'This feature is not available while switching companies.'},
		{'field': 'modal.emailAddressIsRequiredforThisFeature',		'default' : 'This feature requires you to have an email address on file.'},
		{'field': 'modal.tieringNotSet',							'default' : 'Tiering is not set up for this client'},
		{'field': 'modal.downloading',								'default' : 'Downloading'},


		// Catalog
		{'field': 'catalogadmin.crumb',								'default' : 'Administer Catalog'},
		{'field': 'catalogadmin.title',								'default' : 'Administer Catalog'},
		{'field': 'catalogadmin.addCatalog',						'default' : 'Add Catalog'},
		{'field': 'catalogadmin.addTopic',							'default' : 'Add Topic'},

		{'field': 'catalogadmin.searchCatalogs',					'default' : 'Search Catalog'},

		{'field': 'catalogadmin.editCatalog',						'default' : 'Edit Catalog'},
		{'field': 'catalogadmin.header',							'default' : 'Add Catalog'},
		{'field': 'catalogadmin.loadCatalog',						'default' : 'Load Catalog'},
		{'field': 'catalogadmin.addEditSubHeader',					'default' : 'Please fill out the required(*) fields in the form below'},
		{'field': 'catalogadmin.selectCourseID',					'default' : 'Please select a course ID to edit'},

		{'field': 'catalogadmin.save', 								'default' : 'Save Catalog'},
		{'field': 'catalogadmin.saveTopic', 						'default' : 'Save Topic'},
		{'field': 'catalogadmin.deleteTopic', 						'default' : 'Delete Topic'},
		{'field': 'catalogadmin.bulkUpload',						'default' : 'Bulk Upload'},
		{'field': 'catalogadmin.doesNotExist',						'default' : 'That course ID does not exist'},
		{'field': 'catalogadmin.inputCourse',						'default' : 'Input Course ID'},
		{'field': 'catalogadmin.bulkUploadHeader',					'default' : 'Bulk Add/Update Users'},
		{'field': 'catalogadmin.full',								'default' : '<i>Catalogs listed in the uploaded file will be modified.<br /><strong>All catalogs</strong> not listed in the file will be marked as inactive.</i><br /><i><strong class="red">PROCEED WITH CAUTION!</strong></i>'},
		{'field': 'catalogadmin.delta',								'default' : '<i>Only catalogs listed in the uploaded file will be modified.</i>'},
		{'field': 'catalogadmin.bulkUploadHeader',					'default' : 'Bulk Add/Update Catalogs'},
		{'field': 'catalogadmin.deactivate',						'default' : 'Deactivate'},
		{'field': 'catalogadmin.activate',							'default' : 'Activate'},


		// Branding
		{'field': 'branding.crumb',									'default' : 'Branding Assistant'},
		{'field': 'branding.title',									'default' : 'Branding Assistant'},
		{'field': 'branding.uploadLogo',							'default' : 'Upload Logo'},


		// Spider Settings
		{'field': 'learnioSettings.crumb',							'default' : 'Learn IO Settings'},
		{'field': 'learnioSettings.title',							'default' : 'Learn IO Settings'},
		{'field': 'learnioSettings.header',							'default' : 'Learn IO Settings'},
		{'field': 'learnioSettings.globalHeader',					'default' : 'Global Learn IO Settings'},
		{'field': 'learnioSettings.header',							'default' : 'Learn IO Settings'},
		{'field': 'learnioSettings.subHeader',						'default' : 'Hover over any of the settings for a description of what it does'},
		{'field': 'learnioSettings.editGlobalSettings',				'default' : 'Edit Global Settings'},
		{'field': 'learnioSettings.editLocalSettings',				'default' : 'Edit Local Settings'},
		{'field': 'learnioSettings.editCCFTypes',					'default' : 'Edit CCF Types'},
		{'field': 'learnioSettings.hideCCFTypes',					'default' : 'Hide CCF Types'},


		// Course Uploaded
		{'field': 'courseuploader.crumb',							'default' : 'Course Manager'},
		{'field': 'courseuploader.title',							'default' : 'Course Manager'},
		{'field': 'courseuploader.generateID',						'default' : 'Generate Course ID'},
		{'field': 'courseuploader.validateID',						'default' : 'Validate Course ID'},
		{'field': 'courseuploader.whitelist',						'default' : 'Whitelist'},
		{'field': 'courseuploader.uploadFile',						'default' : 'Upload File'},
		{'field': 'courseuploader.saveAccess',						'default' : 'Save Access'},
		{'field': 'courseuploader.saveCourse',						'default' : 'Save Course'},
		{'field': 'courseuploader.saveCourseInfo',					'default' : 'Save Course Information'},
		{'field': 'courseuploader.saveCourseContent',				'default' : 'Save Course Content'},
		{'field': 'courseuploader.loadCourseInfo',					'default' : 'Load Course Information'},
		{'field': 'courseuploader.loadingCourses',					'default' : 'Loading Courses'},
		{'field': 'courseuploader.previewCourse',					'default' : 'Preview Course'},
		{'field': 'courseuploader.keepDownloading',					'default' : 'Course {{courseID}} was not able to be downloaded, would you like to continue getting the rest of them?'},
		{'field': 'courseuploader.courseID',						'default' : 'Course ID'},
		{'field': 'courseuploader.courseType',						'default' : 'Course Type'},
		{'field': 'courseuploader.courseName',						'default' : 'Course Name'},
		{'field': 'courseuploader.courseDescription',				'default' : 'Course Description'},
		{'field': 'courseuploader.length',							'default' : 'Course Length'},
		{'field': 'courseuploader.courseURL',						'default' : 'Course URL'},
		{'field': 'courseuploader.webLaunchParams',					'default' : 'Course Parameters'},
		{'field': 'courseuploader.trackerType',						'default' : 'Tracker Type'},
		{'field': 'courseuploader.courseWidth',						'default' : 'Course Width'},
		{'field': 'courseuploader.courseHeight',					'default' : 'Course Height'},
		{'field': 'courseuploader.overwrite',						'default' : 'Overwrite'},
		{'field': 'courseuploader.uploadDescription',				'default' : 'You can upload a Scorm Package, Video File, or PDF document or click on the "Add course from URL" button to add a video hosted on a third party website ie YouTube'},
		{'field': 'courseuploader.pullingYouTubeData',				'default' : 'Contacting YouTube for the video information'},
		{'field': 'courseuploader.awsSaved',						'default' : 'Saved Successfully to AWS: "{{path}}"'},


		// Delegate Access
		{'field': 'delegateAccess.crumb', 							'default' : 'Delegate Access'},
		{'field': 'delegateAccess.title', 							'default' : 'Delegate Access'},


		// Exclude Users
		{'field': 'excludeUsers.title', 								'default' : 'Exclude Users'},
		{'field': 'excludeUsers.crumb', 								'default' : 'Exclude Users'},
		{'field': 'excludeUsers.addSuccess',							'default' : 'Exclusion added.'},
		{'field': 'excludeUsers.addFail',								'default' : 'Failed to add Exclusion.'},
		{'field': 'excludeUsers.removeSuccess',							'default' : 'Exclusion removed.'},
		{'field': 'excludeUsers.removeFail',							'default' : 'Failed to remove Exclusion.'},
		{'field': 'excludeUsers.modifyExclusions',						'default' : 'Modify Exclusion'},
		{'field': 'excludeUsers.addExclusions',							'default' : 'Add Exclusion'},
	
		// Email
		{'field': 'email.crumb',									'default' : 'Email'},
		{'field': 'email.title',									'default' : 'Email'},
		{'field': 'email.whichcoursetosample',						'default' : 'Which course(s) would you like to sample?'},
		{'field': 'email.selectcourse',								'default' : 'Select the specific course this template will be assigned to'},
		{'field': 'email.whichtemplate',							'default' : 'Which email template would you like to use?'},
		{'field': 'email.sendToEmployees',							'default' : 'Send Email To {{total}} employee(s)'},
		{'field': 'email.allEmailsSent',							'default' : 'All the employee(s) listed below have received their emails!'},
		{'field': 'email.manage',									'default' : 'Manage email templates'},
		{'field': 'email.viewtemplate',								'default' : 'View template'},
		{'field': 'email.edittemplate',								'default' : 'Edit template'},
		{'field': 'email.instructions',								'default' : 'Instructions'},
		{'field': 'email.preview',									'default' : 'Preview sample email'},
		{'field': 'email.send',										'default' : 'Send Email'},
		{'field': 'email.selectTemplate',							'default' : 'Please select a template'},
		{'field': 'email.sentEmail',								'default' : 'Sent Email'},
		{'field': 'email.sendTest',									'default' : 'Send Test Email to myself'},
		{'field': 'email.sendAll',									'default' : 'Send email to all users below'},
		{'field': 'email.name',										'default' : 'Name'},
		{'field': 'email.placeholder',								'default' : 'Enter name to save as'},
		{'field': 'email.save',										'default' : 'Save Template'},
		{'field': 'email.getSample',								'default' : 'Get Sample'},
		{'field': 'email.delete',									'default' : 'Delete Template'},

		// CCF Admin
		{'field': 'ccfadmin.crumb',									'default' : 'Admin Rights'},
		{'field': 'ccfadmin.title',									'default' : 'Admin Rights'},
		{'field': 'ccfadmin.header',								'default' : 'Admin Rights'},
		{'field': 'ccfadmin.subHeader',								'default' : 'Select the fields and options you wish to manage'},
		{'field': 'ccfadmin.suppresingCCFNote',						'default' : 'NOTE: The available values were not loaded due to a large amount of data, you can still manually enter those values in the add option box'},

		// Catalog manager
		{'field': 'catalogCollection.crumb',						'default' : 'Catalog Creator'},
		{'field': 'catalogCollection.title',						'default' : 'Catalog Creator'},
		{'field': 'catalogCollection.catalog',						'default' : 'Catalog Collection'},
		{'field': 'catalogCollection.header',						'default' : 'Manage Catalog Collections'},
		
		// Employee Search
		{'field': 'employeesearch.crumb',							'default' : 'Search Users'},
		{'field': 'employeesearch.title',							'default' : 'Search Users'},
		{'field': 'employeesearch.header',							'default' : 'Search Users'},
		{'field': 'employeesearch.includeInactive',					'default' : 'Include Inactive Employees'},
		{'field': 'employeesearch.hierarchy',						'default' : 'Limit Results based on hierarchy'},

		// Enrollment Query
		{'field': 'enrollquery.crumb',								'default' : 'Enrollment Rules'},
		{'field': 'enrollquery.title',								'default' : 'Enrollment Rules'},
		{'field': 'enrollquery.header',								'default' : 'Enter fields below to select the employees you would like to enroll'},
		{'field': 'enrollquery.filters',							'default' : 'Filters'},
		{'field': 'enrollquery.addrule',							'default' : 'Add Rule'},
		{'field': 'enrollquery.view',								'default' : 'View Enrollments'},
		{'field': 'enrollquery.assign',								'default' : 'Assign Training'},
		{'field': 'enrollquery.loadResults',						'default' : 'Load Results'},
		{'field': 'enrollquery.reset',								'default' : 'Reset'},


		// Enrollments
		{'field': 'enrollments.crumb',								'default' : 'Enrollments'},
		{'field': 'enrollments.title',								'default' : 'Enrollments'},
		{'field': 'enrollments.goBack',								'default' : 'Go Back'},
		{'field': 'enrollments.enterCompletionDate',				'default' : 'Enter a Completion Date'},
		{'field': 'enrollments.markToComplete',						'default' : 'Mark To Complete'},
		{'field': 'enrollments.assignCourses',						'default' : 'Assign Courses'},
		{'field': 'enrollments.exportRecords',						'default' : 'Export Records'},
		{'field': 'enrollments.editUsersProfile',					'default' : 'Edit'},

		{'field': 'loggedoff.title',								'default' : 'Logged Off'},
		{'field': 'loggedoff.crumb',								'default' : 'Logged Off'},

		{'field': 'enrollquery.upload',								'default' : 'Upload File'},
		{'field': 'enrollquery.process',							'default' : 'Process File'},
		{'field': 'enrollquery.selectByFilters',					'default' : 'Enroll Users'},
		{'field': 'enrollquery.selectByFile',						'default' : 'Bulk Upload'},

		{'field': 'enrollquery.enroll',								'default' : 'All employees listed in the file will be selected for enrollments.'},
		{'field': 'enrollquery.unenroll',							'default' : 'All employees listed in the uploaded file will be un-enrolled in course:'},
		{'field': 'enrollquery.update',								'default' : 'All employees listed in the uploaded file will have their enrollment updated for course:'},
		{'field': 'enrollquery.unenrollNote',						'default' : 'NOTE: ONLY ENROLLMENTS THAT HAVE NOT YET BEEN COMPLETED WILL BE UN-ENROLLED'},
		{'field': 'enrollquery.updateNote',							'default' : 'NOTE: ONLY ENROLLMENTS THAT HAVE NOT YET BEEN COMPLETED WILL BE UPDATED'},
		{'field': 'enrollquery.complete',							'default' : 'All employees listed in the uploaded file will be marked as completed for this course:'},
		{'field': 'enrollquery.fileHeader',							'default' : 'Enrollment by File'},
		{'field': 'enrollquery.fileSubHeader',						'default' : 'Select a file to upload'},
		{'field': 'enrollquery.fileSubHeader2',						'default' : 'Employees listed in the uploaded file will be selected for enrollment.'},


		// Reports
		{'field': 'reports.crumb',									'default' : 'Reports'},
		{'field': 'reports.title',									'default' : 'Reports'},
		{'field': 'reports.header',									'default' : 'Please select a report to download'},
		{'field': 'reports.name',									'default' : 'Name'},
		{'field': 'reports.save',									'default' : 'Save Report'},
		{'field': 'reports.delete',									'default' : 'Delete Report'},
		{'field': 'form.reportLocation',							'default' : 'Report Location'},
		
		// Edit Reports
		{'field': 'editreport.crumb',								'default' : 'Report Editing'},
		{'field': 'editreport.title',								'default' : 'Create / Edit Custom Reports'},
		{'field': 'editreport.header',								'default' : 'Report Editing'},
		{'field': 'reporteditor.crumb',								'default' : 'Report Editing'},
		{'field': 'reporteditor.title',								'default' : 'Create / Edit Custom Reports'},
		{'field': 'reporteditor.header',							'default' : 'Report Editing'},

				// Edit Reports
		{'field': 'reportcreator.crumb',							'default' : 'Custom Report Types'},
		{'field': 'reportcreator.title',							'default' : 'Custom Report Types'},
		{'field': 'reportcreator.header',							'default' : 'Custom Report Types'},


		// Custom Reports
		{'field': 'customreports.crumb',							'default' : 'Custom Reports'},
		{'field': 'customreports.title',							'default' : 'Custom Report Types'},
		{'field': 'customreports.header',							'default' : 'Please select a report to download'},
		{'field': 'customreports.name',								'default' : 'Name'},
		{'field': 'customreports.save',								'default' : 'Save Custom Report'},
		{'field': 'customreports.delete',							'default' : 'Delete Custom Report'},
		{'field': 'customreports.editing',							'default' : 'Report Editing'},
		{'field': 'customreports.getCount',							'default' : 'Get Count'},
		{'field': 'customreports.exportReport',						'default' : 'Export Report'},
		{'field': 'customreports.foundTotalResults',				'default' : 'Found {{total}} results'},


		// Enrollment Rules
		{'field': 'enrollquery.crumb',								'default' : 'Enrollment Rules'},
		{'field': 'enrollquery.title',								'default' : 'Enrollment Rules'},

		// Errors
		{'field': '405.crumb',										'default' : 'Errors'},
		{'field': '500.crumb',										'default' : 'Errors'},
		{'field': 'errors.crumb',									'default' : 'Errors'},
		{'field': 'errors.title',									'default' : 'Errors'},

		// Error Log
		{'field': 'errorlog.crumb',									'default' : 'Error Log'},
		{'field': 'errorlog.title',									'default' : 'Error Log'},

		// Global Search
		{'field': 'globalsearch.crumb',								'default' : 'Global Search'},
		{'field': 'globalsearch.title',								'default' : 'Global Search'},
		{'field': 'globalsearch.search',							'default' : 'Search'},
		{'field': 'globalsearch.filters',							'default' : 'Filters'},
		{'field': 'globalsearch.clear',								'default' : 'Clear'},


		// User Summary
		{'field': 'usersummary.crumb',								'default' : 'User Summary'},
		{'field': 'usersummary.title',								'default' : 'User Summary'},
		{'field': 'usersummary.companyChangeRequired',				'default' : 'You are currently at companyID:{{company1}}. This user is from companyID:{{company2}}. Would you like to switch companies?'},
		{'field': 'usersummary.companyNotAllowed',					'default' : 'You are currently at companyID:{{company1}}. This user is from companyID:{{company2}}. You do not have permission to switch to this company.'},

		// Localization
		{'field': 'localization.crumb',								'default' : 'Localization Assistant'},
		{'field': 'localization.title',								'default' : 'Localization Assistant'},
		{'field': 'localization.headerTitle',						'default' : 'Localizations'},
		{'field': 'localization.globalHeaderTitle',					'default' : 'Global Localizations'},
		{'field': 'localization.page',								'default' : 'Page'},
		{'field': 'localization.languageKey',						'default' : 'Language Key'},
		{'field': 'localization.default',							'default' : 'Default'},
		{'field': 'localization.edit',								'default' : 'Edit'},
		{'field': 'localization.field',								'default' : 'Field'},
		{'field': 'localization.english',							'default' : 'English'},
		{'field': 'localization.arabic',							'default' : 'Arabic'},
		{'field': 'localization.brazilPortuguese',					'default' : 'Brazil Portuguese'},
		{'field': 'localization.chineseSimplified',					'default' : 'Chinese Simplified'},
		{'field': 'localization.dutch',								'default' : 'Dutch'},
		{'field': 'localization.french',							'default' : 'French'},
		{'field': 'localization.frenchCanadian',					'default' : 'French Canadian'},
		{'field': 'localization.german',							'default' : 'German'},
		{'field': 'localization.italian',							'default' : 'Italian'},
		{'field': 'localization.japanese',							'default' : 'Japanese'},
		{'field': 'localization.korean',							'default' : 'Korean'},
		{'field': 'localization.latinAmericanSpanish',				'default' : 'Latin American Spanish'},
		{'field': 'localization.spanish',							'default' : 'Spanish'},
		{'field': 'localization.russian',							'default' : 'Russian'},
		{'field': 'localization.hebrew',							'default' : 'Hebrew'},
		{'field': 'localization.exportAllFields',					'default' : 'All Fields'},
		{'field': 'localization.exportTranslations',				'default' : 'Translations'},
		{'field': 'localization.exportMissingTranslations',			'default' : 'Missing Translations'},
		{'field': 'localization.getCompanyLocalizations',			'default' : 'Get Translations'},
		{'field': 'localization.getGlobalLocalizations',			'default' : 'Get Global Translations'},

		{'field': 'localization.importTranslations',				'default' : 'Import Translations'},
		{'field': 'localization.processImport',						'default' : 'Process Import'},
		{'field': 'localization.processImportGlobally',				'default' : 'Process Import Globally'},
		{'field': 'localization.deleteTranslations',				'default' : 'Delete Translations'},
		{'field': 'localization.deleteAllCompanysTranslations',		'default' : "Delete All Company's Translations"},

		// Localization
		{'field': 'localizationadmin.crumb',						'default' : 'Localization Admin'},
		{'field': 'localizationadmin.title',						'default' : 'Localization Admin'},

		// Home Page
		{'field': 'home.crumb',										'default' : 'Home'},
		{'field': 'home.title',										'default' : 'Home'},
		{'field': 'home.header',									'default' : 'Where would you like to start?'},
		{'field': 'home.myLeanring',								'default' : 'My Learning'},
		{'field': 'home.myLeanringSub',								'default' : 'View your active courses'},
		{'field': 'home.selfEnroll',								'default' : 'Self Enrollment'},
		{'field': 'home.batchProcessing',							'default' : 'Batch Processing'},
		{'field': 'home.selfEnrollSub',								'default' : 'Enroll yourself in a course'},
		{'field': 'home.dashboard',									'default' : 'Dashboard'},
		{'field': 'home.dashboardSub',								'default' : 'Create a query and view/export stats'},
		{'field': 'home.addUsers',									'default' : 'Add Users'},
		{'field': 'home.addUsersSub',								'default' : 'Add employees to the LMS'},
		{'field': 'home.enrollUsers',								'default' : 'Enroll Users'},
		{'field': 'home.enrollUsersSub',							'default' : 'Enroll selected users into courses'},
		{'field': 'home.searchUsers',								'default' : 'Search Users'},
		{'field': 'home.searchUsersSub',							'default' : 'Search for an Employee'},
		{'field': 'home.viewReports',								'default' : 'View Reports'},
		{'field': 'home.viewReportsSub',							'default' : 'View Reports'},
		{'field': 'home.emailTemplates',							'default' : 'Email Templates'},
		{'field': 'home.emailTemplatesSub',							'default' : 'View and modify email templates'},
		{'field': 'home.adminTools',								'default' : 'Admin Tools'},
		{'field': 'home.adminToolsSub',								'default' : 'Admin Tools'},
		{'field': 'home.courseUploader',							'default' : 'Upload Course'},
		{'field': 'home.courseUploaderSub',							'default' : 'Upload Course'},
		{'field': 'home.courseManager',								'default' : 'Course Manager'},
		{'field': 'home.courseManagerSub',							'default' : 'Upload, Add, Edit and Add to company'},
		{'field': 'home.disclosureReport',							'default' : 'Disclosure Reports'},

		// Dashboard Page
		{'field': 'dashboard.sendEmailModalTitle',					'default' : 'Send a Dashboard Email'},
		{'field': 'dashboard.crumb',								'default' : 'Dashboard'},
		{'field': 'dashboard.title',								'default' : 'Dashboard'},
		{'field': 'dashboard.header',								'default' : 'Dashboard Query Editor'},
		{'field': 'dashboard.subheader',							'default' : 'Enter fields below to select the employees you would like to view'},
		{'field': 'dashboard.filters',								'default' : 'Filters'},
		{'field': 'dashboard.showExpiredCoursesNote',				'default' : '*** To see expired courses, select the "Show Expired Courses" filter'},
		{'field': 'dashboard.closefilters',							'default' : 'Close Filters'},
		{'field': 'dashboard.addrule',								'default' : 'Add Rule'},
		{'field': 'dashboard.delete',								'default' : 'Delete'},
		{'field': 'dashboard.reset',								'default' : 'Reset'},
		{'field': 'dashboard.create',								'default' : 'Create Dashboard'},
		{'field': 'dashboard.pageHeader',							'default' : '{{pageHeader}}'},
		{'field': 'dashboard.totalUsers',							'default' : 'Total Users'},
		{'field': 'dashboard.activeInactive',						'default' : 'Active / Total'},
		{'field': 'dashboard.totalCourses',							'default' : 'Total Courses'},
		{'field': 'dashboard.started',								'default' : 'Started'},
		{'field': 'dashboard.totalEnrollments',						'default' : 'Total Enrollments'},
		{'field': 'dashboard.completed',							'default' : 'Completed'},
		{'field': 'dashboard.totalLogins',							'default' : 'Total Logins'},
		{'field': 'dashboard.totalActiveLogins',					'default' : 'Total Active Logins'},
		{'field': 'dashboard.completionHistory',					'default' : 'Completion History'},
		{'field': 'dashboard.thisWeek',								'default' : 'This Week'},
		{'field': 'dashboard.lastWeek',								'default' : 'Last Week'},
		{'field': 'dashboard.thisMonth',							'default' : 'This Month'},
		{'field': 'dashboard.lastMonth',							'default' : 'Last Month'},
		{'field': 'dashboard.courseStartStatusInProgress',			'default' : 'In Progress'},
		{'field': 'dashboard.courseStartStatusEnrolled',			'default' : 'Enrolled (Not Started)'},
		{'field': 'dashboard.courseCompleteStatusComplete',			'default' : 'Complete'},
		{'field': 'dashboard.courseCompleteStatusIncomplete',		'default' : 'Not Complete'},
		{'field': 'dashboard.testPDF', 								'default' : 'Test PDF Report Page'},

		// Enrollmentds
		{'field': 'enrollments.crumb',								'default' : 'Enrollments'},
		{'field': 'enrollments.title',								'default' : 'Enrollments'},
		{'field': 'enrollments.header',								'default' : 'Course Enrollments:'},
		{'field': 'enrollments.avgLoginTime',						'default' : 'Average Login Time'},
		{'field': 'enrollments.viewingUser',						'default' : 'Viewing User'},


		//Training page
		{'field': 'training.crumb',									'default' : 'Training'},
		{'field': 'training.title',									'default' : 'Training Page'},
		{'field': 'training.courseID',								'default' : 'Course ID'},
		{'field': 'training.courseName',							'default' : 'Course Name'},
		{'field': 'training.courseDescription',						'default' : 'Description'},
		{'field': 'training.dueDate',								'default' : 'Due Date'},
		{'field': 'training.required',								'default' : 'Required'},
		{'field': 'training.status',								'default' : 'Status'},
		{'field': 'training.completed',								'default' : 'Completed'},
		{'field': 'training.failed',								'default' : 'Failed'},
		{'field': 'training.inProgress',							'default' : 'In Progress'},
		{'field': 'training.toDo',									'default' : 'To Do'},
		{'field': 'training.notAvailable',							'default' : 'Not Available Until'},
		{'field': 'training.disabled',								'default' : 'Disabled'},
		{'field': 'training.startCourse',							'default' : 'Start Course'},
		{'field': 'training.restartCourse',							'default' : 'Restart Course'},
		{'field': 'training.resumeCourse',							'default' : 'Resume Course'},
		{'field': 'training.search',								'default' : 'Search'},
		{'field': 'training.myCourses',								'default' : 'My Courses'},
		{'field': 'training.expiredCourse',							'default' : 'Expired Courses'},

		
		// Edit Profile
		{'field': 'profile.title',									'default' : 'Edit Profile'},
		{'field': 'profile.crumb',									'default' : 'Edit User Profile'},
		{'field': 'selfprofile.title',								'default' : 'Edit My Profile'},
		{'field': 'selfprofile.crumb',								'default' : 'Edit My Profile'},
		{'field': 'employeeprofile.title',							'default' : 'Edit Employee Profile'},
		{'field': 'employeeprofile.crumb',							'default' : 'Edit Employee Profile'},

		{'field': 'profile.header',									'default' : 'Edit Profile'},
		{'field': 'profile.editProfile',							'default' : 'Edit Profile'},
		{'field': 'profile.editAdminRights',						'default' : 'Edit Admin Rights'},
		{'field': 'profile.loginAsUser',							'default' : 'Login as user'},
		{'field': 'profile.successfullyChanged',					'default' : 'Your profile was successfully updated.'},
		{'field': 'profile.setPass',								'default' : 'Set User\'s Password'},

		// Company
		{'field': 'companyadmin.save',								'default' : 'Save Company'},
		{'field': 'company.title',									'default' : 'Company Administration'},
		{'field': 'company.crumb',									'default' : 'Company Administration'},
		{'field': 'company.header',									'default' : 'Edit Company'},
		{'field': 'company.name',									'default' : 'Company Name'},
		{'field': 'company.loginName',								'default' : 'Company Login Name'},
		

		// Forms
		{'field': 'form.name',										'default' : 'Name'},
		{'field': 'form.required',									'default' : 'Required'},
		{'field': 'form.select',									'default' : 'Select'},
		{'field': 'form.companyID',									'default' : 'Company ID'},
		{'field': 'form.employeeID',								'default' : 'Employee ID'},
		{'field': 'form.firstName',									'default' : 'First Name'},
		{'field': 'form.lastName',									'default' : 'Last Name'},
		{'field': 'form.email',										'default' : 'e-mail'},
		{'field': 'form.langID',									'default' : 'Language'},
		{'field': 'form.address1',									'default' : 'Address'},
		{'field': 'form.address2',									'default' : 'Address 2'},
		{'field': 'form.city',										'default' : 'City'},
		{'field': 'form.state',										'default' : 'State'},
		{'field': 'form.status',									'default' : 'Status'},
		{'field': 'form.role',										'default' : 'Role'},
		{'field': 'form.zipCode',									'default' : 'Zip Code'},
		{'field': 'form.country',									'default' : 'Country'},
		{'field': 'form.phone',										'default' : 'Phone #'},
		{'field': 'form.addDate',									'default' : 'Date Added'},
		{'field': 'form.courseID',									'default' : 'Course ID'},
		{'field': 'form.limit',										'default' : 'Limit'},
		{'field': 'form.hierarchy',									'default' : 'Hierarchy'},
		{'field': 'form.bypassHierarchy',							'default' : 'Bypass hierarchy'},
		{'field': 'form.defaultHierarchy',							'default' : 'Default hierarchy Settings'},
		{'field': 'form.courseName',								'default' : 'Course Name'},
		{'field': 'form.dateAdded',									'default' : 'Date Added'},
		{'field': 'form.dateModified',								'default' : 'Date Modified'},
		{'field': 'form.updated',									'default' : 'Date Modified'},
		{'field': 'form.update',									'default' : 'Update'},
		{'field': 'form.save',										'default' : 'Save'},
		{'field': 'form.edit',										'default' : 'Edit'},
		{'field': 'form.delete',									'default' : 'Delete'},
		{'field': 'form.action',									'default' : 'Action'},
		{'field': 'form.copy',										'default' : 'Copy'},

		{'field': 'form.signupdate',								'default' : 'Sign Up Date'},
		{'field': 'form.dueDate',									'default' : 'Due Date'},
		{'field': 'form.courseDueDate',								'default' : 'Due Date'},
		{'field': 'form.availableDate',								'default' : 'Available Date'},
		{'field': 'form.courseAvailableDate',						'default' : 'Available Date'},
		{'field': 'form.daysAdded',									'default' : 'Number of days added to the LMS'},
		{'field': 'form.signupdate',								'default' : 'Sign Up Date'},
		{'field': 'form.courseSignUpDate',							'default' : 'Sign Up Date'},
		{'field': 'form.completionDate',							'default' : 'Completion Date'},
		{'field': 'form.courseExpires',								'default' : 'Expiration Date'},
		{'field': 'form.courseCompletionDate',						'default' : 'Completion Date'},
		{'field': 'form.courseCompletion',							'default' : 'Course Completed'},
		{'field': 'form.courseCompleted',							'default' : 'Course Completed'},
		{'field': 'form.courseIncomplete',							'default' : 'Course Incomplete'},
		{'field': 'form.completed',									'default' : 'Completed'},
		{'field': 'form.started',									'default' : 'Started'},
		{'field': 'form.courseStarted',								'default' : 'Started'},
		{'field': 'form.incomplete',								'default' : 'Incomplete'},
		{'field': 'form.expirationDate',							'default' : 'Expiration Date'},
		{'field': 'form.courseExpirationDate',						'default' : 'Course Expiration Date'},
		{'field': 'form.enterEmployeeID',							'default' : 'Enter an employee ID'},
		{'field': 'form.enterFirstName',							'default' : 'Enter a first name'},
		{'field': 'form.enterLastName',								'default' : 'Enter a last name'},
		{'field': 'form.enterEmail',								'default' : 'Enter an e-mail addresss'},
		{'field': 'form.enterCity',									'default' : 'Enter a city'},
		{'field': 'form.enterEmployeeID',							'default' : 'Enter an employee ID'},
		{'field': 'form.enrolled',									'default' : 'Enrolled'},
		{'field': 'form.notEnrolled',								'default' : 'Not Enrolled'},
		{'field': 'form.showExpired',								'default' : 'Show Expired Courses'},
		{'field': 'form.completedOnTime',							'default' : 'Completed On Time'},
		{'field': 'form.completedLate',								'default' : 'Completed Late'},
		{'field': 'form.pastDue',									'default' : 'Past Due'},
		{'field': 'form.selfEnrolled',								'default' : 'Self Enrolled'},
		{'field': 'form.fromAddress',								'default' : 'From Address'},
		{'field': 'form.fromName',									'default' : 'From Name'},
		{'field': 'form.replyTo',									'default' : 'Reply To Address'},
		{'field': 'form.subject',									'default' : 'Subject'},
		{'field': 'form.message',									'default' : 'Message'},
		{'field': 'form.activeStatus',								'default' : 'Active'},
		{'field': 'form.inactiveStatus',							'default' : 'Inactive'},
		{'field': 'form.learner',									'default' : 'Learner'},
		{'field': 'form.support',									'default' : 'Support'},
		{'field': 'form.analyst',									'default' : 'Analyst'},
		{'field': 'form.learnerAdmin',								'default' : 'Learner Admin'},
		{'field': 'form.clientAdmin',								'default' : 'Client Admin'},
		{'field': 'form.globalAdmin',								'default' : 'Global Admin'},
		{'field': 'form.developer',									'default' : 'Developer'},
		{'field': 'form.and',										'default' : 'And'},
		{'field': 'form.or',										'default' : 'Or'},
		{'field': 'form.either',									'default' : 'Either'},
		{'field': 'form.true',										'default' : 'True'},
		{'field': 'form.false',										'default' : 'False'},
		{'field': 'form.subordinate',								'default' : 'Employees I manage'},
		{'field': 'form.subordinateAsManager',						'default' : 'Employees managed by...'},
		{'field': 'form.region',									'default' : 'Employees in my district'},
		{'field': 'form.regionAsManager',							'default' : 'Employees in district of...'},
		{'field': 'form.subordinateInRegion',						'default' : 'Employees I manage in my district'},
		{'field': 'form.subordinateInRegionAsManager',				'default' : 'Employees managed within the district of...'},
		{'field': 'form.addManagerEmployeeID',						'default' : 'Add email of manager'},
		{'field': 'form.active',									'default' : 'Active'},
		{'field': 'form.inactive',									'default' : 'Inactive'},
		{'field': 'form.requiredTrue',								'default' : 'True'},
		{'field': 'form.requiredFalse',								'default' : 'False'},
		{'field': 'form.toAddresses',								'default' : 'To Addresses'},
		{'field': 'form.pdf',										'default' : 'PDF'},
		{'field': 'form.spreadsheet',								'default' : 'Spreadsheet'},
		{'field': 'form.type',										'default' : 'Type'},
		{'field': 'form.description',								'default' : 'Description'},
		{'field': 'form.course',									'default' : 'Course'},
		{'field': 'form.filters',									'default' : 'Filters'},
		{'field': 'form.questionNumber',							'default' : 'Question ID: {{number}}'},
		{'field': 'form.newPassword',								'default' : 'New Password'},
		{'field': 'form.search',									'default' : 'Search'},
		{'field': 'form.searchField',								'default' : 'Search Field'},
		{'field': 'form.searchFail',								'default' : 'No matching options found'},
		{'field': 'form.searchPlaceholder',							'default' : 'Search'},
		{'field': 'form.noOptions',									'default' : 'No Options Found'},
		{'field': 'form.moreChips',									'default' : '{{number}} others selected'},

		// Login page
		{'field': 'login.crumb',									'default' : 'Log in'},
		{'field': 'login.title',									'default' : 'Log in'},
		{'field': 'login.username',									'default' : 'Employee ID'},
		{'field': 'login.password',									'default' : 'Password'},
		{'field': 'login.company',									'default' : 'Company name or ID'},
		{'field': 'login.login',									'default' : 'Log in'},
		{'field': 'login.logbackin',								'default' : 'Log back in'},
		{'field': 'login.forgotYourPassword',						'default' : 'Forgot your password?'},
		{'field': 'login.troubleLoggingIn',							'default' : 'Trouble Logging In?'},
		{'field': 'login.register',									'default' : 'Register'},
		{'field': 'login.registerUser',								'default' : 'Register as a new user'},
		{'field': 'login.close',									'default' : 'Close'},
		{'field': 'login.forgotPassword',							'default' : 'Forgot Password'},
	
		// Register User
		{'field': 'register.crumb',									'default' : 'Register'},
		{'field': 'register.title',									'default' : 'Register'},
		{'field': 'register.header',								'default' : 'Register'},
		{'field': 'register.subHeader',								'default' : 'Please fill in all the required fields(*)'},
		{'field': 'register.successfullyAdded',						'default' : 'Your account was successfully added, you will receive an email shortly with a link to set your password.'},
		{'field': 'register.youMayCloseWindow',						'default' : 'You may now close this window.'},
		{'field': 'register.logIn',									'default' : 'Log in'},
		{'field': 'register.saving',								'default' : 'Registering...'},
		{'field': 'register.failedToSave',							'default' : 'Hmmm..something didn\'t quite save properly, please try again. If the issue persists, please contact our administrator at {{supportEmail}}'},

		
		// Forgot password
		{'field': 'forgotpass.crumb',								'default' : 'Forgot Password'},
		{'field': 'forgotpass.title',								'default' : 'Forgot Your Password?'},
		{'field': 'forgotpass.emailAddress',						'default' : 'Email Address'},
		{'field': 'forgotpass.subtitle',							'default' : 'Enter either your <b>e-mail address</b> or your <b>employeeID</b> along with the <b>Company Name</b><br />and we\'ll send you a link to reset your password.'},
		{'field': 'forgotpass.submit',								'default' : 'Submit'},
		{'field': 'forgotpass.receiveAnEmail',						'default' : 'You will receive an email with a password reset code'},
		{'field': 'forgotpass.pleaseEnterNewPass',					'default' : 'Please enter your new password'},
		{'field': 'forgotpass.pleaseEnterResetCode',				'default' : 'Please enter the password reset code along with your new password.'},
		{'field': 'forgotpass.passwordValidationMessage',			'default' : 'Password must be at least 8 characters, and contain the following 4 requirements: a lowercase letter, an uppercase letter, a number, and a non-alphabetic character (such as ?,!,$,%,*,^)'},
		{'field': 'forgotpass.successfullyReseted',					'default' : 'Your password was successfully reset, you may now log in using your new credentials'},
		{'field': 'forgotpass.logIn',								'default' : 'Log In'},
		{'field': 'forgotpass.email',								'default' : 'Email Address'},
		{'field': 'forgotpass.employeeID',							'default' : 'Employee ID'},
		{'field': 'forgotpass.companyID',							'default' : 'Company Name or ID'},
		{'field': 'forgotpass.code',								'default' : 'Password Reset Code'},
		{'field': 'forgotpass.password',							'default' : 'Password'},
		{'field': 'forgotpass.password2',							'default' : 'Re-enter Password'},
		{'field': 'forgotpass.OR',									'default' : 'OR'},
		
		// Change password
		{'field': 'changepass.title',								'default' : 'Change Password'},
		{'field': 'changepass.crumb',								'default' : 'Change Password'},
		{'field': 'changepass.passwordValidationMessage',			'default' : 'Password must be at least 8 characters, and contain the following 4 requirements: a lowercase letter, an uppercase letter, a number, and a non-alphabetic character (such as ?,!,$,%,*,^)'},
		{'field': 'changepass.submit',								'default' : 'Submit'},
		{'field': 'changepass.successfullyChanged',					'default' : 'Your password was successfully changed'},
		{'field': 'changepass.goToHomePage',						'default' : 'Go to the home page'},
		{'field': 'changepass.currentPassword',						'default' : 'Current Password'},
		{'field': 'changepass.password',							'default' : 'New Password'},
		{'field': 'changepass.password2',							'default' : 'Re-enter Password'},
		{'field': 'changepass.requiredTitle',						'default' : 'You are required to change your password.'},
		{'field': 'changepass.optionalTitle',						'default' : 'Please enter the form below to change your password.'},
		{'field': 'changepass.updateLang',							'default' : 'Update Your Language'},
		{'field': 'changepass.currentLang',							'default' : 'Current Language: {{lang}}'},
		{'field': 'changepass.pleaseChoose',						'default' : 'Please Choose'},

		// Error messages
		{'field': 'error.loggedOff', 								'default' : '<p>Your current session has timed out or has been terminated.</p><p>Some possible causes of this can be having multiple logins or clearing your browser cache after logging in.</p><p>If you did not create a new session please logout, log back in and try again.</p>'},
		{'field': 'error.logBackIn', 								'default' : '<strong>Log Back In</strong>'},
		{'field': 'error.sessionExpired',							'default' : 'Your session expired, please log in again'},
		{'field': 'error.405', 										'default' : 'You do not have permission to run this task'},
		{'field': 'error.unknown', 									'default' : 'An unknown error occurred, we are alerting our technicians of this issue'},
		{'field': 'error.tryThatAgain',		 						'default' : 'Hmmm..our system didn\'t quite catch that, please try that again'},
		{'field': 'error.weAreAlertingTechnicians', 				'default' : 'We are alerting our technicians of this issue...'},
		{'field': 'error.clickToSendToTechnicians', 				'default' : 'Click the button below to notify our technicians of this error'},
		{'field': 'error.takenBackToHomeShortly', 					'default' : 'You will be taken back to the home screen shortly...'},
		{'field': 'error.weAlertedTechnicians', 					'default' : '<h4>We have alerted our technicians to this issue and they will work on it shortly. Please reference this code for future inquiries of its status:</h4><h5>REFERENCE ID: {{code}}</h5>'},
		{'field': 'error.persistsWithCode', 						'default' : '<h4 class="text-center">If the issue persists, please contact our administrator at {{supportEmail}}</h4><h5>Please reference this code:{{code}}</h5>'},
		{'field': 'error.persistsWithOutCode', 						'default' : '<h4 class="text-center">If the issue persists, please contact our administrator at {{supportEmail}}</h4>'},
		{'field': 'error.permissionError1',							'default' : "You do not have permissions to add a user as a {{role}} for '{{employeeID}}' on row {{row}}"},
		{'field': 'error.permissionError2',							'default' : "You do not have permissions to add a user with this role for '{{employeeID}}' on row {{row}}"},
		{'field': 'error.permissionErrorGeneric',					'default' : "You do not have permission to run this operation on a {{roleName}} role"},
		{'field': 'error.duplicateEmployee',						'default' : "There is a duplicate employeeID for '{{employeeID}}' on row {{row}}"},
		{'field': 'error.invalidRole',								'default' : "The role for this user is not valid for '{{employeeID}}' on row {{row}}"},
		{'field': 'error.invalidLang',								'default' : "The language of \'{{lang}}\' is not valid for '{{employeeID}}' on row {{row}}"},
		{'field': 'error.invalidCredentialsProvided',				'default' : 'Invalid Credentials Provided'},
		{'field': 'error.invalidChars',								'default' : '{{input}} has invalid characters'},
		{'field': 'error.mustBeNumeric',							'default' : '{{input}} must be alphanumeric'},
		{'field': 'error.mustBeAlphaNumeric',						'default' : '{{input}} must be between {{min}} and {{max}} characters'},
		{'field': 'error.requiresMinimumChars',						'default' : '{{input}} requires a minimum of {{min}} characters'},
		{'field': 'error.withinRange',								'default' : '{{input}} must be between {{min}} and {{max}}'},
		{'field': 'error.requiresMaximumChars',						'default' : '{{input}} requires a maximum of {{max}} characters'},
		{'field': 'error.improperEmailAddress',						'default' : '{{input}} is not a proper email address'},
		{'field': 'error.htmlEmbeddedError',						'default' : 'Your settings do not allow for HTML embedded emails. Please contact {{supportEmail}} to get authorization'},
		{'field': 'error.pleaseEnterYourCurrentPass',				'default' : 'Please enter your current Password'},
		{'field': 'error.yourEmployeeIdWasNotRecognized',			'default' : 'Your employee ID was not recognized. Please verify your employee ID and try again.'},
		{'field': 'error.yourEmailAddressWasNotRecognized',			'default' : 'Your e-mail address was not recognized as a valid user. Please verify your email address and try again.'},
		{'field': 'error.pleaseEnterYourPass',						'default' : 'Please enter a password'},
		{'field': 'error.pleaseReEnterYourPass',					'default' : 'Please re-enter your password'},
		{'field': 'error.thePasswordDontMatch',						'default' : 'The passwords do not match'},
		{'field': 'error.newPassCantBeSameAsCurrent',				'default' : 'New password can not be the same as your current password'},
		{'field': 'error.missingPass',								'default' : 'Missing Password'},
		{'field': 'error.passMustBeAtLeast8Chars',					'default' : 'Password must be at least {{min}} characters'},
		{'field': 'error.passIsToLong',								'default' : 'Password must be less than {{max}} characters'},
		{'field': 'error.passCantContainSpecialChars',				'default' : 'Password cannot contain any of these characters (<>;\'#--)'},
		{'field': 'error.thePassDoesntMeetsAllRequirments',			'default' : 'The password only meets {{level}} of the 4 requirements'},
		{'field': 'error.passNoLower',								'default' : 'The password must contain at least 1 lower case character'},
		{'field': 'error.passNoUpper',								'default' : 'The password must contain at least 1 upper case character'},
		{'field': 'error.passNoNumber',								'default' : 'The password must contain at least 1 number'},
		{'field': 'error.passNoSpecial',							'default' : 'The password must contain at least 1 special character'},
		{'field': 'error.currentPassIsIncorrect',					'default' : 'The current password is incorrect'},
		{'field': 'error.passwordIsNotUnique',						'default' : 'Your new password must not match any of your previous five passwords'},
		{'field': 'error.pleaseAddUniqueName',						'default' : 'Please add a unique name, {{name}} is already used'},
		{'field': 'error.pleaseAddManagerEmailOrEmployeeID',		'default' : 'Please add the manager email or employee ID'},
		{'field': 'error.managerEmailOrEmployeeIDNotRecognized',	'default' : 'The manager email or employee ID is not recognized'},
		{'field': 'error.pleaseEnterPass',							'default' : 'Please enter a password'},
		{'field': 'error.pleaseReEnterPass',						'default' : 'Please re-enter your password'},
		{'field': 'error.pleaseEnterPassResetCode',					'default' : 'Please enter the password reset code'},
		{'field': 'error.passDontMatch',							'default' : 'The passwords do not match'},
		{'field': 'error.passwordCriteriaError',					'default' : 'The password only meets {{level}} of the 4 requirements'},
		{'field': 'error.pleaseEnterEitherEmailOrEmployeeID',		'default' : 'Please enter either your email address or your employee ID with your Company Name'},
		{'field': 'error.pleaseEnterValidEmail',					'default' : 'Please enter a valid email address'},
		{'field': 'error.pleaseEnterYourCompanyName',				'default' : 'Please enter your Company Name'},
		{'field': 'error.pleaseEnterYourEmployeeID',				'default' : 'Please enter your Employee ID'},
		{'field': 'error.failedToFindEmployeeFromID',				'default' : 'Failed to find employee from employeeID'},
		{'field': 'error.theseEmployeeIDDontExist',					'default' : 'These employeeIDs don\'t exist'},
		{'field': 'error.thisEmployeeDoesntExist',					'default' : 'This employee doesn\'t exist'},
		{'field': 'error.failedToGetEmployeeByUID',					'default' : 'Failed to get employee by UID'},
		{'field': 'error.thisEmployeIdAlreadyExists',				'default' : 'This employeeID already exists'},
		{'field': 'error.failedToFindEmployeeFromEmployeeID',		'default' : 'Failed to find employee from employeeID'},
		{'field': 'error.thisEmployeeIdAlreadyExists',				'default' : 'This employeeID already exists'},
		{'field': 'error.emailAddressOnFileIsInvalid',				'default' : 'The email address on file is either missing or invalid, please contact your internal support.'},
		{'field': 'error.ssoUserCannotResetPassword',				'default' : 'Your account is set up for SSO access, please re-log in using your SSO portal.'},
		{'field': 'error.courseSpecificEmailTemplateExists',		'default' : 'A template already exists with this course ID and type'},
		{'field': 'error.failedToLoadEvent',						'default' : 'Failed to load event'},

		{'field': 'error.employeeAlreadyExistsWithThisEmployeeID',	'default' : 'An employee already exists with this Employee ID'},
		{'field': 'error.employeeAlreadyExistsWithThisEmail',		'default' : 'An employee already exists with this E-mail address'},


		{'field': 'error.failedToAddEmployee',						'default' : 'Failed to add employee'},
		{'field': 'error.cannotRegisteruserIsInactive',				'default' : 'Your account is currently inactive, please contact your Compliance Training Resource for assistance'},

		{'field': 'error.failedToGetUserFromEmployeeID',			'default' : 'Failed to get user from employeeID'},
		{'field': 'error.failedToSave',								'default' : 'Failed to save'},
		{'field': 'error.failedToEnrollUser',						'default' : 'Failed to enroll user'},
		{'field': 'error.noEnrollmentsFound',						'default' : 'No enrollments found for these users matching the course provided'},
		{'field': 'error.employeeMissingEmail',						'default' : '{{employeeID}} is missing an email address'},
		{'field': 'error.fromAddressInvalid',						'default' : 'The from address is not a valid email address'},
		{'field': 'error.replyToAddressInvalid',					'default' : 'The reply to address is not a valid email address'},
		{'field': 'error.emailAddressInValid',						'default' : '"The email address of {{email}} for user {{employeeID}} is not valid'},
		{'field': 'error.theLocationIsEmpty',						'default' : 'The {{location}} is empty'},
		{'field': 'error.failedToSendEmail',						'default' : 'Failed to send email'},
		{'field': 'error.missingErrorIDtoSendEmail',				'default' : 'Missing an error ID to send email'},
		{'field': 'error.missingIDsToSetCompletion',				'default' : 'Missing employeeInCourseIDs to set completion'},
		{'field': 'error.settingCourseCompletionFailed',			'default' : 'Setting Course completion failed'},
		{'field': 'error.missingEnrollmentInformation',				'default' : 'Missing Enrollment Information'},
		{'field': 'error.missingEmployeeIds',						'default' : 'Missing Employee IDs'},
		{'field': 'error.missingDatesToUpdate',						'default' : 'The dates provided for bulk updating were not valid or missing for {{employeeID}}'},
		{'field': 'error.noChunkByThisName',						'default' : 'No chunk by this name'},
		{'field': 'error.missingFieldsToSearchFor',					'default' : 'Missing fields to search for'},
		{'field': 'error.searcherFormatIsInvalid',					'default' : 'Searcher format is invalid'},
		{'field': 'error.companyIDIsInvalid',						'default' : 'The companyID is invalid'},
		{'field': 'error.failedToGetEmployeeByEmployeeID',			'default' : 'Failed to get employee by employeeID'},
		{'field': 'error.missingUploadedFilePath',					'default' : 'Missing Uploaded File Path'},
		{'field': 'error.failedToGenerateCourseID',					'default' : 'Failed to generate Course ID'},
		{'field': 'error.missingRequiredInformation',				'default' : 'Missing required information'},
		{'field': 'error.missingRequiredFilters',					'default' : 'The filter "{{filter}}" is required'},
		{'field': 'error.failedToUploadToCourseServer',				'default' : 'Failed to upload to course server'},
		{'field': 'error.missingCompanyID',							'default' : 'Missing Company ID'},
		{'field': 'error.noCoursesInThisCompany',					'default' : 'No courses in this company'},
		{'field': 'error.courseCompletionFailed',					'default' : 'Course completion failed'},
		{'field': 'error.wrongFileType',							'default' : 'The file must have a {{fileExt}} extension'},
		{'field': 'error.wrongFileTypes',							'default' : 'The file must be either of the following types: {{fileExt}} '},
		{'field': 'error.fileContainsHtmlTagsOrExcelFormulas',		'default' : 'File contains html tags or excel formulas, please check the file and try again.'},
		{'field': 'error.theFileImportedIsEmpty',					'default' : 'The file imported is empty'},
		{'field': 'error.thereWasAnIssueWithTheFileUploaded',		'default' : 'There was an issue with the file uploaded, please check the file and try again. Error - 003'},
		{'field': 'error.failedToLoadStats',						'default' : 'Failed to load stats'},
		{'field': 'error.invalidToken',								'default' : 'Invalid token'},
		{'field': 'error.noTokenAvailable',							'default' : 'No token available'},
		{'field': 'error.failedToDeleteErrorID',					'default' : 'Failed to delete {{errorID}}'},
		{'field': 'error.missingFileName',							'default' : 'missing file name'},
		{'field': 'error.missingFileParameter',						'default' : 'Missing file parameter'},
		{'field': 'error.failedToLocateFileRrealFilePath',			'default' : 'Failed to locate file: {{realFilePath}}'},
		{'field': 'error.missingSID',								'default' : 'Missing SID'},
		{'field': 'error.unableToDecryptSID',						'default' : 'Unable to decrypt SID'},
		{'field': 'error.failedToLogOut',							'default' : 'Failed to log out'},
		{'field': 'error.dbDriverNotConfiguredDriver',				'default' : 'DB DRIVER NOT CONFIGURED: {{driver}}'},
		{'field': 'error.missingRoleForTaskCurrentTask',			'default' : 'Missing role for task {{currentTask}}'},
		{'field': 'error.tooManyResultsFound',						'default' : 'Too many results found. Please narrow down your search'},
		{'field': 'error.failedToOpenAccessLogFile',				'default' : 'Failed to open Access Log File'},
		{'field': 'error.noLogsFoundWithinFileName',				'default' : 'No logs found within {{fileName}} matching the criteria entered'},
		{'field': 'error.failedToDecode',							'default' : 'Failed to decode'},
		{'field': 'error.failedToWriteFile',						'default' : 'Failed to write file'},
		{'field': 'error.permissionDeniedCreatingDir',				'default' : 'Permission Denied Error Creating Directory: {{path}}'},
		{'field': 'error.failedToUploadFile',						'default' : 'Failed to upload file'},
		{'field': 'error.failedToFTPFile',							'default' : 'Failed to FTP File, no stream was detected'},
		{'field': 'error.failedToValidateFile',						'default' : 'Failed to validate file'},
		{'field': 'error.fileDoesNotExistFile',						'default' : 'File Does not exist:  {{file}}'},
		{'field': 'error.uploadedFileDetectedAsVirus',				'default' : 'Uploaded file detected as virus'},
		{'field': 'error.functionNotUnderstoodForRule',				'default' : 'Function provided was not understood in one of the provided rules'},
		{'field': 'error.connectionError1',							'default' : 'Connection Error 1'},
		{'field': 'error.failedToConnectToFTPSite',					'default' : 'Failed to connect to FTP site'},
		{'field': 'error.serverFileDoesNotExistRemotePath',			'default' : 'Server file does not exist: {{remotePath}}'},
		{'field': 'error.failedToDownloadFile',						'default' : 'Failed to Download File to: {{destination}} from: {{source}}'},
		{'field': 'error.failedToRemoteToGlobal',					'default' : 'Failed to remote to: {{root}}'},
		{'field': 'error.failedToMakeDir',							'default' : 'Failed to make dir {{dir}}'},
		{'field': 'error.failedToGoToNewlyCreatedDirectory',		'default' : 'Failed to go to newly created directory: {{dir}}'},
		{'field': 'error.failedToGoToRemotePath',					'default' : 'Failed to go to : {{remotePath}}'},
		{'field': 'error.connectionErrorGoingToDir',				'default' : 'Connection Error going to {{dir}}'},
		{'field': 'error.missingName',								'default' : 'missing name'},
		{'field': 'error.failedToNotifyTheCourseServer',			'default' : 'Failed to notify the course server'},
		{'field': 'error.failedToUnzipFile',						'default' : 'Failed to unzip file.'},
		{'field': 'error.courseTypeNotRecognized',					'default' : 'Course Type of {{courseType}} was not recognized'},
		{'field': 'error.unableToDecryptEUID',						'default' : 'Unable to decrypt EUID'},
		{'field': 'error.passwordDoesNotMeetRequirements',			'default' : 'Password Does not meet requirements'},
		{'field': 'error.thePasswordResetCodeIsInvalid',			'default' : 'The password reset code is invalid'},
		{'field': 'error.passwordFailedToSet',						'default' : 'Password Failed to set'},
		{'field': 'error.failedToDeleteLink',						'default' : 'Failed to Delete Link'},
		{'field': 'error.noLogsFound',								'default' : 'No Logs found'},
		{'field': 'error.pleaseSelectAProcessType',					'default' : 'Please select a process type'},
		{'field': 'error.sorryTryThatAgain',						'default' : 'Sorry, try that again'},
		{'field': 'error.theProcessWasHalted',						'default' : 'The process was halted, though {{progress}} were processed'},
		{'field': 'error.availableDateCannotBePast',				'default' : 'The course available date cannot be in the past'},
		{'field': 'error.dueDateCannotBePast',						'default' : 'The due date cannot be in the past'},
		{'field': 'error.dueDateCannotPrecedeAvailableDate',		'default' : 'The due date can not precede the course available date for {{employeeID}}'},
		{'field': 'error.dueDateCannotPrecedeAvailableDate1',		'default' : 'The course due date can not precede the course available date'},
		{'field': 'error.dueDateCannotExceedExpirationDate',		'default' : 'The course due date cannot exceed the expiration date'},
		{'field': 'error.expirationDateCannotBePast',				'default' : 'The course expiration date cannot be in the past'},

		{'field': 'error.weDetectedSomeInvalidFileFormats',			'default' : 'We detected some invalid file formats. Please remove these files and try again. If these files are needed for your course, please contact support at {{supportEmail}}'},
		{'field': 'error.invalidFilesBadFiles',						'default' : 'Invalid files:  {{badFiles}}'},
		{'field': 'error.pleaseVaiidateTheCourseID',				'default' : 'Please validate the course ID'},
		{'field': 'error.failedToExportRecords',					'default' : 'Failed to export records'},
		{'field': 'error.noEmployeesFound',							'default' : 'No employees found'},
		{'field': 'error.inputValidCourseID',						'default' : 'Please input a valid course ID'},
		{'field': 'error.inputCourseIDBetween',						'default' : 'Please input a course ID between {{min}} and {{max}}'},
		{'field': 'error.pleaseSelectATemplateToPreview',			'default' : 'Please select a template to preview'},
		{'field': 'error.pleaseFillInForm',							'default' : 'Please fill in form'},
		{'field': 'error.failedTtoMarkUsersToComplete',				'default' : 'Failed to mark users to complete'},
		{'field': 'error.youDoNotHavePermissionToRunThisTask',		'default' : 'You do not have permission to run this task'},
		{'field': 'error.noPermissionToModifyEnrollment',			'default' : 'You do not have permission to modify the enrollment for {{employeeID}}'},

		{'field': 'error.anUnexpectedServerErrorOccurred',			'default' : 'An unexpected server error occurred'},
		{'field': 'error.noErrorFoundByThatID',						'default' : 'No Error found by that ID'},
		{'field': 'error.failedToGetCompanySettings',				'default' : 'Failed to get company settings'},
		{'field': 'error.pleaseSelectAnItemToLocalize',				'default' : 'Please select an item to localize'},
		{'field': 'error.pleaseMakeALocalizationChange',			'default' : 'Please make a localization change'},
		{'field': 'error.missingRequiredCredentials',				'default' : 'Missing required credentials'},
		{'field': 'error.failedToPullReport',						'default' : 'Failed to pull report'},
		{'field': 'error.noCoursesAssigned',						'default' : 'NO COURSES ASSIGNED'},
		{'field': 'error.noExpiredCoursesAssigned',					'default' : 'NO EXPIRED COURSES FOUND'},
		{'field': 'error.noRecordsFound',							'default' : 'No Records Found'},

		{'field': 'error.coursesNotLoadedYet',						'default' : 'Courses have not been loaded for this company yet.  You are unable to enroll or email users. Please contact support@trueoffice.com'},
		{'field': 'error.noResponseFromServerStatusCodeWas',		'default' : 'No response from server, statusCode was: {{status}}, {{lastTask}}'},
		{'field': 'error.noResponseFromServer',						'default' : 'No response from server {{lastTask}}'},
		{'field': 'error.failedToAddPropertyFromTask',				'default' : 'Failed to add property {{name}} from task: {{task}}'},
		{'field': 'error.variableWasNotFoundOnTask',				'default' : 'Variable: {{var}} was not found on {{task}}'},
		{'field': 'error.variableIsNotString',						'default' : 'Variable is not a string'},
		{'field': 'error.unspecifiedTypeToInterpret',				'default' : 'Unspecified type to interpret: {{type}}'},
		{'field': 'error.pathDoesNotExist',							'default' : 'Path does not exist: {{path}}'},
		{'field': 'error.thisControllerIsNotRecognized',			'default' : 'This controller is not recognized: {{controllerName}}'},
		{'field': 'error.methodDoesntExistForThisCtrl',				'default' : 'Method does not exist: {{methodName}} for this controller  {{controllerName}}'},
		{'field': 'error.noCourseLogsFound',						'default' : 'No course logs found'},
		{'field': 'error.noEmailHistoryForThisUserFound',			'default' : 'No email history for this user found'},
		{'field': 'error.noLoginHistoryForThisUserFound',			'default' : 'No login history for this user found'},
		{'field': 'error.theResetCodeProvidedIsNotValid',			'default' : 'The reset password code provided is not valid'},
		{'field': 'error.theSessionIdProvidedWasntRecognized',		'default' : 'The Session ID provided was not recognized'},
		{'field': 'error.credentialsDontMatch',						'default' : 'Credentials do not match'},
		{'field': 'error.userDoesntExistToLoginWithUID',			'default' : 'User does not exist to login with: UID: {{uid}} SID: {{SID}}'},
		{'field': 'error.yourCompanyIsntConfiguredToUseLMS',		'default' : 'Your company is not configured to use this LMS'},
		{'field': 'error.employeeNotFoundBySID',					'default' : 'Employee not found by SID {{SID}}'},
		{'field': 'error.noLocalizationsFound',						'default' : 'No Localizations found'},
		{'field': 'error.coursePathIsInvalid',						'default' : 'Course Path is invalid {{coursePath}}.'},
		{'field': 'error.failedToDetermineCourseType',				'default' : 'Failed to determine course type'},
		{'field': 'error.failedToDetermineTrackerType',				'default' : ''},
		{'field': 'error.failedToDetermineManifestType',			'default' : 'Failed to determine manifest file'},
		{'field': 'error.failedToDetermineCourseAttributes',		'default' : 'Failed to determine course attributes'},
		{'field': 'error.aCourseAlreadyExistsOnTheServer',			'default' : 'A course already exists on the server with this id {{id}}'},
		{'field': 'error.xmlDescriptionFileNotParsedCorrectly',		'default' : 'XML Description File not parsed correctly'},
		{'field': 'error.xmlManifestFileNotParsedCorrectly',		'default' : 'XML Manifest File not parsed correctly'},
		{'field': 'error.scormVersionIsnt1_2',						'default' : 'Scorm Version is not 1.2, it is: {{version}}'},
		{'field': 'error.thisLmsDoenstSupportScorm2004',			'default' : 'This LMS does not support Scorm 2004.'},
		{'field': 'error.failedToWriteBAT',							'default' : 'Failed to write BAT'},
		{'field': 'error.failedToConnect',							'default' : 'Failed to connect'},
		{'field': 'error.failedToUpload',							'default' : 'Failed to upload'},
		{'field': 'error.failedToSetLocalPath',						'default' : 'Failed to set local path'},
		{'field': 'error.failedToWriteBatFile',						'default' : 'Failed to write Bat File'},
		{'field': 'error.noEmployeeByUID',							'default' : 'No employee by uid'},
		{'field': 'error.employeeIsInactive',						'default' : 'Employee is inactive'},
		{'field': 'error.thePermissionOfEmployeIdExceed',			'default' : 'The permissions of {{employeeID}} exceed the current user permissions'},
		{'field': 'error.failedToChangeUsersPass',					'default' : 'Failed to change user\'s password'},
		{'field': 'error.failedToUpdateUser',						'default' : 'Failed to update user'},
		{'field': 'error.failedToChangeUsersLang',					'default' : 'Failed to update user\'s selected language'},
		{'field': 'error.failedToDeactivateEmployees',				'default' : 'Failed to deactivate employees'},
		{'field': 'error.noEmployeesByUIDS',						'default' : 'No employees by uids'},
		{'field': 'error.noCourseWasFoundForEmployeeCourseModel',	'default' : 'No course was found for this employeeCoursesModel'},
		{'field': 'error.noCourseWasFoundForThisUser',				'default' : 'No course was found for this user'},
		{'field': 'error.noCourseFoundForThisUser',					'default' : 'No course was found for this user for the specified course(s)'},
		{'field': 'error.noCourseFoundWithinCompany',				'default' : 'No courses found within specified company'},
		{'field': 'error.noCourseFoundWithinUidsAndCourseIds',		'default' : 'No courses found within specified uids and course IDs'},
		{'field': 'error.noCoursesFoundWithinSpecifiedComapny',		'default' : 'No courses found within specified company'},
		{'field': 'error.noCcfMapFoundWithinSpecifiedCompany',		'default' : 'No ccf map found within specified company'},
		{'field': 'error.failedToUpdateBranding',					'default' : 'Failed to update branding'},
		{'field': 'error.noCcfFoundGroupingWithinSpecifiedCompany',	'default' : 'No ccf grouping found within specified company'},
		{'field': 'error.IdAlreadyExistsInTable',					'default' : '{{id}} already exists'},
		{'field': 'error.missingInformation',						'default' : '{{field}} is missing required information'},
		{'field': 'error.missingIdentifier',						'default' : 'Missing Identifier'},
		{'field': 'error.perhapsYouMeantItToBe',					'default' : "...perhaps you meant it to be '{$match}'"},
		{'field': 'error.hasInvalidCharacters',						'default' : '{{input}} has invalid characters'},
		{'field': 'error.isNotAValidEmailAddress',					'default' : '{{inputValue}} is not a valid email address'},
		{'field': 'error.isNotAValidDate',							'default' : '{{inputValue}} is not a valid date'},
		{'field': 'error.isNotAValidAddress',						'default' : '{{inputValue}} is not a valid address'},
		{'field': 'error.isNotAValidAddressFromFile',				'default' : '{{inputValue}} is not a valid address for \'{{employeeID}}\' on row {{row}}'},
		{'field': 'error.isNotAValidEmailAddressFromFile',			'default' : '{{inputValue}} is not a valid email address for \'{{employeeID}}\' on row {{row}}'},
		{'field': 'error.isARequiredField',							'default' : '{{exportAs}} is a required field'},
		{'field': 'error.isARequiredFieldFromFile',					'default' : '{{exportAs}} is a required field for \'{{employeeID}}\' on row {{row}}'},
		{'field': 'error.isAnInvalidEmailAddress',					'default' : '{{value}} is an invalid email address'},
		{'field': 'error.isAnInvalidEmailAddressFromFile',			'default' : '{{value}} is an invalid email address for \'{{employeeID}}\' on row {{row}}'},
		{'field': 'error.failedToReadFile',							'default' : 'Failed to read file'},
		{'field': 'error.permissionIssueMakingExportDIR',			'default' : 'Permission issue making export directory'},
		{'field': 'error.theFileIsNotFound',						'default' : 'The file is not found'},
		{'field': 'error.classDoesntExist',							'default' : 'Class does not exist {{className}}'},
		{'field': 'error.methodDoesntExistInClass',					'default' : 'Method {{function}} does not exist in {{className}}'},
		{'field': 'error.unknownRoleRecognized',					'default' : 'Unknown role recognized: {{currentRole}}'},
		{'field': 'error.unknownReportTypeSpecified',				'default' : 'Unknown Report Type Specified'},
		{'field': 'error.failedToSaveQuery',						'default' : 'Failed to save query'},
		{'field': 'error.failedToSetAsTheDefault',					'default' : 'Failed to set as the default'},
		{'field': 'error.failedToRemoveDefaults',					'default' : 'Failed to remove defaults'},
		{'field': 'error.failedToDeleteFilters',					'default' : 'Failed to delete filters'},
		{'field': 'error.wasAlreadyDeleted',						'default' : '{{name}} was already deleted'},
		{'field': 'error.isMissingFromTheEmail',					'default' : '{{key}} is missing from the email'},
		{'field': 'error.emailAddressIsNotValid',					'default' : 'Email address is not valid: {{address}}'},
		{'field': 'error.emailAddressesAreNotValid',				'default' : 'Email addresses are not valid: {{addresses}}'},
		{'field': 'error.mailerError',								'default' : 'Mailer error: '},
		{'field': 'error.thisNameAlreadyExists',					'default' : 'This name already exists'},
		{'field': 'error.thisAlreadyExists',						'default' : 'This already exists'},

		{'field': 'error.pleaseEnterAName',							'default' : 'Please enter a name'},
		{'field': 'error.createAtLeastOneRule',						'default' : 'You must create at least one rule'},
		{'field': 'error.pleaseCheckTheFilters',					'default' : 'Please check the filters'},
		{'field': 'error.filterPermissionNotAllowed',				'default' : 'This query uses a filter that you do not have permission to view'},
		{'field': 'error.unknownRoleSet',							'default' : 'Unknown Role Set'},
		{'field': 'error.inputIsRequired',							'default' : '{{input}} is required'},
		{'field': 'error.hasHtml',									'default' : '{{input}} has html'},
		{'field': 'error.hasInvalidChars',							'default' : '{{input}} has invalid characters'},
		{'field': 'error.mustBeNumeric',							'default' : '{{input}} must be numeric'},
		{'field': 'error.mustBeAlphaNumeric',						'default' : '{{input}} must be alphanumeric'},
		{'field': 'error.keyIsntRecognizedInDataModel',				'default' : 'The item: {{input}} is not recognized in the {{dataModel}}'},
		{'field': 'error.coursePackageNotValid',					'default' : 'This course does not appear to be valid. It is missing required package information such as an imsmanifest file. Please check with the course developer and try again.'},
		{'field': 'error.theRoleIsRequired',						'default' : 'The Role is a required field and is missing'},
		{'field': 'error.theRoleIsRequiredFromFile',				'default' : 'The Role is a required field and is missing for \'{{employeeID}}\' on row {{row}}'},
		{'field': 'error.theRoleIsntRecognized',					'default' : 'The role {{role}} is not recognized'},
		{'field': 'error.theRoleIsntRecognizedFromFile',			'default' : 'The role {{role}} is not recognized  for \'{{employeeID}}\' on row {{row}}'},

		{'field': 'error.invalidcourseAvailableDateProvided',		'default' : 'The Course Available Date provided for \'{{employeeID}}\' is invalid'},
		{'field': 'error.invalidcourseDueDateProvided',				'default' : 'The Course Due Date Date provided for \'{{employeeID}}\' is invalid'},
		{'field': 'error.invalidcourseExpiresProvided',				'default' : 'The Course Expiration Date provided for \'{{employeeID}}\' is invalid'},
		{'field': 'error.invalidcourseCompletionDateProvided',		'default' : 'The Course Completion Date provided for \'{{employeeID}}\' is invalid'},

		{'field': 'error.eventDeniedDeactivationDueToProcessing',	'default' :  'This event is currently processing. If you would like to stop this event you must delete it, otherwise you will have to wait until the event finishes processing before deactivating it.'},
		{'field': 'error.eventDeniedDeactivationDueToAction',		'default' :  'This event is in the middle of an action. If you would like to stop this event you must delete it, otherwise you will have to wait until the event finishes processing before deactivating it.'},
		{'field': 'error.noCoursesToDownload',						'default' : 'No course selected to download'},


		// Messages
		{'field': 'message.selectLang', 							'default' : 'Please select language'},
		{'field': 'message.selectCompany', 							'default' : 'Please select a company'},
		{'field': 'message.setLang', 								'default' : 'Set Language'},
		{'field': 'message.setCompany', 							'default' : 'Set Company'},
		{'field': 'message.setRole', 								'default' : 'Set Role'},
		{'field': 'message.cancel', 								'default' : 'Cancel'},
		{'field': 'message.emailOnWay',								'default' : 'Your e-mail is on its way!'},
		{'field': 'message.usersFailedToEnroll',					'default' : 'FAILED to enroll {{failedNumber}} users.'},
		{'field': 'message.usersSuccessfullyEnrolled',				'default' : 'Successfully enrolled {{succcessNumber}} users.'},
		{'field': 'message.usersSuccessfullyUnenrolled',			'default' : 'Successfully unenrolled {{succcessNumber}} users.'},
		{'field': 'message.usersWereAlreadyEnrolled',				'default' : '{{skippedNumber}} user(s) were already enrolled in this course.'},
		{'field': 'message.usersFailedToUnEnroll',					'default' : 'FAILED to unenroll {{failedNumber}} user(s).'},
		{'field': 'message.usersWereNotAlreadyEnrolled',			'default' : '{{notEnrolledNumber}} user(s) were not enrolled in this course.'},
		{'field': 'message.inactiveUsersCantBeEnrolled',			'default' : 'Found {{inactiveUsersNumber}} inactive user(s). Inactive users cannot be enrolled in courses.'},
		{'field': 'message.inactiveUsersCantBeUnenrolled',			'default' : 'Found {{inactiveUsersNumber}} inactive user(s). Inactive users cannot be un-enrolled in courses.'},
		{'field': 'message.totalUsersDetected',						'default' : '{{totalUsers}} total user(s) detected'},
		{'field': 'message.usersExcludedFromTemplates',				'default' : 'There are {{totalUsers}} user(s) who fit this criteria but are being excluded by the filtered language selections'},

		{'field': 'message.totalNotFound',							'default' : '{{totalNotFound}} user(s) were not found'},
		{'field': 'message.totalUsersAreInactive',					'default' : '{{totalInactive}} user(s) are inactive'},
		{'field': 'message.noEmployeesLeftToEmail',					'default' : 'There are no employees left to email'},

		{'field': 'message.totalUsersWillBeEnrolled',				'default' : '{{totalToEnroll}} user(s) will be enrolled'},
		{'field': 'message.totalUsersWillBeUnEnrolled',				'default' : '{{totalToUnEnroll}} user(s) will be unenrolled'},

		{'field': 'message.totalUsersNotRecognized',				'default' : '{{totalNotRecognized}} user(s) were not recognized as valid users in the system'},
		{'field': 'message.alreadyCompletedCannotUnenroll',			'default' : '{{totalCompleted}} user(s) already completed this course and cannot be un-enrolled'},
		{'field': 'message.alreadyStartedCannotUnenroll',			'default' : '{{totalStarted}} user(s) already have started this course and cannot be un-enrolled'},

		{'field': 'message.totalUsersAlreadyCompletedThisCourse',	'default' : '{{totalAlreadyCompleted}} user(s) already completed this course'},
		{'field': 'message.totalUsersWillBeMarkedToComplete',		'default' : '{{totalToComplete}} enrollment(s) will be marked as complete'},
		{'field': 'message.totalEnrollmentsWillBeUpdated',			'default' : '{{totalToUpdate}} enrollment(s) will be updated'},
		{'field': 'message.totalEnrollmentsUpdated',				'default' : '{{totalUpdated}} enrollment(s) were updated'},
		
		{'field': 'message.totalUsersMarkedToComplete',				'default' : '{{totalCompleted}} enrollment(s) marked as complete'},
		{'field': 'message.totalFailedToUpdate',					'default' : '{{totalFailedToUpdate}} user(s) failed to update'},


		{'field': 'message.totalUsersWillBeInserted',				'default' : '{{totalAdded}} user(s) will be inserted'},
		{'field': 'message.totalUsersWillBeUpdated',				'default' : '{{totalEdited}} user(s) will be updated'},
		{'field': 'message.totalUsersWillBeReActivated',			'default' : '{{totalActivated}} user(s) will be reactivated'},
		{'field': 'message.totalUsersWillBeDeactivated',			'default' : '{{totalDeactivated}} user(s) will be deactivated based on imported file'},
		{'field': 'message.totalUserConflicts',						'default' : '{{totalConflicts}} user(s) might be duplicates'},
		{'field': 'message.totalUsersWillDeactivateDueFullProc',	'default' : '{{totalFullFileDeactivations}} user(s) will be deactivated due to full file processing'},
		{'field': 'message.ZeroUsersWillBeUpdated',					'default' : '0 user(s) will be updated'},
		{'field': 'message.youHaveCancelledProcessing',				'default' : 'You have cancelled the processing'},
		{'field': 'message.youHaveCancelledUpload',					'default' : 'You have cancelled the upload'},
		{'field': 'message.totalUsersSuccessfullyInserted',			'default' : '{{totalAdded}} user(s) successfully inserted'},
		{'field': 'message.totalUsersSuccessfullyUpdated',			'default' : '{{totalEdited}} user(s) updated successfully'},
		{'field': 'message.totalUsersSuccessfullyReactivated',		'default' : '{{totalActivated}} user(s) reactivated successfully'},
		{'field': 'message.totalUsersSuccessfullyDeactivated',		'default' : '{{totalDeactivated}} user(s) deactivated successfully based on imported file'},
		{'field': 'message.totalUsersDeactivatedDueToFulProcess',	'default' : '{{totalFullFileDeactivations}} user(s) deactivated successfully due to full file processing'},
		{'field': 'message.employeeIdSuccessfullyAdded',			'default' : '{{employeeID}} has been successfully added'},
		{'field': 'message.readyToUpload',							'default' : 'Ready to upload'},
		{'field': 'message.thisCourseIdAlreadyExistsOverwrite',		'default' : 'This course ID already Exists, would you like to overwrite it?'},
		{'field': 'message.thisCourseIdAlreadyExistsDifferent',		'default' : 'This course ID already Exists, please input a different Course ID.'},
		{'field': 'message.anotherCourseIsUsingThisCourseID',		'default' : 'Another course is using this courseID (Though it is not stored in the database)'},
		{'field': 'message.sentToSelf',								'default' : 'The email has been successfully sent to you'},
		{'field': 'message.sentSuccessfullyEmails',					'default' : 'Sent successfully to {{totalSentEmails}} user(s)'},
		{'field': 'message.totalExcludedUsers',						'default' : 'Excluded {{totalExluded}} user(s)'},
		{'field': 'message.failedToSendEmailTo',					'default' : 'Failed to send email(s) to {{failedOnEmail}}'},
		{'field': 'message.localizationsSuccessfullyInserted',		'default' : '{{totalAdded}} localization(s) successfully inserted'},
		{'field': 'message.localizationsSuccessfullyUpdated',		'default' : '{{totalUpdated}} localization(s) updated successfully'},
		{'field': 'message.localizationsUnexpectedIssues',			'default' : 'There were {{totalFailed}} unexpected issue(s). Please try again. If the issue persists, please contact <a href="mailto:support@trueoffice.com">support@trueoffice.com</a>'},
		{'field': 'message.totalEmailUnexpectedIssues',				'default' : 'There were {{totalFailed}} unexpected issue(s), most likely due to users with invalid email addresses. <br /><br /><span style="font-size: 12px">If you need more information, please contact <a href="mailto:support@trueoffice.com">support@trueoffice.com</a> (Reference ID: {{auditID}})</span>'},
		
		{'field': 'message.totalLocalizationsDetected',				'default' : '{{numberToProcess}} total localization(s) detected'},
		{'field': 'message.totalLocalizationsWillBeAdded',			'default' : '{{totalToBeAdded}} localization(s) will be inserted'},
		{'field': 'message.totalLocalizationsWillBeUpdated',		'default' : '{{numberToBeUpdated}} localization(s) will be updated'},
		{'field': 'message.totalLocalizationsIssues',				'default' : 'There are {{totalFailed}} issue(s) with the file'},
		{'field': 'message.emailToUserToResetPass',					'default' : 'The user has been sent an email to reset their password'},
		{'field': 'message.profileSuccessfullyUpdated',				'default' : 'Profile has been successfully updated'},
		{'field': 'message.saving',									'default' : 'Saving...'},
		{'field': 'message.updating',								'default' : 'Updating...'},
		{'field': 'message.preparingEmail',							'default' : 'Preparing Email...'},
		{'field': 'message.renderingEmail',							'default' : 'Rendering Email...'},
		{'field': 'message.sendingEmail',							'default' : 'Sending Email...'},
		{'field': 'message.addedSuccessfully',						'default' : 'Added Successfully'},
		{'field': 'message.companyAdded',							'default' : '<div>Your new company, <strong>{{coName}}</strong>, has been created.</div>'},
		{'field': 'message.updatedSuccessfully',					'default' : 'Updated Successfully'},
		{'field': 'message.companyUpdated',							'default' : '<div>Changes to company <strong>{{coName}}</strong> have been saved.</div>'},
		{'field': 'message.savedSuccessfully',						'default' : 'Saved Successfully'},
		{'field': 'message.removedSuccessfully',					'default' : 'Removed Successfully'},
		{'field': 'message.activatedSuccessfully',					'default' : 'Activated Successfully'},
		{'field': 'message.deactivatedSuccessfully',				'default' : 'Deactivated Successfully'},
		{'field': 'message.companyActivated',						'default' : '<div>Company <strong>{{coName}}</strong> is now active.</div>'},
		{'field': 'message.companyDeactivated',						'default' : '<div>Company <strong>{{coName}}</strong> is now inactive.</div>'},
		{'field': 'message.savedRefresh',							'default' : 'Saved successfully, the screen will refresh momentarily to take full effect'},
		{'field': 'message.deleting',								'default' : 'Deleting...'},
		{'field': 'message.deletedSuccessfully',					'default' : 'Deleted Successfully'},
		{'field': 'message.somethingWentWrongWhileDeleting',		'default' : 'Something failed trying to delete this'},
		{'field': 'message.displayingEmployees',					'default' : 'Displaying {{totalAllowed}} of {{total}} employee(s). Please narrow down your search'},
		{'field': 'message.numberOfFoundEmployees',					'default' : 'Found {{total}} employee(s)'},
		{'field': 'message.noEmployeesFound',						'default' : 'No Results Found'},
		{'field': 'message.itemsPerPage',							'default' : 'Items per page'},
		{'field': 'message.display',								'default' : 'Display'},
		{'field': 'message.displayingRecords',						'default' : 'Displaying records {{lower}} - {{upper}} of {{total}}'},
		{'field': 'message.totalSuccessfullyReactivated',			'default' : '{{totalActivated}} reactivated successfully'},
		{'field': 'message.totalSuccessfullyDeactivated',			'default' : '{{totalDeactivated}} deactivated successfully based on imported file'},
		{'field': 'message.totalDeactivatedDueToFulProcess',		'default' : '{{totalFullFileDeactivations}} deactivated successfully due to full file processing'},
		{'field': 'message.totalWillBeReActivated',					'default' : '{{totalActivated}} will be reactivated'},
		{'field': 'message.totalWillBeDeactivated',					'default' : '{{totalDeactivated}} will be deactivated based on imported file'},
		{'field': 'message.totalWillDeactivateDueFullProc',			'default' : '{{totalFullFileDeactivations}} will be deactivated due to full file processing'},
		{'field': 'message.eventTemplateMissingFilters',			'default' : 'NOTE: Scheduled emails with multiple templates should have the language filters applied to ensure that users receive a template in their preferred language'},
		{'field': 'message.updatingRecords',						'default' : 'Updating Records...'},

		// Generic Import Messages
		{'field': 'message.totalIssuesWithTheFile',					'default' : 'There are {{totalFailed}} issue(s) with the file'},
		{'field': 'message.totalNumberOfUnexpectedIssues',			'default' : 'There were {{totalFailed}} unexpected issue(s). Please try again. If the issue persists, please contact <a href="mailto:support@trueoffice.com">support@trueoffice.com</a>'},
		{'field': 'message.totalRecordsDetected',					'default' : '{{numberToProcess}} total records(s) detected'},
		{'field': 'message.totalRecordsWillBeInserted',				'default' : '{{totalAdded}} records(s) will be inserted'},
		{'field': 'message.totalRecordsWillBeUpdated',				'default' : '{{totalEdited}} records(s) will be updated'},
		{'field': 'message.totalRecordsWillBeDeleted',				'default' : '{{totalEdited}} record(s) will be deleted'},
		{'field': 'message.zeroRecordsWillBeUpdated',				'default' : '0 records will be updated'},
		{'field': 'message.totalRecordsSuccessfullyInserted',		'default' : '{{totalAdded}} records successfully inserted'},
		{'field': 'message.totalRecordsSuccessfullyUpdated',		'default' : '{{totalEdited}} records updated successfully'},
		{'field': 'message.totalRecordsSuccessfullyDeleted',		'default' : '{{totalEdited}} records deleted successfully'},
	
		// Filters
		{'field': 'filter.selectOptionSelect',						'default': 'Select an option'},
		{'field': 'filter.equal',									'default': 'equal'},
		{'field': 'filter.not_equal',								'default': 'not equal'},
		{'field': 'filter.in',										'default': 'in'},
		{'field': 'filter.not_in',									'default': 'not in'},
		{'field': 'filter.begins_with',								'default': 'begins with'},
		{'field': 'filter.not_begins_with',							'default': 'doesn\'t begin with'},
		{'field': 'filter.contains',								'default': 'contains'},
		{'field': 'filter.not_contains',							'default': 'doesn\'t contain'},
		{'field': 'filter.ends_with',								'default': 'ends with'},
		{'field': 'filter.not_ends_with',							'default': 'doesn\'t end with'},
		{'field': 'filter.is_empty',								'default': 'is empty'},
		{'field': 'filter.is_not_empty',							'default': 'is not empty'},
		{'field': 'filter.is_null',									'default': 'is null'},
		{'field': 'filter.is_not_null',								'default': 'is not null'},
		{'field': 'filter.greater',       							'default': 'greater'},
		{'field': 'filter.greater_or_equal',        				'default': 'greater or equal'},
		{'field': 'filter.less',       								'default': 'less'},
		{'field': 'filter.less_or_equal',         					'default': 'less or equal'},
		{'field': 'filter.between',       							'default': 'between'},
		{'field': 'filter.not_between',         					'default': 'not between'},
		{'field': 'filter.within_past_days',      					'default': 'within the past x day(s)'},
		{'field': 'filter.delete_rule',         					'default': 'Delete'},
		{'field': 'filter.sortBy',         							'default': 'Sort By'},


		// Modals
		{'field': 'modal.userConflictMessage',						'default' : 'WARNING! {{totalConflicts}} employee IDs for this client have leading zeros, but no leading zeros were found in the file being uploaded. This might be because Excel converted values to numbers when saving, which could in turn result in creating duplicate users in the system instead of updating existing users. Are you sure you want to continue?'},
		{'field': 'modal.kickoffuser',				 				'default' :  'This user is currently logged in, are you sure you want to kick this user off?'},
		{'field': 'modal.validatingSession',		 				'default' :  'Validating Session'},
		{'field': 'modal.additionalValidation',		 				'default' :  'Additional Validation...'},
		{'field': 'modal.validating',		 						'default' :  'Validating...'},
		{'field': 'modal.noChangesDetected', 						'default' :  'No Changes Detected'},
		{'field': 'modal.reloading',								'default' : 'Please wait while your screen reloads'},
		{'field': 'modal.reloadingSupport',								'default' : 'Please wait while we attempt to open Microsoft Edge as this browser is no longer supported'},

		{'field': 'modal.yes',		 								'default' :  'Yes'},
		{'field': 'modal.no',		 								'default' :  'No'},
		{'field': 'modal.pleaseInput',	 							'default' :  'Please Input'},
		{'field': 'modal.loading',		 							'default' :  'Loading'},
		{'field': 'modal.loadingRecords',		 					'default' :  'Loading Records'},
		{'field': 'modal.loadingReport',		 					'default' :  'Loading Report'},
		{'field': 'modal.processingRecords',		 				'default' :  'Processing Records'},
		{'field': 'modal.processingFile',			 				'default' :  'Processing File'},
		{'field': 'modal.parsingFile',				 				'default' :  'Parsing File'},
		{'field': 'modal.formattingData',				 			'default' :  'Formatting Data'},
		{'field': 'modal.notYetCompleted',				 			'default' :  'User has not yet completed this course'},

		{'field': 'modal.writingFile',					 			'default' :  'Writing File'},
		{'field': 'modal.uploadingFile',				 			'default' :  'Uploading File'},
		{'field': 'modal.validatingFile',				 			'default' :  'Validating File'},
		{'field': 'modal.readingFile',				 				'default' :  'Reading File'},
		{'field': 'modal.zippingFiles',				 				'default' :  'Zipping File'},
		{'field': 'modal.mergingCourses',				 			'default' :  'Merging Courses'},


		{'field': 'modal.finalizing',				 				'default' :  'Finalizing'},
		{'field': 'modal.rebuildingData',				 			'default' :  'Rebuilding Data'},
		{'field': 'modal.preparingProcessor',		 				'default' :  'Preparing Processor'},
		{'field': 'modal.checkingForExisting',		 				'default' :  'Checking for existing data'},
		{'field': 'modal.preparingFile',			 				'default' :  'Preparing File'},
		{'field': 'modal.loadingStatistics',		 				'default' :  'Loading Statistics'},
		{'field': 'modal.outputtingResults',		 				'default' :  'Outputting Results'},
		{'field': 'modal.heardCancelled',		 					'default' :  'Heard cancel'},
		{'field': 'modal.deactivatingEmployees',			 		'default' :  'Deactivating Employees'},
		{'field': 'modal.saving',									'default' :  'Saving...'},

		{'field': 'modal.uploading',		 						'default' :  'Uploading...'},
		{'field': 'modal.stop',		 								'default' :  'Stop'},
		{'field': 'modal.stopping',		 							'default' :  'Stopping...'},
		{'field': 'modal.no',			 							'default' :  'No'},
		{'field': 'modal.submit',			 						'default' :  'Submit'},
		{'field': 'modal.courseStarted',			 				'default' :  'Course Started'},
		{'field': 'modal.lookingUpEmployees', 						'default' :  'Looking up employees...'},
		{'field': 'modal.removingEnrollments', 						'default' :  'Removing Enrollments from users...'},
		{'field': 'modal.enrollingUsers', 							'default' :  'Enrolling users...'},
		{'field': 'modal.launchingCourse', 							'default' :  'Launching Course'},
		{'field': 'modal.updatingAssignedCourses', 					'default' :  'Updating Assigned Courses...'},
		{'field': 'modal.courseConfigError', 						'default' :  'It looks like something went wrong with the course configuration. If the issue persists, please contact <a href="mailto:support@trueoffice.com">support@trueoffice.com</a>'},
		{'field': 'modal.systemTookTooLong',	 					'default' :  'It appears the system took too long to respond to your query. Please try limiting your results and try again'},
		{'field': 'modal.confirmCloneRole',							'default' :  'Are you sure you want to clone this role?'},
		{'field': 'modal.confirmReset',								'default' :  'Are you sure you want to reset this data'},
		{'field': 'modal.confirmLoad',			 					'default' :  'Found {{total}} records. Are you sure you want to load these results?'},
		{'field': 'modal.tooManyFound',	 							'default' :  'Found {{tooManyTotal}} records. The system can only handle {{maxAmount}} at a time. Please modify your query and try again.'},
		{'field': 'modal.tooManyFoundInEnroll',	 					'default' :  'Found {{tooManyTotal}} records. The system can only handle {{maxAmount}} at a time. Please modify your query and try again.'},
		{'field': 'modal.noEmployeesMatchCriteria', 				'default' : 'No employees found matching this criteria'},
		{'field': 'modal.criticalErrorOccurred', 					'default' : 'A critical error occurred'},
		{'field': 'modal.noEmployeesFound',							'default' : 'No Employees Found'},
		{'field': 'modal.processing',								'default' : 'Processing...'},
		{'field': 'modal.authenticating',							'default' : 'Authenticating...'},
		{'field': 'modal.failedToLoadEmployeeToEdit',				'default' : 'Failed to load the employee to edit'},
		{'field': 'modal.notAuthorizedToEditYourProfile', 			'default' :  'You are not authorized to edit your profile'},
		{'field': 'modal.notAuthorizedToEditProfile',	 			'default' :  'You are not authorized to edit this profile'},
		{'field': 'modal.notAuthorizedToEditPassword',	 			'default' :  'You are not authorized to change your password'},
		{'field': 'modal.failedToExportEmployees',					'default' : 'Failed to export employees'},
		{'field': 'modal.errorOccurred',							'default' : 'An error occurred'},
		{'field': 'modal.popupBlockError', 							'default' : 'It looks like you have a pop up blocker enabled. <br /> Please allow pop ups and try again'},
		{'field': 'modal.courseLaunching',							'default' : 'Course is Launching...'},
		{'field': 'modal.courseLoading',							'default' : 'Course is Loading...'},
		{'field': 'modal.closingCourse',							'default' : 'Closing Course...'},
		{'field': 'modal.courseOpen',								'default' : 'Course is Open'},
		{'field': 'modal.windowClosing',							'default' : 'Window is Closing'},
		{'field': 'modal.close',									'default' : 'Close'},
		{'field': 'modal.tryAgain',									'default' : 'Try Again'},
		{'field': 'modal.courseClosing',							'default' : 'Course is Closing'},
		{'field': 'modal.reportLoading',							'default' : 'Report is Loading...'},
		{'field': 'modal.areYouSureTitle',							'default' : 'Are you sure?'},
		{'field': 'modal.areYouSureDeleteRecords',					'default' : 'Are you sure you want to delete these records?'},

		{'field': 'modal.unSavedChanges',							'default' : 'You have unsaved changes. Are you sure you want to exit this screen?'},
		{'field': 'modal.areYouSureDeleteName',						'default' : 'Are you sure you want to delete {{name}}?'},
		{'field': 'modal.areYouSureDeleteGeneric',					'default' : 'Are you sure you want to delete this?'},
		{'field': 'modal.areYouSureSendUnsavedEmails',				'default' : 'You have unsaved changes. Are you sure you want to send this email?'},
		{'field': 'modal.areYouSureSendAll',						'default' : 'Are you sure you want to send this email to all the listed users?'},
		{'field': 'modal.recommendTestEmail',						'default' : 'It is recommended to send a test email first to ensure everything looks ok'},
		{'field': 'modal.recordingTopics',		 					'default' : 'Recording Topics'},
		{'field': 'modal.recordingCatalogs',		 				'default' : 'Recording Catalogs'},
		{'field': 'modal.recordingLanguages',		 				'default' : 'Recording Languages'},
		{'field': 'modal.recordingCourseTypes',		 				'default' : 'Recording Course Types'},
		{'field': 'modal.activeEventsWarning',		 				'default' : 'You have active event(s) associated with this template, are you sure you want to change this template which will be applied for these event(s)?'},
		{'field': 'modal.activeFiltersWarning',		 				'default' : 'You have active event(s) associated with this filter, are you sure you want to change this filter which will be applied for these event(s)?'},
		{'field': 'modal.eventDeniedTemplateDeletion',				'default' : 'You have event(s) associated with this template, in order to delete a template, you must delete all event(s) associated with it.'},
		{'field': 'modal.eventDeniedFilterDeletion',				'default' : 'You have event(s) associated with this filter, in order to delete a filter, you must delete all event(s) associated with it.'},
		{'field': 'modal.modelCreateSuccess',		 				'default' : '{{modelName}} created successfully'},
		{'field': 'modal.modelCreateFail',		 					'default' : 'Failed to save {{modelName}}'},
		{'field': 'modal.modelCopySuccess',		 					'default' : '{{modelName}} copied successfully'},
		{'field': 'modal.modelCopyFail',		 					'default' : 'Failed to copy {{modelName}}'},
		{'field': 'modal.modelSaveSuccess',		 					'default' : '{{modelName}} saved successfully'},
		{'field': 'modal.modelSaveFail',		 					'default' : 'Failed to save {{modelName}}'},
		{'field': 'modal.modelDeleteSuccess',						'default' : '{{modelName}} deleted successfully'},
		{'field': 'modal.modelDeleteFail',		 					'default' : 'Failed to delete {{modelName}}'},
		{'field': 'modal.modelExportSuccess',						'default' : '{{modelName}} exported successfully'},
		{'field': 'modal.modelExportFail',		 					'default' : 'Failed to export {{modelName}}'},
		{'field': 'modal.noUsersProcessed',		 					'default' : 'There were no users processed'},
		{'field': 'modal.tooManyContactUs',		 					'default' : 'The system is unable to export this many records. Please submit a request to <a href="mailto:service@trueoffice.com">service@trueoffice.com</a> for this data'},
		{'field': 'modal.duplicateEventSave',		 				'default' : 'An existing event with the same course and type as this one is already active, it is called {{duplicateName}}. Are you sure you want to create a new one?'},
		{'field': 'modal.eventSaved',		 						'default' : 'Event Saved.'},
		{'field': 'modal.newEventsAreInactive',		 				'default' : 'New and edited events are inactive by default, you must activate it for it to run.'},
		{'field': 'modal.eventEditFailed',		 					'default' : 'Failed to edit event.'},
		{'field': 'modal.eventCreateFailed',		 				'default' : 'Failed to create event.'},
		{'field': 'modal.dateTooSoon',		 						'default' : 'Date is too soon.'},
		{'field': 'modal.dateInvalid',		 						'default' : 'Job would not run on requested date. Setting start date to next date that the job would run.'},
		{'field': 'modal.dateValidationError',						'default' : 'Chosen date is not valid and a usable date could not be found'},
		{'field': 'modal.dateSetTo',		 						'default' : 'The date has been set to {{newDate}}'},
		{'field': 'modal.descriptionOf',		 					'default' : 'Description of {{describedObject}}'},
		{'field': 'modal.eventActivateSuccess',		 				'default' : 'Event Activated'},
		{'field': 'modal.eventActivateFail',		 				'default' : 'Failed to activate event.'},
		{'field': 'modal.emaileventsTimeNotification',				'default' : 'Scheduled Emails are sent between 11am and 1pm CST beginning on the scheduled start date'},
		{'field': 'modal.enrollmenteventsTimeNotification',			'default' : 'Scheduled Enrollments are processed between 7am and 9m CST beginning on the scheduled start date'},
		{'field': 'modal.confirmActivation',		 				'default' : 'Are you sure you want to activate {{event}}'},
		{'field': 'modal.blockEventEditConfirm',		 			'default' : 'Active Schedules cannot be edited, would you like to deactivate: {{event}}?'},
		{'field': 'modal.eventDeactivateSuccess',		 			'default' : 'Event deactivated'},
		{'field': 'modal.eventDeactivateFail',		 				'default' : 'Failed to deactivate event.'},
		{'field': 'modal.eventDeactivateConfirm',		 			'default' : 'Are you sure you want to deactivate {{event}}'},
		{'field': 'modal.eventDeleteConfirm',		 				'default' : 'Are you sure you would like to delete: {{event}} ?'},
		{'field': 'modal.eventDeleteActiveConfirm',		 			'default' : '<b class="text-danger">{{event}} is currently active, are you sure you would like to delete it?</b>'},
		{'field': 'modal.eventDeleteActiveFail',					'default' : '{{event}} is currently active, it must be deactivated before it can be deleted.'},
		{'field': 'modal.eventDeleteSuccess',		 				'default' : 'Event deleted'},
		{'field': 'modal.eventDeleteFail',		 					'default' : 'Failed to delete event.'},
		{'field': 'modal.backgroundDownload',						'default' : 'Due to the large amount of data, the system will continue to download in the background'},
		{'field': 'modal.largeExportWarning',						'default' : 'Exporting over {{number}} records may take awhile. Are you sure you want to export these records?'},
		{'field': 'modal.bePatientDuringProcess',					'default' : 'Please be patient as the browser may be temporarily unresponsive during this process'},
		{'field': 'modal.exitDownload',								'default' : 'The system is still downloading records, you must first stop the download to proceed'},
		{'field': 'modal.exitAsync',								'default' : 'The system is still processing, you must first stop the process to proceed'},
		{'field': 'modal.stillDownloading',							'default' : 'Please wait, the system is still downloading records'},
		{'field': 'modal.permissionDeniedForNotificaions', 			'default' : 'Permission was denied to send notifications, please check your browser settings to allow notifications for this site'},
		{'field': 'modal.notSupported',					 			'default' : 'Your browser does not support this method'},
		{'field': 'modal.wouldYouLikeToSendNotification',			'default' : 'Would you like us to send you a browser notification when this process finishes running?'},
		{'field': 'modal.changeSuccess',							'default' : 'Change made successfully'},
		{'field': 'modal.changeFail',								'default' : 'Failed to complete change'},
		{'field': 'modal.modifyingPermissions', 					'default' : 'Modifying Permissions...'},
		{'field': 'modal.modifyingCompanies', 						'default' : 'Modifying assigned companies...'},
		{'field': 'modal.modifyingRoles', 							'default' : 'Modifying assigned roles...'},
		{'field': 'modal.impersonationBegin',						'default' : 'You are now impersonating {{firstName}} {{lastName}}'},
		{'field': 'modal.impersonationEnd',							'default' : 'Impersonation has ended'},
		{'field': 'modal.selectImpersonation',						'default' : 'Please select a person to Impersonate'},
		{'field': 'modal.selectPerson',								'default' : 'Select a person'},
		{'field': 'modal.impersonate',								'default' : 'Impersonate'},
		{'field': 'modal.fieldModifiedGlobally',					'default' : 'This field was modified globally, please choose the GLOBAL role and reset it from there'},
		{'field': 'modal.passChanged',								'default' : 'Password was set successfully'},
		{'field': 'modal_setPassword_title',						'default' : 'Enter a password for this employee'},
		{'field': 'modal_setPassword_set',							'default' : 'Set Password'},

		// Push Notifications
		{'field': 'notifications.learnIOSentMessage',				'default' : 'Learn IO sent you a message'},
		{'field': 'notifications.queryReady',						'default' : 'Your query is ready'},
		{'field': 'notifications.dashboardReady',					'default' : 'Your dashboard results are complete and ready for action!'},
		{'field': 'notifications.permissionGranted',				'default' : 'Permission was successfully granted to send notifications!'},
		{'field': 'notifications.notSupported',					 	'default' : 'Your browser does not support sending notifications'},


		// Log out
		{'field': 'logout.crumb',									'default' : 'Log Out'},
		{'field': 'logout.title',									'default' : 'Logging Out'},
		{'field': 'logout.loggedOut',								'default' : 'Logging Out'},
		{'field': 'blank',											'default' : ''},


		// Course Launch
		{'field': 'courselaunch.crumb',								'default' : 'Course Launch'},
		{'field': 'courselaunch.title',								'default' : 'Course Launch'},
		{'field': 'courselaunch.opening',							'default' : 'Launching Course...'},
		{'field': 'courselaunch.inPopup',							'default' : 'The course is currently open in a popup window'},
		{'field': 'courselaunch.closeCourse',						'default' : 'Close Course'},
		{'field': 'courselaunch.exit',								'default' : 'Exit Course'},
		{'field': 'courselaunch.fullScreen',						'default' : 'Go Full Screen'},
		{'field': 'courselaunch.exitFullScreen',					'default' : 'Exit Full Screen'},
		{'field': 'courselaunch.404Error',							'default' : 'The course path is not valid, please check the URL and try again'},
		{'field': 'courselaunch.previewError',						'default' : 'Something did not load correctly during the preview of this catalog, our support team was notified and will be working on the issue shortly'},
		{'field': 'courselaunch.openNewWindow',						'default' : 'Open the course in a new window'},
		{'field': 'courselaunch.courseIncomplete',					'default' : 'Looks like you completed the disclosure, however you have not finished the course itself.  Please make sure to relaunch the course and finish the remaining steps'},

		{'field': 'logout.header',									'default' : 'You have successfully logged out of the LMS.'},
		{'field': 'logout.subheader',								'default' : 'You may now close this browser page.'},

		{'field': 'tooltip.allowHTMLtemplates',						'default' : 'Allows for HTML text in email templates'},
		{'field': 'tooltip.supressLangs',							'default' : 'Suppresses adding a language input on forms'},
		{'field': 'tooltip.suppressCCFValues',						'default' : 'Toggles the loading of client CCF information to speed up the login process'},
		{'field': 'tooltip.hideLangMenu',							'default' : 'Hides the choose language menu in the profile'},
		{'field': 'tooltip.canUploadCourses',						'default' : 'Allows the CLIENT ADMIN to upload courses'},
		{'field': 'tooltip.allowCourseRestart',						'default' : 'Allows for learners to retake their completed training'},
		{'field': 'tooltip.daysUntilPasswordExpiration',			'default' : 'The number of days until a password expires, (-1 wil never expire)'},
		{'field': 'tooltip.canSelfRegister',						'default' : 'Allows for self registration '},
		{'field': 'tooltip.showCourseID',							'default' : 'Shows the course ID on the training page'},
		{'field': 'tooltip.internalIPs',							'default' : 'The internal IP addresses for GLOBAL ADMINs to log in with'},
		{'field': 'tooltip.catalogAdminField',						'default' : 'The employee field that contains the email address of the catalog sales contact'},
		{'field': 'tooltip.logTask',								'default' : 'For DEVELOPERS to add all SQL statements to the audit history for debugging'},
		{'field': 'tooltip.debugTask',								'default' : 'For DEVELOPERS to ouput all SQL statements to the DOM for debugging '},
		{'field': 'tooltip.privateQuery',							'default' : 'Toggle this button to switch between saving this query just for you (Private) or make it available for your company (Public).'},
		{'field': 'tooltip.clientCustomField1Type',					'default' : 'The field type for Client Custom Field 1'},
		{'field': 'tooltip.clientCustomField2Type',					'default' : 'The field type for Client Custom Field 2'},
		{'field': 'tooltip.clientCustomField3Type',					'default' : 'The field type for Client Custom Field 3'},
		{'field': 'tooltip.clientCustomField4Type',					'default' : 'The field type for Client Custom Field 4'},
		{'field': 'tooltip.clientCustomField5Type',					'default' : 'The field type for Client Custom Field 5'},
		{'field': 'tooltip.clientCustomField6Type',					'default' : 'The field type for Client Custom Field 6'},
		{'field': 'tooltip.clientCustomField7Type',					'default' : 'The field type for Client Custom Field 7'},
		{'field': 'tooltip.clientCustomField8Type',					'default' : 'The field type for Client Custom Field 8'},
		{'field': 'tooltip.clientCustomField9Type',					'default' : 'The field type for Client Custom Field 9'},
		{'field': 'tooltip.clientCustomField10Type',				'default' : 'The field type for Client Custom Field 10'},
		{'field': 'tooltip.clientCustomField11Type',				'default' : 'The field type for Client Custom Field 11'},
		{'field': 'tooltip.clientCustomField12Type',				'default' : 'The field type for Client Custom Field 12'},
		{'field': 'tooltip.clientCustomField13Type',				'default' : 'The field type for Client Custom Field 13'},
		{'field': 'tooltip.clientCustomField14Type',				'default' : 'The field type for Client Custom Field 14'},
		{'field': 'tooltip.clientCustomField15Type',				'default' : 'The field type for Client Custom Field 15'},
		{'field': 'tooltip.clientCustomField16Type',				'default' : 'The field type for Client Custom Field 16'},
		{'field': 'tooltip.clientCustomField17Type',				'default' : 'The field type for Client Custom Field 17'},
		{'field': 'tooltip.clientCustomField18Type',				'default' : 'The field type for Client Custom Field 18'},
		{'field': 'tooltip.clientCustomField19Type',				'default' : 'The field type for Client Custom Field 19'},
		{'field': 'tooltip.clientCustomField20Type',				'default' : 'The field type for Client Custom Field 20'},
		{'field': 'tooltip.clientCustomField21Type',				'default' : 'The field type for Client Custom Field 21'},
		{'field': 'tooltip.clientCustomField22Type',				'default' : 'The field type for Client Custom Field 22'},
		{'field': 'tooltip.clientCustomField23Type',				'default' : 'The field type for Client Custom Field 23'},
		{'field': 'tooltip.clientCustomField24Type',				'default' : 'The field type for Client Custom Field 24'},
		{'field': 'tooltip.clientCustomField25Type',				'default' : 'The field type for Client Custom Field 25'},
		{'field': 'tooltip.clientCustomField26Type',				'default' : 'The field type for Client Custom Field 26'},
		{'field': 'tooltip.clientCustomField27Type',				'default' : 'The field type for Client Custom Field 27'},
		{'field': 'tooltip.clientCustomField28Type',				'default' : 'The field type for Client Custom Field 28'},
		{'field': 'tooltip.clientCustomField29Type',				'default' : 'The field type for Client Custom Field 29'},
		{'field': 'tooltip.clientCustomField30Type',				'default' : 'The field type for Client Custom Field 30'},
		
		// Schedule Emails
		{'field': 'scheduleEmails.crumb',							'default' : 'Schedule Emails'},
		{'field': 'scheduleEmails.title',							'default' : 'Schedule Emails'},

		{'field': 'scheduleEmails.editTemplateSubtitle',			'default' : 'Keywords can be used in templates to customize them for each individual employee'},
		{'field': 'scheduleEmails.editTemplateTitle',				'default' : 'Edit Email Templates'},
		{'field': 'scheduleEmails.editTemplateTab',					'default' : 'Email Templates'},

		{'field': 'scheduleEmails.eventFormSubtitle',				'default' : 'Setup an Email to be sent on a schedule'},
		{'field': 'scheduleEmails.eventFormTitle',					'default' : 'Schedule an Email'},
		{'field': 'scheduleEmails.eventFormTab',					'default' : 'Schedule an Email'},

		{'field': 'scheduleEmails.eventListSubtitle',				'default' : 'Active Email Schedules are colored green'},
		{'field': 'scheduleEmails.eventListTitle',					'default' : 'View and Edit Email Schedule'},
		{'field': 'scheduleEmails.eventListTab',					'default' : 'Email Schedule'},

		{'field': 'scheduleEmails.whichTemplate',					'default' : 'Which E-Mail Template(s) should we use?'},
		{'field': 'scheduleEmails.wouldYouLikeMangerEmailed',		'default' : 'Should Managers be CC\'d on emails?'},

		{'field': 'scheduleEmails.reccurInterval',					'default' : 'How often should this run?'},
		{'field': 'scheduleEmails.theseWeekDays',					'default' : 'On these days of the week'},
		{'field': 'scheduleEmails.thisWeekDay',						'default' : 'On this day of the week'},
		{'field': 'scheduleEmails.startDateUntil',					'default' : 'Begin sending emails on'},
		{'field': 'scheduleEmails.startDate',						'default' : 'On this date?'},
		{'field': 'scheduleEmails.thisDayOfMonth',					'default' : 'On this day of the month'},
		{'field': 'scheduleEmails.everyWeeks',						'default' : 'Every X Weeks'},
		{'field': 'scheduleEmails.everyMonths',						'default' : 'Every X Months'},

		{'field': 'scheduleEmails.once',							'default' : 'Once'},
		{'field': 'scheduleEmails.daily',							'default' : 'Daily'},
		{'field': 'scheduleEmails.weekly',							'default' : 'Weekly'},
		{'field': 'scheduleEmails.monthly',							'default' : 'Monthly'},

		{'field': 'scheduleEmails.sunday',							'default' : 'Sunday'},
		{'field': 'scheduleEmails.monday',							'default' : 'Monday'},
		{'field': 'scheduleEmails.tuesday',							'default' : 'Tuesday'},
		{'field': 'scheduleEmails.wednesday',						'default' : 'Wednesday'},
		{'field': 'scheduleEmails.thursday',						'default' : 'Thursday'},
		{'field': 'scheduleEmails.friday',							'default' : 'Friday'},
		{'field': 'scheduleEmails.saturday',						'default' : 'Saturday'},

		{'field': 'scheduleEmails.sundayShort',						'default' : 'Sun'},
		{'field': 'scheduleEmails.mondayShort',						'default' : 'Mon'},
		{'field': 'scheduleEmails.tuesdayShort',					'default' : 'Tue'},
		{'field': 'scheduleEmails.wednesdayShort',					'default' : 'Wed'},
		{'field': 'scheduleEmails.thursdayShort',					'default' : 'Thu'},
		{'field': 'scheduleEmails.fridayShort',						'default' : 'Fri'},
		{'field': 'scheduleEmails.saturdayShort',					'default' : 'Sat'},

		{'field': 'scheduleEmails.typeRegistration',				'default' : 'Registration'},
		{'field': 'scheduleEmails.typeReminder',					'default' : 'Reminder'},
		{'field': 'scheduleEmails.typePastDue',						'default' : 'Course Overdue'},

		{'field': 'scheduleEmails.setupEvent',						'default' : 'Setup Event'},
		{'field': 'scheduleEmails.saveEvent',						'default' : 'Save Existing Event'},
		{'field': 'scheduleEmails.clearForm',						'default' : 'Clear Form'},

		{'field': 'scheduleEmails.name',							'default' : 'Name'},
		{'field': 'scheduleEmails.description',						'default' : 'Description'},
		{'field': 'scheduleEmails.type',							'default' : 'Type'},
		{'field': 'scheduleEmails.courseID',						'default' : 'Course'},
		{'field': 'scheduleEmails.filters',							'default' : 'Filters'},
		{'field': 'scheduleEmails.dateSent',						'default' : 'Date Sent'},
		{'field': 'scheduleEmails.usersEmailed',					'default' : 'Users Emailed'},
		{'field': 'scheduleEmails.exportUsers',						'default' : 'Export Users'},
		{'field': 'scheduleEmails.viewEvent',						'default' : 'View Event'},
		{'field': 'scheduleEmails.searchPlaceholder',				'default' : 'Enter Search Here'},

		{'field': 'scheduleEmails.pastDueLabel',					'default' : 'Enter minimum number of days past due date'},
		{'field': 'scheduleEmails.untilDueLabel',					'default' : 'Enter number of days until due Date'},
		{'field': 'scheduleEmails.someoneInCourse',					'default' : 'For Someone in these course(s)'},


		//Schedule Email Event List
		{'field': 'scheduleEmailEventList.crumb',					'default' : 'Schedule Emails'},
		{'field': 'scheduleEmailEventList.title',					'default' : 'Schedule Emails'},


		//Schedule Email Event History
		{'field': 'scheduleEmailHistory.crumb',						'default' : 'Mail History'},
		{'field': 'scheduleEmailHistory.title',						'default' : 'Mail History'},
		{'field': 'scheduleEmailHistory.subtitle',					'default' : 'Below is a list of Mail jobs that have run and who they sent emails to'},
		{'field': 'scheduleEmailHistory.title',						'default' : 'Mail History'},
		{'field': 'scheduleEmailHistory.tab',						'default' : 'Mail History'},



		//Schedule Enrollments
		{'field': 'scheduleEnrollments.crumb',						'default' : 'Schedule Enrollments'},
		{'field': 'scheduleEnrollments.title',						'default' : 'Schedule Enrollments'},

		{'field': 'scheduleEnrollments.editTemplateSubtitle',		'default' : 'Keywords can be used in templates to customize them for each individual employee'},
		{'field': 'scheduleEnrollments.editTemplateTitle',			'default' : 'Edit Enrollment Templates'},
		{'field': 'scheduleEnrollments.editTemplateTab',			'default' : 'Enrollment Templates'},

		{'field': 'scheduleEnrollments.queryFormSubtitle',			'default' : 'Enrollment Filters'},
		{'field': 'scheduleEnrollments.queryFormTitle',				'default' : 'Enrollment Filters'},
		{'field': 'scheduleEnrollments.queryFormTab',				'default' : 'Enrollment Filters'},


		{'field': 'scheduleEnrollments.eventFormSubtitle',			'default' : 'Setup an Enrollment to be processed on a schedule'},
		{'field': 'scheduleEnrollments.eventFormTitle',				'default' : 'Schedule an Enrollment'},
		{'field': 'scheduleEnrollments.eventFormTab',				'default' : 'Schedule an Enrollment'},

		{'field': 'scheduleEnrollments.eventListSubtitle',			'default' : 'Active Enrollment Schedules are colored green'},
		{'field': 'scheduleEnrollments.eventListTitle',				'default' : 'View and Edit Enrollment Schedule'},
		{'field': 'scheduleEnrollments.eventListTab',				'default' : 'Enrollment Schedule'},

		{'field': 'scheduleEnrollments.eventHistorySubtitle',		'default' : 'Below is a list of jobs that have run and who were enrolled'},
		{'field': 'scheduleEnrollments.eventHistoryTitle',			'default' : 'Enrollment History'},
		{'field': 'scheduleEnrollments.eventHistoryTab',			'default' : 'Enrollment History'},

		{'field': 'scheduleEnrollments.whichCourse',				'default' : 'Which Course should we enroll?'},
		{'field': 'scheduleEnrollments.reccurInterval',				'default' : 'How often should this run?'},
		{'field': 'scheduleEnrollments.theseWeekDays',				'default' : 'On these days of the week'},
		{'field': 'scheduleEnrollments.thisWeekDay',				'default' : 'On this day of the week'},
		{'field': 'scheduleEnrollments.startDateUntil',				'default' : 'Begin scheduling enrollments on'},
		{'field': 'scheduleEnrollments.startDate',					'default' : 'On this date?'},
		{'field': 'scheduleEnrollments.thisDayOfMonth',				'default' : 'On this day of the month'},
		{'field': 'scheduleEnrollments.everyWeeks',					'default' : 'Every X Weeks'},
		{'field': 'scheduleEnrollments.everyMonths',				'default' : 'Every X Months'},

		{'field': 'schedule.weeklyScheduleRecurrance',				'default' : 'Runs every {{detail}} week(s)'},
		{'field': 'schedule.monthlyScheduleRecurrance',				'default' : 'Runs every {{detail}} month(s)'},


		{'field': 'scheduleEnrollments.once',						'default' : 'Once'},
		{'field': 'scheduleEnrollments.daily',						'default' : 'Daily'},
		{'field': 'scheduleEnrollments.weekly',						'default' : 'Weekly'},
		{'field': 'scheduleEnrollments.monthly',					'default' : 'Monthly'},

		{'field': 'scheduleEnrollments.sunday',						'default' : 'Sunday'},
		{'field': 'scheduleEnrollments.monday',						'default' : 'Monday'},
		{'field': 'scheduleEnrollments.tuesday',					'default' : 'Tuesday'},
		{'field': 'scheduleEnrollments.wednesday',					'default' : 'Wednesday'},
		{'field': 'scheduleEnrollments.thursday',					'default' : 'Thursday'},
		{'field': 'scheduleEnrollments.friday',						'default' : 'Friday'},
		{'field': 'scheduleEnrollments.saturday',					'default' : 'Saturday'},

		{'field': 'scheduleEnrollments.sundayShort',				'default' : 'Sun'},
		{'field': 'scheduleEnrollments.mondayShort',				'default' : 'Mon'},
		{'field': 'scheduleEnrollments.tuesdayShort',				'default' : 'Tue'},
		{'field': 'scheduleEnrollments.wednesdayShort',				'default' : 'Wed'},
		{'field': 'scheduleEnrollments.thursdayShort',				'default' : 'Thu'},
		{'field': 'scheduleEnrollments.fridayShort',				'default' : 'Fri'},
		{'field': 'scheduleEnrollments.saturdayShort',				'default' : 'Sat'},

		{'field': 'scheduleEnrollments.typeRegistration',			'default' : 'Registration'},
		{'field': 'scheduleEnrollments.typeReminder',				'default' : 'Reminder'},
		{'field': 'scheduleEnrollments.typePastDue',				'default' : 'Course Overdue'},

		{'field': 'scheduleEnrollments.setupEvent',					'default' : 'Setup Event'},
		{'field': 'scheduleEnrollments.saveEvent',					'default' : 'Save Existing Event'},
		{'field': 'scheduleEnrollments.clearForm',					'default' : 'Clear Form'},

		{'field': 'scheduleEnrollments.name',						'default' : 'Name'},
		{'field': 'scheduleEnrollments.description',				'default' : 'Description'},
		{'field': 'scheduleEnrollments.type',						'default' : 'Type'},
		{'field': 'scheduleEnrollments.courseID',					'default' : 'Course'},
		{'field': 'scheduleEnrollments.filters',					'default' : 'Filters'},
		{'field': 'scheduleEnrollments.dateSent',					'default' : 'Date Sent'},
		{'field': 'scheduleEnrollments.usersEnrolled',				'default' : 'Users Enrolled'},
		{'field': 'scheduleEnrollments.exportUsers',				'default' : 'Export Users'},
		{'field': 'scheduleEnrollments.viewEvent',					'default' : 'View Event'},
		{'field': 'scheduleEnrollments.searchPlaceholder',			'default' : 'Enter Search Here'},

		{'field': 'scheduleEnrollments.pastDueLabel',				'default' : 'Enter minimum number of days past due date'},
		{'field': 'scheduleEnrollments.untilDueLabel',				'default' : 'Enter the number of days the learner has to complete the course from the time of enrollment'},
		{'field': 'scheduleEnrollments.chooseEnrollin',				'default' : 'Which course to enroll users in'},


		{'field': 'emailTemplate.modelName',						'default' : 'Mail Template'},
		{'field': 'emailTemplate.typeRegistration',					'default' : 'Registration'},
		{'field': 'emailTemplate.typeReminder',						'default' : 'Reminder'},
		{'field': 'emailTemplate.typePastDue',						'default' : 'Course Overdue'},
		{'field': 'emailTemplate.ruleLabel',						'default' : 'Which users will receive this template? Please select the filter that applies to the language for this template.'},


		{'field': 'emailTemplate.sampleCompletionSubject',			'default' : 'You have successfully completed {{courseName}}'},
		{'field': 'emailTemplate.sampleCompletionMessage',			'default' : 'Dear {{firstName}} {{lastName}},\n\nCongratulations! You have successfully completed {{courseName}}!\n\nYou were marked complete as of {{courseCompletionDate|format:Y-m-d }}.\n\nThank you,\n{{companyName}}'},
	
		{'field': 'emailTemplate.sampleRegistrationSubject',		'default' : 'You have been registered in {{courseName}}'},
		{'field': 'emailTemplate.sampleRegistrationMessage',		'default' : 'Dear {{firstName}} {{lastName}},\n\n{{companyName}} is pleased to provide you with Web-based training from True Office Learning.\n\n You have been registered for: \n\nCourse Name: {{courseName}}\n\nCourse Due Date: {{courseDuedate}} \n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkURL}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{companyLoginName}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n\—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology and/or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},

		{'field': 'emailTemplate.sampleReminderSubject',			'default' : 'Reminder to finish {{courseName}}'},
		{'field': 'emailTemplate.sampleReminderMessage',			'default' : 'Dear {{firstName}} {{lastName}},\n\nOur records indicate that you have not yet completed the following course(s): \n\nCourse name: {{courseName}}\n\nCourse due date: {{courseDueDate}}\n\n- If this is the first message you have received regarding the course(s), please follow the instructions below to access the course(s).\n- If you have recently completed the course(s), kindly disregard this message.\n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkURL}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{companyLoginName}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology and/or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},

		{'field': 'emailTemplate.samplePastDueSubject',				'default' : 'You are now past due in {{courseName}}'},
		{'field': 'emailTemplate.samplePastDueMessage',				'default' : 'Dear {{firstName}} {{lastName}},\n\nOur records indicate that you have not yet completed the following course(s) and that you are now past due:\n\nCourse name: {{courseName}}\n\nCourse due date: {{courseDuedate}}\n\n- If this is the first message you have received regarding the course(s), please follow the instructions below to access the course(s).\n- If you have recently completed the course(s), kindly disregard this message.\n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkUrl}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{COMPANYLOGINNAME}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},

		{'field': 'emailTemplateMultiCourse.sampleCompletionSubject',		'default' : 'Course Completion Confirmation'},
		{'field': 'emailTemplateMultiCourse.sampleCompletionMessage',		'default' : 'Dear {{firstName}} {{lastName}},\n\nCongratulations! You have successfully completed \n{% for course in courses %} \n Course ID: {{course.courseID}}\nCourse name: {{course.courseName}}\nCourse completion date:\n{{course.courseCompletionDate}}\n{% endfor %}!\n\nYou were marked complete as of {{courseCompletionDate|format:Y-m-d }}.\n\nThank you,\n{{companyName}}'},

		{'field': 'emailTemplateMultiCourse.sampleRegistrationSubject',		'default' : 'You have been registered in the following courses'},
		{'field': 'emailTemplateMultiCourse.sampleRegistrationMessage',		'default' : 'Dear {{firstName}} {{lastName}},\n\n{{companyName}} is pleased to provide you with Web-based training from True Office Learning.\n\n You have been registered in the following courses: \n{% for course in courses %} \n Course ID: {{course.courseID}}\nCourse name: {{course.courseName}}\nCourse due date: {{course.courseDueDate}}\n{% endfor %}\n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkURL}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{companyLoginName}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n\—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology and/or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},

		{'field': 'emailTemplateMultiCourse.sampleReminderSubject',			'default' : 'Reminder to finish the following courses'},
		{'field': 'emailTemplateMultiCourse.sampleReminderMessage',			'default' : 'Dear {{firstName}} {{lastName}},\n\nOur records indicate that you have not yet completed the following course(s): \n\n\n{% for course in courses %} \n Course ID: {{course.courseID}}\nCourse name: {{course.courseName}}\nCourse due date: {{course.courseDueDate}}\n{% endfor %}\n\n- If this is the first message you have received regarding the course(s), please follow the instructions below to access the course(s).\n- If you have recently completed the course(s), kindly disregard this message.\n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkURL}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{companyLoginName}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology and/or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},

		{'field': 'emailTemplateMultiCourse.samplePastDueSubject',			'default' : 'You are now past due in the following courses'},
		{'field': 'emailTemplateMultiCourse.samplePastDueMessage',			'default' : 'Dear {{firstName}} {{lastName}},\n\nOur records indicate that you have not yet completed the following course(s) and that you are now past due:\n\n\n{% for course in courses %} \n Course ID: {{course.courseID}}\nCourse name: {{course.courseName}}\nCourse due date: {{course.courseDueDate}}\n{% endfor %}\n\n- If this is the first message you have received regarding the course(s), please follow the instructions below to access the course(s).\n- If you have recently completed the course(s), kindly disregard this message.\n\n— LOGIN INSTRUCTIONS —\n\n1. Click {{loginLinkUrl}} and enter the following login information:\n\nEmployee ID: {{employeeID}}\n\nPassword: For security purposes, initial login requires you to reset password, to do so please click on the link {{FORGOTPASSWORDURL}}\n\nFor Password reset enter your:\n\nEmployee ID: {{employeeID}}\n\nCompany ID: {{COMPANYLOGINNAME}}\n\n2. Enter your current password, new password, confirm it and click submit.\n\n3. Once you receive confirmation of the password change click “Go to the home page”.\n\n4. Click the Start Course button next to the course you want to launch.\n\n—TROUBLESHOOTING—\n\nAs with any application delivered over the Internet, you may experience performance interruptions while taking a course. If you come to a screen that will not load or you are unable to advance, follow these steps:\n\n- Exit the course\n\n- Clear your browser’s cache\n\n- Restart the course\n\nIf the problem persists, contact your internal Information Technology or support team. If they are unable to resolve the problem, they will contact the True Office Support Team. Please visit https://www.trueoffice.com/course-faq for other Common Support Topics.\n\n—PASSWORD RETRIEVAL—\n\nTo retrieve a forgotten password, go to {{FORGOTPASSWORDURL}}.'},



		{'field': 'modelEditor.createModel',						'default' : 'Create'},
		{'field': 'modelEditor.copyModel',							'default' : 'Copy'},
		{'field': 'modelEditor.saveModel',							'default' : 'Save'},
		{'field': 'modelEditor.deleteModel',						'default' : 'Delete'},
		{'field': 'modelEditor.newModel',							'default' : 'Create a new {{modelName}}'},

		{'field': 'eventSchedule.name',								'default' : 'Name'},
		{'field': 'eventSchedule.description',						'default' : 'Description'},
		{'field': 'eventSchedule.type',								'default' : 'Type'},
		{'field': 'eventSchedule.startDate',						'default' : 'Start Date'},
		{'field': 'eventSchedule.courses',							'default' : 'Course(s)'},
		{'field': 'eventSchedule.courseID',							'default' : 'Course'},
		{'field': 'eventSchedule.filters',							'default' : 'Filters'},
		{'field': 'eventSchedule.totalUsers',						'default' : 'Current User Count'},
		{'field': 'eventSchedule.templates',						'default' : 'Templates'},
		{'field': 'eventSchedule.recurranceDay',					'default' : 'Runs On'},
		{'field': 'eventSchedule.recurranceDetail',					'default' : 'Frequency'},
		{'field': 'eventSchedule.active',							'default' : 'Active'},
		{'field': 'eventSchedule.edit',								'default' : 'Edit'},
		{'field': 'eventSchedule.delete',							'default' : 'Delete'},
		{'field': 'eventSchedule.weekdays',							'default' : 'Weekdays'},
		{'field': 'eventSchedule.yes',								'default' : 'Yes'},
		{'field': 'eventSchedule.no',								'default' : 'No'},
		{'field': 'eventSchedule.every',							'default' : ' - Every '},
		{'field': 'eventSchedule.weeks',							'default' : ' Week(s)'},
		{'field': 'eventSchedule.months',							'default' : ' Month(s)'},
		{'field': 'eventSchedule.descriptionOf',					'default' : 'Description of '},
		{'field': 'eventSchedule.day',								'default' : 'Day'},
		{'field': 'eventSchedule.daysEachWeek',						'default' : 'Days Each Week'},
		{'field': 'eventSchedule.oneTimeSchedule',					'default' : 'Event Schedule'},
		{'field': 'eventSchedule.oneTimeDetail',					'default' : 'This event will run on {{detail}}'},
		{'field': 'eventSchedule.weeklySchedule',					'default' : 'Weekly Schedule'},
		{'field': 'eventSchedule.monthlySchedule',					'default' : 'Monthly Schedule'},
		{'field': 'eventSchedule.templatesModalTitle',				'default' : 'Email Templates'},
		{'field': 'eventSchedule.schedule',							'default' : 'Schedule'},
		{'field': 'eventSchedule.subject',							'default' : 'Subject'},
		{'field': 'eventSchedule.language',							'default' : 'Language'},
		{'field': 'eventSchedule.total',							'default' : 'Total'},
		{'field': 'eventSchedule.manageEmails',						'default' : 'Manage Emails'},
		{'field': 'eventSchedule.manageEnrollments',				'default' : 'Manage Enrollments'},

		{'field': 'emailEvent.usersWho',							'default' : 'Applies to users who:'},
		{'field': 'emailEvent.isActive',							'default' : 'Are marked active'},
		{'field': 'emailEvent.notComplete',							'default' : 'Have not completed course {{courseID}}'},
		{'field': 'emailEvent.pastDueDate',							'default' : '{{filterVal}} days or more past their due date'},
		{'field': 'emailEvent.dueSoon',								'default' : '{{filterVal}} days or less until their due date'},
		{'field': 'emailEvent.isRegistered',						'default' : 'Have been registered for course {{courseID}}'},
		{'field': 'emailEvent.notEmailed',							'default' : 'Have not been sent a registration email for course {{courseID}}'},
		{'field': 'emailEvent.notSelfEnrolled',						'default' : 'Were not self enrolled'},

		{'field': 'emailEvent.sunday',								'default' : 'Sunday'},
		{'field': 'emailEvent.monday',								'default' : 'Monday'},
		{'field': 'emailEvent.tuesday',								'default' : 'Tuesday'},
		{'field': 'emailEvent.wednesday',							'default' : 'Wednesday'},
		{'field': 'emailEvent.thursday',							'default' : 'Thursday'},
		{'field': 'emailEvent.friday',								'default' : 'Friday'},
		{'field': 'emailEvent.saturday',							'default' : 'Saturday'},

		{'field': 'emailEvent.field',								'default' : 'Field'},
		{'field': 'emailEvent.value',								'default' : 'Value'},

		{'field': 'emailEvent.pastDue',								'default' : 'Past Due'},
		{'field': 'emailEvent.registration',						'default' : 'Registration'},
		{'field': 'emailEvent.reminder',							'default' : 'Reminder'},
		{'field': 'emailEvent.completion',							'default' : 'Completion'},

		{'field': 'enrollmentEvent.usersWho',						'default' : 'Applies to users from the query: {{filterName}}'},
		{'field': 'enrollmentEvent.isActive',						'default' : 'Status is active'},
		{'field': 'enrollmentEvent.notComplete',					'default' : 'Have not completed course {{courseID}}'},
		{'field': 'enrollmentEvent.pastDueDate',					'default' : '{{filterVal}} days or more past their due date'},
		{'field': 'enrollmentEvent.dueSoon',						'default' : '{{filterVal}} days or less until their due date'},
		{'field': 'enrollmentEvent.isRegistered',					'default' : 'Have been registered for course {{courseID}}'},
		{'field': 'enrollmentEvent.notEmailed',						'default' : 'Have not been sent a registration email for course {{courseID}}'},
		{'field': 'enrollmentEvent.notSelfEnrolled',				'default' : 'Were not self enrolled'},

		{'field': 'enrollmentEvent.sunday',							'default' : 'Sunday'},
		{'field': 'enrollmentEvent.monday',							'default' : 'Monday'},
		{'field': 'enrollmentEvent.tuesday',						'default' : 'Tuesday'},
		{'field': 'enrollmentEvent.wednesday',						'default' : 'Wednesday'},
		{'field': 'enrollmentEvent.thursday',						'default' : 'Thursday'},
		{'field': 'enrollmentEvent.friday',							'default' : 'Friday'},
		{'field': 'enrollmentEvent.saturday',						'default' : 'Saturday'},

		{'field': 'enrollmentEvent.field',							'default' : 'Field'},
		{'field': 'enrollmentEvent.value',							'default' : 'Value'},

		{'field': 'enrollmentEvent.pastDue',						'default' : 'Past Due'},
		{'field': 'enrollmentEvent.registration',					'default' : 'Registration'},
		{'field': 'enrollmentEvent.reminder',						'default' : 'Reminder'},
		{'field': 'enrollmentEvent.completion',						'default' : 'Completion'},
		{'field': 'emailHistory.crumb',								'default' : 'Email History'},
		{'field': 'emailHistory.title',								'default' : 'Email History'},

		{'field': 'enrollmentHistory.crumb',						'default' : 'Enrollment History'},
		{'field': 'enrollmentHistory.title',						'default' : 'Enrollment History'},

		//edit permissions
		{'field': 'editPermissions.crumb',							'default' : 'Edit Permissions'},
		{'field': 'editPermissions.title',							'default' : 'Edit Permissions'},
		{'field': 'editPermissions.subtitle',						'default' : 'Assign Roles and Groups to Users, Modify Roles'},
		{'field': 'editPermissions.confirmDeleteRole',				'default' : 'You are about to delete a Role. This can have unforeseen consequences if it is assigned to anyone. Are you absolutely sure?'},
		{'field': 'editPermissions.role',							'default' : 'Role'},
		{'field': 'editPermissions.getEmployees',					'default' : 'Get Employees'},


		{'field': 'delegation.addSuccess',							'default' : 'Delegation added.'},
		{'field': 'delegation.addFail',								'default' : 'Failed to add Delegation.'},
		{'field': 'delegation.removeSuccess',						'default' : 'Delegation removed.'},
		{'field': 'delegation.removeFail',							'default' : 'Failed to remove Delegation.'},

		{'field': 'viewDisclosure.title',							'default' : 'Disclosure'},
		{'field': 'viewDisclosure.crumb',							'default' : 'View Disclosure'},
		
		{'field': 'disclosure.crumb',								'default' : 'Manage Disclosures'},
		{'field': 'disclosure.title',								'default' : 'Manage Disclosures'},
		{'field': 'disclosure.saveSuccess',				 			'default' : 'Disclosure successfully saved.'},
		{'field': 'disclosure.saveFail',				 			'default' : 'Disclosure failed to save.'},
		{'field': 'disclosure.confirmSave',					 		'default' : 'Are you sure you want to save this?'},
		{'field': 'disclosure.confirmClone',					 	'default' : 'Are you sure you want to clone this?'},
		{'field': 'disclosure.confirmDelete',				 		'default' : 'Are you sure you want to delete this?'},
		{'field': 'disclosure.deleteSuccess',						'default' : 'Disclosure successfully deleted.'},
		{'field': 'disclosure.deleteFail',				 			'default' : 'Disclosure failed to delete.'},
		{'field': 'disclosure.confirmActivate',				 		'default' : 'Are you sure you want to activate this?'},
		{'field': 'disclosure.activateSuccess',						'default' : 'Disclosure successfully activated.'},
		{'field': 'disclosure.activateFail',				 		'default' : 'Disclosure failed to activate.'},
		{'field': 'disclosure.confirmArchive',				 		'default' : 'Are you sure you want to archive this?'},
		{'field': 'disclosure.archiveSuccess',						'default' : 'Disclosure successfully archived.'},
		{'field': 'disclosure.archiveFail',				 			'default' : 'Disclosure failed to archive.'},
		{'field': 'disclosure.confirmDeactivate',			 		'default' : 'Are you sure you want to deactivate this?'},
		{'field': 'disclosure.confirmRestore',				 		'default' : 'Are you sure you want to restore this?'},
		{'field': 'disclosure.deactivateSuccess',					'default' : 'Disclosure successfully deactivated.'},
		{'field': 'disclosure.deactivateFail',						'default' : 'Disclosure failed to deactivate.'},
		{'field': 'disclosure.restoreSuccess',						'default' : 'Disclosure successfully restored.'},
		{'field': 'disclosure.restoreFail',				 			'default' : 'Disclosure failed to restore.'},
		{'field': 'disclosure.importTextSuccess',				 	'default' : 'Text was successfully imported.'},
		{'field': 'disclosure.importTextFail',				 		'default' : 'There was a problem with the imported file. {{expected}} fields were expected, but the file contained {{actual}}. Please upload a file with the correct number of fields.'},
		{'field': 'disclosure.noDisclosureID',				 		'default' : 'A disclosure ID was not specified.'},
		{'field': 'disclosure.notFound',				 			'default' : 'The specified disclosure does not exist, is inactive, or has no sections.'},
		{'field': 'disclosure.noSections',				 			'default' : 'The certification specified has no sections.'},
		{'field': 'disclosure.duplicateResponse',					'default' : 'One or more questions in this section have already been responded to.'},
		{'field': 'disclosure.notActiveOrArchived',					'default' : 'The specified disclosure is not active or is archived.'},
		{'field': 'disclosure.responseError',						'default' : 'Failed to save response, please contact support@trueoffice.com for assistance.'},
		{'field': 'disclosure.continue',							'default' : 'Continue'},
		{'field': 'disclosure.importFromXML',						'default' : 'Import from XML'},
		{'field': 'disclosure.importText',							'default' : 'Import text'},
		{'field': 'disclosure.filterByCourseID',					'default' : 'Filter By Course ID'},
		{'field': 'disclosure.existingDisclosures',					'default' : 'Existing Disclosures'},
		{'field': 'disclosure.disclosures',							'default' : 'Disclosures'},
		{'field': 'disclosure.active',								'default' : 'Active'},
		{'field': 'disclosure.editable',							'default' : 'Editable'},
		{'field': 'disclosure.archived',							'default' : 'Archived'},
		{'field': 'error.disclosure.invalidQuestion',				'default' : 'A required question has not been answered.'},
		{'field': 'error.disclosure.invalidQuestionResponseLength',	'default' : 'Text responses must be at least 3 characters long.'},
		{'field': 'error.disclosure.legacyImportFail',				'default' : 'Failed to retrieve legacy disclosure. This could be a wrong file path or a CORS issue.'},
		{'field': 'error.disclosure.alreadyExists',					'default' : 'Disclosure cannot be saved with the requested certificationID, a different disclosure with the same ID already exists at this company.'},

		{'field': 'disclosureReport.title',							'default' : 'Disclosure Reports'},
		{'field': 'disclosureReport.crumb',							'default' : 'Disclosure Reports'},
		{'field': 'disclosureReport.completionsSubtitle',			'default' : 'Users who have fully viewed this disclosure'},
		{'field': 'disclosureReport.completionsTab',				'default' : 'Completions'},
		{'field': 'disclosureReport.clear',							'default' : 'Clear'},

		{'field': 'disclosurePDF.header',							'default' : 'Responses for {{firstName}} {{lastName}}'},
		{'field': 'disclosurePDF.subHeader',						'default' : 'User completed the disclosure on {{courseCompletionDate}}'},
		{'field': 'disclosurePDF.questionLabel',					'default' : 'Question : '},
		{'field': 'disclosurePDF.answerLabel',						'default' : 'Answer : '},

		{'field': 'popup.close',									'default' : 'Close'},

		
		{'field': 'home.documentation',								'default' : 'Help'},
		{'field': 'home.documentationSub',							'default' : 'View Documentation'},
		{'field': 'documentation.crumb',							'default' : 'Documentation'},
		{'field': 'documentation.title',							'default' : 'Documentation'},
		];

		this.pagination = {
				'pageLimit'	: '5',
				'pageLimits': ['5', '10', '50', '100'],
				'sortMode'	: {field : 'field', direction : 'asc'}
		}

		this.langs = [
			{
				'code': 'en',
				'name': 'English',
				'label': 'english',
				'lang': 'English',
				'dateFormat': 'MM/dd/yyyy',
				'dateFormatForExport': 'MM/DD/YYYY hh:mm:ssa',
				'inputCodes' : ['en', 'en-us']
			},
			{
				'code': 'ar',
				'name': 'Arabic',
				'label': 'arabic',
				'lang': 'اللغة العربية',
				'rtl': true,
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : [
					'ar','ara','aao','abh','abv','acq','acw','acx','acy','adf','aeb','aec',
					'afb','ajp','apc','apd','arb','arq','ars','ary','arz','auz','avl','ayh',
					'ayl','ayn','ayp','bbz','pga','shu','ssh']
			},
			{
				'code': 'pt-br',
				'name': 'Brazil Portuguese',
				'label': 'brazilPortuguese',
				'lang': 'Português do Brasil',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['pt','por']
			},
			{
				'code': 'zh-hans',
				'name': 'Chinese Simplified',
				'label': 'chineseSimplified',
				'lang': '中文简体',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'YYYY/MM/DD hh:mm:ssa',
				'inputCodes' : [
					'zh','zho','chi','cdo','cjy','cmn','cpx','czh','czo',
					'gan','hak','hsn','lzh','mnp','nan','wuu','yue']
			},
			{
				'code': 'zh-hant',
				'name': 'Chinese Traditional',
				'label': 'chineseTraditional',
				'lang': ' 中國傳統文化',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'YYYY/MM/DD hh:mm:ssa',
				'inputCodes' : ['zh-hant', 'zh_tw']
			},
			{
				'code': 'cs',
				'name': 'Czech',
				'label': 'czech',
				'lang': 'Čeština',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['cs']
			},
			{
				'code': 'da',
				'name': 'Danish',
				'label': 'danish',
				'lang': 'Dansk',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['da']
			},
			{
				'code': 'nl',
				'name': 'Dutch',
				'label': 'dutch',
				'lang': 'Nederlands',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['nl','nld','dut']
			},
			{
				'code': 'fr',
				'name': 'French',
				'label': 'french',
				'lang': 'Français',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['fr','fra','fre']
			},
			{
				'code': 'fr-ca',
				'name': 'French Canadian',
				'label': 'frenchCanadian',
				'lang': 'French Canadian',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['fr-ca']
			},
			{
				'code': 'de',
				'name': 'German',
				'label': 'german',
				'lang': 'Deutsch',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['de','deu','ger']
			},
			{
				'code': 'he',
				'name': 'Hebrew',
				'label': 'hebrew',
				'lang': 'עִבְרִית',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['he','heb'],
				'rtl': true
			},
			{
				'code': 'id',
				'name': 'Indonesian',
				'label': 'indonesian',
				'lang': ' Indonesia',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['id']
			},
			{
				'code': 'it',
				'name': 'Italian',
				'label': 'italian',
				'lang': 'Italiano',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['it','ita']
			},
			{
				'code': 'ja',
				'name': 'Japanese',
				'label': 'japanese',
				'lang': '日本語',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'YYYY/MM/DD hh:mm:ssa',
				'inputCodes' : ['ja','jpn']
			},
			{
				'code': 'ko',
				'name': 'Korean',
				'label': 'korean',
				'lang': '한국어',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'YYYY/MM/DD hh:mm:ssa',
				'inputCodes' : ['ko','kor']
			},
			{
				'code': 'es-419',
				'name': 'Latin American Spanish',
				'label': 'latinAmericanSpanish',
				'lang': 'Español para Latinoamérica',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['es-419']
			},
			{
				'code': 'no',
				'name': 'Norwegian',
				'label': 'norwegian',
				'lang': ' Norsk',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['no']
			},
			{
				'code': 'pl',
				'name': 'Polish',
				'label': 'polish',
				'lang': 'Polski',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['pl']
			},
			{
				'code': 'ru',
				'name': 'Russian',
				'label': 'russian',
				'lang': 'Русский',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['ru','rus']
			},
			{
				'code': 'es',
				'name': 'Spanish',
				'label': 'spanish',
				'lang': 'Español',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['es','spa']
			},
			{
				'code': 'sv',
				'name': 'Swedish',
				'label': 'swedish',
				'lang': ' Svenska',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['sv']
			},
			{
				'code': 'th',
				'name': 'Thai',
				'label': 'thai',
				'lang': ' ไทย',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['th']
			},
			{
				'code': 'tr',
				'name': 'Turkish',
				'label': 'turkish',
				'lang': 'Türkçe',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['tr']
			},
			{
				'code': 'vi',
				'name': 'Vietnamese',
				'label': 'vietnamese',
				'lang': ' Tiếng Việt',
				'dateFormat': 'dd/MM/yyyy',
				'dateFormatForExport': 'DD/MM/YYYY hh:mm:ssa',
				'inputCodes' : ['vi']
			},
		];
		this.datepickerLangMap = {
			'en' 		: 'en',
			'ar' 		: 'ar',
			'pt-br' 	: 'pt-BR',
			'zh-hans' 	: 'zh-CN',
			'zh-hant' 	: 'zh-HK',
			'cs' 		: 'cs',
			'da' 		: 'da',
			'nl' 		: 'nl',
			'fr' 		: 'fr',
			'fr-ca' 	: 'fr-CA',
			'de' 		: 'de',
			'he' 		: 'he',
			'id' 		: 'id',
			'it' 		: 'it',
			'ja' 		: 'ja',
			'ko' 		: 'ko',
			'es-419' 	: 'es',
			'no' 		: 'no',
			'pl' 		: 'pl',
			'ru' 		: 'ru',
			'es' 		: 'es',
			'sv' 		: 'sv',
			'th'		: 'th',
			'tr' 		: 'tr',
			'vi' 		: 'vi',
		};

		this.items = {
				'name': 'Item Name',
				'model': 'item',
				'type': 'select',
				'width': 300,
				'options': []
		};

		this.fields = new LioFieldArray(
			{
				'name': 'Field Name',
				'model': 'field',
				'type': 'text',
				'visible': false
			},
		);

		this.searches = [
			{
				'id': 'page',
				'name': 'Page',
				'model': 'page',
				'code': 'page',
				'type': 'text',
				'visible': true
			},
			{
				'id': 'languageKey',
				'name': 'Language Key',
				'model': 'languageKey',
				'code': 'languageKey',
				'type': 'text',
				'visible': true
			}
		];
	}
}