/*
 * Service for Controlling navigation
*/
import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location, PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';

import { feedbackService } from './feedback.service';
import { lioLogService } from './lio-log.service';
import { lioModalService } from './lio-modal.service';
import { localizationService } from './localization.service';
import { storageService } from './storage.service';
import { utilService } from './util.service';
import { processingService } from './processing.service';
import { permissionService } from './permissions.service';
import { domService } from './dom.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class navService{
	resetAfterConfirm		:boolean 	= false;
	page					:string		= '';
	activePage				:string 	= 'Loading...';
	crumbName				:string		= '';
	homePage				:string 	= 'login';
	loading					:boolean 	= false;
	disableGoto				:boolean	= false;
	loaded					:boolean 	= false;
	disableNav				:boolean 	= true;
	initialized				:boolean	= false;
	showNavBar				:boolean	= true;
	showFooter				:boolean	= true;
	lockLocation			:any 		= null;
	locked					:boolean	= false;
	changedForm				:boolean 	= false;
	debug					:boolean	= false;

	public confirmedExit	:Subject<any> = new Subject();
	public exit				:Subject<any> = new Subject();
	public exiting			:Subject<any> = new Subject();
	public displayedPage	:Subject<void> = new Subject();
	public activePageSet	:Subject<void> = new Subject();
	public contrastChange	:Subject<any> = new Subject();

	constructor(
		@Inject(Router) 				private Router				:Router,
		@Inject(Location) 				private Location			:Location,
		@Inject(DOCUMENT) 				private document			:Document,
		@Inject(feedbackService)		private feedbackService		:feedbackService,
		@Inject(lioLogService)			private lioLogService		:lioLogService,
		@Inject(lioModalService)		private lioModalService		:lioModalService,
		@Inject(localizationService)	private localizationService	:localizationService,
		@Inject(permissionService) 		private permissionService	:permissionService, 
		@Inject(processingService) 		private processingService	:processingService, 
		@Inject(storageService)			private storageService		:storageService,
		@Inject(debugService)			private debugService		:debugService,
		@Inject(domService)				private domService			:domService,
		@Inject(utilService) 			private utilService			:utilService,
		private _location				:PlatformLocation)
	{
		this.feedbackService.addedFeedback.subscribe(() => {
			this.scrollToFeedback();
		});
		this.debugService.register('nav', this);
		this.listenForBrowserNav();
	}

	/*
	 * Displays the page
	*/
	displayPage() {
		return new Promise((resolve) => {
			//check page permissions and redirect to home page if not allowed
			if (Object.keys(this.permissionService.permissionSet).length && !this.permissionService.hasPermission('pages.' + this.page)) {
				this.lioLogService.log('User does not have permission to view: pages.' + this.page + ' redirecting to home');
				this.goto(this.getHomePage());
				resolve(false);
				return;
			}

			setTimeout(() => {
				this.domService.refreshSpinner();
				this.displayedPage.next();
				this.loading = false;
				this.lioModalService.hideLoading();
				this.disableNav = false;
				this.initialized = true;
				resolve(true);
			}, 100);
		});
	}


	setContrast(contrast) {
		this.contrastChange.next(contrast);
	}


	/*
	 * Listens for browser button navigation
	*/
	listenForBrowserNav() {
		this._location.onPopState(() => {
			this.exit.next({path : null});
			this.confirmedExit.next({path : null});
		});
	}

	/*
	 * Sets the active page vars
	 * @param {string} title
	*/
	setActivePage(title, transTitle?:string) {
		if (!title) {
			return;
		}
		this.page = title;
		// Field Editor ID
		if (!transTitle) {
			transTitle = title;
		}
		this.activePage = this.localizationService.get(transTitle + '.title');
		this.crumbName = transTitle + '.crumb';
		this.activePageSet.next();
	}

	getActivePage() {
		return this.activePage;
	}

	/*
	 * Confirm handler
	*/
	confirm(path?) {
		if (this.processingService.downloading) {
			this.lioModalService.showLoading('exitDownload');
			return false;
		}

		if (!path) {
			path = this.getHomePage();
		}

		if (this.changedForm) {
			this.lioModalService.confirm('areYouSureTitle', 'unSavedChanges').then((confirmed) =>{
				if (confirmed) {
					this.confirmedGoTo(path);
				}
			})
			return false;
		}

		this.confirmedGoTo(path);
	}

	/*
	 * Confirmed
	*/
	confirmedGoTo(path) {
		this.confirmedExit.next({path : path});
		this.changedForm = false;	
		this.goto(path);
	}

	/*
	 * Goes back to the user's 'home'
	*/
	goHome() {
		this.confirm();
	}


	/*
	 * Goes back to home screen
	*/
	goback() {
		this.confirm();
	}

	/*
	 * Gets the current refresh count upon same goto address
	 * @return {number}
	*/
	getRefreshCount() {
		let loop = this.Location.path().search('r') ? parseInt(this.Location.path().search('r').toString()) :0;
		if (this.utilService.isNumeric(loop)) {
			loop++;
		} else {
			loop = 1;
		}
		return loop;
	}


	/*
	 * Goto a location
	 * @param {string} path
	 * @param {boolean} force
	 * @return {Promise}
	*/
	goto(path) {
		if (this.disableGoto) {
			this.lioLogService.log('redirecting is temporarily locked');
			return;
		}

		if (this.Location.path() == ('/' + path) && path == this.page) {
			this.displayPage();
			this.lioLogService.log('displayPage');
			return;
		}
		
		this.exiting.next([]);
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		let redirect = this.storageService.get('redirect');
		if (redirect) {
			path = redirect;
			this.storageService.remove('redirect');
		}

		if (this.lockLocation) {
			path = this.lockLocation;
		}

		//check page permissions and redirect to home page if not allowed
		if (Object.keys(this.permissionService.permissionSet).length && !this.permissionService.hasPermission('pages.' + path)) {
			this.lioLogService.log('User does not have permission to view: ' + path + ' redirecting to home');
			this.goto(this.getHomePage());
			return;
		}


		this.loading = true;
		this.lioModalService.hide();
		this.lioModalService.showLoading('');
		this.feedbackService.addToHistory('GOTO: ' + path);
		this.lioLogService.log(['Going to', path]);
		this.processingService.processingRequests = [];
		this.processingService.processing = false;
		this.processingService.setLangIDParam = false;
		this.changedForm = false;

		setTimeout(() => {
			this.exit.next({path : path});
			this.domService.refreshSpinner();
			this.Router.navigateByUrl(path);
			this.scrollToTop();
		}, 500);
	}

	/**
	 * Sets the homepage
	 */
	setHomePage(homePage) {
		this.homePage = homePage;
	}

	/*
	 * Gets the home page
	*/
	getHomePage() {
		return this.homePage;
	}

	/*
	 * Scroll to top
	*/
	scrollToTop() {
		return this.domService.scrollToTop();
	}


	/*
	 * Call dom to focus on element
	 * @param {string} elementID
	*/
	focusOn(el) {
		this.domService.focusOn(el);
	}


	/**
	 * Wraps window.reload
	 */
	reload() {
		if (!this.debug) {
			this.document.defaultView.location.reload();
		}
	}

	/**
	 * Wraps window.open
	 */
	open(page : string, target:any = null, featrues:any = null) {
		this.document.defaultView.open(page, target, featrues);
	}

	/**
	 * Wraps window.close
	 */
	close() {
		this.document.defaultView.close();
	}

	/*
	* Scrolls to the feedback of the page
	* @param {string} id
	*/
	scrollToFeedback() {
		setTimeout(() => {
			if (this.lioModalService.isOpen()) {
				this.scrollToFeedback();
				return;
			}

			if (!this.feedbackService.hasFeedback()) {
				return;
			}
			this.domService.scrollTo('feedback');
		}, 100);
	}
}