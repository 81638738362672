import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-modal-default',
	templateUrl: './default.component.html',
	styleUrls: ['./default.component.css']
})
export class DefaultModalComponent implements OnInit {

	@Output() opened_emitter		= new EventEmitter<any>();
	@Output() closed_emitter		= new EventEmitter<any>();
	@Output() submitted_emitter		= new EventEmitter<any>();
	public  updatedCount			= 0;

	public _settings : any = {
		'id' : 'am_default',
	};

	constructor(@Inject(MAT_DIALOG_DATA) public settings: any) {
		this.settings = Object.assign(this._settings, settings);
	}

	ngOnInit(): void {
		this.opened_emitter.emit();
	}

	close(): void {
		this.closed_emitter.emit();
	}

	updateSettings(settings): void {
		this.settings = settings;
		this.updatedCount++;
	}

	updateSetting(name, value): void {
		this.settings[name] = value;
		this.updatedCount++;
	}

}
