import { Component, Input, Optional, Inject, Output, EventEmitter, OnDestroy, OnInit, DoCheck } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { storageService } from '../../../../services/storage.service';
import { feedbackService } from '../../../../services/feedback.service';

import { PanelTabCache } from './panel-tab-cache.service';
import { PanelTabData } from './panel-tab-data';

@Component({
	selector: 'lio-panel-tabber',
	templateUrl: './panel-tabber.component.html',
	styleUrls: ['./panel-tabber.component.css'],
	providers: [PanelTabCache]
})
export class LioPanelTabber implements OnDestroy, OnInit, DoCheck{	
	@Input() public hideErrors		:boolean = false;
	@Input() public disabled		:boolean = false;
	
	//callback to call when the tab is changed, passes the data object of the tab
	@Output() public onTabChange:EventEmitter<any> = new EventEmitter();
	private defaultTab		:string 		= '';
	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Optional() @Inject(navService) 		private navService		:navService,
		@Optional() @Inject(storageService) 	private storageService	:storageService,	
		@Optional() @Inject(feedbackService) 	private feedbackService	:feedbackService,
												public 	tabCache		:PanelTabCache
	){
		this.subscriptions.add(
			this.tabCache.tabChange.subscribe((tab) => {
				this.onTabsUpdated(tab);
			})
		)
	}

	ngOnInit(){
		this.defaultTab = this.storageService.getAndClear('selectedTab');
		this.changeToDefaultTab();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
	
	ngDoCheck(){
		//check and update tab visibility
		this.tabCache.getTabs().forEach((tab) => {
			let visible = true;

			if(tab.visible){
				visible = tab.visible();
			}

			if(visible != tab._visible){
				tab._visible = visible;
				this.onTabsUpdated(tab);
			}
		});
	}

	/** Checks for changes to tabs */
	onTabsUpdated(tab:PanelTabData) {
		if(!tab._visible && tab == this.tabCache.getSelectedTab()){
			this.tabCache.clearSelectedTab();
		}

		if (tab._visible && !this.tabCache.getSelectedTab()) {
			if (!tab.suppressAutoSelect) {
				this.changeTab(tab, true);
			}
		}
		this.changeToDefaultTab();
	}

	changeToDefaultTab(){
		if(this.defaultTab){
			let switchedToDefault = this.tabCache.selectTabById(this.defaultTab);
			if(switchedToDefault){
				this.defaultTab = '';
			}
		}
	}


	getSelectedTab() {
		return this.tabCache.getSelectedTab();
	}

	/**
	 * Switches the active tab of the panel tabber
	 * @param {panelTab} the tab we are switching to
	 */
	changeTab(newTab:PanelTabData, preventCallback:boolean = false) {
		this.feedbackService.clearAll();
		this.navService.changedForm = false;
		if (newTab.link) {
			this.navService.goto(newTab.link);
		} else {
			this.tabCache.selectTab(newTab);

			if (!preventCallback) {
				this.onTabChange.emit(newTab);
			}
		}
	}

	/**
	 * Gets the number of tabs that are currently visible
	 */
	numVisibleTabs() {
		let numFound = 0;
		this.tabCache.getTabs().forEach((tab) => {
			if (!tab.visible || (typeof tab.visible == 'function' && tab.visible())) {
				numFound++;
			}
		});
		return numFound;
	}
}