import { Component, OnDestroy, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { NEVER, Subscription, of, Subject } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { stateService } from '../../services/state.service';
import { processingService } from '../../services/processing.service';
import { lmsService } from '../../services/lms.service';
import { trackerService } from '../../services/tracker.service';
import { settingsService } from '../../services/settings.service';
import { storageService } from '../../services/storage.service';
import { lioModalService } from '../../services/lio-modal.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { lioLogService } from '../../services/lio-log.service';
import { errorsService } from '../../services/errors.service';

import { configSettings } from '../../settings/config.settings';

@Component({
	selector: 'lio-course-launch',
	templateUrl: './course-launch.component.html'
})
export class CourseLaunchComponent implements OnDestroy {
	public isFullScreen				:boolean 		= false;
	public showCourse				:boolean 		= false;
	public showControls				:boolean 		= false;
	public allowControls			:boolean 		= false;
	public allowFullScreen			:boolean 		= false;
	public showCloseCourse			:boolean 		= false;
	public frameWidth				:string 		= '100%';
	public frameHeight				:string 		= '100%';
	public allowCancelCourseLoad	:boolean 		= true;
	public showMessage				:boolean 		= true;
	public debugInfo				:string 		= '';
	public url						:string 		= '';
	public message					:string 		= 'courselaunch.opening';
	public listenAttempts			:number 		= 0;

	public localeStrings			:any 			= {
		loading 		:'',
		loadingTrans 	:'courselaunch.opening',
		exit			:'EXIT COURSE',
		exitTrans		:'courselaunch.exit',
		closeCourse		:'Close Course',
		closeCourseTrans:'courselaunch.closeCourse',
		cancel			:'Cancel',
		cancelTrans		:'message.cancel'
	};
	public localeStringsKeys		:Array<any> 	= [
		'loading',
		'exit',
		'closeCourse',
		'cancel'
	];

	private openedCourse			:any 			= null;
	private debug					:boolean 		= true;
	private tracker					:any 			= null;
	private courseID				:any 			= null;
	private course					:any 			= {};
	private inSession				:boolean 		= false;	
	private confirmedCourseLoaded	:boolean 		= false;
	private inFrame					:boolean 		= false;
	private preview					:any 			= null;
	private origin					:string 		= '';
	private popoutInterval			:any 			= null;
	private closeInterval			:any 			= null;
	private listenForClosedInterval	:any 			= null;
	private closing					:boolean 		= false;
	private thirdPartyHost			:boolean 		= false;
	private closeRequested			:boolean 		= false;
	private handledError			:boolean		= false;
	private show404					:boolean 		= false;
	private courseSubject			:Subject<any> 	= new Subject();
	
	private subscriptions			:Subscription	= NEVER.subscribe();

	constructor(
		private zone: NgZone,
		@Inject(DOCUMENT) 			private document			:Document,
		@Inject(navService)			public	navService			:navService,
		@Inject(debugService)		public	debugService		:debugService,
		@Inject(stateService)		public	stateService		:stateService,
		@Inject(processingService)	public	processingService	:processingService,
		@Inject(lmsService)			public	lmsService			:lmsService,
		@Inject(trackerService)		public	trackerService		:trackerService,
		@Inject(settingsService)	public	settingsService		:settingsService,
		@Inject(storageService)		public	storageService		:storageService,
		@Inject(lioModalService)	public	lioModalService		:lioModalService,
		@Inject(utilService)		public	utilService			:utilService,
		@Inject(feedbackService)	public	feedbackService		:feedbackService,
		@Inject(lioLogService)		public	lioLogService		:lioLogService,
		@Inject(errorsService)		public	errorsService		:errorsService,
		@Inject(configSettings)		public	configSettings		:configSettings
	){
		this.debugService.register('courselaunch', this);
		this.navService.setActivePage('courselaunch');
		this.processingService.allowCancel = true;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
				this.navService.displayPage();
			})
		);
	
		this.subscriptions.add(
			this.navService.exit.subscribe(() => { this.onExit(); })
		);
	
		this.subscriptions.add(
			this.trackerService.trackerError.subscribe((error) => {
				this.handleTrackerError(error);
			})
		);
	
		this.subscriptions.add(
			this.errorsService.criticalErrorSubject.subscribe(() => {
				this.setError();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.setOnBeforeUnload();
		this.courseID 		= this.storageService.get('courseID');
		this.preview 		= this.storageService.get('preview');
		this.origin 		= this.storageService.get('origin');
		this.show404 		= this.storageService.get('show404');
		this.openedCourse 	= this.storageService.get('openedWindow');

		if (!this.openedCourse || !this.openedCourse.opener) {
			this.openedCourse = null;
		}
		
		if (!this.courseID) {
			this.goBack();
			return;
		}

		// Handles previewing couses from the catalog
		if (this.preview) {
			this.course = this.utilService.copy(this.preview);
			this.course.documentLaunch = true;
			this.allowControls = true;
			this.setTracker();
			this.launchCourse(this.course);
			return;
		}

		this.startSession().then((course) => {
			if (!course) {
				return;
			}
			this.setTracker();
			this.launchCourse(course);
		});
	}

	/*
	 * Sets the tracker to use
	 * @param {?object} course
	*/
	setTracker() {
		this.tracker = this.trackerService;
		this.tracker.reset();
		
		if (this.preview) {
			this.trackerService.setPreviewMode();
		}
	}

	/*
	 * Activate the tracker
	 * @return {Promise}
	*/
	startSession() {
		let courseID = this.courseID;

		this.lioLogService.log(['Starting Session', courseID]);
		this.feedbackService.addToHistory('Starting Course Session: '  + courseID);

		return this.lmsService.post('training/launchCourse', {'courseID': courseID}).then((result) => {
			if (result.success) {
				this.inSession = true;
				return result.properties.courseSession;
			} else if (result.errors) {
				this.setError(result.errors[0]);
			} else {
				this.setError('Failed to launch course');
			}
		});
	}

	/*
	 * Ends the session
	 * @param {?object} course
	 * @return {Promise}
	*/
	endSession() {
		if (!this.inSession) {
			return of(true).toPromise();
		}
		return new Promise((resolve) => { 
			let courseID = this.courseID;
			
			this.lioLogService.log(['Ending Session', courseID]);
			this.feedbackService.addToHistory('Ending Course Session: '  + courseID);

			this.lmsService.post('training/endCourseSession', {'courseID': courseID}).then((result) => {
				this.lioLogService.log('Course Session Ended');
				this.inSession = false;

				if (result.properties.courseIncomplete) {
					this.lioModalService.show('courselaunch.courseIncomplete').then(() => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			});
		});
	}

	/*
	 * Launches the course
	*/
	launchCourse(course) {
		this.course = course;

		this.activateTracker().then((result) => {
			if (result) {
				this.openCourse();
			}
		});	
	}

	/*
	 * Opens the course by a specific method
	*/
	openCourse() {
		let method = this.settingsService.launchMethod ? this.settingsService.launchMethod : 'popout';

		// Course's launch method overrides launch method setting
		if (this.course.launchMethod) {
			method = this.course.launchMethod;
		}

		// When previewing, always use the iFrame (SP-2638)
		if (this.preview) {
			method = 'iframe';
		}

		this.closeRequested = false;

		this.lioLogService.log(['Launch Method', method]);

		switch(method) {
			case 'iframe':
				this.openFrame();
				break;
			case 'legacy':
			case 'popout':
				this.popOut();
				break;
			default:
				this.setError('Launch method of ' + method + ' was not recoginzed');
				break;
		} 
	}

	/*
	 * Opens the iFrame method
	*/
	openFrame() {
		let url = this.getURL();
		if (!url) {
			return;
		}
		url += '&inFrame=true';
		this.showCourse = true;
		this.showControls = true;
		this.allowFullScreen = true;
		this.navService.showFooter = false;
		this.inFrame = true;

		this.listenForFrameOnLoad();
	}

	/*
	 * Close the iFrame method
	*/
	closeFrame() {
		this.url = null;
	}

	/*
	 * Popout the course
	*/
	popOut() {
		let name	= this.course.courseName,
			url		= this.getURL(),
			config	= this.getPopupConfig();

		if (!url) {
			return;
		}
		url += '&inPopOut=true';

		this.lioLogService.log(['Popping out course: ' + name, url, config]);

		this.openedCourse = this.document.defaultView.open(url, name, config);
		this.storageService.set('openedWindow', this.openedCourse);

		this.popoutInterval = setInterval(() => { 
			this.listenToPopoutCourse(); 
		}, 1000);

		this.showCourse = false;
		this.inFrame = false;

		this.showControls = false;
		this.allowControls = false;
		this.allowFullScreen = false;
		this.navService.showFooter = true;


		this.showMessage = true;
		this.localeStrings.loadingTrans = 'courselaunch.opening';
		this.exitFullScreen();

		if (!this.openedCourse) {
			this.handlePopupError();
			return;
		}
	}

	/*
	 * Get the pop up dinensions
	 * NOTE: If you change this here, change it also on training, will revisit making this DRY
	 * @return {string}
	*/
	getPopupConfig() {
		let width	= this.settingsService.useCourseDimensions ? this.course.courseWidth : (this.document.defaultView.screen.width * 90) / 100;
		let height	= this.settingsService.useCourseDimensions ? this.course.courseHeight : (this.document.defaultView.screen.height * 85) / 100;
		let top		= this.document.defaultView.top.outerHeight / 2 + this.document.defaultView.top.screenY - ( this.document.defaultView.screen.height / 2) + 30;
		let left	= this.document.defaultView.top.outerWidth / 2 + this.document.defaultView.top.screenX - ( width / 2);
		let config	= '';

		config += 'directories=0, titlebar=0, toolbar=0, status=0, menubar=0, scrollbars=1, location=0, statusbar=0, resizable=1,';
		config += 'width=' + width + ', height=' + height + ', left=' + left + ', top=' + top;

		return config;
	}

	/*
	 * Listen for the course closed
	*/
	handlePopupError() {
		return this.lioModalService.confirm('popupBlockError').then((confirm) => {
			if (confirm) {
				setTimeout(() => {
					this.launchCourse(this.course);
				}, 100);
			} else {
				setTimeout(() => {
					this.goBack();
				}, 100);
			}
		});
	}

	/*
	 * Listen to the course
	*/
	listenToPopoutCourse() {
		if (this.closing) {
			return;	
		}
		this.showMessage = true;
		this.localeStrings.loadingTrans = 'courselaunch.inPopup';
		this.allowControls = true;
		this.allowCancelCourseLoad = false;

		this.showCloseCourse = true;
		if (this.openedCourse && this.openedCourse.closed) {
			clearInterval(this.popoutInterval);
			this.exitCourse('Course Object is closed');
		} else if (this.openedCourse) {
			this.showCloseCourse = true;
			this.allowCancelCourseLoad = false;			
			// If the course is outside of this server we cannot control it 
			if (this.thirdPartyHost) {
				return;
			}
			
			this.onPopupLoad();

			try {
				this.openedCourse.onclose = () => {
					this.exitCourse('On Close');
				};	
			} catch (e) {
				this.listenForClosedInterval = setInterval(() => { 
					this.listenForClosed(); 
				}, 1000);
				clearInterval(this.popoutInterval);
				this.lioLogService.log(['Permission issue?', e]);
			}
		}
	}


	/*
	 * Listens for the closed window
	*/
	listenForClosed = function() {
		if (this.openedCourse && this.openedCourse.closed) {
			clearInterval(this.listenForClosedInterval);
			this.exitCourse('Course Window is closed');
		}
	};

	/*
	 * Gets the url to launch
	 * @return {?string}
	*/
	getURL() {
		if (!this.course.courseURL) {
			this.setError('Course is missing a url');
			return null;
		}

		let courseURL	= this.course.courseURL,
			server		= this.getCourseServer(),
			params		= this.getParams(),
			isDocument	= this.isDocument(),
			url			= '';

		// Ensure the course can be launched with the current tracker
		if (this.thirdPartyHost && this.tracker && !this.tracker.allowsThirdPartyHost()) {
			this.setError(
				'Course is on a third party server which is unable to be tracked with the selected protocol: ' +
				'Course URL: ' + courseURL + ' ' +
				'Protocol: ' + this.tracker.getProtocol()  
			);
			return null;
		}

		if (isDocument) {
			if (!this.tracker) {
				// Handle Documents loaded without a tracker directly
				this.tracker = this.document;
				this.tracker.setPreviewMode();
				this.lioLogService.log(['Activating Document', this.course]);
				this.tracker.activate(this.course);
			}
			url = this.configSettings.serverGlobal + 'launcher/document.html' + params;
		} else {
			url = server + courseURL + params;
		}
		
		this.lioLogService.log('Launching: ' + url);
		this.url = url;
		return url;
	}

	/*
	 * Is the url a document?
	 * @return {boolean}
	*/
	isDocument() {
		if (!this.course || !this.course.courseURL) {
			return false;
		}

		let courseURL	= this.course.courseURL,
			scormLaunch	= this.course.scormLaunch,
			aiccLaunch	= this.course.aiccLaunch;
		
		if (scormLaunch == 0 && aiccLaunch == 0) {
			return true;
		}

		if (courseURL.indexOf('youtube') > -1) {
			return true;
		}
		
		let extensions	= [
			'png', 'pdf', 'jpg', 'jpeg', 'txt', 'doc', 'docx',
			'ppt', 'pptx', 'csv', 'xls', 'xlsx', 'mp4', 'avi',
			'mpeg', 'mpeg4'
		];
		let url 		= courseURL.split('.');
		let extension	= url.length > 1 ? url[url.length -1] : '';
		let cleanExt	= extension.split('?')[0];

		if (this.utilService.inArray(extensions, cleanExt)) {
			return true;
		}
		return false;
	}

	/*
	 * Gets the server to launch from
	 * @param {string} courseURL
	 * @return {string}
	*/
	getCourseServer() {
		let courseURL 	= this.course.courseURL;
		let courseID 	= parseInt(this.course.courseID);
		let aiccLaunch 	= parseInt(this.course.aiccLaunch);
		
		if (courseURL.indexOf('http') > -1) {
			// Hard coded url
			if (courseURL.indexOf(this.configSettings.serverGlobal) == -1) {
				this.lioLogService.log(['Course URL does not appear to be on the current server', courseURL]);
				this.thirdPartyHost = true;
			}

			return '';
		}

		// Cascade 
		if (courseID >= 45000 && courseID <= 50000 && aiccLaunch == 1) {
			this.thirdPartyHost = true;
			return 'https://courseware.corpedia.com';
		}

		return '';
	}

	/*
	 * Gets the url params
	 * @return {string}
	*/
	getParams() {
		let params 			= '?';
		let courseParams	= this.course.webLaunchParams;
		let companyParams	= this.course.bucketCobrandFile;
		let langID			= this.storageService.get('langID');

		params += '&protocol=' + this.tracker.getProtocol();

		if (courseParams) {
			params += '&ccf=' + courseParams;
		}

		if (companyParams) {
			params += '&cbf=' + companyParams;
		}

		if (langID) {
			params += '&langID=' + langID + '&lang=' + langID;
		}

		if (params.length === 1) {
			return '';
		}

		// Clean the params
		params = params.replace('?&', '?');
		return params;
	}


	/*
	 * Set actions for if the user closes the lms 
	*/
	setOnBeforeUnload() {
		let winRef = this.document.defaultView;
		winRef.addEventListener('beforeunload',	()	=> { return this.onBeforeUnload(); });
		winRef['onBeforeUnload'] = () 				=> { return this.onBeforeUnload(); };
	}

	/*
	 * Clear actions for if the user closes the lms 
	*/
	clearOnBeforeUnload() {
		let winRef = this.document.defaultView;
		winRef.addEventListener('beforeunload',	()	=> {});
		winRef['onBeforeUnload'] = () 				=> {};
	}


	/*
	 * Exit the course on refresh
	*/
	onBeforeUnload() {
		this.exitCourse('On before unload');
	}

	/*
	 * Handles on load of the course frame
	*/
	onFrameLoad() {	
		this.allowCancelCourseLoad = false;

		if (this.confirmedCourseLoaded) {
			return;
		}

		try {
			let el		= document.getElementById('course-frame')[0],
				doc		= el ? el.contentDocument : null,
				title	= doc ? doc.title : null;

			if (this.is404(title)) {
				this.handle404();
				return;
			}
		} catch (e) {
			this.lioLogService.log(['Error ', e]);
		}

		this.tracker.handleCourseOpen();
		this.confirmedCourseLoaded = true;
		this.navService.scrollToTop();
	}

	/*
	 * Checks if title is a 404
	 * @param {string} title
	 * @return {boolean}
	*/
	is404(title) {
		let errorTitles = ['404 Not Found', 'IIS 8.5 Detailed Error - 404.0 - Not Found'];
	
		return errorTitles.indexOf(title) > -1;
	}

	/*
	 * Handles on load of the course from the pop up window
	*/
	onPopupLoad() {
		if (this.confirmedCourseLoaded) {
			return;
		}
	
		try {
			if (this.is404(this.openedCourse.document.title)) {
				this.handle404();
				return;
			}
		} catch (e) {
			this.lioLogService.log(['Error ', e]);
			return;
		}

		this.tracker.handleCourseOpen();
		this.confirmedCourseLoaded = true;
	}

	/*
	 * Handles full screen
	*/
	fullScreen() {
		this.isFullScreen 			= true;
		this.navService.showNavBar 	= false;
		this.navService.showFooter 	= false;
	}

	/*
	 * Handles exiting full screen
	*/
	exitFullScreen() {
		this.isFullScreen 			= false;	
		this.navService.showNavBar 	= true;
		if (this.inFrame) {
			this.navService.showFooter = false;
		}
	}

	/*
	 * Handles on load of the course in the frame
	*/
	listenForFrameOnLoad() {		
		this.showMessage = false;
		this.localeStrings.loadingTrans = 'global.processing';
		let frame = document.getElementById('course-frame');

		if (frame) {
			frame.addEventListener('load', () => {
				if (!this.inFrame) {
					return;
				}
				this.onFrameLoad();
				this.fullScreen();
				this.allowControls 		= true;
				this.allowFullScreen 	= true;
			});
		} else {
			setTimeout(() => {
				this.listenAttempts++;
				if (this.listenAttempts < 5) {
					this.listenForFrameOnLoad();
				}
			}, 1000);
		}
	}

	/*
	 * Activates the tracker
	 * @return {Promise}
	*/
	activateTracker() {
		this.navService.locked 	= true;
		this.tracker.debug 		= this.debug;
		if (this.isDocument()) {
			this.course.courseURL += this.getParams();
			this.course.documentLaunch = true;
		}
		this.lioLogService.log(['Activating Tracker', this.course]);
		return this.tracker.activate(this.course);
	}

	/*
	 * Handles critical errors
	 * @param {string} error
	*/
	setError(error = null) {
		this.lioLogService.log(['Handling Error', error]);

		if (this.preview) {
			this.storageService.set('launchError', error);
			if (typeof error == 'string' && error.indexOf('404') > -1) {
				this.closeCourse();
				this.handle404();
			}
			return;
		}

		if (this.handledError) {
			this.resetStorage();
			this.errorsService.errorOccurred = true;
			this.feedbackService.addToHistory(error);
			this.errorsService.lastResponse = error;
			this.zone.run(() => {
				this.navService.goto('500');
			});
			return;
		}
		this.handledError = true;

		this.closeCourse().then(() => {
			this.resetStorage();
			this.errorsService.errorOccurred = true;
			this.feedbackService.addToHistory(error);
			this.errorsService.lastResponse = error;
			this.zone.run(() => {
				this.navService.goto('500');
			});
		});
	}

	/*
	 * Handles being logged off while user is taking a course
	 * @param {string} error
	*/
	handleLoggedOff() {
		this.lioLogService.log('Heard logged off');
		this.inSession 	= false;
		this.tracker 	= null;
		window['API'] 	= null;
		this.closeCourse();
	}

	/*
	 * Clears storage cache
	*/
	resetStorage() {
		this.storageService.remove('courseID');
		this.storageService.remove('preview');
		this.storageService.remove('tracking');
		this.storageService.remove('exitOn404');
	}

	/*
	 * Handle Tracker Error
	*/
	handleTrackerError(error) {
		this.setError(error);
	}

	/*
	 * Exit the course
	*/
	exitCourse(origin = '') {
		if(this.closing){
			return;
		}
		this.lioLogService.log(['Exit Course Called', origin]);
		this.closeCourse().then(() => {
			this.goBack();
		});
	}

	/*
	 * Handles 404 errors 
	*/
	handle404() {
		if(this.show404){
			setTimeout(() => {
				this.navService.showNavBar = true;
				this.navService.showFooter = true;
				this.showControls = false;
				this.navService.locked = false;
				this.closing = false;
				this.localeStrings.loadingTrans = 'courselaunch.404Error';
				this.debugInfo = this.course.courseURL;
			}, 100);
			this.closeCourse();
			this.show404 = false;
		} else {
			this.setError('Course was not found, got a 404 error on launch, url = ' + this.getURL());
		}
	}

	/*
	 * Close the course
	*/
	closeCourse() {
		this.exitFullScreen();
		this.showControls 		= false;
		this.allowControls 		= false;
		this.allowFullScreen 	= false;
		this.showMessage 		= true;
		this.closing 			= true;
		this.localeStrings.loadingTrans = 'courselaunch.closing';

		if (!this.handleClosing()) {
			return new Promise((resolve) => { 
				resolve(true); 
			});
		}

		this.lioLogService.log('Course closed');
		this.showCourse = false;
		clearInterval(this.closeInterval);
		clearInterval(this.listenForClosedInterval);

		return this.deactivateAndEndSesion().then(() => {
			this.courseSubject.next(true);
		});
	}

	/*
	 * Handle closing the popup course
	 * @return bBoolean}
	*/
	handleClosing() {
		if(!this.openedCourse){
			return true;
		}

		if (!this.openedCourse.closed && !this.closeRequested) {
			this.lioLogService.log('Closing the course');
			this.closeRequested = true;
			this.openedCourse.close();
			this.closeInterval = setInterval(() => { 
				this.closeCourse(); 
			}, 1000);
			return false;
		}else if (!this.openedCourse.closed && this.closeRequested) {
			clearInterval(this.closeInterval);
			this.localeStrings.loadingTrans = 'courselaunch.inPopup';
			this.lioLogService.log('Looks like the course has an onbeforeunload preventer and the user cancelled');
			return false;
		}

		return true;
	}

	/*
	 * Deactivate the tracker and end the session
	*/
	deactivateAndEndSesion() {
		if (this.tracker) {
			return this.tracker.deactivate().then(() => {
				this.tracker = null;
				return this.endSession();
			});	
		} else {
			return this.endSession();
		}
	}

	/*
	 * Go back to the origin
	*/
	goBack() {
		this.lioLogService.log('Exiting Course Screen');
		
		// Go back after a slight delay to let the dom clean up
		setTimeout(() => {
			if (this.handledError) {
				return;
			}
			this.navService.showNavBar = true;
			this.navService.showFooter = true;
			this.exitFullScreen();
			this.resetStorage();
			this.navService.locked = false;
			this.processingService.resendOnInvalidResponse = false;

			clearInterval(this.closeInterval);
			clearInterval(this.listenForClosedInterval);
			
			if (this.origin) {
				this.navService.goto(this.origin);
			} else {
				this.navService.goHome();
			}
		}, 200);
	}

	/*
	 * On Exit
	*/
	onExit() {
		this.navService.showFooter = true;
		clearInterval(this.popoutInterval);
		clearInterval(this.closeInterval);
		clearInterval(this.listenForClosedInterval);
		this.navService.showNavBar = true;
		this.clearOnBeforeUnload();
	}

}