import { NgModule } from '@angular/core';

import { Localize } from './transform/localize.directive';
export { Localize } from './transform/localize.directive';


@NgModule({
	declarations: [
		Localize
	],
	exports: [
		Localize
	],
})
export class LioDirectivesModule {}