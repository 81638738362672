<div class="row">
	<lio-panel-tabber #tabCache class="col-sm-12 col-md-10 mx-auto">
		<lio-panel-tab [settings]="panels.filters">
			<lio-enrollment-editor *lioPanelTabContent
				[eventService]="enrollmentEventService" 
				[settings]="scheduleEnrollmentFilterSettings">
			</lio-enrollment-editor>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.edit">
			<lio-event-editor *lioPanelTabContent
				[settings]="scheduleEnrollmentEventSettings"
				[eventService]="enrollmentEventService">
			</lio-event-editor>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.list">
			<lio-event-list *lioPanelTabContent
				[eventService]="enrollmentEventService"
				[settings]="scheduleEnrollmentEventListSettings" 
				(callback)="callback($event)">
			</lio-event-list>
		</lio-panel-tab>
		<lio-panel-tab [settings]="panels.history">
			<lio-event-history *lioPanelTabContent
				[eventService]="enrollmentEventService"
				[settings]="scheduleEnrollmentEventHistorySettings" 
				[includePacEvents]="false">
			</lio-event-history>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>