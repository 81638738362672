import { Injectable } from '@angular/core';

import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: ccfAdminSettings
})
export class ccfAdminSettings {
	
	optionsField:any = {
		model			: 'options',
		searchable 		: true,
		multiple 		: true,
		orderBy 		: 'name',
		backgroundColor : 'white'
	};

	fieldConfig:any = {
		fieldName : 'ccfadmin',
	};

	fields:LioFieldArray = new LioFieldArray(
		new LioSearchableSelectField({
			name:'Type for CCF1',
			model:'clientCustomField1Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF2',
			model:'clientCustomField2Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF3',
			model:'clientCustomField3Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF4',
			model:'clientCustomField4Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF5',
			model:'clientCustomField5Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF6',
			model:'clientCustomField6Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF7',
			model:'clientCustomField7Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF8',
			model:'clientCustomField8Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF9',
			model:'clientCustomField9Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF10',
			model:'clientCustomField10Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF11',
			model:'clientCustomField11Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF12',
			model:'clientCustomField12Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF13',
			model:'clientCustomField13Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF14',
			model:'clientCustomField14Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF15',
			model:'clientCustomField15Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF16',
			model:'clientCustomField16Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF17',
			model:'clientCustomField17Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF18',
			model:'clientCustomField18Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF19',
			model:'clientCustomField19Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF20',
			model:'clientCustomField20Type',
			group:'Custom Field Types 11-20',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF20',
			model:'clientCustomField20Type',
			group:'Custom Field Types 1-10',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF21',
			model:'clientCustomField21Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF22',
			model:'clientCustomField22Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF23',
			model:'clientCustomField23Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF24',
			model:'clientCustomField24Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF25',
			model:'clientCustomField25Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF26',
			model:'clientCustomField26Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF27',
			model:'clientCustomField27Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF28',
			model:'clientCustomField28Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF29',
			model:'clientCustomField29Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		}),
		new LioSearchableSelectField({
			name:'Type for CCF30',
			model:'clientCustomField30Type',
			group:'Custom Field Types 21-30',
			type:'select',
			required: false,
			min: 2,
			max: 2000,
			options:[],
			visible:true,
			checkForHTMl: true,
			regex: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
		})
	);
}