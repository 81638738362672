<!-- Course Info When Collapsed -->
<div class="row" *ngIf="!catalog.expanded" localize [model]="localeStrings" [items]="localeStringItems">
	<!-- Left Side -->
	<div class="col-md-8 col-xs-8">
		<!-- Course Image -->
		<div [id]="'am_catalog_entry_' + indexInList + '_image'" 
			*ngIf="isFieldVisible('image') && catalog.image" class="d-inline-block">
			<img [alt]="'Catalog ' + catalog.type" [src]="catalog.image" style="height: 90px;">
		</div>

		<div class="d-inline-block ml-3">
			<!-- Course Name -->
			<div [id]="'am_catalog_entry_' + indexInList + '_name'" 
				*ngIf="isFieldVisible('name')">
				<div class="lead" [innerHtml]="utilService.highlight(catalog.name, filters['*']) | html" (click)="clickedCatalog.emit(catalog)"></div>
			</div>

			<!-- Show Course Description if there is not a catalog -->
			<div [id]="'am_catalog_entry_' + indexInList + '_description'" 
				*ngIf="isFieldVisible('description') && catalog.missingCatalog && catalog.description">
				<small>
					<span class="mr-1">{{localeStrings.courseDescription}}</span>
					<span [innerHtml]="utilService.highlight(catalog.description, filters['*']) | html"></span>
				</small>
			</div>

			<!-- Show Course Type if there is a catalog -->
			<div *ngIf="!catalog.missingCatalog">
				<p>
					<small>
						<span [id]="'am_catalog_entry_' + indexInList + '_type'">{{localeStrings.courseTypeHeader}}</span>
						<span [innerHtml]="utilService.highlight(catalog.type, filters['*']) | uppercase | html"></span>
					</small>
				</p>
			</div>
		</div>

		<!-- Show Download URLS Micro-->
		<div [id]="'am_catalog_entry_' + indexInList + '_url_collapsed'" 
			*ngIf="(isFieldVisible('URL') || permissionService.hasPermission('catalog.showLinks')) && (catalog.URL && catalog.URL.length)">
			<div class="mr-1">{{localeStrings.courseURL}}</div>
			<span [innerHtml]="catalog.URL | html"></span>
		</div>

		<!-- Show Download Tuesday with Bernie Video-->
		<div [id]="'am_catalog_entry_' + indexInList + '_url_collapsed'" 
			*ngIf="isFieldVisible('tuesday') && catalog.tuesday">
			<div class="mr-1">{{localeStrings.courseURL}}</div>
			<span [innerHtml]="catalog.tuesday | html"></span>
		</div>

		<!-- Show Download Gary Turk Video-->
		<div [id]="'am_catalog_entry_' + indexInList + '_url_collapsed'" 
			*ngIf="isFieldVisible('turk') && catalog.turk && catalog.turk.length">
			<div class="mr-1">{{localeStrings.courseURL}}</div>
			<span [innerHtml]="catalog.turk | html"></span>
		</div>

		<!-- Show Download BroadCat-->
		<div [id]="'am_catalog_entry_' + indexInList + '_url_collapsed'" 
			*ngIf="isFieldVisible('broadcat') && catalog.broadcat && catalog.broadcat.length">
			<div class="mr-1">{{localeStrings.courseURL}}</div>
			<span [innerHtml]="catalog.broadcat | html"></span>
		</div>
	</div>

	<!-- Course ID -->
	<div *ngIf="isFieldVisible('courseID') && catalog.isAvailable" class="col-md-2 col-xs-2">
		<div *ngIf="!permissionService.hasPermission('catalog.admin') && permissionService.hasPermission('catalog.showcourseID')">
			<h3 [innerHtml]="utilService.highlight('#' + catalog.courseID, filters['*']) | html"></h3>
		</div>
		<div *ngIf="permissionService.hasPermission('catalog.admin')">
			<button class="btn btn-secondary" [innerHtml]="utilService.highlight('#' + catalog.courseID, filters['*']) | html" (click)="gotoAdmin(catalog.courseID)"></button>
		</div>
	</div>

	<!-- PROJECTED RELEASE -->
	<div [id]="'am_catalog_entry_' + indexInList + '_projectedrelease'" *ngIf="isFieldVisible('availableDate') && !catalog.isAvailable" class="col-md-2 col-xs-2">
		<h5  [id]="'am_catalog_entry_' + indexInList + '_availabledate'">{{utilService.formatDate(catalog.availableDate) | date : localizationService.getSelectedDateFormat()}}</h5>
		<h5>PROJECTED RELEASE</h5>
	</div>

	<!-- Print & Course Launch Icon -->
	<div class="col-md-2 col-xs-2">
		<span class="float-right">
			<button [id]="'am_catalog_entry_' + indexInList + '_print'" 
				class="button-hide btn" 
				*ngIf="permissionService.hasPermission('catalog.print') && view.printable"
				title="coursecatalog.print"
				(click)="clickedPrint.emit(catalog)">
				<i class="fa fa-print fa-3x mx-1"  title="Print this catalog" [ngClass]="catalog.print ? 'text-info' : 'text-secondary'"></i>
			</button>
			<button [id]="'am_catalog_entry_' + indexInList + '_preview'" 
				class="button-hide btn" 
				*ngIf="view.canPreview && !catalog.modules.length" 
				(click)="previewCourse.emit(catalog)" 
				title="coursecatalog.previewCourse">
				<i class="fa fa-play-circle fa-3x mx-1 text-info" title="Preview this catalog"></i>
			</button>
		</span>
		<!-- Self Enrollment -->
		<button [id]="'am_catalog_entry_' + indexInList + '_selfenroll'" 
			*ngIf="view.selfEnroll && !catalog.enrolled" 
			[disabled]="processingService.processing" 
			class="btn btn-primary" 
			(click)="selfEnroll.emit(catalog)">{{localeStrings.enroll}}</button>

		<button [id]="'am_catalog_entry_' + indexInList + '_isenrolled'" 
			*ngIf="view.selfEnroll && catalog.enrolled" 
			[disabled]="true" 
			class="btn disabled btn-disabled btn-primary">{{localeStrings.enrolled}}</button>
	</div>
<!-- Row Collapsed -->
</div>