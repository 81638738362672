import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { storageService } from '../../services/storage.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { utilService } from '../../services/util.service';
import { formValidatorService } from '../../services/form-validator.service';
import { filesService } from '../../services/files.service';
import { lioLogService } from '../../services/lio-log.service';
import { loginService } from '../../services/login.service';
import { localizationService } from '../../services/localization.service';
import { permissionService } from '../../services/permissions.service';
import { fieldService } from '../../services/fields.service';

import { addUsersSettings } from './add-users.settings';

@Component({
  selector: 'lio-add-users',
  templateUrl: './add-users.component.html'
})
export class AddUsersComponent implements OnInit {
	public fields				:Array<any>		= this.utilService.copy(this.addUsersSettings.fields);
	private exportReportFields	:Array<any>		= this.addUsersSettings.exportReportFields;
	public panelConfigs			:any			= this.utilService.copy(this.addUsersSettings.panelConfigs);
	public fieldConfig			:any			= this.utilService.copy(this.addUsersSettings.fieldConfig);
	public employee				:any			= {};
	public employees			:Array<any>		= [];
	private employeeIDs			:Array<any>		= [];
	public filteredEmployees	:Array<any>		= [];
	public model				:any			= this.addUsersSettings.model;
	public processTypes			:Array<any>		= this.addUsersSettings.processTypes;
	public processTypeField		:any			= this.utilService.copy(this.addUsersSettings.processTypeField);
	public addedUsers			:Array<any>		= [];
	private editedUsers			:Array<any>		= [];
	private activatedUsers		:Array<any>		= [];
	private deactivatedUsers	:Array<any>		= [];
	public allowSubmit			:boolean		= false;
	private filePath			:any			= null;
	public processedFile		:boolean		= false;
	public pagination			:any			= this.addUsersSettings.pagination;
	public uploadSettings		:any			= this.addUsersSettings.uploadSettings;
	public prefixButtons		:any			= this.utilService.copy(this.addUsersSettings.prefixButtons);
	public localizations		:any			= this.addUsersSettings.localizations;
	public localizationItems	:Array<any>		= Object.keys(this.addUsersSettings.localizations);
	public readyForProcess		:boolean 		= false;

	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)		public 	feedbackService		:feedbackService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(loginService)			public 	loginService		:loginService,
		@Inject(errorsService)			public 	errorsService		:errorsService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(lioLogService)			public 	lioLogService		:lioLogService,
		@Inject(stateService)			public 	stateService		:stateService,
		@Inject(utilService)			public 	utilService			:utilService,
		@Inject(storageService)			public 	storageService		:storageService,
		@Inject(workerService)			public 	workerService		:workerService,
		@Inject(permissionService)		public 	permissionService	:permissionService,
		@Inject(fieldService)			public 	fieldService		:fieldService,
		@Inject(formValidatorService)	public 	formValidatorService:formValidatorService,
		@Inject(localizationService)	public 	localizationService	:localizationService,
		@Inject(lioModalService)		public 	lioModalService		:lioModalService,
		@Inject(addUsersSettings)		public 	addUsersSettings	:addUsersSettings
	){
		this.debugService.register('addUsers', this);
		this.navService.setActivePage('addusers');

		this.prefixButtons[0].callback = (employee) => { return this.edit(employee) };

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);

		/*
		 * On Field Change
		*/
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.navService.displayPage();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/*
	 * Initializes the form
	*/
	init() {
		this.setFields();
	}

	/*
	 * On tab change
	 * @param {object} newTab
	*/
	onTabChange() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
	}

	/*
	 * Go to the email users page for all users
	*/
	sendEmailToAll() {
		this.storageService.set('employeesToEmail', this.employees);
		this.storageService.set('employeeIDsToEmail', this.employeeIDs);
		this.storageService.remove('courseID');
		this.storageService.set('task', 'emailing');
		this.navService.goto('email');
	}

	/*
	 * Go to the email users page for added users
	*/
	sendEmailToAdded() {
		this.storageService.set('employeeIDsToEmail', this.addedUsers);
		this.storageService.remove('courseID');
		this.storageService.set('task', 'emailing');
		this.navService.goto('email');
	}

	/*
	 * Sets the fields with CCF properties
	*/
	setFields() {
		this.employee = {};
		this.employee.inactive = 0;
		this.employee.permissionID = '10';
		this.processTypes = this.permissionService.setFields(this.processTypes);
	}

	/*
	 * Handles not allows to upload
	*/
	handleNotAllowed() {
		if (!this.model.processType) {
			this.feedbackService.setError('pleaseSelectAProcessType');
		} else {
			this.feedbackService.setError('sorryTryThatAgain');
		}
	}

	/*
	 * On update of the user
	*/
	onUpdate() {
		this.navService.changedForm = true;

		if (this.hasValue()) {
			this.allowSubmit = true;
		} else {
			this.allowSubmit = false;
		}
		this.formValidatorService.resetFields();
		this.feedbackService.clearErrors();
	}

	/*
	 * Does the form have a value?
	*/
	hasValue() {
		let hasValue = false;
		this.fields.forEach((field) => {
			if (this.employee[field.model]) {
				hasValue = true;
			}
		});
		return hasValue;
	}

	/*
	 * Reset the form
	*/
	reset() {
		this.employee = {};
		this.addedUsers = [];
		this.editedUsers = [];
		this.activatedUsers = [];
		this.deactivatedUsers = [];
		this.processedFile = false;
	}

	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();

		if (!this.formValidatorService.isValid(this.employee, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		return isValid;
	}

	/* 
	 * On File Added
	*/
	fileAdded(response) {
		this.filePath = response.filePath;
		this.preprocess();
	}

	/*
	 * File preprocess / validation
	*/
	preprocess() {
		let filePath = this.filePath,
			langs = this.localizationService.getLangs();

		this.lmsService.postAsync('importemployee/preprocess', 
		{'delta': this.model.processType, 'filePath': filePath, 'langs': langs},
		'uploading').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handlePreprocessResults(result);
				});
			} else {
				this.handlePreprocessResults(result);
			}
		});	
	}

	/*
	 * Handles pre-process results
	*/
	handlePreprocessResults(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}

		let properties = result.properties,
			totalAdded = properties.totalAdded,
			totalEdited = properties.totalEdited,
			totalActivated = properties.totalActivated,
			totalDeactivated = properties.totalDeactivated,
			totalFullFileDeactivations = properties.totalFullFileDeactivations,
			totalConflicts = properties.conflicts.length,
			totalFailed = properties.totalFailed,
			cancelled = properties.cancelled,
			totalDetected = properties.totalDetected,
			errors = properties.errors,
			isValidFile = true,
			messages = [],
			macros = [];

		if (cancelled) {
			return;
		}

		if (!totalDetected) {
			totalDetected = 0;
		}
		
		macros.push({'key': 'totalUsers', 'value': totalDetected});
		messages.push(this.utilService.localizeMessage('totalUsersDetected', macros));
		
		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeInserted', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'totalEdited', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeUpdated', macros));
		}
		if (totalActivated) {
			macros.push({'key': 'totalActivated', 'value': totalActivated});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeReActivated', macros));
		}
		if (totalDeactivated) {
			macros.push({'key': 'totalDeactivated', 'value': totalDeactivated});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeDeactivated', macros));
		}
		if (totalConflicts) {
			macros.push({'key': 'totalConflicts', 'value': totalConflicts});
			messages.push(this.utilService.localizeMessage('totalUserConflicts', macros));
			this.lioModalService.confirm(null, 'userConflictMessage', macros).then((confirmed) => {
				if (!confirmed) {
					isValidFile = false;
				}
			});
		}

		if (totalFullFileDeactivations) {
			macros.push({'key': 'totalFullFileDeactivations', 'value': totalFullFileDeactivations});
			messages.push(this.utilService.localizeMessage('totalUsersWillDeactivateDueFullProc', macros));
		}

		if (!totalEdited && !totalActivated && !totalDeactivated) {
			messages.push(this.utilService.localizeMessage('ZeroUsersWillBeUpdated'));
		}
		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalIssuesWithTheFile', macros));
			isValidFile = false;
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		this.readyForProcess = isValidFile;
		if (isValidFile) {
			this.navService.changedForm = true;
		}
	}

	/*
	 * Process the file
	*/
	processFile() {
		let	langs = this.localizationService.getAvailableLanguages();

		this.lmsService.postAsync('importemployee/process', {'filePath': this.filePath, 'delta': this.model.processType, 'langs': langs}, 
		'processing').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleProcessResponse(gresult);
				});
			} else {
				this.handleProcessResponse(result);
			}
			
		});
	}

	/*
	 * Handler for process response
	*/
	handleProcessResponse(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}
		let properties = result.properties,
			totalAdded = properties.totalAdded,
			totalEdited = properties.totalEdited,
			totalFailed = properties.totalFailed,
			totalActivated = properties.totalActivated,
			totalDeactivated = properties.totalDeactivated,
			addedUsers = properties.addedUsers,
			editedUsers = properties.editedUsers,
			activatedUsers = properties.activatedUsers,
			deactivatedUsers = properties.deactivatedUsers,
			totalFullFileDeactivations = properties.totalFullFileDeactivations,
			employeeIDs = properties.ids,
			messages = [],
			macros = [];

		this.lioModalService.hideLoading();

		this.addedUsers = addedUsers;
		this.editedUsers = editedUsers;
		this.deactivatedUsers = deactivatedUsers;
		this.activatedUsers = activatedUsers;
		this.processedFile = true;

		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalUsersSuccessfullyInserted', macros));
		}

		if (totalEdited) {
			macros.push({'key': 'totalEdited', 'value': totalEdited});
			messages.push(this.utilService.localizeMessage('totalUsersSuccessfullyUpdated', macros));
		}
		if (totalActivated) {
			macros.push({'key': 'totalActivated', 'value': totalActivated});
			messages.push(this.utilService.localizeMessage('totalUsersSuccessfullyReactivated', macros));
		}
		if (totalDeactivated) {
			macros.push({'key': 'totalDeactivated', 'value': totalDeactivated});
			messages.push(this.utilService.localizeMessage('totalUsersSuccessfullyDeactivated', macros));
		}

		if (totalFullFileDeactivations) {
			macros.push({'key': 'totalFullFileDeactivations', 'value': totalFullFileDeactivations});
			messages.push(this.utilService.localizeMessage('totalUsersDeactivatedDueToFulProcess', macros));
		}
		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalNumberOfUnexpectedIssues', macros));
		}

		if (employeeIDs) {
			this.loginService.updateCCFValues();
			this.storageService.remove('courseID');
			this.getEmployees(employeeIDs);
		}

		this.feedbackService.setMessages(messages);
		this.navService.changedForm = false;
	}

	/*
	 * File input handler
	*/
	onFileInput() {
		this.storageService.deleteItem('dispatchedParams');
	}

	/*
	 * Gets the importing template
	*/
	getTemplate() {
		this.lioModalService.showLoading('processing');
		this.lmsService.post('employee/getTemplate', {'fields': this.fields}).then((result) => {
			let success = result.success,
				file = result.properties.file;

			if (success) {
				this.filesService.getFile(file).then(() => {
					this.lioModalService.hideLoading();
				});
			}
			
		});
	}

	/*
	 * Gets the importing sample
	*/
	getSample() {
		this.lioModalService.showLoading('processing');
		this.lmsService.post('employee/getSample', {'fields': this.fields}).then((result) => {
			let success = result.success,
				file = result.properties.file;

			this.lioModalService.hideLoading();

			if (success) {
			this.filesService.getFile(file).then(() => {
					this.lioModalService.hideLoading();
				});
			}
			
		});
	}

	/*
	 * Gets the employees
	*/
	getEmployees(employeeIDs) {
		this.lmsService.postAsync('employee/getEmployeesByEmployeeIDs', {'employeeIDs': employeeIDs}, 
		'loadingRecords').then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleGotEmployees(gresult);
				});
			} else {
				this.handleGotEmployees(result);
			}
		});
	}

	/*
	 * Handler for got employees
	*/
	handleGotEmployees(result) {
		let employees = result.properties.employees;
		let employeeID;
		let index;

		this.lioModalService.hideLoading();

		employees.forEach((employee) => {
			employee = this.postHandleEmployee(employee);
			employeeID = employee['employeeID'];
			// Remove the employee from the employees if already there so it can update
			if (this.utilService.valueinArray(this.employeeIDs, employeeID)) {
				index = this.employeeIDs.indexOf(employeeID);
				this.employeeIDs.splice(index, 1);
				this.employees.splice(index, 1);
			}
			this.employeeIDs.push(employeeID);
			this.employees.push(employee);
			
		});
	}

	/*
	 * Exports the employee records
	*/
	exportRecords() {
		if (!this.workerService.export(this.employees, this.fields, 'employees')) {
			this.lioModalService.show('Failed to export employees');
		}
	}

	/*
	 * Exports the employee records
	*/
	exportReport() {
		let fields = this.exportReportFields,
			employees = [];


		this.addedUsers.forEach((user) => {
			employees.push({'added': user});
		});

		this.editedUsers.forEach((user) => {
			employees.push({'edited': user});
		});

		this.activatedUsers.forEach((user) => {
			employees.push({'activated': user});
		});

		this.deactivatedUsers.forEach((user) => {
			employees.push({'deactivated': user});
		});

		if (!this.workerService.export(employees, fields, 'process_report')) {
			this.lioModalService.show('Failed to export employees');
		}
	}

	/*
	 * Submits the edit
	*/
	submit() {
		let form = {'employee': this.employee },
			macros = [];

		if (this.feedbackService.getErrors().length) {
			return;
		}

		if (!this.isValid()) {
			return;
		}

		this.feedbackService.clearAll();
		this.lioModalService.showLoading('processing');
		this.navService.changedForm = false;

		this.lmsService.post('employee/addUser', form).then((result) => {
			let employeeIDs = [this.employee.employeeID];
				
			this.lioModalService.hideLoading();
			this.allowSubmit = false;

			if (result.success) {
				this.addedUsers.push(this.employee.employeeID);
				macros.push({'key': 'employeeID', 'value': this.employee.employeeID});
				this.feedbackService.setMessage('employeeIdSuccessfullyAdded', macros);
				this.loginService.updateCCFValues();
				this.getEmployees(employeeIDs);
			} else if (result.errors) {
				this.feedbackService.setErrors(result.errors);
			}
		});
	}

	/*
	 * Post handler for the employee
	 * @param {object} employee
	 * @return {object} employee
	*/
	postHandleEmployee(employee) {
		let role = this.permissionService.getRoleNameFromID(employee.permissionID),
			status = employee.inactive == 1 ? 'Inactive' : 'Active';

		employee.role = role;
		employee.status = status;

		return employee;
	}

	/*
	 * On Click of the Assign button
	*/
	assignCourses() {
		this.storageService.setEmployeesToAssign(this.employees);
		this.navService.goto('assign');
	}

	/*
	 * Edit's an employee
	*/
	edit(employee) {
		this.storageService.set('employeeToEdit', employee);
		this.storageService.set('task', 'external');
		this.navService.goto('profile');
	}

	ngOnInit() {
		this.init();
		this.navService.displayPage();
	}
}