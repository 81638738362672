<!-- wheres -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Wheres</mat-card-title>
	<mat-card-content id="am_form_wheres" [style.fontSize.px]="11" class="mb-0" *ngFor="let where of model.wheres; index as i;">
		<div class="row no-gutters" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Alias -->
				<input id="{{'am_form_alias_' + i}}" 
					matInput
					placeholder="Alias" 
					[(ngModel)]="where.alias" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Field -->
				<input id="{{'am_form_wheres_' + i}}" 
					matInput
					placeholder="Field" 
					[(ngModel)]="where.field" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<mat-label>Operator</mat-label>
				<!-- Operator -->
				<mat-select id="am_form_{{field.model}}" 
					[name]="field.model"
					[(ngModel)]="where.operator"> 

					<!-- Options List -->
					<mat-option
						*ngFor="let option of formdata.operators"
						[model]="option"
						[value]="option.value" 
						localize
						item="name">
						{{option.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<!-- Value -->
				<input id="{{'am_form_wheres_' + i}}" 
					matInput
					placeholder="Value" 
					[(ngModel)]="where.value" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-1" appearance="fill">
				<mat-label>Connector</mat-label>
				<!-- Connector -->
				<mat-select id="am_form_{{field.model}}" 
					[name]="field.model"
					[(ngModel)]="where.operator"> 

					<!-- Options List -->
					<mat-option
						*ngFor="let option of formdata.connectors"
						[model]="option"
						[value]="option.value" 
						localize
						item="name">
						{{option.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="col col-xl-1" appearance="fill">
				<mat-label>Opener</mat-label>
				<!-- Add Opener -->
				<mat-select id="am_form_{{field.model}}" 
					[name]="field.model"
					[(ngModel)]="where.operator"> 

					<!-- Options List -->
					<mat-option
						*ngFor="let option of formdata.openers"
						[model]="option"
						[value]="option.value" 
						localize
						item="name">
						{{option.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field class="col col-xl-1" appearance="fill">
				<mat-label>Closer</mat-label>
				<!-- Add Closers -->
				<mat-select id="am_form_{{field.model}}" 
					[name]="field.model"
					[(ngModel)]="where.operator"> 

					<!-- Options List -->
					<mat-option
						*ngFor="let option of formdata.closers"
						[model]="option"
						[value]="option.value" 
						localize
						item="name">
						{{option.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<button mat-button color="warn" (click)="formdata.remove('wheres', where)" class="col col-xl-1 fa fa-minus-square fa-2x p-1 button"></button>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- New where -->
		<button mat-button color="primary" (click)="formdata.addNew('wheres')">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span>Add where</span>
		</button>

		<!-- Company where -->
		<button mat-button color="primary" (click)="formdata.addCompanyWhere()">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span>Add Company</span>
		</button>
	</mat-card-actions>
</mat-card>