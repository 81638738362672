<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="mx-auto col-md-8">
		<lio-card>
			<div lio-card-header>
				<div>
					<mat-icon [style.fontSize.rem]="6" 
						[style.width.rem]="6" 
						[style.height.rem]="6">
						warning
					</mat-icon>
				</div>
				<button *ngIf="allowRetry" 
					mat-flat-button
					color="primary"
					(click)="retry()" 
					type="submit">

					<strong>{{localeStrings.tryAgain}}</strong>
				</button>
			</div>
			<div lio-card-body>
				<div class="row text-center">
					<div class="mx-auto col-md-8">
						<h4>{{error}}</h4>
						<div [innerHtml]="detail | html"></div>
					</div>
				</div>
			</div>
			<div lio-card-footer>
				<div class="row text-center">
					<div class="mx-auto col-md-8">
						<button *ngIf="allowSend" 
							(click)="sendError()" 
							class="btn btn-sm btn-primary" 
							type="submit">

							<strong>Send Error</strong>
						</button>
						<button *ngIf="permissionService.hasPermission('pages.errorLog') && hasErrorID" 
							mat-flat-button
							color="primary"
							(click)="gotoError()" 
							type="submit">
							
							<strong>View Error Log</strong>
						</button>
						<i [hidden]="!showLoading" class="fa fa-spinner fa-spin" style="font-size:3em; margin: .5em"></i>
					</div>
				</div>
			</div>
		</lio-card>
	</div>
</div>