import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import moment from 'moment';

import { jsPDF } from "jspdf";

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { coursesService } from '../../services/courses.service';
import { processingService } from '../../services/processing.service';
import { storageService } from '../../services/storage.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { fieldService } from '../../services/fields.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { filesService } from '../../services/files.service';
import { lioLogService } from '../../services/lio-log.service';
import { permissionService } from '../../services/permissions.service';
import { pdfService } from '../../services/pdf.service';

import { dashboardSettings } from './dashboard.settings';

@Component({
  selector: 'lio-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
	@ViewChild('pdfTarget') pdfTarget: ElementRef;

	public employees			:Array<any>	= [];
	public employeeIDs			:Array<any>	= [];
	public employeesInCourses	:Array<any>	= [];
	public search				:any		= {};
	public filteredEmployees	:Array<any>	= [];
	public courses				:Array<any>	= [];
	public totalCourses			:number		= 0;
	public loaded				:boolean	= false;
	public coursesLoaded		:boolean	= false;
	public employeesLoaded		:boolean	= false;
	public setCourseSearch		:boolean	= false;
	public searches				:Array<any>	= [];
	public searchConfig			:any		= {};
	public fields				:Array<any>	= [];
	public fieldConfig			:any		= {};
	public pagination			:any		= {};
	public filters				:Array<any>	= [];
	public queryToolSettings	:any 		= {};
	public queryTool			:any		= {};
	public registered			:Array<any>	= [];
	public showTestPDF			:boolean	= false;
	public chunking				:boolean	= false;
	public warnNumber			:number		= 40000;
	public exportingRecords		:boolean 	= false;
	public totalEmployees		:number		= 0;
	public stats				:any		= {};

	// Booleans for functionality
	public disableAssignCourses		:boolean 	= false;
	public supressLoadOnStart		:boolean 	= true;
	public toggleStatsMinimum		:number 	= 15;
	public exportWithWorker			:boolean 	= true;

	// Viewable States
	public showFilters				:boolean	= true;
	public showDashboard			:boolean	= false;
	public showStats				:boolean	= false;
	public showTitle				:boolean	= true;

	// Disabled States
	public canOpenDashboard			:boolean 	= false;
	public canOpenFilters			:boolean	= true;
	public canCloseDashboard		:boolean	= false;
	public canCloseFilters			:boolean	= false;
	public confirmed				:boolean	= false;
	public allowSendEmail			:boolean	= false;
	public statsLoaded				:boolean	= false;
	public collection				:Array<any>	= [];
	public prefixButtons			:Array<any>	= [];
	public permissions				:any		= null;
	public localizations			:any		= {};
	public localizationItems		:Array<any>	= [];
	public contrast:string =  '';

	private subscriptions:Subscription 			= NEVER.subscribe();

  constructor(
		@Inject(navService)			public 	navService			:navService,
		@Inject(debugService)		public 	debugService		:debugService,
		@Inject(coursesService)		public 	coursesService		:coursesService,
		@Inject(processingService) 	public 	processingService	:processingService,
		@Inject(storageService)		public 	storageService		:storageService,
		@Inject(feedbackService)	public 	feedbackService		:feedbackService,
		@Inject(utilService)		public 	utilService			:utilService,
		@Inject(errorsService)		public 	errorsService		:errorsService,
		@Inject(fieldService)		public 	fieldService		:fieldService,
		@Inject(lmsService)			public 	lmsService			:lmsService,
		@Inject(lioLogService)		public 	lioLogService		:lioLogService,
		@Inject(stateService)		public 	stateService		:stateService,
		@Inject(workerService)		public 	workerService		:workerService,
		@Inject(filesService)		public 	filesService		:filesService,
		@Inject(permissionService)	public 	permissionService	:permissionService,
		@Inject(lioModalService)	public 	lioModalService		:lioModalService,
		@Inject(pdfService)			public 	pdfService			:pdfService,
		@Inject(dashboardSettings)	public 	dashboardSettings	:dashboardSettings
	){
		this.debugService.register('dashboard', this);

		// Global Vars
		this.navService.setActivePage('dashboard');
		
		this.processingService.allowCancel = true;	

		this.searches 			= this.dashboardSettings.searches;
		this.searchConfig		= this.dashboardSettings.searchConfig;
		this.fields 			= this.dashboardSettings.fields;
		this.fieldConfig 		= this.dashboardSettings.fieldConfig;
		this.pagination 		= this.dashboardSettings.pagination;
		this.filters 			= this.dashboardSettings.filters;
		this.queryToolSettings 	= this.dashboardSettings.queryToolSettings;
		this.localizations 		= this.dashboardSettings.localizations;
		this.localizationItems 	= Object.keys(this.localizations);
		this.showTestPDF 		= this.storageService.get('qa');
		this.permissions 		= this.permissionService;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
		
		this.subscriptions.add(
			this.errorsService.timeout.subscribe(() => {
				this.reset();
				this.showFilters = true;
				this.showDashboard = false;
				this.lioModalService.show('systemTookTooLong');
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		this.navService.setActivePage('dashboard');
	}

	updateFilteredEmployees(paginatorResults) {
		this.collection = paginatorResults.collection;
		this.filteredEmployees = paginatorResults.filtered;
		this.setCourses();
	}

	requestedDashboard() {
		this.reset();
	}

	toggleFilters() {
		if (this.isDownloading()) {
			return;
		}
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.showFilters = !this.showFilters;
		if (!this.showFilters) {
			this.showDashboard = true;
		} 
	}

	assignCourses() {
		if (this.isDownloading()) {
			return;
		}

		let employees = this.getEmployees();

		if (employees.length > 20000) {
			this.lioModalService.showLoading('processing');
		}

		this.getUniqueEmployees().then((response:any) => {
			this.lioModalService.hideLoading();
			this.storageService.setRulesToAssign(this.queryTool.getRules(), response.unique);
			this.navService.goto('assign');
		});
	}

	toggleDashboard() {
		this.showDashboard = !this.showDashboard;
		if (!this.showDashboard) {
			this.showFilters = true;
		} 
	}

	toggleStats() {
		this.showStats = !this.showStats;
	}

	setCourses() {
		var employees = this.getEmployees(),
			courses = [],
			course = {},
			currentCourse,	
			i;		

		if (!employees.length) {
			this.courses = [];
			return;
		}

		// Loop through each enrollment
		employees.forEach((employee) => {
			var role = this.permissionService.getRoleNameFromID(employee.permissionID),
				status = employee.inactive == 1 ? 'Inactive' : 'Active',
				courseID,
				required = true,
				completed = false,
				courseName;

			employee.role = role;
			employee.status = status;
			courseID = employee.courseID;
			courseName = employee.courseName;

			// Create data object of this course
			if (!course[courseID]) {
				course[courseID] = {};
				course[courseID]['courseID'] = courseID;
				course[courseID]['courseName'] = this.coursesService.localize(courseName, courseID);
				course[courseID]['total'] = 0;
				course[courseID]['completed'] = 0;
			}

			// Remove due date if not required
			if (employee['required'] != 1) {
				required = false;
				employee['courseDueDate'] = null;
			}

			// Fill in the data
			course[courseID]['total']++;
			if (employee.courseCompletion == '1') {
				course[courseID]['completed']++;
				completed = true;
			}

			// If the course is not required we do not need to determnine late/on time
			if (!required) {
				return;
			}

			if (completed) {
				// Check if they completed on time
				if (employee.courseDueDate && employee.courseCompletionDate) {
					let dueDate = moment(employee.courseDueDate).format('Y-MM-DD'),
						completionDate = moment(employee.courseCompletionDate).format('Y-MM-DD'),
						completedOnTime = moment(dueDate).isSameOrAfter(completionDate),
						completedLate = !completedOnTime;

					employee['completedOnTime'] = completedOnTime;
					employee['completedLate'] = completedLate;
				}
			} else {
				// Check if they are past due
				if (employee.courseDueDate) {
					let dueDate = moment(employee.courseDueDate).format('Y-MM-DD'),
						currentDate = moment().format('Y-MM-DD'),
						pastDue = moment(currentDate).isAfter(dueDate);
					employee['pastDue'] = pastDue;
				}
			}

		});

		// Convert data to an array
		for (i in course) {
			currentCourse = course[i];
			if (currentCourse) {
				courses.push(currentCourse);
			}
		}

		if (!courses.length) {
			courses.push({
				'courseID': '0000',
				'courseName': 'No Results',
				'total': 0,
				'completed': 0,
			});
		}

		this.totalCourses = courses.length;
		this.courses = courses;
		this.setCourseIDSearch();
		this.updateEmailVisibility();
		
		this.coursesLoaded = true;
	}

	setCourseIDSearch() {
		if (this.setCourseSearch) {
			return;
		}
		let options = [];

		this.courses.forEach((course) => {
			let option = {
				value	: course.courseID,
				name	: course.courseID + ' - ' + course.courseName,
				selected: false
			};
			options.push(option);
		});

		this.searches.forEach((search) => {
			if(search.model == 'courseID'){
				search.options = options
			}
		});
		this.setCourseSearch = true;
	}

	updateEmailVisibility() {
		var employees = this.getEmployees();

		if (this.courses.length === 1 && employees.length) {
			this.allowSendEmail = true;
		} else {
			this.allowSendEmail = false;
		}
	}

	gobackAndClear() {
		this.navService.goback();
	}

	editQuery() {
		if (this.isDownloading()) {
			return;
		}
		this.showFilters = true;
		// Scroll to the top and focus on the create dashboard button
		this.navService.scrollToTop().then(() => {
			this.navService.focusOn('am_create_dash');
		});
	}

	loadQueryResults(results) {
		if (!results) {
			this.employees = [];
			this.employeeIDs = [];
			return;
		}
		this.lioModalService.showLoading('finalizing');
		this.loadEmployees(results.properties.employees);
		this.loadStats(results.properties.stats);

		this.showTitle = false;
		setTimeout(() => {
			this.lioModalService.hideLoading();
		}, 100);
	}

	loadStats(stats) {
		this.stats = stats;
        if (this.stats) {
			this.statsLoaded = true;
			this.showStats = true;
        }
	}

	send(settings) {
		this.lmsService.postAsync('email/sendEmails', settings,
		'processing').then((result)  => {
			let token = result.properties.token;
			this.lmsService.getAsyncResult(token, (result)  => {
				this.lioModalService.hideLoading();
				if (result.success) {
					this.handleSent(result);
				}
			});
		});
	}

	handleSent(response:any) {
		let success				= response.success,
			totalSent			= response.properties.totalSent,
			failedAddresses 	= response.properties.failedAddresses,
			macros				= [],
			feedback 			= 'error.tryThatAgain';
		
		if (success) {
			macros.push({'key': 'totalSentEmails', 'value': totalSent});
			feedback = 'message.sentSuccessfullyEmails';
		}
		
		if (failedAddresses) {
			macros.push({'key': 'failedOnEmail', 'value': failedAddresses});
			feedback = 'message.failedToSendEmailTo';
		}
		this.lioModalService.show(feedback, null, macros);
	}

	exportPDF() {
		this.pdfService.render(this.pdfTarget).subscribe((pdf:jsPDF) => {			
			pdf.save(this.getTitle() + '.pdf');
		});
	}

    queryToolReady() {
			this.navService.displayPage();
		}

	/*
	 * Gets the title for the pdf report
	*/
	getTitle() {
			return 'dashboard_' + this.utilService.randomNumber();
	}

	/*
	 * Shows the this.lioModalService to email the employee records
	*/
	emailRecords() {
		if (this.isDownloading()) {
			return;
		}
		this.feedbackService.clearErrors();

		if (this.totalEmployees > this.warnNumber) {
			this.lioModalService.showLoading('processing');
		}

		this.workerService.prepareExport(this.getEmployees(), this.fields, 'Employees').then((result:any) => {
			this.lioModalService.hideLoading();
			if (!result) {
				this.feedbackService.setError('failedToExportRecords');
				return;
			}
			this.pdfService.render(this.pdfTarget).subscribe((pdf:jsPDF) => {
				var reader = new window.FileReader();
				reader.readAsDataURL(pdf.output("blob"));
				reader.onloadend = () => {
					let email = {
						'type'				: 'send-email',
						'title' 			: 'dashboard.sendEmailModalTitle', 
						'toAddresses'		: this.stateService.getSession().email,
						'attachmentTitle'	: this.getTitle(), 
						'csv' 				: result.text, 
						'pdf' 				: reader.result, 
						'width'				: '50%',
						'suppressHide'		: true,
					};
				
					this.lioModalService.open(email).then((settings) => {
						if (!settings) {
							return;
						}

						this.lioModalService.hide();
						
						this.send(settings);
					});
				};
			});
		});
	}

	exportRecords() {
		if (this.isDownloading()) {
			return;
		}

		if (this.totalEmployees > this.warnNumber) {
			this.lioModalService.showLoading('processing');
		}

		let employees = this.getEmployees();
		
		this.workerService.export(employees, this.fields, 'Employees').then((result) => {
			this.lioModalService.hideLoading();
			if (!result) {
				this.feedbackService.setError('failedToExportRecords');
			}
			this.exportingRecords = false;
		});

		this.exportingRecords = true;
	}

	isDownloading() {
		if (this.queryTool.cancelled) {
			this.chunking = false;
		}
		if (this.chunking) {		
			this.lioModalService.showLoading('stillDownloading');
			return true;
		}

		if (this.exportingRecords) {
			return true;
		}
		return false;
	}

	loadEmployees(employees) {
		this.employeesInCourses = employees;

		if (!this.employeesInCourses) {
			this.employees = [];
		}

		this.employees = this.employeesInCourses;
		this.update();
		
		this.totalEmployees = this.employees.length;
        this.employeesLoaded = true;

        this.showDashboard = true;
        this.canCloseDashboard = true;
        this.canCloseFilters = true;
        this.canOpenDashboard = true;
        this.showFilters = false;
    }

    onupdate() {
		setTimeout(() => {
			this.update();
		}, 500);
	}

	cleanSearch() {
		let i = null;
		for (i in this.search) {
			if (this.search[i] === '') {
				delete this.search[i];
			}
		}
	}

	update() {
		this.cleanSearch();

		let employees = this.getEmployees();

		this.totalEmployees = employees.length;

		this.setCourses();

		if (!employees.length) {
			this.disableAssignCourses = true;
		} else {
			this.disableAssignCourses = false;
		}

		// Disables the assign courses if we have inactive users visible
		employees.forEach((employee) => {
			if (employee.inactive == 1) {
				this.disableAssignCourses = true;
			}
		});
	}

	hasSearch() {
		let hasProperty = true;
		
		if (!this.search) {
			hasProperty = false;
		}

		if (this.search == {}) {
			hasProperty = false;
		}
		return hasProperty;
	}

	getEmployees() {
		if (!this.collection.length) {
			return this.employees;
		}

		return this.collection;
	}

	getUniqueEmployees() {
		let config = {
			'employees': this.collection, 
			'key': 'employeeID'
		};

		return this.workerService.call('getUnique', config, null);
	}

	sendEmailToEnrolled() {
		if (this.isDownloading()) {
			return;
		}
		
		let courseID = this.courses[0].courseID,
			employees = this.getEmployees();

		if (employees.length > 20000) {
			this.lioModalService.showLoading('processing');
		}

		this.storageService.set('courseID', courseID);

		this.getUniqueEmployees().then((response:any) => {
			this.lioModalService.hideLoading();
			this.storageService.set('employeesToEmail', response.unique);
			this.storageService.set('employeeIDsToEmail', response.uniqueIDs);
			this.storageService.set('task', 'emailing');
			this.navService.goto('email');
		});
	}

	/*
	 * Init
	*/
	init() : void {
		this.prefixButtons = [
			{
				header 		: 'Search',
				name 		: '',
				id 			: 'am_dashboard_profile_prefix',
				color 		: 'primary',
				icon 		: 'search',
				callback 	: (employee) => { this.edit(employee); }
			}
		];
	}

	edit(employee) {
		this.storageService.set('employeeToEdit', employee);
		this.navService.goto('enrollments');
	}

	/*
	 * Resets vars on submit
	*/
	reset() : void {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.showFilters = true;
		this.employees = [];
		this.filteredEmployees = [];
		this.courses = [];
		this.showDashboard = false;
		this.statsLoaded = false;
		this.employeesLoaded = false;
		this.utilService.removeObjectProperties(this.search);
		this.setCourseSearch = false;
		this.canCloseFilters = true;
		this.allowSendEmail = false;
		this.collection = [];
	}
}