/*
 * Service for functions related to enrollment events
*/
import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { lmsService } from './lms.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';
import { lioModalService } from './lio-modal.service';
import { feedbackService } from './feedback.service';
import { querySettings } from '../settings/query.settings';
import { ccfService } from './ccf.service';
import { formValidatorService } from './form-validator.service';
import { storageService } from './storage.service';
import { eventService } from './event.service';
import { debugService } from './debug.service';
import { exportService } from './export.service';
import { fieldService } from './fields.service';
import { navService } from './nav.service';
import { filesService } from './files.service';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class enrollmentEventService extends eventService {
	public override endPoint 		:string = 'enrollmentevents';
	public override historyEndPoint :string = 'enrollmenteventhistory';

	constructor(
		@Inject(lioLogService)			public override lioLogService 			:lioLogService,
		@Inject(lmsService)				public override lmsService				:lmsService,
		@Inject(localizationService)	public override localizationService		:localizationService,
		@Inject(lioModalService)		public override lioModalService			:lioModalService,
		@Inject(feedbackService)		public override feedbackService			:feedbackService,
		@Inject(utilService)			public override utilService				:utilService,
		@Inject(storageService)			public override storageService 			:storageService,
		@Inject(formValidatorService)	public override formValidatorService 	:formValidatorService, 
		@Inject(debugService)			public override debugService 			:debugService,
		@Inject(fieldService)			public override fieldService			:fieldService,
		@Inject(exportService)			public override exportService			:exportService,
		@Inject(navService)				public override navService				:navService,
		@Inject(filesService)			public override filesService			:filesService,
		@Inject(ccfService)				public ccfService						:ccfService,
		@Inject(querySettings)			public querySettings					:querySettings
	){
		super(
			lioLogService, 
			lmsService, 
			localizationService, 
			lioModalService, 
			feedbackService, 
			utilService, 
			storageService, 
			formValidatorService, 
			debugService, 
			exportService, 
			fieldService,
			navService,
			filesService
		);
	}

	/** 
	 * Validate an event
	 * @return {boolean}
	 * @override
	 */
	public override validateEvent(event) {
		if (!super.validateEvent(event)) {
			return false;
		}
		
		return this.validateConditions(event);
	}


	/** 
	 * Validate event conditions
	 * @return {boolean}
	 */
	validateConditions(event) {
		let isValid = true;
		
		event.conditions.forEach((condition, index) => {
			condition.hasError = false;

			if (index == 0) {
				return;
			}
			
			if (!condition.courseID) {
				condition.hasError = true; 
				isValid = false;
			}
			// If they are not enrolled in the course, they could not have completed
			if (!condition.enrolled && (condition.completed == 2)) {
				condition.hasError = true; 
				isValid = false;
			}
		});

		if (!isValid) {
			event.showConditions = true;
			this.lioModalService.show('Please correct the enrollment conditions');
		}
		return isValid;
	}

	
	/**
	 * Gets the filter title
	 * @param {array} event
	 * @return {object}
	 */
	override getFilterTitle(event) {
		let macros 	= [{'key': 'filterName', 'value': event.filterName}],
			title 	= this.localizationService.get('enrollmentEvent.usersWho', 'Applies to users:', macros);
		return title;
	}

	/**
	 * turns event filter data into friendlier descriptions
	 * @param {array} event
	 * @return {array}
	 * @overide
	 */
	override getFilterDescription(event):Array<any> {
		let descriptions = [];

		descriptions.push({
			value				: 'Have not been enrolled in course {{courseID}}', 
			trans 				: 'enrollmentEvent.notEnrolled', 
			macros 				: [{key: 'courseID', value: event.courseID}],
		});

		descriptions = this.addConditionsToDescription(event.actions, descriptions);
		descriptions = this.addFiltersToDescription(event.filters, descriptions);

		return descriptions;
	}


	/** 
	 * Gets the list of active and inactive events
	 */
	override getEvents(includePacEvents) {
		super.getEvents(includePacEvents);
		this.getQueries();
	}


	/** 
	 * Gets the list of active and inactive events
	 */
	getQueries() {
		return this.lmsService.post('query/getAllByType', {'type': 'scheduledEnrollments'}, {'cache': true}).then((result) => {
			let queries = result.properties.values,
				options = [];
			
			if (queries) {
				queries.forEach((query) => {
					options.push({'value': query.id, 'name': query.name});
				});	
			}
			
			this.eventOptions.next(options);
		});
	}

	/** 
	 * Handles the event
	 * @override
	 */
	override handleEvent(event) {
		event = super.handleEvent(event);
		event.rules = [];

		if (!event.rule) {
			event.rule = {};
		}

		if (event.courseID) {
			event.rule = {
				courseID		: event.courseID,
				availableDate	: 0,
				dueDate 		: 30,
				expirationDate 	: 365,
			};
		}

		event = this.handleActions(event);
		event = this.addDefaultCondition(event);
		return event;
	}

	/** 
	 * Handles the actions
	 * @override
	 */
	handleActions(event) {
		event.group = {};
		if (!event.actions) {
			event.actions = [];
		}
		event.actions.forEach((action) => {
			if (action.type == 'enrollmentRule') {
				event.rule = action;
				if (action.dueDate) {
					event.rule.dueDate = parseInt(action.dueDate);
				}
				if (action.availableDate) {
					event.rule.availableDate = parseInt(action.availableDate);
				}
				if (action.expirationDate) {
					event.rule.expirationDate = parseInt(action.expirationDate);
				}
				if (action.courseID) {
					event.rule.courseID = action.courseID;
				}
			}

			if (action.type == 'enrollmentGroup') {
				event.group = action;
				if (!action.conditions || !action.conditions.length) {
					action.conditions = [];
					this.lioLogService.error('The event is missing conditions');
				}
				event.conditions = action.conditions;
			}
		})
		return event;
	}


	/**
	 * Adds the default condition
	 */
	addDefaultCondition(event){
		let courseID = event.rule.courseID;
		
		if (!courseID) {
			event.conditions = [];
			return event;	
		}

		if (!event.conditions) {
			event.conditions  = [];
		}

		if (!event.conditions.length) {
			event.conditions.push({
				'courseID': courseID,
				'connection': null,
				'enrolled': '0',
				'completed': '3',
			});
		} else {
			event.conditions[0]['courseID'] = courseID
		}
		return event;
	}

	/*
	 * Handles managed event redirects
	*/
	override handleManagedEvent(managedEvent) {
		let matchedEvent 	= null,
			courseID 		= null;

		if (managedEvent) {
			courseID = managedEvent.courseID;
			if (courseID && courseID.indexOf(',') > -1) {
				courseID = courseID.split(',');
			} else {
				courseID = [courseID];
			}

			this.events.forEach((event) => {
				if (managedEvent.courseID.indexOf(event.courseID) > -1) {
					// Set the event to the existing enrollment event
					matchedEvent = event;
					courseID = courseID[0];
					matchedEvent.courseID = courseID;
					if (event.active) {
						this.callback.next({'name': 'changeTab', 'value': 'list'});
						return;
					}
				}
			});

			if (Array.isArray(courseID)) {
				courseID = courseID[0];
			}

			if (!matchedEvent) {
				matchedEvent = {
					id: null,
					name: managedEvent.name,
					description: managedEvent.description,
					type: 'enrollment',
					eventType: 'enrollment',
					courseID: courseID,
					actions: [],
					active: false,
					archived: false,
					filterValue: 0,
					filters: [],
					rules: [],
					schedule: managedEvent.schedule,
				};
			}
		}

		return this.handleEvent(matchedEvent);
	}

	/** 
	 * Determines if there is enough info to get the user information
	 * @return {boolean}
	 * @override
	 */
	canGetUserInfo(event):boolean {
		return event.rule.courseID && event.queryID;
	}

	/** 
	 * Preps the event
	 * @override
	 */
	override prepEvent(event) {
		let eventCopy = super.prepEvent(event);

		event.schedule.startTime = moment(event.schedule.startTime).format('YYYY-MM-DD') + ' 07:00:00';


		let groupID = null,
			ruleID = null,
			actions = [];

		if (eventCopy.group) {
			groupID = eventCopy.group.id;
		}
		if (eventCopy.rule) {
			ruleID = eventCopy.rule.id
		}

		eventCopy.courseID = eventCopy.rule.courseID;
		actions = [
			{
				'type': 'enrollmentGroup',
				'id': groupID,
				'conditions': eventCopy.conditions
			},
			{
				'type': 'enrollmentRule',
				'id': ruleID,
				'courseID': eventCopy.rule.courseID,
				'dueDate': eventCopy.rule.dueDate,
				'availableDate': eventCopy.rule.availableDate,
				'expirationDate': eventCopy.rule.expirationDate,
			}
		];

		eventCopy.actions = actions;
		delete eventCopy.group;
		delete eventCopy.conditions;

		return eventCopy;
	}


	/**
	 * Adds filters to the description
	 * @param {array} filters
	 * @param {array} descriptions
	 * @return {array}
	 */
	addFiltersToDescription(filters, descriptions) {
		let queryFilter = null;
		let description = '';
		let operator 	= '';
		let addedStatus = false;
		let label 		= '';
		let value;

		filters.forEach((filter) => {
			queryFilter = this.getQueryToolFilterByField(filter);
			if (!queryFilter) {
				this.lioLogService.error('Failed to match field with queryFilter: ' + filter.field);
				return;
			}

			if (filter.field == 'e.inactive') {
				addedStatus = true;
			}

			if (queryFilter.value) {
				value = queryFilter.value;
			} else {
				value = filter.value;
			}

			label = this.localizationService.get(queryFilter.label, queryFilter.label);

			switch (filter.operator) {
				case 'equal':
					description = label + ' is ' + value;
					break;
				case 'not_equal':
					description = label + ' is ' + value;
					break;
				case 'begins_with':
					description = label + ' begins with ' + value;
					break;
				case 'ends_with':
					description = label + ' ends with ' + value;
					break;
				case 'between':
					description = label + ' is between ' + value[0] + ' and ' + value[1];
					break;
				case 'not_in':
					description = label + ' is not in ' + value;
					break;
				case 'in':
					description = label + ' is in ' + value;
					break;
				case 'is_empty':
				case 'is_null':
					description = label + ' is null';
					break;
				case 'is_not_empty':
				case 'is_not_null':
					description = label + ' is not null';
					break;
				default:
					description = label + ' ' + operator + ' ' + value;
			}
		

			descriptions.push({'value': description, 'trans': null, 'macros': null}); 
		});

		
		if (!addedStatus) {
			descriptions.push({
				'value'				: 'Status is active', 
				'trans' 			: 'enrollmentEvent.isActive',
				'macros' 			: null,
			});
		}

		return descriptions;
	}


	/**
	 * Gets the Query tool filter by a field
	 * @param {array} filters
	 * @param {array} description
	 * @return {array}
	 */
	getQueryToolFilterByField(originalFilter) {
		let match = null,
			filters = this.querySettings.filters;

		filters = this.ccfService.addCCFtoFilters(filters);			
		filters.forEach((filter) => {
			if (filter.field === originalFilter.field) {
				match = filter;
				if (match.options) {
					// Handles matching select options 
					match.options.forEach((value) => {
						if (value[originalFilter.value]) {
							match.value = value[originalFilter.value];
						} else if (typeof value.key !== 'undefined' && value.key == originalFilter.value) {
							match.value = value.value;
						}
					});
				}
			}
		});

		return match;
	}

	/**
	 * Adds enrollment conditions to the description
	 * @param {array} filters
	 * @param {array} descriptions
	 * @return {array}
	 */
	addConditionsToDescription(actions, descriptions) {
		let description = '';
		actions.forEach((action) => {
			if (action.type === 'enrollmentGroup') {
				action.conditions.forEach((condition, index) => {
					description = '';
					if (index == 0) {
						return;
					}

					if (index) {
						if (condition.connection == 'AND') {
							description += 'And ';
						} else if (condition.connection == 'OR') {
							description += 'Or ';
						}
					}
					
					if (condition.enrolled && condition.enrolled != '0') {
						description += 'user is enrolled ';
						if (condition.completed == 2) {
							description += 'and has completed ' + condition.courseID;
						} else if (condition.completed == 1) {
							description += 'and has not completed ' + condition.courseID;
						} else {
							description += 'in ' + condition.courseID;
						}
					} else {
						description += 'User is not enrolled in ' + condition.courseID;
					}
					descriptions.push({'trans': null, 'value': description, 'macros': null}); 
				});
			}
		});

		return descriptions;
	}


	/**
	 * Gets the due date
	 */
	getDueDate(event) {
		let dueDate = '';

		if(event.actions){
			event.actions.forEach((action) => {
				if (action.type == 'enrollmentRule') {
					dueDate = action.dueDate;
				}
			});
		}

		return dueDate;
	}
}