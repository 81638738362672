import { AngularMaterialModule } from '../../imports/angular-material';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LioPipesModule } from '../../pipes/pipes.module';

import { LioDirectivesModule } from '../../directives/directives.module';
import { LioDataDisplayModule } from '../data-display/data-display.module';
import { LioFormsModule } from '../lio-forms/lio-forms.module';
import { LioStructuralModule } from '../structural/structural.module';

import { CompanyAdminEditComponent } from './components/company-admin-edit/company-admin-edit.component';
export { CompanyAdminEditComponent } from './components/company-admin-edit/company-admin-edit.component';

import { CompanyAdminSearchComponent } from './components/company-admin-search/company-admin-search.component';
export { CompanyAdminSearchComponent } from './components/company-admin-search/company-admin-search.component';

const monacoConfig: NgxMonacoEditorConfig = {
	baseUrl: './assets', // configure base path cotaining monaco-editor directory after build default: './assets'
	defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
	onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
	exports: [
		CompanyAdminEditComponent,
		CompanyAdminSearchComponent
	],
	declarations: [
		CompanyAdminEditComponent,
		CompanyAdminSearchComponent
	],
	bootstrap: [
		CompanyAdminEditComponent,
		CompanyAdminSearchComponent
	],
	imports: [
		MonacoEditorModule.forRoot(monacoConfig),
		FormsModule,
		AngularMaterialModule,
		FontAwesomeModule,
		AngularEditorModule,
		CommonModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioFormsModule,
		LioStructuralModule,
		LioPipesModule
	]
})
export class LioCompanyAdminModule {}
