<div id="am_pagination" *ngIf="target" [hidden]="!target.filteredCollection.length" class="dark-text small container-fluid">
	<div class="row mb-2">
		<div class="col-12 col-sm-6 font-weight-bold pb-1">
			<lio-searchable-select [model]="target.settings"
				[field]="pageLimitField"
				[appearance]="appearance"
				[optionAsValue]="true"
				[fullWidth]="false">
			</lio-searchable-select>
		</div>
		
		<div id="am_pagination_rangeDisplay" class="col-12 col-sm-6 font-weight-bold text-xs-left text-sm-right pb-1">{{displayTrans}}</div>
	</div>
	<div class="row overflow-x-auto">
		<div class="col-12" *ngIf="target.getMaxPage() > 0">
			<ul class="pagination mt-1">
				<li class="page-item ml-auto">
					<button class="page-link" id="am_pagination_firstPage" (click)="target.pageFirst()">&laquo;</button>
				</li>
				<li class="page-item">
					<button class="page-link" id="am_pagination_prevPage" (click)="target.pagePrev()">&lsaquo;</button>
				</li>
				<li class="page-item" *ngFor="let pageNum of getPageNumArray()" [ngClass]="{'active' : pageNum == target.currentPage}">
					<button class="page-link" id="{{'am_pagination_setPage_' + pageNum}}" (click)="target.pageSet(pageNum)">{{pageNum + 1}}</button>
				</li>
				<li class="page-item">
					<button class="page-link" id="am_pagination_nextPage" (click)="target.pageNext()">&rsaquo;</button>
				</li>
				<li class="page-item mr-auto">
					<button class="page-link" id="am_pagination_lastPage" (click)="target.pageLast()">&raquo;</button>
				</li>
			</ul>
		</div>
	</div>
</div>