import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultModalComponent } from '.././default/default.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})

export class CatalogComponent extends DefaultModalComponent implements OnInit {
	public topicLimit 	= 10;
	public langLimit 	= 10;
	public descLimit 	= 10;
	public minDesc 		= 10;
	public minTopics 	= 10;
	public minLength 	= 10;

	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public parentSettings: any) {
		super(parentSettings);
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}
}