<!-- joints -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Joints</mat-card-title>
	<mat-card-content id="am_form_joints" [style.fontSize.px]="11" class="mb-0" *ngFor="let joint of model.joints; index as i;">
		<div class="row no-gutters" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
			<mat-form-field class="col col-xl-2" appearance="fill">
				<input id="{{'am_form_joints_' + i}}" 
					matInput
					placeholder="Table Name" 
					[(ngModel)]="joint.tableName" type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<input id="{{'am_form_joints_alias_' + i}}" 
					matInput
					placeholder="Table alias" 
					[(ngModel)]="joint.alias" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<input id="{{'am_form_joints_on' + i}}"
					matInput 
					placeholder="joint on" 
					[(ngModel)]="joint.joinOn" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<input id="{{'am_form_joints_on' + i}}"
					matInput 
					placeholder="Match on Alias" 
					[(ngModel)]="joint.matchOnAlias" 
					type="text" 
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>

			<mat-form-field class="col col-xl-2" appearance="fill">
				<input id="{{'am_form_joints_on' + i}}"
					matInput 
					placeholder="match on" 
					[(ngModel)]="joint.matchOn" 
					type="text"
					(change)="onUpdate.emit()" 
					maxlength="{{field.max || 100}}">
			</mat-form-field>
			<span (click)="formdata.remove('joints', joint)" class="col col-xl-2 fa fa-minus-square fa-2x p-1 button"></span>
		</div>
	</mat-card-content>
	<mat-card-actions>
		<!-- New joint -->
		<button mat-button color="primary" (click)="formdata.addNew('joints')">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">New joint</span>
		</button>

		<!-- Add Enrollment joint -->
		<button mat-button color="primary" (click)="formdata.addEnrollmentJoints()">
			<i class="fa fa-plus fa-lg mr-1"></i>
			<span class="small-topic">Enrollments</span>
		</button>
	</mat-card-actions>
</mat-card>