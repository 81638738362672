<section class="row" localize [model]="localeStrings" [items]="localeStringKeys">
	<div class="col-md-6 mx-auto">
		<lio-card>
			<div lio-card-header>
				<span (click)="navService.goto('login')" class="login-key" >
					<img alt="Login Robot" src="images/assets/LearnIO.png" class="login-robot"/>
				</span>
				<h2 class="h4 pt-3" [innerHtml]="localeStrings.loggedOff | html"></h2>
			</div>
			<div lio-card-body>
				<button (click)="gotoLogin()" mat-flat-button color="primary" type="submit">
					<span [innerHtml]="localeStrings.logBackIn"></span>
				</button>
			</div>
		</lio-card>
	</div>
</section>