import { Component, OnDestroy, Inject, ElementRef } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NEVER, Subscription } from 'rxjs';

import { CatalogSearchComponent } from '../catalog-search/catalog-search.component';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { permissionService } from '../../services/permissions.service';
import { processingService } from '../../services/processing.service';
import { utilService } from '../../services/util.service';
import { fieldService } from '../../services/fields.service';
import { storageService } from '../../services/storage.service';
import { pdfService } from '../../services/pdf.service';
import { catalogService } from '../../services/catalog.service';
import { catalogSettings } from '../../settings/catalog.settings';

@Component({
	selector: 'lio-self-enroll',
	templateUrl: './self-enroll.component.html',
})
export class SelfEnrollComponent extends CatalogSearchComponent implements OnDestroy {
	public override appearance	:MatFormFieldAppearance = 'outline';
	public override view = {
		pageName: 'selfenroll',
		canDownload: false,
		hasFilters: false,
		printable: false,
		canPreview: false,
		selfEnroll: true
	};


	protected override courseTypes				:Array<any> 		= [];
	protected override expandAll				:boolean 			= false;
	protected override expanded					:boolean 			= false;
	protected override activeCollections		:any 				= null;
	protected override fields					:any 				= this.utilService.copy(this.catalogSettings.fields);
	protected override allCourses				:boolean 			= false;
	protected override availableCourseIDs		:Array<any> 		= [];
	protected override lastGrabbedAllCourses	:any 				= null;
	protected override waitingToRender			:boolean 			= false;
	protected override readyToRender			:boolean 			= false;
	protected override renderTarget				:ElementRef 		= null;
	
	public override rendering					:boolean 			= false;
	public override activeCore					:boolean 			= false;
	public override fieldsObject				:any 				= null;
	public override collection					:Array<any> 		= [];
	public override collections					:Array<any> 		= [];
	public override catalogs					:Array<any> 		= [];
	public override showFilter					:boolean 			= false;
	public override loadingCatalogs				:boolean 			= false;
	public override canExpandCatalogs			:boolean 			= false;
	public override pagination					:any 				= this.catalogSettings.pagination;
	public override clearBtnActive				:boolean 			= false;
	public override languageCodes				:Array<any> 		= [];
	public override topics						:Array<any> 		= [];
	public override printable					:boolean 			= false;
	public override printingAll					:boolean 			= false;
	public override printingNone				:boolean 			= true;
	public override printingCount				:number 			= 0;

	public override expandedAll					:boolean 			= false;
	public override collapsedAll				:boolean 			= true;
	public override totalCatalogs				:number 			= 0;
	public override unReleasedStatus			:boolean 			= false;
	public override releasedStatus				:boolean 			= false;

	public override getCatalogsEndPoint:string 			= 'catalog/getAllSelfEnrollmentCatalogs';

	public override filters:any = {
		'*' 			: '',
		langs			: {filter : 'selectedLanguage', param : []},
		topics			: {filter : 'selectedTopic', param : []},
		courseID		: [],
	};

	protected override subscriptions:Subscription = NEVER.subscribe();

	public panelConfig 	= this.utilService.copy(this.catalogSettings.selfEnrollPanelConfig);


	public override searchBarLocale:any 					= {
		title					: 'DEFAULT',
		titleTrans				: 'selfenroll.title',
		subheader 				: 'Enter search criteria below to select the courses you would like to view',
		subheaderTrans 			: 'selfenroll.subheader',
		filters 				: 'FILTERS',
		filtersTrans 			: 'selfenroll.filters',
		clear	 				: 'CLEAR',
		clearTrans	 			: 'selfenroll.clear',
	};

	constructor(
		@Inject(navService)			public override navService			:navService,
		@Inject(debugService)		public override debugService		:debugService,
		@Inject(feedbackService)	public override feedbackService		:feedbackService,
		@Inject(errorsService)		public override errorsService		:errorsService,
		@Inject(catalogService)		public override catalogService		:catalogService,
		@Inject(lmsService)			public override lmsService			:lmsService,
		@Inject(lioLogService)		public override lioLogService		:lioLogService,
		@Inject(workerService)		public override workerService		:workerService,
		@Inject(permissionService)	public override permissionService	:permissionService,
		@Inject(lioModalService)	public override lioModalService		:lioModalService,
		@Inject(processingService) 	public override processingService	:processingService,
		@Inject(utilService) 		public override utilService			:utilService,
		@Inject(fieldService) 		public override fieldService		:fieldService,
		@Inject(storageService)		public override storageService		:storageService,
		@Inject(pdfService)			public override pdfService			:pdfService,
		@Inject(catalogSettings) 	public override catalogSettings		:catalogSettings
	){

		super(
			navService,
			debugService, 
			feedbackService,
			errorsService,
			catalogService,
			lmsService,
			lioLogService,
			workerService,
			permissionService,
			lioModalService,
			processingService,
			utilService,
			fieldService,
			storageService,
			pdfService,
			catalogSettings,
		);

		this.debugService.register('selfenroll', this);
		this.init();
	}

	override init() {
		this.navService.setActivePage('selfenroll');
		this.loadCatalogs();
	}

	/*
	 * Got catalogs
	 * @param {array} catalogs
	 * @override
	*/
	override gotCatalogs(result:any):boolean {
		let catalogs = result.properties.catalogs;
		if (!catalogs.length) {
	 		this.lioModalService.show('selfenroll.noCatalogsFound').then(() => {
	 			this.navService.goback();
	 			return false;
	 		});
	 	}
	 	return super.gotCatalogs(result);
	 }


	/*
	 * On click of the self enroll button
	 * @param {object} catalog
	*/
	selfEnroll(catalog) {
		if (catalog.enrolled) {
			return;
		}

		this.lioModalService.showLoading('processing');
		
		this.lmsService.post('selfregister/selfEnroll', {'courseID': catalog.courseID}).then((result) => {
			let success = result.success,
				macros = [];

			this.lioModalService.hideLoading();

			if (success) {
				macros.push({'key': 'courseName', 'value': catalog.name});
				this.lioModalService.show('selfenroll.success', null, macros);
				catalog.enrolled = true;
			}
		});
	}

	/*
	 * Sets the catalog image
	 * @param {date} catalog
	 * @param {number} key
	 * @override
	*/
	override setCourseTypeToCatalog(catalog) {
		if (!catalog.courseTypeCode) {
			catalog.courseTypeCode = '1';
		}
		super.setCourseTypeToCatalog(catalog);
	}


	/*
	 * Clicked catalog
	 * @param {array} catalog
	*/
	override clickedCatalog(catalog) {
		if (catalog.missingCatalog) {
			return;
		}
		catalog.expanded = !catalog.expanded;
		this.expandedAll = false;
		this.collapsedAll = false;
	}


	
}