import { Component, Inject, ViewChild } from '@angular/core';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { loaderService } from '../../services/loader.service';
import { utilService } from '../../services/util.service';
import { catalogService } from '../../services/catalog.service';
import { storageService } from '../../services/storage.service';
import { catalogAdminSettings } from './catalog-admin.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
  selector: 'lio-catalog-admin',
  templateUrl: './catalog-admin.component.html'
})
export class CatalogAdminComponent {
	@ViewChild('tabCache', {read: PanelTabCache})	
	public panelTabCache	:PanelTabCache 	= null;

	public	searchPanelConfig:any				= {};
	public	addPanelConfig:any					= {};
	public	editPanelConfig:any					= {};
	public	topicPanelConfig:any				= {};
	public	bulkPanelConfig:any					= {};
	public	collectionPanelConfig:any		= {};

	constructor(
		@Inject(navService)					public 	navService			:navService,
		@Inject(debugService)				public 	debugService		:debugService,
		@Inject(loaderService)			public 	loaderService		:loaderService,
		@Inject(storageService)			public 	storageService	:storageService,
		@Inject(catalogService)			public 	catalogService	:catalogService,
		@Inject(utilService)				public 	utilService			:utilService,
		@Inject(catalogAdminSettings) 		public 	catalogAdminSettings		:catalogAdminSettings
	){
		this.debugService.register('catalogadmin', this);
		this.navService.setActivePage('catalogadmin');
		this.searchPanelConfig 			= this.utilService.copy(this.catalogAdminSettings.panelConfig.search);
		this.addPanelConfig 				= this.utilService.copy(this.catalogAdminSettings.panelConfig.add);
		this.editPanelConfig 				= this.utilService.copy(this.catalogAdminSettings.panelConfig.edit);
		this.topicPanelConfig 			= this.utilService.copy(this.catalogAdminSettings.panelConfig.topic);
		this.bulkPanelConfig 				= this.utilService.copy(this.catalogAdminSettings.panelConfig.bulk);
		this.collectionPanelConfig 	= this.utilService.copy(this.catalogAdminSettings.panelConfig.collection);
	}

	loaded() {
		let courseID = this.storageService.getAndClear('catalogID');
		if (courseID) {
			if (this.panelTabCache) {
				this.catalogService.setCatalogByCourseID(courseID);
				this.panelTabCache.selectTab(this.editPanelConfig);
			}
		}
	}


	resetCatalogs() {
		this.catalogService.init();
	}


	onTabChange() {
		this.catalogService.resetCatalog();
	}

	handleCallback(event) {
		if (event.switchTab === 'edit') {
			this.panelTabCache.selectTab(this.editPanelConfig);
		}
		if (event.switchTab === 'copy') {
			this.panelTabCache.selectTab(this.addPanelConfig);
		}
	}
}