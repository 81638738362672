import { Inject, Injectable } from '@angular/core';
import { dashboardSettings } from '../dashboard/dashboard.settings';
import { permissionService } from '../../services/permissions.service';
import { reportsService } from '../../services/reports.service';
import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';


@Injectable({
	providedIn	: 'root', 
	useClass	: reportEditorSettings
})
export class reportEditorSettings {
	constructor(
		@Inject(permissionService) private permissionService:permissionService,
		@Inject(reportsService) private reportsService:reportsService,
		@Inject(dashboardSettings) private dashboardSettings:dashboardSettings
	){}
	
	panelConfig:any = {
		'select' : {
			id 					: '',
			tab					: 'Select Report',
			tabTrans 			: 'report.select',
			link 				: 'reports',
			suppressAutoSelect	: true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('reports.read');
			}
		},
		'edit' : {
			'id'				: 'panelTab_report_edit_form',
			'reloadOnChange'	: false,
			'hasQueryTool'		: true,
			'title'				: 'Create / Edit Report',
			'titleTrans'		: 'editreport.title',
			'tab'				: 'Edit a report',
			'tabTrans'			: 'editreport.title',
			'visible' 			: () => { return this.permissionService.hasPermission('reports.editCustom'); },
			'fields': new LioFieldArray(
				new LioSearchableSelectField({
					name		: 'Report Type',
					model		: 'type',
					type		: 'select',
					required	: true,
					locked		: false,
					visible 	: true,
					options		: this.reportsService.reportsFormatted,
				}),
			),
			'fieldConfig': {
				'fieldName'			: 'reportedit',
			},
		},
		'create' : {
			id 			: '',
			tab			: 'Create Custom Report',
			tabTrans 	: 'customreports.title',
			link 		: 'reportcreator',
			visible 	: () =>  { 
				return this.permissionService.hasPermission('reports.writeCustom');
			}
		},
	};

	reportType:any = {
		'showFilters'				: true,
		'filters'					: [],
		'fields'					: [],
		'fieldConfg'				: [],
		'queryToolSettings'			: {
			'endpoints'	: {
				'get'		: 'query/getAllByType',
				'save'		: 'query/save',
				'delete'	: 'query/deleteByID',
				'report'	: 'customreports/runReport',
			},
			'addBaseFilters'		: true,
			'addEnrollmentFilters'	: true,
			'addCCFtoFilters'		: true,
			'addRoleFilter'			: true,
			'addActiveField'		: true,
			'showSaver'				: true,
			'usesQueryTool'			: true,
			'suppressCachedRules'	: true,
		},
	}

	filters:Array<any> = this.dashboardSettings.filters;

}