/*
 * Service for Controlling a user's own state info
*/
import { Observable, Subject, ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { feedbackService } from './feedback.service';
import { lioLogService } from './lio-log.service';
import { lmsService } from './lms.service';
import { navService } from './nav.service';
import { permissionService } from './permissions.service';
import { storageService } from './storage.service';
import { debugService } from './debug.service';

@Injectable({
	providedIn: 'root',
})
export class stateService {
	homeCompany			: any					= null;
	defaultRole 		: any					= null;
	activeCompany		: any					= {};
	activeRole 			: any					= null;
	allowedRoles  		: any					= null;
	impersonationTarget : any					= null;
	actualSession 		: any					= null;
	log 				: boolean				= false;
	impersonateOptions 	: Array<any>			= [];
	userName 			: string				= '';
	session 			: any					= {
		firstName 	: null,
		lastName 	: null,
		employeeID 	: null
	};
	loggedIn 			: boolean				= false;
	allRoles 			: Array<any>			= [];
	loggedOff 			: boolean				= false;
	loaded 				: boolean = false;

	private _allowedCompanies	:Array<any> 				= [];
	allowedCompanies			:ReplaySubject<Array<any>>	= new ReplaySubject(1);
	sessionUpdated				:Subject<any> 				= new Subject();
	waitForLoaded				:ReplaySubject<void>		= new ReplaySubject(1);
	waitForLoggedIn				:ReplaySubject<void>		= new ReplaySubject(1);

	constructor(
		@Inject(feedbackService)		private feedbackService		: feedbackService,
		@Inject(lioLogService)			private lioLogService		: lioLogService,
		@Inject(lmsService)				private lmsService			: lmsService,
		@Inject(navService)				private navService			: navService,
		@Inject(permissionService)		private permissionService	: permissionService,
		@Inject(storageService)			private storageService		: storageService,
		@Inject(debugService)			private debugService		: debugService,
	) {
		this.debugService.register('state', this);
		this.setHomePage();
	}

	getAllRoles() {
		return this.lmsService.post('permissions/getAllRoles').then((result:any) => {
			this.allRoles = result.properties.allRoles;
			return this.permissionService.setAllRoles(this.allRoles);
		});
	}

	getAllowedRoles() {
		return this.lmsService.post('permissions/getAllowedRoles').then((result:any) => {
			this.allRoles = result.properties.allowedRoles;
			this.permissionService.setAllowedRoles(this.allRoles);
			return this.allRoles;
		});
	}

	getAllowedCompanies() {
		return this._allowedCompanies;
	}

	/**
	 * Gets and sets the current state info
	 */
	getState() {
		return this.lmsService.post('permissions/getState').then((result:any) => {
			if (result.success) {
				this.loggedOff = false;
				this.storageService.set('state', result.properties);
				this.set(result.properties);
				return result.properties;
			} else {
				return false;
			}
		});
	}

	/**
	 * Change either the active role or active company
	 */
	changeState(type, targetID, noReload = false) {
		let command 	= 'changeActiveCompany',
			param:any 	= {targetCompanyID : targetID};

		if (type == 'role') {
			command = 'changeActiveRole';
			param 	= {roleID : targetID};
		}
		
		let deffered = new Observable((subscriber) => {
			this.lmsService.post('permissions/' + command, param).then((result:any) => {
				if (result.success) {
					this.loggedOff = false;
					if (!noReload) {
						this.storageService.reset();
						this.navService.reload();
					}
					subscriber.next(true);
					subscriber.complete();
				} else {
					subscriber.next(false);
					subscriber.complete();
				}
			});
		});

		return deffered.toPromise();
	}

	/**
	 * Sets initial state information
	 */
	set(stateInfo) {
		if (stateInfo) {
			if (stateInfo.allowedRoles) {
				this.allowedRoles = stateInfo.allowedRoles;
				this.permissionService.setAllowedRoles(this.allRoles);
			}

			this._allowedCompanies = stateInfo.allowedCompanies;
			this.allowedCompanies.next(this._allowedCompanies);

			this.activeRole = stateInfo.activeRole;

			this.actualSession = stateInfo.actualSession;

			if (stateInfo.activeCompany) {
				this.activeCompany = stateInfo.activeCompany;
			}

			if (this.actualSession && this.actualSession.companyID) {
				this.homeCompany = stateInfo.actualSession.companyID;

				if (this.activeCompany.companyID != this.actualSession.companyID) {
					this.permissionService.switchedCompany = true;
				}
			}

			if (this.actualSession) {
				this.defaultRole = stateInfo.actualSession.permissionID;
			}

			if(stateInfo.impersonationTarget) {
				this.impersonationTarget = stateInfo.impersonationTarget;
				this.permissionService.isImpersonating = this.impersonationTarget;
			}else{
				this.impersonationTarget 	= null;
				this.permissionService.isImpersonating = false;
			}

			if (stateInfo.allRoles) {
				this.allRoles = stateInfo.allRoles;
				this.permissionService.setAllRoles(this.allRoles);
			}

			this.permissionService.setPermissions(stateInfo.permissions, this.allowedRoles, stateInfo.authorities);
			this.setHomePage();

			if (this.activeCompany && this.activeRole) {
				this.storageService.set('frontEndCompanyID', this.activeCompany.companyID);
				this.storageService.set('frontEndPermissionID', this.activeRole.roleID);
			}
			
			this.loaded = true;
			this.waitForLoaded.next();

			if (this.isReadyToImpersonate()) {
				this.getImpersonationOptions();
			}
			if (!this.activeRole) {
				this.lioLogService.log('No Active Role');
				return;
			}

			if (!this.activeCompany) {
				this.lioLogService.log('No Active Company');
				return;
			}

			let msg = "Active Role: " + this.activeRole.name + " (" + this.activeRole.roleID + ")",
				msg2 = "Active Company: " + this.activeCompany.coName + " (" + this.activeCompany.companyID + ")";

			this.feedbackService.addToHistory(msg);
			this.feedbackService.addToHistory(msg2);

			if (this.log) {
				this.lioLogService.log(msg);
				this.lioLogService.log(msg2);
			}
		}
	}

	/**
	 * Log off
	 */
	logOff() {
		this.loggedOff = true;
		this.activeRole = null;
		this.setHomePage();
		this.setLoggedIn(false);
	}

	/**
	 * Sets the homepage for the current role
	 */
	setHomePage() {
		let homePage = 'login';

		if (this.loggedOff) {
			homePage = 'logout';
		}
		if (this.activeRole) {
			homePage = this.activeRole.homePage;
		}
		this.navService.setHomePage(homePage);
		return homePage;
	}

	/**
	 * Gets the companyID of the active company
	 */
	getActiveCompanyID() {
		if (this.activeCompany) {
			return this.activeCompany.companyID;
		}
	}

	/**
	 * Sets the companyID of the active company
	 */
	setActiveCompanyID(companyID) {
		this.activeCompany.companyID = companyID;
	}

	/**
	* Gets the roleID of the active role
	*/
	getActiveRoleID() {
		if (this.activeRole) {
			return this.activeRole.roleID;
		}
	}

	/**
	 * Gets whether the active company is the home company
	 */
	isAtHomeCompany() {
		if(this.activeCompany) {
			return this.activeCompany.companyID == this.homeCompany;
		}
		return false;
	}

	/**
	 * Gets whether the active role is the default role
	 */
	isUsingDefaultRole() {
		if(this.activeRole) {
			return this.activeRole.roleID == this.defaultRole;
		}
		return false;
	}

	/**
	 * Gets whether the user is impersonating
	 */
	isImpersonating() {
		return this.impersonationTarget != null;
	}

	/**
	 * Gets whether the user is impersonating or using a company or role that is not the default
	 */
	isSessionModified() {
		return this.isImpersonating() || !this.isUsingDefaultRole() || !this.isAtHomeCompany();
	}

	getImpersonationOptions() {
		return this.lmsService.post('impersonation/getImpersonationOptions').then((result:any) => {
			if (result.success) {
				this.loggedOff = false;
				this.impersonateOptions = result.properties.impersonationOptions;
				return this.impersonateOptions;
			}
		});
	}

	isReadyToImpersonate() {
		if(this.permissionService.hasPermission('permissions.impersonateAllowed') 
		&& this.impersonationTarget == null 
		&& !this.isSessionModified()) {
			return true;
		}
		return false;
	}

	canChangeRole() {
		if (this.permissionService.hasPermission('self.changeState') 
		&& this.allowedRoles.length > 1) {
			return true;
		}
		return false;
	}

	canChangeCompany() {
		if (this.permissionService.hasPermission('self.changeState') 
		&& this._allowedCompanies.length > 1) {
			return true;
		}
		return false;
	}

	/**
	 * End ongoing impersonation
	 */
	impersonateEnd() {
		return this.lmsService.post('impersonation/impersonateEnd').then((result:any) => {
			if (result.success) {
				this.navService.reload();
				return true;
			} else {
				return false;
			}
		});
	}

	/**
	 * End ongoing impersonation
	 */
	endProxy() {
		let SID = this.session.proxyingSID,
			companyID = this.session.proxyingCompanyID;

		return this.lmsService.get('useraccess/logOut').then(() => {
			this.lmsService.post('useraccess/submitLogin', {'SID': SID, 'companyID': companyID}).then((result:any) => {
				if (result.success) {
					if (companyID) {
						setTimeout(() => {
							this.lmsService.post('permissions/changeActiveCompany', {'targetCompanyID': companyID}).then(() => {
								this.navService.reload();
							})
						}, 1000);
					} else {
						this.navService.reload();
					}
					return true;
				} else {
					return false;
				}
			});
		});
	}

	isProxying() {
		if (this.session && this.session.proxyingSID) {
			return true;
		}
		return false;
	}

	/*
	* Sets the session
	*/
	setSession(session) {
		if (session) {
			this.session = session;

			if(session.activeCompany) {
				this.activeCompany = session.activeCompany;
			}else{
				this.activeCompany = {companyID : session.companyID};
			}

			this.storageService.set('companyID', session.companyID);			

			this.userName = session.firstName + ' ' + session.lastName;
		} else {
			this.session = {
				firstName 	: '',
				lastName 	: '',
				employeeID 	: ''
			};
			this.userName = '';
		}

		this.storageService.set('frontEndEmployeeID', this.session.employeeID);
		this.sessionUpdated.next(session);
	}

	/*
	* Gets the employeeID
	*/
	getEmployeeID() {
		return this.session.employeeID || '';
	}


	/*
	* Sets the employeeID
	*/
	setEmployeeID(employeeID) {
		return this.session.employeeID = employeeID;
	}

	/*
	* Clears the session
	* @return {boolean}
	*/
	clearSession() {
		this.session = {};
	}

	/*
	* Gets the session
	*/
	getSession() {
		return this.session;
	}

	getSID() {
		return this.session.SID;
	}

	isLoggedIn() {
		return this.loggedIn;
	}

	setLoggedIn(loggedIn) {
		this.loggedIn = loggedIn;
		this.storageService.set('loggedIn', loggedIn);
		if (loggedIn) {
			this.waitForLoggedIn.next();
		}
	}
}