
<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'description', 'close']">
	
	<!-- Close Button -->
	<button (click)="close()" [hidden]="!settings.canClose" class="button-hide modal-closer float-right">
		<i class="modal-close fa fa-times text-secondary"></i>
        <span class="sr-only">Close Modal</span>
	</button>

	<!-- Header -->
	<header class="modal-header">
		<h5 id="am_modal_title" class="modal-title mx-auto">{{settings.title}}</h5>
	</header>


	<!-- Form -->
	<section class="modal-content">
		<form name="ang_sendEmailModalForm" role="form" autocomplete="off">
			<!-- Attachments -->
			<div class="container-fluid attachments">
				<div class="h6">Attach report as:</div>
				<div class="input-group input-group-sm mb-1">
					<div class="input-group-prepend">
						<div *ngIf="settings.sendSpreadsheet" class="btn btn-primary" (click)="toggleSendSpreadsheet()"><i class="fa fa-check"></i></div>
						<div *ngIf="!settings.sendSpreadsheet" class="btn btn-outline-light-gray" (click)="toggleSendSpreadsheet()"><i class="fa fa-check text-white"></i></div>
					</div>
					<div class="input-group-append">
						<span class="input-group-text">spreadsheet</span>
					</div>
				</div>
				<div class="input-group input-group-sm">
					<div class="input-group-prepend">
						<div *ngIf="settings.sendPDF" class="btn btn-primary" (click)="toggleSendPDF()"><i class="fa fa-check"></i></div>
						<div *ngIf="!settings.sendPDF" class="btn btn-outline-light-gray" (click)="toggleSendPDF()"><i class="fa fa-check text-white"></i></div>
					</div>
					<div class="input-group-append">
						<span class="input-group-text">pdf</span>
					</div>
				</div>
			</div>
			<hr/>

			<!-- Fields -->
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">From Address*</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" name="fromAddress" id="am_send_email_modal_fromaddres" [(ngModel)]="settings.fromAddress">
				</div>
			</div>
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">To Addresses*</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" name="toAddresses" id="am_send_email_modal_toaddress" [(ngModel)]="settings.toAddresses">
				</div>
			</div>
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">Subject*</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" name="subject" id="am_send_email_modal_subject" [(ngModel)]="settings.subject">
				</div>
			</div>
			<div class="form-group row">
				<label for="from" class="col-sm-2 col-form-label">Message*</label>
				<div class="col-sm-10">
				    <textarea class="form-control" id="am_send_email_modal_message" name="message" rows="5" [(ngModel)]="settings.message"></textarea>
				</div>
			</div>
		</form>
	</section>

	<!-- Feedback -->
	<section class="feedback">
		<div *ngIf="error" class="error mx-auto">
			<span class="input-group-text">{{error}}</span>
		</div>
	</section>

		<!-- Footer -->
	<footer class="modal-footer">
		<button class="btn btn-sm btn-primary" mat-raised-button id="modal_action_button" (click)="prepareAndSubmit()">{{settings.submit}}</button>
		<button [hidden]="!settings.canClose" class="btn btn-sm btn-primary" mat-raised-button id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>
