/*
 * Simple method for determining browser and browser settings
*/
import { Inject, Injectable } from '@angular/core';
import { lioLogService } from './lio-log.service';
import { lioModalService } from './lio-modal.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class browserService {
	public navigators : {
		isAndroid		:boolean,
		isCordova		:boolean,
		isEdge			:boolean,
		isFirefox		:boolean,
		isChrome		:boolean,
		isChromeIOS		:boolean,
		isChromiumBased	:boolean,
		isIE			:boolean,
		ieVersion		:number,
		isIOS			:boolean,
		isOpera			:boolean,
		isSafari		:boolean,
		isTouchScreen	:boolean,
	};

	constructor(
		@Inject(lioLogService)	private lioLogService	: lioLogService,
		@Inject(lioModalService)	private lioModalService	: lioModalService,
		@Inject(DOCUMENT) 		private document 		: Document
	) {
		let windowRef = this.document.defaultView;

		this.navigators = {
			isAndroid: /Android/.test(navigator.userAgent),
			isCordova: !! Object.prototype.hasOwnProperty.call(windowRef, 'cordova'),
			isEdge: /Edge/.test(navigator.userAgent),
			isFirefox: /Firefox/.test(navigator.userAgent),
			isChrome: /Google Inc/.test(navigator.vendor),
			isChromeIOS: /CriOS/.test(navigator.userAgent),
			isChromiumBased: !! Object.prototype.hasOwnProperty.call(windowRef, 'chrome') && !/Edge/.test(navigator.userAgent),
			isIE: /Trident/.test(navigator.userAgent),
			ieVersion: this.detectIE(),
			isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
			isOpera: /OPR/.test(navigator.userAgent),
			isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
			isTouchScreen: ('ontouchstart' in windowRef) || Object.prototype.hasOwnProperty.call(this.document, 'createTouch')
		};
	}

	detectIE() {
		var ua = this.document.defaultView.navigator.userAgent,
			msie = ua.indexOf('MSIE '),
			edge = ua.indexOf('Edge/'),
			trident = ua.indexOf('Trident/'),
			rv = ua.indexOf('rv:'),
			version = 0;

		if (msie > 0) {
			// IE 10 or older => return version number
			version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		} else if (trident > 0) {
			// IE 11 => return version number
			version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		} else if (edge > 0) {
			// Edge (IE 12+) => return version number
			version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return version;
	}

	/*
	 * Gets the determined browser from a user agent
	 * @param {string} agent
	 * @return {string}
	*/
	determineBrowserFromAgent(agent) {
		let windowRef 			= this.document.defaultView,
			determinedBrowser 	= null,
			browsers 			= [
				{'name': 'Android', is: /Android/.test(agent)},
				{'name': 'Android', is: !! Object.prototype.hasOwnProperty.call(windowRef, 'cordova')},
				{'name': 'Edge', is: /Edge/.test(agent)},
				{'name': 'Chrome', is: /Google Inc/.test(navigator.vendor)},
				{'name': 'Firefox', is: /Firefox/.test(agent)},
				{'name': 'Safari', is: /Safari/.test(agent) && !/Chrome/.test(agent)},
			];

		browsers.forEach((browser) => {
			if (browser.is) {
				determinedBrowser = browser.name;
			}
		});
		return determinedBrowser;
	}

	getUserAgent() {
		return window.navigator.userAgent;
	}

	isIE() {
		return this.navigators.isIE;
	}

	getBrowserInfo() {
		let i:any = 0,
			info = '';

		for (i in this.navigators) {
			info += i + ' = ' + this.navigators[i] + "\n";
		}

		return info;
	}


	copyToClipboard(text) {
		if (!navigator['clipboard']) {
			alert('The clipboard is not available on this browser');
			return false;
		}
		let clip = JSON.stringify(text);
		navigator.clipboard.writeText(clip).then(() => {
			this.lioLogService.log(['Copied successfully']);
		}, (error) => {
			this.lioLogService.error(['Could not copy text', error]);
		});
		return true;
    }


    pasteFromClipboard() {
		if (!navigator['clipboard']) {
			alert('The clipboard is not available on this browser');
			return new Promise((resolve) => {
				resolve(false);
			});
		}
		return navigator.clipboard.readText().then((clip) => { 
			if (!clip) {
				this.lioLogService.log(['Nothing was copied in the clipboard']);
				return '';
			}
			let text = '';
			try {
				text = JSON.parse(clip);
				this.lioLogService.log(['Pasted successfully']);
			} catch (e) {
				this.lioLogService.error(['This clipboard doesn\'t appear to be copied from LIO']);
				text = clip;
			}
			return text; 
		});
    }

	canSendPushNotifications() {
		return 'serviceWorker' in navigator && 'PushManager' in this.document.defaultView;
	}

	forceUseEdge(url){
		this.lioModalService.showLoading('reloadingSupport');
		this.lioModalService.suppressHide();
		setTimeout(() => {
			this.document.defaultView.location.replace('microsoft-edge:' + url);
			setTimeout(() => { 
				this.document.defaultView.location.assign("unsupportedBrowser.html");
			}, 2000);
		}, 1000);
		
	}
}