<form role="form" autocomplete="off" novalidate>
	<section>
		<table class="table">
			<thead class="thead-light">
				<tr>
					<th>Column #</th>
					<th *ngFor="let field of fields">{{field.name}}</th>
					<th>Mapping</th>
					<th>Remove</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of model.fields; let r = index" [ngClass]="{'field-error-wide' : row.error == true}">
					<td>
						<span>{{r + 1}}</span>
					</td>
					<td *ngFor="let field of fields; let i = index">
						<lio-searchable-select
							[model]="row"
							[name]="field.name"
							[field]="field"
							(onUpdate)="update()"
							[appearance]="appearance">
						</lio-searchable-select>
					</td>
					<td *ngIf="row.mapFrom">
						<button class="btn btn-primary" (click)="addMap(r)">Edit map</button>
					</td>
					<td *ngIf="!row.mapFrom">
						<button class="btn btn-primary" (click)="addMap(r)">Add map</button>
					</td>

					<td>
						<button mat-button color="warn" (click)="removeRow(r)" class="fa fa-minus-square fa-2x"></button>
					</td>
				</tr>
			</tbody>
		</table>
		<button mat-button color="primary" (click)="addRow()">
			<span class="fa fa-plus fa-lg mr-1"></span>
			<span>Add Row</span>
		</button>
	</section>
</form>
