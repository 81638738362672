import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { storageService } from '../../services/storage.service';
import { utilService } from '../../services/util.service';
import { loginService } from '../../services/login.service';
import { feedbackService } from '../../services/feedback.service';
import { debugService } from '../../services/debug.service';

@Component({
	selector: 'lio-logged-off',
	templateUrl: './logged-off.component.html'
})
export class LoggedOffComponent implements OnDestroy {
	public localeStrings:any = {
		loggedOff		:`<p>Your current session has timed out or has been terminated.</p>
		<p>Some possible causes of this can be having multiple logins or clearing your browser cache after logging in.</p>
		<p>If you did not create a new session please logout, log back in and try again.</p>`,
		loggedOffTrans	:'error.loggedOff',
		logBackIn		:'Log Back In',
		logBackInTrans	:'error.logBackIn'
	};
	public localeStringKeys:Array<any> = [
		'loggedOff',
		'logBackIn'
	];

	private permissions		:any 			= this.storageService.get('permissions');
	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(navService)			public	navService		:navService,
		@Inject(storageService)		public	storageService	:storageService,
		@Inject(utilService)		public	utilService		:utilService,
		@Inject(loginService)		public	loginService	:loginService,
		@Inject(feedbackService)	public	feedbackService	:feedbackService,
		@Inject(debugService)		public	debugService	:debugService
	){
		this.debugService.register('loggedoff', this);
		this.navService.setActivePage('loggedoff');

		if (!this.loginService.hasSession()) {
			this.navService.disableGoto = false;
			this.navService.goto('login');
		} else {
			this.subscriptions.add(
				this.loginService.loggedIn.subscribe(() => {
					this.logout();
				})
			);
		}
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

    /*
	 * Goes to the login screen / SSO loging portal
	*/
    gotoLogin() {
		let permissions 	= this.permissions;
		let logoutLocation 	= permissions ? permissions.logoutLocation : null;
		let allowSpiderSSO 	= permissions ? permissions.allowSpiderSSO : null;

		if (allowSpiderSSO == 1) {
			if (logoutLocation) {
				this.gotoExternal(logoutLocation);
				return;
			}
		}

		this.navService.reload();
    }

    /*
	 * Takes the user outside of the LMS
	 * @param {string} location 
	*/
    gotoExternal(location) {
		if (!this.utilService.inString('https://', location) 
		&& !this.utilService.inString('http://', location) 
		&& this.utilService.inString('www', location)) {
			location = 'https://' + location;
		}
		document.location.href = location;
    }

    /*
	 * Log out function
	*/
	logout() {
		this.feedbackService.clearAll();
		this.loginService.logout().then(() => {
			this.navService.disableGoto = false;
			this.feedbackService.clearAll();
			this.navService.displayPage();
		});
	}
}