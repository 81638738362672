import { Component, Input, Inject, DoCheck, HostBinding, ChangeDetectorRef } from '@angular/core';

import { localizationService } from '../../../../services/localization.service';
import { permissionService } from '../../../../services/permissions.service';

import { CardButtonModel } from './card-button.model';

@Component({
	selector: 'lio-card-button',
	templateUrl: './card-button.component.html',
})
export class LIOCardButton implements DoCheck {
	private _model:CardButtonModel = null;
	get model(){
		return this._model;
	}
	@Input() set model(value: CardButtonModel) {    
		this._model = value;
	}

	@HostBinding('class') 	_class	:string 	= '';
	@HostBinding('hidden') 	_hidden	:boolean 	= false;

	constructor(
										private changeDetectorRef	:ChangeDetectorRef,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(permissionService) 		public permissionService	:permissionService
	){}

	private _oldClass:string = '';
	ngDoCheck(){
		//check whether card should be visible
		let visible = !this.model.permission || this.permissionService.hasPermission(this.model.permission);
		if(this.model.visible != visible || this._hidden == visible){
			setTimeout(() => {
				this.model.visible 	= visible;
			});
			this._hidden 		= !visible;
			this.changeDetectorRef.detectChanges();
		}

		if(this.model.class != this._oldClass){
			this._class = this.model.class;
		}
	}

	getLocaleKeys(){
		return ['title', 'subtitle'];
	}

	isRTL():boolean{
		return this.localizationService.isRTL();
	}
}