import { Injectable } from '@angular/core';

import { LioSearchableSelectField, LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: addUsersSettings
})
export class addUsersSettings {
	panelConfigs:any = {
		formAdd : {
			id				: 'panelTab_add_user_form',
			title			: 'Add User',
			titleTrans		: 'addusers.header',
			tab				: 'Add User',
			tabTrans		: 'addusers.header',
			reloadOnTabChange : true
		},
		bulkAdd : {
			id				: 'panelTab_add_user_bulk',
			title			: 'Bulk Add/Update Users',
			titleTrans		: 'addusers.bulkUploadHeader',
			tab				: 'Bulk Upload',
			tabTrans		: 'addusers.bulkUpload',
			reloadOnTabChange : true
		}
	};

	localizations: any = {
		add						: 'addusers.add',
		dragExcel				: 'addusers.dragExcel',
		getTemplate				: 'addusers.getTemplate',
		getSample				: 'addusers.getSample',
		choose					: 'addusers.choose',
		makeSelection			: 'addusers.makeSelection',
		full					: 'addusers.full',
		delta					: 'addusers.delta',
		partial					: 'addusers.partial',
		process					: 'addusers.process',
		assignCourses			: 'global.assigncourses',
		emailUsers				: 'global.emailusers',
		exportRecords			: 'global.exportrecords',
		emailAddedUsers			: 'global.emailaddedusers',
		exportProcessReport		: 'addusers.exportProcessReport',
	};

	pagination:any = {
		pageLimit	: '5',
		pageLimits	: ['5', '10', '50', '100'],
		sortMode	: {field : 'lastName', direction : 'asc'}
	};

	uploadSettings:any = {
		name			: 'Upload File',
		trans			: 'this.addUsersSettings.upload',
		fileTask		: 'importemployee/upload',
		allowedFileTypes: ['xlsx'],
		hideLoading		: false,
	};

	model:any = {
		processType		: 'DELTA'
	}

	prefixButtons:Array<any> = [
		{
			header		: 'Search',
			id 			: 'am_this.addUsersSettings_profile_prefix',
			color 		: 'primary',
			icon 		: 'search',
			callback 	: null,
		}
	];

	processType:string = 'DELTA';

	processTypeField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'am_delta',
		name			: 'Choose how you would like us to process an uploaded file',
		nameTrans		: 'addusers.choose',
		model			: 'processType',
		addEmptyOption	: true,
		backgroundColor : 'white'
	});

	processTypes:Array<any> = [
		{
			value	: 'DELTA',
			trans	: 'addusers.deltaOption',
			name	: 'Delta (Changes Only)',
			selected: true,
			visible	: 'import.employeeDelta'
		},
		{
			value	: 'FULL_FILE',
			trans	: 'addusers.fullOption',
			name	: 'Full File',
			selected: false,
			visible	: 'import.employeeFull'
		},
		{
			value	: 'PARTIAL_FILE',
			trans	: 'addusers.partialOption',
			name	: 'Full File',
			selected: false,
			visible	: 'import.employeePartial'
		}
	];

	fieldConfig:any = {
		fieldName			: 'addusers',
		addCCFtoFields	: true,
		localizeFields	: true,
		permissionFields: true,
		addRoleToFields	: true,
		overrideFields	: true,
		addLangField	: true,
		addActiveField	: true,
		ccfFields: {
		},
		roleField: {
			viewAuthority	: 'CREATE',
			lockedAuthority	: 'VIEW'
		}
	};

	exportReportFields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Added Users',
			nameTrans	: 'addusers.addedUsers',
			model		: 'added',
			visible		: true
		},
		{
			name		: 'Edited Users',
			nameTrans	: 'addusers.editedUsers',
			model		: 'edited',
			visible		: true
		},
		{
			name		: 'Activated Users',
			nameTrans	: 'addusers.activatedUsers',
			model		: 'activated',
			visible		: true
		},
		{
			name		: 'Dectivated Users',
			nameTrans	: 'addusers.deactivatedUsers',
			model		: 'deactivated',
			visible		: true
		}
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			name				: 'Employee ID',
			model				: 'employeeID',
			type				: 'text',
			required			: true,
			min					: 2,
			max					: 200,
			checkForSpecialChars: true,
			regex				: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl		: true,
			disableOverride		: true,
			sortable			: true
		},
		{
			name				: 'First Name',
			model				: 'firstName',
			type				: 'text',
			required			: true,
			varchar				: false,
			min					: 2,
			max					: 70,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'Last Name',
			model				: 'lastName',
			type				: 'text',
			required			: true,
			varchar				: false,
			min					: 2,
			max					: 70,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'e-mail',
			model				: 'email',
			type				: 'email',
			required			: true,
			checkForHTMlInEmail	: true,
			checkForSpecialChars: true,
			regex				: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			sortable			: true
		},
		{
			name				: 'Address',
			model				: 'address1',
			type				: 'text',
			required			: false,
			max					: 70,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true,
			regex				: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g,
		},
		{
			name				: 'Address 2',
			model				: 'address2',
			type				: 'text',
			required			: false,
			max					: 60,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true,
			regex				: /\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\"|\;|\:/g,
		},
		{
			name				: 'City',
			model				: 'city',
			type				: 'text',
			varchar				: true,
			required			: false,
			max					: 60,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'State',
			model				: 'state',
			type				: 'text',
			varchar				: true,
			required			: false,
			max					: 60,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'Country',
			model				: 'country',
			type				: 'text',
			min					: 2,
			max					: 60,
			varchar				: false,
			required			: false,
			checkForSpecialChars: false,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'Zip Code',
			model				: 'zipCode',
			type				: 'text',
			required			: false,
			checkForSpecialChars: true,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'Phone #',
			model				: 'phone',
			type				: 'text',
			max 				: 50,
			required			: false,
			checkForSpecialChars: true,
			regex				: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl		: true,
			sortable			: true
		},
		{
			name				: 'Date Added',
			model				: 'addDate',
			type				: 'date',
			required			: false,
			visible				: 'employee.viewAddDate',
			locked				: true,
			sortable			: true
		},
	);
}