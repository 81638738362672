import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import { 
	localizationService
} from '../../../../services/localization.service';

@Component({
  selector: 'app-modal-choose-language',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', '../input/input.component.css', './choose-language.component.css']
})
export class ChooseLanguageComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public override settings : any = {
		'id' : 'am_modal_choose_language',
		'title': 'Please select a language',
		'titleTrans': 'message.selectLang',
		'canClose': true,
		'submit': 'Set Language',
		'submitTrans': 'message.setLang',
		'optionName': 'Choose a Language',
		'optionNameTrans': 'user.chooseLang',
		'options': [],
		'searchFail': 'No matching options found',
		'searchFailTrans': 'user.searchFail',
		'close': 'Close',
		'closeTrans': 'modal.close',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 		public override parentSettings: any,
		@Inject(localizationService) 	private localizationService 	:localizationService) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		this.settings.options = this.localizationService.availableLanguages;
		this.formatOptions();
		this.selection = this.localizationService.selectedLang;
		this.setSelectedLanguage();
	}

	/*
	 * Format the options 
	*/
	formatOptions() : void {
		if (this.settings.options) {
			this.settings.options.forEach((option) => {
				if (option['lang'] !== option['name']) {
					option.name = option['lang'] + ': ' + option['name'];
				} else {
					option.name = option['lang'];
				}
			});
			this.filterSelection();
		}
	}

	/*
	 * Sets the selected language 
	*/
	setSelectedLanguage(): void {
		if (this.selection && this.settings.options) {
			this.settings.options.forEach((option) => {
				if (option['code'] == this.selection['code']) {
					this.selection = option;
				}
			});
		}
	}
}