<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys" *ngIf="!navService.loading">
	<div class="col-md-12">
		<lio-searchable-select
			[hidden]="catalog.courseID"
			[model]="catalog"
			[options]="catalogs"
			[field]="courseIDField"
			(onUpdate)="getCatalog()"
			appearance="outline">
		</lio-searchable-select>

		<!-- If course ID exists -->
		<form name="form" role="form" autocomplete="off" novalidate [hidden]="!catalog.courseID" (ngSubmit)="submit()">
			<lio-form-inputs
				[fields]="fields"
				[model]="catalog"
				(onChanged)="onUpdate()">
			</lio-form-inputs>
			<div>
				<button id="am_catalogadmin_submit" [disabled]="!allowSubmit" (click)="submit()" class="btn btn-sm btn-primary" type="submit"><strong>{{localeStrings.save}}</strong></button>
			</div>
		</form>
	</div>
</div>