<div class="container-fluid">
	<div *ngIf="numVisibleTabs() > 1" class="row">
		<div class="col-12">
			<ul class="nav nav-tabs row float-rtl-right">
				<li *ngFor="let tab of tabCache.getTabs() | reverse" 
					id="{{'am_' + tab.id + '_tabSelect'}}" 
					class="nav-item col-6 col-sm-auto px-0 pl-sm-1 small" 
					[ngClass]="{active:tabCache.getSelectedTab() == tab}" 
					(click)="changeTab(tab)" 
					[hidden]="!tab._visible"
					localize
					[model]="tab"
					item="tab">

					<button *ngIf="!tab.class" 
						class="nav-link h-100" 
						[disabled]="tab.disabled"
						[ngClass]="tabCache.getSelectedTab() == tab ? 'bg-white active font-weight-bold' : 'bg-light-gray text-secondary'">{{tab.tab}}</button>
					<button *ngIf="tab.class"
						class="{{tab.class}} h-100">{{tab.tab}}</button>
				</li>
			</ul>
		</div>
	</div>
	<div class="row">
		<div class="col-12 p-0" [ngClass]="'class-' + tabCache.getSelectedTabID()">
			<ng-content></ng-content>
		</div>
	</div>
</div>
<lio-error *ngIf="!hideErrors"></lio-error>