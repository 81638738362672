import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { localizationService } from '../../../../services/localization.service';
import { utilService } from '../../../../services/util.service';
import { paginationRegistryService } from '../../../../services/pagination-registry.service';
import { fieldService } from '../../../../services/fields.service';

@Component({
	selector: 'lio-results-table',
	styleUrls: ['./results-table.component.css'],
	templateUrl: './results-table.component.html',
})
export class LioResultsTable {
	public paginator:any = {
		settings: {
			sortMode: {
				field: 		'', 
				fieldType: 	'', 
				direction:	''
			}
	}};

	public lastID: string = '';

	@Input() fields:Array<any>;
	@Input() fieldConfig:any;
	@Input() parent:any;

	@Input() headerPadding:string = '';

	@Output() headerClick:EventEmitter<{field:any,item:any}> = new EventEmitter();
	@Output() fieldClick:EventEmitter<{field:any,item:any}> = new EventEmitter();

	private _paginatorID :any;
	get paginatorID(){
		return this._paginatorID;
	}
	@Input() set paginatorID(value: any) {    
		this._paginatorID = value;
		this.paginationRegistryService.registerObserver(value, (paginator) => {
			this.paginator = paginator;
		});
	}

	private _results :Array<any>;
	get results(){
		return this._results;
	}
	@Input() set results(value: Array<any>) {    
		this._results = value;
		this.setResults();
	}

	private _prefixButtons :any;
	get prefixButtons(){
		return this._prefixButtons;
	}
	@Input() set prefixButtons(value: any) {    
		this._prefixButtons = value;
		this.setButtonDefaults(this.prefixButtons);
	}

	private _suffixButtons :any;
	get suffixButtons(){
		return this._suffixButtons;
	}
	@Input() set suffixButtons(value: any) {    
		this._suffixButtons = value;
		this.setButtonDefaults(this.suffixButtons);
	}

	public boolFields = {
		true : 'True',
		trueTrans : 'form.true',
		false : 'True',
		falseTrans : 'form.false'
	};

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(debugService) public debugService :debugService,
		@Inject(localizationService) public localizationService :localizationService,
		@Inject(utilService) public utilService :utilService,
		@Inject(paginationRegistryService) public paginationRegistryService :paginationRegistryService,
		@Inject(fieldService) public fieldService :fieldService
	){
		this.debugService.register('results', this);

		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.setResults();
			})
		);
	}

	newID() {
		if (!this.lastID) {
			this.lastID = (Math.random() + 1).toString(36).substring(7);
		}
		return this.lastID;
	}

	getLastID() {
		return this.lastID;
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
					
	/**
	 * Initializes the directive
	 */
	init(){
		this.setButtonDefaults(this.prefixButtons);
		this.setButtonDefaults(this.suffixButtons);
	}

	/**
	 * Handles clicking on a specific field
	 * @param {object} data
	 * @param {object} field
	 */
	clickedField(item, field) {
		if(field.callback){
			field.callback(item);
		}
		this.fieldClick.emit({item:item, field:field});
	}

	/**
	 * Handles clicking on a specific header
	 * @param {object} data
	 * @param {object} field
	 */
	clickedHeader(field) {
		this.headerClick.emit(field);
		
		if (this.paginator && field.sortable) {
			this.sortResults(field);
		}
	}

	/**
	 * Sorts events by the selected field
	 */
	sortResults(sortField){
		if(sortField.sortable) {			
			if(sortField.type){
				this.paginator.settings.sortMode.fieldType = sortField.type;
			}
			
			if(this.paginator.settings.sortMode.field == sortField.model) {
				if(this.paginator.settings.sortMode.direction == 'asc') {
					this.paginator.settings.sortMode.direction = 'desc';
				} else {
					this.paginator.settings.sortMode.direction = 'asc';
				}
			} else {
				this.paginator.settings.sortMode.field = sortField.model;
			}
		}
	}

	/**
	 * Set buton size and color defaults
	 * @param {[element]} buttons
	 */
	setButtonDefaults(buttons = []){
		buttons.forEach((button) =>{
			if(typeof button.size == 'undefined'){
				button.size = 'sm';
			}

			if(typeof button.color == 'undefined'){
				button.color = 'primary';
			}
		});
	}

	/**
	 * Formats a date
	 * @param {string} date
	 * @return {string}
	 */
	formatDate(date) {
		return this.utilService.formatDate(date, this.localizationService.dateFormat);
	}


	/**
	 * Formats a date as time stamp
	 * @param {string} date
	 * @return {string}
	 */
	formatTimeStamp(date) {
		return this.utilService.formatTimeStamp(date);
	}

	/*
	* Sets the mapping of the results
	*/
	setResults() {
		if (this.fieldConfig && this.results) {
			this.results.forEach((result, index) => {
				this.fields.forEach((field, fieldIndex) => {
					let mapped = null;
					if (field.mappingFunction) {
						mapped = field.mappingFunction(result[field.model], field.mapData);
						if (mapped) {
							this.fields[fieldIndex].mapped = true;
							this.results[index][field.model + 'mapped'] = mapped;
						}
					}
					if (field.mappingTransFunction) {
						mapped = field.mappingTransFunction(result[field.model], field.mapData);
						mapped = this.localizationService.get(mapped);
						if (mapped) {
							this.fields[fieldIndex].mapped = true;
							this.results[index][field.model + 'mapped'] = mapped;
						}

					}
				});
			});
		}
	}

	isDefined(val:any){
		return typeof val != 'undefined';
	}
}