<div localize [model]="model" [items]="getLocaleKeys()">
	<button class="btn btn-block p-0">
		<lio-card [cardId]="model.id"
			cardClass="border-{{model.color}} text-white" 
			headerClass="bg-{{model.color}}" 
			footerClass="text-faded-blue small" 
			[hideErrors]="true">

			<div lio-card-header class="row min-h-8">
				<div class="col-3 pr-md-0" 
					[ngClass]="{'text-left':!isRTL(),'text-right':isRTL()}">

					<i class="fa {{model.icon}} fa-2x"></i>
				</div>
				<div class="col-9 pl-md-0" 
					[ngClass]="{'text-left':isRTL(),'text-right':!isRTL()}">

					<div class="h4 mb-0" id="{{model.id + '_label'}}">{{model.title}}</div>
				</div>
			</div>
			<div lio-card-footer>
				<span id="{{model.id + '_subtitle'}}" 
					[ngClass]="{'float-left':!isRTL(),'float-right':isRTL()}">{{model.subtitle}}</span>
				<i class="fa" 
					[ngClass]="{'float-right fa-arrow-circle-right':!isRTL(),'float-left fa-arrow-circle-left':isRTL()}">
				</i>
			</div>
		</lio-card>
	</button>
</div>