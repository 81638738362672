import Flow from '@flowjs/flow.js';

import { Component, Input, Output, Inject, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { domService } from './../../../../services/dom.service';
import { debugService } from './../../../../services/debug.service';
import { lioLogService } from './../../../../services/lio-log.service';
import { lioModalService } from './../../../../services/lio-modal.service';
import { utilService } from './../../../../services/util.service';
import { errorsService } from './../../../../services/errors.service';
import { feedbackService } from './../../../../services/feedback.service';
import { processingService } from './../../../../services/processing.service';
import { navService } from './../../../../services/nav.service';
import { lmsService } from './../../../../services/lms.service';

@Component({
	selector: 'lio-file-uploader',
	templateUrl: './file-uploader.component.html'
})
export class LioFileUploader implements OnDestroy{
	@Input() matchBootstrap		:boolean = true;

	@Input() settings 			:any = {
		name 		: '',
		class 		: '',
		hideButton 	: true
	};

	@Input() disabled 			:any;

	@Output() success: EventEmitter<any> = new EventEmitter<any>();
	@Output() failure: EventEmitter<any> = new EventEmitter<any>();

	private loaded				:boolean 	= false;
	private debug				:boolean 	= false;
	private runTaskOnFileAdd	:boolean 	= false;
	private listening			:boolean 	= false;

	private flow				:any 		= false;
	private filePath			:any;

	@ViewChild('flowBrowse', {read: ElementRef})
	public flowBrowse			:ElementRef; 

	private _flowBrowseInput:ElementRef = null;
	public get flowBrowseInput():ElementRef{
		return this._flowBrowseInput;
	}
	@Input()
	public set flowBrowseInput(val:ElementRef){
		this._flowBrowseInput = val;
		if(val){
			this.flow.assignBrowse(val.nativeElement);
		}
	}

	@ViewChild('flowDrop', {read: ElementRef})
	public flowDrop				:ElementRef; 

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(debugService)		private debugService		:debugService,
		@Inject(domService)			private domService			:domService,
		@Inject(lioLogService)		private lioLogService		:lioLogService,
		@Inject(lioModalService)	private lioModalService		:lioModalService,
		@Inject(utilService)		private utilService			:utilService,
		@Inject(errorsService)		private errorsService		:errorsService,
		@Inject(feedbackService)	private feedbackService		:feedbackService,
		@Inject(processingService)	private processingService	:processingService,
		@Inject(navService)			private navService			:navService,
		@Inject(lmsService)			private lmsService			:lmsService
	){
		this.debugService.register('fileUploader', this);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {	
		if (!this.settings) {
			if (this.debug) {
				this.lioLogService.warn('Missing File Settings');
			}
			return;
		}

		if (this.loaded) {
			return;
		}

		this.runTaskOnFileAdd 		= true;
		this.flow 					= null;

		if (this.utilService.isDefined(this.settings.runTaskOnFileAdd)) {
			this.runTaskOnFileAdd = this.settings.runTaskOnFileAdd;
		}

		if (!this.utilService.isDefined(this.disabled)) {
			this.disabled = false;
		}

		if (!this.settings.fileTask && this.runTaskOnFileAdd) {
			this.setError('Missing Task');
			return;
		}
		this.loaded = true;
		this.addObservers();

		/*
		* Timeout Heard
		*/
		this.subscriptions.add(
			this.errorsService.timeout.subscribe(() => {
				this.navService.goto('500');
			})
		);

		/*
		* Cancellation heard
		*/
		this.subscriptions.add(
			this.navService.confirmedExit.subscribe(() => {
				this.cancelFileProcess();
			})
		);
	}

	ngAfterViewInit(){
		if(this.flowBrowse){
			this.flow.assignBrowse(this.flowBrowse.nativeElement);
		}
		this.flow.assignDrop(this.flowDrop.nativeElement);
	}
	
	/*
	 * Adds the listeners
	*/
	addObservers() {
		if (this.listening) {
			return;
		}

		this.createFlow();
		this.addFlowObservers();
		this.listening = true;
		if (this.debug) {
			this.lioLogService.log('Ready For File Uploading');
		}
		setTimeout(() => {
			this.addIDToFlow();
		}, 100);
	}


	/*
	 * Adds id to the the flow input for accessibility lable
	*/
	addIDToFlow() {
		let inputs = this.domService.getInputs(),
			input = null,
			i = 0;

		for (i = 0; i < inputs.length; i++) {
			input = inputs[i];
			if (input.type === 'file' && !input.id) {
				input.id = this.settings.name;
				input.name = this.settings.name;
			}
		}
	};


	/*
	 * Adds the flow object
	*/
	createFlow() {
		let flow = {
			'target': this.getFlowTarget(), 
			'singleFile': false,
			'progressCallbacksInterval': 5000,
			'speedSmoothingFactor': 0.1,
			'withCredentials': false,
			'chunkSize': 1 * 1024 * 1024,
			'forceChunkSize': false,
			'simultaneousUploads': 3,
			'fileParameterName': 'file',
			'throttleProgressCallbacks': 0.5,
			'query': {},
			'headers': {},
			'preprocess': null,
			'method': 'multipart',
			'prioritizeFirstAndLastChunk': false,
			'testChunks': true,
			'generateUniqueIdentifier': null,
			'maxChunkRetries': 0,
			'chunkRetryInterval': 0,
			'permanentErrors': [415, 500, 501],
			'maxFiles': 0,
			'maxFilesErrorCallback': (files) => {
				this.setError('maxFilesError',files);
			}
		};

		this.flow = new Flow(flow);
		if (this.debug) {
			this.lioLogService.log('Created Flow');
		}
	}

	/*
	 * Adds the flow listeners selector to select
	*/
	addFlowObservers() {
		// File Added
		this.flow.on('fileAdded', (file) => {
			this.lioLogService.log(['Heard file added', file]);
			if (this.disabled) {
				if (this.debug) {
					this.lioLogService.log('File uploading is currently disabled');
				}
				return;
			}
			this.fileAdded(file);
		});

		// File Progress
		this.flow.on('fileProgress', () => {
			this.handleProgress();
		});

		// File Processed
		this.flow.on('fileSuccess', (file, response) => {
			this.lioLogService.log(['Heard file success', file, response]);

			try {
				response = JSON.parse(response);
			} catch (e) {
				this.setError('File Error', response, true);
				return;
			}
			this.handleFileProcessed(file, response);
			
		});
		
		// File Error
		this.flow.on('fileError', (file, response) => {
			setTimeout(() => {
				if (this.flow) {
					this.flow.upload();
				}
			}, 1000);
			this.lioLogService.log(['Heard file error, try again', file, response]);
			this.lioModalService.hideLoading();
			this.resetFlow();
			this.lioModalService.show('files.failedToUpload');
			//this.setError('File Error', response, true);
		});
	}

	/*
	 * Handles file progress
	*/
	handleProgress() {
		let progress = this.flow.progress();

		if (progress) {
			progress = Math.floor(progress / 1 * 100);
		}

		if (progress > 0 && progress < 100) {
			this.lioModalService.updateSetting('showProgress', true);
			this.lioModalService.updateSetting('progress', progress);
			this.lioModalService.updateSetting('canCancel', true);
		} else {
			this.lioModalService.updateSetting('canCancel', false);
			this.lioModalService.updateSetting('showProgress', false);
		}
	}

	/*
	 * File Error
	 * @param {string} error: The Error Title
	 * @param {array}  response: The Error Response
	 * @param {boolean} critical 
	*/
	setError(error, response = null, critical = false) {		
		this.errorsService.lastResponse = error;
		if (error.key) {
			this.feedbackService.setError(error);
		} else {
			this.feedbackService.setErrors([error]);
		}
		this.resetFlow();

		if (critical) {
			this.errorsService.lastResponse = response;
			this.errorsService.errorCode = 500;
			this.processingService.processing = false;
			this.errorsService.throwCriticalError(response);
		} else {
			this.failure.emit(response);
		}
		if (this.debug) {
			this.lioLogService.log(['File Error', error, response]);
		}
		this.lioModalService.hideLoading();
	}

	/*
	 * File Processed
	*/
	handleFileProcessed(_file, response) {
		this.errorsService.criticalErrors = [];
		this.feedbackService.clearErrors();
		
		if (response.criticalErrors.length) {
			setTimeout(() => {
				this.setError(response.criticalErrors[0], response.criticalErrors, true);
			});
			return;
		}
		
		if (response.properties.cancelled) {
			this.resetFlow();
			return;
		}

		if (response.errors.length) {
			setTimeout(() => {
				this.setError(response.errors[0], response.errors);
			});
			return;
		}

		if (response.success) {
			this.filePath 				= response.properties.filePath;
			this.navService.changedForm = true;
			if (this.settings.hideLoading) {
				this.lioModalService.hideLoading();
			}
			this.resetFlow();
			this.success.emit({filePath : this.filePath, response : response});
		}
	}

	/*
	 * File Added Heard
	 * @param {object} file
	 * @param {event} event
	*/
	fileAdded(file) {
		let allowedFileTypes = this.settings.allowedFileTypes;

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.processingService.allowCancel = true;
		this.feedbackService.clearErrors();

		let extension = file.file.name.split('.'),
			validType = this.utilService.inArray(allowedFileTypes, extension[extension.length -1]);	

		if (!validType) {
			this.handleWrongFileType();
			return;
		}

		if (this.runTaskOnFileAdd) {
			this.lioModalService.showLoading('uploadingFile').then((result) => {
				if (result === 'CANCELLED') {
					this.cancelFileProcess();
				}
			});

			if (this.settings.preFileTask) {
				this.lmsService.post(this.settings.preFileTask, {}).then(() => {
					if (this.flow) {
						this.flow.upload();
					}
				});
			} else {
				setTimeout(() => {
					if (this.flow) {
						this.flow.upload();
					}
				}, 1000);
			}
		} else {
			this.success.emit({filePath : file});
			this.resetFlow();
		}
	}

	/*
	 * Handles the wrong file type
	*/
	handleWrongFileType() {
		let allowedFileTypes 	= this.settings.allowedFileTypes,
			error 				= 'wrongFileType',
			macros				= [],
			value 				= '';

		if (allowedFileTypes.length > 1) {
			error = 'wrongFileTypes';
			allowedFileTypes.forEach((type) => {
				value += '.' + type + ', ';
			});
		} else {
			value = '.' + allowedFileTypes.join(',');
		}
		// Clean trailing commas
		value = value.replace(/,\s*$/, "");

		macros.push({'key': 'fileExt', 'value': value});
		this.setError(this.utilService.localizeError(error, macros));
	}

	/*
	 * Updates the flow target
	 * @param {string} fileTask
	*/
	updateFlowTarget(fileTask) {		
		this.settings.fileTask = fileTask;
		
		if (this.flow) {
			this.flow.opts.target = this.getFlowTarget();
		}
	}

	/*
	 * Gets the flow target
	 * @return {string}
	*/
	getFlowTarget(){
		return 'backend/tasks.php?task=' + this.settings.fileTask;
	}

	/*
	 * Cancel the file upload
	*/
	cancelFileProcess() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (this.filePath) {
			this.lmsService.post('import/deleteFile', {'file': this.filePath}).then(() => {
				this.filePath = null;
				this.cancelFlow();
			});
		} else {
			this.cancelFlow();							
		}
	}

	/*
	 * Reset flow
	*/
	resetFlow() {
		this.lioModalService.updateSetting('showProgress', false);
		setTimeout(() => {
			this.cancelFlow();
		}, 1000);
	}

	/*
	 * Cancel flow
	*/
	cancelFlow() {
		if (this.flow) {
			this.flow.cancel();
		}
		this.loaded = false;
		this.listening = false;
	}
}