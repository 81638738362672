<lio-panel-tabber>
	<lio-panel-tab [settings]="addUsersPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="addUsersPanelConfig.sections.addOne" item="title">
					<mat-panel-title>{{addUsersPanelConfig.sections.addOne.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Add_One_User_Enroll_Email.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="addUsersPanelConfig.sections.addMany" item="title">
					<mat-panel-title>{{addUsersPanelConfig.sections.addMany.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Bulk_Upload_Data.pdf">Download Instructions</a>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Add_Many_Users_Enroll_Email.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>

	<lio-panel-tab [settings]="enrollPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.enrollRules" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.enrollRules.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Enrollment_Rules_And_Email.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.bulkEnroll" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.bulkEnroll.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Bulk_Enroll.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.enrollmentscheduler" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.enrollmentscheduler.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Enrollment_Scheduler_Guide.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.bulkUnenroll" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.bulkUnenroll.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Bulk_Unenroll.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.bulkComplete" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.bulkComplete.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/bulk_completion.pdf">Download Instructions</a>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Bulk_Mark_Complete.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="enrollPanelConfig.sections.bulkUpdate" item="title">
					<mat-panel-title>{{enrollPanelConfig.sections.bulkUpdate.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Bulk_Update_Enrollments.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="schedulingPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="workflowPanelConfig.sections.emailSchedule" item="title">
					<mat-panel-title>{{schedulingPanelConfig.sections.emailSchedule.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Create_Scheduled_Emails_Export_Results.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
			<mat-expansion-panel-header localize [model]="schedulingPanelConfig.sections.multiCourseMacro" item="title">
				<mat-panel-title>{{schedulingPanelConfig.sections.multiCourseMacro.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/MultiCourseMacro.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="workflowPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="workflowPanelConfig.sections.addUsers" item="title">
					<mat-panel-title>{{workflowPanelConfig.sections.addUsers.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Add_Users_Enroll_Email.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="workflowPanelConfig.sections.markingComplete" item="title">
					<mat-panel-title>{{workflowPanelConfig.sections.markingComplete.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Search_Modify_MarkComplete_Export_Assign_Email.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="workflowPanelConfig.sections.reports" item="title">
					<mat-panel-title>{{workflowPanelConfig.sections.reports.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Creating_Dashboard_Reports.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="workflowPanelConfig.sections.resendingregemails" item="title">
					<mat-panel-title>{{workflowPanelConfig.sections.resendingregemails.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Resending_Registrations.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="rolesPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="rolesPanelConfig.sections.differentViews" item="title">
					<mat-panel-title>{{rolesPanelConfig.sections.differentViews.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto text-left">
						<h2 class="h5">The following video demonstrates the different roles that can be assigned to users in LearnIO</h2>
						<ul>
							<li>The Client Admin has access to all available admin functionality.</li>
							<li>The Learner Admin does not have access to the Dashboard or View Reports tiles</li>
							<li>The Analyst does not have access to the Add Users, Enroll Users, Email Templates or Search Users Tiles</li>
							<li>Support does not have access to the Add Users, Enroll Users, Dashboard, View Reports, or Email Templates Tiles</li>
							<li>The Learner is directed to the My Learning tile and has no access to admin functionality.</li>
						</ul>
					</div>
				</div>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Roles.pdf">Download Instructions</a>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Roles.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="launchPrepPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="launchPrepPanelConfig.sections.prep" item="title">
					<mat-panel-title>{{launchPrepPanelConfig.sections.prep.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/2020_LIO_Launch_Prep.pdf">Download Instructions</a>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Launch_Prep_Video.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="selfenrollPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="selfenrollPanelConfig.sections.self" item="title">
					<mat-panel-title>{{selfenrollPanelConfig.sections.self.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Self_Enrollment.pdf">Download Instructions</a>
				</div>
				<div class="row">
					<div class="col-md-6 col-sm-12 mx-auto">
						<video controls width="100%">
							<source src="views/partials/documentation/Self_Enroll.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
	<lio-panel-tab [settings]="otherPanelConfig">
		<mat-accordion *lioPanelTabContent>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.sso" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.sso.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/configuring_sso_learnio.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.onboarding" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.onboarding.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/LMS_Onboarding_Guide_CF.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.localization" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.localization.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/localization_guide_CF.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.troubleshooting" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.troubleshooting.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Troubleshooting.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.whitelisting" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.whitelisting.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/Whitelisting_DOC_LIO.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel>
				<mat-expansion-panel-header localize [model]="otherPanelConfig.sections.userFields" item="title">
					<mat-panel-title>{{otherPanelConfig.sections.userFields.title}}</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="row mb-3">
					<a class="font-bold mx-auto" target="_blank" href="views/partials/documentation/pdfs/User_Data_Fields.pdf">Download Instructions</a>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</lio-panel-tab>
</lio-panel-tabber>