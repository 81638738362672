import { Inject, Injectable } from '@angular/core';

import { storageService } from '../../services/storage.service';
import { navService } from '../../services/nav.service';
import { PanelTabData, CardButtonModelArray } from '../../modules/structural/structural.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: adminToolsSettings
})
export class adminToolsSettings {
	constructor(
		@Inject(storageService) private storageService:storageService,
		@Inject(navService) private navService:navService)
	{}

	managePanelConfig:PanelTabData = new PanelTabData({
		id				: 'panelTab_admin_manage',
		title			: 'Admin Tools',
		titleTrans		: '',
		titleClass		: '',
		subtitle 		: 'Manage',
		tab				: 'Manage',
		tabTrans		: '',
		visible 		: () => { return this.manageCards.hasVisible(); }
	});

	settingsPanelConfig:PanelTabData = new PanelTabData({
		id				: 'panelTab_admin_Settings',
		title			: 'Admin Tools',
		titleTrans		: '',
		titleClass		: '',
		subtitle 		: 'Settings',
		tab				: 'Settings',
		tabTrans		: '',
		visible 		: () => { return this.settingsCards.hasVisible(); }
	});

	logsPanelConfig:PanelTabData = new PanelTabData({
		id				: 'panelTab_admin_logs',
		title			: 'Admin Tools',
		titleTrans		: '',
		titleClass		: '',
		subtitle 		: 'Logs',
		tab				: 'Logs',
		tabTrans		: '',
		visible 		: () => { return this.logsCards.hasVisible(); }
	});
	
	manageCards:CardButtonModelArray = new CardButtonModelArray(
		{
			permission			:'pages.globalSearch',
			onClick				: () => {
				this.navService.goto('globalsearch'); 
			},
			color				:'faded-blue',
			icon				:'fa-globe',
			id					:'am_admintools_gotoGlobalSearch',
			subtitle			:'Global search',
			subtitleTrans		:'globalsearch.title',
			title				:'Global search',
			titleTrans			:'globalsearch.title',
		},
		{
			permission			:'pages.catalogAdmin',
			onClick				: () => {
				this.navService.goto('catalogadmin');
			},
			color				:'green',
			icon				:'fa-book-open',
			id					:'am_admintools_catalogAdmin',
			subtitle			:'Course Catalog',
			subtitleTrans		:'catalogadmin.title',
			title				:'Course Catalog',
			titleTrans			:'catalogadmin.title',
		},
		{
			permission			:'pages.courseUploader',
			onClick				: () => {
				this.storageService.remove('uploadedCourse');
				this.navService.goto('courseuploader');
			},
			color				:'yellow',
			icon				:'fa-upload',
			id					:'am_adminTools_gotoManageCourses',
			subtitle			:'Course Manager',
			subtitleTrans		:'home.courseManagerSub',
			title				:'Course Manager',
			titleTrans			:'home.courseManager',
		},
		{
			permission			:'pages.disclosure',
			onClick				: () => {
				this.navService.goto('disclosure');
			},
			color				:'green',
			icon				:'fa-cog',
			id					:'am_admintools_gotodisclosures',
			subtitle			:'Disclosure Manager',
			subtitleTrans		:'disclosures.title',
			title				:'Disclosure Manager',
			titleTrans			:'disclosures.title',
		},
		{
			permission			:'pages.company',
			onClick				: () => {
				this.navService.goto('company');
			},
			color				:'blue',
			icon				:'fa-building',
			id					:'am_admintools_companyAdmin',
			subtitle			:'Company Administration',
			subtitleTrans		:'companyadmin.title',
			title				:'Company Administration',
			titleTrans			:'companyadmin.title',
		},
	);

	settingsCards:CardButtonModelArray = new CardButtonModelArray(
		{
			permission			:'pages.learnioSettings',
			onClick				: () => {
				this.navService.goto('learniosettings');
			},
			color				:'faded-blue',
			icon				:'fa-cog',
			id					:'am_admintools_gotoSpiderSettings',
			subtitle			:'Learn IO Settings',
			subtitleTrans		:'learnioSettings.title',
			title				:'Learn IO Settings',
			titleTrans			:'learnioSettings.title',
		},
		{
			permission			:'pages.branding',
			onClick				: () => {
				this.navService.goto('branding'); 
			},
			color				:'faded-blue',
			icon				:'fa-brush',
			id					:'am_admintools_gotoBranding',
			subtitle			:'Branding',
			subtitleTrans		:'branding.title',
			title				:'Branding',
			titleTrans			:'branding.title',
		},
		{
			permission			:'pages.localization',
			onClick				: () => {
				this.navService.goto('localizationadmin');
			},
			color				:'faded-blue',
			icon				:'fa-language',
			id					:'am_admintools_gotoLocalization',
			subtitle			:'Localization',
			subtitleTrans		:'localization.title',
			title				:'Localization',
			titleTrans			:'localization.title',
		},
		{
			permission			:'pages.editPermissions',
			onClick				: () => {
				this.navService.goto('editPermissions');
			},
			color				:'yellow',
			icon				:'fa-lock',
			id					:'am_admintools_gotoEditPermissions',
			subtitle			:'Assign Roles and Groups to Users, Modify Roles',
			subtitleTrans		:'editPermissions.subtitle',
			title				:'Edit Permissions',
			titleTrans			:'editPermissions.title',
		},
		{
			permission			:'pages.fields',
			onClick				: () => {
				this.navService.goto('fields');
			},
			color				:'yellow',
			icon				:'fa-cog',
			id					:'am_admintools_gotoFields',
			subtitle			:'Fields',
			subtitleTrans		:'fields.title',
			title				:'Field Manager',
			titleTrans			:'fields.title',
		},
	);

	logsCards:CardButtonModelArray = new CardButtonModelArray(
		{
			permission			:'pages.accessLog',
			onClick				: () => {
				this.navService.goto('accesslog');
			},
			color				:'yellow',
			icon				:'fa-globe',
			id					:'am_admintools_gotoAccessLogs',
			subtitle			:'Access Logs',
			subtitleTrans		:'accesslog.title',
			title				:'Access Logs',
			titleTrans			:'accesslog.title',
		},
		{
			permission			:'pages.auditLog',
			onClick				: () => {
				this.navService.goto('auditlog');
			},
			color				:'yellow',
			icon				:'fa-globe',
			id					:'am_admintools_gotoAuditLogs',
			subtitle			:'Audit Logs',
			subtitleTrans		:'auditlog.title',
			title				:'Audit Logs',
			titleTrans			:'auditlog.title',
		},
		{
			permission			:'pages.errorLog',
			onClick				: () => {
				this.navService.goto('errorlog');
			},
			color				:'yellow',
			icon				:'fa-globe',
			id					:'am_admintools_gotoErrorLogs',
			subtitle			:'Error Logs',
			subtitleTrans		:'errorlog.title',
			title				:'Error Logs',
			titleTrans			:'errorlog.title',
		},
		{
			permission			:'pages.emailAuditLog',
			onClick				: () => {
				this.navService.goto('emailauditlog');
			},
			color				:'yellow',
			icon				:'fa-globe',
			id					:'am_admintools_gotoEmailAuditLogs',
			subtitle			:'Email Audit Logs',
			subtitleTrans		:'emailauditlog.title',
			title				:'Email Audit Logs',
			titleTrans			:'emailAauditlog.title',
		},
	);
}