import { Component, OnDestroy, Inject } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { catalogService } from '../../../../services/catalog.service';
import { workerService } from '../../../../services/worker.service';
import { localizationService } from '../../../../services/localization.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { processingService } from '../../../../services/processing.service';
import { utilService } from '../../../../services/util.service';
import { filesService } from '../../../../services/files.service';
import { fieldService } from '../../../../services/fields.service';
import { storageService } from '../../../../services/storage.service';
import { catalogAdminSettings } from '../../../../components/catalog-admin/catalog-admin.settings';

@Component({
  selector: 'lio-catalog-admin-edit',
  templateUrl: './catalog-admin-edit.component.html'
})
export class CatalogAdminEditComponent implements OnDestroy {

	public	appearance:MatFormFieldAppearance = 'outline';
	public	fields:any									= [];
	public	catalogs:any								= [];
	public	catalog:any									= {};
	public	fieldConfig:any							= {};
	public  courseIDField:any 					= {};
	public	allowSubmit:boolean					= false;
	public	sample :any									= {};
	public	localeStrings:any 					= {};
	public	localeStringsKeys:any 			= [];

	protected subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService			:navService,
		@Inject(debugService)			public 	debugService		:debugService,
		@Inject(feedbackService)		public 	feedbackService		:feedbackService,
		@Inject(errorsService)			public 	errorsService		:errorsService,
		@Inject(lmsService)				public 	lmsService			:lmsService,
		@Inject(catalogService)			public 	catalogService		:catalogService,
		@Inject(lioLogService)			public 	lioLogService		:lioLogService,
		@Inject(stateService)			public 	stateService		:stateService,
		@Inject(formValidatorService)	public 	formValidatorService:formValidatorService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(workerService)			public 	workerService		:workerService,
		@Inject(permissionService)		public 	permissionService	:permissionService,
		@Inject(localizationService)	public 	localizationService	:localizationService,
		@Inject(lioModalService)		public 	lioModalService		:lioModalService,
		@Inject(processingService) 		public 	processingService	:processingService,
		@Inject(utilService) 			public 	utilService			:utilService,
		@Inject(fieldService) 			public 	fieldService		:fieldService,
		@Inject(storageService)			public 	storageService		:storageService,
		@Inject(catalogAdminSettings) 		public 	catalogAdminSettings		:catalogAdminSettings
	){
		this.debugService.register('catalogadminedit', this);
		this.init();
		this.getFieldByName('courseID').locked = true;
	}


	/*
	 Init
	*/
	init() {
		this.fields 				= this.permissionService.setFields(this.catalogAdminSettings.fields);
		this.fieldConfig 		= this.catalogAdminSettings.fieldConfig;
		this.courseIDField 	= this.catalogAdminSettings.courseIDField;
		this.catalog 				= this.utilService.copy(this.catalogAdminSettings.model);
		this.sample 				= this.utilService.copy(this.catalogAdminSettings.sample);

		this.processingService.setLangIDParam = true;
		this.processingService.allowCancel = true;
		this.subscriptions = NEVER.subscribe();

		this.localeStrings = {
			save						:'catalogadmin.save',
		}

		this.localeStringsKeys = Object.keys(this.localeStrings);

		this.subscriptions.add(
				this.catalogService.catalogs.subscribe((catalogs) => {
					this.catalogs = catalogs;
				})
		);

		this.subscriptions.add(
				this.catalogService.catalog.subscribe((catalog) => {
					if (!catalog) {
						catalog = this.utilService.copy(this.catalogAdminSettings.model);
					}
					this.catalog = catalog;
				})
		);
	}


	/*
	 * On update of the profile
	*/
	onUpdate() {
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
		this.navService.changedForm = true;
		this.allowSubmit = true;
	}

	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;

		this.feedbackService.clearErrors();
		if (!this.formValidatorService.isValid(this.catalog, this.fields)) {
			isValid = false;
		}

		return isValid;
	}



	/*
	 * Submits the edit
	*/
	submit():boolean {
		if (!this.isValid()) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			return false;
		}

		this.catalogService.editCatalog(this.catalog).then((result)  => {
			if (result) {
				this.allowSubmit = false;
			}
		});
		return true;
	}


	/*
	 * Gets the catalogs by course ID
	*/
	getCatalog() {
		if (!this.catalog.courseID) {
			return;
		}
		this.catalogService.setCatalogByCourseID(this.catalog.courseID);
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
	}


	/*
	 * Gets field by name
	*/
	getFieldByName(modelName) {
		let foundField = null;
		this.fields.forEach((field) => {
			if (field.model === modelName) {
				foundField = field;
				return;
			}
		});
		return foundField;
	}


	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}