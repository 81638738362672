import { Pipe } from '@angular/core';

@Pipe({
  name: "orderBy"
})
export class OrderByPipe {
	transform(array: Array<any> | null, sortBy:string, reverse: boolean = false): Array<any> {
		if (!array || !sortBy) {
			return array; 
		}
		let copy = array.slice(),
				r = reverse ? -1 : 1; 

		copy.sort((a: any, b: any) => {
			let aVar = a[sortBy] || 0,
					bVar = b[sortBy] || 0;

			if (aVar < bVar) { return -1 * r; }
			if (bVar < aVar) { return 1  * r; }
		});
		return copy;
	}
}