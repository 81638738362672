<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-12">
		<!-- Search -->
		<div class="row">
			<div class="col-md-12 ml-auto" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<mat-form-field [appearance]="appearance" class="w-100" [style.fontSize.rem]="0.8">
					<label class="sr-only" for="search">{{localeStrings.search}}</label>
					<input matInput
						id="search"
						type="text"
						[(ngModel)]="search">
					<mat-icon matSuffix>search</mat-icon>
				</mat-form-field>
			</div>
		</div>
		<div class="card-body table-responsive p-0 rtl-direction">
			<div lio-card-body lio-paginator [controlID]="searchPanelConfig.pagination.id" [collection]="companies" [settings]="searchPanelConfig.pagination" [filters]="search" (onResults)="updateFilteredCompanies($event)">
				<lio-results-table
					[paginatorID]="searchPanelConfig.pagination.id"
					[fields]="fields"
					[fieldConfig]="fieldConfig"
					[results]="filteredCompanies"
					[suffixButtons]="suffixButtons">
				</lio-results-table>
			</div>
		</div>
	</div>
</div>
