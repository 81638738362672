<lio-card [hidden]="!employees.length" bodyClass="p-0" footerClass="container-fluid">
	<div lio-card-header>
		<span><b>Select a Role to assign or un-assign</b></span>
		<lio-searchable-select [model]="targetRole" [field]="roleField"></lio-searchable-select>
	</div>

	<div lio-card-body 
		lio-paginator 
		controlID="assignRoles" 
		[collection]="employees" 
		[settings]="pagination" 
		(onResults)="updateFilteredEmployees($event)">

		<lio-results-table class="text-center"
			paginatorID="assignRoles"
			[fields]="fields"
			[results]="filteredEmployees"
			[suffixButtons]="suffixButtons">
		</lio-results-table>
	</div>
	
	<div lio-card-footer>
		<lio-paginator-controls class="row" controlID="assignRoles"></lio-paginator-controls>
	</div>		
</lio-card>