import { Component, Inject } from '@angular/core';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { emailEventService } from '../../services/email-event.service';

import { scheduleEmailEventHistorySettings } from '../schedule-emails/schedule-email-event-history.settings';
import { scheduleEmailSettings } from '../schedule-emails/schedule-email.settings';

@Component({
	selector: 'lio-email-history',
	templateUrl: './email-history.component.html'
})
export class EmailHistoryComponent {
	public settings	:any = this.scheduleEmailEventHistorySettings;
	public panels	:any = this.scheduleEmailSettings.panels;

	constructor(
		@Inject(navService)								public	navService							:navService,
		@Inject(debugService)							public	debugService						:debugService,
		@Inject(emailEventService)						public	emailEventService					:emailEventService,
		@Inject(scheduleEmailEventHistorySettings)		public	scheduleEmailEventHistorySettings	:scheduleEmailEventHistorySettings,
		@Inject(scheduleEmailSettings)					public	scheduleEmailSettings				:scheduleEmailSettings,
	){
		this.debugService.register('emailHistory', this);
		this.navService.setActivePage('emailHistory');
	}
}