/*
 * Service for Login
*/
import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { feedbackService } from './feedback.service';
import { loginService } from './login.service';
import { navService } from './nav.service';
import { permissionService } from './permissions.service';
import { stateService } from './state.service';
import { lioModalService } from './lio-modal.service';
import { storageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class loaderService {
	gettingBranding : boolean		= false;
	localizationIDs : Array<any>	= [];
	localizations	: Array<any>	= [];
	loadedBrandIds	: Array<any>	= [];
	loadedBrands	: Array<any>	= [];
	loaded 			: boolean 		= false;

	lockedAndLoaded	:ReplaySubject<void> = new ReplaySubject(1);

	constructor(
		@Inject(feedbackService)				private feedbackService 		:feedbackService,
		@Inject(loginService)					private loginService			:loginService,
		@Inject(navService)						private navService				:navService,
		@Inject(storageService)					private storageService			:storageService,
		@Inject(permissionService)				private permissionService		:permissionService,
		@Inject(stateService)					private stateService			:stateService,
		@Inject(lioModalService)				private lioModalService			:lioModalService
	){
		this.feedbackService.clearErrors();
		this.navService.loading = true;
		this.lioModalService.showLoading('');

		this.loginService.notLoggedIn.subscribe(() => { 
			this.notLoggedIn(); 
		});

		this.loginService.loggedIn.subscribe(() => { 
			this.loggedIn(); 
		});

		if (!this.storageService.getParams().SID) {
			this.loginService.isLoggedIn();
		} else {
			this.ready();
		}
	}

	/*
	 * On Successfull Log in
	*/
	successFullLogin() {
		this.loginService.isLoggedIn();
	}

	changeLang(langID, companyID) {
		return this.loginService.getLocalization({langID:langID}, companyID);
	}

	/*
	 * Not logged in, go to login page
	*/
	notLoggedIn() {
		this.ready();
	}

	/*
	 * The user is logged in
	*/
	loggedIn() {
		this.stateService.setLoggedIn(true);
		this.ready();
	}

	/*f
	 * The initialization is done and ready for action
	*/
	ready() {
		var session = this.stateService.getSession();
		if (session && session.changePassword
			&& this.permissionService.hasPermission('self.changePassword')
			&& this.permissionService.hasPermission('pages.changepass')) {
				this.navService.locked = true;
				if (this.navService.page !== 'changepass') {
					this.navService.lockLocation = 'changepass';
					this.navService.goto('changepass');
				}
		}
		this.loaded = true;
		this.navService.loaded = true;
		this.lockedAndLoaded.next();
	}
}