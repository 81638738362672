<!-- Edit Schedule -->
<lio-loading-spinner [hidden]="!loading"></lio-loading-spinner>
<section [hidden]="loading" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
	<mat-form-field [appearance]="appearance" class="w-100">
		<!-- LABEL -->
		<mat-label>{{localeStrings.search}}</mat-label>
		<input id="am_eventList_search" matInput type="text" [placeholder]="localeStrings.searchPlaceholder" [(ngModel)]="search"/>
		<mat-icon matSuffix>search</mat-icon>
	</mat-form-field>
	<lio-card bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
		<div lio-card-body 
			lio-paginator 
			controlID="eventSchedule" 
			[filters]="search" 
			[collection]="events" 
			[settings]="pagination" 
			(onResults)="updateFilteredEvents($event)">

			<lio-results-table
				paginatorID="eventSchedule"
				[fields]="settings.fields"
				[results]="filteredEvents"
				[prefixButtons]="prefixButtons"
				[suffixButtons]="suffixButtons"
				[fieldConfig]="settings.fieldConfig">
			</lio-results-table>
		</div>
		<div lio-card-footer>
			<lio-paginator-controls class="row" controlID="eventSchedule"></lio-paginator-controls>
		</div>
	</lio-card>
</section>

