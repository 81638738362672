import { Directive, Inject, Input, Output, EventEmitter, DoCheck } from '@angular/core';

import { localizationService } from '../../../../services/localization.service';
import { paginationRegistryService } from '../../../../services/pagination-registry.service';
import { workerService } from '../../../../services/worker.service';
import { utilService } from '../../../../services/util.service';
import { debugService } from '../../../../services/debug.service';

/**
 * Directive that paginates an array of objects
 * @param {string} controlID The string ID that identifies this paginator, controls with matching ids will link to this paginator
 * @param {array} collection The array of objects we are going to paginate
 * @param {array | object | string} filters A list of filters to be applied to the data, can be a function, an object with field keys and search values, or a string search value
 * 		Any of the above may be nested into an array
 * @param {object} settings An object containing settings for the paginator
 * 		@param {array} pageLimits The list of options for how long a page can be
 * 		@param {int} pageLimit The starting page length, this number should exist in the pageLimits list as well
 * 		@param {bool} filterModeStrict Whether key/value type filters need to be matched completely or just need to be contained in the target field, @default false
 * 		@param {object} sortMode Specification for how the collection should be sorted. If no sort mode is specified, collection will not be sorted
 * 			@param {string} field Which field to sort based on
 * 			@param {string} direction {'asc' or 'desc'} Whether to sort in ascending or descending order
 * @param {function} pushFunction The function to push the results of the pagination to. This is how our results are delivered wherever they need to go.
 */
@Directive({
	selector: '[lio-paginator]',
})
export class LIOPaginator implements DoCheck {
	private _controlID = 'default-paginator-id';
	get controlID(){
		return this._controlID;
	}
	@Input() set controlID(value:any){
		this._controlID = value;
		//registers with pagination registry service so the controller can find it
		this.paginationRegistryService.registerPaginator(this.controlID, this);
	}
	
	@Input() collection		:Array<any> = [];
	@Input() filters		:any 		= {};
	@Input() settings		:any 		= {};
	/**
	 * Switches from a deep watch to a shallow watch when set
	 * Used for large collections
	 * WARNING: If enabled, changes to individual items in the collection will not trigger sorting
	 * - Only use this option when you do not expect the individual items in the collection to change
	*/
	@Input() shallowWatch	:boolean 	= true;

	@Output() onResults		:EventEmitter<any> = new EventEmitter();

	private initializing:boolean 			= true;
	private sortedCollection:Array<any>		= [];
	private pageRange:any					= {start : 0, end : 0};
	private sorting:boolean 				= false;
	private filtering:boolean 				= false;
	private needsReSort:boolean				= false;
	private needsReFilter:boolean			= false;
	private currentPage:number 				= 0;
	public filteredCollection:Array<any> 	= [];

	constructor(
		@Inject(localizationService) 		public localizationService 			:localizationService,
		@Inject(paginationRegistryService) 	public paginationRegistryService 	:paginationRegistryService,
		@Inject(workerService) 				public workerService 				:workerService,
		@Inject(utilService) 				public utilService 					:utilService,
		@Inject(debugService) 				public debugService 				:debugService
	){
		this.debugService.register('paginator', this);

		if (!this.settings) {
			this.settings = {};
		}

		if(!this.settings.pageLimits){
			this.settings.pageLimits = [10, 50, 100];
		}

		if(!this.settings.pageLimit){
			this.settings.pageLimit = 10;
		}

		if(!this.settings.sortMode){
			this.settings.sortMode = {direction : 'asc'};
		}

		if(typeof this.settings.filterModeStrict == 'undefined'){
			this.settings.filterModeStrict = false;
		}

		setTimeout(() => {
			this.initializing = false;
			this.refresh();
		});
	}

	private _oldCollection:Array<any> 		= [];
	private _oldCollectionRef:Array<any> 	= [];
	private _oldFiltersHash:any 			= null;
	private _oldSettings:any 				= {};
	ngDoCheck() {
		let collectionChanged 			= false;
		let collectionElementChanged 	= false;
		let filtersChanged 				= false;

		if (!this.collection) {
			return;
		}

		// Detect collection changes
		if(this.collection != this._oldCollectionRef){
			collectionChanged = true;
		}else if(this.collection.length != this._oldCollection.length){
			collectionChanged = true;
		}else if(!this.shallowWatch){
			if(!this.utilService.deepObjectEquals(this.collection, this._oldCollection)){
				this._oldCollection 		= this.utilService.deepCopy(this.collection);
				collectionElementChanged 	= true;
			}
		}

		//one or more items changed, but the reference did not, do a resort, but try to keep the same page
		if(collectionElementChanged && !collectionChanged){
			this.reSort(this.currentPage);
		}

		//entire reference changed, do a full refresh
		if(collectionChanged){
			//collection changed
			this._oldCollectionRef 	= this.collection;
			if(this.shallowWatch){
				this._oldCollection	= this.collection.slice(0);
			}else{
				this._oldCollection	= this.utilService.deepCopy(this.collection);
			}
			this.reSort();
		}
		//END Detect collection changes

		// Detect settings changes
		if(!this.utilService.deepObjectEquals(this.settings, this._oldSettings)){
			//settings changed
			this._oldSettings = this.utilService.deepCopy(this.settings);
			this.settingsChanged();
		}
		//END Detect settings changes

		// Detect filters changes
		if(this.filters || this.filters === '') {
			let filtersHash = this.utilService.hash(this.filters);
			if (filtersHash !== this._oldFiltersHash) {
				filtersChanged = true;
			}
		}

		if (filtersChanged) {
			this._oldFiltersHash = this.utilService.hash(this.filters);
			if (!this.initializing) {
				this.pageFirst();
				this.applyFilters();
			}
		}
		//END Detect filters changes
	}

	settingsChanged(){		
		if(!this.initializing){
			this.refresh();
		}
	}

	/**
	 * Performs a full recalculation of the pagination
	 */
	refresh(){
		if(this.collection){
			this.pageFirst();
			this.reSort();
		}
	}

	/**
	 * Re-sorts and filters the collection, but maintains the current page
	 * targetPage - the page to attempt to go to
	 */
	reSort(targetPage:number = null){
		if(this.sorting){
			this.needsReSort = true;
		}else if(this.collection.length){
			this.sorting = true;

			let workerConfig = {
				collection 	: this.collection,
				sortMode 	: this.settings.sortMode
			};

			this.workerService.call('sortCollection', workerConfig, 'PaginationSorter').then((result:Array<any>) => {
				this.sorting = false;
				this.sortedCollection = result;

				if(this.needsReSort){
					this.needsReSort = false;
					this.reSort(targetPage);
				} else {
					this.applyFilters(targetPage);
				}
			});
		} else if (!this.collection.length) {
			this.filteredCollection = [];
			this.sortedCollection = [];
			this.getPageRange(targetPage);
		}
	}

	/**
	 * Gets the current range of pages that should be displayed and sends it to the push function
	 * targetPage - the page to attempt to go to
	 */
	getPageRange(targetPage:number = null){
		if(targetPage != null){
			this.currentPage = targetPage;
		}

		this.currentPage 		= Math.max(this.currentPage, 0);
		this.currentPage 		= Math.min(this.currentPage, this.getMaxPage());

		let pageLimit 			= parseInt(this.settings.pageLimit);

		this.pageRange.start	= (this.currentPage * pageLimit);
		this.pageRange.end 		= ((this.currentPage * pageLimit) + pageLimit) - 1;

		this.pageRange.start 	= Math.max(0, this.pageRange.start);
		this.pageRange.end 		= Math.max(0, this.pageRange.end);

		this.pageRange.start 	= Math.min(this.pageRange.start	, this.filteredCollection.length - 1);
		this.pageRange.end 		= Math.min(this.pageRange.end		, this.filteredCollection.length - 1);

		/*'
		Send back an object that has the following information
		filtered': The filtered results within the limits defined from the page range
		'collection': The entire filtered results
		'pageRange': The page range configuration
		*/
		this.onResults.emit({
			'filtered'		: this.filteredCollection.slice(this.pageRange.start, this.pageRange.end + 1),
			'collection'	: this.filteredCollection,
			'pageRange'		: this.pageRange
		});
	}

	/**
	 * Applies the filters and sends them to the paging function
	 * targetPage - the page to attempt to go to
	 */
	applyFilters(targetPage:number = null){
		if (!this.sortedCollection) {
			return;
		}
		if(this.filtering){
			this.needsReFilter = true;
		} else if(this.sortedCollection.length && this.filters){
			this.filtering = true;

			/**
			 * Retrieve any filter functions that have been asked for and send them to the worker as blobs
			 */
			let filters = this.utilService.copy(this.filters);

			let workerConfig = {
				collection 	: this.sortedCollection,
				filters 	: filters,
				strict 		: this.settings.filterModeStrict
			};

			this.workerService.call('filterCollection', workerConfig, 'PaginationSorter').then((result:Array<any>) => {
				this.filtering 			= false;
				this.filteredCollection	= result;
				
				if(this.needsReFilter){
					this.needsReFilter = false;
					this.applyFilters(targetPage);
				} else {
					this.getPageRange(targetPage);
				}
			});
		} else {
			this.filteredCollection = this.sortedCollection;
			this.getPageRange(targetPage);
		}			
	}

	/**
	 * Sets the current page, value is constrained to the possible pages
	 */
	pageSet(newPage){
		this.currentPage = newPage;
		this.getPageRange();
	}

	/**
	 * Sets the page to 0
	 */
	pageFirst(){
		this.pageSet(0);
	}

	/**
	 * Sets the page to the previous one
	 */
	pagePrev(){
		this.pageSet(this.currentPage - 1);
	}

	/**
	 * Sets the page to the next one
	 */
	pageNext(){
		this.pageSet(this.currentPage + 1);
	}

	/**
	 * Sets the page to the last one
	 */
	pageLast(){
		this.pageSet(this.getMaxPage());
	}

	/**
	 * Gets the highest possible page
	 */
	getMaxPage(){
		let max = Math.ceil(this.filteredCollection.length / parseInt(this.settings.pageLimit)) - 1;
		return Math.max(0, max);
	}
}