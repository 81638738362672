import { AngularMaterialModule } from './../../imports/angular-material';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LioDirectivesModule } from './../../directives/directives.module';
import { LioDataDisplayModule } from './../../modules/data-display/data-display.module';
import { LioFormsModule } from './../../modules/lio-forms/lio-forms.module';
import { LioStructuralModule } from './../../modules/structural/structural.module';
import { LioPipesModule } from './../../pipes/pipes.module';

import { LioAssignCompanies } from './components/assign-companies/assign-companies.component';
export { LioAssignCompanies } from './components/assign-companies/assign-companies.component';

import { LioAssignRoles } from './components/assign-roles/assign-roles.component';
export { LioAssignRoles } from './components/assign-roles/assign-roles.component';

import { LioModifyRoles } from './components/modify-roles/modify-roles.component';
export { LioModifyRoles } from './components/modify-roles/modify-roles.component';

@NgModule({
	exports: [
		LioAssignCompanies,
		LioAssignRoles,
		LioModifyRoles
	],
	declarations: [
		LioAssignCompanies,
		LioAssignRoles,
		LioModifyRoles
	],
	bootstrap: [
		LioAssignCompanies,
		LioAssignRoles,
		LioModifyRoles
	],
	imports: [
		FormsModule,
		AngularMaterialModule,
		FontAwesomeModule,
		CommonModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioFormsModule,
		LioStructuralModule,
		LioPipesModule
	]
})
export class LioPermissionsModule {}