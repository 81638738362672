import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultModalComponent } from '.././default/default.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['../default/default.component.css',  './loading.component.css']
})

export class LoadingComponent extends DefaultModalComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();
	private _intervalID				= null;
	public  override updatedCount			= 0;
	private lastCount				= 0;
	private haltCount				= 0;

	public override settings : any = {
		'id' : 'am_modal_loading',
		'title': 'Loading',
		'titleTrans': 'modal.loading',
		'cancel': 'Cancel',
		'cancelTrans': 'message.cancel',
		'canCancel': false,
		'maxWaitTime': 15,
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign({}, this.settings, parentSettings);
	}

	override ngOnInit(): void {
		if (this.parentSettings && this.parentSettings.title) {
			this.settings.title = this.parentSettings.title;
		}
		this._intervalID = setInterval(() => {
			this.checkIfHalted(); 
		}, 1000);

		super.ngOnInit();
	}

	/*
	 Cancel Request
	*/
	cancel(): void {
		this.closed_emitter.emit('CANCELLED');
	}


	/*
	 * Check if it appears the system is halted
	 * - The modals' settings are constantly updated when something is happening
	 * - If nothing appears to be happening, allow the cancel button on the modal  
	*/
	checkIfHalted() {
		if (this.updatedCount === this.lastCount) {
			this.haltCount++;
		} else {
			this.haltCount = 0;
		}

		// x second max waiting period
		if (this.haltCount > this.settings['maxWaitTime']) {
			this.settings['canCancel'] = true;
			return;
		}

		this.lastCount = this.updatedCount;
	}


	ngOnDestroy() {
		if (this._intervalID) {
			clearInterval(this._intervalID);
		}
	}
	
}
