<div class="row">
	<div class="mx-auto col-md-8">
		<!-- Email Form -->
		<lio-card headerText="Error Log" [showClose]="true">
			<div lio-card-body>
				<form role="form" autocomplete="off" novalidate>
					<!-- Repeat Fields -->
					<lio-form-inputs
						[fields]="fields"
						[model]="errorLog">
					</lio-form-inputs>
				</form>
			</div>
			<div lio-card-footer>
				<div class="btn-toolbar">
					<mat-form-field [appearance]="appearance" class="mb-2 mr-2">
						<mat-label>Enter errorID</mat-label>
						<input id="errorID" 
							matInput 
							type="text" 
							name="errorID"
							[(ngModel)]="errorID"/>
					</mat-form-field>

					<button mat-flat-button
						color="primary"
						[disabled]="!errorID" 
						(click)="submit()" 
						class="mt-1 mb-3 mr-2" 
						type="submit">
						
						<span>Get Error</span>
					</button>
					<button mat-flat-button
						color="primary" 
						[disabled]="!errorID" 
						(click)="delete()" 
						class="mt-1 mb-3 mr-2" 
						type="submit">
						
						<span>Delete Error</span>
					</button>
				</div>
			</div>
		</lio-card>
	</div>
</div>