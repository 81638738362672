<div class="row">
	<lio-panel-tabber #tabCache class="col-sm-12 col-md-10 mx-auto" (onTabChange)="onTabChange()">
		<!--  -->
		<lio-panel-tab [settings]="delegatePanelConfig">
			<ng-container *lioPanelTabContent>
				<lio-search-employees
					[fields]="fields"
					[fieldConfig]="fieldConfig"
					[settings]="settings"
					(onResults)="recieveResults($event)">
				</lio-search-employees>

				<div class="mx-n1 mt-2">
					<div lio-paginator class="mx-n3" 
						[controlID]="delegatePanelConfig.pagination.id" 
						[collection]="employees" 
						[settings]="delegatePanelConfig.pagination" 
						(onResults)="updateFilteredEmployees($event)">

						<lio-results-table
							[paginatorID]="delegatePanelConfig.pagination.id"
							[fields]="fields"
							[results]="filteredEmployees"
							[fieldConfig]="fieldConfig"
							[prefixButtons]="searchPrefixButtons">
						</lio-results-table>
					</div>
				</div>
			</ng-container>
		</lio-panel-tab>
		<lio-panel-tab [settings]="existingdelegationsPanelConfig">
			<ng-container *lioPanelTabContent>
				<div lio-paginator 
					[controlID]="existingdelegationsPanelConfig.pagination.id" 
					[collection]="existingDelegations" 
					[settings]="existingdelegationsPanelConfig.pagination" 
					(onResults)="updateFilteredDelegations($event)">

					<lio-results-table
						[paginatorID]="existingdelegationsPanelConfig.pagination.id"
						[fields]="fields"
						[results]="filteredDelegations"
						[fieldConfig]="fieldConfig"
						[prefixButtons]="existingDelegationsPrefixButtons">
					</lio-results-table>
				</div>
			</ng-container>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>