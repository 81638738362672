<div class="row">
	<div class="col-12">	
		<form role="form" autocomplete="off" novalidate>
			<div class="list-group">
				<mat-accordion [multi]="true">
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title class="font-weight-bold">Login Information</mat-panel-title>
						</mat-expansion-panel-header>
						<lio-form-inputs
							[fields]="loginFields"
							[fieldConfig]="loginFieldConfig"
							[model]="employee">
						</lio-form-inputs>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</form>
	</div>
</div>
<div class="row mt-2">
	<div class="col-12">
		<lio-result-filters
			[searchConfig]="searchConfig"
			[search]="search"
			[searchFields]="searches">
		</lio-result-filters>

		<div lio-paginator 
			controlID="loginHistory" 
			[collection]="loginHistory"
			[filters]="search"
			[settings]="loginHistoryPagination" 
			(onResults)="updateLoginHistory($event)">
			<lio-results-table
				paginatorID="loginHistory"
				[fields]="loginHistoryFields"
				[results]="filteredLoginHistory">
			</lio-results-table>
		</div>
		<lio-paginator-controls controlID="loginHistory"></lio-paginator-controls>
	</div>
</div>