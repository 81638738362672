import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { processingService } from '../../services/processing.service';
import { permissionService } from '../../services/permissions.service';
import { lioModalService } from '../../services/lio-modal.service';
import { fieldService } from '../../services/fields.service';
import { feedbackService } from '../../services/feedback.service';
import { lioLogService } from '../../services/lio-log.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { utilService } from '../../services/util.service';

import { permissionsSettings } from './edit-permissions.settings';
import { configSettings } from '../../settings/config.settings';

@Component({
	selector: 'lio-edit-permissions',
	templateUrl: './edit-permissions.component.html'
})
export class EditPermissionsComponent implements OnDestroy {
	public allCompanies					:Array<any>		= [];
	public allRoles						:Array<any>		= this.permissionService.getRoleAuthorities('EDIT');
	public targetCompany				:any 			= {id:-1};
	public targetRole					:any 			= {id:-1};
	public employees					:Array<any>		= [];
	public unmodifiedEmployees			:Array<any> 	= [];
	public fields						:Array<any>		= this.permissionsSettings.fields;
	public assignCompaniesPagination	:any 			= this.permissionsSettings.assignCompaniesPagination;
	public assignRolesPagination		:any 			= this.permissionsSettings.assignRolesPagination;
	public modifyRolesPanelConfig		:any 			= this.permissionsSettings.panelTabConfigs.modifyRoles;
	public queryPanelPanelConfig		:any 			= this.permissionsSettings.panelTabConfigs.queryPanel;
	public assignCompaniesPanelConfig	:any			= this.permissionsSettings.panelTabConfigs.assignCompanies;
	public assignRolesPanelConfig		:any 			= this.permissionsSettings.panelTabConfigs.assignRoles;
	public queryToolSettings			:any 			= this.permissionsSettings.queryToolSettings;
	public queryTool					:any			= {};
	public filters						:Array<any> 	= this.permissionsSettings.filters;
	public showTitle					:boolean		= true;
	public searches						:Array<any> 	= [];

	public localeStrings				:any 			= {
		subheader		:'Enter fields below to select the employees you would like to view',
		subheaderTrans	:'dashboard.subheader'
	};
	public localeStringsKeys			:Array<any> 	= [
		'subheader'
	];

	private showQueryBuilder			:boolean		= true;
	private subscriptions				:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService			:navService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(processingService)		public	processingService	:processingService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(fieldService)			public	fieldService		:fieldService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(lioLogService)			public	lioLogService		:lioLogService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(errorsService)			public	errorsService		:errorsService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(permissionsSettings)	public	permissionsSettings	:permissionsSettings,
		@Inject(configSettings)			public	configSettings		:configSettings
	){
		this.debugService.register('editPermissions', this);
		this.navService.setActivePage('editPermissions');

		this.processingService.allowCancel = true;

		this.subscriptions.add(
			this.errorsService.timeout.subscribe(() => {
				this.reset();
				this.showQueryBuilder = true;
				this.lioModalService.show('systemTookTooLong');
			})
		);
	
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.navService.displayPage();
			})
		);
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.allRoles = this.permissionService.getRoleAuthorities('EDIT');
				this.pageInit();
			})
		);

		this.subscriptions.add(
			this.stateService.allowedCompanies.subscribe((companies) => {
				this.allCompanies = companies;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}	 
 
	pageInit(){
		this.reset();
		this.navService.displayPage();
	}

	/*
	* On tab change
	*/
	onTabChange() {
		this.feedbackService.clearAll();
	}

	/*
	* Resets vars on submit
	*/
	reset() {
		this.feedbackService.clearAll();
		this.showQueryBuilder		= true;
		this.employees 			= [];
	}

	/** Goes back to home and clears the current filters
 	*/
	gobackAndClear() {
		this.navService.goback();
	}

	/** On submit of the edit query buttomn
 	*/
	editQuery() {
		this.navService.scrollToTop();
	}

	/*
	* Preps the already loaded employees
	*/
	loadEmployees(results){
		this.lioLogService.log(['results', results]);
		if (!results) {
			this.employees 		= [];
			return;
		}

		let tempEmployeeRoles 		= results.properties.employees['roles'] 	|| [];
		let tempEmployeeCompanies 	= results.properties.employees['companies'] || [];
		let tempEmployees			= results.properties.employees['employees'] || [];
		let employees 				= [];

		tempEmployees.forEach((employee) => {
			employees[employee.UID] = {
				UID 		: employee.UID, 
				employeeID	: employee.employeeID, 
				email 		: employee.email, 
				firstName 	: employee.firstName, 
				lastName 	: employee.lastName, 
				roles 		: [],
				companies 	: []
			};
		});

		tempEmployeeRoles.forEach((employeeRole) => {
			employees[employeeRole.UID]['roles'].push(employeeRole.roleID);
		});

		tempEmployeeCompanies.forEach((employeeCompany) => {
			employees[employeeCompany.UID]['companies'].push(employeeCompany.companyID);
		});
		
		this.employees 				= Object.values(employees);
		this.unmodifiedEmployees	= this.utilService.copy(this.employees);
		this.lioModalService.hideLoading();
	}

	/*
	* Toggles the display of filters
	*/
	toggleQueryBuilder() {
		this.showQueryBuilder = !this.showQueryBuilder;
	}
}