import { Component, EventEmitter, Input, Output, Inject, OnDestroy, DoCheck } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, NEVER, Subject, debounceTime } from 'rxjs';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService }	from '../../../../services/localization.service';
import { debugService } 		from '../../../../services/debug.service';
import { fieldService } 		from '../../../../services/fields.service';
import { permissionService } 	from '../../../../services/permissions.service';

import { 
	LioField, 
	LioDateField, 
	LioImageField, 
	LioSearchableSelectField 
} from '../../lio-forms.models';

@Component({
	selector	: 'lio-field-input',
	templateUrl	: './field-input.component.html',
	styleUrls	: ['./field-input.component.css']
})
export class LioFieldInput implements OnDestroy,DoCheck {
	private keyUpSubject: Subject<LioField> 	= new Subject();
	private subscriptions:Subscription 	= NEVER.subscribe();
	public formControl			:FormControl 			= new FormControl();
	public fieldType			:string					= 'standard';
	public inputType			:string					= 'text';
	public searchable			:boolean				= false;
	public showHint				:boolean				= false;
	public showLabel			:boolean				= true;
	public formFieldClass		:string					= '';
	public search				:string					= '';
	public matFormField			:boolean				= false;
	public noOptions 			:any 					= {
		name		:'No Options Found', 
		nameTrans	:'form.noOptions', 
		value		:''
	};

	@Input() builderCtrl?		:any = {};
	@Input() formdata?			:any;
	@Input() appearance			:MatFormFieldAppearance = 'fill';
	@Output() onInputChanged	:EventEmitter<any> = new EventEmitter();
	@Output() onSelectSearch	:EventEmitter<any> = new EventEmitter();

	private _formGroup	:FormGroup;
	get formGroup()		:FormGroup{
		return this._formGroup;
	}
	@Input() set formGroup(value:FormGroup) {    
		this._formGroup = value;
		this.setFormControl();
	}

	private _field	:LioField;
	get field()		:LioField{
		return this._field;
	}
	@Input() set field(value:LioField) {    
		this._field = value;
		this.setFormControl();
		this.initializeField();
	}
	public get selectField():LioSearchableSelectField{
		return this.field as LioSearchableSelectField;
	}
	public get dateField():LioDateField{
		return this.field as LioDateField;
	}
	public get imageField():LioImageField{
		return this.field as LioImageField;
	}

	private _model :any;
	get model() {
		return this._model;
	}
	@Input() set model(value: any) {    
		this._model = value;

		setTimeout(() => {
			this.checkRangeLimits();
			this.coerceModelBoolean();
		});
	}

	public localeStrings		:any 		= {
		moreChips		:'{{number}} others selected',
		moreChipsTrans	:'form.moreChips'
	};
	public localeStringsKeys	:Array<any> = [
		'moreChips'
	];
	public localeStringsMacros	:Array<any> = [
		{
			item 	:'moreChips',
			key		:'number',
			value	:() => { 
				if (this.model[this.field.model]) {
					return Math.max(0, this.model[this.field.model].length - this.field.max);
				}
				return 0;
			}
		}
	];

	constructor(
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(fieldService)		 	public fieldService 		:fieldService,
		@Inject(debugService)	 		public debugService			:debugService,
		@Inject(permissionService)	 	public permissionService	:permissionService,
	) {
		this.debugService.register('fieldinput', this);

		this.fieldService.fieldsUpdated.subscribe(() => {
			this.initializeField();
		});
	}

	setFormControl() {
		if (this.formGroup && this.field) {
			setTimeout(() => {
				if (this.formGroup.controls[this.field.model] != this.formControl) {
					this.formGroup.removeControl(this.field.model);
				}

				if (!this.formGroup.controls[this.field.model]) {
					this.formGroup.addControl(this.field.model, this.formControl);
				}
			});
		}
	}

	ngOnDestroy() {
		if (this.formGroup && this.field) {
			this.formGroup.removeControl(this.field.model);
		}

		this.subscriptions.unsubscribe();
	}

	ngDoCheck() {
		this.field.locked = this.permissionToBoolean(this.field.locked);

		//matches form control disabled state to the field
		if (this.formControl.disabled != this.field.locked) {
			if (this.field.locked) {
				this.formControl.disable();
			}else{
				this.formControl.enable();
			}
		}
	}

	coerceModelBoolean() {
		if (this.field && this.field.type == 'boolean' && this.field.model && this.model) {
			if (this.model[this.field.model] === '0') {
				this.model[this.field.model] = false;
			}else if (this.model[this.field.model] === '1') {
				this.model[this.field.model] = true;
			}
		}
	}

	onInputUpdate(field) {
		this.onInputChanged.emit(field);
	}

	onKeyUp($event, field) {
		// Do not invoke changes on specific keys
		if ($event.code === 'Enter' || $event.code === 'Tab') {
			return;
		}
		if (field.updateOnKeyUp) {
			this.keyUpSubject.next(field);
		}
	}

	//clamps range and number models to their limits
	checkRangeLimits() {
		if ((this.fieldType == 'range' || this.inputType == 'number') && typeof this._model != 'undefined') {
			if (typeof this.field.min != 'undefined' && this._model[this.field.model] < this.field.min) {
				this._model[this.field.model] = this.field.min;
			}else if (typeof this.field.max != 'undefined' && this._model[this.field.model] > this.field.max) {
				this._model[this.field.model] = this.field.max;
			}
		}
	}

	removeChip(chip: string): void {
		const index = this._model[this.field.model].indexOf(chip);
	
		if (index >= 0) {
			this._model[this.field.model].splice(index, 1);
		}
	}

	initializeField() {
		this.initializeInput();

		if (this.field.updateOnKeyUp && !this.field.addedKeyUpSub) {
			this.field.addedKeyUpSub = true;
			this.subscriptions.add(
				this.keyUpSubject.pipe(debounceTime(this.field.debounceTime)).subscribe((field) => {
					this.onInputChanged.emit(field);
				})
			);
		}
		setTimeout(() => {
			this.coerceModelBoolean();
		})
	}

	initializeInput() {
		this.matFormField 	= false;
		this.inputType 		= null;
		this.searchable 	= false;

		if (this.field) {
			if (!this.field.subModel) {
				this.field.subModel = '';
			}

			this.fieldType = this.field.type;
			switch (this.field.type) {
				case 'catalogLanguages' 	:
				case 'catalogModules' 		:
				case 'catalogKeywords' 		:
				case 'catalogNotes' 		:
				case 'catalogTopics' 		:
				case 'catalogUpdates' 		:
				case 'checkbox' 			:
				case 'boolean' 				:
				case 'date' 				:
				case 'eventConditions' 		:
				case 'eventDays' 			:
				case 'eventFilters' 		:
				case 'range' 				:
				case 'reportFields' 		:
				case 'reportJoints' 		:
				case 'reportSettings' 		:
				case 'reportTable' 			:
				case 'reportWheres' 		:
					break;
				case 'email' 				:
					this.fieldType 		= 'standard';
					this.inputType 		= 'email';
					this.matFormField 	= true;
					break;
				case 'select' 				:
					this.matFormField 	= false;
					break;
				case 'password' 			:
					this.fieldType 		= 'standard';
					this.inputType 		= 'password';
					this.matFormField 	= true;
					break;
				case 'textarea' 			:
				case 'chips' 				:
					this.matFormField 	= true;
					break;
				case 'number'				:
					this.fieldType 		= 'standard';
					this.inputType 		= 'number';
					this.matFormField 	= true;
					break;
				case 'text'					:
				case 'default'				:
				default						:
					this.fieldType 		= 'standard';
					this.inputType 		= 'text';
					this.matFormField 	= true;
					break;
			}

			if (this.field instanceof LioSearchableSelectField) {
				let selectField = this.field as LioSearchableSelectField;
				if (selectField.searchable) {
					this.searchable = selectField.searchable;
				}
			}
		}
		this.checkRangeLimits();
	}

	permissionToBoolean(permission:boolean|string|number):boolean{
		if (typeof permission == 'undefined') {
			return false;
		}
		if (typeof permission == 'boolean') {
			return permission;
		}else if (permission === 1 || permission === '1' || permission === 'true') {
			return true;
		}else if (permission === 0 || permission === '0' || permission === 'false') {
			return false;
		}else{
			return this.permissionService.hasPermission(permission);
		}
	}
}