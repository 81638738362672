import { Component, Inject, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { fieldService } from '../../../../services/fields.service';
import { permissionService } from '../../../../services/permissions.service';

@Component({
	selector: 'lio-field-row',
	templateUrl: './field-row.component.html',
})
export class LioFieldRow implements OnInit {
	@Input() formGroup:FormGroup = new FormGroup({});
	@Input() model:any;
	@Input() field:any;
	@Input() formdata:any;
	@Input() onupdate:any;
	@Input() builderCtrl?		:any = {};
	@Input() appearance: MatFormFieldAppearance = 'fill';
	@Output() onFieldRowChanged: EventEmitter<any> = new EventEmitter();
	@Output() onFieldRowSearch		:EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(fieldService) 		public fieldService 		:fieldService,
		@Inject(permissionService) 	public permissionService 	:permissionService
	) {}


	onFieldChanged($event) {
		this.onFieldRowChanged.emit($event);
	}

	ngOnInit(){
		if (this.field) {
			if (!this.field.subModel) {
				this.field.subModel = '';
			}
		}
	}
}