import { Component, Input, Inject, ViewChild } from '@angular/core';

import { AngularEditorConfig, AngularEditorComponent } from '@kolkov/angular-editor';

import { navService } from '../../../../services/nav.service';
import { lioModalService } from '../../../../services/lio-modal.service';

import { disclosureDirectiveSettings } from './disclosure-directive.settings';

@Component({
	selector: 'lio-disclosure-text',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.css'],
})
export class LioDisclosureText{
	private _textDisplay:AngularEditorComponent = null;
	public get textDisplay():AngularEditorComponent{
		return this._textDisplay;
	}
	@ViewChild('textDisplay') public set textDisplay(val:AngularEditorComponent){
		this._textDisplay = val;
		//Fix for angular-editor bug where editor throws an error when getting focus back after model was changed externally
		if(val){
			Object.getPrototypeOf(val).focus = function(){ if (this.modeVisual) {
				this.textArea.nativeElement.focus();
			}
			else {
				const sourceText = this.doc.getElementById('sourceText' + this.id);
				if(sourceText){
					sourceText.focus();
				}
				this.focused = true;
			}}
		}
	}

	@Input() settings:any = null;

	private _model = {text:''};
	public get model():any{
		return this._model;
	}
	@Input() public set model(val:any){
		if(!val.text) {
			val.text = '';
		}

		this._model = val;
	}

	public randID		:string 				= Math.random().toString();

	public editorConfig	: AngularEditorConfig 	= this.disclosureDirectiveSettings.editorConfig;
	
	public monacoOptions = {
		theme	: 'vs-dark', 
		language: 'html'
	};

	public editAsCode:boolean = false;

	constructor(
		@Inject(lioModalService) 				private lioModalService				:lioModalService,
		@Inject(navService)						private navService					:navService,
		@Inject(disclosureDirectiveSettings)	private disclosureDirectiveSettings	:disclosureDirectiveSettings
	){}

	editModeChange(val:boolean){
		this.editAsCode = !val;
	}

	interceptLink(event){
		if(event.target.tagName == 'A') {
			let url = event.target.href;
			
			if (this.settings.openLinksInIframe) {
				this.lioModalService.open({'type': 'iframe', 'maxWidth': '100%', 'url': url});
			} else {
				let width 		= document.documentElement.clientWidth * 0.9;
				let height 		= document.documentElement.clientHeight * 0.9;
				let target 		= '_BLANK';
				let features 	= 'location=1,status=1,scrollbars=1,resizable=1,width='+width+',height='+height+',top=5';

				this.navService.open(url, target, features);
			}
			event.preventDefault();
		}
	}
}