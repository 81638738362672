import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { loaderService } from '../../services/loader.service';
import { processingService } from '../../services/processing.service';
import { storageService } from '../../services/storage.service';
import { fieldService } from '../../services/fields.service';
import { permissionService } from '../../services/permissions.service';

import { employeeSearchSettings } from './employee-search.settings';

@Component({
	selector: 'lio-employee-search',
	templateUrl: './employee-search.component.html'
})
export class EmployeeSearchComponent implements OnDestroy {
	public fields				:any 			= this.employeeSearchSettings.fields;
	public fieldConfig			:any 			= this.employeeSearchSettings.fieldConfig;
	public settings				:any 			= this.employeeSearchSettings.settings;
	public panelConfig			:any 			= this.employeeSearchSettings.panelConfig;
	public pagination			:any 			= this.employeeSearchSettings.pagination;

	public employees			:Array<any> 	= [];
	public filteredEmployees	:Array<any> 	= [];
	public resultPrefixButtons	:Array<any> 	= [
		{
			header 		: 'Edit',
			name 		: '',
			id 			: 'am_results_edit',
			color 		: 'primary',
			icon 		: 'search',
			callback 	: (employee) => { this.edit(employee); }
		}
	];

	public localeStrings		:any 			= {
		assigncourses		:'Assign Courses',
		assigncoursesTrans	:'global.assigncourses'
	};
	public localeStringsKeys	:Array<any> 	= [
		'assigncourses'
	];

	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService				:navService,
		@Inject(debugService)			public	debugService			:debugService,
		@Inject(loaderService)			public	loaderService			:loaderService,
		@Inject(processingService)		public	processingService		:processingService,
		@Inject(storageService)			public	storageService			:storageService,
		@Inject(fieldService)			public	fieldService			:fieldService,
		@Inject(permissionService)		public	permissionService		:permissionService,
		@Inject(employeeSearchSettings)	public	employeeSearchSettings	:employeeSearchSettings
	){
		this.debugService.register('employeesearch', this);
		this.navService.setActivePage('employeesearch');

		processingService.allowCancel = false;

		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.navService.displayPage();
			})
		);
	
		/*
		 * Init
		*/
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.navService.displayPage();
			})
		);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection){
		this.filteredEmployees = collection.filtered;
	}

	recieveResults(employees){
		this.employees = employees;
	}

	/*
	* On selection of an employee to edit
	*/
	edit(employee) {
		this.storageService.set('employeeToEdit', employee);
		this.storageService.set('task', 'external');
		this.navService.goto('profile');
	}

	/*
	* On Click of the Assign Courses buttone
	*/
	assignCourses() {
		var employees = this.employees;
		if (employees.length) {
			this.storageService.setEmployeesToAssign(employees);
			this.navService.goto('assign');
		}
	}
}