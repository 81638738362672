import { Component, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NEVER, Subscription } from 'rxjs';
import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { localizationService } from '../../../../services/localization.service';
import { utilService } from '../../../../services/util.service';
import { companyAdminSettings } from '../../../../components/company-admin/company-admin.settings';
import { lioModalService } from '../../../../services/lio-modal.service';
import { permissionService } from '../../../../services/permissions.service';
import { companyService } from '../../../../services/company.service';

@Component({
  selector: 'lio-company-admin-search',
  templateUrl: './company-admin-search.component.html'
})
export class CompanyAdminSearchComponent implements OnDestroy {
	@Output() callback:EventEmitter<any> = new EventEmitter();
	@Output() onLoaded:EventEmitter<any> = new EventEmitter();

	public appearance:MatFormFieldAppearance = 'outline';
	public fields:any = [];
	public loadFields:any = false;
	public pagination:any = {};
	public search:string = '';
	public companies:any = [];
	public subCompanies:any = [];
	public companyIDs:any = [];
	public filteredCompanies:any = [];
	public fieldConfig:any = {};
	public searchPanelConfig:any = {};
	public localeStrings:any = {};
	public localeStringsKeys:any = [];
	public suffixButtons:Array<any> = [];
	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(navService) public navService:navService,
		@Inject(debugService) public debugService:debugService,
		@Inject(companyService) public companyService:companyService,
		@Inject(localizationService) public localizationService:localizationService,
		@Inject(utilService) public utilService:utilService,
		@Inject(companyAdminSettings) public companyAdminSettings:companyAdminSettings,
		@Inject(lioModalService) public lioModalService:lioModalService,
		@Inject(permissionService) public permissionService:permissionService,
	){
		this.debugService.register('companyadminsearch', this);

		this.loadFields = this.utilService.copy(this.companyAdminSettings.loadFields);
		this.pagination = this.companyAdminSettings.pagination;
		this.filteredCompanies = [];
		this.fieldConfig = this.companyAdminSettings.fieldConfig;
		this.suffixButtons = this.companyAdminSettings.searchFieldSuffixButtons;

		this.searchPanelConfig 	= this.utilService.copy(this.companyAdminSettings.panelConfig.search);
		this.subscriptions = NEVER.subscribe();
		this.localeStrings = {
			active    :'form.activeStatus',
			inactive  :'form.inactiveStatus',
			name      :'company.name',
			loginName :'company.loginName',
			search    :'form.search',
			companyID :'form.companyID',
			status    :'form.status',
			action    :'form.action',
			copy      :'form.copy',
			edit      :'form.edit',
			save      :'companyadmin.save',
	}

	this.localeStringsKeys = Object.keys(this.localeStrings);
		this.subscriptions.add(
			this.companyService.companies.subscribe((companies) => {
				this.companies = companies;
				this.navService.displayPage().then(() => {
					this.onLoaded.emit();
				})
			})
		);
		this.subscriptions.add(
			this.companyService.subCompanies.subscribe((subCompanies) => {
				this.subCompanies = subCompanies;
			})
		);

		this.subscriptions.add(
			this.companyService.onEvent.subscribe((event) => {
				this.onEvent(event);
			})
		);
		this.companyService.init();
	}

	/*
	 * On init.
	 */
	ngOnInit() {
		this.fields = this.permissionService.setFields(this.companyAdminSettings.searchFields);
		this.subscriptions.add(
			this.companyService.search.subscribe((search) => {
				this.search = search;
			})
		);
	}

	/**
	 * Recieve information from paginator.
	 *
	 * @param {object} paginatorResults
	 */
	updateFilteredCompanies(paginatorResults) {
		this.filteredCompanies = paginatorResults.filtered;
	}

	/*
	 * On event of the company service
	 * @param {object} event
	 */
	onEvent(event) {
		this.callback.emit({'switchTab': event});
	}

	/*
	 * On destroy.
	 */
	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}
