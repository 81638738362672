import { Injectable } from '@angular/core';

import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: auditLogSettings
})
export class auditLogSettings {
	pagination:any = {
		pageLimit	: '10',
		pageLimits	: ['10', '50', '100'],
		sortMode	: {field : 'creationDate', direction : 'desc'}
	};


	fieldConfig:any = {
		fieldName		: 'auditlogsearch',
		addCCFtoFields	: false,
		localizeFields	: false,
		permissionFields: true,
		addRoleToFields	: false,
		overrideFields	: false,
	};


	searchFieldConfig:any = {
		fieldName		: 'auditlogsearch',
		addCCFtoFields	: false,
		localizeFields	: false,
		permissionFields: true,
		addRoleToFields	: false,
		overrideFields	: false,
	};

	searchFields:LioFieldArray = new LioFieldArray(
		{
			name	: 'Company ID',
			model	: 'companyID',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Action',
			model	: 'action',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Total Affected',
			model	: 'affectedRows',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Query Contains',
			model	: 'sqlQuery',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Creation Date',
			model	: 'creationDate',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Created By',
			model	: 'createdBy',
			type	: 'text',
			visible	: true,
		}
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			name	: 'Company ID',
			model	: 'companyID',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Action',
			model	: 'action',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'QUERY',
			model	: 'sqlQuery',
			type	: 'textarea',
			sortable: true
		},
		{
			name	: 'Total Users Affected',
			model	: 'affectedRows',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Creation Date',
			model	: 'creationDate',
			type	: 'text',
			sortable: true
		},
		{
			name	: 'Created By',
			model	: 'createdBy',
			type	: 'text',
			sortable: true
		}
	);
}