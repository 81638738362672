export class LioSelectOption{
	//Name of the option
	public name		:string;
	//Translation tag for the option name
	public nameTrans:string;
	//value of the option
	public value	:any;
	//whether a value field displayed from this option should allow multiple values
	public multiple	:boolean;
	//whether to delete the value in a value field displayed from this option
	public setNull	:boolean;

	//whether to show the standard input and not the date input
	public noDate	:boolean;

	constructor(obj:Partial<LioSelectOption>){
		Object.assign(this, obj);
	}
}