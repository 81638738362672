/*
 * Service for Localization
*/
import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lioLogService } from './lio-log.service';
import { permissionService } from './permissions.service';
import { storageService } from './storage.service';
import { utilService } from './util.service';
import { debugService } from './debug.service';
import { localizationSettings } from '../components/localization/localization.settings';
import { querySettings } from '../settings/query.settings';

import { LioSearchableSelectField, FilterOptionField } from '../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn: 'root',
})
export class localizationService {
	dateFormat 				: any			= 'MM/dd/yyyy';
	rtl 					: boolean		= false;
	usedLangs 				: Array<any>	= [];
	localizations 			: Array<any>	= [];
	localizationSet 		: boolean 		= false;
	localizableItems		: Array<any>;
	datepickerLangMap 		: any;
	requests				: Array<any>	= [];
	suppressContext 		: boolean		= true;
	logMissingLocalizations : boolean		= false;
	availableLanguages		: Array<any>	= [];
	shouldShowLanguageModal : boolean		= false;
	selectedLang 			: any			= {};
	localizing 				: boolean		= false;
	localized				:ReplaySubject<void> = new ReplaySubject(1);

	constructor(
		@Inject(lioLogService)			private lioLogService 			:lioLogService,
		@Inject(permissionService)		private permissionService		:permissionService,
		@Inject(storageService)			private storageService			:storageService,
		@Inject(utilService)			private utilService				:utilService,
		@Inject(debugService)			private debugService 			:debugService, 
		@Inject(localizationSettings)	private localizationSettings	:localizationSettings,
		@Inject(querySettings)			private querySettings			:querySettings,
	){
		this.debugService.register('localizationService', this);
		this.localizableItems = this.utilService.copy(this.localizationSettings.localizableItems);
		this.datepickerLangMap = this.utilService.copy(this.localizationSettings.datepickerLangMap);
	}

	/*
	 * Get the lang ID from the session with backup methods
	 * @param {object} session 
	 * @return {string} 
	*/
	getLangIDFromSession(session) {
		let langID = this.determineLangID(session),
			matchedLangID = this.getMatchingLanguage(langID);

		// match the language to the available langs
		if (langID !== matchedLangID) {
			this.lioLogService.log(['Matched LangID', langID, matchedLangID]);
		}
		return matchedLangID;
	}


	/*
	 * Determines the lang ID in order of importance
	 * @param {object} session 
	 * @return {string} 
	*/
	determineLangID(session) {
		let storedLangID 	= this.storageService.getLangID(),
			browserLangID 	= this.getBrowserLangID(),
			sessionLangID	= session.langID,
			defaultLang 	= 'en';

		if (sessionLangID) {
			this.lioLogService.log(['Got LangID From Session', sessionLangID]);
			return sessionLangID;
		}

		if (storedLangID) {
			this.lioLogService.log(['Got LangID From Storage', storedLangID]);
			return storedLangID;
		}

		if (browserLangID) {
			this.lioLogService.log(['Got LangID From Browser', browserLangID]);
			return browserLangID;
		}

		return defaultLang; 
	}


	/*
	 * Sets the localizations
	*/
	setLocalizations(langID, result) {
		let localizations = result.properties.localizations,
			availableLanguages = result.properties.availableLanguages;

		this.set(localizations);
		this.setAvailableLanguages(availableLanguages);
		this.setCurrentLang(langID);
	}


	/*
	 * Adds an item to localize
	 * @param {string} field
	 * @param {string} defaultText 
	*/
	addItemToLocalize(field, defaultText) {
		let exists = this.localizableItems.find((item) => {
			return item.field == field;
		});

		if (!exists){
			this.localizableItems.push({'field': field, 'default': defaultText});
		}
	}

	/*
	 * Is the current Lang RTL?
	 * @return {boolean}
	*/
	isRTL() {
		return this.rtl;
	}

	/*
	 * Get the lang ID
	 * @return {string} 
	*/
	getLangID() {
		return this.selectedLang.code;
	}


	/*
	 * Gets the list of all langauges for forms
	 * @param {?string} langID
	 * @param {?object} configuration
	 * @return {array}
	*/
	getLangField(langID, configuration) {
		let config 			= configuration && configuration['langField'] 	 ? configuration['langField'] : {},
			locked 			= this.utilService.isDefined(config['locked']) 	 ? config['locked'] : 'employee.editLocalization',
			required 		= this.utilService.isDefined(config['required']) ? config['required'] : undefined,
			selectedLangID 	= langID || this.getLangID(),
			field:LioSearchableSelectField = new LioSearchableSelectField({
				name					: 'Language',
				nameTrans				: 'form.langID',
				model					: 'langID',
				type					: 'select',
				required				: required,
				locked					: locked,
				options					: this.getLangOptions(selectedLangID),
				disableOptionOverride	: true,
				visible					: true
			});
			
		return field;
	}

	/*
	 * Gets language filter
	 * @param {?string} langID
	 * @return {array}
	*/
	getLangFilter() {
		return new FilterOptionField({
			name 				: 'Language',
			nameTrans 			: 'form.langID',
			field 				: 'e.langID',
			model 				: 'e.langID',
			type 				: 'select',
			visible				: true,
			options 			: this.getLangFilterValues(),
			clearable 			: true,
			operators 			: [
				this.querySettings.operatorOptions.equal, 
				this.querySettings.operatorOptions.not_equal, 
				this.querySettings.operatorOptions.in, 
				this.querySettings.operatorOptions.not_in
			]
		});
	}

	/*
	 * Gets language filter values
	 * @param {?string} langID
	 * @return {array}
	*/
	getLangFilterValues() {
		let langs 	= this.getLangOptions().splice(1),
			options	= [];

			langs.forEach((lang) => {
			options.push({
				'name'		: lang.name,
				'value'		: lang.value,
			}); 
		});

		return options;
	}

	/*
	 * Gets the list of all langauges for forms
	 * @param {?string} langID
	 * @return {array}
	*/
	getLangOptions(langID = null) {
		let langs = this.getAvailableLanguages().length ? this.getAvailableLanguages() : this.getLangs(),
			options = [{
				'value': '',
				'name': '',
				'selected': false
			}];

		langs.forEach((lang) => {
			let selected = false,
				name = lang.name;
			
			if (lang.code == langID) {
				selected = true;
			}

			if (lang.name != lang.lang) {
				name = lang.name  + ' - ' + lang.lang;
			}
			options.push({
				'value': lang.code,
				'name': name,
				'selected': selected,
			});
		});

		return options;
	}

	/** Gets the language code from the browser
	 * @return {string}
	 */
	getBrowserLangID(){
		let langID = null;
		if (navigator.languages) {
			langID = navigator.languages[0];
		} else if (navigator.language) {
			langID = navigator.language;
		} else if (navigator['userLanguage']) {
			langID = navigator['userLanguage'];
		}
		return langID;
	}


	/**
	 * Checks if the passed langID is in the list of input codes for one of our supported languages
	 * If so, returns the code we use for it, otherwise null
	 * @param {string} langID
	 * @return {string}
	 */
	getMatchingLanguage(langID) {
		if (typeof langID == 'string') {
			langID = langID.toLowerCase();
			langID = langID.replace(/_/g, '-');
			langID = langID.trim();
		}

		let languages = this.getLangs(),
			language = {name : '', code : null, inputCodes : []},
			i = 0,
			inputCodes = [];

		for (i = 0; i < languages.length; i++) {
			language = languages[i];
			inputCodes = language.inputCodes;
			if (inputCodes.indexOf(langID) !== -1 ||language.code == langID || language.name.toLowerCase() === langID) {
				return language.code;
			}
		}

		return 'en';
	}


	/*
	 * Translates fields
	*/
	translateFields(fields) {
		if (!this.localizationSet) {
			return fields;
		}
		let localized;
		fields.forEach((field) => {
			if (field.nameTrans) {
				localized = this.get(field.nameTrans);
			} else {
				localized = this.get('form.' + field.model);
			}
			if (localized) {
				field.name = localized;
			}
		});

		return fields;
	}

	/*
	 * Sets the current langauge
	*/
	setCurrentLang(langID) {
		let langs 		= this.getLangs(),
			currentLang = null;
		this.rtl = false;

		langs.forEach((lang) => {
			if (lang.code === langID) {
				currentLang = lang;
				if (lang.rtl) {
					this.rtl = true;
				}
				if (lang.dateFormat) {
					this.dateFormat = lang.dateFormat;
				}
			}
		});

		if (currentLang) {
			this.lioLogService.log(['Set langID To', langID]);
			this.selectedLang = currentLang;
			this.storageService.setLangID(langID);
			this.localized.next();
		} else {
			this.lioLogService.log(['Failed to match provided langID', langID]);
		}
	}

	/*
	 * Sets the available langauges
	 * @param {array} result
	*/
	setAvailableLanguages(availableLanguages) {
		if (!availableLanguages) {
			return;
		}
		let langs = this.getLangs(),
			addedLangs = [];

		this.availableLanguages = [];
		if (availableLanguages) {
			availableLanguages.push({'langID': "en"});
		}
		langs.forEach((lang) => {
			availableLanguages.forEach((availableLanguage) => {
				if (addedLangs.indexOf(availableLanguage.langID) > -1) {
					return;
				}
				if (availableLanguage.langID === lang.code) {
					this.availableLanguages.push(lang);
					addedLangs.push(availableLanguage.langID);
				}
			});
		});
		this.shouldShowLanguageModal = this.availableLanguages.length > 1;
	}

	/*
	 * Gets the currently selected language
	 * @return {array}
	*/
	getSelectedLang() {
		return this.selectedLang;
	}

	/*
	 * Gets the currently selected language
	 * @return {string}
	*/
	getSelectedLangName() {
		return this.selectedLang.name;
	}

	/*
	 * Gets the currently selected date format for export
	 * @return {string}
	*/
	getSelectedDateFormatForExport() {
		return this.selectedLang.dateFormatForExport || 'MM/DD/YYYY hh:mm:ssa';
	}

	/*
	 * Gets the currently selected language code
	 * @return {?string}
	*/
	getLangCodeForDatePicker() {
		let langID = this.getLangID();
		return this.datepickerLangMap[langID];
	}

	/*
	 * Gets the currently selected language date format
	 * @return {?string}
	*/
	getSelectedDateFormat() {
		return this.selectedLang.dateFormat || 'mm/dd/yy';
	}

	/*
	 * Gets the currently selected language localized name
	 * @return {?string}
	*/
	getSelectedLangLocalized() {
		return this.selectedLang.lang;
	}

	/*
	 * Gets the list of all langauges
	 * @return {array}
	*/
	getLangs() {
		return this.localizationSettings.langs || [];
	}

	/*
	 * Gets the available langauges
	 * @return {array}
	*/
	getAvailableLanguages() {
		return this.availableLanguages;
	}

	/*
	 * Gets the available localizeable items
	 * @return {array}
	*/
	getLocalizableItems() {
		return this.localizableItems;
	}

	/*
	 * Sets the localization
	*/
	set(localizations) {
		this.localizationSet = true;
		this.localizations = localizations;
		if (localizations) {
			this.localizations.forEach((localization) => {
				this.addItemToLocalize(localization.field, localization.text);
			});	
		}
	}

	/*
	 * Processes localization
	*/
	process() {
		this.processRequests();
	}

	/*
	 * Process localization requests
	*/
	processRequests() {
		let element,
			localized,
			field,
			defaultHTML;

		this.requests.forEach((request) => {
			element = request['element'];
			field = request['field'];
			defaultHTML = request['defaultHTML'];

			localized = this.get(field);
			if (localized) {
				element.html(localized);
			} else {
				element.html(defaultHTML);
			}
		});
	}

	/*
	 * Saves a localization request until localization is loaded
	 * @param {element} element
	 * @param {string} field
	 * @return {string}
	*/
	save(element, field) {
		let html = element.html(),
			defaultHTML = this.getDefaultByField(field);

		if (defaultHTML) {
			html = defaultHTML;
		}

		if (!this.utilService.inString('{{', html)) {
			this.requests.push({'element': element, 'field':field, 'defaultHTML': html});
		}
	}

	/*
	 * Gets the Default HTML by field
	 * @param {string} field
	 * @return {string}
	*/
	getDefaultByField(field) {
		let defaultHTML = '';

		this.localizableItems.forEach((localizableItem) => {
			if (localizableItem.field === field) {
				defaultHTML = localizableItem.default;
			}
		});
		return defaultHTML;
	}

	/*
	 * Is localization ready?
	 * @return {boolean}
	*/
	isReady() {
		return this.localizationSet;
	}

	getDateFormat() {
		return this.dateFormat;
	}

	/*
	 * Gets the localization
	 * @param {string} field
	 * @param {?string} defaultText
	 * @param {?array} macros
	 * @return {string}
	*/
	get(field, defaultText = null, macros = null) {
		let newDefaultText = this.getDefaultByField(field);

		if (!field) {
			return this.handleMacros(defaultText, macros);
		}

		if (newDefaultText) {
			defaultText = newDefaultText;
		}

		let foundField = false,
			x = 0,
			y = 0,
			totalLocalizations = this.localizations ? this.localizations.length : 0,
			totalRequests = this.requests ? this.requests.length : 0,
			localization,
			defaultHTML,
			request,
			text = '';

		// Gets the localization
		for (x = 0; x < totalLocalizations; x++) {
			if (foundField) {
				break;
			}
			localization = this.localizations[x];
			// If the field matches...
			if (localization.field === field) {
				// And there is text
				if (localization.text) {
					foundField = true;
					text = localization.text;
				}
				break;
			}
		}

		// Gets the default within the html markup if no localization exists
		if (!foundField) {
			for (y = 0; y < totalRequests; y++) {
				request = this.requests[y];
				if (field === request['field']) {
					defaultHTML = request['defaultHTML'];
					if (defaultHTML) {
						if (!this.utilService.inString('{{', defaultHTML)) {
							text = defaultHTML;
						}
					}

					foundField = true;
					break;
				}
			}
		}
		

		if (!text && defaultText) {
			text = defaultText;
		}

		if (macros) {
			text = this.handleMacros(text, macros);
		}

		return text;
	}

	/*
	 * Handles macros
	 * @param {string} text
	 * @param {array} macros
	 * @return {string}
	*/
	handleMacros(text, macros) {
		if (!this.utilService.inString('{{', text) || !macros) {
			return text;
		}

		if (macros !== null && typeof macros === 'object' && !Array.isArray(macros)) {
			macros = [macros];
		}

		macros.forEach((macro) => {
			let macroString = '{{' + macro.key + '}}';

			let value = macro.value;

			//if macro value is a function, get the return value
			if(typeof value == 'function'){
				value = value();
			}

			if (this.utilService.inString(macroString, text)) {
				text = text.replace(macroString, value, text);
			}
		});

		return text;
	}

	/*
	 * Goto a localization
	 * @param {string} field
	*/
	localize(field) {
		if(this.permissionService.hasPermission('localization.edit')) {
            this.localizing = field;
            return true;
		}
		return false;
	}

	/*
	 * Handles Context maenu
	 * @param {string} field
	*/
	handleContextMenu(field) {
		return this.localize(field);
	}
}