
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { AngularMaterialModule } from './../imports/angular-material';

import { LioPipesModule } from '../pipes/pipes.module';
import { LioFormsModule } from './../modules/lio-forms/lio-forms.module';
import { LioDataDisplayModule } from './../modules/data-display/data-display.module';
import { LioStructuralModule } from './../modules/structural/structural.module';
import { LioCatalogModule } from './../modules/catalog/catalog.module';
import { LioCatalogAdminModule } from './../modules/catalog-admin/catalog-admin.module';
import { LioCompanyAdminModule } from './../modules/company-admin/company-admin.module';
import { LioDisclosuresModule } from './../modules/disclosures/disclosures.module';
import { LioPermissionsModule } from './../modules/permissions/permissions.module';

import { LioDirectivesModule } from './../directives/directives.module';

import { AccessLogComponent } from './accesslog/accesslog.component';
export { AccessLogComponent } from './accesslog/accesslog.component';

import { AddUsersComponent } from './add-users/add-users.component';
export { AddUsersComponent } from './add-users/add-users.component';

import { BatchProcessingComponent } from './batch-processing/batch-processing.component';
export { BatchProcessingComponent } from './batch-processing/batch-processing.component';

import { AssignComponent } from './assign/assign.component';
export { AssignComponent } from './assign/assign.component';

import { AdminToolsComponent } from './admin-tools/admin-tools.component';
export { AdminToolsComponent } from './admin-tools/admin-tools.component';

import { AuditLogComponent } from './audit-log/audit-log.component';
export { AuditLogComponent } from './audit-log/audit-log.component';

import { HomeComponent } from './home/home.component';
export { HomeComponent } from './home/home.component';

import { CourseUploaderComponent } from './course-uploader/course-uploader.component';
export { CourseUploaderComponent } from './course-uploader/course-uploader.component';

import { ReportsComponent } from './reports/reports.component';
export { ReportsComponent } from './reports/reports.component';

import { ReportEditorComponent } from './reports/report-editor.component';
export { ReportEditorComponent } from './reports/report-editor.component';

import { ReportCreatorComponent } from './reports/report-creator.component';
export { ReportCreatorComponent } from './reports/report-creator.component';

import { DashboardComponent } from './dashboard/dashboard.component';
export { DashboardComponent } from './dashboard/dashboard.component';

import { EnrollmentComponent } from './enrollments/enrollments.component';
export { EnrollmentComponent } from './enrollments/enrollments.component';

import { CatalogSearchComponent } from './catalog-search/catalog-search.component';
export { CatalogSearchComponent } from './catalog-search/catalog-search.component';

import { CatalogAdminComponent } from './catalog-admin/catalog-admin.component';
export { CatalogAdminComponent } from './catalog-admin/catalog-admin.component';

import { SelfEnrollComponent } from './self-enroll/self-enroll.component';
export { SelfEnrollComponent } from './self-enroll/self-enroll.component';

import { BrandingComponent } from './branding/branding.component';
export { BrandingComponent } from './branding/branding.component';

import { UserSummaryComponent } from './user-summary/user-summary.component';
export { UserSummaryComponent } from './user-summary/user-summary.component';

import { TrainingComponent } from './training/training.component';
export { TrainingComponent } from './training/training.component';

import { SettingsComponent } from './spider-settings/settings.component';
export { SettingsComponent } from './spider-settings/settings.component';

import { ViewDisclosureComponent } from './view-disclosure/view-disclosure.component';
export { ViewDisclosureComponent } from './view-disclosure/view-disclosure.component';

import { ScheduleEnrollmentsComponent } from './schedule-enrollments/schedule-enrollments.component';
export { ScheduleEnrollmentsComponent } from './schedule-enrollments/schedule-enrollments.component';

import { ScheduleEmailsComponent } from './schedule-emails/schedule-emails.component';
export { ScheduleEmailsComponent } from './schedule-emails/schedule-emails.component';

import { ProfileComponent } from './profile/profile.component';
export { ProfileComponent } from './profile/profile.component';

import { SelfProfileComponent } from './selfprofile/selfprofile.component';
export { SelfProfileComponent } from './selfprofile/selfprofile.component';

import { LogoutComponent } from './logout/logout.component';
export { LogoutComponent } from './logout/logout.component';

import { LoginComponent } from './login/login.component';
export { LoginComponent } from './login/login.component';

import { LoggedOffComponent } from './logged-off/logged-off.component';
export { LoggedOffComponent } from './logged-off/logged-off.component';

import { RegisterComponent } from './register/register.component';
export { RegisterComponent } from './register/register.component';

import { LocalizationAdminComponent } from './localization-admin/localization-admin.component';
export { LocalizationAdminComponent } from './localization-admin/localization-admin.component';

import { LocalizationComponent } from './localization/localization.component';
export { LocalizationComponent } from './localization/localization.component';

import { GlobalSearchComponent } from './global-search/global-search.component';
export { GlobalSearchComponent } from './global-search/global-search.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
export { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { FieldsComponent } from './fields/fields.component';
export { FieldsComponent } from './fields/fields.component';

import { ExcludeUsersComponent } from './exclude-users/exclude-users.component';
export { ExcludeUsersComponent } from './exclude-users/exclude-users.component';

import { ErrorLogComponent } from './error-log/error-log.component';
export { ErrorLogComponent } from './error-log/error-log.component';

import { ErrorPageComponent } from './error-page/error-page.component';
export { ErrorPageComponent } from './error-page/error-page.component';

import { EnrollQueryComponent } from './enroll-query/enroll-query.component';
export { EnrollQueryComponent } from './enroll-query/enroll-query.component';

import { EnrollmentHistoryComponent } from './enrollment-history/enrollment-history.component';
export { EnrollmentHistoryComponent } from './enrollment-history/enrollment-history.component';

import { EmployeeSearchComponent } from './employee-search/employee-search.component';
export { EmployeeSearchComponent } from './employee-search/employee-search.component';

import { EmailHistoryComponent } from './email-history/email-history.component';
export { EmailHistoryComponent } from './email-history/email-history.component';

import { EmailAuditLogComponent } from './email-audit-log/email-audit-log.component';
export { EmailAuditLogComponent } from './email-audit-log/email-audit-log.component';

import { EmailComponent } from './email/email.component';
export { EmailComponent } from './email/email.component';

import { EditPermissionsComponent } from './edit-permissions/edit-permissions.component';
export { EditPermissionsComponent } from './edit-permissions/edit-permissions.component';

import { DocumentationComponent } from './documentation/documentation.component';
export { DocumentationComponent } from './documentation/documentation.component';

import { DelegateAccessComponent } from './delegate-access/delegate-access.component';
export { DelegateAccessComponent } from './delegate-access/delegate-access.component';

import { CompanyAdminComponent } from './company-admin/company-admin.component';
export { CompanyAdminComponent } from './company-admin/company-admin.component';

import { DisclosureManagerComponent } from './disclosure-manager/disclosure-manager.component';
export { DisclosureManagerComponent } from './disclosure-manager/disclosure-manager.component';

import { DisclosureReportsComponent } from './disclosure-reports/disclosure-reports.component';
export { DisclosureReportsComponent } from './disclosure-reports/disclosure-reports.component';

import { ChangePasswordComponent } from './change-password/change-password.component';
export { ChangePasswordComponent } from './change-password/change-password.component';

import { CCFAdminComponent } from './ccf-admin/ccf-admin.component';
export { CCFAdminComponent } from './ccf-admin/ccf-admin.component';

import { CatalogCollectionComponent } from './catalog-collection/catalog-collection.component';
export { CatalogCollectionComponent } from './catalog-collection/catalog-collection.component';

import { CourseLaunchComponent } from './course-launch/course-launch.component';
export { CourseLaunchComponent } from './course-launch/course-launch.component';

const monacoConfig: NgxMonacoEditorConfig = {
	baseUrl: './assets', // configure base path cotaining monaco-editor directory after build default: './assets'
	defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
	onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
	declarations: [
		AccessLogComponent,
		AuditLogComponent,
		AdminToolsComponent,
		AddUsersComponent,
		BatchProcessingComponent,
		AssignComponent,
		HomeComponent,
		CourseUploaderComponent,
		ReportsComponent,
		ReportEditorComponent,
		ReportCreatorComponent,
		DashboardComponent,
		EnrollmentComponent,
		CatalogAdminComponent,
		CatalogSearchComponent,
		SelfEnrollComponent,
		BrandingComponent,
		UserSummaryComponent,
		TrainingComponent,
		SettingsComponent,
		ViewDisclosureComponent,
		ScheduleEnrollmentsComponent,
		ScheduleEmailsComponent,
		ProfileComponent,
		SelfProfileComponent,
		LogoutComponent,
		LoginComponent,
		LoggedOffComponent,
		RegisterComponent,
		LocalizationAdminComponent,
		LocalizationComponent,
		GlobalSearchComponent,
		ForgotPasswordComponent,
		FieldsComponent,
		ExcludeUsersComponent,
		ErrorLogComponent,
		ErrorPageComponent,
		EnrollQueryComponent,
		EnrollmentHistoryComponent,
		EmployeeSearchComponent,
		EmailHistoryComponent,
		EmailAuditLogComponent,
		EmailComponent,
		EditPermissionsComponent,
		DocumentationComponent,
		DelegateAccessComponent,
		CompanyAdminComponent,
		DisclosureManagerComponent,
		DisclosureReportsComponent,
		ChangePasswordComponent,
		CCFAdminComponent,
		CatalogCollectionComponent,
		CourseLaunchComponent
	],
	bootstrap: [
		AccessLogComponent,
		AuditLogComponent,
		AdminToolsComponent,
		AddUsersComponent,
		BatchProcessingComponent,
		AssignComponent,
		HomeComponent,
		CourseUploaderComponent,
		ReportsComponent,
		ReportEditorComponent,
		ReportCreatorComponent,
		DashboardComponent,
		EnrollmentComponent,
		CatalogAdminComponent,
		CatalogSearchComponent,
		SelfEnrollComponent,
		BrandingComponent,
		UserSummaryComponent,
		TrainingComponent,
		SettingsComponent,
		ViewDisclosureComponent,
		ScheduleEnrollmentsComponent,
		ScheduleEmailsComponent,
		ProfileComponent,
		SelfProfileComponent,
		LogoutComponent,
		LoginComponent,
		LoggedOffComponent,
		RegisterComponent,
		LocalizationAdminComponent,
		LocalizationComponent,
		GlobalSearchComponent,
		ForgotPasswordComponent,
		FieldsComponent,
		ExcludeUsersComponent,
		ErrorLogComponent,
		ErrorPageComponent,
		EnrollQueryComponent,
		EnrollmentHistoryComponent,
		EmployeeSearchComponent,
		EmailHistoryComponent,
		EmailAuditLogComponent,
		EmailComponent,
		EditPermissionsComponent,
		DocumentationComponent,
		DelegateAccessComponent,
		CompanyAdminComponent,
		DisclosureManagerComponent,
		DisclosureReportsComponent,
		ChangePasswordComponent,
		CCFAdminComponent,
		CatalogCollectionComponent,
		CourseLaunchComponent
	],
	exports: [
		AccessLogComponent,
		AuditLogComponent,
		AdminToolsComponent,
		AddUsersComponent,
		BatchProcessingComponent,
		AssignComponent,
		HomeComponent,
		CourseUploaderComponent,
		ReportsComponent,
		ReportEditorComponent,
		ReportCreatorComponent,
		DashboardComponent,
		EnrollmentComponent,
		CatalogAdminComponent,
		CatalogSearchComponent,
		SelfEnrollComponent,
		BrandingComponent,
		UserSummaryComponent,
		TrainingComponent,
		SettingsComponent,
		ViewDisclosureComponent,
		ScheduleEnrollmentsComponent,
		ScheduleEmailsComponent,
		ProfileComponent,
		SelfProfileComponent,
		LogoutComponent,
		LoginComponent,
		LoggedOffComponent,
		RegisterComponent,
		LocalizationAdminComponent,
		LocalizationComponent,
		GlobalSearchComponent,
		ForgotPasswordComponent,
		FieldsComponent,
		ExcludeUsersComponent,
		ErrorLogComponent,
		ErrorPageComponent,
		EnrollQueryComponent,
		EnrollmentHistoryComponent,
		EmployeeSearchComponent,
		EmailHistoryComponent,
		EmailAuditLogComponent,
		EmailComponent,
		EditPermissionsComponent,
		DocumentationComponent,
		DelegateAccessComponent,
		CompanyAdminComponent,
		DisclosureManagerComponent,
		DisclosureReportsComponent,
		ChangePasswordComponent,
		CCFAdminComponent,
		CatalogCollectionComponent,
		CourseLaunchComponent
	],
	imports: [
		AngularMaterialModule,
		MonacoEditorModule.forRoot(monacoConfig),
		FormsModule,
		LioPipesModule,
		LioFormsModule,
		LioDirectivesModule,
		LioCatalogModule,
		LioCatalogAdminModule,
		LioCompanyAdminModule,
		LioDataDisplayModule,
		LioStructuralModule,
		LioDisclosuresModule,
		LioPermissionsModule,
		CommonModule
	],
})
export class LioPagesModule {}