import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { processingService } from '../../services/processing.service';
import { formValidatorService } from '../../services/form-validator.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { fieldService } from '../../services/fields.service';
import { lioLogService } from '../../services/lio-log.service';

import { ccfAdminSettings } from './ccf-admin.settings';

@Component({
	selector: 'lio-ccf-admin',
	templateUrl: './ccf-admin.component.html'
})
export class CCFAdminComponent implements OnDestroy {
	public optionsField		:any 			= this.ccfAdminSettings.optionsField;
	public allowSubmit			:boolean 		= false;
	public groups				:Array<any> 	= [];
	public refreshing			:boolean 		= false;
	public suppressCCF			:boolean 		= false;
	public differentEmployee	:boolean 		= false;
	public model				:any 			= {};
	public employeeID			:any 			= null;
	public localeStrings		:any 			= {
		header							:'Admin Rights',
		headerTrans						:'ccfAdmin.header',
		subHeader						:'Select the fields and options you wish to manage',
		subHeaderTrans					:'ccfAdmin.subHeader',
		suppresingCCFNote				:'NOTE: The available values were not loaded due to a large amount of data, you can still manually enter those values in the add option box',
		suppresingCCFNoteTrans			:'ccfAdmin.suppresingCCFNote',
		update							:'Update',
		updateTrans						:'form.update',
	};
	public localeStringsKeys	:Array<any>	= [
		'header',
		'subHeader',
		'suppresingCCFNote',
		'update'
	];

	private fields				:Array<any> 	= [];
	private availableAdminFields:Array<any> 	= [];
	private employeeAdminFields	:Array<any> 	= [];
	private ccfs				:Array<any> 	= [];
	private ccfGroups			:Array<any> 	= [];
	private settings			:any 			= {};
	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService			:navService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(processingService)		public	processingService	:processingService,
		@Inject(formValidatorService)	public	formValidatorService:formValidatorService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(fieldService)			public	fieldService		:fieldService,
		@Inject(lioLogService)			public	lioLogService		:lioLogService,
		@Inject(ccfAdminSettings)		public	ccfAdminSettings	:ccfAdminSettings
	){
		this.debugService.register('ccfadmin', this);
		this.navService.setActivePage('ccfadmin');

		this.processingService.allowCancel = false;
		
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initializes variables
	 * @return {boolean}
    */
	init() {
		this.getAdminData().then((result) => {
			if (result) {
				this.setFields();
			} else {
				setTimeout(() => {
					this.navService.goback();
				}, 2000);
			}
		});
	}

	/*
	 * Gets the employee's admin fields
	*/
	getAdminData() {
		let employee 	= this.storageService.get('employeeToEdit');
		let session 	= this.stateService.getSession();
		let ccfs		= this.storageService.get('ccfs');

		if (!employee) {
			employee = session;
		}

		if (session.employeeID !== employee.employeeID) {
			this.differentEmployee = true;
		}
		this.employeeID = employee.employeeID;
		this.ccfs = ccfs;

		return this.lmsService.post('employee/getCCFAdminFields', {'employeeID': this.employeeID}).then((result) => {
			if (result.success) {
				let employeeAdminFields 	= result.properties.employeeAdminFields;
				let	availableAdminFields 	= result.properties.availableAdminFields;
				let	ccfGroups 				= result.properties.ccfGroups;
				let	suppressCCF 			= result.properties.suppressCCF;

				if (!ccfGroups.length) {
					this.lioModalService.show('tieringNotSet');
					return false;
				}

				this.suppressCCF 			= suppressCCF;
				this.availableAdminFields 	= availableAdminFields;
				this.employeeAdminFields 	= employeeAdminFields;
				this.ccfGroups 				= ccfGroups;
				return true;
			}
        });
	}

	/*
	 * Formats the options for the drop down menu
	 * @param {[object[array]]} ccfOptions
	 * @return {array[objects]}
	*/
	formatOption(ccfOptions:Array<any> = []) {
		let cleanOptions 	= [];
		let	option 			= {};

		ccfOptions.forEach((ccfOption) => {
			option = {'name': ccfOption, 'value': ccfOption};
			cleanOptions.push(option);
		});

		return cleanOptions;
	}

	/*
	 * Adds an option to the fields
	*/
	addOption(field) {
		let customOption = field.customOption;
		let	alreadyAdded = false;

		if (!customOption) {
			return;
		}

		// Add new option
		field.options.forEach((option) => {
			if (option.name === field.customOption) {
				alreadyAdded = true;
			}
		});

		if (!alreadyAdded) {
			field.options.push({'name': field.customOption, 'value': field.customOption});
			this.model[field.model].options.push(field.customOption);
		}

		field.customOption = '';
		this.onupdate();
	}

	/*
	 * Sets the fields
	*/
	setFields() {
		let ccfs 					= this.ccfs;
		let	ccfFields 				= [];
		let	employeeAdminFields 	= this.employeeAdminFields;
		let	availableAdminFields 	= this.availableAdminFields;
		let	ccfGroups 				= this.ccfGroups;

		let ccfKeys = Object.keys(ccfs);
		ccfKeys.forEach((key) => {
			let index 		= parseInt(key.replace('clientCustomField', ''));
			let ccfField 	= {};
			let groupName 	= 'Custom Fields ';
			let ccfOptions 	= this.formatOption(availableAdminFields['e.' + key]);
			let modelKey 	= 'ccfAdminRights' + index;
			let ccfValues 	= employeeAdminFields[key];
			let wildCard 	= false;

			// Only show the ccfs that are groupable
			if (!this.utilService.inArray(ccfGroups, key)) {
				return;
			}

			ccfField['name'] = ccfs[key];
			ccfField['model'] = modelKey;

			if (!this.model[modelKey]) {
				this.model[modelKey] = {};
			}

			if (ccfValues && ccfValues[0] && ccfValues[0] === '*') {
				wildCard = true;
				ccfValues = [];
			}
			this.model[modelKey].wildCard = wildCard;
			if(ccfValues){
				this.model[modelKey].options = ccfValues;
				ccfValues = this.formatOption(ccfValues);
				ccfOptions = ccfValues;
			}else{
				this.model[modelKey].options = [];
			}

			if (index < 11) {
				groupName +=  '1-10';
			}
			if (index > 10 && index < 21) {
				groupName +=  '11-20';
			}
			if (index > 20) {
				groupName +=  '21-30';
			}

			ccfField['group'] = groupName;
			ccfField['type'] = 'select';
			ccfField['required'] = false;
			ccfField['options'] = ccfOptions;
			ccfField['min'] = 2;
			ccfField['max'] = 2000;
			ccfField['visible'] = true;
			ccfField['multiple'] = true;
			ccfField['searchable'] = true;
			ccfFields.push(ccfField);
		});
		
		this.fieldService.setFields(ccfFields, this.ccfAdminSettings.fieldConfig).then((fields:Array<any>) => {
			this.fields = fields;
			this.compileGroups();
			this.navService.displayPage();
		});
	}

	/*
	 * Compule the groups
    */
	compileGroups() {
		let groupsObj 	= {};
		let	groups 		= [];

		// Organize all fields into its designated group
		this.fields.forEach((field) => {
			if (!field.group) {
				this.lioLogService.log(['Field missing a group', field]);
				return;
			}
			if (!groupsObj[field.group]) {
				groupsObj[field.group] = {'fields': [field], 'name': field.group};
			} else {
				groupsObj[field.group].fields.push(field);
			}
		});

		// Convert to an array
		let groupKeys = Object.keys(groupsObj);
		groupKeys.forEach((key) => {
			let visible = false;
			groupsObj[key].fields.forEach((field) => {
				if (field.visible) {
					visible = true;
				}
			});
			groupsObj[key].visible = visible;
			groups.push(groupsObj[key]);
		});

		this.groups = groups;
	}

	/*
	 * On update
	 * @param {boolean} apply
    */
	onupdate(field = null) {
		if (field) {
			this.navService.changedForm = true;
		}
		this.allowSubmit = true;
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
	}

	/*
	 * Checks if the form is valid
    */
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (!this.formValidatorService.isValid(this.settings, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.settings = this.formValidatorService.getData();
		return isValid;
	}

	/*
	 * Submits the edit
    */
    submit() {
		if (!this.isValid()) {
			return;
		}

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lioModalService.showLoading('processing');

		this.navService.changedForm = false;
		this.lmsService.post('employee/updateCCFAdminFields', {'employeeID': this.employeeID, 'model': this.model}).then((result) => {
			
			this.lioModalService.hideLoading();
			this.allowSubmit = false;

			if (result.success) {
				this.lioModalService.show('savedSuccessfully');
			}
        });
    }	
}