import { Injectable, Inject } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

import { querySettings } from '../../settings/query.settings';

@Injectable({
	providedIn	: 'root', 
	useClass	: permissionsSettings
})
export class permissionsSettings {

	constructor(
		@Inject(querySettings)	private querySettings	: querySettings
	){}

	panelTabConfigs:any = {
		'modifyRoles' : {
			id				: 'panelTab_modify_roles',
			title			: 'Modify Roles',
			subtitle		: 'Modify Roles',
			tab				: 'Modify Roles',
		},
		'queryPanel' : {
			id				: 'panelTab_query_panel',
			title			: 'Assign Companies and Roles',
			subtitle		: 'Enter a query below to choose which users to affect',
			tab				: 'Assign Companies / Roles',
		},
		'assignCompanies' : {
			id				: 'panelTab_assign_companies',
			tab				: 'Assign Companies',
			panelClass 		: 'notaclass',
			bodyClass 		: 'notaclass',
		},
		'assignRoles' : {
			id				: 'panelTab_assign_roles',
			tab				: 'Modify Role Assignments',
			panelClass 		: 'notaclass',
			bodyClass 		: 'notaclass'
		},
	};

	queryToolSettings:any = {
		'type'					: 'editpermissions',
		'addBaseFilters'		: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'showSaver'				: false,
		'usesQueryTool'			: true,
		'text'				: {
			'runReport'			: 'editPermissions.getEmployees',
		},
		'endpoints'				: {
			'get'					: 'query/getAllByType',
			'save'					: 'query/save',
			'delete'				: 'query/deleteByID',
			'report'				: 'permissions/getAllowedCompaniesAndRolesByRules',
		},
	};

	assignCompaniesPagination:any = {
		id			: 'assignCompanies',
		pageLimit	: 10,
		pageLimits	: [10, 50, 100]
	};

	assignRolesPagination:any = {
		id			: 'assignRoles',
		pageLimit	: 10,
		pageLimits	: [10, 50, 100]
	};

	fieldConfig:any = {
		'fieldName'			: 'editPermissions',
		'permissionFields'	: true,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			'name'			: 'UID',
			'model'			: 'UID',
			'type'			: 'text',
			'invertModel'	: false,
			'visible' 		: false
		},
		{
			'name'			: 'employeeID',
			'model'			: 'employeeID',
			'type'			: 'text',
			'invertModel'	: false,
			'sortable' 		: true,
			'visible' 		: true
		},
		{
			'name'			: 'email',
			'model'			: 'email',
			'type'			: 'text',
			'invertModel'	: false,
			'sortable' 		: true,
			'visible' 		: true
		},
		{
			'name'			: 'firstName',
			'model'			: 'firstName',
			'type'			: 'text',
			'invertModel'	: false,
			'sortable' 		: true,
			'visible' 		: true
		},
		{
			'name'			: 'lastName',
			'model'			: 'lastName',
			'type'			: 'text',
			'invertModel'	: false,
			'sortable' 		: true,
			'visible' 		: true
		}
	);

	filters:Array<any> = [
		{
			'field': 'fn.hierarchy',
			'label': 'form.hierarchy',
			'type': 'integer',
			'input': 'select',
			'operators': [this.querySettings.operatorOptions.equal],
			'options': [
				{
					'name': 'bypass',
					'value': 'bypass',
					'nameTrans': 'form.bypassHierarchy',
					'locked': 'filters.bypassSegregation'
				},
				{
					'name': 'subordinate',
					'value': 'subordinate',
					'nameTrans': 'form.subordinate',
					'locked': 'filters.subordinateOption'
				},
				{
					'name': 'region',
					'value': 'region',
					'nameTrans': 'form.region',
					'locked': 'filters.regionOption',
				},
				{
					'name': 'subordinateInRegion',
					'value': 'subordinateInRegion',
					'nameTrans': 'form.subordinateInRegion',
					'locked': 'filters.subordinateInRegionOption'
				},
			],
			'locked': 'filters.hierarchy',
			'required': 'filters.requireHierarchy',
			'default': true 
		}
	];
}