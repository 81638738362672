import { Component, Inject, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultModalComponent } from '.././default/default.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})

export class IFrameComponent extends DefaultModalComponent implements OnInit {
	@ViewChild("iframe") frame: ElementRef;

	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public width 	: any;
	public height 	: any;
	public override settings : any = {
		'id' : 'am_modal_iframe',
		'canClose': true,
		'close': 'Close',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public parentSettings: any) {
		super(parentSettings);
		this.settings = Object.assign(this.settings, parentSettings);

		this.width = this.settings.width 	|| '300';
		this.height = this.settings.height 	|| '500px';
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}
}
