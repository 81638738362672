import { LioField } from './field.model';

export class LioDateField extends LioField{
	public minDate:Date;
	public maxDate:Date;
	public blackoutDates:(date:moment.Moment, schedule)=>boolean;

	constructor(obj:Partial<LioDateField>){
		super(obj);
		Object.assign(this, obj);
	}
}