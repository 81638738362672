import { Inject, Injectable } from '@angular/core';
import { addUsersSettings } from '../add-users/add-users.settings';
import { catalogSettings } from '../../settings/catalog.settings';
import { dashboardSettings } from '../dashboard/dashboard.settings';
import { disclosureReportSettings } from '../disclosure-reports/disclosure-report.settings';
import { mailTemplateSettings } from '../../modules/structural/components/email-editor/mail-template.settings';
import { profileSettings } from '../profile/profile.settings';
import { registerSettings } from '../register/register.settings';
import { courseUploaderSettings } from '../course-uploader/course-uploader.settings';
import { scheduleEmailEventSettings } from '../../components/schedule-emails/schedule-email-event.settings';
import { scheduleEnrollmentEventSettings } from '../schedule-enrollments/schedule-enrollment-event.settings';
import { employeeSearchSettings } from '../employee-search/employee-search.settings';
import { delegateAccessSettings } from '../delegate-access/delegate-access.settings';
import { enrollmentSettings } from '../enrollments/enrollments.settings';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: fieldsSettings
})
export class fieldsSettings {
	constructor(
		@Inject(profileSettings) private profileSettings:profileSettings,
		@Inject(addUsersSettings) private addUsersSettings:addUsersSettings,
		@Inject(registerSettings) private registerSettings:registerSettings,
		@Inject(dashboardSettings) private dashboardSettings:dashboardSettings,
		@Inject(catalogSettings) private catalogSettings:catalogSettings,
		@Inject(courseUploaderSettings) private courseUploaderSettings:courseUploaderSettings,
		@Inject(disclosureReportSettings) private disclosureReportSettings:disclosureReportSettings,
		@Inject(mailTemplateSettings) private mailTemplateSettings:mailTemplateSettings,
		@Inject(employeeSearchSettings) private employeeSearchSettings:employeeSearchSettings,
		@Inject(delegateAccessSettings) private delegateAccessSettings:delegateAccessSettings,
		@Inject(enrollmentSettings) private enrollmentSettings:enrollmentSettings,
		@Inject(scheduleEmailEventSettings) private scheduleEmailEventSettings:scheduleEmailEventSettings,
		@Inject(scheduleEnrollmentEventSettings) private scheduleEnrollmentEventSettings:scheduleEnrollmentEventSettings
	){}

	employeesPagination:any = {
		pageLimit	: '10',
		pageLimits	: ['10', '50', '100'],
		sortMode 	: {
			field: 'employeeID'
		}
	};

	fieldsPagination:any = {
		pageLimit	: '20',
		pageLimits	: ['20', '50', '100'],
	};

	panelConfig:any = {
		'page' : {
			id				: 'panelTab_field_page_editor',
			title			: 'Field Editor',
			subtitle		: 'Field Editor',
			tab				: 'Field Editor',
			reloadOnTabChange: true,
		}
	};

	dataModel:Array<any> = [
			{
				'name': 'type',
				'type': 'string',
			},
			{
				'name': 'visible',
				'type': 'boolean',	
			},
			{
				'name': 'locked',
				'type': 'boolean',	
			},
			{
				'name': 'required',
				'type': 'boolean',	
			},
			{
				'name': 'export',
				'type': 'boolean',	
			},
			{
				'name': 'customOptions',
				'type': 'boolean',	
			},
			{
				'name': 'loadOptions',
				'type': 'boolean',	
			},
			{
				'name': 'segregation',
				'type': 'string',
			},
			{
				'name': 'global',
				'type': 'boolean',	
			}
	];

	employeeFields:LioFieldArray = new LioFieldArray(
		{
			'name'	: 'Employee ID',
			'model'	: 'employeeID',
			'type'	: 'text',
			'visible': true,
			'sortable':true
		},
		{
			'name'	: 'First Name',
			'model'	: 'firstName',
			'type'	: 'text',
			'visible': true,
			'sortable':true
		},
		{
			'name'	: 'Last Name',
			'model'	: 'lastName',
			'type'	: 'text',
			'visible': true,
			'sortable':true
		},
		{
			'name'	: 'e-mail',
			'model'	: 'email',
			'type'	: 'text',
			'visible': true,
			'sortable':true
		},
	);

	employeeSettings:any = {
		'includeUID'	: true
	};

	segregationOptions:Array<any> = [
		{
			'name'	: 'employeeSearch.defaultHierarchy', 
			'value'	: '',
			'visible' : 'employeeSearch.defaultHierarchy',
			'trans' 	: 'form.defaultHierarchy'
		},
		{
			'name'	: 'employeeSearch.bypassHierarchy', 
			'value'	: 'bypass',
			'visible' : 'employeeSearch.bypassHierarchy',
			'trans' 	: 'form.bypassHierarchy'
		},
		{
			'name'	: 'employeeSearch.subordinateHierarchy', 
			'value'	: 'subordinate',
			'visible' : 'employeeSearch.subordinateHierarchy',
			'trans' 	: 'form.subordinate'
		}, 
		{
			'name'	: 'employeeSearch.regionHierarchy', 
			'value'	: 'region',
			'visible' : 'employeeSearch.regionHierarchy',
			'trans' 	: 'form.region'
		},
		{
			'name'	: 'employeeSearch.subordinateInRegion', 
			'value'	: 'subordinateInRegion',
			'visible' : 'employeeSearch.subordinateInRegion',
			'trans' 	: 'form.subordinateInRegion'
		},
	]

	fieldTypes:Array<any> = [
		{
			'name' : 'Text',
			'value' : 'text'
		},
		{
			'name' : 'Default',
			'value' : 'default'
		},
		{
			'name' : 'Drop Down',
			'value' : 'select'
		},
		{
			'name' : 'Number',
			'value' : 'number'
		},
		{
			'name' : 'E-mail',
			'value' : 'email'
		},
		{
			'name' : 'Date',
			'value' : 'date'
		},
		{
			'name' : 'Boolean',
			'value' : 'boolean'
		},
		{
			'name' : 'Checkbox',
			'value' : 'checkbox'
		}
	];

	pages:Array<{
		name	:string, 
		fields	:LioFieldArray, 
		config	:any
	}> = [
		{
			'name'	:'Edit Own Profile',
			'fields': this.profileSettings.personalFields,
			'config': this.profileSettings.personalConfig,
		},
		{
			'name'	:'Edit Another Employee Profile',
			'fields': this.profileSettings.externalFields,
			'config': this.profileSettings.externalConfig,
		},
		{
			'name'	:'Add Users',
			'fields': this.addUsersSettings.fields,
			'config': this.addUsersSettings.fieldConfig,
		},
		{
			'name'	:'Register Users',
			'fields': this.registerSettings.fields,
			'config': this.registerSettings.fieldConfig,
		},
		{
			'name'	:'Course Editor',
			'fields': this.courseUploaderSettings.tabSettings.edit.fields,
			'config': this.courseUploaderSettings.tabSettings.edit.fieldConfig,
		},
		{
			'name'	:'Dashboard Results',
			'fields': this.dashboardSettings.fields,
			'config': this.dashboardSettings.fieldConfig,
		},
		{
			'name'	:'Dashboard Search',
			'fields': this.dashboardSettings.searches,
			'config': this.dashboardSettings.searchConfig,
		},
		{
			'name'	:'Email',
			'fields': this.mailTemplateSettings.fields,
			'config': this.mailTemplateSettings.fieldConfig,
		},
		{
			'name'	:'Disclosure Report Completions',
			'fields': this.disclosureReportSettings.panelConfig.completions.fields,
			'config': this.disclosureReportSettings.panelConfig.completions.fieldConfig,
		},
		{
			'name'	:'Disclosure Report Inompletions',
			'fields': this.disclosureReportSettings.panelConfig.incompletions.fields,
			'config': this.disclosureReportSettings.panelConfig.incompletions.fieldConfig,
		},
		{
			'name'	:'Catalog Search',
			'fields': this.catalogSettings.fields,
			'config': this.catalogSettings.fieldConfig,
		},
		{
			'name'	:'Enrollment Scheduler',
			'fields': this.scheduleEnrollmentEventSettings.fields,
			'config': this.scheduleEnrollmentEventSettings.fieldConfig,
		},
		{
			'name'	:'Email Event Scheduler',
			'fields': this.scheduleEmailEventSettings.fields,
			'config': this.scheduleEmailEventSettings.fieldConfig,
		},
		{
			'name'	:'Search Employees',
			'fields': this.employeeSearchSettings.fields,
			'config': this.employeeSearchSettings.fieldConfig,
		},
		{
			'name'	:'Delegate Access',
			'fields': this.delegateAccessSettings.fields,
			'config': this.delegateAccessSettings.fieldConfig,
		},
		{
			'name'	:'Enrollments',
			'fields': this.enrollmentSettings.fields,
			'config': this.enrollmentSettings.fieldConfig,
		},
	];
}