<div class="row" [hidden]="!showLoader" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-8 mx-auto">
		<lio-card footerClass="text-right" headerText="Disclosure Reports" headerTrans="disclosureReport.title">
			<div lio-card-body>
				<h2 class="h5">Filter By:</h2>
				<mat-button-toggle-group [(ngModel)]="this.filterBy" color="primary" class="mb-3" (change)="filterDisclosures()">
					<mat-button-toggle value="active">{{localeStrings.active}}</mat-button-toggle>
					<mat-button-toggle value="archived">{{localeStrings.archived}}</mat-button-toggle>
				</mat-button-toggle-group>

				<lio-searchable-select amID="am_disclosure_list"
					[model]="model"
					[field]="disclosureField"
					appearance="fill">
				</lio-searchable-select>
			</div>
			<div lio-card-footer *ngIf="model.data && model.data.id">
				<button mat-flat-button color="primary" (click)="load()">View Report</button>
			</div>
		</lio-card>
	</div>
</div>
<lio-panel-tabber #tabCache (onTabChange)="onMainPanelTabberChange($event)" [hidden]="showLoader">
	<lio-panel-tab [settings]="panelConfig.completions">
		<ng-container *lioPanelTabContent>
			<div class="card-body p-0">
				<div class="card-header bg-white">
					<div class="row">
						<div class="col-md-12 ml-auto" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
							<mat-form-field [appearance]="appearance" class="w-100" [style.fontSize.rem]="0.8">
								<mat-label>Search</mat-label>
								<input matInput 
									id="searchCompletions" 
									type="text"
									[(ngModel)]="searchCompletions">
								<mat-icon matSuffix>search</mat-icon>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div lio-paginator 
					[controlID]="panelConfig.completions.pagination.id" 
					[collection]="stats.completions" 
					[settings]="panelConfig.completions.pagination"
					[filters]="searchCompletions"
					(onResults)="updateCompletions($event)">

					<lio-results-table
						[paginatorID]="panelConfig.completions.pagination.id"
						[fields]="panelConfig.completions.fields"
						[results]="filteredCompletions"
						[fieldConfig]="panelConfig.completions.fieldConfig"
						[prefixButtons]="prefixButtons"
						(headerClick)="clickedHeader($event)">
					</lio-results-table>
				</div>
			</div>
			<div class="card-footer">
				<button mat-flat-button 
					color="primary" 
					[disabled]="!filteredCompletions.length" 
					(click)="export('completions')">
					
					<span>Export</span>
				</button>
			</div>
		</ng-container>
	</lio-panel-tab>
	<lio-panel-tab [settings]="panelConfig.incompletions">
		<ng-container *lioPanelTabContent>
			<div class="card-body p-0">
				<div class="card-header bg-white">
					<div class="row">
						<div class="col-md-12 ml-auto" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
							<mat-form-field [appearance]="appearance" class="w-100" [style.fontSize.rem]="0.8">
								<mat-label>Search</mat-label>
								<input matInput 
									id="searchIncompletions" 
									type="text"
									[(ngModel)]="searchIncompletions">
								<mat-icon matSuffix>search</mat-icon>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div lio-paginator 
					[controlID]="panelConfig.incompletions.pagination.id" 
					[collection]="stats.incompletions" 
					[settings]="panelConfig.incompletions.pagination"
					[filters]="searchIncompletions"
					(onResults)="updateIncompletions($event)">

					<lio-results-table
						[paginatorID]="panelConfig.incompletions.pagination.id"
						[fields]="panelConfig.incompletions.fields"
						[results]="filteredIncompletions"
						[fieldConfig]="panelConfig.incompletions.fieldConfig">
					</lio-results-table>
				</div>
			</div>
			<div class="card-footer">
				<button mat-flat-button 
					color="primary" 
					[disabled]="!filteredIncompletions.length" 
					(click)="export('incompletions')">
					
					<span>Export</span>
				</button>
			</div>
		</ng-container>
	</lio-panel-tab>

	<lio-panel-tab [settings]="panelConfig.clear"></lio-panel-tab>
</lio-panel-tabber>

<lio-disclosure-pdf 
	[hidden]="!pdfRendering"
	[responseData]="responseData" 
	(pdfTargetReady)="setPdfTarget($event)">
</lio-disclosure-pdf>