import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';

import { MatAccordion } from '@angular/material/expansion';

import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { navService } from '../../../../services/nav.service';
import { permissionService } from '../../../../services/permissions.service';
import { feedbackService } from '../../../../services/feedback.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { lmsService } from '../../../../services/lms.service';
import { workerService } from '../../../../services/worker.service';
import { stateService } from '../../../../services/state.service';
import { localizationService } from '../../../../services/localization.service';
import { utilService } from '../../../../services/util.service';
import { lioLogService } from '../../../../services/lio-log.service';

import { roleSettings } from './roles.settings';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-modify-roles',
	templateUrl: './modify-roles.component.html',
	styleUrls: ['./modify-roles.component.css']
})
export class LioModifyRoles implements OnDestroy {
	@ViewChild('permissionSections') accordion: MatAccordion;

	private allRoles				:Array<any> = [];
	public targetRole				:any 		= {roleID:-1};
	private newRole 				:any 		= this.utilService.copy(this.roleSettings.newRole);
	public fields 					:Array<any> = this.permissionService.setFields(this.utilService.copy(this.roleSettings.fields));
	private allPermissions			:Array<any> = [];
	private unmodifiedPermissions	:Array<any> = [];
	public inProgressPermissions	:Array<any>	= [];
	public editRole 				:any 		= this.newRole;
	public allPermissionsCollapse 	:boolean 	= true;
	private localizedModelName 		:string 	= this.localizationService.get('editPermissions.role');
	public uploadSettings 			:any 		= {
		'name'				: 'Upload Permissions',
		'fileTask'			: 'importpermissions/upload',
		'allowedFileTypes'	: ['xlsx'],
		'hideLoading'		: false,
	};
	private filePath 				:string 	= '';
	public originalRole				:any 		= null;
	public copiedRole				:any 		= null;
	public query 					:string 	= '';

	public authorityField:LioSearchableSelectField = new LioSearchableSelectField({
		model 				: 'authorityLevels',
		options 			: this.utilService.copy(this.roleSettings.authorities),
		multiple			: true
	});
	public roleField:LioSearchableSelectField = new LioSearchableSelectField({
		name				: 'Select a Role to Modify',
		model 				: 'roleID',
		options 			: this.utilService.copy(this.roleSettings.authorities),
		optionValueField 	: 'roleID',
		orderBy 			: 'roleID'
	});

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(navService) 			public navService 			:navService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(permissionService) 		public permissionService 	:permissionService,
		@Inject(lioModalService) 		public lioModalService 		:lioModalService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(workerService) 			public workerService 		:workerService,
		@Inject(stateService) 			public stateService 		:stateService,
		@Inject(localizationService) 	public localizationService 	:localizationService,
		@Inject(lioLogService) 			public lioLogService 		:lioLogService,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(roleSettings) 			public roleSettings			:roleSettings,
	){
		this.debugService.register('modifyRoles', this);

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.loadRoles();
				this.roleField.options = this.allRoles;
			})
		);

		this.getFullPermissionList();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	loadRoles(){
		this.allRoles = this.permissionService.getRoleAuthorities('EDIT');
		this.allRoles.unshift(this.utilService.copy(this.newRole));
	}
	
	/**
	 * Gets the full list of all permissions
	 */
	getFullPermissionList(){
		this.lmsService.post('permissions/getAll').then((result) => {
			this.allPermissions = result.properties.permissions;
			this.getRole();
		});
	}

	/**
	 * Exports the permissions of a role
	 */
	exportPermissions(){
		let permExport = this.preparePermissionsExport();
		if(!this.workerService.export(permExport.data, permExport.headers, this.editRole.name)) {
			this.lioModalService.show('modelExportFail', null, {key : 'modelName', value : this.localizedModelName});
		}
	}

	/* 
	* On File Added
	*/
	fileAdded(event) {
		this.filePath = event.filePath;
		this.importPermissionsPreprocess();
	}

	/*
	* Sends import file for preprocess operations, handles results
	*/
	importPermissionsPreprocess(){
		let filePath = this.filePath;

		this.lmsService.postAsync('importpermissions/preprocess', {'filePath': filePath},
		'uploading').then((result) => {
			var token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handlePreProcessResults(result);
				});
			} else {
				this.handlePreProcessResults(result);
			}
		});	
	}

	//confirms that we want the file to process, handles results
	importPermissionsProcess(){
		let filePath = this.filePath;

		this.lmsService.postAsync('importpermissions/process', {'filePath': filePath},
		'uploading').then((result) => {
			var token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleProcessResults(result);
				});
			} else {
				this.handleProcessResults(result);
			}
		});	
	}

	handleProcessResults(result) {
		this.lioModalService.hideLoading();

		if (!result.success || !result.properties) {
			return;
		}

		let properties = result.properties,
			totalToProcess = properties.totalToProcess,
			totalAdded = properties.totalAdded,
			totalFailed = properties.totalFailed,
			cancelled = properties.cancelled,
			errors = properties.errors,
			messages = [],
			macros = [];


		if (cancelled) {
			return;
		}

		if (!totalToProcess) {
			return;
		}

		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalRecordsSuccessfullyInserted', macros));
		}
		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalNumberOfUnexpectedIssues', macros));
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		
		this.navService.changedForm = false;
	}

	handlePreProcessResults(result) {
		this.lioModalService.hideLoading();

		if (!result.success || !result.properties) {
			return;
		}
		let properties = result.properties,
			totalToProcess = properties.totalToProcess,
			totalAdded = properties.totalAdded,
			totalFailed = properties.totalFailed,
			modifiedPermissions = properties.modifiedPermissions,
			cancelled = properties.cancelled,
			errors = properties.errors,
			isValidFile = true,
			messages = [],
			macros = [];


		if (cancelled) {
			return;
		}

		if (!totalToProcess) {
			return;
		}
		macros.push({'key': 'numberToProcess', 'value': totalToProcess});
		messages.push(this.utilService.localizeMessage('totalRecordsDetected', macros));
		
		if (totalAdded) {
			macros.push({'key': 'totalAdded', 'value': totalAdded});
			messages.push(this.utilService.localizeMessage('totalRecordsWillBeInserted', macros));
		}

		// The modifications are based on the modification from the default built in setting  
		if (modifiedPermissions) {
			this.lioLogService.log(['These permissions will be modified from the default', modifiedPermissions]);
			macros.push({'key': 'totalEdited', 'value': modifiedPermissions.length});
			messages.push(this.utilService.localizeMessage('totalRecordsWillBeUpdated', macros));
		}

		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalIssuesWithTheFile', macros));
			isValidFile = false;
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		
		if (isValidFile) {
			this.navService.changedForm = true;
		}
	}

	preparePermissionsExport(){
		let data 	= [];
		let headers:Array<any> = [
			{visible: true, allowed: false, description: '', model : 'roleID'			, name : 'roleID'}, 
			{visible: true, allowed: false, description: '', model : 'section'			, name : 'section'}, 
			{visible: true, allowed: false, description: '', model : 'permissionName'	, name : 'permissionName'}, 
			{visible: true, allowed: false, description: '', model : 'allowed'			, name : 'allowed'}, 
			{visible: true, allowed: false, description: '', model : 'description'		, name : 'description'}
		];
		
		let sectionKeys = Object.keys(this.inProgressPermissions);
		sectionKeys.forEach((i) => {
			let permKeys = Object.keys(this.inProgressPermissions[i]);
			permKeys.forEach((j) => {
				let entry = {
					allowed 		: false,
					description 	: '',
					roleID 			: this.targetRole.roleID,
					section 		: i, 
					permissionName 	: j
				};

				if (this.inProgressPermissions[i][j].settings) {
					entry.allowed = this.inProgressPermissions[i][j].settings.allowed;
				}

				if (this.inProgressPermissions[i][j].description) {
					entry.description = this.inProgressPermissions[i][j].description;
				}

				data.push(entry);
			});
		});
		return {data : data, headers : headers};
	}
	
	/**
	 * Gets the permissions set for the target role
	 */
	loadRole(role = null){
		if (role) {
			this.editRole = role;
		} else if (!this.editRole) {
			this.editRole = this.utilService.copy(this.newRole);
		}
		
		if (this.editRole.roleID != -1){
			this.lmsService.post('permissions/getRolePermissions', {roleID : this.editRole.roleID}).then((result) => {
				this.editRole.permissions = result.properties.permissions;
				this.editRole.defaultPermissions = result.properties.defaultPermissions;

				this.loadPermissions();
			});
		} else {
			this.loadPermissions();
		}
	}

	/**
	 * Gets the default permission by name
	 * @param {string} name 
	 * @return {boolean}  
	 */
	getDefaultPermission(name):boolean {
		if (!this.editRole.defaultPermissions) {
			return false;
		}
		let defaultSetting = false;
		this.editRole.defaultPermissions.forEach((permission) => {
			if (permission.permissionName.toLowerCase() == name.toLowerCase()) {
				defaultSetting = permission.allowed;
			}
		});

		return defaultSetting;
	}

	/**
	 * Loads a set of permissions values into the permissions form
	 */
	loadPermissions(){
		this.inProgressPermissions = this.utilService.copy(this.allPermissions);

		let permissions 	= this.permissionService.getPermissions();
		let permissionKeys 	= Object.keys(permissions);
		permissionKeys.forEach((i) => {
			let splitPermissionName = permissions[i].permissionname.split("."),
				section 			= splitPermissionName[0],
				permissionName 		= splitPermissionName[1];

			let sectionKeys 			= Object.keys(this.allPermissions);
			let sectionLowerCaseKeys 	= sectionKeys.map((value) => {
				return value.toLowerCase();
			});
			let sectionIndex 			= sectionLowerCaseKeys.indexOf(section.toLowerCase());

			if(sectionIndex >= 0){
				let permissionNamesKeys 			= Object.keys(this.allPermissions[sectionKeys[sectionIndex]]);
				let permissionNamesLowerCaseKeys 	= permissionNamesKeys.map((value) => {
					return value.toLowerCase();
				});
				let permissionNameIndex 			= permissionNamesLowerCaseKeys.indexOf(permissionName.toLowerCase());

				if(permissionNameIndex >= 0){
					let self = {'allowed' : JSON.parse(permissions[i].allowed)};
					this.inProgressPermissions[sectionKeys[sectionIndex]][permissionNamesKeys[permissionNameIndex]].self = self;
					if(!this.inProgressPermissions[sectionKeys[sectionIndex]][permissionNamesKeys[permissionNameIndex]].settings){
						this.inProgressPermissions[sectionKeys[sectionIndex]][permissionNamesKeys[permissionNameIndex]].settings = {};
					}
				}
			}
		});

		let rolePerms 		= this.editRole.permissions;
		let rolePermKeys 	= Object.keys(rolePerms);
		rolePermKeys.forEach((i) => {
			let splitPermissionName = rolePerms[i].permissionname.split("."),
				section 			= splitPermissionName[0],
				permissionName 		= splitPermissionName[1];

			let sectionKeys 			= Object.keys(this.allPermissions);
			let sectionLowerCaseKeys 	= sectionKeys.map((value) => {
				return value.toLowerCase();
			});
			let sectionIndex 			= sectionLowerCaseKeys.indexOf(section.toLowerCase());

			if(sectionIndex >= 0){
				let permissionNamesKeys 			= Object.keys(this.allPermissions[sectionKeys[sectionIndex]]);
				let permissionNamesLowerCaseKeys 	= permissionNamesKeys.map((value) => {
					return value.toLowerCase();
				});
				let permissionNameIndex 			= permissionNamesLowerCaseKeys.indexOf(permissionName.toLowerCase());

				if(permissionNameIndex >= 0){
					let settings = {'allowed' : JSON.parse(rolePerms[i].allowed)};
					this.inProgressPermissions[sectionKeys[sectionIndex]][permissionNamesKeys[permissionNameIndex]].settings = settings;
				}
			}
		});
		this.unmodifiedPermissions 	= this.utilService.copy(this.inProgressPermissions);
		this.editRole.ipWhiteList 	= this.editRole.ipWhiteList == true;
		this.setAuthorities();
	}

	/**
	 * Sets the authority model
	 */
	setAuthorities() {
		let definedRoleIDs = [];

		if (!this.editRole.authorities) {
			this.editRole.authorities = [];
		}
		
		this.editRole.authorities.forEach((authority) => {
			authority.name = this.permissionService.getRoleNameFromID(authority.roleID);
			definedRoleIDs.push(authority.roleID);
		});

		this.allRoles.forEach((role) => {
			if (definedRoleIDs.indexOf(role.roleID) === -1 && role.roleID > 0) {
				this.editRole.authorities.push(role);
			}
		});
		this.originalRole = this.utilService.copy(this.editRole); 
	}

	/**
	 * Sends modifications to a role to the backend
	 */
	modifyRole(){

		let sendRole = this.utilService.copy(this.editRole),
			permissions = this.preparePermissionsForSend();
		
		delete sendRole.permissions;

		this.lmsService.postAsync('editpermissions/modifyRole', {role : sendRole, 'permissions' : permissions},
		'processing').then((result) => {
			let token = result.properties.token;
			
			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleModifyRoleResults(result);
				});
			} else {
				this.handleModifyRoleResults(result);
			}
		});
	}

	/**
	 * Handles results of modifying a role
	 */
	handleModifyRoleResults(result){
		if (result.success) {
			this.lioModalService.show('modelSaveSuccess', null, {key : 'modelName', value : this.localizedModelName});
		} else {
			this.lioModalService.show('modelSaveFail', null, {key : 'modelName', value : this.localizedModelName});
		}
	}

	/**
	 * Gets the permissions ready to send
	 */
	preparePermissionsForSend(){
		let permissions = [];
		let sectionKeys	= Object.keys(this.unmodifiedPermissions);

		sectionKeys.forEach((i) => {
			let permKeys = Object.keys(this.unmodifiedPermissions[i]);
			permKeys.forEach((j) => {
				
				let permissionSectionName = i + '.' + j,
					newPermission = {
						permissionName 	: permissionSectionName,
						allowed 		: false,
						delete 			: false
					},
					defaultSetting = this.getDefaultPermission(permissionSectionName),
					settings = this.inProgressPermissions[i][j].settings;

				// If the permission setting matches the default, flag to 
				if (!settings || (defaultSetting === settings.allowed)) {
					newPermission.delete = true;
				}

				if (settings) {
					newPermission.allowed = settings.allowed;
				}

				permissions.push(newPermission);
			});
		});

		return permissions;
	}

	/**
	 * Gets info for the target role
	 */
	getRole(){
		if (this.targetRole.roleID >= 0) {
			this.lmsService.post('permissions/getRole', {roleID : this.targetRole.roleID}).then((result) => {
				this.loadRole(result.properties.role);
			});
		} else {
			this.editRole = null;
			this.loadRole();
		}
	}

	/**
	 * Handles results of attmepting to create a role
	 */
	handleCreateResults(result) {
		if (result.success) {
			this.stateService.getAllowedRoles().then(() => {
				this.loadRoles();
				this.loadRole(result.properties.role);
				this.targetRole.roleID = result.properties.role.roleID;
			});
			this.lioModalService.show('modelCreateSuccess', null, {key : 'modelName', value : this.localizedModelName});
		} else {
			this.lioModalService.show('modelCreateFail', null, {key : 'modelName', value : this.localizedModelName});
		}
	}

	/**
	 * Sets all permissions for the target role to be allowed, must still be submitted to take effect
	 */
	assignAllPermissions() {
		this.toggleAllPermissions(true);
	}
	
	/**
	 * Sets all permissions for the target role to not be allowed, must still be submitted to take effect
	 */
	unAssignAllPermissions() {
		this.toggleAllPermissions(false);
	}

	/**
	 * Sets all permissions for the target role to either allowed or not allowed
	 * @param setTo {bool} whether the permissions should be set to allowed or not allowed
	 */
	toggleAllPermissions(setTo){
		let sections 	= Object.keys(this.inProgressPermissions),
		i 				= 0,
		j 				= 0,
		permissionNames = null,
		permission 		= null,
		section 		= null;

		for (i = 0; i < sections.length; i++) {
			section 		= this.inProgressPermissions[sections[i]];
			permissionNames = Object.keys(section);

			for (j = 0; j < permissionNames.length; j++) {
				permission = section[permissionNames[j]];
				if(setTo == false || (permission.self && permission.self.allowed)){
					permission.settings = {allowed: setTo};
				}
			}
		}
	}

	/**
	 * Collapses or expands all permissions sections
	 */
	toggleAllSectionCollapse(){
		this.allPermissionsCollapse = !this.allPermissionsCollapse;
		if(this.allPermissionsCollapse){
			this.accordion.closeAll();
		}else{
			this.accordion.openAll();
		}
	}

	/**
	 * Check all available settings within a section
	 * @param {object} section
	 */
	checkAll(section) {
		let check = true;

		let keys = Object.keys(section);
		keys.forEach((i) => {
			if (section[i].settings) {
				if (section[i].settings.checkedAll) {
					check = false;
				}
			}
		});

		keys.forEach((i) => {
			if (section[i].self && section[i].self.allowed) {
				if (!section[i].settings) {
					section[i].settings = {};
				}
				section[i].settings.checkedAll = check;
				section[i].settings.allowed = check;
			}
		});
	}

	/**
	 * Copies a role
	 */
	copyRole() {
		this.copiedRole = this.utilService.copy(this.editRole); 
	}

	/**
	 * Reset a role
	 */
	resetRole() {
		this.loadRole(this.editRole);
		this.copiedRole = null;
	}

	/**
	 * Pastes a role
	 */
	pasteRole() {
		let roleID = this.editRole.roleID,
			name = this.editRole.name;

		this.editRole = this.utilService.copy(this.copiedRole); 
		this.editRole.roleID = roleID;
		this.editRole.name = name;
		this.copiedRole = null;
	}

	/**
	 * Checks if all settings are checked within a section
	 * @param {object} section
	 * @return {boolean}
	 */
	checkedAll(section) {
		let checked = true;
		let keys 	= Object.keys(section);

		keys.forEach((i) => {
			if (!section[i].settings) {
				checked = false;
				return;
			}
			if (!section[i].settings.allowed) {
				checked = false;
			}
		});

		if (checked) {
			keys.forEach((i) => {
				section[i].settings.checkedAll = true;
			});
		}
		
		return checked;
	}

	searchPermissions(obj, query) {
		if (!obj || !query) {
			return obj;
		}

		let result = {};

		let sectionKeys = Object.keys(obj);
		sectionKeys.forEach((i) => {
			// Match the key
			let match = i.toLowerCase().indexOf(query) !== -1;

			if (!match) {
				let permKeys = Object.keys(obj[i]);
				permKeys.forEach((j) => {
					// Match the exact permission (ie employeeSearch.read)
					if ((i.toLowerCase() + '.' + j.toLowerCase()).indexOf(query) !== -1) {
						match = true;
					}

					// Match the description
					if (obj[i][j].description.toLowerCase().indexOf(query) !== -1) {
						match = true;
					}
				});
			}
			// Add the results
			if (match) {
				result[i] = obj[i];
			}
		});
		return result;
	}
}