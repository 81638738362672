import { Inject, Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';
import { employeeSearchSettings } from '../employee-search/employee-search.settings';

@Injectable({
	providedIn	: 'root', 
	useClass	: excludeUsersSettings
})
export class excludeUsersSettings {

	constructor(
		@Inject(employeeSearchSettings) private employeeSearchSettings:employeeSearchSettings) {
	}


	exclusionPanelConfig:any = {
		id				: 'panelTab_exclude_users',
		title			: 'Add Exclusions',
		titleTrans		: 'excludeUsers.panelTitle',
		subtitle		: 'Search an employee below to add or remove exclusions for specific employees',
		subtitleTrans	: 'excludeUsers.panelSubtitle',
		tab				: 'Exclude Users',
		tabTrans		: 'excludeUsers.panelTab',
		pagination		: {
			id				: 'excludeUsersPanelConfig',
			currentPage		: 1,
			offset			: 0,
			pageLimit		: '10',
			pageLimits		: ['10', '50', '100'],
			sortMode		: {field : 'lastName', direction : 'asc'}
		}
	};

	existingExclusionPanelConfig:any = {
		id				: 'panelTab_existing_existings',
		title			: 'Existing Exclusions',
		titleTrans		: 'excludeUsers.panelTitle',
		subtitle		: 'The following people have exclusions set',
		subtitleTrans	: 'excludeUsers.panelSubtitle',
		tab				: 'Existing Exclusions',
		tabTrans		: 'excludeUsers.panelTab',
		bodyClass 		: 'card-body p-0',
		pagination 		: {
			id				: 'existingExclusionsPanelConfig',
			currentPage		: 1,
			offset			: 0,
			pageLimit		: '10',
			pageLimits		: ['10', '50', '100'],
			sortMode		: {field : 'lastName', direction : 'asc'}
		}
	};

	settings:any = {
		removeSelf		: false,
		endPoint		: 'exclusions/searchEmployees'
	};


	searchFieldConfig:any = this.employeeSearchSettings.fieldConfig;

	searchFields:LioFieldArray = this.employeeSearchSettings.fields;

	allowedExclusions:Array<any> = [
		'lioEmails',
		'lioEnrollments',
		'scheduleEmails',
		'scheduleEnrollments',
	];

	fieldConfig:any = {
		'fieldName'			: 'excludeUsers',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: false,
		'addActiveField'	: true,
		'ccfFields': {
			'locked': 'employee.updateCCF'
		},
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name				: 'LIO Emails',
			nameTrans			: 'excludeUsers.lioEmails',
			model				: 'lioEmails',
			type				: 'checkselection',
			class				: 'center',
			visible				: true,
			export				: false,
		},
		{
			name				: 'LIO Enrollments',
			nameTrans			: 'excludeUsers.lioEnrollments',
			model				: 'lioEnrollments',
			type				: 'checkselection',
			class				: 'center',
			visible				: true,
			export				: false,
		},
		{
			name				: 'Scheduled Emails',
			nameTrans			: 'excludeUsers.scheduleEmails',
			model				: 'scheduleEmails',
			type				: 'checkselection',
			class				: 'center',
			visible				: true,
			export				: false,
		},
		{
			name				: 'Scheduled Enrollments',
			nameTrans			: 'excludeUsers.scheduleEnrollmnts',
			model				: 'scheduleEnrollments',
			type				: 'checkselection',
			class				: 'center',
			visible				: true,
			export				: false,
		},
		{
			name: 'Employee ID',
			model: 'employeeID',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'First Name',
			model: 'firstName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'text',
			visible	: true,
			sortable: true
		},
		{
			name: 'Address',
			model: 'address1',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Address 2',
			model: 'address2',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'City',
			model: 'city',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'State',
			model: 'state',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Country',
			model: 'country',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Phone #',
			model: 'phone',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Date Modified',
			model: 'updated',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Admin ID',
			model: 'AdminID',
			type	: 'text',
			visible	: false,
			sortable: true
		},
		{
			name: 'Added By',
			model: 'addedBy',
			type	: 'text',
			visible	: false,
		}
	);
}

