<section class="row mb-3" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-10 mx-auto">
		<!-- Email Form -->
		<lio-card headerText="Manage Catalog Collections" *ngIf="catalogs.length">
			<div lio-card-body>
				<!-- Repeat Fields -->
				<lio-model-editor [model]="model.collection" 
					[settings]="settings" 
					(onUpdate)="onupdate($event)" 
					(onOptionsLoaded)="onOptionsLoaded()" 
					(onModelSave)="onCollectionLoad($event)"
					(onModelLoad)="onCollectionLoad($event)">
				</lio-model-editor>
			</div>
		</lio-card>
	</div>
</section>
<section class="row mb-3">
	<div class="col-md-10 mx-auto">
		<lio-panel-tabber (onTabChange)="onTabChange" [hideErrors]="true">
			<lio-panel-tab [settings]="catalogConfig">
				<ng-container *lioPanelTabContent>
					<label class="form-control" *ngIf="!catalogs.length">Loading...</label>
					<mat-form-field *ngIf="catalogs.length" appearance="outline" class="w-100">
						<mat-label>Search Catalog</mat-label>
						<input matInput [(ngModel)]="query" autofocus/>
					</mat-form-field>
					<div lio-paginator 
						controlID="catalogID" 
						[collection]="catalogs" 
						[shallowWatch]="false"
						[filters]="query"
						[settings]="pagination" 
						(onResults)="updateFilteredCatalogs($event)">

						<lio-results-table
							paginatorID="catalogID"
							[fields]="catalogFields"
							[results]="filteredCatalogs"
							[prefixButtons]="catalogPrefixButtons">
						</lio-results-table>
					</div>
					<div class="card-footer">
						<div class="container-fluid">
							<lio-paginator-controls class="row" controlID="catalogID"></lio-paginator-controls>
							<!-- Buttons -->
							<div class="btn-toolbar">
								<button id="am_fields_selectAll" 
									mat-flat-button
									color="primary"
									[disabled]="!catalogs.length || model.collection.allCourses" 
									class="mr-1" 
									type="button" 
									(click)="selectAll()">

									<span>{{localeStrings.selectAll}}</span>
								</button>
								<button id="am_fields_clearSelected" 
									mat-flat-button
									color="primary"
									[disabled]="!model.collection || !model.collection.courseIDs.length || model.collection.allCourses" 
									type="button" 
									(click)="clearSelected()">

									<span>{{localeStrings.clear}}</span>
								</button>
							</div>
						</div>
					</div>
				</ng-container>
			</lio-panel-tab>
			<lio-panel-tab [settings]="employeeConfig">
				<ng-container *lioPanelTabContent>
					<lio-search-employees
						[fields]="employeeFields"
						[fieldConfig]="employeeFieldConfig"
						[hideHierarchy]="true"
						(onResults)="recieveResults($event)">
					</lio-search-employees>

					<div lio-paginator 
						controlID="searchEmployees" 
						[collection]="employees" 
						[settings]="pagination"
						[shallowWatch]="false"
						(onResults)="updateFilteredEmployees($event)">

						<lio-results-table
							paginatorID="searchEmployees"
							[fields]="employeeFields"
							[results]="filteredEmployees"
							[prefixButtons]="employeePrefixButtons">
						</lio-results-table>
					</div>
					<div class="mt-2">
						<lio-paginator-controls controlID="searchEmployees"></lio-paginator-controls>
						<!-- Buttons -->
						<div class="btn-toolbar">
							<button id="am_employees_selectAll" 
								mat-flat-button
								color="primary"
								[disabled]="!employees.length" 
								class="mr-1" 
								type="button" 
								(click)="selectAllEmployees()">

								<span>{{localeStrings.selectAll}}</span>
							</button>
							<button id="am_employees_clearSelected" 
								mat-flat-button
								color="primary"
								[disabled]="!model.collection || !model.collection.employeeIDs || !model.collection.employeeIDs.length" 
								type="button" 
								(click)="clearSelectedEmployees()">

								<span>{{localeStrings.clear}}</span>
							</button>
						</div>
					</div>
				</ng-container>
			</lio-panel-tab>
		</lio-panel-tabber>
	</div>
</section>