import { FormGroup } from '@angular/forms';
import { Component, Inject, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { MatFormFieldAppearance } from '@angular/material/form-field';
import { presetModelService } from '../../../../services/preset-model.service';

@Component({
	selector: 'lio-form-inputs',
	templateUrl: './form-inputs.component.html',
})
export class LioFormInputs implements OnInit, OnDestroy {
	@Input() model:any;

	@Input() fields:Array<any>;
	@Input() fieldConfig:any;
	@Input() formdata:any;
	@Input() builderCtrl?		:any = {};
	@Input() appearance: MatFormFieldAppearance = 'fill';
	@Output() onChanged: EventEmitter<any> = new EventEmitter();
	@Output() onSearch: EventEmitter<any> = new EventEmitter();
	private subscriptions:Subscription = NEVER.subscribe();

	private _parentGroup:FormGroup;
	public get parentGroup():FormGroup{
		return this._parentGroup;
	}
	@Input() public set	parentGroup(val:FormGroup){
		this._parentGroup = val;
		val.addControl('formInputs', this.formGroup);
	}

	public formGroup:FormGroup = new FormGroup({});

	constructor(
		@Inject(presetModelService)	private presetModelService :presetModelService,
	){
		this.subscriptions.add(
			this.presetModelService.modelReady.subscribe((preset:any) => {
				this.applyPreset(preset);
			})
		);
	}

	ngOnInit(){
		this.presetModelService.init();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Applies presets
	 * @param {array} presets
	 */
	applyPreset(preset) {
		preset.fields.forEach((preset) => {
			this.model[preset.field] = preset.value;
			this.fields.forEach((field) => {
				if (field.model == preset.field) {
					if (preset.locked) {
						field.locked = true;
					}
				}
			})
		});
	}
}