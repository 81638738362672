<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'cancel']">

	<!-- Header -->
	<header class="modal-header">
		<h5 id="am_modal_title" class="modal-title mx-auto">{{settings.title}}</h5>
	</header>

	<!-- Spinner and Progress -->
	<div class="spinner mx-auto">
		<i class="fa fa-3x fa-spinner fa-spin text-center"></i>
		<!-- Progress -->
		<section [hidden]="!settings.showProgress">
			<div class="modal-content text-center" [innerHtml]="settings.progress + '%'"></div>
			<div class="progress">
				<div class="progress-bar" role="progressbar" aria-valuenow="settings.progressNow" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ 'width' : settings.progress + '%' }"></div>
			</div> 		
		</section>
	</div>


	<!-- Footer -->
	<footer [hidden]="!settings.canCancel" class="modal-footer">
		<button class="btn btn-sm btn-primary mx-auto" mat-raised-button id="modal_cancel_button" (click)="cancel()">{{settings.cancel}}</button>
	</footer>
</div>
