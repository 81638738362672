/*
 * Service for Controlling the CCF values and fields
*/
import { Inject, Injectable } from '@angular/core';

import { settingsService } from './settings.service';
import { storageService } from './storage.service';
import { debugService } from './debug.service';
import { utilService } from './util.service';
import { querySettings } from './../settings/query.settings';

@Injectable({
	providedIn: 'root',
})
export class ccfService {
	
	constructor(
		@Inject(settingsService)		private settingsService		:settingsService, 
		@Inject(debugService)			private debugService		:debugService, 
		@Inject(storageService)			private storageService		:storageService, 
		@Inject(utilService)			private utilService			:utilService, 
		@Inject(querySettings) 			private querySettings		:querySettings
	){
		this.debugService.register('ccfservice', this);
	}

	/*
	 Adds CCF to fields
	*/
	/*
	 * Adds CCF fields to fields
	 * @param {array} fields
	 * @param {?array} ccfConfig
	 * @return {array}
	*/
	addCCFtoFields(fields, configuration) {
		if (!fields) {
			fields = [];
		}

		var ccfs = this.storageService.get('ccfs'),
			config = configuration && configuration['ccfFields'] ? configuration['ccfFields'] : {},
			options = [],
			booleanOptons = this.getBooleanOptions(),
			locked = typeof config['locked'] !== 'undefined' ? config['locked'] : false,
			exportable = typeof config['export'] !== 'undefined' ? config['export'] : true,
			visible = typeof config['visible'] !== 'undefined' ? config['visible'] : undefined;

		if(ccfs){
			let keys = Object.keys(ccfs);
			keys.forEach((key) => {
				let setting = this.settingsService.getSetting(key + 'Type');

				if (setting === 'boolean') {
					options = booleanOptons;
				}

				if (!setting) {
					setting = 'default';
				}

				if (setting == 'select') {
					if (this.settingsService.suppressCCFValues) {
						setting = 'default';
					} else {
						options = this.getCCFValueOptions('e.' + key);
					}
				}

				let field = {
					alias					: 'e',
					model					: key,
					name					: ccfs[key],
					isCCF					: true,
					type					: setting,
					options					: options,
					required				: false,
					checkForSpecialChars	: false,
					export					: exportable,
					checkForHTMl			: true,
					regex 					: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
					default					: '',
					locked					: locked,
					visible					: visible,
					disableOptionOverride 	: true,
					allowOptions 			: true,
					sortable				: true,
					placeholder 			: null
				};
				
				if(field.type == 'date' && !field.placeholder){
					field.placeholder = 'Date';
				}

				if (!this.fieldExists(fields, field)) {
					fields.push(field);
				}
			});
		}

		return fields;
	}

	/**
	 * Gets common boolean options
	 * @return {array}
	 */
	getBooleanOptions() {
		let options = [
			{
				'value': '1',
				'name': 'True',
				'trans': 'form.true',
				'selected': 0
			},
			{
				'value': '0',
				'name': 'False',
				'trans': 'form.false',
				'selected': 0
			}
		];
		return options;
	}

	/**
	 * Checks if the passed field already exists in a collection of fields, check is made based on model name
	 * @param {array} fields A collection of fields
	 * @param {object} newField The field to check for
	 * @return {boolean}
	 */
	fieldExists(fields, newField){ 
		let found = false;
		let keys = Object.keys(fields);
		keys.forEach((key) => {
			if(fields[key] && fields[key].model == newField.model){
				found = true;
			}
		});
		return found;
	}



	/*
	 * Adds CCF fields to filters
	*/
	addCCFtoFilters(filters) {
		if (!filters) {
			filters = [];
		}
		
		let ccfs 		= this.storageService.get('ccfs');
		let ccfValues 	= this.storageService.get('ccfValues');
			
		if (!ccfs) {
			return filters;
		}

		let keys = Object.keys(ccfs);
		keys.forEach((key) => {
			let eKey		= 'e.' + key;
			let options		= [];
			let multiple	= false;
			let type		= 'string';			

			// Treat all ccf values as a multiple select by default
			if (ccfValues) {
				let ccfValue = ccfValues[eKey];
				if (!ccfValue) {
					ccfValue = [];
				}
				if (!Array.isArray(ccfValue)) {
					ccfValue = [ccfValue];
				}
				ccfValue.forEach((value) => {
					value 		= value.trim();
					type 		= 'select';
					multiple 	= true;
					options.push({
						'name'		: value,
						'value'		: value,
					}); 
				});
			}

			let setting = this.settingsService[key + '.type'];


			let filter = {
				model					: eKey,
				name					: ccfs[key],
				field					: eKey,
				label					: ccfs[key],
				type					: type,
				multiple				: multiple,
				operators				: this.getOperatorsForType(setting, options),
				options					: options,
				visible					: true,
				suppressLocalization	: true,
				loadOptions				: true
			};

			switch(setting) {
				case 'date'	:
					filter['type'] 			= 'date';
					filter['loadOptions']	= false;
					break;
				case 'text':
					filter['type'] 			= 'string';
					filter['loadOptions']	= false;
					break;
				case 'boolean':
					filter['type'] 			= 'boolean';
					filter['multiple'] 		= false;
					filter['loadOptions']	= true;
					filter['options'] 		= this.utilService.copy(this.querySettings.booleanValues);
					break;	
				default:
					break;
			}

			if (filters.indexOf(filter) === -1) {
				filters.push(filter);
			}
		});

		return filters;
	}

	/**
	 * Get the operators associated with a field type
	 * @param {string} type
	 * @return {array} an array of operators
	 */
	getOperatorsForType(type, options) {
		let operatorsByType	= this.utilService.copy(this.querySettings.operatorsByType);

		if (options && options.length) {
			type = 'select';
		}
			
		switch (type) {
			case 'string':
				return operatorsByType.default;
			case 'boolean':
				return operatorsByType.boolean;
			case 'date':
				return operatorsByType.date;
			case 'select':
				return operatorsByType.multiSelect;
			default:
				return this.querySettings.operatorsByType.default;
		}
	}

	/**
	 * Gets the existing values for a ccf
	 */
	getCCFValueOptions(eKey) {
		let values 		= [];
		let ccfValues 	= this.storageService.get('ccfValues');

		if (ccfValues) {
			let ccfValue = ccfValues[eKey];
			if (ccfValue) {
				if (!Array.isArray(ccfValue)) {
					ccfValue = [ccfValue];
				}
				ccfValue.forEach((value) => {
					value 		= value.trim();
					values.push({
						'name'		: value,
						'value'		: value,
					}); 
				});
			}
			
		}

		return values;
	}


	/*
	 * Adds CCF fields to filters
	*/
	addCCFtoSelectFields(fields) {
		var ccfs = this.storageService.get('ccfs'),
			field;
		
			ccfs.forEach((ccf, key) => {
			field = {
				name: ccf,
				model: key,
				type: 'text'
			};
			if (fields.indexOf(field) == -1) {
				fields.push(field);
			}
		});
		return fields;
	}


	/*
	 * Gets the CCFs as a drop down option
	*/
	getCCFOptions() {
		let ccfs = this.addCCFtoFields([], {}),
			options = [];
		ccfs.forEach((ccf) => {
			if (!ccf.name) {
				ccf.name = ccf.model;
			}
			options.push({'value': ccf.model, 'name': ccf.name});
		});
		return options;
	}
}