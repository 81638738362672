<div id="page-top" 
	[ngClass]="{'lang-rtl':localizationService.rtl}" 
	class="p-0 pb-5 pb-sm-0 mb-5 {{contrast}}" 
	landing-scrollspy>
	
	<lio-title></lio-title>

	<!-- Wrapper-->
	<lio-nav-bar id="top" class="mb-3 sticky-top"></lio-nav-bar>

	<div *ngIf="navService.loaded" class="container-fluid fade p-0 p-sm-3" [ngClass]="{'show':!navService.loading}">
		<router-outlet></router-outlet>
	</div>

	<!-- Errors -->
	<section>
		<lio-critical-error></lio-critical-error>
	</section>

	<!-- Footer -->
	<lio-footer></lio-footer>
</div>