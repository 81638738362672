import { Injectable } from '@angular/core';

import { LioSelectOption } from '../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: querySettings
})
export class querySettings {
	queryToolSettings 	: any;
	defaultRules		: Array<any>;
	filters 			: Array<any>;
	booleanValues		: Array<any>;
	operators			: Array<any>;
	operatorsByType		: any;

	operatorOptions		: any = {
		equal 				: new LioSelectOption({nameTrans: 'filter.equal'			, name: 'equal'					, value: 'equal',				multiple: false, 	setNull: false}),
		not_equal 			: new LioSelectOption({nameTrans: 'filter.not_equal'		, name: 'not equal'				, value: 'not_equal',			multiple: false, 	setNull: false}),
		in 					: new LioSelectOption({nameTrans: 'filter.in'				, name: 'in'					, value: 'in',					multiple: false, 	setNull: false}),
		not_in 				: new LioSelectOption({nameTrans: 'filter.not_in'			, name: 'not in'				, value: 'not_in',				multiple: false, 	setNull: false}),
		begins_with 		: new LioSelectOption({nameTrans: 'filter.begins_with'		, name: 'begins with'			, value: 'begins_with',			multiple: false, 	setNull: false}),
		not_begins_with 	: new LioSelectOption({nameTrans: 'filter.not_begins_with'	, name: 'doesn\'t begin with'	, value: 'not_begins_with',		multiple: false, 	setNull: false}),
		not_ends_with 		: new LioSelectOption({nameTrans: 'filter.not_ends_with'	, name: 'doesn\'t end with'		, value: 'not_ends_with',		multiple: false, 	setNull: false}),
		contains 			: new LioSelectOption({nameTrans: 'filter.contains'			, name: 'contains'				, value: 'contains',			multiple: false,	setNull: false}),
		not_contains 		: new LioSelectOption({nameTrans: 'filter.not_contains'		, name: 'doesn\'t contain'		, value: 'not_contains',		multiple: false,	setNull: false}),
		ends_with 			: new LioSelectOption({nameTrans: 'filter.ends_with'		, name: 'ends with'				, value: 'ends_with',			multiple: false, 	setNull: false}),
		is_empty 			: new LioSelectOption({nameTrans: 'filter.is_empty'			, name: 'is empty'				, value: 'is_empty',			multiple: false, 	setNull: true}),
		is_not_empty 		: new LioSelectOption({nameTrans: 'filter.is_not_empty'		, name: 'is not empty'			, value: 'is_not_empty',		multiple: false, 	setNull: true}),
		is_null 			: new LioSelectOption({nameTrans: 'filter.is_null'			, name: 'is null'				, value: 'is_null',				multiple: false, 	setNull: true}),
		is_not_null 		: new LioSelectOption({nameTrans: 'filter.is_not_null'		, name: 'is not null'			, value: 'is_not_null',			multiple: false, 	setNull: true}),
		greater 			: new LioSelectOption({nameTrans: 'filter.greater'			, name: 'greater'				, value: 'greater',				multiple: false, 	setNull: false}),
		greater_or_equal 	: new LioSelectOption({nameTrans: 'filter.greater_or_equal'	, name: 'greater or equal'		, value: 'greater_or_equal',	multiple: false, 	setNull: false}),
		less 				: new LioSelectOption({nameTrans: 'filter.less'				, name: 'less'					, value: 'less',				multiple: false, 	setNull: false}),
		less_or_equal 		: new LioSelectOption({nameTrans: 'filter.less_or_equal'	, name: 'less or equal'			, value: 'less_or_equal',		multiple: false, 	setNull: false}),
		between 			: new LioSelectOption({nameTrans: 'filter.between'			, name: 'between'				, value: 'between',				multiple: true, 	setNull: false}),
		not_between 		: new LioSelectOption({nameTrans: 'filter.not_between'		, name: 'not between'			, value: 'not_between', 		multiple: true, 	setNull: false}),
		within_past_days 	: new LioSelectOption({nameTrans: 'filter.within_past_days'	, name: 'is within past x days'	, value: 'within_past_days', 	multiple: false, 	setNull: false, noDate:true})
	};

	constructor(){
		this.queryToolSettings = {
			'type'					: 'default',
			'builderID' 			: 'builder',
			'destroyResult' 		: 'true',
			'hasButtons'			: true,
			'usesQueryTool'			: true,
			'addBaseFilters'		: true,
			'addCCFtoFilters'		: true,
			'addRoleFilter'			: true,
			'confirmationTotal'		: 10000,
			'savePrivately' 		: 'query.savePrivately',
			'savePublicly'			: 'query.savePublicly',
			'saverole'				: 'query.saveRole',
			'text'				: {
				'runReport' : 'query.runReport',				
			},
			'filters': [],
			'feedback'				: {
				'noRecords'				: 'noEmployeesFound',
				'tooMany'				: 'tooManyFound',
				'confirmLoad'			: 'confirmLoad',
				'pushNotificationTitle'	: 'notifications.learnIOSentMessage',
				'pushNotificationMsg' 	: 'notifications.queryReady',
			},
			'endpoints'				: {
				'get'					: 'query/getAllByType',
				'save'					: 'query/save',
				'delete'				: 'query/deleteByID',
				'report'				: 'dashboard/getEmployeesByRules',
			},
			'visibilityOptions': [
				{
					'name'		: 'All Users',
					'value'		: 'public',
					'locked'	: 'query.savePublicly',
				},
				{
					'name'		: 'Just me',
					'value'		: 'private',
					'locked'	: 'query.savePrivately',
				},
				{
					'name'		: 'Current Role',
					'value'		: 'currentRole',
					'locked'	: 'query.saveRole',
				}
			],
		};

		this.defaultRules = [
				{
					field: 'e.inactive',
					id: 'e.inactive',
					operator: 'equal',
					value: 0
				}
		];

		this.filters = [
			{
				field: 'e.employeeID',
				label: 'form.employeeID',
				type: 'string',
				placeholder: 'form.enterEmployeeID',
				visible: true,
			},
			{
				field: 'e.firstName',
				label: 'form.firstName',
				type: 'string',
				placeholder: 'form.enterFirstName',
				visible: true,
			},
			{
				field: 'e.lastName',
				label: 'form.lastName',
				type: 'string',
				placeholder: 'form.enterLastName',
				visible: true,
			},
			{
				field: 'e.email',
				label: 'form.email',
				type: 'string',
				placeholder: 'form.enterEmail',
				visible: true,
			},
			{
				field: 'e.city',
				label: 'form.city',
				type: 'string',
				placeholder: 'form.enterCity',
				visible: true,
			},
			{
				field: 'e.state',
				label: 'form.state',
				type: 'string',
				operators: [
					this.operatorOptions.equal, 
					this.operatorOptions.not_equal, 
					this.operatorOptions.in, 
					this.operatorOptions.not_in, 
					this.operatorOptions.is_null, 
					this.operatorOptions.is_not_null
				],
				visible: true,
			},
			{
				field: 'e.country',
				label: 'form.country',
				type: 'string',
				operators: [
					this.operatorOptions.equal, 
					this.operatorOptions.not_equal, 
					this.operatorOptions.in, 
					this.operatorOptions.not_in, 
					this.operatorOptions.is_null, 
					this.operatorOptions.is_not_null
				],
				visible: false,
			},
			{
				field: 'e.addDate',
				label: 'form.addDate',
				type: 'date',
				operators: [
					this.operatorOptions.equal, 
					this.operatorOptions.not_equal, 
					this.operatorOptions.greater, 
					this.operatorOptions.greater_or_equal, 
					this.operatorOptions.less, 
					this.operatorOptions.less_or_equal, 
					this.operatorOptions.between, 
					this.operatorOptions.not_between, 
					this.operatorOptions.is_null, 
					this.operatorOptions.is_not_null,
					this.operatorOptions.within_past_days
				],
				visible: true,
			},
			{
				field: 'fn.daysadded',
				label: 'form.daysAdded',
				type: 'integer',
				operators: [
					this.operatorOptions.equal, 
					this.operatorOptions.not_equal,
					this.operatorOptions.greater_or_equal, 
					this.operatorOptions.less_or_equal, 
				],
				visible: true,
			},
			{
				field: 'e.inactive',
				label: 'form.status',
				type: 'select',
				options: [
					{
						'value': 0,
						'name': 'Active',
						'nameTrans': 'form.active'
					},
					{
						'value': 1,
						'name': 'Inactive',
						'nameTrans': 'form.inactive'
					},
				],
				operators: [this.operatorOptions.equal],
				visible: true,
				disableOptionOverride : true,	
			},
			{
				field: 'limit',
				label: 'form.limit',
				type: 'integer',
				width: '200px',
				operators: [this.operatorOptions.equal],
				locked: 'filters.limit',
				visible: true
			}
		];

		this.booleanValues = [
			{
				'value': 1,
				'name': 'True',
				'nameTrans': 'form.true'
			},
			{
				'value': 0,
				'name': 'False',
				'nameTrans': 'form.false'
			},
		];

		this.operators = [
			this.operatorOptions.equal,
			this.operatorOptions.not_equal,
			this.operatorOptions.in,
			this.operatorOptions.not_in,
			this.operatorOptions.begins_with,
			this.operatorOptions.not_begins_with,
			this.operatorOptions.not_ends_with,
			this.operatorOptions.contains,
			this.operatorOptions.not_contains,
			this.operatorOptions.ends_with,
			this.operatorOptions.is_empty,
			this.operatorOptions.is_not_empty,
			this.operatorOptions.is_null,
			this.operatorOptions.is_not_null,
			this.operatorOptions.greater,
			this.operatorOptions.greater_or_equal,
			this.operatorOptions.less,
			this.operatorOptions.less_or_equal,
			this.operatorOptions.between,
			this.operatorOptions.not_between,
		];

		this.operatorsByType = {
			date : [
				this.operatorOptions.equal,
				this.operatorOptions.not_equal,
				this.operatorOptions.greater,
				this.operatorOptions.greater_or_equal,
				this.operatorOptions.less,
				this.operatorOptions.less_or_equal,
				this.operatorOptions.between,
				this.operatorOptions.not_between,
				this.operatorOptions.is_null,
				this.operatorOptions.is_not_null,
				this.operatorOptions.within_past_days
			],
			default : [
				this.operatorOptions.equal,
				this.operatorOptions.not_equal,
				this.operatorOptions.in,
				this.operatorOptions.not_in,
				this.operatorOptions.begins_with,
				this.operatorOptions.not_begins_with,
				this.operatorOptions.not_ends_with,
				this.operatorOptions.contains,
				this.operatorOptions.not_contains,
				this.operatorOptions.ends_with,
				this.operatorOptions.is_empty,
				this.operatorOptions.is_not_empty,
				this.operatorOptions.is_null,
				this.operatorOptions.is_not_null
			],
			string : [
				this.operatorOptions.equal,
				this.operatorOptions.not_equal,
				this.operatorOptions.in,
				this.operatorOptions.not_in,
				this.operatorOptions.begins_with,
				this.operatorOptions.not_begins_with,
				this.operatorOptions.not_ends_with,
				this.operatorOptions.contains,
				this.operatorOptions.not_contains,
				this.operatorOptions.ends_with,
				this.operatorOptions.is_empty,
				this.operatorOptions.is_not_empty,
				this.operatorOptions.is_null,
				this.operatorOptions.is_not_null
			],
			select : [
				this.operatorOptions.equal,
				this.operatorOptions.not_equal,
				this.operatorOptions.is_null,
				this.operatorOptions.is_not_null
			],
			multiSelect : [
				this.operatorOptions.in,
				this.operatorOptions.not_in,
				this.operatorOptions.is_null,
				this.operatorOptions.is_not_null
			],
			boolean : [
				this.operatorOptions.equal
			]		
		};
	}
}