import { Inject, Injectable } from '@angular/core';

import moment from 'moment';

import { profileSettings } from '../profile/profile.settings';
import { emailEventService } from '../../services/email-event.service';
import { coursesService } from '../../services/courses.service';
import { permissionService } from '../../services/permissions.service';

import { querySettings } from '../../settings/query.settings';
import { LioDateField, LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEmailEventSettings
})
export class scheduleEmailEventSettings {
	constructor(
		@Inject(profileSettings)	private profileSettings		:profileSettings,
		@Inject(emailEventService)	private emailEventService	:emailEventService,
		@Inject(permissionService)	private permissionService	:permissionService,
		@Inject(coursesService)		private coursesService		:coursesService,
		@Inject(querySettings) 		private querySettings		:querySettings
	){}

	appearance:string = 'outline';

	employeeFields:any  = this.profileSettings.externalFields;
	
	employeeConfig:any = {
		'fieldName'			: 'emailEventExport',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: true,
	};


	fieldConfig:any = {
		'fieldName'			: 'scheduleemails',
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: true,
		'addLangField'		: false,
		'addActiveField'	: false,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			model		: 'name',
			type		: 'text',
			required	: true,
			visible		: true
		},
		{
			name		: 'Description',
			model		: 'description',
			type		: 'textarea',
			required	: true,
			visible		: true
		},
		new LioSearchableSelectField({
			name		: 'Type',
			model		: 'type',
			type		: 'select',
			options 	: [
				{name : 'Course Overdue'	, trans : 'emailEvent.pastDue'		, value : 'Past Due'},
				{name : 'Course Registered'	, trans : 'emailEvent.registration'	, value : 'Registration'},
				{name : 'Reminder'			, trans : 'emailEvent.reminder'		, value : 'Reminder'}],
			required	: true,
			lockWithID  : true, // Temp fix for field locking based on conditions 
			visible		: true
		}),
		{
			name 		: 'Enter minimum number of days past due date',
			model		: 'filterValue',
			nameTrans 	: 'scheduleEmails.pastDueLabel',
			type		: 'number',
			min 		: 1,
			max 		: 10000,
			required	: false,
			visible		: true,
			conditions  : [{'type': 'Past Due'}],
		},
		{
			name 		: 'Enter number of days until due Date',
			model		: 'filterValue',
			nameTrans 	: 'scheduleEmails.untilDueLabel',
			type		: 'number',
			min 		: 1,
			max 		: 10000,
			required	: false,
			visible		: true,
			conditions  : [{'type': 'Reminder'}],
		},
		new LioSearchableSelectField({
			name 		: 'For Someone in these course(s)',
			model		: 'courseID',
			nameTrans 	: 'scheduleEmails.someoneInCourse',
			type		: 'select',
			options 	: this.coursesService.activeCourseOptionsRepSub,
			required	: true,
			multiple	: true,
			visible		: true,
			searchable 	: true
		}),
		new LioSearchableSelectField({
			name 		: 'Which E-Mail Template(s) should we use?',
			model		: 'actions',
			nameTrans 	: 'scheduleEmails.whichTemplate',
			type		: 'select',
			searchable 	: true,
			multiple 	: true,
			options 	: this.emailEventService.getEmailTemplates(),
			filters 	: [(templates = [], model = null) => {
				if(templates && model){
					return templates.filter((template) => {
						return template.type == model.type;
					});
				}
				return templates;
			}],
			optionAsValue 	: true,
			conditions  	: ['type'],
			required		: true,
			visible			: true
		}),
		{
			name 		: 'Should Managers be CC\'d on emails?',
			model		: 'ccManagers',
			nameTrans 	: 'scheduleEmails.wouldYouLikeMangerEmailed',
			type		: 'boolean',
			required	: false,
			visible 	: () => {return this.permissionService.hasPermission('email.ccManagers');}
		},
		{
			name		: 'Email Filters',
			model		: 'filters',
			type		: 'eventFilters',
			required	: true,
			visible		: false,
		},
		new LioSearchableSelectField({
			name 		: 'How often should this run?',
			model		: 'recurranceType',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.reccurInterval',
			type		: 'select',
			options 	: [
				{name : 'Once'		, trans : 'scheduleEmails.once'		, value : 'once', selected : true},
				{name : 'Daily'		, trans : 'scheduleEmails.daily'	, value : 'daily'},
				{name : 'Weekly'	, trans : 'scheduleEmails.weekly'	, value : 'weekly'},
				{name : 'Monthly'	, trans : 'scheduleEmails.monthly'	, value : 'monthly'},
			],
			required	: true,
			visible		: true
		}),
		{
			name 		: 'On these days of the week',
			model		: 'days',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.theseWeekDays',
			type		: 'eventDays',
			required	: true,
			visible		: true,
			weekDays 					: this.emailEventService.weekDays,
			moreThanOneWeekDayEnabled 	: this.emailEventService.moreThanOneWeekDayEnabled,
			conditions  : [{'schedule.recurranceType': 'daily'}],
		},
		new LioSearchableSelectField({
			name 		: 'On this day of the week',
			model		: 'recurranceDay',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.thisWeekDay',
			type		: 'select',
			required	: true,
			visible		: true,
			options 	: this.emailEventService.weekDays,
			conditions  : [{'schedule.recurranceType': 'weekly'}],
			disableOverride: true,
		}),
		{
			name 		: 'On this day of the month',
			model		: 'recurranceDay',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.thisDayOfMonth',
			type		: 'range',
			min 		: 1,
			max 		: 28,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'monthly'}],
			disableOverride: true,
		},
		new LioDateField({
			name 		: 'On this date',
			model		: 'startTime',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.startDate',
			type		: 'date',
			minDate 	: (this.emailEventService.getMinEventStartDate() as moment.Moment).toDate(),
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'once'}],
		}),
		new LioDateField({
			name 		: 'Begin sending Emails on',
			model		: 'startTime',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.startDateUntil',
			type		: 'date',
			minDate 	: (this.emailEventService.getMinEventStartDate() as moment.Moment).toDate(),
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': '!once'}],
			blackoutDates : this.emailEventService.isBlackoutDate,
		}),
		{
			name 		: 'Every X Weeks',
			model		: 'recurranceDetail',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.everyWeeks',
			type		: 'range',
			min 		: 1,
			max			: 52,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'weekly'}],
		},
		{
			name 		: 'Every X Months',
			model		: 'recurranceDetail',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEmails.everyMonths',
			type		: 'range',
			min 		: 1,
			max 		: 12,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'monthly'}],
		},
	);

	model:any = {
		id 					: null,
		name 				: '',
		description			: '',
		type 				: '',
		courseIDs			: [],
		actions 			: [],
		filters 			: [],
		rules 				: [],
		filterValue 		: 0,
		schedule 			: {
			startTime			: this.emailEventService.getMinEventStartDate(true),
			endTime				: null,
			recurranceType 		: 'once',
			recurranceDetail 	: '',
			recurranceDay 		: '',
			sunday				: false, 
			monday				: true, 
			tuesday				: true, 
			wednesday			: true, 
			thursday			: true, 
			friday				: true, 
			saturday			: false
		}
	};

	filterTemplates:any = {
		'Past Due':{
			name				: 'Enter minimum number of days past due date',
			nanError 			: 'The value entered was not a number',
			minError			: 'The minimum number of days past the due date must be greater than 0',
			maxError			: 'The minimum number of days past the due date must be less than 10000',
			field				: 'fn.dayspastdue',
			type				: 'number',
			operator			: '>=',
			value				: 1,
			min					: 1,
			max					: 10000,
			required			: true

		},
		'Reminder' : {
			name		: 'Enter number of days until Date',		
			nanError	: 'The value entered was not a number',
			minError	: 'The number of days until the due date must be greater than 0',
			maxError	: 'The number of days until the due date must be less than 10000',
			field 		: 'fn.daysuntilcoursedue',
			type 		: 'number',
			operator 	: '<=',
			value 		: 1,
			min			: 1,
			max			: 10000,
			required	: true
		}
	};

	weekDays:Array<any> = [
		{name: 'sun', trans : 'scheduleEmails.sundayShort'		, value: 'sunday'},
		{name: 'mon', trans : 'scheduleEmails.mondayShort'		, value: 'monday'},
		{name: 'tue', trans : 'scheduleEmails.tuesdayShort'		, value: 'tuesday'},
		{name: 'wed', trans : 'scheduleEmails.wednesdayShort'	, value: 'wednesday'},
		{name: 'thu', trans : 'scheduleEmails.thursdayShort'	, value: 'thursday'},
		{name: 'fri', trans : 'scheduleEmails.fridayShort'		, value: 'friday'},
		{name: 'sat', trans : 'scheduleEmails.saturdayShort'	, value: 'saturday'}
	];

	filters:any = [
		{
			'field': 'ec.courseCompletion',
			'label': 'form.courseCompleted',
			'type': 'select',
			'visible': true,
			'width': '200px',
			'options': [
				{
					'value': 1,
					'name': 'Completed',
					'nameTrans': 'form.completed'
				},
				{
					'value': 0,
					'name': 'Incomplete',
					'nameTrans': 'form.incomplete'
				},
			],
			'operators': [this.querySettings.operatorOptions.equal],
			'disableOptionOverride' : true,	

		},
	];

	builderSettings:any = {
		'type'					: 'scheduledEmails',
		'addBaseFilters'		: true,
		'hasButtons'			: true,
		'addCCFtoFilters'		: true,
		'addRoleFilter'			: true,
		'usesQueryTool'			: false,
		'showSaver'				: false,
		'filterOverrideID' 		: 'scheduledEmails',
		'hideDelete' 			: false,
		'hideRunReport'			: true,
		'suppressDefaultRules'	: true,
	};
}