<div localize [model]="localizations" [items]="localizationItems" class="row">
	<div class="col-md-10 offset-md-1">
		<lio-panel-tabber (onTabChange)="onTabChange()">
			<lio-panel-tab [settings]="panelConfigs.formAdd">
				<form *lioPanelTabContent name="form" role="form" autocomplete="off" novalidate>
					<lio-form-inputs
						[fields]="fields"
						[fieldConfig]="fieldConfig"
						[model]="employee"
						(onChanged)="onUpdate()">
					</lio-form-inputs>
					<div class="btn-toolbar">
						<button id="am_addusers_submit" [disabled]="!allowSubmit" (click)="submit()" class="btn btn-sm btn-primary  mr-1 mb-1" type="submit"><strong>{{localizations.add}}</strong></button>
					</div>
				</form>
			</lio-panel-tab>
			<lio-panel-tab [settings]="panelConfigs.bulkAdd">
				<!-- Bulk Upload form -->
				<form *lioPanelTabContent name="form" role="form" autocomplete="off" novalidate>
					<div flow-drop class="border-dotted rounded mb-2 p-2">
						<div>{{localizations.dragExcel}}</div>
						<button mat-button color="primary" (click)="getTemplate()" class="ml-2" type="submit">
							<span class="font-weight-bold">{{localizations.getTemplate}}</span>
						</button>
						<span>|</span>
						<button mat-button color="primary" (click)="getSample()" type="submit">
							<span class="font-weight-bold">{{localizations.getSample}}</span>
						</button>

						<div class="form-check">
							<div class="alert alert-dismissible" [ngClass]="model.processType  == 'DELTA' || model.processType  == 'PARTIAL_FILE' ? 'alert-info' : model.processType  == 'FULL_FILE' ? 'alert-success' : 'alert-danger'" alert-dismissible role="alert">
								<lio-searchable-select
									[model]="model"
									[options]="processTypes"
									[field]="processTypeField"
									(onUpdate)="onUpdate()"
									appearance="outline">
								</lio-searchable-select>
								<span *ngIf="model.processType == ''">
									<i [innerHtml]="localizations.makeSelection | html"></i>
								</span>
								<span *ngIf="model.processType == 'FULL_FILE'" [innerHtml]="localizations.full | html">
								</span>
								<span *ngIf="model.processType == 'DELTA'" [innerHtml]="localizations.delta | html">
								</span>
								<span *ngIf="model.processType == 'PARTIAL_FILE'" [innerHtml]="localizations.partial | html">
								</span>
							</div>
						</div>
					</div>
					<div>
						<lio-file-uploader [settings]="uploadSettings" 
							[matchBootstrap]="false"
							[disabled]="!model.processType" 
							(success)="fileAdded($event)">
						</lio-file-uploader>
						<button id="am_bulkusers_process" 
							mat-flat-button
							color="primary"
							(click)="processFile()" 
							[disabled]="!readyForProcess || processedFile" 
							class="ml-2" 
							type="submit">{{localizations.process}}</button>
					</div>
				</form>
			</lio-panel-tab>
		</lio-panel-tabber>

		<div [hidden]="!filteredEmployees.length">
			<lio-card body-class="p-0" footer-class="container-fluid" [hideErrors]="true">
				<div lio-card-body lio-paginator controlID="addusers" [collection]="employees" [settings]="pagination" (onResults)="updateFilteredEmployees($event)">
					<lio-results-table
						paginatorID="addusers"
						[fields]="fields"
						[fieldConfig]="fieldConfig"
						[results]="filteredEmployees"
						[prefixButtons]="prefixButtons">
					</lio-results-table>
				</div>
				<div lio-card-footer>
					<lio-paginator-controls class="row" controlID="addusers"></lio-paginator-controls>
					<div class="btn-toolbar">
						<button id="am_employeesearch_assigncourses" [hidden]="!permissionService.hasPermission('enrollment.enroll')" class="btn btn-sm btn-primary mr-1" type="button" (click)="assignCourses()"><span>{{localizations.assignCourses}}</span></button>
						<button id="am_employeesearch_exportrecords" [hidden]="!permissionService.hasPermission('reports.export')" class="btn btn-sm btn-primary mr-1" type="button" (click)="exportRecords()"><span>{{localizations.exportRecords}}</span>&nbsp;<i class="fa fa-file-excel ml-1" aria-hidden="true"></i></button>
						<button id="am_employeesearch_emailusers" [hidden]="!permissionService.hasPermission('email.sendToUsers')" class="btn btn-sm btn-primary mr-1" type="button" (click)="sendEmailToAll()"><span>{{localizations.emailUsers}}</span>&nbsp;<i class="fa fa-envelope ml-1" aria-hidden="true"></i></button>
						<button id="am_employeesearch_emailaddedusers" [hidden]="!processedFile || !permissionService.hasPermission('email.sendToUsers')" class="btn btn-sm btn-primary mr-1" type="button" (click)="sendEmailToAdded()" [disabled]="!addedUsers.length"><span >{{localizations.emailAddedUsers}}</span>&nbsp;<i class="fa fa-envelope ml-1" aria-hidden="true"></i></button>
						<button id="am_employeesearch_exportreport" [hidden]="!processedFile || !permissionService.hasPermission('reports.export')" class="btn btn-sm btn-primary" type="button" (click)="exportReport()"><span>{{localizations.exportProcessReport}}</span>&nbsp;<i class="fa fa-file-excel ml-1" aria-hidden="true"></i></button>
					</div>
				</div>
			</lio-card>
		</div>
	</div>
</div>