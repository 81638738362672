import { Inject, Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';
import { permissionService } from '../../services/permissions.service';


@Injectable({
	providedIn	: 'root', 
	useClass	: trainingSettings
})
export class trainingSettings {
	constructor(
		@Inject(permissionService) 	private permissionService	:permissionService,
	){}


	panelConfig:any = {
		coursesTab : {
			id				: 'panelTab_training_courses_form',
			tab				: 'My Courses',
			tabTrans		: 'training.myCourses',
			bodyClass 		: 'noclass',
			settings	: {
				id: 				'training',
				getTrainingEndPoint: 'training/getTraining',
				localeStrings: {
					noCoursesAssigned: 'noCoursesAssigned',
				}
			},
			visible 	: () =>  { 
				return this.permissionService.hasPermission('courses.read');
			},
			reloadOnTabChange: true
		},
		expireCoursesTab : {
			id				: 'panelTab_training_expired_courses_form',
			tab				: 'Expired Courses',
			tabTrans		: 'training.expiredCourse',
			bodyClass 		: 'noclass',
			settings	: {
				id: 				'expired',
				getTrainingEndPoint: 'training/getExpired',
				localeStrings: {
					noCoursesAssigned: 'noExpiredCoursesAssigned',

				}
			},
			visible 	: () =>  { 
				return this.permissionService.hasPermission('courses.readExpired');
			},
			reloadOnTabChange: true
		},
		selfEnroll : {
			id 			: '',
			tab			: 'Self Enrollment',
			tabTrans 	: 'selfenroll.title',
			link 		: 'selfenroll',
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.selfEnroll');
			}
		},
		pagination : {
			id				: 'trainingPanelConfig',
			pageLimit		: '100000',
			pageLimits		: ['100000'],
			sortMode		: {field : 'startTime', direction : 'desc'}
		},
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Course ID',
			nameTrans	: 'training.courseID',
			model		: 'courseID',
			sortable 	: true,
			class 		: 'text-left',
			permission  : 'training.viewCourseID',
			setting 	: 'showCourseID',
		},
		{
			name		: 'Course Name',
			nameTrans	: 'training.courseName',
			model		: 'courseName',
			sortable 	: true,
			class 		: 'text-left w-100',
			permission  : 'bypass',
			setting  	: 'bypass',
		},
		{
			name		: 'Due Date',
			nameTrans	: 'training.courseDueDate',
			model		: 'courseDueDate',
			sortable 	: true,
			class 		: 'd-none d-lg-table-cell',
			permission  : 'bypass',
			setting  	: 'bypass',
		},
		{
			name		: 'Required',
			nameTrans	: 'training.required',
			model		: 'required',
			sortable 	: true,
			permission  : 'bypass',
			setting  	: 'bypass',
		},
		{
			name		: 'Status',
			nameTrans	: 'training.courseCompletion',
			model		: 'courseCompletion',
			sortable 	: true,
			class 		: 'd-none d-lg-table-cell',
			permission  : 'bypass',
			setting  	: 'bypass',
		},
	);
}