import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { debugService } from '../../services/debug.service';
import { navService } from '../../services/nav.service';
import { loaderService } from '../../services/loader.service';
import { feedbackService } from '../../services/feedback.service';
import { utilService } from '../../services/util.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';

import { excludeUsersSettings } from './exclude-users.settings';

@Component({
	selector: 'lio-exclude-users',
	templateUrl: './exclude-users.component.html'
})
export class ExcludeUsersComponent implements OnDestroy {	
	public exclusionPanelConfig			:any			= this.excludeUsersSettings.exclusionPanelConfig;
	public exclusionsPagination			:any			= this.exclusionPanelConfig.pagination;

	public existingExclusionPanelConfig	:any  			= this.excludeUsersSettings.existingExclusionPanelConfig;
	public existingExclusionsPagination	:any			= this.existingExclusionPanelConfig.pagination;

	public searchFields					:Array<any>		= this.excludeUsersSettings.searchFields;
	public searchFieldConfig			:any			= this.excludeUsersSettings.searchFieldConfig;
	public settings						:any			= this.excludeUsersSettings.settings;
	public existingExclusions			:Array<any>		= [];
	public filteredExclusions			:Array<any>		= [];
	public hasExclusions				:boolean		= false;
	public fields						:Array<any>		= this.excludeUsersSettings.fields;
	public fieldConfig					:any			= this.excludeUsersSettings.fieldConfig;
	public employees					:Array<any> 	= [];
	public filteredEmployees			:Array<any> 	= [];

	public localeStrings				:any = {
		addExclusions			:'Add Exclusion',
		addExclusionsTrans		:'excludeUsers.addExclusions',
		modifyExclusions		:'Modify Exclusion',
		modifyExclusionsTrans	:'excludeUsers.modifyExclusions'
	};

	public localeStringsKeys			:Array<any> 	= [
		'addExclusions',
		'modifyExclusions'
	];

	private allowedExclusions			:Array<any>		= this.excludeUsersSettings.allowedExclusions;
	private selectedExclusions			:Array<any>		= [];

	private subscriptions				:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(debugService)			public debugService		:debugService,
		@Inject(navService)				public navService		:navService,
		@Inject(loaderService)			public loaderService	:loaderService,
		@Inject(feedbackService)		public feedbackService	:feedbackService,
		@Inject(utilService)			public utilService		:utilService,
		@Inject(lmsService)				public lmsService		:lmsService,
		@Inject(lioModalService)		public lioModalService	:lioModalService,
		@Inject(excludeUsersSettings)	public excludeUsersSettings	:excludeUsersSettings
	){
		this.debugService.register('excludeUsers', this);
		this.navService.setActivePage('excludeUsers');

		//Init
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.getexclusions().then(() =>{
					this.navService.displayPage();
				});
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	* On Field Click from the results_table
	*/
	onFieldClick(event) {
		setTimeout(() => {
			this.check(event.item, event.field);
		});
	}

	/*
	* (De)Selects the exclusion
	*/
	check(employee, field) {
		let model 		= field.model,
			selected 	= employee[model],
			employeeID 	= employee.employeeID,
			exclusion 	= {},
			id 			= null;

		this.allowedExclusions.forEach((exclusionName) => {
			if (model === exclusionName) {
				exclusion = {
					'employeeID'	: employeeID,
					'exclusionName'	: exclusionName,
					'selected'		: selected,
				};
				id = JSON.stringify(employeeID + model);
				this.selectedExclusions[id] = exclusion;
			}
		});
		this.hasExclusions = !this.utilService.isEmpty(this.selectedExclusions);
	}

	/*
	 * Checks if an employee is already being excluded
	 * @param {object} employee
	 * @return {boolean}
	*/
	exists(employee) {
		let exists = false;
		this.existingExclusions.forEach((existingExclusion) => {
			if (existingExclusion.employeeID === employee.employeeID) {
				exists = true;
			}
		});

		return exists;
	}

	/*
	 * Adds exclusions
	*/
	addExclusions() {
		let exclusions 	= [],
			i 			= null;

		// Exclusions need to be formatted as an array for the backend
		for (i in this.selectedExclusions) {
			exclusions.push(this.selectedExclusions[i]);
		}

		this.hasExclusions = false;	
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('exclusions/addExclusion', {'exclusions': exclusions}).then((result) => {
			this.lioModalService.hideLoading();
			this.selectedExclusions = [];
			
			if (result.success){
				this.lioModalService.show('savedSuccessfully');
				this.getexclusions();
			}
		});
	}

	/*
	 * Tab change
	*/
	onTabChange() {
		this.feedbackService.clearAll();
	}

	/*
	 * Removes exclusions
	 * @param {object} employee
	*/
	removeExclusion(employee){
		this.lioModalService.showLoading('processing');

		this.hasExclusions = false;	
		return this.lmsService.post('exclusions/removeExclusion', {inheritorEmployeeID : employee['employeeID']}).then((result) => {
			this.lioModalService.hideLoading();
		
			if(result.success){
				this.existingExclusions = result.properties.exclusions;
				this.hasExclusions = false;	
				this.lioModalService.show('excludeUsers.removeSuccess');
			} else {
				this.lioModalService.show('excludeUsers.removeFail');
			}
		});
	}

	/*
	 * Sets the employees
	 * @param {array} employees
	*/
	recieveResults(employees) {
		this.employees = employees;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredExclusions(collection) {
		this.filteredExclusions = collection.filtered;
	}

	/**
	 * Gets the list of existing exclusions
	 */
	getexclusions() {
		return this.lmsService.post('exclusions/getAll').then((result) => {
			if (result.success) {
				this.existingExclusions = result.properties.exclusions;
			}
		});
	}
}