import { Inject, Injectable } from '@angular/core';
import { dashboardSettings } from '../components/dashboard/dashboard.settings';
import { enrollmentSettings } from '../components/enrollments/enrollments.settings';
import { querySettings } from './query.settings';
import { scheduleEnrollmentFilterSettings } from '../components/schedule-enrollments/schedule-enrollment-filter.settings';

@Injectable({
	providedIn	: 'root', 
	useClass	: filterSettings
})
export class filterSettings {
	constructor(
		@Inject(dashboardSettings) private dashboardSettings:dashboardSettings,
		@Inject(enrollmentSettings) private enrollmentSettings:enrollmentSettings,
		@Inject(querySettings) private querySettings:querySettings,
		@Inject(scheduleEnrollmentFilterSettings) private scheduleEnrollmentFilterSettings:scheduleEnrollmentFilterSettings,
	){}

	panelConfig:any = {
		'page' : {
			id				: 'panelTab_filter_page_editor',
			title			: 'Filter Editor',
			subtitle		: 'Filter Editor',
			tab				: 'Filter Editor',
			reloadOnTabChange: true,
		}
	};

	dataModel:any = [
			{
				'name': 'type',
				'type': 'string',
			},
			{
				'name': 'visible',
				'type': 'boolean',	
			},
			{
				'name': 'locked',
				'type': 'boolean',	
			},
			{
				'name': 'required',
				'type': 'boolean',	
			},
			{
				'name': 'multiple',
				'type': 'boolean',	
			},
			{
				'name': 'customOptions',
				'type': 'boolean',	
			},
			{
				'name': 'loadOptions',
				'type': 'boolean',	
			},
			{
				'name': 'segregation',
				'type': 'string',	
			},
			{
				'name': 'global',
				'type': 'boolean',	
			}
	];

	segregationOptions:any = [
		{
			'name'	: 'employeeSearch.defaultHierarchy', 
			'value'	: '',
			'visible' : 'employeeSearch.defaultHierarchy',
			'trans' 	: 'form.defaultHierarchy'
		},
		{
			'name'	: 'employeeSearch.bypassHierarchy', 
			'value'	: 'bypass',
			'visible' : 'employeeSearch.bypassHierarchy',
			'trans' 	: 'form.bypassHierarchy'
		},
		{
			'name'	: 'employeeSearch.subordinateHierarchy', 
			'value'	: 'subordinate',
			'visible' : 'employeeSearch.subordinateHierarchy',
			'trans' 	: 'form.subordinate'
		}, 
		{
			'name'	: 'employeeSearch.regionHierarchy', 
			'value'	: 'region',
			'visible' : 'employeeSearch.regionHierarchy',
			'trans' 	: 'form.region'
		},
		{
			'name'	: 'employeeSearch.subordinateInRegion', 
			'value'	: 'subordinateInRegion',
			'visible' : 'employeeSearch.subordinateInRegion',
			'trans' 	: 'form.subordinateInRegion'
		},
	];

	fieldTypes:any = [
		{
			'name' : 'Text',
			'value' : 'string'
		},
		{
			'name' : 'Drop Down',
			'value' : 'select'
		},
		{
			'name': 'Number',
			'value': 'number',
		},
		{
			'name' : 'Date',
			'value' : 'date'
		},
		{
			'name' : 'Boolean',
			'value' : 'boolean'
		}
	];

	pages:Array<any> = [
		{
			'id'	:'dashboardFilters',
			'name'	:'Dashboard',
			'filters': this.dashboardSettings.filters,
			'baseFilters': this.querySettings.filters,
			'queryToolSettings': this.dashboardSettings.queryToolSettings,
			'addCCF': true
		},
		{
			'id'	:'enrollQueryFilters',
			'name'	:'Enrollment',
			'filters': this.enrollmentSettings.filters,
			'baseFilters': this.querySettings.filters,
			'queryToolSettings': this.enrollmentSettings.queryToolSettings,
			'addCCF': true
		},
		{
			'id'	:'scheduleEnrollmentsFilters',
			'name'	:'Schedule Enrollments',
			'filters': this.scheduleEnrollmentFilterSettings.filters,
			'baseFilters': this.querySettings.filters,
			'queryToolSettings': this.scheduleEnrollmentFilterSettings.queryToolSettings,
			'removeFromBase': ['e.inactive'],
			'addCCF': true
		},
	];
}