import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-report-joints',
	templateUrl: './joints.component.html',
})
export class LioReportJoints {
	@Input() model:any;
	@Input() field:any;
	@Input() formdata:any;
	@Output() onUpdate	:EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}
}