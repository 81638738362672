import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';

import { localizationService } from '../../../../../services/localization.service';
import { debugService } from '../../../../../services/debug.service';

@Component({
	selector: 'lio-catalog-keywords',
	templateUrl: './keywords.component.html',
})
export class LioCatalogKeywords {
	@Input() model:any;
	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(localizationService) public localizationService :localizationService,
		@Inject(debugService) public debugService :debugService
	){
		this.debugService.register('keywords', this);
	}


	/*
	 * Adds new keyword
	*/
	addKeyword() {
		this.model.keywords.push({
			'name': '',
			'featured': false,
		});
	}

	/*
	 * Removes a keyword
	*/
	removeKeyword(keyword) {
		let index = this.model.keywords.indexOf(keyword);
		this.model.keywords.splice(index, 1);
	}

}