import { Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: employeeSearchSettings
})
export class employeeSearchSettings {
	pagination:any = {
		'pageLimit'	: '10',
		'pageLimits': ['10', '50', '100'],
		'sortMode'	: {field : 'lastName', direction : 'asc'}
	};

	panelConfig:any = {
		id				: 'panelTab_employee_search',
		title			: 'Search Users',
		titleTrans		: 'employeesearch.header',
	};

	settings:any = {
		'removeSelf'	: false,
		'includeUID'	: true
	};



	fieldConfig:any = {
		'fieldName'			: 'employeesearch',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'addActiveField'	: true,
		'overrideFields'	: true,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name: 'Employee ID',
			model: 'employeeID',
			type	: 'text',
			visible	: true,
			export	: true,
			sortable: true
		},
		{
			name: 'First Name',
			model: 'firstName',
			type	: 'text',
			visible	: true,
			export	: true,
			sortable: true
		},
		{
			name: 'Last Name',
			model: 'lastName',
			type	: 'text',
			visible	: true,
			export	: true,
			sortable: true
		},
		{
			name: 'e-mail',
			model: 'email',
			type: 'text',
			visible	: true,
			export	: true,
			sortable: true
		},
		{
			name: 'Address',
			model: 'address1',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Address 2',
			model: 'address2',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'City',
			model: 'city',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'State',
			model: 'state',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Country',
			model: 'country',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Zip Code',
			model: 'zipCode',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Phone #',
			model: 'phone',
			type	: 'text',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Date Added',
			model: 'addDate',
			type	: 'date',
			visible	: false,
			export	: false,
			sortable: true
		},
		{
			name: 'Date Modified',
			model: 'updated',
			type	: 'date',
			visible	: false,
			export	: false,
			sortable: true
		},
	);
}