import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { loaderService } from '../../services/loader.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { workerService } from '../../services/worker.service';
import { permissionService } from '../../services/permissions.service';
import { stateService } from '../../services/state.service';
import { feedbackService } from '../../services/feedback.service';
import { formValidatorService } from '../../services/form-validator.service';

import { emailAuditLogSettings } from './email-audit-log.settings';

@Component({
	selector: 'lio-email-audit-log',
	templateUrl: './email-audit-log.component.html'
})
export class EmailAuditLogComponent implements OnDestroy {
	public fields			:Array<any> 	= this.permissionService.setFields(this.emailAuditLogSettings.fields);
	public searchFields		:Array<any> 	= this.permissionService.setFields(this.emailAuditLogSettings.searchFields);
	public pagination		:any 			= this.emailAuditLogSettings.pagination;

	public logs				:Array<any> 	= [];
	public allowSubmit		:boolean 		= false;
	public searchers		:any 			= {};
	public filteredLogs		:Array<any> 	= [];

	private subscriptions	:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService				:navService,
		@Inject(debugService)			public	debugService			:debugService,
		@Inject(loaderService)			public	loaderService			:loaderService,
		@Inject(lmsService)				public	lmsService				:lmsService,
		@Inject(lioModalService)		public	lioModalService			:lioModalService,
		@Inject(workerService)			public	workerService			:workerService,
		@Inject(permissionService)		public	permissionService		:permissionService,
		@Inject(stateService)			public	stateService			:stateService,
		@Inject(feedbackService)		public	feedbackService			:feedbackService,
		@Inject(formValidatorService)	public	formValidatorService	:formValidatorService,
		@Inject(emailAuditLogSettings)	public	emailAuditLogSettings	:emailAuditLogSettings
	){
		this.debugService.register('emailauditlog', this);
		this.navService.setActivePage('emailauditlog');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Init
	*/
	init() {
		this.searchers = {
			companyID: this.stateService.getActiveCompanyID() 
		};
		this.getMostRecentLogs();
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredLogs(collection){
		this.filteredLogs = collection.filtered;
	}
	
	/*
	 * Exports the employee records
	*/
	exportRecords() {
		if (!this.workerService.export(this.logs, this.fields, 'Email Audit Logs')) {
			this.lioModalService.show('Failed to export Email Audit Logs');
		}
	}

	/*
	 * Searches for a log
	*/
	submit() {
		this.logs = [];
		this.feedbackService.clearErrors();

		if (!this.formValidatorService.isValid(this.searchers, this.searchFields)) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			return;
		}

		this.searchers = this.formValidatorService.getData();
		this.lioModalService.showLoading('loadingRecords');

		this.lmsService.post('emailauditlog/search', {'searchers': this.searchers}).then((result) => {
			let errors 		= result.errors;
			let auditLogs 	= result.properties.auditLogs;
				
			this.lioModalService.hideLoading();

			if (auditLogs) {
				this.logs = this.formatLogs(auditLogs);
			} else if (errors.length) {
				this.feedbackService.setErrors(errors);
			} else {
				this.feedbackService.setError('noLogsFound');
			}
        });
	}

	/*
	 * Formats the logs
	*/
	formatLogs(logs) {
		return logs;
	}

	/*
	 * Gets the most recent logs
	*/
	getMostRecentLogs() {
		this.lmsService.get('emailauditlog/getMostRecent').then((result) => {
			var auditLogs = result.properties.auditLogs;
			if (auditLogs) {
				this.logs = auditLogs;
			} else if (result.errors.length) {
				this.feedbackService.setErrors(result.errors);
			}
			this.navService.displayPage();
        });
	}

	/*
	 * On Update
	*/
	onupdate(){
		let hasValue = false;

		this.feedbackService.errors = [];
		this.formValidatorService.resetFields();

		let searchKeys = Object.keys(this.searchers);
		searchKeys.forEach((key) => {
			if (this.searchers[key]) {
				hasValue = true;
				return;
			}
		});
		this.allowSubmit = hasValue;
	}
}