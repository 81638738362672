import { Component, Inject, Input, DoCheck } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../services/localization.service';
import { debugService } from '../../../../services/debug.service';
import { paginationRegistryService } from '../../../../services/pagination-registry.service';

import { LioSearchableSelectField } from 'src/app/modules/lio-forms/lio-forms.models';

@Component({
	selector: 'lio-paginator-controls',
	templateUrl: './paginator-controls.component.html',
})
export class LIOPaginatorControls implements DoCheck {
	public target				:any = null;
	public displayTrans			:any = null;
	public displayTransMacros	:any = null;
	public pageLimitField		:LioSearchableSelectField = new LioSearchableSelectField({
		name		: 'Items per page:',
		nameTrans	: 'message.itemsPerPage',
		model 		: 'pageLimit',
		options 	: []
	});

	@Input() appearance:MatFormFieldAppearance = 'standard';

	private _controlID:string = 'default-paginator-id';
	get controlID(){
		return this._controlID;
	}
	@Input() set controlID(value:any){
		this._controlID = value;
		this.paginationRegistryService.registerObserver(this.controlID, (target) => {
			this.setTarget(target)
		});
	}

	constructor(
		@Inject(localizationService) 		public localizationService 			:localizationService,
		@Inject(debugService) 				public debugService 				:debugService,
		@Inject(paginationRegistryService)	public paginationRegistryService	:paginationRegistryService
	){	
		this.debugService.register('paginationControl', this);
	}

	/**
	 * Update display text when paginator variables change
	 */
	private _oldPageRangeStart 		= null;
	private _oldPageRangeEnd 		= null;
	private _oldCollectionLength 	= null;
	ngDoCheck() {
		if(this.target){
			if(this.target.pageRange){
				if(this.target.pageRange.start != this._oldPageRangeStart){
					this._oldPageRangeStart = this.target.pageRange.start;
					this.setDisplayTrans();
				}

				if(this.target.pageRange.end != this._oldPageRangeEnd){
					this._oldPageRangeEnd = this.target.pageRange.end;
					this.setDisplayTrans();
				}

				if(this.target.filteredCollection.length != this._oldCollectionLength){
					this._oldCollectionLength = this.target.filteredCollection.length;
					this.setDisplayTrans();
				}

				if(this.target.settings.pageLimits != this.pageLimitField.options){
					this.pageLimitField.options = this.target.settings.pageLimits;
				}
			}
		}
	}

	/**
	 * Gets the translated values for info that is rendered about the collection
	 */
	setDisplayTrans(){
		this.displayTransMacros = [
			{key : 'lower', value : this.target.pageRange.start + 1},
			{key : 'upper', value : this.target.pageRange.end + 1},
			{key : 'total', value : this.target.filteredCollection.length},
		];
		this.displayTrans = this.localizationService.get('message.displayingRecords', null, this.displayTransMacros);
	}

	/**
	 * Sets the value of target, this function will be called by the paginator registry service
	 */
	setTarget(target){
		this.target = target;
		this.setDisplayTrans();
	}

	/**
	 * Gets an array of the page numbers that should be shown in the page selection area
	 */
	getPageNumArray(){
		let low 	= Math.max(0, this.target.currentPage - 5);
		let high 	= Math.min(low + 9, this.target.getMaxPage());
		let pages 	= [];

		for(let i = low; i <= high; i++){
			pages.push(i);
		}

		return pages;
	}
}