
/*
 * Service for Controlling Tracker of courses for learn IO
*/
import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { apiInterface } from './api.interface';

import { feedbackService } from './feedback.service';
import { lioLogService } from './lio-log.service';
import { utilService } from './util.service';
import { scorm12Settings } from '../settings/scorm-12.settings';

@Injectable({
	providedIn: 'root',
})
export class scorm12Service implements apiInterface {
	public APIversion 			:string		= 'Scorm 1.2';
	public protocol 			:string		= 'scorm';
	public launchMethod 		:string		= 'scorm';
	public active				:boolean	= false;
	public error 				:string		= '';
	public courseSession 		:any		= null;
	public debug				:boolean	= false;
	public allowsThirdPartyHost	:boolean	= false;
	public commitsData			:boolean	= true;

	public apiCommit			:Subject<void> = new Subject();
	public apiError				:Subject<any> = new Subject();
	public apiInitialized		:Subject<void> = new Subject();
	public apiFinished			:Subject<void> = new Subject();
	public trackerError			:Subject<any> = new Subject();

	protected lastCommand			:string		= '';
	protected itemsToCommit 		:any		= {};
	protected fields 				:any 		= null;

	constructor(
		@Inject(DOCUMENT)			protected document			:Document,
		@Inject(feedbackService)	protected feedbackService	:feedbackService,
		@Inject(lioLogService)		protected lioLogService		:lioLogService,
		@Inject(utilService)		protected utilService		:utilService,
		@Inject(scorm12Settings)	protected scorm12Settings	:scorm12Settings
	){}

	/*
	 * Activate the tracker
	 * @param {object} course
	 * @return {Promise}
	*/
	activate(courseSession) {
		this.log('Activating: ' + this.APIversion);
		this.log('Course Session', courseSession);
		this.courseSession = courseSession;
		
		this.initialize();
		return this.active;
	}

	/*
	 * Handles the course is open
	*/
	handleCourseOpen() {
		return;
	}

	/*
	 * Handles the course is closing
	*/
	handleCourseClosing() {
		this.log('Course Closing');
	}

	/*
	 * Initialize the API
	 * @return {boolean}
	*/
	initialize() {
		this.fields = this.utilService.copy(this.scorm12Settings.fields);

		if (!this.courseSession) {
			this.dispatchError('Course Session was empty');
			return false;
		}
		if (!this.setAPIData()) {
			this.dispatchError('Failed to Set API Data');
			return false;
		}

		if (!this.createAPI()) {
			this.dispatchError('Failed to Create API');
			return false;
		}

		this.active = true;
		return true;
	}

	/*
	 * Is the API active?
	 * @return {boolean}
	*/		
	isActive() {
		return this.active;
	}

	/*
	 * Gets the model based on the API field
	 * @param {string} apiField
	 * @return {object}
	*/
	getModel(apiField) {
		let model	= null;

		this.fields.forEach((field) => {
			if (field.apiField === apiField) {
				model = field;
				return;
			}
		});

		if (!model) {
			this.log('API Field not set for ' + apiField);
		}

		return model;
	}

	/*
	 * Sets the model based on the course
	 * @param {string} apiField
	 * @return {boolean}
	*/
	setAPIData() {
		this.fields.forEach((field) => {
			let value = null;

			if (field.initValue) {
				if (field.initValueFunction) {
					value = this[field.initValueFunction]();
				} else {
					value = this.courseSession[field.model];
				}
				this.log('Initialized: ' + field.name + ': ' + value);
				field.value = value;
			}
			if (this.utilService.isDefined(field.defaultValue) && value === null) {
				value = field.defaultValue;
				this.log('Set Field to Default: ' + field.name + ': ' + value);
				field.value = value;
			}
		});

		return true;
	}

	/*
	 * Gets the entry value
	 * @return {string}
	*/
	getEntry() {
		if (this.courseSession.courseStarted == 1) {
			return 'resume';
		}
		return 'ab-initio';
	}

	/*
	 * Gets the status
	 * @return {string}
	*/
	getStatus() {
		if (this.courseSession.courseCompletion == 1) {
			return 'complete';
		}
		return 'incomplete';
	}

	/*
	 * Should we save completion to the backend?
	 * @param {array} model
	 * @return {number}
	*/
	shouldSaveCompletion(model) {
		let value = model.value,
			allowedValues = ['completed', 'complete', 'passed', 'failed'];

		return allowedValues.indexOf(value) > -1;
	}

	/*
	 * Should we save session time to the backend?
	 * @param {array} model
	 * @return {number}
	*/
	shouldSaveSessionTime() {
		return false;
	}

	/*
	 * Gets the score
	 * @return {number}
	*/
	getScore() {
		let score = this.courseSession.aicc_score;
		
		score = parseInt(score);
		
		if (!score) {
			score = 0;
		}

		return score;
	}

	/*
	 * Sets the status
	 * @return {string}
	*/
	setStatus(value) {
		if (value === 'failed') {
			return 'failed';
		}

		if (value == 'completed' || value == 'complete' || value == 'passed') {
			return 'completed';
		}
		return 0;
	}

	/*
	 * Gets the session time
	 * @return {string}
	*/
	getSessionTime() {
		return '00:00:00';
	}

	/*
	 * Gets the suspend data
	 * @return {string}
	*/
	getSuspendData() {
		let suspendData = this.courseSession.core_lesson;
		if (suspendData) {
			suspendData = decodeURIComponent(suspendData);
		}
		return suspendData;
	}

	/*
	 * Sets the suspend data
	 * @param {string} suspendData
	 * @return {string}
	*/
	setSuspendData(suspendData) {
		return suspendData;
	}


	/*
	 * Gets the lesson location
	 * @return {string}
	*/
	initLessonLocation() {
		return this.getLessonLocation();
	}


	/*
	 * Gets the lesson location
	 * @return {string}
	*/
	getLessonLocation() {
		let lessonLocation = this.courseSession.aicc_lesson_location;
		
		if (!lessonLocation) {
			lessonLocation = '';
		}

		return lessonLocation;
	}

	/*
	 * Sets the lesson location
	 * @param {string} lessonLocation
	 * @return {string}
	*/
	setLessonLocation(lessonLocation) {
		return lessonLocation;
	}


	/*
	 * LMSInitialize
	 * @return {string}
	*/
	LMSInitialize() {
		this.lastCommand = 'LMSInitialize';
		this.log(this.lastCommand, '');
		this.apiInitialized.next();
		return 'true';
	}

	/*
	 * Get value
	 * @param {string} item
	 * @return {string}
	*/
	LMSGetValue(item) {
		let model	= this.getModel(item);
		
		if (!model) {
			return '';
		}

		this.lastCommand = 'LMSGetValue: ' + item;
		this.log(this.lastCommand, model.value);

		return model.value;
	}

	/*
	 * Get the mastery score
	 * @return {number}
	*/
	getMasteryScore() {
		let score = this.courseSession.masteryScore;
		if (!score) {
			score = 50;
		}
		return parseFloat(score);
	}

	/*
	 * Set value
	 * @param {string} item
	 * @param {string} value
	 * @return {string}
	*/
	LMSSetValue(item, value) {
		let model	= this.getModel(item);

		if (!model) {
			return 'false';
		}

		this.lastCommand = 'LMSSetValue: ' + item;
		this.log(this.lastCommand, value);

		// If we dont care to set the value, just say ok and be done
		if (!model.canSet) {
			return 'true';
		}


		if (model.lastValue === value) {
			this.log('NO CHANGE FOR ' + item);
			return 'true';
		}
		
		model.lastValue = value;

		// Validate the incoming value
		if (!this.isValid(model, value)) {
			this.dispatchError(this.error);
			return 'false';	
		}

		// Handle model functions
		if (this[model.setValueFunction]) {
			value = this[model.setValueFunction](value);
		}

		model.value = value;

		// Shoud we save the new value or leave it alone without any errors
		if (!this.shouldSave(model)) {
			return 'true';	
		}

		this.log('Adding item to commit', model.model , value);

		// Update the model with the current value
		this.itemsToCommit[model.model] = value;
		return 'true';
	}

	/*
	 * Should this value be saved as is
	 * @param {array} model
	 * @return {boolean}
	*/
	shouldSave(model) {
		if (model.shouldSaveFn) {
			return this[model.shouldSaveFn](model);
		}

		return true;
	}

	/*
	 * Is Valid value
	 * @param {array} model
	 * @param {string} value
	 * @return {boolean}
	*/
	isValid(model, value) {
		let length = value.length,
			error = '',
			isValid = true;

		if (model.type == 'number') {
			value = parseInt(value);
			if (!value && value !== 0) {
				error = 'Value of "' + value + '"" is not a number';
				isValid = false;
			}
		}

		if (length > model.max) {
			error = 'Value of "' + value + '"" was too large';
			isValid = false;
		}

		if (length < model.min) {
			error = 'Value of "' + value + '"" was too small';
			isValid=  false;
		}

		if (error) {
			this.error = error + ' for ' + model.name;
		}

		return isValid;
	}

	/*
	 * Commit
	 * @param {string} item
	 * @param {string} value
	 * @return {string}
	*/
	LMSCommit () {
		this.lastCommand = 'LMSCommit';
		this.log(this.lastCommand, '');

		if (!this.utilService.isEmpty(this.itemsToCommit)) {
			this.apiCommit.next();
		}
		return 'true';
	}

	/*
	 * Saves Data
	 @return {object}
	*/
	getData() {
		return this.itemsToCommit;
	}

	/*
	 * Clears Data that has been saved
	 * @param {object} data
	 * @return {object}
	*/
	clearData(data) {
		let keys 	= Object.keys(data);

		keys.forEach((key) => {
			this.log('Deleting Item', key);
			delete this.itemsToCommit[key];
		});

		// If after clearing data we still have data to commit then we need to recommit
		// This can happen when a commit happens during a save
		if (!this.utilService.isEmpty(this.itemsToCommit)) {
			this.log('Committing after clear');
			this.apiCommit.next();
		} else {
			this.log('Items have been emptied');
		}
	}

	/*
	 * Finish
	 * @return {string}
	*/
	LMSFinish() {
		this.log('LMSFinish');
		this.apiFinished.next();
		return 'true';
	}

	/*
	 * Get Error
	 * @return {string}
	*/
	LMSGetLastError() {
		this.lastCommand = 'LMSGetLastError';
		this.log(this.lastCommand, '');
		return '0';
	}

	/*
	 * Get Error String
	 * @return {string}
	*/
	LMSGetErrorString() {
		this.lastCommand = 'LMSGetErrorString';
		this.log(this.lastCommand, '');

		return '';
	}

	/*
	 * Get Error Diagnostid
	 * @return {string}
	*/
	LMSGetDiagnostic() {
		this.lastCommand = 'LMSGetDiagnostic';
		this.log(this.lastCommand, '');

		return '';
	}

	/*
	 * Deactivate the tracker
	 * @return {boolean}
	*/
	deactivate() {		
		this.active = false;
		this.document.defaultView['API'] = null;

		this.apiCommit.complete();
		this.apiCommit = new Subject();
		this.apiError.complete();
		this.apiError = new Subject();
		
		return true;
	}

	/**
 	* Exports the API to the $window for the scorm detector.
	 * @return {boolean}
	*/
	createAPI() {
		let winRef = this.document.defaultView;
		winRef['API'] 						= {};
		winRef['API']['LMSInitialize'] 		= () 			=> { return this.LMSInitialize(); 			};
		winRef['API']['LMSGetValue'] 		= (val) 		=> { return this.LMSGetValue(val);	 		};
		winRef['API']['LMSSetValue'] 		= (name, val) 	=> { return this.LMSSetValue(name, val);	};
		winRef['API']['LMSCommit'] 			= () 			=> { return this.LMSCommit(); 				};
		winRef['API']['LMSFinish'] 			= () 			=> { return this.LMSFinish(); 				};
		winRef['API']['LMSGetLastError'] 	= () 			=> { return this.LMSGetLastError(); 		};
		winRef['API']['LMSGetErrorString'] 	= () 			=> { return this.LMSGetErrorString();		};
		winRef['API']['LMSGetDiagnostic'] 	= () 			=> { return this.LMSGetDiagnostic();		};
		return true;
	}

	/*
	 * Console log
	*/
	log (param1, param2 = null, param3 = null) {
		if (this.debug) {
			if (typeof param3 !== 'undefined') {
				this.lioLogService.log([param1, param2, param3]);
				return;
			}
			if (typeof param2 !== 'undefined') {
				this.lioLogService.log([param1, param2]);
				return;
			}
			this.lioLogService.log(param1);
		}
		this.feedbackService.addToHistory('Scorm: ' + param1);
	}

	/*
	 * Dispatch Error
	 * @param {string} error
	*/
	dispatchError(error) {
		error = this.protocol.toUpperCase() + ': ' + this.lastCommand + ': ' + error;
		this.log('Dispatching Error', error);
		this.apiError.next(error);
	}
}