import { Component, OnDestroy, Inject, DoCheck, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NEVER, Subscription, Observable } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { jsPDF } from 'jspdf';
import moment from 'moment';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { processingService } from '../../services/processing.service';
import { lmsService } from '../../services/lms.service';
import { permissionService } from '../../services/permissions.service';
import { workerService } from '../../services/worker.service';
import { feedbackService } from '../../services/feedback.service';
import { lioLogService } from '../../services/lio-log.service';
import { pdfService } from '../../services/pdf.service';
import { lioModalService } from '../../services/lio-modal.service';
import { localizationService } from '../../services/localization.service';
import { stateService } from '../../services/state.service';
import { utilService } from '../../services/util.service';

import { disclosureReportSettings } from './disclosure-report.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
	selector: 'lio-disclosure-reports',
	templateUrl: './disclosure-reports.component.html'
})
export class DisclosureReportsComponent implements OnDestroy, DoCheck {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache			:PanelTabCache 	= null;

	public panelConfig				:any			= this.disclosureReportSettings.panelConfig;
	public disclosureField			:any 			= this.disclosureReportSettings.disclosureField;
	public model					:any			= {};
	public filterBy					:string			= 'active';
	public filteredCompletions		:Array<any>		= [];
	public filteredIncompletions	:Array<any>		= [];
	public showLoader				:boolean		= true;
	public pdfRendering				:boolean		= false;
	public responseData				:any			= null;
	public currentTab				:string			= '';
	public searchCompletions		:string 		= '';
	public searchIncompletions		:string 		= '';
	public appearance				:MatFormFieldAppearance = 'outline';
	public stats					:{sections: Array<any>, completions: Array<any>, incompletions: Array<any>} = {
		sections 		: [], 
		completions		: [], 
		incompletions	: []
	};
	public prefixButtons			:Array<any>		= [
		{
			header 		: 'Export',
			name 		: '',
			id 			: 'disclosureReport_pdf_prefix',
			color 		: 'primary',
			icon 		: 'picture_as_pdf',
			callback 	: (employee) => { this.pdf(employee); }
		},
	];
	public localeStrings			:any 			= {
		active 			: 'Active',
		activeTrans		: 'disclosure.active',
		archived		: 'Archived',
		archivedTrans	: 'disclosure.archived'
	};
	public localeStringsKeys		:Array<any> 	= [
		'active',
		'archived'
	];

	private pdfTarget				:any			= null;
	private certificationIDs		:Array<any>		= [];
	private disclosures				:Array<any>		= [];
	private exportingRecords		:boolean 		= false;
	private warnNumber				:number			= 10000;

	private subscriptions			:Subscription	= NEVER.subscribe();

	constructor(
											private changeDetectorRef			:ChangeDetectorRef,
		@Inject(navService)					public	navService					:navService,
		@Inject(debugService)				public	debugService				:debugService,
		@Inject(processingService)			public	processingService			:processingService,
		@Inject(lmsService)					public	lmsService					:lmsService,
		@Inject(permissionService)			public	permissionService			:permissionService,
		@Inject(workerService)				public	workerService				:workerService,
		@Inject(feedbackService)			public	feedbackService				:feedbackService,
		@Inject(localizationService)		public	localizationService			:localizationService,
		@Inject(lioLogService)				public	lioLogService				:lioLogService,
		@Inject(pdfService)					public	pdfService					:pdfService,
		@Inject(lioModalService)			public	lioModalService				:lioModalService,
		@Inject(stateService)				public	stateService				:stateService,
		@Inject(utilService)				public	utilService					:utilService,
		@Inject(disclosureReportSettings)	public	disclosureReportSettings	:disclosureReportSettings
	){
		this.debugService.register('disclosureReport', this);
		this.navService.setActivePage('disclosureReport');
		
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private _oldDisclosures	:Array<any> = [];
	private _oldModelData	:any 		= null;
	ngDoCheck(){
		if(this._oldDisclosures != this.disclosures){
			this._oldDisclosures = this.disclosures;
			this.filterDisclosures();
		}
		if(this.model && this._oldModelData != this.model.data){
			this._oldModelData = this.model.data;
			if (this.model.data) {
				this.panelConfig.completions.title 		= this.model.data.title;
				this.panelConfig.incompletions.title 	= this.model.data.title;
			}
		}
	}

	/*
	* Init
	*/
	init() {
		this.clearStats();
		this.getAll().then(() => {
			this.navService.displayPage();
		});		

		if (this.permissionService.hasPermission('disclosure.canResetUsers')) {
			this.prefixButtons.push({
				header 		: 'Reset',
				id 			: 'disclosureReport_delete_prefix',
				color 		: 'primary',
				icon 		: 'restart_alt',
				callback 	: (employee) => { this.resetUser(employee); }
			});
		}
	}
	
	/** 
	 * Create a PDF for the responses from this user
	 * */
	pdf(employee) {
		this.lioModalService.showLoading('processing');

		this.loadResponses(employee.UID, employee.certificationID).then((responseData) => {
			this.responseData 		= responseData;
			this.responseData.title = this.model.data.title;
			this.pdfRendering 		= true;
			
			setTimeout(() => {
				this.pdfService.render(this.pdfTarget, false).subscribe((pdf:jsPDF) => {
					pdf.save(moment().toString() + '.pdf');
					this.pdfRendering = false;
				});
			});
		});
	}

	/** 
	 * Resets a user's progress
	 * */
	resetUser(employee) {
		this.lioModalService.confirm('confirmReset').then((response) => {
			if (!response) {
				return;
			}
			this.lioModalService.showLoading('processing');
			this.lmsService.post('disclosure/resetUser', {'UID': employee.UID, 'certificationID': this.model.data.certificationID}).then((result) => {
				this.lioModalService.hideLoading();
				if (result && result.properties.deleted) {
					let index = this.stats.completions.indexOf(employee);
					this.stats.completions.splice(index, 1);
					
					this.lioModalService.show('deletedSuccessfully');
				}
			});
		});
	}

	/**
	 * On tab change
	 */
	onMainPanelTabberChange(newTab) {
		this.feedbackService.clearErrors();

		if (newTab.type == 'clear') {
			this.clearStats();
			this.currentTab = null;
			this.panelTabCache.selectTabByIndex(0);
		}

		if (newTab.type == 'incompletions') {
			this.currentTab = 'incompletions';
			this.getIncompletions();
		}

		if (newTab.type == 'completions') {
			this.currentTab = 'completions';
		}
	}

	/**
	 * Completion filter push function
	 */
	updateCompletions(collection) {
		this.filteredCompletions = collection.filtered;
	}
	
	/**
	 * Completion filter push function
	 */
	updateIncompletions(collection) {
		this.filteredIncompletions = collection.filtered;
	}

	/**
	* Filters disclosures based on their status
	*/
	filterDisclosures() {
		setTimeout(() => {
			this.model.data = {};
			let results 	= [];
			this.disclosures.forEach((disclosure) => {
				let allow = false;
				switch(this.filterBy) {
					case 'active':
						allow = disclosure.active;
						break;
					case 'archived':
						allow = disclosure.archived;
						break;
				}

				if(allow){
					results.push(disclosure);
				}
			});
			this.disclosureField.options = results;
			this.changeDetectorRef.detectChanges();
		});
	}

	/**
	* Exports completions
	*/
	export(type) {
		if (!this.exportingRecords) {
			let employees = this.stats[type],
				fields = this.utilService.copy(this.panelConfig[type].fields);

			if (!employees) {
				this.lioLogService.log('No Employees Found by ' + type);
				return;
			}


			if (employees.length > this.warnNumber) {
				this.lioModalService.showLoading('processing');
			}

			// Loop through each employee to set some additional info
			employees.forEach((employee) => {
				employee.role = this.permissionService.getRoleNameFromID(employee.permissionID);
				employee.status = employee.inactive == 1 ? 'Inactive' : 'Active';
			});

			this.workerService.export(employees, fields, 'Employees').then((result) => {
				this.lioModalService.hideLoading();
				if (!result) {
					this.feedbackService.setError('failedToExportRecords');
				}
				this.exportingRecords = false;
			});

			this.exportingRecords = true;
		}
	}

	/**
	 * Gets all disclosures at the current company
	 */
	getAll() {
		return this.lmsService.post('disclosure/getAllVisible').then((result) => {
			this.disclosures = result.properties.disclosures;

			this.disclosures.forEach((disclosure) => {
				disclosure.sections = JSON.parse(disclosure.sections);
				disclosure.settings = JSON.parse(disclosure.settings);
			});
		});	
	}

	/**
	 * Clears Stats
	 */
	clearStats() {
		this.showLoader = true;
		this.stats 			= {
			sections 		: [], 
			completions		: [], 
			incompletions	: []
		};
		this.filteredCompletions 	= [];
		this.filteredIncompletions 	= [];
	}

	/**
	 * Gets Incompletions Data
	 * @param {string} fieldType
	 */
	getIncompletions() {
		if (!this.stats.incompletions) {
			this.filteredIncompletions = [];
			return this.lmsService.postAsync('disclosure/getIncompletions', {'certificationIDs' : this.certificationIDs},
			'processing').then((result) => {
				var token = result.properties.token;
				if (token) {
					return this.lmsService.getAsyncResult(token, (result) => {
						this.stats.incompletions = result.properties.incompletions;
						this.filteredIncompletions = this.stats.incompletions || [];
						this.lioModalService.hideLoading();
					});
				} else {
					this.stats.incompletions = result.properties.incompletions;
					this.filteredIncompletions = this.stats.incompletions || [];
					this.lioModalService.hideLoading();
				}
			});
		}
	}

	/**
	 * Loads the report for the selected disclosure
	 */
	load() {
		this.processingService.allowCancel = true;
		this.stats = {
			sections		: [], 
			completions		: [], 
			incompletions	: []
		};

		this.filteredCompletions 	= [];
		this.filteredIncompletions 	= [];
		this.getAllByCourseID().then((certificationIDs:any) => {
			this.certificationIDs = certificationIDs;
			this.getCompletions().then((completions:Array<any>) => {
			this.showLoader = false;

				if (completions) {
					this.stats.completions = completions;
					this.getSections();
				}else{
					this.lioModalService.hideLoading();
				}
			});
		});
	}

	/**
	 * Loads all matching certificationIDs by courseID
	 */
	getAllByCourseID() {
		let observable = new Observable((subscriber) => {
			this.lmsService.postAsync('disclosure/getMatchingCourseIDs', {'certificationID' : this.model.data.certificationID},
			'loadingRecords').then((result) => {
				var token = result.properties.token;
				if (token) {
					return this.lmsService.getAsyncResult(token, (result) => {
						subscriber.next(result.properties.certificationIDs);
						subscriber.complete();
					});
				} else {
					subscriber.next(result.properties.certificationIDs);
					subscriber.complete();
				}
			});
		});
		return observable.toPromise();
	}

	/**
	 * Loads the compleition report for the selected disclosure
	 */
	getCompletions() {
		let observable = new Observable((subscriber) => {
			this.lmsService.postAsync('disclosure/getCompletions', {'certificationIDs' : this.certificationIDs}, 
			'loadingRecords').then((result) => {
				let token = result.properties.token;

				if (token) {
					this.lmsService.getAsyncResult(token, (result) => {
						subscriber.next(result.properties.completions);
						subscriber.complete();
					});
				} else {
					subscriber.next(result.properties.completions);
					subscriber.complete();
				}
			});
		});
		return observable.toPromise();
	}

	getSections() {
		return this.lmsService.postAsync('disclosure/getSections', {'certificationID' : this.model.data.certificationID}).then((result) => {
			var token = result.properties.token;

			if (token) {
				return this.lmsService.getAsyncResult(token, (result) => {
					return this.gotSections(result);
				});
			} else {
				return this.gotSections(result);
			}
		});
	}

	/*
	 * Loaded the sections, now loads each individial section
	*/
	gotSections(result) {
		let sections	= result.properties.sections;
		let count		= 0;
		
		if (!sections) {
			return;
		}
		let totalSections = sections.length;

		sections.forEach((section, index) => {	
			this.getSection(section).then((section) => {
				count++;
				this.stats.sections[index] = section;
				if (totalSections === count) {
					this.handleFields();
				}
			});
		});
	}

	/*
	 * Gets the detail per section
	*/
	getSection(section) {
		let observable = new Observable((subscriber) => {
			this.lmsService.postAsync('disclosure/getSection', {'certificationID' : this.model.data.certificationID, 'section' : section}).then((result) => {
				var token = result.properties.token;

				if (token) {
					return this.lmsService.getAsyncResult(token, (result) => {
						subscriber.next(result.properties.section);
						subscriber.complete();
					});
				} else {
					subscriber.next(result.properties.section);
					subscriber.complete();
				}
			});	
		});
		return observable.toPromise();
	}

	/*
	 * Handles header click
	*/
	clickedHeader(model) {
		if (model.title) {
			this.lioModalService.show(model.title);
		}
	}

	/*
	 * Handles stats
	*/
	handleFields() {
		let workerConfig = {
			stats: this.stats,
		};

		this.lioModalService.showLoading('processing');

		this.workerService.call('handleFields', workerConfig, 'DisclosureExporter').then((response:any) => {
			response.fields.forEach((field) => {
				this.panelConfig.completions.fields.push(field);
			});
			this.lioModalService.hideLoading();
		});
	}

	setPdfTarget(target){
		this.pdfTarget = target;
	}

	/**
	 * Gets the responses to a disclosure for a certain user
	 * @return {object} A disclosure table entry
	 */
	getResponses(UID, certificationID) {
		let url 		= 'disclosure/getMyStats';
		let observable = new Observable((subscriber) => {
			if (UID) {
				url = 'disclosure/getStats';
			}

			this.lmsService.post(url, {UID : UID, certificationID : certificationID}).then((result) => {
				if (result.success) {
					subscriber.next(result.properties.data);
					subscriber.complete();
				} else {
					subscriber.error();
					subscriber.complete();
				}
			});
		});
		return observable.toPromise();
	}

	loadResponses(UID, certificationID) {
		return this.getResponses(UID, certificationID).then((responses:any) => {
			let responseOutput	= [];

			responses.sections.forEach((section) => {
				let sectionResponses = [];
				section.questions.forEach((question) => {
					section.responses.forEach((response) => {
						if (response.certificationQuestionID == question.certificationQuestionID) {
							let questionText = question.verbiage;
							let responseText = '';

							if (response.responseText) {
								responseText = response.responseText;
							} else if (response.certificationOptionID != null && question.options != null) {
								question.options.forEach((option) => {
									if (option.certificationOptionID == response.certificationOptionID) {
										responseText = option.optionText;
									}
								});
							}

							if (responseText) {
								sectionResponses.push({question : questionText, response : responseText});
							}
						}
					});
				});

				if (sectionResponses.length > 0) {
					responseOutput.push(sectionResponses);
				}
			});

			return {
				completion	: responses.completions[0],
				responses	: responseOutput
			};
		}, () => {
			this.feedbackService.setError('User progress for disclosure not found');
		});
	}
}