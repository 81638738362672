import { Injectable } from '@angular/core';

@Injectable({
	providedIn	: 'root', 
	useClass	: documentationSettings
})
export class documentationSettings {
	addUsersPanelConfig:any = {
		id					: 'panelTab_documentation_addUsers',
		title				: 'How to use the "Add Users" page',
		titleTrans			: 'documentation_addUsersPanelTitle',
		tab					: 'Adding Users',
		tabTrans			: 'documentation_addUsersTab',
		sections 			: {
			addOne				: {
				id					: 'panelTab_documentation_learning_accordion_1',
				title				: 'Adding individual users',
				titleTrans			: 'documentation_addUsers_accordion_1_title',
			},
			addMany				: {
				id					: 'panelTab_documentation_learning_accordion_2',
				title				: 'Adding users in bulk',
				titleTrans			: 'documentation_addUsers_accordion_2_title',
			}
		}
	};

	enrollPanelConfig:any = {
		id					: 'panelTab_documentation_enroll',
		title				: 'How to use the "Enroll" page',
		titleTrans			: 'documentation_enrollPanelTitle',
		tab					: 'Enrolling Users',
		tabTrans			: 'documentation_enroll',
		sections 			: {
			enrollRules			: {
				id					: 'panelTab_documentation_enroll_accordion_1',
				title				: 'Enrollment Rules',
				titleTrans			: 'documentation_enroll_accordion_1_title',
			},
			bulkEnroll			: {
				id					: 'panelTab_documentation_enroll_accordion_2',
				title				: 'Enrolling in bulk',
				titleTrans			: 'documentation_enroll_accordion_2_title',
			},
			enrollmentscheduler			: {
				id					: 'panelTab_documentation_enroll_accordion_3',
				title				: 'Scheduling Automatic Enrollments',
				titleTrans			: 'documentation_enroll_accordion_3_title',
			},

			bulkUnenroll		: {
				id					: 'panelTab_documentation_enroll_accordion_4',
				title				: 'Unenrolling in bulk',
				titleTrans			: 'documentation_enroll_accordion_4_title',
			},
			bulkComplete		: {
				id					: 'panelTab_documentation_enroll_accordion_5',
				title				: 'Marking courses as complete in bulk',
				titleTrans			: 'documentation_enroll_accordion_5_title',
			},
			bulkUpdate			: {
				id					: 'panelTab_documentation_enroll_accordion_6',
				title				: 'Updating enrollments in bulk',
				titleTrans			: 'documentation_enroll_accordion_6_title',
			}
		}
	};


	schedulingPanelConfig:any = {
		id					: 'panelTab_documentation_scheduling',
		title				: 'Scheduling emails in LearnIO',
		titleTrans			: 'documentation_schedulingPanelTitle',
		tab					: 'Email Scheduling',
		tabTrans			: 'documentation_scheduling',
		sections 			: {
	
			emailSchedule		: {
				id					: 'panelTab_documentation_workflow_accordion_4',
				title				: 'Setting Up a Scheduled Email',
				titleTrans			: 'documentation_workflow_accordion_4_title',
			},
			multiCourseMacro		: {
				id					: 'panelTab_documentation_macro_accordion_4',
				title				: 'Scheduling Multiple Courses in one Template',
				titleTrans			: 'documentation_macro_accordion_4_title',
			},
		}
	};

	workflowPanelConfig:any = {
		id					: 'panelTab_documentation_workflow',
		title				: 'Some example workflows in LearnIO',
		titleTrans			: 'documentation_workflowPanelTitle',
		tab					: 'Workflows',
		tabTrans			: 'documentation_workflow',
		sections 			: {
			addUsers			: {
				id					: 'panelTab_documentation_workflow_accordion_1',
				title				: 'Adding, Enrolling, and Emailing',
				titleTrans			: 'documentation_workflow_accordion_1_title',
			},
			markingComplete		: {
				id					: 'panelTab_documentation_workflow_accordion_2',
				title				: 'Marking a course as complete',
				titleTrans			: 'documentation_workflow_accordion_2_title',
			},
			reports				: {
				id					: 'panelTab_documentation_workflow_accordion_3',
				title				: 'Pulling Dashboard Reports',
				titleTrans			: 'documentation_workflow_accordion_3_title',
			},
			resendingregemails		: {
				id					: 'panelTab_documentation_workflow_accordion_4',
				title				: 'Resending Registration Emails',
				titleTrans			: 'documentation_workflow_accordion_4_title',
			},
		}
	};

	rolesPanelConfig:any = {
		id					: 'panelTab_documentation_roles',
		title				: 'How Roles work in LearnIO',
		titleTrans			: 'documentation_rolesPanelTitle',
		tab					: 'Roles',
		tabTrans			: 'documentation_roles',
		sections 			: {
			differentViews		: {
				id					: 'panelTab_documentation_roles_accordion_1',
				title				: 'What different roles can see',
				titleTrans			: 'documentation_roles_accordion_1_title',
			}
		}
	};

	launchPrepPanelConfig:any = {
		id					: 'panelTab_documentation_launchPrep',
		title				: 'Preparing a launch in LearnIO',
		titleTrans			: 'documentation_launchPrepPanelTitle',
		tab					: 'Launch Prep',
		tabTrans			: 'documentation_launchPrep',
		sections 			: {
			prep		: {
				id					: 'panelTab_documentation_launchPrep_accordion_1',
				title				: 'Launch Preparation Process',
				titleTrans			: 'documentation_launchPrep_accordion_1_title',
			}
		}
	};

	selfenrollPanelConfig:any = {
		id					: 'panelTab_documentation_selfenroll',
		title				: 'Using LearnIO to Self-Enroll in Courses',
		titleTrans			: 'documentation_selfenrollPanelTitle',
		tab					: 'Self-Enrollment',
		tabTrans			: 'documentation_selfenroll',
		sections 			: {
			self		: {
				id					: 'panelTab_documentation_selfenroll_accordion_1',
				title				: 'Self-Enrollment Process',
				titleTrans			: 'documentation_selfenroll_accordion_1_title',
			}
		}
	};

	otherPanelConfig:any = {
		id					: 'panelTab_documentation_other',
		title				: 'Other Instructions',
		titleTrans			: 'documentation_otherPanelTitle',
		tab					: 'Other',
		tabTrans			: 'documentation_other',
		sections 			: {
			sso		: {
				id					: 'panelTab_documentation_other_accordion_1',
				title				: 'How to set up SSO',
				titleTrans			: 'documentation_other_accordion_1_title',
			},
			onboarding		: {
				id					: 'panelTab_documentation_other_accordion_2',
				title				: 'Onboarding Guide',
				titleTrans			: 'documentation_other_accordion_2_title',
			},
			localization		: {
				id					: 'panelTab_documentation_other_accordion_3',
				title				: 'Localization Guide',
				titleTrans			: 'documentation_other_accordion_3_title',
			},
			troubleshooting		: {
				id					: 'panelTab_documentation_other_accordion_4',
				title				: 'Troubleshooting',
				titleTrans			: 'documentation_other_accordion_4_title',
			},
			whitelisting		: {
				id					: 'panelTab_documentation_other_accordion_5',
				title				: 'Whitelisting',
				titleTrans			: 'documentation_other_accordion_5_title',
			},
			userFields		: {
				id					: 'panelTab_documentation_other_accordion_6',
				title				: 'User Fields',
				titleTrans			: 'documentation_other_accordion_6_title',
			}
		}
	};
}