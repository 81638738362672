
/*
 * Service for Handling form validation
*/
import { Inject, Injectable } from '@angular/core';

import { configSettings } from '../settings/config.settings';
import { lioLogService } from './lio-log.service';
import { settingsService } from './settings.service';
import { localizationService } from './localization.service';
import { utilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class formValidatorService {
	fields			: Array<any>;
	data 			: any;
	errors 			: Array<any>;
	originalData 	: any;

	constructor(
		@Inject(configSettings)			private configSettings		:configSettings,
		@Inject(lioLogService)			private lioLogService		:lioLogService,
		@Inject(settingsService)		private settingsService		:settingsService,
		@Inject(localizationService)	private localizationService	:localizationService,
		@Inject(utilService)			private utilService			:utilService
	){
		this.fields = null;
		this.data = null;
		this.errors = [];
	}

	/*
	 * Is the form valid?
	 * @param {array} data
	 * @param {array} fields
	 * @return {boolean}
	*/
	isValid(data, fields) {
		this.data = data;
		this.fields = fields;
		this.resetFields();
		return this.validate();
	}

	/*
	 * Reset each field to remove the exisiting errors
	*/
	resetFields() {
		if(this.fields){
			this.fields.forEach((field) => {
				field.error = false;
				field.errorString = '';
			});
		}
	}

	/*
	 * Sets the original model
	 * @param {array} data
	*/
	setOriginalModel(data) {
		this.originalData = data;
	}

	/*
	 * Gets the revised form data
	 * @return {array}
	*/
	getData() {
		return this.data;
	}

	/*
	 * Gets the revised fields
	 * @return {array}
	*/
	getFields() {
		return this.fields;
	}

	/*
	 * Gets the uniquely changed data
	 * @return {?object}
	*/
	getDelta() {
		let delta = {},
			hasDelta = false;
			let keys = Object.keys(this.data);
			keys.forEach((key) => {
			let originalValue = this.originalData[key];
			if (originalValue !== this.data[key]) {
				delta[key] = this.data[key];
				hasDelta = true;
			}
		});

		if (!hasDelta) {
			return null;
		}

		return delta;
	}

	/*
	 * Gets the errors
	 * @return {array}
	*/
	getErrors() {
		return this.errors;
	}

	/*
	 * Gets the email value by class
	 * @return {array}
	*/
	getEmailByClass() {
		var value = '',
			email = document.getElementsByClassName('email')[0];

		if (email) {
			value = email.getAttribute('value');
		}

		return value;
	}

	/*
	 * Validate the filters
	 * @return {boolean}
	*/
	validateFilters(field) {
		let isValid = true,
			filters = this.data[field.model],
			error = this.utilService.localizeError('pleaseCheckTheFilters'),
			empty = false;

		if (!filters) {
			filters = [];
		}

		filters.forEach((filter) => {
			//No operator or value was passed
			if (!filter.operator && !filter.value) {
				empty = true;
				return;
			}

			//No field was passed or no operator was passed, input is definitely not valid
			if (!this.utilService.isDefined(filter.field) || !this.utilService.isDefined(filter.operator) || !filter.field || !filter.operator)  {
				this.errors.push(error);
				isValid = false;
				return;
			}

			//No value was passed, setting value to null since it may still be a valid input
			if (!this.utilService.isDefined(filter.value)) {
				filter.value = null;
			}

			//For operators 'in' and 'not_in' an empty value is not allowed, input is not valid
			if (filter.operator === 'in' || filter.operator === 'not_in') {
				if (!filter.value) {
					isValid = false;
				}
			}

			//for operators 'between' and 'not_between' the value must be passed and must be an array, otherwise it is not valid
			if (filter.operator === 'between' || filter.operator === 'not_between') {
				if (!filter.value || !filter.value[0] || !filter.value[1]) {
					isValid = false;
				}
			}
		});

		//since operator and value were not passed, clear all input
		if (empty) {
			filters = [];
		}

		//If the field is required, no filters being passed would not be valid
		if (field.required && !filters.length) {
			isValid = false;
		}

		if (!isValid) {
			this.errors.push(error);
		}
		this.data[field.model] = filters;

		return isValid;
	}


	/*
	 * Validates the form
	 * @return {string}
	*/
	validate() {
		let isValid = true;

		this.errors = [];

		this.fields.forEach((field) => {
			let value	:any,
				type	:any  		= field.validateAs || field.type,
				error:any = '',
				macros	:Array<any> = [
				{'key': 'input', 'value': field.name},
				{'key': 'supportEmail', 'value': this.configSettings.SUPPORT_EMAIL}
			];

			if (field.subModel && type == 'eventDays') {
				value = this.data[field.subModel];
			} else if (field.subModel) {
				value = this.data[field.subModel][field.model];
			} else {
				value = this.data[field.model];
			}

			if (field.validateAs) {
				type = field.validateAs;
			}

			if (!field.visible || field.hiddenByConditions) {
				if(type === 'rule') {
					value = [];
					this.updateData(field, value);
				}
				return;
			}

			// Override angular email validation to use our own 
			if (type === 'email' && !value) {
				value = this.getEmailByClass();
				if (!value && field.required) {
					isValid = false;
					field.error = true;
				}
			}

			// Rule validation
			if (type === 'rule') {
				if (!this.validateFilters(field)) {
					isValid = false;
					field.error = true;
				}
			}

			// No value and required
			if (!value && field.required) {
				if (type == 'boolean') {
					value = 'OK';
				}
				// Handle select drop downs that were not changed, therefore the default selection is used as the value
				if (field.options && field.options.length) {
					field.options.forEach((option) => {
						if (option.selected) {
							value = option.value;
							this.updateData(field, value);
						}
						return;
					});
				}

				// If still no value, add an error
				if (!value) {
					isValid = false;
					field.error = true;
					this.lioLogService.log([field, this.data]);
					error = this.utilService.localizeError('inputIsRequired', macros);
					field.errorString = this.localizationService.get(error.key, '', error.macros); 
					this.errors.push(error);
					return false;
				}
			} else if (!value && (field.default !== undefined && field.default !== null)) {
				value = field.default;
				this.updateData(field, value);
			}

			// Has value
			if (value) {
				// Check for HTML
				if (field.checkForHTMl && !this.utilService.hasHTML(value)) {
					error = this.utilService.localizeError('hasHtml', macros);
					this.errors.push(error);
					isValid = false;
					field.error = true;
					field.errorString = this.localizationService.get(error.key, '', error.macros); 
					return false;
				}

				// Check for html in email
				if (!this.settingsService['allowHTMLtemplates']) {
					if (field.checkForHTMlInEmail && !this.utilService.hasHTML(value)) {
						error = this.utilService.localizeError('htmlEmbeddedError', macros);
						field.errorString = this.localizationService.get(error.key, '', error.macros); 
						this.errors.push(error);
						isValid = false;
						field.error = true;
						return false;
					}
				}

				// Check for special chars
				if (field.checkForSpecialChars && this.utilService.hasSpecialChars(value, field.regex)) {
					error = this.utilService.localizeError('invalidChars', macros);
					field.errorString = this.localizationService.get(error.key, '', error.macros); 
					this.errors.push(error);
					isValid = false;
					field.error = true;
					return false;
				}

				// Numbers only
				if (type === 'number') {
					if (!this.utilService.isNumeric(value)) {
						error = this.utilService.localizeError('mustBeNumeric', macros);
						field.errorString = this.localizationService.get(error.key, '', error.macros); 
						this.errors.push(error);
						isValid = false;
						field.error = true;
						return false;
					}

					// Is between range
					if ((field.min && value < field.min) || (field.max && value > field.max)) {
						isValid = false;
						macros.push({'key': 'min', 'value': field.min});
						macros.push({'key': 'max', 'value': field.max});
						error = this.utilService.localizeError('withinRange', macros);
						field.errorString = this.localizationService.get(error.key, '', error.macros); 
						this.errors.push(error);
						field.error = true;
					}
				}

				// Requires alphanumeric
				if (value && field.varchar && !this.utilService.isAlphaNumeric(value)) {
					if (field.allowNumericOnly && this.utilService.isNumeric(value)) {
						// Allow numeric only
					} else {
						isValid = false;
						error = this.utilService.localizeError('mustBeAlphaNumeric', macros);
						field.errorString = this.localizationService.get(error.key, '', error.macros); 
						this.errors.push(error);
						field.error = true;
						return false;
					}
				}

				// Has minimum length requirements
				if (field.min && value.length < field.min) {
					isValid = false;
					macros.push({'key': 'min', 'value': field.min});
					macros.push({'key': 'max', 'value': field.max});
					error = this.utilService.localizeError('requiresMinimumChars', macros);
					field.errorString = this.localizationService.get(error.key, '', error.macros); 
					this.errors.push(error);
					field.error = true;
					return false;
				}

				// Is email address
				if (type === 'email') {
					if (value.indexOf(',') > -1) {
						let emails = value.split(',');
						emails.forEach((email) => {
							email = email.trim();
							if (!this.utilService.validateEmail(email)) {
								error = this.utilService.localizeError('improperEmailAddress', macros);
								field.errorString = this.localizationService.get(error.key, '', error.macros); 
								this.errors.push(error);
								isValid = false;
								field.error = true;
								return false;
							}
						});
					} else if (!this.utilService.validateEmail(value)) {
						error = this.utilService.localizeError('improperEmailAddress', macros);
						field.errorString = this.localizationService.get(error.key, '', error.macros); 
						this.errors.push(error);
						isValid = false;
						field.error = true;
						return false;
					}
				}

				//if any day is set to true, we are valid
				if(type == 'eventDays'){
					isValid = false;
					let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
					days.forEach((day) => {
						if(value[day]){
							isValid = true;
						}
					});
				}
			}
		});

		return isValid;
	}

	/*
	 * Updates the form data
	 * @param {array} field
	 * @param {string} value
	*/
	updateData(field, value) {
		if (field.subModel) {
			this.data[field.subModel][field.model] = value;
		}else if(field.type == 'rule'){
			this.data[field.model] = [];
		} else {
			this.data[field.model] = value;
		}
	}
}