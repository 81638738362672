import { LioField } from './field.model';

export class LioImageField extends LioField{
	public width	:string;
	public height	:string;

	constructor(obj:Partial<LioImageField>){
		super(obj);
		Object.assign(this, obj);
	}
}