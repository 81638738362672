import { Pipe } from '@angular/core';

@Pipe({
  name: "limit"
})
export class LimitPipe {
	transform(name: string, maxLength:number = 20, append:string = '...'): string {
		if (name) {
			name = name.toString();
		}
		if (!name) {
			return name;
		}
		let text = name.substr(0, maxLength);

		if (name.length > maxLength && append) {
			text += append;
		}
		return text;
		
	}
}