import { Inject, Injectable } 		from '@angular/core';
import { profileSettings } 			from '../profile/profile.settings';
import { permissionService } 		from '../../services/permissions.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEnrollmentSettings
})
export class scheduleEnrollmentSettings {
	constructor(
		@Inject(profileSettings) 		private profileSettings:profileSettings,
		@Inject(permissionService) 		private permissionService:permissionService
	){}

	employeeFields:any  = this.profileSettings.externalFields;
	employeeConfig:any = {
		'fieldName'			: 'enrollmentEventExport',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: true,
	};

	panels:any = {
		filters : {
			id				: 'filters',
			title			: 'Create Enrollment Filters',
			titleTrans		: 'scheduleEnrollments.queryFormTitle',
			subtitle		: 'Create Enrollment Filters',
			subtitleTrans	: 'scheduleEnrollments.queryFormSubtitle',
			tab				: 'Create Enrollment Filters',
			tabTrans		: 'scheduleEnrollments.queryFormTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEnrollments');
			}
		},
		edit: {
			id				: 'editor',
			title			: 'Schedule an Enrollment',
			titleTrans		: 'scheduleEnrollments.eventFormTitle',
			subtitle		: 'Setup an Enrollment to be processed on a schedule',
			subtitleTrans	: 'scheduleEnrollments.eventFormSubtitle',
			tab				: 'Schedule an Enrollment',
			tabTrans		: 'scheduleEnrollments.eventFormTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEnrollments');
			}
		},
		list : {
			id				: 'list',
			title			: 'View and Edit Enrollment Schedule',
			titleTrans		: 'scheduleEnrollments.eventListTitle',
			subtitle		: 'Active Email Schedules are colored green',
			subtitleTrans	: 'scheduleEnrollments.eventListSubtitle',
			tab				: 'Email Schedule',
			tabTrans		: 'scheduleEnrollments.eventListTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEnrollments');
			}
		},
		history : {
			id				: 'history',
			title			: 'Enrollment History',
			titleTrans		: 'scheduleEnrollments.eventHistoryTitle',
			subtitle		: 'Below is a list of Enrollments that have run and who were enrolled',
			subtitleTrans	: 'scheduleEnrollments.eventHistorySubtitle',
			tab				: 'Enrollment History',
			tabTrans		: 'scheduleEnrollments.eventHistoryTab',
			reloadOnTabChange : true,
			visible 	: () =>  { 
				return this.permissionService.hasPermission('pages.scheduleEnrollmentHistory');
			}
		},
	};

}