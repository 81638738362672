import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-impersonate',
  templateUrl: '../input/input.component.html',
  styleUrls: ['../default/default.component.css', '../input/input.component.css', './impersonate.component.css']

})

export class ImpersonateComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public override settings : any = {
		'id' : 'am_modal_impersonate',
		'title': 'Please select a person to Impersonate',
		'titleTrans': 'modal.selectImpersonation',
		'canClose': true,
		'submit': 'Impersonate',
		'submitTrans': 'modal.impersonate',
		'close': 'Close',
		'closeTrans': 'modal.close',
		'optionName': 'Select a person',
		'optionNameTrans': 'modal.selectPerson',
		'options': [],
		'searchFail': 'No matching options found',
		'searchFailTrans': 'user.searchFail',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public override parentSettings: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.formatOptions();
	}

	/*
	 * Format the options 
	*/
	formatOptions() : void {
		if (this.settings.options) {
			this.settings.options.forEach((option) => {
				option.name = option['firstName'] + ' ' + option['lastName'];
			});
		}
	}
}