<section class="row mb-1" 
	[hidden]="!showControls" 
	localize 
	[model]="localeStrings" 
	[items]="localeStringsKeys">

	<div class="col-3">
		<button id="am_courselaunch_exit"
			[disabled]="!allowControls" 
			mat-flat-button
			color="primary"
			(click)="exitCourse()" 
			class="mr-auto" 
			title="courselaunch.exit">

			<span>{{localeStrings.exit}}</span>
		</button>
	</div>
	<div class="col-9 text-right">
		<button id="am_courselaunch_fullscreen" 
			[disabled]="!allowFullScreen" 
			mat-flat-button
			color="primary"
			*ngIf="!isFullScreen"
			(click)="fullScreen()" 
			title="courselaunch.fullScreen">

			<mat-icon>open_in_full</mat-icon>
		</button>
		<button id="am_courselaunch_fullscreen_exit" 
			[disabled]="!allowFullScreen" 
			mat-flat-button
			color="primary"
			*ngIf="isFullScreen" 
			(click)="exitFullScreen()" 
			title="courselaunch.exitFullScreen">

			<mat-icon>close_fullscreen</mat-icon>
		</button>
		<button id="am_courselaunch_popout" 
			[disabled]="!allowControls" 
			mat-flat-button
			color="primary"
			(click)="popOut()" 
			title="courselaunch.openNewWindow">
			
			<mat-icon>open_in_new</mat-icon>
		</button>
	</div>
</section>

<div id="am_courselaunch_course_container" *ngIf="showCourse">
	<iframe id="course-frame" 
		[style.height.vh]="85" 
		[src]="url | url" 
		[width]="frameWidth" 
		[height]="frameHeight">
	</iframe>
</div>

<!-- Course in Popup Message -->
<section class="row" [hidden]="!showMessage || !message">
	<div class="col-md-8 mx-auto">
 		<lio-card>
			<div lio-card-header>
				<h4>{{localeStrings.loading}}</h4>
				<p *ngIf="debugInfo">{{debugInfo}}</p>
				<p><br /></p>
				<button id="am_courselaunch_popup_close" 
					*ngIf="showCloseCourse" 
					mat-flat-button
					color="primary"
					[disabled]="!allowControls" 
					(click)="exitCourse()" 
					type="submit">

					<strong>{{localeStrings.closeCourse}}</strong>
				</button>

				<button id="am_courselaunch_popup_cancel"
					*ngIf="allowCancelCourseLoad" 
					mat-flat-button
					color="primary"
					(click)="exitCourse()" 
					type="submit">
					<strong>{{localeStrings.cancel}}</strong>
				</button>
			</div>
		</lio-card>
	</div>
</section>