import { NgModule } from '@angular/core';

import { TrustUrlPipe } from './trust/url.pipe';
export { TrustUrlPipe } from './trust/url.pipe';

import { TrustHtmlPipe } from './trust/html.pipe';
export { TrustHtmlPipe } from './trust/html.pipe';

import { SearchPipe } from './search/search.pipe';
export { SearchPipe } from './search/search.pipe';

import { FilterPipe } from './filter/filter.pipe';
export { FilterPipe } from './filter/filter.pipe';

import { LimitPipe } from './limit/limit.pipe';
export { LimitPipe } from './limit/limit.pipe';

import { ReversePipe } from './reverse/reverse.pipe';
export { ReversePipe } from './reverse/reverse.pipe';

import { OrderByPipe } from './order-by/order-by.pipe';
export { OrderByPipe } from './order-by/order-by.pipe';

import { HighlightPipe } from './highlight/highlight.pipe';
export { HighlightPipe } from './highlight/highlight.pipe';

import { AsArrayPipe } from './as-array/as-array.pipe';
export { AsArrayPipe } from './as-array/as-array.pipe';

import { PermissionToBooleanPipe } from './permission-to-boolean/permission-to-boolean.pipe';
export { PermissionToBooleanPipe } from './permission-to-boolean/permission-to-boolean.pipe';

@NgModule({
	declarations: [
		TrustUrlPipe,
		TrustHtmlPipe,
		SearchPipe,
		FilterPipe,
		LimitPipe,
		ReversePipe,
		OrderByPipe,
		HighlightPipe,
		AsArrayPipe,
		PermissionToBooleanPipe
	],
	exports: [
		TrustUrlPipe,
		TrustHtmlPipe,
		SearchPipe,
		FilterPipe,
		LimitPipe,
		ReversePipe,
		OrderByPipe,
		HighlightPipe,
		AsArrayPipe,
		PermissionToBooleanPipe
	],
})
export class LioPipesModule {}

