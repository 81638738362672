import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultModalComponent } from '.././default/default.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-table',
  templateUrl: './table.component.html',
  styleUrls: ['../default/default.component.css', './table.component.css']
})

export class TableComponent extends DefaultModalComponent implements OnInit {
	public override settings : any = {
		'id' : 'am_modal_table',
		'title': 'Table Title',
		'canClose': true,
		'close': 'Close',
	};

	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter	= new EventEmitter<any>();

	public hasFieldNames: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) 	public parentSettings: any) {
		super(parentSettings);
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);

		if(this.settings.fields){
			this.settings.fields.forEach((field) => {
				if(field.name){
					this.hasFieldNames = true;
				}
			});
		}
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}
}