import { Inject, Injectable } from '@angular/core';

import { utilService } from '../../services/util.service';
import { coursesService } from '../../services/courses.service';

import { LioFieldArray, LioSearchableSelectField, LioDateField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: assignSettings
})
export class assignSettings {
	constructor(
		@Inject(coursesService) private coursesService	:coursesService,
		@Inject(utilService) 	private utilService		:utilService,
	){}

	pagination:any = {
		pageLimit	: '10',
		pageLimits	: ['10', '50', '100'],
		sortMode	: {field : 'lastName', direction : 'asc'}
	};

	model:any = {
		courseID		: null,
		availableDate 	: this.utilService.getDate(1, 'mm/dd/yyyy'),
		dueDate			: this.utilService.getDate(31, 'mm/dd/yyyy'),
		expirationDate 	: this.utilService.addOneYearFromProvidedDate(this.utilService.getDate(1, 'mm/dd/yyyy'), 'mm/dd/yyyy')
	};

	localizations:any = {
		enrollUsers		: 'assign.enrollUsers',
		emailEnrolled	: 'assign.emailEnrolled',
		unenrollUsers	: 'assign.unenrollUsers',
	};

	fieldConfig:any = {
		fieldName		: 'assign',
		addCCFtoFields	: false,
		localizeFields	: true,
		permissionFields: true,
		addRoleToFields	: true,
		overrideFields	: true,
		addActiveField	: true,
	};

	formFieldConfig:any = {
		fieldName		: 'assignform',
		permissionFields: true,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Employee ID',
			model		: 'employeeID',
			type		: 'text',
			invertModel	: false,
			sortable	: true
		},
		{
			name		: 'First Name',
			model		: 'firstName',
			type		: 'text',
			invertModel	: false,
			sortable	: true
		},
		{
			name		: 'Last Name',
			model		: 'lastName',
			type		: 'text',
			invertModel	: false,
			sortable	: true
		},
		{
			name		: 'e-mail',
			model		: 'email',
			type		: 'text',
			invertModel	: false,
			sortable	: true
		}
	);

	formFields:LioFieldArray = new LioFieldArray(
		new LioSearchableSelectField({
			name			: 'Course ID',
			model			: 'courseID',
			type			: 'select',
			visible 		: true,
			debounce 		: 500,
			required 		: true,
			options			: this.coursesService.activeCourseOptionsRepSub,
			searchable		: true
		}),
		new LioDateField({
			name		: 'Course Available Date',
			nameTrans	:'assign.courseavailableDate',
			model		: 'availableDate',
			type		: 'date',
			placeholder	: 'Enter Available Date',
			minDate		: new Date(),
			maxDate		: new Date((new Date()).setDate((new Date()).getDate() + 365))
		}),
		new LioDateField({
			name		: 'Course Due Date',
			nameTrans	:'assign.courseDueDate',
			model		: 'dueDate',
			type		: 'date',
			placeholder	: 'Enter Due Date',
			minDate		: new Date((new Date()).setDate((new Date()).getDate() + 1)),
			maxDate		: new Date((new Date()).setDate((new Date()).getDate() + 365))
		}),
		new LioDateField({
			name		: 'Course Expiration Date',
			nameTrans	:'assign.courseExpires',
			model		: 'expirationDate',
			type		: 'date',
			placeholder	: 'Enter Expiration Date',
			minDate		: new Date((new Date()).setDate((new Date()).getDate() + 1)),
			maxDate		: new Date((new Date()).setDate((new Date()).getDate() + 365)),
			locked		: 'employeeCourses.modifyExpiration'
		})
	)
}