import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { feedbackService } from '../../../../services/feedback.service';
import { errorsService } from '../../../../services/errors.service';
import { debugService } from '../../../../services/debug.service';


@Component({
  selector: 'lio-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnDestroy, OnInit {
	public errors	:any 		= []; 
	public messages	:any 		= []; 
	public showSQL	:boolean 	= false; 
	public task		:string 	= ''; 
	public sql		:string 	= ''; 

	private subscriptions:Subscription = NEVER.subscribe();

  constructor(
									private changeDetectorRef	:ChangeDetectorRef,
		@Inject(feedbackService) 	public 	feedbackService		:feedbackService,
		@Inject(errorsService)     	public 	errorsService		:errorsService,
		@Inject(debugService) 		public 	debugService		:debugService
	){ 
		this.debugService.register('error', this);
		this.showSQL 	= this.errorsService.showSQL;
		this.task 		= this.errorsService.lastTask;
		this.sql 		= this.errorsService.sql;
	}

	ngOnInit(){
		this.subscriptions.add(
			this.feedbackService.addedFeedback.subscribe(() => {
				this.update();
			})
		);
		this.subscriptions.add(
			this.feedbackService.resetFeedback.subscribe(() => {
				this.update();
			})
		);
	}

	update() {
		this.errors 	= this.feedbackService.errors;
		this.messages 	= this.feedbackService.messages;
		this.changeDetectorRef.detectChanges();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
}