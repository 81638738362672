<lio-fields 
	[fields]="fields" 
	[fieldConfig]="fieldConfig">
	<div class="w-100" *ngFor="let item of fields; index as i">
		<lio-field-row [field]="fields[i]"
			[formGroup]="formGroup"
			[builderCtrl]="builderCtrl"
			[model]="model" 
			(onFieldRowChanged)="onChanged.emit($event)"
			[formdata]="formdata"
			(onFieldRowSearch)="onSearch.emit($event)"
			[appearance]="appearance">
		</lio-field-row>
	</div>
</lio-fields>