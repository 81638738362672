import { Component, Inject, Input, Output, EventEmitter, OnDestroy, DoCheck } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { NEVER, Subject, Subscription } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { navService } from '../../../../services/nav.service';
import { storageService } from '../../../../services/storage.service';
import { feedbackService } from '../../../../services/feedback.service';
import { localizationService } from '../../../../services/localization.service';
import { ccfService } from '../../../../services/ccf.service';
import { coursesService } from '../../../../services/courses.service';
import { utilService } from '../../../../services/util.service';

import { mailTemplateSettings } from './mail-template.settings';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-email-editor',
	templateUrl: './email-editor.component.html',
})
export class LioEmailEditor implements OnDestroy, DoCheck{
	@Input() settings			:any;
	@Input() showCourses		:boolean 			= false;
	@Input() appearance			:MatFormFieldAppearance 			= 'outline';
	@Input() preview			:boolean			= true;
	public callback				: Subject<any> 			= new Subject();

	private _template = {
		'filters': [],
	};
	get template(){
		return this._template;
	}
	@Input() set template(value:any){
		this._template 	= value;
		this.filters 	= this.setFilters();
		this.setCourseIDs();
		this.updateTemplate.emit(value);
	}

	@Output() updateTemplate	:EventEmitter<any> = new EventEmitter();
	@Output() onOptionsLoaded	:EventEmitter<any> = new EventEmitter();
	@Output() onChanged			:EventEmitter<any> = new EventEmitter();

	private defaultRule			:any 			= {};
	private coursesWithAll		:Array<any> 	= [];
	private coursesWithoutAll	:Array<any> 	= [];
	private subscriptions		:Subscription 	= NEVER.subscribe();

	public filters				:Array<any> 	= [];
	public modelSettings		:any			= this.mailTemplateSettings;

	public templateField:LioSearchableSelectField = new LioSearchableSelectField({
		model				: 'courseIDs',
		amID 				: 'am.form.courseID',
		options				: [],
		optionNameField 	: 'nameID',
		optionValueField 	: 'courseID',
		clearable			: true,
		multiple 			: true
	});

	public localeStrings		:any 			= {
		sampleSelect 		: 'Which course(s) would you like to sample?',
		sampleSelectTrans 	: 'email.whichcoursetosample',
		courseSelect 		: 'Select the specific course this template will be assigned to',
		courseSelectTrans 	: 'email.selectcourse',
		ruleLabel			: 'Language',
		ruleLabelTrans		: 'emailTemplate.ruleLabel'
	};

	public builderCtrl			:any = {};
	constructor(
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(navService) 			public navService 			:navService,
		@Inject(storageService) 		public storageService 		:storageService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(localizationService) 	public localizationService :localizationService,
		@Inject(ccfService) 			public ccfService 			:ccfService,
		@Inject(coursesService) 		public coursesService 		:coursesService,
		@Inject(utilService) 			public utilService 			:utilService,
		@Inject(mailTemplateSettings) 	public mailTemplateSettings :mailTemplateSettings
	){
		this.debugService.register('emailEditor', this);

		this.subscriptions = NEVER.subscribe();
		
		this.subscriptions.add(
			this.coursesService.activeCourses.subscribe((result) => {
				let coursesWithoutAll 	= this.utilService.copy(result);
				let coursesWithAll 		=  this.utilService.copy(result);

				// Add a global course to the start of the array
				coursesWithAll.unshift({
					courseID: '0',
					courseName: "All Courses",
					nameID: "All Courses",
				});

				this.coursesWithAll = coursesWithAll;
				this.coursesWithoutAll = coursesWithoutAll;
				if (this.template.type === 'Completion') {
					this.templateField.options = coursesWithAll;
				} else {
					this.templateField.options = coursesWithoutAll;
				}

			})
		);
		
		this.filters = this.setFilters();
		this.setCourseIDs();
		this.getCourses();
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}
 
	ngDoCheck(){
		if(this.template.type == 'Completion' || !this.preview){
			this.templateField.name 		= this.localeStrings.courseSelect;
			this.templateField.nameTrans 	= this.localeStrings.courseSelectTrans;
		}else{
			this.templateField.name 		= this.localeStrings.sampleSelect;
			this.templateField.nameTrans 	= this.localeStrings.sampleSelectTrans;
		}
	}

	/*
	* Gets the courses
	*/
	getCourses() {
		if (this.showCourses) {
			this.coursesService.get();
		}
	}

	/**
	 * Sets the course IDs
	 */
	setCourseIDs() {
		if (!this.template.courseIDs) {
			this.template.courseIDs = [];
		}
	}

	/**
	 * Sets the language filter
	 */
	setFilters() {
		let langFilter		= this.localizationService.getLangFilter();
		let settings		= this.storageService.get('settings');		
		let ccfFilters		= this.ccfService.addCCFtoFilters([]);

		// Uncomment to test other fields
		// settings['emailTemplateLangField'] = 'e.clientCustomField3';
		ccfFilters.forEach((filter) => {
			if(filter.model == settings['emailTemplateLangField']) {
				langFilter = filter;
				return;
			}
		});

		this.defaultRule = {
			field		: langFilter.field,
			operator	: 'equal',
			value		: '',
			required	: true,
			clearable	: true
		};

		if(!this.template.filters){
			this.template.filters = [this.defaultRule];
		}

		return [langFilter];
	}

	/*
	* On Template Deleted
	*/
	onTemplateDelete() {
		this.template 				= {};
		this.template.filters 		= [this.defaultRule];
		this.template.courseIDs 	= [];
		this.onChanged.emit(true);
	}

	/*
	* On Template Loaded
	*/
	onTemplateLoad(template) {
		if (template) {
			this.template = template;
			if (template.filters && template.filters[0]) {
				template.filters[0].required = true;
			} else {
				this.template.filters = [this.defaultRule];
			}
		} else {
			template = {};
			template.filters = [this.defaultRule];
		}

		if (!template.courseIDs) {
			template.courseIDs = [];
		}
	}


	/*
	* On Template Saved
	*/
	onTemplateSaved(template) {
		if (template) {
			this.template = template;
			this.onChanged.emit(true);
		}
	}

	onModelPreSubmit(template){
		if (template) {
			this.template = template;
			this.template.filters = this.builderCtrl.getRules();
			this.onChanged.emit(true);
		}
	}

	/*
	* On update handler
	*/
	onupdate(field?) {
		if(field){
			this.navService.changedForm = true;
		}
		
		this.feedbackService.clearErrors();
		if (this.template.type === 'Completion') {
			this.templateField.options = this.coursesWithAll;
		} else {
			this.templateField.options = this.coursesWithoutAll;
		}
	}
}