import { Component, Inject, Input } from '@angular/core';

import { debugService } from '../../../../services/debug.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { utilService } from '../../../../services/util.service';

import { LioSearchableSelectField } from '../../../lio-forms/lio-forms.models';

@Component({
	selector: 'lio-assign-companies',
	templateUrl: './assign-companies.component.html',
})
export class LioAssignCompanies{
	@Input() public employees 			:Array<any> = [];
	@Input() public filteredEmployees	:Array<any> = [];
	@Input() public unmodifiedEmployees :Array<any> = [];
	@Input() public fields 				:Array<any> = [];
	@Input() public suffixButtons		:Array<any> = [];
	@Input() public pagination 			:any 		= null;

	private _allCompanies				:Array<any> = [];
	public get allCompanies()			:Array<any>{
		return this._allCompanies;
	}
	@Input() public set allCompanies(val:Array<any>){
		this._allCompanies 			= val;
		this.companyField.options 	= val;
	}

	private _targetCompany				:any 		= null;
	public get targetCompany()			:any{
		return this._targetCompany;
	}
	@Input() public set targetCompany(val:any){
		this._targetCompany = val;

		this.suffixButtons 	= [
			{
				name 		: 'Allow',
				id 			: 'am_results_company_allow',
				color 		: 'primary',
				callback 	: (employee) => { this.activateCompany(employee); },
				visible 	: (employee) => {
					return this.targetCompany.id != -1 && employee.companies.indexOf(this.targetCompany.id) == -1;
				}
			},
			{
				name 		: 'DisAllow',
				id 			: 'am_results_company_disallow',
				color 		: 'warn',
				callback 	: (employee) => { this.deActivateCompany(employee); },
				visible 	: (employee) => {
					return this.targetCompany.id != -1 && employee.companies.indexOf(this.targetCompany.id) > -1;
				}
			}
		];
	}

	public companyField:LioSearchableSelectField = new LioSearchableSelectField({
		model 				: 'id',
		options 			: [],
		orderBy				: 'companyID',
		optionValueField	: 'companyID',
		nameFormatter 		: (_name, option) => { return option.companyID + ': ' + option.coName; }
	});

	constructor(
		@Inject(debugService) 		public debugService 	:debugService,
		@Inject(lmsService) 		public lmsService 		:lmsService,
		@Inject(lioModalService) 	public lioModalService 	:lioModalService,
		@Inject(utilService) 		public utilService 		:utilService,
	){
		this.debugService.register('assignCompaniesComponent', this);
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(collection){
		this.filteredEmployees = collection.filtered;
	}

	activateCompany(employee){
		let changes = [{UID : employee['UID'], addCompanies : [this.targetCompany.id], removeCompanies : []}];
	
		this.lmsService.postAsync('editpermissions/modifyCompanies', {changes : changes}, 'processing').then((result) => {
			this.handleResults(result);
		});
	}

	deActivateCompany(employee){
		let changes = [{UID : employee['UID'], addCompanies : [], removeCompanies : [this.targetCompany.id]}];
	
		this.lmsService.postAsync('editpermissions/modifyCompanies', {changes : changes}, 'processing').then((result) => {
			this.handleResults(result);
		});
	}

	handleResults(result){
		var token = result.properties.token;
		if(token){
			this.lmsService.getAsyncResult(token, (gresult) => {
				if(gresult.success){
					this.unmodifiedEmployees = this.utilService.copy(this.employees);
					this.lioModalService.show('changeSuccess');
				} else {
					this.lioModalService.show('changeFail');
				}
			});
		} else {
			if (result.success) {
				this.unmodifiedEmployees = this.utilService.copy(this.employees);
				this.lioModalService.show('changeSuccess');
			} else {
				this.lioModalService.show('changeFail');
			}
		}
	}
}