import { Component, Inject, Input, Output, EventEmitter, DoCheck, ChangeDetectorRef } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { of } from 'rxjs';

import { debugService } from '../../../../services/debug.service';
import { navService } from '../../../../services/nav.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { localizationService } from '../../../../services/localization.service';
import { feedbackService } from '../../../../services/feedback.service';
import { utilService } from '../../../../services/util.service';
import { FormGroup } from '@angular/forms';

/** 
  * A directive for displaying a form that can be used to manipulate instances of a model
  * 
  * @param settings the settings template for the model
  * @param modelName the friendly name of the model
  * @param modelNameTrans the translation string for the name of the model
  * @param createEndpoint the endpoint to use when creating a new instance of the model
  * @param getAllEndpoint the endpoint to use when getting a list of the existing instances of the model
  * @param deleteEndpoint the endpoint to use when deleting an instance of the model
  * @param saveEndpoint the endpoint to use when saving an existing instance of the model
  * @param onUpdate callback for a function to call when any input has been changed
  * @param model the object representing the instance of the model currently being edited
  * @param additionalButtons an array of objects representing additional buttons to add to the bottom of the form
  * 	@param name the name of the button
  * 	@param trans the translation string for the name of the button
  * 	@param action the function to call when the button is clicked
  * @param customConfirmations an array of objects containing custom functions to call before allowing functions in this class to proceed
  * 	@param operations an array of strings matching the names of functions in this class
  * 		- when any of these functions is called the confirmation function will be checked first to determine whether the initial function is allowed to proceed
  * 	@param callback the function to call to determine whether this function may proceed
  * 		- callback will be passed a copy of the working instance of the model
 */
@Component({
	selector: 'lio-model-editor',
	templateUrl: './model-editor.component.html',
})
export class LioModelEditor implements DoCheck{
	public formGroup:FormGroup = new FormGroup({});

	public amID = 'model';

	public _model:any = {
		field : {
			name 		: 'Select an option',
			nameTrans 	: '',
			model 		: 'model',
			options 	: []
		}
	};
	get model(){
		return this._model.model;
	}
	@Input() set model(value:any){
		if(typeof value == 'undefined'){
			value = {};
		}
		this._model.model = value;
	}

	private _settings:any = {};
	get settings(){
		return this._settings;
	}
	@Input() set settings(value:any){
		this._settings = value;
		if (this._settings.amID) {
			this.amID = this._settings.amID;
		}
		this.getAll();
	}

	@Input() formData			:any 				= null;
	@Input() appearance			:MatFormFieldAppearance = 'outline';

	@Output() onClick				:EventEmitter<any>  = new EventEmitter();
	@Output() onUpdate			:EventEmitter<any>  = new EventEmitter();
	@Output() onOptionsLoaded	:EventEmitter<any>	= new EventEmitter();
	@Output() onModelLoad		:EventEmitter<any> 	= new EventEmitter();
	@Output() onModelDelete 	:EventEmitter<any> 	= new EventEmitter();
	@Output() onModelSave		:EventEmitter<any> 	= new EventEmitter();

	@Output() onModelPreSubmit	:EventEmitter<any> 	= new EventEmitter();
	@Output() onModelPreSave	:EventEmitter<any> 	= new EventEmitter();
	@Output() onModelPreCopy	:EventEmitter<any> 	= new EventEmitter();
	@Output() onModelPreCreate	:EventEmitter<any> 	= new EventEmitter();
	@Output() onValidate		:EventEmitter<any> 	= new EventEmitter();

	public modelSort 			:any			= {
		field 		: {
			model					: 'model',
			name 					: 'Sort by',
			nameTrans 				: 'filter.sortBy',
			options 				: [],
			optionValueField 		: 'model',
			includeInvisibleOptions : true
		},
		model 		: null,
		reverse		: false,
		sortOrder	:null
	};

	public submitMode 			:string			= '';

	public localeStrings		:any			= {
		modelName 					: '',
		modelNameTrans 				: '',
		createNewModelText			: '',
		createNewModelTextTrans		: 'modelEditor.newModel',
		createModel 				: 'Create',
		createModelTrans			: 'modelEditor.createModel',
		saveModel					: 'Save',
		saveModelTrans				: 'modelEditor.saveModel',
		copyModel					: 'Copy',
		copyModelTrans				: 'modelEditor.copyModel',
		deleteModel					: 'Delete',
		deleteModelTrans			: 'modelEditor.deleteModel'
	};

	public localeStringsKeys		:Array<any>	= [
		'modelName',
		'createNewModelText',
		'createModel',
		'saveModel',
		'copyModel',
		'deleteModel',
	];

	public localeStringsMacros		:Array<any> = [{
		item 	: 'createNewModelText',
		key 	: 'modelName',
		value 	: () => { return this.localeStrings.modelName; }
	}];

	constructor(
										private changeDetectorRef	:ChangeDetectorRef,
		@Inject(debugService) 			public debugService 		:debugService,
		@Inject(navService) 			public navService 			:navService,
		@Inject(formValidatorService) 	public formValidatorService :formValidatorService,
		@Inject(lmsService) 			public lmsService 			:lmsService,
		@Inject(lioModalService) 		public lioModalService 		:lioModalService,
		@Inject(localizationService)	public localizationService	:localizationService,
		@Inject(feedbackService) 		public feedbackService 		:feedbackService,
		@Inject(utilService) 			public utilService 			:utilService
	){
		this.debugService.register('modeleditor', this);
	}
	
	public _oldFields				:Array<any>	= [];
	public _oldModelOptions			:Array<any>	= [];
	public _oldModelName			:any		= '';
	public _oldModelNameTrans		:any		= '';
	ngDoCheck(){
		if(this.settings){
			if(this.settings.modelNameTrans != this._oldModelNameTrans){
				this._oldModelNameTrans 			= this.settings.modelNameTrans;
				this.localeStrings.modelNameTrans 	= this.settings.modelNameTrans;
			}

			if(this.settings.modelName != this._oldModelName){
				this._oldModelName					= this.settings.modelName;
				this.localeStrings.modelName		= this.settings.modelName;
			}

			if(this.settings.editOptions && this.settings.editOptions != this._oldModelOptions){
				this._oldModelOptions				= this.settings.editOptions;
				this.sortModelOptions();
			}

			if(!this.utilService.shallowObjectEquals(this.settings.fields, this._oldFields)){
				this._oldFields						= this.utilService.shallowCopy(this.settings.fields);
				let options 						= [];
				this.settings.fields.forEach((field)=>{
					if(field.sortable){
						options.push(field);
					}
				});
				this.modelSort.field.options = options;
			}
		}
	}

	sortModelOptions() {
		let sortBy					= this.modelSort.sortOrder || [this.modelSort.model],
				name 						= this.localizationService.get(this.localeStrings.createNewModelTextTrans, '', this.localeStringsMacros),
				createNew 			= {'name' : name, 'value' : ''};

		this._model.field.options	= [createNew].concat(this.sortWithBackups(this.settings.editOptions, sortBy, this.modelSort.reverse));
		this.onUpdate.emit();
	}

	switchModelSortDir() {
		if(this.modelSort.reverse) {
			this.modelSort.reverse = false;
		} else {
			this.modelSort.reverse = true;
		}
		this.sortModelOptions();
	}

	/**
	 * Gets a list of models that can be loaded by calling the passed getAll endpoint
	 */
	getAll() {
		return this.checkCustomConfirmations('getAll').then((confirmed) => {
			if (confirmed) {
				return this.lmsService.post(this.settings.endpoints.getAll, {}, {cache: true}).then((result) => {
					this.settings.editOptions = result.properties.values;
					//if we already had an existing event, update it
					this.syncLoadedModel();
					
					if(this.onOptionsLoaded){
						this.onOptionsLoaded.emit(result.properties.values);
					}
				});
			} else {
				return of(false).toPromise();
			}
		});
	}

	/**
	 * On update of the form
	 */
	updatedField = (field) => {
		this.navService.changedForm = true;
		this.formValidatorService.resetFields();
		this.onUpdate.emit(field);
	}

	/**
	 * Validate the form
	 * @return {boolean}
	 */
	validateForm() {
		this.feedbackService.clearErrors();
	
		let isValid = true,
			model = this.model;
	
		if (!model || model === {}) {
			this.feedbackService.setError('pleaseFillInForm');
			return false;
		}

		if (!this.formValidatorService.isValid(this.model, this.settings.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.model = this.formValidatorService.getData();

		// Parent can add additional validation
		if (isValid) {
			this.onValidate.emit(isValid);
		}

		return isValid;
	}

	/**
	 * Handles form submission, choosing to either call the create or save endpoint based on the submitMode setting
	 * @param {object} model 
	 */
	submit() {
		let mode = this.submitMode;

		this.submitMode = null;

		if (!mode) {
			return of(false).toPromise();
		}

		// We shouldnt need to validate the form upon deleting
		if (mode !== 'delete' && !this.validateForm()) {
			return of(false).toPromise();
		}

		this.onModelPreSubmit.emit(this.model);
		this.checkCustomConfirmations('submit').then((confirmed) => {
			if (confirmed) {
				switch(mode) {
					case 'create'	:
						return this.create(this.model);
					case 'save'		:
						return this.save(this.model);
					case 'copy'		:
						return this.copy(this.model);
					case 'delete'	:
						return this.delete(this.model);
				}
			}
			return of(false).toPromise();
		});
	}

	/**
	 * Creates an instance of the model by calling the passed save endpoint
	 * @param {object} model 
	 */
	create(model) {
		this.onModelPreCreate.emit(model);

		if (this.model) {
			this.createModel(this.model);
		}
	}

	/**
	 * Creates an instance of the model by calling the passed create endpoint
	 * @param {object} model 
	 */
	createModel(model) {
		return this.checkCustomConfirmations('create').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('saving');
				this.navService.changedForm = true;
				return this.lmsService.post(this.settings.endpoints.create, {'model' : model}, {keepChangedForm : true, clearCache: true}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						this.lioModalService.show('modelCreateSuccess', null, {key : 'modelName', value : this.localeStrings.modelName});
						this.model = result.properties.value;
						this.modelLoaded();
						return this.getAll().then(() => {
							if (this.onModelSave) {
								this.onModelSave.emit(this.model);
							}
						})
					} else {
						this.lioModalService.show('modelCreateFail', null, {key : 'modelName', value : this.localeStrings.modelName});
						return of(false).toPromise();
					}
				});
			}
		});
	}

	/**
	 * Copy an instance of the model by calling the passed save endpoint
	 * @param {object} model 
	 */
	copy(model) {
		this.onModelPreCopy.emit(model)
		if (model) {
			this.copyModel(model);
		}
	}

	/**
	 * Creates a copy of a model
	 */
	copyModel(model){
		return this.checkCustomConfirmations('copy').then((confirmed) => {
			if (confirmed) {
				delete model.id;
				model.name = model.name + ' (copy)';
				this.lioModalService.showLoading('saving');
				this.navService.changedForm = true;
				return this.lmsService.post(this.settings.endpoints.copy, {'model' : model}, {keepChangedForm : true, clearCache: true}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						this.lioModalService.show('modelCopySuccess', null, {key : 'modelName', value : this.localeStrings.modelName});
						this.model = result.properties.value;
						this.modelLoaded();
						return this.getAll();
					} else {
						this.lioModalService.show('modelCopyFail', null, {key : 'modelName', value : this.localeStrings.modelName});
						return of(false).toPromise();
					}
				});
			}
		});
	}

	/**
	 * Saves an instance of the model by calling the passed save endpoint
	 * @param {object} model 
	 */
	save(model) {
		this.onModelPreSave.emit(model);
		if (model) {
			this.saveModel(model);
		}
	}

	saveModel(model) {
		return this.checkCustomConfirmations('save').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('saving');
				this.navService.changedForm = true;
				return this.lmsService.post(this.settings.endpoints.save, {'model' : model}, {keepChangedForm : true, clearCache: true}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						this.lioModalService.show('modelSaveSuccess', null, {key : 'modelName', value : this.localeStrings.modelName});
						this.model = result.properties.value;
						this.modelLoaded();
						this.getAll().then(() => {
							if (this.onModelSave) {
								this.onModelSave.emit(this.model);
							}
						})
					} else {
						this.lioModalService.show('modelSaveFail', null, {key : 'modelName', value : this.localeStrings.modelName});
					}
				});
			}
		});
	}

	/**
	 * Deletes an instance of the model by calling the passed delete endpoint
	 * @param {object} model 
	 */
	delete(model) {
		return this.checkCustomConfirmations('delete').then((confirmed) => {
			if (confirmed) {
				this.lioModalService.showLoading('deleting');
				this.navService.changedForm = true;
				return this.lmsService.post(this.settings.endpoints.delete, {'id' : model.id, 'model': model}, {keepChangedForm : true, clearCache: true}).then((result) => {
					this.lioModalService.hideLoading();
					if (result.success) {
						this.model = {};
						this.formGroup.reset();
						this.modelDeleted();
						this.lioModalService.show('modelDeleteSuccess', null, {key : 'modelName', value : this.localeStrings.modelName});
						this.getAll();
					} else {
						this.lioModalService.show('modelDeleteFail', null, {key : 'modelName', value : this.localeStrings.modelName});
					}
				});
			}
		});
	}

	/**
	 * Clears the active model
	 */
	clearForm(){
		return this.checkCustomConfirmations('clearForm').then((confirmed) => {
			if (confirmed) {
				this.model = {};
			}
		});
	}

	/**
	 * Reselects the active model, used after the model options list has been loaded or cleared
	 */
	syncLoadedModel(){
		if(this.model && this.model.id){
			this.settings.editOptions.forEach((option) => {
				if(this.model.id == option.id){
					this.model = option;
				}
			});
		}
	}

	/**
	 * Runs the passed onModelLoad function when a model has been loaded
	 */
	modelLoaded() {
		this.model = this.utilService.copy(this.model);
		if (!this.model) {
			this.model = {};
		}
		this.syncLoadedModel();

		this.onModelLoad.emit(this.model);
		this.onUpdate.emit();
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Runs the passed onModelDelete function when a model has been deleted
	 */
	modelDeleted() {
		this.onModelDelete.emit();
	}

	/**
	 * Checks the list of custom confirmations to see if any of them are waiting for the passed operation
	 * If any of them are set to check the operation, they are called and passed the working model, if any return false, this function also returns false
	 * @param operation the name of a function in this directive
	 */
	checkCustomConfirmations(operation) {
		let promises = [];
		if (this.settings.customConfirmations) {
			this.settings.customConfirmations.forEach((confirmation) => {
				if (confirmation.operations.indexOf(operation) > -1) {
					//make a copy of the current element, otherwise when .then is run, the array pointer will always be on the last element since this is async
					let confirmationIter 	= confirmation;
					let modelCopy 			= this.utilService.copy(this.model);
					promises.push(of(confirmationIter.callback(modelCopy)).toPromise());
				}
			});
		}

		return promises.reduce((promiseChain, newPromise) => {
			return promiseChain.then((confirmationState) => {
				if (confirmationState) {
					return newPromise;
				} else {
					return of(false).toPromise();
				}
			});
		}, of(true).toPromise());
	}

	/**
	 * Sets the model
	 * @param {object} model
	 */
	setModel = (model) => {
		this.model = model;
	}

	/**
	 * Sorts a collection of objects, an array of properties may be passed for sort paramaters.
	 * Sort preference is to the beginning of the list
	 * @param items the collection of items to sort
	 * @param fields the fields to sort by
	 */
	sortWithBackups(items, fields, reverse) {
		let filtered = [];

		if(reverse) {
			reverse = -1;
		} else {
			reverse = 1;
		}

		items.forEach((item) => {
			filtered.push(item);
		});

		let sortItem = (a, b, aFields = null, bFields = null) => {
			if (!aFields) {
				aFields = fields.slice();
			}
			if (!bFields) {
				bFields = fields.slice();
			}

			let aItem 	= a[aFields[0]],
				bItem 	= b[bFields[0]];

			if (aItem == null && aFields.length > 1) {
				aFields.shift();
				return sortItem(a, b, aFields, bFields); 
			} 
			
			if (bItem == null) {
				if(bFields.length > 1) {
					bFields.shift();
					return sortItem(a, b, aFields, bFields);
				}
			}

			if (this.utilService.isString(aItem)) {
				aItem = aItem.toLowerCase();
			}
			
			if (this.utilService.isString(bItem)) {
				bItem = bItem.toLowerCase();
			}

			if (aItem == null) {
				if (bItem == null) {
					return -1 * reverse;
				} else {
					return 1 * reverse;
				}
			} else if (bItem == null) {
				return -1 * reverse;
			} else if (aItem >= bItem) {
				return 1 * reverse;
			} else {
				return -1 * reverse;
			}
		};

		filtered.sort(sortItem);
		
		return filtered;
	}
}