import { Component, OnDestroy, Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { utilService } from '../../services/util.service';
import { enrollmentSettings } from './enrollments.settings';

@Component({
	selector: 'lio-enrollments-page',
	templateUrl: './enrollments.component.html'
})
export class EnrollmentComponent implements OnDestroy {
	
	public panelConfig		:any 		= this.utilService.copy(this.enrollmentSettings.panelConfig);

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(enrollmentSettings) 		public enrollmentSettings		:enrollmentSettings
	){
		this.navService.setActivePage('enrollments');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.navService.displayPage();
	}
}