<div [id]="cardId" class="card {{cardClass}}">
	<div localize [model]="localeStrings" [items]="['headerText', 'subHeaderText']">
		<div #customHeader [hidden]="!customHeader.children.length" class="card-header text-center {{headerClass}}">
			<ng-content select="[lio-card-header]"></ng-content>
		</div>

		<!-- Defualt header if no custom header was used -->
		<div *ngIf="!customHeader.children.length && (localeStrings.headerText || localeStrings.headerTextTrans || showClose)" 
			[id]="cardId + '_header_default'"
			class="card-header text-center {{headerClass}}">
			
			<button href [id]="cardId + '_close_button'" *ngIf="showClose" class="btn p-0 float-right" (click)="goback()">
				<i class="fa fa-times text-secondary" aria-hidden="true"></i>
		        <span class="sr-only">Close Page</span>
			</button>

			<div [id]="headerId" *ngIf="localeStrings.headerText || localeStrings.headerTextTrans" class="h4">
				{{localeStrings.headerText}}
			</div>

			<div [id]="subHeaderId" *ngIf="localeStrings.subHeaderText || localeStrings.subHeaderTextTrans" class="h6">
				{{localeStrings.subHeaderText}}
			</div>
		</div>
	</div>

	<div #hasBody [hidden]="!hasBody.children.length" [id]="bodyId" [ngClass]="bodyClass ? bodyClass : 'p-1 p-sm-3'">
		<ng-content select="[lio-card-body]"></ng-content>
	</div>

	<div #hasFooter [hidden]="!hasFooter.children.length" [id]="footerId" class="card-footer {{footerClass}}">
		<ng-content select="[lio-card-footer]"></ng-content>
	</div>
</div>
<lio-error *ngIf="!hideErrors"></lio-error>