<div localize [model]="formConditions" [items]="['true', 'false', 'either', 'or', 'and']">
	<div id="am_enrollment_conditions" class="row">
		<label class="col-12 col-sm-2 col-form-label col-form-label-sm text-sm-left text-md-right font-weight-bold">
			<span>Enrollment Conditions</span>
		</label>
		<div class="col-12 col-sm-8">
			<span [hidden]="model.showConditions" (click)="model.showConditions = true" class="fa fa-caret-right fa-lg"></span>
			<span [hidden]="!model.showConditions" (click)="model.showConditions = false" class="fa fa-caret-down fa-lg"></span>
		</div>
	</div>

	<div class="form-group row rounded border" *ngIf="model.showConditions"> 
		<table id="fields_table" class="mx-3 table table-md small mb-0">
			<tbody>
				<tr class="row no-gutters" [ngClass]="{'error':condition.hasError}" *ngFor="let condition of model.conditions; index as i">
					<td class="no-wrap">
						<button 
							mat-raised-button 
							color="warn" 
							class="my-1 py-1" 
							(click)="deleteCondition(condition)" 
							[disabled]="i == 0" 
							[ngClass]="{'disabled':i == 0}">
							<i class="fa fa-lg fa-trash"></i>
						</button>
					</td>

					<td class="pl-1 col-2">
						<lio-searchable-select [model]="model.conditions[i]"
							[field]="connectionField"
							(onUpdate)="onUpdate.emit($event)"
							[appearance]="appearance" 
							[disabled]="i == 0">
						</lio-searchable-select>
					</td>

					<td class="pl-1 col">
						<lio-searchable-select [model]="model.conditions[i]"
							[field]="courseIDField"
							(onUpdate)="onUpdate.emit($event)"
							[appearance]="appearance" 
							[disabled]="i == 0">
						</lio-searchable-select>
					</td>
					<td class="pl-1 col-2">
						<lio-searchable-select [model]="model.conditions[i]"
							[field]="enrolledField"
							(onUpdate)="onUpdate.emit($event)"
							[appearance]="appearance" 
							[disabled]="i == 0">
						</lio-searchable-select>
					</td>
					<td class="pl-1 col-2">
						<lio-searchable-select [model]="model.conditions[i]"
							[field]="completedField"
							(onUpdate)="onUpdate.emit($event)"
							[appearance]="appearance" 
							[disabled]="i == 0">
						</lio-searchable-select>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="py-2 col border-top">
			<span (click)="addCondition()">
				<span class="fa fa-plus-square fa-lg button mr-2"></span>
				<span class="small-topic">Add condition</span>
			</span>
		</div>
	</div>
</div>