<div class="modal-container" id="{{settings.id}}_modal" localize [model]="settings" [items]="['title', 'description', 'searchFail', 'optionName', 'close', 'submit']">
	
	<!-- Close Button -->
	<button (click)="close()" *ngIf="settings.canClose" class="button-hide modal-closer float-right">
		<i class="modal-close fa fa-times text-secondary"></i>
        <span class="sr-only">Close Modal</span>
	</button>

	<!-- Header -->
	<header class="modal-header">
		<h2 id="am_modal_title" class="h5 modal-title mx-auto">
			<span [innerHtml]="settings.title | html"></span>
		</h2>
	</header>
	
	<!-- Description -->
	<section 
		id="am_modal_subtext" 
		*ngIf="settings.description" 
		class="modal-content" 
		[innerHtml]="settings.description | html">
	</section>

	<!-- Input -->
	<section *ngIf="settings.input">
		<input class="form-control" [(ngModel)]="selection" />
	</section>

	<!-- Date -->
	<section *ngIf="settings.date">
		<lio-mat-wrap-date-field
			amID="am_form_{{settings.model}}"
			[model]="model" 
			[field]="settings" 
			[minDate]="settings.minDate" 
			[maxDate]="settings.maxDate"
			[placeholder]="settings.placeholder"
			[required]="settings.required == true">
		</lio-mat-wrap-date-field>
	</section>

	<!-- Drop down selection -->
	<section *ngIf="settings.options">
		<mat-form-field class="lio-drop-down" appearance="fill">
			<span style="top:0;" class="mat-form-field-label mb-4">{{settings.optionName}}</span>
			<mat-select id="am_modal_input_select" [(ngModel)]="selection">
				<mat-option>
					<ngx-mat-select-search 
						id="am_input_modal_search"
						[formControl]="selectionFilterCtrl"
						[placeholderLabel]="settings.optionName" 
                       	[noEntriesFoundLabel]="settings.searchFail">
					</ngx-mat-select-search>
				</mat-option>
				<mat-option id="{{settings.id}}_option_{{i}}" *ngFor="let option of filteredSelection | async; index as i;" [value]="option">
					{{option.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</section>

	<!-- Footer -->
	<footer class="modal-footer">
		<button mat-raised-button 
			*ngIf="settings.submit && !settings.date"
			color="primary"
			id="modal_action_button" 
			(click)="submit(selection)" 
			class="mr-2 focus-primary" 
			[disabled]="!selection">
			{{settings.submit}}
		</button>
		<button mat-raised-button 
			*ngIf="settings.submit && settings.date"
			color="primary" 
			id="modal_action_button" 
			(click)="submit(model.value)" 
			class="mr-2" 
			[disabled]="!model.value">
			{{settings.submit}}
		</button>
		<button mat-raised-button color="warn" *ngIf="settings.close" id="am_modal_close" (click)="close()">{{settings.close}}</button>
	</footer>
</div>
