<!-- Updates -->
<mat-card class="mb-3">
	<mat-card-title class="text-secondary">Updates</mat-card-title>
	<mat-card-content id="am_form_updates" [style.fontSize.px]="11" class="mb-0" *ngFor="let updateModel of model.updates; let i = index">
		<div class="row no-gutters">
			<div class="col-11" [dir]="localizationService.isRTL() ? 'rtl' : 'ltr'">
				<lio-mat-wrap-date-field 
					amID="am_form_updates_date_{{i}}"
					[model]="model.updates[i]"
					[field]="dateField"
					(onUpdate)="onUpdate.emit($event)" 
					placeholder="Add date of update">
				</lio-mat-wrap-date-field>
				<div class="row" *ngFor="let update of model.updates[i].updateGroup; let u = index">
					<mat-form-field class="col-md-10" [appearance]="appearance">
						<input id="am_form_updates_text_{{i}}" 
							matInput
							placeholder="Add update information" 
							[(ngModel)]="model.updates[i].updateGroup[u].text"
							(change)="onUpdate.emit($event)" 
							type="text" 
							maxlength="{{field.max || 100}}"/>
					</mat-form-field>
					<div class="col-md-1">
						<button mat-button color="warn" (click)="removeUpdateText(updateModel, u)" class="fa fa-minus-square fa-2x"></button>
					</div>
				</div>
				<mat-card-actions>
				<!-- New Update -->
				<button mat-button color="primary" (click)="addText(updateModel)">
					<span class="fa fa-plus fa-lg mr-1"></span>
					<span>Add Text</span>
				</button>
			</mat-card-actions>
			</div>
			<div class="col-1">
				<button mat-button color="warn" (click)="removeUpdate(i)" class="fa fa-minus-square fa-2x"></button>
			</div>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- New Update -->
		<button mat-button color="primary" (click)="addUpdate()">
			<span class="fa fa-plus fa-lg mr-1"></span>
			<span>Add Update</span>
		</button>
	</mat-card-actions>
</mat-card>