import { Injectable } from '@angular/core';
import { LioField, LioFieldArray } from '../modules/lio-forms/lio-forms.models';

export class LioScormField extends LioField{
	//api field for scorm fields
	public apiField 		:string;
	//initial value for a scorm field
	public initValue		:any;
	//function to get initial value
	public initValueFunction:string;
	//function to set value
	public setValueFunction	:string;
	//function to determine whether to save
	public shouldSaveFn		:string;
	//whether the value can be set
	public canSet			:any;
	//default value for the field
	public defaultValue		:any;
	//function to get the value
	public getValueFunction	:string;

	constructor(obj:Partial<LioScormField>){
		super(obj);
		Object.assign(this, obj);
	}
}

@Injectable({
	providedIn	: 'root', 
	useClass	: scorm12Settings
})
export class scorm12Settings {
	fields :LioFieldArray;

	constructor() {
		this.fields = new LioFieldArray(
			new LioScormField({
				'name': 'Student Name',
				'model': 'studentName',
				'type': 'string',
				'apiField': 'cmi.core.student_name',
				'initValue': true,
			}),
			new LioScormField({
				'name': 'Student ID',
				'type': 'number',
				'model': 'employeeID',
				'apiField': 'cmi.core.student_id',
				'initValue': true,
			}),
			new LioScormField({
				'name': 'Status',
				'model': 'aicc_lesson_status',
				'type': 'string',
				'initValueFunction': 'getStatus',
				'setValueFunction': 'setStatus',
				'shouldSaveFn': 'shouldSaveCompletion',
				'apiField': 'cmi.core.lesson_status',
				'canSet': true,
				'initValue': true,
			}),
			new LioScormField({
				'name': 'Session Time',
				'model': 'aicc_time',
				'type': 'string',
				'apiField': 'cmi.core.session_time',
				'initValueFunction': 'getSessionTime',
				'shouldSaveFn': 'shouldSaveSessionTime',
				'initValue': true,
				'canSet': true,
				'max': 10000,
				'min': 1,
			}),
			new LioScormField({
				'name': 'Suspend Data',
				'model': 'core_lesson',
				'type': 'string',
				'apiField': 'cmi.suspend_data',
				'initValueFunction': 'getSuspendData',
				'setValueFunction': 'setSuspendData',
				'initValue': true,
				'defaultValue': '',
				'max': 30000,
				'min': 0,
				'canSet': true,
			}),
			new LioScormField({
				'name': 'Lesson Mode',
				'model': 'core_lesson',
				'apiField': 'cmi.core.lesson_mode',
				'canSet': false,
				'defaultValue': 'normal',
			}),
			new LioScormField({
				'name': 'Lesson Location',
				'model': 'aicc_lesson_location',
				'type': 'string',
				'apiField': 'cmi.core.lesson_location',
				'initValueFunction': 'initLessonLocation',
				'setValueFunction': 'setLessonLocation',
				'initValue': true,
				'max': 100,
				'min': 0,
				'canSet': true,
			}),
			new LioScormField({
				'name': 'Score',
				'model': 'aicc_score',
				'type': 'number',
				'apiField': 'cmi.core.score.raw',
				'initValueFunction': 'getScore',
				'initValue': true,
				'max': 100,
				'min': 0,
				'canSet': true,
			}),
			new LioScormField({
				'name': 'Score Max',
				'model': 'aicc_score_max',
				'type': 'number',
				'apiField': 'cmi.core.score.max',
				'canSet': false,
			}),
			new LioScormField({
				'name': 'Score Min',
				'model': 'aicc_score_min',
				'type': 'number',
				'apiField': 'cmi.core.score.min',
				'canSet': false,
			}),
			new LioScormField({
				'name': 'Mastery Score',
				'model': 'masteryScore',
				'type': 'number',
				'apiField': 'cmi.student_data.mastery_score',
				'initValueFunction': 'getMasteryScore',
				'canSet': false,
				'defaultValue': '50',
			}),
			new LioScormField({
				'name': 'Interactions',
				'type': 'string',
				'model': 'interactions',
				'apiField': 'cmi.interactions._count',
				'canSet': false,
			}),
			new LioScormField({
				'name': 'Exit',
				'type': 'string',
				'model': 'exit',
				'apiField': 'cmi.core.exit',
				'max': 100,
				'min': 0,
				'canSet': false,
			}),
			new LioScormField({
				'name': 'Credit',
				'model': 'credit',
				'apiField': 'cmi.core.credit',
				'canSet': false,
				'defaultValue': 'credit',
			}),
			new LioScormField({
				'name': 'Entry',
				'model': 'courseStarted',
				'apiField': 'cmi.core.entry',
				'canSet': false,
				'initValueFunction': 'getEntry',
				'initValue': true,
			}),
			new LioScormField({
				'name': 'Preference Text',
				'model': 'preferenceText',
				'apiField': 'cmi.student_preference.text',
				'canSet': false,
				'defaultValue': '0',
			}),
			new LioScormField({
				'name': 'Preference Audio',
				'model': 'preferenceAudio',
				'apiField': 'cmi.student_preference.audio',
				'canSet': false,
				'defaultValue': '0',
			}),
			new LioScormField({
				'name': 'Launch Data',
				'model': 'launchData',
				'apiField': 'cmi.launch_data',
				'canSet': false,
				'defaultValue': '',
				'initValueFunction': 'getSuspendData',
				'setValueFunction': 'setSuspendData',
				'initValue': true,
			}),
			new LioScormField({
				'name': 'Total Time',
				'model': 'totalTime',
				'type': 'number',
				'apiField': 'cmi.core.total_time',
				'defaultValue': '0',
			}),
		);
	}
}