/*
 * Service for Controlling save/loaders
*/
import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lmsService } from './lms.service';
import { lioModalService } from './lio-modal.service';
import { debugService } from './debug.service';


@Injectable({
	providedIn: 'root',
})
export class catalogService {
	catalogs 		:ReplaySubject<any>	= new ReplaySubject(1);
	catalog 		:ReplaySubject<any>	= new ReplaySubject(1);
	courseTypes 	:ReplaySubject<any> = new ReplaySubject(1);
	topics 			:ReplaySubject<any> = new ReplaySubject(1);
	languages		:ReplaySubject<any>	= new ReplaySubject(1);
	
	public _catalogs 					= [];
	public _topics 						= [];
	public _languages 					= [];
	public _courseTypes 				= [];
	public _catalog:any 				= null;

	constructor(
		@Inject(lmsService) 			private lmsService			:lmsService,
		@Inject(lioModalService)		private lioModalService			:lioModalService,
		@Inject(debugService)			private debugService		:debugService
	){
		this.debugService.register('catalogs', this);
	}


	/*
	 * Init
	*/
	init() {
		this.getTopics();
		this.getCourseTypes();
		this.getLanguages();
		return this.getAllCatalogs();
	}


	/*
	 * Reset
	*/
	resetCatalog() {
		this._catalog = null;
		this.catalog.next(null);
	}


	/*
	 * Gets the current catalog
	*/
	getCatalog() {
		return this._catalog;
	}



	/*
	 * Sets catalog by courseID
	*/
	setCatalogByCourseID(courseID) {
		this._catalogs.forEach((catalog) => {
			if (catalog.courseID == courseID) {
				this.setCatalog(catalog);
				return;
			}
		})
		return null;
	}


	/*
	 * Sets the catalog
	*/
	setCatalog(catalog) {
		this._catalog = catalog;
		this.catalog.next(catalog);
	}


	/*
	 * Updates the catalog list
	*/
	updateCatalogs(catalog) {
		this._catalogs.forEach((_catalog, index) => {
			if (_catalog.courseID === catalog.courseID) {
				this._catalogs[index] = catalog;	
				this.catalogs.next(this._catalogs);
				return;
			}
		})
	}


	/*
	 * Saves the catalog
	*/
	saveCatalog(catalog) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('catalog/addCatalog', { 'catalog': catalog}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this._catalog = catalog;
				this._catalogs.push(catalog);
				this.catalogs.next(this._catalogs);
				this.lioModalService.show('savedSuccessfully');
			}
			return result.success;
		});
	}

	/*
	 * Edit the catalog
	*/
	editCatalog(catalog) {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('catalog/editCatalog', { 'catalog': catalog}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this._catalog = catalog;
				this.updateCatalogs(catalog);
				this.lioModalService.show('savedSuccessfully');
			}
			return result.success;
		});
	}


	/*
	 * Gets all catalogs
	*/
	getAllCatalogs(headers:Array<any> = []) {
		return new Promise((resolve) => {
			if (this._catalogs.length) {
				this.catalogs.next(this._catalogs);
				resolve(true);
				return;
			}
			this.lmsService.post('catalog/getAllCatalogs', { 'headers': headers}).then((result) => {
				let catalogs = result.properties.catalogs;
				this.catalogs.next(catalogs);
				this._catalogs = catalogs;
				resolve(true);
			});
		});
	}


	/*
	 * Gets Topics
	*/
	getTopics() {
		this.lmsService.post('catalog/getAllTopics', {}).then((result) => {
			let topics = result.properties.topics;
			this.topics.next(topics);
			this._topics = topics;
		});
	}


	/*
	 * Gets the course types
	*/
	getCourseTypes() {
		return this.lmsService.post('catalog/getAllCourseTypes', {}).then((result) => {
			let courseTypes = result.properties.courseTypes;
			this.courseTypes.next(courseTypes);
			this._courseTypes = courseTypes;
			return courseTypes;
		});
	}


	/*
	 * Deletes a catalog
	*/
	deleteCatalog(catalog) {
		this.lioModalService.showLoading('processing');
		this.lmsService.post('catalog/deleteCatalog', {'catalog': catalog}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this.lioModalService.show('deletedSuccessfully');
				let index = this._catalogs.indexOf(catalog);
				this._catalogs.splice(index, 1);
				this.catalogs.next(this._catalogs);
			}
		});
	}


	/*
	 * Deactivates a catalog
	*/
	deactivateCatalog(catalog) {
		this.lmsService.post('catalog/deactivate', {'courseID': catalog.courseID}).then((result) => {
			if (result.success) {
				catalog.active = 0;
				this.updateCatalogs(catalog);
			}
		});
	}


	/*
	 * Activates a catalog
	*/
	activateCatalog(catalog) {
		this.lmsService.post('catalog/activate', {'courseID': catalog.courseID}).then((result) => {
			if (result.success) {
				catalog.active = 1;
				this.updateCatalogs(catalog);
			}
		});
	}

	/*
	 * Gets available languages
	*/
	getLanguages() {
		this.lmsService.get('catalog/getAllLangs').then((result) => {
			let languages = result.properties.languages;
			this.languages.next(languages);
			this._languages = languages;
		});
	}


	/*
	 * Adds Topic
	*/
	addTopic(topic) {
		topic.keywords = [];
		this.lioModalService.showLoading('oneSec');
		return this.lmsService.post('catalog/addTopic', {'topic': topic}).then((result) => {
			this.lioModalService.hideLoading();
			return result;
		});
	}


}