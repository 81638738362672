import { Component, Input, Output, EventEmitter, DoCheck } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { LioSearchableSelectField } from 'src/app/modules/lio-forms/lio-forms.models';

@Component({
	selector: 'lio-disclosure-response',
	templateUrl: './response.component.html',
})
export class LioDisclosureResponse implements DoCheck{
	@Output() optionAdded					:EventEmitter<any> 	= new EventEmitter();
	@Output() notificationResponseAdded		:EventEmitter<any> 	= new EventEmitter();
	@Output() notificationResponseRemoved	:EventEmitter<any> 	= new EventEmitter();
	@Input() settings						:any;
	@Input() appearance						:MatFormFieldAppearance = 'outline';

	private _response:any = {type : '', input : {}};
	public get response():any{
		return this._response;
	}
	@Input() public set response(val:any){
		if(!val.options && val.type == 'singleselect') {
			let option1 = {text : '', value :  null};
			let option2 = {text : '', value :  null};
			val.options = [option1, option2];

			this.optionAdded.emit(option1);
			this.optionAdded.emit(option2);
		}

		if(!val.input){
			val.input = {};
		}

		this._response = val;
	}

	public inputField:LioSearchableSelectField = new LioSearchableSelectField({
		model			: 'value',
		options			: [],
		optionNameField : 'text'
	});

	private _oldResponseOptions	:Array<any> = [];
	private _oldType			:any 		= null;
	ngDoCheck(){
		if(this.response && this.response.type != this._oldType){
			this._oldType = this.response.type;

			//if response type changes, delete the option set or create a new option set, depending on the new type
			if(this.response.type == 'singleselect') {
				if(!this.response.options) {
					let option1 			= {text : '', value :  null};
					let option2 			= {text : '', value :  null};
					this.response.options 	= [option1, option2];

					this.optionAdded.emit(option1);
					this.optionAdded.emit(option2);
				}
			} else {
				delete this.response.options;
			}

			if(this.response.options != this._oldResponseOptions){
				this._oldResponseOptions 	= this.response.options;
				this.inputField.options 	= this.response.options;
			}
		}
	}

	/**
	 * Adds an option to this response section
	 */
	addOption() {
		let option = {text : '', value :  null};
		this.response.options.push(option);

		this.optionAdded.emit(option);
	}

	/**
	 * Deletes an option from this response section
	 */
	deleteOption(option) {
		option.notify = false;
		this.toggledNotification(option);
		
		for(let i = 0; i < this.response.options.length; i++) {
			if(this.response.options[i] == option) {
				this.response.options.splice(i, 1);
			}
		}
	}

	toggledNotification(option){
		if(option.notify){
			this.notificationResponseAdded.emit(option.value);
		}else{
			this.notificationResponseRemoved.emit(option.value);
		}
	}
}