
/*
 * Service for Controlling Tracker of Document type courses for learn IO
*/
import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { apiInterface } from './api.interface';

import { configSettings } from '../settings/config.settings';
import { lioLogService } from './lio-log.service';

@Injectable({
	providedIn: 'root',
})
export class documentService implements apiInterface {
	public APIversion 			:string		= 'Document';
	public protocol	 			:string		= '&lmsoff=1&standalone=1&protocol=document';
	public launchMethod	 		:string		= 'document';
	public courseSession 		:any		= null;
	public active 				:boolean	= false;
	public error				:string		= '';
	public debug				:boolean	= true;
	public allowsThirdPartyHost	:boolean	= true;
	public commitsData 			:boolean	= true;

	public apiCommit			:Subject<void> = new Subject();
	public apiError				:Subject<any> = new Subject();
	public apiInitialized		:Subject<any> = new Subject();
	public apiFinished			:Subject<any> = new Subject();
	public trackerError			:Subject<any> = new Subject();

	private completionSet		:boolean	= false;
	private courseOpened		:boolean	= false;
	private itemsToCommit		:any		= {};

	constructor(
		@Inject(DOCUMENT)			private document		:Document,
		@Inject(configSettings)		private configSettings	:configSettings,
		@Inject(lioLogService)		private lioLogService	:lioLogService,
	){}

	/*
	 * Activate the tracker
	 * @param {object} course
	 * @return {Promise}
	*/
	activate(courseSession) {
		this.log('Activating: ' + this.APIversion, courseSession);
		this.completionSet = false;
		this.courseSession = courseSession;
		this.initialize();
		this.addWindowObserver();
		this.active = true;
		return this.active;
	}
	
	/*
	 * Handles the course is open
	*/
	handleCourseOpen() {
		this.log('Course Opened');
		this.courseOpened = true;
	}

	/*
	 * Handles the course is closing
	*/
	handleCourseClosing() {
		// If the course was never opened, forget about it
		if (!this.courseOpened) {
			return;
		}

		// Set completion when using on close method
		if (!this.courseSession.completionMethod || this.courseSession.completionMethod === 'onclose') {
			// no need to dispatch the commit, since this was invoked from the tracker and will automatically commit
			this.setCompletion(false);
		}

		this.log('Course Closing');
	}

	/*
	 * The disclosure sent us a completed message
	*/
	private handleFrameCompleted() {
		this.log('Heard Frame Completion');

		// Set completion when using on close method
		if (this.courseSession.completionMethod === 'onpostmessage') {
			this.setCompletion(true);
		}
	}

	/*
	 * Sets the completion items
	 * @param {boolean} dispatchCommit
	*/
	private setCompletion(dispatchCommit) {
		if (this.completionSet) {
			return;
		}

		this.itemsToCommit['aicc_lesson_status'] ='completed';
		this.itemsToCommit['aicc_score'] = '100';
		if (dispatchCommit) {
			this.log('Dispatching commit');
			this.apiCommit.next();
		}
		this.log('Setting Completion');
		this.completionSet = true;
	}

	/*
	 * Initialize the API
	 * @return {boolean}
	*/
	initialize() {
		return true;
	}

	/*
	 * Is the API active?
	 * @return {boolean}
	*/		
	isActive() {
		return this.active;
	}

	/*
	 * Saves Data
	 @return {object}
	*/
	getData() {
		return this.itemsToCommit;
	}

	/*
	 * Clears Data
	*/
	clearData() {
		this.itemsToCommit = {};
	}

	/*
	 * Deactivate the tracker
	 * @return {boolean}
	*/
	deactivate() {
		this.apiCommit.complete();
		this.apiCommit = new Subject();
		this.apiError.complete();
		this.apiError = new Subject();
		
		return true;
	}

	/*
	 * Console log
	*/
	log(param1, param2 = null, param3 = null) {
		if (this.debug) {
			if (typeof param3 !== 'undefined') {
				this.lioLogService.log([param1, param2, param3]);
				return;
			}
			if (typeof param2 !== 'undefined') {
				this.lioLogService.log([param1, param2]);
				return;
			}
			this.lioLogService.log(param1);
		}
	}

	/*
	 * Dispatch Error
	 * @param {string} error
	*/ 
	private dispatchError(error) {
		error = this.protocol.toUpperCase() + ': ' + error;
		this.log('Dispatching Error', error);
		this.apiError.next(error);
	}

	/*
	 * Adds the onmessage listener
	*/
	private addWindowObserver() {
		this.document.defaultView.onmessage = (e) => {
			let msg 			= e.data.msg ? e.data.msg : e.data,
				origin			= e.origin + '/',
				url				= this.courseSession.courseURL,
				allowedOrigins = [
					'https://www.trueofficelearning.com/',
					'https://www.trueoffice.com/',
					'https://www.corpedia.com/',
					'https://www-stage.corpedia.com/',
					'http://127.0.0.1/', 
					'http://localhost/', 
					this.configSettings.serverGlobal,
				];
			
			if (allowedOrigins.indexOf(origin) > -1) {
				this.lioLogService.log('Origin Allowed');
			} else {
				this.dispatchError('Invalid Origin From Post Message: ' + origin);
				return;
			}

			if (!msg) {
				this.lioLogService.log(['Invalid Message Format', e.data]);
				return;
			}


			if (msg === 'IFRAME_COMPLETED') {
				this.handleFrameCompleted();
				return;
			}

			if (msg == 'REQUEST URL') {
				this.log('Heard Request, Sending URL');
				try {
					this.log('Sending URL to Source');
					e.source.postMessage({'msg': 'URL', 'url': url}, '*');
				} catch (e) {
					this.lioLogService.log(['Error', e]);
					this.dispatchError('Failed to send signal to source: ' + url);
				}
			}


			if (msg == '404') {
				this.log('Heard 404 Error');
				this.dispatchError('Failed to launch document 404 Error: ' + url);
			}

			if (msg == 'ERROR') {
				this.log('Heard Critical Error');
				let detail = '';
				if (e.data.detail) {
					detail = '\nError Detail:' + e.data.detail;
				}
				this.dispatchError('Error heard from document: ' + url + detail);

			}

			if (msg == 'INITIALIZE CALLED') {
				this.dispatchError('Heard LMSInitialize Called From The Wrong Tracker: ' + url);
			}

			if (msg == 'Confirmed') {
				this.log('Confirmed Document Launched');
				this.handleCourseOpen();
			}
		};

	}
}