<div class="row">
	<lio-panel-tabber class="col-sm-12 col-md-10 mx-auto">
		<lio-panel-tab [settings]="panels.history">
			<lio-event-history *lioPanelTabContent
				[settings]="settings" 
				[includePacEvents]="true" 
				[eventService]="enrollmentEventService">
			</lio-event-history>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>