<div *ngIf="(settings.mode == 'read' || settings.mode == 'review') && !button.hide">
	<button mat-flat-button
		color="primary"
		(click)="response.emit();" 
		[disabled]="sendingResponse">
		{{button.text}}
	</button>
</div>
<div *ngIf="settings.mode == 'edit'" class="row">
	<div class="col-0 col-lg-2"></div>
	<div class="col-12 col-lg-8">
		<div *ngIf="!button.hide" class="d-flex">
			<mat-form-field [appearance]="appearance" class="ml-auto">
				<mat-label>Next Section Button Text</mat-label>
				<input matInput type="text" [(ngModel)]="button.text"/>
			</mat-form-field>
			<button mat-flat-button color="warn" class="mr-auto my-1 ml-2" (click)="hide();">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		<div *ngIf="button.hide" class="btn-group">
			<button mat-flat-button color="primary" (click)="show();">
				<span>Add Next Section Button</span>
				<mat-icon class="ml-1">add</mat-icon>
			</button>
		</div>
	</div>
	<div class="col-lg-3"></div>
</div>