import { Injectable } from '@angular/core';

import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: disclosureManagerSettings
})
export class disclosureManagerSettings {

	notificationFieldField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'Field',
		model			: 'field',
		addEmptyOption	: true,
		optionAsValue 	: true
	});

	notificationOperatorField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'Operator',
		model			: 'operator',
		addEmptyOption	: true,
	});

	notificationValueField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'Value',
		model			: 'condition',
		addEmptyOption	: true,
		optionValueField: 'model'
	});

	defaultSettings:any = {
		settings : {
			mode 				: 'edit',
			openLinksInIframe 	: true
		}
	};

	filterByCourseField:LioSearchableSelectField = new LioSearchableSelectField({
		name		: 'Filter By Course ID',
		nameTrans	: 'disclosure.filterByCourseID',
		model		: 'courseID',
		options		: [],
		searchable	: true,
		clearable 	: true
	});

	disclosureField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'Existing Disclosures',
		nameTrans		: 'disclosure.existingDisclosures',
		model			: 'data',
		optionNameField : 'title',
		optionAsValue	: true,
		searchable 		: true,
		addEmptyOption 	: true
	});

	panelConfig:any = {
		manage : {
			id				: 'panelTab_disclosures_manage',
			title			: 'Manage Disclosures',
			tab				: 'Manage',
			bodyClass 		: 'none'
		},
		settings : {
			id				: 'panelTab_disclosures_settings',
			title			: 'Disclosure Settings',
			tab				: 'Settings',
		}
	};

	fieldConfig:any = {
		'fieldName'			: 'disclosure',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: true,
		'ccfFields': {
		},
		'roleField': {
			'viewAuthority': 'CREATE',
			'lockedAuthority': 'VIEW'
		}
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'required': true,
			'min': 2,
			'max': 200,
			'locked': 'employee.updateEmployeeID',
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'checkForHTMl': true
		},
		{
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 2,
			'max': 70,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'required': true,
			'varchar': false,
			'min': 2,
			'max': 70,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'e-mail',
			'model': 'email',
			'type': 'email',
			'required': true,
			'checkForHTMlInEmail': true,
			'checkForSpecialChars': true,
			'blockEditHigherRole': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g
		},
		{
			'name': 'Address',
			'model': 'address1',
			'type': 'text',
			'required': false,
			'max': 70,
			'checkForSpecialChars': true,
			'checkForHTMl': true
		},
		{
			'name': 'Address 2',
			'model': 'address2',
			'type': 'text',
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'City',
			'model': 'city',
			'type': 'text',
			'varchar': true,
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'State',
			'model': 'state',
			'type': 'text',
			'varchar': true,
			'required': false,
			'max': 60,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'Country',
			'model': 'country',
			'type': 'text',
			'min': 2,
			'max': 60,
			'varchar': false,
			'required': false,
			'checkForSpecialChars': false,
			'checkForHTMl': true,
		},
		{
			'name': 'Zip Code',
			'model': 'zipCode',
			'type': 'text',
			'required': false,
			'checkForSpecialChars': true,
			'checkForHTMl': true,
		},
		{
			'name': 'Phone #',
			'model': 'phone',
			'type': 'text',
			'max' : 50,
			'required': false,
			'checkForSpecialChars': true,
			'regex': /\`|\~|\!|\#|\$|\%|\^|\&|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			'checkForHTMl': true,
		},
		{
			'name': 'Date Added',
			'model': 'addDate',
			'type': 'date',
			'required': false,
			'locked': 'employee.editAddDate',
			'visible': 'employee.viewAddDate'
		},
		{
			'name': 'Date Modified',
			'model': 'updated',
			'type': 'date',
			'required': false,
			'locked': 'employee.editAddDate',
			'visible': 'employee.viewAddDate'
		},
		{
			'name': 'Admin ID',
			'model': 'AdminID',
			'type': 'number',
			'required': false,
			'visible': 'employee.viewAdminID',
			'locked': true
		},
		{
			'name': 'Added By',
			'model': 'addedBy',
			'type': 'text',
			'visible':  'employee.viewAddedBy',
			'required': false,
			'locked': true,
		},
	);

	operators:Array<any> = [
		{
			'name' : 'equal',
			'value': '='
		},
		{
			'name' : 'not equal',
			'value': '!='
		},
		{
			'name' : 'greater',
			'value': '>'
		},
		{
			'name' : 'less',
			'value': '<'
		},
		{
			'name' : 'in',
			'value': 'in'
		},
		{
			'name' : 'not in',
			'value': 'not in'
		}
	];
}