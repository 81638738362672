import { Inject, Injectable } from '@angular/core';

import { lmsService } from './lms.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class batchService {

	public config	:any = {};
	public employeeSubject :Subject<any> = new Subject();

	constructor(
		@Inject(lmsService)		private lmsService		:lmsService
	) {}


	/**
	 * Gets the history
	 */
	getHistory(type, globally) {
		let url = 'batchProcess/getHistory'
		if (type === 'upload') {
			url = 'batchUpload/getHistory';
		}
		return this.lmsService.post(url, {'globally': globally}).then((result) => {
			if (result.success) {
				return result.properties.history;
			} else {
				return [];
			}
		});
	}


	/* 
	 * Gets the config
	*/
	getConfig() {
		return this.lmsService.get('batchConfig/getConfig').then((result) => {
			if (result.success && result.properties.config) {
				this.config = result.properties.config;
				return result.properties.config;
			} else {
				return {};
			}
		});
	}


	/* 
	 * Save the format fields
	*/
	saveFormatFields(fields) {
		return new Promise((resolve) => {
			this.lmsService.post('batchFormat/saveFormatFields',{'fields': fields}).then((result) => {					
				if (result.success) {
					this.getFormatFields().then((fields) => {
						resolve(fields);
					});
				} else {
					resolve(null);
				}
			});
		});
	}

	/* 
	 * Gets the format fields
	*/
	getFormatFields() {
		return this.lmsService.get('batchFormat/getFormatFields').then((result) => {
			if (result.success && result.properties.fields) {
				return result.properties.fields;
			} else {
				return [];
			}
		});
	}


	/* 
	 * Gets the format fields from an uploaded file
	*/
	getFormatFieldsFromFile(filePath) {
		return this.lmsService.post('batchFormat/getFormatFromFile', {'filePath': filePath}).then((result) => {
			if (result.success && result.properties.fields) {
				return result.properties.fields;
			} else {
				return [];
			}
		});
	}


	getEmployees(model, status) {
		let url = 'batchValidation/getByTempStatus';

		if (model.processed) {
			url = 'batchValidation/getByStatus';
		}

		return this.lmsService.postAsync(url, {uploadID: model.uploadID, companyID: model.COMPANYID, status: status},
		'loadingRecords').then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					if (gresult.success) {
						this.employeeSubject.next(gresult.properties.employees);
					}
				});
			} else {
				if (result.success) {
					this.employeeSubject.next(result.properties.employees);
				}
			}
		});
	}
		

}