import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { localizationService } from '../../../../../services/localization.service';

@Component({
	selector: 'lio-report-settings',
	templateUrl: './settings.component.html',
})
export class LioReportSettings {
	@Input() model:any;
	@Input() field:any;
	@Output() onUpdate	:EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}

}