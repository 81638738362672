import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { localizationService } from '../../services/localization.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { storageService } from '../../services/storage.service';
import { stateService } from '../../services/state.service';
import { permissionService } from '../../services/permissions.service';
import { feedbackService } from '../../services/feedback.service';
import { utilService } from '../../services/util.service';
import { navService } from '../../services/nav.service';
import { processingService } from '../../services/processing.service';
import { debugService } from '../../services/debug.service';

import { localizationSettings } from './localization.settings';

@Component({
	selector: 'lio-localization',
	templateUrl: './localization.component.html'
})
export class LocalizationComponent implements OnDestroy {
	public model				:any 			= {
		item:null
	};
	public items				:any 			= this.utilService.copy(this.localizationSettings.items);
	public fields				:Array<any> 	= [];
	public showForm				:boolean 		= false;
	public allowSave			:boolean 		= true;
	public localization			:any 			= {};
	public localizeItemField	:any 			= this.localizationSettings.localizeItemField;

	private localizableItems	:Array<any> 	= this.localizationService.getLocalizableItems();
	private langs				:Array<any> 	=  this.utilService.copy(this.localizationSettings.langs);
	private isValid				:boolean 		= false;
	private updated				:boolean 		= false;

	private localizations		:Array<any> 	= [];

	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(lmsService) 			public lmsService			:lmsService,
		@Inject(lioModalService) 		public lioModalService		:lioModalService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(stateService) 			public stateService			:stateService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(feedbackService) 		public feedbackService		:feedbackService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(navService) 			public navService			:navService,
		@Inject(processingService) 		public processingService	:processingService,
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(localizationSettings) 	public localizationSettings	:localizationSettings
	){
		this.debugService.register('localization', this);
		this.navService.setActivePage('localization');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.addItems();	
		this.navService.displayPage();
	}

	/*
	 * Adds items to localize
	*/
	addItems() {
		let items = this.localizableItems;

		this.model.item = this.localizationService.localizing || this.storageService.get('localizingField');

		items.forEach((item) => {
			let field = item.field;
			let selected = false;
			if (field === this.model.item) {
				selected = true;
			}
			let option = {
				value 		: field,
				name 		: field,
				selected 	: selected
			};
			this.items.options.push(option);
		});
		
		if (this.model.item) {
			this.loadByField();
		}
	}

	/*
	 * Loads all localizations
	*/
	loadByField() {
		let field = this.model.item;
		if (!field) {
			this.feedbackService.setError('pleaseSelectAnItemToLocalize');
			this.allowSave = false;
			return;
		}
		this.lmsService.post('localization/getByField', {'field': field}).then((result) => {
			var localizations = result.properties.localizations,
				defaultText  = this.localizationService.getDefaultByField(field),
				defaultField = {};

			if (!localizations) {
				localizations = [];
			}

			this.storageService.set('localizingField', field);
			defaultField = {'text': defaultText, 'field': 'Default', 'langID': 'default'};
			localizations.push(defaultField);

			this.localizations = localizations;

			this.fields = this.permissionService.setFields(this.utilService.copy(this.localizationSettings.fields));
			this.addLanguages();
			this.setFields();
			this.showForm = true;
			this.allowSave = true;
			this.setCurrentLangField();
			this.navService.displayPage();
		});
	}

	/*
	 * Sets the curent language field indicator
	*/
	setCurrentLangField() {
		var selectedLang = this.localizationService.getSelectedLang().code;
		this.fields.forEach((field) => {
			if (field.model === selectedLang) {
				field.rowClass = 'highlight';
			}
		});
	}

	/*
	 * Updates localizations with current variables
	*/
	updateLocalizations() {
		this.feedbackService.clearErrors();
		this.isValid = true;
		this.allowSave = true;

		this.localizations.forEach((localization) => {
			var langID = localization.langID,
				text = this.localization[langID];
			if (!text) {
				text = '';
			}

			localization.text = text;
		});
	}

	/*
	 * Saves localizations
	*/
	save() {
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		
		if (!this.updated) {
			this.feedbackService.setError('pleaseMakeALocalizationChange');
			return;
		}

		if (!this.isValid) {
			return;
		}

		this.lioModalService.showLoading('saving');
		this.lmsService.post('localization/save', {'localizations': this.localizations}).then((result) => {
			this.lioModalService.hideLoading();
			this.updated = false;
			if (result.success) {
				// reload
				this.onItemChange();
				if (result.success) {
					setTimeout(() => {
						this.navService.reload();
					}, 2000);
					this.lioModalService.show('savedRefresh');
				}
			}
		});
	}

	/*
	 * Get the language by a langID
	*/
	getLanguageByLangID(langID) {
		var language = null;
		this.langs.forEach((lang) => {
			if (lang.code === langID) {
				language = lang; 
			}
		});
		return language;
	}

	/*
	 * Adds all languages to the form
	*/
	addLanguages() {
		let field = {
			name	: 'Default', 
			model	: 'default', 
			type	: 'text', 
			locked	: true, 
			visible	: true
		};

		this.fields.push(field);

		this.langs.forEach((lang) => {
			let field = {
				name	: lang.name,
				model	: lang.code,
				type	: 'text',
				visible	: true,
			};
			this.fields.push(field);
			this.localization[lang.code] = '';
		});
	}

	/*
	 * On Update
	*/
	onupdate() {
		this.updateLocalizations();
		this.updated = true;
	}

	/*
	 * On Localization Item Change 
	*/
	onItemChange() {
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.loadByField();
	}

	/*
	 * Sets the form fields
	*/
	setFields() {
		let setLangs = [];
	
		this.localizations.forEach((localization) => {	
			let langId 					= localization.langID;
			this.localization[langId] 	= localization.text;
			setLangs.push(langId);
		});

		let localizationKeys = Object.keys(this.localization);
		localizationKeys.forEach((langID) => {
			if (!this.utilService.inArray(setLangs, langID)) {
				let localization = {
					companyID	: this.stateService.getActiveCompanyID(),
					field		: this.model.item,
					text		: this.localization[langID],
					langID		: langID,
				};
				this.localizations.push(localization);
			}
		});

		this.fields.forEach((field) => {
			if (field.visible !== false) {
				field.visible = true;
			}	
		});
	}
}