import { Injectable } from '@angular/core';

import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: catalogCollectionSettings
})
export class catalogCollectionSettings {
	modelName:string = 'Catalog';

	modelNameTrans:string = 'catalogCollection.catalog';

	copyable:boolean =  true;

	endpoints:any = {
		create				: 'catalogcollection/save',
		copy				: 'catalogcollection/copy',
		getAll				: 'catalogcollection/getAll',
		save				: 'catalogcollection/save',
		delete				: 'catalogcollection/delete',
	};

	catalogConfig:any = {
		id 				: 'panelTab_catalog_form',
		title			: 'Select Catalogs',
		titleTrans		: 'catalogCollection.selectCatalogs',
		subtitle		: 'Keywords can be used in templates to customize them for each individual employee',
		subtitleTrans	: 'catalogCollection.createSubtitle',
		tab				: 'Select Catalogs',
		tabTrans		: 'catalogCollection.selectCatalogs'
	};

	employeeConfig:any = {
		id 				: 'panelTab_employee_form',
		title			: 'Select Employees',
		titleTrans		: 'catalogCollection.selectEmployees',
		subtitle		: 'Keywords can be used in templates to customize them for each individual employee',
		subtitleTrans	: 'catalogCollection.createSubtitle',
		tab				: 'Select Employees',
		tabTrans		: 'catalogCollection.selectEmployees'
	};

	pagination:any = {
		pageLimit	: '10',
		pageLimits	: ['10', '50', '100'],
		sortMode	: {field : 'lastName', direction : 'asc'}
	};
	
	fields:LioFieldArray = new LioFieldArray(
		{
			name					: 'Course Name',
			model					: 'name',
			type					: 'text',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Catalog IDs',
			model					: 'courseIDs',
			type					: 'chips',
			max						: 5,
			required				: true,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Users',
			model					: 'employeeIDs',
			type					: 'chips',
			max						: 5,
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'All Courses',
			model					: 'allCourses',
			type					: 'boolean',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'All Companies',
			model					: 'allCompanies',
			type					: 'boolean',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Default Collection',
			model					: 'defaultCollection',
			type					: 'boolean',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
	);

	fieldConfig:any = {
		addRoleToFields	: true,
		roleField		: {
			required: false
		}
	};

	catalogFields:LioFieldArray = new LioFieldArray(
		{
			name					: 'Course ID',
			model					: 'courseID',
			type					: 'number',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 999999999,
			disableOptionOverride	: true,
			sortable				: true
		},
		{
			name					: 'Course Name',
			model					: 'name',
			type					: 'text',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
			sortable				: true
		},
	);


	employeeFieldConfig:any = {
		fieldName		: 'catalogemployeefields',
		addCCFtoFields	: true,
		localizeFields	: true,
		permissionFields: true,
		addRoleToFields	: true,
		overrideFields	: false,
		addLangField	: true,
	};

	employeeFields:LioFieldArray = new LioFieldArray(
		{
			name	: 'Employee ID',
			model	: 'employeeID',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name	: 'First Name',
			model	: 'firstName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name	: 'Last Name',
			model	: 'lastName',
			type	: 'text',
			visible	: true,
			sortable: true
		},
		{
			name	: 'e-mail',
			model	: 'email',
			type	: 'text',
			visible	: true,
			sortable: true
		},
	);

	employeeSettings:any = {
		includeUID: true
	}
}