import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { feedbackService } from '../../../../services/feedback.service';
import { debugService } from '../../../../services/debug.service';

@Component({
	selector: 'lio-disclosure-section',
	templateUrl: './section.component.html',
})
export class LioDisclosureSection{
	@Output() questionAdded					:EventEmitter<any> 	= new EventEmitter();
	@Output() questionDeleted				:EventEmitter<any> 	= new EventEmitter();
	@Output() optionAdded					:EventEmitter<any> 	= new EventEmitter();
	@Output() delete						:EventEmitter<any> 	= new EventEmitter();
	@Output() notificationResponseAdded		:EventEmitter<any> 	= new EventEmitter();
	@Output() notificationResponseRemoved	:EventEmitter<any> 	= new EventEmitter();
	@Output() response						:EventEmitter<any> 	= new EventEmitter();

	@Input() settings						:any 				= null;
	@Input() allQuestions					:Array<any>			= [];
	@Input() sendingResponse				:boolean			= false;
	@Input() appearance						:MatFormFieldAppearance = 'outline';

	private _section:any = null;
	public get section():any{
		return this._section;
	}
	@Input() public set section(val:any){
		if(!val.button) {
			val.button = {text : null, hide : false};
		}
		if(!val.questions) {
			val.questions = [];
		}

		this._section = val;
	}

	constructor(
		@Inject(feedbackService) private feedbackService:feedbackService,
		@Inject(debugService) private debugService:debugService
	) {
		this.debugService.register('disclosuresection', this);
	}

	/**
	 * Adds a question to this section
	 */
	addQuestion() {
		let newQuestion = {questionid : null};
		this.section.questions.push(newQuestion);
		this.questionAdded.emit(newQuestion);
	}

	/**
	 * Adds non-question text to this section
	 */
	addText() {
		this.section.text = '';
	}
	
	/**
	 * Adds non-question text to this section
	 */
	deleteText() {
		this.section.text = null;
	}

	/**
	 * Deletes a question from this section
	 * @param {object} question - the question to be deleted
	 */
	deleteQuestion(question) {
		for(let i = 0; i < this.section.questions.length; i++) {
			if(this.section.questions[i] == question) {
				this.section.questions.splice(i, 1);
				this.questionDeleted.emit(question);
			}
		}
	}

	/**
	 * Checks to see if any required questions have not been answered, then sends the responses for this section
	 */
	sendResponse() {
		let hasBlockingQuestion = false;
		this.section.questions.forEach((question) => {
			if((!question.noresponserequired || question.disabledCondition) && !question.disabled && question.response.type != '') {
				//singleselect questions just need a response of some kind, text responses must be at least 3 characters
				if ((!question.response.input.value && question.response.input.value != 0) || (question.response.type == 'singleselect' && question.response.input.value == '')) {
					hasBlockingQuestion = true;
					question.invalid 	= true;
					this.feedbackService.setError('disclosure.invalidQuestion');
				} else if (question.response.type != 'singleselect' && question.response.input.value.length < 3) {
					hasBlockingQuestion = true;
					question.invalid 	= true;
					this.feedbackService.setError('disclosure.invalidQuestionResponseLength');
				}
			}
		});

		if(!hasBlockingQuestion) {
			this.response.emit();
		}
	}

	/**
	 * Gets a question in this section by its questionID
	 */
	getQuestion(questionid) {
		let foundQuestion = null;

		this.section.questions.forEach((question) => {
			if(question.questionid == questionid) {
				foundQuestion = question;
			}
		});

		return foundQuestion;
	}
}