import { AngularMaterialModule } from '../../imports/angular-material';

import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LioPipesModule } from '../../pipes/pipes.module';

import { LioDirectivesModule } from '../../directives/directives.module';
import { LioDataDisplayModule } from '../data-display/data-display.module';
import { LioFormsModule } from '../lio-forms/lio-forms.module';
import { LioStructuralModule } from '../structural/structural.module';

import { LioDisclosureButton } from './components/button/button.component';
export { LioDisclosureButton } from './components/button/button.component';

import { LioDisclosure } from './components/disclosure/disclosure.component';
export { LioDisclosure } from './components/disclosure/disclosure.component';

import { LioDisclosureQuestion } from './components/question/question.component';
export { LioDisclosureQuestion } from './components/question/question.component';

import { LioDisclosureResponse } from './components/response/response.component';
export { LioDisclosureResponse } from './components/response/response.component';

import { LioDisclosureSection } from './components/section/section.component';
export { LioDisclosureSection } from './components/section/section.component';

import { LioDisclosureText } from './components/text/text.component';
export { LioDisclosureText } from './components/text/text.component';

import { LioDisclosureTitle } from './components/title/title.component';
export { LioDisclosureTitle } from './components/title/title.component';

const monacoConfig: NgxMonacoEditorConfig = {
	baseUrl: './assets', // configure base path cotaining monaco-editor directory after build default: './assets'
	defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
	onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
	exports: [
		LioDisclosureButton,
		LioDisclosure,
		LioDisclosureQuestion,
		LioDisclosureResponse,
		LioDisclosureSection,
		LioDisclosureText,
		LioDisclosureTitle
	],
	declarations: [
		LioDisclosureButton,
		LioDisclosure,
		LioDisclosureQuestion,
		LioDisclosureResponse,
		LioDisclosureSection,
		LioDisclosureText,
		LioDisclosureTitle
	],
	bootstrap: [
		LioDisclosureButton,
		LioDisclosure,
		LioDisclosureQuestion,
		LioDisclosureResponse,
		LioDisclosureSection,
		LioDisclosureText,
		LioDisclosureTitle
	],
	imports: [
		MonacoEditorModule.forRoot(monacoConfig),
		FormsModule,
		AngularMaterialModule,
		FontAwesomeModule,
		AngularEditorModule,
		CommonModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioFormsModule,
		LioStructuralModule,
		LioPipesModule
	]
})
export class LioDisclosuresModule {}