/*
 * Service for Controlling save/loaders
*/
import { ReplaySubject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { lmsService } from './lms.service';
import { localizationService } from './localization.service';
import { debugService } from './debug.service';
import { stateService } from './state.service';
import { storageService } from './storage.service';


@Injectable({
	providedIn: 'root',
})
export class coursesService {
	courses 					:ReplaySubject<any>	= new ReplaySubject(1);
	activeCourses 				:ReplaySubject<any>	= new ReplaySubject(1);
	coursesOutsideCompany 		:ReplaySubject<any> = new ReplaySubject(1);
	rawCourses 					:any 				= [];
	courseOptionsRepSub			:ReplaySubject<any>	= new ReplaySubject(1);
	activeCourseOptionsRepSub	:ReplaySubject<any>	= new ReplaySubject(1);
	courseOptions 				:any 				= [];
	course 						:any 				= null;
	errors 						:any 				= [];
	lastToken 					:any 				= null;
	companyID 					:number 			= null;
	warnPartial					:boolean 			= false;
	chunkedResponse				:boolean 			= false;
	chunkCompleted				:boolean 			= false;
	noCoursesAssignedToCompany  :boolean 			= false;

	private courseTimeout 		:any				= null;

	constructor(
		@Inject(lmsService) 			private lmsService			:lmsService,
		@Inject(localizationService)	private localizationService	:localizationService,
		@Inject(stateService)			private stateService		:stateService,
		@Inject(debugService)			private debugService		:debugService,
		@Inject(storageService)			private storageService		:storageService
	){
		this.debugService.register('courses', this);
		
		this.stateService.waitForLoaded.subscribe(() => {
			this.get();
		});
	}

	/*
	 * Reset on logout
	*/
	reset() {
		this.courses.next([]);
		this.activeCourses.next([]);
		this.errors = [];
		this.warnPartial = false;
		this.chunkedResponse = false;
		this.chunkCompleted = false;
		this.companyID = null;
		this.courseTimeout = null;
	}

	/*
	 * Set the companyID
	*/
	setCompanyID(companyID) {
		this.companyID = companyID;
	}

	/*
	 * Gets the courses
	*/
	get() {
		if(!this.courseTimeout){
			// Cacheing fix for some reason this is getting called even after logging out
			if (!this.stateService.getSession()) {
				this.courses.next([]);
				this.activeCourses.next([]);
				return;
			}

			this.lmsService.post('courses/getCoursesInCompany', {'companyID': this.companyID}).then((result:any) => {
				if (!result || !result.properties.courses) {
					this.noCoursesAssignedToCompany = true;
					this.courses.next([]);
					this.activeCourses.next([]);
					return;
				}

				let courseName		:string,
					nameID			:string,
					output			:Array<any> = [],
					activeOutput	:Array<any> = [],
					options 		:Array<any> = [],
					activeOptions 	:Array<any> = [];


				// Convert course array to an object for the drop down
				this.courses.next([]);
				this.activeCourses.next([]);
				result.properties.courses.forEach((course) => {
					courseName = this.localize(course.courseName, course.courseID);
					nameID = course.courseID + ' - ' + courseName;
					output.push({'courseID': course.courseID, 'nameID': nameID, 'courseName': course.courseName});
					options.push({'name': nameID, 'value': course.courseID});
					if (course.active) {
						activeOutput.push({'courseID': course.courseID, 'nameID': nameID, 'courseName': course.courseName});
						activeOptions.push({'name': nameID, 'value': course.courseID});
					}
				});
				this.rawCourses = result.properties.courses;
				this.courses.next(output);
				this.activeCourses.next(activeOutput);
				this.courseOptionsRepSub.next(options);
				this.activeCourseOptionsRepSub.next(activeOptions);
			});

			this.courseTimeout = setTimeout(() => {
				clearTimeout(this.courseTimeout);
			}, 1000);
		}
	}


	/*
	 * Gets the courses
	*/
	getExternalCourses(search, companyID) {
		let courses = [];

		if (!companyID) {
			this.coursesOutsideCompany.next(courses);
			return;
		}

		this.lmsService.post('courses/getAllCoursesOutsideCompany', {'format': 'options', 'search': search, 'companyID': companyID}).then((result) => {
			if (!result || !result.properties.courses) {
				courses = [{'locked': true, 'disabled': true,'name': 'No Courses Found', 'value': ''}];
				this.coursesOutsideCompany.next(courses);
				return;
			}

			let courseName	:string,
				nameID		:string,
				output		:Array<any> = [];

			// Convert course array to an object for the drop down
			result.properties.courses.forEach((course) => {
				courseName = this.localize(course.courseName, course.courseID);
				nameID = course.courseID + ' - ' + courseName;
				output.push({'name': nameID, 'value': course.courseID});
			});
			this.coursesOutsideCompany.next(output);
		});
	}

	
	/*
	 * Localizes the course ID
	 * @param {string} courseName
	 * @param {number} courseID
	 * @return {string}
	*/
	localize(courseName, courseID) {
		var name = this.localizationService.get('course.' + courseID);
		if (!name) {
			name = courseName;
		}
		return name;
	}

	/*
	 * Gets course by id
	*/
	getById(courseID) {
		return this.lmsService.post('courses/getCourseByID', {'courseID': courseID}).then((result:any) => {
			var course = result.properties.course;

			this.course = course;
			this.storageService.set('course', this.course);
		});
	}
}