import { Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';

import { apiInterface } from './api.interface';

import { configSettings } from '../settings/config.settings';
import { lioLogService } from './lio-log.service';
import { stateService } from './state.service';
import { debugService } from './debug.service';
/*
 * Service for Controlling Tracker of courses for learn IO
*/

@Injectable({
	providedIn: 'root',
})
export class aiccService implements apiInterface{
	public APIversion 				:string		= 'AICC';
	public protocol 				:string		= 'aicc';
	public launchMethod 			:string		= 'aicc';
	public active 					:boolean	= false;
	public error 					:string		= '';
	public courseSession 			:any		= null;
	public debug 					:boolean	= false;
	public allowsThirdPartyHost 	:boolean	= true;
	public commitsData	 			:boolean	= true;
	protected itemsToCommit 		:any		= {};

	public apiCommit			:Subject<any> = new Subject();
	public apiError				:Subject<any> = new Subject();
	public apiInitialized		:Subject<any> = new Subject();
	public apiFinished			:Subject<any> = new Subject();
	public trackerError			:Subject<any> = new Subject();

	constructor(
		@Inject(configSettings)	private configSettings	:configSettings,
		@Inject(lioLogService)	private lioLogService	:lioLogService,
		@Inject(stateService)	private stateService	:stateService,
		@Inject(debugService)	private debugService	:debugService,
	){
		this.debugService.register('aicc', this);
	}

	/*
	 * Activate the tracker
	 * @param {object} course
	 * @return {Promise}
	*/
	activate(courseSession) {
		this.log('Activating: ' + this.APIversion);
		this.log('Course Session', courseSession);
		this.courseSession = courseSession;
		this.initialize();
		this.active = true;
		return this.active;
	}


	/*
	 * Initialize the API
	 * @return {boolean}
	*/
	initialize() {
		this.protocol =
			'aicc' +  
			'&aicc_sid=' + this.stateService.getSID() +
			'&aicc_url=' + this.configSettings.serverGlobal + 'backend/tasks.php?task=training/updateAICCData' +
			'%26courseID=' + this.courseSession.courseID +
			'%26employeeInCourseID=' + this.courseSession.employeeInCourseID +
			'%26SID=' + this.stateService.getSID();

		return true;
	}


	/*
	 * Test the API
	*/
	test() {
		window.open(
			this.configSettings.serverGlobal + 'backend/tasks.php?task=training/updateAICCData' +
			'&courseID=' + this.courseSession.courseID +
			'&employeeInCourseID=' + this.courseSession.employeeInCourseID +
			'&SID=' + this.stateService.getSID() + 
			'&command=getParam' 
		);

		return true;
	}

	/*
	 * Handles the course is open
	*/
	handleCourseOpen() {
		this.log('Course Opened');
	}


	/*
	 * Handles the course is closing
	*/
	handleCourseClosing() {
		// Set completion when using on close method
		if (this.courseSession.completionMethod === 'onclose') {
			// no need to dispatch the commit, since this was invoked from the tracker and will automatically commit
			this.itemsToCommit['aicc_lesson_status'] = 'completed';
			this.itemsToCommit['aicc_score'] = '100';
		}
		this.log('Course Closing');
	}


	/*
	 * Is the API active?
	 * @return {boolean}
	*/		
	isActive() {
		return this.active;
	}



	/*
	 * Saves Data
	 @return {object}
	*/
	getData() {
		return this.itemsToCommit;
	}


	/*
	 * Clears Data
	*/
	clearData() {
		this.itemsToCommit = {};
		return true;
	}


	/*
	 * Deactivate the tracker
	 * @return {boolean}
	*/
	deactivate() {
		this.apiCommit.complete();
		this.apiCommit = new Subject();
		this.apiError.complete();
		this.apiError = new Subject();
		
		return true;
	}



	/*
	 * Console log
	*/
	log(param1, param2 = null, param3 = null) {
		console.log(param1, param2);
		if (this.debug) {
			if (typeof param3 !== 'undefined') {
				this.lioLogService.log([param1, param2, param3]);
				return;
			}
			if (typeof param2 !== 'undefined') {
				this.lioLogService.log([param1, param2]);
				return;
			}
			this.lioLogService.log(param1);
		}
	}
}