import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { loginService } from '../../services/login.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { permissionService } from '../../services/permissions.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { localizationService } from '../../services/localization.service';
import { settingsService } from '../../services/settings.service';

import { changePassSettings } from './change-pass.settings';

@Component({
	selector: 'lio-change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnDestroy {
	public showSuccess			:boolean		= false;
	public allowSubmit			:boolean 		= false;
	public required				:boolean 		= false;
	public fields				:Array<any> 	= this.permissionService.setFields(this.changePassSettings.fields);
	public langIDField			:any 			= this.changePassSettings.langIDField;
	public employee				:any 			= {
		currentPassword	: '',
		password		: '',
		password2		: ''
	};
	public selectedLang			:any 			= { code: this.localizationService.getSelectedLang().code };
	public localeStrings		:any 			= {
		optionalTitle					:'Please enter the form below to change your password',
		optionalTitleTrans				:'changepass.optionalTitle',
		requiredTitle					:'You are required to change your password.',
		requiredTitleTrans				:'changepass.requiredTitle',
		passwordValidationMessage		:'Password must be at least 8 characters, and contain any 3 of the following 4: a lowercase letter, an uppercase letter, a number, or a non-alphabetic character (such as ?,!,$,%,*,^)',
		passwordValidationMessageTrans	:'changepass.passwordValidationMessage',
		submit							:'Submit',
		submitTrans						:'changepass.submit',
		updateLang						:'Update Your Language',
		updateLangTrans					:'changepass.updateLang',
		currentLang						:'',
		currentLangTrans				:'changepass.currentLang',
		currentLangMacros 				: [{
			key		: 'lang', 
			value	: this.localizationService.getSelectedLangName()
		}],
		save							:'Save',
		saveTrans						:'form.save',
		goToHomePage					:'Go to the home page',
		goToHomePageTrans				:'changepass.goToHomePage',
	};
	public localeStringsKeys	:Array<any>		= [
		'optionalTitle',
		'requiredTitle',
		'passwordValidationMessage',
		'submit',
		'updateLang',
		'currentLang',
		'save',
		'goToHomePage'
	];

	private enterCurrentPass	:boolean 		= true;
	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public	navService			:navService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(loginService)			public	loginService		:loginService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(localizationService)	public	localizationService	:localizationService,
		@Inject(settingsService)		public	settingsService		:settingsService,
		@Inject(changePassSettings)		public	changePassSettings	:changePassSettings,
	){
		this.debugService.register('changepass', this);
		this.navService.setActivePage('changepass');

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => { 
				this.init(); 
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}	

	selectLanguage() {
		this.lioModalService.showLoading('processing');
		this.lmsService.post('employee/setLang', {'langID': this.selectedLang.code}).then((result) => {
			if (result.success) {
				this.loginService.getLocalization().then(() => {
					this.lioModalService.hideLoading();
					if(result.success){
						setTimeout(() => {
							this.navService.reload();
						}, 3000);
						this.lioModalService.show('savedRefresh');
					}
				});
			}
		});
	}

	/*
	 * Sets the fields with CCF properties
	*/
	setFields() {
		if (!this.enterCurrentPass) {
			this.fields.forEach((field) => {
				if (field.model === 'currentPassword') {
					field.visible = false;
				}
			});
		}
	}

    /*
     * Gets a form error
     * @return {string}
    */
    getFormError() {
        let password 		= this.employee.password ? this.employee.password.trim() : null;
		let password2 		= this.employee.password2 ? this.employee.password2.trim() : null;
		let currentPassword = this.employee.currentPassword ? this.employee.currentPassword.trim() : null;
		let error 			= this.utilService.getPasswordError(password);

        if (this.enterCurrentPass && !currentPassword) {
			return this.utilService.localizeError('pleaseEnterYourCurrentPass');
		}

        if (!password) {
			return this.utilService.localizeError('pleaseEnterYourPass');
        }

        if (!password2) {
			return this.utilService.localizeError('pleaseReEnterYourPass');
        }

        if (password !== password2) {
			return this.utilService.localizeError('thePasswordDontMatch');
        }

		if (this.enterCurrentPass && currentPassword === password) {
			return this.utilService.localizeError('newPassCantBeSameAsCurrent');
		}

        return error;
    }

	/*
	 * Gets the password
    */
	getPassword() {
		let password 	= this.employee.password ? this.employee.password.trim() : null;
		let error 		= this.getFormError();

		if (error) {
            this.feedbackService.setError(error);
            return; 
        }

		this.feedbackService.clearErrors();

		return password;
	}

	/*
	 * On update of the password
    */
	onUpdate() {
		this.feedbackService.clearErrors();
		this.allowSubmit = true;
	}

    /*
	 * Submits the edit
    */
	submit() {
		let password 	= this.getPassword();
		let password1 	= this.employee.password ? this.employee.password.trim() : null;
		let form 		= {
			password		:null,
			originalPassword:null
		};

		if (this.feedbackService.getErrors().length) {
			return;
		}

		if (password) {
			form.password = password;
		}

		if (this.enterCurrentPass) {
			form.originalPassword = this.employee.currentPassword;
		}

		if (!this.meetsComplexity(password1)) {
			return;
		}

		this.lioModalService.showLoading('processing');

		this.lmsService.post('password/changePassword', form).then((result) => {
			let success = result.success;
			this.lioModalService.hideLoading();
			if (success) {
				this.stateService.getSession().changePassword 	= 0;
				this.navService.locked 							= false;
				this.allowSubmit 								= false;
				this.showSuccess 								= true;
				this.navService.lockLocation 					= null;
			}
		});
	}

    /*
	 * Determines if password meets complexity requirements
	 * @param {string} password
	 * @return {boolean}
    */
	meetsComplexity(password:string) {
		let errors = [];

		if (password.length <= 7) {
			errors.push('error.passMustBeAtLeast8Chars');
		}

		if (!this.utilService.hasNumber(password)) {
			errors.push('error.passNoNumber');
		}

		if (!this.utilService.hasLower(password)) {
			errors.push('error.passNoLower');
		}

		if (!this.utilService.hasUpper(password)) {
			errors.push('error.passNoUpper');
		}

		if (!this.utilService.hasSpecialChar(password)) {
			errors.push('error.passNoSpecial');
		}

		if (errors.length) {
			this.feedbackService.setErrors(errors);
			return false;
		}

		return true;
	}

    /*
	 * Retry
    */
    retry() {
		this.feedbackService.clearErrors();
    }

    /*
	 * Checks if user can change his password
    */
    checkPermission() {
		if (this.required) {
			return;
		}
		if (!this.permissionService.hasPermission('self.changePassword')) {
			this.lioModalService.show('notAuthorizedToEditPassword');
			this.navService.goto('home');
		}
    }

    /*
	 * Log in is complete, now go to wherever
    */
	redirect() {
		this.navService.goto('dashboard');
	}

	init() {
		let session = this.stateService.getSession();
		if (session && session.changePassword) {
			this.required = true;
		}
		this.setFields();

		this.checkPermission();
		this.navService.displayPage();
	}
}