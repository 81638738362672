import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularMaterialModule } from '../../imports/angular-material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { LioPipesModule } from './../../pipes/pipes.module';

import { LioDirectivesModule } from './../../directives/directives.module';

import { LioCatalogModules } from './components/catalog/modules/modules.component';
export { LioCatalogModules } from './components/catalog/modules/modules.component';

import { LioCatalogKeywords } from './components/catalog/keywords/keywords.component';
export { LioCatalogKeywords } from './components/catalog/keywords/keywords.component';


import { LioCatalogNotes } from './components/catalog/notes/notes.component';
export { LioCatalogNotes } from './components/catalog/notes/notes.component';

import { LioCatalogTopics } from './components/catalog/topics/topics.component';
export { LioCatalogTopics } from './components/catalog/topics/topics.component';

import { LioCatalogUpdates } from './components/catalog/updates/updates.component';
export { LioCatalogUpdates } from './components/catalog/updates/updates.component';


import { LioFileFormat } from './components/batch-processing/file-format.component';
export { LioFileFormat } from './components/batch-processing/file-format.component';

import { LioReportFields } from './components/custom-reports/fields/fields.component';
export { LioReportFields } from './components/custom-reports/fields/fields.component';

import { LioReportJoints } from './components/custom-reports/joints/joints.component';
export { LioReportJoints } from './components/custom-reports/joints/joints.component';

import { LioReportSettings } from './components/custom-reports/settings/settings.component';
export { LioReportSettings } from './components/custom-reports/settings/settings.component';

import { LioReportTable } from './components/custom-reports/table/table.component';
export { LioReportTable } from './components/custom-reports/table/table.component';

import { LioReportWheres } from './components/custom-reports/wheres/wheres.component';
export { LioReportWheres } from './components/custom-reports/wheres/wheres.component';

import { LioEventConditions } from './components/events/conditions/conditions.component';
export { LioEventConditions } from './components/events/conditions/conditions.component';

import { LioEventDays } from './components/events/days/days.component';
export { LioEventDays } from './components/events/days/days.component';

import { LioMatWrapDateField } from './components/date-field/date-field.component';
export { LioMatWrapDateField } from './components/date-field/date-field.component';

import { LioContextMenu } from './components/context-menu/context-menu.component';
export { LioContextMenu } from './components/context-menu/context-menu.component';

import { LioDrag } from './components/drag/lio-drag.component';
export { LioDrag } from './components/drag/lio-drag.component';

import { LioTooltip } from './components/tooltip/tooltip.component';
export { LioTooltip } from './components/tooltip/tooltip.component';

import { LioButtons } from './components/buttons/buttons.component';
export { LioButtons } from './components/buttons/buttons.component';

import { LioFileUploader } from './components/file-uploader/file-uploader.component';
export { LioFileUploader } from './components/file-uploader/file-uploader.component';

import { LioFieldInput } from './components/field-input/field-input.component';
export { LioFieldInput } from './components/field-input/field-input.component';

import { FilterByTemplateType } from './pipes/filter-by-template-type.pipe';
export { FilterByTemplateType } from './pipes/filter-by-template-type.pipe';

import { LioQueryBuilder } from './components/query-builder/query-builder/query-builder.component';
export { LioQueryBuilder } from './components/query-builder/query-builder/query-builder.component';

import { LioQueryRule } from './components/query-builder/query-rule/query-rule.component';
export { LioQueryRule } from './components/query-builder/query-rule/query-rule.component';

import { LioQueryTool } from './components/query-builder/query-tool/query-tool.component';
export { LioQueryTool } from './components/query-builder/query-tool/query-tool.component';

import { LioSearchableSelect } from './components/searchable-select/searchable-select.component';
export { LioSearchableSelect } from './components/searchable-select/searchable-select.component';

import { LioFieldRow } from './components/field-row/field-row.component';
export { LioFieldRow } from './components/field-row/field-row.component';

import { LioFields } from './components/fields/fields.component';
export { LioFields } from './components/fields/fields.component';

import { LioFormInputs } from './components/form-inputs/form-inputs.component';
export { LioFormInputs } from './components/form-inputs/form-inputs.component';

import { LioFormInputsTableGroup } from './components/form-inputs-table-group/form-inputs-table-group.component';
export { LioFormInputsTableGroup } from './components/form-inputs-table-group/form-inputs-table-group.component';

import { LioFormInputsVertical } from './components/form-inputs-vertical/form-inputs-vertical.component';
export { LioFormInputsVertical } from './components/form-inputs-vertical/form-inputs-vertical.component';

import { LioSearchEmployees } from './components/search-employees/search-employees.component';
export { LioSearchEmployees } from './components/search-employees/search-employees.component';

import { LioModelEditor } from './components/model-editor/model-editor.component';
export { LioModelEditor } from './components/model-editor/model-editor.component';

import { LioEventEditor } from './components/events/editor/editor.component';
export { LioEventEditor } from './components/events/editor/editor.component';

import { LioLoadingSpinner } from './components/loading-spinner/loading-spinner.component';
export { LioLoadingSpinner } from './components/loading-spinner/loading-spinner.component';

@NgModule({
	imports: [
		FormsModule,
		FontAwesomeModule,
		AngularMaterialModule,
		CommonModule,
		LioPipesModule,
		LioDirectivesModule,
		NgxMatSelectSearchModule,
		ReactiveFormsModule,
	],
	exports: [
		LioMatWrapDateField,
		LioContextMenu,
		LioDrag,
		LioTooltip,
		LioButtons,
		LioFileUploader,
		LioFieldInput,
		LioReportFields,
		LioFileFormat,
		LioReportJoints,
		LioReportSettings,
		LioReportTable,
		LioReportWheres,
		LioCatalogModules,
		LioCatalogKeywords,
		LioCatalogNotes,
		LioCatalogTopics,
		LioCatalogUpdates,
		LioEventConditions,
		LioEventDays,
		FilterByTemplateType,
		LioQueryBuilder,
		LioQueryRule,
		LioQueryTool,
		LioSearchableSelect,
		LioFieldRow,
		LioFields,
		LioFormInputs,
		LioFormInputsTableGroup,
		LioFormInputsVertical,
		LioSearchEmployees,
		LioModelEditor,
		LioEventEditor,
		LioLoadingSpinner
	],
	declarations: [
		LioMatWrapDateField,
		LioContextMenu,
		LioDrag,
		LioTooltip,
		LioButtons,
		LioFileUploader,
		LioFieldInput,
		LioReportFields,
		LioFileFormat,
		LioReportJoints,
		LioReportSettings,
		LioReportTable,
		LioReportWheres,
		LioCatalogModules,
		LioCatalogKeywords,
		LioCatalogNotes,
		LioCatalogTopics,
		LioCatalogUpdates,
		LioEventConditions,
		LioEventDays,
		FilterByTemplateType,
		LioQueryBuilder,
		LioQueryRule,
		LioQueryTool,
		LioSearchableSelect,
		LioFieldRow,
		LioFields,
		LioFormInputs,
		LioFormInputsTableGroup,
		LioFormInputsVertical,
		LioSearchEmployees,
		LioModelEditor,
		LioEventEditor,
		LioLoadingSpinner
	],
	bootstrap: [
		LioMatWrapDateField,
		LioContextMenu,
		LioDrag,
		LioTooltip,
		LioButtons,
		LioFileUploader,
		LioFieldInput,
		LioReportFields,
		LioFileFormat,
		LioReportJoints,
		LioReportSettings,
		LioReportTable,
		LioReportWheres,
		LioCatalogModules,
		LioCatalogKeywords,
		LioCatalogNotes,
		LioCatalogTopics,
		LioCatalogUpdates,
		LioEventConditions,
		LioEventDays,
		LioQueryBuilder,
		LioQueryRule,
		LioQueryTool,
		LioSearchableSelect,
		LioFieldRow,
		LioFields,
		LioFormInputs,
		LioFormInputsTableGroup,
		LioFormInputsVertical,
		LioSearchEmployees,
		LioModelEditor,
		LioEventEditor,
		LioLoadingSpinner
	],
})
export class LioFormsModule{}