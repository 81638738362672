import { Inject, Injectable } from '@angular/core';

import { profileSettings } from '../profile/profile.settings';
import { enrollmentEventService } from '../../services/enrollment-event.service';
import { coursesService } from '../../services/courses.service';
import { LioDateField, LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: scheduleEnrollmentEventSettings
})
export class scheduleEnrollmentEventSettings {
	constructor(
		@Inject(profileSettings)			private profileSettings			:profileSettings,
		@Inject(enrollmentEventService) 	private enrollmentEventService	:enrollmentEventService,
		@Inject(coursesService) 			private coursesService			:coursesService
	){}

	appearance:string = 'outline';

	employeeFields:any  = this.profileSettings.externalFields;
	
	employeeConfig:any = {
		'fieldName'			: 'enrollmentEventExport',
		'addCCFtoFields'	: true,
		'localizeFields'	: true,
		'permissionFields'	: true,
		'addRoleToFields'	: true,
		'overrideFields'	: true,
		'addLangField'		: true,
		'addActiveField'	: true,
	};

	fieldConfig:any = {
		'fieldName'			: 'scheduleenrollmentevents',
		'addCCFtoFields'	: false,
		'permissionFields'	: true,
		'addRoleToFields'	: false,
		'overrideFields'	: false,
		'addLangField'		: false,
		'addActiveField'	: false,
		'allowSpecificUser': true,
	};

	fields:LioFieldArray = new LioFieldArray(
		{
			name		: 'Name',
			model		: 'name',
			type		: 'text',
			required	: true,
			visible		: true,
		},
		{
			name		: 'Description',
			model		: 'description',
			type		: 'textarea',
			required	: true,
			visible		: true,
		},
		new LioSearchableSelectField({
			name		: 'Enrollment Filters',
			model		: 'queryID',
			type		: 'select',
			required	: true,
			visible		: true,
			options		: this.enrollmentEventService.eventOptions,
			hideFromFieldEditor : true,
			searchable 	: true
		}),
		new LioSearchableSelectField({
			name 		: 'Course to enroll in',
			model		: 'courseID',
			subModel	: 'rule',
			nameTrans 	: 'scheduleEnrollments.chooseEnrollin',
			type		: 'select',
			required	: true,
			visible		: true,
			options  	: this.coursesService.activeCourseOptionsRepSub,
			hideFromFieldEditor : true,
			searchable 	: true
		}),
		new LioSearchableSelectField({
			name 		: 'Conditions',
			model		: 'conditions',
			nameTrans 	: 'scheduleEnrollments.conditions',
			type		: 'eventConditions',
			required	: false,
			visible		: true,
			class  		: 'col-md-12',
			conditions 	: ['rule.courseID'],
			options 	: this.coursesService.courses,
			nameFormatter : (name, course) => {
				return course.courseID + ' - ' + name;
			}
		}),
		new LioSearchableSelectField({
			name 		: 'How often should this run?',
			model		: 'recurranceType',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.reccurInterval',
			type		: 'select',
			options 	: [
				{name : 'Once'		, trans : 'scheduleEnrollments.once'		, value : 'once', selected : true},
				{name : 'Daily'		, trans : 'scheduleEnrollments.daily'	, value : 'daily'},
				{name : 'Weekly'	, trans : 'scheduleEnrollments.weekly'	, value : 'weekly'},
				{name : 'Monthly'	, trans : 'scheduleEnrollments.monthly'	, value : 'monthly'},
			],
			required	: true,
			visible		: true,
			conditions  : ['rule.courseID'],
			hideFromFieldEditor : true,
		}),
		{
			name 		: 'On these days of the week',
			model		: 'days',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.theseWeekDays',
			type		: 'eventDays',
			required	: true,
			visible		: true,
			weekDays 					: this.enrollmentEventService.weekDays,
			moreThanOneWeekDayEnabled	: this.enrollmentEventService.moreThanOneWeekDayEnabled,
			conditions  : [{'schedule.recurranceType': 'daily'}],
		},
		new LioSearchableSelectField({
			name 		: 'On this day of the week',
			model		: 'recurranceDay',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.thisWeekDay',
			type		: 'select',
			required	: true,
			visible		: true,
			options		: this.enrollmentEventService.weekDays,
			conditions  : [{'schedule.recurranceType': 'weekly'}],
			hideFromFieldEditor : true,
		}),
		{
			name 		: 'Every X Weeks',
			model		: 'recurranceDetail',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.everyWeeks',
			type		: 'range',
			min 		: 1,
			max 		: 52,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'weekly'}],
			hideFromFieldEditor : true,
		},
		{
			name 		: 'On this day of the month',
			model		: 'recurranceDay',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.thisDayOfMonth',
			type		: 'range',
			min 		: 1,
			max 		: 28,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'monthly'}],
			hideFromFieldEditor : true,
		},
		{
			name 		: 'Every X Months',
			model		: 'recurranceDetail',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.everyMonths',
			type		: 'range',
			min 		: 1,
			max 		: 12,
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'monthly'}],
			hideFromFieldEditor : true,
		},
		new LioDateField({
			name 		: 'On this date',
			model		: 'startTime',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.startDate',
			type		: 'date',
			minDate 	: (this.enrollmentEventService.getMinEventStartDate() as moment.Moment).toDate(),
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': 'once'}],
			hideFromFieldEditor : true,
		}),
		new LioDateField({
			name 		: 'Begin scheduling enrollments on',
			model		: 'startTime',
			subModel	: 'schedule',
			nameTrans 	: 'scheduleEnrollments.startDateUntil',
			type		: 'date',
			minDate 	: (this.enrollmentEventService.getMinEventStartDate() as moment.Moment).toDate(),
			required	: true,
			visible		: true,
			conditions  : [{'schedule.recurranceType': ['daily', 'weekly', 'monthly']}],
			hideFromFieldEditor : true,
			blackoutDates		: this.enrollmentEventService.isBlackoutDate,
		}),
			{
			name 		: 'Enter number of days until available Date',
			model		: 'availableDate',
			subModel	: 'rule',
			nameTrans 	: 'scheduleEnrollments.untilAvailableLabel',
			type		: 'number',
			min 		: 0,
			max 		: 10000,
			required	: false,
			visible		: false,
			conditions  : ['rule.courseID'],
		},
		{
			name 		: 'Enter minimum number of days until due date',
			model		: 'dueDate',
			subModel	: 'rule',
			nameTrans 	: 'scheduleEnrollments.untilDueLabel',
			type		: 'number',
			min 		: 1,
			max 		: 10000,
			required	: true,
			visible		: true,
			conditions  : ['rule.courseID']
		},
		{
			name 		: 'Enter number of days until expiration Date',
			model		: 'expirationDate',
			subModel	: 'rule',
			nameTrans 	: 'scheduleEnrollments.untilExpirationLabel',
			type		: 'number',
			min 		: 0,
			max 		: 10000,
			required	: false,
			visible		: false,
			conditions  : ['rule.courseID']
		},
	);

	weekDays:Array<any> = [
		{name: 'sun', trans : 'scheduleEnrollments.sundayShort'		, value: 'sunday'},
		{name: 'mon', trans : 'scheduleEnrollments.mondayShort'		, value: 'monday'},
		{name: 'tue', trans : 'scheduleEnrollments.tuesdayShort'	, value: 'tuesday'},
		{name: 'wed', trans : 'scheduleEnrollments.wednesdayShort'	, value: 'wednesday'},
		{name: 'thu', trans : 'scheduleEnrollments.thursdayShort'	, value: 'thursday'},
		{name: 'fri', trans : 'scheduleEnrollments.fridayShort'		, value: 'friday'},
		{name: 'sat', trans : 'scheduleEnrollments.saturdayShort'	, value: 'saturday'}
	];

	model:any = {
		id 					: null,
		name 				: '',
		description			: '',
		type 				: 'enrollment',
		actions 			: [],
		filters 			: [],
		queryID 			: null,
		conditions 			: [],
		rule				: {
			courseID		: '',
			availableDate	: 0,
			dueDate 		: 30,
			expirationDate 	: 365,
		},
		schedule 			: {
			startTime			: this.enrollmentEventService.getMinEventStartDate(true),
			endTime				: null,
			recurranceType 		: 'once',
			recurranceDetail 	: '',
			recurranceDay 		: '',
			sunday				: false, 
			monday				: true, 
			tuesday				: true, 
			wednesday			: true, 
			thursday			: true, 
			friday				: true, 
			saturday			: false
		}
	};
}