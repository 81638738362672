import { Component, Inject } from '@angular/core';

import { loaderService } from './services/loader.service';
import { localizationService } from './services/localization.service';
import { storageService } from './services/storage.service';
import { navService } from './services/nav.service';

@Component({
	selector: 'app-root',
	styleUrls: ['./app.component.scss'],
	templateUrl: './app.component.html'
})
export class AppComponent {

	public contrast:string = '';

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(storageService) 		public storageService	:storageService,
		@Inject(navService) 			public navService			:navService
	){

		this.navService.contrastChange.subscribe((contrast) => {
			this.contrast = contrast;
			this.storageService.set('contrast', contrast);
		});

		this.contrast = this.storageService.get('contrast');
	}


}