<lio-card cardId="search-results" 
	cardClass="my-2" 
	bodyClass="container-fluid py-1" 
	[hideErrors]="true"
	localize 
	[model]="localeStrings" 
	[items]="localeStringItems">
	<!-- Screen -->
	<div lio-card-body>
		<div class="row">
			<div class="col-7">
				<div class="h4 my-1" *ngIf="!catalogs.length && loadingCatalogs">{{localeStrings.loading}}</div>

				<div class="h4 my-1" *ngIf="!catalogs.length && !loadingCatalogs">{{localeStrings.noCatalogsFound}}</div>
				
				<div class="h4 my-1" *ngIf="catalogs.length">
					<span id="am_catalog_search_results_count" class="mr-1">{{collection.length}}/{{catalogs.length}}</span>
					<span id="am_catalog_search_results">{{localeStrings.results}}</span>
				</div>
			</div>

			<!-- Print -->
			<div class="col-5 ml-auto text-right" *ngIf="permissionService.hasPermission('catalog.print') && view.printable">
				<button
					id="am_catalog_search_print"
					[disabled]="!catalogs.length"
					class="btn btn-sm btn-primary rounded-pill my-1 mr-1"
					type="button"
					(click)="print.emit()">
					<span><span>{{localeStrings.print}}</span> ({{printingCount}}/{{collection.length}})</span>
				</button>

				<button
					id="am_catalog_search_print_all"
					[disabled]="!catalogs.length"
					class="btn btn-sm btn-primary rounded-pill my-1"
					type="button"
					(click)="printAll.emit()">
					<span>{{localeStrings.printAll}}</span>
				</button>
			</div><!-- Print -->
		</div>
	</div><!-- Screen -->
</lio-card><!-- Results Header -->