<lio-catalog-search-bar 
	[filters]="filters"
	[view]="view"
	[showFilter]="showFilter"
	[clearBtnActive]="clearBtnActive"
	(onupdate)="checkPrintability()"
	(toggleFilters)="toggleFilters()"
	[localeStrings]="searchBarLocale"
	(resetQuery)="resetQuery()">
</lio-catalog-search-bar>

<lio-catalog-filters 
	[fieldsObject]="fieldsObject" 
	[collections]="collections"
	[releasedStatus]="releasedStatus"
	[unReleasedStatus]="unReleasedStatus"
	[topics]="topics"
	[activeCore]="activeCore"
	[languageCodes]="languageCodes"
	(editCollections)="editCollections()"
	(activateCollection)="activateCollection($event)"
	(activateTopic)="activateTopic($event)"
	(activateCoreLangs)="activateCoreLangs()"
	(activateLanguage)="activateLanguage($event)"
	(clickedReleased)="clickedReleased()"
	(clickedUnreleased)="clickedUnreleased()"
	*ngIf="showFilter">
</lio-catalog-filters>

<lio-catalog-results-header
	[catalogs]="catalogs"
	[collection]="collection"
	[loadingCatalogs]="loadingCatalogs"
	[view]="view"
	[printingCount]="printingCount"
	(print)="print()"
	(printAll)="printAll()">
</lio-catalog-results-header>

<lio-catalog-results-sub-header
	[canExpandCatalogs]="canExpandCatalogs"
	[catalogs]="catalogs"
	[pagination]="pagination"
	[appearance]="appearance"
	[view]="view"
	(expandAllCatalogs)="expandAllCatalogs()"
	(collapseAllCatalogs)="collapseAllCatalogs()"
	(checkPrintAll)="checkPrintAll()"
	(clearPrint)="clearPrint()">
</lio-catalog-results-sub-header>

<lio-catalogs 
	[catalogs]="catalogs" 
	[filters]="filters"  
	[pagination]="pagination" 
	[view]="view"
	[filters]="filters"
	[fieldsObject]="fieldsObject"
	(clickedCatalog)="clickedCatalog($event)"
	(selectModule)="selectModule($event)"
	(previewModule)="previewModule($event)"
	(clickedPrint)="clickedPrint($event)"
	(previewCourse)="previewCourse($event)"
	(clickedOnScript)="clickedOnScript($event)"
	(clickedOnInfoSheet)="clickedOnInfoSheet($event)"
	(clickedOnRequest)="clickedOnRequest($event)"
	(catalogsFiltered)="updateFilteredCatalogs($event)">
</lio-catalogs>
<span [hidden]="!rendering" *ngIf="pdfDomReady">
	<lio-catalog-pdf [catalogs]="filterPrintCatalogs(collection)" 
		(readyToRender)="setRendererReady($event)">
	</lio-catalog-pdf>
</span>