/*
 * Service for interacting with backend files
*/

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { lmsService } from './lms.service';
import { configSettings } from '../settings/config.settings';
import { processingService } from './processing.service';
import { navService } from './nav.service';

@Injectable({
	providedIn: 'root',
})
export class filesService{
	constructor(
		@Inject(configSettings)		private configSettings		:configSettings,
		@Inject(lmsService)			private lmsService			:lmsService,
		@Inject(navService)			private navService			:navService,
		@Inject(processingService)	private processingService	:processingService,
		@Inject(DOCUMENT)			private document			:Document
		) {}
	/*
		* Gets a file
	*/
	getFile(file) {
		this.navService.changedForm = false;

		return this.lmsService.post('export/fileExists', {'file':file}).then((result:any) => {
			this.processingService.processing = false;
			if (result.success) {
				this.document.location.href = this.configSettings.serverGlobal + 'backend/tasks.php?task=export/getFile&file=' + file;
			}
		});
	}

	/*
		* Downlads a file
		* @param {string} file
	*/
	downloadFile(file, title) {
		let a = this.document.createElement('a');
		a.href = this.configSettings.serverGlobal + 'backend/tasks.php?task=export/getFile&file=' + file;
		a.download = title;
		a.click();
	}

	/*
	* Downlads a batch uploaded file
	* @param {string} file
	*/
	downloadBatchFile(uploadID, companyID, mode) {
		return this.lmsService.post('batchUpload/downloadFileByUploadID&uploadID=', {'uploadID':uploadID, 'companyID': companyID, 'mode': mode}).then((result:any) => {
			if (result.success) {
				this.downloadFile(result.properties.filePath, result.properties.fileName);
			}
		});
	}


	/*
		* Gets a file by reading it
		* @param {string} file
		* @param {string} type
	*/
	openFile(file, type) {		
		this.navService.changedForm = false;

		this.lmsService.post('export/fileExists', {'file':file}).then((result:any) => {
			if (result.success) {
				this.processingService.processing = null;
				this.document.location.href = this.configSettings.serverGlobal + 'backend/tasks.php?task=export/readFile&file=' + file + '&type=' + type;
			}
		});
	}
}