import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../../imports/angular-material';

import { LioDirectivesModule } from './../../directives/directives.module';
import { LioPipesModule } from './../../pipes/pipes.module';
import { LioFormsModule } from './../lio-forms/lio-forms.module';
import { LioDataDisplayModule } from './../data-display/data-display.module';
import { LioStructuralModule } from './../structural/structural.module';

import { LioCatalogs } from './components/catalogs/catalogs.component';
export { LioCatalogs } from './components/catalogs/catalogs.component';

import { LioCatalogFilters } from './components/filters/filters.component';
export { LioCatalogFilters } from './components/filters/filters.component';

import { LioCatalogResultsHeader } from './components/results-header/results-header.component';
export { LioCatalogResultsHeader } from './components/results-header/results-header.component';

import { LioResultsSubHeader } from './components/results-sub-header/results-sub-header.component';
export { LioResultsSubHeader } from './components/results-sub-header/results-sub-header.component';

import { LioCatalogSearchBar } from './components/search-bar/search-bar.component';
export { LioCatalogSearchBar } from './components/search-bar/search-bar.component';

import { LIOCatalogCollapsed } from './components/catalog-collapsed/catalog-collapsed.component';
export { LIOCatalogCollapsed } from './components/catalog-collapsed/catalog-collapsed.component';

import { LIOCatalogExpanded } from './components/catalog-expanded/catalog-expanded.component';
export { LIOCatalogExpanded } from './components/catalog-expanded/catalog-expanded.component';

import { LioCatalogPDF } from './components/catalog-pdf/catalog-pdf.component';
export { LioCatalogPDF } from './components/catalog-pdf/catalog-pdf.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		AngularMaterialModule,
		LioFormsModule,
		LioPipesModule,
		LioDirectivesModule,
		LioDataDisplayModule,
		LioStructuralModule
	],
	declarations: [
		LioCatalogs,
		LioCatalogFilters,
		LioCatalogResultsHeader,
		LioResultsSubHeader,
		LioCatalogSearchBar,
		LIOCatalogCollapsed,
		LIOCatalogExpanded,
		LioCatalogPDF
	],
	exports: [
		LioCatalogs,
		LioCatalogFilters,
		LioCatalogResultsHeader,
		LioResultsSubHeader,
		LioCatalogSearchBar,
		LIOCatalogCollapsed,
		LIOCatalogExpanded,
		LioCatalogPDF
	],
	bootstrap: [
		LioCatalogs,
		LioCatalogFilters,
		LioCatalogResultsHeader,
		LioResultsSubHeader,
		LioCatalogSearchBar,
		LIOCatalogCollapsed,
		LIOCatalogExpanded,
		LioCatalogPDF
	],
})
export class LioCatalogModule{}