import { Component, OnDestroy } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../../../services/loader.service';
import { navService } from '../../../../services/nav.service';
import { settingsService } from '../../../../services/settings.service';
import { permissionService } from '../../../../services/permissions.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { storageService } from '../../../../services/storage.service';
import { workerService } from '../../../../services/worker.service';
import { stateService } from '../../../../services/state.service';
import { passwordResetService } from '../../../../services/password-reset.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { loginService } from '../../../../services/login.service';
import { debugService } from '../../../../services/debug.service';

import { userSummarySettings } from '../../../../components/user-summary/user-summary.settings';
import { localizationService } from '../../../../services/localization.service';

@Component({
	selector: 'lio-email-audit-history',
	templateUrl: './email-audit-history.component.html'
})
export class LioEmailAuditHistory implements OnDestroy {

	public fields						:Array<any>		= this.utilService.copy(this.userSummarySettings.fields);
	public searchConfig					:any			= this.utilService.copy(this.userSummarySettings.searchConfig);
	public searches						:Array<any>		= this.utilService.copy(this.userSummarySettings.emailAuditHistorySearches);
	public search						:any 			= {};
	
	public emailAuditHistoryFields		:Array<any>		= this.utilService.copy(this.userSummarySettings.emailAuditHistoryFields);
	public emailAuditHistoryPagination	:any 			= this.utilService.copy(this.userSummarySettings.emailAuditHistoryPagination);

	public employee						:any 			= {};
	public filteredEmailAuditHistory	:Array<any>		= [];
	public emailAuditHistory			:Array<any>		= [];

	private UID							:string 		= '';
	
	private subscriptions			:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(settingsService) 		public settingsService		:settingsService,
		@Inject(permissionService) 		public permissionService	:permissionService,
		@Inject(formValidatorService) 	public formValidatorService	:formValidatorService,
		@Inject(lmsService) 			public lmsService			:lmsService,
		@Inject(lioModalService) 		public lioModalService		:lioModalService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(workerService) 			public workerService		:workerService,
		@Inject(stateService) 			public stateService			:stateService,
		@Inject(passwordResetService) 	public passwordResetService	:passwordResetService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(feedbackService) 		public feedbackService		:feedbackService,
		@Inject(loginService) 			public loginService			:loginService,
		@Inject(debugService) 			public debugService			:debugService,
		@Inject(localizationService) 	public localizationService	:localizationService,
		@Inject(userSummarySettings) 	public userSummarySettings	:userSummarySettings,
	)
	{
		this.debugService.register('emailaudithistory', this);

		/*
		* Init
		*/
		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				if (!this.getEmployee()) {
					this.navService.goto('home');
					return;
				}
				this.getEmailHistory();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initializes
    */
	initForm() {
		this.navService.displayPage();
	}


	updateEmailAuditHistory(collection) {
		this.filteredEmailAuditHistory = collection.filtered;
	}

	

	/*
	 * Gets the employee from storage
    */
	getEmployee() {
		this.UID = this.storageService.get('UID');
		return this.UID ? true : false;
	}

	/*
	 * Gets the user summary
    */
	getEmailHistory() {
		this.lmsService.post('userSummary/getEmailAuditHistory', {'UID': this.UID}).then((result) => {
			if (result.properties.emailAuditHistory) {
				this.emailAuditHistory = result.properties.emailAuditHistory;
			}
		});
	}

	/*
	 * Exports the records
	*/
	exportRecords() {
		if (!this.workerService.export(this.emailAuditHistory, this.fields, 'Email History')) {
			this.lioModalService.show('Failed to export email history');
		}
	}
}