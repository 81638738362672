import { Component, OnDestroy, Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { stateService } from '../../services/state.service';
import { coursesService } from '../../services/courses.service';
import { storageService } from '../../services/storage.service';
import { permissionService } from '../../services/permissions.service';
import { lmsService } from '../../services/lms.service';
import { lioModalService } from '../../services/lio-modal.service';
import { utilService } from '../../services/util.service';
import { feedbackService } from '../../services/feedback.service';
import { filesService } from '../../services/files.service';
import { processingService } from '../../services/processing.service';
import { fieldService } from '../../services/fields.service';

import { enrollmentSettings } from '../enrollments/enrollments.settings';

@Component({
	selector: 'lio-enroll-query',
	templateUrl: './enroll-query.component.html'
})
export class EnrollQueryComponent implements OnDestroy {
	public selectByFilterPanelConfig	:any 			= this.enrollmentSettings.panelConfig.selectByFilter;
	public selectByFilePanelConfig		:any 			= this.enrollmentSettings.panelConfig.selectByFile;

	public proccessTypeField			:any 			= this.enrollmentSettings.proccessTypeField;
	public unenrollCourseField			:any			= this.enrollmentSettings.unenrollCourseField;
	public updateCourseField			:any			= this.enrollmentSettings.updateCourseField;
	public completeCourseField			:any			= this.enrollmentSettings.completeCourseField;
	public courses						:Array<any> 	= [];

	// Builder vars
	public filters						:Array<any>		= this.enrollmentSettings.filters;
	public queryToolSettings			:any 			= this.enrollmentSettings.queryToolSettings;
	public queryTool					:any			= {};
	public readyForProcess				:boolean		= false;
	public processTypes					:Array<any>		= this.enrollmentSettings.processTypes;

	public model						:any 			= {
		processType : this.enrollmentSettings.defaultProcessType,
		courseID 	: null
	};

	public uploadSettings				:any 			= {
		name			: 'Upload File',
		trans			: 'enrollquery.upload',
		fileTask		: 'importenrollment/upload',
		allowedFileTypes: ['xlsx'],
		hideLoading		: false,
	};

	public localeStrings				:any 			= {
		filters				:'Filters',
		filtersTrans		:'enrollquery.filters',
		dragExcel			:'Drag And Drop your excel (xlsx) file anywhere on this page',
		dragExcelTrans		:'addusers.dragExcel',
		getTemplate			:'Get Template For importing',
		getTemplateTrans	:'addusers.getTemplate',
		getSample			:'Get Sample File',
		getSampleTrans		:'addusers.getSample',
		enroll				:'All employees listed in the file will be selected for enrollments.',
		enrollTrans			:'enrollquery.enroll',
		unenrollNote		:'NOTE: ONLY ENROLLMENTS THAT HAVE NOT YET BEEN COMPLETED WILL BE UPDATED',
		unenrollNoteTrans	:'enrollquery.unenrollNote',
		updateNote			:'NOTE: ONLY ENROLLMENTS THAT HAVE NOT YET BEEN COMPLETED WILL BE UN-ENROLLED',
		updateNoteTrans		:'enrollquery.updateNote',
		process				:'Process File',
		processTrans		:'enrollquery.process'
	};
	public localeStringsKeys			:Array<any> 	= [
		'filters',
		'dragExcel',
		'getTemplate',
		'getSample',
		'makeSelection',
		'enroll',
		'updateNote',
		'process'
	];

	private cancelFileProcess			:any 			= null;
	private file						:any 			= null;
	private filePath					:string 		= null;

	private subscriptions				:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(navService)			public	navService			:navService,
		@Inject(debugService)		public	debugService		:debugService,
		@Inject(stateService)		public	stateService		:stateService,
		@Inject(coursesService)		public	coursesService		:coursesService,
		@Inject(storageService)		public	storageService		:storageService,
		@Inject(permissionService)	public	permissionService	:permissionService,
		@Inject(lmsService)			public	lmsService			:lmsService,
		@Inject(lioModalService)	public	lioModalService		:lioModalService,
		@Inject(utilService)		public	utilService			:utilService,
		@Inject(feedbackService)	public	feedbackService		:feedbackService,
		@Inject(filesService)		public	filesService		:filesService,
		@Inject(processingService)	public	processingService	:processingService,
		@Inject(fieldService)		public	fieldService		:fieldService,
		@Inject(enrollmentSettings)	public	enrollmentSettings	:enrollmentSettings
	){
		this.debugService.register('enrollquery', this);
		this.navService.setActivePage('enrollquery');

		this.processingService.allowCancel = true;
			
		// Override endpoint for quicker query 
		this.queryToolSettings.endpoints.report;

		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				this.init();
			})
		);
	
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.init();
			})
		);
	
		this.subscriptions.add(
			this.coursesService.courses.subscribe((result) => {
				this.courses = result;
			})
		);
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
	}

	/*
	 * Inits
	*/
	init(){
		this.handleProcessType();
		this.processTypes = this.permissionService.setFields(this.processTypes);
		this.navService.displayPage();
	}

	/*
	 * On tab change
	 * @param {object} newTab
	*/
	onTabChange() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
	}

	/*
	 * Gets the importing template
	*/
	getTemplate() {
		if (this.processingService.processing) {
			return;
		}

		let processType = this.getProcessType();
		let form 		= {
			rows		: [],
			headers		: processType.templateHeaders,
			fileName	: processType.templateFileName,
		};

		this.lmsService.post('export/writeFileFromExternal', form).then((result) => {
			let success = result.success;
			let file 	= result.properties.file;

			if (success) {
				this.filesService.getFile(file);
			}
		});
	}

	/*
	 * Gets the process object
	 * @return {object}
	*/
	getProcessType() {
		let processType; 
		this.processTypes.forEach((type) => {
			if (this.model.processType === type.value) {
				processType = type;
			}
		});

		return processType;
	}

	/*
	 * Gets the importing sample
	*/
	getSample() {
		if (this.processingService.processing) {
			return;
		}
		let processType = this.getProcessType();
		let form 		= {
			rows	: processType.sampleUsers,
			headers	: processType.sampleHeaders,
			fileName: processType.sampleFileName
		};
		this.lmsService.post('export/writeFileFromExternal', form).then((result) => {
			var success = result.success,
				file = result.properties.file;

			if (success) {
				this.filesService.getFile(file);
			}
		});
	}

	/*
	 * Resets variables
	*/
	reset() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
	}

	/*
	 * Handles not allows to upload
	*/
	handleNotAllowed() {
		if (!this.model.processType) {
			this.feedbackService.setError('pleaseSelectAProcessType');
		} else {
			this.feedbackService.setError('sorryTryThatAgain');
		}
	}

	/*
	 * Goes back to home and clears the current filters
	*/
	gobackAndClear() {
		this.navService.goback();
	}

	/*
	 * Handles processtype change
	*/
	handleProcessType() {
		this.model.courseID = null;
		
		if (this.file) {
			this.feedbackService.clearMessages();
			this.feedbackService.clearErrors();
			this.cancelFileProcess();
		}
		this.feedbackService.clearErrors();
	}

	/* 
	 * On File Added
	*/
	fileAdded(event) {
		this.filePath = event.filePath;
		this.preprocess();
	}

	/*
	 * File preprocess / validation
	*/
	preprocess() {
		var filePath = this.filePath;

		this.lmsService.postAsync('importenrollment/preprocess', {'processType': this.model.processType, 'filePath': filePath, 'courseID': this.model.courseID},
		'uploading').then((result) => {
			var token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handlePreProcessResults(result);
				});
			} else {
				this.handlePreProcessResults(result);
			}
		});	
	}

	/*
	 * On Query tool response
	*/
	handleResponse(results) {
		if (results) {
			let employees = results.properties.employees;
			this.storageService.setRulesToAssign(this.queryTool.getRules(), employees);
			this.navService.goto('assign');
		}
	}

	/*
	 * File process
	*/
	process() {
		let filePath = this.filePath;

		this.lmsService.postAsync('importenrollment/process', {
			processType	: this.model.processType, 
			filePath	: filePath, 
			courseID	: this.model.courseID
		}, 'processing').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleProcessResults(result);
				});
			} else {
				this.handleProcessResults(result);
			}
		});	
	}

	/*
	 * Handles preprocess results
	*/
	handlePreProcessResults(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}

		let properties 						= result.properties;
		let totalDetected 					= properties.totalDetected;
		let totalNotRecognized 				= properties.totalNotRecognized;
		let totalToEnroll 					= properties.totalToEnroll;
		let totalToUnEnroll 				= properties.totalToUnEnroll;
		let totalNotEnrolled 				= properties.totalNotEnrolled;
		let totalToComplete 				= properties.totalToComplete;
		let totalToUpdate 					= properties.totalToUpdate;
		let totalAlreadyCompleted 			= properties.totalAlreadyCompleted;
		let totalFailed 					= properties.totalFailed;
		let alreadyStarted 					= properties.alreadyStarted;
		let totalUnEnrolled 				= properties.totalUnEnrolled;
		let totalInactive 					= properties.totalInactive;
		let totalCompletedNoUnenrollment 	= properties.totalCompletedNoUnenrollment;
		let cancelled 						= properties.cancelled;
		let employees 						= properties.employees;
		let file 							= properties.file;
		let errors 							= properties.errors;
		let isValid 						= properties.isValid;
		let isValidFile 					= true;
		let messages 						= [];
		let macros 							= [];

		if (cancelled) {
			this.navService.changedForm = false;
			return;
		}

		if (totalDetected) {
			macros.push({'key': 'totalUsers', 'value': totalDetected});
			messages.push(this.utilService.localizeMessage('totalUsersDetected', macros));	
		}
		
		
		if (totalToEnroll) {
			macros.push({'key': 'totalToEnroll', 'value': totalToEnroll});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeEnrolled', macros));
		}

		if (totalUnEnrolled) {
			macros.push({'key': 'succcessNumber', 'value': totalUnEnrolled});
			messages.push(this.utilService.localizeMessage('usersSuccessfullyUnenrolled', macros));
		}

		if (totalCompletedNoUnenrollment) {
			macros.push({'key': 'totalCompleted', 'value': totalCompletedNoUnenrollment});
			messages.push(this.utilService.localizeMessage('alreadyCompletedCannotUnenroll', macros));
		}

		if (totalInactive) {
			macros.push({'key': 'totalInactive', 'value': totalInactive});
			messages.push(this.utilService.localizeMessage('totalUsersAreInactive', macros));
		}

		if (totalNotEnrolled) {
			macros.push({'key': 'notEnrolledNumber', 'value': totalNotEnrolled});
			messages.push(this.utilService.localizeMessage('usersWereNotAlreadyEnrolled', macros));
		}

		if (totalNotRecognized) {
			macros.push({'key': 'totalNotRecognized', 'value': totalNotRecognized});
			messages.push(this.utilService.localizeMessage('totalUsersNotRecognized', macros));
		}

		if (totalToUnEnroll) {
			macros.push({'key': 'totalToUnEnroll', 'value': totalToUnEnroll});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeUnEnrolled', macros));
		}

		if (totalAlreadyCompleted) {
			macros.push({'key': 'totalAlreadyCompleted', 'value': totalAlreadyCompleted});
			messages.push(this.utilService.localizeMessage('totalUsersAlreadyCompletedThisCourse', macros));
		}

		if (alreadyStarted) {
			macros.push({'key': 'totalStarted', 'value': properties.alreadyStarted});
			messages.push(this.utilService.localizeMessage('alreadyStartedCannotUnenroll', macros));
		}

		if (totalToComplete) {
			macros.push({'key': 'totalToComplete', 'value': totalToComplete});
			messages.push(this.utilService.localizeMessage('totalUsersWillBeMarkedToComplete', macros));
		}

		if (totalToUpdate) {
			macros.push({'key': 'totalToUpdate', 'value': totalToUpdate});
			messages.push(this.utilService.localizeMessage('totalEnrollmentsWillBeUpdated', macros));
		}
		

		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalIssuesWithTheFile', macros));
			isValidFile = false;
		}

		if (!isValid) {
			messages.push(this.utilService.localizeMessage('ZeroUsersWillBeUpdated'));
			isValidFile = false;
		}

		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		this.readyForProcess 	= isValidFile;
		this.file 				= file;
		this.storageService.setEmployeesToAssign(employees);
		this.storageService.set('employees', employees);

		if (!isValidFile) {
			this.navService.changedForm = false;
		}
	}

	/*
	 * Handles process results
	*/
	handleProcessResults(result) {
		this.lioModalService.hideLoading();
		this.navService.changedForm = false;
		if (!result.success) {
			return;
		}

		var properties = result.properties,
			totalDetected = properties.totalDetected,
			totalNotRecognized = properties.totalNotRecognized,
			totalNotEnrolled = properties.totalNotEnrolled,
			totalMarkedComplete = properties.totalMarkedComplete,
			totalAlreadyCompleted = properties.totalAlreadyCompleted,
			totalCompletedNoUnenrollment = properties.totalCompletedNoUnenrollment,
			totalFailedToUpdate = properties.totalFailedToUpdate,
			totalUpdated = properties.totalUpdated,
			totalFailed = properties.totalFailed,
			totalUnEnrolled = properties.totalUnEnrolled,
			totalInactive = properties.totalInactive,
			cancelled = properties.cancelled,
			employees = properties.employees,
			file = properties.file,
			errors = properties.errors,
			isValid = properties.isValid,
			isValidFile = true,
			messages = [],
			macros = [];

		if (cancelled) {
			return;
		}

		macros.push({'key': 'totalUsers', 'value': totalDetected});
		messages.push(this.utilService.localizeMessage('totalUsersDetected', macros));
		
		if (totalUnEnrolled) {
			macros.push({'key': 'succcessNumber', 'value': totalUnEnrolled});
			messages.push(this.utilService.localizeMessage('usersSuccessfullyUnenrolled', macros));
		}

		if (totalCompletedNoUnenrollment) {
			macros.push({'key': 'totalCompleted', 'value': totalCompletedNoUnenrollment});
			messages.push(this.utilService.localizeMessage('alreadyCompletedCannotUnenroll', macros));
		}

		if (totalMarkedComplete) {
			macros.push({'key': 'totalCompleted', 'value': totalMarkedComplete});
			messages.push(this.utilService.localizeMessage('totalUsersMarkedToComplete', macros));	
		}

		if (totalInactive) {
			macros.push({'key': 'totalInactive', 'value': totalInactive});
			messages.push(this.utilService.localizeMessage('totalUsersAreInactive', macros));
		}

		if (totalNotEnrolled) {
			macros.push({'key': 'notEnrolledNumber', 'value': totalNotEnrolled});
			messages.push(this.utilService.localizeMessage('usersWereNotAlreadyEnrolled', macros));
		}

		if (totalNotRecognized) {
			macros.push({'key': 'totalNotRecognized', 'value': totalNotRecognized});
			messages.push(this.utilService.localizeMessage('totalUsersNotRecognized', macros));
		}

		if (totalFailedToUpdate) {
			macros.push({'key': 'totalFailedToUpdate', 'value': totalFailedToUpdate});
			messages.push(this.utilService.localizeMessage('totalFailedToUpdate', macros));
		}

		if (totalUpdated) {
			macros.push({'key': 'totalUpdated', 'value': totalUpdated});
			messages.push(this.utilService.localizeMessage('totalEnrollmentsUpdated', macros));
		}
		
		if (totalAlreadyCompleted) {
			macros.push({'key': 'totalAlreadyCompleted', 'value': totalAlreadyCompleted});
			messages.push(this.utilService.localizeMessage('totalUsersAlreadyCompletedThisCourse', macros));
		}

		
		if (totalFailed) {
			macros.push({'key': 'totalFailed', 'value': totalFailed});
			messages.push(this.utilService.localizeMessage('totalIssuesWithTheFile', macros));
			isValidFile = false;
		}

		if (!isValid) {
			messages.push(this.utilService.localizeMessage('ZeroUsersWillBeUpdated'));
			isValidFile = false;
		}


		this.feedbackService.setMessages(messages);
		this.feedbackService.setErrors(errors);
		this.readyForProcess 	= isValidFile;
		this.file 				= file;
		this.storageService.setEmployeesToAssign(employees);
		this.storageService.set('employees', employees);

		if (isValidFile) {
			this.readyForProcess = false;
		}
	}

	/*
	 * Validate Selection
	 * @return {boolean}
	*/
	validate() {
		var type = this.model.processType,
			isValid = false;

		switch (type) {
			case 'ENROLL':
				isValid = true;
				break;
			case 'UNENROLL':
				if (this.model.courseID) {
					isValid = true;
				}
				break;
			case 'UPDATE':
				if (this.model.courseID) {
					isValid = true;
				}
				break;
			case 'COMPLETE':
				if (this.model.courseID) {
					isValid = true;
				}
				break;
			default:
				this.feedbackService.setError('sorryTryThatAgain');
				break;
		}

		return isValid;
	}

	/*
	 * Process
	*/
	processFile() {
		this.reset();
		var type = this.model.processType;
		switch (type) {
			case 'ENROLL':
				this.navService.goto('assign');
				break;
			case 'UNENROLL':
				this.process();
				break;
			case 'UPDATE':
				this.process();
				break;
			case 'COMPLETE':
				this.process();
				break;
			default:
				this.feedbackService.setError('sorryTryThatAgain');
				break;
		}
	}
}