<div class="row">
	<div class="col-12">
		<lio-result-filters
			[searchConfig]="searchConfig"
			[search]="search"
			[searchFields]="searches">
		</lio-result-filters>
	</div>
</div>
<div class="row mt-2">
	<div class="col-12">
		<div lio-paginator 
			controlID="courseHistory" 
			[collection]="courseHistory" 
			[filters]="search"
			[settings]="courseHistoryPagination" 
			(onResults)="updateCourseHistory($event)">
			<lio-results-table
				paginatorID="courseHistory"
				[fields]="courseHistoryFields"
				[results]="filteredCourseHistory">
			</lio-results-table>
		</div>
		<lio-paginator-controls controlID="courseHistory"></lio-paginator-controls>
	</div>
</div>