/*
 * Service for Displaying info in a popup
*/
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { storageService } from './storage.service';

@Injectable({
	providedIn: 'root',
})
export class popupService {
	constructor(
		@Inject(storageService) private storageService 	: storageService,
		@Inject(DOCUMENT)		private document		: Document
	){}

	/**
	 *  Opens a popup
	*/
	popup(url, title, w, h) {
		let y = this.document.defaultView.top.outerHeight / 2 + this.document.defaultView.top.screenY - ( h / 2);
		let	x = this.document.defaultView.top.outerWidth / 2 + this.document.defaultView.top.screenX - ( w / 2);
		
		this.document.defaultView.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
	}

	/** 
	 * Shows the email template instructions
	*/
	showInstructions() {
		var langID = this.storageService.getLangID(),
			availableLangs = ['en', 'ar','pt-br','zh-hans','nl','fr','fr-ca','de','it','ja','ko','es-419','es','ru', 'he'];

		if (availableLangs.indexOf(langID) == -1) {
			langID = 'en';
		}

		this.popup('views/instructions/email/' + langID + '.html', 'Instructions', 650, 600);
	}
}