import { Inject, Injectable } from '@angular/core';
import { permissionService } from '../services/permissions.service';
import { catalogService } from '../services/catalog.service';

import { LioSearchableSelectField, LioFieldArray } from '../modules/lio-forms/lio-forms.models';
import { PanelTabData } from '../modules/structural/structural.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: catalogSettings
})
export class catalogSettings {
	constructor(
		@Inject(catalogService) private catalogService:catalogService,
		@Inject(permissionService) private permissionService:permissionService
	){}

	courseIDField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'am_catalogadmin_courseIDs',
		name			: 'Course ID',
		nameTrans		: 'form.courseID',
		model			: 'id',
		addEmptyOption	: true,
		searchable 		: true
	});

	topicField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'am_catalogadmin_topics',
		name			: 'Topics',
		nameTrans		: 'form.topics',
		model			: 'topicCodeID',
		optionValueField: 'topicCodeID',
		addEmptyOption	: true,
		searchable 		: true
	});

	uploadTypeField:LioSearchableSelectField = new LioSearchableSelectField({
		amID 			: 'delta',
		name			: 'Choose what you would like to upload',
		nameTrans		: 'catalogadmin.choose',
		model			: 'type',
		addEmptyOption	: true,
		placeholder		: 'Please make a selection',
		placeholderTrans: 'addusers.makeSelection',
	});

	uploadSettings:any = {
		name				: 'Upload File',
		trans				: 'addusers.upload',
		fileTask			: 'importcatalog/upload',
		allowedFileTypes	: ['xlsx'],
		hideLoading			: false,
	};

	selfEnrollPanelConfig:{
		training	:PanelTabData, 
		selfEnroll	:PanelTabData
	} = {
		training : new PanelTabData({
			id				: 'panelTab_training_courses_form',
			tab				: 'My Courses',
			tabTrans		: 'training.myCourses',
			bodyClass 		: 'noclass',
			link 			: 'training',
			suppressAutoSelect	: true,
			visible 	: () => { 
				return this.permissionService.hasPermission('pages.training');
			}
		}),
		selfEnroll : new PanelTabData({
			id 			: 'panelTab_self_enroll',
			tab			: 'Self Enrollment',
			tabTrans 	: 'selfenroll.title',
			visible 	: () => { 
				return this.permissionService.hasPermission('pages.selfEnroll');
			}
		})
	};

	panelConfig:{
		search		:PanelTabData, 
		add			:PanelTabData, 
		edit		:PanelTabData, 
		topic		:PanelTabData,
		bulk		:PanelTabData,
		collection	:PanelTabData
	} = {
		search : new PanelTabData({
			id				: 'panelTab_catalog_admin_search',
			title			: 'Search Catalogs',
			titleTrans		: 'catalogadmin.searchCatalogs',
			tab				: 'Search Catalogs',
			tabTrans		: 'catalogadmin.searchCatalogs',
			bodyClass 		: 'card-body p-0',
			pagination 		: {
				id 				: 'searchPanelControlID',
				pageLimit		: '15',
				pageLimits		: ['5', '10', '15', '25', '50'],
				sortMode		: {'field' : 'courseID', 'direction' : 'asc'}
			}
		}),
		add : new PanelTabData({
			id				: 'panelTab_catalog_admin_add',
			title			: 'Please fill out the required(*) fields in the form below',
			titleTrans		: 'catalogadmin.addEditSubHeader',
			tab				: 'Add Catalog',
			tabTrans		: 'catalogadmin.addCatalog',
			reloadOnTabChange: true
		}),
		edit : new PanelTabData({
			id				: 'panelTab_catalog_admin_edit',
			tab				: 'Edit Catalog',
			tabTrans		: 'catalogadmin.editCatalog',
			reloadOnTabChange: true
		}),
		topic : new PanelTabData({
			id				: 'panelTab_catalog_admin_topic',
			tab				: 'Edit Topics',
			tabTrans		: 'catalogadmin.topicCatalog',
			visible 		: () => {return this.permissionService.hasPermission('catalog.admin');},
			reloadOnTabChange: true
		}),
		bulk : new PanelTabData({
			id				: 'panelTab_catalog_admin_bulk',
			title			: 'Bulk Add/Update Catalogs',
			titleTrans		: 'catalogadmin.bulkUploadHeader',
			tab				: 'Bulk Upload',
			tabTrans		: 'catalogadmin.bulkUpload',
			visible 		: () => {return this.permissionService.hasPermission('catalog.upload');}
		}),
		collection : new PanelTabData({
			id				: 'panelTab_catalog_collections',
			tab				: 'Catalog Collections',
			tabTrans		: 'catalogadmin.createCatalogHeader',
			link 			: 'catalogcollection',
		})
	};

	editPanelTitles:any = {
		noCourseIDTitle		: 'Please select a course ID to edit',
		noCourseIDTitleTrans: 'catalogadmin.selectCourseID',
		courseIDTitle		: 'Please fill out the required(*) fields in the form below',
		courseIDTitleTrans 	: 'catalogadmin.addEditSubHeader'
	};

	pagination:any = {
		pageLimit		: '10',
		pageLimits		: ['10', '15', '25', '50'],
		sortMode		: {'field' : 'courseID', 'direction' : 'desc'}
	};

	fieldsSet:any = false;
	
	loadFields:LioFieldArray = new LioFieldArray(
		new LioSearchableSelectField({
			name	: 'Course ID',
			model	: 'courseIDs',
			type	: 'select',
			required: true,
			visible	: true,
			options	: []
		})
	);

	processType:any = 'catalog';

	processTypes:any = [
		{
			value	: 'catalog',
			trans	: 'catalogadmin.catalogs',
			name	: 'Catalogs',
			selected: true,
		},
		{
			value	: 'courseType',
			trans	: 'catalogadmin.courseTypes',
			name	: 'Course Types',
			selected: false,
		},
		{
			value	: 'language',
			trans	: 'catalogadmin.languages',
			name	: 'Languages',
			selected: false,
		}
	];

	topicFields:LioFieldArray = new LioFieldArray(
		{
			name	: 'Topic Name',
			model	: 'name',
			type	: 'text',
			visible	: true,
		},
		{
			name	: 'Keywords',
			model	: 'keywords',
			type	: 'catalogKeywords',
			visible	: true,
		},
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			name					: 'Collections Filter',
			model					: 'filterCollections',
			type					: 'select',
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Status Filter',
			model					: 'filterStatus',
			type					: 'select',
			visible					: false,
			disableOptionOverride	: true,
		},
		{
			name					: 'Topics Filter',
			model					: 'filterTopics',
			type					: 'select',
			visible					: false,
			disableOptionOverride	: true,
		},
		{
			name					: 'Languages Filter',
			model					: 'filterLanguages',
			type					: 'select',
			visible					: false,
			disableOptionOverride	: true,
		},
		{
			name					: 'Image',
			model					: 'image',
			type					: 'image',
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Course ID',
			model					: 'courseID',
			type					: 'text',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 9,
			disableOptionOverride	: true,
		},
		{
			name					: 'Course Name',
			model					: 'name',
			type					: 'text',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\*|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		new LioSearchableSelectField({
			name					: 'Course Type',
			model					: 'type',
			type					: 'select',
			required				: true,
			visible					: true,
			options					: this.catalogService.courseTypes,
			disableOptionOverride	: true
		}),
		{
			name					: 'Course Description',
			model					: 'description',
			type					: 'textarea',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 5000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Micro Course Link',
			model					: 'URL',
			type					: 'text',
			required				: false,
			visible					: false,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Tuesday with Bernie Video',
			model					: 'tuesday',
			type					: 'text',
			required				: false,
			visible					: false,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Gary Turk Video',
			model					: 'turk',
			type					: 'text',
			required				: false,
			visible					: false,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'BroadCat',
			model					: 'broadcat',
			type					: 'text',
			required				: false,
			visible					: false,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'PDF Description',
			model					: 'pdfDescription',
			type					: 'textarea',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 5000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Length',
			model					: 'length',
			type					: 'text',
			required				: true,
			visible					: true,
			min						: 2,
			max						: 30,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Version',
			model					: 'version',
			type					: 'text',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Training Frequency',
			model					: 'trainingFrequency',
			type					: 'text',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Recommended Audience',
			model					: 'recommendedAudience',
			type					: 'text',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 128,
			checkForSpecialChars	: true,
			regex					: /\`|\~|\!|\#|\$|\%|\^|\&|\*|\(|\)|\=|\[|\{|\]|\}|\||\\|\<|\>|\?|\/|\"|\;|\:/g,
			checkForHTMl			: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Days From Hire',
			model					: 'daysFromHire',
			type					: 'date',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Script Location',
			model					: 'scriptLocation',
			type					: 'text',
			required				: false,
			visible					: true,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Info Sheet Location',
			model					: 'infoSheetLocation',
			type					: 'text',
			required				: false,
			visible					: true,
			varchar					: false,
			min						: 2,
			max						: 1000,
			disableOptionOverride	: true,
		},
		{
			name					: 'Cascade ID',
			model					: 'cascadeID',
			type					: 'text',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 30,
			disableOptionOverride	: true,
		},
		{
			name					: 'Format',
			model					: 'format',
			type					: 'text',
			required				: false,
			visible					: true,
			min						: 2,
			max						: 90,
			disableOptionOverride	: true,
		},
		{
			name					: 'Date Available',
			model					: 'availableDate',
			type					: 'date',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Release Date',
			model					: 'releaseDate',
			type					: 'date',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Last Updated',
			model					: 'lastUpdatedDate',
			type					: 'date',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Topics',
			model					: 'topics',
			type					: 'catalogTopics',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Languages',
			model					: 'languages',
			type					: 'catalogLanguages',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Updates',
			model					: 'updates',
			type					: 'catalogUpdates',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Modules',
			model					: 'modules',
			type					: 'catalogModules',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		{
			name					: 'Notes',
			model					: 'notes',
			type					: 'vatalogNotes',
			required				: false,
			visible					: true,
			disableOptionOverride	: true,
		},
		new LioSearchableSelectField({
			name					: 'Display On Training Page',
			model					: 'showOnTrainingPage',
			type					: 'select',
			required				: true,
			visible					: true,
			options					: [],
			disableOptionOverride	: true,
		}),
		new LioSearchableSelectField({
			name					: 'Status',
			model					: 'active',
			type					: 'select',
			required				: true,
			visible					: true,
			options					: [],
			disableOptionOverride	: true,
		}),
	);

	fieldConfig:any = {
		fieldName			: 'catalogSearch',
		addCCFtoFields		: false,
		localizeFields		: true,
		permissionFields	: true,
		addRoleToFields		: false,
		overrideFields		: true,
		addLangField		: false,
		addActiveField		: false,
		allowSpecificUser	: true,
	};

	model:any = {
		name				: '',
		active				: null,
		showOnTrainingPage	: null,
		type				: '',
		cascadeID			: '',
		availableDate		: '',
		releaseDate			: '',
		scriptLocation		: '',
		infoSheetLocation	: '',
		lastUpdatedDate		: '',
		format				: '',
		topics				: [],
		updates				: [],
		modules				: [],
		courseID			: '',
		description			: '',
		pdfDescription		: '',
		languages			: [],
		notes				: [],
		length				: '',
		version 			: '',
		trainingFrequency	: '',
		daysFromHire		: '',
		recommendedAudience	: ''
	};

	sample:any = {
		name				: 'Course Name',
		active				: '1',
		showOnTrainingPage	: '1',
		type				: '1',
		cascadeID			: 45666,
		availableDate		: '12/3/12',
		releaseDate			: '12/3/12',
		scriptLocation		: 'http://google.com',
		infoSheetLocation	: 'http://google.com',
		lastUpdatedDate		: '12/3/12',
		format				: 'My Format',
		topics				: ['18', '19', '20', '21', '22', '24'],
		updates				: [
			{
				text			: 'update 1',
				updateDate		: '12/3/12'
			},
			{
				text			: 'update 2',
				updateDate		: '1/4/13'
			}
		],
		modules: [
			{
				courseID		: 32687674324,
				name			: 'Pizza',
				description		: 'Pizza',
				url				: 'https://www.corpedia.com/course/45666',
				scriptLocation	: 'www.pizza.com'
			},
			{
				courseID		: 3423432,
				name			: 'Pizza 2',
				description		: 'Pizza 2',
				url				: 'https://www.corpedia.com/course/45666',
				scriptLocation	: 'www.pizza.com'
			}
		],
		courseID 			: 12345,
		description 		: 'This is the course description',
		pdfDescription 		: 'This is the course description for the pdf',
		languages			: ['63', '64'],
		notes 				: [
			{note : 'This is a note' },
			{note : 'This is another note' }
		],
		length 				: '45 min',
		version 			: '1.0',
		trainingFrequency 	: '2 years',
		daysFromHire 		: '12/12/15',
		recommendedAudience : 'Managers'
	};
}