import { Injectable } from '@angular/core';

import { LioFieldArray, LioSearchableSelectField } from '../../modules/lio-forms/lio-forms.models';

@Injectable({
	providedIn	: 'root', 
	useClass	: forgotPasswordSettings
})
export class forgotPasswordSettings {

	chooseLangField:LioSearchableSelectField = new LioSearchableSelectField({
		amID			: 'am_forgotpass_chooseLangText',
		name			: 'Please Choose',
		nameTrans 		: 'changepass.pleaseChoose',
		model			: 'selectedLang',
		addEmptyOption	: true,
		optionNameField : 'lang',
		optionAsValue 	: true,
		searchable 		: true
	});

	initialFields:LioFieldArray = new LioFieldArray(
		{
			name: 'Email Address',
			model: 'email',
			nameTrans: 'forgotpass.emailAddress',
			type: 'email',
			required: false,
		},
		{
			name: 'OR',
			model: 'OR',
			nameTrans: 'forgotpass.OR',
			type: 'label',
		},
		{
			name: 'Employee ID',
			model: 'employeeID',
			nameTrans: 'forgotpass.employeeID',
			type: 'text',
			required: false,
			max: 200,
		},
		{
			name: 'Company Name or ID',
			model: 'companyID',
			nameTrans: 'forgotpass.companyID',
			type: 'text',
			required: false,
		},
	);

	fields:LioFieldArray = new LioFieldArray(
		{
			name: 'Password Reset Code',
			model: 'code',
			type: 'text',
			nameTrans: 'forgotpass.code',
			required: true,
		},
		{
			name: 'Password',
			model: 'password',
			type: 'password',
			nameTrans: 'forgotpass.password',
			required: true,
			min: 8,
		},
		{
			name: 'Re-enter Password',
			model: 'password2',
			type: 'password',
			nameTrans: 'forgotpass.password2',
			required: true,
			min: 8,
		},
	);
}