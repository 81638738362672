import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { localizationService } from '../services/localization.service';

@NgModule({
	imports: [
		BrowserAnimationsModule,
		MatSelectModule,
		MatOptionModule,
		MatInputModule,
		MatDatepickerModule,
		MatDialogModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatMenuModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		DragDropModule,
		MatChipsModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatTabsModule,
		MatExpansionModule,
		MatTooltipModule,
		MatButtonToggleModule
	],
	exports: [
		MatSelectModule,
		MatOptionModule,
		MatInputModule,
		MatDatepickerModule,
		MatDialogModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatMenuModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		DragDropModule,
		MatChipsModule,
		MatIconModule,
		MatCardModule,
		MatButtonModule,
		MatTabsModule,
		MatExpansionModule,
		MatTooltipModule,
		MatButtonToggleModule
	],
	providers: [
		{
			provide: localizationService,
			useClass: localizationService
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{
			provide		: MAT_DATE_FORMATS,
			deps 		: [localizationService],
			useFactory 	: (localizationService:localizationService) => {
				return {
					display: {
						dateInput: localizationService.getDateFormat().toUpperCase(),
						monthYearLabel: 'MMM YYYY',
						dateA11yLabel: 'LL',
						monthYearA11yLabel: 'MMMM YYYY'
					},
					parse: {
						dateInput: localizationService.getDateFormat().toUpperCase()
					},
				};
			}
		},
	]
})
export class AngularMaterialModule {}