<div class="row">
	<div class="col-md-10 offset-md-1">
		<lio-card headerID="am_user_summary_header" headerText="User Summary">
			<div lio-card-body>
				<form role="form" autocomplete="off" novalidate>
					<div class="list-group">
						<mat-accordion [multi]="true">
							<mat-expansion-panel [expanded]="true">
								<mat-expansion-panel-header>
									<mat-panel-title class="font-weight-bold">Employee Information</mat-panel-title>
								</mat-expansion-panel-header>
								<lio-form-inputs
									[fields]="fields"
									[fieldConfig]="employeeFieldConfig"
									[model]="employee"
									(onChanged)="onupdate()">
								</lio-form-inputs>
							</mat-expansion-panel>
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title class="font-weight-bold">Login Information</mat-panel-title>
								</mat-expansion-panel-header>
								<lio-form-inputs
									[fields]="loginFields"
									[fieldConfig]="loginFieldConfig"
									[model]="employee"
									(onChanged)="onupdate()">
								</lio-form-inputs>
							</mat-expansion-panel>
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title class="font-weight-bold">Custom Field Information</mat-panel-title>
								</mat-expansion-panel-header>
								<lio-form-inputs
									[fields]="ccfFields"
									[fieldConfig]="ccfFieldConfig"
									[model]="employee"
									(onChanged)="onupdate()">
								</lio-form-inputs>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</form>
			</div>
			<div lio-card-footer>
				<button mat-flat-button color="primary" class="ml-1" 
					[hidden]="!permissionService.hasPermission('employee.update')" 
					(click)="tryGotoProfile()">

					<span>Edit Profile</span>
					<mat-icon class="mat-button-icon ml-1">edit</mat-icon>
				</button>
				<button mat-flat-button color="primary" class="ml-1" 
					[hidden]="!permissionService.hasPermission('employee.resetPassword') && allowResetPassword" 
					(click)="resetPassword()">
					
					<span>Reset Password</span>
				</button>
			</div>
		</lio-card>

		<lio-card bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
			<div lio-card-header>
				<span *ngIf="editable">
					<span class="text-danger">*</span>
					<span>Required Fields</span>
				</span>
				<div *ngIf="!editable">
					<button mat-flat-button color="primary" (click)="enableCourseEnrollments()">Course Enrollments</button>
					<button mat-flat-button color="primary" class="ml-1" (click)="enableLoginHistory()">User Login History</button>
					<button mat-flat-button color="primary" class="ml-1" (click)="enableCourseHistory()">Course History</button>
					<button mat-flat-button color="primary" class="ml-1" [hidden]="true">Email History</button>
					<button mat-flat-button color="primary" class="ml-1" 
						[disabled]="!currentLog.logs || !currentLog.logs.length" 
						(click)="exportRecords()" [hidden]="!currentLog.class || !permissionService.hasPermission('reports.read')">
						
						<span>Export Records</span>
						<mat-icon class="mat-button-icon ml-1">file_download</mat-icon>
					</button>
				</div>
			</div>
			<div lio-card-body *ngIf="!editable">
				<!-- Current Logs -->
				<div [hidden]="currentLog.class != 'enrollments'" 
					lio-paginator 
					controlID="enrollments" 
					[collection]="enrollments" 
					[settings]="enrollmentPagination" 
					(onResults)="updateEnrollments">
					<lio-results-table
						paginatorID="enrollments"
						[fields]="enrollmentFields"
						[results]="filteredEnrollments">
					</lio-results-table>
				</div>
				<div [hidden]="currentLog.class != 'loginHistory'" 
					lio-paginator 
					controlID="loginHistory" 
					[collection]="loginHistory" 
					[settings]="loginHistoryPagination" 
					(onResults)="updateLoginHistory">
					<lio-results-table
						paginatorID="loginHistory"
						[fields]="loginHistoryFields"
						[results]="filteredLoginHistory">
					</lio-results-table>
				</div>
				<div [hidden]="currentLog.class != 'courseHistory'" 
					lio-paginator 
					controlID="courseHistory" 
					[collection]="courseHistory" 
					[settings]="courseHistoryPagination" 
					(onResults)="updateCourseHistory">
					<lio-results-table
						paginatorID="courseHistory"
						[fields]="courseHistoryFields"
						[results]="filteredCourseHistory">
					</lio-results-table>
				</div>
			</div>
			<div lio-card-footer *ngIf="!editable">
				<lio-paginator-controls class="row" [hidden]="currentLog.class != 'enrollments'" controlID="enrollments"></lio-paginator-controls>
				<lio-paginator-controls class="row" [hidden]="currentLog.class != 'loginHistory'" controlID="loginHistory"></lio-paginator-controls>
				<lio-paginator-controls class="row" [hidden]="currentLog.class != 'courseHistory'" controlID="courseHistory"></lio-paginator-controls>
			</div>
		</lio-card>
	</div>
</div>