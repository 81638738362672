<div class="row" >
	<div class="col-md-12">
		<!-- Buttons -->
		<lio-card bodyClass="text-center">
			<div lio-card-header>
				<button mat-flat-button color="primary" type="button" (click)="show.instructions = !show.instructions">
					<span [hidden]="show.instructions">Show Instructions</span>
					<span [hidden]="!show.instructions">Hide Instructions</span>
				</button>

				<button mat-flat-button color="primary" class="ml-2" type="button" (click)="show.loginKey = !show.loginKey">
					<span [hidden]="show.loginKey">Show Login Robot</span>
					<span [hidden]="!show.loginKey">Hide Login Robot</span>
				</button>
				<lio-file-uploader class="ml-2" [matchBootstrap]="false" [settings]="uploadSettings" (success)="fileAdded($event)" (failure)="fileFailed($event)"></lio-file-uploader>
				<button mat-flat-button color="primary" [disabled]="!allowSave" class="ml-2" type="button" (click)="saveCSS()">Save CSS</button>
			</div>
			<!-- Login Robot -->
			<div lio-card-body [hidden]="!show.loginKey" alt="Login">
				<img src="images/assets/LearnIO.png" class="login-robot"/>
			</div>
			<!-- Instructions -->
			<div lio-card-footer>
				<div [hidden]="!show.instructions">
					<span>This is a CSS editor that can override all visual elements of the application.</span>
					<span>As you type in the CSS, the CSS will be applied to your screen. To apply those changes permanently, hit the Save CSS button</span> 
					<br/><br/>
					<span>Here is a sample of CSS you can use to get you started:</span> 
					<div [innerHtml]="sampleCss"></div>
				</div>
				<!-- CSS Editor -->
				<div [hidden]="show.instructions">
					<lio-form-inputs
						[fields]="fields"
						[model]="branding"
						(onChanged)="onupdate()">
					</lio-form-inputs>

					<ngx-monaco-editor class="monaco-editor" [options]="monacoOptions" [(ngModel)]="branding.css"></ngx-monaco-editor>
				</div>
			</div>
		</lio-card>
	</div>
</div>