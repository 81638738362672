export class LioField{
	//automation ID of the field
	public amID					:string;
	//Label for the field
	public name					:string;
	//translation tag for the field label
	public nameTrans			:string;
	//name of the property on the model that this field should edit
	public model				:string;
	//whether this field is required for the form containing it
	//*Some* applications support passing a permission string
	public required				:boolean|string;
	/** whether this field should be visible
	 * boolean 	- simple true or false
	 * string 	- name of the permission that determines visibility
	 * function - function to call to check visibility
	 */
	public visible				:boolean|string|(()=>boolean);
	/** whether this field should be disabled
	 * boolean 	- simple true or false
	 * string 	- name of the permission that determines disabled status
	 */
	public locked				:boolean|string;
	//whether this field should be included in exports
	public export				:boolean;
	// update triggered on key up
	public updateOnKeyUp		:boolean = true;
	// debounce time for delay in events
	public debounceTime			:number = 50;

	//the type of input that should be used for this field
	public type					:string;
	//the validation type or defaults to the type
	public validateAs			:string;

	public customOptions		:string;
	public segregation			:string;
	public loadOptions			:boolean;
	//whether we have found too many results for this field
	public tooManyResults		:boolean;
	//Placeholder value for the label
	public placeholder			:string;
	//translation tag for the placehold
	public placeholderTrans		:string;
	//whether the input in the field fails to meet requirements
	public error 				:boolean;
	//the string of the error
	public errorString 			:string;
	//minimum length of the input for the field
	public min 					:number;
	//maximum length of the input for the field
	public max 					:number;
	//hint text for the field
	public hint 				:string;
	//subModel object if the field contains sub-fields
	public subModel 			:any;
	//whether the options for this field can be overriden
	public disableOptionOverride:boolean;
	//whether this field can be used for sorting
	public sortable				:boolean;
	//whether to invalidate this field if it has special characters
	public checkForSpecialChars	:boolean;
	//custom regex special character check for this field
	public regex				:RegExp;
	//whether to invalidate this field if it has html
	public checkForHTMl			:boolean;
	//whether to invalidate this field if it has html - email specific
	public checkForHTMlInEmail	:boolean;
	//if true, prevents this field from overrwriting hard-coded settings with values from the DB
	public disableOverride		:boolean;
	//if true, invalidates this field if the input contains charcters that are not alphanumeric
	public varchar				:boolean;
	//whether to flip true and false for boolean fields
	public invertModel			:boolean;
	//Display name for the group this field belongs to, only used when grouping fields
	public group				:string;
	//Alias for the db table for this field, used in query builder fields
	public alias				:string;

	//model field for query builder filters
	//TODO - switch to using the 'model' property instead
	//this is temporary as there is no reason to use this instead of model
	public field 				:string;

	/** 
	 * Array of conditions for displaying this field
	 * conditions can be a string indicating a field on the model which should not be null
	 * also can be a key/value pair indicating the field on the model and what value it must have to be shown
	 * TODO- Create a model for these conditions so that they are no longer arbitrary key/value pairs(IE {key:'someKey',value:'someValue',type:'equals'})
	**/
	public conditions			:Array<(string|any)>;

	//Whether this field should be locked if the field is part of a form editing a higher role
	//TODO- decide whether there is a better way to do this
	public blockEditHigherRole	:boolean;

	//whether this field can be hidden with the context menu
	public allowHide			:boolean;

	//whether this field should be accessed from the field editor
	public hideFromFieldEditor	:boolean;

	//Temp fix for field locking based on conditions 
	//TODO- decide if this is how this should be handled
	public lockWithID 			:boolean;

	//html class to use for this field, applied to different elements in different contexts
	public class:string;

	//holds days of the week
	//TODO- this probably shouldn't exist
	public weekDays:Array<any>;

	//function to check whether more than one weekday is enabled
	//TODO- this probably shouldn't exist
	public moreThanOneWeekDayEnabled:()=>boolean;

	//sort order array
	//TODO- determine if this should be here, it is currently only used for one field in the model editor
	public sortOrder:Array<string>;

	//TODO- needed for training courses for now
	public setting					:string;
	
	//TODO- needed for training courses for now
	public permission				:string;
	
	// Added subscription for keyup
	public addedKeyUpSub		:boolean;

	constructor(obj:Partial<LioField>){
		Object.assign(this, obj);
	}
}