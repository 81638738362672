import { Component, OnDestroy, Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { navService } from '../../services/nav.service';
import { utilService } from '../../services/util.service';
import { profileSettings } from '../profile/profile.settings';
import { stateService } from '../../services/state.service';
import { storageService } from '../../services/storage.service';

@Component({
	selector: 'self-profile',
	templateUrl: './selfprofile.component.html'
})
export class SelfProfileComponent implements OnDestroy {
	
	public panelConfigs		:any 		= this.utilService.copy(this.profileSettings.panelConfigs);
	public fields				:Array<any>		= [];
	public fieldConfig			:any			= null;
	

	private subscriptions:Subscription = NEVER.subscribe();

	constructor(
		@Inject(loaderService) 			public loaderService		:loaderService,
		@Inject(navService) 			public navService			:navService,
		@Inject(stateService) 			public stateService			:stateService,
		@Inject(storageService) 		public storageService		:storageService,
		@Inject(utilService) 			public utilService			:utilService,
		@Inject(profileSettings) 		public profileSettings		:profileSettings
	){
		this.navService.setActivePage('selfprofile');
		this.stateService.waitForLoaded.subscribe(() => {
			this.storageService.set('employeeToEdit', this.stateService.getSession());
		})

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);

		this.fieldConfig = this.utilService.copy(this.profileSettings.personalConfig);
		this.fields = this.utilService.copy(this.profileSettings.personalFields);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Initialize
	*/
	init() {
		this.navService.displayPage();
	}

}