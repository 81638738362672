<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="col-md-12 mx-auto">
		<!-- Forgot form -->
		<form role="form" autocomplete="off" novalidate>
			<lio-form-inputs
				[fields]="fields"
				[fieldConfig]="config"
				[model]="employee"
				(onChanged)="onupdate()">
			</lio-form-inputs>
			<div class="btn-toolbar">
				<span [hidden]="!permissionService.hasPermission(updateProfilePermission)">
					<button id="am_profile_update" 
						mat-flat-button
						color="primary"
						[disabled]="!allowSubmit || viewOnly" 
						(click)="submit()" 
						class="mr-1 mb-1" 
						type="submit">
						
						<strong>{{localeStrings.update}}</strong>
					</button>
				</span>
				<button id="am_profile_assign" 
					mat-flat-button
					color="primary"
					[disabled]="coursesService.noCoursesAssignedToCompany" 
					[hidden]="!allowAssign || !permissionService.hasPermission('enrollment.enroll')" 
					(click)="assign()" 
					class="mr-1 mb-1" 
					type="submit">

					<strong>{{localeStrings.title}}</strong>
				</button>
				<button id="am_profile_resetPassword" 
					mat-flat-button
					color="primary"
					[hidden]="!allowResetPassword || !permissionService.hasPermission('employee.resetPassword') || !allowAssign" 
					(click)="submitForgotPass()" 
					class="mr-1 mb-1" 
					type="submit">

					<strong>{{localeStrings.resetPass}}</strong>
				</button>
				<button id="am_profile_setPassword" 
					mat-flat-button
					color="primary"
					[hidden]="!allowResetPassword || !permissionService.hasPermission('employee.setPassword') || !allowAssign" 
					(click)="showSetPasswordModal()" 
					class="mr-1 mb-1" 
					type="submit">

					<strong>{{localeStrings.setPass}}</strong>
				</button>
				<button id="am_profile_emailUser" 
						*ngIf="!self"
						mat-flat-button
						color="primary"
						[hidden]="!permissionService.hasPermission('email.sendToUsers') || !permissionService.hasPermission('pages.email')" 
						(click)="emailUser()" 
						class="mr-1 mb-1" 
						type="submit">
						<strong>{{localeStrings.emailuser}}</strong>
					</button>
				<button id="am_profile_editCCFAdmin" 
					mat-flat-button
					color="primary"
					[hidden]="!permissionService.hasPermission(updateCCFPermission)" 
					(click)="editCCFAdmin()" 
					class="mr-1 mb-1" 
					type="submit">

					<strong>{{localeStrings.editAdminRights}}</strong>
				</button>

				<button id="am_profile_proxyasuser"
					*ngIf="!self"
					mat-flat-button
					color="primary"
					[hidden]="!permissionService.hasPermission('employee.proxyAsUser')" 
					(click)="loginAsUser()" 
					class="mr-1 mb-1" 
					type="submit">

					<strong>{{localeStrings.loginAsUser}}</strong>
				</button>
			</div>
		</form>
	</div>
</div>