import { Injectable } from '@angular/core';

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Injectable({
	providedIn	: 'root', 
	useClass	: disclosureDirectiveSettings
})
export class disclosureDirectiveSettings {
	editorConfig: AngularEditorConfig = {
		editable					: true,
		spellcheck					: true,
		height						: 'auto',
		minHeight					: '300px',
		maxHeight					: 'auto',
		width						: 'auto',
		minWidth					: '0',
		enableToolbar				: true,
		showToolbar					: true,
		defaultParagraphSeparator	: 'p',
		sanitize					: false,
		toolbarPosition				: 'top',
	}
}