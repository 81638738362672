
<div class="text-center mb-2" *ngIf="settings.mode == 'read' || settings.mode == 'review'" >
	<span [innerHtml]="model.text | html" (click)="interceptLink($event)"></span>
</div>

<angular-editor *ngIf="settings.mode == 'edit'"
	#textDisplay
	[id]="'am_form_text_editor_' + randID"
	[ngClass]="{'hide-text-area':editAsCode}"
	[config]="editorConfig"
	[(ngModel)]="model.text"
	(viewMode)="editModeChange($event)">
</angular-editor>
<ngx-monaco-editor *ngIf="editAsCode" class="monaco-editor" [options]="monacoOptions" [(ngModel)]="model.text"></ngx-monaco-editor>