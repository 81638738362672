import { Component, ViewChild, Input, Inject, ElementRef } from '@angular/core';

import { jsPDF } from "jspdf";

import { navService } from '../../../../services/nav.service';
import { debugService } from '../../../../services/debug.service';
import { coursesService } from '../../../../services/courses.service';
import { processingService } from '../../../../services/processing.service';
import { storageService } from '../../../../services/storage.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { errorsService } from '../../../../services/errors.service';
import { fieldService } from '../../../../services/fields.service';
import { workerService } from '../../../../services/worker.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { filesService } from '../../../../services/files.service';
import { lioLogService } from '../../../../services/lio-log.service';
import { permissionService } from '../../../../services/permissions.service';
import { pdfService } from '../../../../services/pdf.service';
import { formValidatorService } from '../../../../services/form-validator.service';

import { localizationService } from '../../../../services/localization.service';
import { enrollmentSettings } from '../../../../components/enrollments/enrollments.settings';

@Component({
  selector: 'lio-enrollments',
  templateUrl: './enrollments.component.html'
})
export class LioEnrollments {
	@ViewChild('pdfTarget') pdfTarget: ElementRef;
	@Input() allowEditProfile			:boolean 			= true;
	@Input() showUser							:boolean 			= true;


	protected completionDateField:any = this.utilService.copy(this.enrollmentSettings.completionDateField);

	public employees							:Array<any>		= [];
	public employeeIDs						:Array<any>		= [];
	public employeesInCourses			:Array<any>		= [];
	public search									:any 					= {};
	public filteredEmployees			:Array<any>		= [];
	public courses								:Array<any>		= [];
	public selectedEnrollments		:Array<any>		= [];
	public totalCourses						:number				= 0;
	public loaded									:boolean			= false;
	public coursesLoaded					:boolean			= false;
	public employeesLoaded				:boolean			= false;
	public setCourseSearch				:boolean			= false;
	public showAssignBtn					:boolean			= false;
	public searches								:Array<any>		= [];
	public searchConfig						:any					= {};
	public fields									:Array<any>		= [];
	public fieldConfig						:any					= {};
	public pagination							:any					= {};
	public filters								:Array<any>		= [];
	public queryToolSettings			:any 					= {};
	public queryTool							:any					= {};
	public registered							:Array<any>		= [];
	public showTestPDF						:boolean			= false;
	public chunking								:boolean			= false;
	public warnNumber							:number				= 40000;
	public exportingRecords				:boolean 			= false;
	public totalEmployees					:number				= 0;
	public stats									:any					= {};

	// Booleans for functionality
	public disableAssignCourses		:boolean 	= false;
	public supressLoadOnStart			:boolean 	= true;
	public toggleStatsMinimum			:number 	= 15;
	public exportWithWorker				:boolean 	= true;

	// Viewable States
	public showFilters						:boolean	= true;
	public showDashboard					:boolean	= false;
	public showStats							:boolean	= true;
	public showTitle							:boolean	= true;

	// Disabled States
	public canOpenDashboard				:boolean 	= false;
	public canOpenFilters					:boolean	= true;
	public canCloseDashboard			:boolean	= false;
	public canCloseFilters				:boolean	= false;
	public confirmed							:boolean	= false;
	public allowSendEmail					:boolean	= false;
	public statsLoaded						:boolean	= false;
	public collection							:Array<any>	= [];
	public prefixButtons					:Array<any>	= [];
	public employee								:any		= null;
	public permissions						:any		= null;
	public localizations					:any		= {};
	public localizationItems			:Array<any>	= [];
	private showExpired 					:boolean = true;
  constructor(
		@Inject(navService)								public 	navService			:navService,
		@Inject(debugService)							public 	debugService		:debugService,
		@Inject(coursesService)						public 	coursesService		:coursesService,
		@Inject(processingService) 				public 	processingService	:processingService,
		@Inject(storageService)						public 	storageService		:storageService,
		@Inject(feedbackService)					public 	feedbackService		:feedbackService,
		@Inject(utilService)							public 	utilService			:utilService,
		@Inject(errorsService)						public 	errorsService		:errorsService,
		@Inject(fieldService)							public 	fieldService		:fieldService,
		@Inject(lmsService)								public 	lmsService			:lmsService,
		@Inject(lioLogService)						public 	lioLogService		:lioLogService,
		@Inject(stateService)							public 	stateService		:stateService,
		@Inject(workerService)						public 	workerService		:workerService,
		@Inject(filesService)							public 	filesService		:filesService,
		@Inject(permissionService)				public 	permissionService	:permissionService,
		@Inject(lioModalService)					public 	lioModalService		:lioModalService,
		@Inject(pdfService)								public 	pdfService			:pdfService,
		@Inject(localizationService)			public 	localizationService			:localizationService,
		@Inject(enrollmentSettings)				public 	enrollmentSettings	:enrollmentSettings,
		@Inject(formValidatorService)				public 	formValidatorService	:formValidatorService
	){
		this.debugService.register('enrollments', this);

		// Global Vars

		this.searches 					= this.enrollmentSettings.searches;
		this.searchConfig				= this.enrollmentSettings.searchConfig;
		this.fields 						= this.enrollmentSettings.fields;
		this.fieldConfig 				= this.enrollmentSettings.fieldConfig;
		this.pagination 				= this.enrollmentSettings.pagination;
		this.filters 						= this.enrollmentSettings.filters;
		this.queryToolSettings 	= this.enrollmentSettings.queryToolSettings;
		this.localizations 			= this.enrollmentSettings.localizations;
		this.localizationItems 	= Object.keys(this.localizations);
		this.showTestPDF 				= this.storageService.get('qa');
		this.permissions 				= this.permissionService;

		this.stateService.waitForLoaded.subscribe(() => {
			this.employee = this.storageService.get('employeeToEdit');
			if (!this.employee) {
				this.navService.goto('home');
				return;
			}
			this.init();
		});
	}

	/**
	 * Recieves information from paginator
	 */
	updateFilteredEmployees(paginatorResults) {
		this.collection = paginatorResults.collection;
		this.filteredEmployees = paginatorResults.filtered;
	}

	/*
	 * Initialize
	*/
	init() {
		this.loadEmployee().then(() => {
			this.update();
			this.employeesLoaded = true;
			this.navService.displayPage();
		});
	}

	/*
	 * Exports the employee records
	*/
	exportRecords() {
		let employee = this.employee,
			courses = this.utilService.copy(this.courses),
			value,
			i;

		this.lioModalService.showLoading('processing');

		for (i in employee) {
			value = employee[i];
			courses.forEach((course) => {
				course[i] = value;
			});
		}

		courses.forEach((course) => {
			delete course['courses'];
			delete course['role'];
			course['role'] = this.permissionService.getRoleNameFromID(course.permissionID); 
		});

		this.workerService.export(courses, this.fields, 'Enrollments').then((result) => {
			if (!result) {
				this.feedbackService.setError('failedToExportRecords');
			}
			this.lioModalService.hideLoading();
		});
	}

	/* Export PDF */
	exportPDF() {
		this.pdfService.render(this.pdfTarget).subscribe((pdf:jsPDF) => {			
			pdf.save(this.getTitle() + '.pdf');
		});
	}

	/*
	 * Gets the title for the pdf report
	*/
	getTitle() {
			return 'enrollments_' + this.utilService.randomNumber();
	}

	/*
	 * On Click of the assign Courses button
	*/
	assignCourses() {
		let employees = [this.employee];
		this.storageService.setEmployeesToAssign(employees);
		this.navService.goto('assign');
	}

	/*
	 * Updates the pie charts
	*/
	update() {
		let courses = [],
			employee = this.employee,
			employeeCourses,
			status;

		if (!employee) {
			return;
		}

		// Loop through each employee
		employeeCourses = employee['courses'];
		status = employee.inactive == 1 ? 'Inactive' : 'Active';

		if (!employeeCourses || !employeeCourses.length) {
			employeeCourses = [];
		}

		// Loop through each employee's courses
		employeeCourses.forEach((employeeCourse) => {
			// Employee has a direct reference to the courses array,
			// we need to make a copy and remove it otherwise we have a circular reference
			let tempEmployee = this.utilService.copy(employee);
			delete tempEmployee.courses;
			
			// Fill in the data
			employeeCourse = this.utilService.extend(employeeCourse, tempEmployee);
			employeeCourse['total'] = 1;
			employeeCourse['status'] = status;
			employeeCourse['completed'] = 0;
			employeeCourse['selected'] = false;


			// Remove due date if not required
			if (employeeCourse['required'] != 1) {
				employeeCourse['courseDueDate'] = null;
			}

			if (employeeCourse.courseCompletion == '1') {
				employeeCourse['completed']++;
				// Ensure the mark to complete button for this enrollment is disabled
				employeeCourse.disabled = true;
			} else {
				employeeCourse.disabled = false;
			}
			courses.push(employeeCourse);
		});	


		this.totalEmployees = 1;
		this.totalCourses = courses.length;
		this.courses = courses;
		
		this.coursesLoaded = true;
	}

	/*
	 * On update of the search fields
	*/
	onUpdate() {
		setTimeout(() => {
			this.update();
		}, 500);
	}

	/*
	 * Edit's an employee
	*/
	editProfile() {
		this.storageService.set('employeeToEdit', this.employee);
		this.storageService.set('task', 'external');
		this.navService.goto('profile');
	}

	/*
	 * Views enrollments
    */
	loadEmployee() {
		let form = {'employeeID': this.employee.employeeID, 'showExpired': this.showExpired};

		return this.lmsService.post('dashboard/getEmployeeCoursesByID', form).then((result) => {
			let employee = result.properties.employee,
				stats = result.properties.stats;

			if (!employee) {
				return;
			}


			employee.courses.forEach((course) => {
				if (course.courseCompletion) {
					course.disabledForCourseCompletion = true;
				}
			});

			this.employee = employee;
			this.stats = stats;
			this.statsLoaded = true;
			this.showAssignBtn = employee.inactive == 0;
		});
	}

	/*
	* On Field Click from the results_table
	*/
	onFieldClick(event) {
		if (event.field.model === 'selectedForCourseCompletion') {
			setTimeout(() => {
				this.selectForCourseCompletion(event.item);
			});
		}
	}

	/*
	* (De)Selects the enrollment
	*/
	selectForCourseCompletion(enrollment) {
		this.employee.courses.forEach((course) => {
			if (enrollment.courseID == course.courseID) {
				if (enrollment.selectedForCourseCompletion) {
					this.selectedEnrollments.push(enrollment.employeeInCourseID);
				} else {
					let index = this.selectedEnrollments.indexOf(enrollment.employeeInCourseID);
					if (index > -1) {
						this.selectedEnrollments.splice(index, 1);
					}
				}
			}
		});
	}

	/**
	 * Show the set password lioModal
	*/
	showMarkToCompleteModal() {
		this.lioModalService.open({
			model		: 'value',
			type		:'input', 
			title		: 'Enter a Completion Date', 
			titleTrans	: 'Enter a Completion Date', 
			date		: true, 
			submit		: 'Mark as Complete',
		}).then((date) => {
			if (date) {
				this.markToComplete(date);
			}
		});
	}

	/*
	* Marks selected courses to complete
	*/
	markToComplete(date) {
		if (!this.formValidatorService.isValid({'completionDate': date}, [this.completionDateField])) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors());
			return;
		}

		this.lioModalService.showLoading('processing');
		this.lmsService.post('employeeCourses/completeCourses',{
			employeeInCourseIDs	: this.selectedEnrollments,
			completionDate		: date
		}).then((result) => {
			if (!result.success) {
				if (result.errors) {
					this.feedbackService.setErrors(result.errors);
				} else {
					this.feedbackService.setError('failedTtoMarkUsersToComplete');
				}
				this.lioModalService.hideLoading();
			} else {
				this.loadEmployee().then(() => {
					this.lioModalService.hideLoading();
					this.update();
				});
			}
		});
	}
}