<section class="catalogPDF p-5" #pdfTarget localize [model]="localeStrings" [items]="[]">
	<!-- Cover Page -->
	<section id="p1" class="print-section page-break">
		<div id="tol-logo" class="mb-3">
			<img alt="TOL Catalog Logo" style="background-size: 10px !important;width: 200px;" src="images/catalog/TOL-Logo.yellow.png" />
		</div>
		<img alt="Scholar Library Logo" class="w-100" src="images/catalog/Scholar_Library.png" />
		<h3 class="text-right m-2 pt-4" [innerHtml]="localeStrings.coverTitle"></h3>
	</section>
	<!-- Cover Page -->
	<!-- Explanation -->
	<section id="explanation" *ngFor="let courseType of courseTypes" [id]="courseType.name">
		<div *ngIf="courseType.used && courseType.pdfExplanation" class="print-section page-break">
			<h4 class="pb-3">{{courseType.name}}</h4>
			<img alt="Course type logo" class="mb-1 w-100" [src]="courseType.imageURL"/>
			<span [innerHtml]="courseType.pdfExplanation | html"></span>
		</div>
	</section>
	<!-- Explanation -->
	<!-- TOC -->
	<div id="toc" *ngFor="let combinedTOC of combinedTOCs">
		<div *ngIf="combinedTOCs.length">
			<section id="t1" class="print-section page-break">
				<section>
					<h4><span>Course Type: </span>{{combinedTOC.courseType}}</h4>
					<h5 class="font-weight-bold">{{localeStrings.tocHeader}}</h5>
					<table class="small w-100">
						<col width="60">
						<col width="80">
						<col width="400">
						<col width="150">
						<thead class="bg-light">
							<tr>
								<th>{{localeStrings.page}}</th>
								<th>{{localeStrings.courseID}}</th>
								<th>{{localeStrings.courseName}}</th>
								<th>{{localeStrings.courseType}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let catalog of combinedTOC.catalogs">
								<td>{{catalog.pageNumber}}</td>
								<td>{{catalog.courseID}}</td>
								<td nowrap style="text-align:left; max-width: 490px;">{{catalog.name | limit:90}}
								</td>
								<td>{{catalog.type}}</td>
							</tr>
						</tbody>
						</table>
				</section><!-- Header -->
			</section>
		</div>
	</div>
	<!-- TOC -->
	<!-- PDF -->
	<div *ngFor="let cataloggroup of combinedCatalogs">
		<section *ngFor="let catalogs of cataloggroup.pages">
			<div *ngFor="let catalog of catalogs" class="print-section page-break">
				<section>
					<!-- COURSE NUMBER -->
					<div class="float-right bg-dark-blue text-white small px-3 py-1" *ngIf="catalog.courseID">
						<span class="mr-1">{{catalog.pageNumber}}</span>
						<span>#{{catalog.courseID}}</span>
					</div>
					<!-- COURSE NAME -->
					<h4 class="font-weight-bold">{{catalog.name}}</h4>
					<div class="small font-weight-bold">
						<span class="mr-1">{{localeStrings.type}}</span>
						<span>{{catalog.type}}</span>
					</div>
					<div class="row">
						<!-- DESCRIPTION -->
						<div class="col-6">
							<h4 class="sub-head-text">{{localeStrings.courseDescription}}</h4>
							<!-- Shows the course description if pdf description is empty -->
							<div *ngIf="catalog.pdfDescription">
								<h4 class="body-copy" [innerHtml]="catalog.pdfDescription | html"></h4>
							</div>
							<div *ngIf="!catalog.pdfDescription">
								<h4 class="body-copy" [innerHtml]="catalog.description | html"></h4>
							</div>
						</div>
						<div class="col-6" *ngIf="catalog.length">
							<!-- LENGTH -->
							<div class="left">
								<h4 class="sub-head-text">{{localeStrings.length}}</h4>
								<h4 class="body-copy">{{catalog.length}}</h4>
							</div>
							<!-- TOPICS -->
							<div class="topics" *ngIf="catalog.topics.length">
								<h4 class="sub-head-text">{{localeStrings.topicsCovered}}</h4>
								<ul class="max-size-150 row">
									<li class="col-6 font-weight-bold h7" *ngFor="let topic of catalog.featuredTopics | orderBy:'name':false" [innerHtml]="topic.name | html"></li>
									<li class="col-6 h7" *ngFor="let topic of catalog.genericTopics | orderBy:'name':false" [innerHtml]="topic.name | html"></li>
								</ul>
							</div>
							<!-- LANGUAGES -->
							<div *ngIf="catalog.langs && catalog.langs.length" id="languages" style="clear: both">
								<h4 class="sub-head-text">{{localeStrings.availableLangs}}</h4>
								<ul class="row small">
									<li class="col-6" *ngFor="let language of catalog.langs | orderBy:'name'">{{language.name}}</li>
								</ul>
							</div>
							<div *ngIf="catalog.format">
								<h4>{{localeStrings.format}}</h4>
								<h4>{{catalog.format}}</h4>
							</div>				
						</div>
					</div><!-- CourseInfo -->
				</section>
			</div>
		</section>
	</div>
	<!-- PDF -->
	<!-- Footer Page -->
	<section>
		<section [innerHtml]="localeStrings.footerTop | html"></section>
		<img alt="Catalog product" style="width: 100%" src="images/catalog/Products.png"/>
		<div class="mt-5 pt-5">
			<section [innerHtml]="localeStrings.footerBottom | html"></section>
	
			<hr class="bg-warning"/>
	
			<div class="row small">
				<div class="col-4">trueoffice.com</div>
				<div class="col-4">(551) 220-5930</div>
				<div class="col-4">info@trueoffice.com</div>
			</div>
		</div>
	</section>
	<!-- Footer Page -->
</section>