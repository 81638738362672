import { Injectable } from '@angular/core';

@Injectable({
	providedIn	: 'root', 
	useClass	: searchEmployeesSettings
})
export class searchEmployeesSettings {
	segregationOptions:Array<any> = [
		{
			name	: 'employeeSearch.defaultHierarchy', 
			value	: '',
			visible : 'employeeSearch.defaultHierarchy',
			trans 	: 'form.defaultHierarchy'
		},
		{
			name	: 'employeeSearch.bypassHierarchy', 
			value	: 'bypass',
			visible : 'employeeSearch.bypassHierarchy',
			trans 	: 'form.bypassHierarchy'
		},
		{
			name	: 'employeeSearch.subordinateHierarchy', 
			value	: 'subordinate',
			visible : 'employeeSearch.subordinateHierarchy',
			trans 	: 'form.subordinate'
		}, 
		{
			name	: 'employeeSearch.regionHierarchy', 
			value	: 'region',
			visible : 'employeeSearch.regionHierarchy',
			trans 	: 'form.region'
		},
		{
			name	: 'employeeSearch.subordinateInRegion', 
			value	: 'subordinateInRegion',
			visible : 'employeeSearch.subordinateInRegion',
			trans 	: 'form.subordinateInRegion'
		},
	];
}