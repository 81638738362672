<div localize [model]="localizations" [items]="localizationItems">
	<div #pdfTarget>
		<div *ngIf="showUser" class="row">
			<!-- Current User -->
			<div class="col mb-2" id="am_stats_current_user">
				<lio-card [hideErrors]="true" [showClose]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_usercount_title">{{localizations.viewingUser}}</div>
					</div>
					<div lio-card-body bodyClass="container-fluid py-2">
						<div class="font-weight-bold" id="am_stats_usercount_percent">{{employee.firstName}} {{employee.lastName}}</div>
					</div>
				</lio-card>
			</div>
		</div>

		<div class="row" [hidden]="!statsLoaded">
			<!-- Total Courses -->
			<div class="col-6 col-md-3 mb-2" id="am_stats_coursecount_panel">
				<lio-card [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_coursecount_title">{{localizations.totalCourses}}</div>
					</div>
					<div lio-card-body bodyClass="container-fluid py-2">
						<div class="h4 mb-0" id="am_stats_coursecount_total">{{stats.totalCourses}}</div>
						<div class="font-weight-bold" id="am_stats_coursecount_percent">{{(100*stats.totalStarted/stats.totalEnrollments) | number:'1.0-0'}}%</div>
						<div class="small" id="am_stats_coursecount_text">{{localizations.started}}</div>
					</div>
				</lio-card>
			</div>

			<!-- Total Enrollments -->
			<div class="col-6 col-md-3 mb-2" id="am_stats_enrollcount_panel">
				<lio-card [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_enrollcount_title">{{localizations.totalEnrollments}}</div>
					</div>
					<div lio-card-body bodyClass="container-fluid py-2">
						<div class="h4 mb-0" id="am_stats_enrollcount_total">{{stats.totalEnrollments}}</div>
						<div class="font-weight-bold" id="am_stats_enrollcount_percent">{{stats.percentCompletion}}%</div>
						<div class="small" id="am_stats_enrollcount_text">{{localizations.completed}}</div>
					</div>
				</lio-card>
			</div>

			<!-- Total Logins -->
			<div class="col-6 col-md-3 mb-2" id="am_stats_logincount_panel">
				<lio-card [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_logincount_title">{{localizations.totalLogins}}</div>
					</div>
					<div lio-card-body bodyClass="container-fluid py-2">
						<div class="h4 mb-0" id="am_stats_logincount_total">{{stats.totalLogins}}</div>
						<div class="font-weight-bold" id="am_stats_logincount_active">{{stats.totalActiveLogins}}</div>
						<div class="small" id="am_stats_logincount_text">{{localizations.totalActiveLogins}}</div>
					</div>
				</lio-card>
			</div>
			
			<!-- Completion History -->
			<div class="col-12 mb-2" id="am_stats_completion_panel">
				<lio-card [hideErrors]="true">
					<div lio-card-header>
						<div class="h6 mb-0" id="am_stats_completion_title">{{localizations.completionHistory}}</div>
					</div>
					<div lio-card-body>
						<div class="row">
							<div class="col-3" id="am_stats_completion_thisweek_panel">
								<div class="small" id="am_stats_completion_thisweek_title">{{localizations.thisWeek}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_thisweek_total">{{stats.completedThisWeek}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_lastweek_panel">
								<div class="small" id="am_stats_completion_lastweek_title">{{localizations.lastWeek}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_lastweek_total">{{stats.completedLastWeek}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_thismonth_panel">
								<div class="small" id="am_stats_completion_thismonth_title">{{localizations.thisMonth}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_thismonth_total">{{stats.completedThisMonth}}</div>
							</div>
							<div class="col-3" id="am_stats_completion_lastmonth_panel">
								<div class="small" id="am_stats_completion_lastmonth_title">{{localizations.lastMonth}}</div>
								<div class="h4 mb-0 text-primary" id="am_stats_completion_lastmonth_total">{{stats.completedLastMonth}}</div>
							</div>
						</div>
					</div>
				</lio-card>
			</div>
		</div>

		<!-- Pie Charts -->
		<div class="row" [hidden]="!coursesLoaded || !showStats">
			<div class="col-md-4 col-lg-3 mb-2 print-section" *ngFor="let course of courses; index as i">
				<lio-card bodyClass="p-3" cardClass="h-100" id="{{'am_stats_pie_panel_' + course.courseID}}" [hideErrors]="true">
					<div lio-card-header [style.minHeight.em]="3.5">
						<div class="small mb-0" id="{{'am_stats_pie_title_' + course.courseID}}">{{course.courseID}} - {{course.courseName | limit:50}}</div>
					</div>
					<div lio-card-body>
						<lio-pie-chart id="{{course.courseID}}" [data]="[course.completed, course.total]"></lio-pie-chart>
					</div>
				</lio-card>
			</div>
		</div>
	</div>

	<!-- Results Table -->
	<div [hidden]="!coursesLoaded || !showStats">
		<lio-card headerClass="pb-0" bodyClass="p-0" footerClass="container-fluid" [hideErrors]="true">
			<div lio-card-header>
				<lio-result-filters
					(update)="onUpdate()"
					[searchConfig]="searchConfig"
					[search]="search"
					[searchFields]="searches">
				</lio-result-filters>
			</div>
			<div lio-card-body>
			<div lio-paginator controlID="enrollment" [collection]="courses" [filters]="search" [settings]="pagination" (onResults)="updateFilteredEmployees($event)">
				<lio-results-table
					paginatorID="enrollment"
					[fields]="fields"
					[results]="filteredEmployees"
					[fieldConfig]="fieldConfig"
					[prefixButtons]="prefixButtons"
					(fieldClick)="onFieldClick($event)">
				</lio-results-table>
			</div>
		</div>
		<div lio-card-footer>
			<lio-paginator-controls class="row" controlID="enrollment"></lio-paginator-controls>
			<div class="btn-toolbar">
					<!-- Buttons -->
					<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_enrollmentsearch_markcomplete" *ngIf="permissions.hasPermission('employeeCourses.complete')" [disabled]="!selectedEnrollments.length" (click)="showMarkToCompleteModal()"><strong>{{localizations.markToComplete}}</strong></button>
					<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_enrollmentsearch_assignCourses" [hidden]="!showAssignBtn || !permissions.hasPermission('enrollment.enroll')" (click)="assignCourses()"><strong>{{localizations.assignCourses}}</strong><i class="fa fa-graduation-cap ml-1" aria-hidden="true"></i></button>
					<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_enrollmentsearch_exportrecords" [disabled]="!courses.length" [hidden]="!permissions.hasPermission('employeeCourses.export')" (click)="exportRecords()"><strong>{{localizations.exportRecords}}</strong><i class="fa fa-file-excel ml-1" aria-hidden="true"></i></button>
					<button class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_employeesearch_exportpdf" [hidden]="!permissionService.hasPermission('dashboard.export')" [disabled]="!filteredEmployees.length" (click)="exportPDF()">{{localizations.exportPDF}}<i class="ml-1 fa fa-file-pdf ml-1" aria-hidden="true"></i></button>
					<button *ngIf="allowEditProfile" class="btn btn-sm btn-primary mr-1 mb-1" type="button" id="am_enrollmentsearch_editprofile" (click)="editProfile()" [hidden]="!permissions.hasPermission('employee.update')" ><strong>{{localizations.editProfile}}</strong><i class="fa fa-user ml-1" aria-hidden="true"></i></button>
				</div>
			</div>
		</lio-card>
	</div>
</div>