import { Component, Inject, Input, EventEmitter, Output } from '@angular/core';

import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../../services/localization.service';

import { LioSearchableSelectField } from '../../../lio-forms.models';

@Component({
	selector: 'lio-event-conditions',
	templateUrl: './conditions.component.html',
})
export class LioEventConditions {
	private _model:any = {};
	public get model(){
		return this._model;
	}
	@Input() public set model(val:any){
		if(val.conditions && val.conditions.length){
			val.conditions.forEach((condition) => {
				if(typeof condition.enrolled != 'undefined'){
					condition.enrolled = parseInt(condition.enrolled);
				}
				if(typeof condition.completed != 'undefined'){
					condition.completed = parseInt(condition.completed);
				}
			});
		}

		this._model = val;
	}

	@Input() formdata:any;
	@Input() appearance:MatFormFieldAppearance = 'outline';

	@Output() onUpdate:EventEmitter<any> = new EventEmitter();

	private _field:any = {};
	public get field(){
		return this._field;
	}
	@Input() public set field(val:any){
		this._field = val;
		this.courseIDField.options 			= this.field.options;
		this.courseIDField.nameFormatter 	= this.field.nameFormatter;
	}

	public connectionField:LioSearchableSelectField = new LioSearchableSelectField({
		name	: 'Logic',
		model	: 'connection',
		options	: [
			{
				name: 'And',
				nameTrans: 'formConditions.and',
				value: 'AND'
			},
			{
				name: 'Or',
				nameTrans: 'formConditions.or',
				value: 'OR'
			}
		]
	});

	public courseIDField:LioSearchableSelectField = new LioSearchableSelectField({
		name			: 'Course ID',
		model			: 'courseID',
		optionNameField : 'courseName',
		optionValueField: 'courseID',
		options			: [],
		searchable		: true,
		nameFormatter 	: null
	});

	public enrolledField:LioSearchableSelectField = new LioSearchableSelectField({
		name	: 'User Enrolled In Course',
		model	: 'enrolled',
		options	: [
			{
				name: 'True',
				nameTrans: 'formConditions.true',
				value: 1
			},
			{
				name: 'False',
				nameTrans: 'formConditions.false',
				value: 0
			}
		]
	});

	public completedField:LioSearchableSelectField = new LioSearchableSelectField({
		name	: 'User Completed Course',
		model	: 'completed',
		options	: [
			{
				name: 'True',
				nameTrans: 'formConditions.true',
				value: 2
			},
			{
				name: 'False',
				nameTrans: 'formConditions.false',
				value: 1
			},
			{
				name: 'Either',
				nameTrans: 'formConditions.either',
				value: 3
			}
		]
	});

	public formConditions = {
		true		: 'True',
		trueTrans	: 'form.true',
		false		: 'False',
		falseTrans	: 'form.false',
		either		: 'Either',
		eitherTrans	: 'form.either',
		or			: 'Or',
		orTrans		: 'form.or',
		and			: 'And',
		andTrans	: 'form.and'
	};

	addCondition() {
		this.model.conditions.push({
			'courseID': null,
			'connection': 'AND',
			'enrolled': false,
			'completed': false,
		});

		this.fixConditionLogic();
	}

	/*
	 * Fixes the logic
	*/
	fixConditionLogic() {
		let courseID = this.model.rule.courseID;
	
		this.model.conditions.forEach((condition, index) => {
			if (index == 0) {
				// Ensure the first condition matches the current course ID
				condition.courseID = courseID;
				return;
			}

			// Ensure the first condition is an AND Operator
			if (index == 1) {
				condition.connection = 'AND';
			}
		});
	}

	/*
	 * Deletes a condition
	*/
	deleteCondition(condition) {
		let index = this.model.conditions.indexOf(condition); 
		if (index) {
			this.model.conditions.splice(index, 1);
		}

		this.fixConditionLogic();		
	}

	constructor(
		@Inject(localizationService) public localizationService :localizationService
	){}
}