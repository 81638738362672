import { Inject, Injectable } from '@angular/core';
import { LioFieldArray } from '../../modules/lio-forms/lio-forms.models';
import { permissionService } from '../../services/permissions.service';

@Injectable({
	providedIn	: 'root', 
	useClass	: reportCreatorSettings
})
export class reportCreatorSettings {
	constructor(
		@Inject(permissionService) private permissionService:permissionService
	){}
	
	panelConfig:any = {
		'select' : {
			id 					: '',
			tab					: 'Select Report',
			tabTrans		 	: 'report.reports',
			link 				: 'reports',
			suppressAutoSelect	: true,
			visible 			: () =>  { 
				return this.permissionService.hasPermission('reports.read');
			}
		},
		'edit' : {
			id 					: '',
			tab					: 'Create / Edit Report',
			tabTrans 			: 'editreport.title',
			link 				: 'reporteditor',
			suppressAutoSelect	: true,
			visible 			: () =>  { 
				return this.permissionService.hasPermission('reports.editCustom');
			}
		},
		'create' : {
			id					: 'panelTab_report_create_form',
			reloadOnChange		: false,
			hasQueryTool		: false,
			title				: 'Create Custom Report',
			titleTrans			: 'customreports.title',
			tab					: 'Create Custom Report',
			tabTrans			: 'customreports.title',
			visible 			: () => { return this.permissionService.hasPermission('reports.writeCustom'); },
			settings: {
				'modelName' 			: 'Custom Reports',
				'modelNameTrans' 		: 'customReports.modelName',
				'type'					: 'customReports',
				'copyable' 				: true,
				'sortable' 				: false,
				'endpoints' 			: {
					'create'				: 'customreports/save',
					'copy'					: 'customreports/save',
					'getAll'				: 'customreports/getAll',
					'save'					: 'customreports/save',
					'delete'				: 'customreports/deleteByID',
				},
				fields: new LioFieldArray(
					{
						'name'					: 'Name',
						'model'					: 'name',
						'type'					: 'text',
						'required'				: true,
						'visible'				: true,
						'sortable'				: true
					},
					{
						'name'					: 'Table Name',
						'model'					: 'tableName',
						'required'				: true,
						'locked'				: false,
						'visible' 				: true,
						'type'					: 'reportTable',
					},
					{
						'name'					: 'Joints',
						'model'					: 'joints',
						'required'				: false,
						'visible'				: true,
						'sortable'				: true,
						'type'					: 'reportJoints',
						'class'			: 'col',	
					},
					{
						'name'					: 'Fields',
						'model'					: 'fields',
						'required'				: true,
						'visible'				: true,
						'sortable'				: true,
						'type'					: 'reportFields',
						'class'			: 'col',	
					},
					{
						'name'					: 'Wheres',
						'model'					: 'wheres',
						'required'				: true,
						'visible'				: true,
						'sortable'				: true,
						'type'					: 'reportWheres',
						'class'			: 'col',	
					},
					{
						'name'					: 'Settings',
						'model'					: 'settings',
						'required'				: true,
						'visible'				: true,
						'sortable'				: true,
						'type'					: 'reportSettings',
					}
				),
				fieldConfig: {
					'fieldName'			: 'customreportcreate',
				},
				buttons			: [
					{
						'id'					: 'getcount',
						'visible'				: true,
						'text'   				: 'Get Count',
						'textTrans'				: 'reports.getCount',
						'disabledConditions' 	: [],
						'visibleConditions' 	: [],
						'class'					: 'btn-primary',
						'callback'			: 'testReport',
					},
					{
						'id'					: 'testexport',
						'visible'				: true,
						'text'   				: 'Export Report',
						'textTrans'				: 'reports.exportReport',
						'disabledConditions' 	: [],
						'visibleConditions' 	: [],
						'class'					: 'btn-primary',
						'callback'			: 'testExportReport',
					},
					{
						'id'					: 'copycustom',
						'visible'				: true,
						'text'   				: 'Copy Report To Cache',
						'textTrans'				: 'reports.copyReport',
						'disabledConditions' 	: [],
						'visibleConditions' 	: [],
						'class'					: 'btn-primary',
						'callback'			: 'copyReportToCache',
					},
					{
						'id'					: 'pastecustom',
						'visible'				: true,
						'text'   				: 'Paste Report From Cache',
						'textTrans'				: 'reports.pasteReport',
						'disabledConditions' 	: [],
						'visibleConditions' 	: [],
						'class'					: 'btn-primary',
						'callback'			: 'pasteReportFromCache',
					},
					{
						'id'					: 'getQuery',
						'visible'				: true,
						'text'   				: 'Get SQL',
						'textTrans'				: 'reports.getSql',
						'disabledConditions' 	: [],
						'visibleConditions' 	: [],
						'class'					: 'btn-primary',
						'callback'			: 'getSQL',
					}
				],
			},
		},
	};


	model:any = {
		id 					: null,
		name 				: '',
		description			: '',
		joints 				: [],
		wheres 				: [],
		fields 				: [],
		settings 			: {
			'orderBy'	: 'desc',
			'limit'		: 50000,
			'distinct'	: false,
		},
	};

	employeeFields:LioFieldArray = new LioFieldArray(
		{
			'alias': 'e',
			'name': 'Employee ID',
			'model': 'employeeID',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',
			'name': 'First Name',
			'model': 'firstName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',			
			'name': 'Last Name',
			'model': 'lastName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',			
			'name': 'e-mail',
			'model': 'email',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',			
			'name': 'State',
			'model': 'state',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'e',			
			'name': 'Country',
			'model': 'country',
			'type': 'text',
			'invertModel': false,
			'visible': false,
			'sortable' : true,
		},
	);

	enrollmentFields:LioFieldArray = new LioFieldArray(
		{
			'alias': 'ec',
			'name': 'Course ID',
			'model': 'courseID',
			'type': 'text',
			'invertModel': false,
			'disableOverride': true,
			'sortable' : true,
		},
		{
			'alias': 'co',
			'name': 'Course Name',
			'model': 'courseName',
			'type': 'text',
			'invertModel': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Course Sign-Up Date',
			'model': 'courseSignUpDate',
			'type': 'date',
			'visible': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Course Available Date',
			'model': 'courseAvailableDate',
			'type': 'date',
			'visible': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Course Due Date',
			'model': 'courseDueDate',
			'type': 'date',
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Course Started',
			'model': 'courseStarted',
			'type': 'boolean',
			'visible': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Course Completed',
			'model': 'courseCompletion',
			'type': 'checkbox',
			'visible': false,
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Completion Date',
			'model': 'courseCompletionDate',
			'type': 'date',
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'selfEnrolled',
			'model': 'selfEnrolled',
			'type': 'checkbox',
			'visible': 'filters.selfEnroll',
			'sortable' : true,
		},
		{
			'alias': 'ec',			
			'name': 'Required',
			'model': 'required',
			'type': 'checkbox',
			'visible': false,
			'sortable' : true,
		},
	);

	formData:any = {
		'connectors'	: [
			{
				'value' : '',
				'name' : '',
			},
			{
				'value' : 'and',
				'name' : 'And',
			},
			{
				'value' : 'or',
				'name' : 'Or',
			},
		],
		'boolean'	: [
			{
				'value' : 'true',
				'name' : 'True',
			},
			{
				'value' : 'false',
				'name' : 'False',
			},
		],
		'openers'  :  [
			{
				'value' : 'true',
				'name' : 'Add Open',
			},
			{
				'value' : 'false',
				'name' : '',
			},
		],
		'closers'	: [
			{
				'value' : 'true',
				'name' : 'Add Close',
			},
			{
				'value' : 'false',
				'name' : '',
			},
		],
		'fieldTypes'	: [
			{
				'value' : 'text',
				'name' : 'Text',
			},
			{
				'value' : 'date',
				'name' : 'Date',
			},
		],
		'filterTypes'	: [
			{
				'value' : 'string',
				'name' : 'Text',
			},
			{
				'value' : 'date',
				'name' : 'Date',
			},
		],
	};
}