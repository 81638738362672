import { Pipe } from '@angular/core';

@Pipe({
  name: "filter"
})
export class FilterPipe {
	transform(options: Array<any>, filter:{items:Array<any>,name:string,requiredItems?:boolean}): Array<any> {
		if (!options || !filter || !filter.items || !filter.name) {
			if (filter.requiredItems) {
				return [];
			}
			return options; 
		}

		let cleanOptions = [];
		options.forEach((option) => {
			let item = option[filter.name];
			if (filter.items.indexOf(item) > -1) {
				cleanOptions.push(option);
			}
		});
		return cleanOptions;
		
	}
}