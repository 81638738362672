export class PanelTabData {
	//automation id for the tab
	public id?					:string 				= '';
	//current state of whether the tab is visible
	public _visible?			:boolean				= true;
	//a parameterless function that returns true/false whether the tab is visible
	public visible?				:()=>boolean 			= null;
	//whether to hide or remove the tab when not shown
	public reloadOnTabChange?	:boolean				= false;
	//html class to assign the whole panel
	public panelClass?			:string 				= '';
	//html class to assign the body
	public bodyClass?			:string 				= '';
	//text for the button that selects this tab
	public tab					:string 				= '';
	//trans tag for the button that selects this tab
	public tabTrans?			:string 				= '';
	//header text for this tab
	public title?				:string 				= '';
	//trans tag for the header text for this tab
	public titleTrans?			:string 				= '';
	//html class for header of this tab
	public titleClass?			:string 				= '';
	//sub header text for this tab
	public subtitle?			:string 				= '';
	//trans tag for the sub header text for this tab
	public subtitleTrans?		:string 				= '';
	//html class for sub header of this tab
	public subTitleClass?		:string 				= '';
	//small text that goes beneath the sub header
	public description?			:string 				= '';
	//trans tag for the small text that goes beneath the sub header
	public descriptionTrans?	:string 				= '';
	//html class for small text that goes beneath the sub header
	public descriptionClass?	:string 				= '';
	//pagination control settings for this tab, controls are not shown if left null
	public pagination?			:any 					= null;
	//whether this tab is currently shown, controlled by the panel-tabber
	public selected?			:boolean 				= false;
	//the routing link for this tab, if set, this tab is only a button that links to a page
	public link?				:string 				= '';
	//whether this tab should be skipped when deciding which tab should be initially shown during init
	public suppressAutoSelect?	:boolean 				= false;
	// Whether this tab is disabled.
	public disabled?			:string					= '';

	constructor(obj:Partial<PanelTabData>){
		Object.assign(this, obj);
	}
}