import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { lmsService } from './lms.service';

@Injectable({
	providedIn: 'root'
})
export class brandingService{
	css		: string 	= '';
	logoURL	: string 	= '';
	coName	: string	= '';

	public brandingUpdated	:Subject<any> = new Subject();

	private cssElement:HTMLStyleElement = null;

	constructor(
		@Inject(lmsService)		private lmsService		:lmsService
	) {}


	render() {
		let style = document.createElement('style');
		style.innerHTML = this.css;

		//if there is already an existing style element, remove it before placing new styles
		if(this.cssElement){
			this.cssElement.parentNode.removeChild(this.cssElement);
		}

		this.cssElement = document.head.appendChild(style);
	}

	/*
	 * Sets the branding
	*/
	setBranding(branding) {
		if (!branding) {
			branding = {};
		}
		
		if (!branding.coName) {
			branding.coName ='True Office Learning';
		}
		
		if (!branding.logo) {
			branding.logo = 'images/assets/logo.png';
		}

		this.coName 	= branding.coName;
		this.logoURL 	= branding.logo;
		this.css 		= branding.css;
		this.render();
	}

	/* 
	 * Sets the CSS from the existing branding
	*/
	saveBranding(branding) {
		return this.lmsService.post('company/setBranding', {'branding': branding});
	}

	/* 
	 * Gets the existing branding
	*/
	getBranding() {
		return this.lmsService.post('company/getBranding');
	}

	/**
	 * Saves a new logo
	 */
	saveLogo(filePath, resize, width, height) {
		this.lmsService.postAsync('importimage/saveLogo', {'filePath': filePath, 'resize': resize, 'width': width, 'height': height},
		'uploading').then((result:any) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.brandingUpdated.next(result);
					return result;
				});
			} else {
				this.brandingUpdated.next(result);
				return result;
			}
		});
	}
}