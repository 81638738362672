<div class="list-group list-group-flush">
	<div *ngIf="settings.mode == 'read' || settings.mode == 'review'">
		<lio-disclosure-text *ngIf="section.text != null" 
			class="list-group-item" 
			[settings]="settings" 
			[model]="section">
		</lio-disclosure-text>
		<lio-disclosure-question *ngFor="let question of section.questions; index as i"
			[appearance]="appearance"
			[settings]="settings" 
			[question]="section.questions[i]" 
			[allQuestions]="allQuestions"
			(optionAdded)="optionAdded.emit($event)" 
			(delete)="deleteQuestion($event)"
			(notificationResponseAdded)="notificationResponseAdded.emit($event)"
			(notificationResponseRemoved)="notificationResponseRemoved.emit($event)">
		</lio-disclosure-question>
	</div>
	<div *ngIf="settings.mode == 'edit'">
		<div class="list-group-item">
			<div class="row">
				<div class="col-lg-12 text-right">
					<button mat-flat-button color="warn" [disabled]="settings.wasActive" (click)="delete.emit(section)">Delete Section</button>
					<button mat-flat-button color="primary" class="ml-2" (click)="addQuestion()">Add Question</button>
					<button mat-flat-button color="primary" class="ml-2" (click)="addText()">Add Text</button>
				</div>
			</div>
		</div>
		<div class="list-group-item" *ngIf="section.text != null">
			<lio-card cardClass="bg-faded-blue text-white" headerClass="text-white" bodyClass="bg-white text-dark">	
				<div lio-card-header>
					<div class="row">
						<h5 class="col-12 col-md-6 text-left">Non-Question Text</h5>
						<div class="col-12 col-md-6 text-right">
							<button mat-flat-button color="warn"
								[disabled]="settings.wasActive" 
								(click)="deleteText()">Delete Text</button>
						</div>
					</div>
				</div>
				<div lio-card-body>
					<lio-disclosure-text [settings]="settings" [model]="section"></lio-disclosure-text>
				</div>
			</lio-card>
		</div>
		<lio-disclosure-question *ngFor="let question of section.questions; index as i" 
			[appearance]="appearance"
			[settings]="settings" 
			[question]="section.questions[i]" 
			[allQuestions]="allQuestions"
			(optionAdded)="optionAdded.emit($event)"
			(delete)="deleteQuestion($event)"
			(notificationResponseAdded)="notificationResponseAdded.emit($event)"
			(notificationResponseRemoved)="notificationResponseRemoved.emit($event)">
		</lio-disclosure-question>
	</div>
	<div class="card-footer text-center">
		<lio-disclosure-button [settings]="settings" 
			[appearance]="appearance"
			[button]="section.button"
			[sendingResponse]="sendingResponse"
			(response)="sendResponse()">
		</lio-disclosure-button>
	</div>
</div>