import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { loaderService } from '../../services/loader.service';
import { batchService } from '../../services/batch.service';

import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { fieldService } from '../../services/fields.service';
import { filesService } from '../../services/files.service';
import { exportService } from '../../services/export.service';
import { permissionService } from '../../services/permissions.service';
import { formValidatorService } from '../../services/form-validator.service';
import { storageService } from '../../services/storage.service';
import { ccfService } from '../../services/ccf.service';
import { utilService } from '../../services/util.service';
import { browserService } from '../../services/browser.service';
import { configSettings } from '../../settings/config.settings';
import { batchProcessingSettings } from './batch-processing.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
  selector: 'lio-batch-processing',
  templateUrl: './batch-processing.component.html'
})
export class BatchProcessingComponent implements OnInit, OnDestroy {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache		:PanelTabCache 	= null;

	public tabSettings			:any			= this.batchProcessingSettings.tabSettings;
	public fileName					:string		= '';
	public filePaths				:Array<string>	= [];
	public model						:any		= {};
	public formatCtrl 			:any 		= {};
	public history					:any		= [];
	public employees				:any		= [];
	public filteredEmployees:any		= [];
	public config						:any		= {};
	public processing				:boolean		= false;
	public format						:any		= {
		fields: [],
	};
	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService				:navService,
		@Inject(debugService)			public 	debugService			:debugService,
		@Inject(feedbackService)		public 	feedbackService			:feedbackService,
		@Inject(errorsService)			public 	errorsService			:errorsService,
		@Inject(lmsService)				public 	lmsService				:lmsService,
		@Inject(lioLogService)			public 	lioLogService			:lioLogService,
		@Inject(stateService)			public 	stateService			:stateService,
		@Inject(workerService)			public 	workerService			:workerService,
		@Inject(permissionService)		public 	permissionService		:permissionService,
		@Inject(batchService)			public 	batchService			:batchService,
		@Inject(loaderService)			public 	loaderService			:loaderService,
		@Inject(filesService)			public 	filesService		:filesService,
		@Inject(lioModalService)		public 	lioModalService			:lioModalService,
		@Inject(utilService)			public 	utilService				:utilService,
		@Inject(exportService)			public 	exportService			:exportService,
		@Inject(formValidatorService)	public 	formValidatorService	:formValidatorService,
		@Inject(fieldService)			public 	fieldService			:fieldService,
		@Inject(browserService)			public 	browserService			:browserService,
		@Inject(ccfService)				public 	ccfService				:ccfService,
		@Inject(configSettings)			public 	configSettings			:configSettings,
		@Inject(storageService)			public 	storageService			:storageService,
		@Inject(batchProcessingSettings) public 	batchProcessingSettings	:batchProcessingSettings
	){
		this.debugService.register('batchProcessing', this);
		this.navService.setActivePage('batchProcessing');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);

		this.subscriptions.add(
			this.lmsService.cancelledSubject.subscribe(() => {
				this.handleCancelled();
			})
		);


		this.subscriptions.add(
			this.batchService.employeeSubject.subscribe((employees) => {
					this.gotEmployees(employees);
			})
		);	
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	handleClick($fn) {
		this[$fn]();
	}

	handleUpload($response) {
		if ($response.fn) {
			this[$response.fn]($response.event);

		}
	}

	/*
	 * Handles tab change
	*/
	onPanelTabChange(tab) {
		let id = tab.id;
		this.feedbackService.clearAll();
		this.processing = false;
		switch (id) {
			case 'config':
				this.getConfig();
				break;
			case 'format':
				this.getFormatFields();
				break;
			case 'upload':
				this.filePaths = [];
				this.model = {};
				this.employees = [];
				this.resetUploads();
				break;
			case 'uploadHistory':
				this.getHistory('upload', false);
				break;
			case 'globalUploadHistory':
				this.getHistory('upload', true);
				break;
			case 'processHistory':
				this.getHistory('process', false);
				break;
			case 'globalProcessHistory':
				this.getHistory('process', true);
				break;
			default:
				break;
		}
	}


	/* 
	 * Gets the config
	*/
	getConfig() {
		return this.batchService.getConfig().then((config) => {
				this.config = config;
		});
	}


	/* 
	 * Gets the history
	*/
	getHistory(type, globally) {
		this.history = [];
		return this.batchService.getHistory(type, globally).then((history) => {
			this.history = history;
		});
	}


	/*
	 * Gets the format
	*/
	getFormatFields() {
		return this.batchService.getFormatFields().then((fields) => {
				this.format.fields = fields;
				if (fields.length) {
					this.formatCtrl.validate();
				}
		});
	}

	/* 
	 * On init
	*/
	init() {
		this.getConfig().then(() => {
			this.getFormatFields();
			this.navService.displayPage();
		})
	}

	/* 
	 * Reset
	*/
	reset() {
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
	}


	/*
	 * Reset the imports directory
	*/
	resetUploads() {
		return this.lmsService.post('batchUpload/resetManualUploads').then((result) => {
				this.model.canUpload = true;
				return result.success;
		});
	}


	/*
	 * Purge the uploads directory
	*/
	purgeUploads() {
		this.lioModalService.showLoading('processing');
		return this.lmsService.post('batchUpload/purgeUploads').then((result) => {
				this.lioModalService.hideLoading();
				this.lioModalService.show('Purged Uploads');
				this.model.canUpload = true;
				this.model.canProcess = false;
				return result.success;
		});
	}


	/*
	 * Gets The Upload Log
	*/
	getUploadLog() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.lioModalService.showLoading();
		this.lmsService.get('batchUpload/getLog').then((result) => {
			let success = result.success,
					filePath = result.properties.filePath,
					fileName = result.properties.fileName;
			if (success) {
				this.filesService.downloadFile(filePath, fileName);
			}
			this.lioModalService.hideLoading();
		});
	}


	/*
	 * Gets The Process Log
	*/
	getProcessLog() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.lioModalService.showLoading();
		this.lmsService.get('batchProcess/getLog').then((result) => {
			let success = result.success,
					filePath = result.properties.filePath,
					fileName = result.properties.fileName;
			if (success) {
				this.filesService.downloadFile(filePath, fileName);
			}
			this.lioModalService.hideLoading();
		});
	}

	/*
	 * Get Sample 
	*/
	getSample() {
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.lioModalService.showLoading();
		this.lmsService.get('batchFormat/getSample').then((result) => {
			let success = result.success,
					filePath = result.properties.filePath,
					fileName = result.properties.fileName;
			if (success) {
				this.filesService.downloadFile(filePath, fileName);
			}
			this.lioModalService.hideLoading();
		});
	}


	/*
	 * Get the format file
	*/
	getFormatFile() {
		this.feedbackService.clearAll();
		this.lioModalService.showLoading();
		this.lmsService.get('batchFormat/getFormatFile').then((result) => {
			let success = result.success,
					filePath = result.properties.filePath,
					fileName = result.properties.fileName;
			if (success) {
				this.filesService.downloadFile(filePath, fileName);
			}
			this.lioModalService.hideLoading();
		});
	}


	/*
	 * Save format fields 
	*/
	saveFormatFields() {
		this.feedbackService.clearAll();

		if (!this.formatCtrl.validate()) {
			this.feedbackService.addError('Please check the filters');
			return;
		}
		this.lioModalService.showLoading('saving');
		this.batchService.saveFormatFields(this.format.fields).then((fields) => {
				if (fields) {
					this.format.fields = fields;
					this.savedSuccessfully();
				} else {
					this.lioModalService.hideLoading();
				}
		});
	}


	updateFilteredEmployees(collection) {
		this.filteredEmployees = collection.filtered;
	}


	getAll() {
		this.getEmployees('all');
	}

	getInserts() {
		if (this.model.processed) {
			this.getEmployees('inserted');
		} else {
			this.getEmployees('insert');
		}
	}

	getUpdates() {
		if (this.model.processed) {
			this.getEmployees('updated');
		} else {
			this.getEmployees('update');
		}
	}

	getReactivations() {
		if (this.model.processed) {
			this.getEmployees('reactivated');
		} else {
			this.getEmployees('reactivate');
		}
	}

	getDeactivations() {
		if (this.model.processed) {
			this.getEmployees('deactivated');
		} else {
			this.getEmployees('deactivate');
		}
	}


	/*
	 * On Click of the Assign button
	*/
	assignCourses() {
		if (!this.employees.length) {
			this.lioModalService.show('Please load the employees you want to assign first');
			return;
		}
		this.storageService.setEmployeesToAssign(this.employees);
		this.navService.goto('assign');
	}


	/*
	 * Gets the employees
	*/
	getEmployees(status) {
		this.feedbackService.clearErrors();
		this.employees = [];
		this.filteredEmployees = [];
		this.batchService.getEmployees(this.model, status);
	}


	/* 
	 * Got employees
	*/
	gotEmployees(employees) {
		if (!employees) {
			employees = [];
			this.lioModalService.show('error.noEmployeesFound');		
		}
		this.employees = employees;
		this.lioModalService.hideLoading();
	}


	/* 
	 * On Format File Added
	*/
	formatFileAdded(event) {
		let filePath = event.response.properties.filePath;
		this.batchService.getFormatFieldsFromFile(filePath).then((fields) => {
				this.format.fields = fields;
				this.formatCtrl.validate();
				this.lioModalService.hideLoading();
				this.lioModalService.show('Please validate the information and then save the format');
		});
	}

	keyFileAdded(event) {
		let filePath = event.response.properties.filePath;
		if (filePath) {
			this.lioModalService.show('savedSuccessfully');
		}
	}


	/* 
	 * On File Added
	*/
	fileAdded(event) {
		let filePath = event.response.properties.filePath;
		this.fileName = event.response.properties.fileName;
		if (this.config.merge_names) {
			this.filePaths.push(filePath);
			this.lioModalService.hideLoading();
			this.lioModalService.confirm('Would you like to upload another file?').then((anotherFile) => {
				if (!anotherFile) {
					this.preprocess();
				}
			})
		} else {
			this.filePaths = [filePath];
			this.preprocess();
		}
	}


	/*
	 * Preprocess
	*/
	preprocess() {
		if (this.processing) {
			return;
		}
		this.feedbackService.clearAll();
		this.model.processed = false;
		this.model.preProcessed = false;
		this.model.canUpload = false;

		this.processing = true;
		this.lmsService.postAsync('batchUpload/manualUpload', {'filePaths': this.filePaths},
		'validating').then((result) => {
			let token = result.properties.token;
			this.filePaths = [];
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handlePreprocessResults(gresult);
				});
			} else {
				this.handlePreprocessResults(result);
			}
		});
	}



	/*
	 * Handles pre-process results
	*/
	handlePreprocessResults(result) {
		this.lioModalService.hideLoading();
		this.processing = false;

		let results = result.properties.results;
		
		this.employees = [];
		this.model = {};
		if (results) {
			if (results.VALIDATION) {
				this.model = this.handleValidationResults(results.VALIDATION);
				this.model.preProcessed = true;
				if (!this.model.TOTALCHANGES) {
					this.lioModalService.show('batchProcessing.nothingToProcess');
				} else {
					let macros = [{'key': 'total', 'value': this.model.TOTALCHANGES}];
					this.lioModalService.show('batchProcessing.foundChanges', null, macros);
				}
			}
			this.model.uploadID = results.uploadID;
		} else {
			this.model.canUpload = true;
		}
		this.model.fileName = result.properties.fileName;
		this.lioModalService.hideLoading();
	}


	/*
	 * Process
	*/
	process() {
		this.feedbackService.clearAll();
		
		return this.lmsService.postAsync('batchProcess/manualProcess', {'uploadID': this.model.uploadID}, 'processing').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleProcessResults(gresult);
				});
			} else {
				this.handleProcessResults(result);
			}
		});
	}



	/*
	 * Handles process results
	*/
	handleProcessResults(result) {
		this.lioModalService.hideLoading();

		let results = result.properties.results;

		this.employees = [];
		this.model = {};

		if (results) {
			if (results.success && results.PROCESS) {
				this.lioModalService.show('savedSuccessfully');
			}
			if (results.PROCESS) {
				this.model = results.PROCESS;
				this.model.processed = true;
				this.model.preProcessed = false;
				this.model.canProcess = false;
			}
			this.model.uploadID = results.uploadID;
		}
		this.model.fileName = this.fileName;
	}

	/*
	 * Handles cancelled process
	*/
	handleCancelled() {
		this.filePaths = [];
		this.model = {};
		this.employees = [];
		this.processing = false;
		this.resetUploads();
	}


	/*
	 * Handle validation results
	*/
	handleValidationResults(results) {
		results.canProcess = results.TOTALCHANGES > 0 && !this.config.test_mode;
		return results;
	}


	/*
	 * Upon uploaded
	*/
	handleUploadedToServer(result) {
		if (result.success && !result.errors.length && !result.properties.cancelled) {
			this.savedSuccessfully();
		}
	}

	/*
	 * Upon saved successfully
	*/
	savedSuccessfully() {
		this.navService.changedForm = false;
		this.lioModalService.hideLoading();
		this.lioModalService.show('savedSuccessfully');
	}

	onUpdate() {
		this.isValid();
	}

	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;

		this.feedbackService.clearErrors();
		
		return isValid;
	}


	/*
	 * Preprocess / Unzips the file Path
	*/
	saveConfig() {
		this.feedbackService.clearAll();
		this.lioModalService.showLoading();
		this.lmsService.post('batchConfig/saveConfig', {'config': this.config}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this.lioModalService.show('savedSuccessfully');
			}
			this.getConfig();
		});
	}



}