import { Component, OnDestroy, Inject, Input } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../../../services/loader.service';
import { coursesService } from '../../../../services/courses.service';
import { navService } from '../../../../services/nav.service';
import { permissionService } from '../../../../services/permissions.service';
import { formValidatorService } from '../../../../services/form-validator.service';
import { lmsService } from '../../../../services/lms.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { storageService } from '../../../../services/storage.service';
import { localizationService } from '../../../../services/localization.service';
import { fieldService } from '../../../../services/fields.service';
import { passwordResetService } from '../../../../services/password-reset.service';
import { utilService } from '../../../../services/util.service';
import { feedbackService } from '../../../../services/feedback.service';
import { stateService } from '../../../../services/state.service';
import { processingService } from '../../../../services/processing.service';
import { loginService } from '../../../../services/login.service';
import { debugService } from '../../../../services/debug.service';

import { profileSettings } from '../../../../components/profile/profile.settings';

@Component({
	selector: 'lio-profile',
	templateUrl: './profile.component.html'
})
export class LioProfile implements OnDestroy {
	@Input() updateCCFPermission			:string 			= '';
	@Input() updateProfilePermission		:string 			= '';
	@Input() fields							:any 				= {};
	@Input() config							:any 				= {};
	@Input() self							:boolean 			= false;

	public employee				:any 			= {};
	public allowSubmit			:boolean		= false;
	public allowAssign			:boolean		= false;
	public allowResetPassword	:boolean		= false;
	public viewOnly				:boolean		= false;

	public localeStrings		:any 			= {
		update				:'Update',
		updateTrans			:'form.update',
		view				:'View Enrollments',
		viewTrans			:'enrollquery.view',
		title				:'Assign Training',
		titleTrans			:'assign.title',
		resetPass			:'Reset User\'s Password',
		resetPassTrans		:'user.resetPass',
		setPass				:'Set User\'s Password',
		setPassTrans		:'profile.setPass',
		emailuser			:'Email User',
		emailuserTrans		:'global.emailuser',
		editAdminRights		:'Edit Admin Rights',
		editAdminRightsTrans:'employee.editAdminRights',
		loginAsUser 		:'profile.loginAsUser',
	};

	public localeStringsKeys	:Array<string> 	= [
		'update',
		'view',
		'title',
		'resetPass',
		'setPass',
		'emailuser',
		'editAdminRights',
		'loginAsUser'
	];

	protected employeeID			:string			= '';
	protected setPasswordField	:any 			= this.utilService.copy(this.profileSettings.setPasswordField);
	protected originalEmployee	:any 			= {};
	public panelConfigs	:any 					= this.utilService.copy(this.profileSettings.panelConfigs);

	protected subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(loaderService)			public	loaderService		:loaderService,
		@Inject(coursesService)			public	coursesService		:coursesService,
		@Inject(navService)				public	navService			:navService,
		@Inject(permissionService)		public	permissionService	:permissionService,
		@Inject(formValidatorService)	public	formValidatorService:formValidatorService,
		@Inject(lmsService)				public	lmsService			:lmsService,
		@Inject(lioModalService)		public	lioModalService		:lioModalService,
		@Inject(storageService)			public	storageService		:storageService,
		@Inject(localizationService)	public	localizationService	:localizationService,
		@Inject(fieldService)			public	fieldService		:fieldService,
		@Inject(passwordResetService)	public	passwordResetService:passwordResetService,
		@Inject(utilService)			public	utilService			:utilService,
		@Inject(feedbackService)		public	feedbackService		:feedbackService,
		@Inject(stateService)			public	stateService		:stateService,
		@Inject(processingService)		public	processingService	:processingService,
		@Inject(loginService)			public	loginService		:loginService,
		@Inject(debugService)			public	debugService		:debugService,
		@Inject(profileSettings)		public	profileSettings		:profileSettings
	){	
		processingService.allowCancel 	= false;

		//Init
		this.subscriptions.add(
			this.stateService.waitForLoaded.subscribe(() => {
				if (this.init() && this.isAllowed()) {
					this.loadEmployee();
				}
			})
		);

		//On Field Change
		this.subscriptions.add(
			this.fieldService.fieldsUpdated.subscribe(() => {
				this.navService.displayPage();
			})
		);

		this.setup();
	}

	setup() {
		this.debugService.register('profile', this);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/*
	 * Goes to the assign training page for this user
    */
	assign() {
		var employees = [this.employee];
		this.storageService.setEmployeesToAssign(employees);
		this.navService.goto('assign');
	}

	/*
	 * Views enrollments
    */
	viewEnrollments() {
		this.storageService.set('employee', this.employee);
		this.navService.goto('enrollments');
	}

	/*
	 * Changes the password
    */
	changePassword() {
		this.navService.goto('changepass');
	}

	/*
	 * Initializes variables
	 * @return {boolean}
    */
	init() {
		if (!this.setEmployeeID()) {
			this.lioModalService.showError('failedToLoadEmployeeToEdit');
			setTimeout(() => {
				this.navService.goback();
			}, 2000);
			return false;
		}
		this.setPermissions();
		return true;
	}

	/*
	 * Sets the employeeID from either the local session or from an external source
	 * @return {boolean}
    */
	setEmployeeID() {
		let employee = this.storageService.get('employeeToEdit');
		this.employeeID = employee.employeeID;
		this.employee = employee;
		this.storageService.set('UID', employee.UID);

		return true;
	}


	/*
	 * Email the user
    */
	emailUser() {
		this.storageService.set('employeesToEmail', [this.employee]);
		this.storageService.set('employeeIDsToEmail', [this.employee.employeeID]);
		this.storageService.set('task', 'emailing');
		this.storageService.set('courseID', null);
		this.navService.goto('email');
	}

	/*
	 * Edit the ccf admin for this user
    */
	editCCFAdmin() {
		this.storageService.set('employeeToEdit', this.employee);
		this.navService.goto('ccfadmin');
	}

	/*
	 * Set what is allowed to do on this page
    */
	setPermissions() {
		this.allowAssign = this.employee.inactive == 0;
		if (this.employee.isSSO == 1) {
			this.allowResetPassword = false;
		} else {
			this.allowResetPassword = true;
		}
	}

	/*
	 * Loads the employees
	*/
	loadEmployee() {
		this.lmsService.post('employee/getEmployeeByEmployeeID', {'employeeID': this.employeeID}).then((result) => {
			var employee = result.properties.employee;

			// Trim values
			this.employee = this.cleanUpEmployee(employee);

			this.initForm();
		});
	}

	/*
	 *  Cleans up the employee
	 * @param {object} employee
	 * @return {object} employee
    */
	cleanUpEmployee(employee) {
		if (employee) {
			let employeeKeys = Object.keys(employee);
			employeeKeys.forEach((key) => {
				if (this.utilService.isString(employee[key])) {
					employee[key] = employee[key].trim();
				}
			});

			if (!employee.langID) {
				employee.langID = 'en';
			}
		}
		return employee;
	}

	/*
	 *  Initializes the form
    */
	initForm() {
		if (!this.employee) {
			this.navService.goto('home');
			return;
		}
		this.originalEmployee = this.utilService.copy(this.employee);
		this.setFields();
		this.navService.changedForm = false;
		this.allowSubmit = false;
	}

	/*
	 * Sets the editable fields
    */
	setFields() {
		let langID = this.originalEmployee.langID,
			config = this.utilService.copy(this.config),
			fields = this.utilService.copy(this.fields);


		config.langID = langID;
		this.config = config;
		config.permissionID = this.employee.permissionID;
		this.fields = fields;

		if (!this.permissionService.hasRoleAuthority(this.employee.permissionID, 'EDIT')) {
			this.allowSubmit = false;
			this.viewOnly = true;
		}
	}

	/*
	 * On update of the profile
    */
	onupdate() {
		this.navService.changedForm = true;
		this.allowSubmit = true;
		this.feedbackService.clearErrors();
		this.formValidatorService.resetFields();
	}


	/**
	 * Show the set password lioModal
	 */
	showSetPasswordModal() {
		this.lioModalService.open({
			'type':'input', 
			'title': 'Enter a password for this employee', 
			'titleTrans': 'Enter a password for this employee', 
			'input': 'New Password', 
			'submit': 'Set Password',
		}).then((password) => {
			if (password) {
				this.setPassword(password);
			}
		});
	}

	/**
	 * Set the password
	 * @param {string} password
	 */
	setPassword(password) {
		if (!this.formValidatorService.isValid({'newPassword': password}, [this.setPasswordField])) {
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
			return;
		}

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.lioModalService.showLoading('processing');
		this.lmsService.post('password/setPassword', {'employeeID': this.employeeID, 'password' : password}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this.lioModalService.show('passChanged');
			} else if (result.errors.length) {
				this.feedbackService.setErrors(result.errors);
			}
		});
	}

	/*
	 * Sends the user a password reset link
    */
	submitForgotPass() {
		if (!this.allowResetPassword) {
			return;
		}
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.lioModalService.showLoading('processing');

		this.passwordResetService.resetRequest(this.employee, true).then((result) => {
			this.lioModalService.hideLoading();
			if (result) {
				this.lioModalService.show('emailToUserToResetPass');
			}
		});
	}
	
	/*
	 * Checks if the form is valid
    */
	isValid() {
		var isValid = true;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (!this.formValidatorService.isValid(this.employee, this.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.employee = this.formValidatorService.getData();
		if (this.originalEmployee) {
			this.employee.originalEmployeeID = this.originalEmployee.employeeID;
		}

		return isValid;
	}

	/*
	 * Submits the edit
    */
    submit() {
		let messages = [];
		if (!this.isValid()) {
			return;
		}

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.navService.changedForm = false;

		this.lioModalService.showLoading('processing');

		this.lmsService.post('employee/editProfile', {'employee': this.employee}).then((result) => {
			this.lioModalService.hideLoading();
			this.allowSubmit = false;

			if (result.success) {
				if (this.self && this.originalEmployee.langID != this.employee.langID) {
					this.loginService.getLocalizationByLangID(this.employee.langID);
				}
				messages.push(this.utilService.localizeMessage('profileSuccessfullyUpdated'));
				this.originalEmployee = this.utilService.copy(this.employee);
				this.feedbackService.setMessages(messages);
				this.loginService.updateCCFValues();
				this.storageService.set('employeeToEdit', this.employee);
				this.allowAssign = this.employee.inactive == 0;
			}
        });
    }


	loginAsUser(kickoffuser:boolean = false) {
		if (!this.permissionService.hasPermission('employee.proxyAsUser')) {
			return;
		}
		this.lioModalService.showLoading();
		this.lmsService.post('impersonation/proxyAsUser', {'targetEmployeeID': this.employeeID, 'kickoffuser': kickoffuser}).then((result) => {
			if (result.properties.isAlreadyLoggedin) {
				this.lioModalService.confirm('kickoffuser').then((result) => {
					if (result) {
						this.loginAsUser(true);
					}
				});
			} else if (result.success) {
				this.navService.goHome();
			}
		});	
    }

    /*
	 * Checks if user can change his profile
	 * @return {boolean}
    */
    isAllowed() {
		if (this.permissionService.hasPermission('employee.update')) {
			return true;
		}

		this.lioModalService.showError('notAuthorizedToEditProfile');
		setTimeout(() => {
			this.navService.goback();
		}, 2000);
		return false;
    }
}