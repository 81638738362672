import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { localizationService } from '../../../../services/localization.service';
import { lioModalService } from '../../../../services/lio-modal.service';
import { fileFormatSettings } from './file-format.settings';
import { debugService } from '../../../../services/debug.service';
import { feedbackService } from '../../../../services/feedback.service';

@Component({
	selector: 'lio-file-format',
	styleUrls: ['./file-format.component.css'],
	templateUrl: './file-format.component.html',
})
export class LioFileFormat implements OnInit {
	@Input() model:any;
	@Output() onUpdate				: EventEmitter<any> = new EventEmitter();
	@Input() appearance 			: MatFormFieldAppearance = 'fill';

	public fields:any 				= this.fileFormatSettings.fields;
	public fieldConfig:any 			= this.fileFormatSettings.fieldConfig;
	private _controlObject :any;
	get controlObject(){
		return this._controlObject;
	}
	@Input() set controlObject(value: any) {    
		this._controlObject = value;
		if (value) {
			value.validate 		= () 		=> { return this.validate(); };
		}
	
	}
	constructor(
		@Inject(fileFormatSettings) public fileFormatSettings :fileFormatSettings,
		@Inject(debugService) public debugService :debugService,
		@Inject(lioModalService) public lioModalService :lioModalService,
		@Inject(feedbackService) public feedbackService :feedbackService,
		@Inject(localizationService) public localizationService :localizationService
	){
		this.debugService.register('fileformat', this);
	}

	/*
	 * Init
	*/
	ngOnInit() {
		if (!this.model.fields.length) {
			this.addRow();
		}
	}


	/*
	 * Validate
	*/
	validate() {
		let isValid = true;
		if (!this.model.fields.length) {
			return false;
		}

		this.model.fields.forEach((field) => {
			field.error = false;
			if (!field.fieldName || !field.delimitter) {
				field.error = true;
				isValid = false;
			}

			if (field.fieldName == 'customField') {
				field.skipField = '1';
			}

			if (!this.fields[0].options.find(option => option.value == field.fieldName)) {
				isValid = false;
				field.error = true;
			}
		});

		return isValid;
	}


	/*
	 * On update
	*/
	update() {
		this.onUpdate.emit();
	}


	/*
	 * Remove row
	*/
	removeRow(index) {
		this.model.fields.splice(index, 1);
		this.onUpdate.emit();
	}

	addMap(index) {
		let field = this.model.fields[index],
			modal = this.fileFormatSettings.modalSettings;

		modal.fromField.options.forEach((option) => {
			option.locked = true;
		});

		if (!field['fieldName']) {
			this.feedbackService.addError('Please select a field name first');
			return;
		}

		modal['mapFrom']	= field['fieldName'];
		modal['mapTo']		= field['mapTo'];
		modal['maps']		= field['maps'] || [];
		this.lioModalService.open(modal).then((response) => {
			if (response) {
				field['mapFrom'] 	= response['mapFrom'];
				field['mapTo'] 		= response['mapTo'];
				field['maps'] 		= response['maps'];
			}
		});
	}


	/*
	 * Add Row
	*/
	addRow() {
		this.model.fields.push({});
	}
}