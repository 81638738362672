<div class="wrapper wrapper-content animated" localize [model]="localeStrings" [items]="localeStringsKeys">
	<div class="row" localize [model]="localeStrings" [items]="localeStringsKeys" *ngIf="!navService.loading">
		<div class="col-md-12">

			<!-- Form to create or edit a company. -->
			<!-- When creating, always show the form. -->
			<!-- When editing, show the form after a company is selected. -->
			<form name="form" role="form" autocomplete="off" novalidate (ngSubmit)="submit()">
				<!-- Search for a company to edit. -->
				<lio-searchable-select *ngIf="action === 'edit'"
					[hidden]="!!company.companyID"
					[model]="company"
					[options]="companies"
					[field]="companyIDField"
					(onUpdate)="getCompany()"
					appearance="outline">
				</lio-searchable-select>
				<lio-form-inputs
					[hidden]="!company.companyID && action === 'edit'"
					[fields]="fields"
					[fieldConfig]="fieldConfig"
					[model]="company"
					(onChanged)="onUpdate()">
				</lio-form-inputs>
				<mat-accordion [hidden]="!company.companyID && action === 'edit'">
					<!-- Sub Companies -->
					<mat-expansion-panel *ngFor="let subCompany of subCompanies" [hidden]="!subCompany.visible">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div id="toggle_{{subCompany.id}}">{{subCompany.name}}</div>
								<mat-icon color="warn" (click)="deleteSubCompany(subCompany)">delete</mat-icon>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<lio-form-inputs
							[fields]="subCompanyFields"
							[fieldConfig]="subCompanyFieldConfig"
							[model]="subCompany"
							(onChanged)="onUpdate()">
						</lio-form-inputs>
					</mat-expansion-panel>
					<!-- Groups -->
					<mat-expansion-panel *ngFor="let group of groups" [hidden]="!group.visible">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div id="toggle_{{group.id}}">{{group.name}}</div>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<table class="table table-striped table-md mb-0">
							<thead>
								<tr>
									<td id="am_ccfadmin_name">Name</td>
									<td id="am_ccfadmin_value">Value</td>
									<td id="am_ccfadmin_groupable">Groupable</td>
									<td id="am_ccfadmin_visible">Visible</td>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let field of group.fields"
									[hidden]="!field.visible"
									localize
									[model]="field"
									item="name"
									[itemTag]="'form.' + field.model">

									<td class="label.{{field.type}}">
										<span>{{field.name}}</span>
										<span *ngIf="field.tooltip">
											<mat-icon [matTooltip]="field.tooltip">help</mat-icon>
										</span>
									</td>
									<td class="p-0">
										<!-- Name -->
										<mat-form-field [appearance]="appearance">
											<mat-label>Name</mat-label>
											<input matInput
												[name]="field.model"
												(click)="onUpdate()"
												[(ngModel)]="company[field.model]"
												type="text"/>
										</mat-form-field>
									</td>
									<td>
										<!-- Groupable -->
										<mat-slide-toggle
											[name]="field.groupableModel"
											color="primary"
											[(ngModel)]="company[field.groupableModel]"
											(change)="onUpdate()">
										</mat-slide-toggle>
									</td>
									<td>
										<!-- Visible -->
										<mat-slide-toggle
											[name]="field.visibleModel"
											color="primary"
											[(ngModel)]="company[field.visibleModel]"
											(change)="onUpdate()">
										</mat-slide-toggle>
									</td>
								</tr>
							</tbody>
						</table>
					</mat-expansion-panel>
				</mat-accordion>
				<div [hidden]="!company.companyID && action === 'edit'">
					<button id="am_companyadmin_submit"
						[disabled]="!allowSubmit"
						(click)="submit()"
						class="btn btn-primary mr-2 mt-3"
						type="button">
						<strong>{{localeStrings.save}}</strong>
					</button>
					<button id="am_company_addSubCompany"
						class="btn btn-primary mr-1 mt-3"
						(click)="addSubCompany()"
						type="button">
						<strong>{{localeStrings.addSubCompany}}</strong>
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
