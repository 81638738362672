import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { NEVER, Subscription } from 'rxjs';

import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { feedbackService } from '../../services/feedback.service';
import { stateService } from '../../services/state.service';
import { errorsService } from '../../services/errors.service';
import { workerService } from '../../services/worker.service';
import { lmsService } from '../../services/lms.service';
import { loaderService } from '../../services/loader.service';
import { lioModalService } from '../../services/lio-modal.service';
import { lioLogService } from '../../services/lio-log.service';
import { fieldService } from '../../services/fields.service';
import { exportService } from '../../services/export.service';
import { permissionService } from '../../services/permissions.service';
import { filesService } from '../../services/files.service';
import { formValidatorService } from '../../services/form-validator.service';
import { storageService } from '../../services/storage.service';
import { ccfService } from '../../services/ccf.service';
import { coursesService } from '../../services/courses.service';
import { utilService } from '../../services/util.service';
import { browserService } from '../../services/browser.service';
import { configSettings } from '../../settings/config.settings';
import { courseUploaderSettings } from './course-uploader.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
  selector: 'lio-course-uploader',
  templateUrl: './course-uploader.component.html'
})
export class CourseUploaderComponent implements OnInit, OnDestroy {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache		:PanelTabCache 	= null;

	public tabSettings			:any			= this.courseUploaderSettings.tabSettings;
	private settings				:any			= this.courseUploaderSettings.tabSettings.upload;
	private filePath				:string		= '';
	public course						:any			= {
		disableUpload 			: false,
		showURL							: false, 
		validCourseID   		: false,
		active   						: true,
	};
	private coursePath			:any			= null;
	private searchedFor			:string			= '';
	private googleAPIKey		:string			= 'AIzaSyBdP1rWNl0fUPQtJsnu4ExIaRL5-c6Aoto';
	private currentTabID		:any			= null;
	private autoChangingTab		:boolean		= false;
	private reuploading			:boolean		= false;
	private editing				:boolean		= false;
	private coursesToDownload:any = [];
	private totalCoursesToDownload:number = 0;

	private subscriptions		:Subscription	= NEVER.subscribe();

	constructor(
		@Inject(navService)				public 	navService				:navService,
		@Inject(debugService)			public 	debugService			:debugService,
		@Inject(filesService)			public 	filesService			:filesService,
		@Inject(feedbackService)		public 	feedbackService			:feedbackService,
		@Inject(errorsService)			public 	errorsService			:errorsService,
		@Inject(lmsService)				public 	lmsService				:lmsService,
		@Inject(lioLogService)			public 	lioLogService			:lioLogService,
		@Inject(stateService)			public 	stateService			:stateService,
		@Inject(workerService)			public 	workerService			:workerService,
		@Inject(permissionService)		public 	permissionService		:permissionService,
		@Inject(loaderService)			public 	loaderService			:loaderService,
		@Inject(lioModalService)		public 	lioModalService			:lioModalService,
		@Inject(utilService)			public 	utilService				:utilService,
		@Inject(exportService)			public 	exportService			:exportService,
		@Inject(formValidatorService)	public 	formValidatorService	:formValidatorService,
		@Inject(fieldService)			public 	fieldService			:fieldService,
		@Inject(browserService)			public 	browserService			:browserService,
		@Inject(ccfService)				public 	ccfService				:ccfService,
		@Inject(coursesService)			public 	coursesService			:coursesService,
		@Inject(configSettings)			public 	configSettings			:configSettings,
		@Inject(storageService)			public 	storageService			:storageService,
		@Inject(courseUploaderSettings) public 	courseUploaderSettings	:courseUploaderSettings
	){
		this.debugService.register('courseuploader', this);
		this.navService.setActivePage('courseuploader');

		this.subscriptions.add(
			this.loaderService.lockedAndLoaded.subscribe(() => {
				this.init();
			})
		);	
	}

	ngOnInit() {
		this.init();
		this.navService.displayPage();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	handleClick($fn) {
		this[$fn]();
	}

	handleUpload($response) {
		if ($response.fn) {
			this[$response.fn]($response.event);

		}
	}

	/*
	 * Handles tab change
	*/
	onPanelTabChange(tab) {
		let id = tab.id;

		this.settings = tab;
		this.currentTabID = id;

		if (this.autoChangingTab) {
			return;
		}

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();
		this.reuploading = false;

		switch (id) {
			case 'upload': 
				this.course = {
					disableUpload : false,
					showURL			: false,
					active 			: true 
				};
				this.generateID();
				break;
			case 'add':
				this.course = {
					companyID: this.stateService.getActiveCompanyID(),
					active: true
				};
				this.editing = false;
				this.filePath = null;
				this.generateID();
				break;
			case 'edit':
				this.editing = true;
				this.course.courseID = null;
				break;
			case 'access':
			case 'download':
				break;
			case 'addToCompany':
				this.course = {
					'companyID': this.stateService.getActiveCompanyID(),
				};
				this.getAllCoursesOutsideCompany();
				break;
			case 'removeFromCompany':
				this.course = {
					'companyID': this.stateService.getActiveCompanyID(),
				};
				this.setCourses();
				break;
			default: 
				break;
		}
	}

	/*
	 * Handles course information is gathered
	*/
	handleInfoGathererd() {
		if (this.reuploading) {
			this.saveCourseContent();	
		} else {
			this.changeTab('add');
		}
	}

	/*
	 * Changes the tab
	 * @param {string} tabID
	*/
	changeTab(tabID) {
		if (this.currentTabID === tabID) {
			return;
		}
		this.currentTabID = tabID;
		this.autoChangingTab = true;
		if (this.panelTabCache) {
			this.settings = this.panelTabCache.selectTabById(tabID);
		}
		this.autoChangingTab = false;
	}

	/*
	 * On Changed Company ID
	*/
	changedCompanyID() {
		this.setCourses();
		this.course.courseID = null;
	}

	/*
	 * Sets the courses
	*/
	setCourses() {
		this.coursesService.reset();
		this.coursesService.setCompanyID(this.course.companyID);		
		this.coursesService.get();
	}

	/* 
	 * On init
	*/
	init() {
		this.tabSettings.upload.fields 				= this.permissionService.setFields(this.tabSettings.upload.fields);
		this.tabSettings.add.fields 				= this.permissionService.setFields(this.tabSettings.add.fields);
		this.tabSettings.edit.fields 				= this.permissionService.setFields(this.tabSettings.edit.fields);
		this.tabSettings.addToCompany.fields 		= this.permissionService.setFields(this.tabSettings.addToCompany.fields);
		this.tabSettings.removeFromCompany.fields 	= this.permissionService.setFields(this.tabSettings.removeFromCompany.fields);
		this.setCourses();

		if (!this.getCachedCourse()) {
			this.generateID();
			this.navService.displayPage();
		}
	}

	/*
	 * Gets the cached course
	 * @return {boolean}
	*/
	getCachedCourse() {
		let course = this.storageService.get('uploadedCourse');

		if (course) {
			course.courseID = course.courseID.toString(); 
			this.course = course;
			setTimeout(() => {
				this.changeTab('edit');
				this.editing = true;
				this.navService.displayPage();
			}, 1000);
			return true;
		}
		return false;
	}

	/* 
	 * Reset
	*/
	reset() {
		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
	}

	/* 
	 * Gets all courses for this specific companyID
	*/
	getAllCoursesOutsideCompany() {
		this.feedbackService.clearErrors();
		this.course.courseID = 0;
		return this.coursesService.getExternalCourses(this.searchedFor, this.course.companyID);
	}

	/* 
	 * Generates a course ID
	*/
	generateID() {
		if (this.course.courseID) {
			return;
		}
		this.course.courseType = 'thirdParty';
		this.lmsService.post('importcourse/generateCourseID', {'courseType': this.course.courseType}).then((result) => {
			if (result && result.success) {
				this.course.courseID = result.properties.courseID;
				this.course.disableUpload = false;
				this.course.validCourseID = true;
			}
		});
	}

	/* 
	 * On File Added
	*/
	fileAdded(event) {
		this.filePath = event.filePath;
		this.preprocess();
	}

	/* 
	 * On File Added while reuploading
	*/
	reuploadCourse(event) {
		let fileName =  event.response.properties.fileName,
			extension =  event.response.properties.extension;
		this.filePath =  event.filePath;
		this.reuploading = true;
		if (extension !== 'zip' && fileName) {
			this.course.courseURL = '/courses/' + this.course.courseID + '/' + fileName;
		}
		this.preprocess();
	}


	/*
	 * Upload to AWS
	*/
	uploadToAWS(courseIDs:Array<number> = []) {
		courseIDs = courseIDs.length ? courseIDs : this.course.courseIDs;
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		if (!courseIDs.length) {
			this.feedbackService.setError('noCoursesToDownload');
			return;
		}

		this.lioModalService.showLoading();
		this.lmsService.postAsync('courses/uploadToAWS', {'courseIDs': courseIDs}).then((result) => {
				let token = result.properties.token;
				if (token) {
					this.lmsService.getAsyncResult(token, (gresult) => {
						this.uploadToAWSResult(gresult);
					});
				} else {
					this.uploadToAWSResult(result);
				}
		});
	}


	/*
	 * Upload all courses to AWS
	*/
	uploadAllToAWS() {
		let courses = this.coursesService.rawCourses,
				courseIDs = [];

		courses.forEach((course) => {
			courseIDs.push(course.courseID);
		});

		this.uploadToAWS(courseIDs);
	}

	/*
	 * On AWS result
	*/
	uploadToAWSResult(result) {
		if (result.success) {
			let path = result.properties.companyIDPath,
					macros = [{'key': 'path', 'value': path}];
				this.lioModalService.show('courseuploader.awsSaved', null, macros);
		} else {
			this.lioModalService.hideLoading();
		}
	}


	/*
	 * On Download
	*/
	download() {
		let courseIDs = this.course.courseIDs;
		this.coursesToDownload = courseIDs;
		if (!courseIDs.length) {
				this.course.courseIDs = [];
				this.feedbackService.setError('noCoursesToDownload');
				return;
		}
		this.totalCoursesToDownload = courseIDs.length;
		this.downloadCourses();
	}


	downloadCourses() {
		let courseID = this.coursesToDownload.shift(-1),
				total = this.totalCoursesToDownload,
				current = total - this.coursesToDownload.length,
				title = 'Downloading ' + current + ' of ' + total;
		if (!courseID) {
			this.lioModalService.hideLoading();
			return;
		}
		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lmsService.postAsync('courses/download', {'courseID': courseID}, title).then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handleFileToDownload(courseID, gresult);
				});
			} else {
				this.handleFileToDownload(courseID, result);
			}
		});
	}

	/*
	 * Downloads the file
	*/
	handleFileToDownload(courseID, result) {
		if (result.success && result.properties.zipFile) {
			this.filesService.downloadFile(result.properties.zipFile, courseID + '.zip');
			this.downloadCourses();
		} else {
			let macros = [{'key': 'courseID', 'value': courseID}];
			if (this.coursesToDownload.length) {
				this.lioModalService.confirm('courseuploader.keepDownloading', null, macros).then((confirmed) => {
					if (confirmed) {
						this.downloadCourses();
					}
				})
			} else {
				this.lioModalService.hideLoading();
			}
		}
	}

	/*
	 * On Download All
	*/
	downloadAll() {
		let courses = this.coursesService.rawCourses,
				courseIDs = [];
		if (courses) {
			courses.forEach((course) => {
				courseIDs.push(course.courseID);
			});
			if (!courseIDs.length) {
				this.feedbackService.setError('noCoursesToDownload');
				return;
			}
			this.coursesToDownload = courseIDs;
			this.totalCoursesToDownload = courseIDs.length;
			this.feedbackService.clearErrors();
			this.feedbackService.clearMessages();

			this.coursesToDownload = courseIDs;
			this.totalCoursesToDownload = courseIDs.length;
			this.lioModalService.showLoading();

			this.lmsService.postAsync('courses/downloadAll', {'courseIDs': courseIDs}).then((result) => {
			let token = result.properties.token;
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.lioModalService.hideLoading();
					if (gresult.success && gresult.properties.zipFile) {
						this.filesService.downloadFile(gresult.properties.zipFile, this.stateService.getActiveCompanyID() + '.zip');
					}
				});
			} else {
				this.lioModalService.hideLoading();
				if (result.success && result.properties.zipFile) {
						this.filesService.downloadFile(result.properties.zipFile, this.stateService.getActiveCompanyID() + '.zip');
					}
			}
		});
		}
	}



	/*
	 * Get Course List
	*/
	getCourseList() {
		let courses = this.coursesService.rawCourses,
				courseIDs = [],
				headers = ['courseName', 'courseID', 'courseDescription', 'companyID'];

		if (courses) {
			courses.forEach((course) => {
				courseIDs.push(course.courseID);
			});
		}

		this.lmsService.post('courses/getCourseList', {couseIDs: courseIDs, headers: headers}).then((result) => {

			let courses = result.properties.courses;

			courses.forEach((course) => {
					course.filePath = course.courseID;
			});
			let fields = [
						{ name : 'courseName', model : 'courseName', export: true },
						{ name : 'courseID', model : 'courseID', export: true },
						{ name : 'courseDescription', model : 'courseDescription', export: true },
						{ name : 'companyID', model : 'companyID', export: true },
						{ name : 'filePath', model : 'filePath', export: true },
					]
			this.workerService.export(courses, fields, 'courselist');
		});
	}


	/*
	 * Preprocess / Unzips the file Path
	*/
	preprocess() {
		let courseType = this.course.courseType;
		let courseID = this.course.courseID;
		let filePath = this.filePath;

		this.feedbackService.clearErrors();
		this.feedbackService.clearMessages();

		this.lmsService.postAsync('importcourse/preprocess', {'courseType': courseType, 'courseID': courseID, 'filePath': filePath},
		'uploading').then((result) => {
			let token = result.properties.token;
			
			if (token) {
				this.lmsService.getAsyncResult(token, (gresult) => {
					this.handlePreprocessResults(gresult);
				});
			} else {
				this.handlePreprocessResults(result);
			}
		});	
	}

	/*
	 * Handles pre-process results
	*/
	handlePreprocessResults(result) {
		this.lioModalService.hideLoading();
		if (!result.success) {
			return;
		}

		let badFiles 	= result.properties.badFiles || [];
		let courseInfo 	= result.properties.courseInfo;
		let filePath 	= result.properties.filePath;
		let errors 		= [];
		let macros 		= [
			{'key': 'badFiles', 'value': badFiles.join(',')},
			{'key': 'supportEmail', 'value': this.configSettings.SUPPORT_EMAIL}
		];

		// The file path changes when uploading a document and not a course package
		if (filePath) {
			this.filePath = filePath;
		}

		if (badFiles.length) {
			errors.push(this.utilService.localizeError('weDetectedSomeInvalidFileFormats', macros));
			errors.push(this.utilService.localizeError('invalidFilesBadFiles', macros));
			this.feedbackService.setErrors(errors);
		} else {
			this.course = courseInfo;
			this.handleInfoGathererd();
		}
	}

	/*
	 * Edits a course
	*/
	editCourse() {
		this.handleYouTube().then(() => {
			this.saveCourseInfo();
		});
	}

	/*
	 * Saves the course info
	*/
	saveCourseInfo() {
		let courseType 					= this.course.courseType,
				courseID 						= this.course.courseID,
				coursePath 					= this.coursePath,
				filePath 						= this.filePath,
				url 								= 'importcourse/saveCourse',
				processingMessage 	= 'saving';

		if (!this.isValid()) {
			return;
		}

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		if (this.editing) {
			processingMessage = 'updating';
		}

		return this.lmsService.postAsync(url, {'course': this.course, 'filePath': filePath, 'courseType': courseType, 'courseID': courseID, 'coursePath': coursePath},
		processingMessage).then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, () => {
					this.savedSuccessfully();
				});
			} else {
				this.savedSuccessfully();
			}
		});
	}

	/*
	 * Saves the course
	*/
	saveCourse() {
		this.handleYouTube().then(() => {
			this.processCourse();
		});
	}

	/*
	 * Saves the course and Uploads to the course server
	*/
	processCourse() {
		let courseType 			= this.course.courseType,
				courseID 				= this.course.courseID,
				coursePath 			= this.coursePath,
				filePath 				= this.filePath,
				url 						= 'importcourse/process';

		if (!this.isValid()) {
			return;
		}

		// If no file path, they are saving an external course
		if (!filePath) {
			this.saveCourseInfo();
			return;
		}

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		this.lmsService.postAsync(url, {'course': this.course, 'filePath': filePath, 'courseType': courseType, 'courseID': courseID, 'coursePath': coursePath},
		'saving').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleUploadedToServer(result);
				});
			} else {
				this.handleUploadedToServer(result);
			}
		});
	}

	/*
	 * Preview the course and Uploads to the course server
	*/
	previewCourse() {
		this.storageService.set('origin', 'courseuploader');
		this.storageService.set('preview', this.course);
		this.storageService.set('uploadedCourse', this.course);
		this.storageService.set('courseURL', this.course.courseURL);
		this.storageService.set('courseID', this.course.courseID);
		this.storageService.set('show404', true);
		this.navService.goto('courselaunch');
	}

	/*
	 * Replaces the course to the course server
	*/
	saveCourseContent() {
		let courseID = this.course.courseID,
			coursePath = this.coursePath,
			filePath = this.filePath,
			url = 'importcourse/saveCourseContent';

		if (!this.isValid()) {
			return;
		}	

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();

		this.lmsService.postAsync(url, {'filePath': filePath, 'courseID': courseID, 'coursePath': coursePath, 'course': this.course},
		'saving').then((result) => {
			let token = result.properties.token;

			if (token) {
				this.lmsService.getAsyncResult(token, (result) => {
					this.handleUploadedToServer(result);
				});
			} else {
				this.handleUploadedToServer(result);
			}
		});
	}


	/*
	 * Gets the course access
	*/
	getAccessByCourseID() {
		this.lmsService.post('courseaccess/getCourseAccess', {'courseID': this.course.courseID}).then((result) => {
			if (result.properties.course) {
				this.course = result.properties.course;
			} else {
				this.course.archived = 0;
				this.course.whitelist = 0;
			}
		});
	}

	/*
	 * Saves the course access
	*/
	saveAccess() {
		this.lmsService.post('courseaccess/saveAccess', {'course': this.course}).then((result) => {
			this.lioModalService.hideLoading();
			if (result.success) {
				this.lioModalService.show('savedSuccessfully');
			}
		});
	}

	/*
	 * Gets course Info by course ID
	*/
	getCourseByID() {
		if (!this.course.courseID) {
			return;
		}
		this.lioModalService.showLoading('loading');

		this.lmsService.post('courses/getCourseByID', {'courseID': this.course.courseID}).then((result) => {
			this.lioModalService.hideLoading();
			this.course = result.properties.course;
			if (!this.course.companyID) {
				this.course.companyID = this.stateService.getActiveCompanyID();
			}
		});
	}

	/*
	 * Upon uploaded
	*/
	handleUploadedToServer(result) {
		if (result.success && !result.errors.length && !result.properties.cancelled) {
			this.savedSuccessfully();
		}
	}

	/*
	 * Upon saved successfully
	*/
	savedSuccessfully() {
		this.navService.changedForm = false;
		this.coursesService.reset();
		this.setCourses();
		this.course.courseID = this.course.courseID.toString(); 
		this.changeTab('edit');
		if (this.editing) {
			this.lioModalService.show('updatedSuccessfully');
		} else {
			this.lioModalService.show('savedSuccessfully');
		}

		this.editing = true;
	}

	/*
	 * Shows the course URL link
	*/
	showLink() {
		this.course.showURL = true;
	}

	/*
	 * Upon saved successfully
	*/
	addCourseManually() {
		if (!this.isValid()) {
			return;
		}

		this.handleYouTube().then(() => {
			this.changeTab('add');
		});
	}

	/*
	 * Handles youTube links
	 * @return {Promise}
	*/
	handleYouTube() {
		let id = this.getYoutubeID();
		if (id) {
			this.lioModalService.showLoading('courseuploader.pullingYouTubeData');
			return this.getYouTubeInfo(id).then(() => {
				return setTimeout(() => {
					this.lioModalService.hideLoading();
				}, 500);
			});
		}
		return new Promise((resolve) => {
			resolve(true);
		});
	}

	/*
	 * Determines if course link is youtubee
	*/
	getYoutubeID() {
		let youTubes = [
			{'url': 'youtube.com', 'splitter': 'v='},
			{'url': 'youtu.be', 'splitter': 'youtu.be/'}, 
		];
		let youTubeID = null;
		let splitURL = null;
		let courseURL = this.course.courseURL;

		if (!courseURL) {
			return null;
		}

		// Already has correct youtube embedded info 
		if (courseURL.indexOf('youtube.com/embed') > -1) {
			return null;
		}

		youTubes.forEach((youTube) => {
			this.lioLogService.log([courseURL, youTube]);
			if (courseURL.indexOf(youTube.url) > -1) {
				splitURL = courseURL.split(youTube.splitter);
				this.lioLogService.log([courseURL, youTube, splitURL]);

				if (splitURL && splitURL[1]) {
					youTubeID = splitURL[1];
				}
			}
		});

		return youTubeID;
	}

	/*
	 * Gets youtube info
	 * @param {string} id
	*/
	getYouTubeInfo(id) {
		let	apiKey	= this.googleAPIKey;
		let	info 	= null;

		return this.lmsService.getReal('https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + id +  '&key=' + apiKey, {responseType: 'json'}).then((result) => {
			if (result && result.success) {
				info = result.data.items[0];
				if (!info) {
					return;
				}
				this.course.courseName = info.snippet.title;
				this.course.courseDescription = info.snippet.description;
				if (info.snippet.thumbnails.standard) {
					this.course.thumbnail = info.snippet.thumbnails.standard.url;
				}
				this.course.courseURL = 'https://www.youtube.com/embed/' + id;
				return this.getYouTubeDuration(id);
			}
		});
	}

	/*
	 * Gets youtube info
	 * @param {string} id
	*/
	getYouTubeDuration(id) {
		let apiKey	= this.googleAPIKey;
		let info 	= null;
		let duration = null;

		return this.lmsService.getReal('https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=' + id +  '&key=' + apiKey, {responseType: 'json'}).then((result) => {
			if (result && result.success) {
				info = result.data.items[0];
				if (info) {
					duration = info.contentDetails.duration;
					if (duration) {
						this.course.length = this.convertYouTubeTime(duration);	
					}
				}
			}
		});
	}

	/*
	 * Converts youtube time to something readable
	 * @prarm {string} length
	 * @return {string}
	*/
	convertYouTubeTime(duration) {
		let a			= duration.match(/\d+/g);
		let readable 	= '';

		if (duration.indexOf('M') >= 0 && duration.indexOf('H') == -1 && duration.indexOf('S') == -1) {
			a = [0, a[0], 0];
		}

		if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1) {
			a = [a[0], 0, a[1]];
		}
		if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1 && duration.indexOf('S') == -1) {
			a = [a[0], 0, 0];
		}

		if (a.length == 3) {
			readable += a[0] + ' Hours ';
			readable += a[1] + ' Minutes ';
			readable += a[2] + ' Seconds';
		}else if (a.length == 2) {
			readable += a[0] + ' Minutes ';
			readable += a[1] + ' Seconds';
		}else if (a.length == 1) {
			readable += a[0] + ' Seconds';
		}
		return readable;
	}

	/*
	 * Generates a course ID
	*/
	validateID() {
		let courseID = this.course.courseID;

		this.feedbackService.clearMessages();
		this.feedbackService.clearErrors();
		this.course.validCourseID = false;

		if (!courseID) {
			return;
		}

		this.lmsService.post('importcourse/validateID', {'courseID': courseID}).then((result) => {
			if (result.success) {
				let dbExists = result.properties.dbExists,
					fileExists = result.properties.fileExists,
					invalidCourseID = result.properties.invalidCourseID,
					macros = [{'key': 'input', 'value': 'courseID'}];

				this.course.validCourseID = true;

				if (invalidCourseID) {
					this.course.validCourseID = false;
					this.feedbackService.setError('mustBeNumeric', macros);
				} else if (!dbExists && fileExists) {
					this.feedbackService.setMessage('anotherCourseIsUsingThisCourseID');
					this.course.validCourseID = false;
				} else if (dbExists) {
					this.feedbackService.setMessage('thisCourseIdAlreadyExistsDifferent');
					this.course.validCourseID = false;
				}
			}
		});
	}

	/*
	 * Adds a course to a company
	*/
	addToCompany() {
		this.lmsService.post('courses/addToCompany', {'courseIDs': this.course.courseIDs, 'companyID': this.course.companyID}).then((result) => {
			if (result.success) {
				this.lioModalService.show('addedSuccessfully');
				this.getAllCoursesOutsideCompany();
				this.course.courseIDs = [];
				this.setCourses();
			}
		});
	}

	/*
	 * Removes a course from a company
	*/
	removeFromCompany() {
		this.lmsService.post('courses/removeFromCompany', {'courseID': this.course.courseID, 'companyID': this.course.companyID}).then((result) => {
			if (result.success) {
				this.lioModalService.show('removedSuccessfully');
				this.course.courseID = null;
				this.setCourses();
			}
		});
	}

	/*
	 * Updates the allowed state
	*/
	updateAllowed() {
		if (this.course.requireOverwrite) {
			if (this.course.overwrite) {
				this.course.disableUpload = false;
			} else {
				this.course.disableUpload = true;
			}
		}
	}

	/*
	 * Disable upload
	*/
	disableUpload() {
		this.course.disableUpload = true;
	
	}
	
	/*
	 * Checks if the form is valid
	 * @return {boolean}
	*/
	isValid() {
		let isValid = true;
		this.feedbackService.clearErrors();

		if (!this.formValidatorService.isValid(this.course, this.settings.fields)) {
			isValid = false;
			this.feedbackService.setErrors(this.formValidatorService.getErrors(), false);
		}

		this.course = this.formValidatorService.getData();

		return isValid;
	}



	/* 
	 * Set the updated search criteria
	*/
	onSearchedFor(search) {
			this.searchedFor = search;
	}

	/* 
	 * On update
	 * @param {array} the field updated
	*/
	onUpdate(field) {
		let updated = false;
		if (field) {
			switch (field.model) {
				case 'courseID':
					if (this.currentTabID == 'upload') {
						this.feedbackService.clearErrors();
						this.validateID();
						updated = true;
					} else if (this.currentTabID == 'edit') {
						this.feedbackService.clearErrors();
						this.getCourseByID();
						updated = true;
					} else if (this.currentTabID == 'access') {
						this.feedbackService.clearErrors();
						this.getAccessByCourseID();
						updated = true;
					}
					break;
				case 'overwrite':
					if (this.currentTabID == 'upload') {
						this.feedbackService.clearErrors();
						this.updateAllowed();
						updated = true;
					}
					break;
				case 'courseIDs':
					if (this.currentTabID == 'addToCompany') {
						this.feedbackService.clearErrors();
						this.getAllCoursesOutsideCompany();
						updated = true;
					}
					break;
				case 'companyID':
					if (this.currentTabID == 'addToCompany') {
						this.feedbackService.clearErrors();
						this.getAllCoursesOutsideCompany();
						updated = true;
					} else if (this.currentTabID == 'removeFromCompany') {
						this.feedbackService.clearErrors();
						this.changedCompanyID();
						updated = true;
					}
					break;
				default:
					break;
			}
		}

		if (updated) {
			this.formValidatorService.resetFields();
			this.feedbackService.clearErrors();
		}
	}
}