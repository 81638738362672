
import { ChartsModule } from 'ng2-charts';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../../imports/angular-material';

import { LioDirectivesModule } from './../../directives/directives.module';
import { LioPipesModule } from './../../pipes/pipes.module';
import { LioFormsModule } from './../lio-forms/lio-forms.module';

import { LioPieChart } from './components/pie-chart/pie-chart.component';
export { LioPieChart } from './components/pie-chart/pie-chart.component';

import { LioResultsTable } from './components/results-table/results-table.component';
export { LioResultsTable } from './components/results-table/results-table.component';

import { LioResultFilters } from './components/result-filters/result-filters.component';
export { LioResultFilters } from './components/result-filters/result-filters.component';

import { ErrorComponent } from './components/error/error.component';
export { ErrorComponent } from './components/error/error.component';

import { LIOPaginator } from './components/paginator/paginator.component';
export { LIOPaginator } from './components/paginator/paginator.component';

import { LIOPaginatorControls } from './components/paginator-controls/paginator-controls.component';
export { LIOPaginatorControls } from './components/paginator-controls/paginator-controls.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ChartsModule,
		AngularMaterialModule,
		LioFormsModule,
		LioPipesModule,
		LioDirectivesModule
	],
	declarations: [
		LioPieChart,
		LioResultsTable,
		LioResultFilters,
		ErrorComponent,
		LIOPaginator,
		LIOPaginatorControls
	],
	exports: [
		LioPieChart,
		LioResultsTable,
		LioResultFilters,
		ErrorComponent,
		LIOPaginator,
		LIOPaginatorControls
	],
	bootstrap: [
		LioPieChart,
		LioResultsTable,
		LioResultFilters,
		ErrorComponent,
		LIOPaginatorControls
	],
})
export class LioDataDisplayModule{}