import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { InputComponent } from '.././input/input.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})

export class SendEmailComponent extends InputComponent implements OnInit {
	@Output() override opened_emitter		= new EventEmitter<any>();
	@Output() override closed_emitter		= new EventEmitter<any>();
	@Output() override submitted_emitter		= new EventEmitter<any>();

	public error 			= null;
	public response 		= null;

	public override settings : any = {
		'id' : 'am_modal_send_email',
		'title': 'Send an email',
		'canClose': true,
		'submit': 'Send',
		'close': 'Close',
		'fromAddress': 'donotreply@trueoffice.com',
		'toAddresses': '',
		'subject': 'Here is your report',
		'message': 'Attached is the information you requested',
		'attachments': [],
		'attachmentTitle': '',
		'sendSpreadsheet': true,
		'sendPDF': false,
		'csv': '',
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) 		public override parentSettings		: any) {
		super(parentSettings)
		// Extends the base settings
		this.settings = Object.assign(this.settings, parentSettings);
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}

	/*
	 * Toggle
	*/
	toggleSendPDF() : void {
		this.settings.sendPDF = !this.settings.sendPDF;
	}

	/*
	 * Toggle
	*/
	toggleSendSpreadsheet() : void {
		this.settings.sendSpreadsheet = !this.settings.sendSpreadsheet;
	}

	/*
	 * Prepare and submit
	*/
	prepareAndSubmit() : void {
		if (!this.settings.sendSpreadsheet && !this.settings.sendPDF) {
			this.error = 'Please select one of the attachment methods';
			return;
		}

		if (this.settings.sendSpreadsheet) {
			this.settings.attachments.push({
				'type' : 'csv',
				'name' : this.settings.attachmentTitle, 
				'content' : this.settings.csv
			});
		}

		if (this.settings.sendPDF) {
			this.settings.attachments.push({
				'type' : 'pdf',
				'name' : this.settings.attachmentTitle, 
				'content' : this.settings.pdf
			});
		}
		
		this.submit();
	}

	/*
	 * Submits the result
	 * @param {Object} result 
	*/
	override submit() : void {
		this.submitted_emitter.emit(this.settings);
	}
}

