import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';

import { NEVER, Subscription } from 'rxjs';

import { loaderService } from '../../services/loader.service';
import { emailEventService } from '../../services/email-event.service';
import { navService } from '../../services/nav.service';
import { debugService } from '../../services/debug.service';
import { utilService } from '../../services/util.service';

import { emailSettings } from '../email/email.settings';
import { scheduleEmailSettings } from './schedule-email.settings';
import { scheduleEmailEventSettings } from './schedule-email-event.settings';
import { scheduleEmailEventHistorySettings } from './schedule-email-event-history.settings';
import { scheduleEmailEventListSettings } from './schedule-email-event-list.settings';

import { PanelTabCache } from '../../modules/structural/components/panel-tabber/panel-tab-cache.service';

@Component({
	selector: 'lio-schedule-emails',
	templateUrl: './schedule-emails.component.html'
})
export class ScheduleEmailsComponent implements OnDestroy {
	@ViewChild('tabCache', {read: PanelTabCache})
	public panelTabCache	:PanelTabCache 	= null;

	public panels			:any			= this.utilService.copy(this.scheduleEmailSettings.panels);

	private subscriptions	:Subscription 	= NEVER.subscribe();

	constructor(
		@Inject(loaderService)							public	loaderService							:loaderService,
		@Inject(emailEventService)						public	emailEventService					:emailEventService,
		@Inject(navService)								public	navService								:navService,
		@Inject(debugService)							public	debugService							:debugService,
		@Inject(utilService)							public	utilService								:utilService,
		@Inject(emailSettings)							public	emailSettings				:emailSettings,
		@Inject(scheduleEmailSettings)					public	scheduleEmailSettings		:scheduleEmailSettings,
		@Inject(scheduleEmailEventSettings)				public	scheduleEmailEventSettings			:scheduleEmailEventSettings,
		@Inject(scheduleEmailEventHistorySettings)		public	scheduleEmailEventHistorySettings	:scheduleEmailEventHistorySettings,
		@Inject(scheduleEmailEventListSettings)			public	scheduleEmailEventListSettings		:scheduleEmailEventListSettings
	){
		this.debugService.register('scheduleEmails', this);
		this.navService.setActivePage('scheduleEmails');

		this.subscriptions.add(
			this.emailEventService.callback.subscribe((request) => {
				this.callback(request);
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	onTemplatesChanged() {
		this.emailEventService.getEmailTemplates();
	}

	/*
	 * Call back from one of the services
	*/
	callback(request) {
		if (request.name === 'changeTab') {
			this.changeTab(request);
		}

		if (request.name === 'loaded') {
			this.navService.displayPage();
		}
	}

	/*
	 * Changes the tab
	*/
	changeTab(request) {
		let panel = this.panels[request.value]; 
		if (panel) {
			this.panelTabCache.selectTab(panel);
		}
	}

	/*
 	 * Templates loaded
	*/
	templatesLoaded() {
		this.navService.displayPage();
	}
}