<div class="row">
	<!-- Course Uploader/Editor Form -->
	<lio-panel-tabber #tabCache class="col-md-10 offset-md-1" (onTabChange)="onPanelTabChange($event)">
		<lio-panel-tab [settings]="tabSettings.upload">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.upload.selected"
					[fields]="tabSettings.upload.fields"
					[fieldConfig]="tabSettings.upload.fieldConfig"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)" 
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.upload.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.add">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.add.selected"
					[fields]="tabSettings.add.fields"
					[fieldConfig]="tabSettings.add.fieldConfig"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)" 
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.add.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.edit">
			<form *lioPanelTabContent role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.edit.selected"
					[fields]="tabSettings.edit.fields"
					[fieldConfig]="tabSettings.edit.fieldConfig"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.edit.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.access">
			<form *lioPanelTabContent role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.access.selected"
					[fields]="tabSettings.access.fields"
					[fieldConfig]="tabSettings.access.fieldConfig"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)" 
					[buttons]="tabSettings.access.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.addToCompany">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.addToCompany.selected"
					[fields]="tabSettings.addToCompany.fields"
					[fieldConfig]="tabSettings.addToCompany.fieldConfig"
					[model]="course"
					(onSearch)="onSearchedFor($event)"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)"
					[buttons]="tabSettings.addToCompany.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.removeFromCompany">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.removeFromCompany.selected"
					[fields]="tabSettings.removeFromCompany.fields"
					[fieldConfig]="tabSettings.removeFromCompany.fieldConfig"
					(onSearch)="onSearchedFor($event)"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)"
					[buttons]="tabSettings.removeFromCompany.buttons" 
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
		<lio-panel-tab [settings]="tabSettings.download">
			<form *lioPanelTabContent class="center" role="form" autocomplete="off" novalidate>
				<lio-form-inputs *ngIf="tabSettings.download.selected"
					[fields]="tabSettings.download.fields"
					[fieldConfig]="tabSettings.download.fieldConfig"
					(onSearch)="onSearchedFor($event)"
					[model]="course"
					(onChanged)="onUpdate($event)">
				</lio-form-inputs>
				<lio-buttons
					(onClick)="handleClick($event)"
					(onUpload)="handleUpload($event)"
					[buttons]="tabSettings.download.buttons"
					[model]="course">
				</lio-buttons>
			</form>
		</lio-panel-tab>
	</lio-panel-tabber>
</div>

